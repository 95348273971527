import './neb-tooltip';
import './controls/neb-button-action';

import { LitElement, html, css } from 'lit';

import { APPOINTMENT_BILLING_TYPE } from '../../../neb-api-client/src/mappers/appointment-billing-info-mapper';
import {
  CSS_COLOR_GREY_3,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';
import { formatDollarAmount } from '../../../neb-utils/formatters';
import { BILL_TYPE } from '../../../neb-utils/neb-ledger-util';

const ENCOUNTER_BALANCE_TOOLTIP_TEXT =
  'This is an estimate based on the selected bill type. Click Save to update the balance.';

export const ELEMENTS = {
  balanceTooltip: {
    id: 'tooltip-encounter-balance',
  },
  toolTipText: {
    id: 'tooltip-encounter-balance-text',
  },
  encounterBalanceContainer: {
    id: 'encounter-balance-container',
  },
  encounterBalance: {
    id: 'encounter-balance',
  },
  encounterCharges: {
    id: 'encounter-charges',
  },
  encounterPayments: {
    id: 'encounter-payments',
  },
  encounterCoveredCharges: {
    id: 'covered-charges',
  },
  encounterAdjustments: {
    id: 'adjustments',
  },
  encounterPayerOwed: {
    id: 'payer-owed',
  },
  encounterPatientOwed: {
    id: 'patient-owed',
  },
  buttonVisitSummary: {
    id: 'button-visit-summary',
  },
  encounterDiscount: {
    id: 'encounter-discount',
  },
};

class NebEncounterBalance extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      model: Object,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.model = {
      billType: BILL_TYPE.SELF,
      totalAllCharges: 0,
      adjustments: 0,
      patientOwed: 0,
      payerOwed: 0,
      totalPayments: 0,
      totalDiscounts: 0,
      totalBalance: 0,
      totalTaxes: 0,
    };

    this.onOpenVisitSummary = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      openVisitSummary: () => this.onOpenVisitSummary(),
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
          width: 100%;
        }

        .container-label {
          display: flex;
          justify-content: space-between;
        }

        .container-label-balance {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin-top: 10px;
        }

        .container-title-tooltip {
          display: flex;
        }

        .encounter-balance {
          padding: 10px;
          margin-bottom: ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
        }

        .encounter-balance-tooltip {
          margin-left: 5px;
          margin-bottom: 2px;
        }

        .label-header {
          margin-bottom: 2px;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  __renderTooltip() {
    return html`
      <neb-tooltip
        id="${ELEMENTS.balanceTooltip.id}"
        class="encounter-balance-tooltip"
        defaultAnchor="top"
      >
        <div slot="tooltip" id="${ELEMENTS.toolTipText.id}">
          ${ENCOUNTER_BALANCE_TOOLTIP_TEXT}
        </div>
      </neb-tooltip>
    `;
  }

  __renderVisitSummaryButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.buttonVisitSummary.id}"
        label="Open Visit Summary"
        .disabled="${!this.model.encounterId}"
        leadingIcon="noteAdd"
        .onClick="${this.__handlers.openVisitSummary}"
      ></neb-button-action>
    `;
  }

  __renderTotalCharges() {
    return html`
      <div class="container-label container-label-total-charges">
        <div>Total Charges</div>
        <div id="${ELEMENTS.encounterCharges.id}">
          ${
            this.model.totalAllCharges
              ? formatDollarAmount(this.model.totalAllCharges)
              : '$0.00'
          }
        </div>
      </div>
    `;
  }

  __renderAdjustments() {
    return html`
      <div class="container-label">
        <div>Adjustments</div>

        <div id="${ELEMENTS.encounterAdjustments.id}">
          ${
            this.model.adjustments
              ? formatDollarAmount(this.model.adjustments)
              : '$0.00'
          }
        </div>
      </div>
    `;
  }

  __renderPayerOwed() {
    return html`
      <div class="container-label">
        <div>Payer Owed</div>

        <div id="${ELEMENTS.encounterPayerOwed.id}">
          ${
            this.model.payerOwed
              ? formatDollarAmount(this.model.payerOwed)
              : '$0.00'
          }
        </div>
      </div>
    `;
  }

  __renderTotalPatientPayments() {
    return html`
      <div class="container-label">
        <div>Total Patient Payments</div>

        <div id="${ELEMENTS.encounterPayments.id}">
          ${
            this.model.totalPayments
              ? formatDollarAmount(this.model.totalPayments)
              : '$0.00'
          }
        </div>
      </div>
    `;
  }

  __renderDiscounts() {
    return html`
      <div class="container-label">
        <div>Discounts</div>

        <div id="${ELEMENTS.encounterDiscount.id}">
          ${
            this.model.totalDiscounts
              ? formatDollarAmount(this.model.totalDiscounts)
              : '$0.00'
          }
        </div>
      </div>
    `;
  }

  __renderTotalPayment() {
    return html`
      <div class="container-label">
        <div>Patient Payment</div>

        <div id="${ELEMENTS.encounterPayments.id}">
          ${
            this.model.totalPayments
              ? formatDollarAmount(this.model.totalPayments)
              : '$0.00'
          }
        </div>
      </div>
    `;
  }

  __renderTotalBalance() {
    return html`
      <div class="container-label container-label-balance">
        <div>Patient Encounter Balance</div>
        <div id="${ELEMENTS.encounterBalance.id}">
          ${
            this.model.totalBalance
              ? formatDollarAmount(this.model.totalBalance)
              : '$0.00'
          }
        </div>
      </div>
    `;
  }

  __renderCoveredCharges() {
    return this.model.billType === BILL_TYPE.PACKAGE
      ? html`
          <div class="container-label">
            <div>Covered by Care Package</div>

            <div id="${ELEMENTS.encounterCoveredCharges.id}">
              ${
                this.model.coveredCharges
                  ? formatDollarAmount(this.model.coveredCharges)
                  : '$0.00'
              }
            </div>
          </div>
        `
      : '';
  }

  __renderPatientOwed() {
    return html`
      <div class="container-label">
        <div>Patient Owed</div>

        <div id="${ELEMENTS.encounterPatientOwed.id}">
          ${
            this.model.patientOwed
              ? formatDollarAmount(this.model.patientOwed)
              : '$0.00'
          }
        </div>
      </div>
    `;
  }

  __renderBalancesForSelfPay() {
    return html`
      <div
        id="${ELEMENTS.encounterBalanceContainer.id}"
        class="encounter-balance"
      >
        ${this.__renderTotalCharges()} ${this.__renderAdjustments()}
        ${this.__renderTotalPatientPayments()} ${this.__renderDiscounts()}
        ${this.__renderTotalBalance()}
      </div>
    `;
  }

  __renderBalancesForCarePackage() {
    return html`
      <div
        id="${ELEMENTS.encounterBalanceContainer.id}"
        class="encounter-balance"
      >
        ${this.__renderTotalCharges()} ${this.__renderAdjustments()}
        ${this.__renderCoveredCharges()} ${this.__renderTotalPatientPayments()}
        ${this.__renderDiscounts()} ${this.__renderTotalBalance()}
      </div>
    `;
  }

  __renderBalancesForInsurance() {
    return html`
      <div
        id="${ELEMENTS.encounterBalanceContainer.id}"
        class="encounter-balance"
      >
        ${this.__renderTotalCharges()} ${this.__renderAdjustments()}
        ${this.__renderCoveredCharges()} ${this.__renderPayerOwed()}
        ${this.__renderPatientOwed()} ${this.__renderTotalPayment()}
        ${this.__renderDiscounts()} ${this.__renderTotalBalance()}
      </div>
    `;
  }

  render() {
    return html`
      <div class="container-encounter-balance">
        <div class="container-title-tooltip">
          <div class="label-header label-encounter-balance">
            Patient Encounter Balance
          </div>
          ${this.__renderTooltip()}
        </div>

        ${
          this.model.billType === APPOINTMENT_BILLING_TYPE.SelfPay
            ? this.__renderBalancesForSelfPay()
            : ''
        }
        ${
          this.model.billType === APPOINTMENT_BILLING_TYPE.CarePackage
            ? this.__renderBalancesForCarePackage()
            : ''
        }
        ${
          this.model.billType === APPOINTMENT_BILLING_TYPE.Insurance
            ? this.__renderBalancesForInsurance()
            : ''
        }
        ${this.__renderVisitSummaryButton()}
      </div>
    `;
  }
}

customElements.define('neb-encounter-balance', NebEncounterBalance);
