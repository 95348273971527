import './neb-documents-scroll';
import '../../../neb-lit-components/src/components/neb-search-bar';
import '../../../neb-lit-components/src/components/neb-button-actions';

import { LitElement, html } from 'lit';

import '../../../../src/components/filters/neb-filters-documents';
import '../../../../src/components/filters/neb-filters-documents-mobile';
import {
  CSS_SPACING,
  CSS_SPACING_ROW_LARGE,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_2,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  uploadButton: {
    id: 'upload-button',
  },
  scanButton: {
    id: 'scan-button',
  },
  scroll: {
    id: 'scroll',
  },
  searchBar: {
    id: 'search-bar',
  },
  bulkActionMenu: {
    id: 'bulk-action-menu',
  },
  filters: { id: 'filters' },
  filtersMobile: { id: 'filters-mobile' },
};
const DEBOUNCE_DELAY = 300;

export class NebDocumentsList extends LitElement {
  static get properties() {
    return {
      searchText: String,
      totalDocuments: Number,
      selectedIndex: Number,
      documents: Array,
      small: {
        type: Boolean,
        reflect: true,
      },
      noDocuments: Boolean,
      expanded: Boolean,
      checkedDocuments: Object,
      isOnEncounterDocuments: Boolean,
      appointmentTypes: Array,
      displayEncounterFilter: Boolean,
      advancedSearchModel: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.searchText = '';
    this.totalDocuments = 0;
    this.selectedIndex = -1;
    this.debounceDelay = DEBOUNCE_DELAY;
    this.debounceTimeout = null;
    this.documents = [];
    this.noDocuments = false;
    this.expanded = false;
    this.checkedDocuments = {};
    this.isOnEncounterDocuments = false;
    this.appointmentTypes = [];
    this.displayEncounterFilter = false;
    this.advancedSearchModel = {};

    this.onRequestDocuments = () => {};

    this.onSelectDocumentIndex = () => {};

    this.onOpenPopupUpload = () => {};

    this.onSearch = () => {};

    this.onAdvancedSearch = () => {};

    this.onDocumentListRendered = () => {};

    this.onGetBulkActions = () => {};

    this.onCheckDocument = () => {};

    this.onOpenPopupScan = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      requestDocuments: () => this.onRequestDocuments(),
      selectDocumentIndex: index => this.onSelectDocumentIndex(index),
      uploadClicked: () => this.onOpenPopupUpload(),
      scanClicked: () => this.onOpenPopupScan(),

      search: text => this.debounce(text),
      applyFilters: filterModel => {
        this.__isFilteringByAvancedSearch = true;

        this.onAdvancedSearch(filterModel);
      },
      documentListRendered: count => this.onDocumentListRendered(count),
      getBulkActions: () => this.onGetBulkActions(),
      checkDocument: (documentId, checked) =>
        this.onCheckDocument(documentId, checked),
    };
  }

  debounce(text) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    }

    this.debounceTimeout = setTimeout(
      () => this.search(text),
      this.debounceDelay,
    );
  }

  search(text) {
    this.searchText = text;
    this.onSearch(this.searchText);
  }

  reset() {
    if (this.searchText.length) {
      this.search('');
    }

    this.__elements.scroll.reset();
  }

  scrollToDocument(index) {
    this.__elements.scroll.scrollToDocument(index);
  }

  isFilteringByAdvancedSearch() {
    const {
      searchText,
      encounterNumber,
      appointmentTypeId,
      dosFrom,
      dosTo,
    } = this.advancedSearchModel;
    return (
      searchText || encounterNumber || appointmentTypeId || dosFrom || dosTo
    );
  }

  isFiltering() {
    return this.searchText.length > 0 || this.isFilteringByAdvancedSearch();
  }

  firstUpdated() {
    this.__elements = {
      scroll: this.shadowRoot.getElementById(ELEMENTS.scroll.id),
    };
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .button-container {
          margin-top: ${CSS_SPACING};
        }

        .search-container {
          padding-top: ${CSS_SPACING};
          padding-left: ${CSS_SPACING};
          padding-right: ${CSS_SPACING};
          box-sizing: border-box;
        }

        .base-button {
          outline: none;
          cursor: pointer;
          padding: 5px 12px;
          border: 1px solid ${CSS_COLOR_HIGHLIGHT};
          border-radius: 18px;
          background-color: transparent;
          font-weight: 700;
          font-size: 12px;
          text-transform: uppercase;
          color: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 20px;
        }

        .search-documents-input {
          border: 1px solid ${CSS_COLOR_GREY_2};
          border-radius: 3px;
          height: 35px;
          width: 100%;
        }

        .documents-scroll-container {
          height: calc(100% - 130px);
          box-sizing: border-box;
          overflow-y: hidden;
          padding-top: ${CSS_SPACING_ROW_LARGE};
        }

        .action-menu {
          padding-top: ${CSS_SPACING_ROW_LARGE};
          padding-left: 14px;
        }

        .mobile-filter {
          margin-top: ${CSS_SPACING};
        }
      </style>
    `;
  }

  __renderButtons() {
    return html`
      <div class="button-container">
        ${this.__renderUploadButton()} ${this.__renderScanButton()}
      </div>
    `;
  }

  __renderUploadButton() {
    return html`
      <button
        id="${ELEMENTS.uploadButton.id}"
        class="base-button"
        @click="${this.__handlers.uploadClicked}"
      >
        Upload Document
      </button>
    `;
  }

  __renderScanButton() {
    if (this.small) {
      return html``;
    }

    return html`
      <button
        id="${ELEMENTS.scanButton.id}"
        class="base-button"
        @click="${this.__handlers.scanClicked}"
      >
        Scan
      </button>
    `;
  }

  __renderActionMenu() {
    return html`
      <neb-button-actions
        id="${ELEMENTS.bulkActionMenu.id}"
        align="left"
        class="action-menu"
        .forceDownMenu="${true}"
        .onClick="${this.__handlers.getBulkActions}"
      >
      </neb-button-actions>
    `;
  }

  __renderAdvancedFilter() {
    return this.small
      ? html`
          <neb-filters-documents-mobile
            id="${ELEMENTS.filtersMobile.id}"
            class="mobile-filter"
            .onApply="${this.__handlers.applyFilters}"
            .appointmentTypes="${this.appointmentTypes}"
            .advancedSearchModel="${this.advancedSearchModel}"
            .expanded="${false}"
          ></neb-filters-documents-mobile>
        `
      : html`
          <neb-filters-documents
            id="${ELEMENTS.filters.id}"
            .onApply="${this.__handlers.applyFilters}"
            .appointmentTypes="${this.appointmentTypes}"
          ></neb-filters-documents>
        `;
  }

  __renderFilter() {
    return this.displayEncounterFilter
      ? this.__renderAdvancedFilter()
      : html`
          <div class="search-container">
            <neb-search-bar
              id="${ELEMENTS.searchBar.id}"
              type="text"
              placeholderText="Filter by Name or Tags"
              .searchText="${this.searchText}"
              .onSearch="${this.__handlers.search}"
            ></neb-search-bar>
          </div>
        `;
  }

  render() {
    return html`
      ${this.__renderStyles()} ${this.__renderButtons()}
      ${this.__renderFilter()} ${this.__renderActionMenu()}

      <div class="documents-scroll-container">
        <neb-documents-scroll
          id="${ELEMENTS.scroll.id}"
          .small="${this.small}"
          .isFiltering="${this.isFiltering()}"
          .totalDocuments="${this.totalDocuments}"
          .selectedIndex="${this.selectedIndex}"
          .documents="${this.documents}"
          .noDocuments="${this.noDocuments}"
          .onRequestDocuments="${this.__handlers.requestDocuments}"
          .onSelectDocumentIndex="${this.__handlers.selectDocumentIndex}"
          .onDocumentListRendered="${this.__handlers.documentListRendered}"
          .onCheckDocument="${this.__handlers.checkDocument}"
          .checkedDocuments="${this.checkedDocuments}"
          .expanded="${this.expanded}"
          .hasVerticalPadding="${true}"
          .isOnEncounterDocuments="${this.isOnEncounterDocuments}"
        ></neb-documents-scroll>
      </div>
    `;
  }
}
customElements.define('neb-documents-list', NebDocumentsList);
