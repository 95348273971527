import {
  mapElectronicPaymentMethodsToModel,
  mapToRawModel,
} from '../../../neb-utils/electronicPaymentMethod';
import ApiClient, { Method } from '../utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'payments' });

const baseUrl = 'account';

export const createPaymentMethod = (account, body) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${baseUrl}/${account.id}/payment-methods`,
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

export const updatePaymentMethod = body => {
  const rawModel = mapToRawModel(body);

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${baseUrl}/${rawModel.merchantAccountId}/payment-methods/${
      rawModel.id
    }`,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(rawModel),
  });
};

export const deleteToken = (account, token, type = 'cnp') =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${baseUrl}/${account.id}/payment-methods/token/${token}`,

    queryParams: { type },
    method: Method.DELETE,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getPaymentMethods = async (
  account,
  patientId,
  queryParams,
  holderIds = null,
) => {
  const rawPaymentMethods = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${baseUrl}/${account.id}/payment-methods/${patientId}`,
    queryParams,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
      holderIds,
    },
  });

  return mapElectronicPaymentMethodsToModel(rawPaymentMethods);
};
