import { html, css } from 'lit';
import '../../../src/components/forms/neb-form-national-drug-code';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  form: { id: 'form' },
  mobileHeader: {
    id: 'mobile-header',
  },
};

const TEXT_TITLE = 'Edit National Drug Code Information';

class NebPopupNationalDrugCode extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      __formModel: Object,
    };
  }

  initState() {
    super.initState();
    this.title = TEXT_TITLE;
    this.__formModel = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.cancel = () => this.onClose();

    this.handlers.saveNdc = e => this.onClose(e);
  }

  modelChanged() {
    this.__formModel = {
      ndc: this.model.ndc,
      nationalDrugCode: this.model.nationalDrugCode,
      nationalDrugCodeDosage: this.model.nationalDrugCodeDosage,
      nationalDrugCodeNumberCategory: this.model.nationalDrugCodeNumberCategory,
      nationalDrugCodeQualifier: this.model.nationalDrugCodeQualifier,
      nationalDrugCodeSequenceOrPrescription: this.model
        .nationalDrugCodeSequenceOrPrescription,
      nationalDrugCodeUnitOfMeasurement: this.model
        .nationalDrugCodeUnitOfMeasurement,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          position: fixed;
          width: 600px;
        }

        :host([layout='small']) {
          width: 100%;
          height: 100%;
          top: -40px;
        }

        .header {
          margin-bottom: 10px;
          font: Bold 16px/22px Open Sans;
        }

        .content {
          width: 100%;
          height: 100%;
          min-height: unset;
          margin: 0;
        }

        .popup-body {
          display: flex;
          flex-direction: column;
        }

        .info-text {
          margin-bottom: 20px;
          font: Regular 14px/19px Open Sans;
        }
      `,
    ];
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.mobileHeader.id}" class="header">${this.title}</div>
    `;
  }

  renderContent() {
    return html`
      <div class="popup-body">
        <neb-form-national-drug-code
          id="${ELEMENTS.form.id}"
          .model="${this.__formModel}"
          .onCancel="${this.handlers.cancel}"
          .onSave="${this.handlers.saveNdc}"
        ></neb-form-national-drug-code>
      </div>
    `;
  }
}

customElements.define('neb-popup-national-drug-code', NebPopupNationalDrugCode);
