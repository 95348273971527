import '../../neb-lit-components/src/components/neb-tooltip';
import '../../neb-lit-components/src/components/inputs/neb-textfield';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  textInputs: { selector: 'neb-textfield' },
};

class NebPopupClaimTextInput extends NebPopupClaim {
  static get styles() {
    return [
      super.styles,
      css`
        .text-container {
          display: flex;
        }

        .text-input {
          width: 100%;
          margin: 0 ${CSS_SPACING} -5px 0;
        }

        .text-input:last-of-type {
          margin-right: 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="text-container">
        ${
          this.model.items.map(
            ({ width, name, label, maxLength, mask }) =>
              html`
                <neb-textfield
                  class="text-input"
                  style="width: ${width}"
                  helper=" "
                  .error="${this.__errors[name]}"
                  .label="${label}"
                  .mask="${mask}"
                  .maxLength="${maxLength}"
                  .name="${name}"
                  .value="${this.__state[name]}"
                  .onChange="${this.handlers.change}"
                ></neb-textfield>
              `,
          )
        }
      </div>
    `;
  }
}

customElements.define('neb-popup-claim-text-input', NebPopupClaimTextInput);
