import * as eClaimsSettingsApi from '../../packages/neb-api-client/src/electronic-claims/settings';
import ApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import { ADD_ONS } from '../utils/add-ons';
import { hasByocAddons, CLEARINGHOUSES } from '../utils/clearinghouse-settings';

export const apiClient = new ApiClient({ microservice: 'billing' });

const mapPayerPlanToRaw = (model, clearinghouseId) => ({
  ...(model.clearinghousePayerPlanId
    ? { id: model.clearinghousePayerPlanId }
    : undefined),
  ...(clearinghouseId ? { clearinghouseId } : undefined),
  payerPlanId: model.id,
  primaryIdentifier: model.primaryIdentifier,
  secondaryIdentifier: model.secondaryIdentifier,
  payerEtin: model.payerEtin,
  submissionMethodPrimary: model.submissionMethodPrimary,
  submissionMethodSecondary: model.submissionMethodSecondary,
});

const mapRawToPayerPlan = model => ({
  id: model.payerPlanId,
  active: model.active,
  alias: model.alias,
  payerName: model.payerName,
  primaryIdentifier: model.primaryIdentifier,
  secondaryIdentifier: model.secondaryIdentifier,
  clearinghousePayerPlanId: model.id,
  submissionMethodPrimary: model.submissionMethodPrimary,
  submissionMethodSecondary: model.submissionMethodSecondary,
  payerEtin: model.payerEtin,
});

export const mapClearinghouseToRaw = model => ({
  id: model.id,
  partner: model.partner,
  active: model.active,
  alias: model.alias,
  clearinghouseIdentifierId: model.clearinghouseIdentifierId,
  clearinghouseEtin: model.clearinghouseEtin,
  clearinghouseApplicationCode: model.clearinghouseApplicationCode,
  practiceIdentifierId: model.practiceIdentifierId,
  practiceEtin: model.practiceEtin,
  practiceApplicationCode: model.practiceApplicationCode,
  clearinghousePayerPlans: model.payerPlans.map(x =>
    mapPayerPlanToRaw(x, model.id),
  ),
  customExtension: model.customExtension,
  customFilenameTemplate: model.customFilenameTemplate,
  sftpActive: model.sftpActive,
  sftpHost: model.sftpHost,
  sftpPort: model.sftpPort,
  sftpUsername: model.sftpUsername,
  sftpPathClaim: model.sftpPathClaim,
  sftpPathEra: model.sftpPathEra,
  sftpPathAcknowledgement: model.sftpPathAcknowledgement,
  sftpPath277: model.sftpPath277,
  sftpPassword: model.sftpPassword,
  testMode: model.testMode,
  sftpDownloadActive: model.sftpActive && model.sftpDownloadActive,
  submitterContactOverride: model.submitterContactOverride,
  submitterContactName: model.submitterContactName || null,
  submitterContactNumber: model.submitterContactNumber || null,
  submitterContactType: model.submitterContactType || null,
  submitterContactEmail: model.submitterContactEmail || null,
});

export const mapClearinghouseRawToModel = model => ({
  id: model.id,
  partner: model.partner,
  active: model.active,
  alias: model.alias,
  clearinghouseIdentifierId: model.clearinghouseIdentifierId,
  clearinghouseEtin: model.clearinghouseEtin,
  clearinghouseApplicationCode: model.clearinghouseApplicationCode,
  practiceIdentifierId: model.practiceIdentifierId,
  practiceEtin: model.practiceEtin,
  practiceApplicationCode: model.practiceApplicationCode,
  payerPlans: model.clearinghousePayerPlans.map(item =>
    mapRawToPayerPlan(item),
  ),
  customExtension: model.customExtension,
  customFilenameTemplate: model.customFilenameTemplate,
  sftpActive: model.sftpActive,
  sftpHost: model.sftpHost,
  sftpPort: model.sftpPort,
  sftpUsername: model.sftpUsername,
  sftpPathClaim: model.sftpPathClaim,
  sftpPathEra: model.sftpPathEra,
  sftpPathAcknowledgement: model.sftpPathAcknowledgement,
  sftpPath277: model.sftpPath277 || '',
  testMode: model.testMode,
  sftpDownloadActive: model.sftpActive && model.sftpDownloadActive,
  submitterContactOverride: model.submitterContactOverride,
  submitterContactName: model.submitterContactName || '',
  submitterContactNumber: model.submitterContactNumber || '',
  submitterContactType: model.submitterContactType || '',
  submitterContactEmail: model.submitterContactEmail || '',
});

export async function add(model, optOutLoadingIndicator = false) {
  const body = mapClearinghouseToRaw(model);
  const result = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    optOutLoadingIndicator,
    body: JSON.stringify(body),
    path: '/clearinghouse',
    version: 2,
  });

  return mapClearinghouseRawToModel(result);
}

export async function update(model, optOutLoadingIndicator = false) {
  const body = mapClearinghouseToRaw(model);
  const result = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    optOutLoadingIndicator,
    body: JSON.stringify(body),
    path: `/clearinghouse/${model.id}`,
    version: 2,
  });

  return mapClearinghouseRawToModel(result);
}

export async function fetchMany(optOutLoadingIndicator = false) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: 'clearinghouse',
    version: 1,
  });

  const mappedClearinghouses = res.map(c => mapClearinghouseRawToModel(c));

  return mappedClearinghouses;
}

export async function fetchOne(id, optOutLoadingIndicator = false) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: `clearinghouse/${id}`,
    version: 2,
  });

  return mapClearinghouseRawToModel(res);
}

export async function getClearinghousesWithCHC(
  addOnsSupport = null,
  optOutLoadingIndicator = true,
) {
  const clearinghouses = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: 'clearinghouse',
    version: 1,
  });
  const chc = await eClaimsSettingsApi.fetch();

  let byocAddons = {};

  if (addOnsSupport) {
    byocAddons = {
      CT_FLEXCLEAR: addOnsSupport.includes(ADD_ONS.CT_FLEXCLEAR),
      CT_MAXCLEAR: addOnsSupport.includes(ADD_ONS.CT_MAXCLEAR),
      CT_PROCLEAR_TRIZETTO: addOnsSupport.includes(
        ADD_ONS.CT_PROCLEAR_TRIZETTO,
      ),
      CT_PROCLEAR_WAYSTAR: addOnsSupport.includes(ADD_ONS.CT_PROCLEAR_WAYSTAR),
      CT_VERIFY: addOnsSupport.includes(ADD_ONS.CT_VERIFY),
    };
  } else {
    byocAddons = await hasByocAddons();
  }

  return [
    ...clearinghouses.map(clearinghouse =>
      mapClearinghouseRawToModel(clearinghouse),
    ),
    ...(chc.id
      ? [
          {
            id: chc.id,
            partner: CLEARINGHOUSES.CHC,
            active: byocAddons.CT_MAXCLEAR || byocAddons.CT_VERIFY,
          },
        ]
      : []),
  ];
}

export async function swapClearinghouse(partner, clearinghouseId) {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `clearinghouse/${clearinghouseId}/swap`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      partner,
    }),
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });

  return res;
}

export async function fetchClearinghousePayerPlan(
  id,
  optOutLoadingIndicator = false,
) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: `clearinghouse-payer-plan/${id}`,
    version: 1,
  });
  return res;
}
