import '../neb-text';
import '../../../../../src/components/misc/neb-icon';
import '../controls/neb-button-icon';
import '../controls/neb-progress-linear';

import { css, html } from 'lit';

import { E_CLAIM_FILE_STATUS } from '../../../../../src/utils/e-claim-files';
import { toFormatDate } from '../../../../neb-input/nebFormatUtils';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { CLAIM_STATUS } from '../../../../neb-utils/claims';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  rows: { selector: '[id^=row-]' },
  s3Buttons: {
    selector: '[id^=s3-button-]',
  },
  downloadButtons: {
    selector: '[id^=download-button-]',
  },
  expandIcons: {
    selector: '[id^=icon-expand-]',
  },
  actionHistoryTables: {
    selector: '[id^=table-action-history-]',
  },
};

const ACTION_HISTORY_CONFIG = [
  {
    key: 'field',
    label: 'Field',
    flex: css`1 0 0`,
  },
  {
    key: 'value',
    label: 'Value',
    flex: css`1 0 0`,
  },
  {
    key: 'location',
    label: 'Location',
    flex: css`1 0 0`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`2 0 0`,
  },
  {
    key: 'code',
    label: 'Code',
    flex: css`.5 0 0`,
  },
  {
    key: 'createdAt',
    label: 'Created At',
    flex: css`1 0 0`,
  },
  {
    key: 'completedAt',
    label: 'Completed At',
    flex: css`1.2 0 0`,
  },
  {
    key: 'completedBy',
    label: 'Completed By',
    flex: css`1.2 0 0`,
  },
];
class NebTableClaimStatusHistoryLog extends NebTable {
  static get properties() {
    return {
      hasAddOnCtBYOC: Boolean,
      users: Array,
      expandFlags: Array,
      hasRCMClaimActionHistoryFeatureFlag: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 100%;
        }

        .icon {
          width: 16px;
          height: 16px;
          flex: 0 0 30px;
        }

        .icon-download {
          width: 22px;
          height: 22px;
          flex: 0 0 20px;
        }
        .cell-expand {
          width: 12px;
          height: 12px;
          fill: ${CSS_COLOR_GREY_1};
          display: flex;
          justify-content: center;
        }
        .cell-expanded {
          transform: rotate(-180deg);
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.users = [];
    this.expandFlags = [];
    this.hasRCMClaimActionHistoryFeatureFlag = false;

    this.onClickS3Icon = () => {};

    this.onClickDownloadIcon = () => {};

    this.onToggleExpand = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      click: e => this.onClickS3Icon(e),
      download: e => this.onClickDownloadIcon(e),
      toggleExpand: index => this.onToggleExpand(index),
    };
  }

  renderRow(item, rowIndex) {
    const showExpandedColumn =
      this.hasRCMClaimActionHistoryFeatureFlag &&
      this.model[rowIndex].claimValidationErrors.length;

    const isExpanded = this.expandFlags[rowIndex];

    return html`
      <div
        id="row-${rowIndex}"
        class="row row-data"
        row="${rowIndex}"
        @click="${
          () => (showExpandedColumn ? this.handlers.toggleExpand(rowIndex) : '')
        }"
      >
        <div
          id="content-${rowIndex}"
          class="content"
          ?
          ?clickable="${showExpandedColumn}"
        >
          ${this.__renderDataCells(item, rowIndex)}
        </div>
        ${isExpanded ? this.renderExpandedRow(rowIndex) : ''}
      </div>
    `;
  }

  renderExpandedRow(rowIndex) {
    const validateInformation = this.model[rowIndex].claimValidationErrors;
    const formattedModel = validateInformation.map(item => ({
      ...item,
      createdAt: toFormatDate(item.createdAt, 'MM/DD/YYYY'),
      completedAt: item.completedAt
        ? toFormatDate(item.completedAt, 'MM/DD/YYYY')
        : '',
      completedBy: item.completedBy
        ? this.users.find(user => user.data.id === item.completedBy).label
        : '',
    }));

    return html`
      <neb-table
        id="table-action-history-${rowIndex}"
        class="table"
        .config="${ACTION_HISTORY_CONFIG}"
        .model="${formattedModel}"
      >
      </neb-table>
    `;
  }

  __renderDownloadButton(rowIndex) {
    return this.hasAddOnCtBYOC
      ? html`
          <neb-button-icon
            id="download-button-${rowIndex}"
            icon="neb:download"
            class="icon-download"
            .onClick="${() => this.handlers.download(this.model[rowIndex])}"
          ></neb-button-icon>
        `
      : '';
  }

  // eslint-disable-next-line complexity
  __renderButtonIcons(value, rowIndex) {
    switch (value) {
      case CLAIM_STATUS.ERROR:
      case CLAIM_STATUS.VALIDATION_ERROR:
      case CLAIM_STATUS.SUBMITTING:
      case CLAIM_STATUS.TRANSMITTED:
        return this.model[rowIndex].s3KeyJson &&
          !this.model[rowIndex].description
          ? html`
              <neb-button-icon
                id="s3-button-${rowIndex}"
                icon="neb:open"
                class="icon"
                .onClick="${() => this.handlers.click(this.model[rowIndex])}"
              ></neb-button-icon>
            `
          : '';
      case E_CLAIM_FILE_STATUS.READY_TO_DOWNLOAD:
      case CLAIM_STATUS.READY_TO_DOWNLOAD:
        return this.model[rowIndex].s3KeyX12
          ? this.__renderDownloadButton(rowIndex)
          : '';
      case CLAIM_STATUS.CANCELED_VOIDED:
        return this.model[rowIndex].s3KeyX12
          ? this.__renderDownloadButton(rowIndex)
          : '';
      default:
        return '';
    }
  }

  __renderExpandIcon(rowIndex) {
    const isExpanded = this.expandFlags[rowIndex];
    const iconClass = isExpanded ? 'cell-expanded' : '';

    return html`
      <neb-button-icon
        id="icon-expand-${rowIndex}"
        class=" cell-expand ${iconClass}"
        icon="neb:chevron"
        .onClick="${() => this.handlers.toggleExpand(rowIndex)}"
      ></neb-button-icon>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    const showExpandedColumn =
      this.hasRCMClaimActionHistoryFeatureFlag &&
      this.model[rowIndex].claimValidationErrors.length;

    switch (columnConfig.key) {
      case 'status':
        return html`
          <neb-text>${value}</neb-text>${
            this.__renderButtonIcons(value, rowIndex)
          }
        `;
      case 'updatedBy':
        return this.model[rowIndex].updatedBy
          ? this.users.find(
              user => user.data.id === this.model[rowIndex].updatedBy,
            ).label
          : '-';
      case 'expand':
        return showExpandedColumn ? this.__renderExpandIcon(rowIndex) : '';
      default:
        return super.renderDataCell(value, columnConfig, rowIndex, name, error);
    }
  }
}
customElements.define(
  'neb-table-claim-status-history-log',
  NebTableClaimStatusHistoryLog,
);
