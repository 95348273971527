import {
  formatPackageTemplatesToUI,
  formatPackageTemplateToAPI,
} from './formatters/package-template';
import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const BASE_PATH = 'package-templates';

export const getPackageTemplates = async () => {
  const packageTemplates = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: BASE_PATH,
    version: 1,
    cacheKey: 'package-templates',
  });
  return formatPackageTemplatesToUI(packageTemplates);
};

export const savePackageTemplate = packageTemplate =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: BASE_PATH,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formatPackageTemplateToAPI(packageTemplate)),
    version: 1,
  });

export const updatePackageTemplate = packageTemplate =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${BASE_PATH}/${packageTemplate.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formatPackageTemplateToAPI(packageTemplate)),
    version: 1,
  });

export const deletePackageTemplate = packageTemplateId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: `${BASE_PATH}/${packageTemplateId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
  });
