import '../../inputs/neb-textfield';
import '../../../../../neb-material-design/src/components/neb-md-textfield';
import '../../../../../neb-material-design/src/components/neb-md-checkbox';
import '../../inputs/neb-select';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import {
  currency,
  currencyNegative,
  number,
} from '../../../../../neb-utils/masks';
import { NebTableRow, ELEMENTS as BASE_ELEMENTS } from '../../neb-table-row';

import {
  KEYS,
  ROW_ELEMENTS,
  buildHandlers,
  calculateOwed,
} from './neb-ledger-purchase-charges-row-util';

const UNIT_LENGTH = 11;
const ITEM_HEIGHT = 64;
export const ELEMENTS = {
  ...BASE_ELEMENTS,
  ...ROW_ELEMENTS,
  codeField: {
    id: 'field-code',
  },
  descriptionField: {
    id: 'field-description',
  },
};

class NebLedgerPurchaseChargesTableRow extends NebTableRow {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      error: Object,
      itemsMap: Object,
      __invalidAdjustmentAmount: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  initState() {
    super.initState();
    this.config = [];
    this.error = {
      [KEYS.UNIT_CHARGE]: ' ',
      [KEYS.ADJUSTMENT_AMOUNT]: ' ',
    };

    this.model = {
      code: '',
      description: '',
      units: '1',
      unitCharge: 0,
      adjustment: '',
      adjustmentAmount: '$0.00',
      owed: '$0.00',
      tax: '',
    };

    this.__invalidAdjustmentAmount = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      ...buildHandlers(this),
      changeUnits: e => {
        this.onChange(e);
      },
      remove: () => {
        this.onRemove(this.index);
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .description {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .flex {
          flex: 1 0 0;
        }

        .container {
          align-items: baseline;
          padding: ${CSS_SPACING} var(--cell-spacing) 10px;
        }
      `,
    ];
  }

  renderColumn(key, value) {
    let isDisabled;

    switch (key) {
      case KEYS.CODE:
        return html`
          <div id="${ELEMENTS.codeField.id}">${value}</div>
        `;

      case KEYS.DESCRIPTION:
        return html`
          <div id="${ELEMENTS.descriptionField.id}" class="description">
            ${value}
          </div>
        `;

      case KEYS.UNITS:
        return html`
          <neb-textfield
            id="${ELEMENTS.unitsField.id}"
            name="${this.index}.units"
            maxLength="3"
            labelText=" "
            .mask="${number}"
            .inputMode="${'numeric'}"
            .value="${value}"
            .onChange="${this.handlers.changeUnits}"
          ></neb-textfield>
        `;

      case KEYS.UNIT_CHARGE:
        return this.model.type
          ? html`
              <neb-textfield
                id="${ELEMENTS.unitChargeField.id}"
                name="${this.index}.unitCharge"
                labelText=" "
                maxLength="${UNIT_LENGTH}"
                helper="Required"
                inputMode="numeric"
                .mask="${currency}"
                .value="${value}"
                .onChange="${this.handlers.changeUnits}"
                .error="${this.error[KEYS.UNIT_CHARGE]}"
              ></neb-textfield>
            `
          : html`
              <div id="${ELEMENTS.unitChargeField.id}">${value}</div>
            `;

      case KEYS.ADJUSTMENT:
        isDisabled = this.model.unitCharge === '$0.00';
        return html`
          <neb-select
            id="${ELEMENTS.adjustmentField.id}"
            class="flex"
            name="${this.index}.adjustment"
            forceAlignMenu="right"
            .items="${this.itemsMap.adjustments}"
            .value="${value}"
            .onChange="${this.handlers.changeUnits}"
            .onRenderItem="${this.handlers.renderAdjustmentItems}"
            .itemHeight="${ITEM_HEIGHT}"
            ?disabled="${isDisabled}"
            showFullText
            wrapText
          ></neb-select>
        `;

      case KEYS.ADJUSTMENT_AMOUNT:
        const adj = this.model.adjustment;
        isDisabled = adj ? adj.data.id === '' : true;
        return html`
          <neb-textfield
            id="${ELEMENTS.adjustmentAmountField.id}"
            name="${this.index}.adjustmentAmount"
            labelText=" "
            inputMode="numeric"
            no-wrap
            maxLength="10"
            .mask="${currencyNegative}"
            .value="${value}"
            .error="${this.error[KEYS.ADJUSTMENT_AMOUNT]}"
            .helper="${isDisabled ? ' ' : 'Required'}"
            .onChange="${this.handlers.changeUnits}"
            ?disabled="${isDisabled}"
          ></neb-textfield>
        `;

      case KEYS.OWED:
        return html`
          <div id="${ELEMENTS.owedField.id}">${calculateOwed(this.model)}</div>
        `;

      case KEYS.TAX:
        return html`
          <neb-select
            id="${ELEMENTS.taxField.id}"
            class="flex"
            name="${this.index}.taxId"
            forceAlignMenu="right"
            .items="${this.itemsMap.taxRates}"
            .value="${this.model.taxId}"
            .onChange="${this.handlers.changeUnits}"
            showFullText
          ></neb-select>
        `;

      default:
        return '';
    }
  }
}

customElements.define(
  'neb-ledger-purchase-charges-table-row',
  NebLedgerPurchaseChargesTableRow,
);
