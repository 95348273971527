import '../../../../../neb-lit-components/src/components/encounter/neb-encounter-filter';
import '../../../../../neb-lit-components/src/components/encounter-diagnoses/neb-encounter-diagnoses-summary';
import '../../../../../neb-lit-components/src/components/encounter-charges/neb-encounter-charges-summary';
import '../../../../../neb-lit-components/src/components/neb-button-actions';
import '../../../../../neb-lit-components/src/components/controls/neb-button-action';

import { openPopup } from '@neb/popup';
import { html, LitElement, css } from 'lit';

import { verifyAssociatedInvoices } from '../../../../../../src/features/charting/neb-charting-encounter-header-util';
import {
  createNewCase,
  updateAssociatedCase,
} from '../../../../../../src/features/charting/neb-charting-util';
import {
  ENCOUNTER_BULK_SELECT_ONE_POPUP_TITLE,
  ENCOUNTER_BULK_SELECT_ONE_POPUP_MESSAGE,
} from '../../../../../../src/utils/user-message';
import { printEncounterSummaries } from '../../../../../neb-api-client/src/encounters-api-client';
import { NO_RESULTS_MESSAGE } from '../../../../../neb-lit-components/src/components/encounter/neb-encounter-card-list';
import { ELEMENTS as encounterSummaryIds } from '../../../../../neb-lit-components/src/components/encounter/neb-encounter-summary';
import { BUTTON_ROLE } from '../../../../../neb-lit-components/src/components/neb-button';
import { disableSignIfEncounterOnly } from '../../../../../neb-lit-components/src/utils/encounter-only-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../../neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
  CSS_BORDER_GREY_2,
} from '../../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../../neb-utils/date-util';
import { formatSectionTitle } from '../../../../../neb-utils/neb-charting-util';
import { printPdf } from '../../../../../neb-utils/neb-pdf-print-util';

const TEXT_LINKS = {
  VIEW_ENCOUNTER_HISTORY: 'View Encounter History',
  MANAGE_CHARGES_DIAGNOSES: 'Manage Charges and Diagnoses',
};

export const ELEMENTS = {
  patientEncounter: {
    id: 'patient-encounter',
  },
  encounterCardList: {
    id: 'encounter-card-list',
  },
  bulkActionMenu: {
    id: 'bulk-action-menu',
  },
  filter: {
    id: 'filter',
  },
  encounterCardTitle: {
    id: 'encounter-card-title',
  },
  encounterDetails: {
    id: 'encounter-details',
  },
  diagnosesTitle: {
    id: 'diagnoses-title',
  },
  diagnosesSummary: {
    id: 'diagnoses-summary',
  },
  chargeTitle: {
    id: 'charges-title',
  },
  chargeSummary: {
    id: 'charges-summary',
  },
  signButton: {
    id: 'button-sign',
    label: 'SIGN',
  },
  printButton: {
    id: 'button-print',
    label: 'PRINT',
  },
  editButton: {
    id: 'button-edit',
    label: 'EDIT',
  },
  deleteButton: {
    id: 'button-delete',
    label: 'DELETE',
  },
  reopenButton: {
    id: 'button-reopen',
    label: 'REOPEN',
  },
  viewEncounterHistoryLink: {
    id: 'link-view-encounter-history',
  },
  manageEncounterChargesDiagnosesLink: {
    id: 'link-manage-encounter-charge-diagnoses',
  },
  encounterSummary: {
    id: 'encounter-summary',
  },
  containerButtonsAndLinks: {
    id: 'container-buttons-and-links',
  },
  buttonVisitSummary: {
    id: 'button-visit-summary',
  },
};

class NebPatientEncounter extends LitElement {
  static get properties() {
    return {
      patientId: {
        type: String,
      },
      encountersModel: {
        type: Array,
      },
      encounterDataModels: {
        type: Object,
      },
      selectedEncounterId: {
        type: String,
      },
      layout: {
        type: String,
        reflect: true,
      },
      appointmentTypes: Array,
      cases: Array,
      providers: Array,
      __signedMetadata: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.encountersModel = [];
    this.patientId = '';
    this.layout = '';
    this.selectedEncounterId = '';
    this.appointmentTypes = [];
    this.cases = [];
    this.providers = [];

    this.__signedMetadata = this.__setSignedMetadata({
      signed: false,
      encounterOnly: false,
    });

    this.__initCallbacks();
  }

  __initHandlers() {
    this.__handlers = {
      encounterCardSelect: ({ value: model }) =>
        this.__handleEncounterCardSelect(model),
      checkItem: (encounterId, checked) => {
        this.encountersModel = [
          ...this.encountersModel.map(encounter =>
            encounter.id === encounterId
              ? { ...encounter, checked: !checked }
              : encounter,
          ),
        ];
      },
      onFilter: filter => {
        const { from, to } = filter.serviceDate;
        const serviceDate = {
          ...filter.serviceDate,
          from: from ? parseDate(from._d) : null,
          to: to ? parseDate(to._d) : null,
        };

        this.onFilter({
          ...filter,
          serviceDate,
        });
      },
      delete: () => this.onDelete(),
      edit: () => this.onEdit(),
      reopen: () => this.onReopen(),
      sectionClick: ({ target: { id } }) => this.__handleSummaryScroll(id),
      sign: () => this.onSign(),
      viewHistory: () => this.onViewHistory(this.selectedEncounterId),
      print: () => this.onPrint(),
      openEditCasePopup: async () => {
        if (this.encountersModel.every(({ checked }) => !checked)) {
          await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: ENCOUNTER_BULK_SELECT_ONE_POPUP_TITLE(),
            message: ENCOUNTER_BULK_SELECT_ONE_POPUP_MESSAGE(),
          });

          return;
        }

        const {
          encounterIds,
          caseId,
          patientAuthorizationId,
          hasMoreThanOneDistinctCases,
        } = this.getEncountersAndCaseSelected();

        const result = await openPopup(
          POPUP_RENDER_KEYS.UPDATE_ASSOCIATED_CASE,
          {
            encounterIds,
            patientId: this.patientId,
            defaultCaseId: caseId,
            patientAuthorizationId,
            enableSave: hasMoreThanOneDistinctCases,
          },
        );

        if (!result) return;

        if (result.type === 'updateCase') {
          await this.__updateCase({
            encounterIds,
            newCaseId: result.caseId,
            patientAuthorizationId: result.patientAuthorizationId,
          });
        } else if (result.type === 'addNewCase') {
          await this.__addNewCase(encounterIds);
        }
      },
      getBulkActions: () => [
        {
          id: 'selectAll',
          label: 'Select All',
          onSelect: this.__handlers.selectAll,
        },
        {
          id: 'deselectAll',
          label: 'Deselect All',
          onSelect: this.__handlers.deselectAll,
        },
        {
          id: 'openEditCasePopup',
          label: 'Update Case',
          onSelect: this.__handlers.openEditCasePopup,
        },
        {
          id: 'printSelectedEncounters',
          label: 'Print Selected Encounters',
          onSelect: this.__handlers.bulkPrintEncounters,
        },
      ],
      selectAll: () => {
        this.encountersModel = [
          ...this.encountersModel.map(encounter => ({
            ...encounter,
            checked: true,
          })),
        ];
      },
      deselectAll: () => {
        this.encountersModel = [
          ...this.encountersModel.map(encounter => ({
            ...encounter,
            checked: false,
          })),
        ];
      },
      bulkPrintEncounters: () => this.__bulkPrint(),
      openVisitSummary: () => this.onOpenVisitSummary(),
      openManageEncounter: async () => {
        const result = await openOverlay(OVERLAY_KEYS.MANAGE_ENCOUNTER, {
          patientId: this.patientId,
          encounterIds: [this.selectedEncounterId],
        });

        if (result) {
          this.onEncounterSelect(this.selectedEncounterId, true);
        }
      },
    };
  }

  __initCallbacks() {
    this.onFilter = () => {};

    this.onEncounterSelect = () => {};

    this.onEdit = () => {};

    this.onSign = () => {};

    this.onReopen = () => {};

    this.onViewHistory = () => {};

    this.onPrint = () => {};

    this.onDelete = () => {};

    this.onOpenVisitSummary = () => {};

    this.onUpdateEncounterCaseAuth = () => {};
  }

  __handleAfterUpdateCase(encounterIds, caseId, patientAuthorizationId = null) {
    if (!encounterIds || !encounterIds.length) return;

    this.onUpdateEncounterCaseAuth({
      encounterIds,
      caseId,
      patientAuthorizationId,
    });

    if (encounterIds.includes(this.selectedEncounterId)) {
      this.onEncounterSelect(this.selectedEncounterId, true);
    }
  }

  async __updateCase({
    encounterIds,
    newCaseId,
    patientAuthorizationId: newPatientAuthorizationId,
  }) {
    const updateResult = await updateAssociatedCase({
      encounterIds,
      patientId: this.patientId,
      newCaseId,
      newPatientAuthorizationId,
    });

    if (updateResult) {
      this.__handleAfterUpdateCase(
        encounterIds,
        newCaseId,
        newPatientAuthorizationId,
      );
    }
  }

  async __addNewCase(encounterIds) {
    const warningMessageResult = await verifyAssociatedInvoices(encounterIds);
    if (!warningMessageResult) return;

    const newCase = await createNewCase(this.patientId);
    if (!newCase) return;

    const caseUpdate = await updateAssociatedCase({
      encounterIds,
      patientId: this.patientId,
      newCaseId: newCase.id,
      newPatientAuthorizationId: null,
    });

    if (caseUpdate) this.__handleAfterUpdateCase(encounterIds, newCase.id);
  }

  getEncountersAndCaseSelected() {
    const encounters = this.encountersModel.filter(({ checked }) => checked);
    const encounterIds = encounters.map(({ id }) => id);
    const distinctCases = [...new Set(encounters.map(({ caseId }) => caseId))];
    const distinctAuths = [
      ...new Set(
        encounters.map(({ patientAuthorizationId }) => patientAuthorizationId),
      ),
    ];
    const hasMoreThanOneDistinctCases = distinctCases.length > 1;

    const caseId = hasMoreThanOneDistinctCases ? null : encounters[0].caseId;
    const patientAuthorizationId =
      distinctAuths.length > 1 || !distinctAuths[0] ? '' : distinctAuths[0];
    return {
      encounterIds,
      caseId,
      patientAuthorizationId,
      hasMoreThanOneDistinctCases,
    };
  }

  firstUpdated() {
    this.__elements = {
      encounters: this.shadowRoot.getElementById(ELEMENTS.encounterCardList.id),
    };
  }

  updated(changed) {
    if (changed.has('encountersModel')) {
      this.__handleEncountersModelChanged();
    }
  }

  __bulkPrint() {
    const selectedEncountersIds = this.encountersModel
      .filter(({ checked }) => checked)
      .map(encounter => encounter.id);

    if (selectedEncountersIds.length <= 0) {
      openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: ENCOUNTER_BULK_SELECT_ONE_POPUP_TITLE(),
        message: ENCOUNTER_BULK_SELECT_ONE_POPUP_MESSAGE(),
      });

      return;
    }

    printPdf(
      printEncounterSummaries({
        patientId: this.patientId,
        encounterIds: selectedEncountersIds,
      }),
    );
  }

  __handleEncountersModelChanged() {
    const selectedEncounter = this.encountersModel.find(
      encounter => encounter.id === this.selectedEncounterId,
    );

    this.__setSignedMetadata(selectedEncounter);
  }

  __setSignedMetadata(encounter) {
    if (encounter) {
      this.__signedMetadata = {
        signed: encounter.signed,
        encounterOnly: encounter.encounterOnly,
      };
    }
  }

  __handleEncounterCardSelect(encounterModel) {
    this.__setSignedMetadata(encounterModel);
    const { id } = encounterModel;
    this.onEncounterSelect(id);
    this.selectedEncounterId = id;
  }

  showNoResults() {
    this.__elements.encounters.updateMessage(NO_RESULTS_MESSAGE);
  }

  __handleSummaryScroll(id) {
    const {
      assessmentInfo: { id: assessmentInfo },
      planInfo: { id: planInfo },
    } = encounterSummaryIds;

    if (this.layout !== 'small') {
      this.shadowRoot
        .getElementById(ELEMENTS.encounterSummary.id)
        .scrollToSection(id.includes('diagnoses') ? assessmentInfo : planInfo);
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          height: 100%;
          width: 100%;
        }

        .flex-column {
          display: flex;
          flex-direction: column;
        }

        .flex-row {
          display: flex;
          flex-direction: row;
        }

        .flex-1 {
          flex: 1 0 0;
        }

        .flex-2 {
          flex: 2 0 0;
        }

        .flex-9 {
          flex: 9 0 0;
        }

        .search-container {
          padding: 10px ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
        }

        .title-text {
          font-family: ${CSS_FONT_FAMILY};
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: bold;
          padding-bottom: 15px;
          padding-left: ${CSS_SPACING};
        }

        .action-menu {
          padding-left: ${CSS_SPACING};
        }

        .border {
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
        }

        .column-1 {
          min-width: 265px;
          padding: ${CSS_SPACING} 0 0 ${CSS_SPACING};
        }

        .column-2 {
          min-width: 125px;
          flex-basis: 50%;
        }

        .column-3 {
          width: 1px;
          flex-basis: 40%;
          overflow: hidden;
        }

        .encounter-card-container {
          overflow-y: auto;
          overflow-x: hidden;
          margin: 0 0 ${CSS_SPACING} 0;
        }

        :host([layout='small']) .encounter-card-container {
          margin: 0;
        }

        .encounter-data-container {
          padding: ${CSS_SPACING} 14px;
          height: 100%;
        }

        .encounter-summary-container {
          margin-right: 10px;
        }

        .encounter-summary-content {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
        }

        .encounter-summary {
          padding: 5px 10px;
          overflow: auto;
        }

        .overflow-summary {
          height: inherit;
          hyphens: auto;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          overflow: auto;
        }

        .section-container {
          overflow: hidden;
          height: calc(100% - 40px);
        }

        .diagnosis-container {
          margin-bottom: 10px;
        }

        .section-header {
          padding-top: 10px;
          padding-left: 10px;
        }

        .section-content {
          min-height: 0;
        }

        :host([layout='small']) .search-container {
          padding-right: ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        :host([layout='small']) .encounter-card-container {
          border-radius: 0;
        }

        .container-buttons-and-links {
          padding: 5px 0 20px 5px;
        }

        .container-links {
          display: flex;
          flex-direction: column;
          padding-top: ${CSS_SPACING};
        }

        .button {
          padding: 4px;
        }

        :host([layout='small']) .encounter-data-container {
          flex-direction: column-reverse;
          padding: 0;
          max-width: 100%;
        }

        :host([layout='small']) .column-3 {
          width: 100%;
          overflow: visible;
          flex-basis: auto;
        }

        :host([layout='small']) .section-content {
          max-width: 100%;
        }

        :host([layout='small']) .section-container {
          overflow: visible;
          height: auto;
          margin: ${CSS_SPACING} 0 0 0;
        }

        :host([layout='small']) .border {
          border: none;
        }

        :host([layout='small']) .column-2 {
          display: block;
          overflow: visible;
          margin-top: ${CSS_SPACING};
        }

        :host([layout='small']) .encounter-summary-content {
          overflow: visible;
          margin-top: 10px;
        }

        :host([layout='small']) .flex-1 {
          flex: auto;
        }

        :host([layout='medium']) .encounter-data-container {
          flex-direction: column-reverse;
          width: 1px;
        }

        :host([layout='medium']) .column-3 {
          width: auto;
          flex: 1 0 auto;
        }

        :host([layout='medium']) .column-2 {
          width: auto;
          flex: 1 1 auto;
        }

        :host([layout='medium']) .encounter-summary-container {
          margin-top: 10px;
          margin-right: 0;
          display: block;
          overflow: visible;
        }

        :host([layout='large']) .encounter-summary-container {
          min-width: 372px;
        }

        :host([layout='medium']) .section-content {
          max-width: 100%;
        }

        :host([layout='medium']) .section-container {
          flex: 1 1 auto;
          height: auto;
          overflow: visible;
        }

        :host([layout='medium']) .encounter-summary-content {
          overflow: visible;
        }

        :host([layout='medium']) .encounter-summary {
          flex: 1 0 auto;
        }

        :host([layout='medium']) .container {
          flex-basis: auto;
        }

        .menu {
          display: flex;
          flex-direction: column;
          position: sticky;
          background-color: white;
          top: 0px;
          z-index: 1;
          padding-top: 10px;
        }
      `,
    ];
  }

  __renderContent() {
    return html`
      <div
        id="${ELEMENTS.patientEncounter.id}"
        class="flex-row flex-1 container"
      >
        ${this.__renderEncounterSection()}
        ${this.__renderEncounterDataSection()}
      </div>
    `;
  }

  __renderEncounterSection() {
    if (this.layout === 'small' && this.selectedEncounterId) {
      return '';
    }

    return html`
      <div class="flex-column flex-1 column-1">
        <div class="flex-9 border encounter-card-container">
          <div class="search-container">
            <neb-encounter-filter
              id="${ELEMENTS.filter.id}"
              .appointmentTypeItems="${this.appointmentTypes}"
              .caseItems="${this.cases}"
              .providerItems="${this.providers}"
              .onApply="${this.__handlers.onFilter}"
            ></neb-encounter-filter>
          </div>
          <div class="menu">
            ${this.__renderEncounterTitle()} ${this.__renderActionMenu()}
          </div>
          <neb-encounter-card-list
            id="${ELEMENTS.encounterCardList.id}"
            .layout="${this.layout}"
            .models="${this.encountersModel}"
            .selectedEncounterId="${this.selectedEncounterId}"
            .onItemClicked="${this.__handlers.encounterCardSelect}"
            .onItemCheck="${this.__handlers.checkItem}"
            ?showArrowIcon="${this.layout === 'small'}"
            ?showCheckbox="${true}"
          ></neb-encounter-card-list>
        </div>
      </div>
    `;
  }

  __renderEncounterTitle() {
    return this.layout !== 'small'
      ? html`
          <span id="${ELEMENTS.encounterCardTitle.id}" class="title-text"
            >Encounters
          </span>
        `
      : '';
  }

  __renderEncounterDataSection() {
    if (this.layout === 'small' && !this.selectedEncounterId) return '';

    return html`
      <div
        id="${ELEMENTS.encounterDetails.id}"
        class="flex-row flex-2 encounter-data-container"
      >
        ${this.__renderEncounterSummarySection()}
        <div class="flex-column flex-1 column-3">
          ${this.__renderDiagnosisSection()}${this.__renderChargesSection()}
        </div>
      </div>
    `;
  }

  __renderEncounterSummarySection() {
    return html`
      <div
        class="flex-column flex-1 border encounter-summary-container column-2 overflow-summary"
      >
        <span class="title-text section-header"> Encounter Summary </span>
        <div class="encounter-summary-content">
          ${
            this.encountersModel.length > 0
              ? html`
                  <div
                    id="${ELEMENTS.containerButtonsAndLinks.id}"
                    class="container-buttons-and-links"
                  >
                    ${this.__renderButtons()}
                  </div>
                `
              : ''
          }

          <neb-encounter-summary
            id="${ELEMENTS.encounterSummary.id}"
            class="encounter-summary"
            .layout="${this.layout}"
            .model="${this.encounterDataModels.notes}"
          >
          </neb-encounter-summary>
        </div>
      </div>
    `;
  }

  __renderDiagnosisSection() {
    const { diagnoses } = this.encounterDataModels;
    const diagnosesTitleText = formatSectionTitle('Diagnoses', diagnoses);

    return html`
      <div
        class="border section-container diagnosis-container flex-column flex-1"
      >
        <span
          id="${ELEMENTS.diagnosesTitle.id}"
          class="title-text section-header"
          @click="${this.__handlers.sectionClick}"
          >${diagnosesTitleText}</span
        >
        ${
          this.encountersModel.length > 0
            ? html`
                <neb-encounter-diagnoses-summary
                  id="${ELEMENTS.diagnosesSummary.id}"
                  class="section-content"
                  .model="${diagnoses}"
                  ?small="${this.layout !== 'large'}"
                  @click="${this.__handlers.sectionClick}"
                >
                </neb-encounter-diagnoses-summary>
              `
            : ''
        }
      </div>
    `;
  }

  __renderChargesSection() {
    const { charges } = this.encounterDataModels;
    const chargesTitleText = formatSectionTitle('Charges', charges);

    return html`
      <div class="border section-container flex-column flex-1">
        <span
          id="${ELEMENTS.chargeTitle.id}"
          class="title-text section-header"
          @click="${this.__handlers.sectionClick}"
          >${chargesTitleText}</span
        >
        ${
          this.encountersModel.length > 0
            ? html`
                <neb-encounter-charges-summary
                  id="${ELEMENTS.chargeSummary.id}"
                  class="section-content"
                  .savedCharges="${charges}"
                  ?small="${this.layout !== 'large'}"
                  @click="${this.__handlers.sectionClick}"
                ></neb-encounter-charges-summary>
              `
            : ''
        }
      </div>
    `;
  }

  __renderActionMenu() {
    return html`
      <neb-button-actions
        id="${ELEMENTS.bulkActionMenu.id}"
        align="left"
        class="action-menu"
        .forceDownMenu="${true}"
        .onClick="${this.__handlers.getBulkActions}"
      >
      </neb-button-actions>
    `;
  }

  __renderSummaryButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.buttonVisitSummary.id}"
        label="Open Visit Summary"
        showUnderline
        leadingIcon=""
        .onClick="${this.__handlers.openVisitSummary}"
      ></neb-button-action>
    `;
  }

  __renderButtons() {
    const signed = this.__signedMetadata && this.__signedMetadata.signed;
    return signed
      ? this.__renderSignedButtons()
      : this.__renderUnsignedButtons();
  }

  __renderPrintButton() {
    return html`
      <neb-button
        id="${ELEMENTS.printButton.id}"
        class="button button-print"
        .role="${BUTTON_ROLE.OUTLINE}"
        .label="${ELEMENTS.printButton.label}"
        .onClick="${this.__handlers.print}"
      ></neb-button>
    `;
  }

  __renderUnsignedButtons() {
    return html`
      <neb-button
        id="${ELEMENTS.signButton.id}"
        class="button"
        .role="${BUTTON_ROLE.CONFIRM}"
        .label="${ELEMENTS.signButton.label}"
        ?disabled="${disableSignIfEncounterOnly(this.__signedMetadata)}"
        .onClick="${this.__handlers.sign}"
      ></neb-button>
      ${this.__renderPrintButton()}
      ${
        this.layout !== 'small'
          ? html`
              <neb-button
                id="${ELEMENTS.editButton.id}"
                class="button"
                .role="${BUTTON_ROLE.OUTLINE}"
                .label="${ELEMENTS.editButton.label}"
                @click="${this.__handlers.edit}"
              ></neb-button>
            `
          : ''
      }
      <neb-button
        id="${ELEMENTS.deleteButton.id}"
        class="button"
        .role="${BUTTON_ROLE.OUTLINE}"
        .label="${ELEMENTS.deleteButton.label}"
        @click="${this.__handlers.delete}"
      ></neb-button>
      ${this.__renderLinks()}
    `;
  }

  __renderSignedButtons() {
    return html`
      ${this.__renderPrintButton()}
      <neb-button
        id="${ELEMENTS.reopenButton.id}"
        class="button"
        .role="${BUTTON_ROLE.OUTLINE}"
        .label="${ELEMENTS.reopenButton.label}"
        @click="${this.__handlers.reopen}"
      ></neb-button>
      ${this.__renderLinks()}
    `;
  }

  __renderLinks() {
    return html`
      <div class="container-links">
        ${this.__renderViewEncounterHistoryLink()}
        ${this.__renderSummaryButton()}
        ${this.__renderManageChargesAndDiagnosesLink()}
      </div>
    `;
  }

  __renderViewEncounterHistoryLink() {
    return this.encounterDataModels.history &&
      this.encounterDataModels.history.length > 0
      ? html`
          <neb-button-action
            id="${ELEMENTS.viewEncounterHistoryLink.id}"
            label="${TEXT_LINKS.VIEW_ENCOUNTER_HISTORY}"
            showUnderline
            leadingIcon=""
            .onClick="${this.__handlers.viewHistory}"
          ></neb-button-action>
        `
      : '';
  }

  __renderManageChargesAndDiagnosesLink() {
    return html`
      <neb-button-action
        id="${ELEMENTS.manageEncounterChargesDiagnosesLink.id}"
        label="${TEXT_LINKS.MANAGE_CHARGES_DIAGNOSES}"
        showUnderline
        leadingIcon=""
        .onClick="${this.__handlers.openManageEncounter}"
      ></neb-button-action>
    `;
  }

  render() {
    return html`
      ${this.__renderContent()}
    `;
  }
}

customElements.define('neb-patient-encounter', NebPatientEncounter);
