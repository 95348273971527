import '../neb-radio-button';
import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';

import { baseStyles } from '../../../../neb-styles/neb-styles';

const YEARLY = 'YEARLY';
const DAY = 'DAY';
const NTH = 'NTH';
const LAST = 'LAST';
const nthDay = ['first', 'second', 'third', 'fourth'];
const FIRST_DAY_OF_FOURTH_WEEK = 22;
const FIRST_DAY_OF_FIFTH_WEEK = 29;
export const ELEMENTS = {
  radioDay: {
    id: 'radio-day',
  },
  radioNthDay: {
    id: 'radio-nth-day',
  },
  radioLastDay: {
    id: 'radio-last-day',
  },
};

class NebRecurringMonthYearOptions extends LitElement {
  static get properties() {
    return {
      __selectedOption: {
        type: String,
      },
      date: {
        type: Date,
      },
      selectedDateOption: {
        type: Object,
      },
      optionType: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandler();
  }

  __initState() {
    this.__dateOptions = {
      dayText: '',
      nthDayText: '',
      monthText: '',
      dayNum: null,
      monthNum: null,
      lastDay: false,
    };

    this.__labelDayOptions = {
      YEARLY: {
        dayOrMonth: '',
        ofMonth: '',
      },
      MONTHLY: {
        dayOrMonth: '',
        ofMonth: '',
      },
    };

    this.__selectedOption = null;
    this.optionType = null;
    this.selectedDateOption = {};

    this.onChange = () => {};
  }

  __initHandler() {
    this.__handlers = {
      radioSelect: value => {
        const month =
          this.optionType === YEARLY ? this.__dateOptions.monthNum : null;
        this.__dayConfig = {
          DAY: {
            monthDay: this.__dateOptions.dayNum,
            month,
          },
          NTH: {
            setPos: [nthDay.indexOf(this.__dateOptions.nthDayText) + 1],
            weekDay: moment(this.date)
              .format('dd')
              .toUpperCase(),
            month,
          },
          LAST: {
            setPos: [-1],
            weekDay: moment(this.date)
              .format('dd')
              .toUpperCase(),
            month,
          },
        };

        this.onChange(this.__dayConfig[value]);
        this.__selectedOption = value;
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          height: 100%;
        }

        .radio-button {
          margin-left: -10px;
        }
      `,
    ];
  }

  __assignDateOptions() {
    const date = moment(this.date);
    const dayNumber = date.date();
    const dateOfStartOfTheMonth = date.clone().startOf('month');
    const week = date.diff(dateOfStartOfTheMonth, 'week') + 1;

    let dateOptionDayNum = dayNumber;
    let dateOptionNthDay;
    let dateOptionLastDay = false;

    if (dayNumber >= FIRST_DAY_OF_FOURTH_WEEK) {
      if (dayNumber < FIRST_DAY_OF_FIFTH_WEEK) dateOptionNthDay = nthDay[3];
      else dateOptionDayNum = '';
      dateOptionLastDay = true;
    } else {
      dateOptionNthDay = nthDay[week - 1];
    }

    this.__dateOptions = {
      dayText: moment(this.date).format('dddd'),
      nthDayText: dateOptionNthDay,
      monthText: moment(this.date).format('MMMM'),
      dayNum: dateOptionDayNum,
      monthNum: moment(this.date).month() + 1,
      lastDay: dateOptionLastDay,
    };
  }

  __generateDateOptionLabels() {
    this.__labelDayOptions[this.optionType] = {
      dayOrMonth:
        this.optionType === YEARLY ? this.__dateOptions.monthText : 'day',
      ofMonth:
        this.optionType === YEARLY ? ` of ${this.__dateOptions.monthText}` : '',
    };
  }

  updated(changed) {
    if (changed.has('selectedDateOption')) {
      if (this.selectedDateOption.setPos) {
        const [setPos] = this.selectedDateOption.setPos;
        if (setPos > -1) this.__selectedOption = NTH;
        else this.__selectedOption = LAST;
      } else if (changed.has('__selectedOption')) {
        this.__handlers.radioSelect(this.__dateOptions.dayNum ? DAY : LAST);
      }
    } else if (changed.has('date')) {
      this.__handlers.radioSelect(this.__dateOptions.dayNum ? DAY : LAST);
    }

    super.updated(changed);
  }

  render() {
    this.__assignDateOptions();

    this.__generateDateOptionLabels();

    return html`
      <div class="options">
        ${
          this.__dateOptions.dayNum
            ? html`
                <neb-radio-button
                  id="${ELEMENTS.radioDay.id}"
                  class="radio-button"
                  label="On ${
                    this.__labelDayOptions[this.optionType].dayOrMonth
                  } ${this.__dateOptions.dayNum}"
                  .value="${DAY}"
                  .onSelect="${this.__handlers.radioSelect}"
                  .checked="${this.__selectedOption === DAY}"
                ></neb-radio-button>
              `
            : ''
        }
        ${
          this.__dateOptions.nthDayText
            ? html`
                <neb-radio-button
                  id="${ELEMENTS.radioNthDay.id}"
                  class="radio-button"
                  label="On the ${this.__dateOptions.nthDayText} ${
                    this.__dateOptions.dayText
                  }${this.__labelDayOptions[this.optionType].ofMonth}"
                  .value="${NTH}"
                  .onSelect="${this.__handlers.radioSelect}"
                  .checked="${this.__selectedOption === NTH}"
                ></neb-radio-button>
              `
            : ''
        }
        ${
          this.__dateOptions.lastDay
            ? html`
                <neb-radio-button
                  id="${ELEMENTS.radioLastDay.id}"
                  class="radio-button"
                  label="On the last ${this.__dateOptions.dayText}${
                    this.__labelDayOptions[this.optionType].ofMonth
                  }"
                  .value="${LAST}"
                  .onSelect="${this.__handlers.radioSelect}"
                  .checked="${this.__selectedOption === LAST}"
                ></neb-radio-button>
              `
            : ''
        }
      </div>
    `;
  }
}

customElements.define(
  'neb-recurring-month-year-options',
  NebRecurringMonthYearOptions,
);
