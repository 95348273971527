import { LitElement, html, css } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
} from '../../../packages/neb-styles/neb-variables';
import {
  calcIntervalArray,
  format24Time,
  formatLocaleTime,
} from '../../../packages/neb-utils/neb-cal-util';
import { CSS_FONT_SIZE_CAPTION } from '../../styles';

import { MAXIMUM_END, MINIMUM_START } from './neb-base-calendar-view';

export const ELEMENTS = {
  foldChevrons: { selector: '.fold-chevron' },
  timeSlots: { selector: '.time-slot' },
  lastSlots: { selector: '.last' },
};

function formatNumber(n) {
  if (n >= 1000) {
    return `${(n / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return n;
}

function foldEqualsInterval(fold, interval) {
  const hour = Math.floor(fold.roundedStart / 60);
  const min = fold.roundedStart % 60;

  return hour === interval.hour && min === interval.min;
}

class NebCalendarTimeColumn extends LitElement {
  static get properties() {
    return {
      __intervals: Array,

      folds: Array,
      cornerBlockHeight: Number,
      end: Number,
      interval: Number,
      start: Number,
      small: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return css`
      :host {
        position: relative;
        display: block;
        width: 100px;
      }

      .time-slot-mobile,
      .time-slot {
        height: 70px;
      }

      .time-slot,
      .last {
        text-align: end;
        font-size: ${CSS_FONT_SIZE_CAPTION};
        margin-right: 5px;
        display: flex;
        justify-content: flex-end;
      }

      :host([small]) .time-slot,
      :host([small]) .last {
        text-align: center;
        font-size: ${CSS_FONT_SIZE_CAPTION};
        display: block;
      }

      .corner-block {
        background-color: ${CSS_COLOR_WHITE};
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 4;
      }

      .chevron-icon {
        position: relative;
        width: 20px;
        height: 20px;
        z-index: 1;
        fill: ${CSS_COLOR_HIGHLIGHT};
        transition: 0.2s;
      }

      .fold-chevron {
        position: absolute;
        align-items: center;
        user-select: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        left: 15px;
        gap: 5px;
      }

      :host([small]) .fold-chevron {
        left: 1px;
      }

      .fold-chevron-right {
        transform: rotate(-90deg);
        transition: 200ms;
      }

      .fold-chevron:hover {
        opacity: 0.65;
      }

      .fold-count {
        color: ${CSS_COLOR_HIGHLIGHT};
        font-weight: bold;
        text-align: center;
        opacity: 0;
      }

      .fold-count-on {
        opacity: 1;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__intervals = [];

    this.onClickFold = () => {};

    this.folds = [];
    this.cornerBlockHeight = 0;
    this.interval = 1;
    this.start = MINIMUM_START;
    this.end = MAXIMUM_END;
  }

  __initHandlers() {
    this.__handlers = {
      clickFold: fold => () => {
        this.onClickFold(this.folds.findIndex(f => f.start === fold.start));
      },
    };
  }

  update(changedProps) {
    if (
      changedProps.has('interval') ||
      changedProps.has('start') ||
      changedProps.has('end')
    ) {
      this.__intervals = calcIntervalArray(this.interval, this.start, this.end);

      const lastHour = Math.floor(this.end / 60);
      const lastMin = this.end % 60;

      if (lastHour !== 24 || this.start === MAXIMUM_END) {
        this.__intervals.push({
          hour: lastHour,
          min: lastMin,
          time24: format24Time(lastHour, lastMin),
          time: formatLocaleTime(lastHour, lastMin),
        });
      }
    }

    super.update(changedProps);
  }

  __renderIntervals() {
    return this.__intervals.map((interval, index) => {
      const fold = this.folds.find(f => {
        if (f.start === 0 && index === 0) {
          return true;
        }

        return foldEqualsInterval(f, interval);
      });

      return html`
        <div
          class="${
            index === this.__intervals.length - 1 && fold && fold.isFolded
              ? 'last'
              : 'time-slot'
          }"
        >
          ${fold ? this.__renderFoldChevron(fold) : ''} ${interval.time}
        </div>
      `;
    });
  }

  __renderFoldChevron(fold) {
    const chevronClasses = {
      'chevron-icon': true,
      'fold-chevron-right': fold.isFolded,
    };

    const foldClasses = {
      'fold-count': true,
      'fold-count-on': fold.isFolded && fold.count,
    };

    return html`
      <div class="fold-chevron" @click="${this.__handlers.clickFold(fold)}">
        <neb-icon
          slot="content"
          class="${classMap(chevronClasses)}"
          icon="neb:chevron"
        ></neb-icon>
        <div class="${classMap(foldClasses)}">
          (${formatNumber(fold.count)})
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div
        class="corner-block"
        style="height: ${this.cornerBlockHeight}px"
      ></div>
      ${this.__renderIntervals()}
    `;
  }
}

customElements.define('neb-calendar-time-column', NebCalendarTimeColumn);
