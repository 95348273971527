import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { html, css } from 'lit';

import { getReportLogs } from '../../../../../packages/neb-api-client/src/electronic-claims/reports';
import NebOverlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  parseDate,
  FORMATS,
} from '../../../../../packages/neb-utils/date-util';
import { CSS_SPACING } from '../../../../styles';
import '../../../../../packages/neb-lit-components/src/components/tables/neb-table';

export const ELEMENTS = {
  header: { id: 'header', title: 'Report Logs' },
  table: { id: 'report-logs-table' },
};

const TABLE_CONFIG = [
  {
    key: 'createdAt',
    label: 'Date',
    flex: css`2 0 0`,
    formatter: value =>
      value ? parseDate(value).format(`MM/DD/YYYY ${FORMATS.timeAmPm}`) : '',
  },
  {
    key: 'type',
    label: 'Log Type',
    flex: css`1 0 0`,
    formatter: v => v,
  },
  {
    key: 'description',
    label: 'Log Description',
    flex: css`3 0 0`,
    formatter: v => v,
  },
  {
    key: 'additionalData',
    label: 'Additional Data',
    flex: css`4 0 0`,
    formatter: v => v,
  },
];
class NebOverlayReportLogs extends NebOverlay {
  static get properties() {
    return {
      model: Object,
      __reportLogs: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header-container {
          padding: ${CSS_SPACING};
        }

        .content {
          width: 80rem;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.model = { reportId: null };
  }

  initHandlers() {
    super.initHandlers();
  }

  async updated(changedProps) {
    if (changedProps.has('model') && this.model) {
      this.__reportLogs = (await getReportLogs(this.model.reportId)).data;
    }
  }

  __renderHeader() {
    return html`
    <div class="header-container">
      <neb-popup-header
      id="${ELEMENTS.header.id}"
      showCancelButton
      title="${ELEMENTS.header.title}"
      .onCancel="${this.handlers.dismiss}"
      >
  </div>`;
  }

  __renderTable() {
    return html`
      <div class="logs-container">
        <neb-table
          id="${ELEMENTS.table.id}"
          .model="${this.__reportLogs}"
          .config="${TABLE_CONFIG}"
        ></neb-table>
      </div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderTable()}
    `;
  }
}

customElements.define('neb-overlay-report-logs', NebOverlayReportLogs);
