import { css, html } from 'lit';

import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';

import Overlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { CSS_SPACING } from '../../../../styles';

export const ELEMENTS = {
  header: { id: 'header' },
  buildNewViewButton: { id: 'build-new-view-button' },
};

class NebOverlayManageViews extends Overlay {
  static get properties() {
    return {};
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 500px;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  __initState() {
    super.initState();
  }

  __initHandlers() {
    super.initHandlers();
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Manage Views"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}
      <neb-button-action
        id="${ELEMENTS.buildNewViewButton.id}"
        label="Build New View"
      ></neb-button-action>
    `;
  }
}

customElements.define('neb-overlay-manage-views', NebOverlayManageViews);
