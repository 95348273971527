import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const baseUrl = 'patient-guarantor';

const calculateCacheKey = (patientId, queryParams) => {
  const cacheKey = `${patientId}-patient-guarantors`;

  const queryParamsString = Object.entries(queryParams)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .join();

  return queryParamsString ? `${cacheKey},${queryParamsString}` : cacheKey;
};

export const getPatientGuarantors = (
  patientId,
  queryParams = {},
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/guarantors`,
    headers: {
      'Content-Type': 'application/json',
    },
    queryParams,
    version: 1,
    cacheKey: calculateCacheKey(patientId, queryParams),
    optOutLoadingIndicator,
  });

export const createPatientGuarantor = guarantorBody =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(guarantorBody),
  });

export const updatePatientGuarantor = guarantorBody =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${baseUrl}/${guarantorBody.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(guarantorBody),
  });
