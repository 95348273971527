import * as patientApiClient from '../../packages/neb-api-client/src/patient-api-client';
import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import {
  HIDE_PREFERRED_OPTS,
  objToName,
} from '../../packages/neb-utils/formatters';

export const billingApiClient = new ApiClient({ microservice: 'billing' });

export const getRunningLedgerItems = async patientId => {
  const { data } = await billingApiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/running-ledger`,
    version: 1,
  });

  const otherPatientIds = data
    .filter(item => item.patientId && item.patientId !== patientId)
    .map(item => item.patientId);

  if (otherPatientIds.length) {
    const otherPatients = await patientApiClient.fetchSome(
      otherPatientIds,
      {},
      false,
      true,
      true,
    );

    const otherPatientNames = otherPatients.reduce(
      (map, { id, name }) => map.set(id, objToName(name, HIDE_PREFERRED_OPTS)),
      new Map(),
    );

    data.forEach(item => {
      if (otherPatientNames.has(item.patientId)) {
        item.otherPatientName = otherPatientNames.get(item.patientId);
      }
    });
  }

  return data;
};
