import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_4,
  CSS_COLOR_GREY_5,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  button: {
    id: 'button',
  },
  checkMarkIcon: {
    id: 'icon-check-mark',
  },
  plusIcon: {
    id: 'icon-plus',
  },
  text: {
    id: 'text',
  },
};

const DEFAULT_ADD_ALL_ICON = 'neb:plusOnly';
export const DEFAULT_TOGGLE_ICON = 'neb:checkmark';

class NebToggleButton extends LitElement {
  static get properties() {
    return {
      buttonText: {
        type: String,
      },
      canToggle: {
        type: Boolean,
        reflect: true,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
      hideIcon: {
        type: Boolean,
        reflect: true,
      },
      isToggled: {
        type: Boolean,
        reflect: true,
      },
      layout: {
        type: String,
        reflect: true,
      },
      icon: {
        type: String,
        reflect: true,
      },
      toggledIcon: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.buttonText = 'Sample Text';
    this.canToggle = false;
    this.disabled = false;
    this.hideIcon = false;
    this.isToggled = false;
    this.layout = 'large';
    this.index = 0;

    this.icon = DEFAULT_ADD_ALL_ICON;
    this.toggledIcon = DEFAULT_TOGGLE_ICON;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: e => {
        e.stopPropagation();

        if (this.disabled) return;

        if (this.canToggle) {
          const changedState = !this.isToggled;
          this.onClick(changedState, this.index);
        } else if (!this.isToggled) {
          this.onClick(true, this.index);
        }
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline;
          width: 180px;
        }

        .button {
          display: flex;
          cursor: pointer;
          outline: none;
          padding: 0 15px;
          height: 30px;
          border: 0;
          border-radius: 15px;
          color: ${CSS_COLOR_HIGHLIGHT};
          background-color: ${CSS_COLOR_GREY_4};
          font-weight: 100;
          font-size: ${CSS_FONT_SIZE_BODY};
          align-items: center;
        }

        :host([disabled]) .button,
        :host([isToggled]:not([canToggle])) .button {
          cursor: default;
          background-color: rgba(149, 149, 149, 0.15);
          color: ${CSS_COLOR_GREY_5};
          opacity: 1;
        }

        :host([isToggled][hideIcon]) .button {
          color: ${CSS_COLOR_GREY_4};
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([layout='small']) .button {
          padding: 0;
          width: 30px;
          height: 30px;
          justify-content: center;
        }

        .label {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .icon {
          margin-right: 12px;
          width: 12px;
          height: 12px;
          stroke: ${CSS_COLOR_GREY_1};
          stroke-width: 1;
        }

        :host([layout='small']) .icon {
          margin: 0;
          width: 12px;
          height: 12px;
        }

        :host(:not([disabled])) .icon-plus {
          fill: ${CSS_COLOR_HIGHLIGHT};
          stroke-width: 0;
        }

        :host(:not([disabled])) .icon-check-mark {
          fill: ${CSS_COLOR_HIGHLIGHT};
          stroke-width: 1;
        }

        :host(:not([disabled])[canToggle]) .icon-check-mark {
          fill: ${CSS_COLOR_HIGHLIGHT};
          stroke-width: 0;
        }
      `,
    ];
  }

  __renderIcon() {
    if (this.hideIcon) {
      return '';
    }

    return this.isToggled
      ? html`
          <neb-icon
            id="${ELEMENTS.checkMarkIcon.id}"
            class="icon icon-check-mark"
            icon="${this.toggledIcon}"
          ></neb-icon>
        `
      : html`
          <neb-icon
            id="${ELEMENTS.plusIcon.id}"
            class="icon icon-plus"
            icon="${this.icon}"
          ></neb-icon>
        `;
  }

  __renderText() {
    return this.layout !== 'small'
      ? html`
          <span id="${ELEMENTS.text.id}" class="label">
            ${this.buttonText}
          </span>
        `
      : '';
  }

  __renderLabel() {
    return html`
      ${this.__renderIcon()} ${this.__renderText()}
    `;
  }

  render() {
    return html`
      <button
        id="${ELEMENTS.button.id}"
        class="button"
        @click="${this.__handlers.click}"
      >
        ${this.__renderLabel()}
      </button>
    `;
  }
}

customElements.define('neb-toggle-button', NebToggleButton);
