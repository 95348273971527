import { css, html } from 'lit';

import {
  CSS_COLOR_GREY_4,
  CSS_FONT_SIZE_CAPTION,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  COVERED_SERVICE_TYPE,
  QUANTITY_TYPES,
  TERMS,
} from '../../../../neb-utils/patientInsurance';
import { formatCode } from '../patients/ledger/charges/neb-ledger-charges-util';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
};

const CONFIG = [
  {
    key: 'serviceTypeProcedureCode',
    label: 'Service Type/ Procedure Code',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'limit',
    label: 'Limit',
    flex: css`0 0 105px`,
  },
  {
    key: 'remainingQuantity',
    label: 'Remaining',
    flex: css`0 0 105px`,
  },
  {
    key: 'copay',
    label: 'Copay',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'coinsurance',
    label: 'Coins. %',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'preAuthorization',
    label: 'Pre-Auth',
    flex: css`0 0 80px`,
    sortable: true,
  },
];

class NebTableCoveredServices extends NebTable {
  initState() {
    super.initState();

    this.config = CONFIG;
    this.sortParams = [];
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
        }

        .bold {
          font-weight: bold;
        }

        .cell-service-detail {
          align-self: flex-start;
          flex-direction: column;
          padding-bottom: 18px;
        }

        .caption-text {
          font-size: ${CSS_FONT_SIZE_CAPTION};
        }

        .row-data[expanded] {
          background-color: ${CSS_COLOR_GREY_4};
        }
      `,
    ];
  }

  __getCopayDisplay(copayData, defaultDisplay) {
    return !copayData.length ? defaultDisplay : copayData;
  }

  __getCoinsuranceDisplay(percentData, defaultDisplay) {
    if (!percentData.length) return defaultDisplay;

    return `${percentData}/${100 - percentData}`;
  }

  renderDataCell(_, columnConfig, rowIndex) {
    const row = this.model[rowIndex];

    switch (columnConfig.key) {
      case 'serviceTypeProcedureCode':
        if (row.type === COVERED_SERVICE_TYPE.ServiceType) {
          return row.serviceType.code;
        }

        return formatCode(row.charge.procedure, row.charge.modifiers);

      case 'limit':
        if (!row.quantity.length) return '';

        let quantityDisplay = '';
        let termDisplay;

        switch (row.quantityType) {
          case QUANTITY_TYPES.Units:
            if (row.quantity !== '1') quantityDisplay = ' units';
            else quantityDisplay = ' unit';
            break;
          case QUANTITY_TYPES.Visits:
            if (row.quantity !== '1') quantityDisplay = ' visits';
            else quantityDisplay = ' visit';
            break;
          case QUANTITY_TYPES.Episodes:
            if (row.quantity !== '1') quantityDisplay = ' eps.';
            else quantityDisplay = ' ep.';
            break;
          case QUANTITY_TYPES.DollarAmount:
            break;
          default:
        }

        switch (row.term) {
          case TERMS.CalendarYear:
            termDisplay = ' per CY';
            break;
          case TERMS.ServiceYear:
            termDisplay = ' per SY';
            break;
          case TERMS.Month:
            termDisplay = ' per mo.';
            break;
          case TERMS.Day:
            termDisplay = ' per day';
            break;
          default:
        }

        return `${row.quantity}${quantityDisplay}${termDisplay}`;

      case 'remainingQuantity':
        if (!row.remainingQuantity.length) return '';

        return `${row.remainingQuantity} as of ${parseDate(row.asOfDate).format(
          'MM/DD/YYYY',
        )}`;

      case 'copay':
        const inNetworkCopay = this.__getCopayDisplay(
          row.inNetworkCopayAmount,
          '- ',
        );
        const outOfNetworkCopay = this.__getCopayDisplay(
          row.outOfNetworkCopayAmount,
          '-',
        );

        if (inNetworkCopay === '- ' && outOfNetworkCopay === '-') return '';

        return `${inNetworkCopay}/ ${outOfNetworkCopay}`;

      case 'coinsurance':
        const inNetworkCoinsurance = this.__getCoinsuranceDisplay(
          row.inNetworkCoinsurancePercent,
          '- ',
        );
        const outOfNetworkCoinsurance = this.__getCoinsuranceDisplay(
          row.outOfNetworkCoinsurancePercent,
          '-',
        );

        if (inNetworkCoinsurance === '- ' && outOfNetworkCoinsurance === '-') {
          return '';
        }

        return `${inNetworkCoinsurance}; ${outOfNetworkCoinsurance}`;

      case 'preAuthorization':
        return row.preAuthorizationRequired ? 'Yes' : 'No';

      default:
        return '';
    }
  }

  renderExpandedRow(index, row) {
    let description;
    if (row.type === COVERED_SERVICE_TYPE.ServiceType) {
      description = row.serviceType.description;
    } else description = row.charge.description;

    const inNetworkCopay = this.__getCopayDisplay(
      row.inNetworkCopayAmount,
      html`
        &nbsp;
      `,
    );
    const outOfNetworkCopay = this.__getCopayDisplay(
      row.outOfNetworkCopayAmount,
      html`
        &nbsp;
      `,
    );

    const inNetworkCoinsurance = this.__getCoinsuranceDisplay(
      row.inNetworkCoinsurancePercent,
      html`
        &nbsp;
      `,
    );
    const outOfNetworkCoinsurance = this.__getCoinsuranceDisplay(
      row.outOfNetworkCoinsurancePercent,
      html`
        &nbsp;
      `,
    );

    return html`
      <div class="content">
        <div class="cell cell-service-detail" style="flex: 1 0 0">
          <div class="bold caption-text">Description</div>
          <div id="detail-description-${index}" class="caption-text">
            ${description}
          </div>
        </div>
        <div class="cell cell-service-detail" style="flex: 1 0 0">
          <div class="bold caption-text">In Network Copay</div>
          <div id="detail-in-network-copay-${index}" class="caption-text">
            ${inNetworkCopay}
          </div>
        </div>
        <div class="cell cell-service-detail" style="flex: 1 0 0">
          <div class="bold caption-text">Out of Network Copay</div>
          <div id="detail-out-of-network-copay-${index}" class="caption-text">
            ${outOfNetworkCopay}
          </div>
        </div>
        <div class="cell cell-service-detail" style="flex: 1 0 0">
          <div class="bold caption-text">In Network Coins. %</div>
          <div id="detail-in-network-coinsurance-${index}" class="caption-text">
            ${inNetworkCoinsurance}
          </div>
        </div>
        <div class="cell cell-service-detail" style="flex: 0 0 150px">
          <div class="bold caption-text">Out of Network Coins. %</div>
          <div
            id="detail-out-of-network-coinsurance-${index}"
            class="caption-text"
          >
            ${outOfNetworkCoinsurance}
          </div>
        </div>
        <div class="cell cell-expand"></div>
      </div>
    `;
  }
}

customElements.define('neb-table-covered-services', NebTableCoveredServices);
