import '../../../../neb-lit-components/src/components/neb-toggle-button';

import { LitElement, html } from 'lit';
import moment from 'moment-timezone';

import {
  CSS_SPACING,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
} from '../../../../neb-styles/neb-variables';
import { formatIcdSelectorIdCode } from '../../../../neb-utils/neb-charting-util';

export const ELEMENTS = {
  icd10: {
    id: 'icd10',
  },
  addButton: {
    id: 'add-button',
  },
  code: {
    id: 'code',
  },
  description: {
    id: 'description',
  },
  providerName: {
    id: 'provider-name',
  },
  onsetDate: {
    id: 'onset-date',
  },
  resolutionDate: {
    id: 'resolution-date',
  },
  status: {
    id: 'status',
  },
  toggleButton: {
    selector: 'neb-toggle-button',
  },
};

class NebChartingDiagnosisProblemRow extends LitElement {
  static get properties() {
    return {
      problemInfo: Object,
      isAdded: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.isAdded = false;
    this.buttonText = '';
    this.buttonToggledText = '';
    this.problemInfo = {};

    this.onButtonClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      toggle: () => this.onButtonClick(this.problemInfo),
    };
  }

  __formatDate(date) {
    return date ? moment(date).format('MM/DD/YY') : '';
  }

  __styles() {
    return html`
      <style>
        .cell {
          margin: 0 10px 0 0;
          min-width: 0;
        }

        .cell:first-child {
          margin: 0 10px 0 ${CSS_SPACING};
        }

        .cell:last-child {
          margin: 0 ${CSS_SPACING} 0 0;
        }

        .cell-button {
          flex: 1 0 180px;
        }

        .cell-code {
          flex: 1 0 70px;
        }

        .cell-description {
          flex: 6 0 138px;
          text-align: left;
        }

        .cell-provider {
          flex: 1 0 70px;
        }

        .cell-onset {
          flex: 1 0 70px;
        }

        .cell-resolution {
          flex: 1 0 75px;
        }

        .cell-status {
          flex: 1 0 70px;
          text-transform: capitalize;
        }

        .result-row {
          display: flex;
          border-bottom: 1px solid rgba(151, 151, 151, 0.3);
          padding: 11px 0;
          align-items: center;
          font-family: ${CSS_FONT_FAMILY};
          font-size: ${CSS_FONT_SIZE_BODY};
        }
      </style>
    `;
  }

  render() {
    return html`
      ${this.__styles()}

      <div
        id="${ELEMENTS.icd10.id}-${
          formatIcdSelectorIdCode(this.problemInfo.code)
        }"
        class="result-row"
      >
        <div class="cell cell-button">
          <neb-toggle-button
            id="${this.problemInfo.code}-${ELEMENTS.addButton.id}"
            .buttonText="${
              this.isAdded ? this.buttonToggledText : this.buttonText
            }"
            .isToggled="${this.isAdded}"
            .onClick="${this.__handlers.toggle}"
          ></neb-toggle-button>
        </div>
        <div id="${ELEMENTS.code.id}" class="cell cell-code">
          ${this.problemInfo.code}
        </div>
        <div id="${ELEMENTS.description.id}" class="cell cell-description">
          ${this.problemInfo.shortDescription}
        </div>
        <div id="${ELEMENTS.providerName.id}" class="cell cell-provider">
          ${this.problemInfo.providerName}
        </div>
        <div id="${ELEMENTS.onsetDate.id}" class="cell cell-onset">
          ${this.__formatDate(this.problemInfo.onsetDate)}
        </div>
        <div id="${ELEMENTS.resolutionDate.id}" class="cell cell-resolution">
          ${this.__formatDate(this.problemInfo.resolutionDate)}
        </div>
        <div id="${ELEMENTS.status.id}" class="cell cell-status">
          ${this.problemInfo.status}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-charting-diagnosis-problem-row',
  NebChartingDiagnosisProblemRow,
);
