import { ConnectedService } from './connected-service';

export class SchedulingRequirementService extends ConnectedService {
  constructor(callback) {
    super({
      providerAvailability: 'providersAvailability.item',
      appointmentTypes: 'appointmentTypes.activeTypes',
    });

    this.onStateChanged = callback;
  }

  __validateAppointmentTypes(appointmentTypes) {
    return appointmentTypes && appointmentTypes.length;
  }

  __validateWeekDayAvailabilityForProvider({ availability }) {
    return Object.values(availability).find(weekday => weekday.enabled);
  }

  __validateProviderAvailability(providerAvailability) {
    return Object.values(providerAvailability).some(provider =>
      this.__validateWeekDayAvailabilityForProvider(provider),
    );
  }

  _stateChanged({ providerAvailability, appointmentTypes }) {
    const hasAppointmentTypes = this.__validateAppointmentTypes(
      appointmentTypes,
    );

    const hasProviderAvailability = this.__validateProviderAvailability(
      providerAvailability,
    );

    this.onStateChanged(
      Boolean(hasAppointmentTypes && hasProviderAvailability),
    );
  }
}
