import '../../../../src/components/misc/neb-icon';
import './neb-button-actions';
import './neb-tooltip';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_DISABLED,
} from '../../../neb-styles/neb-variables';

import { DEFAULT_TOGGLE_ICON } from './neb-toggle-button';

export const ELEMENTS = {
  labels: {
    selector: '.label',
  },
  arrowIcons: {
    selector: '.icon-arrow',
  },
  addAllButton: {
    id: 'button-add-all',
  },

  removeAllButton: {
    id: 'button-remove-all',
  },
  columns: {
    selector: '.cell',
  },
  leadingActions: {
    id: 'leading-actions',
  },
  trailingActions: {
    id: 'trailing-actions',
  },
  reorderSpace: {
    id: 'reorder-space',
  },
  toolTips: {
    selector: '.tooltip',
  },
  toolTipTexts: {
    selector: '.text-tooltip',
  },
};
export const SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc',
};

class NebTableHeader extends LitElement {
  static get properties() {
    return {
      allAdded: Boolean,
      reorder: Boolean,
      forceDownMenu: Boolean,
      addAllLabel: String,
      addAllToggledIcon: String,
      sortParams: Object,
      config: Array,
      forceHeaderActionsDown: Boolean,
      maxVisibleItems: Number,
      getLeadingHeaderActions: Function,
      getTrailingHeaderActions: Function,
      onRenderLeadingHeaderCustom: Function,
      getDynamicLeadingHeaderActions: Function,
      getDynamicTrailingHeaderActions: Function,
      showDetailIcon: {
        reflect: true,
        type: Boolean,
      },
      showExpandButton: {
        reflect: true,
        type: Boolean,
      },
      showRemoveButton: {
        reflect: true,
        type: Boolean,
      },
      showRemoveAllButton: {
        reflect: true,
        type: Boolean,
      },
      removeAllDisabled: {
        reflect: true,
        type: Boolean,
      },
      showTrailingActions: {
        reflect: true,
        type: Boolean,
      },
      showLeadingActions: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        reflect: true,
        type: String,
      },
      preview: {
        type: Boolean,
        reflect: true,
      },
      condensed: {
        type: Boolean,
        reflect: true,
      },
      addAllAlwaysEnabled: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.showDetailIcon = false;
    this.showExpandButton = false;
    this.showRemoveButton = false;
    this.showRemoveAllButton = false;
    this.showTrailingActions = false;
    this.showLeadingActions = false;
    this.forceHeaderActionsDown = false;
    this.preview = false;
    this.reorder = false;
    this.getLeadingHeaderActions = null;
    this.getTrailingHeaderActions = null;
    this.allAdded = null;
    this.layout = '';
    this.addAllLabel = '';
    this.addAllToggledIcon = DEFAULT_TOGGLE_ICON;
    this.condensed = false;
    this.forceDownMenu = false;
    this.config = [
      {
        sortable: false,
        key: '',
        label: 'Title',
        flex: css`0 0 80px`,
      },
    ];

    this.sortParams = {
      key: '',
      dir: SORT_DIR.ASC,
    };

    this.onSort = () => {};

    this.onRemoveAll = () => {};

    this.onAddAll = () => {};

    this.getDynamicLeadingHeaderActions = null;
    this.getDynamicTrailingHeaderActions = null;
    this.onRenderLeadingHeaderCustom = null;
    this.maxVisibleItems = 5;
    this.addAllAlwaysEnabled = false;
    this.removeAllDisabled = false;
  }

  __initHandlers() {
    this.__handlers = {
      addAll: () => this.onAddAll(),
      sort: e => {
        const { key } = e.currentTarget;
        const column = this.config.find(val => key === val.key);
        if (!column.sortable) return;

        if (key === this.sortParams.key) {
          this.onSort({
            key,
            dir:
              this.sortParams.dir === SORT_DIR.ASC
                ? SORT_DIR.DESC
                : SORT_DIR.ASC,
          });
        } else {
          this.onSort({
            key,
            dir: SORT_DIR.ASC,
          });
        }
      },
      removeAll: () => {
        if (!this.removeAllDisabled) {
          this.onRemoveAll();
        }
      },
    };
  }

  __isSortArrowSelected(key, dir) {
    return key === this.sortParams.key && dir === this.sortParams.dir;
  }

  __pointerCursor(item) {
    return item.sortable ? 'cursor: pointer' : '';
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          background-color: ${CSS_COLOR_WHITE};

          --add-all-column-width: 180px;
          --icon-spacer-max-height: none;
        }

        :host([condensed]) {
          background-color: unset;
        }

        :host([condensed]) .container {
          border: none;
          padding: 0;
        }

        :host([condensed]) .label {
          font-size: 12px;
        }

        .container {
          display: flex;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
          align-items: center;
        }

        :host([preview]) .container {
          padding: 0 10px 10px 10px;
        }

        .container-arrow {
          display: flex;
          margin-left: 10px;
          justify-content: flex-end;
          flex-flow: column nowrap;
        }

        .icon-remove {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .icon-remove[disabled] {
          cursor: default;
          opacity: 0.5;
          fill: ${CSS_COLOR_DISABLED};
        }
        .tooltip {
          margin-left: 5px;
        }

        .text-tooltip {
          margin: 0;
        }

        .cell {
          display: flex;
          align-items: center;
          margin-right: ${CSS_SPACING};
          min-width: 0;
        }

        .cell:last-child {
          margin-right: 0;
        }

        .cell-spacer-20 {
          min-width: ${CSS_SPACING};
        }

        .cell-detail {
          width: 12px;
        }

        .cell-add-all {
          min-width: var(--add-all-column-width);
          max-width: var(--add-all-column-width);
          white-space: nowrap;
        }

        .cell-icon-spacer {
          width: 28px;
          max-height: var(--icon-spacer-max-height);
        }

        .ellipsis {
          padding-top: 1px;
        }

        .add-all-button-row {
          padding: 10px ${CSS_SPACING};
        }

        .icon-arrow {
          cursor: pointer;
          width: 10px;
          height: 10px;
          fill: ${CSS_COLOR_GREY_2};
        }

        .icon-arrow[selected] {
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-arrow-up {
          transform: scaleY(-1);
        }

        .label {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        :host([layout='small']) .label {
          width: min-content;
        }
      `,
    ];
  }

  __renderArrows(item) {
    return item.sortable
      ? html`
          <div id="sort-${item.key}" class="container-arrow">
            <neb-icon
              class="icon-arrow icon-arrow-up"
              icon="neb:arrow"
              ?selected="${this.__isSortArrowSelected(item.key, SORT_DIR.ASC)}"
            ></neb-icon>

            <neb-icon
              class="icon-arrow"
              icon="neb:arrow"
              ?selected="${this.__isSortArrowSelected(item.key, SORT_DIR.DESC)}"
            ></neb-icon>
          </div>
        `
      : '';
  }

  __renderTooltip(item) {
    return item.tooltip
      ? html`
          <neb-tooltip
            class="tooltip"
            .defaultAnchor="${item.tooltip.anchor || 'right'}"
            .small="${this.layout === 'small'}"
          >
            <p slot="tooltip" class="text-tooltip">${item.tooltip.text}</p>
          </neb-tooltip>
        `
      : '';
  }

  __renderAddAllButton() {
    return html`
      <neb-toggle-button
        id="${ELEMENTS.addAllButton.id}"
        .buttonText="${this.addAllLabel}"
        .onClick="${this.__handlers.addAll}"
        .canToggle="${this.addAllAlwaysEnabled || !this.allAdded}"
        .isToggled="${this.allAdded}"
        .toggledIcon="${this.addAllToggledIcon}"
        ?disabled="${!this.addAllAlwaysEnabled && this.allAdded}"
      ></neb-toggle-button>
    `;
  }

  __renderAddAllButtonCell() {
    if (this.allAdded !== null) {
      return html`
        <div class="cell cell-add-all">${this.__renderAddAllButton()}</div>
      `;
    }

    return '';
  }

  __renderColumns() {
    return html`
      ${
        this.config
          .filter(item => this.layout !== 'small' || item.mobile)
          .map(
            item => html`
              <div
                class="cell"
                style="flex:${item.flex}; ${this.__pointerCursor(item)} "
                .key="${item.key}"
                @click="${this.__handlers.sort}"
              >
                <div class="label">${item.label}</div>
                ${this.__renderArrows(item)} ${this.__renderTooltip(item)}
              </div>
            `,
          )
      }
    `;
  }

  __renderDetailIconSpacing() {
    return this.showDetailIcon
      ? html`
          <div class="cell cell-detail"></div>
        `
      : '';
  }

  __renderExpandButtonSpacing() {
    return this.showExpandButton
      ? html`
          <div class="cell cell-detail"></div>
        `
      : '';
  }

  __renderRemoveButtonSpacing() {
    return this.showRemoveButton
      ? html`
          <div class="cell cell-spacer-20"></div>
        `
      : '';
  }

  __renderRemoveAllButton() {
    return this.showRemoveAllButton
      ? html`
          <neb-icon
            class="cell icon-remove"
            id="${ELEMENTS.removeAllButton.id}"
            icon="neb:minus"
            ?disabled="${this.removeAllDisabled}"
            @click="${this.__handlers.removeAll}"
          ></neb-icon>
        `
      : this.__renderRemoveButtonSpacing();
  }

  __renderDynamicActionButton(onClick, align) {
    const { id } = ELEMENTS[
      align === 'left' ? 'leadingActions' : 'trailingActions'
    ];
    return onClick
      ? html`
          <neb-button-actions
            id="${id}"
            class="cell cell-icon-spacer ellipsis"
            maxVisibleItems="${this.maxVisibleItems}"
            .align="${align}"
            .onClick="${onClick}"
            .forceDownMenu="${this.forceDownMenu}"
          ></neb-button-actions>
        `
      : '';
  }

  __renderActionButton(action, align) {
    const { id } = ELEMENTS[
      align === 'left' ? 'leadingActions' : 'trailingActions'
    ];
    return action
      ? html`
          <neb-button-actions
            id="${id}"
            class="cell cell-icon-spacer ellipsis"
            maxVisibleItems="${this.maxVisibleItems}"
            .forceDownMenu="${this.forceHeaderActionsDown}"
            .align="${align}"
            .value="${action()}"
          ></neb-button-actions>
        `
      : '';
  }

  __renderActionButtonSpacing(flag) {
    return flag
      ? html`
          <div class="cell cell-icon-spacer"></div>
        `
      : '';
  }

  __renderReorderCell() {
    return this.reorder
      ? html`
          <div
            id="${ELEMENTS.reorderSpace.id}"
            class="cell cell-spacer-20"
          ></div>
        `
      : '';
  }

  __renderLeadingHeaderCustom() {
    if (this.onRenderLeadingHeaderCustom) {
      return this.onRenderLeadingHeaderCustom();
    }

    return undefined;
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderReorderCell()} ${this.__renderLeadingHeaderCustom()}
        ${this.__renderAddAllButtonCell()}
        ${
          this.__renderDynamicActionButton(
            this.getDynamicLeadingHeaderActions,
            'left',
          )
        }
        ${this.__renderActionButton(this.getLeadingHeaderActions, 'left')}
        ${this.__renderActionButtonSpacing(this.showLeadingActions)}
        ${this.__renderColumns()}
        ${this.__renderActionButtonSpacing(this.showTrailingActions)}
        ${
          this.__renderDynamicActionButton(
            this.getDynamicTrailingHeaderActions,
            'right',
          )
        }
        ${this.__renderActionButton(this.getTrailingHeaderActions, 'right')}
        ${this.__renderRemoveAllButton()} ${this.__renderDetailIconSpacing()}
        ${this.__renderExpandButtonSpacing()}
      </div>
    `;
  }
}

window.customElements.define('neb-table-header', NebTableHeader);
