import '../../../../../../src/components/misc/neb-icon';
import '../../../../../neb-styles/neb-icons';
import '../../inputs/neb-select';
import '../../neb-loading-spinner-resizable';
import '../../neb-button';
import { openPopup } from '@neb/popup';
import deepEqual from 'fast-deep-equal';
import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';

import { createFiservPayment } from '../../../../../../src/features/payfac/fiserv-api-client';
import {
  formatFiservElectronicPayment,
  getMerchantProvider,
  filterPaymentMethodsByMerchantProvider,
} from '../../../../../../src/features/payfac/utils';
import { getDefaultPaymentMethod } from '../../../../../../src/utils/default-payment';
import { createPayment } from '../../../../../neb-api-client/src/payments/electronic-payments-api-client';
import { openDirtyPopup } from '../../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../../neb-styles/neb-variables';
import { MERCHANT_PROVIDERS } from '../../../../../neb-utils/constants';
import { currencyToCents } from '../../../../../neb-utils/formatters';

export const ELEMENTS = {
  cancelButton: {
    id: 'button-cancel',
  },
  closeButton: {
    id: 'button-close',
  },
  submitButton: {
    id: 'button-submit',
  },
  textHeader: {
    id: 'text-header',
  },
  textSubheader: {
    id: 'text-subheader',
  },
  textAmount: {
    id: 'text-amount',
  },
  merchantAccountDropDown: {
    id: 'merchant-account-dropdown',
  },
  paymentMethodDropdown: {
    id: 'payment-method-dropdown',
  },
};

export const PAYMENT_RESULT_CONFIG = {
  PAYMENT: {
    SUCCESS: {
      title: 'Payment Transaction Successful',
      subheader: '',
      message: '',
      success: true,
      allowSaveCardOnFile: false,
      confirmLabel: '',
      cancelLabel: '',
      cardOnFileName: '',
    },
    FAILURE: {
      title: 'Payment Transaction Unsuccessful',
      message:
        'Payment for this transaction could not be completed.  Try using different payment details.',
      success: false,
      allowSaveCardOnFile: false,
      confirmLabel: 'Try Different Payment Details',
      cancelLabel: '',
      cardOnFileName: '',
    },
    FAILURE_RETRY: {
      title: 'Payment Transaction Unsuccessful',
      message:
        'Payment for this transaction could not be completed. Try again to re-submit the request, or try using different payment details.',
      success: false,
      allowSaveCardOnFile: false,
      confirmLabel: 'Try Again',
      cancelLabel: 'Try Different Payment Details',
      cardOnFileName: '',
    },
  },
};

const EMPTY_PAYMENT = { id: '', label: '' };

class NebPaymentCardOnFileForm extends LitElement {
  static get properties() {
    return {
      __dirty: Boolean,
      __paymentMethodNames: Array,
      __processing: Boolean,
      __selectedMerchantAccount: Object,
      __selectedPaymentMethod: Object,
      __previouslySelectMerchantAccount: Object,
      amount: String,
      postalCode: String,
      address1: String,
      address2: String,
      state: String,
      city: String,
      firstName: String,
      lastName: String,
      merchantAccounts: Array,
      merchantAccountNames: Array,
      paymentMethods: Array,
      holderType: String,
      holderId: String,
      logContent: Object,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__dirty = false;
    this.__paymentMethodNames = [];
    this.__processing = false;
    this.__selectedMerchantAccount = null;
    this.__selectedPaymentMethod = null;
    this.__originalSelectedMerchantAccount = null;
    this.__originalSelectedPaymentMethod = null;
    this.__previouslySelectMerchantAccount = null;
    this.amount = '';
    this.holderType = '';
    this.holderId = '';
    this.postalCode = '';
    this.address1 = '';
    this.address2 = '';
    this.state = '';
    this.city = '';
    this.firstName = '';
    this.lastName = '';
    this.merchantAccounts = [];
    this.paymentMethods = [];
    this.merchantAccountNames = [];
    this.logContent = {};

    this.onCancel = () => {};

    this.onSave = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      submitPayment: async () => {
        const selectedPaymentMethod = this.paymentMethods.find(
          method => method.id === this.__selectedPaymentMethod.id,
        );

        const selectedMerchantAccount = this.merchantAccounts.find(
          ma => ma.id === this.__selectedMerchantAccount.id,
        );

        if (!selectedPaymentMethod || !selectedMerchantAccount) return;
        this.__processing = true;

        let payload;
        let result;

        if (
          selectedMerchantAccount.merchantProvider === MERCHANT_PROVIDERS.FISERV
        ) {
          payload = {
            merchantAccountId: selectedMerchantAccount.id,
            amount: currencyToCents(this.amount),
            postal: this.postalCode,
            address: this.address1,
            address2: this.address2,
            region: this.state,
            city: this.city,
            firstName: this.firstName,
            lastName: this.lastName,
            expiry: moment(
              selectedPaymentMethod.expiryDate.toISOString(),
            ).format('YYYYM'),
            token: selectedPaymentMethod.token,
            holderId: this.holderId,
            holderType: this.holderType,
            logContent: this.logContent,
            ecomind: 'T',
            cof: 'C',
          };

          result = formatFiservElectronicPayment(
            await createFiservPayment(payload),
          );
        } else {
          payload = {
            card: { token: selectedPaymentMethod.token },
            transactionAmount: `${this.__convertCurrency(this.amount)}`,
            holderId: this.holderId,
            holderType: this.holderType,
            postalCode: this.postalCode || undefined,
            address1: this.address1 || undefined,
            logContent: {
              action: this.logContent.action,
              rawModel: this.logContent.rawModel,
            },
          };

          result = await createPayment(selectedMerchantAccount, payload);
        }

        if (result) {
          if (result.status === 'Approved') {
            this.onSave(result);

            await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS,
            );

            return;
          }

          if (result.code?.slice(0, 1) === '5') {
            const response = await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              {
                ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE_RETRY,
                subheader: 'Service Unavailable',
              },
            );

            if (response) {
              if (response.action === 'confirm') {
                this.__processing = true;
                this.__handlers.submitPayment();
              } else {
                this.__processing = false;
              }
            } else this.onCancel();
          } else {
            const response = await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              {
                ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
                subheader: result.statusDescription || result.status,
              },
            );

            if (response) this.__processing = false;
            else this.onCancel();
          }
        } else this.__processing = false;
      },
      cancel: async () => {
        if (this.__dirty) {
          if (await openDirtyPopup()) this.onCancel();
        } else {
          this.onCancel();
        }
      },
      paymentMethodChange: ({ value }) => {
        this.__selectedPaymentMethod = value;
        this.__checkDirty();
      },
      merchantAccountChange: ({ value }) => {
        if (deepEqual(this.__selectedMerchantAccount, value)) return;

        this.__previouslySelectMerchantAccount = this.__selectedMerchantAccount;
        this.__selectedMerchantAccount = value;

        this.__updatePaymentMethodSelection();

        if (this.__merchantProviderChanged()) {
          this.__setDefaultPaymentMethod();
        }

        this.__checkDirty();
      },
    };
  }

  __merchantProviderChanged() {
    return (
      getMerchantProvider(this.__previouslySelectMerchantAccount) !==
      getMerchantProvider(this.__selectedMerchantAccount)
    );
  }

  __checkDirty() {
    this.__dirty =
      this.__originalSelectedMerchantAccount.id !==
        this.__selectedMerchantAccount.id ||
      this.__originalSelectedPaymentMethod.id !==
        this.__selectedPaymentMethod.id;
  }

  firstUpdated(props) {
    this.merchantAccountNames = this.merchantAccounts
      .filter(v => v.active)
      .map(v => ({
        ...v,
        id: v.id,
        label: v.name,
      }));

    this.__selectedMerchantAccount = this.merchantAccountNames[0];

    this.__updatePaymentMethodSelection();

    this.__setDefaultPaymentMethod();

    this.__originalSelectedMerchantAccount = {
      ...this.__selectedMerchantAccount,
    };

    this.__originalSelectedPaymentMethod = { ...this.__selectedPaymentMethod };
    super.firstUpdated(props);
  }

  __convertCurrency(amount) {
    return amount ? Number(amount.replace(/[^0-9.]/g, '')) : 0;
  }

  __updatePaymentMethodSelection() {
    const filteredPaymentMethods = filterPaymentMethodsByMerchantProvider(
      this.paymentMethods,
      this.merchantAccounts,
      this.__selectedMerchantAccount,
    );

    this.__paymentMethodNames = filteredPaymentMethods.map(v => ({
      ...v,
      label: v.description,
    }));
  }

  __setDefaultPaymentMethod() {
    const defaultPayment = getDefaultPaymentMethod(
      this.__paymentMethodNames,
      this.holderId,
    );

    this.__selectedPaymentMethod = defaultPayment || EMPTY_PAYMENT;
  }

  static get styles() {
    return css`
      .container-content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
      }

      .header {
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: space-between;
      }

      .text-header {
        font-size: ${CSS_FONT_SIZE_HEADER};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        padding-bottom: ${CSS_SPACING};
      }

      .text-message {
        margin: 0;
        white-space: inherit;
        color: ${CSS_COLOR_BLACK};
        font-weight: bold;
        padding: ${CSS_SPACING} 0;
      }

      .text-subheader {
        margin: 0;
        white-space: inherit;
        color: ${CSS_COLOR_BLACK};
      }

      .icon-close {
        height: 24px;
        width: 24px;
        fill: ${CSS_COLOR_GREY_1};
        padding: 0;
        cursor: pointer;
      }

      .dropdown {
        padding-bottom: 16px;
      }

      .margin-left {
        margin-left: 16px;
      }

      .buttons {
        margin-top: 20px;
      }

      .button-cancel:active {
        opacity: 0.65;
      }

      .button-submit:active {
        opacity: 0.65;
      }

      .container-spinner {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
      }

      .spinner {
        margin-top: ${CSS_SPACING};
        padding-bottom: 50px;
        text-align: center;
        z-index: 1;
      }
    `;
  }

  __hasMerchantAccounts() {
    return this.merchantAccountNames.length > 1;
  }

  __getTitle() {
    if (this.__processing) return 'Transaction Processing';

    return this.__hasMerchantAccounts()
      ? 'Merchant Account and Card Details'
      : 'Card Details';
  }

  __renderSpinner() {
    return html`
      <div class="container-spinner">
        <neb-loading-spinner-resizable
          class="spinner"
        ></neb-loading-spinner-resizable>
      </div>
    `;
  }

  __renderTitle() {
    return html`
      <div class="header">
        <div class="text-header" id="${ELEMENTS.textHeader.id}">
          ${this.__getTitle()}
        </div>
        ${
          !this.__processing
            ? html`
                <neb-icon
                  id="${ELEMENTS.closeButton.id}"
                  class="icon-close"
                  icon="neb:close"
                  @click="${this.__handlers.cancel}"
                ></neb-icon>
              `
            : ''
        }
      </div>
    `;
  }

  __renderMerchantAccounts() {
    return this.__hasMerchantAccounts()
      ? html`
          <neb-select
            id="${ELEMENTS.merchantAccountDropDown.id}"
            class="dropdown"
            label="Merchant Account"
            .items="${this.merchantAccountNames}"
            .value="${this.__selectedMerchantAccount}"
            .onChange="${this.__handlers.merchantAccountChange}"
          ></neb-select>
        `
      : '';
  }

  __renderPaymentMethods() {
    return html`
      <neb-select
        id="${ELEMENTS.paymentMethodDropdown.id}"
        class="dropdown"
        label="Card On File"
        .items="${this.__paymentMethodNames}"
        .value="${this.__selectedPaymentMethod}"
        .onChange="${this.__handlers.paymentMethodChange}"
      ></neb-select>
    `;
  }

  __getSubheader() {
    return this.__hasMerchantAccounts()
      ? 'Select the merchant account and card on file for this transaction.'
      : 'Select the card on file for this transaction.';
  }

  renderForm() {
    return html`
      <div class="container-content">
        <div id="${ELEMENTS.textSubheader.id}" class="text-subheader">
          ${this.__getSubheader()}
        </div>

        <div id="${ELEMENTS.textAmount.id}" class="text-message">
          Payment Amount: ${this.amount}
        </div>

        ${this.__renderMerchantAccounts()} ${this.__renderPaymentMethods()}
      </div>

      <div class="buttons">
        <neb-button
          id="${ELEMENTS.submitButton.id}"
          label="Submit"
          class="button-submit"
          .onClick="${this.__handlers.submitPayment}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button-cancel margin-left"
          label="Cancel"
          role="cancel"
          .onClick="${this.__handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  render() {
    return html`
      ${this.__renderTitle()}
      ${this.__processing ? this.__renderSpinner() : this.renderForm()}
    `;
  }
}

customElements.define(
  'neb-payment-card-on-file-form',
  NebPaymentCardOnFileForm,
);
