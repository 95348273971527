import '../../../../neb-lit-components/src/components/neb-tabs-old';
import '../../../../neb-lit-components/src/components/neb-tab-old';
import '../../../../neb-lit-components/src/components/neb-dropdown';
import '../../../../neb-lit-components/src/components/neb-breadcrumbs';
import '../../../../neb-lit-components/src/components/controls/neb-tab-group';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { TABS, TAB_KEYS } from '../../../../neb-utils/macro-sets';

export const ELEMENTS = {
  tabGroup: {
    id: 'tab-group',
  },
  macroSetDropdown: {
    id: 'macros',
  },
  breadcrumbs: {
    id: 'breadcrumbs',
  },
};

class NebChartingNotesHeader extends LitElement {
  static get properties() {
    return {
      selectedMacroSetId: String,
      selectedTab: String,
      macroSets: Array,
      breadcrumbs: Array,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.selectedMacroSetId = null;
    this.selectedTab = '';
    this.macroSets = [];
    this.breadcrumbs = [];

    this.onTabSelected = () => {};

    this.onMacroSetSelected = () => {};

    this.onBreadcrumbSelected = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      tabSelected: key => this.onTabSelected(key),
      breadcrumbSelected: index => this.onBreadcrumbSelected(index),
      macroSetSelected: ({ selectedItem }) => {
        this.onMacroSetSelected(selectedItem.id);
      },
    };
  }

  getSelectedDropdownItem() {
    return this.macroSets.find(item => item.id === this.selectedMacroSetId);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .macroset-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 15px ${CSS_SPACING};
        }

        .tabs {
          width: 100%;
          display: block;
        }

        .label {
          align-self: center;
          margin-right: 10px;
        }

        .dropdown {
          width: 208px;
        }

        .macroset-container {
          display: flex;
        }
      `,
    ];
  }

  __genNavItems() {
    return [
      ...TABS,
      {
        id: TAB_KEYS.SOAP,
        label: 'SOAP',
      },
      {
        id: TAB_KEYS.DATA,
        label: 'Data',
      },
    ];
  }

  __renderSetDropdown() {
    return html`
      <div class="macroset-container">
        <div class="label">Set Name</div>
        <neb-dropdown
          id="${ELEMENTS.macroSetDropdown.id}"
          class="dropdown"
          placeholder="Macro Set"
          .items="${this.macroSets}"
          .selectedItem="${this.getSelectedDropdownItem()}"
          .onItemSelected="${this.__handlers.macroSetSelected}"
        ></neb-dropdown>
      </div>
    `;
  }

  render() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabGroup.id}"
        class="tabs"
        .selectedId="${this.selectedTab}"
        .items="${this.__genNavItems()}"
        .onSelect="${this.__handlers.tabSelected}"
      ></neb-tab-group>

      ${
        this.selectedTab !== TAB_KEYS.DATA
          ? html`
              <div class="macroset-row">
                <neb-breadcrumbs
                  id="${ELEMENTS.breadcrumbs.id}"
                  class="breadcrumbs"
                  .items="${this.breadcrumbs}"
                  .onSelect="${this.__handlers.breadcrumbSelected}"
                ></neb-breadcrumbs>
                ${this.__renderSetDropdown()}
              </div>
            `
          : ''
      }
    `;
  }
}

customElements.define('neb-charting-notes-header', NebChartingNotesHeader);
