import { LitElement, html } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
} from '../../../neb-styles/neb-variables';

import '../../../../src/components/controls/field-groups/neb-pill';
import '../../../../src/components/controls/field-groups/neb-tags-list';
import '../../../../src/components/controls/inputs/neb-checkbox';

export const ELEMENTS = {
  tagsList: {
    id: 'tags-list',
  },
  thumbnailContainer: {
    selector: '.thumbnail-container',
  },
  documentCheckbox: {
    id: 'document-checkbox',
  },
};

export const ID_ITEM_CONTAINER_PREFIX = 'item-';
export const ID_IMAGE_PREFIX = 'img-';
const ID_PLACEHOLDER_PREFIX = 'placeholder-';
export class NebDocumentItem extends LitElement {
  static get properties() {
    return {
      doc: Object,
      isSelected: {
        type: Boolean,
        reflect: true,
        attribute: 'selected',
      },
      small: {
        type: Boolean,
        reflect: true,
      },
      horizontalDisplay: {
        type: Boolean,
        reflect: true,
        attribute: 'horizontal-display',
      },
      showCheckbox: {
        type: Boolean,
        reflect: true,
        attribute: 'show-checkbox',
      },
      checkedDocuments: Object,
      showTag: Boolean,
    };
  }

  constructor() {
    super();

    this.onSelectDocumentIndex = () => {};

    this.onCheckDocument = () => {};

    this.checkedDocuments = {};
    this.isSelected = false;
    this.horizontalDisplay = false;
    this.doc = {
      name: '',
      img: '',
      documentTags: [],
    };

    this.showCheckbox = false;
    this.showTag = false;

    this.__initHandlers();
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          display: block;
          box-sizing: border-box;
          flex-shrink: 0;
        }

        :host([horizontal-display]) {
          display: inline-block;
          box-sizing: border-box;
          height: 100%;
        }

        .document-item {
          display: flex;
          flex-direction: row;
          align-items: start;
          width: 100%;
          box-sizing: border-box;
          padding-bottom: 2px;
          height: 100%;
        }

        :host([horizontal-display]) .document-item {
          padding-bottom: 0;
          height: 100%;
        }

        .thumbnail-container {
          box-sizing: border-box;
          width: 100%;
        }

        :host([horizontal-display]) .thumbnail-container {
          height: calc(100% - 20px);
          width: initial;
        }

        .thumbnail {
          box-sizing: border-box;
          border: 3px solid transparent;
          max-height: 100%;
          max-width: 100%;
        }

        :host([horizontal-display]) .thumbnail {
          box-sizing: border-box;
          border: 3px solid transparent;
          height: 100%;
          width: initial;
        }

        :host([selected]:not([small])) .thumbnail {
          box-sizing: border-box;
          border: 6px solid ${CSS_COLOR_HIGHLIGHT};
        }

        .document-item-container {
          position: relative;
          box-sizing: border-box;
          padding-bottom: 20px;
          margin-bottom: 20px;
          height: initial;
        }

        :host([horizontal-display]) .document-item-container {
          padding-right: 30px;
          padding-bottom: 0;
          height: 100%;
        }

        .label {
          box-sizing: border-box;
          overflow: hidden;
          font-size: ${CSS_FONT_SIZE_BODY};
          text-overflow: ellipsis;
          white-space: nowrap;
          position: absolute;
          left: 0;
          right: 0;
          margin-top: 6px;
        }

        :host([horizontal-display]) .label {
          padding-left: 0;
        }

        .checkbox {
          margin: 0px 10px 10px 10px;
        }

        :host([show-checkbox]) .label {
          margin-left: 40px;
        }

        neb-tags-list {
          margin-top: 35px;
        }

        :host([show-checkbox]) neb-tags-list {
          margin-left: 40px;
        }

        .icon-thumbnail {
          display: block;
          width: 100px;
          height: 100px;
        }
      </style>
    `;
  }

  __initHandlers() {
    this.__handlers = {
      checkItem: () => {
        this.onCheckDocument(this.doc.id, !this.checkedDocuments[this.doc.id]);
        this.requestUpdate();
      },
    };
  }

  __renderTags() {
    return this.showTag
      ? html`
          <neb-tags-list
            id="${ELEMENTS.tagsList.id}"
            .model="${{ tags: this.doc.documentTags }}"
          ></neb-tags-list>
        `
      : '';
  }

  __renderCheckbox(index) {
    return this.showCheckbox
      ? html`
          <neb-checkbox
            id="${ELEMENTS.documentCheckbox.id}-${index}"
            class="checkbox"
            index="${index}"
            @click="${this.__handlers.checkItem}"
            ?checked="${this.checkedDocuments[this.doc.id]}"
          >
          </neb-checkbox>
        `
      : '';
  }

  __renderThumbnail() {
    return html`
      <div
        id="${ID_ITEM_CONTAINER_PREFIX}${this.docIndex}"
        class="thumbnail-container"
        @click="${() => this.onSelectDocumentIndex(this.docIndex)}"
      >
        ${
          this.doc.hasThumbnail
            ? html`
                <img
                  id="${ID_IMAGE_PREFIX}${this.docIndex}"
                  class="thumbnail"
                  src="${this.doc.img || ''}"
                />
              `
            : html`
                <neb-icon
                  id="${ID_PLACEHOLDER_PREFIX}${this.docIndex}"
                  class="icon-thumbnail"
                  icon="neb:placeholder"
                ></neb-icon>
              `
        }

        <div id="label-${this.docIndex}" class="label">${this.doc.name}</div>
      </div>
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <div class="document-item-container">
        <div class="document-item">
          ${this.__renderCheckbox(this.docIndex)} ${this.__renderThumbnail()}
        </div>

        ${this.__renderTags()}
      </div>
    `;
  }
}
customElements.define('neb-document-item', NebDocumentItem);
