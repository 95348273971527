import { css, html } from 'lit';

import { getActiveProviderUsers } from '../../../packages/neb-api-client/src/practice-users-api-client';
import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { getDescriptionLines } from '../../../packages/neb-utils/patientPackage';
import * as selectors from '../../../packages/neb-utils/selectors';
import { CSS_FONT_WEIGHT_BOLD, CSS_SPACING } from '../../styles';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  packageDescription: { id: 'package-description' },
  providerSelect: { id: 'provider-select' },
  packageSelect: { id: 'package-select' },
  confirmButton: { id: 'confirm-button' },
  cancelButton: { id: 'cancel-button' },
};

class NebFormQuickCheckIn extends NebForm {
  static get properties() {
    return {
      hasProvider: Boolean,
      patientPackages: Array,
      usedCounts: Array,
      __providers: Array,
      __packages: Array,
    };
  }

  static createModel() {
    return {
      providerId: '',
      patientPackageId: '',
    };
  }

  createSelectors() {
    return {
      children: {
        providerId: selectors.select(
          [selectors.ITEM_EMPTY],
          selectors.ITEM_EMPTY,
          {
            validators: !this.hasProvider
              ? [
                  {
                    error: 'Required',
                    validate: v => v.data.id,
                  },
                ]
              : [],
          },
        ),
        patientPackageId: selectors.select(
          [selectors.ITEM_EMPTY],
          selectors.ITEM_EMPTY,
          {
            validators:
              this.patientPackages && this.patientPackages.length
                ? [
                    {
                      error: 'Required',
                      validate: v => v.data.id,
                    },
                  ]
                : [],
          },
        ),
      },
    };
  }

  initState() {
    super.initState();

    this.__providers = [];
    this.__packages = [];

    this.hasProvider = true;
    this.patientPackages = [];
    this.usedCounts = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
    };
  }

  async updated(changedProps) {
    if (changedProps.has('hasProvider')) {
      const providers = !this.hasProvider
        ? await getActiveProviderUsers(true)
        : [];
      this.__providers = providers.length
        ? providers.map(data => ({
            data,
            label: objToName(data.name, DEFAULT_NAME_OPTS),
          }))
        : [];
    }

    if (changedProps.has('patientPackages')) {
      this.__packages =
        this.patientPackages && this.patientPackages.length > 1
          ? this.patientPackages.map(data => ({
              data,
              label: data.name,
            }))
          : [];
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow-y: unset;
          min-height: unset;
        }

        .container-select {
          display: flex;
          align-items: baseline;
        }

        .spacing {
          margin-right: ${CSS_SPACING};
        }

        .buttons-container {
          display: flex;
        }

        .add-button {
          padding-right: 10px;
        }

        .input {
          flex: 1 0 0px;
        }

        .container-care-package-summary {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 10px;
        }

        .label-care-package-summary {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  __renderPackageBalance() {
    return this.state.patientPackageId.data &&
      this.state.patientPackageId.data.id
      ? html`
          <div class="container-care-package-summary">
            <div class="label-care-package-summary">Summary</div>

            <div id="${ELEMENTS.packageDescription.id}" class="label-visits">
              ${
                getDescriptionLines(
                  this.state.patientPackageId.data,
                  this.usedCounts.find(
                    usage =>
                      usage.patientPackageId ===
                      this.state.patientPackageId.data.id,
                  ),
                ).map(
                  data => html`
                    <div>${data}</div>
                  `,
                )
              }
            </div>
          </div>
        `
      : '';
  }

  renderActionBar() {
    return html`
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.confirmButton.id}"
          class="button add-button"
          .label="${this.confirmLabel}"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.save}"
          unelevated
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button"
          .label="${this.cancelLabel}"
          .role="${BUTTON_ROLE.CANCEL}"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    return html`
      ${
        !this.hasProvider
          ? html`
              <div class="container-select">
                <neb-select
                  id="${ELEMENTS.providerSelect.id}"
                  class="input"
                  name="providerId"
                  label="Provider"
                  helper="Required"
                  .items="${this.__providers}"
                  .value="${this.state.providerId}"
                  .error="${this.errors.providerId}"
                  .onChange="${this.handlers.change}"
                ></neb-select>
              </div>
            `
          : ''
      }
      ${
        this.patientPackages && this.patientPackages.length
          ? html`
              <div class="container-select">
                <neb-select
                  id="${ELEMENTS.packageSelect.id}"
                  class="input"
                  name="patientPackageId"
                  label="Care Package/Subscription"
                  helper="Required"
                  .items="${this.__packages}"
                  .value="${this.state.patientPackageId}"
                  .error="${this.errors.patientPackageId}"
                  .onChange="${this.handlers.change}"
                ></neb-select>
              </div>
              ${this.__renderPackageBalance()}
            `
          : ''
      }
    `;
  }
}

customElements.define('neb-form-quick-check-in', NebFormQuickCheckIn);
