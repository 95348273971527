import '../../../../../../src/components/misc/neb-icon';
import '../../field-groups/neb-notifications-cell';

import { html, css, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_FONT_SIZE_CAPTION,
  CSS_FONT_SIZE_BODY,
  CSS_BORDER_GREY_1,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_4,
  CSS_SPACING,
  CSS_COLOR_GREY_3,
  CSS_COLOR_ERROR,
  CSS_ERROR_BACKGROUND_COLOR,
} from '../../../../../neb-styles/neb-variables';
import { getClaimChargesWithLineItemInfo } from '../../../../../neb-utils/claims';
import { parseDate } from '../../../../../neb-utils/date-util';
import { displayBox24IJShaded } from '../utils';

export const ELEMENTS = {
  rows: { selector: '[id^=row-]' },
  expandIcons: { selector: 'neb-icon' },
  shaded24Cells: { selector: '.shaded-24' },
  familyPlanCells: { selector: '.family-plan-cell' },
  epsdtCells: { selector: '.epsdt-cell' },
  providerOtherIdentifierCells: { selector: '.provider-identifier-cell' },
  qualifierCells: { selector: '.qualifier-cell' },
  identifierCells: { selector: '.identifier-cell' },
  providerNPICells: { selector: '.provider-NPI-cell' },
  dateOfServiceCells: { selector: '.date-of-service-cell' },
  fromDateCells: { selector: '.from-date' },
  toDateCells: { selector: '.to-date' },
  posCells: { selector: '.pos' },
  emergencyCells: { selector: '.emergency' },
  codeCells: { selector: '.code' },
  modifiersCells: { selector: '.modifiers' },
  diagnosisPointersCells: { selector: '.diagnosis-pointers' },
  chargeAmountCells: { selector: '.charge-amount' },
  unitsCells: { selector: '.units' },
  nebNotificationsCells: { selector: '[id^=neb-notification-]' },
  statusCodeCells: { selector: '.status-code-cell' },
};

const EMPTY_CLAIM_CHARGE = {
  shaded24: '',
  dateOfService: '',
  code: '',
  modifier_1: '',
  modifier_2: '',
  modifier_3: '',
  modifier_4: '',
  diagnosisPointers: '',
  chargeAmount: '',
  units: '',
  providerOtherId: '',
  providerNPI: '',
  providerOtherIdQualifier: '',
};

const CONFIG = {
  isEmergency: {
    title: '24C. EMG',
    type: 'yesNo',
  },
  isFamilyPlanning: {
    title: '24H. Family Planning',
    type: 'yesNo',
  },
  providerIdentifier: {
    title: '24I/J. Rendering Provider ID',
    type: 'singleSelect',
  },
};

class NebClaimFormCellFL24 extends LitElement {
  static get properties() {
    return {
      billingProviderNPI: String,
      billingProviderOtherId: String,
      billingProviderOtherIdQualifier: String,
      claimCharges: Array,
      errors: Array,
      renderExpandIcon: {
        type: Boolean,
        reflect: true,
      },
      claimStatusLineItems: Array,
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.billingProviderNPI = '';
    this.billingProviderOtherId = '';
    this.billingProviderOtherIdQualifier = '';

    this.claimCharges = [];
    this.renderExpandIcon = false;
    this.claimStatusLineItems = [];
    this.errors = [];

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: e => {
        const index = Number(e.currentTarget.getAttribute('index'));

        if (index >= this.claimCharges.length) {
          return;
        }

        const name = e.currentTarget.getAttribute('name');

        this.onClick({
          name: `claimCharges.${index}.${name}`,
          ...CONFIG[name],
        });
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-template-rows: repeat(7, auto);
          align-items: center;
          width: 100%;
        }

        .header-container {
          display: grid;
          grid-template-columns: repeat(34, 1fr);
          font-size: ${CSS_FONT_SIZE_CAPTION};
          border-bottom: 2px solid ${CSS_COLOR_GREY_1};
        }

        .row-container {
          display: grid;
          border-bottom: 2px solid ${CSS_COLOR_GREY_1};
          grid-template-columns: repeat(34, 1fr);
          grid-template-rows: repeat(2, 50px);
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .add-to-grid {
          grid-template-columns: repeat(35, 1fr);
        }

        .header-cell {
          display: grid;
          grid-template-rows: 40px auto;
          justify-items: center;
          text-align: center;
          border-right: ${CSS_BORDER_GREY_1};
        }

        .header-cell:last-child {
          border-right: none;
        }

        .text-box {
          display: flex;
          height: 100%;
          width: 100%;
          justify-content: center;
          align-items: center;
        }

        .proc-mod-cell {
          display: grid;
          grid-column: span 7;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 40px auto;
          justify-items: center;
          text-align: center;
          border-right: ${CSS_BORDER_GREY_1};
        }

        .date-of-service-header {
          display: grid;
          grid-column: span 6;
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: 40px auto;
          justify-items: center;
          border-right: ${CSS_BORDER_GREY_1};
        }

        .data-cell {
          display: grid;
          grid-column: span 2;
          justify-items: center;
          align-items: center;
          border-right: ${CSS_BORDER_GREY_1};
        }

        .date-of-service-cell,
        .two-data-cell {
          display: grid;
          grid-column: span 6;
          grid-template-columns: 1fr 1fr;
          justify-items: center;
          align-items: center;
          border-right: ${CSS_BORDER_GREY_1};
        }

        .family-plan-epsdt-cell {
          display: grid;
          grid-column: span 2;
          grid-row: span 2;
          justify-items: center;
          align-items: center;
          border-right: ${CSS_BORDER_GREY_1};
          grid-auto-rows: 1fr;
        }

        .epsdt-cell {
          border-bottom: ${CSS_BORDER_GREY_1};
        }

        .cell {
          height: 100%;
          width: 100%;
          display: grid;
          place-items: center;
        }

        .modifiers {
          display: flex;
          justify-content: center;
        }

        .modifier {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
        }

        .selectable {
          position: relative;
        }

        :host([renderExpandIcon]) .selectable:hover {
          background-color: ${CSS_COLOR_GREY_4};
          cursor: pointer;
        }

        .selectable:hover .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(45deg);
        }

        .provider-identifier-cell,
        .provider-NPI-cell {
          display: grid;
          grid-column: span 8;
          grid-template-columns: repeat(8, 1fr);
          justify-items: center;
        }

        .provider-cell {
          display: grid;
          grid-column: span 8;
          grid-row: span 2;
          grid-template-rows: repeat(2, 50px);
        }

        .qualifier-cell {
          display: grid;
          grid-column: span 2;
          border-right: ${CSS_BORDER_GREY_1};
          width: 100%;
          justify-items: center;
          align-items: center;
        }

        .identifier-cell {
          display: grid;
          grid-column: span 6;
          width: 100%;
          justify-items: center;
          align-items: center;
        }

        .provider-identifier-cell {
          border-bottom: 1px dashed ${CSS_COLOR_GREY_1};
        }

        .status-code-cell {
          display: grid;
          grid-row: span 2;
          width: 150px;
          border-right: ${CSS_BORDER_GREY_1};
        }

        .shaded {
          background-color: ${CSS_COLOR_GREY_3};
        }

        .span-column-7 {
          grid-column: span 7;
        }

        .span-column-6 {
          grid-column: span 6;
        }

        .span-column-4 {
          grid-column: span 4;
        }

        .span-column-3 {
          grid-column: span 3;
        }

        .span-column-2 {
          grid-column: span 2;
        }

        .icon {
          cursor: pointer;
          margin: 6px 6px auto auto;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
          position: absolute;
          top: 0;
          right: 0;
        }

        .flex-column {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .flex {
          display: flex;
        }

        .shaded-24 {
          display: flex;
          align-items: center;
          grid-column: span 24;
          border-right: ${CSS_BORDER_GREY_1};
          border-bottom: ${CSS_BORDER_GREY_1};
          padding-left: ${CSS_SPACING};
        }

        .label {
          padding: 0 ${CSS_SPACING};
        }

        .error-cell {
          border: 1px solid ${CSS_COLOR_ERROR};
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
        }
      `,
    ];
  }

  __getRows() {
    const claimChargesInfo = this.claimStatusLineItems.length
      ? getClaimChargesWithLineItemInfo(
          this.claimCharges,
          this.claimStatusLineItems,
        )
      : this.claimCharges;

    if (claimChargesInfo.length > 6) {
      return claimChargesInfo.slice(0, 6);
    }

    const emptyRows = new Array(6 - claimChargesInfo.length).fill(
      EMPTY_CLAIM_CHARGE,
    );

    return claimChargesInfo.concat(emptyRows);
  }

  __formatShaded24({
    reportTypeCode,
    reportTransmissionCode,
    identificationNumber,
    supplementalInformation,
    shaded24,
  }) {
    if (supplementalInformation && reportTypeCode && reportTransmissionCode) {
      const supplemental = [
        'NTE',
        reportTypeCode,
        reportTransmissionCode,
        identificationNumber || '',
      ]
        .filter(Boolean)
        .join('');
      return [shaded24, supplemental].filter(Boolean).join('   ');
    }
    return shaded24;
  }

  __formatDate(date) {
    return date ? parseDate(date).format('L') : '';
  }

  __renderHeader() {
    return html`
      <div
        class="header-container ${
          this.claimStatusLineItems.length ? 'add-to-grid' : ''
        }"
      >
        ${
          this.claimStatusLineItems.length
            ? html`
                <div class="status-code-cell"></div>
              `
            : ''
        }
        <div class="date-of-service-header">
          <p class="label">24.</p>
          <p class="span-column-7 flex">A. Date(s) of Service</p>
          <p class="span-column-4">From</p>
          <p class="span-column-4">To</p>
        </div>
        <div class="span-column-2 header-cell">
          <p>B.</p>
          <p class="flex-column ">Place of Service</p>
        </div>
        <div class="span-column-2 header-cell">
          <p>C.</p>
          <p class="flex-column ">EMG</p>
        </div>
        <div class="proc-mod-cell">
          <p class="span-column-2 flex">D. Procedures, Services, or Supplies</p>
          <p>CPT/HCPCS</p>
          <p>Modifiers</p>
        </div>
        <div class="span-column-2 header-cell">
          <p>E.</p>
          <p class="flex-column">Diagnosis Pointer</p>
        </div>
        <div class="span-column-3 header-cell">
          <p>F.</p>
          <p class="flex">$ Charges</p>
        </div>
        <div class="span-column-2 header-cell">
          <p>G.</p>
          <p class="flex-column">Days or Units</p>
        </div>
        <div class="span-column-2 header-cell">
          <p>H.</p>
          <p class="flex-column">EPSDT<br />Family Plan</p>
        </div>
        <div class="span-column-2  header-cell">
          <p>I.</p>
          <p class="flex-column">ID. Qual.</p>
        </div>
        <div class="span-column-6 header-cell">
          <p>J.</p>
          <p class="flex ">Rendering Provider ID Number</p>
        </div>
      </div>
    `;
  }

  __renderExpandButton(lineItemId) {
    return this.renderExpandIcon && lineItemId
      ? html`
          <neb-icon class="icon" icon="neb:expand"></neb-icon>
        `
      : '';
  }

  __renderIdentifierCell({
    qualifier,
    identifier,
    lineItemId,
    index,
    charge,
    errors,
  }) {
    const displayIdentifier = displayBox24IJShaded({
      charge,
      billingProviderOtherIdQualifier: this.billingProviderOtherIdQualifier,
      billingProviderOtherId: this.billingProviderOtherId,
    });

    const qualifierClasses = {
      'qualifier-cell': true,
      'error-cell':
        errors && errors[index] && errors[index].providerOtherIdQualifier,
    };

    const identifierClasses = {
      'identifier-cell': true,
      'error-cell': errors && errors[index] && errors[index].providerOtherId,
    };

    return html`
      <div
        class="provider-identifier-cell ${
          lineItemId ? 'selectable' : ''
        } shaded"
        index="${index}"
        name="providerIdentifier"
        @click="${this.__handlers.click}"
      >
        <div class="${classMap(qualifierClasses)}">
          ${displayIdentifier ? qualifier : ''}
        </div>
        <div class="${classMap(identifierClasses)}">
          ${this.__renderExpandButton(lineItemId)}
          ${displayIdentifier ? identifier : ''}
        </div>
      </div>
    `;
  }

  __renderNPICell({ qualifier, identifier, displayIdentifier }) {
    return html`
      <div class="provider-NPI-cell">
        <div class="qualifier-cell">${displayIdentifier ? qualifier : ''}</div>
        <div class="identifier-cell">
          ${displayIdentifier ? identifier : ''}
        </div>
      </div>
    `;
  }

  __getStatusCodesAndText(csLineItems) {
    const statusText =
      csLineItems.length === 1 ? '1 status' : `${csLineItems.length} statuses`;

    return { statusCodes: csLineItems.flat(), statusText };
  }

  __renderDiagnosisPointers(charge, index, errors) {
    const error =
      errors && errors.length > index ? errors[index].diagnosisPointers : '';
    const styles = `diagnosis-pointers data-cell${error ? ' error-cell' : ''}`;

    return html`
      <div class="${styles}"><div>${charge.diagnosisPointers}</div></div>
    `;
  }

  __renderChargeAmount(charge, index, errors) {
    const error =
      errors && errors.length > index ? errors[index].chargeAmount : '';
    const styles = `charge-amount data-cell span-column-3${
      error ? ' error-cell' : ''
    }`;

    return html`
      <div class="${styles}"><div>${charge.chargeAmount}</div></div>
    `;
  }

  __renderDateOfServiceCell(dateOfService, index, errors) {
    const DOSClasses = {
      'date-of-service-cell': true,
      'error-cell': errors && errors[index] && errors[index].dateOfService,
    };

    return html`
      <div class="${classMap(DOSClasses)}">
        <div class="from-date">${this.__formatDate(dateOfService)}</div>
        <div class="to-date">${this.__formatDate(dateOfService)}</div>
      </div>
    `;
  }

  __renderPlaceOfServiceCell(charge, index, errors) {
    const POSClasses = {
      pos: true,
      'data-cell': true,
      'error-cell': errors && errors[index] && errors[index].pos,
    };

    return html`
      <div class="${classMap(POSClasses)}"><div>${charge.pos}</div></div>
    `;
  }

  __renderModifiers(charge, index, errors) {
    const modifierClasses = {
      modifiers: true,
      cell: true,
      'error-cell':
        errors &&
        errors[index] &&
        (errors[index].modifier_1 ||
          errors[index].modifier_2 ||
          errors[index].modifier_3 ||
          errors[index].modifier_4),
    };

    const modifiers = [
      charge.modifier_1,
      charge.modifier_2,
      charge.modifier_3,
      charge.modifier_4,
    ];

    return html`
      <div class="${classMap(modifierClasses)}">
        ${
          modifiers.some(Boolean)
            ? html`
                <div class="modifier">${modifiers[0] || '-'}</div>
                <div class="modifier">${modifiers[1] || '-'}</div>
                <div class="modifier">${modifiers[2] || '-'}</div>
                <div class="modifier">${modifiers[3] || '-'}</div>
              `
            : ''
        }
      </div>
    `;
  }

  __renderNotificationsCell({ claimStatusLineItems }, index) {
    if (claimStatusLineItems) {
      const { statusCodes, statusText } = this.__getStatusCodesAndText(
        claimStatusLineItems,
      );

      return html`
        <neb-notifications-cell
          id="neb-notification-${index}"
          class="status-code-cell"
          .notifications="${statusCodes}"
          .notificationText="${statusText}"
        ></neb-notifications-cell>
      `;
    }

    return html`
      <div class="status-code-cell"></div>
    `;
  }

  __renderStatusCodeCell(charge, index) {
    return this.claimStatusLineItems.length
      ? html`
          ${this.__renderNotificationsCell(charge, index)}
        `
      : '';
  }

  __renderRows() {
    return this.__getRows().map((charge, index) => {
      const shaded24Classes = {
        'shaded-24': true,
        shaded: true,
        'error-cell':
          this.errors && this.errors[index] && this.errors[index].shaded24,
      };

      const chargeCodeClasses = {
        code: true,
        'text-box': true,
        'error-cell':
          this.errors && this.errors[index] && this.errors[index].code,
      };

      return html`
        <div id="row-${index}" class="row-container ${
        this.claimStatusLineItems.length ? 'add-to-grid' : ''
      }" >
        ${this.__renderStatusCodeCell(charge, index)}

        <div class="${classMap(shaded24Classes)}">${this.__formatShaded24(
        charge,
      )}</div>
          <div class="family-plan-epsdt-cell">
            <div class="epsdt-cell cell shaded">${charge.EPSDT}</div>
            <div
              class="family-plan-cell cell ${
                charge.lineItemId ? 'selectable' : ''
              }"
              index="${index}"
              name="isFamilyPlanning"
              @click="${this.__handlers.click}"
            >
              ${
                charge.isFamilyPlanning ? 'Yes' : ''
              }${this.__renderExpandButton(charge.lineItemId)}
            </div>
          </div>
          <div class="provider-cell">
            ${this.__renderIdentifierCell({
              qualifier: charge.providerOtherIdQualifier,
              identifier: charge.providerOtherId,
              lineItemId: charge.lineItemId,
              index,
              charge,
              errors: this.errors,
            })}
            ${this.__renderNPICell({
              qualifier: 'NPI',
              identifier: charge.providerNPI,
              displayIdentifier:
                charge.providerNPI &&
                `${charge.providerNPI}` !== `${this.billingProviderNPI}`,
            })}
          </div>
          ${this.__renderDateOfServiceCell(
            charge.dateOfService,
            index,
            this.errors,
          )}

          ${this.__renderPlaceOfServiceCell(charge, index, this.errors)}
          <div
            class="emergency data-cell ${charge.lineItemId ? 'selectable' : ''}"
            index="${index}"
            name="isEmergency"
            @click="${this.__handlers.click}"
          >
            ${this.__renderExpandButton(charge.lineItemId)}
            <div>${charge.isEmergency ? 'Yes' : ''}</div>
          </div>
          <div class="two-data-cell span-column-7">
            <div class="${classMap(chargeCodeClasses)}">${charge.code}</div>
            ${this.__renderModifiers(charge, index, this.errors)}
          </div>
          ${this.__renderDiagnosisPointers(charge, index, this.errors)}
          ${this.__renderChargeAmount(charge, index, this.errors)}
          <div class="units data-cell"><div>${charge.units}</div></div>
        </div>
      </div>
      `;
    });
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderHeader()}${this.__renderRows()}
      </div>
    `;
  }
}

window.customElements.define('neb-claim-form-cell-fl-24', NebClaimFormCellFL24);
