import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
} from '../../../../../../../neb-styles/neb-variables';
import {
  getMatchingServiceTypes,
  isEmpty,
} from '../../../../../utils/insurance-util';

export const ELEMENTS = {
  coverageLevelText: {
    id: 'coverage-level-text',
  },
  inNetworkPercentText: {
    id: 'in-network-percent-text',
  },
  inNetworkPercentValue: {
    id: 'in-network-percent-value',
  },
  outOfNetworkPercentText: {
    id: 'out-of-network-percent-text',
  },
  outOfNetworkPercentValue: {
    id: 'out-of-network-percent-value',
  },
  serviceTypesText: {
    id: 'service-types-text',
  },
  noContentText: {
    id: 'no-content-text',
  },
  isDefaultIndicator: {
    id: 'is-default-indicator',
  },
};
const SERVICE_TYPES_HEADER = 'Service Types';
export const NO_CONTENT_TEXT = 'There are no coinsurances for this plan.';

class NebPatientInsuranceCoinsurancesSummary extends LitElement {
  static get properties() {
    return {
      layout: {
        reflect: true,
        type: String,
      },
      coinsurances: {
        type: Array,
      },
      allServiceTypes: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.coinsurances = [];
    this.allServiceTypes = [];
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .box-container {
          padding: 0 10px;
        }

        :host([layout='small']) .box-container {
          padding: 0;
        }

        .coinsurance-container {
          padding-top: ${CSS_SPACING};
        }

        .coinsurance-row {
          display: flex;
          justify-content: space-between;
        }

        .flex-one {
          flex: 1;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .pill {
          padding: 0px 10px;
          color: ${CSS_COLOR_WHITE};
          background: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 10px;
          border-radius: 10px;
        }

        .cst-title {
          display: flex;
        }

        .no-content {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: ${CSS_SPACING} 10px 0 0;
        }
      `,
    ];
  }

  __renderServiceTypes(serviceTypes, idx) {
    return html`
      <div class="bold">${SERVICE_TYPES_HEADER}</div>
      <div id="${ELEMENTS.serviceTypesText.id}_${idx}">
        ${getMatchingServiceTypes(serviceTypes, this.allServiceTypes)}
      </div>
    `;
  }

  __renderInNetworkPercent(coinsurance, idx) {
    if (isEmpty(coinsurance.inNetworkPercent)) return '';
    return html`
      <div id="${ELEMENTS.inNetworkPercentText.id}_${idx}">
        In Network %:
        <span id="${ELEMENTS.inNetworkPercentValue.id}_${idx}">
          ${coinsurance.inNetworkPercent}/${100 - coinsurance.inNetworkPercent}
        </span>
      </div>
    `;
  }

  __renderOutOfNetworkPercent(coinsurance, idx) {
    if (isEmpty(coinsurance.outOfNetworkPercent)) return '';
    return html`
      <div id="${ELEMENTS.outOfNetworkPercentText.id}_${idx}">
        Out of Network %:
        <span id="${ELEMENTS.outOfNetworkPercentValue.id}_${idx}">
          ${coinsurance.outOfNetworkPercent}/${
            100 - coinsurance.outOfNetworkPercent
          }
        </span>
      </div>
    `;
  }

  __renderDefaultIndicator(cst) {
    return cst.isDefault
      ? html`
          <div id="${ELEMENTS.isDefaultIndicator.id}" class="pill">Default</div>
        `
      : '';
  }

  __renderCoinsurances() {
    return this.coinsurances.map((coinsurance, idx) => {
      if (
        !coinsurance.coverageLevel ||
        !coinsurance.coverageLevel.code ||
        coinsurance.coverageLevel.code === 'NA'
      ) {
        return html`
          <div id="${ELEMENTS.noContentText.id}" class="no-content">
            ${NO_CONTENT_TEXT}
          </div>
        `;
      }
      return html`
        <div class="coinsurance-container">
          <div class="coinsurance-row">
            <div class="flex-one">
              <div
                class="bold cst-title"
                id="${ELEMENTS.coverageLevelText.id}_${idx}"
              >
                ${coinsurance.coverageLevel.description}
                ${this.__renderDefaultIndicator(coinsurance)}
              </div>
              ${this.__renderInNetworkPercent(coinsurance, idx)}
              ${this.__renderOutOfNetworkPercent(coinsurance, idx)}
            </div>

            <div class="flex-one">
              ${this.__renderServiceTypes(coinsurance.serviceTypes, idx)}
            </div>
          </div>
        </div>
      `;
    });
  }

  render() {
    return html`
      <div class="box-container">${this.__renderCoinsurances()}</div>
    `;
  }
}

customElements.define(
  'neb-patient-insurance-coinsurances-summary',
  NebPatientInsuranceCoinsurancesSummary,
);
