import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClientPDF = new ApiClient({
  microservice: 'pdf',
  useTenant: true,
});

function buildScheduleBody(scheduleInfo, location) {
  const { name, zipCode, tenantId } = location;

  return {
    scheduleInfo,
    practiceInformation: {
      ...location,
      id: tenantId,
      practiceName: name,
      locations: [{ ...location, zipcode: zipCode }],
    },
  };
}

export const printSchedule = async (scheduleInfo, location) => {
  const res = await apiClientPDF.makeRequest({
    path: 'schedule',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(buildScheduleBody(scheduleInfo, location)),
    version: 1,
  });
  return res.buffer;
};
