import { openPopup } from '@neb/popup';

import { getInvoicesFromEncounters } from '../../../packages/neb-api-client/src/encounters-api-client';
import {
  hidePropIfEncounterOnly,
  renderDateOrFullDateBasedOnEncounterOnly,
} from '../../../packages/neb-lit-components/src/utils/encounter-only-util';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { BILL_TYPE } from '../../../packages/neb-utils/neb-ledger-util';
import {
  formatAuthorizationLabel,
  hasAuthorizationRemaining,
} from '../../../packages/neb-utils/patientAuthorization';
import {
  WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_ENCOUNTER_LEVEL,
  SINGLE_INVOICE_ASSOCIATED_TO_ENCOUNTER_WARNING,
  MULTIPLE_ENCOUNTER_ASSOCIATE_WITH_CHARGES_ASSOCIATED_WARNING,
  MULTIPLE_ENCOUNTER_REMOVE_CASE_ASSOCIATE__WITH_CHARGES_ASSOCIATED_WARNING,
  MULTIPLE_ENCOUNTER_REMOVE_CASE_ASSOCIATE_WARNING,
} from '../../utils/user-message';

const mapBillTypeToView = billType => {
  switch (billType) {
    case BILL_TYPE.SELF:
      return 'Self Pay';
    case BILL_TYPE.INSURANCE:
      return 'Insurance';
    case BILL_TYPE.PACKAGE:
      return 'Care Package';
    default:
  }
  return '';
};

const getPlanName = model => {
  if (!model.encounterInfo || !model.encounterInfo.appointmentBillingInfo) {
    return '';
  }

  if (model.encounterInfo.appointmentBillingInfo.primaryInsurance) {
    return model.encounterInfo.appointmentBillingInfo.primaryInsurance.planName;
  }

  if (model.encounterInfo.appointmentBillingInfo.patientPackage) {
    return model.encounterInfo.appointmentBillingInfo.patientPackage.name;
  }

  return '';
};

export async function getWarningMessage(encounterIds) {
  const onlyOneEncounter = encounterIds.length === 1;
  const associatedInvoices = await getInvoicesFromEncounters(encounterIds);

  if (associatedInvoices && !associatedInvoices.length) return null;

  if (onlyOneEncounter) {
    if (associatedInvoices.length === 1) {
      const { invoiceNumber } = associatedInvoices[0];

      return SINGLE_INVOICE_ASSOCIATED_TO_ENCOUNTER_WARNING(invoiceNumber);
    }
    const associatedInvoiceNumbers = associatedInvoices.map(
      ({ invoiceNumber }) => invoiceNumber,
    );
    return WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_ENCOUNTER_LEVEL(
      associatedInvoiceNumbers,
    );
  }

  return MULTIPLE_ENCOUNTER_ASSOCIATE_WITH_CHARGES_ASSOCIATED_WARNING;
}

export async function getRemoveWarningMessage(encounterIds) {
  const associatedInvoices = await getInvoicesFromEncounters(encounterIds);

  if (associatedInvoices && associatedInvoices.length) {
    return MULTIPLE_ENCOUNTER_REMOVE_CASE_ASSOCIATE__WITH_CHARGES_ASSOCIATED_WARNING;
  }

  return MULTIPLE_ENCOUNTER_REMOVE_CASE_ASSOCIATE_WARNING;
}

export async function verifyAssociatedInvoices(
  encounterIds,
  removeCase = false,
) {
  const message = removeCase
    ? await getRemoveWarningMessage(encounterIds)
    : await getWarningMessage(encounterIds);

  if (!message) return true;

  const result = await openPopup(POPUP_RENDER_KEYS.DIALOG, {
    title: 'Warning',
    message,
    buttons: [{ name: 'save', label: 'YES' }, { name: 'discard', label: 'NO' }],
  });

  return result === 'save';
}

export function formatGradualDate(date, dateFormat = 'MM/DD/YYYY') {
  return date ? parseDate(date).format(dateFormat) : 'Gradual';
}

// eslint-disable-next-line complexity
export const mapModelToView = model => {
  const viewModel = {
    dateOfService: renderDateOrFullDateBasedOnEncounterOnly(model),
    caseName: model.case ? model.case.label : '',
    lastVisit:
      model.encounterInfo && model.encounterInfo.previousAppointment
        ? parseDate(model.encounterInfo.previousAppointment.start).format(
            'MM/DD/YYYY',
          )
        : '',
    billType:
      model.encounterInfo && model.encounterInfo.appointmentBillingInfo
        ? mapBillTypeToView(model.encounterInfo.appointmentBillingInfo.billType)
        : '',
    appointmentType: hidePropIfEncounterOnly(model, 'appointmentType', ' '),
    provider: model.provider,
    authorization: model.case?.patientAuthorizations
      ? formatAuthorizationLabel(
          model.case.patientAuthorizations.find(
            authorization => authorization.id === model.patientAuthorizationId,
          ),
        )
      : '',
    showAuthorizationWarning: !!(
      model.case?.patientAuthorizations &&
      !hasAuthorizationRemaining(
        model.case.patientAuthorizations.find(
          authorization => authorization.id === model.patientAuthorizationId,
        ),
      )
    ),
    planName: getPlanName(model),
  };
  return viewModel;
};
