import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/patients/neb-patient-card';
import '../controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import * as payerPlansApi from '../../../packages/neb-api-client/src/payer-plan-api-client';
import { CARDS } from '../../../packages/neb-lit-components/src/components/claims/neb-claims-worklist-cards';
import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { CLAIM_STATUS } from '../../../packages/neb-utils/claims';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { number } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { FetchService } from '../../../packages/neb-utils/services/fetch';
import { CSS_SPACING } from '../../styles';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters, ELEMENTS as BASE_ELEMENTS } from './neb-filters';

export const SUBMISSION_METHODS = [
  { label: 'Paper Claims', data: { id: 'Paper Claims' } },
  { label: 'Electronic Claims', data: { id: 'Electronic Claims' } },
];

const APPROVED_STATUS_ITEM = {
  label: CLAIM_STATUS.APPROVED,
  data: { id: CLAIM_STATUS.APPROVED },
};

export const ASSIGNED_TO = {
  CLINIC: {
    label: 'Clinic',
    data: { id: 'Clinic' },
  },
  RCM: {
    label: 'RCM',
    data: { id: 'RCM' },
  },
};

const ASSIGNED_TO_ITEMS = [ASSIGNED_TO.CLINIC, ASSIGNED_TO.RCM];

export const STATUS_CONFIG = {
  [CARDS.READY.name]: {
    statuses: [
      { label: CLAIM_STATUS.SUBMITTING, data: { id: CLAIM_STATUS.SUBMITTING } },
    ],
  },
  [CARDS.NEEDS_ATTENTION.name]: {
    statuses: [
      {
        label: CLAIM_STATUS.NEEDS_ATTENTION,
        data: { id: CLAIM_STATUS.NEEDS_ATTENTION },
      },
      { label: CLAIM_STATUS.ERROR, data: { id: CLAIM_STATUS.ERROR } },
      {
        label: CLAIM_STATUS.VALIDATION_ERROR,
        data: { id: CLAIM_STATUS.VALIDATION_ERROR },
      },
    ],
    statusCodes: [
      {
        label: 'P3 - Pending/Provider Requested Information',
        data: { id: 'P3' },
      },
      {
        label: 'P4 - Pending/Patient Requested Information',
        data: { id: 'P4' },
      },
      {
        label: 'R0 - Requests for additional Information/General Requests',
        data: { id: 'R0' },
      },
      {
        label: 'R1 - Requests for additional Information/Entity Requests',
        data: { id: 'R1' },
      },
      {
        label: 'R3 - Requests for additional Information/Claim/Line',
        data: { id: 'R3' },
      },
      {
        label: 'R4 - Requests for additional Information/Documentation',
        data: { id: 'R4' },
      },
      {
        label: 'R5 - Request for additional information/more specific detail',
        data: { id: 'R5' },
      },
      {
        label: 'R6 - Requests for additional information',
        data: { id: 'R6' },
      },
      {
        label: 'R7 - Requests for additional information',
        data: { id: 'R7' },
      },
      {
        label: 'R8 - Requests for additional information',
        data: { id: 'R8' },
      },
      {
        label: 'R9 - Requests for additional information',
        data: { id: 'R9' },
      },
      {
        label: 'R10 - Requests for additional information',
        data: { id: 'R10' },
      },
      {
        label: 'R11 - Requests for additional information',
        data: { id: 'R11' },
      },
      {
        label: 'R12 - Requests for additional information',
        data: { id: 'R12' },
      },
      {
        label: 'R13 - Requests for additional information',
        data: { id: 'R13' },
      },
      {
        label: 'R14 - Requests for additional information',
        data: { id: 'R14' },
      },
      {
        label: 'R15 - Requests for additional information',
        data: { id: 'R15' },
      },
      {
        label: 'R16 - Requests for additional information',
        data: { id: 'R16' },
      },
      {
        label: 'R17 - Replacement of a Prior Request',
        data: { id: 'R17' },
      },
      {
        label: 'A4 - Acknowledgement/Not Found',
        data: { id: 'A4' },
      },
      {
        label: 'DR04 - Acknowledgement/Not Found',
        data: { id: 'DR04' },
      },
      {
        label: 'E0 - Response not possible',
        data: { id: 'E0' },
      },
      {
        label: 'E1 - Response not possible',
        data: { id: 'E1' },
      },
      {
        label:
          'E2 - Information Holder is not responding; resubmit at a later time.',
        data: { id: 'E2' },
      },
      {
        label: 'E3 - Correction required',
        data: { id: 'E3' },
      },
      {
        label:
          'E4 - Trading partner agreement specific requirement not met: Data correction required.',
        data: { id: 'E4' },
      },
      {
        label: 'D0 - Data Search Unsuccessful',
        data: { id: 'D0' },
      },
    ],
  },
  [CARDS.SUBMITTED.name]: {
    statuses: [
      { label: CLAIM_STATUS.GENERATED, data: { id: CLAIM_STATUS.GENERATED } },
      { label: CLAIM_STATUS.PRINTED, data: { id: CLAIM_STATUS.PRINTED } },
      { label: CLAIM_STATUS.MAILED, data: { id: CLAIM_STATUS.MAILED } },
      {
        label: CLAIM_STATUS.TRANSMITTED,
        data: { id: CLAIM_STATUS.TRANSMITTED },
      },
      { label: CLAIM_STATUS.RECEIVED, data: { id: CLAIM_STATUS.RECEIVED } },
      { label: CLAIM_STATUS.PENDING, data: { id: CLAIM_STATUS.PENDING } },
      { label: CLAIM_STATUS.ACCEPTED, data: { id: CLAIM_STATUS.ACCEPTED } },
      {
        label: CLAIM_STATUS.UPLOADED_TO_CLEARINGHOUSE,
        data: { id: CLAIM_STATUS.UPLOADED_TO_CLEARINGHOUSE },
      },
      {
        label: CLAIM_STATUS.UPLOADING_TO_CLEARINGHOUSE,
        data: { id: CLAIM_STATUS.UPLOADING_TO_CLEARINGHOUSE },
      },
      { label: CLAIM_STATUS.DOWNLOADED, data: { id: CLAIM_STATUS.DOWNLOADED } },
      {
        label: CLAIM_STATUS.READY_TO_DOWNLOAD,
        data: { id: CLAIM_STATUS.READY_TO_DOWNLOAD },
      },
    ],
    statusCodes: [
      {
        label: 'A0 - Acknowledgement/Forwarded',
        data: { id: 'A0' },
      },
      {
        label: 'A1 - Acknowledgement/Receipt',
        data: { id: 'A1' },
      },
      {
        label: 'A2 - Acknowledgement/Acceptance into adjudication system',
        data: { id: 'A2' },
      },
      {
        label: 'A5 - Acknowledgement/Split Claim',
        data: { id: 'A5' },
      },
      {
        label: 'DR01 - Acknowledgement/Receipt',
        data: { id: 'DR01' },
      },
      {
        label:
          'DR02 - Acknowledgement/Acceptance into the data reporting/processing system',
        data: { id: 'DR02' },
      },
      {
        label: 'DR08 - Acknowledgement/Warning',
        data: { id: 'DR08' },
      },
      {
        label: 'P0 - Pending: Adjudication/Details',
        data: { id: 'P0' },
      },
      {
        label: 'P1 - Pending/In Process',
        data: { id: 'P1' },
      },
      {
        label: 'P2 - Pending/Payer Review',
        data: { id: 'P2' },
      },
      {
        label: 'P5 - Pending/Payer Administrative/System hold',
        data: { id: 'P5' },
      },
    ],
  },
  [CARDS.DENIED.name]: {
    statuses: [
      { label: CLAIM_STATUS.REJECTED, data: { id: CLAIM_STATUS.REJECTED } },
      { label: CLAIM_STATUS.DENIED, data: { id: CLAIM_STATUS.DENIED } },
      {
        label: CLAIM_STATUS.ERA_EOB_RECEIVED_DENIED,
        data: { id: CLAIM_STATUS.ERA_EOB_RECEIVED_DENIED },
      },
    ],

    statusCodes: [
      {
        label: 'F2 - Finalized/Denial',
        data: { id: 'F2' },
      },
      {
        label: 'F4 - Finalized/Adjudication Complete',
        data: { id: 'F4' },
      },
      {
        label: 'A3 - Acknowledgement/Returned as un-processable claim',
        data: { id: 'A3' },
      },
      {
        label: 'A6 - Acknowledgement/Rejected for Missing Information',
        data: { id: 'A6' },
      },
      {
        label: 'A7 - Acknowledgement/Rejected for Invalid Information',
        data: { id: 'A7' },
      },
      {
        label: 'A8 - Acknowledgement/Rejected for relational field in error',
        data: { id: 'A8' },
      },
      {
        label: 'DR03 - Acknowledgement/Returned as un-processable claim',
        data: { id: 'DR03' },
      },
      {
        label: 'DR05 - Acknowledgement/Rejected for Missing Information',
        data: { id: 'DR05' },
      },
      {
        label: 'DR06 - Acknowledgment/Rejected for invalid information',
        data: { id: 'DR06' },
      },
      {
        label: 'DR07 - Acknowledgement/Rejected for relational field in error',
        data: { id: 'DR07' },
      },
    ],
  },
  [CARDS.APPROVED.name]: {
    statuses: [
      APPROVED_STATUS_ITEM,
      {
        label: CLAIM_STATUS.ERA_RECEIVED,
        data: { id: CLAIM_STATUS.ERA_RECEIVED },
      },
      {
        label: CLAIM_STATUS.ERA_EOB_RECEIVED_APPROVED,
        data: { id: CLAIM_STATUS.ERA_EOB_RECEIVED_APPROVED },
      },
    ],
    statusCodes: [
      {
        label: 'F0 - Finalized',
        data: { id: 'F0' },
      },
      {
        label: 'F3 - Finalized/Revised',
        data: { id: 'F3' },
      },
      {
        label: 'F1 - Finalized/Payment',
        data: { id: 'F1' },
      },
      {
        label: 'F3F - Finalized/Forwarded',
        data: { id: 'F3F' },
      },
      {
        label: 'F3N - Finalized/Not Forwarded',
        data: { id: 'F3N' },
      },
    ],
  },
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  patientSearch: { id: 'search-patient' },
  dosRange: { id: 'range-date-of-service' },
  invoiceTextfield: { id: 'textfield-invoice' },
  claimTextfield: { id: 'textfield-claim' },
  submissionMethodsSelect: { id: 'select-submission-methods' },
  statusesSelect: { id: 'select-statuses' },
  statusCodesSelect: { id: 'select-status-codes' },
  payersSelect: { id: 'select-payers' },
  providersSelect: { id: 'select-providers' },
  locationsSelect: { id: 'select-locations' },
  amountRange: { id: 'range-amount' },
  balanceRange: { id: 'range-balance' },
  showHiddenCheckbox: { id: 'show-hidden-checkbox' },
  assignedSelect: { id: 'select-assigned' },
  completedCheckbox: { id: 'completed-checkbox' },
};

export class NebClaimsWorklistFilters extends NebFilters {
  static get properties() {
    return {
      __eclaimsStatusChangeEnabled: {
        type: Boolean,
      },
      __patientItems: Array,
      __payerPlansState: Object,
      enablePatient: {
        reflect: true,
        type: Boolean,
      },
      status: String,
      providers: Array,
      userLocations: Array,
      defaultLocationId: String,
      hasRcmRelease2FF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(4, 1fr);
        }

        .checkbox-container {
          display: flex;
          align-items: center;
        }

        .checkbox-group-container,
        .checkbox-group-container-patient {
          align-items: center;
          display: flex;
          gap: ${CSS_SPACING};
        }

        .checkbox-group-container-patient {
          margin-bottom: 10px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      patientId: '',
      dos: NebDateRange.createModel(),
      invoice: '',
      claim: '',
      submissionMethods: [],
      statuses: [],
      statusCodes: [],
      payerIds: [],
      locationIds: [],
      providerIds: [],
      amount: NebCurrencyRange.createModel(),
      balance: NebCurrencyRange.createModel(),
      showHidden: false,
      assignedTo: [],
      completed: false,
    };
  }

  initState() {
    super.initState();

    this.__eclaimsStatusChangeEnabled = false;
    this.__patientItems = [];
    this.__payerPlansState = FetchService.createModel();

    this.enablePatient = false;
    this.status = '';
    this.providers = [];
    this.userLocations = [];
    this.defaultLocationId = '';
    this.hasRcmRelease2FF = false;

    this.onSelect = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      searchPatients: e => this.__patientsService.search(e.value),
      requestMorePatients: () => this.__patientsService.fetchMore(),
      requestMorePayers: () => this.__payerPlansService.fetchMore(),
      clearInvoiceSearch: () => this.formService.apply('invoice', ''),
      clearClaimSearch: () => this.formService.apply('claim', ''),
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      changePatient: e => {
        if (e.value) {
          return this.__formService.apply(e.name, e.value);
        }

        return this.__formService.apply(e.name, selectors.ITEM_EMPTY);
      },
      changePatients: state => {
        this.__patientItems = state.pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      apply: e => {
        const isButtonPress = !!e;
        const model = this.formService.build();
        const { from, to } = model.dos;

        this.onApply(
          {
            ...model,
            dos: {
              from: from ? parseDate(from._i) : null,
              to: to ? parseDate(to._i) : null,
            },
          },
          isButtonPress,
        );
      },
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select([]),
        submissionMethods: selectors.multiSelect([]),
        statuses: selectors.multiSelect([]),
        statusCodes: selectors.multiSelect([]),
        payerIds: selectors.multiSelect([]),
        locationIds: selectors.multiSelect([]),
        providerIds: selectors.multiSelect([]),
        dos: NebDateRange.createSelectors(),
        amount: NebCurrencyRange.createSelectors(),
        balance: NebCurrencyRange.createSelectors(),
        assignedTo: selectors.multiSelect(
          ASSIGNED_TO_ITEMS,
          [ASSIGNED_TO.CLINIC],
          {},
          true,
        ),
      },
    };
  }

  getProviderItems() {
    const providers = this.providers.map(data => ({
      label: objToName(data.name, DEFAULT_NAME_OPTS),
      data,
    }));

    return this.status === CARDS.NEEDS_ATTENTION.name
      ? providers.concat({
          label: 'No Billing Provider',
          data: { id: '00000000-0000-0000-0000-000000000000' },
        })
      : providers;
  }

  async load() {
    if (this.enablePatient) {
      this.__patientsService = createServerPatientsCollection({
        onChange: this.handlers.changePatients,
        alwaysShowInactive: true,
      });

      await this.__patientsService.fetchComplete();
    }

    this.__payerPlansService = new FetchService(
      {
        onChange: state => {
          this.__payerPlansState = state;
        },
        onMapItem: data => ({ label: data.alias, data }),
      },
      query => payerPlansApi.fetchMany(query),
    );

    if (this.__shouldUseAdditionalFilters()) {
      this.formService.apply('assignedTo', [ASSIGNED_TO.CLINIC]);
    }

    this.__payerPlansService.fetch();
  }

  firstUpdated() {
    if (this.status === 'approved') {
      this.handlers.change({ name: 'statuses', value: [APPROVED_STATUS_ITEM] });
    }
    super.firstUpdated();
  }

  update(changedProps) {
    if (
      (changedProps.has('userLocations') ||
        changedProps.has('defaultLocationId')) &&
      (this.userLocations && this.defaultLocationId)
    ) {
      this.__getDefaultLocation();
    }

    super.update(changedProps);
  }

  __shouldUseAdditionalFilters() {
    return this.hasRcmRelease2FF && this.status === CARDS.NEEDS_ATTENTION.name;
  }

  __getDefaultLocation() {
    const location = this.userLocations.find(
      l => l.data.id === this.defaultLocationId,
    );

    if (location) {
      this.handlers.change({
        name: 'locationIds',
        value: [location],
      });
    }

    this.handlers.apply();
  }

  __getCheckboxStyle() {
    if (this.__shouldUseAdditionalFilters()) {
      return this.enablePatient
        ? 'checkbox-group-container-patient'
        : 'checkbox-group-container';
    }

    return 'checkbox-container';
  }

  renderCurrencyFilter() {
    return this.status === CARDS.APPROVED.name
      ? html`
          <neb-range-currency
            id="${ELEMENTS.balanceRange.id}"
            name="balance"
            label="Balance"
            .model="${this.state.balance}"
            .onChange="${this.handlers.change}"
            .minGap="${true}"
          ></neb-range-currency>
        `
      : html`
          <neb-range-currency
            id="${ELEMENTS.amountRange.id}"
            name="amount"
            label="Amount"
            .model="${this.state.amount}"
            .onChange="${this.handlers.change}"
            .minGap="${true}"
          ></neb-range-currency>
        `;
  }

  renderPatientFilter() {
    return this.enablePatient
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSearch.id}"
            name="patientId"
            label="Patient"
            helper=" "
            itemHeight="80"
            .items="${this.__patientItems}"
            .value="${this.state.patientId}"
            .onChange="${this.handlers.changePatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRequest="${this.handlers.requestMorePatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            showSearch
            forceDown
          ></neb-select-search>
        `
      : '';
  }

  renderLocationsFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.locationsSelect.id}"
        name="locationIds"
        label="Locations"
        helper=" "
        .value="${this.state.locationIds}"
        .items="${this.userLocations}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  renderCheckboxes() {
    return html`
      <div class="${this.__getCheckboxStyle()}">
        ${this.__renderCompletedCheckbox()}
        <neb-checkbox
          id="${ELEMENTS.showHiddenCheckbox.id}"
          name="showHidden"
          label="Show Hidden Items"
          ?checked="${this.state.showHidden}"
          value="${this.state.showHidden}"
          .onChange="${this.handlers.change}"
        ></neb-checkbox>
      </div>
    `;
  }

  __renderCompletedCheckbox() {
    return this.__shouldUseAdditionalFilters() && this.hasCollapsedFilters
      ? html`
          <neb-checkbox
            id="${ELEMENTS.completedCheckbox.id}"
            name="completed"
            label="Completed"
            ?checked="${this.state.completed}"
            value="${this.state.completed}"
            .onChange="${this.handlers.change}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderAssignedFilter() {
    return this.__shouldUseAdditionalFilters()
      ? html`
          <neb-select
            id="${ELEMENTS.assignedSelect.id}"
            name="assignedTo"
            label="Assigned To"
            helper=" "
            .value="${this.state.assignedTo}"
            .items="${ASSIGNED_TO_ITEMS}"
            .onChange="${this.handlers.change}"
            multiSelect
          ></neb-select>
        `
      : '';
  }

  __renderInvoiceFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.invoiceTextfield.id}"
        name="invoice"
        label="Invoice #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.invoice ? 'neb:clear' : ''}"
        .value="${this.state.invoice}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearInvoiceSearch}"
      ></neb-textfield>
    `;
  }

  __renderDosFilter() {
    const date = {
      from: this.state.dos.from ? parseDate(this.state.dos.from) : null,
      to: this.state.dos.to ? parseDate(this.state.dos.to) : null,
    };

    return html`
      <neb-range-date
        id="${ELEMENTS.dosRange.id}"
        name="dos"
        label="Date"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
        .minGap="${true}"
      ></neb-range-date>
    `;
  }

  __renderClaimFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.claimTextfield.id}"
        name="claim"
        label="Claim #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.claim ? 'neb:clear' : ''}"
        .value="${this.state.claim}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearClaimSearch}"
      ></neb-textfield>
    `;
  }

  __renderSubmissionMethodFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.submissionMethodsSelect.id}"
        name="submissionMethods"
        label="Submission Method"
        helper=" "
        .value="${this.state.submissionMethods}"
        .items="${SUBMISSION_METHODS}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderStatusesFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.statusesSelect.id}"
        name="statuses"
        label="Status"
        helper=" "
        .value="${this.state.statuses}"
        .items="${STATUS_CONFIG[this.status].statuses}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderStatusCodeFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.statusCodesSelect.id}"
        name="statusCodes"
        label="Status Codes"
        helper=" "
        .value="${this.state.statusCodes}"
        .items="${STATUS_CONFIG[this.status].statusCodes}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderPayerFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.payersSelect.id}"
        name="payerIds"
        label="Payers"
        helper=" "
        .value="${this.state.payerIds}"
        .items="${this.__payerPlansState.pageItems}"
        .onChange="${this.handlers.change}"
        .onRequest="${this.handlers.requestMorePayers}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderProviderFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.providersSelect.id}"
        name="providerIds"
        label="Providers"
        helper=" "
        .value="${this.state.providerIds}"
        .items="${this.getProviderItems()}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderFullFilters() {
    return html`
      ${this.renderPatientFilter()} ${this.__renderInvoiceFilter()}
      ${this.__renderDosFilter()} ${this.__renderClaimFilter()}
      ${this.__renderSubmissionMethodFilter()} ${this.__renderStatusesFilter()}
      ${this.__renderStatusCodeFilter()} ${this.__renderPayerFilter()}
      ${this.__renderProviderFilter()} ${this.renderCurrencyFilter()}
      ${this.renderLocationsFilter()} ${this.renderCheckboxes()}
    `;
  }

  renderCollapsedContent() {
    return html`
      ${this.__renderDosFilter()} ${this.__renderProviderFilter()}
      ${this.__renderPayerFilter()} ${this.__renderStatusesFilter()}
    `;
  }

  renderContent() {
    return this.hasCollapsedFilters
      ? html`
          ${this.__renderStatusCodeFilter()}
          ${this.__renderSubmissionMethodFilter()} ${this.renderPatientFilter()}
          ${this.__renderInvoiceFilter()} ${this.__renderClaimFilter()}
          ${this.renderCurrencyFilter()} ${this.renderLocationsFilter()}
          ${this.__renderAssignedFilter()} ${this.renderCheckboxes()}
        `
      : this.__renderFullFilters();
  }
}

window.customElements.define(
  'neb-filters-claims-worklist',
  NebClaimsWorklistFilters,
);
