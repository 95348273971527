import '../../../../../src/components/misc/neb-icon';
import '../neb-text';
import './neb-table-ledger-charge-expanded';

import { css, html } from 'lit';

import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { MODE } from '../../../../neb-utils/table';
import { BUTTON_ROLE } from '../neb-button';

import NebTable from './neb-table';

export const ELEMENTS = {
  matchButtons: {
    selector: '[id^=match-button-]',
  },
  row: {
    selector: '[id^=row-]',
  },
};

class NebTableOnlineBookingLink extends NebTable {
  static get properties() {
    return {
      renderMatchColumn: Boolean,
    };
  }

  initState() {
    super.initState();
    this.renderMatchColumn = true;

    this.onMatch = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      matchButtonClicked: index => this.onMatch(this.model[index]),
      clickRow: e => {
        if (this.mode === MODE.DETAIL) {
          const index = Number(e.currentTarget.getAttribute('row'));
          this.onMatch(this.model[index]);
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .button {
          padding-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'phoneNumbers':
      case 'emailAddresses':
        return this.__renderItems(value);

      case 'matches':
        return this.__renderMatch(value);
      case 'matchButton':
        return this.__renderMatchButton(rowIndex);
      default:
        return value;
    }
  }

  renderHeaderCell({ key, label }) {
    if (key === 'matches') {
      return this.renderMatchColumn ? label : '';
    }
    return label;
  }

  __renderItems(items) {
    return items.map(
      item =>
        html`
          ${item}
        `,
    );
  }

  __renderMatchButton(rowIndex) {
    return this.renderMatchColumn
      ? html`
          <neb-button
            id="match-button-${rowIndex}"
            class="button"
            role="${BUTTON_ROLE.CONFIRM}"
            label="Match"
            .onClick="${() => this.handlers.matchButtonClicked(rowIndex)}"
          ></neb-button>
        `
      : '';
  }

  __renderMatch(matchStength) {
    return this.renderMatchColumn ? matchStength : '';
  }
}

customElements.define(
  'neb-table-online-booking-link',
  NebTableOnlineBookingLink,
);
