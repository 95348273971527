import '../neb-text';

import { css, html } from 'lit';
import moment from 'moment-timezone';

import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../../../src/utils/locations/location-util';
import { NO_ITEMS_INITIAL_LOAD } from '../../../../../src/utils/user-message';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_CAPTION,
  CSS_COLOR_GREY_4,
} from '../../../../neb-styles/neb-variables';
import { TRANSACTION_TYPE } from '../../../../neb-utils/enums';
import { centsToCurrency, objToName } from '../../../../neb-utils/formatters';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  encounterNumberLink: { id: 'encounter-number-link' },
  invoiceNumber: { id: 'invoice-number' },
  payer: { id: 'payer' },
  detailChargeId: { id: 'detail-charge-Id' },
  chargeStatus: { id: 'charge-status' },
  patientLink: { id: 'patient-link' },
  noPatientText: { id: 'no-patient-text' },
  locations: { selector: '[id^=detail-location-]' },
};

export const NO_ITEMS_TEXT_PATIENT =
  'There are no transactions for this patient.';
export const NO_ITEMS_TEXT_PRACTICE = 'There are no transactions.';

const STATUS = {
  billed: 'Billed',
  hold: 'Hold',
};

const TABLE_CONFIG = [
  {
    key: 'patient',
    label: 'Patient',
    flex: css`5 0 0`,
    formatter: p =>
      p
        ? objToName(p.name, {
            reverse: true,
            middleInitial: true,
            preferred: true,
          })
        : '-',
  },
  {
    key: 'transactionDate',
    label: 'Date',
    flex: css`3 0 0`,
    formatter: v => moment(v).format('MM/DD/YYYY'),
  },
  {
    key: 'name',
    label: 'Type',
    flex: css`5 0 0`,
    truncate: true,
  },
  {
    key: 'encounterNumber',
    label: 'Encounter',
    flex: css`3 0 0`,
  },
  {
    key: 'payer',
    label: 'Payer',
    flex: css`4 0 0`,
    truncate: true,
  },
  {
    key: 'invoiceNumber',
    label: 'Invoice',
    flex: css`2 0 0`,
  },
  {
    key: 'amount',
    label: 'Amount',
    flex: css`2 0 0`,
    formatter: v => centsToCurrency(v),
  },
];

class NebTableLedgerActivityTransactions extends NebTable {
  static get properties() {
    return {
      includePatient: Boolean,
      locations: Array,
      isInitialLoadDisabled: Boolean,
    };
  }

  initState() {
    super.initState();
    this.config = TABLE_CONFIG;
    this.locations = [];

    this.onLinkClick = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      linkClick: (rowIndex, column) => this.onLinkClick(rowIndex, column),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
        }

        .bold {
          font-weight: bold;
        }

        .cell-transaction-detail {
          flex-direction: column;
          padding-bottom: 18px;
          word-break: break-all;
        }

        .caption-text {
          font-size: ${CSS_FONT_SIZE_CAPTION};
          word-break: break-word;
        }

        .serviceCell,
        .chargeCell {
          display: flex;
          flex-direction: row;
        }

        .serviceData {
          flex: 3;
        }

        .chargeData {
          flex: 1 0 56px;
        }

        .expanded {
          align-items: baseline;
        }

        .row-data[expanded] {
          background-color: ${CSS_COLOR_GREY_4};
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: block;
          align-items: center;
          cursor: pointer;
        }

        .button-link-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
        }
      `,
    ];
  }

  update(changed) {
    this.config = TABLE_CONFIG.filter(
      elm => elm.key !== 'patient' || this.includePatient,
    );

    this.emptyMessage = this.includePatient
      ? NO_ITEMS_TEXT_PRACTICE
      : NO_ITEMS_TEXT_PATIENT;

    if (this.isInitialLoadDisabled) {
      this.emptyMessage = NO_ITEMS_INITIAL_LOAD;
    }

    super.update(changed);
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'patient':
        return value === '-'
          ? html`
              <div class="text" id="${ELEMENTS.noPatientText.id}-${rowIndex}">
                ${value}
              </div>
            `
          : html`
              <div
                id="${ELEMENTS.patientLink.id}-${rowIndex}"
                class="button"
                @click="${() => this.handlers.linkClick(rowIndex, 'patient')}"
              >
                <span class="button-link-text">${value}</span
                ><neb-icon class="icon" icon="neb:open"></neb-icon>
              </div>
            `;
      case 'encounterNumber':
        if (!value) return '-';

        return html`
          <neb-text
            id="${ELEMENTS.encounterNumberLink.id}-${rowIndex}"
            bold
            link
            .onClick="${() => this.handlers.linkClick(rowIndex, 'encounter')}"
          >
            ${value}
          </neb-text>
        `;
      case 'invoiceNumber':
        if (!value) return '-';

        return html`
          <neb-text
            id="${ELEMENTS.invoiceNumber.id}-${rowIndex}"
            bold
            link
            .onClick="${() => this.handlers.linkClick(rowIndex, 'invoice')}"
          >
            ${value}
          </neb-text>
        `;
      case 'payer':
        if (!value) return '-';

        return this.model[rowIndex].primaryPayerId
          ? html`
              <neb-text
                id="${ELEMENTS.payer.id}-${rowIndex}"
                bold
                link
                .onClick="${() => this.handlers.linkClick(rowIndex, 'payer')}"
                truncate
              >
                ${value}
              </neb-text>
            `
          : html`
              <div><neb-text truncate>${value}</neb-text></div>
            `;
      case 'amount':
        const row = this.model[rowIndex];
        const { type } = row;

        let displayValue;

        const isValueNegative = value.charAt(1) === '-';

        if (
          (type === TRANSACTION_TYPE.PAYMENT ||
            type === TRANSACTION_TYPE.ADJUSTMENT) &&
          !isValueNegative
        ) {
          displayValue = `(${value})`;
        } else if (
          (type === TRANSACTION_TYPE.PAYMENT ||
            type === TRANSACTION_TYPE.ADJUSTMENT) &&
          isValueNegative
        ) {
          displayValue = `${value.slice(0, 1) + value.slice(2)}`;
        } else {
          displayValue = `${value}`;
        }

        return html`
          <div class="bold">${displayValue}</div>
        `;
      default:
        if (!value) return '-';

        return value;
    }
  }

  __renderPackageName(index, packageName) {
    return html`
      <div class="bold caption-text">Care Package</div>
      <div id="detail-packageName-${index}" class="caption-text">
        ${packageName || '-'}
      </div>
    `;
  }

  __renderPaymentMethod(index, paymentMethod) {
    return html`
      <div class="bold caption-text">Payment Method</div>
      <div id="detail-paymentMethod-${index}" class="caption-text">
        ${paymentMethod || '-'}
      </div>
    `;
  }

  __renderReferenceId(index, referenceId) {
    return html`
      <div class="bold caption-text">Reference ID</div>
      <div id="detail-referenceId-${index}" class="caption-text">
        ${referenceId || '-'}
      </div>
    `;
  }

  __renderChargeCell(index, row) {
    return html`
      <div class="chargeCell">
        <div class="chargeData">${this.__renderChargeNumber(index, row)}</div>
        <div class="chargeData">
          ${this.__renderChargeStatus(index, row, STATUS.billed)}
        </div>
        <div class="chargeData">
          ${this.__renderChargeStatus(index, row, STATUS.hold)}
        </div>
      </div>
    `;
  }

  __renderChargeNumber(index, row) {
    const { chargeNumber, voidedAt } = row;

    if (chargeNumber && !voidedAt) {
      return html`
          <div class="bold caption-text">Charge</div>
          <neb-text
            id="${ELEMENTS.detailChargeId.id}-${index}"
            caption
            bold
            link
            .onClick="${() => this.handlers.linkClick(index, 'chargeId')}"
          >
            ${chargeNumber}
          </div>
        `;
    }

    return html`
      <div class="bold caption-text">Charge</div>
      <div id="${ELEMENTS.detailChargeId.id}-${index}" class="caption-text">
        ${chargeNumber || '-'}
      </div>
    `;
  }

  __renderDOSFromTo(index, fromDate, toDate) {
    return html`
      <div class="bold caption-text">DOS From - DOS To</div>
      <div id="detail-dosFromTo-${index}" class="caption-text">
        ${fromDate ? moment(fromDate).format('MM/DD/YYYY') : '-'} -
        ${toDate ? moment(toDate).format('MM/DD/YYYY') : '-'}
      </div>
    `;
  }

  __renderServiceDate(index, row) {
    return html`
      <div class="serviceCell">
        <div class="serviceData">
          <div class="bold caption-text">Service Date</div>
          <div id="detail-serviceDate-${index}" class="caption-text">
            ${
              row.serviceDate
                ? moment(row.serviceDate).format('MM/DD/YYYY')
                : '-'
            }
          </div>
        </div>
        ${
          row.locationId
            ? html`
                <div style="flex: ${this.config[2].flex}">
                  ${this.__renderLocation(index, row)}
                </div>
              `
            : ''
        }
      </dix>
    `;
  }

  __renderPackageOrPaymentMethod(index, row) {
    if (
      row.type === TRANSACTION_TYPE.PAYMENT ||
      row.type === TRANSACTION_TYPE.REFUND_PAYMENT
    ) {
      return this.__renderPaymentMethod(index, row.paymentMethod);
    }

    if (row.packageName && row.packageName.length > 0) {
      return this.__renderPackageName(index, row.packageName);
    }

    return '';
  }

  __renderLocation(index, row) {
    if (row.type === TRANSACTION_TYPE.LINE_ITEM) {
      return html`
        <div class="bold caption-text">Location</div>
        <div id="detail-location-${index}" class="caption-text">
          ${
            getLocationValue(this.locations, row.locationId, LOCATION_KEYS.NAME)
          }
        </div>
      `;
    }
    return '';
  }

  __renderReferenceIdOrServiceDate(index, row) {
    if (row.type === TRANSACTION_TYPE.PAYMENT) {
      if (row.referenceId) {
        return this.__renderReferenceId(index, row.referenceId);
      }
    }

    if (
      row.type === TRANSACTION_TYPE.LINE_ITEM ||
      row.type === TRANSACTION_TYPE.ADJUSTMENT
    ) {
      return this.__renderServiceDate(index, row);
    }

    return '';
  }

  __renderChargeOrDOSFrom(index, row) {
    if (
      row.type === TRANSACTION_TYPE.LINE_ITEM ||
      row.type === TRANSACTION_TYPE.ADJUSTMENT
    ) {
      return this.__renderChargeCell(index, row);
    }

    if (row.type === TRANSACTION_TYPE.PAYMENT) {
      if (row.dateOfServiceFrom && row.dateOfServiceTo) {
        return this.__renderDOSFromTo(
          index,
          row.dateOfServiceFrom,
          row.dateOfServiceTo,
        );
      }
    }

    return '';
  }

  __renderChargeStatus(index, row, key) {
    if (
      row.type === TRANSACTION_TYPE.LINE_ITEM &&
      row.name === 'Encounter Charge'
    ) {
      return html`
        <div class="bold caption-text">${key}</div>
        <neb-text id="${ELEMENTS.chargeStatus.id}-${key}-${index}" caption>
          ${key === 'Billed' ? row.billed : row.hold}
        </neb-text>
      `;
    }
    return '';
  }

  __renderTransactionDescription(index, row) {
    return row.transactionDescription && row.transactionDescription.length > 0
      ? html`
          <div class="bold caption-text">Transaction Description</div>
          <div id="detail-transactionDescription-${index}" class="caption-text">
            ${row.transactionDescription}
          </div>
        `
      : '';
  }

  __renderPostedBy(index, row) {
    if (
      row.type === TRANSACTION_TYPE.PAYMENT ||
      row.type === TRANSACTION_TYPE.VOID_PAYMENT ||
      row.type === TRANSACTION_TYPE.REFUND_PAYMENT
    ) {
      return html`
        <div class="bold caption-text">Posted By:</div>
        <div id="detail-postedBy-${index}" class="caption-text">
          ${row.postedBy || '-'}
        </div>
      `;
    }

    if (row.type === TRANSACTION_TYPE.LINE_ITEM && row.voidedAt) {
      return html`
        <div class="bold caption-text">Voided By:</div>
        <div id="detail-voidedBy-${index}" class="caption-text">
          ${row.voidedByName || '-'}
        </div>
      `;
    }

    return '';
  }

  __renderVoidedDate(index, row) {
    if (row.type === TRANSACTION_TYPE.LINE_ITEM && row.voidedAt) {
      return html`
        <div class="bold caption-text">Date Voided</div>
        <div id="detail-voidedAt-${index}" class="caption-text">
          ${moment(row.voidedAt).format('MM/DD/YYYY')}
        </div>
      `;
    }

    return '';
  }

  renderExpandedRowPatient(index, row) {
    return html`
      <div class="content expanded">
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[0].flex}"
        >
          ${this.__renderPackageOrPaymentMethod(index, row)}
        </div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[1].flex}"
        >
          ${this.__renderReferenceIdOrServiceDate(index, row)}
        </div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[2].flex}"
        >
          ${this.__renderChargeOrDOSFrom(index, row)}
        </div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[3].flex}"
        >
          ${this.__renderTransactionDescription(index, row)}
        </div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[4].flex}"
        >
          ${this.__renderPostedBy(index, row)}
        </div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[5].flex}"
        >
          ${this.__renderVoidedDate(index, row)}
        </div>
        <div class="cell cell-expand"></div>
      </div>
    `;
  }

  renderExpandedRowPractice(index, row) {
    return html`
      <div class="content expanded">
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[0].flex}"
        ></div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[1].flex}"
        >
          ${this.__renderPackageOrPaymentMethod(index, row)}
        </div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[2].flex}"
        >
          ${this.__renderReferenceIdOrServiceDate(index, row)}
        </div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[3].flex}"
        >
          ${this.__renderChargeOrDOSFrom(index, row)}
        </div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[4].flex}"
        >
          ${this.__renderTransactionDescription(index, row)}
        </div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[5].flex}"
        >
          ${this.__renderPostedBy(index, row)}
        </div>
        <div
          class="cell cell-transaction-detail"
          style="flex: ${this.config[6].flex}"
        >
          ${this.__renderVoidedDate(index, row)}
        </div>
        <div class="cell cell-expand"></div>
      </div>
    `;
  }

  renderExpandedRow(index, row) {
    return this.includePatient === false
      ? this.renderExpandedRowPatient(index, row)
      : this.renderExpandedRowPractice(index, row);
  }
}

customElements.define(
  'neb-table-ledger-activity-transactions',
  NebTableLedgerActivityTransactions,
);
