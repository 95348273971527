import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

const BASE_URL = 'service-types';

export function getServiceTypes(queryParams = {}) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: BASE_URL,
    queryParams,
  });
}
