import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { html, css } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import * as hotButtonApi from '../../../api-clients/hot-buttons';
import { mapCategoryItemsToButtonModel } from '../../../utils/hot-buttons';
import {
  HOT_BUTTONS_ERROR_MESSAGE,
  HOT_BUTTONS_SUCCESS_MESSAGE,
} from '../../../utils/user-message';
import { FormHotButtons } from '../../forms/settings/neb-form-hot-buttons';

export const ELEMENTS = {
  form: {
    id: 'form',
  },
  header: {
    id: 'header',
  },
};

class OverlayHotButtons extends Overlay {
  static get properties() {
    return {
      __formModel: Object,
    };
  }

  initState() {
    super.initState();
    this.__formModel = FormHotButtons.createModel();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: async formModel => {
        this.isDirty = false;

        try {
          let result;

          if (this.model.id) {
            result = await hotButtonApi.updateCategory(
              this.model.id,
              formModel,
            );
          } else {
            result = await hotButtonApi.createCategory(
              this.model.providerId,
              formModel,
            );
          }

          this.dismiss(result);

          store.dispatch(openSuccess(HOT_BUTTONS_SUCCESS_MESSAGE));
        } catch (e) {
          console.error(e);

          this.dismiss();

          store.dispatch(openError(HOT_BUTTONS_ERROR_MESSAGE));
        }
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      if (this.model.id) {
        this.__formModel = {
          ...FormHotButtons.createModel(),
          type: this.model.categoryType,
          name: this.model.name,
          active: this.model.active,
          indexOrder: this.model.indexOrder,
          buttons: mapCategoryItemsToButtonModel(
            this.model.diagnoses || this.model.charges,
            this.model.categoryType,
          ),
        };
      } else {
        this.__formModel = {
          type: this.model.type,
          ...FormHotButtons.createModel(),
        };
      }
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }

        .spacing {
          margin: ${CSS_SPACING} ${CSS_SPACING} 10px;
        }
      `,
    ];
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="spacing"
        showCancelButton
        title="Hot Buttons"
        .onCancel="${this.handlers.dismiss}"
      ></neb-popup-header>
    `;
  }

  __renderForm() {
    return html`
      <neb-form-hot-buttons
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-hot-buttons>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderForm()}
    `;
  }
}

customElements.define('neb-overlay-hot-buttons', OverlayHotButtons);
