import '../neb-toggle-button';

import { css, html } from 'lit';

import NebTable from './neb-table';

export const ELEMENTS = {
  addButton: {
    selector: '[id^=add-button-]',
  },
  row: {
    selector: '[id^=row-]',
  },
};

const CONFIG = [
  {
    key: 'selected',
    label: '',
    flex: css`0 0 300px`,
  },
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`0 0 120px`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
  },
  {
    key: 'modifiers',
    label: 'Modifiers',
    flex: css`0 0 120px`,
    formatter: value => (value ? value.filter(m => m).join(', ') : ''),
  },
];

const MOBILE_CONFIG = [
  {
    key: 'selected',
    label: '',
    flex: css`0 0 50px`,
    mobile: true,
  },
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`1 1 110px`,
    mobile: true,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 1 110px`,
    mobile: true,
  },
  {
    key: 'modifiers',
    label: 'Mods',
    flex: css`0 0 40px`,
    mobile: true,
    formatter: value => (value ? value.filter(m => m).join('\n') : ''),
  },
];

class NebTableAuthorizedProcedureSelect extends NebTable {
  static get properties() {
    return {
      selectedItems: Array,
    };
  }

  initState() {
    super.initState();

    this.selectedItems = [];

    this.onAddCharge = () => {};
  }

  updated(changedProps) {
    if (changedProps.has('layout')) {
      this.config = this.layout === 'small' ? MOBILE_CONFIG : CONFIG;
    }

    super.updated(changedProps);
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      add: (_, rowIndex) => this.onAddCharge(rowIndex),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .add-button {
          width: 280px;
          padding: 0;
          white-space: nowrap;
        }

        :host([layout='small']) .add-button {
          width: 30px;
          padding: 0;
          white-space: nowrap;
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'selected': {
        const isAdded = !!this.selectedItems.find(
          e => e.id === this.model[rowIndex].id,
        );

        return html`
          <neb-toggle-button
            id="add-button-${rowIndex}"
            class="add-button"
            .index="${rowIndex}"
            .layout="${this.layout}"
            .isToggled="${isAdded}"
            .onClick="${this.handlers.add}"
            .buttonText="${
              isAdded
                ? 'Added to Authorized Procedures'
                : 'Add to Authorized Procedures'
            }"
          ></neb-toggle-button>
        `;
      }
      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-authorized-procedure-select',
  NebTableAuthorizedProcedureSelect,
);
