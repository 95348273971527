import '../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { getSettingsPermissions } from '../../../../packages/neb-api-client/src/permissions-api-client';
import { fetchPracticeInformation } from '../../../../packages/neb-api-client/src/practice-information';
import {
  openSuccess,
  openError,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import {
  getLocationInactivation,
  createLocation,
  editLocation,
} from '../../../api-clients/locations';
import {
  LOCATION_SAVED_SUCCESS,
  LOCATION_SAVED_ERROR,
  POPUP_MESSAGE_CONFIRM_INACTIVATE_LOCATION,
  POPUP_MESSAGE_UNABLE_TO_INACTIVATE_ONLY_SERVICE_LOCATION,
} from '../../../utils/user-message';
import { NebFormPracticeLocation } from '../../forms/settings/neb-form-practice-location';

import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

const ONE_ACTIVE_SERVICE_LOCATION_REQUIRED = {
  title: 'Unable to Inactivate',
  confirmText: 'Ok',
  message: POPUP_MESSAGE_UNABLE_TO_INACTIVATE_ONLY_SERVICE_LOCATION(),
};

const CONFIRM_INACTIVATE_LOCATION = {
  title: 'Inactivate Location',
  message: POPUP_MESSAGE_CONFIRM_INACTIVATE_LOCATION(),
  confirmText: 'YES',
  cancelText: 'NO',
};

export const LOCATION_IN_USE_GROUPS = Object.freeze({
  billingInfo: 'Billing Information',
  locations: 'Locations',
  payerPlans: 'Payer Plans',
});

class NebOverlayPracticeLocation extends Overlay {
  static get properties() {
    return {
      __practiceLocation: Object,
      __locations: Array,
      __savingError: Object,
      __hasSettingsPermissions: { type: Boolean },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 880px;
        }

        .container {
          display: flex;
          flex-direction: column;
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 10px ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__hasSettingsPermissions = false;
    this.__practiceLocation = NebFormPracticeLocation.createModel();
    this.__locations = [];
    this.__savingError = null;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: location => this.verifySave(location),
    };
  }

  firstUpdated() {
    super.firstUpdated();

    this.__practiceLocation = this.__formatModel();

    this.__locations = this.model.context.locations.map(location => ({
      data: location,
      label: location.name,
    }));

    this.__hasSettingsPermissions = getSettingsPermissions();
  }

  __getTitle() {
    const action = this.__practiceLocation.id ? 'Update' : 'Add';
    return this.model.context.readOnly
      ? 'Location Information'
      : `${action} Location`;
  }

  __formatModel() {
    if (!this.model.item) {
      return NebFormPracticeLocation.createModel();
    }

    const {
      address1,
      address2,
      city,
      state,
      zipCode,
      fl33Location,
      fl32LocationId,
      businessName,
      taxonomy,
      ...item
    } = this.model.item;

    return {
      ...item,
      taxonomy: taxonomy || '',
      fl33Location: fl33Location || '',
      businessName: businessName || '',
      fl32LocationId: fl32LocationId || '',
      useCurrentFl33: !fl33Location,
      address: {
        address1,
        address2,
        city,
        state,
        zipcode: zipCode,
      },
    };
  }

  __buildLocationInUsePopup(areasInUse) {
    const content = Object.entries(areasInUse);
    return {
      title: 'Unable to Inactivate - Location In Use',
      confirmText: 'Ok',
      message: html`
        <span>
          Before inactivating this location, you must make sure it's not being
          used. This location is currently in use in the following areas:
        </span>
        <div
          style="padding-top: 20px; padding-right: 20px; padding-bottom: 10px"
        >
          ${
            content
              .filter(([_, items]) => items.length)
              .map(([group, items]) =>
                group !== LOCATION_IN_USE_GROUPS.payerPlans
                  ? html`
                      <div style="display: flex">
                        <div style="padding-right: 10px; padding-bottom: 10px">
                          <b>${group}</b>:
                        </div>
                        <div>${items.join(', ')}</div>
                      </div>
                    `
                  : html`
                      <div>
                        <div style="padding-right: 10px; padding-bottom: 10px">
                          <b>${group}</b>:
                        </div>
                        <neb-textarea
                          .value="${items.join(', ')}"
                          readonly
                        ></neb-textarea>
                      </div>
                    `,
              )
          }
        </div>
        <span>
          Please review each of these and choose a new location wherever this
          location is currently in use. When you're done, return here to
          inactivate the location.
        </span>
      `,
    };
  }

  __getLocationsInUseFl() {
    const { id: practiceId } = this.__practiceLocation;
    return this.__locations
      .filter(
        ({ data: { id, fl33Location, fl32LocationId } }) =>
          practiceId !== id &&
          (fl33Location === practiceId || fl32LocationId === practiceId),
      )
      .map(({ label }) => label);
  }

  async __getAreasUsedAt() {
    const billingAreasWithLocation = await getLocationInactivation(
      this.__practiceLocation.id,
    );

    return {
      [LOCATION_IN_USE_GROUPS.billingInfo]:
        billingAreasWithLocation.billingInfo,
      [LOCATION_IN_USE_GROUPS.locations]: this.__getLocationsInUseFl(),
      [LOCATION_IN_USE_GROUPS.payerPlans]: billingAreasWithLocation.payerPlans,
    };
  }

  __checkIfOnlyActiveServiceLocation() {
    return (
      this.__practiceLocation.active &&
      !this.__practiceLocation.addressOnly &&
      !this.__locations.some(
        ({ data }) =>
          data.id !== this.__practiceLocation.id &&
          data.active &&
          !data.addressOnly,
      )
    );
  }

  async __isLocationNotInUse() {
    let areasInUse;

    if (!this.__practiceLocation.id) return true;

    try {
      areasInUse = await this.__getAreasUsedAt();
    } catch (error) {
      this.__savingError = { label: 'Save failed' };
      store.dispatch(openError(LOCATION_SAVED_ERROR));
      return false;
    }

    if (Object.values(areasInUse).some(area => area.length)) {
      this.__savingError = { label: 'Inactivation canceled' };

      await openPopup(
        POPUP_RENDER_KEYS.MESSAGE,
        this.__buildLocationInUsePopup(areasInUse),
      );

      return false;
    }

    return true;
  }

  async verifySave(location) {
    this.__savingError = null;

    if (!this.__practiceLocation.active || location.active) {
      this.__save(location);
      return;
    }

    if (this.__checkIfOnlyActiveServiceLocation()) {
      this.__savingError = { label: 'Inactivation canceled' };

      await openPopup(
        POPUP_RENDER_KEYS.MESSAGE,
        ONE_ACTIVE_SERVICE_LOCATION_REQUIRED,
      );
    } else if (await this.__isLocationNotInUse()) {
      const accepted = await openPopup(
        POPUP_RENDER_KEYS.CONFIRM,
        CONFIRM_INACTIVATE_LOCATION,
      );

      if (accepted) {
        this.__save(location);
      } else this.__savingError = { label: 'Inactivation canceled' };
    }
  }

  async __save(location) {
    const { tenantId } = store.getState().session.item;
    const { zipcode, ...address } = location.address;
    const body = {
      ...address,
      ...(location.id && { id: location.id }),
      active: location.active,
      zipCode: zipcode,
      name: location.name,
      phoneNumber: location.phoneNumber,
      emailAddress: location.emailAddress,
      taxId: location.taxId,
      taxonomy: location.taxonomy,
      addressOnly: location.addressOnly,
      allowOnlineBooking: location.allowOnlineBooking,
      allowWalkInAppointments: location.allowWalkInAppointments,
      organizationNPI: location.organizationNPI,
      fl33Location: location.fl33Location,
      fl32LocationId: location.fl32LocationId,
      businessName: location.businessName,
      useBillingNPI: location.useBillingNPI,
      useBillingTaxId: location.useBillingTaxId,
      useBillingTaxonomy: location.useBillingTaxonomy,
      hideLogo: location.hideLogo,
    };

    try {
      const savedLocation = location.id
        ? await editLocation({
            ...body,
            billingInfoId: this.model.context.billingInfo.id,
          })
        : await createLocation(body);
      await store.dispatch(fetchPracticeInformation(tenantId));

      store.dispatch(openSuccess(LOCATION_SAVED_SUCCESS));
      this.onClose(savedLocation);
    } catch (error) {
      store.dispatch(openError(LOCATION_SAVED_ERROR));
      this.__savingError = { label: 'Save failed' };
    }
  }

  renderContent() {
    const {
      context: { billingInfo, readOnly },
    } = this.model;

    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="${this.__getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-practice-location
        id="${ELEMENTS.form.id}"
        class="container"
        .layout="${this.layout}"
        .model="${this.__practiceLocation}"
        .billingInfo="${billingInfo}"
        .locations="${this.__locations}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .savingError="${this.__savingError}"
        .readOnly="${readOnly}"
        ?hasSettingsPermissions="${this.__hasSettingsPermissions}"
      ></neb-form-practice-location>
    `;
  }
}

window.customElements.define(
  'neb-overlay-practice-location',
  NebOverlayPracticeLocation,
);
