import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { timesToDisplayFormat } from '../../../neb-input/nebFormatUtils';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_BODY,
} from '../../../neb-styles/neb-variables';
import { formatSelectedTime } from '../../../neb-utils/moment-flag-conversion';

export const ELEMENTS = {
  containerTime: {
    id: 'container-time',
  },
  containerScroll: {
    id: 'container-scroll',
  },
  morningColumn: {
    id: 'morning-column',
  },
  afternoonColumn: {
    id: 'afternoon-column',
  },
  eveningColumn: {
    id: 'evening-column',
  },
  timeButtons: {
    selector: '.button-time',
  },
};

const TIME_TYPE = {
  MORNING: 'morning',
  AFTERNOON: 'afternoon',
  EVENING: 'evening',
};

class NebTimeList extends LitElement {
  static get properties() {
    return {
      momentFlag: {
        type: Boolean,
        reflect: true,
      },
      layout: {
        type: String,
        reflect: true,
      },
      selectedTime: {
        type: Object,
      },
      times: {
        type: Array,
      },
      __displayScrollableUp: {
        type: Boolean,
      },
      __displayScrollableDown: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.momentFlag = false;
    this.layout = '';
    this.selectedTime = null;
    this.times = [];
    this.__displayScrollableUp = false;
    this.__displayScrollableDown = false;

    this.onTimeSelected = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      scroll: e => {
        const { scrollHeight, offsetHeight, scrollTop } = e.currentTarget;
        const overflow = scrollHeight - offsetHeight;
        const contentRemaining = overflow - scrollTop;
        this.__displayScrollableUp = scrollTop !== 0;
        this.__displayScrollableDown = contentRemaining !== 0;
      },
      selectTime: e => {
        const timesOfDay = this.__getTimes(e.currentTarget.timeType);

        const times = timesToDisplayFormat(timesOfDay);
        const result = times[Number(e.currentTarget.index)];
        this.selectedTime = result;
        this.onTimeSelected(result);
      },
    };
  }

  __getTimes(type) {
    switch (type) {
      case TIME_TYPE.MORNING:
        return this.times.filter(time => time < 12);

      case TIME_TYPE.AFTERNOON:
        return this.times.filter(time => time >= 12 && time < 16);

      case TIME_TYPE.EVENING:
        return this.times.filter(time => time >= 16);

      default:
        throw new Error('invalid type:', type);
    }
  }

  __scrollToSelectedDate() {
    const timeContainer = this.shadowRoot.getElementById(
      ELEMENTS.containerTime.id,
    );

    if (timeContainer) {
      const { scrollHeight, offsetHeight, scrollTop } = timeContainer;
      const overflow = scrollHeight - offsetHeight;
      const contentRemaining = overflow - scrollTop;

      if (this.selectedTime) {
        const selectedDateDisplay = formatSelectedTime(
          this.momentFlag,
          this.selectedTime,
        );
        const selectedTimeButton = this.shadowRoot.getElementById(
          `time-${selectedDateDisplay.replace(/:*\s*/g, '')}`,
        );
        const sizeOfContainer = timeContainer.offsetHeight;

        if (selectedTimeButton) {
          if (overflow > 0) {
            if (selectedTimeButton.offsetTop < sizeOfContainer / 2 - 20) {
              timeContainer.scrollTop = 0;
            } else if (
              selectedTimeButton.offsetTop > timeContainer.scrollHeight
            ) {
              timeContainer.scrollTop = timeContainer.scrollHeight;
            } else {
              timeContainer.scrollTop =
                selectedTimeButton.offsetTop - (sizeOfContainer / 2 - 20);
            }
          }
        }
      }

      this.__displayScrollableUp = scrollTop !== 0;
      this.__displayScrollableDown = contentRemaining !== 0;
    }
  }

  firstUpdated() {
    this.__scrollToSelectedDate();
  }

  updated(changedProps) {
    if (changedProps.has('times') && this.times) {
      if (
        JSON.stringify(changedProps.get('times')) !== JSON.stringify(this.times)
      ) {
        this.__scrollToSelectedDate();
      }
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          justify-content: center;
        }

        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 100%;
          min-height: 210px;
        }

        .container-scroll {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 100%;
          min-height: 210px;
        }

        .container-times {
          display: flex;
          justify-content: space-around;
          width: 100%;
          height: 270px;
          overflow-y: scroll;
        }

        :host([layout='small']) .container-times {
          height: 100%;
        }

        .fade {
          position: absolute;
          width: 100%;
          height: 2.4rem;
          left: 0;
          pointer-events: none;
        }

        .fade-top {
          top: 0;
          background-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1)
          );
        }

        .fade-bottom {
          bottom: 0;
          background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1)
          );
        }

        .header {
          display: flex;
          justify-content: space-around;
          width: 100%;
        }

        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: fit-content;
          min-width: 88px;
        }

        .column-header {
          margin-bottom: ${CSS_SPACING};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .column-header[hasTimes] {
          opacity: 0.25;
        }

        .button-time {
          cursor: pointer;
          display: block;
          justify-content: center;
          width: 88px;
          height: 30px;
          min-height: 30px;
          padding: 0;
          outline: none;
          border: none;
          border-radius: 15px;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          background: transparent;
          background-color: transparent;
          margin-bottom: 4px;
        }

        .button-time[selected] {
          color: ${CSS_COLOR_WHITE};
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        .button-time:hover {
          color: ${CSS_COLOR_WHITE};
          background-color: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon {
          position: absolute;
          display: flex;
          width: 24px;
          height: 24px;
          bottom: 0;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-rotate {
          top: 0;
          transform: rotate(180deg);
        }
      `,
    ];
  }

  __renderTimes(type, times) {
    const selectedDateDisplay = formatSelectedTime(
      this.momentFlag,
      this.selectedTime,
    );
    return timesToDisplayFormat(times).map(
      (time, index) => html`
        <button
          id="time-${time.display.replace(/:*\s*/g, '')}"
          class="button-time"
          .index="${index}"
          .timeType="${type}"
          ?selected="${time.display === selectedDateDisplay}"
          @click="${this.__handlers.selectTime}"
        >
          ${time.display}
        </button>
      `,
    );
  }

  render() {
    const morningTimes = this.__getTimes(TIME_TYPE.MORNING);

    const afternoonTimes = this.__getTimes(TIME_TYPE.AFTERNOON);

    const eveningTimes = this.__getTimes(TIME_TYPE.EVENING);

    return html`
      <div class="container">
        <div class="header">
          <div class="column-header" ?hasTimes="${morningTimes.length === 0}">
            Morning
          </div>

          <div class="column-header" ?hasTimes="${afternoonTimes.length === 0}">
            Afternoon
          </div>

          <div class="column-header" ?hasTimes="${eveningTimes.length === 0}">
            Evening
          </div>
        </div>

        <div id="${ELEMENTS.containerScroll.id}" class="container-scroll">
          <div
            id="${ELEMENTS.containerTime.id}"
            class="container-times"
            @scroll="${this.__handlers.scroll}"
          >
            <div id="${ELEMENTS.morningColumn.id}" class="column">
              ${this.__renderTimes(TIME_TYPE.MORNING, morningTimes)}
            </div>

            <div id="${ELEMENTS.afternoonColumn.id}" class="column">
              ${this.__renderTimes(TIME_TYPE.AFTERNOON, afternoonTimes)}
            </div>

            <div id="${ELEMENTS.eveningColumn.id}" class="column">
              ${this.__renderTimes(TIME_TYPE.EVENING, eveningTimes)}
            </div>
          </div>
          ${
            this.__displayScrollableUp
              ? html`
                  <div class="fade fade-top"></div>
                  <neb-icon
                    class="icon icon-arrow icon-rotate"
                    icon="neb:arrow"
                  ></neb-icon>
                `
              : ''
          }
          ${
            this.__displayScrollableDown
              ? html`
                  <div class="fade fade-bottom"></div>

                  <neb-icon class="icon icon-arrow" icon="neb:arrow"></neb-icon>
                `
              : ''
          }
        </div>
      </div>
    `;
  }
}

customElements.define('neb-time-list', NebTimeList);
