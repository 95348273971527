const DEBOUNCE_DELAY = 800;
const UP = 'up';
const DOWN = 'down';
const SPACE_TOP_ATTR = '[space-top]';
const SPACE_BOTTOM_ATTR = '[space-bottom]';
const SCROLL_SPACE = {
  [UP]: scrollStep => -Math.abs(scrollStep),
  [DOWN]: scrollStep => Math.abs(scrollStep),
};
export default class DragAndDropService {
  constructor(targetEl, dragSelector, rowSelector, onChange) {
    this.__isDragging = false;
    this.__autoScrollSpace = 0;
    this.__targetEl = targetEl;
    this.__dragSelector = dragSelector;
    this.__rowSelector = rowSelector;
    this.__items = [];
    this.__spaces = [];

    this.__onChange = onChange || (() => {});

    this.scrollThresholdDown = 100;
    this.scrollThresholdUp = 150;
    this.scrollStep = 5;
  }

  async setItems(items) {
    this.__items = items;

    this.__removeSpaces();

    const rows = this.__getRowElements();

    const draggables = await this.__getDraggableElements();

    if (this.__spaces.length === 0) {
      for (let i = 0; i < rows.length; i++) {
        const draggable = draggables[i];

        if (draggable) {
          draggable.setAttribute('index', i);

          this.__handleAddSpaces(rows, i);

          this.__setListeners(draggables, i);
        }
      }
    }
  }

  __removeSpaces() {
    this.__spaces.forEach(space => space.remove());

    this.__spaces = [];
  }

  __getRowElements() {
    return this.__targetEl.shadowRoot.querySelectorAll(this.__rowSelector);
  }

  async __getDraggableElements() {
    const rows = this.__getRowElements();

    const draggables = [];

    for (let i = 0; i < rows.length; i++) {
      const draggable = await this.__getDraggableElement(rows[i]);
      draggables.push(draggable);
    }

    return draggables;
  }

  async __getDraggableElement(row) {
    await row.updateComplete;
    return !row.shadowRoot
      ? row.querySelector(this.__dragSelector)
      : row.shadowRoot.querySelector(this.__dragSelector);
  }

  __createSpaceElement(name) {
    const space = document.createElement('div');
    space.setAttribute(name, '');
    return space;
  }

  __addSpace(targetedRow, space, isBelow = false) {
    if (targetedRow.parentNode) {
      if (!isBelow) {
        targetedRow.parentNode.insertBefore(space, targetedRow);
      } else {
        targetedRow.parentNode.appendChild(space);
      }

      this.__spaces.push(space);
    }
  }

  __addBottomAndTopSpace(targetedRow, spaceBottom, spaceTop) {
    this.__addSpace(targetedRow, spaceBottom);

    this.__addSpace(targetedRow, spaceTop);
  }

  __handleAddSpaces(rows, index) {
    const spaceTop = this.__createSpaceElement('space-top');

    const spaceBottom = this.__createSpaceElement('space-bottom');

    const targetedRow = rows[index];

    if (index === rows.length - 1) {
      this.__addBottomAndTopSpace(targetedRow, spaceBottom, spaceTop);

      const lastSpaceBottom = spaceBottom.cloneNode();

      this.__addSpace(targetedRow, lastSpaceBottom, true);
    } else if (index === 0) {
      this.__addSpace(targetedRow, spaceTop);
    } else {
      this.__addBottomAndTopSpace(targetedRow, spaceBottom, spaceTop);
    }
  }

  __setListeners(draggables, index) {
    draggables[index].ondragstart = () => false;

    draggables[index].setAttribute('draggable', 'false');
    draggables[index].onmousedown = this.__onDragStart.bind(this);
    draggables[index].ontouchstart = this.__onDragStart.bind(this);
  }

  __getPageY({ pageY, touches } = {}) {
    return typeof pageY === 'number' ? pageY : touches[0].pageY || 0;
  }

  __setDragProps(event) {
    const pageY = this.__getPageY(event);

    const index = event.currentTarget.getAttribute('index');
    this.__targetEl.__drag = {};
    this.__targetEl.__drag.origIndex = index;
    this.__targetEl.__drag.moveToIndex = this.__targetEl.__drag.origIndex;
    this.__targetEl.__drag.row = this.__getRowElements()[index];
    this.__targetEl.__drag.space = this.__targetEl.shadowRoot.querySelectorAll(
      SPACE_BOTTOM_ATTR,
    )[index];

    this.__targetEl.__drag.initialSpace = this.__targetEl.__drag.space;

    this.__targetEl.__drag.origY = pageY;
    this.__targetEl.__drag.rowContainerHeight = this.__targetEl.getBoundingClientRect().height;

    this.__setDragStart(pageY);
  }

  __getMidHeight(height) {
    return height / 2;
  }

  __getHiddenScrollHeight(pageY) {
    return (
      this.__targetEl.__drag.row.offsetTop +
      this.__targetEl.__drag.row.offsetHeight / 2 -
      pageY
    );
  }

  __setDragStart(pageY) {
    this.__targetEl.__drag.hiddenScrollHeight = this.__getHiddenScrollHeight(
      pageY,
    );

    const higherZIndex = '1000';
    this.__targetEl.__drag.row.style.width = `${
      this.__targetEl.__drag.row.offsetWidth
    }px`;

    this.__targetEl.__drag.space.style.height = `${
      this.__targetEl.__drag.row.offsetHeight
    }px`;

    this.__targetEl.__drag.row.style.position = 'absolute';
    this.__targetEl.__drag.row.style.top = this.__calcDragPosition(pageY);
    this.__targetEl.__drag.row.style.zIndex = higherZIndex;
    this.__targetEl.__drag.row.style.cursor = 'ns-resize';
  }

  __setDragEnd() {
    this.__targetEl.__drag.row.style.position = '';
    this.__targetEl.__drag.row.style.zIndex = '';
    this.__targetEl.__drag.row.style.width = '';
    this.__targetEl.__drag.row.style.top = '';
    this.__targetEl.__drag.row.style.cursor = '';
    this.__targetEl.__drag.space.style.height = '';
  }

  __getDragYcoordinate(pageY) {
    const adjustScrollSpace = this.__getAdjustAutoScrollSpace(pageY);

    return (
      pageY -
      this.__getMidHeight(this.__targetEl.__drag.row.offsetHeight) +
      adjustScrollSpace
    );
  }

  __getAdjustAutoScrollSpace(pageY) {
    return this.__isAutoScrollUp(pageY) ||
      this.__isAutoScrollDown(pageY) ||
      this.__hasAutoScroll
      ? this.__autoScrollSpace
      : 0;
  }

  __setDragMove(event) {
    const pageY = this.__getPageY(event);

    this.__targetEl.__drag.row.style.top = this.__calcDragPosition(pageY);
  }

  __calcDragPosition(pageY) {
    return this.__isBodyOffsetParent()
      ? `${this.__getDragYcoordinate(pageY)}px`
      : `${this.__getDragYcoordinate(pageY) +
          this.__targetEl.__drag.hiddenScrollHeight}px`;
  }

  __changeSpace(event) {
    const pageY = this.__getPageY(event);

    const prevSpace = this.__targetEl.__drag.space;
    const query =
      this.__targetEl.__drag.origIndex < this.__targetEl.__drag.moveToIndex
        ? SPACE_BOTTOM_ATTR
        : SPACE_TOP_ATTR;
    this.__targetEl.__drag.space = this.__targetEl.shadowRoot.querySelectorAll(
      query,
    )[this.__targetEl.__drag.moveToIndex];

    if (this.__targetEl.__drag.origY > pageY) {
      this.__changeSpaceMoveUp(prevSpace);
    } else {
      this.__changeSpaceMoveDown(prevSpace);
    }
  }

  __changeSpaceMoveUp(prevSpace) {
    if (this.__debounceTimeoutId) {
      clearTimeout(this.__debounceTimeoutId);
    }

    this.__debounceTimeoutId = setTimeout(() => {
      this.__swapSpace(prevSpace);
    }, DEBOUNCE_DELAY);
  }

  __changeSpaceMoveDown(prevSpace) {
    this.__swapSpace(prevSpace);
  }

  __swapSpace(prevSpace) {
    if (this.__targetEl.__drag.space) {
      this.__targetEl.__drag.space.style.height = `${
        this.__targetEl.__drag.row.offsetHeight
      }px`;
    }

    prevSpace.style.height = '';

    if (this.__targetEl.__drag.initialSpace) {
      this.__targetEl.__drag.initialSpace.style.height = '';
      this.__targetEl.__drag.initialSpace = this.__targetEl.__drag.space;
    }
  }

  __isBodyOffsetParent() {
    return this.__targetEl.offsetParent.tagName === 'BODY';
  }

  __calcRowTopOffset(row) {
    return this.__isBodyOffsetParent()
      ? row.offsetTop
      : row.offsetTop - this.__targetEl.__drag.hiddenScrollHeight;
  }

  __handleSpaceChange(event) {
    const { space, row, moveToIndex } = this.__targetEl.__drag;

    const rowTop = this.__calcRowTopOffset(row);

    const rowBottom = rowTop + row.offsetHeight;
    const spaceTop =
      space.offsetTop - this.__targetEl.__drag.hiddenScrollHeight;
    const spaceBottom = spaceTop + space.offsetHeight;

    let isChangeSpace = false;

    if (
      rowTop > spaceBottom &&
      moveToIndex < this.__getRowElements().length - 1
    ) {
      this.__targetEl.__drag.moveToIndex++;
      isChangeSpace = true;
    } else if (rowBottom < spaceTop && moveToIndex > 0) {
      this.__targetEl.__drag.moveToIndex--;
      isChangeSpace = true;
    }

    if (isChangeSpace) {
      this.__changeSpace(event);
    }
  }

  __onDragMove(event) {
    this.__shouldAutoScroll(event);

    this.__setDragMove(event);

    this.__handleSpaceChange(event);
  }

  __shouldAutoScroll(event) {
    const pageY = this.__getPageY(event);

    if (this.__isAutoScrollUp(pageY) && this.__doesNotCrossTopLimit()) {
      this.__setAutoScrollSpace(UP);
    } else if (
      this.__isAutoScrollDown(pageY) &&
      this.__doesNotCrossBottomLimit()
    ) {
      this.__setAutoScrollSpace(DOWN);
    }
  }

  __setAutoScrollSpace(direction) {
    this.__autoScrollSpace += SCROLL_SPACE[direction](this.scrollStep);
    this.offsetParent.scrollTop += SCROLL_SPACE[direction](this.scrollStep);
    this.__hasAutoScroll = true;
  }

  __doesNotCrossBottomLimit() {
    return (
      window.innerHeight +
        (this.__isBodyOffsetParent()
          ? 0
          : this.__targetEl.__drag.hiddenScrollHeight) +
        this.__autoScrollSpace <
      this.__targetEl.__drag.rowContainerHeight + this.scrollThresholdDown
    );
  }

  __doesNotCrossTopLimit() {
    return this.offsetParent.scrollTop > 0;
  }

  __isAutoScrollUp(pageY) {
    return pageY < this.scrollThresholdUp;
  }

  __isAutoScrollDown(pageY) {
    return window.innerHeight - pageY < this.scrollThresholdDown;
  }

  __onDragEnd() {
    this.__setDragEnd();

    document.body.style.MozUserSelect = '';

    this.__clearListeners();

    this.__handleRearrangeItems();

    this.__handleReflectDragging();

    this.__hasAutoScroll = false;
    this.__autoScrollSpace = 0;

    this.__onChange(this.__items);
  }

  __handleRearrangeItems() {
    if (
      this.__targetEl.__drag.moveToIndex !== this.__targetEl.__drag.origIndex
    ) {
      this.__clearDragStartListeners();

      const movedItem = this.__items.splice(
        this.__targetEl.__drag.origIndex,
        1,
      )[0];

      this.__items.splice(this.__targetEl.__drag.moveToIndex, 0, movedItem);

      this.__targetEl.__drag = {};

      this.__removeSpaces();

      this.__targetEl.requestUpdate();
    }
  }

  __clearListeners() {
    this.__clearMouseListeners();

    this.__clearTouchListeners();
  }

  async __clearDragStartListeners() {
    const draggables = await this.__getDraggableElements();
    draggables.forEach(el => {
      el.onmousedown = null;
      el.ontouchstart = null;
      el.ondragstart = null;
    });
  }

  __clearTouchListeners() {
    document.ontouchmove = null;
    document.ontouchend = null;
  }

  __clearMouseListeners() {
    document.onmousemove = null;
    document.onmouseup = null;
  }

  __setMouseListeners() {
    document.onmousemove = this.__onDragMove.bind(this);
    document.onmouseup = this.__onDragEnd.bind(this);
  }

  __setTouchListeners() {
    document.ontouchmove = this.__onDragMove.bind(this);
    document.ontouchend = this.__onDragEnd.bind(this);
  }

  __onDragStart(e) {
    if (e.button > 0) return;

    this.__handleReflectDragging();

    this.__setDragProps(e);

    this.__setMouseListeners();

    this.__setTouchListeners();

    e.preventDefault();
  }

  __handleReflectDragging() {
    this.__targetEl.__isDragging = !this.__targetEl.__isDragging;

    this.__targetEl.toggleAttribute('dragging');
  }
}
