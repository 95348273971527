import '../../components/misc/neb-icon';
import '../../../packages/neb-styles/neb-icons';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/neb-loading-spinner-resizable';
import '../../../packages/neb-lit-components/src/components/neb-button';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { createPayment } from '../../../packages/neb-api-client/src/payments/electronic-payments-api-client';
import { getPayfieldsApiKey } from '../../../packages/neb-api-client/src/payments/payfields-api-client';
import {
  createPaymentMethod,
  deleteToken,
} from '../../../packages/neb-api-client/src/payments/payment-methods-api-client';
import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../packages/neb-styles/neb-variables';
import {
  SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH,
  SMALL_LAYOUT_WIDTH_PERCENT,
} from '../../../packages/neb-utils/payment-popup-constants';

import {
  PAYMENT_RESULT_CONFIG,
  PAYMENT_METHOD_SUCCESS_MESSAGE,
} from './messages';

export const ELEMENTS = {
  iframeSpinner: {
    id: 'iframe-spinner',
  },
  payfieldsIframe: {
    id: 'payfields-iframe',
  },
};

export const TIMEOUT = 1000;

class NebPayfacGlobalPayments extends LitElement {
  static get properties() {
    return {
      merchantAccount: Object,
      amount: String,
      holderId: String,
      holderType: String,
      postalCode: String,
      address1: String,
      cardDescription: String,
      logContent: Object,
      confirmLabel: String,
      layout: { type: String, reflect: true },
      isProcessing: Boolean,
      hasDirtyPopup: { Boolean, reflect: true },
      __initialized: Boolean,
    };
  }

  static get styles() {
    return css`
      .container-content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
      }

      .iframe {
        display: flex;
        border: none;
        width: 100%;
      }

      :host([layout='small']) .iframe {
        transform: scale(${SMALL_LAYOUT_WIDTH_PERCENT});
        transform-origin: 0 0;
      }

      :host([hasDirtyPopup='true']) .iframe {
        pointer-events: none;
      }

      .spinner {
        margin-top: ${CSS_SPACING};
        margin-bottom: -124px;
        text-align: center;
        z-index: 1;
      }

      .visible {
        opacity: 1;
      }

      .invisible {
        opacity: 0;
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('message', this.__handlers.messageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('message', this.__handlers.messageChanged);
  }

  firstUpdated(props) {
    this.__elements = {
      iframe: this.shadowRoot.getElementById('payfields-iframe'),
    };

    this.__elements.iframe.onload = this.__handlers.initialize;

    super.firstUpdated(props);
  }

  __initState() {
    this.merchantAccount = null;
    this.amount = '';
    this.holderId = '';
    this.holderType = '';
    this.postalCode = '';
    this.address1 = '';
    this.cardDescription = '';
    this.logContent = {};
    this.confirmLabel = '';
    this.layout = '';
    this.isProcessing = false;
    this.hasDirtyPopup = false;
    this.__initialized = false;
    this.__tempToken = '';

    this.onDirty = () => {};

    this.onProcessing = () => {};

    this.onSave = () => {};

    this.onCancel = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      initialize: async () => {
        if (!this.__initialized) {
          const { key, env } = await getPayfieldsApiKey();

          this.__postMessage({
            action: 'initialize',
            key,
            env,
            opts: {
              confirmLabel: this.confirmLabel,
            },
          });
        }
      },

      // eslint-disable-next-line complexity
      messageChanged: ({ source, data }) => {
        if (source !== this.__elements.iframe.contentWindow) {
          return;
        }

        if (data.dimensions) {
          this.__elements.iframe.style.height = `${data.dimensions.height +
            1}px`;

          let widthToSet = data.dimensions.width;

          if (this.layout === 'small') {
            // subtract 40 for left and right margins on this form
            widthToSet =
              (SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH - 40) /
              SMALL_LAYOUT_WIDTH_PERCENT;
          }

          this.__elements.iframe.style.width = `${widthToSet}px`;
        }

        if (data.dirty) this.onDirty(true);

        if (data.initialized) {
          window.setTimeout(() => {
            this.__initialized = true;
          }, TIMEOUT);
        }

        if (data.action === 'cancel') {
          this.__handlers.cancel();
        }

        if (data.errors) {
          this.__handleErrors(data);
        }

        if (data['temporary-token']) {
          this.__tempToken = data['temporary-token'];
          this.onProcessing(true);
          this.__handlers.createPayment();
        }
      },

      // eslint-disable-next-line complexity
      createPayment: async () => {
        const payload = {
          temporary_token: this.__tempToken,
          transactionAmount: String(
            this.amount ? Number(this.amount.replace(/[^0-9.]/g, '')) : 0,
          ),
          holderId: this.holderId,
          holderType: this.holderType,
          postalCode: this.postalCode || undefined,
          address1: this.address1 || undefined,
        };

        const result = this.amount
          ? await createPayment(this.merchantAccount, {
              ...payload,
              logContent: {
                action: 'payment - createPayment - neb-payfac-global-payments',
                rawModel: this.logContent,
              },
            })
          : await createPaymentMethod(this.merchantAccount, {
              ...payload,
              description: this.cardDescription,
            });

        if (result) {
          if (!this.amount) {
            if (result.status === 'Failed') {
              const response = await openPopup(
                POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                {
                  ...PAYMENT_RESULT_CONFIG.PAYMENT_METHOD.FAILURE,
                  subheader: result.statusDescription || result.status,
                },
              );

              if (response) this.onProcessing(false);
              else this.onCancel();

              return;
            }
            this.onSave(result);

            store.dispatch(openSuccess(PAYMENT_METHOD_SUCCESS_MESSAGE));

            await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              PAYMENT_RESULT_CONFIG.PAYMENT_METHOD.SUCCESS,
            );

            return;
          }

          if (result.status === 'Approved') {
            this.onSave(result);

            if (result.card.token && this.holderType !== 'payer') {
              const response = await openPopup(
                POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS_AND_SAVE,
              );

              try {
                if (response.action === 'confirm' && response.saveCardOnFile) {
                  await createPaymentMethod(this.merchantAccount, {
                    ...result.card,
                    holderId: this.holderId,
                    holderType: this.holderType,
                    description: response.cardOnFileName,
                  });

                  store.dispatch(
                    openSuccess('Payment method stored successfully'),
                  );
                } else {
                  deleteToken(
                    this.merchantAccount,
                    result.card.token,
                    result.entryType,
                  );
                }
              } catch (e) {
                store.dispatch(
                  openError('An error occurred when storing payment method'),
                );

                console.log(e);
              }
            } else {
              await openPopup(
                POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                PAYMENT_RESULT_CONFIG.PAYMENT.SUCCESS,
              );
            }

            return;
          }

          if (result.code.slice(0, 1) === '5') {
            const response = await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              {
                ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE_RETRY,
                subheader: 'Service Unavailable',
              },
            );

            if (response) {
              if (response.action === 'confirm') {
                this.onProcessing(true);
                this.__handlers.createPayment();
              } else {
                this.onProcessing(false);
              }
            } else this.onCancel();
          } else {
            const response = await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              {
                ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
                subheader: result.statusDescription || result.status,
              },
            );

            if (response) this.onProcessing(false);
            else this.onCancel();
          }
        }
      },

      cancel: () => {
        this.onCancel();
      },
    };
  }

  __handleErrors(data) {
    let errors;

    if (data.errors.code === 'invalid_card') {
      errors = ['card_number'];
    } else {
      errors = data.errors.detail
        .map(({ data_path: dataPath, description }) => {
          const [, , prop] = dataPath.split('/');

          if (!prop) {
            return undefined;
          }

          let field;

          if (prop.includes('expiry')) {
            field =
              description === 'Invalid data' ? 'expiration_data' : 'expiration';
          } else {
            field = prop;
          }

          return field;
        })
        .filter(prop => prop);

      if (errors.includes('expiration') && errors.includes('expiration_data')) {
        errors = errors.filter(prop => !prop.includes('expiration_data'));
      }
    }

    if (errors) {
      this.__postMessage({
        action: 'errors',
        errors,
      });
    }
  }

  __postMessage(message) {
    this.__elements.iframe.contentWindow.postMessage(message, '*');
  }

  __renderSpinner() {
    return !this.__initialized || this.isProcessing
      ? html`
          <neb-loading-spinner-resizable
            id="${ELEMENTS.iframeSpinner.id}"
            class="spinner"
          ></neb-loading-spinner-resizable>
        `
      : '';
  }

  __renderIframe() {
    return html`
      <iframe
        id="${ELEMENTS.payfieldsIframe.id}"
        src="https://s3.amazonaws.com/neb-payfields-iframe/payfields-iframe.html"
        class="iframe ${
          this.__initialized && !this.isProcessing ? 'visible' : 'invisible'
        }"
      ></iframe>
    `;
  }

  render() {
    return html`
      <div class="container-content">
        ${this.__renderSpinner()} ${this.__renderIframe()}
      </div>
    `;
  }
}

customElements.define('neb-payfac-global-payments', NebPayfacGlobalPayments);
