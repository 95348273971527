import '../../neb-lit-components/src/components/neb-tooltip';
import '../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../../src/components/controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import {
  CSS_FONT_FAMILY,
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../neb-styles/neb-variables';

import NebPopupClaim, { ELEMENTS as BASE_ELEMENTS } from './neb-popup-claim';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  textInputs: { selector: 'neb-textfield' },
  checkbox: { selector: 'neb-checkbox' },
};

class NebPopupClaimMultiSelectInput extends NebPopupClaim {
  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        this.__state = {
          ...this.__state,
          [name]: {
            ...this.__state[name],
            value: {
              ...this.__state[name].value,
              displayLabel: value,
              displayValue: value,
            },
          },
        };
      },
      changeCheckbox: ({ name, value }) => {
        this.__state = {
          ...this.__state,
          [name]: {
            ...this.__state[name],
            value: {
              ...this.__state[name].value,
              enabled: value,
            },
          },
        };
      },
      confirm: () => {
        if (this.__validate()) {
          this.onClose(this.__state);
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
        }

        .content {
          max-height: 500px;
          display: block;
          overflow-y: auto;
        }

        .grid {
          display: grid;
          padding: 0 ${CSS_SPACING};
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          align-items: center;
        }

        .grid-2 {
          grid-template-columns: auto 1fr;
        }

        .label {
          padding: ${CSS_SPACING_ROW} 0;
        }

        .scroll {
          display: block;
          flex: 1 0 0;
          max-height: 400px;
          overflow-y: auto;
          margin: -15px 0 -5px;
        }

        .text-input {
          width: 100%;
        }
      `,
    ];
  }

  __validate() {
    let result = true;

    this.model.items[0].items.forEach(({ name }) => {
      if (
        this.__state[name].value.enabled &&
        this.__state[name].value.displayLabel === '' &&
        !this.__state[name].readOnly
      ) {
        this.__errors = { ...this.__errors, [name]: 'Required' };
        result = false;
      } else {
        this.__errors[name] = '';
      }
    });

    return result;
  }

  firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      this.__state = this.model.items[0].items.reduce((acc, item) => {
        acc[item.name] = item;

        return acc;
      }, {});
    }
  }

  renderContent() {
    if (Object.keys(this.__state).length === 0) return '';

    return html`
      <div class="scroll">
        <div class="grid grid-2">
          ${
            this.model.items[0].items.map(({ name, label, maxLength }) =>
              this.__state[name].readOnly
                ? html`
                    <neb-checkbox
                      name="${name}"
                      class=""
                      label=""
                      .checked="${this.__state[name].value.enabled}"
                      .onChange="${this.handlers.changeCheckbox}"
                    ></neb-checkbox>

                    <div class="label">
                      ${this.__state[name].value.displayLabel}
                    </div>
                  `
                : html`
                    <neb-checkbox
                      name="${name}"
                      class=""
                      label=""
                      .checked="${this.__state[name].value.enabled}"
                      .onChange="${this.handlers.changeCheckbox}"
                    ></neb-checkbox>

                    <neb-textfield
                      class="text-input"
                      helper=" "
                      .error="${this.__errors[name]}"
                      .disabled="${!this.__state[name].value.enabled}"
                      .name="${name}"
                      .label="${label}"
                      .maxLength="${maxLength}"
                      .value="${this.__state[name].value.displayLabel}"
                      .onChange="${this.handlers.change}"
                    ></neb-textfield>
                  `,
            )
          }
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-multi-select-input',
  NebPopupClaimMultiSelectInput,
);
