import ApiClient, { Method, RESPONSE_TYPE } from '../utils/api-client-utils';

const apiClient = new ApiClient({
  microservice: 'electronic-claims',
  useTenant: true,
});

export const ECLAIM_API_ERROR = Object.freeze({
  CHC_VALIDATION: 'CHCValidaion',
  CHC_INTERNAL: 'CHCInternal',
  UNKNOWN_ERROR: 'Unknown Error',
  BYOC_VALIDATION: 'byoc',
});

export const postClaimsValidation = (data, optOutLoadingIndicator = false) =>
  apiClient.makeRequest({
    path: 'claims/validation',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: Method.POST,
    version: 4,
    optOutLoadingIndicator,
  });

export const postClaimsSubmission = data =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'claims/submission',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: Method.POST,
    version: 2,
  });

export const requestClaimStatus = data =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `claims/${data.id}/claim-status`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: Method.POST,
    version: 2,
  });

export const getS3EClaimFileJson = eClaimFileId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `eclaim-files/${eClaimFileId}/json`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version: 1,
  });

export const verifyElectronicClaimsValidationEndpoint = () =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'claims/validation/status',
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    version: 1,
  });

export const submitEclaim = (data, optOutLoadingIndicator = false) =>
  apiClient.makeRequest({
    path: 'claims/submission',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: Method.POST,
    version: 3,
    optOutLoadingIndicator,
  });

export function retryClaimBatch(eClaimFileId, claimBatchId) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: true,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `eclaim-files/${eClaimFileId}/retry`,
    body: JSON.stringify({ claimBatchId }),
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });
}
