import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../controls/inputs/neb-checkbox';

import { html, css } from 'lit';
import { join } from 'lit/directives/join.js';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import Table, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { CSS_FIELD_MARGIN } from '../../../../packages/neb-styles/neb-variables';
import { CODE_PAYMENTS } from '../../../../packages/neb-utils/constants';
import { getTextWidth } from '../../../../packages/neb-utils/dom';
import { centsToCurrency } from '../../../../packages/neb-utils/formatters';
import { currency } from '../../../../packages/neb-utils/masks';
import {
  owedGrandTotalValidator,
  validateAllocatedAgainstOwed,
  validateAllocatedAgainstAvailable,
  validateAllowedAgainstTotalOwed,
  isActivePatientPaymentCode,
  getDebitPaymentIds,
  BILL_TYPE,
} from '../../../../packages/neb-utils/neb-ledger-util';
import * as selectors from '../../../../packages/neb-utils/selectors';
import {
  getValueByPath,
  uniqArrayWithObjects,
} from '../../../../packages/neb-utils/utils';
import { searchItems } from '../../../utils/filters';
import { ALLOCATED_AMOUNT_NOT_BALANCED_TO_PAYER_OWED_AMOUNT_ERROR_MSG } from '../../../utils/user-message';

const hasCarePackage = (allocations, responsibilityType, billType) =>
  billType === BILL_TYPE.PACKAGE &&
  (allocations.some(a => a.credit && a.credit.patientPackageId) ||
    responsibilityType === CODE_PAYMENTS.PACKAGE.code);

const isAllocationDisabled = ({
  payerId = null,
  allocatableId = null,
  disabled = false,
}) => disabled || payerId !== allocatableId;

const isPayerAllocationDisabled = ({
  payerId,
  disabled,
  allocatableId,
  isAllocatableByGroupIndex = null,
}) => {
  if (isAllocatableByGroupIndex) return false;
  return isAllocationDisabled({ disabled, allocatableId, payerId });
};

const isPrimaryPayer = ({
  primaryPayerId,
  payerId,
  lineItemPatientInsuranceId,
  patientInsuranceId,
}) =>
  primaryPayerId &&
  payerId === primaryPayerId &&
  (lineItemPatientInsuranceId === patientInsuranceId || !patientInsuranceId);

const isSecondaryPayer = ({ secondaryPayerId, payerId }) =>
  !!(secondaryPayerId && payerId === secondaryPayerId);

const isInsurancePayer = ({
  payerId,
  primaryPayerId,
  lineItemPatientInsuranceId,
  patientInsuranceId,
  secondaryPayerId,
}) =>
  isPrimaryPayer({
    payerId,
    primaryPayerId,
    lineItemPatientInsuranceId,
    patientInsuranceId,
  }) || isSecondaryPayer({ secondaryPayerId, payerId });

const isPatientAllocationDisabled = ({
  allocatableId,
  disabled,
  payerId = null,
  allocations = [],
}) =>
  !!(
    payerId ||
    isAllocationDisabled({ payerId, allocatableId, disabled }) ||
    hasCarePackage(allocations)
  );

const CONFIG = [
  {
    key: 'patientInsuranceId',
    label: 'Secondary Plan',
    flex: css`0 0 144px`,
    show: m => m.showSecondary,
    renderer: (component, value, columnConfig, rowIndex, name, error) => {
      const secondaryDebit = component.model[rowIndex].debit;
      const uniqueSecondaryPlans = uniqArrayWithObjects(
        component.secondaryPlanItems,
      );

      if (secondaryDebit.payerId) {
        return html`
          <neb-select
            id="secondary-id-${rowIndex}"
            showFullText
            wrapText
            forceAlignMenu="right"
            .name="${name}"
            .value="${value}"
            .error="${!!error}"
            .items="${uniqueSecondaryPlans}"
            .onChange="${component.handlers.changeSelect}"
            .onBlur="${component.handlers.blur}"
            .onRenderItem="${component.handlers.renderSecondaryInsuranceItem}"
            ?disabled="${
              !!secondaryDebit.allocations.length || component.disabled
            }"
          ></neb-select>
        `;
      }
      return '';
    },
  },
  {
    key: 'codePaymentId',
    label: 'Type',
    flex: css`0 0 128px`,
    show: m => m.showSecondary,
    renderer: (component, value, columnConfig, rowIndex, name, error) =>
      component.model[rowIndex].debit.payerId
        ? html`
            <neb-select-search
              id="secondary-type-${rowIndex}"
              name="${name}"
              .error="${!!error}"
              .items="${component.getPaymentTypeSearchItems(value, rowIndex)}"
              .value="${value}"
              ?disabled="${component.disabled}"
              .showMenu="${component.__showResults}"
              .onSearch="${component.handlers.searchCode}"
              .onChange="${component.handlers.changeSelectSearch}"
              itemMinWidth="500"
              showSearch
              showFullText
              wrapText
              itemHeight="60"
            ></neb-select-search>
          `
        : '',
  },
  {
    key: 'debit.amount',
    label: 'Owed',
    flex: css`0 0 96px`,
    show: m => m.showSecondary,
    renderer: (component, value, columnConfig, rowIndex, name, error) =>
      component.model[rowIndex].debit.payerId
        ? html`
            <neb-textfield
              id="secondary-owed-${rowIndex}"
              .mask="${currency}"
              .inputMode="${'numeric'}"
              .name="${name}"
              .value="${value}"
              .error="${!!error}"
              .onChange="${component.handlers.change}"
              .onBlur="${component.handlers.blur}"
              ?disabled="${component.disabled}"
            ></neb-textfield>
          `
        : '',
  },
  {
    key: 'debit.allocated',
    label: 'Allocated',
    flex: css`0 0 96px`,
    show: m => m.showSecondary && m.showAllocated,
    renderer: (component, value, columnConfig, rowIndex, name, error) =>
      component.model[rowIndex].debit.payerId
        ? html`
            <neb-textfield
              id="secondary-allocated-${rowIndex}"
              class="field"
              .name="${name}"
              .value="${value}"
              .error="${!!error}"
              .mask="${currency}"
              .inputMode="${'numeric'}"
              .onChange="${component.handlers.change}"
              .onBlur="${component.handlers.blur}"
              ?disabled="${
                isPayerAllocationDisabled({
                  payerId: component.model[rowIndex].debit.payerId,
                  disabled: component.disabled,
                  allocatableId: component.allocatableId,
                  isAllocatableByGroupIndex:
                    component.isAllocatableByGroup[rowIndex],
                })
              }"
            ></neb-textfield>
          `
        : '',
  },
  {
    key: 'debit.allocations',
    label: 'Paid',
    flex: css`0 0 80px`,
    show: m => m.showSecondary,
    renderer: (component, value, columnConfig, rowIndex, _name, _error) =>
      component.model[rowIndex].debit.payerId
        ? html`
            <span id="secondary-paid-${rowIndex}"
              >${
                centsToCurrency(value.reduce((sum, a) => sum + a.amount, 0))
              }</span
            >
          `
        : '',
  },
  {
    key: 'debit.allocations',
    label: 'Pymt ID(s)',
    flex: css`0 0 96px`,
    show: m => m.showSecondary,
    renderer: (component, value, columnConfig, rowIndex, _name, _error) =>
      component.model[rowIndex].debit.payerId
        ? component.__renderPaymentIds({
            rowIndex,
            allocations: value,
            prefixId: 'secondary-payment-ids-',
          })
        : '',
  },
  {
    key: 'debit.payerId',
    label: 'COB',
    flex: css`0 0 32px`,
    formatter: v => !!v,
    show: () => true,
    renderer: (component, value, columnConfig, rowIndex, name, error) => {
      const hasAllocations =
        component.model[rowIndex].debit.allocations.reduce(
          (sum, a) => sum + a.amount,
          0,
        ) > 0;

      const isDisabled =
        component.disabled ||
        !component.primaryPayer ||
        (!value && hasAllocations);

      return html`
        <neb-checkbox
          id="secondary-checkbox-${rowIndex}"
          class="align-offset"
          .name="${name}"
          .value="${value}"
          .error="${error}"
          .onChange="${component.handlers.changeCob}"
          ?checked="${value}"
          ?disabled="${isDisabled}"
        ></neb-checkbox>
      `;
    },
  },
  {
    key: 'codePaymentId',
    label: 'Pt. Resp.',
    flex: css`0 0 144px`,
    show: () => true,
    renderer: (component, value, columnConfig, rowIndex, name, error) => {
      const { payerId } = component.model[rowIndex].debit;

      return html`
        <neb-select-search
          id="patient-type-${rowIndex}"
          name="${name}"
          .error="${payerId ? '' : !!error}"
          .items="${component.getPaymentTypeSearchItems(value, rowIndex)}"
          .value="${payerId ? '' : value}"
          ?disabled="${
            component.disabled ||
              payerId ||
              hasCarePackage(
                component.model[rowIndex].debit.allocations,
                component.model[rowIndex].responsibilityType,
                component.billType,
              )
          }"
          .showMenu="${component.__showResults}"
          .onSearch="${component.handlers.searchCode}"
          .onChange="${component.handlers.changeSelectSearch}"
          itemMinWidth="500"
          showSearch
          showFullText
          wrapText
          itemHeight="60"
        ></neb-select-search>
      `;
    },
  },
  {
    key: 'debit.amount',
    label: 'Pt. Owed',
    flex: css`0 0 96px`,
    show: () => true,
    renderer: (component, value, columnConfig, rowIndex, name, error) => {
      const { payerId } = component.model[rowIndex].debit;

      return html`
        <neb-textfield
          id="patient-owed-${rowIndex}"
          class="field"
          name="${name}"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .value="${payerId ? '$0.00' : value}"
          .error="${!!error}"
          .onChange="${component.handlers.change}"
          .onBlur="${component.handlers.blur}"
          ?disabled="${
            component.disabled ||
              payerId ||
              hasCarePackage(
                component.model[rowIndex].debit.allocations,
                component.model[rowIndex].responsibilityType,
                component.billType,
              )
          }"
        ></neb-textfield>
      `;
    },
  },
  {
    key: 'debit.allocated',
    label: 'Allocated',
    flex: css`0 0 96px`,
    show: m => m.showAllocated,
    renderer: (component, value, columnConfig, rowIndex, name, error) => html`
      <neb-textfield
        id="patient-allocated-${rowIndex}"
        class="field"
        .name="${name}"
        .value="${component.model[rowIndex].debit.payerId ? '$0.00' : value}"
        .error="${!!error}"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .onChange="${component.handlers.change}"
        .onBlur="${component.handlers.blur}"
        ?disabled="${
          isPatientAllocationDisabled({
            payerId: component.model[rowIndex].debit.payerId,
            disabled: component.disabled,
            allocatableId: component.allocatableId,
            allocations: component.model[rowIndex].debit.allocations,
          })
        }"
      ></neb-textfield>
    `,
  },
  {
    key: 'debit.allocations',
    label: 'Pt. Paid',
    flex: css`0 0 80px`,
    show: () => true,
    renderer: (component, value, columnConfig, rowIndex, _name, _error) => html`
      <span id="patient-paid-${rowIndex}"
        >${
          centsToCurrency(
            component.model[rowIndex].debit.payerId
              ? 0
              : value.reduce((sum, a) => sum + a.amount, 0),
          )
        }</span
      >
    `,
  },
  {
    key: 'debit.allocations',
    label: 'Pymt ID(s)',
    flex: css`0 0 96px`,
    show: () => true,
    renderer: (component, value, columnConfig, rowIndex, _name, _error) =>
      component.model[rowIndex].debit.payerId
        ? ''
        : component.__renderPaymentIds({
            rowIndex,
            allocations: value,
            prefixId: 'patient-payment-ids-',
          }),
  },
];

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  secondaryPlans: {
    selector: '[id^=secondary-id-]',
    tag: 'neb-select',
  },
  secondaryTypes: {
    selector: '[id^=secondary-type-]',
    tag: 'neb-select-search',
  },
  secondaryOwedAmounts: {
    selector: '[id^=secondary-owed-]',
    tag: 'neb-textfield',
  },
  secondaryPaidAmounts: {
    selector: '[id^=secondary-paid-]',
  },
  secondaryAllocated: {
    selector: '[id^=secondary-allocated-]',
    tag: 'neb-textfield',
  },
  secondaryCheckboxes: {
    selector: '[id^=secondary-checkbox-]',
    tag: 'neb-checkbox',
  },
  patientTypes: {
    selector: '[id^=patient-type-]',
    tag: 'neb-select-search',
  },
  patientOwedAmounts: {
    selector: '[id^=patient-owed-]',
    tag: 'neb-textfield',
  },
  patientAllocated: {
    selector: '[id^=patient-allocated-]',
    tag: 'neb-textfield',
  },
  patientPaidAmounts: {
    selector: '[id^=patient-paid-]',
  },
  patientPaymentIds: {
    selector: '[id^=patient-payment-ids-]',
  },
  secondaryPaymentIds: {
    selector: '[id^=secondary-payment-ids-]',
  },
  paymentNumbers: {
    selector: '[id^=payment-number-]',
  },
};

export class ChargeResponsibilityTable extends Table {
  static get properties() {
    return {
      __searchResult: Array,
      __showResults: Boolean,
      __searchItems: Array,

      paymentTypes: Array,
      secondaryPlanItems: Array,
      primaryPayer: Object,
      allocatableId: String,
      showSecondary: {
        reflect: true,
        type: Boolean,
      },
      showAllocated: {
        type: Boolean,
        reflect: true,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
      isAllocatableByGroup: Array,
    };
  }

  static createModel() {
    return [];
  }

  static createItem(payerId = null, patientInsuranceId = null) {
    return {
      debit: {
        allocations: [],
        amount: '',
        id: '',
        payerId,
        allocated: 0,
      },
      id: '',
      patientInsuranceId,
      codePaymentId: '',
    };
  }

  static createAllocation() {
    return {
      amount: 0,
      credit: {
        amount: 0,
        patientPackageId: null,
      },
    };
  }

  static createSelectors(
    { paymentTypes = [], insurances = [] } = {},
    { payment = {}, preadjusted = [], showAllocated = false } = {},
  ) {
    return {
      createItem: () => ChargeResponsibilityTable.createItem(),
      children: {
        $: {
          children: {
            patientInsuranceId: selectors.select(
              insurances,
              selectors.ITEM_EMPTY,
              {
                validateManually: true,
                validateRaw: true,
                validators: [
                  {
                    error:
                      'All Secondary entries must have an insurance selected',

                    validate: (value, keyPath, state) => {
                      const payerIdPath = [
                        ...keyPath.slice(0, keyPath.length - 1),
                        'debit',
                        'payerId',
                      ];

                      const primaryPayerIdPath = [
                        ...keyPath.slice(0, keyPath.length - 3),
                        'primaryPayerId',
                      ];

                      const secondaryPayerId = getValueByPath(
                        state,
                        payerIdPath,
                      );
                      const primaryPayerId = getValueByPath(
                        state,
                        primaryPayerIdPath,
                      );

                      return !!secondaryPayerId &&
                        secondaryPayerId !== primaryPayerId
                        ? value
                        : true;
                    },
                  },
                ],
              },
            ),
            debit: {
              children: {
                amount: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    {
                      validate: (value, keyPath, state) => {
                        const payerIdPath = [
                          ...keyPath.slice(0, keyPath.length - 1),
                          'payerId',
                        ];

                        const primaryPayerIdPath = [
                          ...keyPath.slice(0, keyPath.length - 4),
                          'primaryPayerId',
                        ];

                        const secondaryPayerId = getValueByPath(
                          state,
                          payerIdPath,
                        );

                        const primaryPayerId = getValueByPath(
                          state,
                          primaryPayerIdPath,
                        );

                        const debitPath = keyPath.slice(0, keyPath.length - 1);

                        const debit = getValueByPath(state, debitPath);

                        const paidAmount = debit.allocations.reduce(
                          (sum, a) => sum + a.amount,
                          0,
                        );

                        return primaryPayerId &&
                          secondaryPayerId === primaryPayerId
                          ? value >= paidAmount
                          : true;
                      },
                    },

                    {
                      error: 'Secondary Owed amount must be greater than $0.00',
                      validate: (value, keyPath, state) => {
                        const payerIdPath = [
                          ...keyPath.slice(0, keyPath.length - 1),
                          'payerId',
                        ];

                        const primaryPayerIdPath = [
                          ...keyPath.slice(0, keyPath.length - 4),
                          'primaryPayerId',
                        ];

                        const secondaryPayerId = getValueByPath(
                          state,
                          payerIdPath,
                        );
                        const primaryPayerId = getValueByPath(
                          state,
                          primaryPayerIdPath,
                        );

                        return secondaryPayerId &&
                          secondaryPayerId !== primaryPayerId
                          ? value > 0
                          : true;
                      },
                    },

                    {
                      validate: (value, keyPath, state, _service) => {
                        const payerIdPath = [
                          ...keyPath.slice(0, keyPath.length - 1),
                          'payerId',
                        ];

                        const primaryPayerIdPath = [
                          ...keyPath.slice(0, keyPath.length - 4),
                          'primaryPayerId',
                        ];

                        const secondaryPayerId = getValueByPath(
                          state,
                          payerIdPath,
                        );
                        const primaryPayerId = getValueByPath(
                          state,
                          primaryPayerIdPath,
                        );

                        const debitPath = keyPath.slice(0, keyPath.length - 1);

                        const debit = getValueByPath(state, debitPath);

                        const paidAmount = debit.allocations.reduce(
                          (sum, a) => sum + a.amount,
                          0,
                        );

                        return secondaryPayerId &&
                          secondaryPayerId !== primaryPayerId
                          ? value >= paidAmount
                          : true;
                      },
                    },
                    {
                      validate: (value, keyPath, state, _service) => {
                        const payerIdPath = [
                          ...keyPath.slice(0, keyPath.length - 1),
                          'payerId',
                        ];

                        const payerId = getValueByPath(state, payerIdPath);

                        const lineItemDebitsPath = keyPath.slice(
                          0,
                          keyPath.length - 3,
                        );

                        const lineItemDebits = getValueByPath(
                          state,
                          lineItemDebitsPath,
                        );

                        const primaryPayerIdPath = [
                          ...keyPath.slice(0, keyPath.length - 4),
                          'primaryPayerId',
                        ];

                        const primaryPayerId = getValueByPath(
                          state,
                          primaryPayerIdPath,
                        );

                        const debitPath = keyPath.slice(0, keyPath.length - 1);

                        const debit = getValueByPath(state, debitPath);

                        const paidAmount = debit.allocations.reduce(
                          (sum, a) => sum + a.amount,
                          0,
                        );

                        const emptyListLength = primaryPayerId ? 2 : 1;

                        const adjustmentsPath = [
                          ...keyPath.slice(0, keyPath.length - 4),
                          'adjustments',
                        ];

                        const adjustedAmount = getValueByPath(
                          state,
                          adjustmentsPath,
                        ).reduce((sum, adj) => sum + adj.amount, 0);

                        let preadjustedAmount = 0;

                        if (preadjusted && preadjusted.length) {
                          const preadjustedIndex = !Number.isNaN(
                            Number(keyPath[0]),
                          )
                            ? Number(keyPath[0])
                            : Number(keyPath[1]);

                          preadjustedAmount = preadjusted[preadjustedIndex];
                        }

                        const actualAdjustedAmount = showAllocated
                          ? Math.abs(adjustedAmount - preadjustedAmount)
                          : 0;

                        return !payerId &&
                          lineItemDebits.length >= emptyListLength
                          ? value + actualAdjustedAmount >= paidAmount
                          : true;
                      },
                    },

                    validateAllowedAgainstTotalOwed(
                      'The total Owed amounts must be equal to or less than the Allowed amount.',
                    ),

                    owedGrandTotalValidator(
                      "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax",
                    ),

                    validateAllowedAgainstTotalOwed(
                      'The total Owed amounts must equal the Allowed amount',
                      true,
                    ),
                  ],
                }),
                allocated: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    {
                      error:
                        'The allocated amount may not exceed the owed or available amount.',

                      validate: (value, keyPath, state) =>
                        Object.keys(payment).length
                          ? validateAllocatedAgainstOwed({
                              value,
                              keyPath,
                              state,
                            }) ||
                            validateAllocatedAgainstAvailable(
                              value,
                              keyPath,
                              state,
                              payment,
                            )
                          : true,
                    },
                    {
                      error: `The allocated amount may not exceed the ${
                        Object.keys(payment).length && payment.payerPlanId
                          ? 'payer'
                          : 'patient'
                      }'s available amount.`,

                      validate: (value, keyPath, state) =>
                        Object.keys(payment).length
                          ? validateAllocatedAgainstAvailable(
                              value,
                              keyPath,
                              state,
                              payment,
                            )
                          : true,
                    },
                    {
                      error: ALLOCATED_AMOUNT_NOT_BALANCED_TO_PAYER_OWED_AMOUNT_ERROR_MSG,

                      validate: (value, keyPath, state) => {
                        const payerIdPath = [
                          ...keyPath.slice(0, keyPath.length - 1),
                          'payerId',
                        ];

                        const allocationsPath = [
                          ...keyPath.slice(0, keyPath.length - 1),
                          'allocations',
                        ];

                        const payerId = getValueByPath(state, payerIdPath);
                        const allocations = getValueByPath(
                          state,
                          allocationsPath,
                        );
                        const allocatableId = payment && payment.payerPlanId;
                        const isPatientPayer = !payerId;

                        if (
                          isPatientPayer &&
                          isPatientAllocationDisabled({
                            payerId,
                            allocations,
                            allocatableId,
                          })
                        ) {
                          return true;
                        }

                        const primaryPayerIdPath = [
                          keyPath[0],
                          'primaryPayerId',
                        ];

                        const patientInsuranceIdPath = [
                          keyPath[0],
                          'patientInsuranceId',
                        ];

                        const secondaryPayerIdPath = [
                          keyPath[0],
                          'secondaryInsuranceId',
                        ];

                        const lineItemPatientInsuranceIdPath = [
                          ...keyPath.slice(0, keyPath.length - 2),
                          'patientInsuranceId',
                          'data',
                          'id',
                        ];

                        const primaryPayerId = getValueByPath(
                          state,
                          primaryPayerIdPath,
                        );

                        const patientInsuranceId = getValueByPath(
                          state,
                          patientInsuranceIdPath,
                        );

                        const lineItemPatientInsuranceId = getValueByPath(
                          state,
                          lineItemPatientInsuranceIdPath,
                        );

                        const secondaryPayerId = getValueByPath(
                          state,
                          secondaryPayerIdPath,
                        );

                        if (
                          isInsurancePayer({
                            payerId,
                            primaryPayerId,
                            lineItemPatientInsuranceId,
                            patientInsuranceId,
                            secondaryPayerId,
                          }) &&
                          isPayerAllocationDisabled({
                            allocatableId,
                            payerId,
                          })
                        ) {
                          return true;
                        }

                        return Object.keys(payment).length === 0
                          ? true
                          : validateAllocatedAgainstOwed({
                              value,
                              keyPath,
                              state,
                            });
                      },
                    },
                  ],
                }),
                allocations: {
                  createItem: () =>
                    ChargeResponsibilityTable.createAllocation(),
                },
              },
            },
            codePaymentId: {
              validateRaw: true,
              validateManually: true,
              unsafe: true,
              clipPristine: true,
              format: v =>
                (v &&
                  paymentTypes &&
                  paymentTypes.find(item => item.data.id === v)) ||
                selectors.ITEM_EMPTY,
              unformat: v => (v.data ? v.data.id : ''),
              validators: [
                {
                  error: 'All Secondary entries must have a type selected',
                  validate: (value, keyPath, state) => {
                    const payerIdPath = [
                      ...keyPath.slice(0, keyPath.length - 1),
                      'debit',
                      'payerId',
                    ];

                    const primaryPayerIdPath = [
                      ...keyPath.slice(0, keyPath.length - 3),
                      'primaryPayerId',
                    ];

                    const secondaryPayerId = getValueByPath(state, payerIdPath);
                    const primaryPayerId = getValueByPath(
                      state,
                      primaryPayerIdPath,
                    );

                    return !!secondaryPayerId &&
                      secondaryPayerId !== primaryPayerId
                      ? value
                      : true;
                  },
                },
                {
                  error:
                    'Patient Responsibility is required if Patient Owed is greater than $0.00',
                  validate: (value, keyPath, state) => {
                    const payerIdPath = [
                      ...keyPath.slice(0, keyPath.length - 1),
                      'debit',
                      'payerId',
                    ];

                    const amountPath = [
                      ...keyPath.slice(0, keyPath.length - 1),
                      'debit',
                      'amount',
                    ];

                    const secondaryPayerId = getValueByPath(state, payerIdPath);
                    const amount = getValueByPath(state, amountPath);

                    return !secondaryPayerId && amount ? value : true;
                  },
                },
              ],
            },
          },
        },
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          padding-top: ${CSS_FIELD_MARGIN};
        }

        .row {
          border-bottom: none;
        }

        .row-data {
          padding: 0;
        }

        .cell-data {
          overflow: initial;
          padding: 6px 0;
        }

        .row-data:first-of-type .cell-data {
          padding-top: 0;
        }

        .row-data:last-of-type .cell-data {
          padding-bottom: 0;
        }

        .payment-ids {
          display: flex;
          flex-wrap: wrap;
        }

        .separator {
          margin-right: 5px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__searchResult = [];
    this.__showResults = false;
    this.__searchItems = [];

    this.hideHeader = true;
    this.showSecondary = false;
    this.showRemoveButton = true;
    this.showAllocated = false;
    this.paymentTypes = [];
    this.secondaryPlanItems = [];
    this.disabled = false;
    this.primaryPayer = {};
    this.isAllocatableByGroup = [];

    this.onBlur = () => {};

    this.onClickPaymentId = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      renderSecondaryInsuranceItem: item =>
        item.label &&
        html`
          <p style="padding: 0 16px; width: max-content; max-width: 440px">
            ${item.label}
          </p>
        `,
      changeSelect: e => {
        switch (e.event) {
          case 'select':
            this.handlers.change(e);
            break;

          case 'blur':
            this.handlers.change(e);
            this.handlers.blur(e);
            break;

          default:
        }
      },
      changeSelectSearch: e => {
        this.handlers.change(e);
        this.handlers.blur(e);
      },
      changeCob: ({ name, value }) => {
        const index = Number(name.split('.')[0]);

        const paid = this.model[index].debit.allocations.reduce(
          (sum, a) => sum + a.amount,
          0,
        );
        const segments = name.split('.');

        if (!value && paid > 0) {
          this.handlers.change({
            name: [...segments.slice(0, segments.length - 1), 'id'].join('.'),
            value: null,
          });
        }

        this.handlers.change({
          name,
          value: value ? true : null,
        });

        if (!value) {
          this.handlers.change({
            name: [
              ...segments.slice(0, segments.length - 2),
              'patientInsuranceId',
            ].join('.'),
            value: selectors.ITEM_EMPTY,
          });

          this.handlers.change({
            name: [...segments.slice(0, segments.length - 1), 'allocated'].join(
              '.',
            ),
            value: '$0.00',
          });
        }
      },
      change: e => {
        let namespace = [this.name, e.name].filter(item => item).join('.');

        if (this.primaryPayer) {
          const res = /(\d+\.lineItemDebits\.)(\d+)(\..*)/.exec(namespace);
          if (!res) return;
          namespace = `${res[1]}${Number(res[2]) + 1}${res[3]}`;
        }
        this.onChange({ ...e, name: namespace });
      },
      blur: e => {
        let namespace = [this.name, e.name].filter(item => item).join('.');

        if (this.primaryPayer) {
          const res = /(\d+\.lineItemDebits\.)(\d+)(\..*)/.exec(namespace);
          namespace = `${res[1]}${Number(res[2]) + 1}${res[3]}`;
        }
        this.onBlur({ ...e, name: namespace });
      },
      removeRow: e => {
        const { currentTarget } = e;
        e.stopPropagation();

        const index = Number(currentTarget.index);
        const item = this.model[index];

        if (!this.disabled && !currentTarget.disabled) {
          this.onRemove(this.name, item, index);
        }
      },
      clickPayerPymtId: async ({ name }) => {
        const [rowIndex, paymentId] = name.split('.');
        const {
          debit: { allocations = [] },
        } = this.model[Number(rowIndex)];
        const {
          credit: { payment },
        } = allocations.find(
          allocation => allocation.credit.payment.id === paymentId,
        );

        await this.onClickPaymentId(payment);
      },
      searchCode: ({ value }) => {
        if (!value) this.__showResults = true;

        this.__searchResult = searchItems(value, this.__searchItems);
      },
    };
  }

  __renderPaymentIds({ rowIndex, allocations = [], prefixId = '' }) {
    const payments = getDebitPaymentIds(allocations);

    return html`
      <span id="${prefixId}${rowIndex}" class="payment-ids">
        ${
          join(
            payments.map(
              ({ id, paymentNumber: value, icon }) =>
                html`
                  ${
                    this.__renderLinkCell({
                      value,
                      icon,
                      id: `payment-number-${id}`,
                      name: `${rowIndex}.${id}`,
                      onClick: this.handlers.clickPayerPymtId,
                    })
                  }
                `,
            ),
            html`
              <span class="separator">,</span>
            `,
          )
        }</span
      >
    `;
  }

  __renderLinkCell({ value, onClick, id, name, icon }) {
    return html`
      <neb-text
        id="${id}"
        bold
        link
        .name="${name}"
        trailingIcon="${icon}"
        .onClick="${onClick}"
        class="link-cell"
        >${value}</neb-text
      >
    `;
  }

  getPaymentTypeItems(value) {
    if (value && value.label === 'Pkg') {
      const pkgType = this.paymentTypes.find(
        type => type.data.id === CODE_PAYMENTS.PACKAGE.id,
      );
      return [selectors.ITEM_EMPTY, pkgType];
    }

    const items = this.paymentTypes
      ? this.paymentTypes.filter(isActivePatientPaymentCode)
      : [];

    return [selectors.ITEM_EMPTY, ...items];
  }

  getPaymentTypeSearchItems(value) {
    if (value && value.label === 'Pkg') {
      const pkgType = this.paymentTypes.find(
        type => type.data.id === CODE_PAYMENTS.PACKAGE.id,
      );

      return [
        selectors.ITEM_EMPTY,
        {
          label: `${pkgType.label} - ${pkgType.data.description}`,
          data: pkgType.data,
        },
      ];
    }

    return this.__searchResult;
  }

  static buildConfig(params) {
    const config = CONFIG.filter(c => c.show(params));

    if (params.model.length) {
      let secondaryPaymentIdsWidthMin = 96;
      let patientPaymentIdsWidthMin = 96;

      params.model.forEach(lid => {
        const paymentIds = lid.debit.allocations
          .filter(a => a.credit && !!a.credit.payment)
          .map(a => a.credit.payment.paymentNumber)
          .join(', ');
        const paymentIdsWidth = getTextWidth(paymentIds);

        if (lid.debit.payerId) {
          secondaryPaymentIdsWidthMin = Math.max(
            paymentIdsWidth,
            secondaryPaymentIdsWidthMin,
          );
        } else {
          patientPaymentIdsWidthMin = Math.max(
            paymentIdsWidth,
            patientPaymentIdsWidthMin,
          );
        }
      });

      const paymentIdsConfig = config.filter(c => c.label === 'Pymt ID(s)');

      paymentIdsConfig[0].flex = css`0 0 ${secondaryPaymentIdsWidthMin}px`;
      paymentIdsConfig[
        paymentIdsConfig.length - 1
      ].flex = css`0 0 ${patientPaymentIdsWidthMin}px`;
    }

    return config;
  }

  shouldEnableRemoveButton(item) {
    return this.model.length > 1
      ? item.debit.allocations.every(
          a => a.credit && !a.credit.patientPackageId,
        )
      : null;
  }

  updated(changedProps) {
    if (changedProps.has('paymentTypes')) {
      const items = this.paymentTypes
        ? this.paymentTypes.filter(isActivePatientPaymentCode).map(item => ({
            label: `${item.label} - ${item.data.description}`,
            data: item.data,
          }))
        : [];

      this.__searchResult = [selectors.ITEM_EMPTY, ...items];
      this.__searchItems = [selectors.ITEM_EMPTY, ...items];
    }
  }

  update(changedProps) {
    if (
      changedProps.has('showSecondary') ||
      changedProps.has('model') ||
      changedProps.has('showAllocated')
    ) {
      this.config = ChargeResponsibilityTable.buildConfig(this);
    }

    super.update(changedProps);
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    return columnConfig.renderer(
      this,
      value,
      columnConfig,
      rowIndex,
      name,
      error,
    );
  }
}

window.customElements.define(
  'neb-table-charges-responsibility',
  ChargeResponsibilityTable,
);
