import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import { CSS_SPACING } from '../../../packages/neb-styles/neb-variables';
import { layoutStyles } from '../../styles';

import {
  getScanners,
  getDocumentSources,
  getDocumentSourceLists,
  getDefaultScannerConfig,
  getDefaultDocumentSourceListsValues,
} from './processor/scan-config-processor';
import {
  getLoadingIndicatorOn,
  getLoadingIndicatorOff,
  LOADING_INDICATOR_MESSAGE,
} from './utils/loading-indicator-util';

export const ELEMENTS = {
  scanner: {
    id: 'scanner',
    name: '__selectedScanner',
  },
  documentSource: {
    id: 'document-source',
    name: '__selectedDocumentSource',
  },
  resolution: {
    id: 'resolution',
    name: '__selectedResolution',
  },
  color: {
    id: 'color',
    name: '__selectedColor',
  },
  pageSize: {
    id: 'page-size',
    name: '__selectedPageSize',
  },
  duplex: {
    id: 'duplex',
    name: '__selectedDuplex',
  },
  documentType: {
    id: 'document-type',
    name: '__selectedDocumentType',
  },
  preview: {
    id: 'preview',
    name: '__preview',
  },
};

class NebScanConfig extends LitElement {
  static get properties() {
    return {
      isScanServiceAvailable: Boolean,
      __selectedScanner: Object,
      __selectedDocumentSource: Object,
      __selectedResolution: Object,
      __selectedColor: Object,
      __selectedPageSize: Object,
      __selectedDuplex: Object,
      __selectedDocumentType: Object,
      __preview: Boolean,
      __scanners: Array,
      __documentSources: Array,
      __resolutions: Array,
      __colors: Array,
      __pageSizes: Array,
      __duplexes: Array,
      __documentTypes: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .container {
          padding: ${CSS_SPACING} 0 ${CSS_SPACING};
          width: 100%;
          height: 100%;
        }

        .content {
          display: grid;
          grid-template-columns: 1fr;
          padding-left: ${CSS_SPACING};
          padding-right: ${CSS_SPACING};
        }

        .two-columns-grid {
          display: grid;
          height: 100%;
          grid-template-columns: 1fr 1fr;
          column-gap: ${CSS_SPACING};
        }

        .field {
          padding-top: ${CSS_SPACING};
          width: 100%;
        }

        .field-preview {
          margin-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__scanners = [];
    this.__documentSources = [];
    this.__resolutions = [];
    this.__colors = [];
    this.__pageSizes = [];
    this.__duplexes = [];
    this.__documentTypes = [];

    this.__selectedScanner = null;
    this.__selectedDocumentSource = null;
    this.__selectedResolution = null;
    this.__selectedColor = null;
    this.__selectedPageSize = null;
    this.__selectedDuplex = null;
    this.__selectedDocumentType = null;
    this.__preview = true;
    this.isScanServiceAvailable = false;

    this.onLoadingUpdated = () => {};

    this.onScanConfigUpdated = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: async e => {
        if (e.event === 'select' || e.name === ELEMENTS.preview.name) {
          this[e.name] = e.value;

          if (e.name === ELEMENTS.scanner.name) {
            await this.__changeScanner(this.__selectedScanner.data);
          }

          if (e.name === ELEMENTS.documentSource.name) {
            await this.__changeDocumentSource(
              this.__selectedScanner.data,
              this.__selectedDocumentSource.data.id,
            );
          }
          this.__updateScanConfigModel();
        }
      },
    };
  }

  // eslint-disable-next-line complexity
  __updateScanConfigModel() {
    const model = {
      scannerId: this.__selectedScanner?.data?.id.toString(),
      scannerName: this.__selectedScanner?.data?.name || '',
      documentSourceId: this.__selectedDocumentSource?.data?.id.toString(),
      documentSourceName: this.__selectedDocumentSource?.data?.name || '',
      resolutionId: this.__selectedResolution?.data?.id || '',
      resolutionName: this.__selectedResolution?.data?.name || '',
      colorId: this.__selectedColor?.data?.id || '',
      colorName: this.__selectedColor?.data?.name || '',
      pageSizeId: this.__selectedPageSize?.data?.id || '',
      pageSizeName: this.__selectedPageSize?.data?.name || '',
      duplexId: this.__selectedDuplex?.data?.id || '',
      duplexName: this.__selectedDuplex?.data?.name || '',
      documentType: this.__selectedDocumentType,
      preview: this.__preview,
    };
    this.onScanConfigUpdated(model);
  }

  async __changeScanner(scanner) {
    await this.__loadDocumentSources(scanner);
    await this.__changeDocumentSource(
      scanner,
      this.__selectedDocumentSource.data.id,
    );
  }

  async __changeDocumentSource(scanner, documentSourceId) {
    this.onLoadingUpdated(
      getLoadingIndicatorOn(
        LOADING_INDICATOR_MESSAGE.LOADING_SCANNER_DOCUMENT_SOURCE_CONFIGURATION,
      ),
    );

    const documentSourceLists = await getDocumentSourceLists(
      scanner,
      documentSourceId,
    );
    this.__loadDocumentSourceLists(documentSourceLists);

    const defaultDocumentSourceListsValues = getDefaultDocumentSourceListsValues(
      documentSourceLists,
    );

    defaultDocumentSourceListsValues.preview = this.__preview;

    this.__setDocumentSourceListsDefaultValues(
      defaultDocumentSourceListsValues,
    );

    this.onLoadingUpdated(getLoadingIndicatorOff());
  }

  async __loadDocumentSources(scanner) {
    this.onLoadingUpdated(
      getLoadingIndicatorOn(
        LOADING_INDICATOR_MESSAGE.LOADING_SCANNER_DOCUMENT_SOURCE_CONFIGURATION,
      ),
    );

    const documentSources = await getDocumentSources(scanner);
    this.__documentSources = this.__mapToNebSelectArray(documentSources);

    if (this.__documentSources.length) {
      this.__selectedDocumentSource = this.__documentSources[0];
    }
    this.onLoadingUpdated(getLoadingIndicatorOff());
  }

  __loadDocumentSourceLists(documentSourceLists) {
    this.__resolutions = this.__mapToNebSelectArray(
      documentSourceLists.resolutions,
    );

    this.__colors = this.__mapToNebSelectArray(documentSourceLists.colors);
    this.__pageSizes = this.__mapToNebSelectArray(
      documentSourceLists.pageSizes,
    );

    this.__duplexes = this.__mapToNebSelectArray(documentSourceLists.duplexes);
    this.__documentTypes = documentSourceLists.documentTypes;
  }

  __mapToNebSelectArrayItem(item) {
    if (!item) {
      return null;
    }
    return {
      label: item.name,
      data: { id: item.id, name: item.name },
    };
  }

  __mapToNebSelectArray(items) {
    return items.map(item => this.__mapToNebSelectArrayItem(item));
  }

  __setDocumentSourceListsDefaultValues(defaultScannerConfig) {
    this.__selectedPageSize = this.__mapToNebSelectArrayItem(
      defaultScannerConfig.pageSize,
    );

    this.__selectedResolution = this.__mapToNebSelectArrayItem(
      defaultScannerConfig.resolution,
    );

    this.__selectedColor = this.__mapToNebSelectArrayItem(
      defaultScannerConfig.color,
    );

    this.__selectedDuplex = this.__mapToNebSelectArrayItem(
      defaultScannerConfig.duplex,
    );

    this.__selectedDocumentType = defaultScannerConfig.documentType;
    this.__preview = defaultScannerConfig.preview;
  }

  async __loadScanners() {
    this.onLoadingUpdated(
      getLoadingIndicatorOn(LOADING_INDICATOR_MESSAGE.LOADING_SCANNER_LIST),
    );

    this.__scanners = this.__mapToNebSelectArray(await getScanners());

    if (this.__scanners.length) {
      const defaultScannerConfig = await getDefaultScannerConfig();

      this.__selectedScanner = this.__mapToNebSelectArrayItem(
        defaultScannerConfig.scanner,
      );

      await this.__loadDocumentSources(this.__selectedScanner.data);

      this.__selectedDocumentSource = this.__mapToNebSelectArrayItem(
        defaultScannerConfig.documentSource,
      );

      this.onLoadingUpdated(
        getLoadingIndicatorOn(
          LOADING_INDICATOR_MESSAGE.LOADING_SCANNER_CONFIGURATION,
        ),
      );

      const documentSourceLists = await getDocumentSourceLists(
        this.__selectedScanner.data,
        this.__selectedDocumentSource.data.id,
      );

      this.__loadDocumentSourceLists(documentSourceLists);

      this.__setDocumentSourceListsDefaultValues(defaultScannerConfig);
    }
    this.onLoadingUpdated(getLoadingIndicatorOff());

    this.__updateScanConfigModel();
  }

  updated(changedProps) {
    if (
      changedProps.has('isScanServiceAvailable') &&
      this.isScanServiceAvailable === true
    ) {
      this.__loadScanners();
    }
    super.updated(changedProps);
  }

  __renderScanner() {
    return html`
      <neb-select
        id="${ELEMENTS.scanner.id}"
        name="${ELEMENTS.scanner.name}"
        label="Scanner"
        .value="${this.__selectedScanner}"
        .items="${this.__scanners}"
        .onChange="${this.__handlers.change}"
      ></neb-select>
    `;
  }

  __renderDocumentSource() {
    return html`
      <neb-select
        id="${ELEMENTS.documentSource.id}"
        class="field"
        name="${ELEMENTS.documentSource.name}"
        label="Document Source"
        .value="${this.__selectedDocumentSource}"
        .items="${this.__documentSources}"
        .onChange="${this.__handlers.change}"
      ></neb-select>
    `;
  }

  __renderResolution() {
    return html`
      <neb-select
        id="${ELEMENTS.resolution.id}"
        class="field"
        name="${ELEMENTS.resolution.name}"
        label="Resolution"
        .value="${this.__selectedResolution}"
        .items="${this.__resolutions}"
        .onChange="${this.__handlers.change}"
      ></neb-select>
    `;
  }

  __renderColor() {
    return html`
      <neb-select
        id="${ELEMENTS.color.id}"
        class="field"
        name="${ELEMENTS.color.name}"
        label="Color"
        .value="${this.__selectedColor}"
        .items="${this.__colors}"
        .onChange="${this.__handlers.change}"
      ></neb-select>
    `;
  }

  __renderPageSize() {
    return html`
      <neb-select
        id="${ELEMENTS.pageSize.id}"
        class="field"
        name="${ELEMENTS.pageSize.name}"
        label="Page Size"
        .value="${this.__selectedPageSize}"
        .items="${this.__pageSizes}"
        .onChange="${this.__handlers.change}"
      ></neb-select>
    `;
  }

  __renderDuplex() {
    return html`
      <neb-select
        id="${ELEMENTS.duplex.id}"
        class="field"
        name="${ELEMENTS.duplex.name}"
        label="Duplex"
        .value="${this.__selectedDuplex}"
        .items="${this.__duplexes}"
        .onChange="${this.__handlers.change}"
      ></neb-select>
    `;
  }

  __renderDocumentType() {
    return html`
      <neb-select
        id="${ELEMENTS.documentType.id}"
        class="field"
        name="${ELEMENTS.documentType.name}"
        label="Document Type"
        .value="${this.__selectedDocumentType}"
        .items="${this.__documentTypes}"
        .onChange="${this.__handlers.change}"
      ></neb-select>
    `;
  }

  __renderPreview() {
    return html`
      <neb-switch
        id="${ELEMENTS.preview.id}"
        class="field-preview"
        name="${ELEMENTS.preview.name}"
        label="Preview"
        .on="${this.__preview}"
        .onChange="${this.__handlers.change}"
      ></neb-switch>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="content">
          ${this.__renderScanner()}
          <div class="two-columns-grid">
            <div id="left-panel">
              ${this.__renderDocumentSource()}${this.__renderResolution()}${
                this.__renderColor()
              }
            </div>
            <div id="right-panel">
              ${this.__renderPageSize()}${this.__renderDuplex()}${
                this.__renderDocumentType()
              }
            </div>
          </div>

          ${this.__renderPreview()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-scan-config', NebScanConfig);
