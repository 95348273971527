import '../scheduling/neb-blocked-off-time-form';

import { html, css } from 'lit';

import Overlay from './neb-overlay';

export const ELEMENTS = {
  blockedOffTimeForm: {
    id: 'neb-blocked-off-time-form',
  },
};

class NebOverlayBlockedOffTimeForm extends Overlay {
  initState() {
    super.initState();
    this.model = {
      appointment: null,
      providerId: '',
      providerIds: [],
    };
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      dirty: dirty => {
        this.isDirty = dirty;
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 491px;
        }

        :host([layout='small']) .content {
          width: 100%;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-blocked-off-time-form
        id="${ELEMENTS.blockedOffTimeForm.id}"
        .layout="${this.layout}"
        .appointment="${this.model.appointment}"
        .providerId="${this.model.providerId}"
        .providerIds="${this.model.providerIds}"
        .onDirty="${this.__handlers.dirty}"
        .onDismiss="${this.handlers.dismiss}"
      ></neb-blocked-off-time-form>
    `;
  }
}

window.customElements.define(
  'neb-overlay-blocked-off-time-form',
  NebOverlayBlockedOffTimeForm,
);
