import { parseDate } from './date-util';
import { centsToCurrency, decimalToCents } from './formatters';
import { mapPolicyHolderToModel, policyHolderToRaw } from './policyHolder';

export const DEFAULT_LEVEL = Object.freeze({
  Primary: 'Primary',
  Secondary: 'Secondary',
  Tertiary: 'Tertiary',
  Other: 'Other',
});

export const COVERAGE_LEVEL = Object.freeze({
  ChildrenOnly: {
    code: 'CHD',
    description: 'Children Only',
  },
  DependentsOnly: {
    code: 'DEP',
    description: 'Dependents Only',
  },
  EmployeeAndChildren: {
    code: 'ECH',
    description: 'Employee and Children',
  },
  EmployeeAndSpouse: {
    code: 'ESP',
    description: 'Employee and Spouse',
  },
  EmployeeOnly: {
    code: 'EMP',
    description: 'Employee Only',
  },
  Family: {
    code: 'FAM',
    description: 'Family',
  },
  Individual: {
    code: 'IND',
    description: 'Individual',
  },
  SpouseAndChildren: {
    code: 'SPC',
    description: 'Spouse and Children',
  },
  SpouseOnly: {
    code: 'SPO',
    description: 'Spouse Only',
  },
  NotApplicable: {
    code: 'NA',
    description: 'Not Applicable',
  },
});

export const COVERAGE_LEVEL_SIMPLIFIED = [
  { description: 'Not Applicable', code: 'NA' },
  { description: 'Individual', code: 'IND' },
  { description: 'Family', code: 'FAM' },
];

export const INSURED_RELATIONSHIP = Object.freeze({
  Self: 'Self',
  Spouse: 'Spouse',
  LifePartner: 'Life Partner',
  Parent: 'Parent',
  Employer: 'Employer',
  Caregiver: 'Caregiver',
  Other: 'Other',
});

export const POLICY_HOLDER_ITEMS = Object.values(INSURED_RELATIONSHIP);

export const COVERED_SERVICE_TYPE = Object.freeze({
  ServiceType: 'ServiceType',
  ProcedureCode: 'ProcedureCode',
});

export const QUANTITY_TYPES = Object.freeze({
  Units: 'Units',
  Visits: 'Visits',
  Episodes: 'Episodes',
  DollarAmount: 'Dollar Amount',
});

export const TERMS = Object.freeze({
  CalendarYear: 'Calendar year',
  ServiceYear: 'Service year',
  Month: 'Month',
  Day: 'Day',
});

export const EMPTY_PAYER_PLAN = {
  phone: '',
  extension: '',
  website: '',
  contactName: '',
  alias: '',
  financialClass: '',
  payerName: '',
};

export const FINANCIAL_CLASSES = Object.freeze({
  CommercialInsurance: 'Commercial Insurance',
  BCBS: 'BCBS',
  Medicare: 'Medicare',
  Medicaid: 'Medicaid',
  VeteransAffairs: 'Veterans Affairs',
  OtherGovernment: 'Other Government',
  WorkersCompensation: 'Workers Compensation',
  AutoInsurance: 'Auto-Insurance',
  OtherLiability: 'Other Liability',
});

export const INSURANCE_TYPE = Object.freeze({
  WorkingAgedBeneficiary: {
    description:
      '12 - Medicare Secondary, Working Aged Beneficiary or Spouse with Employer Group Health Plan',
    code: '12',
  },
  EndStageRenalDisease: {
    description:
      "13 - Medicare Secondary, End-Stage Renal Disease Beneficiary in the Mandated Coordination Period with an Employer's Group Health Plan",
    code: '13',
  },
  NoFaultInsurance: {
    description:
      '14 - Medicare Secondary, No-fault Insurance including Auto is Primary',
    code: '14',
  },
  WorkersCompensation: {
    description: "15 - Medicare Secondary, Worker's Compensation",
    code: '15',
  },
  PublicHealthService: {
    description:
      '16 - Medicare Secondary, Public Health Service (PHS) or Other Federal Agency',
    code: '16',
  },
  BlackLung: { description: '41 - Medicare Secondary, Black Lung', code: '41' },
  VeteransAdmisitration: {
    description: "42 - Medicare Secondary, Veteran's Administration",
    code: '42',
  },
  DisabledBeneficiary: {
    description:
      '43 - Medicare Secondary, Disabled Beneficiary Under Age 65 with Large Group Health Plan (LGHP)',
    code: '43',
  },
  OtherLiabilityInsurance: {
    description:
      '47 - Medicare Secondary, Other Liability Insurance is Primary',
    code: '47',
  },
});

export const WORKERS_COMPENSATION = FINANCIAL_CLASSES.WorkersCompensation;

export const isWorkersComp = payerPlan =>
  payerPlan && payerPlan.financialClass === WORKERS_COMPENSATION;

export const isInsuredRelationshipSelf = insurance =>
  insurance.insuredRelationship === INSURED_RELATIONSHIP.Self;

export const DATA_KEYS = Object.freeze({
  Copay: 'copays',
  Coinsurance: 'coinsurances',
  Deductible: 'deductibles',
  OutOfPocket: 'outOfPockets',
  PolicyHolder: 'policyHolder',
  PlanInfo: 'planInfo',
  CoveredServices: 'coveredServices',
  NonCoveredServices: 'nonCoveredServices',
});

export const createModelPatientInsuranceVisitLimit = () => ({
  patientInsuranceId: '',
  asOf: '',
  maxVisits: '',
  visitsRenderedOutOfOffice: '',
});

export const createModelCopay = (oldForm = true) => ({
  coverageLevel: COVERAGE_LEVEL.NotApplicable,
  inNetworkAmount: '',
  outOfNetworkAmount: '',
  serviceTypes: [],
  ...(!oldForm ? { isDefault: null } : {}),
});

export const createModelCoinsurance = (oldForm = true) => ({
  coverageLevel: COVERAGE_LEVEL.NotApplicable,
  inNetworkPercent: '',
  outOfNetworkPercent: '',
  serviceTypes: [],
  ...(!oldForm ? { isDefault: null } : {}),
});

export const createModelDeductible = (oldForm = true) => ({
  coverageLevel: COVERAGE_LEVEL.NotApplicable,
  inNetworkAmount: '',
  inNetworkRemaining: '',
  inNetworkStart: null,
  outOfNetworkAmount: '',
  outOfNetworkRemaining: '',
  outOfNetworkStart: null,
  serviceTypes: [],
  ...(!oldForm ? { isDefault: null } : {}),
});

export const createModelOutOfPocket = (oldForm = true) => ({
  coverageLevel: COVERAGE_LEVEL.NotApplicable,
  inNetworkAmount: '',
  inNetworkRemaining: '',
  inNetworkStart: null,
  outOfNetworkAmount: '',
  outOfNetworkRemaining: '',
  outOfNetworkStart: null,
  ...(!oldForm ? { isDefault: null } : {}),
});

export const createModelCoveredService = type =>
  type
    ? {
        type,
        serviceTypeId: null,
        chargeId: null,
        preAuthorizationRequired: false,
        quantityType: '',
        quantity: '',
        term: '',
        remainingQuantity: '',
        asOfDate: null,
        inNetworkCopayAmount: '',
        outOfNetworkCopayAmount: '',
        inNetworkCoinsurancePercent: '',
        outOfNetworkCoinsurancePercent: '',
      }
    : {};

export const createModelNonCoveredService = type =>
  type
    ? {
        type,
        serviceTypeId: null,
        chargeId: null,
      }
    : {};

export const createModelCoverageObject = type => {
  if (type === 'copays') return createModelCopay();
  if (type === 'coinsurances') return createModelCoinsurance();
  if (type === 'deductibles') return createModelDeductible();
  if (type === 'outOfPockets') return createModelOutOfPocket();
  return {};
};

export const createModelCoverageObjectArray = (type, count, attrs) => {
  const sectionArray = [];

  for (let index = 0; index < count; index++) {
    sectionArray.push({ ...createModelCoverageObject(type), ...attrs });
  }

  return sectionArray;
};

export const getIndexOrderForDefaultLevel = value => {
  if (value === DEFAULT_LEVEL.Primary) return 1;
  if (value === DEFAULT_LEVEL.Secondary) return 2;
  if (value === DEFAULT_LEVEL.Tertiary) return 3;
  return 4;
};

export const defaultLevelToRaw = value => {
  if (value === DEFAULT_LEVEL.Other) return null;
  return value;
};

export const rawDefaultLevelToModel = value => {
  if (!value) return DEFAULT_LEVEL.Other;
  return value;
};

export const formatPolicyHolderName = model => {
  if (!model.policyHolder) return '';

  return !model.payerPlan ||
    model.payerPlan.financialClass !== 'Workers Compensation'
    ? `${model.policyHolder.lastName || ''}, ${model.policyHolder.firstName ||
        ''}`
    : `${model.policyHolder.insuredName || ''}`;
};

export const formatPolicyHolderNameLong = ({ payerPlan, policyHolder }) => {
  if (!policyHolder) return '';

  const lastnameAndSuffix = policyHolder.suffix
    ? `${policyHolder.lastName || ''} ${policyHolder.suffix || ''}`
    : `${policyHolder.lastName || ''}`;

  const displayComma = policyHolder.lastName ? ', ' : '';
  const firstName = policyHolder.firstName ? policyHolder.firstName : '';

  const middleInitial = policyHolder.middleName
    ? ` ${policyHolder.middleName[0]}.`
    : '';

  return !payerPlan || payerPlan.financialClass !== 'Workers Compensation'
    ? `${lastnameAndSuffix}${displayComma}${firstName}${middleInitial}`
    : `${policyHolder.insuredName || ''}`;
};

export const formatCoverageDates = model => {
  const start = model.start ? parseDate(model.start).format('M/D/YYYY') : '';
  const end = model.end ? parseDate(model.end).format('M/D/YYYY') : '';
  return `${start} - ${end}`;
};

export const rawCoverageLevelToModel = rawCoverageLevel =>
  Object.values(COVERAGE_LEVEL).find(
    coverage => coverage.code === rawCoverageLevel,
  );

export const rawServiceTypeToModel = rawServiceType => ({
  ...rawServiceType,
  id: rawServiceType.id || null,
  serviceTypeId: rawServiceType.serviceTypeId,
});

export const rawAppointmentTypeToModel = rawAppointmentType => ({
  ...rawAppointmentType,
  id: rawAppointmentType.id || null,
});

export const rawServiceTypesToModel = rawServiceTypes => {
  if (!rawServiceTypes) return [];

  return rawServiceTypes.map(rawServiceType =>
    rawServiceTypeToModel(rawServiceType),
  );
};

export const rawAppointmentTypesToModel = rawAppointmentTypes => {
  if (!rawAppointmentTypes) return [];

  return rawAppointmentTypes.map(rawAppointmentType =>
    rawAppointmentTypeToModel(rawAppointmentType),
  );
};

export const rawDateToModel = raw => (raw ? parseDate(raw) : null);

export const rawCurrencyToModel = amount =>
  amount === null || amount === undefined
    ? ''
    : centsToCurrency(amount.toString(), true);

export const rawPercentToModel = amount =>
  amount === null ? '' : amount.toString();

export const rawCopayToModel = (rawCopay, oldForm = true) => {
  if (!rawCopay) return [createModelCopay(oldForm)];

  return rawCopay.map(copay => ({
    ...copay,
    coverageLevel: rawCoverageLevelToModel(copay.coverageLevel),
    inNetworkAmount: rawCurrencyToModel(copay.inNetworkAmount),
    outOfNetworkAmount: rawCurrencyToModel(copay.outOfNetworkAmount),
    serviceTypes: rawServiceTypesToModel(copay.serviceTypes),
  }));
};

export const rawCoinsuranceToModel = (rawCoinsurance, oldForm = true) => {
  if (!rawCoinsurance) return [createModelCoinsurance(oldForm)];

  return rawCoinsurance.map(coins => ({
    ...coins,
    coverageLevel: rawCoverageLevelToModel(coins.coverageLevel),
    inNetworkPercent: rawPercentToModel(coins.inNetworkPercent),
    outOfNetworkPercent: rawPercentToModel(coins.outOfNetworkPercent),
    serviceTypes: rawServiceTypesToModel(coins.serviceTypes),
  }));
};

export const rawDeductibleToModel = (rawDeductible, oldForm = true) => {
  if (!rawDeductible) return [createModelDeductible(oldForm)];

  return rawDeductible.map(item => ({
    ...item,
    coverageLevel: rawCoverageLevelToModel(item.coverageLevel),
    inNetworkAmount: rawCurrencyToModel(item.inNetworkAmount),
    inNetworkRemaining: rawCurrencyToModel(item.inNetworkRemaining),
    inNetworkStart: item.inNetworkStart,
    outOfNetworkAmount: rawCurrencyToModel(item.outOfNetworkAmount),
    outOfNetworkRemaining: rawCurrencyToModel(item.outOfNetworkRemaining),
    outOfNetworkStart: item.outOfNetworkStart,
    serviceTypes: rawServiceTypesToModel(item.serviceTypes),
  }));
};

export const rawOutOfPocketToModel = (rawOutOfPocket, oldForm = true) => {
  if (!rawOutOfPocket) return [createModelOutOfPocket(oldForm)];

  return rawOutOfPocket.map(item => ({
    ...item,
    coverageLevel: rawCoverageLevelToModel(item.coverageLevel),
    inNetworkAmount: rawCurrencyToModel(item.inNetworkAmount),
    inNetworkRemaining: rawCurrencyToModel(item.inNetworkRemaining),
    inNetworkStart: item.inNetworkStart,
    outOfNetworkAmount: rawCurrencyToModel(item.outOfNetworkAmount),
    outOfNetworkRemaining: rawCurrencyToModel(item.outOfNetworkRemaining),
    outOfNetworkStart: item.outOfNetworkStart,
  }));
};

const rawQuantityOrRemainingToModel = (quantityType, value) =>
  quantityType === 'Dollar Amount' ? rawCurrencyToModel(value) : `${value}`;

export const rawCoveredServicesToModel = rawCoveredServices => {
  if (!rawCoveredServices) return [];

  return rawCoveredServices.map(item => {
    const model = {
      ...item,
      serviceTypeId: item.serviceType
        ? item.serviceType.id
        : item.serviceTypeId,
      chargeId: item.charge ? item.charge.id : item.chargeId,
      quantityType: item.quantityType ? item.quantityType : '',
      quantity:
        item.quantity !== null
          ? rawQuantityOrRemainingToModel(item.quantityType, item.quantity)
          : '',
      remainingQuantity:
        item.remainingQuantity !== null
          ? rawQuantityOrRemainingToModel(
              item.quantityType,
              item.remainingQuantity,
            )
          : '',
      term: item.term ? item.term : '',
      asOfDate: item.asOfDate,
      inNetworkCopayAmount: rawCurrencyToModel(item.inNetworkCopayAmount),
      outOfNetworkCopayAmount: rawCurrencyToModel(item.outOfNetworkCopayAmount),
      inNetworkCoinsurancePercent: rawPercentToModel(
        item.inNetworkCoinsurancePercent,
      ),
      outOfNetworkCoinsurancePercent: rawPercentToModel(
        item.outOfNetworkCoinsurancePercent,
      ),
    };

    return model;
  });
};

export const rawNonCoveredServicesToModel = rawNonCoveredServices => {
  if (!rawNonCoveredServices) return [];

  return rawNonCoveredServices.map(item => {
    const model = {
      ...item,
      serviceTypeId: item.serviceType
        ? item.serviceType.id
        : item.serviceTypeId,
      chargeId: item.charge ? item.charge.id : item.chargeId,
    };

    return model;
  });
};

const getVisitLimitTotalRemaining = ({
  maxVisits = 0,
  currentVisits = 0,
  visitsRenderedOutOfOffice = 0,
  asOf,
}) => {
  const isAsOfDateInCurrentYear =
    !!asOf && parseDate(asOf).format('YYYY') === parseDate().format('YYYY');

  return (
    maxVisits -
    currentVisits -
    (isAsOfDateInCurrentYear ? visitsRenderedOutOfOffice : 0)
  );
};

export const rawPatientInsuranceVisitLimitToModel = (raw = {}) => {
  const { patientInsuranceVisitLimit } = raw;

  if (!patientInsuranceVisitLimit) {
    return createModelPatientInsuranceVisitLimit();
  }

  const totalRemaining = patientInsuranceVisitLimit.maxVisits
    ? getVisitLimitTotalRemaining(patientInsuranceVisitLimit)
    : null;

  return {
    ...patientInsuranceVisitLimit,
    asOf: patientInsuranceVisitLimit.asOf || '',
    maxVisits: patientInsuranceVisitLimit.maxVisits || '',
    visitsRenderedOutOfOffice:
      typeof patientInsuranceVisitLimit.visitsRenderedOutOfOffice === 'number'
        ? patientInsuranceVisitLimit.visitsRenderedOutOfOffice
        : '',
    patientInsuranceId:
      patientInsuranceVisitLimit.patientInsuranceId || raw.id || '',
    totalRemaining,
    displayTotalRemaining: Math.max(totalRemaining, 0),
  };
};

export const dateToRaw = start =>
  !start ? null : parseDate(start).format('YYYY-MM-DD');

export const currencyToRaw = amount =>
  amount && amount.length > 0
    ? decimalToCents(Number(amount.replace(/[^0-9.]/g, '')))
    : null;

export const percentToRaw = percent =>
  percent && percent.length > 0 ? parseInt(percent, 10) : null;

export const computeCoverageLevelDisplay = (item, index) =>
  index > 0 && item === COVERAGE_LEVEL.NotApplicable ? '' : item.description;

export const computeServiceTypeDisplay = item =>
  item && item.code && item.description
    ? `${item.code} - ${item.description}`
    : '';

export const computeApptTypeDisplay = item => (item ? item.name : '');

export const getSelectedServiceTypes = (typeList, items) => {
  if (!typeList || typeList.length === 0) return [];
  if (!items || items.length === 0) return [];

  const result = items.reduce((memo, item) => {
    const match = typeList.find(type => type.id === item.serviceTypeId);

    if (match) {
      memo.push(match);
    }

    return memo;
  }, []);

  return result;
};

export const getSelectedServiceType = (typeList, item) => {
  if (!typeList || typeList.length === 0) return {};
  if (!item) return {};

  const match = typeList.find(type => type.id === item.serviceTypeId);

  if (match) {
    return match;
  }

  return {};
};

export const mapSelectedServiceTypes = items =>
  items.map(item => ({
    serviceTypeId: item.id,
  }));

export const selectedServiceTypeDisplay = serviceType => ({
  ...serviceType,
  label: computeServiceTypeDisplay(serviceType),
});

export const mapSelectedServiceTypesDisplay = serviceTypes =>
  serviceTypes.map(serviceType => selectedServiceTypeDisplay(serviceType));

export const mapSelectedAppointmentTypesDisplay = apptTypes =>
  apptTypes.map(a => ({
    ...a,
    label: computeApptTypeDisplay(a),
  }));

export const mapCoverageLevelDisplayValue = (coverageLevel, idx) => ({
  ...coverageLevel,
  label: computeCoverageLevelDisplay(coverageLevel, idx),
});

export const mapCoverageLevelValues = idx =>
  Object.values(COVERAGE_LEVEL).map(item =>
    mapCoverageLevelDisplayValue(item, idx),
  );

export const mapCoverageLevelValueSimplified = idx =>
  Object.values(COVERAGE_LEVEL_SIMPLIFIED).map(item =>
    mapCoverageLevelDisplayValue(item, idx),
  );

export const mapAvailableCoverageLevelValues = (coverageLevels, idx) =>
  coverageLevels.map(item => mapCoverageLevelDisplayValue(item, idx));

const __getUnavailableServiceTypeIds = (
  costSharingTypes,
  costSharingTypeIdx,
  coverageLevelCode,
) => {
  const unavailableServiceTypeIds = [];

  for (let idx = 0; idx < costSharingTypes.length; idx++) {
    if (idx === costSharingTypeIdx) continue;

    const otherCostSharingType = costSharingTypes[idx];

    if (otherCostSharingType.coverageLevel.code !== coverageLevelCode) continue;

    otherCostSharingType.serviceTypes.forEach(serviceType =>
      unavailableServiceTypeIds.push(serviceType.serviceTypeId),
    );
  }

  return unavailableServiceTypeIds;
};

const __getUnavailableServiceTypeIdsForServices = (
  patientInsurance,
  selectedServiceTypeId,
) => {
  const unavailableServiceTypeIds = [];

  for (let idx = 0; idx < patientInsurance.coveredServices.length; idx++) {
    const coveredService = patientInsurance.coveredServices[idx];
    if (coveredService.serviceTypeId === selectedServiceTypeId) continue;

    unavailableServiceTypeIds.push(coveredService.serviceTypeId);
  }

  for (let idx = 0; idx < patientInsurance.nonCoveredServices.length; idx++) {
    const nonCoveredService = patientInsurance.nonCoveredServices[idx];
    if (nonCoveredService.serviceTypeId === selectedServiceTypeId) continue;

    unavailableServiceTypeIds.push(nonCoveredService.serviceTypeId);
  }

  return unavailableServiceTypeIds;
};

export const getAvailableServiceTypes = (
  costSharingTypes,
  costSharingTypeIdx,
  coverageLevelCode,
  allServiceTypes,
) => {
  const unavailableServiceTypeIds = __getUnavailableServiceTypeIds(
    costSharingTypes,
    costSharingTypeIdx,
    coverageLevelCode,
  );

  const availableServiceTypes = [...allServiceTypes];

  unavailableServiceTypeIds.forEach(unavailableServiceTypeId => {
    const idx = availableServiceTypes.findIndex(
      serviceType => serviceType.id === unavailableServiceTypeId,
    );

    if (idx > -1) availableServiceTypes.splice(idx, 1);
  });

  return availableServiceTypes;
};

export const getAvailableServiceTypesForServices = (
  patientInsurance,
  selectedServiceTypeId,
  allServiceTypes,
) => {
  const unavailableServiceTypeIds = __getUnavailableServiceTypeIdsForServices(
    patientInsurance,
    selectedServiceTypeId,
  );

  const availableServiceTypes = [...allServiceTypes];

  unavailableServiceTypeIds.forEach(unavailableServiceTypeId => {
    const idx = availableServiceTypes.findIndex(
      serviceType => serviceType.id === unavailableServiceTypeId,
    );

    if (idx > -1) availableServiceTypes.splice(idx, 1);
  });

  return availableServiceTypes;
};

const __getUnavailableCoverageLevelCodes = (
  costSharingTypes,
  costSharingTypeIdx,
) => {
  const unavailableCoverageLevelCodes = [];

  for (let idx = 0; idx < costSharingTypes.length; idx++) {
    if (idx === costSharingTypeIdx) continue;

    const otherCostSharingType = costSharingTypes[idx];

    if (otherCostSharingType.coverageLevel !== COVERAGE_LEVEL.NotApplicable) {
      unavailableCoverageLevelCodes.push(
        otherCostSharingType.coverageLevel.code,
      );
    }
  }

  return unavailableCoverageLevelCodes;
};

export const getAvailableCoverageLevels = (
  costSharingTypes,
  costSharingTypeIdx,
) => {
  const availableCoverageLevels = Object.values(COVERAGE_LEVEL);

  const unavailableCodes = __getUnavailableCoverageLevelCodes(
    costSharingTypes,
    costSharingTypeIdx,
  );

  unavailableCodes.forEach(unavailableCode => {
    const idx = availableCoverageLevels.findIndex(
      coverageLevel => coverageLevel.code === unavailableCode,
    );
    availableCoverageLevels.splice(idx, 1);
  });

  return availableCoverageLevels;
};

export const isMedicareSecondary = (financialClass, defaultLevel) =>
  financialClass === 'Medicare' && defaultLevel === DEFAULT_LEVEL.Secondary;

export const serviceTypeToRaw = (type, attrs) => ({
  ...attrs,
  id: type.id,
  serviceTypeId: type.serviceTypeId,
});

export const serviceTypesToRaw = (serviceTypes, attrs) => {
  if (!serviceTypes) return [];

  return serviceTypes.map(serviceType => serviceTypeToRaw(serviceType, attrs));
};

export const copayToRaw = copayModel =>
  copayModel.map(copay => {
    const result = {
      ...copay,
      inNetworkAmount: currencyToRaw(copay.inNetworkAmount),
      outOfNetworkAmount: currencyToRaw(copay.outOfNetworkAmount),
      coverageLevel: copay.coverageLevel.code,
      isDefault: copay.isDefault,
    };

    result.serviceTypes = serviceTypesToRaw(copay.serviceTypes, {
      copayId: copay.id,
    });

    return result;
  });

export const coinsuranceToRaw = coinsuranceModel =>
  coinsuranceModel.map(coins => {
    const result = {
      ...coins,
      inNetworkPercent: percentToRaw(coins.inNetworkPercent),
      outOfNetworkPercent: percentToRaw(coins.outOfNetworkPercent),
      coverageLevel: coins.coverageLevel.code,
      isDefault: coins.isDefault,
    };

    result.serviceTypes = serviceTypesToRaw(coins.serviceTypes, {
      coinsuranceId: coins.id,
    });

    return result;
  });

export const deductibleToRaw = deductibleModel =>
  deductibleModel.map(ded => {
    const result = {
      ...ded,
      coverageLevel: ded.coverageLevel.code,
      inNetworkAmount: currencyToRaw(ded.inNetworkAmount),
      inNetworkRemaining: currencyToRaw(ded.inNetworkRemaining),
      inNetworkStart: dateToRaw(ded.inNetworkStart),
      outOfNetworkAmount: currencyToRaw(ded.outOfNetworkAmount),
      outOfNetworkRemaining: currencyToRaw(ded.outOfNetworkRemaining),
      outOfNetworkStart: dateToRaw(ded.outOfNetworkStart),
      isDefault: ded.isDefault,
    };

    result.serviceTypes = serviceTypesToRaw(ded.serviceTypes, {
      deductibleId: ded.id,
    });

    return result;
  });

export const visitLimitToRaw = model => ({
  ...model.patientInsuranceVisitLimit,
  patientInsuranceId:
    model.patientInsuranceVisitLimit.patientInsuranceId || model.id,
});

export const outOfPocketToRaw = oopModel =>
  oopModel.map(item => ({
    ...item,
    coverageLevel: item.coverageLevel.code,
    inNetworkAmount: currencyToRaw(item.inNetworkAmount),
    inNetworkRemaining: currencyToRaw(item.inNetworkRemaining),
    inNetworkStart: dateToRaw(item.inNetworkStart),
    outOfNetworkAmount: currencyToRaw(item.outOfNetworkAmount),
    outOfNetworkRemaining: currencyToRaw(item.outOfNetworkRemaining),
    outOfNetworkStart: dateToRaw(item.outOfNetworkStart),
    isDefault: item.isDefault,
  }));

const quantityOrRemainingToRaw = (quantityType, value) =>
  quantityType === 'Dollar Amount' ? currencyToRaw(value) : parseInt(value, 10);

export const coveredServicesToRaw = coveredServicesModel => {
  if (!coveredServicesModel) return [];

  return coveredServicesModel.map(item => {
    const raw = {
      ...item,
      serviceTypeId: item.serviceType
        ? item.serviceType.id
        : item.serviceTypeId,
      chargeId: item.charge ? item.charge.id : item.chargeId,
      quantityType: item.quantityType ? item.quantityType : null,
      quantity: item.quantity
        ? quantityOrRemainingToRaw(item.quantityType, item.quantity)
        : null,
      remainingQuantity: item.remainingQuantity
        ? quantityOrRemainingToRaw(item.quantityType, item.remainingQuantity)
        : null,
      term: item.term ? item.term : null,
      asOfDate: dateToRaw(item.asOfDate),
      inNetworkCopayAmount: currencyToRaw(item.inNetworkCopayAmount),
      outOfNetworkCopayAmount: currencyToRaw(item.outOfNetworkCopayAmount),
      inNetworkCoinsurancePercent: percentToRaw(
        item.inNetworkCoinsurancePercent,
      ),
      outOfNetworkCoinsurancePercent: percentToRaw(
        item.outOfNetworkCoinsurancePercent,
      ),
    };

    return raw;
  });
};

export const nonCoveredServicesToRaw = nonCoveredServicesModel => {
  if (!nonCoveredServicesModel) return [];

  return nonCoveredServicesModel.map(item => {
    const raw = {
      ...item,
      serviceTypeId: item.serviceType
        ? item.serviceType.id
        : item.serviceTypeId,
      chargeId: item.charge ? item.charge.id : item.chargeId,
    };

    return raw;
  });
};

export const patientInsuranceVisitLimitToRaw = model => {
  const { patientInsuranceVisitLimit } = model;

  if (!patientInsuranceVisitLimit) return null;

  return {
    ...patientInsuranceVisitLimit,
    asOf: patientInsuranceVisitLimit.asOf || null,
    maxVisits: patientInsuranceVisitLimit.maxVisits || null,
    visitsRenderedOutOfOffice:
      patientInsuranceVisitLimit.visitsRenderedOutOfOffice || null,
    patientInsuranceId:
      patientInsuranceVisitLimit.patientInsuranceId || model.id || '',
  };
};

export const typeCodeModelToRaw = insuranceTypeCode => {
  const matchedInsuranceType = Object.values(INSURANCE_TYPE).find(
    item => item.description === insuranceTypeCode,
  );

  return matchedInsuranceType.code;
};

export const rawTypeCodeToModel = insuranceTypeCode => {
  if (!insuranceTypeCode) return '';

  const matchedInsuranceType = Object.values(INSURANCE_TYPE).find(
    item => item.code === insuranceTypeCode,
  );

  return matchedInsuranceType ? matchedInsuranceType.description : '';
};

const mapPayerPlan = rawPayerPlan => {
  if (!rawPayerPlan) {
    return {
      id: '',
      alias: '',
      financialClass: '',
      payerName: '',
      phone: '',
      extension: '',
      website: '',
      fax: '',
      contactName: '',
      submissionMethodPrimary: '',
      submissionMethodSecondary: '',
      realTimeEligibilityEnabled: false,
      realTimeEligibilityPayerId: '',
      useRealTimeEligibilityPortal: '',
      realTimeEligibilityPortalId: '',
      realTimeEligibilityPortalPassword: '',
      realTimeEligibilityPortalUsername: '',
      realTimeEligibilityNoFutureDates: false,
      providerInOutOfNetwork: [],
      wcbCaseNumberOverride: false,
    };
  }

  return {
    id: rawPayerPlan.id || '',
    alias: rawPayerPlan.alias || '',
    financialClass: rawPayerPlan.financialClass || '',
    payerName: rawPayerPlan.payerName || '',
    phone: rawPayerPlan.phone || '',
    extension: rawPayerPlan.extension || '',
    website: rawPayerPlan.website || '',
    fax: rawPayerPlan.fax || '',
    contactName: rawPayerPlan.contactName || '',
    submissionMethodPrimary: rawPayerPlan.submissionMethodPrimary || '',
    submissionMethodSecondary: rawPayerPlan.submissionMethodSecondary || '',
    realTimeEligibilityEnabled:
      rawPayerPlan.realTimeEligibilityEnabled || false,
    realTimeEligibilityPayerId: rawPayerPlan.realTimeEligibilityPayerId || '',
    useRealTimeEligibilityPortal:
      rawPayerPlan.useRealTimeEligibilityPortal || '',
    realTimeEligibilityPortalId: rawPayerPlan.realTimeEligibilityPortalId || '',
    realTimeEligibilityPortalPassword:
      rawPayerPlan.realTimeEligibilityPortalPassword || '',
    realTimeEligibilityPortalUsername:
      rawPayerPlan.realTimeEligibilityPortalUsername || '',
    realTimeEligibilityNoFutureDates:
      rawPayerPlan.realTimeEligibilityNoFutureDates || false,
    providerInOutOfNetwork: rawPayerPlan.providerInOutOfNetwork || [],
    wcbCaseNumberOverride: rawPayerPlan.wcbCaseNumberOverride || false,
  };
};

export const mapToPatientInsuranceModel = (raw, oldForm = true) => {
  const policyHolder = { ...mapPolicyHolderToModel(raw.policyHolder || {}) };

  const { payerPlan: rawPayerPlan } = raw;

  const payerPlan = mapPayerPlan(rawPayerPlan);

  const planInfo = {
    planName: raw.planName || '',
    groupIdentifier: raw.groupIdentifier ? raw.groupIdentifier.trim() : '',
    memberIdentifier: raw.memberIdentifier ? raw.memberIdentifier.trim() : '',
    subscriberIdentifier: raw.subscriberIdentifier || '',
    contactName: raw.contactName || '',
    phone: raw.phone || '',
    extension: raw.extension || '',
    website: raw.website || '',
    notes: raw.notes || '',
    start: raw.start || '',
    end: raw.end || '',
    resetDate: raw.resetDate || '',
    wcbCaseNumber: raw.wcbCaseNumber || '',
  };

  const copays = rawCopayToModel(raw.copays, oldForm);

  const coinsurances = rawCoinsuranceToModel(raw.coinsurances, oldForm);

  const deductibles = rawDeductibleToModel(raw.deductibles, oldForm);

  const outOfPockets = rawOutOfPocketToModel(raw.outOfPockets, oldForm);

  const coveredServices = rawCoveredServicesToModel(raw.coveredServices);

  const nonCoveredServices = rawNonCoveredServicesToModel(
    raw.nonCoveredServices,
  );

  const defaultLevel = rawDefaultLevelToModel(raw.defaultLevel);
  const insuranceTypeCode = rawTypeCodeToModel(raw.insuranceTypeCode);
  const coverageDates = formatCoverageDates(raw);
  const policyHolderName = formatPolicyHolderName(raw);
  const policyHolderNameLong = formatPolicyHolderNameLong(raw);

  let payerPlanId;
  if (raw.payerPlan && raw.payerPlan.id) payerPlanId = raw.payerPlan.id;
  else payerPlanId = raw.payerPlanId || '';

  return {
    id: raw.id || '',
    active: raw.active === undefined ? true : raw.active,
    patientId: raw.patientId || '',
    payerPlanId,
    insuredRelationship: raw.insuredRelationship || '',
    defaultLevel,
    hasAssociatedLineItems: !raw.hasAssociatedLineItems
      ? false
      : raw.hasAssociatedLineItems,
    indexOrder: raw.indexOrder || 4,
    insuranceTypeCode,
    updatedBy: raw.updatedBy || '',
    updatedAt: raw.updatedAt || '',
    copays,
    coinsurances,
    deductibles,
    outOfPockets,
    coveredServices,
    nonCoveredServices,
    planInfo,
    payerPlan,
    policyHolder,
    coverageDates,
    policyHolderName,
    policyHolderNameLong,
    realTimeEligibilityStatus: raw.realTimeEligibilityStatus || '',
    providerId: raw.providerId || '',
    patientInsuranceVisitLimit: rawPatientInsuranceVisitLimitToModel(raw),
    patientInsRealTimeEligibilityAutoUpdate: !!raw.patientInsRealTimeEligibilityAutoUpdate,
    patientInsAutoUpdateCopay: !!raw.patientInsAutoUpdateCopay,
    patientInsAutoUpdateCoinsurance: !!raw.patientInsAutoUpdateCoinsurance,
    patientInsAutoUpdateDeductible: !!raw.patientInsAutoUpdateDeductible,
    patientInsAutoUpdateOutOfPocket: !!raw.patientInsAutoUpdateOutOfPocket,
    autoUpdateCopay: !!raw.autoUpdateCopay,
    autoUpdateCoinsurance: !!raw.autoUpdateCoinsurance,
    autoUpdateDeductible: !!raw.autoUpdateDeductible,
    autoUpdateOutOfPocket: !!raw.autoUpdateOutOfPocket,
    ...(raw.providerFeeSchedules && {
      providerFeeSchedules: raw.providerFeeSchedules,
    }),
  };
};

export const createModelNewPatientInsurance = () => {
  const emptyRaw = {
    policyHolder: {},
    payerPlan: {},
    planInfo: {},
  };
  return mapToPatientInsuranceModel(emptyRaw);
};

export const patientInsuranceToRaw = (model, medicareSecondary = false) => {
  const policyHolder = policyHolderToRaw(model.policyHolder);

  const planInfo = {
    planName: model.planInfo.planName,
    groupIdentifier: model.planInfo.groupIdentifier
      ? model.planInfo.groupIdentifier.trim()
      : '',
    memberIdentifier: model.planInfo.memberIdentifier
      ? model.planInfo.memberIdentifier.trim()
      : '',
    subscriberIdentifier: model.planInfo.subscriberIdentifier,
    contactName: model.planInfo.contactName,
    phone: model.planInfo.phone,
    extension: model.planInfo.extension,
    website: model.planInfo.website,
    notes: model.planInfo.notes,
    start: dateToRaw(model.planInfo.start),
    end: dateToRaw(model.planInfo.end),
    resetDate: dateToRaw(model.planInfo.resetDate),
    wcbCaseNumber: model.planInfo.wcbCaseNumber || null,
  };

  return {
    id: model.id,
    providerId: model.providerId || null,
    patientId: model.patientId,
    updatedBy: model.updatedBy,
    active: model.active,
    updatedAt: model.updatedAt,
    defaultLevel: defaultLevelToRaw(model.defaultLevel),
    indexOrder: parseInt(model.indexOrder, 10),
    insuranceTypeCode: medicareSecondary
      ? typeCodeModelToRaw(model.insuranceTypeCode)
      : null,
    insuredRelationship: model.insuredRelationship,
    payerPlanId: model.payerPlan.id,
    ...planInfo,
    policyHolder,
    copays: copayToRaw(model.copays),
    coinsurances: coinsuranceToRaw(model.coinsurances),
    deductibles: deductibleToRaw(model.deductibles),
    outOfPockets: outOfPocketToRaw(model.outOfPockets),
    coveredServices: coveredServicesToRaw(model.coveredServices),
    nonCoveredServices: nonCoveredServicesToRaw(model.nonCoveredServices),
    patientInsuranceVisitLimit: patientInsuranceVisitLimitToRaw(model),
    patientInsRealTimeEligibilityAutoUpdate:
      model.patientInsRealTimeEligibilityAutoUpdate,
    patientInsAutoUpdateCopay: model.patientInsAutoUpdateCopay,
    patientInsAutoUpdateCoinsurance: model.patientInsAutoUpdateCoinsurance,
    patientInsAutoUpdateDeductible: model.patientInsAutoUpdateDeductible,
    patientInsAutoUpdateOutOfPocket: model.patientInsAutoUpdateOutOfPocket,
  };
};

export const findNewPayerId = (oldList, newList) => {
  const oldIds = oldList.map(p => p.id);
  const newIds = newList.map(p => p.id);

  return newIds.find(id => !oldIds.includes(id));
};

export const addLabelToPlan = plan =>
  plan ? { ...plan, label: `(${plan.alias}) ${plan.payerName}` } : null;

export const removeFutureDates = date => parseDate(date).isBefore(parseDate());

export const isSelfSelected = insuredRelationship =>
  insuredRelationship === INSURED_RELATIONSHIP.Self;

const isPersonSelected = (insuredRelationship, selectedPerson) =>
  insuredRelationship !== INSURED_RELATIONSHIP.Self && selectedPerson !== null;

export const shouldDisablePolicyHolderField = (
  insuredRelationship,
  selectedPerson,
) =>
  isPersonSelected(insuredRelationship, selectedPerson) ||
  isSelfSelected(insuredRelationship);

export const getInsurancePlanName = (payerPlans, state) => {
  const isPayerPlanName = payerPlans.find(
    payerPlan =>
      payerPlan.alias === state.planInfo.planName &&
      state.planInfo.planName !== state.payerPlan.alias,
  );

  if ((!state.planInfo.planName && state.payerPlan) || isPayerPlanName) {
    return state.payerPlan.alias;
  }

  return '';
};
