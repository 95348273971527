import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getPracticeUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { objToName, DEFAULT_NAME_OPTS } from '../../../../neb-utils/formatters';
import { createOccurrenceNarrative } from '../../../../neb-utils/recurring';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  iconRecurring: {
    id: 'icon-recurring',
  },
  iconStatus: {
    id: 'icon-status',
  },
  iconConfirmedAt: {
    id: 'icon-confirmedAt',
  },
  iconCheckedInRoom: {
    id: 'icon-checked-in-room',
  },
  labelStatus: {
    id: 'label-status',
  },
  labelConfirmedAt: {
    id: 'label-confirmedAt',
  },
  labelCheckedInRoom: {
    id: 'label-checked-in-room',
  },
  recurringNarrative: {
    id: 'recurring-narrative',
  },
};
const RENDER_STATUS = {
  Active: {
    icon: '',
    label: 'Scheduled',
  },
  Canceled: {
    icon: '',
    label: 'Canceled',
  },
  'Checked-In': {
    icon: 'neb:checkedIn',
    label: 'Checked In',
  },
  'Checked-Out': {
    icon: 'neb:checkedOut',
    label: 'Checked Out',
  },
  Deleted: {
    icon: '',
    label: 'Deleted',
  },
  'No-Show': {
    icon: '',
    label: 'Marked as No Show',
  },
  Rescheduled: {
    icon: '',
    label: 'Rescheduled',
  },
};

function formatDate(date) {
  return parseDate(date).format('dddd, MMMM D, YYYY');
}

function formatTime(time) {
  return parseDate(time).format('h:mm A');
}

class NebAppointmentStatus extends LitElement {
  static get properties() {
    return {
      __practiceUsers: {
        type: Array,
      },
      __patient: {
        type: Object,
      },
      model: {
        type: Object,
      },
      checkedInRoomName: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.__practiceUsers = [];
    this.model = null;
    this.checkedInRoomName = '';
  }

  __isCheckedInByPatient() {
    const { selfCheckIn, status } = this.model;
    return selfCheckIn && this.__patient && status === 'Checked-In';
  }

  __getAuthor() {
    if (this.model.arrivedAt && this.model.status === 'Active') {
      return '';
    }

    const { scheduledBy } = this.model;
    const { name } = this.__practiceUsers.find(u => u.id === scheduledBy) || {
      name: null,
    };

    if (this.__isCheckedInByPatient()) {
      return ` by ${objToName(this.__patient.name, DEFAULT_NAME_OPTS)}`;
    }

    if (this.__isRescheduledViaOnlineBooking(name)) {
      return ' via online booking';
    }

    return name ? ` by ${objToName(name, DEFAULT_NAME_OPTS)}` : '';
  }

  __getIcon() {
    if (!this.model.status) return '';

    if (this.model.status === 'Active' && this.model.arrivedAt) {
      return 'neb:arrived';
    }

    return RENDER_STATUS[this.model.status].icon;
  }

  __getStatus() {
    if (!this.model.status) return '';

    if (this.model.status === 'Active') {
      if (this.model.arrivedAt) {
        return 'Arrived';
      }

      if (this.model.cancelRescheduleReasonId) {
        return 'Rescheduled';
      }
    }

    return RENDER_STATUS[this.model.status].label;
  }

  __getDateAndTime() {
    let dateToParse = this.model.updatedAt;

    if (this.model.checkedInAt && this.model.status === 'Checked-In') {
      dateToParse = this.model.checkedInAt;
    }

    if (this.model.arrivedAt && this.model.status === 'Active') {
      dateToParse = this.model.arrivedAt;
    }

    return parseDate(dateToParse)
      .format('dddd, MMMM D, YYYY & h:mm A')
      .split('&')
      .join('at');
  }

  __isRescheduledViaOnlineBooking(name) {
    return (
      !name &&
      (this.model.status === 'Rescheduled' ||
        this.model.cancelRescheduleReasonId)
    );
  }

  async __fetchUsers() {
    this.__practiceUsers = await getPracticeUsers();
  }

  async __fetchPatient() {
    const ids = [this.model.accountId, this.model.patientId].filter(
      item => item,
    );

    const query = { includeBooking: true };

    [this.__patient] = await patientApiClient.fetchSome(
      ids,
      query,
      undefined,
      true,
    );
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model) {
      this.__fetchUsers();

      this.__fetchPatient();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        .label {
          width: fit-content;
        }

        .label-header {
          margin-bottom: 15px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .container-status,
        .container-confirmedAt,
        .container-checkedInRoom {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
        }

        .container-recurring-narrative {
          display: flex;
          margin-top: ${CSS_SPACING};
        }

        .icon-status,
        .icon-recurring,
        .icon-confirmedAt,
        .icon-checked-in-room {
          display: flex;
          min-width: 24px;
          height: 24px;

          margin-right: 10px;
        }
      `,
    ];
  }

  __renderRecurringNarrative() {
    const recurrenceModel = this.model.recurrenceEvent
      ? this.model.recurrenceEvent
      : this.model;
    return recurrenceModel.rrule && recurrenceModel.firstOccurrence
      ? html`
          <div class="container-recurring-narrative">
            <neb-icon
              id="${ELEMENTS.iconRecurring.id}"
              class="icon icon-recurring"
              icon="neb:loop"
            ></neb-icon>

            <span
              id="${ELEMENTS.recurringNarrative.id}"
              class="label label-recurring-narrative"
            >
              ${
                createOccurrenceNarrative(
                  recurrenceModel.rrule,
                  recurrenceModel.firstOccurrence,
                )
              }
            </span>
          </div>
        `
      : '';
  }

  __renderContent() {
    const icon = this.__getIcon();

    return html`
      <div class="label label-header">Appointment Status</div>

      ${
        this.model.confirmedAt
          ? html`
              <div class="container-confirmedAt">
                <neb-icon
                  id="${ELEMENTS.iconConfirmedAt.id}"
                  class="icon icon-confirmedAt"
                  icon="neb:confirmed"
                ></neb-icon>
                <span
                  id="${ELEMENTS.labelConfirmedAt.id}"
                  class="label label-confirmedAt"
                >
                  Confirmed on ${formatDate(this.model.confirmedAt)} at
                  ${formatTime(this.model.confirmedAt)}
                </span>
              </div>
            `
          : html``
      }

      <div class="container-status">
        ${
          icon
            ? html`
                <neb-icon
                  id="${ELEMENTS.iconStatus.id}"
                  class="icon icon-status"
                  .icon="${icon}"
                ></neb-icon>
              `
            : ''
        }
        <span id="${ELEMENTS.labelStatus.id}" class="label label-status">
          ${
            `${this.__getStatus()}${this.__getAuthor()} on ${this.__getDateAndTime()}`
          }
        </span>
      </div>

      ${
        this.checkedInRoomName &&
        this.model.roomId &&
        this.model.status === 'Checked-In'
          ? html`
              <div class="container-checkedInRoom">
                <neb-icon
                  id="${ELEMENTS.iconCheckedInRoom.id}"
                  class="icon icon-checked-in-room"
                  icon="neb:roomDoor"
                ></neb-icon>
                <span id="${ELEMENTS.labelCheckedInRoom.id}" class="label">
                  Checked-In Room: ${this.checkedInRoomName}
                </span>
              </div>
            `
          : ''
      }
      ${this.__renderRecurringNarrative()}
    `;
  }

  render() {
    return this.model && this.__practiceUsers.length
      ? html`
          <div class="container">${this.__renderContent()}</div>
        `
      : html``;
  }
}

customElements.define('neb-appointment-status', NebAppointmentStatus);
