import ApiClient, {
  Method,
} from '../../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient({
  microservice: 'pdf',
  useTenant: true,
});

export default async params => {
  const res = await apiClient.makeRequest({
    path: 'appointments',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
    version: 1,
  });
  return res.buffer;
};
