import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const getLineItem = (id, version = 2) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `line-items/${id}`,
    version,
  });

export const bulkUpdateCase = ({ lineItemIds, newCaseId, newPatientAuthId }) =>
  apiClient.makeRequest({
    version: 1,
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: 'line-items/case',
    body: JSON.stringify({
      caseId: newCaseId || null,
      patientAuthorizationId: newPatientAuthId || null,
      lineItemIds,
    }),
  });
