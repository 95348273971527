import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/neb-unsupported-page';

import { css, html, LitElement } from 'lit';

import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { CSS_COLOR_WHITE } from '../../../styles';

export const ELEMENTS = {
  manageViewsButton: { id: 'manage-views-button' },
  unsupportedPage: { id: 'unsupported-page' },
};

const AR_HUB_RESOLUTION_MESSAGE =
  'You can access AR HUB by resizing your browser to fit the resolution of a desktop/laptop.';

class NebPageArHub extends LitElement {
  static get properties() {
    return {
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  static get styles() {
    return css`
      :host {
        height: 100%;
        background-color: ${CSS_COLOR_WHITE};
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
  }

  __initHandlers() {
    this.__handlers = {
      openManageViews: async () => {
        await openOverlay(OVERLAY_KEYS.MANAGE_VIEWS);
      },
    };
  }

  __renderUnsupportedPage() {
    return html`
      <neb-unsupported-page
        id="${ELEMENTS.unsupportedPage.id}"
        .layout="${this.layout}"
        icon="wallet"
        location="AR Hub"
        specificMessage="${AR_HUB_RESOLUTION_MESSAGE}"
      ></neb-unsupported-page>
    `;
  }

  render() {
    return this.layout !== 'large'
      ? html`
          ${this.__renderUnsupportedPage()}
        `
      : html`
          <neb-button-action
            id="${ELEMENTS.manageViewsButton.id}"
            label="Manage Views"
            .onClick="${this.__handlers.openManageViews}"
          ></neb-button-action>
        `;
  }
}

customElements.define('neb-page-ar-hub', NebPageArHub);
