import '../neb-radio-button';
import '../inputs/neb-textarea';
import '../../../../neb-material-design/src/components/neb-md-button';
import '../../../../../src/components/controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import { NEXT_APPOINTMENT_TYPE } from '../../../../neb-api-client/src/mappers/next-appointment-mapper';
import { number } from '../../../../neb-utils/masks';
import { range } from '../../../../neb-utils/validators';
import { ELEMENTS as NEB_SELECT_ELEMENTS } from '../inputs/neb-select';
import { ELEMENTS as NEB_TEXTFIELD_ELEMENTS } from '../inputs/neb-textfield';

import { ELEMENTS as ELEMENTS_BASE, NebFormOld } from './neb-form-old';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  buttonDay: {
    id: 'button-day',
  },
  buttonMonth: {
    id: 'button-month',
  },
  buttonWeek: {
    id: 'button-week',
  },
  checkBoxExtra: {
    id: 'checkbox-extra',
  },
  dropdownMinute: {
    id: 'dropdownMinute',
  },
  formContainer: {
    id: 'form-container',
  },
  noteField: {
    id: 'note-field',
  },
  radioOn: {
    id: 'radio-on',
  },
  radioIn: {
    id: 'radio-in',
  },
  selectOn: {
    id: 'select-on',
  },
  textfieldIn: {
    id: 'textfield-in',
  },
};
const IN_TYPE = Object.freeze({
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
});
const MINUTE_VALUES = [
  '5',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
  '60',
  '65',
  '70',
  '75',
  '80',
  '85',
  '90',
  '95',
  '100',
  '105',
  '110',
  '115',
  '120',
];
const ON_WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
  'Daily',
  'Tomorrow',
  'As Needed',
];
export class NebFormRequestNextAppointment extends NebFormOld {
  static get properties() {
    return {};
  }

  static createModel() {
    return {
      id: null,
      onDay: '',
      inTime: '',
      inTimeType: '',
      enableExtraTime: false,
      extraMinutes: '',
      type: NEXT_APPOINTMENT_TYPE.ON,
      note: '',
      encounterId: null,
    };
  }

  initState() {
    super.initState();
    this.confirmLabel = 'Request Next Appointment';
    this.cancelLabel = 'Cancel';
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeType: e => {
        this.formService.apply(e.name, e.value);

        switch (e.value) {
          case NEXT_APPOINTMENT_TYPE.ON:
            this.__handleInTimeBlur();

            this.formService.apply('inTime', '');
            this.formService.apply('inTimeType', '');
            break;

          case NEXT_APPOINTMENT_TYPE.IN:
            this.__handleOnDayBlur();

            this.formService.apply('onDay', '');
            this.formService.apply('inTimeType', IN_TYPE.DAYS);
            break;

          default:
        }
      },
      changeExtra: e => {
        this.formService.apply(e.name, e.value);

        if (!e.value) {
          this.formService.apply('extraMinutes', '');
        }
      },
      changeExtraTime: e => {
        if (!e.value) {
          this.formService.apply('extraMinutes', '');
        }

        this.formService.apply(e.name, e.value);
      },
    };
  }

  __handleOnDayBlur() {
    const selectOnDay = this.shadowRoot.getElementById(ELEMENTS.selectOn.id);

    if (selectOnDay) {
      const textbox = selectOnDay.shadowRoot.getElementById(
        NEB_SELECT_ELEMENTS.textbox.id,
      );

      if (textbox) {
        textbox.blur();
      }
    }
  }

  __handleInTimeBlur() {
    const textfieldIn = this.shadowRoot.getElementById(ELEMENTS.textfieldIn.id);

    if (textfieldIn) {
      const input = textfieldIn.shadowRoot.getElementById(
        NEB_TEXTFIELD_ELEMENTS.input.id,
      );

      if (input) {
        input.blur();
      }
    }
  }

  buildSelectors() {
    return {
      onDay: {
        validators: [
          {
            error: 'Required',
            validate: (v, _, state) =>
              v || state.type !== NEXT_APPOINTMENT_TYPE.ON,
          },
        ],
      },
      inTime: {
        format: v => (v ? v.toString() : v),
        unformat: v => Number.parseInt(v, 10),
        validators: [
          {
            error: 'Required',
            validate: (v, _, state) =>
              v || state.type !== NEXT_APPOINTMENT_TYPE.IN,
          },
          range(1, 99),
        ],
      },
      extraMinutes: {
        validators: [
          {
            error: 'Required',
            validate: (v, _, _state) => !this.state.enableExtraTime || !!v,
          },
        ],
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .form {
          padding-top: 0;
        }

        .request-next-appointment-form {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .flex-row {
          display: flex;
          margin-bottom: 16px;
          height: auto;
          flex: 0 0 1;
        }

        .flex-1 {
          flex: 1 1 0;
          align-self: flex-start;
        }

        .select-on {
          width: 100%;
        }

        .textfield-in {
          width: 25%;
          justify-self: left;
        }

        .button-row {
          padding: 16px 10px 0 10px;
          justify-content: space-between;
        }

        .dropdown-minute {
          width: 25%;
        }

        .radio-button-on {
          padding-top: 17px;
        }

        .radio-button-in {
          padding-top: 16px;
        }

        .extra-checkbox {
          padding: 27px 10px 0 10px;
        }

        .notes {
          display: block;
          margin-bottom: 20px;
          width: 100%;
          height: 100%;
          padding-left: 10px;
        }
      `,
    ];
  }

  __renderOnFields() {
    return html`
      <div class="flex-row">
        <neb-radio-button
          id="${ELEMENTS.radioOn.id}"
          name="type"
          class="radio-button-on"
          .value="${NEXT_APPOINTMENT_TYPE.ON}"
          .onChange="${this.handlers.changeType}"
          ?checked="${this.state.type === NEXT_APPOINTMENT_TYPE.ON}"
        >
        </neb-radio-button>
        <neb-select
          id="${ELEMENTS.selectOn.id}"
          name="onDay"
          class="select-on"
          label="On"
          .helper="${
            this.state.type === NEXT_APPOINTMENT_TYPE.ON ? 'Required' : ' '
          }"
          .error="${this.errors.onDay}"
          .items="${ON_WEEKDAYS}"
          .value="${this.state.onDay}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.state.type === NEXT_APPOINTMENT_TYPE.IN}"
        >
        </neb-select>
      </div>
    `;
  }

  __renderInFields() {
    return html`
      <div class="flex-row">
        <neb-radio-button
          id="${ELEMENTS.radioIn.id}"
          name="type"
          class="radio-button-in"
          .value="${NEXT_APPOINTMENT_TYPE.IN}"
          .onChange="${this.handlers.changeType}"
          ?checked="${this.state.type === NEXT_APPOINTMENT_TYPE.IN}"
        >
        </neb-radio-button>
        <neb-textfield
          id="${ELEMENTS.textfieldIn.id}"
          name="inTime"
          class="textfield-in"
          label="In"
          maxLength="2"
          .helper="${
            this.state.type === NEXT_APPOINTMENT_TYPE.IN ? 'Required' : ' '
          }"
          .error="${this.errors.inTime}"
          .mask="${number}"
          .inputMode="${'numeric'}"
          .value="${this.state.inTime}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.state.type === NEXT_APPOINTMENT_TYPE.ON}"
        >
        </neb-textfield>
        <div class="button-row">
          <neb-md-button
            id="${ELEMENTS.buttonDay.id}"
            class="buttonIn"
            label="Day(s)"
            name="inTimeType"
            .value="${IN_TYPE.DAYS}"
            .layout="${this.layout}"
            .onClick="${this.handlers.change}"
            ?disabled="${this.state.type === NEXT_APPOINTMENT_TYPE.ON}"
            ?selected="${this.state.inTimeType === IN_TYPE.DAYS}"
            ?grey="${this.state.inTimeType !== IN_TYPE.DAYS}"
          ></neb-md-button>
          <neb-md-button
            id="${ELEMENTS.buttonWeek.id}"
            class="buttonIn"
            label="Week(s)"
            name="inTimeType"
            .value="${IN_TYPE.WEEKS}"
            .layout="${this.layout}"
            .onClick="${this.handlers.change}"
            ?disabled="${this.state.type === NEXT_APPOINTMENT_TYPE.ON}"
            ?selected="${this.state.inTimeType === IN_TYPE.WEEKS}"
            ?grey="${this.state.inTimeType !== IN_TYPE.WEEKS}"
          ></neb-md-button>
          <neb-md-button
            id="${ELEMENTS.buttonMonth.id}"
            class="buttonIn"
            label="Month(s)"
            name="inTimeType"
            .value="${IN_TYPE.MONTHS}"
            .layout="${this.layout}"
            .onClick="${this.handlers.change}"
            ?disabled="${this.state.type === NEXT_APPOINTMENT_TYPE.ON}"
            ?selected="${this.state.inTimeType === IN_TYPE.MONTHS}"
            ?grey="${this.state.inTimeType !== IN_TYPE.MONTHS}"
          ></neb-md-button>
        </div>
      </div>
    `;
  }

  __renderExtraTimeFields() {
    return html`
      <div class="flex-row">
        <neb-checkbox
          id="${ELEMENTS.checkBoxExtra.id}"
          class="extra-checkbox"
          name="enableExtraTime"
          label="Extra"
          .onChange="${this.handlers.changeExtra}"
          ?checked="${this.state.enableExtraTime === true}"
        ></neb-checkbox>
        <neb-select
          id="${ELEMENTS.dropdownMinute.id}"
          class="dropdown-minute"
          name="extraMinutes"
          label="Extra Minutes"
          .helper="${this.state.enableExtraTime ? 'Required' : ' '}"
          .error="${this.errors.extraMinutes}"
          .items="${MINUTE_VALUES}"
          .value="${this.state.extraMinutes}"
          .onChange="${this.handlers.changeExtraTime}"
          ?disabled="${!this.state.enableExtraTime}"
        ></neb-select>
      </div>
    `;
  }

  __renderNoteField() {
    return html`
      <div class="flex-row">
        <neb-textarea
          id="${ELEMENTS.noteField.id}"
          class="notes"
          label="Note"
          name="note"
          maxLength="500"
          showCount
          .value="${this.state.note}"
          .onChange="${this.handlers.change}"
        ></neb-textarea>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div
        id="${ELEMENTS.formContainer.id}"
        class="request-next-appointment-form"
      >
        ${this.__renderOnFields()} ${this.__renderInFields()}
        ${this.__renderExtraTimeFields()} ${this.__renderNoteField()}
      </div>
    `;
  }
}
customElements.define(
  'neb-form-request-next-appointment',
  NebFormRequestNextAppointment,
);
