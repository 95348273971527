import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';
import './neb-calendar-day-column';

import { css, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { CALENDAR_TYPES } from '../../../packages/neb-utils/calendar-resources-util';
import {
  computeFullWeekRange,
  computeWorkWeekRange,
} from '../../../packages/neb-utils/moment-flag-conversion';
import { CSS_BORDER_GREY_2, CSS_COLOR_WHITE, CSS_SPACING } from '../../styles';

import {
  ELEMENTS as ELEMENTS_BASE,
  NebBaseCalendarView,
} from './neb-base-calendar-view';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  calendarDayColumns: { selector: 'neb-calendar-day-column' },
  roomHeaders: { selector: '.room-header' },
};

const DAY_LABELS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const MONTH_LABELS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const VIEWPORT_OFFSET = 70;

function getWeekDays(startDate, endDate) {
  const weekDays = [];

  const day = startDate.clone();

  while (day.isBefore(endDate, 'day')) {
    weekDays.push({
      date: day.format('YYYY-MM-DD'),
      label: `${DAY_LABELS[day.day()]}, ${
        MONTH_LABELS[day.month()]
      } ${day.date()}`,
    });

    day.add(1, 'day');
  }

  return weekDays;
}

class NebRoomCalendarWeekView extends NebBaseCalendarView {
  static get properties() {
    return {
      location: Object,
      rooms: Array,
      locations: Array,
      targetDate: String,
      isWorkWeek: Boolean,
      appointmentDragAndDropSettings: Object,
      __weekDays: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .columns-container-layout,
        .columns-header-container-layout {
          display: flex;
          background-color: ${CSS_COLOR_WHITE};
        }

        .day-column-layout {
          flex: 1 0 0;
          display: flex;
        }

        .day-column-layout:not(:first-child) {
          padding-left: 10px;
        }

        .container-room-names {
          flex: 1 0 0;
          display: flex;
        }

        .container-location-names {
          flex: 1 0 0;
          display: flex;
        }

        .calendar-column-layout {
          flex: 1 1 0;
          box-sizing: border-box;
          min-width: 145px;
        }

        .room-header {
          flex: 1 1 0;
          box-sizing: border-box;
          min-width: 145px;
          text-align: center;
          font-weight: bold;
          font-size: 12px;
          padding-bottom: 18px;
          height: 35px;
        }

        .day-header-label {
          padding-bottom: ${CSS_SPACING};
          text-align: center;
          font-size: 14px;
        }

        .day-column {
          flex: 1;
          box-sizing: border-box;
          min-width: 145px;

          border-top: ${CSS_BORDER_GREY_2};
          border-right: ${CSS_BORDER_GREY_2};
        }

        .day-column:first-child {
          border-left: ${CSS_BORDER_GREY_2};
        }

        .container-column-header:not(:first-child) {
          padding-left: 10px;
        }

        .container-column-header {
          flex: 1 0 0;
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-room-columns {
          flex: 1 0 0;
          display: flex;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
  }

  initState() {
    super.initState();

    this.targetDate = '';
    this.rooms = [];
    this.locations = [];
    this.location = {};
    this.viewportTopOffset = VIEWPORT_OFFSET;
    this.isWorkWeek = true;
    this.appointmentDragAndDropSettings = {};

    this.__weekDays = [];
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('isWorkWeek') || changedProps.has('targetDate')) {
      const momentWeekRange = this.isWorkWeek
        ? computeWorkWeekRange(true, this.targetDate)
        : computeFullWeekRange(true, this.targetDate);

      this.__weekDays = getWeekDays(momentWeekRange.start, momentWeekRange.end);
    }
  }

  __renderRoomHeaders() {
    return this.rooms.map(
      room => html`
        <div class="room-header">${room.name}</div>
      `,
    );
  }

  __renderDayColumns(day) {
    return this.rooms.map((room, roomIdx) => {
      const { events = null, masked = null } = this.getEvents(
        day.date,
        this.location.id,
        room.id,
      );

      const dayColumnClasses = {
        'day-column': true,
        'calendar-column-layout': true,
        'day-column-spacing': roomIdx > 0,
      };

      return html`
        <neb-calendar-day-column
          class="${classMap(dayColumnClasses)}"
          type="${CALENDAR_TYPES.ROOM}"
          .events="${events}"
          .maskedSlots="${masked}"
          .locations="${this.locations}"
          .locationId="${this.location.id}"
          .resourceId="${room.id}"
          .rooms="${this.rooms}"
          .date="${day.date}"
          .zoomInterval="${this.zoomInterval}"
          .isDragging="${this.isDragging}"
          .active="${this.location.active}"
          .start="${this.startMinute}"
          .end="${this.endMinute}"
          .appointmentDragAndDropSettings="${
            this.appointmentDragAndDropSettings
          }"
          .onUpdateCalendar="${this.handlers.updateCalendar}"
          .onUpdateDragging="${this.handlers.updateDragging}"
        ></neb-calendar-day-column>
      `;
    });
  }

  renderColumnHeaders() {
    return this.__weekDays.map(
      day => html`
        <div class="container-column-header">
          <div class="day-header-label">${day.label}</div>

          <div class="container-room-names">${this.__renderRoomHeaders()}</div>
        </div>
      `,
    );
  }

  renderColumns() {
    return this.__weekDays.map(
      day =>
        html`
          <div class="day-column-layout container-column">
            ${this.__renderDayColumns(day)}
          </div>
        `,
    );
  }
}

customElements.define('neb-room-calendar-week-view', NebRoomCalendarWeekView);
