import '../neb-button-actions';
import '../../../../../src/components/misc/neb-icon';
import '../neb-text';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { css, html } from 'lit';

import { STATUS } from '../../../../../src/components/filters/neb-filters-activity-encounters';
import { verifyAssociatedInvoices } from '../../../../../src/features/charting/neb-charting-encounter-header-util';
import {
  createNewCase,
  updateAssociatedCase,
} from '../../../../../src/features/charting/neb-charting-util';
import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../../../src/utils/locations/location-util';
import { NO_ITEMS_INITIAL_LOAD } from '../../../../../src/utils/user-message';
import { getEncounterCharges } from '../../../../neb-api-client/src/charting/encounter-charge';
import { getEncounter } from '../../../../neb-api-client/src/encounters-api-client';
import {
  createAndAddInvoiceToLineItems,
  getLedgerInvoiceItems,
} from '../../../../neb-api-client/src/invoice-api-client';
import { BILL_TYPES } from '../../../../neb-api-client/src/mappers/patient-case-mapper';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getEncounterCharges as getBillingEncounterCharges } from '../../../../neb-api-client/src/services/encounter-charge';
import saveCharges from '../../../../neb-api-client/src/services/encounter-charge/save-charges';
import { openOverlayPatientInsuranceView } from '../../../../neb-app-layout/neb-open-overlay';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../neb-redux/services/locations';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { FEATURE_FLAGS, getFeatures } from '../../../../neb-utils/feature-util';
import {
  centsToCurrency,
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../neb-utils/formatters';
import { mapToPatientModel } from '../../../../neb-utils/patient';
import { openEncounterSummary } from '../../utils/encounter-overlays-util';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { formatAdjustmentAmount } from '../patients/ledger/charges/neb-ledger-charges-util';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  buttonActions: { id: 'button-actions' },
  colorBox: { selector: '.color-box' },
  date: { selector: '.date' },
  time: { selector: '.time' },
  providerName: { selector: '.provider' },
  appointmentType: { selector: '.apptType' },
  encounterInfo: { selector: '[id^=encounter-info-' },
  invoiceNumber: { selector: '[id^=invoice-info-' },
  planInformationName: { selector: '[id^=plan-information-name-' },
  viewMore: { selector: '[id^=view-more-' },
  actions: { selector: '.actions' },
  status: {
    selector: '.status',
  },
  charges: {
    selector: '.charges',
  },
  encounterNumber: {
    selector: '.encounterNumber',
  },
  invoices: {
    selector: '.invoice',
  },
  patientResponsibility: {
    selector: '.patient-responsibility',
  },
  payerResponsibility: {
    selector: '.payer-responsibility',
  },
  adjustments: {
    selector: '.adjustments',
  },
  location: {
    selector: '.location',
  },
  outstanding: {
    selector: '.outstanding',
  },
  patientLinks: {
    selector: '[id^=patient-]',
  },
  caseLinks: {
    selector: '[id^=case-]',
  },
  carePackageLinks: {
    selector: '[id^=care-package-]',
  },
};

export const NO_ITEMS_TEXT_PATIENT =
  'There are no encounters for this patient.';
export const NO_ITEMS_TEXT_PRACTICE = 'There are no encounters.';

export const NO_ITEMS_TEXT_CASE =
  'There are no encounters linked to this case.';

const TOTAL_DISPLAYABLE_INVOICES = 2;
class NebTableLedgerActivityEncounters extends NebTable {
  static get properties() {
    return {
      includePatient: Boolean,
      hasChartingPermission: Boolean,
      hasBillingPermission: Boolean,
      isInitialLoadDisabled: Boolean,
      config: Array,
      patientId: String,
      fromCase: {
        type: Boolean,
        reflect: true,
      },
      __locations: Array,
      __hasFitInvoiceOverlayPerformanceFF: Boolean,
    };
  }

  constructor() {
    super();

    this.__initServices();
  }

  initState() {
    super.initState();

    this.config = [];
    this.includePatient = false;
    this.hasChartingPermission = false;
    this.hasBillingPermission = false;
    this.isInitialLoadDisabled = false;
    this.patientId = '';
    this.fromCase = false;
    this.__locations = [];
    this.__hasFitInvoiceOverlayPerformanceFF = false;

    this.onPaymentCollected = () => {};

    this.onEditCase = () => {};

    this.onDataChanged = () => {};
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      clickCase: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { caseId } = this.model[rowIndex];
        this.onEditCase(caseId);
      },
      clickEncounter: async ({ name }) => {
        const rowIndex = name.split('.')[1];
        const encounterId = this.model[rowIndex].id;
        const encounter = await getEncounter(encounterId);

        const patient = this.patientId
          ? await patientApiClient.fetchOne(this.patientId, false, true)
          : this.__getPatient(rowIndex);

        await openEncounterSummary({
          patient,
          appointmentTypeId: encounter.appointmentTypeId,
          encounterId,
        });

        this.onDataChanged();
      },
      clickPatient: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const { patientId } = this.model[rowIndex];
        return navigate(`/patients/${patientId}/ledger/activity`);
      },
      clickInvoice: async ({ name }) => {
        const [groupIndex, rowIndex] = name.split('.');
        const { groups = [], patientId } = this.model[rowIndex];
        const patient = await patientApiClient.fetchOne(patientId, false, true);

        const selectGroupByInvoiceNumber = groups[groupIndex] || null;

        const group =
          selectGroupByInvoiceNumber ||
          (await this.__showChargeGroupPopup('View', groups, patient));

        if (!group) return;

        const lineItemIds = group.invoiceId
          ? (await getLedgerInvoiceItems(group.invoiceId)).data.map(
              ({ id }) => id,
            )
          : group.lineItemIds;

        const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

        await openOverlay(overlayKey, {
          patient: this.__getViewChargesPatient(mapToPatientModel(patient)),
          lineItemIds,
          selectedIds: [],
        });

        this.onDataChanged();
      },

      editPackage: async ({ name }) => {
        const [groupIndex, rowIndex] = name.split('.');
        const { groups = [], patientId = '' } = this.model[rowIndex];
        const { multiCarePackage, patientPackageId: id } = groups[groupIndex];

        if (multiCarePackage) {
          await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGES_SUMMARY, {
            patientId,
            lineItems: groups.flatMap(item =>
              item.lineItemIds.map(id => ({ id })),
            ),
          });
        } else {
          await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, {
            item: {
              id,
              patientId,
            },
          });
        }

        this.onDataChanged();
      },

      editPlan: async ({ name }) => {
        const [groupIndex, rowIndex] = name.split('.');
        const { groups = [], patientId = '' } = this.model[rowIndex];
        const { primaryInsuranceId, secondaryInsuranceId } = groups[groupIndex];
        const id = primaryInsuranceId || secondaryInsuranceId;

        await openOverlayPatientInsuranceView({
          patientId,
          patientInsurance: { id },
        });

        this.onDataChanged();
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();

    const featureFlags = await getFeatures();
    this.__hasFitInvoiceOverlayPerformanceFF = featureFlags.includes(
      FEATURE_FLAGS.FIT_INVOICE_OVERLAY_PERFORMANCE,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  __showChargeGroupPopup(label, groups, patient) {
    return groups.length > 1
      ? openPopup(POPUP_RENDER_KEYS.CHARGE_GROUP, {
          confirmLabel: label,
          groups,
          patient,
          includePatient: this.includePatient,
        })
      : groups[0];
  }

  __getPatient(rowIndex) {
    const data = this.model[rowIndex];
    return {
      id: data.patientId,
      medicalRecordNumber: data['patient.medicalRecordNumber'],
      name: {
        first: data['patient.firstName'],
        middle: data['patient.middleName'],
        last: data['patient.lastName'],
        suffix: data['patient.suffix'],
        preferred: data['patient.preferredName'],
      },
    };
  }

  updated() {
    this.updateEmptyMessage();
  }

  updateEmptyMessage() {
    if (this.fromCase) {
      this.emptyMessage = NO_ITEMS_TEXT_CASE;
      return;
    }

    if (this.isInitialLoadDisabled) {
      this.emptyMessage = NO_ITEMS_INITIAL_LOAD;
      return;
    }

    this.emptyMessage = this.includePatient
      ? NO_ITEMS_TEXT_PRACTICE
      : NO_ITEMS_TEXT_PATIENT;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        .color-box {
          position: absolute;
          bottom: 0%;
          width: 20px;
          height: 100%;
          margin-left: -20px;
        }

        .link-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .billing-details {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
        }

        :host([fromCase]) #header {
          padding-top: 20px;
          background-color: white;
          position: sticky;
          top: 0px;
          z-index: 1;
        }
      `,
    ];
  }

  __getViewChargesPatient(patient) {
    return {
      id: patient.id,
      ...(this.includePatient && {
        name: objToName(patient.name, DEFAULT_NAME_OPTS),
        mrn: patient.medicalRecordNumber,
      }),
    };
  }

  __getActions(rowIndex) {
    const actions = [];
    const {
      id,
      patientId,
      groups,
      outstandingBalance,
      unpostedChargeCount,
      signed,
      caseId,
      patientAuthorizationId,
    } = this.model[rowIndex];

    if (groups.some(g => g.patientResponsibility > 0)) {
      actions.push({
        label: 'Collect Payment',
        onSelect: async () => {
          const patient = await patientApiClient.fetchOne(patientId);
          const payment = await openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
            encounterId: id,
            patientId,
            patient,
            chargeInfo: {
              amount: outstandingBalance,
              paymentType: { label: '' },
              alwaysShowActionBar: true,
              allowEditPaymentTypeAndAmount: true,
              hideVoidRefundButton: true,
            },
          });

          if (payment.paid) {
            this.onDataChanged();
          }
        },
      });
    }

    if (groups.some(g => !g.invoiceNumber)) {
      actions.push({
        label: 'Create Invoice',
        onSelect: async () => {
          const filteredGroups = groups.filter(g => !g.invoiceNumber);
          const patient = await patientApiClient.fetchOne(
            patientId,
            false,
            true,
          );

          const group = await this.__showChargeGroupPopup(
            'Create',
            filteredGroups,
            patient,
          );

          if (!group) {
            return undefined;
          }

          try {
            await createAndAddInvoiceToLineItems(
              group.lineItemIds,
              group.patientId,
            );

            store.dispatch(openSuccess('Invoice created successfully'));
            return this.onDataChanged();
          } catch (e) {
            console.error(e);
            store.dispatch(
              openError('An error has occurred when creating the invoice'),
            );

            return undefined;
          }
        },
      });
    }

    if (groups.length) {
      actions.push({
        label: 'View Charges',
        onSelect: async () => {
          const patient = await patientApiClient.fetchOne(
            patientId,
            false,
            true,
          );
          const group = await this.__showChargeGroupPopup(
            'View',
            groups,
            patient,
          );

          if (!group) return;

          const lineItemIds = group.invoiceId
            ? (await getLedgerInvoiceItems(group.invoiceId)).data.map(
                li => li.id,
              )
            : group.lineItemIds;

          const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
            ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
            : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

          await openOverlay(overlayKey, {
            patient: this.__getViewChargesPatient(mapToPatientModel(patient)),
            lineItemIds,
            selectedIds: [],
          });

          this.onDataChanged();
        },
      });
    }

    if (this.hasChartingPermission) {
      actions.push({
        label: 'Manage Encounter',
        onSelect: async () => {
          await openOverlay(OVERLAY_KEYS.MANAGE_ENCOUNTER, {
            patientId,
            encounterIds: [id],
          });

          this.onDataChanged();
        },
      });
    }

    if (unpostedChargeCount > 0 && this.hasBillingPermission) {
      actions.push({
        label: 'Post All Charges',
        onSelect: async () => {
          const pristineCharges = await getEncounterCharges(id);
          const charges = pristineCharges.map(({ encounterId, ...charge }) => ({
            ...charge,
            posted: true,
          }));
          const billingEncounterCharges = await getBillingEncounterCharges(
            charges,
            id,
          );

          await saveCharges({
            pristineCharges,
            charges,
            encounterId: id,
            billingEncounterCharges,
            patientId,
            signed,
          });

          this.onDataChanged();
        },
      });
    }

    actions.push({
      label: 'Update Case',
      onSelect: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.UPDATE_ASSOCIATED_CASE,
          {
            encounterIds: [id],
            patientId,
            defaultCaseId: caseId,
            patientAuthorizationId: patientAuthorizationId || '',
          },
        );

        if (!result) return;

        if (result.type === 'updateCase') {
          const caseUpdated = await updateAssociatedCase({
            encounterIds: [id],
            patientId,
            newCaseId: result.caseId,
            newPatientAuthorizationId: result.patientAuthorizationId,
          });

          if (caseUpdated) this.onDataChanged();
        } else if (result.type === 'addNewCase') {
          const warningMessageResult = await verifyAssociatedInvoices([id]);
          if (!warningMessageResult) return;

          const newCase = await createNewCase(patientId);
          if (!newCase) return;

          const caseUpdated = await updateAssociatedCase({
            encounterIds: [id],
            patientId,
            newCaseId: newCase.id,
            newPatientAuthorizationId: result.patientAuthorizationId,
          });

          if (caseUpdated) this.onDataChanged();
        }
      },
    });

    return actions;
  }

  __renderLinkCell({ value, rowIndex, onClick, id, name }) {
    return html`
      <neb-text
        id="${id}-${rowIndex}"
        bold
        link
        .name="${name}"
        .onClick="${onClick}"
        >${value}</neb-text
      >
    `;
  }

  __renderPlanName({
    planInformationName: value,
    rowIndex,
    billType,
    groupIndex,
  }) {
    return value
      ? html`
          -
          ${
            this.__renderLinkCell({
              value,
              rowIndex,
              name: `${groupIndex}.${rowIndex}`,
              id: `plan-information-name-${rowIndex}`,
              onClick:
                billType === BILL_TYPES.INSURANCE
                  ? this.handlers.editPlan
                  : this.handlers.editPackage,
            })
          }
        `
      : '';
  }

  __renderInvoiceNumber({ rowIndex, invoiceNumber, groupIndex }) {
    return this.__renderLinkCell({
      rowIndex,
      id: `invoice-info-${rowIndex}`,
      name: `${groupIndex}.${rowIndex}`,
      onClick: this.handlers.clickInvoice,
      value: invoiceNumber || 'Not Invoiced',
    });
  }

  __renderInvoices({ invoices, rowIndex }) {
    return invoices.map(
      ({ billType, invoiceNumber, planInformationName, groupIndex }) =>
        html`
          <div class="invoice">
            Invoice:
            ${
              this.__renderInvoiceNumber({
                rowIndex,
                invoiceNumber,
                groupIndex,
              })
            }
            - ${billType}
            ${
              this.__renderPlanName({
                planInformationName,
                rowIndex,
                groupIndex,
                billType,
              })
            }
          </div>
        `,
    );
  }

  __renderViewMore({ showViewMoreLink = false, rowIndex }) {
    const renderProps = {
      value: 'View More',
      id: `view-more-${rowIndex}`,
      name: `view-more.${rowIndex}`,
      rowIndex,
      onClick: this.handlers.clickInvoice,
    };

    return showViewMoreLink
      ? html`
          <div class="view-more">${this.__renderLinkCell(renderProps)}</div>
        `
      : '';
  }

  __getBillType({
    primaryPayerId = null,
    primaryInsuranceId = null,
    secondaryInsuranceId = null,
    primaryPlanName = null,
    secondaryPlanName = null,
    patientPackageId = null,
  }) {
    if (primaryPayerId || primaryInsuranceId || secondaryInsuranceId) {
      return {
        billType: BILL_TYPES.INSURANCE,
        planInformationName: primaryPlanName || secondaryPlanName,
      };
    }

    if (patientPackageId) {
      return {
        billType: BILL_TYPES.CARE_PACKAGE,
      };
    }

    return {
      billType: BILL_TYPES.SELF,
    };
  }

  __renderBillingDetailsColumn({ rowIndex, chargeStatus, groups = [] }) {
    if (chargeStatus === STATUS.NONE) {
      return html`
        <neb-text bold>-</neb-text>
      `;
    }

    const formatedInvoices = groups.map(
      ({ invoiceNumber, ...group }, groupIndex) => {
        const { billType, planInformationName = null } = this.__getBillType(
          group,
        );

        return {
          invoiceNumber,
          billType,
          planInformationName,
          groupIndex,
        };
      },
    );

    const showViewMoreLink =
      formatedInvoices.length > TOTAL_DISPLAYABLE_INVOICES;
    const invoices = formatedInvoices.slice(0, TOTAL_DISPLAYABLE_INVOICES);

    return html`
      ${
        this.__renderInvoices({
          invoices,
          rowIndex,
        })
      }
      ${this.__renderViewMore({ showViewMoreLink, rowIndex })}
    `;
  }

  __renderCheckMarkLink({ rowIndex, groups }) {
    const carePackageGroups = groups.filter(
      group =>
        group.patientPackageId !== null &&
        this.__getBillType(group).billType === BILL_TYPES.CARE_PACKAGE,
    );

    if (carePackageGroups.length) {
      const groupIndex = carePackageGroups.findIndex(
        group => group.patientPackageId !== null,
      );

      return html`
        <neb-text
          id="care-package-${rowIndex}"
          bold
          link
          .name="${groupIndex}.${rowIndex}"
          .onClick="${this.handlers.editPackage}"
          >✓</neb-text
        >
      `;
    }

    return '';
  }

  renderDataCell(value, columnConfig, rowIndex) {
    const {
      postedChargeAmount,
      unpostedChargeAmount,
      status: chargeStatus,
      groups = [],
    } = this.model[rowIndex];

    switch (columnConfig.key) {
      case 'color':
        return html`
          <div class="color-box" style="background-color:${value}"></div>
        `;

      case 'ellipsis':
        return html`
          <neb-button-actions
            id="${ELEMENTS.buttonActions.id}-${rowIndex}"
            class="actions"
            .value="${this.__getActions(rowIndex)}"
            align="left"
          ></neb-button-actions>
        `;

      case 'patient':
        const patient = this.__getPatient(rowIndex);

        return html`
          <div
            id="patient-link-${rowIndex}"
            class="button"
            index="${rowIndex}"
            @click="${this.handlers.clickPatient}"
          >
            <span class="link-text"
              >${
                patient ? objToName(patient.name, DEFAULT_NAME_OPTS) : ''
              }</span
            >
            <neb-icon class="icon" icon="neb:open"></neb-icon>
          </div>
        `;

      case 'caseName':
        return this.__renderLinkCell({
          value,
          rowIndex,
          onClick: this.handlers.clickCase,
          id: 'case',
          name: `case.${rowIndex}`,
        });

      case 'serviceDate':
        return html`
          <div class="text">
            <div class="date">
              <neb-text bold>${parseDate(value).format('MM/DD/YYYY')}</neb-text>
            </div>
            <div class="time">${parseDate(value).format('h:mm A')}</div>
          </div>
        `;

      case 'locationId':
        return html`
          <div class="text">
            <div class="location">
              <neb-text
                >${
                  getLocationValue(this.__locations, value, LOCATION_KEYS.NAME)
                }</neb-text
              >
            </div>
          </div>
        `;

      case 'appointmentType':
        const { provider } = this.model[rowIndex];
        return html`
          <div class="text">
            <div class="provider">${provider}</div>
            <div class="apptType">${value}</div>
          </div>
        `;

      case 'charges':
        const totalCharges = postedChargeAmount + unpostedChargeAmount || 0;

        return html`
          <div class="text">
            <div class="status">Charge Status: ${chargeStatus}</div>
            <div class="charges">
              Total Charges: ${centsToCurrency(totalCharges)}
            </div>
          </div>
        `;

      case 'billingDetails':
        return html`
          <div class="billing-details">
            ${
              this.__renderBillingDetailsColumn({
                rowIndex,
                groups,
                chargeStatus,
              })
            }
          </div>
        `;

      case 'carePackage':
        return html`
          <div>${this.__renderCheckMarkLink({ rowIndex, groups })}</div>
        `;

      case 'number':
        const { encounterNumber, signed } = this.model[rowIndex];
        const status = signed ? 'Signed' : 'Open';
        const encounterValue = `${encounterNumber} - ${status}`;
        return this.__renderLinkCell({
          value: encounterValue,
          rowIndex,
          onClick: this.handlers.clickEncounter,
          id: 'encounter-info',
          name: `encounter-info.${rowIndex}`,
        });

      case 'balance':
        return html`
          <div>
            <div class="patient-responsibility">
              Pat.Resp:
              ${
                this.model[rowIndex].groups.length
                  ? centsToCurrency(
                      this.model[rowIndex].groups.reduce(
                        (sum, g) => sum + g.patientResponsibility,
                        0,
                      ),
                    )
                  : '-'
              }
            </div>
            <div class="payer-responsibility">
              Payers:
              ${
                this.model[rowIndex].groups.length
                  ? centsToCurrency(
                      this.model[rowIndex].groups.reduce(
                        (sum, g) => sum + g.payerResponsibility,
                        0,
                      ),
                    )
                  : '-'
              }
            </div>
            <div class="adjustments">
              Adjustments:
              ${
                this.model[rowIndex].groups.length
                  ? formatAdjustmentAmount(
                      this.model[rowIndex].groups.reduce(
                        (sum, g) => sum + g.adjustments,
                        0,
                      ),
                    )
                  : '-'
              }
            </div>
          </div>
        `;

      case 'outstandingBalance':
        return html`
          <div class="outstanding">
            <neb-text bold>${value ? centsToCurrency(value) : '-'}</neb-text>
          </div>
        `;

      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-ledger-activity-encounters',
  NebTableLedgerActivityEncounters,
);
