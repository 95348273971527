import '../controls/neb-button-action';
import '../neb-action-bar';
import '../neb-header';
import '../neb-loading-overlay';
import '../neb-popup-header';
import '../patients/ledger/neb-ledger-purchase-charges-table';
import '../patients/ledger/neb-ledger-purchase-total-section';

import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { getLowInventoryMessage } from '../../../../../src/api-clients/inventory';
import { formatGuarantorName } from '../../../../neb-api-client/src/formatters/guarantor';
import { createLineItems } from '../../../../neb-api-client/src/ledger/line-items';
import { getPatientGuarantors } from '../../../../neb-api-client/src/patient-guarantor-api-client';
import {
  openSuccess,
  openError,
  openInfo,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { centsToCurrency } from '../../../../neb-utils/formatters';
import {
  calculateAdjustment,
  calculateSubtotal,
  calculateTaxes,
  CODE_TYPE,
  fetchAdjustmentItems,
  fetchAttributeToItems,
  fetchInvoiceItems,
  fetchTaxRateItems,
  invoiceSectionHandlers,
  ITEM_NONE,
  ITEM_SELF,
  LINE_ITEM_TYPE,
} from '../../../../neb-utils/neb-ledger-util';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../neb-utils/neb-refresh';
import {
  ITEM_EMPTY,
  select,
  currency,
  currencyWithNegative,
} from '../../../../neb-utils/selectors';
import { getValueByPath } from '../../../../neb-utils/utils';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';
import { getPurchaseCharges } from '../../utils/purchase-charges';
import {
  NebLedgerInvoiceSection,
  INVOICE_TYPE,
} from '../patients/ledger/neb-ledger-invoice-section';

import NebForm from './neb-form';

export const ADD_CHARGE_CONFIG = {
  tableConfig: [
    {
      key: 'code',
      label: 'Code',
      flex: css`0 0 96px`,
    },
    {
      key: 'description',
      label: 'Description',
      flex: css`1 0 0`,
    },
    {
      key: 'amount',
      label: 'Amount',
      flex: css`0 0 80px`,
      formatter: amount => centsToCurrency(amount || 0),
    },
  ],
  mobileTableConfig: [
    {
      mobile: true,
      key: 'code',
      label: 'Code',
      flex: css`0 0 60px`,
    },
    {
      mobile: true,
      key: 'description',
      label: 'Description',
      flex: css`1 0 0`,
    },
    {
      mobile: true,
      key: 'amount',
      label: 'Amount',
      flex: css`0 0 80px`,
      formatter: amount => centsToCurrency(amount || 0),
    },
  ],
  itemName: 'charge',
  itemPluralName: 'charges',
  title: 'Add Charge - Purchase',
};

export const ELEMENTS = {
  addChargeButton: { id: 'add-charge-button' },
  chargesTable: { id: 'charges-table' },
  description: { id: 'description' },
  header: { id: 'header' },
  totalSection: { id: 'total-section' },
  invoiceSection: { id: 'invoice-section' },
  actionBar: { id: 'action-bar' },
};

const APPEND_KEYS = [
  'id',
  'code',
  'description',
  'units',
  'unitCharge',
  'adjustment',
  'adjustmentAmount',
  'taxId',
  'requirePayment',
  'type',
  'autoInvoice',
];

const renderRequirePaymentCharges = charges =>
  charges.map(
    c => html`
      <p style="font-weight: 700; margin: 10px;">
        ${c.code} - ${c.description}
      </p>
    `,
  );

const generateRequirePaymentMessage = charges => {
  const message = html`
    <div style="padding-bottom: 20px;">
      The following charges require payment at time of charge:
    </div>
    ${renderRequirePaymentCharges(charges)}
    <div style="padding-top: 20px;">
      Please remove charges or elect to receive Payment now and click "Pay".
    </div>
  `;
  return {
    title: 'Payment Required',
    message,
  };
};

const isRequiredIfAdjustment = () => [
  {
    error: 'Required',
    validate: (v, keyPath, model) => {
      const path = keyPath.slice(0, keyPath.length - 1);
      const adjustment = getValueByPath(model, [...path, 'adjustment']);

      return adjustment ? !!v : !v;
    },
  },

  {
    error: 'Must be less than Charge',
    validate: (v, keyPath, model) => {
      const path = keyPath.slice(0, keyPath.length - 1);
      const unitCharge = getValueByPath(model, [...path, 'unitCharge']);
      const units = getValueByPath(model, [...path, 'units']);

      return v <= unitCharge * units;
    },
  },
];

export class NebFormPurchaseCharges extends NebForm {
  static get properties() {
    return {
      __calculations: Object,
      __menuItemsMap: Object,

      invoiceId: String,
      patientId: String,
    };
  }

  static createModel() {
    return {
      radio: INVOICE_TYPE.GENERATE,
      guarantor: ITEM_SELF,
      invoice: ITEM_EMPTY,
      items: [],
      attributeTo: ITEM_NONE,
    };
  }

  static createModelItemsMap() {
    return {
      invoices: [],
      guarantors: [],
      adjustments: [],
      taxRates: [],
      attributeTo: [],
    };
  }

  initState() {
    super.initState();

    this.__menuItemsMap = NebFormPurchaseCharges.createModelItemsMap();
    this.__calculations = {
      adjustments: 0,
      subtotal: 0,
      taxInfo: { taxes: [], total: 0 },
      total: 0,
      totalDisplay: '$0.00',
    };

    this.patientId = '';
    this.invoiceId = '';

    this.onDismiss = () => {};

    this.onDismissAll = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      ...invoiceSectionHandlers.call(this),
      dismiss: () => this.onDismiss(),
      cancel: e => {
        if (this.invoiceId) {
          this.onDismiss(true);
        } else {
          this.onDismissAll(e);
        }
      },
      addCharge: async () => {
        const stateItemsLength = this.state.items.length;
        const stateItems = [...this.state.items];
        const selectedCharges = await openOverlay(
          OVERLAY_KEYS.LEDGER_ADD_CHARGE,
          {
            config: ADD_CHARGE_CONFIG,
            items: await getPurchaseCharges(),
            selectedCharges: stateItems,
            isDirty: this.__dirty,
          },
        );

        selectedCharges
          .filter(
            charge => !this.state.items.find(item => item.id === charge.id),
          )
          .map(charge => this.__formatCharge(charge))
          .forEach((item, index) => {
            this.formService.addItem('items');

            APPEND_KEYS.forEach(key => {
              this.formService.apply(
                `items.${index + stateItemsLength}.${key}`,
                getValueByPath(item, key.split('.')),
              );
            });
          });

        if (this.state.items.some(c => c.autoInvoice) && !this.invoiceId) {
          this.handlers.changeRadio(INVOICE_TYPE.GENERATE);
        }

        this.__calculations = this.__formatCalculations();
      },
      pay: async () => {
        if (this.state.items.length === 0) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Purchase Charges',
            message:
              'You must have at least one charge to make a purchase. Please add at least one charge and save again.',
          });
        }

        if (this.__calculations.total === 0) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Purchase Total',
            message:
              'Purchase total amount must be greater than $0.00 to accept payment.',
          });
        }

        if (this.__calculations.total > 99999999) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Purchase Total',
            message:
              'Purchase total amount may not exceed $999,999.99 to accept payment.',
          });
        }

        const addedLineItems = await this.__handlePayment();

        if (this.invoiceId && addedLineItems.length) {
          this.onChangeDirty(false);
          sendRefreshNotification([REFRESH_CHANGE_TYPE.LEDGER]);
          this.onDismiss(addedLineItems.map(li => li.id));
        }

        return undefined;
      },

      post: () => {
        if (!this.state.items.length) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Purchase Charges',
            message:
              'You must have at least one charge to make a purchase. Please add at least one charge and save again.',
          });
        }

        if (this.formService.validate()) {
          const requirePaymentCharges = this.state.items.filter(
            c => c.requirePayment,
          );

          if (requirePaymentCharges.length) {
            return openPopup(
              POPUP_RENDER_KEYS.MESSAGE,
              generateRequirePaymentMessage(requirePaymentCharges),
            );
          }
          return this.__createLineItems();
        }

        return undefined;
      },
      changeAttributeTo: value => this.formService.apply('attributeTo', value),

      updateCharge: ({ name, value }) => {
        const [index, key] = name.split('.');
        this.formService.apply(`items.${name}`, value);

        if (key === 'adjustment' && equal(value, ITEM_NONE)) {
          this.formService.apply(`items.${index}.adjustmentAmount`, '$0.00');
        }

        if (key === 'unitCharge' || key === 'units') {
          this.formService.validate();
        }

        this.__calculations = this.__formatCalculations();
      },

      removeCharge: async (name, index) => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Remove Charge',
          message: 'Are you sure you want to remove this charge?',
          confirmText: 'Yes',
          cancelText: 'No',
        });
        if (result) this.formService.removeItem(name, index);

        this.__calculations = this.__formatCalculations();
      },
    };
  }

  async __checkLowInventory(args) {
    const { postedChargeIds, postedChargeCodes } = args;

    const inventoryMessage = await getLowInventoryMessage({
      chargeIds: postedChargeIds,
      codes: postedChargeCodes,
    });

    if (inventoryMessage.length) {
      store.dispatch(openInfo(inventoryMessage));
    }
  }

  async __createLineItems() {
    this.__saving = true;

    try {
      const {
        lineItems,
        postedChargeIds,
        postedChargeCodes,
      } = await createLineItems({
        patientId: this.patientId,
        lineItems: this.__formatPurchases(),
        invoiceId: this.state.invoice.data.id || null,
        guarantorId: this.state.guarantor.data.id || null,
        attributeToUserId: this.state.attributeTo.data.id || null,
      });

      this.onChangeDirty(false);
      store.dispatch(openSuccess('Charge(s) posted successfully'));
      sendRefreshNotification([REFRESH_CHANGE_TYPE.LEDGER]);

      await this.__checkLowInventory({ postedChargeIds, postedChargeCodes });

      if (this.invoiceId) {
        this.onDismiss(lineItems.map(li => li.id));
      } else {
        this.onDismissAll();
      }
    } catch (e) {
      console.error(e);
      store.dispatch(openError('An error occurred when posting charge(s)'));
    }
  }

  createSelectors() {
    return {
      children: {
        items: {
          createItem: () => ({
            id: '',
            code: '',
            description: '',
            units: '',
            unitCharge: '',
            adjustment: '',
            adjustmentAmount: '',
            taxId: '',
            requirePayment: '',
            type: '',
            autoInvoice: '',
          }),
          children: {
            $: {
              children: {
                units: {
                  validators: [
                    {
                      error: '',
                      validate: v => !!v,
                    },
                  ],
                },

                unitCharge: currency({
                  validateRaw: true,
                  validators: [],
                }),

                adjustment: select(
                  this.__menuItemsMap.adjustments,
                  ITEM_EMPTY,
                  { validators: [] },
                ),

                adjustmentAmount: currencyWithNegative({
                  validateRaw: true,
                  validators: isRequiredIfAdjustment(),
                }),

                taxId: select(this.__menuItemsMap.taxRates, ITEM_NONE, {
                  validators: [],
                }),
              },
            },
          },
        },
        ...NebLedgerInvoiceSection.createSelectors(this.__menuItemsMap),
      },
    };
  }

  async load() {
    const [
      invoices,
      guarantors,
      taxRates,
      adjustments,
      attributeToItems,
    ] = await Promise.all([
      fetchInvoiceItems(this.patientId, this.invoiceId, true),
      getPatientGuarantors(this.patientId, undefined, true),
      fetchTaxRateItems(true),
      fetchAdjustmentItems(true),
      fetchAttributeToItems(),
    ]);

    const formattedGuarantors = guarantors
      .map(data => ({
        label: formatGuarantorName(data),
        data,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    this.__menuItemsMap = {
      invoices,
      guarantors: [ITEM_SELF, ...formattedGuarantors],
      taxRates,
      adjustments,
      attributeTo: attributeToItems,
    };
  }

  async __handlePayment() {
    if (!this.formService.validate()) {
      return [];
    }

    const result = await openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
      patientId: this.patientId,
      doNotDismissAll: !!this.invoiceId,
      chargeInfo: {
        lineItems: this.__formatPurchases(),
        amount: this.__calculations.total,
        invoiceId: this.state.invoice.data.id || null,
        guarantorId: this.state.guarantor.data.id || null,
        attributeToUserId: this.state.attributeTo.data.id || null,
        hideVoidRefundButton: true,
      },
    });

    return result.payment ? result.payment.lineItems : [];
  }

  __getTaxId(taxId) {
    return taxId
      ? this.__menuItemsMap.taxRates.find(({ data }) => data.id === taxId)
      : ITEM_NONE;
  }

  __formatCharge(charge) {
    return {
      ...charge,
      adjustment: ITEM_NONE,
      adjustmentAmount: '$0.00',
      autoInvoice: charge.autoInvoice || '',
      taxId: this.__getTaxId(charge.taxId),
      unitCharge: centsToCurrency(charge.amount || 0),
      units: '1',
      requirePayment: charge.requirePayment || '',
      type: charge.type || '',
    };
  }

  __formatPurchases() {
    const { items } = this.formService.build();

    return items.map(sc => {
      let adjustments = null;

      if (sc.adjustmentAmount !== 0) {
        adjustments = [
          {
            codeId: sc.adjustment,
            amount: sc.adjustmentAmount,
          },
        ];
      }

      return {
        adjustments,
        codeType: sc.type ? CODE_TYPE.CODE_CHARGE : CODE_TYPE.CHARGE,
        type: LINE_ITEM_TYPE.PURCHASE,
        codeId: sc.id,
        taxId: sc.taxId || null,
        unitCharge: sc.unitCharge,
        units: sc.units,
      };
    });
  }

  __formatCalculations() {
    const calculations = {
      adjustments: calculateAdjustment(this.state.items),
      subtotal: calculateSubtotal(this.state.items),
      taxInfo: calculateTaxes(this.state.items, this.__menuItemsMap.taxRates),
    };

    calculations.total =
      calculations.subtotal -
      calculations.adjustments +
      calculations.taxInfo.total;

    calculations.totalDisplay = centsToCurrency(calculations.total);

    return calculations;
  }

  renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Add Charge - Purchase"
        showBackButton
        showCancelButton
        .onCancel="${this.handlers.cancel}"
        .onBack="${this.handlers.dismiss}"
      ></neb-popup-header>
    `;
  }

  renderActionBar() {
    return this.__dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            removeLabel="CANCEL"
            cancelLabel="POST"
            confirmLabel="PAY"
            .onConfirm="${this.handlers.pay}"
            .onCancel="${this.handlers.post}"
            .onRemove="${this.handlers.dismiss}"
            unelevated-cancel
          ></neb-action-bar>
        `
      : '';
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.description.id}" class="pad">
        Add charges and enter charge details to post against the patient’s
        ledger.
      </div>

      <div class="pad">
        <neb-button-action
          id="${ELEMENTS.addChargeButton.id}"
          label="Add Charge"
          .onClick="${this.handlers.addCharge}"
        ></neb-button-action>
      </div>

      <neb-header label="Charge Details"></neb-header>

      <neb-ledger-purchase-charges-table
        id="${ELEMENTS.chargesTable.id}"
        name="items"
        .layout="${this.layout}"
        .model="${this.state.items}"
        .errors="${this.errors.items}"
        .itemsMap="${this.__menuItemsMap}"
        .onChange="${this.handlers.updateCharge}"
        .onRemove="${this.handlers.removeCharge}"
      ></neb-ledger-purchase-charges-table>

      <neb-ledger-purchase-total-section
        id="${ELEMENTS.totalSection.id}"
        .calculations="${this.__calculations}"
      ></neb-ledger-purchase-total-section>

      <neb-ledger-invoice-section
        id="${ELEMENTS.invoiceSection.id}"
        .model="${this.state}"
        .layout="${this.layout}"
        .isAutoGenerateInvoice="${this.state.items.some(i => i.autoInvoice)}"
        .invoiceId="${this.invoiceId}"
        .itemsMap="${this.__menuItemsMap}"
        .onChangeRadio="${this.handlers.changeRadio}"
        .onChangeGuarantor="${this.handlers.changeGuarantor}"
        .onChangeInvoice="${this.handlers.changeInvoice}"
        .onChangeAttributeTo="${this.handlers.changeAttributeTo}"
      ></neb-ledger-invoice-section>
    `;
  }
}

customElements.define('neb-form-purchase-charges', NebFormPurchaseCharges);
