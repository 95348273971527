import '../../../../neb-table-readonly';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  table: {
    id: 'table',
  },
};
export const CONFIG = [
  {
    key: 'payerPlan',
    label: 'Payer',
    flex: css`2.0`,
    truncate: true,
    formatter: payerPlan => payerPlan.payerName,
  },
  {
    key: 'planInfo',
    label: 'Group ID',
    flex: css`1.5`,
    truncate: true,
    formatter: planInfo => planInfo.groupIdentifier,
  },
  {
    key: 'payerPlan',
    label: 'Type',
    flex: css`1`,
    truncate: true,
    formatter: payerPlan => payerPlan.financialClass,
  },
  {
    key: 'planInfo',
    label: 'Subscriber ID',
    flex: css`2`,
    truncate: true,
    formatter: planInfo => planInfo.subscriberIdentifier,
  },
  {
    key: 'coverageDates',
    label: 'Coverage Dates',
    flex: css`2`,
    truncate: true,
  },
];

class NebPatientInsurancePayerTable extends LitElement {
  static get properties() {
    return {
      patientInsurance: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.patientInsurance = null;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .table {
          border-radius: 5px;
          padding: ${CSS_SPACING} 0;
        }
      `,
    ];
  }

  __renderTable() {
    return this.patientInsurance
      ? html`
          <div class="table">
            <neb-table-readonly
              id="${ELEMENTS.table.id}"
              .config="${CONFIG}"
              .model="${[this.patientInsurance]}"
              .layout="${'large'}"
            >
            </neb-table-readonly>
          </div>
        `
      : '';
  }

  render() {
    return html`
      ${this.__renderTable()}
    `;
  }
}

customElements.define(
  'neb-patient-insurance-payer-table',
  NebPatientInsurancePayerTable,
);
