import '../controls/neb-switch';
import '../inputs/neb-textarea';
import '../inputs/neb-textfield';
import '../neb-progress-blocker';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { BUTTON_ROLE } from '../neb-button';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  blockerDelete: { id: 'blocker-delete' },
  buttonDelete: { id: 'button-delete' },
  textfieldName: { id: 'textfield-name' },
};

export default class FormFeatureFlag extends NebForm {
  static get properties() {
    return {
      __deleting: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: flex;
        }

        .content {
          padding: ${CSS_SPACING};
        }

        .textarea {
          height: 250px;
        }

        .button {
          width: fit-content;
        }
      `,
    ];
  }

  static createModel() {
    return {
      name: '',
    };
  }

  initState() {
    super.initState();

    this.onDelete = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      delete: () => {
        this.__deleting = true;
        this.onDelete(this.model);
      },
    };
  }

  createSelectors() {
    return {};
  }

  __renderDeleteBlocker() {
    return this.__deleting
      ? html`
          <neb-progress-blocker
            id="${ELEMENTS.blockerDelete.id}"
            label="Deleting"
          ></neb-progress-blocker>
        `
      : '';
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.textfieldName.id}"
        class="textfield textfield-name"
        label="Name"
        name="name"
        .error="${this.errors.name}"
        .value="${this.state.name}"
        .onChange="${this.handlers.change}"
        ?disabled="${!!this.model.name}"
        ?valid="${!this.errors.name}"
      ></neb-textfield>
      ${
        this.model.name
          ? html`
              <neb-button
                id="${ELEMENTS.buttonDelete.id}"
                class="button button-delete"
                label="Delete"
                .role="${BUTTON_ROLE.DELETE}"
                .onClick="${this.handlers.delete}"
              ></neb-button>
            `
          : ''
      }
      ${this.__renderDeleteBlocker()}
    `;
  }
}

window.customElements.define('neb-form-feature-flag', FormFeatureFlag);
