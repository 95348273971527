import moment from 'moment-timezone';

import Address from '../../neb-lit-components/src/components/field-groups/neb-address';
import EmailList from '../../neb-lit-components/src/components/field-groups/neb-email-list';
import Name from '../../neb-lit-components/src/components/field-groups/neb-name';
import PhoneList from '../../neb-lit-components/src/components/field-groups/neb-phone-list';

import { DATE_FORMAT } from './document-api-client';
import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

const baseUrl = 'person';

export function mapToModel(raw) {
  return {
    address: raw.address || Address.createModel(),
    emails: raw.emails || EmailList.createModel(),
    phones: raw.phones || PhoneList.createModel(),
    name:
      {
        first: raw.firstName || '',
        last: raw.lastName || '',
        middle: raw.middleName || '',
        preferred: raw.preferredName || '',
        suffix: raw.suffix || '',
      } || Name.createModel(),
    dateOfBirth: raw.dateOfBirth || '',
    sex: raw.sex || '',
    employmentStatus: raw.employmentStatus || '',
  };
}

export function mapToRaw(model) {
  return {
    address: model.address || Address.createModel(),
    emails: model.emails || EmailList.createModel(),
    phones: model.phones || PhoneList.createModel(),
    firstName: model.name.first || '',
    lastName: model.name.last || '',
    middleName: model.name.middle || '',
    preferredName: model.name.preferred || '',
    suffix: model.name.suffix || '',
    dateOfBirth: model.dateOfBirth
      ? moment(model.dateOfBirth).format(DATE_FORMAT)
      : null,
    sex: model.sex || '',
    employmentStatus: model.employmentStatus || null,
  };
}

export function add(
  model,
  optOutLoadingIndicator = false,
  forceCreate = false,
) {
  return apiClient.makeRequest({
    body: JSON.stringify({ ...mapToRaw(model), forceCreate }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    optOutLoadingIndicator,
    path: baseUrl,
    version: 1,
  });
}

export function getPersonByPatientId(patientId, queryParams = {}) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `patients/${patientId}/person`,
    queryParams,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function getPerson(queryParams, optOutLoadingIndicator = false) {
  return apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: baseUrl,
    queryParams,
  });
}

export function fetchOne(queryParams, optOutLoadingIndicator = false) {
  const res = apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: baseUrl,
    queryParams,
  });

  return mapToModel(res.data);
}

export function update(model, optOutLoadingIndicator = false) {
  return apiClient.makeRequest({
    body: JSON.stringify(mapToRaw(model)),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    optOutLoadingIndicator,
    path: `${baseUrl}/${model.id}`,
    version: 1,
  });
}
