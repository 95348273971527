import '../../../../packages/neb-lit-components/src/components/pdf-viewer/neb-pdf-viewer-controller';
import '../../../../packages/neb-document/src/components/neb-image-viewer';
import '../../../../packages/neb-lit-components/src/components/neb-text';
import '../../misc/neb-icon';
import '../../../../packages/neb-material-design/src/components/neb-loading-spinner';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { DATE_FORMAT } from '../../../../packages/neb-api-client/src/document-api-client';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { createObjectURL } from '../../../../packages/neb-utils/blobProcessor';
import {
  baseStyles,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../styles';

const PDF_MIME_TYPE = 'application/pdf';
const ID_DOCUMENT_VIEWER_CONTENT = 'document-viewer-content';

export const ELEMENTS = {
  imageViewer: {
    id: 'image',
  },
  pdfViewer: {
    id: 'pdf',
  },
  title: {
    id: 'title',
  },
  fullScreenButton: {
    id: 'document-fullscreen-button',
  },
  uploadDate: {
    id: 'upload-date',
  },
  documentDate: {
    id: 'document-date',
  },
  documentTags: {
    id: 'document-tags',
  },
  iconClose: {
    id: 'icon-close',
  },
  loadingSpinner: {
    id: 'loading-spinner',
  },
};

class NebPageDocumentViewer extends LitElement {
  static get properties() {
    return {
      __url: String,
      selectedDocument: Object,
      imageSrcSpec: Object,
      showLocalLoading: Boolean,
      loading: Boolean,
    };
  }

  constructor() {
    super();

    this.__initHandlers();
    this.__initState();
  }

  __initState() {
    this.__url = null;
    this.selectedDocument = null;
    this.imageSrcSpec = null;
    this.loading = true;

    this.onClose = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      getDocumentContentSize: () => this.__getDocumentContentSize(),
      fullscreenContent: () =>
        openPopup(POPUP_RENDER_KEYS.FULLSCREEN_CONTENT, {
          src: this.imageSrcSpec.src,
          name: this.__selectedDocument.name,
          mimeType: this.__imageSrcSpec ? this.__imageSrcSpec.mimeType : null,
        }),
      closeDocument: () => this.onClose(),
    };
  }

  __getDocumentContentSize() {
    const content = this.shadowRoot.getElementById(ID_DOCUMENT_VIEWER_CONTENT);

    if (content && content.clientHeight && content.clientWidth) {
      return {
        width: content.clientWidth,
        height: content.clientHeight,
      };
    }

    return {
      width: null,
      height: null,
    };
  }

  reset() {
    const imageViewer = this.shadowRoot.getElementById(ELEMENTS.imageViewer.id);

    if (imageViewer) {
      imageViewer.identity();
    }
  }

  async updated(changedProps) {
    if (changedProps.has('imageSrcSpec')) {
      if (this.imageSrcSpec && this.imageSrcSpec.src) {
        this.reset();
        this.__url = await createObjectURL(this.imageSrcSpec.src);
      }
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .document-view-container {
          position: relative;
          height: 100%;
          width: 100%;
          min-width: 300px;
          min-height: 300px;
        }

        .content {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .content[pdf] {
          width: 100%;
          height: 100%;
        }

        .pdf-container {
          height: 100%;
          width: 100%;
        }

        .header-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
        }

        .sub-header-container {
          display: flex;
          justify-content: space-between;
          align-items: end;
          padding-bottom: 10px;
        }

        .icon {
          height: 24px;
          width: 24px;
          fill: ${CSS_COLOR_GREY_1};
          cursor: pointer;
        }

        .icon-link {
          height: 15px;
          width: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .title {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .button-field {
          display: flex;
          align-items: center;
          cursor: pointer;
          gap: 5px;
        }

        .button-field:hover {
          opacity: 0.65;
        }

        .date {
          text-align: end;
        }

        .tags-container {
          padding-bottom: ${CSS_SPACING};
        }

        .loading-spinner {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 50%;
        }
      `,
    ];
  }

  __renderByMimeType(mimeType) {
    switch (mimeType) {
      case PDF_MIME_TYPE:
        return html`
          <neb-pdf-viewer-controller
            id="${ELEMENTS.pdfViewer.id}"
            class="pdf-container"
            .src="${this.imageSrcSpec.src}"
          ></neb-pdf-viewer-controller>
        `;

      case 'image/jpeg':
      case 'image/png':
        return html`
          <neb-image-viewer
            id="${ELEMENTS.imageViewer.id}"
            maxZoom="5"
            .src="${this.imageSrcSpec.src}"
            .onGetDocumentContentSize="${
              this.__handlers.getDocumentContentSize
            }"
            .model="${this.selectedDocument}"
            .hideFooter="${true}"
          ></neb-image-viewer>
        `;

      default:
        return html``;
    }
  }

  __renderHeader() {
    const title = this.selectedDocument.name;

    return html`
      <div class="header-container">
        <neb-text .id="${ELEMENTS.title.id}" class="title">${title}</neb-text>
        <neb-icon
          id="${ELEMENTS.iconClose.id}"
          class="icon"
          icon="neb:close"
          @click="${this.__handlers.closeDocument}"
        ></neb-icon>
      </div>
    `;
  }

  __renderSubHeader() {
    const { uploadDate, documentDate } = this.selectedDocument;

    return html`
      <div class="sub-header-container">
        <div
          id="${ELEMENTS.fullScreenButton.id}"
          class="button-field"
          @click="${this.__handlers.fullscreenContent}"
        >
          <neb-icon class="icon-link" icon="neb:fullscreen"></neb-icon>
          <neb-text link>Fullscreen</neb-text>
        </div>
        <div>
          <div id="${ELEMENTS.uploadDate.id}" class="date">
            Upload Date: ${uploadDate.format(DATE_FORMAT)}
          </div>
          <div id="${ELEMENTS.documentDate.id}" class="date">
            Document Date: ${documentDate.format(DATE_FORMAT)}
          </div>
        </div>
      </div>
    `;
  }

  __renderDocumentTags() {
    const { documentTags } = this.selectedDocument;

    return documentTags && documentTags.length
      ? html`
          <neb-tags-list
            id="${ELEMENTS.documentTags.id}"
            class="tags-container"
            .model="${{ tags: documentTags }}"
          >
          </neb-tags-list>
        `
      : '';
  }

  __renderDocument() {
    const mimeType = this.__imageSrcSpec ? this.__imageSrcSpec.mimeType : null;
    const isPdf = mimeType === PDF_MIME_TYPE;

    return html`
      <div class="document-view-container">
        ${this.__renderLoadingSpinner()}
        <div id="${ID_DOCUMENT_VIEWER_CONTENT}" class="content" ?pdf="${isPdf}">
          ${mimeType ? this.__renderByMimeType(mimeType) : ''}
        </div>
      </div>
    `;
  }

  __renderLoadingSpinner() {
    return this.loading
      ? html`
          <div id="${ELEMENTS.loadingSpinner.id}" class="loading-spinner">
            <neb-loading-spinner></neb-loading-spinner>
            <div>Loading Document</div>
          </div>
        `
      : '';
  }

  render() {
    return html`
      ${this.__renderHeader()} ${this.__renderSubHeader()}
      ${this.__renderDocumentTags()} ${this.__renderDocument()}
    `;
  }
}

customElements.define('neb-page-document-viewer', NebPageDocumentViewer);
