import '../neb-radio-button';
import '../neb-header';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../neb-tooltip';
import '../tables/neb-table-patient-package-charges';

import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import {
  formatPatientPackageForPost,
  getPatientPackageExists,
  createPatientPackage,
} from '../../../../../src/api-clients/patient-package';
import {
  baseStyles,
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../../src/styles';
import { BILL_TYPE_DEFAULT_CARE_PACKAGE } from '../../../../../src/utils/user-message';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getPatientRelationshipsActiveGroup } from '../../../../neb-api-client/src/patient-relationship-api-client';
import {
  getMerchantAccounts,
  TOOL_TIP_SUBSCRIPTION_NOT_ALLOWED,
  TOOL_TIP_SUBSCRIPTION_ALLOWED,
} from '../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import { updateRecurringPayment } from '../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import {
  openSuccess,
  openError,
  openInfo,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  PACKAGE_TYPE,
  SCHEDULED_PAYMENT_TYPE,
  SELECT_CHARGES_OVERLAY_TYPE,
  PAYMENT_FREQUENCY_OPTIONS,
  PAYMENT_FREQUENCY,
} from '../../../../neb-utils/enums';
import {
  objToName,
  DEFAULT_NAME_OPTS,
  toNumeric,
  normalizeForSearch,
} from '../../../../neb-utils/formatters';
import { currency, number } from '../../../../neb-utils/masks';
import {
  CODE_TYPE,
  LINE_ITEM_TYPE,
} from '../../../../neb-utils/neb-ledger-util';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../neb-utils/neb-refresh';
import {
  mapChargesToPatientPackageCharges,
  mapPatientPackageChargesToCharges,
  getSummaryDescription,
  formatUsageUnit,
  formatFrequencyPhrase,
} from '../../../../neb-utils/patientPackage';
import * as selectors from '../../../../neb-utils/selectors';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { required, range } from '../../../../neb-utils/validators';
import { openBulkUpdatePopup } from '../../utils/charge-overlays-util';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';
import { getPackageSubscriptionChargeCodes } from '../../utils/package-subscription-charge-codes';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';
import FormScheduledPaymentRecurring from './neb-form-scheduled-payment-recurring';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  visitsRadioButton: { id: 'visits-radio' },
  checkboxSharedCarePackage: { id: 'checkbox-shared-care-package' },
  patientPackageRelatedPatients: { id: 'patient-package-related-patients' },
  servicesRadioButton: { id: 'services-radio' },
  templateDropdown: { id: 'template' },
  nameField: { id: 'name' },
  subscriptionCheckbox: { id: 'subscription-checkbox' },
  tooltip: { id: 'tooltip' },
  summary: { id: 'summary' },
  effectiveDate: { id: 'effective-date' },
  expirationDate: { id: 'expiration-date' },
  totalVisitsField: { id: 'total-visits' },
  totalPriceField: { id: 'total-price' },
  frequencyDropdown: { id: 'frequency' },
  unlimitedCheckbox: { id: 'unlimited' },
  unlimitedTooltip: { id: 'unlimited-tooltip' },
  addChargesButton: { id: 'add-charges' },
  searchField: { id: 'search' },
  maxUnitsField: { id: 'max-units' },
  bulkUpdateIcon: { id: 'bulk-icon' },
  bulkUpdateTooltip: { id: 'bulk-tooltip' },
  chargesTable: { id: 'charges-table' },
  pagination: { id: 'pagination' },
  removeIcon: { id: 'remove-icon' },
  defaultCheckbox: { id: 'default-checkbox' },
  limitsHeader: { id: 'limits-header' },
};

const PACKAGE_SUMMARY_HEADER = 'Package Summary';
const TOTAL_VISITS_ERROR = 'Required, must be between 1 - 999';
const TOTAL_PRICE_ERROR = 'Required, must be between $0.01 and $99,999.99';
const DUPLICATE_MSG = {
  confirmText: 'Yes',
  title: 'Duplicate Patient Package Name',
  message:
    'There is an existing package for this patient with the same name. Please change the name to save this package for the patient.',
};

export const NO_CHARGES_TEXT =
  'There are no charges for this package. Click "Add Charges" to include covered services.';

export const PATIENT_PACKAGE_SUCCESS_MESSAGE =
  'Patient package added successfully';
export const PATIENT_PACKAGE_ERROR_MESSAGE = 'Unable to add Patient package';
export const PKG_PAYMENT_TYPE = 'Pkg - Package';
const SUB_PAYMENT_TYPE = 'Sub - Subscription';
const DATE_FORMAT = 'MM/DD/YYYY';

class NebFormPatientPackageAdd extends NebForm {
  static get properties() {
    return {
      __collectionState: Object,
      __maxUnits: String,
      __maxUnitsError: String,
      __packageTemplateItem: Object,
      __packageTemplateError: String,
      __processing: Boolean,
      __relationships: Array,
      __hasPaymentFrequencyFeature: Boolean,

      packageTemplates: Array,
      patientId: String,
      disableDefault: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .row {
          display: flex;
          padding-left: ${CSS_SPACING};
        }

        .radio-buttons {
          padding-left: 10px;
        }

        .template-dropdown {
          flex: 1;
          padding-top: ${CSS_SPACING_ROW};
        }

        .package-name {
          flex: 1;
          padding: 10px ${CSS_SPACING} 0;
        }

        .default-checkbox {
          padding-top: ${CSS_SPACING};
        }

        .subscription-checkbox {
          padding: ${CSS_SPACING} 0;
        }

        .shared-care-package-checkbox {
          flex: 1;
          padding-bottom: ${CSS_SPACING};
        }

        .shared-care-package-select {
          flex: 1;
          margin-top: -${CSS_SPACING};
          padding-right: ${CSS_SPACING};
          padding-left: ${CSS_SPACING};
        }

        .unlimited-row {
          display: flex;
        }

        .unlimited-checkbox {
          padding: ${CSS_SPACING} 0;
        }

        .tooltip {
          padding: ${CSS_SPACING} 0 0 10px;
        }

        .effective-date {
          flex: 1;
          padding-top: ${CSS_SPACING};
        }

        .expiration-date {
          flex: 1;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .summary-container {
          display: flex;
          flex-direction: column;
          padding-left: ${CSS_SPACING};
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .limits-header {
          padding-top: 40px;
        }

        .section {
          display: grid;
          margin: ${CSS_SPACING};
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }

        .section[subscription][type='visit'] {
          grid-template-columns: 1fr 1fr 1fr;
          grid-row-gap: 5px;
        }

        .charges-section {
          padding-top: ${CSS_SPACING};
        }

        .charges-section[dirty] {
          padding-bottom: 80px;
        }

        .pagination {
          flex-direction: row-reverse;
          padding-top: ${CSS_SPACING};
          padding-right: 10px;
          margin-bottom: ${CSS_SPACING};
        }

        .add-and-search-container {
          display: flex;
          margin: 0 ${CSS_SPACING};
          flex-flow: column nowrap;
        }

        .add-and-update-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: ${CSS_SPACING} 0 10px 0;
        }

        .flex-center {
          display: flex;
          align-items: center;
          height: 68px;
        }

        .max-units-field {
          padding-top: 7px;
          width: 100px;
          height: 54px;
        }

        .max-units-label {
          padding-right: 10px;
        }

        .icon-bulk {
          height: 28px;
          width: 28px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-bulk[disabled] {
          fill: ${CSS_COLOR_GREY_1};
        }

        .bulk-tooltip {
          padding-left: 12px;
        }

        .action-bar {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      `,
    ];
  }

  static createModel() {
    return {
      active: true,
      charges: [],
      completedDate: null,
      effectiveDate: null,
      encounterCharges: [],
      expirationDate: null,
      frequency: null,
      id: '',
      name: '',
      patientId: '',
      patientPackageRelatedPatients: null,
      sharedCarePackage: false,
      subscription: false,
      totalVisits: '',
      totalPrice: '',
      type: PACKAGE_TYPE.VISIT,
      unlimited: false,
      isDefault: false,
    };
  }

  createSelectors() {
    return {
      children: {
        name: {
          validators: [
            required('Required'),
            {
              error: 'Required',
              validate: _v => !this.__packageNameExists,
            },
          ],
        },
        effectiveDate: {
          unsafe: true,
          clipPristine: true,
          format: v => (v ? moment(v).format(DATE_FORMAT) : ''),
          unformat: v => {
            if (!v || new Date(v, DATE_FORMAT).toString() === 'Invalid Date') {
              return null;
            }

            return moment(v);
          },
          validators: [required()],
        },
        totalVisits: selectors.numeric(0, {
          validators: [
            {
              error: TOTAL_VISITS_ERROR,
              validate: v =>
                this.state.type === PACKAGE_TYPE.SERVICE ||
                this.state.unlimited ||
                v,
            },
            range(1, 999, {
              error: TOTAL_VISITS_ERROR,
            }),
          ],
        }),
        totalPrice: selectors.currency({
          validators: [
            required(TOTAL_PRICE_ERROR),
            range(1, 9999999, {
              error: TOTAL_PRICE_ERROR,
            }),
          ],
        }),
        frequency: {
          validators: [
            {
              error: 'Required',
              validate: v => !this.state.subscription || v,
            },
          ],
        },
        patientPackageRelatedPatients: {
          unsafe: true,
          clipPristine: true,
          validators: [
            {
              error: 'Required',
              validate: v =>
                !this.state.sharedCarePackage || (v && v.length > 1),
            },
          ],
        },
        charges: {
          unsafe: true,
          createItem: () => ({
            id: '',
            quantity: '',
            chargeId: '',
            active: true,
            charge: {
              amount: '',
              description: '',
              procedure: '',
              modifiers: ['', '', '', ''],
            },
          }),
          children: {
            $: {
              children: {
                quantity: {
                  validators: [
                    {
                      error: '1 - 999',
                      validate: v =>
                        this.state.type !== PACKAGE_TYPE.SERVICE || v,
                    },
                    {
                      error: '1 - 999',
                      validate: v => {
                        if (this.state.type !== PACKAGE_TYPE.SERVICE) {
                          return true;
                        }
                        const passesMin = v >= 1;
                        const passesMax = v <= 999;
                        return v === '' || (passesMin && passesMax);
                      },
                    },
                  ],
                },
                charge: {
                  clipPristine: true,
                },
              },
            },
          },
        },
      },
    };
  }

  constructor() {
    super();

    this.__initServices();
  }

  initState() {
    super.initState();

    this.__collectionState = CollectionService.createModel();
    this.__maxUnits = '';
    this.__maxUnitsError = '';
    this.__merchantAccounts = [];
    this.__packageTemplateItem = {};
    this.__packageTemplateError = '';
    this.__processing = false;
    this.__purchaseCharges = [];
    this.__relationships = [];
    this.__today = parseDate();

    this.packageTemplates = [];
    this.patientId = '';
    this.disableDefault = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeType: e => {
        if (
          e.value !== this.state.type &&
          this.__packageTemplateItemSelected()
        ) {
          this.__clearPackageTemplateFields();
        }

        this.formService.apply('type', e.value);
      },
      changeSubscription: e => {
        if (!e.value) {
          this.formService.apply('unlimited', false);
        } else {
          this.formService.apply('effectiveDate', parseDate());
          this.formService.apply('expirationDate', null);
        }

        this.formService.apply('subscription', e.value);
        this.__updateEffectiveDateSelectable();
        this.__updateExpirationDateSelectable();
      },
      changeTemplate: ({ value }) => {
        if (!Object.keys(value).length) {
          this.__packageTemplateError = 'Required';
        } else this.__packageTemplateError = '';

        if (equal(this.__packageTemplateItem, value)) return;

        const { item } = value;

        if (item) {
          this.formService.apply('effectiveDate', parseDate());
          this.formService.apply('expirationDate', null);
          this.formService.apply('frequency', item.frequency);
          this.formService.apply('subscription', item.subscription);
          this.formService.apply('totalPrice', item.totalPrice);
          this.formService.apply(
            'totalVisits',
            item.type !== PACKAGE_TYPE.SERVICE && !item.unlimited
              ? `${item.totalVisits}`
              : '',
          );

          this.formService.apply('isDefault', this.disableDefault);
          this.formService.apply('unlimited', item.unlimited);
          const mappedCharges = mapChargesToPatientPackageCharges(item.charges);

          this.__updateEffectiveDateSelectable();
          this.__updateExpirationDateSelectable();
          this.__applyCharges(mappedCharges);
        }

        this.__packageTemplateItem = value;
      },
      changeEffectiveDate: ({ value }) => {
        if (value !== this.state.effectiveDate) {
          this.formService.apply('effectiveDate', value);
          this.__updateExpirationDateSelectable();
        }
      },
      clearEffectiveDate: () => {
        this.formService.apply('effectiveDate', null);

        if (this.state.subscription) {
          this.formService.apply('expirationDate', null);
        }
      },
      changeExpirationDate: ({ value }) => {
        if (value !== this.state.expirationDate) {
          this.formService.apply('expirationDate', value);
          this.__updateEffectiveDateSelectable();
        }
      },
      changeFrequency: ({ value }) => {
        if (value !== this.state.frequency) {
          this.formService.apply('frequency', value);
          this.__updateEffectiveDateSelectable();
          this.__updateExpirationDateSelectable();

          if (
            this.state.expirationDate &&
            !this.handlers.expirationDateSelectable(this.state.expirationDate)
          ) {
            this.formService.apply('expirationDate', null);
          }
        }
      },
      changeUnlimited: e => {
        this.formService.apply('unlimited', e.value);
        this.formService.apply('totalVisits', '');
      },
      changeName: ({ value }) => {
        this.formService.apply('name', value);
        this.__packageNameExists = false;
      },
      searchCharges: ({ value }) => {
        if (value !== this.__collectionState.searchText) {
          this.__collectionService.search(value);
        }
      },
      clearSearch: () => this.__collectionService.search(''),
      addCharge: async () => {
        const selectedCharges = await openOverlay(OVERLAY_KEYS.SELECT_CHARGES, {
          charges: mapPatientPackageChargesToCharges(this.state.charges),
          type:
            this.state.type === PACKAGE_TYPE.VISIT
              ? SELECT_CHARGES_OVERLAY_TYPE.NO_UNITS
              : SELECT_CHARGES_OVERLAY_TYPE.SHOW_UNITS,
        });
        const mappedCharges = mapChargesToPatientPackageCharges(
          selectedCharges,
        );

        this.__applyCharges(mappedCharges);
      },
      removeCharge: async (_, idx) => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Remove Charge',
          message:
            'This will remove the charge from the package. Are you sure that you want to proceed?',
          confirmText: 'YES',
          cancelText: 'NO',
        });

        if (result) {
          const idxToRemove = this.__findChargeIdx(idx);

          this.formService.removeItem('charges', idxToRemove);
        }
      },
      changeQuantity: e => {
        const pageItemsIdx = e.name.split('.')[1];
        const charge = { ...this.__collectionState.pageItems[pageItemsIdx] };

        const idxToChange = this.__findChargeIdx(pageItemsIdx);

        charge.quantity = e.value;
        this.formService.apply(`charges.${idxToChange}.quantity`, e.value);
      },
      changeMaxUnits: ({ value }) => {
        this.__maxUnits = value;
        if (this.__maxUnits.length === 0 || this.__maxUnits < 1) {
          this.__maxUnitsError = '1 - 999';
        } else this.__maxUnitsError = '';
      },
      bulkUpdate: async () => {
        if (this.__maxUnits.length === 0 || this.__maxUnitsError.length > 0) {
          return;
        }
        const result = await openBulkUpdatePopup(this.__maxUnits);

        if (result) {
          const charges = this.state.charges.map(charge => {
            if (this.__doesChargeMatchFilter(charge)) {
              return { ...charge, quantity: this.__maxUnits };
            }
            return charge;
          });

          this.__applyCharges(charges);
        }
      },
      changeCollectionState: state => {
        this.__collectionState = state;
      },
      cacheItem: ({ charge: { procedure, description } }) =>
        normalizeForSearch([procedure, description].join(' ')),
      search: ({ terms, item }) => terms.every(term => item.includes(term)),
      sort: (a, b) => {
        if (a.charge.procedure > b.charge.procedure) {
          return 1;
        }

        if (a.charge.procedure < b.charge.procedure) {
          return -1;
        }

        if (a.charge.description > b.charge.description) {
          return 1;
        }

        if (a.charge.description < b.charge.description) {
          return -1;
        }

        return 0;
      },
      pageChanged: index => this.__collectionService.setPageIndex(index),
      pay: async () => {
        const valid = await this.__validate();

        if (valid) {
          this.__processing = true;

          const rawPackage = formatPatientPackageForPost({
            ...this.state,
            patientId: this.patientId,
          });

          const { totalPrice } = rawPackage;

          const paymentType = this.state.subscription
            ? SUB_PAYMENT_TYPE
            : PKG_PAYMENT_TYPE;

          const typeCode = this.state.subscription ? 'Sub' : 'Pkg';

          const chargeCodeId = this.__purchaseCharges.find(
            c => c.code === typeCode,
          ).id;

          try {
            if (this.state.subscription) {
              const recurringPaymentResult = await openOverlay(
                OVERLAY_KEYS.SCHEDULED_PAYMENT,
                {
                  type: SCHEDULED_PAYMENT_TYPE.RECURRING,
                  patientId: this.patientId,
                  item: {
                    ...FormScheduledPaymentRecurring.createModel(),
                    name: this.state.name,
                    amount: toNumeric(this.state.totalPrice),
                    start: rawPackage.effectiveDate,
                    end: rawPackage.expirationDate,
                    frequency: this.state.frequency,
                  },
                  forPatientPackage: true,
                  stayDirty: true,
                },
              );

              if (recurringPaymentResult && recurringPaymentResult.id) {
                const result = await createPatientPackage(this.patientId, {
                  ...rawPackage,
                });

                const patientPackageId = result.id;

                await updateRecurringPayment(this.patientId, {
                  ...recurringPaymentResult.recurringPayment,
                  patientPackageId,
                });

                this.onChangeDirty(false);
                this.__handleDefaultBillTypeBanner(result);
                this.onSave(result);

                return;
              }
            } else {
              const paymentResult = await openOverlay(
                OVERLAY_KEYS.ADD_PATIENT_PAYMENT,
                {
                  patientId: this.patientId,
                  doNotDismissAll: true,
                  packageOrSubscription: true,
                  chargeInfo: {
                    amount: totalPrice,
                    paymentType,
                    alwaysShowActionBar: true,
                    lineItems: [
                      {
                        codeType: CODE_TYPE.CODE_CHARGE,
                        codeId: chargeCodeId,
                        unitCharge: totalPrice,
                        units: 1,
                        type: LINE_ITEM_TYPE.PURCHASE,
                      },
                    ],
                  },
                },
              );

              if (paymentResult.paid) {
                const {
                  lineItems: [{ id: purchaseLineItemId }],
                } = paymentResult.payment;

                const result = await createPatientPackage(this.patientId, {
                  ...rawPackage,
                  purchaseLineItemId,
                });

                this.__handleDefaultBillTypeBanner(result);

                sendRefreshNotification(
                  [
                    REFRESH_CHANGE_TYPE.PATIENT_PACKAGE,
                    REFRESH_CHANGE_TYPE.SCHEDULED_PAYMENTS,
                  ],
                  this.patientId,
                );

                this.onChangeDirty(false);
                this.onSave(result);

                return;
              }
            }
          } catch (error) {
            store.dispatch(openError(PATIENT_PACKAGE_ERROR_MESSAGE));
          }
        }

        this.__blurActionBar();

        this.__processing = false;
      },
      post: async () => {
        const valid = await this.__validate();

        if (valid) {
          this.__processing = true;

          try {
            const result = await createPatientPackage(
              this.patientId,
              formatPatientPackageForPost({
                ...this.state,
                patientId: this.patientId,
              }),
            );

            this.__handleDefaultBillTypeBanner(result);

            sendRefreshNotification(
              [REFRESH_CHANGE_TYPE.PATIENT_PACKAGE, REFRESH_CHANGE_TYPE.LEDGER],
              this.patientId,
            );

            this.onChangeDirty(false);
            this.onSave(result);

            return;
          } catch (error) {
            store.dispatch(openError(PATIENT_PACKAGE_ERROR_MESSAGE));
          }
        }

        this.__blurActionBar();

        this.__processing = false;
      },
    };

    this.__updateEffectiveDateSelectable();
    this.__updateExpirationDateSelectable();
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: this.handlers.changeCollectionState,
        onCacheItem: this.handlers.cacheItem,
        onSearch: this.handlers.search,
        onSort: this.handlers.sort,
      },
      {
        sortParams: {
          key: '',
          dir: 'asc',
        },
      },
    );
  }

  async connectedCallback() {
    this.__merchantAccounts = await getMerchantAccounts({ hideInactive: true });

    this.__purchaseCharges = await getPackageSubscriptionChargeCodes();

    super.connectedCallback();
  }

  async __validate() {
    if (this.shadowRoot.getElementById(ELEMENTS.expirationDate.id)) {
      const expirationDateErrors = this.shadowRoot.getElementById(
        ELEMENTS.expirationDate.id,
      ).invalidTextInput;

      if (expirationDateErrors) return false;
    }

    if (!this.__packageTemplateItemSelected()) {
      this.__packageTemplateError = 'Required';
      return false;
    }

    if (!this.state.charges.length) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: 'Package Charges',
        message:
          'You must have at least one charge to create a package. Please add at least one charge and save again.',
      });

      return false;
    }

    if (!this.state.name) {
      this.formService.apply('name', this.__packageTemplateItem.label);
    }

    const { exists } = await getPatientPackageExists(
      this.patientId,
      {
        name: this.state.name,
      },
      true,
    );

    this.__packageNameExists = exists;

    const valid = this.formService.validate();

    if (this.__packageNameExists) {
      await openPopup('message', DUPLICATE_MSG);
    }

    return valid;
  }

  __handleDefaultBillTypeBanner(result) {
    if (result.isBillTypeUpdated) {
      store.dispatch(openInfo(BILL_TYPE_DEFAULT_CARE_PACKAGE));
    }

    store.dispatch(openSuccess(PATIENT_PACKAGE_SUCCESS_MESSAGE));
  }

  __blurActionBar() {
    if (this.__processing) return;

    this.shadowRoot.getElementById(ELEMENTS.actionBar.id).blur();
  }

  __removeCharges() {
    for (
      let countCharges = this.state.charges.length;
      countCharges > 0;
      countCharges--
    ) {
      this.formService.removeItem('charges', countCharges - 1);
    }
  }

  __applyCharges(charges) {
    this.__removeCharges();

    charges.forEach((charge, idx) => {
      this.formService.addItem('charges');
      this.formService.apply(`charges.${idx}.id`, charge.id);
      this.formService.apply(`charges.${idx}.quantity`, charge.quantity);
      this.formService.apply(`charges.${idx}.chargeId`, charge.chargeId);
      this.formService.apply(`charges.${idx}.active`, charge.active);

      this.formService.apply(`charges.${idx}.charge`, charge.charge);
    });
  }

  __doesChargeMatchFilter(charge) {
    const searchTerms = this.__collectionState.searchText.trim().split(' ');

    return searchTerms.every(
      text =>
        charge.charge.procedure.toLowerCase().includes(text.toLowerCase()) ||
        charge.charge.description.toLowerCase().includes(text.toLowerCase()),
    );
  }

  __findChargeIdx(pageItemsIdx) {
    return this.state.charges.findIndex(
      charge =>
        charge.chargeId ===
        this.__collectionState.pageItems[pageItemsIdx].chargeId,
    );
  }

  __interval() {
    switch (this.state.frequency) {
      case PAYMENT_FREQUENCY.WEEKLY:
        return [1, 'week'];
      case PAYMENT_FREQUENCY.BI_WEEKLY:
        return [2, 'weeks'];
      default:
        return [1, 'month'];
    }
  }

  __updateEffectiveDateSelectable() {
    if (this.state.subscription) {
      this.handlers.effectiveDateSelectable = date =>
        date.isSameOrAfter(this.__today, 'day') &&
        (this.state.expirationDate === null ||
          date.isSameOrBefore(
            parseDate(this.state.expirationDate).subtract(...this.__interval()),
            'day',
          ));
    } else {
      this.handlers.effectiveDateSelectable = date =>
        this.state.expirationDate === null || date < this.state.expirationDate;
    }
  }

  __updateExpirationDateSelectable() {
    if (this.state.subscription) {
      this.handlers.expirationDateSelectable = date =>
        date.isSameOrAfter(this.__today, 'day') &&
        (this.state.effectiveDate === null ||
          date.isSameOrAfter(
            parseDate(this.state.effectiveDate).add(...this.__interval()),
            'day',
          ));
    } else {
      this.handlers.expirationDateSelectable = date =>
        this.state.effectiveDate === null || date > this.state.effectiveDate;
    }

    if (this.shadowRoot) {
      const expirationDateField = this.shadowRoot.getElementById(
        ELEMENTS.expirationDate.id,
      );

      if (expirationDateField) {
        expirationDateField.validateDate();
      }
    }
  }

  __packageTemplateItemSelected() {
    return this.__packageTemplateItem.item !== undefined;
  }

  __subscriptionsDisabled() {
    if (this.__merchantAccounts.length === 0) return true;

    return !this.__packageTemplateItemSelected();
  }

  __formatToItems(items) {
    return items.map(data => ({
      label: objToName(data.name, DEFAULT_NAME_OPTS),
      data,
    }));
  }

  __clearPackageTemplateFields() {
    this.formService.apply('subscription', false);
    this.formService.apply('totalPrice', '');
    this.formService.apply('totalVisits', '');
    this.formService.apply('unlimited', false);
    this.formService.apply('effectiveDate', null);
    this.formService.apply('expirationDate', null);
    this.formService.apply('sharedCarePackage', false);

    let primaryPatient = null;

    if (this.__relationships && this.__relationships.length > 0) {
      const primaryIndex = this.__relationships.findIndex(
        p => p.data.id === this.patientId,
      );
      primaryPatient = [this.__relationships[primaryIndex]];
    }
    this.formService.apply('patientPackageRelatedPatients', primaryPatient);

    this.__removeCharges();

    this.formService.refresh(this.state);
    this.__packageTemplateItem = {};
    this.__packageTemplateError = 'Required';
  }

  __getPackageTemplateItemsByType() {
    const allowSubscriptions = this.__merchantAccounts.length > 0;

    const templatesByType = this.packageTemplates.filter(
      item => item.item.type === this.state.type,
    );

    return allowSubscriptions
      ? templatesByType
      : templatesByType.filter(item => !item.item.subscription);
  }

  __checkServiceSubscriptionFields(packageTemplate) {
    const { totalPrice, frequency, charges } = packageTemplate;
    return (
      !!totalPrice &&
      !!frequency &&
      charges.length > 0 &&
      charges.every(c => !!c.quantity)
    );
  }

  __checkServiceFields(patientPackage) {
    const { totalPrice, charges } = patientPackage;

    return (
      !!totalPrice && charges.length > 0 && charges.every(c => !!c.quantity)
    );
  }

  __calculateServiceUnits(patientPackage) {
    const quantity = patientPackage.charges.reduce(
      (total, c) => total + parseInt(c.quantity, 10),
      0,
    );

    return `${quantity} ${formatUsageUnit('service', quantity)}`;
  }

  __buildServiceSummary(patientPackage) {
    const { subscription, totalPrice } = patientPackage;

    const allFieldsEntered = subscription
      ? this.__checkServiceSubscriptionFields(patientPackage)
      : this.__checkServiceFields(patientPackage);

    const frequencyDescription = formatFrequencyPhrase(patientPackage);

    return allFieldsEntered
      ? `${this.__calculateServiceUnits(
          patientPackage,
        )} for ${totalPrice}${frequencyDescription}`
      : '';
  }

  __getPackageSummary() {
    const { totalVisits } = this.state;

    const totalVisitsValue =
      totalVisits.length > 0 ? parseInt(totalVisits, 10) : 0;

    return this.state.type === PACKAGE_TYPE.VISIT
      ? getSummaryDescription({
          ...this.state,
          totalVisits: totalVisitsValue,
          visitsUsed: 0,
          visitsRemaining: totalVisitsValue,
        })
      : this.__buildServiceSummary(this.state);
  }

  __getTotalQuantity() {
    return this.state.charges.reduce(
      (accum, charge) => accum + Number(charge.quantity),
      0,
    );
  }

  __getRemainingSummary() {
    const { totalVisits } = this.state;

    const totalVisitsValue =
      totalVisits.length > 0 ? parseInt(totalVisits, 10) : 0;

    const remaining =
      this.state.type === PACKAGE_TYPE.VISIT
        ? `${totalVisitsValue}`
        : `${this.__getTotalQuantity()}`;

    if (!this.state.unlimited && remaining.length) {
      return `${remaining} remaining ${
        this.state.subscription ? 'this period' : ''
      }`;
    }

    return '';
  }

  async __loadRelationships() {
    const activeRelationshipsGroup = await getPatientRelationshipsActiveGroup(
      this.patientId,
    );

    const patientIds = [
      ...activeRelationshipsGroup.related,
      activeRelationshipsGroup.primary,
    ];

    const patientNames = await patientApiClient.fetchSome(patientIds);

    const ownerIndex = patientNames.findIndex(p => p.id === this.patientId);

    const primaryIndex = patientNames.findIndex(
      p => p.id === activeRelationshipsGroup.primary,
    );

    this.__relationships = this.__formatToItems(patientNames);

    if (primaryIndex === ownerIndex) {
      this.__relationships[ownerIndex].label = `${
        this.__relationships[ownerIndex].label
      } - Primary, Owner`;
    } else {
      this.__relationships[ownerIndex].label = `${
        this.__relationships[ownerIndex].label
      } - Owner`;

      this.__relationships[primaryIndex].label = `${
        this.__relationships[primaryIndex].label
      } - Primary`;
    }

    this.__relationships[ownerIndex].disabled = true;

    const model = this.formService.build();

    model.patientPackageRelatedPatients = [this.__relationships[ownerIndex]];

    this.formService.refresh(model);
  }

  update(changedProps) {
    if (changedProps.has('patientId')) {
      this.__loadRelationships();
    }

    if (changedProps.has('__state')) {
      const { pageIndex } = this.__collectionState;

      this.__collectionService.setItems(this.state.charges);

      this.__collectionService.setPageIndex(pageIndex);
    }

    super.update(changedProps);
  }

  renderActionBar() {
    if (!this.__dirty) return '';

    if (this.__processing) return '';

    return this.state.subscription
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            class="action-bar"
            confirmLabel="Pay"
            cancelLabel="Cancel"
            .onConfirm="${this.handlers.pay}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            class="action-bar"
            confirmLabel="Pay"
            cancelLabel="Post"
            removeLabel="Cancel"
            .onConfirm="${this.handlers.pay}"
            .onCancel="${this.handlers.post}"
            .onRemove="${this.handlers.cancel}"
            unelevated-cancel
          ></neb-action-bar>
        `;
  }

  __renderSummarySection() {
    if (!this.__packageTemplateItemSelected()) return '';

    return html`
      <div id="${ELEMENTS.summary.id}">
        <div>${this.__getPackageSummary()}</div>
        <div>0 rendered</div>
        <div>${this.__getRemainingSummary()}</div>
      </div>
    `;
  }

  __renderTotalPriceField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.totalPriceField.id}"
        name="totalPrice"
        label="${this.state.subscription ? '' : 'Total '}Price"
        helper="Required"
        maxLength="10"
        .inputMode="${'numeric'}"
        .error="${this.errors.totalPrice}"
        .mask="${currency}"
        .value="${this.state.totalPrice}"
        .onChange="${this.handlers.change}"
        ?disabled="${
          this.__processing || !this.__packageTemplateItemSelected()
        }"
      ></neb-textfield>
    `;
  }

  __renderFrequencyDropdown() {
    return html`
      <neb-select
        id="${ELEMENTS.frequencyDropdown.id}"
        name="frequency"
        label="Frequency"
        helper="Required"
        .items="${PAYMENT_FREQUENCY_OPTIONS}"
        .error="${this.errors.frequency}"
        .value="${this.state.frequency}"
        .onChange="${this.handlers.changeFrequency}"
        ?disabled="${
          this.__processing || !this.__packageTemplateItemSelected()
        }"
      ></neb-select>
    `;
  }

  __renderVisitSubscriptionFields() {
    return html`
      ${this.__renderFrequencyDropdown()}

      <div class="unlimited-row">
        <neb-checkbox
          id="${ELEMENTS.unlimitedCheckbox.id}"
          class="unlimited-checkbox"
          name="unlimited"
          label="Unlimited"
          .value="${this.state.unlimited}"
          .onChange="${this.handlers.changeUnlimited}"
          ?checked="${this.state.unlimited}"
          ?disabled="${
            this.__processing || !this.__packageTemplateItemSelected()
          }"
        ></neb-checkbox>

        <neb-tooltip
          id="${ELEMENTS.unlimitedTooltip.id}"
          class="tooltip"
          defaultAnchor="right"
        >
          <div slot="tooltip">
            Optionally enter 'Visits' to limit the number of visits allowed, or
            check 'Unlimited'.
          </div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderServiceBasedLimits() {
    return html`
      ${this.__renderTotalPriceField()}
      ${this.state.subscription ? this.__renderFrequencyDropdown() : ''}
    `;
  }

  __renderVisitBasedLimits() {
    return html`
      <neb-textfield
        id="${ELEMENTS.totalVisitsField.id}"
        name="totalVisits"
        label="${this.state.subscription ? '' : 'Total '}Visits"
        helper="Required"
        maxLength="3"
        .error="${this.errors.totalVisits}"
        .mask="${number}"
        .inputMode="${'numeric'}"
        .value="${this.state.totalVisits}"
        .onChange="${this.handlers.change}"
        ?disabled="${
          this.__processing ||
            this.state.unlimited ||
            !this.__packageTemplateItemSelected()
        }"
      ></neb-textfield>

      ${this.__renderTotalPriceField()}
      ${this.state.subscription ? this.__renderVisitSubscriptionFields() : ''}
    `;
  }

  __renderPagination() {
    return html`
      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        class="pagination"
        .pageCount="${this.__collectionState.pageCount}"
        .currentPage="${this.__collectionState.pageIndex}"
        .onPageChanged="${this.handlers.pageChanged}"
      ></neb-pagination>
    `;
  }

  __renderNoChargesText() {
    return this.__collectionState.searchText.length > 0 &&
      this.state.charges.length > 0
      ? 'No results.'
      : NO_CHARGES_TEXT;
  }

  __renderChargesTable() {
    return html`
      <neb-table-patient-package-charges
        id="${ELEMENTS.chargesTable.id}"
        name="charges"
        .layout="${this.layout}"
        .type="${this.state.type}"
        .model="${this.__collectionState.pageItems}"
        .errors="${this.errors.charges}"
        .errorChargeIds="${this.state.charges.map(charge => charge.chargeId)}"
        .onChange="${this.handlers.changeQuantity}"
        .onRemove="${this.handlers.removeCharge}"
        showRemoveButton
        >${this.__renderNoChargesText()}</neb-table-patient-package-charges
      >
      ${this.__renderPagination()}
    `;
  }

  __renderBulkUpdate() {
    return html`
      <div class="flex-center">
        <div class="flex-center">
          <div class="max-units-label">Bulk Max Unit Update</div>

          <neb-textfield
            id="${ELEMENTS.maxUnitsField.id}"
            class="max-units-field"
            maxLength="3"
            .mask="${number}"
            .inputMode="${'numeric'}"
            .value="${this.__maxUnits}"
            .error="${this.__maxUnitsError}"
            .onChange="${this.handlers.changeMaxUnits}"
            ?disabled="${this.__processing}"
          ></neb-textfield>

          <neb-icon
            id="${ELEMENTS.bulkUpdateIcon.id}"
            class="icon-bulk"
            icon="neb:updateAll"
            ?disabled="${this.__processing || this.__maxUnits.length === 0}"
            @click="${this.handlers.bulkUpdate}"
          ></neb-icon>
        </div>

        <neb-tooltip
          id="${ELEMENTS.bulkUpdateTooltip.id}"
          class="bulk-tooltip"
          defaultAnchor="left"
        >
          <div slot="tooltip">
            To update multiple charges with the same value, enter the unit value
            and click the arrow icon to populate against all filtered charges
            below.
          </div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderSharedPatientsList() {
    return this.state.sharedCarePackage && this.__relationships.length > 0
      ? html`
          <neb-select
            id="${ELEMENTS.patientPackageRelatedPatients.id}"
            class="shared-care-package-select"
            name="patientPackageRelatedPatients"
            label="Select Patients"
            allLabel="Related Patients"
            helper="Required"
            .items="${this.__relationships}"
            .value="${this.state.patientPackageRelatedPatients || []}"
            .onChange="${this.handlers.change}"
            .error="${this.errors.patientPackageRelatedPatients}"
            ?disabled="${this.__processing}"
            multiSelect
            wrapText
          ></neb-select>
        `
      : '';
  }

  __renderSharedCarePackage() {
    return html`
      <div class="row">
        <neb-checkbox
          id="${ELEMENTS.checkboxSharedCarePackage.id}"
          name="sharedCarePackage"
          class="shared-care-package-checkbox"
          label="Share with Patient Relationships"
          .onChange="${this.handlers.change}"
          ?disabled="${
            this.__relationships.length < 2 ||
              this.__processing ||
              !this.__packageTemplateItemSelected()
          }"
          ?checked="${
            this.state.sharedCarePackage && this.__relationships.length > 0
          }"
        ></neb-checkbox>
        ${this.__renderSharedPatientsList()}
      </div>
    `;
  }

  __renderDefaultCheckbox() {
    return html`
      <div class="row">
        <neb-checkbox
          id="${ELEMENTS.defaultCheckbox.id}"
          name="isDefault"
          class="default-checkbox"
          label="Default"
          ?checked="${this.disableDefault || this.state.isDefault}"
          ?disabled="${
            this.disableDefault ||
              (this.__processing || !this.__packageTemplateItemSelected())
          }"
          .onChange="${this.handlers.change}"
        ></neb-checkbox>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="row radio-buttons">
        <neb-radio-button
          id="${ELEMENTS.visitsRadioButton.id}"
          name="type"
          label="Visits"
          .value="${PACKAGE_TYPE.VISIT}"
          .onChange="${this.handlers.changeType}"
          ?checked="${this.state.type === PACKAGE_TYPE.VISIT}"
          ?disabled="${this.__processing}"
        ></neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.servicesRadioButton.id}"
          name="type"
          label="Services"
          .value="${PACKAGE_TYPE.SERVICE}"
          .onChange="${this.handlers.changeType}"
          ?checked="${this.state.type === PACKAGE_TYPE.SERVICE}"
          ?disabled="${this.__processing}"
        ></neb-radio-button>
      </div>
      <div class="row">
        <neb-select
          id="${ELEMENTS.templateDropdown.id}"
          class="template-dropdown"
          label="Package Template"
          helper="Required"
          .error="${this.__packageTemplateError}"
          .value="${this.__packageTemplateItem}"
          .items="${this.__getPackageTemplateItemsByType()}"
          .onChange="${this.handlers.changeTemplate}"
          ?disabled="${this.__processing}"
        ></neb-select>

        <neb-textfield
          id="${ELEMENTS.nameField.id}"
          name="name"
          class="package-name"
          label="Patient's Package Name"
          maxLength="50"
          .value="${this.state.name}"
          .error="${this.errors.name}"
          .onChange="${this.handlers.changeName}"
          ?disabled="${
            this.__processing || !this.__packageTemplateItemSelected()
          }"
        ></neb-textfield>
      </div>

      ${this.__renderDefaultCheckbox()}

      <div class="row">
        <neb-checkbox
          id="${ELEMENTS.subscriptionCheckbox.id}"
          name="subscription"
          class="subscription-checkbox"
          label="Sell as Subscription"
          .onChange="${this.handlers.changeSubscription}"
          ?checked="${this.state.subscription}"
          ?disabled="${this.__processing || this.__subscriptionsDisabled()}"
        ></neb-checkbox>

        <neb-tooltip
          id="${ELEMENTS.tooltip.id}"
          class="tooltip"
          defaultAnchor="right"
        >
          <div slot="tooltip">
            ${
              this.__subscriptionsDisabled()
                ? TOOL_TIP_SUBSCRIPTION_NOT_ALLOWED
                : TOOL_TIP_SUBSCRIPTION_ALLOWED
            }
          </div>
        </neb-tooltip>
      </div>
      ${this.__renderSharedCarePackage()}

      <div class="summary-container">
        <div class="bold">${PACKAGE_SUMMARY_HEADER}</div>

        ${this.__renderSummarySection()}
      </div>

      <div class="row">
        <neb-date-picker
          id="${ELEMENTS.effectiveDate.id}"
          name="effectiveDate"
          class="effective-date"
          helperText="Required"
          label="Effective Date"
          placeholder="Select Date"
          manualPopoverPosition="center"
          .onChange="${this.handlers.changeEffectiveDate}"
          .onClear="${this.handlers.clearEffectiveDate}"
          .isDateSelectable="${this.handlers.effectiveDateSelectable}"
          .selectedDate="${this.state.effectiveDate}"
          ?disabled="${
            this.__processing || !this.__packageTemplateItemSelected()
          }"
          ?invalid="${!!this.errors.effectiveDate}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.expirationDate.id}"
          name="expirationDate"
          class="expiration-date"
          label="Expiration Date"
          placeholder="Select Date"
          manualPopoverPosition="center"
          .onChange="${this.handlers.changeExpirationDate}"
          .isDateSelectable="${this.handlers.expirationDateSelectable}"
          .selectedDate="${this.state.expirationDate}"
          ?disabled="${
            (this.state.subscription && !this.state.frequency) ||
              !this.state.effectiveDate ||
              this.__processing ||
              !this.__packageTemplateItemSelected()
          }"
          momentFlag
        ></neb-date-picker>
      </div>

      <neb-header
        id="${ELEMENTS.limitsHeader.id}"
        class="limits-header"
        label="${
          this.state.subscription && this.state.frequency
            ? `${this.state.frequency} Limits`
            : 'Limits'
        }"
      ></neb-header>

      <div
        class="section"
        type="${this.state.type}"
        ?subscription="${this.state.subscription}"
      >
        ${
          this.state.type === PACKAGE_TYPE.VISIT
            ? this.__renderVisitBasedLimits()
            : this.__renderServiceBasedLimits()
        }
      </div>

      <neb-header label="Covered Charges (Required)"></neb-header>

      <div class="charges-section" ?dirty="${this.__dirty}">
        <div class="add-and-search-container">
          <neb-textfield
            id="${ELEMENTS.searchField.id}"
            leadingIcon="neb:search"
            label="Enter procedure or description to filter list below."
            .trailingIcon="${
              this.__collectionState.searchText ? 'neb:clear' : ''
            }"
            .value="${this.__collectionState.searchText}"
            .onChange="${this.handlers.searchCharges}"
            .onClickIcon="${this.handlers.clearSearch}"
            ?disabled="${
              this.__processing || !this.__packageTemplateItemSelected()
            }"
          ></neb-textfield>

          <div class="add-and-update-container">
            <neb-button-action
              id="${ELEMENTS.addChargesButton.id}"
              class="button-add-charges"
              label="Add Charges"
              .onClick="${this.handlers.addCharge}"
              ?disabled="${
                this.__processing || !this.__packageTemplateItemSelected()
              }"
            ></neb-button-action>
            ${
              this.state.type === PACKAGE_TYPE.SERVICE &&
              this.state.charges.length > 0
                ? this.__renderBulkUpdate()
                : ''
            }
          </div>
        </div>

        ${this.__renderChargesTable()}
      </div>
    `;
  }
}

customElements.define('neb-form-patient-package-add', NebFormPatientPackageAdd);
