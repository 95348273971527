import '../neb-login/neb-session-state';

import { store } from '../neb-redux/neb-redux-store';
import { watch } from '../neb-redux/neb-redux-watcher';

const ACTION_SET_LAYOUT = 'NEB_NAV';
const ACTION_SET_LOCAL_DEFAULT_NAV_TOGGLE = `${ACTION_SET_LAYOUT}_SET_DEFAULT_NAV_TOGGLE`;
const ACTION_SET_NAV_TOGGLED = `${ACTION_SET_LAYOUT}_SET_NAV_TOGGLE`;
const PROPERTY_NAME = 'navClosed';

export const navState = (
  state = {
    navClosed: false,
    localStorageInitialized: false,
  },
  action,
) => {
  switch (action.type) {
    case ACTION_SET_NAV_TOGGLED:
      return { ...state, navClosed: action.navClosed };

    case ACTION_SET_LOCAL_DEFAULT_NAV_TOGGLE:
      return {
        ...state,
        localStorageInitialized: true,
        navClosed: action.navClosed,
      };

    default:
      return state;
  }
};

const __buildLocalKey = id => `${id}::nav::${PROPERTY_NAME}`;

const __getLocalKey = () => {
  const sessionState = store.getState().session;

  if (!sessionState || !sessionState.item || !sessionState.item.id) {
    return null;
  }

  const { id } = sessionState.item;
  return __buildLocalKey(id);
};

const __setLocalDefault = value => {
  if (!store.getState().navState.localStorageInitialized) {
    return;
  }

  const key = __getLocalKey();

  if (!key) {
    return;
  }

  window.localStorage.setItem(key, JSON.stringify(value));
};

const __getLocalDefault = key => {
  const localValue = window.localStorage.getItem(key);
  return JSON.parse(localValue);
};

const __reactToSessionChanged = userId => {
  if (!userId) return;

  const key = __buildLocalKey(userId);

  store.dispatch({
    navClosed: __getLocalDefault(key),
    type: ACTION_SET_LOCAL_DEFAULT_NAV_TOGGLE,
  });
};

export const toggleNav = () => {
  const toggled = !store.getState().navState.navClosed;

  __setLocalDefault(toggled);

  store.dispatch({
    navClosed: toggled,
    type: ACTION_SET_NAV_TOGGLED,
  });
};
/**
 * Call to initialize session listener. This should only be called once.
 * @returns {Function} call to remove session listener
 */

export const initialize = () => {
  const unsubscribe = store.subscribe(
    watch(store.getState, 'session.item.id')(userId =>
      __reactToSessionChanged(userId),
    ),
  );
  return () => unsubscribe();
};
