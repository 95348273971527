import '../../../../neb-styles/neb-icons';
import '../../../../neb-lit-components/src/components/neb-search-bar';
import '../../../../neb-lit-components/src/components/neb-tabs-old';
import '../../../../neb-lit-components/src/components/neb-tab-old';
import '../../../../neb-lit-components/src/components/neb-tooltip';
import '../../../../neb-lit-components/src/components/inputs/neb-select';

import { LitElement, html } from 'lit';

import { listIcd10Years } from '../../../../../src/utils/clinical-codes';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';

export const ICD10_TAB = 'icd10';
export const PROBLEM_LIST_TAB = 'problemList';
export const PRIOR_ENCOUNTERS_TAB = 'priorEncounters';
export const HOT_BUTTONS_TAB = 'hotButtons';

export const ELEMENTS = {
  filterTooltip: {
    id: 'filter-tooltip',
  },
  icd10CodesTab: {
    id: 'icd10-codes-tab',
  },
  hotButtonsTab: {
    id: 'hot-buttons-tab',
  },
  icd10Dropdown: {
    id: 'icd10-dropdown',
  },
  priorEncountersTab: {
    id: 'prior-encounters-tab',
  },
  problemListTab: {
    id: 'problem-list-tab',
  },
  searchBar: {
    id: 'search-bar',
  },
  tabs: {
    id: 'tabs',
  },
};

export const PLACEHOLDER_TEXT = {
  [ICD10_TAB]: 'Search Diagnoses',
  [PROBLEM_LIST_TAB]: 'Filter Problems',
  [PRIOR_ENCOUNTERS_TAB]: 'Filter Prior Encounters',
};

const HINT_TEXT =
  'You can filter the prior encounter list by service date, provider, appointment type, encounter diagnoses codes and descriptions.';

class NebChartingDiagnosisDetailTabs extends LitElement {
  static get properties() {
    return {
      searchText: String,
      selectedTab: String,
      icd10Year: Number,
      visible: Boolean,
      layout: String,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.searchText = '';
    this.selectedTab = HOT_BUTTONS_TAB;
    this.icd10Year = null;
    this.visible = false;
    this.layout = '';

    this.onTabSelected = () => {};

    this.onIcd10YearSelected = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => this.onTabSelected(tab),
      changeIcd10Year: ({ value }) => this.onIcd10YearSelected(value),
    };
  }

  setSearchBarFocus() {
    this.__elements.searchBar.setFocus();
  }

  setSearchBarUnfocus() {
    this.__elements.searchBar.setBlur();
  }

  __renderStyles() {
    return html`
      <style>
        .add-diagnosis-container {
          padding: 0 20px;
        }

        .search-input-positioning {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
        }

        .title-text {
          font-weight: bold;
        }

        .tabs {
          padding-top: 16px;
        }

        .tooltip {
          padding: 26px 15px 10px;
        }

        .hint {
          width: 420px;
          height: 40px;
        }

        .search-and-tooltip {
          display: flex;
        }

        .icd10-dropdown {
          width: 140px;
          padding-right: ${CSS_SPACING};
        }

        .neb-search-bar {
          padding-top: 16px;
          width: 300px;
        }
      </style>
    `;
  }

  firstUpdated() {
    this.__elements = {
      tabs: this.shadowRoot.getElementById(ELEMENTS.tabs.id),
      searchBar: this.shadowRoot.getElementById(ELEMENTS.searchBar.id),
      priorEncountersTab: this.shadowRoot.getElementById(
        ELEMENTS.priorEncountersTab.id,
      ),
    };
  }

  reset() {
    this.__elements.tabs.reset();
  }

  updated(changedProps) {
    if (this.selectedTab !== HOT_BUTTONS_TAB) {
      this.__elements.searchBar = this.shadowRoot.getElementById(
        ELEMENTS.searchBar.id,
      );

      if (changedProps.has('visible')) {
        if (!this.visible) this.setSearchBarUnfocus();
        else this.setSearchBarFocus();
      }

      if (changedProps.has('selectedTab')) {
        this.setSearchBarFocus();
      }
    }
  }

  render() {
    let yearValue = this.icd10Year;

    const yearList = listIcd10Years();

    if (yearValue < yearList[0]) yearValue = yearList[0];
    else if (yearValue > yearList[yearList.length - 1]) {
      yearValue = yearList[yearList.length - 1];
    }

    return html`
      ${this.__renderStyles()}
      <div class="add-diagnosis-container">
        <div class="title-text">Add Diagnosis</div>

        <div class="search-input-positioning">
          <div class="tab-header-positioning">
            <neb-tabs-old
              id="${ELEMENTS.tabs.id}"
              class="tabs"
              .onChange="${this.__handlers.selectTab}"
              .selected="${this.selectedTab}"
            >
              <neb-tab-old
                id="${ELEMENTS.hotButtonsTab.id}"
                name="${HOT_BUTTONS_TAB}"
              >
                Hot Buttons
              </neb-tab-old>

              <neb-tab-old
                id="${ELEMENTS.icd10CodesTab.id}"
                name="${ICD10_TAB}"
              >
                ICD10 Codes
              </neb-tab-old>

              <neb-tab-old
                id="${ELEMENTS.problemListTab.id}"
                name="${PROBLEM_LIST_TAB}"
              >
                Problem List
              </neb-tab-old>

              <neb-tab-old
                id="${ELEMENTS.priorEncountersTab.id}"
                name="${PRIOR_ENCOUNTERS_TAB}"
              >
                Prior Encounters
              </neb-tab-old>
            </neb-tabs-old>
          </div>

          <div class="search-and-tooltip">
            ${
              this.selectedTab === PRIOR_ENCOUNTERS_TAB
                ? html`
                    <neb-tooltip
                      id="${ELEMENTS.filterTooltip.id}"
                      class="tooltip"
                      defaultAnchor="top"
                    >
                      <div class="hint" slot="tooltip">${HINT_TEXT}</div>
                    </neb-tooltip>
                  `
                : ''
            }
            ${
              this.selectedTab === ICD10_TAB
                ? html`
                    <neb-select
                      id="${ELEMENTS.icd10Dropdown.id}"
                      label="ICD10 Year"
                      class="icd10-dropdown"
                      .items="${listIcd10Years()}"
                      .value="${yearValue}"
                      .onChange="${this.__handlers.changeIcd10Year}"
                    ></neb-select>
                  `
                : ''
            }
            ${
              this.selectedTab !== HOT_BUTTONS_TAB
                ? html`
                    <neb-search-bar
                      id="${ELEMENTS.searchBar.id}"
                      class="neb-search-bar"
                      placeholderText="${PLACEHOLDER_TEXT[this.selectedTab]}"
                      .searchText="${this.searchText}"
                      .onSearch="${this.onSearch}"
                      .debounceFilter="${true}"
                    >
                    </neb-search-bar>
                  `
                : ''
            }
          </div>
        </div>
      </div>
    `;
  }
}
customElements.define(
  'neb-charting-diagnosis-detail-tabs',
  NebChartingDiagnosisDetailTabs,
);
