import { html, css } from 'lit';

import { formatPhoneNumber } from '../../../packages/neb-utils/formatters';
import {
  CSS_COLOR_GREY_5,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../../styles';

import { NebEventClickCard } from './neb-event-click-card';

export const ELEMENTS = {
  name: { id: 'name' },
  headerDetails: { id: 'header-details' },
  address: { id: 'address' },
  phoneNumber: { id: 'phone-number' },
  email: { id: 'email' },
};

class NebPracticeLocationCard extends NebEventClickCard {
  static get styles() {
    return [
      super.styles,
      css`
        .header {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-size: ${CSS_FONT_SIZE_BODY};
          margin-bottom: 20px;
        }

        .header-details {
          font-style: italic;
          font-weight: 100;
          color: ${CSS_COLOR_GREY_5};
        }
      `,
    ];
  }

  __renderHeaderDetails() {
    const { addressOnly, active } = this.item;
    const addressOnlyTx = addressOnly ? 'Address Only' : '';
    const activeTx = active ? '' : 'Inactive';
    const headerDetails =
      addressOnly && activeTx
        ? `${addressOnlyTx}, ${activeTx}`
        : `${addressOnlyTx}${activeTx}`;
    return headerDetails
      ? html`
          <div id="${ELEMENTS.headerDetails.id}" class="header-details">
            (${headerDetails})
          </div>
        `
      : html``;
  }

  __renderHeader() {
    const { name } = this.item;
    return html`
      <div id="${ELEMENTS.name.id}" class="header">
        ${name} ${this.__renderHeaderDetails()}
      </div>
    `;
  }

  __renderAddress() {
    const { address1, address2, city, state, zipCode } = this.item;
    return html`
      <div id="${ELEMENTS.address.id}">
        <div>${address1}</div>

        ${
          address2
            ? html`
                <div>${address2}</div>
              `
            : ''
        }

        <div>${city}, ${state} ${zipCode}</div>
      </div>
    `;
  }

  __renderDetails() {
    const { phoneNumber, emailAddress } = this.item;
    return html`
      <div>
        <div id="${ELEMENTS.phoneNumber.id}">
          ${formatPhoneNumber(phoneNumber)}
        </div>

        <div id="${ELEMENTS.email.id}">${emailAddress}</div>
      </div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderAddress()}
      ${this.__renderDetails()}
    `;
  }
}

customElements.define('neb-practice-location-card', NebPracticeLocationCard);
