import ApiClient, {
  Method,
} from '../../../packages/neb-api-client/src/utils/api-client-utils';

export const BASE_URL = 'booking/patient';

export const billingApiClient = new ApiClient({ microservice: 'billing' });

export const fetchOnlinePatient = async () => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: BASE_URL,
    version: 1,
  });

  return res;
};

export const getOnlinePatientBalance = patientId => {
  const path = `${BASE_URL}/${patientId}/balance`;

  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    version: 1,
    cacheKey: path,
  });
};

export const getBookingPayments = async ({
  patientId,
  offset,
  limit,
  optOutLoadingIndicator = false,
}) => {
  const path = `${BASE_URL}/${patientId}/payments?offset=${offset}&limit=${limit}`;

  const res = await billingApiClient.makeRequest({
    path,
    version: 1,
    cacheKey: path,
    optOutLoadingIndicator,
  });

  const results = res.data;

  return {
    count: res.count,
    data: results,
  };
};

export const getOnlinePatientStatements = async ({
  patientId,
  offset,
  limit,
  optOutLoadingIndicator = false,
}) => {
  const path = `${BASE_URL}/${patientId}/statements?offset=${offset}&limit=${limit}`;

  const res = await billingApiClient.makeRequest({
    path,
    version: 1,
    cacheKey: path,
    optOutLoadingIndicator,
  });

  return {
    count: res.count,
    data: res.data,
  };
};

export const postPayment = async data => {
  const path = `${BASE_URL}/${data.patientId}/payments`;

  const result = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    method: Method.POST,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return result;
};
