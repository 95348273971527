import ApiClient, { Method } from '../utils/api-client-utils';

const BASE_URL = 'transfer-balance';
const apiClient = new ApiClient({ microservice: 'billing' });

const BASE_HEADER = {
  'Content-Type': 'application/json',
};

export const postTransferBalance = async (
  payload,
  optOutLoadingIndicator = false,
) => {
  const res = await apiClient.makeRequest({
    method: Method.POST,
    path: BASE_URL,
    body: JSON.stringify(payload),
    headers: BASE_HEADER,
    optOutLoadingIndicator,
    version: 1,
  });

  return res;
};
