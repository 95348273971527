import '../encounter/neb-encounter-status';
import '../../../../neb-material-design/src/components/neb-md-textarea';
import './neb-appointment-details-lit';
import './neb-appointment-status';
import '../neb-dropdown-menu';
import '../inputs/neb-textarea';
import '../inputs/neb-textfield';
import '../patients/neb-patient-summary-controller';

import { LitElement, html, css } from 'lit';

import { ADD_ONS, hasAddOn } from '../../../../../src/utils/add-ons';
import { openDoctibleSsoLink } from '../../../../../src/utils/sso';
import { createBannerController } from '../../../../neb-alert/components/neb-alert-banner-controller';
import { APPOINTMENT_STATUS } from '../../../../neb-appointment/neb-appointments';
import { LAYOUT_TYPE } from '../../../../neb-redux/services/layout';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import { formatAuthorizationLabel } from '../../../../neb-utils/patientAuthorization';
import { openAppointmentPage } from '../../utils/appointment-overlays-util';
import { BUTTON_ROLE } from '../neb-button';

import { CSS_BASE_CONTENT_WIDTH } from './neb-appointment-styles';

const { SMALL } = LAYOUT_TYPE;

export const ELEMENTS = {
  buttonCheckIn: {
    id: 'button-check-in',
  },
  buttonCheckOut: {
    id: 'button-check-out',
  },
  buttonNoShow: {
    id: 'button-no-show',
  },
  ctEngageSendMessage: {
    id: 'ct-engage-send-message',
  },
  actionMenu: {
    id: 'action-menu',
  },
  iconClose: {
    id: 'icon-close',
  },
  patientSummary: {
    id: 'patient-summary',
  },
  details: {
    id: 'appointment-details',
  },
  noteHeader: {
    id: 'appointment-note-header',
  },
  note: {
    id: 'appointment-note',
  },
  cancelRescheduleReason: {
    id: 'cancel-reschedule-reason',
  },
  cancelRescheduleNote: {
    id: 'cancel-reschedule-note',
  },
  viewAppointmentStatus: {
    id: 'view-appointment-status',
  },
  viewEncounterStatus: {
    id: 'view-encounter-status',
  },
  viewCurrentAppointment: {
    id: 'view-current-appointment',
  },
  mainContainer: {
    id: 'main-container',
  },
};
const APPOINTMENT_NOTE_HEADER = 'Appointment Note';

const CT_ENGAGE_SEND_MESSAGE_CONFIG = {
  icon: 'neb:open',
  url: '/providers/messaging',
  label: 'Send a Message',
};

class NebAppointmentPageView extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      model: {
        type: Object,
      },
      patientId: {
        type: String,
      },
      hasEncounter: {
        type: Boolean,
      },
      patientCaseName: {
        type: String,
      },
      selectedAuthorization: {
        type: Object,
      },
      appointmentActionButtons: {
        type: Array,
      },
      appointmentActionMenuItems: {
        type: Array,
      },
      scheduledRoom: {
        type: Object,
      },
      checkedInRoom: {
        type: Object,
      },
      cancelRescheduleReason: String,
      __hasAddOnCTEngage: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__hasAddOnCTEngage = false;
    this.cancelRescheduleReason = '';
    this.layout = '';
    this.model = {};
    this.selectedAuthorization = null;
    this.hasEncounter = false;
    this.patientId = '';
    this.patientCaseName = '';
    this.appointmentActionButtons = [];
    this.appointmentActionMenuItems = [];
    this.scheduledRoom = {};
    this.checkedInRoom = {};

    this.__alertBanner = createBannerController();

    this.onNavigateToPatientEncounter = () => {};

    this.onAuthorizationDetailChanged = () => {};

    this.onActionMenuItemSelect = () => {};

    this.onActionButtonClick = () => {};

    this.onPatientSummaryModelLoaded = () => {};

    this.onClose = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      close: () => this.onClose(),
      encounterSummaryClicked: () => this.onNavigateToPatientEncounter(),
      onAuthorizationDetailChanged: () => this.onAuthorizationDetailChanged(),
      sendMessage: () => openDoctibleSsoLink(CT_ENGAGE_SEND_MESSAGE_CONFIG.url),
      patientSummaryModelLoaded: model => {
        this.onPatientSummaryModelLoaded(model);
      },
      openAppointmentOverlay: async () => {
        await openAppointmentPage(this.model.appointmentId);

        this.__handlers.close();
      },
    };
  }

  __getMenuItems() {
    return this.appointmentActionMenuItems.map(menuItem => ({
      ...menuItem,
      onSelect: () => this.onActionMenuItemSelect(menuItem),
    }));
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__alertBanner.connect();
    this.__hasAddOnCTEngage = await hasAddOn(ADD_ONS.CT_ENGAGE);
  }

  disconnectedCallback() {
    this.__alertBanner.disconnect();
    super.disconnectedCallback();
  }

  updated(changedProps) {
    if (changedProps.has('patientId')) {
      this.__alertBanner.update(this.patientId, true);
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .container {
          position: relative;
          background-color: ${CSS_COLOR_WHITE};

          width: 100%;
          height: 100%;
        }

        .content {
          display: flex;
          height: 100%;
          overflow-y: auto;
        }

        :host([layout='small']) .content {
          flex-direction: column;
        }

        .content-right {
          display: flex;
          flex-direction: column;
          padding: 0 ${CSS_SPACING};
        }

        .actions {
          display: flex;
          align-items: center;
        }

        .text-note-box {
          margin-top: ${CSS_SPACING};
        }

        .content-form {
          display: flex;
          flex-direction: column;
        }

        :host([layout='small']) .content-form {
          padding: ${CSS_SPACING};
        }

        :host(:not([layout='small'])) .content-form {
          width: ${CSS_BASE_CONTENT_WIDTH};
        }

        .header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: ${CSS_SPACING} 0;
        }

        :host([layout='small']) .header {
          justify-content: space-between;
          padding: ${CSS_SPACING} 10px;
        }

        .header-left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 10px;
        }

        :host([layout='small']) .header-left {
          margin-right: 0;
        }

        .header-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .container-appointment-status {
          margin-bottom: ${CSS_SPACING};
        }

        .appointment-status {
          font-weight: bold;
          margin-right: ${CSS_SPACING};
        }

        :host([layout='small']) .appointment-status {
          margin: 0 10px;
        }

        .action-menu {
          height: fit-content;
          margin-right: ${CSS_SPACING};
        }

        :host([layout='small']) .action-menu {
          margin-right: 10px;
          padding-left: 0;
        }

        .button-check-in,
        .button-check-out,
        .button-no-show {
          margin-right: 10px;
        }

        :host([layout='small']) .button-check-in {
          width: fit-content;
          white-space: nowrap;
        }

        :host([layout='small']) .button-check-out {
          width: fit-content;
          white-space: nowrap;
        }

        .appointment-details,
        .container-encounter-status {
          margin-bottom: 40px;
        }

        .container-recurring-status {
          margin-bottom: 20px;
        }

        .group-section {
          margin-bottom: ${CSS_SPACING};
        }

        .section-header {
          font-weight: bold;
        }

        .note {
          min-height: 80px;
          display: flex;
          width: 95%;
          border: 1px solid #ccc;
          border-radius: 5px;
          padding: 1% 3%;
          margin-bottom: ${CSS_SPACING};
          word-break: break-word;
        }

        .content-left {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          padding: ${CSS_SPACING};
        }

        :host([layout='small']) .patient-summary {
          border-top: 1px solid ${CSS_COLOR_GREY_2};
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .content-margin-right {
          margin-right: ${CSS_SPACING};
        }

        .spacer {
          flex: 1 0 0;
        }

        .appointment-note-header {
          margin: 0 0 5px 0;
        }

        .icon {
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_COLOR_GREY_1};
        }

        .header-close-icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          padding-right: 0;

          fill: ${CSS_COLOR_GREY_1};
        }

        .content::-webkit-scrollbar {
          width: 0;
        }

        .recurring-icon {
          cursor: default;
          min-width: 24px;
          width: 24px;
          height: 24px;
          padding-right: 0;

          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .container-recurring-narrative {
          display: flex;
          padding-top: 15px;
        }

        .recurring-narrative {
          padding-left: 5px;
        }

        .view-appointment-status {
          margin-bottom: 30px;
        }

        .view-encounter-status {
          margin-bottom: 30px;
        }

        .ct-engage-send-message-link {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          margin-bottom: 30px;
        }

        .icon-open-link {
          cursor: pointer;
          width: 20px;
          height: 20px;
          padding-left: 5px;

          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .text-link {
          width: fit-content;
          text-decoration: underline;
          color: rgb(12, 170, 220);
        }

        .flex-row {
          display: flex;
          flex-direction: row;
          cursor: pointer;
        }
      `,
    ];
  }

  __renderActionMenu() {
    const menuItems = this.__getMenuItems();

    if (menuItems.length > 0) {
      return html`
        <neb-dropdown-menu
          id="${ELEMENTS.actionMenu.id}"
          class="action-menu"
          .label="${this.layout === 'small' ? 'Update' : 'Update Appointment'}"
          .items="${menuItems}"
          showFullText
        ></neb-dropdown-menu>
      `;
    }

    return html``;
  }

  __renderCloseIcon() {
    return html`
      <neb-icon
        id="${ELEMENTS.iconClose.id}"
        class="header-close-icon"
        icon="neb:close"
        @click="${this.__handlers.close}"
      ></neb-icon>
    `;
  }

  __renderPatientSummary() {
    return html`
      <neb-patient-summary-controller
        id="${ELEMENTS.patientSummary.id}"
        class="patient-summary"
        .layout="${this.layout}"
        .patientId="${this.__patientId}"
        .onModelLoaded="${this.__handlers.patientSummaryModelLoaded}"
      >
      </neb-patient-summary-controller>
    `;
  }

  __getSelectedAuthorizationName() {
    const selectedAuthExists = !!Object.keys(this.selectedAuthorization).length;

    return selectedAuthExists
      ? formatAuthorizationLabel(this.selectedAuthorization)
      : '';
  }

  __renderContentForm() {
    return html`
      <neb-appointment-details-lit
        id="${ELEMENTS.details.id}"
        class="appointment-details"
        .layout="${this.layout}"
        .appointmentDetails="${this.model.details}"
        .patientCaseName="${this.patientCaseName}"
        .patientAuthorizationName="${this.__getSelectedAuthorizationName()}"
        .scheduledRoomName="${this.scheduledRoom.name}"
        .selectedAuthorization="${this.selectedAuthorization}"
        .onAuthorizationDetailChanged="${
          this.__handlers.onAuthorizationDetailChanged
        }"
        ?showAuthorizationWarningOnLoad="${true}"
      >
      </neb-appointment-details-lit>

      ${this.__renderAppointmentStatus()} ${this.__renderLinkToAppointment()}
      ${this.__renderCTEngageSendMessageLink()}
      ${this.__renderEncounterStatus()} ${this.__renderAppointmentNote()}
      ${this.__renderAppointmentCancelRescheduleReason()}
      ${this.__renderAppointmentCancelRescheduleNote()}
    `;
  }

  __renderAppointmentStatus() {
    return html`
      <neb-appointment-status
        id="${ELEMENTS.viewAppointmentStatus.id}"
        class="view-appointment-status"
        .model="${this.model}"
        .checkedInRoomName="${this.checkedInRoom.name}"
      ></neb-appointment-status>
    `;
  }

  __renderLinkToAppointment() {
    return this.model.appointmentId
      ? html`
          <div
            id="${ELEMENTS.viewCurrentAppointment.id}"
            class="flex-row"
            @click="${this.__handlers.openAppointmentOverlay}"
          >
            <div class="view-appointment-status text-link">
              View Current Appointment
            </div>
            <neb-icon class="icon-open-link" icon="neb:open"></neb-icon>
          </div>
        `
      : html``;
  }

  __renderEncounterStatus() {
    return this.hasEncounter
      ? html`
          <neb-encounter-status
            id="${ELEMENTS.viewEncounterStatus.id}"
            class="view-encounter-status"
            .model="${this.model.encounter}"
            .selfCheckIn="${this.model.selfCheckIn}"
            .onClickLink="${this.__handlers.encounterSummaryClicked}"
          ></neb-encounter-status>
        `
      : '';
  }

  __renderCTEngageSendMessageLink() {
    return this.__hasAddOnCTEngage
      ? html`
          <div
            id="${ELEMENTS.ctEngageSendMessage.id}"
            class="ct-engage-send-message-link"
            @click="${this.__handlers.sendMessage}"
          >
            <span>${CT_ENGAGE_SEND_MESSAGE_CONFIG.label}</span>
            <neb-icon
              class="icon-open-link"
              icon="${CT_ENGAGE_SEND_MESSAGE_CONFIG.icon}"
            ></neb-icon>
          </div>
        `
      : '';
  }

  __renderAppointmentNote() {
    return html`
      <neb-textarea
        id="${ELEMENTS.note.id}"
        class="text text-note"
        label="${APPOINTMENT_NOTE_HEADER}"
        .value="${this.model.note || 'This appointment does not have a note'}"
        ?disabled="${true}"
      ></neb-textarea>
    `;
  }

  __renderAppointmentCancelRescheduleReason() {
    return this.cancelRescheduleReason
      ? html`
          <neb-textfield
            id="${ELEMENTS.cancelRescheduleReason.id}"
            class="text text-note text-note-box"
            disabled
            .label="${
              this.model.status === APPOINTMENT_STATUS.CANCELED
                ? 'Cancel'
                : 'Reschedule'
            } Reason"
            .value="${this.cancelRescheduleReason}"
          ></neb-textfield>
        `
      : '';
  }

  __renderAppointmentCancelRescheduleNote() {
    return this.model.cancelReason || this.model.rescheduleReason
      ? html`
          <neb-textarea
            id="${ELEMENTS.cancelRescheduleNote.id}"
            class="text text-note text-note-box"
            .label="${this.model.cancelReason ? 'Cancel' : 'Reschedule'} Note"
            .value="${this.model.cancelReason || this.model.rescheduleReason}"
            disabled
          ></neb-textarea>
        `
      : '';
  }

  __renderActionButtons() {
    if (this.appointmentActionButtons.length > 0) {
      return this.appointmentActionButtons.map(
        action => html`
          <neb-button
            id="${action.id}"
            class="${action.class}"
            .label="${action.label}"
            .layout="${this.layout}"
            .role="${
              action.isOutlined ? BUTTON_ROLE.OUTLINE : BUTTON_ROLE.CONFIRM
            }"
            .onClick="${() => this.onActionButtonClick(action)}"
          ></neb-button>
        `,
      );
    }

    return html``;
  }

  __renderActions() {
    return html`
      ${this.__renderActionMenu()} ${this.__renderActionButtons()}
    `;
  }

  __renderSmall() {
    return html`
      <div class="content">
        <div class="header">
          <div class="header-left">${this.__renderActions()}</div>
          <div class="header-right">${this.__renderCloseIcon()}</div>
        </div>

        <div class="content-summary">${this.__renderPatientSummary()}</div>
        <div class="content-form">${this.__renderContentForm()}</div>
      </div>
    `;
  }

  __renderBig() {
    return html`
      <div class="content">
        <div class="content-left">
          <div class="content-summary">${this.__renderPatientSummary()}</div>
        </div>

        <div class="content-right">
          <div class="header">
            <div class="actions">${this.__renderActions()}</div>
            <div class="spacer"></div>
            ${this.__renderCloseIcon()}
          </div>
          <div class="content-form">${this.__renderContentForm()}</div>
        </div>
      </div>
    `;
  }

  render() {
    return this.model
      ? html`
          <div id="${ELEMENTS.mainContainer.id}" class="container">
            ${this.layout === SMALL ? this.__renderSmall() : this.__renderBig()}
          </div>
        `
      : html``;
  }
}

customElements.define('neb-appointment-page-view', NebAppointmentPageView);
