import '../inputs/neb-textfield';
import '../neb-radio-button';
import '../neb-header';
import '../inputs/neb-select-search';

import { isRequired } from '@neb/form-validators';
import { LitElement, html, css } from 'lit';

import NebSubstituteProviderList from '../../../../../src/components/controls/field-groups/neb-substitute-provider-list';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../../neb-styles/neb-variables';
import {
  FEDERAL_TAX_ID_TYPE,
  PROVIDER_TYPE,
} from '../../../../neb-utils/enums';
import { number, ssn, taxId } from '../../../../neb-utils/masks';
import { TAXONOMY_DATA, TAXONOMY_DATA_EMPTY } from '../../utils/taxonomy';

import IdList from './neb-id-list';
import LicenseList from './neb-license-list';

const ITEM_HEIGHT = 84;

export const ELEMENTS = {
  npi: { id: 'number-npi' },
  organizationNpi: { id: 'organization-npi' },
  licenses: { id: 'license-list' },
  taxonomy: { id: 'taxonomy' },
  description: { id: 'taxonomy-description' },
  identifiers: { id: 'id-list' },
  header: { id: 'header' },
  taxId: { id: 'tax-id' },
  buttonSSN: { id: 'button-ssn' },
  buttonEIN: { id: 'button-ein' },
  substitute: { id: 'substitute' },
};

export const EMPTY_ERRORS = {
  licenses: [
    {
      license: '',
      state: '',
    },
  ],
  NPI: '',
  organizationNPI: '',
  taxId: '',
  taxIdType: FEDERAL_TAX_ID_TYPE.SSN,
  taxonomy: {
    code: '',
    description: '',
    label: '',
  },
  identifiers: [
    {
      number: '',
      type: {
        value: '',
        label: '',
      },
    },
  ],
};

const removeSpecialCharacters = str => str.replace(/[()-/]/g, '');

const isSSN = type => type === FEDERAL_TAX_ID_TYPE.SSN;

export default class NebIdentifier extends LitElement {
  static get properties() {
    return {
      __searchResult: Array,
      __showResults: Boolean,

      errors: Object,
      header: String,
      model: Object,
      isProvider: {
        attribute: 'is-provider',
        type: Boolean,
      },
      providerItems: Array,
      payerItems: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .header {
          padding-top: ${CSS_SPACING};
        }

        .content {
          display: flex;
          flex-flow: column nowrap;
        }

        .field {
          margin: ${CSS_SPACING_ROW} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .container-info {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
          padding-top: ${CSS_SPACING};
        }

        .full {
          grid-column: 1 / span 2;
        }

        .item {
          display: flex;
          flex-direction: row;
          padding: 20px;
        }

        .taxonomy-description {
          padding: 5px 0 0 15px;
          grid-column-start: 1;
          grid-column-end: span 2;
          margin-left: ${CSS_SPACING};
        }

        .container {
          display: grid;
          gap: 0 20px;
          grid-template-columns: 1fr 1fr;
        }

        .radio-button-container {
          display: flex;
          column-gap: ${CSS_SPACING};
        }

        .tax-id {
          align-items: baseline;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  static createModel() {
    return {
      licenses: [
        {
          license: '',
          state: '',
        },
      ],
      NPI: '',
      organizationNPI: '',
      taxId: '',
      taxIdType: FEDERAL_TAX_ID_TYPE.SSN,
      taxonomy: {
        code: '',
        description: '',
        label: '',
      },
      identifiers: [
        {
          number: '',
          type: {
            value: '',
            label: '',
          },
        },
      ],
      substituteProviders: [
        {
          providerId: '',
          payerIds: [],
        },
      ],
    };
  }

  static createSelectors(items, enableNPI = false) {
    return {
      ...this.taxonomySelector(),
      ...this.NPISelector(enableNPI),
      ...this.organizationNPISelector(),
      ...this.taxIdSelector(),
      ...this.identifierSelectors(),
      substituteProviders: NebSubstituteProviderList.createSelectors(
        items.providers,
        items.payers,
      ),
    };
  }

  static taxonomySelector() {
    return {
      taxonomy: {
        clipPristine: true,
        validators: [
          isRequired(),
          {
            error: 'Required',
            validate: v => v.data && v.data.id,
          },
        ],
        format: v =>
          TAXONOMY_DATA
            ? TAXONOMY_DATA.find(taxonomy => taxonomy.data.id === v.code)
            : TAXONOMY_DATA_EMPTY,
        unformat: v => ({
          code: v.data.id,
          description: v.data.description,
          label: v.label,
        }),
      },
    };
  }

  static identifierSelectors() {
    return {
      licenses: LicenseList.createSelectors(),
      identifiers: IdList.createSelectors(),
    };
  }

  static NPISelector(enableNPI) {
    return enableNPI
      ? {
          NPI: {
            format: v => v.toString(),
            unformat: v => Number.parseInt(v, 10),
            validators: [
              isRequired(),
              {
                error: 'Must be 10 digits',
                validate: v => v.length === 10,
              },
            ],
          },
        }
      : this.NPIEmptySelector();
  }

  static NPIEmptySelector() {
    return {
      NPI: {
        format: v => v,
        unformat: v => v || null,
      },
    };
  }

  static organizationNPISelector() {
    return {
      organizationNPI: {
        format: v => v.toString(),
        unformat: v => Number.parseInt(v, 10) || null,
        validators: [
          {
            error: 'Must be 10 digits',
            validate: v => v.length === 10 || !v.length,
          },
        ],
      },
    };
  }

  static taxIdSelector() {
    return {
      taxId: {
        format: v => v,
        unformat: v => v || null,
        validators: [
          {
            error: 'Must be 9 digits',
            validate: v => v.replace(/-/g, '').length === 9 || !v.length,
          },
        ],
      },
    };
  }

  __initState() {
    this.__search = '';
    this.__searchResult = [];
    this.__showResults = false;

    this.model = this.constructor.createModel();
    this.errors = EMPTY_ERRORS;
    this.header = '';
    this.maxLength = 10;
    this.isProvider = false;
    this.providerItems = [];
    this.payerItems = [];

    this.onAdd = () => {};

    this.onChange = () => {};

    this.onRemove = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e => this.onChange(e),
      search: e => {
        this.__search = e.value;
        if (!this.__search) this.__showResults = true;

        this.__searchTaxonomy();
      },
      add: name => {
        this.onAdd(name);
      },
      remove: (name, index) => this.onRemove(name, index),
      select: item => {
        this.__selectTaxonomy(item);
      },
      changeTaxIdType: e => {
        this.onChange(e);

        const rawTaxId = this.model.taxId.replace(/-/g, '');

        const formattedTaxId = isSSN(e.value)
          ? ssn.toFormattedValue(rawTaxId)
          : taxId.toFormattedValue(rawTaxId);

        this.onChange({ name: 'taxId', value: formattedTaxId });
      },
    };
  }

  __searchTaxonomy() {
    const queryTerms = this.__search
      .trim()
      .toLowerCase()
      .split(' ');

    this.__searchResult = TAXONOMY_DATA.filter(taxonomy => {
      const search = [
        taxonomy.data.code,
        taxonomy.data.description,
        taxonomy.label,
      ]
        .join(' ')
        .toLowerCase();
      return queryTerms.every(term =>
        search.includes(removeSpecialCharacters(term)),
      );
    });
  }

  __selectTaxonomy(item) {
    this.__showResults = false;
    const taxonomy = {
      ...item,
      value: typeof item.value === 'object' ? item.value : TAXONOMY_DATA_EMPTY,
    };
    this.onChange(taxonomy);
  }

  __renderTaxId() {
    return html`
      <div class="container full field tax-id">
        <neb-textfield
          id="${ELEMENTS.taxId.id}"
          name="taxId"
          label="Tax ID"
          helper=" "
          .error="${this.errors.taxId}"
          .mask="${isSSN(this.model.taxIdType) ? ssn : taxId}"
          .inputMode="${'numeric'}"
          .maxLength="${isSSN(this.model.taxIdType) ? 11 : 10}"
          .value="${this.model.taxId}"
          .onChange="${this.__handlers.change}"
        ></neb-textfield>
        <div class="radio-button-container">
          <neb-radio-button
            id="${ELEMENTS.buttonSSN.id}"
            name="taxIdType"
            .label="${FEDERAL_TAX_ID_TYPE.SSN}"
            .value="${FEDERAL_TAX_ID_TYPE.SSN}"
            .checked="${isSSN(this.model.taxIdType)}"
            .onChange="${this.__handlers.changeTaxIdType}"
          ></neb-radio-button>
          <neb-radio-button
            id="${ELEMENTS.buttonEIN.id}"
            name="taxIdType"
            .label="${FEDERAL_TAX_ID_TYPE.EIN}"
            .value="${FEDERAL_TAX_ID_TYPE.EIN}"
            .checked="${!isSSN(this.model.taxIdType)}"
            .onChange="${this.__handlers.changeTaxIdType}"
          ></neb-radio-button>
        </div>
      </div>
    `;
  }

  __renderSubstituteProvider() {
    const filterProviders = this.providerItems.filter(
      provider => provider.data.type === PROVIDER_TYPE.PROVIDER,
    );

    return filterProviders.length
      ? html`
          <neb-substitute-provider-list
            id="${ELEMENTS.substitute.id}"
            class="field full"
            name="substituteProviders"
            .model="${this.model.substituteProviders}"
            .providerItems="${filterProviders}"
            .payerItems="${this.payerItems}"
            .errors="${this.errors.substituteProviders}"
            .onAdd="${this.__handlers.add}"
            .onRemove="${this.__handlers.remove}"
            .onChange="${this.__handlers.change}"
          ></neb-substitute-provider-list>
        `
      : '';
  }

  __renderNPI() {
    return this.isProvider
      ? html`
          <div class="container full field">
            <neb-textfield
              id="${ELEMENTS.npi.id}"
              name="NPI"
              label="Provider Individual NPI"
              helper="Required"
              .error="${this.errors.NPI}"
              .mask="${number}"
              .inputMode="${'numeric'}"
              .value="${this.model.NPI}"
              .maxLength="${this.maxLength}"
              .onChange="${this.__handlers.change}"
            ></neb-textfield>
            <neb-textfield
              id="${ELEMENTS.organizationNpi.id}"
              name="organizationNPI"
              label="Provider Organization NPI"
              .error="${this.errors.organizationNPI}"
              .mask="${number}"
              .inputMode="${'numeric'}"
              .value="${this.model.organizationNPI}"
              .maxLength="${this.maxLength}"
              .onChange="${this.__handlers.change}"
            ></neb-textfield>
          </div>
        `
      : '';
  }

  renderTaxonomyDescription() {
    return this.model.taxonomy.label
      ? html`
          <div id="${ELEMENTS.description.id}" class="taxonomy-description">
            ${this.model.taxonomy.data.description}
          </div>
        `
      : '';
  }

  __renderDefaultProvider() {
    const filterProviders = this.providerItems.filter(
      provider => provider.data.type === PROVIDER_TYPE.PROVIDER,
    );

    return filterProviders.length
      ? html`
          <neb-substitute-provider-list
            id="${ELEMENTS.substitute.id}"
            class="field full"
            name="substituteProviders"
            providerLabel="Default Claim Provider"
            addLabel="Add Default Claim Provider"
            .model="${this.model.substituteProviders}"
            .providerItems="${filterProviders}"
            .payerItems="${this.payerItems}"
            .errors="${this.errors.substituteProviders}"
            .onAdd="${this.__handlers.add}"
            .onRemove="${this.__handlers.remove}"
            .onChange="${this.__handlers.change}"
          ></neb-substitute-provider-list>
        `
      : '';
  }

  __renderInfo() {
    return html`
      <neb-header
        id="${ELEMENTS.header.id}"
        class="header"
        label="${this.header}"
      ></neb-header>

      <div class="container-info">
        ${this.__renderNPI()} ${this.__renderTaxId()}

        <neb-select-search
          id="${ELEMENTS.taxonomy.id}"
          name="taxonomy"
          label="Primary Taxonomy"
          class="field full"
          .search="${this.__search}"
          .value="${this.model.taxonomy}"
          .itemHeight="${ITEM_HEIGHT}"
          .items="${this.__searchResult}"
          .onSearch="${this.__handlers.search}"
          .onChange="${this.__handlers.select}"
          .showMenu="${this.__showResults}"
          showSearch
          helper="Required"
          .error="${this.errors.taxonomy}"
        ></neb-select-search>

        ${this.renderTaxonomyDescription()}

        <neb-license-list
          id="${ELEMENTS.licenses.id}"
          name="licenses"
          class="field full"
          .model="${this.model.licenses}"
          .errors="${this.errors.licenses}"
          .onChange="${this.__handlers.change}"
          .onAdd="${this.__handlers.add}"
          .onRemove="${this.__handlers.remove}"
        >
        </neb-license-list>

        <neb-id-list
          id="${ELEMENTS.identifiers.id}"
          name="identifiers"
          class="field full"
          .model="${this.model.identifiers}"
          .errors="${this.errors.identifiers}"
          .onChange="${this.__handlers.change}"
          .onAdd="${this.__handlers.add}"
          .onRemove="${this.__handlers.remove}"
        ></neb-id-list>

        ${
          this.isProvider
            ? this.__renderSubstituteProvider()
            : this.__renderDefaultProvider()
        }
      </div>
    `;
  }

  render() {
    return html`
      <div class="content">${this.__renderInfo()}</div>
    `;
  }
}
customElements.define('neb-identifier', NebIdentifier);
