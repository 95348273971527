/* eslint-disable no-await-in-loop */
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import { autoAllocatePayments } from '../../../../../../src/api-clients/auto-allocation';
import { openPayfacReaderPopup } from '../../../../../../src/features/payfac/utils';
import { hasAddOn, ADD_ONS } from '../../../../../../src/utils/add-ons';
import { formatAllocationLineItemsV2 } from '../../../../../../src/utils/allocate-charges/neb-allocate-charge-util';
import { getAutoAllocationBanner } from '../../../../../../src/utils/auto-allocation';
import {
  ERROR_AUTO_ALLOCATE_PAYMENT,
  SELECT_LOCATION_MESSAGE_FOR_RECEIPTS,
} from '../../../../../../src/utils/user-message';
import {
  allocatePayment,
  getChangedLineItemsForAllocationV2,
  deleteAllocation,
  getAllocatedCharges,
} from '../../../../../neb-api-client/src/allocation-api-client';
import { getBillingCodesWriteOffs } from '../../../../../neb-api-client/src/billing-codes';
import * as patientApiClient from '../../../../../neb-api-client/src/patient-api-client';
import { getPatientRelationships } from '../../../../../neb-api-client/src/patient-relationship-api-client';
import { getPayerPlans } from '../../../../../neb-api-client/src/payer-plan-api-client';
import {
  getElectronicPayment,
  refundElectronicPayment,
} from '../../../../../neb-api-client/src/payments/electronic-payments-api-client';
import {
  getPaymentDetail,
  updatePaymentLocation,
  voidPayment,
  refundPayment,
  printReceipt,
  updatePayment,
} from '../../../../../neb-api-client/src/payments-api-client';
import {
  getProviderUsers,
  getPracticeUsers,
} from '../../../../../neb-api-client/src/practice-users-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { VOIDED_BANNER_MESSAGE } from '../../../../../neb-patient/src/components/ledger/payment/neb-patient-payment-controller';
import { REFUNDED_BANNER_MESSAGE } from '../../../../../neb-patient/src/components/ledger/payment/neb-payments-list-page';
import { openDirtyPopup } from '../../../../../neb-popup';
import { PAYMENT_ACTION_KEYS } from '../../../../../neb-popup/src/neb-popup-payment-action';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { Dirty } from '../../../../../neb-redux/services/dirty';
import { LocationsService } from '../../../../../neb-redux/services/locations';
import {
  ELECTRONIC_PAYMENT_TYPES,
  GENIUS_SALE_TYPES,
  REFUND_MESSAGES,
} from '../../../../../neb-utils/electronic-payments-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../neb-utils/feature-util';
import {
  centsToCurrency,
  objToName,
} from '../../../../../neb-utils/formatters';
import { fetchPaymentTypeItems } from '../../../../../neb-utils/neb-ledger-util';
import {
  isPayerPayment,
  isElectronicPayment,
  is500Error,
  isRefunded,
} from '../../../../../neb-utils/neb-payment-util';
import { printPdf } from '../../../../../neb-utils/neb-pdf-print-util';
import { OVERLAY_KEYS, openOverlay } from '../../../utils/overlay-constants';
import { TABS } from '../../forms/neb-form-allocation-charges';
import NebFormPaymentView2 from '../../forms/neb-form-payment-view-2';
import Overlay from '../neb-overlay';

import { sortPatients } from './util';

export const ELEMENTS = {
  form: { id: 'form-payment-detail' },
};

const uniq = arr => [...new Set(arr)];

const OPTIONAL_PAYMENT_FIELDS = [
  'cardSaleId',
  'authEFT',
  'maskedCardDescription',
  'referenceId',
  'dateOfServiceFrom',
  'dateOfServiceTo',
  'electronicPaymentId',
  'electronicReferenceId',
  'patientOnline',
  'postedById',
];

const UPDATED_PAYMENT_BANNER_MESSAGE = {
  success: 'Payment saved successfully',
  error: 'An error has occurred when saving payment',
};

const REFUND_BANNER_MESSAGE = {
  success: 'Payment refunded successfully',
  error: 'An error occurred when refunding the payment',
};

const REMOVE_ALLOCATION_BANNER_MESSAGE = {
  success: 'Allocation(s) successfully removed',
  error: 'An error has occurred while removing the allocated payment(s)',
};

const BANNER_SUCCESS = 'Charges and payments allocated successfully';
const BANNER_ERROR = 'An error occurred when allocating the payment(s)';

class NebOverlayPaymentDetail2 extends Overlay {
  static get properties() {
    return {
      __patients: Array,
      __paymentDetail: Object,
      __loading: Boolean,
      __formModel: Object,
      __practiceUsers: Array,
      __paymentTypes: Array,
      __adjustmentTypes: Array,
      __hasRelationships: Boolean,
      __locations: Array,
      __userLocations: Array,
      __defaultLocationId: String,
      __hasFifo: Boolean,
      __hasRCMSecondaryField: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__hasRelationships = false;
    this.__hasFifo = false;

    this.__providers = [];
    this.__payerPlans = [];
    this.__patients = [];
    this.__locations = [];
    this.__userLocations = [];
    this.__defaultLocationId = '';
    this.__paymentDetail = {
      allocations: [],
      paymentId: '',
      amount: 0,
    };

    this.__selectPayment = {};
    this.__patientsHash = {};

    this.__paymentTypes = [];
    this.__practiceUsers = [];
    this.__adjustmentTypes = [];
    this.__itemFilters = {
      dateOfTransactionFrom: null,
      dateOfTransactionTo: null,
      patient: '',
      locations: [],
    };

    this.__hasRCMSecondaryField = false;

    this.onEmailReceipt = () => {};

    this.__formModel = NebFormPaymentView2.createModel();
    this.__dirtyService = new Dirty(
      (hash, nextHash) => hash !== nextHash,
      () => this.__isDirty,
    );
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      voidPayment: () => this.__voidPayment(),
      refundPayment: () => this.__refundPatientPayment(),
      allocatePayment: async () => {
        await openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
          payments: [this.__paymentDetail],
          showAdditionalCharges: true,
          selectedTab: TABS.OUTSTANDING,
        });

        return this.__load();
      },
      splitPayment: async () => {
        await openOverlay(OVERLAY_KEYS.SPLIT_PAYMENT, {
          payment: this.__paymentDetail,
        });

        return this.__load();
      },
      autoAllocate: async () => {
        try {
          const { data: allocations } = await autoAllocatePayments({
            paymentIds: [this.model.payment.id],
          });

          await this.__load();
          store.dispatch(getAutoAllocationBanner(allocations));
        } catch (e) {
          console.error(e);
          store.dispatch(openError(ERROR_AUTO_ALLOCATE_PAYMENT));
        }
      },
      filterItems: query => this.__filterItems(query),
      printPayment: () => this.__printPatientPayment(),
      printPaymentForLocation: () => this.__printPatientPaymentForLocation(),
      emailReceipt: async () => {
        const patient = await patientApiClient.fetchOne(
          this.__paymentDetail.patientId,
          true,
          false,
        );

        const res = await openPopup(POPUP_RENDER_KEYS.EMAIL_RECEIPT, {
          paymentDetail: this.__paymentDetail,
          patientEmailAddresses: patient.emailAddresses,
        });

        if (res.success) await this.__load();
      },

      changeDirty: isDirty => {
        this.__isDirty = isDirty;
      },
      cancel: () => this.__cancelEditedPayment(),
      removeAllocation: items => this.__removeAllocation(items),
      save: (model, selectIndexes) =>
        this.__paymentDetail.amount - this.__paymentDetail.available
          ? this.__saveAllocatedCharges(model, selectIndexes)
          : this.__saveEditedPayment(model),
      reload: async () => {
        await this.__load();

        if (
          !!this.__itemFilters.dateOfTransactionFrom ||
          !!this.__itemFilters.dateOfServiceFrom ||
          this.__itemFilters.locations.length ||
          this.__itemFilters.patient.data.id
        ) {
          await this.__filterItems(this.__itemFilters);
        }
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(
      ({ userLocations, defaultLocationId }) => {
        this.__locations = userLocations;
        this.__defaultLocationId = defaultLocationId;
      },
    );
  }

  async connectedCallback() {
    super.connectedCallback();

    const [
      paymentTypes,
      practiceUsers,
      adjustments,
      hasFifo,
      hasRCM,
    ] = await Promise.all([
      fetchPaymentTypeItems(),
      getPracticeUsers(),
      getBillingCodesWriteOffs({}, true),
      hasAddOn(ADD_ONS.CT_FIFO),
      hasFeatureOrBeta(FEATURE_FLAGS.RCM_SECONDARY_FIELD),
    ]);

    this.__paymentTypes = paymentTypes;
    this.__practiceUsers = practiceUsers;
    this.__adjustmentTypes = adjustments;
    this.__hasFifo = hasFifo;
    this.__hasRCMSecondaryField = hasRCM;

    this.__dirtyService.connect();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__dirtyService.disconnect();
    this.__locationsService.disconnect();
  }

  reset() {
    this.__formModel = { ...this.__formModel };
    this.__isDirty = false;
  }

  __setDefaultLocation() {
    if (this.__defaultLocationId) {
      const defaultLocation = this.__locations.find(
        location => location.id === this.__defaultLocationId,
      );

      if (defaultLocation) {
        this.__itemFilters = {
          ...this.__itemFilters,
          locations: [{ data: defaultLocation, label: defaultLocation.name }],
        };
      }
    }
  }

  async __filterItems(query) {
    const lineItems = await this.__getAllocatedCharges(query);

    this.__lineItems = lineItems;

    this.__formModel = {
      ...this.__formModel,
      items: lineItems,
    };

    this.__itemFilters = {
      dateOfTransactionFrom: query.dateOfTransactionFrom || null,
      dateOfTransactionTo: query.dateOfTransactionTo || null,
      patient: query.patient || '',
      locations: query.locations || [],
    };
  }

  async updated(changedProps) {
    if (changedProps.has('model') || changedProps.has('__defaultLocationId')) {
      if (changedProps.has('__defaultLocationId')) {
        this.__setDefaultLocation();
      }
      await this.__load();
    }

    super.updated(changedProps);
  }

  async __load() {
    this.__loading = true;

    const { payment } = this.model;

    const paymentId = payment.originalPaymentId
      ? payment.originalPaymentId
      : payment.id;

    this.__paymentDetail = await getPaymentDetail(paymentId, true);

    this.__formModel = await this.__getFormModel();

    this.__loading = false;
  }

  async __getPatientsHash(patientIds) {
    const uniqueIds = uniq(patientIds);

    const patientsList = await patientApiClient.fetchSome(
      uniqueIds,
      {},
      true,
      true,
    );

    return patientsList.reduce((memo, patient) => {
      memo[patient.id] = patient;
      return memo;
    }, {});
  }

  // eslint-disable-next-line complexity
  async __getAllocatedCharges(query) {
    const locationIds =
      query.locations && query.locations.length
        ? query.locations.map(({ data }) => data.id)
        : this.__locations.map(location => location.id);

    const filteredLocationIds = locationIds.filter(id => id);

    const queryParams = query
      ? {
          ...(query.dateOfTransactionFrom && {
            dateOfServiceFrom: moment
              .tz(query.dateOfTransactionFrom, 'UTC')
              .startOf('day')
              .toISOString(),
          }),
          ...(query.dateOfTransactionTo && {
            dateOfServiceTo: moment
              .tz(query.dateOfTransactionTo, 'UTC')
              .endOf('day')
              .toISOString(),
          }),
          ...(query.patient &&
            query.patient.data &&
            query.patient.data.id && {
              patientId: query.patient.data.id,
            }),
          ...(filteredLocationIds.length
            ? {
                locationIds: filteredLocationIds,
              }
            : {}),
        }
      : null;

    const lineItems = await getAllocatedCharges(
      this.__paymentDetail.id,
      queryParams,
      true,
    );

    const patientIds = lineItems.map(li => li.patientId);

    this.__patientsHash = await this.__getPatientsHash(patientIds);

    const formattedLineItems = formatAllocationLineItemsV2(
      lineItems,
      this.__providers,
      this.__payerPlans,
      this.__paymentDetail,
      this.__patientsHash,
    );

    return formattedLineItems;
  }

  async __getFormModel() {
    const [providers, payerPlans] = await Promise.all([
      getProviderUsers(true),
      getPayerPlans({}, null, true),
    ]);

    this.__providers = providers;
    this.__payerPlans = payerPlans.payerPlan;

    const lineItems = await this.__getAllocatedCharges({
      locations: this.__itemFilters.locations,
    });

    const patientIds = [...new Set(lineItems.map(li => li.patientId))];

    const patients = await patientApiClient.fetchSome(patientIds);

    const sortedPatients = sortPatients(patients);

    const relatedPatientIds = this.__paymentDetail.patientId
      ? (await getPatientRelationships(this.__paymentDetail.patientId)).map(
          relationship => relationship.relatedPatientId,
        )
      : [];

    this.__hasRelationships = relatedPatientIds.length > 0;

    this.__patients = [
      this.__hasRelationships
        ? {
            label: '[All Related Patients]',
            data: {
              id: '',
              patientIds: [
                this.__paymentDetail.patientId,
                ...relatedPatientIds,
              ],
            },
          }
        : { label: '[All Patients]', data: { id: '' } },
      ...sortedPatients.map(p => ({
        label: objToName(p.name, {
          reverse: true,
          middleInitial: true,
        }),
        data: { id: p.id },
      })),
    ];

    const {
      amount,
      codePayment,
      transactionDate: date,
      paymentMethod: method,
      note,
      payerName,
      payerPlan,
      payerPlanId,
      patientName,
    } = this.__paymentDetail;

    const paymentType = codePayment
      ? `${codePayment.code} - ${codePayment.description}`
      : '';

    const payer = isPayerPayment({ payerPlanId })
      ? {
          item: {
            id: payerPlan.id,
            payerName: payerPlan.payerName,
          },
          label: `(${payerPlan.alias}) ${payerPlan.payerName}`,
        }
      : payerName;

    const mapFormToPaymentDetail = Object.keys(
      NebFormPaymentView2.createModel().paymentTransaction[0],
    ).reduce(
      (prev, key) => ({ ...prev, [key]: this.__paymentDetail[key] || null }),
      {},
    );

    this.__itemFilters = {
      ...this.__itemFilters,
      patient: this.__getPatientForFilter(patientName),
    };

    return {
      paymentTransaction: [
        {
          ...mapFormToPaymentDetail,
          transactionDate: date.toISOString(),
          paymentType,
          payer,
          method,
          amount,
          payerPlanId,
          ...this.__getOptionalPaymentFields(),
        },
      ],
      note,
      items: lineItems,
    };
  }

  __getPatientForFilter(patientName) {
    if (
      this.__itemFilters.patient &&
      this.__itemFilters.patient.data.id &&
      this.__patients.some(
        p => p.data.id === this.__itemFilters.patient.data.id,
      )
    ) {
      return this.__patients.find(
        p => p.data.id === this.__itemFilters.patient.data.id,
      );
    }

    return this.__hasRelationships
      ? this.__patients[0]
      : this.__patients.find(p => p.label === patientName) ||
          this.__patients[0];
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      label: location.name,
      data: location,
    }));
  }

  __formatPaymentBody(model) {
    const {
      paymentTransaction: [updatedPayment],
      note,
    } = model;

    const paymentCode = updatedPayment.paymentType.split(' - ')[0];

    const updatedPaymentType = this.__paymentTypes.find(
      type => type.data.code === paymentCode,
    );

    const codePaymentId = updatedPaymentType.data.id;

    delete updatedPayment.payerPlanId;

    return {
      ...updatedPayment,
      codePaymentId,
      paymentMethod: updatedPayment.method,
      amount: updatedPayment.amount,
      note,
    };
  }

  async __saveEditedPayment(model) {
    try {
      const paymentBody = this.__formatPaymentBody(model);

      const result = await updatePayment(this.__paymentDetail.id, paymentBody);

      this.__formModel = { ...model };
      this.__isDirty = false;

      this.__paymentDetail = result;

      store.dispatch(openSuccess(UPDATED_PAYMENT_BANNER_MESSAGE.success));
    } catch (e) {
      console.error(e);
      store.dispatch(openError(UPDATED_PAYMENT_BANNER_MESSAGE.error));
    }
  }

  async __saveAllocatedCharges(model, selectIndexes) {
    const changedLineItems = getChangedLineItemsForAllocationV2(
      this.__formModel.items,
      model.items,
      false,
      selectIndexes,
    );

    if (changedLineItems.length === 0) return;

    try {
      await allocatePayment(this.__paymentDetail.id, changedLineItems, 4);

      await this.__load();

      store.dispatch(openSuccess(BANNER_SUCCESS));
    } catch (_) {
      store.dispatch(openError(BANNER_ERROR));
    }
  }

  async __cancelEditedPayment() {
    if (await openDirtyPopup()) {
      this.reset();
    }
  }

  async __removeAllocation(model) {
    const lineItems = model.map(li => ({
      id: li.id,
      debits: li.debits
        .map(debit => ({ id: debit.id }))
        .filter(debit => debit.id),
    }));

    try {
      await deleteAllocation(this.__paymentDetail.id, lineItems);
      await this.__load();

      store.dispatch(openSuccess(REMOVE_ALLOCATION_BANNER_MESSAGE.success));
    } catch (_) {
      store.dispatch(openError(REMOVE_ALLOCATION_BANNER_MESSAGE.error));
    }
  }

  async __voidPayment() {
    if (isElectronicPayment(this.__paymentDetail)) return;

    const paymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
      action: PAYMENT_ACTION_KEYS.VOID,
      paymentId: this.__paymentDetail.paymentNumber,
      payer: this.__paymentDetail.payerName,
      amount: centsToCurrency(this.__paymentDetail.amount),
    });

    if (paymentAction) {
      try {
        this.__paymentDetail = await voidPayment({
          paymentId: this.__paymentDetail.id,
          codeRefundId: paymentAction.selectedReason.id,
        });

        store.dispatch(openSuccess(VOIDED_BANNER_MESSAGE.success));
      } catch (e) {
        console.error(e);
        store.dispatch(openError(VOIDED_BANNER_MESSAGE.error));
      }
    }
  }

  // eslint-disable-next-line complexity
  async __refundElectronicPayment(payment, opts = {}) {
    const ePayment = await getElectronicPayment(payment.electronicPaymentId);

    let refundPaymentAction;

    if (payment.payerPlan) {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND,
        paymentId: payment.paymentNumber,
        payer: payment.payerName,
        paymentMethod: `${payment.paymentMethod} - ${
          payment.maskedCardDescription
        }`,
        amount: centsToCurrency(payment.amount),
        selectedReader: opts.selectedReader,
        selectedReason: opts.selectedReason,
      });

      if (refundPaymentAction && refundPaymentAction.selectedReason) {
        const refundPaymentBody = {
          paymentId: payment.id,
          codeRefundId: refundPaymentAction.selectedReason.id,
          refundMethod: refundPaymentAction.refundMethod,
          amount: refundPaymentAction.amount,
        };

        try {
          const result = await refundPayment(refundPaymentBody);
          this.__paymentDetail = result;
          store.dispatch(openSuccess(REFUND_BANNER_MESSAGE.success));
        } catch (e) {
          console.error(e);
          store.dispatch(openError(REFUND_BANNER_MESSAGE.error));
        }

        return undefined;
      }
    } else {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC,
        paymentId: payment.paymentNumber,
        payer: payment.payerName,
        paymentMethod: `${payment.paymentMethod} - ${
          payment.maskedCardDescription
        }`,
        amount: centsToCurrency(payment.amount),
        selectedReader: opts.selectedReader,
        selectedReason: opts.selectedReason,
        ePayment,
      });
    }

    if (refundPaymentAction) {
      if (refundPaymentAction.action === PAYMENT_ACTION_KEYS.REFUND) {
        return refundPaymentAction;
      }

      let done = false;
      let paymentSuccess;
      let geniusRefund;
      let refundPaymentResult;

      while (!done) {
        done = true;

        if (ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT) {
          geniusRefund = await openPayfacReaderPopup({
            amount: refundPaymentAction.amount / 100,
            merchantAccounts: [
              {
                id: ePayment.merchantAccountId,
                cardReaders: [refundPaymentAction.selectedReader],
                active: true,
              },
            ],
            holderId: this.__paymentDetail.patientId,
            title: 'Merchant Account Details',
            subheader:
              'Select the merchant account details for this transaction, then follow the prompts on the card reader.',
            pendingModel: {
              action: 'refund',
              patientId: this.__paymentDetail.patientId || null,
              payerPlanId: this.__paymentDetail.payerPlanId,
              amount: this.__paymentDetail.amount,
              note: this.__paymentDetail.note,
              authEFT: this.__paymentDetail.authEFT,
              cardSaleId: this.__paymentDetail.cardSaleId,
              referenceId: this.__paymentDetail.referenceId,
              codePaymentId: this.__paymentDetail.codePaymentId,
              paymentMethod: this.__paymentDetail.paymentMethod,
              dateOfServiceTo: this.__paymentDetail.dateOfServiceTo,
              dateOfServiceFrom: this.__paymentDetail.dateOfServiceFrom,
              transactionDate: this.__paymentDetail.transactionDate,
              electronicPaymentId: this.__paymentDetail.electronicPaymentId,
              electronicReferenceId: this.__paymentDetail.electronicReferenceId,
              maskedCardDescription: this.__paymentDetail.maskedCardDescription,
              codeRefundId: refundPaymentAction.selectedReason,
            },
            message: 'Payment Amount',
            confirmLabel: 'Submit',
            holderType: 'patient',
            geniusSaleType: GENIUS_SALE_TYPES.RETURN,
            logContent: {
              ...ePayment,
              patientId: this.__paymentDetail.patientId || null,
              payerPlanId: this.__paymentDetail.payerPlanId || null,
              action: 'refund - cardReader - neb-overlay-payment-detail',
            },
          });

          if (!geniusRefund) {
            this.__refundElectronicPayment(
              this.__paymentDetail,
              refundPaymentAction,
            );

            return {};
          }

          if (geniusRefund.status === 'DECLINED') {
            const response = await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              {
                ...(ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                  ? REFUND_MESSAGES.FAILED_400_MANUAL
                  : REFUND_MESSAGES.FAILED_400),
                subheader: geniusRefund.status,
                hyperLink:
                  ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                    ? 'Process Refund Manually'
                    : '',
              },
            );

            if (response.action === 'link') {
              this.__refundPatientPayment(true);
            }

            return {};
          }
        }

        try {
          const result = await openPopup(
            POPUP_RENDER_KEYS.TRANSACTION_PROCESSING,
            {
              promise: refundElectronicPayment(
                ePayment.merchantAccountId,
                ePayment.id,
                {
                  ...geniusRefund,
                  amount: refundPaymentAction.amount,
                  logContent: {
                    action: 'cardOnFile - neb-overlay-payment-detail',
                    rawModel: ePayment,
                  },
                },
              ),
            },
          );

          if (result.success) {
            paymentSuccess = true;
            const refundPaymentBody = {
              paymentId: this.__paymentDetail.id,
              codeRefundId: refundPaymentAction.selectedReason.id,
              refundMethod: refundPaymentAction.refundMethod,
              cardRefundId: result.refund.returnId || result.refund.saleId,
              amount: refundPaymentAction.amount,
            };

            refundPaymentResult = await refundPayment(refundPaymentBody);
          }

          if (result) {
            let response;

            if (result.success) {
              if (isRefunded(result.status)) {
                response = await openPopup(
                  POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                  REFUND_MESSAGES.APPROVED,
                );
              } else {
                response = await openPopup(
                  POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                  REFUND_MESSAGES.VOIDED,
                );
              }
            } else if (is500Error(result.code)) {
              response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
                ...REFUND_MESSAGES.FAILED_500,
                subheader: result.status,
              });

              if (response.action === 'confirm') {
                done = false;
              }
            } else {
              response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
                ...(ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                  ? REFUND_MESSAGES.FAILED_400_MANUAL
                  : REFUND_MESSAGES.FAILED_400),
                subheader: result.status,
                hyperLink:
                  ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                    ? 'Process Refund Manually'
                    : '',
              });

              if (response.action === 'link') {
                this.__refundPatientPayment(true);
              }
            }
          }
        } catch (e) {
          store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
        }
      }

      if (paymentSuccess) {
        store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));

        this.__paymentDetail = refundPaymentResult;

        this.__load();
      }
    }

    return refundPaymentAction;
  }

  // eslint-disable-next-line complexity
  async __refundPatientPayment(skipElectronic = false) {
    let refundPaymentAction;

    if (isElectronicPayment(this.__paymentDetail) && !skipElectronic) {
      refundPaymentAction = await this.__refundElectronicPayment(
        this.__paymentDetail,
      );

      if (
        refundPaymentAction &&
        refundPaymentAction.action !== PAYMENT_ACTION_KEYS.REFUND
      ) {
        return;
      }
    } else {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND,
        paymentId: this.__paymentDetail.paymentNumber,
        payer: this.__paymentDetail.payerName,
        paymentMethod: isElectronicPayment(this.__paymentDetail)
          ? `${this.__paymentDetail.paymentMethod} - ${
              this.__paymentDetail.maskedCardDescription
            }`
          : this.__paymentDetail.paymentMethod,
        amount: centsToCurrency(this.__paymentDetail.amount),
        patientId: this.__paymentDetail.patientId,
        parentPaymentId: this.__paymentDetail.parentPaymentId,
        id: this.__paymentDetail.id,
      });
    }

    if (refundPaymentAction) {
      try {
        const result = await refundPayment({
          paymentId: this.__paymentDetail.id,
          codeRefundId: refundPaymentAction.selectedReason.id,
          refundMethod: refundPaymentAction.refundMethod,
          amount: refundPaymentAction.amount,
        });
        this.__paymentDetail = result;
        store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));

        this.__load();
      } catch (e) {
        console.error(e);
        store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
      }
    }
  }

  async __printPatientPaymentForLocation() {
    const { location, canceled } = await openPopup(
      POPUP_RENDER_KEYS.SELECT_LOCATION,
      {
        locationId: this.__paymentDetail.locationId,
        message: SELECT_LOCATION_MESSAGE_FOR_RECEIPTS,
      },
    );

    if (!canceled) {
      this.__paymentDetail.locationId = location ? location.id : null;

      await updatePaymentLocation(
        this.__paymentDetail.id,
        this.__paymentDetail.locationId,
      );

      await this.__printPatientPayment();
    }
  }

  async __printPatientPayment() {
    if (isElectronicPayment(this.__paymentDetail)) {
      const ePayment = await getElectronicPayment(
        this.__paymentDetail.electronicPaymentId,
      );

      if (ePayment.receipt) {
        this.__paymentDetail.receipt = {
          text: JSON.parse(ePayment.receipt).text.customer_receipt,
        };
      }
    }

    const selectedLocation = this.__locations.find(
      location => location.id === this.__paymentDetail.locationId,
    );

    return printPdf(
      printReceipt({
        patientPayments: [this.__paymentDetail],
        selectedLocation,
      }),
    );
  }

  __getOptionalPaymentFields() {
    return OPTIONAL_PAYMENT_FIELDS.reduce((memo, key) => {
      if (Object.keys(this.__paymentDetail).includes(key)) {
        memo[key] = this.__paymentDetail[key];
      }
      return memo;
    }, {});
  }

  renderContent() {
    return html`
      <neb-form-payment-view-2
        id="${ELEMENTS.form.id}"
        ?readonly="${this.model.readonly}"
        .layout="${this.layout}"
        .patients="${this.__patients}"
        .patientsHash="${this.__patientsHash}"
        .locations="${this.__formatLocations(this.__locations)}"
        .patientId="${this.model.payment.patientId}"
        .paymentDetail="${this.__paymentDetail}"
        .model="${this.__formModel}"
        .itemFilters="${this.__itemFilters}"
        .onDismiss="${this.handlers.dismiss}"
        .onVoid="${this.handlers.voidPayment}"
        .onRefund="${this.handlers.refundPayment}"
        .onRemoveAllocation="${this.handlers.removeAllocation}"
        .onAllocatePayment="${this.handlers.allocatePayment}"
        .onSplitPayment="${this.handlers.splitPayment}"
        .onAutoAllocate="${this.handlers.autoAllocate}"
        .onPrint="${this.handlers.printPayment}"
        .onPrintForLocation="${this.handlers.printPaymentForLocation}"
        .onEmail="${this.handlers.emailReceipt}"
        .onChangeDirty="${this.handlers.changeDirty}"
        .onItemFiltersChange="${this.handlers.filterItems}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        .onUpdate="${this.handlers.reload}"
        .hasRelationships="${this.__hasRelationships}"
        .hasFifo="${this.__hasFifo}"
        .paymentTypes="${this.__paymentTypes}"
        .practiceUsers="${this.__practiceUsers}"
        .adjustmentTypes="${this.__adjustmentTypes}"
        .payers="${this.__payerPlans}"
        ?loading="${this.__loading}"
        .hasRCMSecondaryField="${this.__hasRCMSecondaryField}"
      ></neb-form-payment-view-2>
    `;
  }
}

customElements.define('neb-overlay-payment-detail-2', NebOverlayPaymentDetail2);
