import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { openWarning } from '../../../store';
import { PAYMENT_RESULT_CONFIG } from '../messages';

export const FISERV_ERROR_CODE = Object.freeze({
  OPERATION_CANCELLED: 8,
});

export const handleCardReaderError = async ({
  error,
  message,
  retryCallback,
  retryCallbackArgs,
}) => {
  let action;

  const response = error?.responseBody?.error?.response;

  switch (response?.status) {
    case 401:
      console.error(error);

      await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
        subheader: `${message} - Invalid API key`,
      });

      return null;

    case 403:
      console.error(error);

      await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE,
        subheader: `${message} - Invalid HSN for MID`,
      });

      return null;

    default:
      if (response?.data?.errorCode === FISERV_ERROR_CODE.OPERATION_CANCELLED) {
        openWarning('Payment canceled');

        return null;
      }

      console.error(error);

      ({ action } = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
        ...PAYMENT_RESULT_CONFIG.PAYMENT.FAILURE_RETRY,
        subheader: `${message} - ${response?.data?.errorMessage ||
          'Service Unavailable'}`,
      }));

      return action === 'confirm' ? retryCallback(...retryCallbackArgs) : null;
  }
};
