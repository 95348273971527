import ApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient({
  microservice: 'electronic-claims',
  useTenant: true,
});

export const getEPaymentFiles = async (version = 1) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'epayment-files',
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version,
  });
  return res;
};

export const uploadEPaymentFiles = async (
  ePaymentFiles,
  optOutLoadingIndicator = false,
) => {
  const res = await apiClient.makeRequest({
    path: 'epayment-files/upload',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ePaymentFiles,
    }),
    method: Method.POST,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
    optOutLoadingIndicator,
  });
  return res;
};

export const downloadEPaymentOrOtherFileById = async (
  ePaymentFileId,
  optOutLoadingIndicator = false,
) => {
  const response = await apiClient.makeRequest({
    path: `epayment-files/${ePaymentFileId}/download`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
    optOutLoadingIndicator,
  });

  const blob = await response.blob();
  return window.URL.createObjectURL(blob);
};
