import './neb-calendar-day-column';
import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';

import { html, css } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { CALENDAR_TYPES } from '../../../packages/neb-utils/calendar-resources-util';
import {
  computeFullWeekRange,
  computeWorkWeekRange,
} from '../../../packages/neb-utils/moment-flag-conversion';
import { CSS_BORDER_GREY_2, CSS_COLOR_WHITE, CSS_SPACING } from '../../styles';

import {
  NebBaseCalendarView,
  ELEMENTS as ELEMENTS_BASE,
} from './neb-base-calendar-view';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  calendarDayColumns: { selector: 'neb-calendar-day-column' },
  providerHeaders: { selector: '.provider-header' },
  locationHeaders: { selector: '.location-header' },
};

const DAY_LABELS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const MONTH_LABELS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const VIEWPORT_OFFSET = 70;
const VIEWPORT_OFFSET_LOCATIONS = VIEWPORT_OFFSET + 34;

function getWeekDays(startDate, endDate) {
  const weekDays = [];

  const day = startDate.clone();

  while (day.isBefore(endDate, 'day')) {
    weekDays.push({
      date: day.format('YYYY-MM-DD'),
      label: `${DAY_LABELS[day.day()]}, ${
        MONTH_LABELS[day.month()]
      } ${day.date()}`,
    });

    day.add(1, 'day');
  }

  return weekDays;
}

class NebProviderCalendarWeekView extends NebBaseCalendarView {
  static get properties() {
    return {
      startMinute: Number,
      endMinute: Number,
      targetDate: String,
      locations: Array,
      providers: Array,
      isWorkWeek: Boolean,
      appointmentDragAndDropSettings: Object,
      isMultiLocation: {
        type: Boolean,
        reflect: true,
        attribute: 'is-multi-location',
      },
      __weekDays: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .columns-container-layout,
        .columns-header-container-layout {
          display: flex;
          background-color: ${CSS_COLOR_WHITE};
        }

        .day-column-layout {
          flex: 1 0 0;
          display: flex;
        }

        .day-column-layout:not(:first-child) {
          padding-left: 10px;
        }

        :host([is-multi-location]) .day-column-layout:not(:first-child) {
          padding-left: 60px;
        }

        .container-provider-names {
          flex: 1 0 0;
          display: flex;
        }

        .container-location-names {
          flex: 1 0 0;
          display: flex;
        }

        .calendar-column-layout {
          flex: 1 1 0;
          box-sizing: border-box;
          min-width: 145px;
        }

        .provider-header {
          flex: 1 1 0;
          box-sizing: border-box;
          min-width: 145px;
          text-align: center;
          font-weight: bold;
          font-size: 12px;
          padding-bottom: 18px;
          height: 35px;
        }

        .location-header {
          text-align: center;
          font-weight: bold;
          font-size: 12px;
          height: 35px;
        }

        :host([is-multi-location]) .provider-header:not(:first-child) {
          margin-left: ${CSS_SPACING};
        }

        .day-header-label {
          padding-bottom: ${CSS_SPACING};
          text-align: center;
          font-size: 14px;
        }

        .day-column {
          flex: 1;
          box-sizing: border-box;
          min-width: 145px;

          border-top: ${CSS_BORDER_GREY_2};
          border-right: ${CSS_BORDER_GREY_2};
        }

        .day-column:first-child {
          border-left: ${CSS_BORDER_GREY_2};
        }

        .container-column-header:not(:first-child) {
          padding-left: 10px;
        }

        .container-column-header {
          flex: 1 0 0;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([is-multi-location])
          .container-column-header:not(:first-of-type) {
          padding-left: 60px;
        }

        .container-provider-columns {
          flex: 1 0 0;
          display: flex;
        }

        :host([is-multi-location]) .provider-spacing {
          margin-left: ${CSS_SPACING};
        }

        :host([is-multi-location]) .day-column-spacing {
          margin-left: ${CSS_SPACING};
          border-left: ${CSS_BORDER_GREY_2};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
  }

  initState() {
    super.initState();

    this.targetDate = '';
    this.locations = [];
    this.providers = [];
    this.isWorkWeek = true;
    this.viewportTopOffset = 0;
    this.isMultiLocation = false;
    this.appointmentDragAndDropSettings = {};

    this.__weekDays = [];
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('locations') && this.locations) {
      this.isMultiLocation = this.locations.length > 1;

      this.viewportTopOffset = this.isMultiLocation
        ? VIEWPORT_OFFSET_LOCATIONS
        : VIEWPORT_OFFSET;
    }

    if (changedProps.has('isWorkWeek') || changedProps.has('targetDate')) {
      const momentWeekRange = this.isWorkWeek
        ? computeWorkWeekRange(true, this.targetDate)
        : computeFullWeekRange(true, this.targetDate);

      this.__weekDays = getWeekDays(momentWeekRange.start, momentWeekRange.end);
    }
  }

  firstUpdated() {
    super.firstUpdated();
    this.isMultiLocation = this.locations.length > 1;
  }

  __renderLocationHeaders() {
    return this.isMultiLocation
      ? html`
          <div class="container-location-names">
            ${
              this.providers.map((_, providerIdx) =>
                this.locations.map((location, locationIdx) => {
                  const headerClasses = {
                    'location-header': true,
                    'calendar-column-layout': true,
                    'provider-spacing': providerIdx && locationIdx === 0,
                  };

                  return html`
                    <div class="${classMap(headerClasses)}">
                      ${location.name}
                    </div>
                  `;
                }),
              )
            }
          </div>
        `
      : '';
  }

  __renderProviderHeaders() {
    return this.providers.map(
      provider => html`
        <div class="provider-header">${provider.lastName}</div>
      `,
    );
  }

  __renderDayColumns(day) {
    return this.providers.map(
      (provider, providerIdx) => html`
          ${this.locations.map((location, locationIdx) => {
            const { events = null, masked = null } = this.getEvents(
              day.date,
              provider.id,
              location.id,
            );

            const dayColumnClasses = {
              'day-column': true,
              'calendar-column-layout': true,
              'day-column-spacing': providerIdx && locationIdx === 0,
            };

            return html`
              <neb-calendar-day-column
                class="${classMap(dayColumnClasses)}"
                type="${CALENDAR_TYPES.PROVIDER}"
                .events="${events}"
                .maskedSlots="${masked}"
                .locations="${this.locations}"
                .locationId="${location.id}"
                .providerId="${provider.id}"
                .providers="${this.providers}"
                .start="${this.startMinute}"
                .end="${this.endMinute}"
                .date="${day.date}"
                .zoomInterval="${this.zoomInterval}"
                .isDragging="${this.isDragging}"
                .active="${location.active}"
                .appointmentDragAndDropSettings="${
                  this.appointmentDragAndDropSettings
                }"
                .onUpdateCalendar="${this.handlers.updateCalendar}"
                .onUpdateDragging="${this.handlers.updateDragging}"
              ></neb-calendar-day-column>
            `;
          })}
        </div>
      `,
    );
  }

  renderColumnHeaders() {
    return this.__weekDays.map(
      day => html`
        <div class="container-column-header">
          <div class="day-header-label">${day.label}</div>

          <div class="container-provider-names">
            ${this.__renderProviderHeaders()}
          </div>
          ${this.__renderLocationHeaders()}
        </div>
      `,
    );
  }

  renderColumns() {
    return this.__weekDays.map(
      day =>
        html`
          <div class="day-column-layout container-column">
            ${this.__renderDayColumns(day)}
          </div>
        `,
    );
  }
}

customElements.define(
  'neb-provider-calendar-week-view',
  NebProviderCalendarWeekView,
);
