import { LitElement, css, html } from 'lit';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table-ledger-activity-encounters';
import '../../../../packages/neb-lit-components/src/components/encounter/neb-encounter-card-list';
import '../../../../packages/neb-appointment/neb-appointments';

import { ALL_QUERY_META } from '../../../../packages/neb-appointment/neb-appointment-query-meta';
import { queryAppointments } from '../../../../packages/neb-appointment/neb-appointments-query';
import { ALL_STATUSES_EXCEPT_DELETED } from '../../../../packages/neb-patient/src/components/neb-patient-appointments';
import * as rteInsuranceApi from '../../../api-clients/insurance-status';
import { ADD_ONS, hasAddOn } from '../../../utils/add-ons';

export const ELEMENTS = {
  listAppointments: {
    id: 'list-appointments',
  },
  appointmentsTable: { id: 'appointments' },
};

class NebPageCaseAppointments extends LitElement {
  static get properties() {
    return {
      caseId: {
        type: String,
      },
      patientId: {
        type: String,
      },
      __appointments: {
        type: Object,
      },
      __resetPage: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.initState();
  }

  initState() {
    this.__initHandlers();
    this.caseId = '';
    this.patientId = '';
    this.__hasCTVerify = false;
    this.__resetPage = false;

    this.__appointments = {
      appointments: [],
      queryMetadata: {},
      count: 0,
    };

    this.__query = {
      ...ALL_QUERY_META(),
      columns: ['dateAndTime', 'rte', 'type', 'provider', 'status'],
    };
  }

  __initHandlers() {
    this.__handlers = {
      tableUpdated: queryParam => {
        if (!queryParam) {
          queryParam = this.__buildDefaultQueryParam(this.__query);
          this.__resetPage = !this.__resetPage;
        }
        this.__fetch(queryParam);
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__hasCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
    const query = this.__buildDefaultQueryParam(this.__query);
    await this.__fetch(query);
  }

  async __fetch(query) {
    let data = await queryAppointments({
      ...query,
      standardSort: true,
    });

    if (this.__hasCTVerify) data = await this.__updateRTEStatuses(data);

    this.__appointments = data;
  }

  async __updateRTEStatuses(apt) {
    const insurancesStatuses = await rteInsuranceApi.fetchMany(
      apt.appointments,
    );

    if (insurancesStatuses.length) {
      return {
        ...apt,
        appointments: apt.appointments.map(appointment => ({
          ...appointment,
          realTimeEligibilityStatus: insurancesStatuses.find(insurance =>
            Object.values(appointment).includes(insurance.appointmentId),
          ).status,
        })),
      };
    }

    return apt;
  }

  __buildDefaultQueryParam(query) {
    const {
      sort: { field, dir },
      pageSize,
      filters,
    } = query;

    return {
      patientId: this.patientId,
      caseIds: [this.caseId],
      sortField: field,
      sortDir: dir,
      secondarySortField: field,
      secondarySortDir: dir,
      limit: pageSize,
      expand: 'appointmentType,splits',
      status: ALL_STATUSES_EXCEPT_DELETED,
      ...filters,
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .container {
        display: flex;
        overflow: auto;
        width: auto;
        height: auto;
        min-height: 0;
        flex-flow: column nowrap;
        flex: 1 0 0px;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }

      .table {
        flex-shrink: 0;
      }
    `;
  }

  render() {
    return html`
      <div class="container">
        <neb-appointments
          id="${ELEMENTS.appointmentsTable.id}"
          class="table"
          title=""
          emptyMessage="There are no appointments linked to this case."
          .active="${true}"
          .patientId="${this.patientId}"
          .query="${this.__query}"
          .model="${this.__appointments}"
          .onTableUpdated="${this.__handlers.tableUpdated}"
          .resetPage="${this.__resetPage}"
          .isFromCase="${true}"
        >
        </neb-appointments>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-page-case-appointments',
  NebPageCaseAppointments,
);
