import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

export function getPatientPayers(patientId, optOutLoadingIndicator = false) {
  const path = `patients/${patientId}/ledger/payers`;

  return apiClient.makeRequest({
    method: Method.GET,
    path,
    cacheKey: path,
    version: 1,
    optOutLoadingIndicator,
  });
}

export function getPatientPaymentPayers(patientId) {
  const path = `patients/${patientId}/payment-payers`;

  return apiClient.makeRequest({
    method: Method.GET,
    path,
    cacheKey: path,
    version: 1,
  });
}
