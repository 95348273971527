import './neb-encounter-list-card';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_BORDER_GREY_2,
  CSS_COLOR_GREY_4,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { NebScrollThreshold } from '../../services/neb-scroll-threshold';

const ID_CONTAINER = 'list-container';
const ID_ITEM_PREFIX = 'item-';

export const ELEMENTS = {
  container: {
    id: `${ID_CONTAINER}`,
  },
  items: {
    selector: `[id^=${ID_ITEM_PREFIX}] :first-child`,
    tag: 'neb-encounter-list-card',
  },
  itemsCard: {
    selector: `[id^=${ID_ITEM_PREFIX}card-]`,
  },
  spacers: {
    selector: '.spacer',
  },
};

class NebEncounterListItem extends LitElement {
  static get properties() {
    return {
      toggledIndex: Number,
      hasPriorEncounters: Boolean,
      items: {
        type: Array,
      },
      scrollToIndex: {
        type: Number,
      },
      encounterId: { type: String },
      renderSpacers: { type: Boolean },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          overflow: hidden;
          display: block;
          height: 100%;
        }

        .container {
          height: 100%;
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
          position: relative;
        }

        .spacer {
          text-align: center;
          font-weight: bold;
          color: ${CSS_COLOR_HIGHLIGHT};
          padding: 15px;
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
          background-color: ${CSS_COLOR_GREY_4};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();

    this.__initGetElements();
  }

  __initState() {
    this.__didScroll = false;
    this.__itemTops = [];

    this.items = [];
    this.lowerThreshold = 0;
    this.topVisibleIndex = 0;
    this.upperThreshold = 0;
    this.renderSpacers = false;
    this.toggledIndex = -1;
    this.hasPriorEncounters = true;

    this.encounterId = '';

    this.onSelect = () => {};

    this.onUpperThreshold = () => {};

    this.onLowerThreshold = () => {};

    this.onTopVisibleIndexChange = () => {};

    this.onDisplayCard = () => {};

    this.onQuickAction = () => {};

    this.onToggleItem = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      scroll: e => this.__scroll(e),
      select: e => this.onSelect(e),
      toggle: item => this.onToggleItem(item),
      action: (item, index) => {
        this.onAction(item, index);
      },
      quickAction: () => {
        this.onQuickAction();
      },
      cardDisplayed: model => {
        this.onDisplayCard(model);
      },
    };
  }

  __initGetElements() {
    this.__getElement = {
      container: () => this.shadowRoot.getElementById(ELEMENTS.container.id),
      item: index =>
        this.shadowRoot.getElementById(`${ID_ITEM_PREFIX}${index}`),
      items: () =>
        this.shadowRoot.querySelectorAll(`[id^='${ID_ITEM_PREFIX}']`),
    };
  }

  __scroll() {
    // TODO - bcalisch: Look into Twitter performance fix for scrolling.
    this.__didScroll = true;
  }

  firstUpdated() {
    window.setInterval(() => {
      if (this.__didScroll) {
        this.__didScroll = false;

        const container = this.__getElement.container();

        const { scrollTop } = container;
        const index = this.__itemTops.findIndex(item => item > scrollTop) - 1;
        if (index !== this.topVisibleIndex) this.onTopVisibleIndexChange(index);
        this.topVisibleIndex = index;
      }
    }, 250);

    const container = this.__getElement.container();

    this.scrollThreshold = new NebScrollThreshold(container, {
      upperThreshold: this.upperThreshold,
      lowerThreshold: this.lowerThreshold,
      onLowerThreshold: this.onLowerCallback,
      onUpperThreshold: this.onUpperCallback,
    });

    this.__recalculateItemTops();

    container.addEventListener('scroll', this.__handlers.scroll);
  }

  async updated(changed) {
    if (changed.has('scrollToIndex') && this.scrollToIndex >= 0) {
      const item = this.__getElement.item(this.scrollToIndex);

      if (item) {
        await item.updateComplete;
        await this.updateComplete;

        const container = this.__getElement.container();
        await container.updateComplete;

        container.style['-webkit-overflow-scrolling'] = 'auto';
        item.scrollIntoView();
        container.style['-webkit-overflow-scrolling'] = 'touch';
      }
    }

    if (changed.has('items')) {
      await this.updateComplete;
      await this.__recalculateItemTops();
    }
  }

  async __recalculateItemTops() {
    const items = this.__getElement.items();

    if (!items.length) return;
    await this.updateComplete;
    this.__itemTops = [...items].map(item => item.offsetTop);
  }

  __renderItem(item, index) {
    return html`
      <neb-encounter-list-card
        id="${ID_ITEM_PREFIX}card-${index}"
        class="item"
        tabindex="0"
        index="${index}"
        .model="${item}"
        .onSelect="${this.__handlers.select}"
        .onToggle="${this.__handlers.toggle}"
        .onAction="${this.__handlers.action}"
        .onQuickAction="${this.__handlers.quickAction}"
        .onDisplayCard="${this.__handlers.cardDisplayed}"
        .hasPriorEncounters="${this.hasPriorEncounters}"
        ?expanded="${this.__toggledIndex === index}"
        ?selected="${this.encounterId === item.id}"
      ></neb-encounter-list-card>
    `;
  }

  __renderItemAndSpacers(item, index, dateSpacers) {
    const currDate = parseDate(item.start)
      .startOf('day')
      .format('dddd, MMMM D, YYYY');

    if (!dateSpacers[currDate]) {
      dateSpacers[currDate] = true;
      return html`
        <div class="spacer">${currDate}</div>
        <div id="${ID_ITEM_PREFIX}${index}">
          ${this.__renderItem(item, index)}
        </div>
      `;
    }
    return html`
      <div id="${ID_ITEM_PREFIX}${index}">
        ${this.__renderItem(item, index)}
      </div>
    `;
  }

  render() {
    if (this.renderSpacers) {
      const dateSpacers = {};
      return html`
        <div id="${ELEMENTS.container.id}" class="container">
          ${
            this.items.map(
              (item, index) => html`
                ${this.__renderItemAndSpacers(item, index, dateSpacers)}
              `,
            )
          }
        </div>
      `;
    }
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        ${
          this.items.map(
            (item, index) => html`
              <div id="${ID_ITEM_PREFIX}${index}">
                ${this.__renderItem(item, index)}
              </div>
            `,
          )
        }
      </div>
    `;
  }
}
customElements.define('neb-encounter-list-item', NebEncounterListItem);
