import '../../../../../src/components/misc/neb-icon';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../controls/neb-button-icon';
import '../neb-text';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_3,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_DISABLED,
} from '../../../../neb-styles/neb-variables';
import { MODE } from '../../../../neb-utils/table';
import { getValueByPath } from '../../../../neb-utils/utils';

export const SORT_DIR = {
  ASC: 'asc',
  DESC: 'desc',
};

export const ELEMENTS = {
  header: { id: 'header' },
  noItemsLabel: { id: 'label-no-items' },
  rows: { selector: '[id^=row-]' },
  cells: { selector: '[id^=cell-]' },
  headers: { selector: '[id^=header-]' },
  sortArrows: { selector: '[id^=icon-sort-]' },
  detailIcons: { selector: '[id^=icon-detail-]' },
  expandIcons: { selector: '[id^=icon-expand-]' },
  reorderIcons: { selector: '[id^=icon-reorder-]' },
  reorderLayers: { selector: '[id^=layer-reorder-]' },
  reorderTargets: { selector: '[id^=target-reorder-]' },
  reorderSpacers: { selector: '[id^=spacer-reorder-]' },
  removeButtons: { selector: '[id^=button-remove-]' },
  expandSections: { selector: '[id^=section-expand-]' },
  removeAll: { id: 'remove-all' },
  expandAll: { id: 'expand-all' },
  selectAllRows: { id: 'select-all' },
  selectCheckboxRows: { selector: '[id^=select-checkbox-]' },
  bulkActionMenu: { id: 'bulk-action-menu' },
  bulkActionItems: { selector: '[id^=bulk-action-item-]' },
};

const SORT_ARROWS = {
  [SORT_DIR.ASC]: {
    dir: SORT_DIR.ASC,
    cssClass: 'icon-arrow-up',
  },
  [SORT_DIR.DESC]: {
    dir: SORT_DIR.DESC,
    cssClass: 'icon-arrow-down',
  },
};

export default class Table extends LitElement {
  static get properties() {
    return {
      __startDragIndex: Number,
      __dragIndex: Number,
      __dragging: {
        reflect: true,
        type: Boolean,
        attribute: 'dragging',
      },

      name: String,
      mode: String,
      emptyMessage: String,
      model: Array,
      errors: Array,
      config: Array,
      expandFlags: Array,
      sortParams: Array,
      preview: {
        reflect: true,
        type: Boolean,
      },
      writable: {
        reflect: true,
        type: Boolean,
      },
      reorder: {
        reflect: true,
        type: Boolean,
      },
      hideHeader: {
        reflect: true,
        type: Boolean,
      },
      showRemoveButton: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        reflect: true,
        type: String,
      },
      allowPropagation: {
        reflect: true,
        type: Boolean,
      },
      showShadedRows: {
        reflect: true,
        type: Boolean,
      },
      wrapper: {
        reflect: true,
        type: Boolean,
      },
      showRemoveAll: Boolean,
      showExpandAll: Boolean,
      showBulkActionMenu: Boolean,
      bulkActionItems: Array,
      showSelectAll: Boolean,
      selectedItems: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: nowrap column;
        }

        .container-arrow {
          display: flex;
          margin-left: 4px;
          align-self: center;
          align-items: center;
          justify-content: flex-end;
          flex-flow: column nowrap;
        }

        .row {
          display: flex;
          position: relative;
          min-width: 0;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .row[hidden] {
          display: none;
        }

        .row[preview] {
          padding: 0 10px;
        }

        .row[shaded]:nth-child(odd) {
          background-color: #f5f5f5;
        }

        :host([dragging]) .row {
          user-select: none;
        }

        .row-header {
          user-select: none;
          padding: 0 ${CSS_SPACING};
          font-weight: 700;
          align-items: center;
        }

        .row-reorder {
          height: 0;
          border-bottom: none;
          background-color: ${CSS_COLOR_GREY_3};
          transition: none;
        }

        :host([dragging]) .row-reorder {
          transition: height 200ms;
        }

        .row-reorder[expanded] {
          height: 6rem;
        }

        .row-data {
          display: block;
          padding: 0 ${CSS_SPACING};
          flex-flow: column nowrap;
        }

        .row-data[expanded] {
          background-color: ${CSS_COLOR_GREY_3};
        }

        .drag-layer {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .drag-target {
          width: 100%;
          height: 50%;
        }

        .content {
          display: flex;
          min-width: 0;
          flex: 1 0 0;
          align-items: center;
        }

        .content[clickable] {
          cursor: pointer;
        }

        .cell {
          display: flex;
        }

        .cell:not(:last-child) {
          margin-right: ${CSS_SPACING};
        }

        .cell[sortable] {
          cursor: pointer;
        }

        .cell[truncate] {
          display: inline;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 0;
          min-width: 0;
        }

        .cell[wrapper] {
          word-wrap: break-word;
          white-space: normal;
          display: inline-block;
        }

        .cell-header {
          padding-bottom: 18px;
        }

        .cell-header[preview] {
          padding-bottom: 10px;
        }

        .cell-data {
          padding: 18px 0;
        }

        .cell-data[preview] {
          padding: 10px 0;
        }

        .cell-data[writable] {
          padding: 0;
        }

        .cell-icon {
          overflow: initial;
          width: 20px;
        }

        .cell-detail {
          width: 12px;
          height: 12px;
          transform: rotate(-90deg);
          fill: ${CSS_COLOR_GREY_1};
        }

        .cell-expand {
          width: 12px;
          height: 12px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .cell-expand-all {
          cursor: pointer;
        }

        .cell-expand[expanded] {
          transform: rotate(-180deg);
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-20 {
          width: 20px;
          height: 20px;
        }

        .icon-reorder {
          cursor: ns-resize;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-reorder[disabled] {
          cursor: default;
          opacity: 0.5;
        }

        .icon-arrow {
          cursor: pointer;
          width: 10px;
          height: 10px;
          fill: ${CSS_COLOR_GREY_2};
        }

        .icon-arrow[selected] {
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-arrow-up {
          transform: scaleY(-1);
        }

        .text-no-items {
          margin: 18px ${CSS_SPACING};
          color: ${CSS_COLOR_GREY_1};
          font-style: italic;
        }

        .text-no-items[preview] {
          margin: 10px;
        }

        .bulk-action-menu {
          padding: 0 20px;
          display: flex;
          gap: 20px;
        }

        .bulk-action-item {
          display: flex;
          align-items: center;
          gap: 5px;
          color: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .bulk-action-item-disabled {
          opacity: 0.65;
          color: ${CSS_COLOR_DISABLED};
          cursor: auto;
        }

        .action-icon {
          width: 16px;
          height: 16px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__dragging = false;
    this.__startDragIndex = null;
    this.__dragIndex = null;

    this.preview = false;
    this.writable = false;
    this.reorder = false;
    this.hideHeader = false;
    this.showRemoveButton = false;
    this.allowPropagation = false;
    this.wrapper = false;
    this.mode = MODE.NONE;
    this.name = '';
    this.emptyMessage = 'No items';
    this.layout = '';
    this.model = [];
    this.errors = [];
    this.config = [];
    this.expandFlags = [];
    this.sortParams = [];
    this.showRemoveAll = false;
    this.showExpandAll = false;
    this.showBulkActionMenu = false;
    this.showShadedRows = false;
    this.bulkActionItems = [];
    this.showSelectAll = false;
    this.selectedItems = [];

    this.onSort = () => {};

    this.onChange = () => {};

    this.onRemove = () => {};

    this.onReorder = () => {};

    this.onSelectRow = () => {};

    this.onSelectCell = () => {};

    this.onToggleExpand = () => {};

    this.onRemoveAll = () => {};

    this.onExpandAll = () => {};

    this.onSelectAll = () => {};

    this.onSelectCheckbox = () => {};
  }

  initHandlers() {
    this.handlers = {
      change: e => {
        const namespace = [this.name, e.name]
          .filter(item => item !== '')
          .join('.');

        this.onChange({ ...e, name: namespace });
      },
      clickCell: e => {
        const key = e.currentTarget.getAttribute('key');
        const special = key.startsWith('SPECIAL');

        if (special || (!this.writable && this.mode !== MODE.NONE)) {
          const index = Number(e.currentTarget.getAttribute('row'));
          const value = this.model[index][key];
          this.onSelectCell(this.name, key, index, value);
        }
      },
      clickRow: e => {
        if (!this.writable && this.mode !== MODE.NONE) {
          const index = Number(e.currentTarget.getAttribute('row'));
          const item = this.model[index];

          if (this.isExpandable()) {
            this.onToggleExpand(
              this.name,
              item,
              index,
              !this.expandFlags[index],
            );
          } else {
            this.onSelectRow(this.name, item, index);
          }
        }

        if (!this.allowPropagation) e.stopPropagation();
      },
      removeRow: e => {
        const index = Number(e.currentTarget.index);
        const item = this.model[index];

        if (!e.currentTarget.disabled) {
          this.onRemove(this.name, item, index);
        }

        e.stopPropagation();
      },
      sort: e => {
        const key = e.currentTarget.getAttribute('key');
        const config = this.config.filter(
          item => this.layout !== 'small' || item.mobile,
        );

        const configItem = config.find(item => key === item.key);

        if (!configItem.sortable) {
          return;
        }

        const params = this.sortParams.find(item => key === item.key) || null;

        if (params) {
          const ascending = params.dir === SORT_DIR.ASC;
          const dir = ascending ? SORT_DIR.DESC : SORT_DIR.ASC;
          const result = [
            {
              key,
              dir,
            },
            ...this.sortParams.filter(item => item.key !== params.key),
          ];

          this.onSort(this.name, result);
        } else {
          const count = config.filter(item => item.sortable).length;
          const result = [
            {
              key,
              dir: SORT_DIR.ASC,
            },
            ...this.sortParams,
          ].slice(0, count);

          this.onSort(this.name, result);
        }
      },
      dragStart: e => {
        const index = Number(e.currentTarget.getAttribute('row'));
        const rowEl = e.currentTarget.parentElement.parentElement.parentElement;

        setTimeout(() => {
          this.__startDragIndex = Number(index);
          this.__dragIndex = Number(index);

          setTimeout(() => {
            this.__dragging = true;
          }, 4);
        });

        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', '');
        e.dataTransfer.setDragImage(rowEl, 28, 28);
        e.stopPropagation();
      },
      dragEnd: () => {
        if (this.__startDragIndex !== this.__dragIndex) {
          const endIndex =
            this.__startDragIndex < this.__dragIndex
              ? this.__dragIndex - 1
              : this.__dragIndex;

          this.onReorder(this.__startDragIndex, endIndex);
        }

        this.__startDragIndex = null;
        this.__dragIndex = null;
        this.__dragging = false;
      },
      dragOver: e => {
        this.__dragIndex = Number(e.currentTarget.getAttribute('row'));

        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
      },
      removeAll: () => this.onRemoveAll(this.name),
      expandAll: () => this.onExpandAll(),
      selectAll: () => this.onSelectAll(),
      selectCheckbox: (item, index) => this.onSelectCheckbox(item, index),
      selectBulkActionItem: ({ disabled = false, onSelect = () => {} }) => {
        if (!disabled) {
          onSelect();
        }
      },
    };
  }

  shouldEnableRemoveButton(item, _rowIndex) {
    if (item.disableRemove) return false;

    return true;
  }

  isExpandable() {
    return this.mode === MODE.EXPAND;
  }

  isSortArrowSelected(key, dir) {
    return this.sortParams.find(item => item.key === key && item.dir === dir);
  }

  renderNoItems() {
    return this.emptyMessage;
  }

  renderHeaderCell(columnConfig) {
    return columnConfig.sortable
      ? html`
          <div class="label">${columnConfig.label}</div>

          ${this.__renderSortArrows(columnConfig)}
        `
      : columnConfig.label;
  }

  renderDataCell(value, columnConfig, _rowIndex, _name, _error) {
    return html`
      <neb-text ?truncate="${columnConfig.truncate}">${value}</neb-text>
    `;
  }

  renderExpandedRow(_index, _row, _errors) {
    return '';
  }

  __renderSortArrow(key, sortData) {
    const selected = this.isSortArrowSelected(key, sortData.dir);
    const idKey = key.replace(/\s./g, '-');

    return html`
      <neb-icon
        id="icon-sort-${idKey}-${sortData.dir}"
        class="icon-arrow ${sortData.cssClass}"
        icon="neb:arrow"
        ?selected="${selected}"
      ></neb-icon>
    `;
  }

  __renderSortArrows(item) {
    return item.sortable
      ? html`
          <div class="container-arrow">
            ${this.__renderSortArrow(item.key, SORT_ARROWS.asc)}
            ${this.__renderSortArrow(item.key, SORT_ARROWS.desc)}
          </div>
        `
      : '';
  }

  __renderSpacerCell(allow, cssClass) {
    return allow
      ? html`
          <span
            class="cell cell-header ${cssClass}"
            ?preview="${this.preview}"
          ></span>
        `
      : '';
  }

  __renderConfigHeaderCells() {
    return this.config
      .filter(item => this.layout !== 'small' || item.mobile)
      .map(
        item => html`
          <div
            id="header-${item.key}"
            class="cell cell-header"
            style="flex: ${item.flex}"
            key="${item.key}"
            @click="${this.handlers.sort}"
            ?sortable="${item.sortable}"
            ?preview="${this.preview}"
          >
            ${this.renderHeaderCell(item)}
          </div>
        `,
      );
  }

  __renderExpandAllHeader() {
    const expanded = this.expandFlags.every(flag => !!flag);
    return html`
      <div class="cell cell-header">
        <neb-icon
          id="${ELEMENTS.expandAll.id}"
          class="cell-expand cell-expand-all"
          icon="neb:chevron"
          ?expanded="${expanded}"
          key="SPECIAL.expand"
          @click="${this.handlers.expandAll}"
        ></neb-icon>
      </div>
    `;
  }

  __renderModeHeaderCell() {
    switch (this.mode) {
      case MODE.DETAIL:
        return this.__renderSpacerCell(true, 'cell-detail');

      case MODE.EXPAND:
        return this.showExpandAll
          ? this.__renderExpandAllHeader()
          : this.__renderSpacerCell(true, 'cell-expand');

      default:
        return '';
    }
  }

  __renderRemoveAll() {
    return this.showRemoveAll && this.model && this.model.length
      ? html`
          <span
            class="cell cell-icon cell-header"
            ?writable="${this.writable}"
            ?preview="${this.preview}"
            ><neb-button-icon
              id="${ELEMENTS.removeAll.id}"
              class="cell icon-20 align-offset"
              icon="neb:minus"
              @click="${this.handlers.removeAll}"
            ></neb-button-icon
          ></span>
        `
      : this.__renderSpacerCell(this.showRemoveButton, 'cell-icon');
  }

  __renderSelectAll() {
    const isAllItemsSelected =
      this.selectedItems.length > 0 && this.selectedItems.every(Boolean);

    return this.showSelectAll && this.model && this.model.length
      ? html`
          <neb-checkbox
            class="cell-header cell-checkbox"
            id="${ELEMENTS.selectAllRows.id}"
            ?checked="${isAllItemsSelected}"
            @click="${
              e => {
                this.handlers.selectAll();
                e.stopPropagation();
              }
            }"
          ></neb-checkbox>
        `
      : this.__renderSpacerCell(this.showSelectAll, 'cell-icon');
  }

  __renderHeader() {
    const reorder = this.reorder !== false;

    return !this.hideHeader
      ? html`
          <div
            id="${ELEMENTS.header.id}"
            class="row row-header"
            ?preview="${this.preview}"
          >
            ${this.__renderSpacerCell(reorder, 'cell-icon')}
            ${this.__renderSelectAll()} ${this.__renderConfigHeaderCells()}
            ${this.__renderRemoveAll()} ${this.__renderModeHeaderCell()}
          </div>
        `
      : '';
  }

  renderSubHeader() {
    return '';
  }

  __renderRemoveButton(index) {
    const enabled = this.shouldEnableRemoveButton(this.model[index], index);

    return enabled !== null
      ? html`
          <neb-button-icon
            id="button-remove-${index}"
            class="cell icon-20 align-offset"
            icon="neb:minus"
            .index="${index}"
            @click="${this.handlers.removeRow}"
            ?disabled="${!enabled}"
          ></neb-button-icon>
        `
      : '';
  }

  __renderReorderCell(index) {
    return this.reorder !== false
      ? html`
          <span
            class="cell cell-icon"
            ?writable="${this.writable}"
            ?preview="${this.preview}"
          >
            <neb-icon
              id="icon-reorder-${index}"
              class="cell icon-20 icon-reorder align-offset"
              icon="neb:reorder"
              row="${index}"
              draggable="${this.reorder === true ? 'true' : 'false'}"
              @touchstart="${this.handlers.touchstart}"
              @touchmove="${this.handlers.touchmove}"
              @touchend="${this.handlers.touchend}"
              @dragstart="${this.handlers.dragStart}"
              @dragend="${this.handlers.dragEnd}"
              ?disabled="${this.reorder === null}"
            ></neb-icon>
          </span>
        `
      : '';
  }

  __renderRemoveCell(index) {
    return this.showRemoveButton
      ? html`
          <span
            class="cell cell-icon"
            ?writable="${this.writable}"
            ?preview="${this.preview}"
            >${this.__renderRemoveButton(index)}</span
          >
        `
      : '';
  }

  __renderSelectCheckboxCell(index) {
    return this.showSelectAll && this.model && this.model.length
      ? html`
          <neb-checkbox
            id="select-checkbox-${index}"
            class="cell-checkbox"
            .name="${index}"
            ?checked="${this.selectedItems[index] || false}"
            @click="${
              e => {
                this.handlers.selectCheckbox(this.model[index], index);
                e.stopPropagation();
              }
            }"
          ></neb-checkbox>
        `
      : '';
  }

  renderModeCell(index) {
    switch (this.mode) {
      case MODE.DETAIL:
        return html`
          <neb-icon
            id="icon-detail-${index}"
            class="cell cell-detail"
            icon="neb:chevron"
            key="SPECIAL.detail"
            row="${index}"
            @click="${this.handlers.clickCell}"
          ></neb-icon>
        `;

      case MODE.EXPAND:
        return html`
          <neb-icon
            id="icon-expand-${index}"
            class="cell cell-expand"
            icon="neb:chevron"
            ?expanded="${this.expandFlags[index]}"
            key="SPECIAL.expand"
            row="${index}"
            @click="${this.handlers.clickCell}"
          ></neb-icon>
        `;

      default:
        return '';
    }
  }

  __sanitizeKeyForElementId(value) {
    return value ? value.replace(/\./g, '-') : '';
  }

  __renderDataCells(item, rowIndex) {
    return this.config
      .filter(item => this.layout !== 'small' || item.mobile)
      .map(config => {
        const { key, skipRawValue } = config;

        const name = `${rowIndex}.${key}`;
        const idKey = this.__sanitizeKeyForElementId(key);

        let rawValue;
        let error;

        if (skipRawValue) {
          rawValue = '';
          error = '';
        } else {
          const keyPath = key ? key.split('.') : [];

          rawValue = key ? getValueByPath(item, keyPath) : '';
          error = key ? getValueByPath(this.errors[rowIndex], keyPath) : '';
        }

        const value = config.formatter
          ? config.formatter(rawValue, this.model[rowIndex])
          : rawValue;

        return html`
          <span
            id="cell-${idKey}-${rowIndex}"
            class="cell cell-data"
            style="flex: ${config.flex}${
              config.customStyle ? `; ${config.customStyle}` : ''
            }"
            key="${key}"
            row="${rowIndex}"
            @click="${this.handlers.clickCell}"
            ?truncate="${config.truncate}"
            ?writable="${this.writable}"
            ?preview="${this.preview}"
            ?wrapper="${this.wrapper}"
            >${this.renderDataCell(value, config, rowIndex, name, error)}</span
          >
        `;
      });
  }

  __renderReorderSpacer(index) {
    return this.reorder !== false
      ? html`
          <div
            id="spacer-reorder-${index}"
            class="row row-reorder"
            ?expanded="${index === this.__dragIndex}"
          ></div>
        `
      : '';
  }

  __renderReorderLayer(index) {
    return this.__dragIndex !== null
      ? html`
          <div id="layer-reorder-${index}" class="drag-layer">
            <div
              id="target-reorder-${index}-prev"
              class="drag-target"
              row="${index}"
              @dragover="${this.handlers.dragOver}"
              @touchmove="${this.handlers.touchmove}"
            ></div>

            <div
              id="target-reorder-${index}-next"
              class="drag-target"
              row="${index + 1}"
              @dragover="${this.handlers.dragOver}"
              @touchmove="${this.handlers.touchmove}"
            ></div>
          </div>
        `
      : '';
  }

  renderRow(item, index) {
    const rowModel = this.model[index];
    const rowErrors = this.errors[index];
    const expanded = this.expandFlags[index];

    return html`
      ${this.__renderReorderSpacer(index)}

      <div
        id="row-${index}"
        class="row row-data"
        row="${index}"
        @click="${this.handlers.clickRow}"
        ?hidden="${index === this.__startDragIndex}"
        ?preview="${this.preview}"
        ?expanded="${expanded}"
        ?shaded="${this.showShadedRows}"
      >
        <div class="content" ?clickable="${this.mode !== MODE.NONE}">
          ${this.__renderReorderCell(index)}
          ${this.__renderSelectCheckboxCell(index)}
          ${this.__renderDataCells(item, index)}
          ${this.__renderRemoveCell(index)} ${this.renderModeCell(index)}
        </div>

        ${
          expanded
            ? html`
                <div id="section-expand-${index}">
                  ${this.renderExpandedRow(index, rowModel, rowErrors)}
                </div>
              `
            : ''
        }
        ${this.__renderReorderLayer(index)}
      </div>
    `;
  }

  __renderContent() {
    return this.model && this.model.length
      ? html`
          ${this.renderSubHeader()}
          ${this.model.map((item, index) => this.renderRow(item, index))}
          ${this.__renderReorderSpacer(this.model.length)}
        `
      : html`
          <div
            id="${ELEMENTS.noItemsLabel.id}"
            class="text-no-items"
            ?preview="${this.preview}"
          >
            ${this.renderNoItems()}
          </div>
        `;
  }

  renderFooter() {
    return html``;
  }

  __renderBulkActionItem({
    id,
    label,
    icon: itemIcon,
    disabled = false,
    onSelect,
    showTextOnly = false,
  }) {
    const icon = itemIcon
      ? html`
          <neb-button-icon
            class="action-icon"
            .icon="${itemIcon}"
            ?disabled="${disabled}"
          ></neb-button-icon>
        `
      : '';

    if (showTextOnly) {
      return html`
        <div
          id="bulk-action-item-${id}"
          class="${disabled ? 'bulk-action-item-disabled' : ''}"
        >
          ${icon}
          <p>${label}</p>
        </div>
      `;
    }

    return html`
      <div
        id="bulk-action-item-${id}"
        class="bulk-action-item ${disabled ? 'bulk-action-item-disabled' : ''}"
        @click="${
          () => this.handlers.selectBulkActionItem({ disabled, onSelect })
        }"
      >
        ${icon}
        <p>${label}</p>
      </div>
    `;
  }

  __renderBulkActionMenu() {
    if (this.showBulkActionMenu && this.bulkActionItems.length >= 0) {
      return html`
        <div class="row bulk-action-menu" id="bulk-action-menu">
          ${this.bulkActionItems.map(item => this.__renderBulkActionItem(item))}
        </div>
      `;
    }

    return '';
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderBulkActionMenu()} ${this.__renderHeader()}
        ${this.__renderContent()} ${this.renderFooter()}
      </div>
    `;
  }
}

customElements.define('neb-table', Table);
