import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../../../packages/neb-lit-components/src/components/guarantor/neb-organization-card';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-switch';

import { css, html } from 'lit';

import { LABEL_ORGANIZATION_SEARCH } from '../../../../../packages/neb-lit-components/src/components/guarantor/neb-guarantor-form';
import NebTable, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { SOURCE_TYPE } from '../../../../formatters/referral-sources';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  type: { id: 'type' },
  name: { id: 'name' },
  sourceStatus: { id: 'source-status' },
  organization: { id: 'organization' },
};

const CONFIG = [
  {
    key: 'status',
    label: 'Status',
    flex: 'none',
  },
  {
    key: 'type',
    label: 'Type',
    flex: css`1 1 0`,
  },
  {
    key: 'name',
    label: 'Name',
    flex: css`3 0 0`,
  },
  {
    key: 'removeButton',
    label: '',
    flex: 'none',
  },
];

const SOURCE_TYPES = [SOURCE_TYPE.SOURCE, SOURCE_TYPE.ORGANIZATION];

const toNumeric = str => str.replace(/\D/g, '');

const removeSpecialCharacters = str => str.replace(/[()-/]/g, '');

class NebTableReferralSources extends NebTable {
  static get properties() {
    return {
      organizationItems: Array,
      originalSources: Array,
      hideInactive: Boolean,
    };
  }

  initState() {
    super.initState();

    this.config = CONFIG;
    this.organizationItems = [];
    this.originalSources = [];
    this.hideInactive = true;

    this.onChangeSourceName = () => {};

    this.onChangeSourceType = () => {};

    this.onChangeOrganization = () => {};

    this.onChangeSourceStatus = () => {};

    this.onRemove = () => {};

    this.onSearch = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeName: e => {
        const index = Number(e.name.split('.')[0]);

        const item = this.model[index];
        this.onChangeSourceName({
          value: e.value,
          event: e.event,
          index,
          item,
        });
      },
      changeType: e => {
        const index = Number(e.name.split('.')[0]);

        const item = this.model[index];
        this.onChangeSourceType({ value: e.value, event: e.event, item });
      },
      changeOrganization: e => {
        const index = Number(e.name.split('.')[0]);

        const item = this.model[index];
        this.onChangeOrganization({ value: e.value, event: e.event, item });
      },
      changeStatus: e => {
        const index = Number(e.name.split('.')[0]);

        const item = this.model[index];
        this.onChangeSourceStatus({ value: e.value, index, item });
      },
      removeRow: e => {
        const index = Number(e.currentTarget.index);

        this.onRemove({ index });

        e.stopPropagation();
      },
      searchOrganization: e => {
        const index = Number(e.name.split('.')[0]);

        this.onSearch({ value: e.value, index });
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        neb-select-search {
          width: 100%;
        }

        neb-textfield {
          width: 100%;
        }
      `,
    ];
  }

  __filterOrganizations(searchValue) {
    if (!searchValue) {
      return this.organizationItems;
    }

    const queryTerms = searchValue
      .trim()
      .toLowerCase()
      .split(' ');

    const searchResults = this.organizationItems.filter(item => {
      const phoneItems = item.phones.map(phone => toNumeric(phone.number));
      const search = [item.name, ...item.emails, ...phoneItems]
        .join(' ')
        .toLowerCase();

      return queryTerms.every(term =>
        search.includes(removeSpecialCharacters(term)),
      );
    });

    return searchResults;
  }

  renderOrgItem(model) {
    return html`
      <neb-organization-card
        .model="${model}"
        .enableIcon="${false}"
      ></neb-organization-card>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    const { key } = columnConfig;
    const item = this.model[rowIndex];

    switch (key) {
      case 'status':
        return html`
          <neb-switch
            id="${ELEMENTS.sourceStatus.id}-${rowIndex}"
            .on="${item.active}"
            .onChange="${this.handlers.changeStatus}"
            .name="${name}"
          ></neb-switch>
        `;
      case 'type':
        return html`
          <neb-select
            id="${ELEMENTS.type.id}-${rowIndex}"
            helper=" "
            .items="${SOURCE_TYPES}"
            .value="${value}"
            .name="${name}"
            .onChange="${this.handlers.changeType}"
          ></neb-select>
        `;
      case 'name':
        return item.type === SOURCE_TYPE.ORGANIZATION
          ? html`
              <neb-select-search
                id="${ELEMENTS.organization.id}-${rowIndex}"
                helper="Required"
                emptyMessage="No organizations found"
                placeholder="${LABEL_ORGANIZATION_SEARCH}"
                itemHeight="84"
                .name="${name}"
                .value="${value}"
                .items="${this.__filterOrganizations(item.search)}"
                .search="${item.search}"
                .onSearch="${this.handlers.searchOrganization}"
                .onChange="${this.handlers.changeOrganization}"
                .onRenderItem="${this.renderOrgItem}"
                .error="${error}"
                showSearch
              ></neb-select-search>
            `
          : html`
              <neb-textfield
                id="${ELEMENTS.name.id}-${rowIndex}"
                helper="Required"
                .name="${name}"
                .index="${rowIndex}"
                .value="${value}"
                .onChange="${this.handlers.changeName}"
                .error="${error}"
              ></neb-textfield>
            `;
      case 'removeButton':
        return html`
          <span
            class="cell cell-icon"
            ?writable="${this.writable}"
            ?preview="${this.preview}"
            >${!item.id ? this.__renderRemoveButton(rowIndex) : ''}</span
          >
        `;
      default:
        return value;
    }
  }

  renderRow(item, index) {
    const originalSource = this.originalSources.find(
      source => source.id === item.id,
    );

    if (
      this.hideInactive &&
      (item.id && !item.active && !originalSource?.active)
    ) {
      return '';
    }

    return super.renderRow(item, index);
  }
}

customElements.define('neb-table-referral-sources', NebTableReferralSources);
