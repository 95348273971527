import { getAppointmentBillingInfo } from '../../packages/neb-api-client/src/appointment-billing-info-api-client';
import {
  getPatientInsurances,
  getPatientInsurance,
} from '../../packages/neb-api-client/src/patient-insurance-api-client';
import { dateToIcd10Year } from '../../packages/neb-utils/icd10-util';
import { getOutOfCoverageDatePlans } from '../../packages/neb-utils/neb-ledger-util';
import {
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
  hasAuthorizationRemaining,
} from '../../packages/neb-utils/patientAuthorization';
import { mapToPatientInsuranceModel } from '../../packages/neb-utils/patientInsurance';
import { fetchOne } from '../api-clients/clinical-codes';

import {
  CHARGES_COVERAGE_WARNING,
  PATIENT_INSURANCE_VISIT_LIMIT_EXCEEDED,
  DIAGNOSIS_WARNING,
} from './user-message';

const getOutOfCoverageWarning = async ({
  appointmentId,
  patientId,
  serviceDate,
  patientPrimaryInsurance,
}) => {
  const appointmentBillingInfo = await getAppointmentBillingInfo(appointmentId);

  let outOfCoverage;

  if (appointmentBillingInfo) {
    const { primaryInsuranceId } = appointmentBillingInfo;

    if (primaryInsuranceId) {
      const rawPrimaryInsurance = await getPatientInsurance(
        patientId,
        primaryInsuranceId,
      );

      if (rawPrimaryInsurance) {
        const encounterPrimaryInsurance = mapToPatientInsuranceModel(
          rawPrimaryInsurance,
        );

        [outOfCoverage] = getOutOfCoverageDatePlans(
          [serviceDate],
          encounterPrimaryInsurance,
        );
      }
    }
  } else if (patientPrimaryInsurance) {
    [outOfCoverage] = getOutOfCoverageDatePlans(
      [serviceDate],
      patientPrimaryInsurance,
    );
  }

  if (outOfCoverage) {
    return CHARGES_COVERAGE_WARNING;
  }

  return null;
};

const getPrimaryInsuranceLimitWarning = ({ patientPrimaryInsurance }) => {
  if (patientPrimaryInsurance?.patientInsuranceVisitLimit?.totalRemaining < 0) {
    return PATIENT_INSURANCE_VISIT_LIMIT_EXCEEDED;
  }

  return null;
};

export const getChargesWarnings = async ({
  patientId,
  appointmentId,
  serviceDate,
}) => {
  let patientPrimaryInsurance;

  const [insurance] = await getPatientInsurances(patientId, {
    defaultLevel: 'Primary',
    active: true,
  });

  if (insurance) {
    patientPrimaryInsurance = mapToPatientInsuranceModel(insurance);
  }

  const outOfCoverageWarning = await getOutOfCoverageWarning({
    appointmentId,
    patientId,
    serviceDate,
    patientPrimaryInsurance,
  });

  const warnings = [];

  if (outOfCoverageWarning) warnings.push(outOfCoverageWarning);

  const primaryInsuranceWarning = getPrimaryInsuranceLimitWarning({
    patientPrimaryInsurance,
  });

  if (primaryInsuranceWarning) warnings.push(primaryInsuranceWarning);

  return warnings;
};

export const hasDiagnosisWarnings = async ({ diagnosisCodes, serviceDate }) => {
  if (!diagnosisCodes) return null;

  const validIcd10Codes = {};
  const icd10year = dateToIcd10Year(serviceDate);

  await Promise.all(
    diagnosisCodes.map(async ({ code }) => {
      validIcd10Codes[code] = await fetchOne({
        code,
        icd10year,
      });
    }),
  );

  return diagnosisCodes.some(({ code }) => !validIcd10Codes[code]);
};

export const getDiagnosisWarnings = async ({ diagnosisCodes, serviceDate }) => {
  const hasWarnings = await hasDiagnosisWarnings({
    diagnosisCodes,
    serviceDate,
  });

  if (hasWarnings) {
    return DIAGNOSIS_WARNING;
  }

  return null;
};

export const getAuthorizationWarnings = ({ patientAuthorization }) => {
  const hasWarnings = !hasAuthorizationRemaining(patientAuthorization);

  if (hasWarnings) {
    return NO_REMAINING_AUTHORIZATIONS_MESSAGE;
  }

  return null;
};
