import '@polymer/iron-icons/iron-icons';
import '@polymer/iron-icon/iron-icon';
import './neb-checkbox-old';

import { html, LitElement, css } from 'lit';

import {
  CSS_BORDER_GREY_2,
  CSS_COLOR_BLACK,
  CSS_COLOR_ERROR,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_4,
  CSS_COLOR_WHITE,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_BODY,
  CSS_BORDER_HIGHLIGHT,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  invalidText: {
    id: 'invalid-text',
  },
  option: {
    selector: '.dropdown-option',
  },
  dropdownField: {
    id: 'dropdown-field',
  },
  dropdownList: { id: 'dropdown-list' },
};
export const ID_SELECTOR_NATIVE = 'selector-native';

class NebDropdown extends LitElement {
  static get properties() {
    return {
      opened: {
        type: Boolean,
        reflect: true,
      },
      placeholder: {
        type: String,
      },
      items: {
        type: Array,
      },
      emptyMessage: {
        type: String,
      },
      multiSelect: {
        type: Boolean,
      },
      selectedItem: {
        type: String,
      },
      selectedItems: {
        type: Array,
      },
      limitSelect: {
        type: Number,
      },
      __displayValue: {
        type: String,
      },
      __disableSelect: {
        type: Boolean,
        reflect: true,
        attribute: 'disable-select',
      },
      invalid: {
        type: Boolean,
        reflect: true,
      },
      invalidText: String,
      required: {
        type: Boolean,
        value: false,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
      useEmptyDefault: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.__displayValue = '';
    this.__clicked = false;
    this.__disabledItems = [];
    this.__iconDirection = 'down';
    this.small = false;
    this.items = [];
    this.emptyMessage = '';
    this.placeholder = '';
    this.selectedItem = '';
    this.selectedItems = [];
    this.multiSelect = false;
    this.opened = false;
    this.invalidText = 'Required';
    this.invalid = false;
    this.useEmptyDefault = false;

    this.onItemSelected = () => {};
  }

  static get styles() {
    return css`
      :host {
        display: inline-block;
        position: relative;
        width: 100%;
        height: 36px;
      }

      :host(:not([opened])) .dropdown-list {
        display: none;
      }

      :host([invalid]) .dropdown-container {
        border: 1px solid ${CSS_COLOR_ERROR};
      }

      :host([invalid]) .invalid-text {
        color: ${CSS_COLOR_ERROR};
        font-size: 11px;
        position: absolute;
      }

      .dropdown-field {
        cursor: pointer;
        border: none;
        outline: none;
        overflow: hidden;
        user-select: none;
        min-width: 0;
        flex: 1 0 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${CSS_COLOR_GREY_1};
      }

      .dropdown-field[selected] {
        color: ${CSS_COLOR_BLACK};
      }

      .dropdown-list {
        position: absolute;
        max-height: var(--dropdown-max-height, 250px);
        background-color: white;
        width: 100%;
        overflow-y: auto;
        z-index: 100;

        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
          0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4);
      }

      .dropdown-text {
        font-family: ${CSS_FONT_FAMILY};
        font-size: ${CSS_FONT_SIZE_BODY};

        padding-left: 11px;
        --neb-checkbox-label-color: black;
        cursor: pointer;
      }

      .dropdown-text[element-provided] {
        padding: 0;
      }

      :host([disable-select]) .dropdown-text {
        --neb-checkbox-label-color: ${CSS_COLOR_GREY_1};
      }

      .dropdown-container {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
        align-items: center;
        border: ${CSS_BORDER_GREY_2};
        background-color: var(--dropdown-bg-color, white);
        cursor: pointer;
      }

      .dropdown-container:focus {
        border: ${CSS_BORDER_HIGHLIGHT};
        outline: none;
      }

      .dropdown-appearance {
        border-radius: 4px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: textfield;
      }

      .icon {
        cursor: pointer;
      }

      .item {
        padding-top: 4px;
        min-height: 26px;
        cursor: pointer;
      }

      .item:hover,
      neb-checkbox-old:hover {
        background-color: ${CSS_COLOR_GREY_4};
      }

      .no-items-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: var(--neb-patient-list-empty-message-padding, 10px);
        font-size: ${CSS_FONT_SIZE_BODY};
        font-style: italic;
        color: ${CSS_COLOR_GREY_1};
      }

      .native-selector {
        font-family: ${CSS_FONT_FAMILY};
        font-size: 14px;
        width: 100%;
        height: 36px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: ${CSS_COLOR_WHITE};
        outline: none;
        padding-left: 11px;
      }

      .native-selector::-ms-expand {
        display: none;
      }
    `;
  }

  updated() {
    this.__updateDisplayValue();

    this.__handleLimitSelect();
  }

  __renderNativeSelectOptions() {
    return html`
      <select
        id="${ID_SELECTOR_NATIVE}"
        class="native-selector"
        @change="${this.__onChange}"
      >
        ${
          this.useEmptyDefault
            ? html`
                <option hidden></option>
              `
            : ''
        }
        ${
          this.items.map(item => {
            const selectedItem = this.selectedItem.value || this.selectedItem;
            const currentItem = item.value || item;
            return html`
              <option
                id="row-${currentItem}"
                class="item dropdown-text"
                value="${JSON.stringify(item)}"
                ?selected="${selectedItem === currentItem}"
              >
                ${this.__getDisplayHtml(item)}
              </option>
            `;
          })
        }
      </select>
      <div class="invalid-text" id="invalid-text" ?hidden="${!this.invalid}">
        ${this.invalidText}
      </div>
    `;
  }

  __renderItems() {
    if (!this.items.length && this.emptyMessage) {
      return html`
        <div class="no-items-message">${this.emptyMessage}</div>
      `;
    }
    return this.items.map(item => this.__handleListing(item));
  }

  __handleListing(item) {
    if (this.multiSelect) {
      return html`
        <neb-checkbox-old
          id="row-${item.value || item}"
          class="dropdown-text"
          checkbox-value="${item}"
          .checked="${this.__isPreselected(item)}"
          .item="${item}"
          .label="${item.displayValue ? item.displayValue : item}"
          .disabled="${this.__isDisabled(item)}"
          .onClick="${this.__onCheckboxClick.bind(this, item)}"
          @click="${this.__handleCheckboxClick}"
        ></neb-checkbox-old>
      `;
    }
    return html`
      <div
        id="row-${item.value || item}"
        class="item dropdown-text dropdown-option"
        ?element-provided="${!!item.displayHtml}"
        .item="${item}"
        @click="${this.__onItemClick}"
      >
        ${this.__getDisplayHtml(item)}
      </div>
    `;
  }

  __handleCheckboxClick({ target }) {
    const { tagName } = target;

    if (tagName === 'NEB-CHECKBOX') {
      this.__checkboxClicked = true;

      if (!this.__checkboxOnClickCalled) {
        target.click();
      }

      this.__checkboxOnClickCalled = false;
    }
  }

  __handleSelectChanged(selectedItem) {
    this.__checkboxOnClickCalled = true;

    if (!this.__disableSelect) {
      if (this.multiSelect && this.selectedItems.length === 1) {
        this.selectedItem = this.selectedItems[0];
      } else if (this.multiSelect && this.selectedItems.length === 0) {
        this.selectedItem = '';
      } else if (!this.multiSelect) {
        this.selectedItem = selectedItem;
      }

      const detail = {
        selectedItem: this.selectedItem,
        selectedItems: this.selectedItems,
      };

      this.__updateDisplayValue();

      this.onItemSelected(detail, this);
    }
  }

  __onCheckboxClick(item) {
    const selectedCheckbox = this.shadowRoot.querySelector(
      `neb-checkbox-old[checkbox-value="${item}"]`,
    );

    this.__handleAddRemoveSelectedItems(selectedCheckbox.checked, item);

    this.__updateDisplayValue();

    this.__handleLimitSelect();
  }

  __updateDisplayValue() {
    this.__displayValue = this.__getDisplayValue();
  }

  __handleLimitSelect() {
    if (this.limitSelect && !(this.selectedItems.length < this.limitSelect)) {
      if (!this.__disableSelect) {
        this.__disableCheckboxes();
      }
    }
  }

  __handleAddRemoveSelectedItems(checked, item) {
    if (checked) {
      this.selectedItem = item;
      this.selectedItems.push(this.selectedItem);
    } else {
      const index = this.selectedItems.indexOf(item);
      this.selectedItems.splice(index, 1);

      this.__enableCheckboxes();
    }

    this.__handleSelectChanged(this.selectedItem);
  }

  __getDisplayValue() {
    if (this.multiSelect) {
      if (this.selectedItems.length > 1) {
        return `${this.selectedItems.length} of ${this.limitSelect ||
          this.items.length} selected`;
      }

      if (this.selectedItems.length === 1) {
        return this.selectedItems[0].displayValue || this.selectedItems[0];
      }

      if (this.selectedItems.length === 0) {
        this.selectedItem = null;
        return '';
      }

      return undefined;
    }

    return this.selectedItem
      ? this.selectedItem.displayValue || this.selectedItem
      : '';
  }

  __getDisplayHtml(item) {
    return (
      (item.displayHtml && item.displayHtml()) || item.displayValue || item
    );
  }

  __onFieldClick(_e) {
    if (!this.__hasListener) {
      this.__setDropdownBehavior();
    }

    this.__clicked = true;
    this.__hasListener = true;
  }

  __getCheckboxes() {
    return this.shadowRoot.querySelectorAll('neb-checkbox-old');
  }

  __disableCheckboxes() {
    const unselectedCheckboxes = this.__getUnselectedCheckboxes();

    unselectedCheckboxes.forEach(checkbox => {
      checkbox.disabled = true;
      this.__disableSelect = true;

      this.__disabledItems.push(checkbox.label);
    });
  }

  __enableCheckboxes() {
    this.__getCheckboxes().forEach(checkbox => {
      checkbox.disabled = false;
      this.__disableSelect = false;
      this.__disabledItems = [];
    });
  }

  __getUnselectedCheckboxes() {
    return Array.from(this.__getCheckboxes()).filter(
      checkbox =>
        checkbox.checked === false || typeof checkbox.checked === 'undefined',
    );
  }

  __onItemClick({ currentTarget: { item } }) {
    this.__handleSelectChanged(item);

    this.__close();
  }

  __onChange(e) {
    this.__handleSelectChanged(JSON.parse(e.currentTarget.value));
  }

  __handleShowDropdown() {
    if (
      (!this.opened && this.__clicked) ||
      (this.opened && this.__checkboxClicked)
    ) {
      this.__open();
    } else if (this.opened) {
      this.__close();
    }

    this.__clicked = false;
    this.__checkboxClicked = false;
  }

  __open() {
    this.opened = true;
    this.__iconDirection = 'up';
  }

  __close() {
    this.opened = false;
    this.__iconDirection = 'down';
    window.removeEventListener('click', this.__bindListener);
    this.__hasListener = false;
  }

  __setDropdownBehavior() {
    this.__bindListener = this.__handleShowDropdown.bind(this);
    window.addEventListener('click', this.__bindListener);
    this.__hasListener = false;
  }

  __isPreselected(item) {
    return this.selectedItems.includes(item);
  }

  __isDisabled(item) {
    return this.__disabledItems.includes(item);
  }

  render() {
    return html`
      ${
        this.small
          ? this.__renderNativeSelectOptions()
          : html`
              <div
                class="dropdown-container dropdown-appearance"
                tabindex="0"
                @click="${this.__onFieldClick}"
              >
                <div
                  id="${ELEMENTS.dropdownField.id}"
                  class="dropdown-field dropdown-text"
                  ?selected="${this.__displayValue.length}"
                >
                  ${
                    this.__displayValue.length
                      ? this.__displayValue
                      : this.placeholder
                  }
                </div>

                <iron-icon
                  class="icon"
                  icon="icons:arrow-drop-${this.__iconDirection}"
                ></iron-icon>
              </div>
              <div id="dropdown-list" class="dropdown-appearance dropdown-list">
                ${
                  this.opened
                    ? html`
                        ${this.__renderItems()}
                      `
                    : ''
                }
              </div>

              <div
                id="${ELEMENTS.invalidText.id}"
                class="invalid-text"
                ?hidden="${!this.invalid}"
              >
                ${this.invalidText}
              </div>
            `
      }
    `;
  }
}

window.customElements.define('neb-dropdown', NebDropdown);
