import '../../neb-app-layout/neb-nav-secondary-lit';
import '../../neb-lit-components/src/components/patients/neb-patient-condensed-info';

import { popPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import {
  openEncounterSummary,
  isEncounterSummaryOpened,
} from '../../neb-lit-components/src/utils/encounter-overlays-util';
import {
  OVERLAY_KEYS,
  OVERLAY_STACK_KEY,
} from '../../neb-lit-components/src/utils/overlay-constants';
import { connect, store } from '../../neb-redux/neb-redux-store';
import { CSS_SECONDARY_NAV_HEIGHT } from '../../neb-styles/neb-variables';

export const ELEMENTS = {
  patientCondensedInfo: { id: 'patient-condensed-info' },
  secondaryNav: { id: 'nav-secondary' },
};

class NebChartingTopBar extends connect(store)(LitElement) {
  static get properties() {
    return {
      name: String,
      layout: String,
      patient: Object,
      info: Object,
      user: Object,
      expanded: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandler();
  }

  __initState() {
    this.expanded = false;
    this.name = '';
    this.layout = '';
    this.patient = {
      dateOfBirth: null,
      hasPhoto: false,
      name: {
        first: '',
        middle: '',
        last: '',
        preferred: '',
        suffix: '',
      },
    };

    this.user = {};

    this.info = {};
    this.__currentOverlay = null;

    this.onUserMenuOptions = () => {};

    this.onUpdateEncounter = () => {};
  }

  __initHandler() {
    this.__handlers = {
      userMenuOptions: (label, options) =>
        this.onUserMenuOptions(label, options),

      toggleEncounterSummaryOverlay: async patientImageSrc => {
        if (this.expanded) {
          this.__closeEncounterSummaryOverlay();
          await this.onUpdateEncounter();
        } else {
          this.__openEncounterSummaryOverlay(patientImageSrc);
        }
      },
    };
  }

  __computeTopBarPatientInfo({ encounterId }) {
    this.__handleShowPatientInfoAttribute(encounterId);
  }

  __handleShowPatientInfoAttribute(encounterId) {
    if (encounterId) {
      this.setAttribute('show-patient-info', '');
    } else {
      this.removeAttribute('show-patient-info');
    }
  }

  updated() {
    this.__computeTopBarPatientInfo(this.info);
  }

  _stateChanged({ popup }) {
    this.expanded = isEncounterSummaryOpened();
    const { overlays } = popup;

    if (overlays && overlays.length) {
      this.__currentOverlay = overlays && overlays[overlays.length - 1];
    }
  }

  async __openEncounterSummaryOverlay(patientImageSrc) {
    await openEncounterSummary({
      patient: this.patient,
      appointmentTypeId: this.info.appointmentTypeId,
      encounterId: this.info.encounterId,
      patientImageSrc,
    });

    this.onUpdateEncounter();
  }

  __closeEncounterSummaryOverlay() {
    if (
      this.__currentOverlay &&
      this.__currentOverlay.key === OVERLAY_KEYS.ENCOUNTER_SUMMARY.name
    ) {
      popPopup(OVERLAY_STACK_KEY);
    }
  }

  static get styles() {
    return css`
      :host {
        height: ${CSS_SECONDARY_NAV_HEIGHT};
      }
    `;
  }

  render() {
    return html`
      <neb-nav-secondary-lit
        id="${ELEMENTS.secondaryNav.id}"
        .layout="${this.layout}"
        .apiOverride="${this.__apiOverride}"
        .user="${this.user}"
        .onUserMenuOptions="${this.__handlers.userMenuOptions}"
        hasActions
      >
        ${
          this.patient && this.patient.id
            ? html`
                <neb-patient-condensed-info
                  id="${ELEMENTS.patientCondensedInfo.id}"
                  .showTodaysEncounter="${false}"
                  .model="${this.patient}"
                  .layout="${this.layout}"
                  .onOpenOverlay="${
                    this.__handlers.toggleEncounterSummaryOverlay
                  }"
                  ?expanded="${this.expanded}"
                  slot="actions"
                ></neb-patient-condensed-info>
              `
            : ''
        }
      </neb-nav-secondary-lit>
    `;
  }
}

customElements.define('neb-charting-top-bar', NebChartingTopBar);
