import '../../../neb-tabs-old';
import '../../../neb-tab-old';
import '../../../neb-text';
import './neb-ledger-balance-statements-collection-page';
import './neb-ledger-balance-superbills-collection-page';

import { matchRouteSwitch, navigate, redirect } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { getScheduledBalancePayment } from '../../../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import { baseStyles } from '../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
} from '../../../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../../../neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../../neb-utils/feature-util';
import {
  formatDollarAmount,
  centsToCurrency,
  centsToCurrencyWithNegative,
} from '../../../../../../neb-utils/formatters';
import { openOverlay, OVERLAY_KEYS } from '../../../../utils/overlay-constants';
import { TABS as MODES } from '../charges/neb-ledger-charges';

export const TABS = {
  SUMMARY: 'summary',
  STATEMENTS: 'statements',
  SUPERBILLS: 'superbills',
};
export const ELEMENTS = {
  tabs: {
    id: 'tabs',
  },
  summaryPage: {
    id: 'summary-page',
  },
  statementsPage: {
    id: 'statements-page',
  },
  superbillsPage: {
    id: 'superbills-page',
  },
  summaryTable: {
    id: 'summary-table',
  },
  nextPaymentTable: {
    id: 'next-payment-table',
  },
  outstandingCharges: {
    id: 'outstanding-charges',
  },
  header: {
    id: 'header',
  },
  totalCharges: {
    id: 'charges-total',
  },
  payerCharges: {
    id: 'charges-payer',
  },
  patientCharges: {
    id: 'charges-patient',
  },
  adjustments: {
    id: 'adjustments',
  },
  totalPayments: {
    id: 'payments-total',
  },
  totalDiscounts: {
    id: 'total-discount',
  },
  payerPayments: {
    id: 'payment-payers',
  },
  patientPayments: {
    id: 'payments-patient',
  },
  patientDiscounts: {
    id: 'payments-discount',
  },
  balance: {
    id: 'balance',
  },
  payerBalance: {
    id: 'balance-payer',
  },
  patientBalance: {
    id: 'balance-patient',
  },
  paymentLink: {
    id: 'payment-link',
  },
  tabItems: { selector: 'neb-tab-old' },
};
const CHARGES_CONFIG = {
  mode: MODES.OPEN,
  description: 'Review charges and perform updates.',
  noChargesMessage: {
    patient: 'There are no outstanding charges for this patient.',
    practice: 'There are no outstanding charges.',
  },
};

class NebLedgerBalanceController extends LitElement {
  static get properties() {
    return {
      __selectedTab: String,
      __scheduledPayment: Object,
      balance: Object,
      patient: Object,
      layout: String,
      cases: Array,
      route: String,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__selectedTab = '';
    this.__scheduledPayment = null;
    this.layout = '';
    this.patient = null;
    this.balance = {};
    this.cases = [];
    this.route = '';

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => navigate(this.__buildRoute(tab)),
      change: () => this.onChange(),
      editPaymentPlan: async () => {
        await openOverlay(OVERLAY_KEYS.SCHEDULED_PAYMENT, {
          type: this.__scheduledPayment.scheduleType,
          patientId: this.patient.id,
          item: this.__getScheduledPaymentType(this.__scheduledPayment),
        });

        await this.fetch();
      },
    };
  }

  __isPatientLedger() {
    return this.patient && this.patient.id;
  }

  __getRouteTail() {
    const segmentsToSlice = this.__isPatientLedger() ? 2 : 3;
    const segments = this.route.split('/');
    return segments.slice(segmentsToSlice).join('/');
  }

  __buildRoute(tab) {
    return this.__isPatientLedger()
      ? `#/patients/${this.patient.id}/ledger/balance/${tab}`
      : `#/practice-ledger/ledger/balance/${tab}`;
  }

  __evaluateRoute() {
    const routeTail = this.__getRouteTail();
    return this.layout === 'large'
      ? !!this.__navItems.find(({ name }) => name === routeTail)
      : routeTail === '';
  }

  async fetchNextPayment() {
    if (!this.patient) return;

    this.__scheduledPayment = await getScheduledBalancePayment({
      patientId: this.patient.id,
    });
  }

  async fetch() {
    if (this.__selectedTab === TABS.SUMMARY) {
      this.__elements = {
        outstandingCharges: this.shadowRoot.getElementById(
          ELEMENTS.outstandingCharges.id,
        ),
      };

      this.__elements.outstandingCharges.fetch();

      await this.fetchNextPayment();
    }

    if (this.__selectedTab === TABS.STATEMENTS) {
      this.__elements = {
        statementsPage: this.shadowRoot.getElementById(
          ELEMENTS.statementsPage.id,
        ),
      };

      this.__elements.statementsPage.fetch();
    }

    if (this.__selectedTab === TABS.SUPERBILLS) {
      this.__elements = {
        superbillsPage: this.shadowRoot.getElementById(
          ELEMENTS.superbillsPage.id,
        ),
      };

      await this.__elements.superbillsPage.refetch();
    }
  }

  async connectedCallback() {
    this.__hasDisableInitialLoadFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    this.__navItems = this.genNavItems();
    super.connectedCallback();
  }

  __shouldUpdateNextPayment(changedProps) {
    return (
      this.__selectedTab === TABS.SUMMARY &&
      this.patient &&
      (changedProps.has('__selectedTab') || changedProps.has('patient'))
    );
  }

  __shouldAutoRedirect(changedProps) {
    return (
      (changedProps.has('route') && this.route) || changedProps.has('layout')
    );
  }

  __shouldRefetch(changedProps) {
    return (
      changedProps.has('__selectedTab') && this.__selectedTab !== TABS.SUMMARY
    );
  }

  async updated(changedProps) {
    if (this.__shouldAutoRedirect(changedProps)) {
      let tab = this.__getRouteTail();

      if (!this.__evaluateRoute()) {
        tab = this.layout === 'large' ? TABS.SUMMARY : '';
        redirect(this.__buildRoute(tab));
      }

      this.__selectedTab = tab;
    }

    if (this.__shouldRefetch(changedProps)) {
      await this.fetch();
    }

    if (this.__shouldUpdateNextPayment(changedProps)) {
      await this.fetchNextPayment();
    }
  }

  __getScheduledPaymentType(scheduledPayment) {
    return scheduledPayment[scheduledPayment.scheduleType];
  }

  __getScheduledPaymentDate(scheduledPayment) {
    return parseDate(scheduledPayment.start).format('dddd, MMMM D, YYYY');
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
        }

        .left {
          margin-left: ${CSS_SPACING};
        }

        .no-shrink {
          flex-shrink: 0;
        }

        .tabs {
          padding: 0 ${CSS_SPACING};
          flex: 0 0 auto;
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .balance-summary-container {
          display: flex;
          padding-bottom: 30px;
        }

        td,
        th {
          text-align: left;
          padding: 5px 8px;
          white-space: nowrap;
        }

        table.summary {
          margin: 12px ${CSS_SPACING} 0 ${CSS_SPACING};
          border-collapse: collapse;
          width: 20%;
        }

        table.summary > tbody > tr:first-child > th:first-child {
          padding-left: 0;
        }

        table.summary tr:not(:first-child) {
          border-left: ${CSS_BORDER_GREY_2};
          border-right: ${CSS_BORDER_GREY_2};
        }

        table.summary tr:last-child {
          background-color: #f5f5f5;
        }

        table.summary td,
        table.summary th {
          border-bottom: ${CSS_BORDER_GREY_2};
          padding-right: 55px;
        }

        table.summary td:not(:first-child),
        table.summary th:not(:first-child) {
          text-align: right;
        }

        table.summary td:last-child,
        table.summary th:last-child {
          padding-right: 30px;
        }

        table.next-payment {
          margin: 12px 0 0 5px;
          border-collapse: collapse;
        }

        table.next-payment tr:first-child {
          height: 29px;
        }

        table.next-payment td,
        table.next-payment th {
          border-bottom: 1px solid transparent;
        }
      `,
    ];
  }

  __renderSummaryTable() {
    const {
      payerOwed = 0,
      patientOwed = 0,
      payerPaid = 0,
      patientPaid = 0,
      adjustments = 0,
      discounts = 0,
    } = this.balance;

    return html`
      <table id="${ELEMENTS.summaryTable.id}" class="summary">
        <tr>
          <th>Charges Balance</th>
          <th></th>
          <th>Payers</th>
          <th>Patient</th>
        </tr>
        <tr>
          <td>Total Charges:</td>
          <td id="${ELEMENTS.totalCharges.id}">
            ${centsToCurrency(payerOwed + patientOwed + adjustments)}
          </td>
          <td id="${ELEMENTS.payerCharges.id}">
            ${centsToCurrency(payerOwed)}
          </td>
          <td id="${ELEMENTS.patientCharges.id}">
            ${centsToCurrency(patientOwed)}
          </td>
        </tr>
        <tr>
          <td>Adjustments:</td>
          <td id="${ELEMENTS.adjustments.id}">
            ${centsToCurrencyWithNegative(adjustments)}
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Discounts:</td>
          <td id="${ELEMENTS.totalDiscounts.id}">
            ${centsToCurrency(discounts)}
          </td>
          <td></td>
          <td id="${ELEMENTS.patientDiscounts.id}">
            ${centsToCurrency(discounts)}
          </td>
        </tr>
        <tr>
          <td>Payments:</td>
          <td id="${ELEMENTS.totalPayments.id}">
            ${centsToCurrency(payerPaid + patientPaid - discounts)}
          </td>
          <td id="${ELEMENTS.payerPayments.id}">
            ${centsToCurrency(payerPaid)}
          </td>
          <td id="${ELEMENTS.patientPayments.id}">
            ${centsToCurrency(patientPaid - discounts)}
          </td>
        </tr>
        <tr>
          <th>Balance:</th>
          <th id="${ELEMENTS.balance.id}">
            ${
              formatDollarAmount(
                payerOwed - payerPaid + patientOwed - patientPaid,
              )
            }
          </th>
          <th id="${ELEMENTS.payerBalance.id}">
            ${formatDollarAmount(payerOwed - payerPaid)}
          </th>
          <th id="${ELEMENTS.patientBalance.id}">
            ${formatDollarAmount(patientOwed - patientPaid)}
          </th>
        </tr>
      </table>
    `;
  }

  __renderNextPaymentTable() {
    return html`
      <table id="${ELEMENTS.nextPaymentTable.id}" class="next-payment">
        <tr>
          <td></td>
        </tr>
        <tr>
          <td>Next Scheduled Balance Payment</td>
        </tr>
        <tr>
          <th>
            <neb-text
              id="${ELEMENTS.paymentLink.id}"
              link
              .onClick="${this.__handlers.editPaymentPlan}"
              >${
                this.__getScheduledPaymentType(this.__scheduledPayment).name
              }</neb-text
            >
          </th>
        </tr>
        <tr>
          <td>${this.__getScheduledPaymentDate(this.__scheduledPayment)}</td>
        </tr>
        <tr>
          <th>${centsToCurrency(this.__scheduledPayment.amount)}</th>
        </tr>
      </table>
    `;
  }

  __renderOustandingCharges() {
    const noChargesMessage = this.__isPatientLedger()
      ? CHARGES_CONFIG.noChargesMessage.patient
      : CHARGES_CONFIG.noChargesMessage.practice;

    return html`
      <neb-text id="${ELEMENTS.header.id}" class="left no-shrink" bold
        >Outstanding Charges Balance</neb-text
      >
      <neb-ledger-charges
        id="${ELEMENTS.outstandingCharges.id}"
        showResponsibilityBalance="true"
        .layout="${this.layout}"
        .patient="${this.patient}"
        .cases="${this.cases}"
        .mode="${CHARGES_CONFIG.mode}"
        .description="${CHARGES_CONFIG.description}"
        .noChargesMessage="${noChargesMessage}"
        .onChange="${this.__handlers.change}"
      ></neb-ledger-charges>
    `;
  }

  __renderSummary() {
    return html`
      <div
        id="${ELEMENTS.summaryPage.id}"
        class="balance-summary-container no-shrink"
      >
        ${this.__renderSummaryTable()}
        ${this.__scheduledPayment ? this.__renderNextPaymentTable() : ''}
      </div>

      ${this.__renderOustandingCharges()}
    `;
  }

  __renderStatements() {
    const patientId = this.patient ? this.patient.id : '';

    return html`
      <neb-ledger-balance-statements-collection-page
        id="${ELEMENTS.statementsPage.id}"
        .patientId="${patientId}"
        .onChange="${this.__handlers.change}"
        .hasDisableInitialLoadFF="${this.__hasDisableInitialLoadFF}"
      >
      </neb-ledger-balance-statements-collection-page>
    `;
  }

  __renderSuperBills() {
    const patientId = this.patient ? this.patient.id : '';
    const patient = this.patient ? this.patient : null;
    return html`
      <neb-ledger-balance-superbills-collection-page
        id="${ELEMENTS.superbillsPage.id}"
        .patientId="${patientId}"
        .patient="${patient}"
        .hasDisableInitialLoadFF="${this.__hasDisableInitialLoadFF}"
      >
      </neb-ledger-balance-superbills-collection-page>
    `;
  }

  __getBasePath() {
    return `${this.__isPatientLedger() ? '' : '/ledger'}/balance`;
  }

  genNavItems() {
    const basePath = this.__getBasePath();
    return [
      {
        exact: false,
        label: 'Summary',
        name: `${TABS.SUMMARY}`,
        path: `${basePath}/${TABS.SUMMARY}/`,
        resolver: () => this.__renderSummary(),
      },
      {
        exact: false,
        label: 'Statements',
        name: `${TABS.STATEMENTS}`,
        path: `${basePath}/${TABS.STATEMENTS}/`,
        resolver: () => this.__renderStatements(),
      },
      {
        exact: false,
        label: 'Superbills',
        name: `${TABS.SUPERBILLS}`,
        path: `${basePath}/${TABS.SUPERBILLS}/`,
        resolver: () => this.__renderSuperBills(),
      },
    ];
  }

  __renderTabs() {
    return html`
      <neb-tabs-old
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selected="${this.__selectedTab}"
        .onChange="${this.__handlers.selectTab}"
      >
        ${
          this.__navItems.map(
            item => html`
              <neb-tab-old name="${item.name}">${item.label}</neb-tab-old>
            `,
          )
        }
      </neb-tabs-old>
    `;
  }

  render() {
    return html`
      ${this.__renderTabs()} ${matchRouteSwitch(this.__navItems, this.route)}
    `;
  }
}

customElements.define(
  'neb-ledger-balance-controller',
  NebLedgerBalanceController,
);
