import '../../forms/neb-form-patient-package-edit';
import '../../neb-popup-header';

import { html, css } from 'lit';

import {
  getPatientPackage,
  getPatientPackageUsedCount,
} from '../../../../../../src/api-clients/patient-package';
import * as patientApiClient from '../../../../../neb-api-client/src/patient-api-client';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import { objToName } from '../../../../../neb-utils/formatters';
import {
  createModel,
  mapToPatientPackageModel,
} from '../../../../../neb-utils/patientPackage';
import Overlay from '../neb-overlay';

const EDIT_PACKAGE_HEADER = 'Update Patient Package/Subscription';
const EDIT_PACKAGE_DESCRIPTION = 'Update package details.';
export const ELEMENTS = {
  popupHeader: {
    id: 'popup-header',
  },
  form: {
    id: 'form',
  },
};

const NAME_OPTS = {
  reverse: true,
  preferred: true,
  middleInitial: true,
};

class NebOverlayPatientPackageEdit extends Overlay {
  static get properties() {
    return {
      __patientPackage: Object,
    };
  }

  initState() {
    super.initState();
    this.__patientPackage = createModel();
    this.__disableDefault = false;
    this.model = {
      context: { fromRecurringPayment: false },
      item: { usedCount: {} },
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      completeFromRecurringPaymentLink: () => this.handlers.dismissAll(true),
    };
  }

  async __getSharedPackageOwner(carePackage) {
    const owner = await patientApiClient.fetchOne(carePackage.patientId);
    const name = objToName(owner.name, NAME_OPTS);

    return name;
  }

  __setIsDefault(rawPackage) {
    return (
      rawPackage.defaultPatientIds &&
      this.model.item &&
      rawPackage.defaultPatientIds.some(id => id === this.model.item.patientId)
    );
  }

  async connectedCallback() {
    const { id } = this.model.item;

    const rawPackage = await getPatientPackage(id, {
      includeEncounterCharges: true,
    });

    const ownerName = await this.__getSharedPackageOwner(rawPackage);

    const usedCount = await getPatientPackageUsedCount(id);

    const isDefault = !!this.__setIsDefault(rawPackage);

    this.__disableDefault = isDefault;

    this.__patientPackage = mapToPatientPackageModel(
      { ...rawPackage, ownerName, isDefault },
      usedCount,
    );

    super.connectedCallback();
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 80rem;
          flex-flow: column nowrap;
        }

        .header-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 10px ${CSS_SPACING};
          display: flex;
          flex-direction: column;
        }

        .form {
          flex: 1 0 0;
          overflow: auto;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="header-container">
        <neb-popup-header
          id="${ELEMENTS.popupHeader.id}"
          title="${EDIT_PACKAGE_HEADER}"
          .onCancel="${this.handlers.dismiss}"
          showCancelButton
        ></neb-popup-header>

        <div>${EDIT_PACKAGE_DESCRIPTION}</div>
      </div>

      <neb-form-patient-package-edit
        id="${ELEMENTS.form.id}"
        class="form"
        .layout="${this.layout}"
        .fromRecurringPayment="${
          this.model.context && this.model.context.fromRecurringPayment
        }"
        .model="${this.__patientPackage}"
        .patientId="${this.model.item ? this.model.item.patientId : ''}"
        .disableDefault="${this.__disableDefault}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.dismiss}"
        .onCompleteFromRecurringPaymentLink="${
          this.handlers.completeFromRecurringPaymentLink
        }"
      ></neb-form-patient-package-edit>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-package-edit',
  NebOverlayPatientPackageEdit,
);
