import '../../../../src/components/misc/neb-icon';
import './neb-toggle-button';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  addButton: { id: 'button-add' },
  addButtonLabel: { id: 'label-button' },
  removeIcon: { id: 'icon-remove' },
  cells: { selector: '.cell' },
};
export class NebTableRow extends LitElement {
  static get properties() {
    return {
      index: Number,
      topic: {
        reflect: true,
        type: String,
      },
      model: Object,
      config: Array,
      added: {
        reflect: true,
        type: Boolean,
      },
      showRemoveButton: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.added = false;
    this.showRemoveButton = false;
    this.index = -1;
    this.topic = '';
    this.model = {};
    this.config = [
      {
        key: '',
        flex: css`0 0 80px`,
      },
    ];

    this.onAdd = () => {};

    this.onRemove = () => {};

    this.onChange = () => {};
  }

  initHandlers() {
    this.handlers = {
      add: () => this.onAdd(this.model, this.index),
      remove: () => this.onRemove(this.model, this.index),
    };
  }

  change(key, value) {
    this.onChange({ ...this.model, [key]: value });
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          background-color: ${CSS_COLOR_WHITE};

          --cell-spacing: ${CSS_SPACING};
        }

        .container {
          display: flex;
          padding: 10px var(--cell-spacing);
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
          font-size: ${CSS_FONT_SIZE_BODY};
          min-height: 48px;
          align-items: center;
        }

        .cell {
          display: block;
          overflow-wrap: break-word;
          margin-right: ${CSS_SPACING};
          min-width: 0;
        }

        .cell:last-child {
          margin-right: 0;
        }

        :host([topic='']) .cell {
          padding: 0;
        }

        .cell-button,
        .add-button {
          width: var(--custom-add-button-column-width, 180px);
          padding: 0;
        }

        :host([layout='small']) .cell-button {
          width: auto;
        }

        .button {
          display: flex;
          cursor: pointer;
          outline: none;
          border: none;
          border-radius: 15px;
          align-items: center;
          justify-content: center;
          height: 30px;
          min-width: 30px;
          background-color: ${CSS_COLOR_GREY_4};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .button:disabled {
          cursor: unset;
          background-color: ${CSS_COLOR_GREY_2};
          color: ${CSS_COLOR_GREY_1};
        }

        :host(:not([layout='small'])) .button {
          padding: 0 ${CSS_SPACING};
        }

        .text-button {
          margin-left: 8px;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .icon-add {
          padding: 0;
          width: 12px;
          height: 12px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([added]) .icon-add {
          stroke: ${CSS_COLOR_GREY_1};
          stroke-width: 1;
        }

        .icon-remove {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .item {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderColumn(key, value, item, configItem) {
    return configItem.formatter ? configItem.formatter(value, item) : value;
  }

  __renderAddButton() {
    return this.topic
      ? html`
          <div class="cell cell-button">
            <neb-toggle-button
              id="${ELEMENTS.addButton.id}"
              class="add-button"
              .layout="${this.layout}"
              .isToggled="${this.added}"
              .onClick="${this.handlers.add}"
              .buttonText="${
                this.added ? `${this.topic} Added` : `Add ${this.topic}`
              }"
            ></neb-toggle-button>
          </div>
        `
      : '';
  }

  __renderRemoveButton() {
    return this.showRemoveButton
      ? html`
          <neb-icon
            id="${ELEMENTS.removeIcon.id}"
            class="icon-remove"
            icon="neb:minus"
            @click="${this.handlers.remove}"
          ></neb-icon>
        `
      : '';
  }

  __renderColumns() {
    return this.config.map(
      item => html`
        <div
          class="cell"
          style="flex: ${item.flex};${item.style ? item.style : ''}"
        >
          ${this.renderColumn(item.key, this.model[item.key], this.model, item)}
        </div>
      `,
    );
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderAddButton()} ${this.__renderColumns()}
        ${this.__renderRemoveButton()}
      </div>
    `;
  }
}
customElements.define('neb-table-row', NebTableRow);
