import { Method } from '../../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../../packages/neb-api-client/src/utils/api-client-v2';

import { formatPatientInfo } from './utils';

export const paymentsApiClient = new ApiClientV2({
  microservice: 'payments',
});

export const FISERV_RESPSTAT = Object.freeze({
  APPROVED: 'A',
  RETRY: 'B',
  DECLINED: 'C',
});

export const createFiservPayment = async ({
  merchantAccountId,
  amount,
  expiry,
  token,
  ecomind,
  cof,
  postal,
  address,
  address2,
  city,
  region,
  firstName,
  lastName,
  holderId,
  holderType,
  logContent,
}) => {
  const res = await paymentsApiClient.makeRequest({
    method: Method.POST,
    path:
      '/api/v1/tenants/:tenantId/fiserv/merchant-accounts/:merchantAccountId/payments',
    replacements: { merchantAccountId },
    body: {
      amount,
      expiry,
      token,
      ...(ecomind && { ecomind }),
      ...(cof && { cof }),
      holderId,
      holderType,
      logContent,
      ...formatPatientInfo({
        holderType,
        postal,
        address,
        address2,
        city,
        region,
        firstName,
        lastName,
      }),
    },
  });

  return {
    id: res.transaction ? res.transaction.id : null,
    respstat: res.fiserv.respstat,
    resptext: res.fiserv.resptext,
    status:
      res.fiserv.respstat === FISERV_RESPSTAT.APPROVED ? 'Approved' : null,
    saleId: res.fiserv.retref,
    referenceId: res.fiserv.retref,
    maskedCardNumber: res.fiserv.account,
  };
};

export const createFiservPaymentMethod = async ({
  merchantAccountId,
  expiry,
  token,
  postal,
  address,
  address2,
  city,
  region,
  firstName,
  lastName,
  holderId,
  holderType,
  description,
  logContent,
}) => {
  const res = await paymentsApiClient.makeRequest({
    method: Method.POST,
    path:
      '/api/v1/tenants/:tenantId/fiserv/merchant-accounts/:merchantAccountId/payment-methods',
    replacements: { merchantAccountId },
    body: {
      description,
      expiry,
      token,
      holderId,
      holderType,
      logContent,
      ...formatPatientInfo({
        holderType,
        postal,
        address,
        address2,
        city,
        region,
        firstName,
        lastName,
      }),
    },
  });

  return {
    id: res.paymentMethod?.id,
    status: res.fiserv.respstat,
    text: res.fiserv.resptext,
  };
};

export const fiservConnect = cardReaderId =>
  paymentsApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/fiserv/card-readers/:cardReaderId/connect',
    replacements: { cardReaderId },
  });

export const fiservReadCard = (cardReaderId, { sessionKey, amount }) =>
  paymentsApiClient.makeRequest({
    method: Method.POST,
    path:
      '/api/v1/tenants/:tenantId/fiserv/card-readers/:cardReaderId/read-card',
    replacements: { cardReaderId },
    body: { sessionKey, amount },
  });

export const fiservCancel = (cardReaderId, { sessionKey }) =>
  paymentsApiClient.makeRequest({
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/fiserv/card-readers/:cardReaderId/cancel',
    replacements: { cardReaderId },
    body: { sessionKey },
  });
