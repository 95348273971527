import './neb-category';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';

export const ELEMENTS = {
  categories: { selector: 'neb-category' },
};

class CategoryAccordion extends LitElement {
  static get properties() {
    return {
      __toggleState: Array,

      model: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: block;
          min-width: 620px;
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = [];

    this.__toggleState = [];

    this.onSelectItem = () => {};

    this.onViewRuleSets = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectItem: item => this.onSelectItem(item),
      viewRuleSets: () => this.onViewRuleSets(),

      toggleCategory: index => {
        this.__toggleState[index] = !this.__toggleState[index];

        this.__toggleState = [...this.__toggleState];
      },
    };
  }

  buildLabel({ name, items }) {
    const capitalizedLabel = name.charAt(0).toUpperCase() + name.slice(1);

    return items.length
      ? `${capitalizedLabel} (${items.length})`
      : capitalizedLabel;
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__toggleState = Array(this.model.length).fill(false);
    }

    super.update(changedProps);
  }

  render() {
    return html`
      <div class="container">
        ${
          this.model.map(
            (category, i) =>
              html`
                <neb-category
                  .icon="${category.icon}"
                  .index="${i}"
                  .items="${category.items}"
                  .label="${this.buildLabel(category)}"
                  .name="${category.name}"
                  .onClick="${this.__handlers.toggleCategory}"
                  .onSelectItem="${this.__handlers.selectItem}"
                  .onViewRuleSets="${this.__handlers.viewRuleSets}"
                  .open="${this.__toggleState[i]}"
                  .theme="${category.theme}"
                >
                </neb-category>
              `,
          )
        }
      </div>
    `;
  }
}

customElements.define('neb-category-accordion', CategoryAccordion);
