import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import * as pdfOptions from './core/neb-pdf-viewer-options';

export const ELEMENTS = {
  nextButton: {
    id: 'next-page',
  },
  previousButton: {
    id: 'previous-page',
  },
  pageNumber: {
    id: 'page-number',
  },
  pageCount: {
    id: 'page-count',
  },
  selectZoomMode: {
    id: 'select-zoom-mode',
  },
  zoomIn: {
    id: 'zoom-in',
  },
  zoomOut: {
    id: 'zoom-out',
  },
  toolbarLeft: {
    id: 'toolbar-left',
  },
  toolbarMiddle: {
    id: 'toolbar-middle',
  },
  toolbarRight: {
    id: 'toolbar-right',
  },
};
export class NebPdfViewerHeader extends LitElement {
  static get properties() {
    return {
      pageCount: Number,
      pageNumber: Number,
      zoom: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.pageCount = 0;
    this.pageNumber = 0;
    this.zoom = {
      mode: null,
      value: 0,
    };

    this.onNext = () => {};

    this.onPrevious = () => {};

    this.onZoomIn = () => {};

    this.onZoomOut = () => {};

    this.onSetZoom = () => {};

    this.onPageNumberInput = () => {};

    this.__zoomItems = pdfOptions.getDefaultZoomItems();
  }

  __initHandlers() {
    this.__handlers = {
      nextClicked: () => this.onNext(),
      previousClicked: () => this.onPrevious(),
      pageNumberInput: () => {
        this.__elements.pageNumber.value = this.onPageNumberInput(
          this.__elements.pageNumber.valueAsNumber,
        );
      },
      zoomInClicked: () => this.onZoomIn(),
      zoomOutClicked: () => this.onZoomOut(),
      changeZoom: ({ target: { selectedIndex } }) => {
        const optionItem = this.__elements.selectZoomMode[selectedIndex];

        if (optionItem) {
          this.onSetZoom({
            mode: this.__getZoomModeFromOption(optionItem),
            value: this.__getZoomValueFromOption(optionItem),
          });
        }
      },
    };
  }

  __getZoomModeFromOption(optionItem) {
    return optionItem.getAttribute('code');
  }

  __getZoomValueFromOption(optionItem) {
    return parseInt(optionItem.value, 10);
  }

  __updateSelectZoom() {
    if (this.zoom.mode === pdfOptions.ZoomMode.CUSTOM_ZOOM) {
      this.__selectCustomZoomOption();
    } else {
      this.__selectNonCustomZoomOption();
    }
  }

  __selectCustomZoomOption() {
    this.__elements.customZoomOption.text = `${this.zoom.value}%`;
    this.__elements.customZoomOption.value = this.zoom.value;
    this.__elements.customZoomOption.hidden = false;
    this.__elements.customZoomOption.selected = true;
  }

  __selectNonCustomZoomOption() {
    this.__elements.customZoomOption.hidden = true;

    const optionItem = this.__getSelectOption(this.zoom.mode, this.zoom.value);

    if (optionItem) {
      this.__elements.selectZoomMode.selectedIndex = optionItem.index;
    }
  }

  __getSelectOption(zoomMode, zoomValue) {
    return this.shadowRoot.getElementById(
      pdfOptions.getZoomId(zoomMode, zoomValue),
    );
  }

  firstUpdated() {
    this.__elements = {
      pageNumber: this.shadowRoot.getElementById(ELEMENTS.pageNumber.id),
      selectZoomMode: this.shadowRoot.getElementById(
        ELEMENTS.selectZoomMode.id,
      ),
      customZoomOption: this.__getSelectOption(pdfOptions.ZoomMode.CUSTOM_ZOOM),
    };
  }

  updated(changedProps) {
    if (changedProps.has('pageNumber')) {
      this.__elements.pageNumber.value = this.pageNumber;
    }

    if (changedProps.has('zoom') && this.zoom) {
      this.__updateSelectZoom();
    }
  }

  static get styles() {
    return css`
      .header-container {
        position: static;
        display: flex;
        height: 35px;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        background-color: #131e2a;
        z-index: 1000;
      }

      .left-panel {
        display: flex;
        flex-direction: row;
      }

      .header-font {
        font-family: Open Sans;
        font-size: 14px;
      }

      .header-text {
        color: white;
      }

      .page-input {
        display: inline;
        height: 15px;
        width: 30px;
        outline: none;
        margin-left: 5px;
      }

      .input-text-color {
        color: white;
      }

      .icon {
        display: flex;
        height: 15px;
        width: 15px;
      }

      .icon-up-arrow {
        transform: rotate(180deg);
      }

      .buttons {
        background-color: #f0f0f0;
        padding: 3px 6px;
        border-radius: 3px;
        outline: none;
      }

      .spacer {
        margin-right: 3px;
      }

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }

      .dropdownToolbarButton {
        width: 110px;
        max-width: 110px;
        padding: 0;
        overflow: hidden;
        color: white;
        background-color: #131e2a;
      }
    `;
  }

  __renderLeftToolbar() {
    return html`
      <div id="${ELEMENTS.toolbarLeft.id}" class="left-panel header-font">
        <button
          id="${ELEMENTS.previousButton.id}"
          class="buttons spacer"
          @click="${this.__handlers.previousClicked}"
        >
          <neb-icon class="icon icon-up-arrow" icon="neb:arrow"/></neb-icon>
        </button>
        <button
          id="${ELEMENTS.nextButton.id}"
          class="buttons"
          @click="${this.__handlers.nextClicked}"
        >
          <neb-icon class="icon icon-down-arrow" icon="neb:arrow"></neb-icon>
        </button>
        <div id="pages-info" class="header-text">
          <input
            id="${ELEMENTS.pageNumber.id}"
            class="page-input header-font"
            autocomplete="off"
            type="number"
            value="${this.pageNumber}"
            @change="${this.__handlers.pageNumberInput}"
          />
          <span id="${ELEMENTS.pageCount.id}">of ${this.pageCount}</span>
        </div>
      </div>
    `;
  }

  __renderMiddleToolbar() {
    return html`
      <div id="${ELEMENTS.toolbarMiddle.id}" class="left-panel header-font">
        <button
          id="${ELEMENTS.zoomIn.id}"
          @click="${this.__handlers.zoomInClicked}"
          class="buttons"
        >
          <neb-icon class="icon icon-zoomIn" icon="neb:plusOnly"></neb-icon>
        </button>
        <button
          id="${ELEMENTS.zoomOut.id}"
          @click="${this.__handlers.zoomOutClicked}"
          class="buttons"
        >
          <neb-icon class="icon icon-zoomOut" icon="neb:minusOnly"></neb-icon>
        </button>
        <select
          id="${ELEMENTS.selectZoomMode.id}"
          class="header-font dropdownToolbarButton"
          @change="${this.__handlers.changeZoom}"
        >
          ${this.__zoomItems.map(item => this.__renderOption(item))}
        </select>
      </div>
    `;
  }

  __renderOption(item) {
    switch (item.code) {
      case pdfOptions.ZoomMode.ACTUAL_SIZE:
        return html`
          <option
            id="${item.id}"
            code="${item.code}"
            value="${item.value}"
            selected="selected"
            >${item.name}</option
          >
        `;

      case pdfOptions.ZoomMode.CUSTOM_ZOOM:
        return html`
          <option
            id="${item.id}"
            code="${item.code}"
            value="${item.value}"
            disabled="disabled"
            hidden="true"
            >${item.name}</option
          >
        `;

      default:
        return html`
          <option id="${item.id}" code="${item.code}" value="${item.value}"
            >${item.name}</option
          >
        `;
    }
  }

  __renderRightToolbar() {
    return html`
      <div
        id="${ELEMENTS.toolbarRight.id}"
        class="header-text header-font"
      ></div>
    `;
  }

  render() {
    return html`
      <div class="header-container">
        ${this.__renderLeftToolbar()} ${this.__renderMiddleToolbar()}
        ${this.__renderRightToolbar()}
      </div>
    `;
  }
}
customElements.define('neb-pdf-viewer-header', NebPdfViewerHeader);
