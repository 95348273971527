import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import * as payerPlansApi from '../../../packages/neb-api-client/src/payer-plan-api-client';
import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { createServerPatientsCollection } from '../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { number } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { FetchService } from '../../../packages/neb-utils/services/fetch';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';
import { ITEM_PAYER_SELF } from './neb-filters-ledger-charges';

const CHARGE_STATUS = {
  CLOSED: 'Closed',
  OPEN: 'Open',
};

export const ELEMENTS = {
  invoiceTextfield: { id: 'textfield-invoice' },
  patientSearch: { id: 'search-patient' },
  statusSelect: { id: 'select-status' },
  payersSelect: { id: 'select-payers' },
  dosRange: { id: 'range-date-dos' },
};

class NebEobChargesFilters extends NebFilters {
  static get properties() {
    return {
      defaultPayer: Object,

      __patientItems: Array,
      __payerPlansState: Object,
      __chargeStatus: String,
      __defaultPayerLoaded: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(3, 1fr);
        }
      `,
    ];
  }

  static createModel() {
    const today = parseDate().endOf('day');
    const thirtyDaysAgo = parseDate()
      .startOf('day')
      .subtract(1, 'month');

    const dateOfService = {
      ...NebDateRange.createModel(),
      from: new Date(thirtyDaysAgo),
      to: new Date(today),
    };

    return {
      patientId: '',
      invoice: '',
      payerIds: [],
      dateOfService,
    };
  }

  initState() {
    super.initState();
    this.defaultPayer = {};

    this.__defaultPayerLoaded = false;
    this.__chargeStatus = CHARGE_STATUS.OPEN;
    this.__patientItems = [];
    this.__payerPlansState = FetchService.createModel();

    this.__payerPlansService = new FetchService(
      {
        onChange: state => {
          state.pageItems = this.__completePayerFilterItems(state.pageItems);

          this.__payerPlansState = state;
          this.__setPayerFilterValue(state.pageItems);

          if (this.__defaultPayerLoaded) return;

          this.handlers.apply();
          this.__defaultPayerLoaded = true;
        },
        onMapItem: data => ({ label: data.alias, data }),
      },
      payerPlansApi.fetchMany,
    );

    this.onChangeChargeStatus = () => {};
  }

  __completePayerFilterItems(payers) {
    const items = [ITEM_PAYER_SELF, ...payers];

    if (
      !this.defaultPayer.id ||
      items.some(l => l.data.id === this.defaultPayer.id)
    ) {
      return items;
    }

    const defaultPayer = {
      label: this.defaultPayer.alias,
      data: this.defaultPayer,
    };

    return [...items, defaultPayer];
  }

  __setPayerFilterValue(payers) {
    if (!this.defaultPayer.id) return;

    const selectedItems = this.__defaultPayerLoaded
      ? this.state.payerIds.map(payer => payer.data.id)
      : [this.defaultPayer.id];

    const value = payers.filter(payer => selectedItems.includes(payer.data.id));

    this.handlers.change({
      name: 'payerIds',
      value,
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      searchPatients: e => this.__patientsService.search(e.value),
      requestMorePatients: () => this.__patientsService.fetchMore(),
      fetchMorePayers: () => this.__payerPlansService.fetchMore(),

      clearInvoiceSearch: () => this.formService.apply('invoice', ''),
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      changePatient: e => {
        if (e.value) {
          return this.__formService.apply(e.name, e.value);
        }

        return this.__formService.apply(e.name, selectors.ITEM_EMPTY);
      },
      changeChargeStatus: e => {
        this.__chargeStatus = e.value;
        this.onChangeChargeStatus(e);
      },
      changePatients: state => {
        this.__patientItems = state.pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
      renderPatientItem: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      apply: () => {
        const model = this.formService.build();

        const { from, to } = model.dateOfService;

        this.onApply({
          ...model,
          dateOfService: {
            from: from ? parseDate(from._i) : null,
            to: to ? parseDate(to._i) : null,
          },
        });
      },
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select([]),
        status: selectors.select([]),
        dateOfService: NebDateRange.createSelectors(),
        payerIds: selectors.multiSelect([]),
      },
    };
  }

  async load() {
    this.__payerPlansService.fetch();

    this.__patientsService = createServerPatientsCollection({
      onChange: this.handlers.changePatients,
    });

    await this.__patientsService.fetchComplete();
  }

  renderPatientFilter() {
    return html`
      <neb-select-search
        id="${ELEMENTS.patientSearch.id}"
        name="patientId"
        label="Patient"
        helper=" "
        itemHeight="80"
        .items="${this.__patientItems}"
        .value="${this.state.patientId}"
        .onChange="${this.handlers.changePatient}"
        .onSearch="${this.handlers.searchPatients}"
        .onRequest="${this.handlers.requestMorePatients}"
        .onRenderItem="${this.handlers.renderPatientItem}"
        showSearch
        forceDown
      ></neb-select-search>
    `;
  }

  renderPayerFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.payersSelect.id}"
        name="payerIds"
        label="Payers"
        helper=" "
        .value="${this.state.payerIds}"
        .items="${this.__payerPlansState.pageItems}"
        .onChange="${this.handlers.change}"
        .onRequest="${this.handlers.fetchMorePayers}"
        multiSelect
      ></neb-select>
    `;
  }

  renderDateOfServiceFilter() {
    const dos = {
      from: this.state.dateOfService.from
        ? parseDate(this.state.dateOfService.from)
        : null,
      to: this.state.dateOfService.to
        ? parseDate(this.state.dateOfService.to)
        : null,
    };
    return html`
      <neb-range-date
        id="${ELEMENTS.dosRange.id}"
        name="dateOfService"
        label="Date"
        .model="${dos}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>
    `;
  }

  renderInvoiceFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.invoiceTextfield.id}"
        name="invoice"
        label="Invoice #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.invoice ? 'neb:clear' : ''}"
        .value="${this.state.invoice}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearInvoiceSearch}"
        ?disabled="${this.state.notInvoiced}"
      ></neb-textfield>
    `;
  }

  renderStatusFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.statusSelect.id}"
        name="status"
        label="Status"
        helper=" "
        .value="${this.__chargeStatus}"
        .items="${Object.values(CHARGE_STATUS)}"
        .onChange="${this.handlers.changeChargeStatus}"
      ></neb-select>
    `;
  }

  renderContent() {
    return html`
      ${this.renderPatientFilter()} ${this.renderPayerFilter()}
      ${this.renderDateOfServiceFilter()} ${this.renderInvoiceFilter()}
      ${this.renderStatusFilter()}
    `;
  }
}

window.customElements.define('neb-filters-eob-charges', NebEobChargesFilters);
