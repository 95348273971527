import { css, html } from 'lit';

import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../controllers/conversions/neb-controller-conversion';
import '../../controllers/conversions/neb-controller-new-conversion';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_EXTRA_LARGE,
} from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  conversionController: { id: 'conversionController' },
  header: { id: 'header' },
  newConversionForm: { id: 'newConversion' },
};

class NebOverlayConversion extends Overlay {
  constructor() {
    super();

    this.initHandlers();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      cancel: () => this.__cancel(),
      delete: model => this.__delete(model),
      save: model => this.__save(model),
    };
  }

  __cancel() {
    this.dismiss();
  }

  __save(model) {
    this.dismiss(model);
  }

  __delete(model) {
    this.dismiss({ ...model, active: false });
  }

  getTitle() {
    return this.model.item ? 'Conversion' : 'Add Conversion';
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: ${OVERLAY_WIDTH_EXTRA_LARGE};
          flex-flow: column nowrap;
          overflow: auto;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .newForm {
          flex: 1 0 0;
        }
      `,
    ];
  }

  __isNew() {
    return !this.model.item;
  }

  __renderNewConversion() {
    return html`
      <neb-controller-new-conversion
        id="${ELEMENTS.newConversionForm.id}"
        class="newForm"
        .onCancel="${this.handlers.cancel}"
        .onSave="${this.handlers.save}"
      ></neb-controller-new-conversion>
    `;
  }

  __renderExistingConversion() {
    return html`
      <neb-controller-conversion
        id="${ELEMENTS.conversionController.id}"
        .model="${this.model.item}"
        .onDelete="${this.handlers.delete}"
      ></neb-controller-conversion>
    `;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>
      ${
        this.__isNew()
          ? this.__renderNewConversion()
          : this.__renderExistingConversion()
      }
    `;
  }
}

customElements.define('neb-overlay-conversion', NebOverlayConversion);
