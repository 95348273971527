import '../../neb-lit-components/src/components/inputs/neb-textfield';
import '../../../src/components/controls/inputs/neb-checkbox';
import '../../neb-lit-components/src/components/neb-button';

import { html, css } from 'lit';

import { RENDERER_SVGS } from '../../neb-styles/icons';
import {
  CSS_SPACING,
  CSS_COLOR_ERROR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_COLOR_HIGHLIGHT,
} from '../../neb-styles/neb-variables';
import { SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH } from '../../neb-utils/payment-popup-constants';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  title: {
    id: 'title',
  },
  subheader: {
    id: 'subheader',
  },
  message: {
    id: 'message',
  },
  cancelActionButton: {
    id: 'cancel-action-button',
  },
  confirmButton: {
    id: 'confirm-button',
  },
  icon: {
    id: 'icon',
  },
  hyperLink: {
    id: 'hyper-link',
  },
  payfieldsForm: {
    id: 'payfields-controller',
  },
  textHeader: {
    id: 'text-header',
  },
  addCardCheckbox: {
    id: 'add-card-checkbox',
  },
  cardDescription: {
    id: 'card-description',
  },
  iconSuccess: {
    id: 'icon-success',
  },
  iconWarning: {
    id: 'icon-warning',
  },
};

class NebPopupMerchantResponse extends NebPopup {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      dirty: {
        type: Boolean,
      },
    };
  }

  initState() {
    super.initState();
    this.showHeader = true;
    this.model = {
      title: '',
      subheader: '',
      message: '',
      success: '',
      cancelLabel: '',
      confirmLabel: '',
      confirmLabelSave: '',
      allowSaveCardOnFile: false,
      saveCardOnFile: false,
      cardOnFileName: '',
      merchantAccounts: [],
      action: '',
      hyperLink: '',
    };
  }

  constructor() {
    super();
    this.initState();

    this.__initHandlers();
  }

  __initHandlers() {
    this.handlers = {
      cancel: () => {
        this.model.action = 'close';
        this.onClose(this.model);
      },
      onCancel: () => {
        this.model.action = 'cancel';
        this.onClose(this.model);
      },
      onConfirm: () => {
        this.dirty = true;

        this.model.action = 'confirm';
        this.onClose(this.model);
      },
      onLinkClick: () => {
        this.model.action = 'link';
        this.onClose(this.model);
      },
      checkboxChanged: () => {
        this.model = {
          ...this.model,
          saveCardOnFile: !this.model.saveCardOnFile,
        };
      },
      change: e => {
        this.model = { ...this.model, cardOnFileName: e.value };
      },
    };
  }

  firstUpdated() {
    if (this.model.confirmLabel === '' && this.model.cancelLabel === '') {
      setTimeout(() => {
        this.onClose();
      }, 1000);
    }
  }

  modelChanged() {
    this.title = this.model.title;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 525px;
        }

        :host([layout='small']) {
          margin: 40px 0;
          width: ${SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH}px;
        }

        .label {
          padding-bottom: ${CSS_SPACING};
        }

        .icon-container {
          width: 100%;
          padding-top: 50px;
          padding-bottom: 80px;
        }

        .icon-success {
          display: block;
          width: 128px;
          height: 128px;
          fill: ${CSS_BANNER_SUCCESS_COLOR};
          margin-left: auto;
          margin-right: auto;
        }

        .icon-warning {
          display: block;
          width: 128px;
          height: 128px;
          fill: ${CSS_COLOR_ERROR};
          margin-left: auto;
          margin-right: auto;
        }

        .card-on-file {
          display: grid;
          grid-template-columns: 2fr 3fr 1fr;
        }

        .field {
          display: grid;
        }

        .button {
          padding-right: ${CSS_SPACING};
          min-width: 150px;
        }

        .button:active {
          opacity: 0.65;
        }

        .visible {
          opacity: 1;
        }

        .invisible {
          opacity: 0;
        }

        .container-button {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
        }

        .link-text {
          color: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
          text-decoration: underline;
          padding-top: 8px;
          margin-left: auto;
        }
      `,
    ];
  }

  __renderConfirm() {
    if (this.model.confirmLabel !== '') {
      let label = this.model.confirmLabel;

      if (this.model.saveCardOnFile && this.model.allowSaveCardOnFile) {
        label = this.model.confirmLabelSave;
      }

      return html`
        <neb-button
          id="${ELEMENTS.confirmButton.id}"
          class="button"
          .label="${label}"
          .onClick="${this.handlers.onConfirm}"
        ></neb-button>
      `;
    }

    return '';
  }

  __renderCancel() {
    if (this.model.cancelLabel !== '') {
      return html`
        <neb-button
          id="${ELEMENTS.cancelActionButton.id}"
          role="outline"
          class="button"
          .label="${this.model.cancelLabel}"
          .onClick="${this.handlers.onCancel}"
        ></neb-button>
      `;
    }

    return '';
  }

  __renderLargeIcon() {
    if (this.model.success) {
      return html`
        <svg
          id="${ELEMENTS.iconSuccess.id}"
          class="icon-success"
          viewBox="${RENDERER_SVGS.checked.viewBox}"
        >
          ${RENDERER_SVGS.checked.src}
        </svg>
      `;
    }

    return html`
      <svg
        id="${ELEMENTS.iconWarning.id}"
        class="icon-warning"
        viewBox="${RENDERER_SVGS.warning.viewBox}"
      >
        ${RENDERER_SVGS.warning.src}
      </svg>
    `;
  }

  __renderSaveCardOnFile() {
    if (this.model.allowSaveCardOnFile) {
      return html`
        <div class="card-on-file">
          <neb-checkbox
            id="${ELEMENTS.addCardCheckbox.id}"
            label="Add Card on File"
            class="field"
            ?checked="${this.model.saveCardOnFile}"
            .onChange="${this.handlers.checkboxChanged}"
          ></neb-checkbox>

          <neb-textfield
            id="${ELEMENTS.cardDescription.id}"
            class="field ${this.model.saveCardOnFile ? 'visible' : 'invisible'}"
            name="cardDescription"
            label="Card Description"
            helper=" "
            maxLength="50"
            ?disabled="${!this.model.saveCardOnFile}"
            .value="${this.model.cardOnFileName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>
      `;
    }

    return html`
      <div class="card-on-file"></div>
    `;
  }

  __renderSubHeader() {
    if (this.model.subheader !== '') {
      return html`
        <p class="label" id="${ELEMENTS.subheader.id}">
          ${this.model.subheader}
        </p>
      `;
    }

    return '';
  }

  __renderHyperLink() {
    if (this.model.hyperLink !== '') {
      return html`
        <div
          id="${ELEMENTS.hyperLink.id}"
          class="link-text"
          @click="${this.handlers.onLinkClick}"
        >
          ${this.model.hyperLink}
        </div>
      `;
    }
    return '';
  }

  __renderMessage() {
    if (this.model.message !== '') {
      return html`
        <p id="${ELEMENTS.message.id}">${this.model.message}</p>
      `;
    }

    return '';
  }

  renderContent() {
    return html`
      ${this.__renderSubHeader()} ${this.__renderMessage()}
      <div class="icon-container">${this.__renderLargeIcon()}</div>
      ${this.__renderSaveCardOnFile()}
      <div class="container-button">
        ${this.__renderConfirm()} ${this.__renderCancel()}
        ${this.__renderHyperLink()}
      </div>
    `;
  }
}

customElements.define('neb-popup-merchant-response', NebPopupMerchantResponse);
