/* eslint-disable no-case-declarations */
/* eslint-disable complexity */
import { openPopup } from '@neb/popup';

import {
  deleteSeries,
  quickCheckInAppointment,
  quickCheckOutAppointment,
  roomConflictCheck,
  updateAppointment,
  deleteAppointment,
  getBlockedOffTimeById,
  getAppointmentById,
} from '../../../packages/neb-api-client/src/appointment-api-client';
import {
  deleteBlockedOffTime,
  updateBlockedOffTime,
} from '../../../packages/neb-api-client/src/blocked-off-time-api-client';
import { getEncounterCharges } from '../../../packages/neb-api-client/src/charting/encounter-charge';
import { fetchMany as fetchReasons } from '../../../packages/neb-api-client/src/reasons-api-client';
import { fetchManyRooms } from '../../../packages/neb-api-client/src/rooms-api-client';
import { recurringBlockedOffTimeActions } from '../../../packages/neb-calendar/neb-appointments-state';
import {
  openError,
  openSuccess,
  openWarning,
} from '../../../packages/neb-dialog/neb-banner-state';
import { computeTime } from '../../../packages/neb-input/nebFormatUtils';
import {
  APPOINTMENT_ACTIONS,
  APPOINTMENT_ACTIONS_ENCOUNTER_CHECK,
} from '../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-options';
import { openAppointmentPage } from '../../../packages/neb-lit-components/src/utils/appointment-overlays-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { MAX_OCCUPANCY_POPUP_MESSAGE } from '../../../packages/neb-utils/calendar-resources-util';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../packages/neb-utils/feature-util';
import {
  markPatientAsArrived,
  confirmAppointment,
  unconfirmAppointment,
  updateAppointmentRoom,
} from '../../api-clients/appointments';
import { getLocations } from '../../api-clients/locations';
import NebFormAppointment from '../../components/forms/appointments/neb-form-appointment';
import { EDIT_MODE } from '../../components/overlays/appointments/neb-overlay-edit-reschedule-appointment';
import { bannerMessage } from '../../components/overlays/blocked-off-time/neb-overlay-blocked-off-time-page';
import { openOverlayCheckInOut } from '../../features/check-in-out/utils/open-overlay-check-in-out';
import { SELECT_BY_TYPE, mapSplitsToModel } from '../scheduling/appointments';

export const BANNER_QUICK_CHECK_IN_MAX_OCC =
  'Check in successful. Max Occupancy exceeded';

export const CURRENT_APPOINTMENT = 'Current Appointment';

export function validQuickActionResponse(res) {
  return res && (res.data || res.status === 200 || res.success);
}

export function isUnmatchedPatient(model) {
  return (
    !model.patientId && (!model.patient || (model.patient && !model.patient.id))
  );
}

export function getAppointmentId(event) {
  return event.appointmentId ? event.appointmentId : event.id;
}

async function hasPostedCharges(encounterId) {
  const charges = await getEncounterCharges(encounterId, true);
  return charges.some(c => c.postedToLedgerId);
}

function computeDurationDisplay(startDate, endDate) {
  const start = startDate.format('h:mm A');
  const end = endDate.format('h:mm A');
  const duration = computeTime(endDate.diff(startDate));
  const durationDisplay = `${start} - ${end} (${duration})`;

  return durationDisplay;
}

function getSplitRescheduleStart(model, details) {
  const split = details.appointmentSplits.find(
    s => s.splitIndex === model.splitRescheduleIndex,
  );

  const splitStart = parseDate(split.start);
  const appointmentStart = parseDate(model.start);

  const splitStartDiff = appointmentStart.diff(splitStart);

  return parseDate(details.start)
    .add(splitStartDiff)
    .toISOString();
}

const ACTION = {
  QUICK_ACTION: 'quick-action',
  PROVIDER_CALENDAR_DRAG_DROP: 'provider-calendar-drag-drop',
  ROOM_CALENDAR_DRAG_DROP: 'room-calendar-drag-drop',
};

function getAction(model) {
  const { quickActionFetch, providerCalendarFetch, roomCalendarFetch } = model;

  if (providerCalendarFetch) return ACTION.PROVIDER_CALENDAR_DRAG_DROP;
  if (roomCalendarFetch) return ACTION.ROOM_CALENDAR_DRAG_DROP;

  return quickActionFetch && ACTION.QUICK_ACTION;
}

// when removing hasEditRescheduleFF, look into seeing if we can remove quickActionFetch, providerCalendarFetch, roomCalendarFetch
export async function fetchAndMapQuickActionAppointmentModel(model) {
  const { appointment: details } = await getAppointmentById(model.id, {
    include: ['appointmentSplits'],
  });

  let start;
  let end;
  let dragAndDropStart;
  let schedulingDetails = {};

  const action = getAction(model);

  switch (action) {
    case 'quick-action':
      start = details.start ? parseDate(details.start) : parseDate(model.start);

      end = parseDate(details.end);

      schedulingDetails = {
        start: details.start,
        duration: end.diff(start),
        type: SELECT_BY_TYPE.DATE,
        quickActionFetch: true,
        providerCalendarFetch: false,
        roomCalendarFetch: false,
      };

      break;
    case 'provider-calendar-drag-drop':
      start = parseDate(details.start);
      end = parseDate(details.end);

      schedulingDetails = {
        start: details.start,
        duration: end.diff(start),
        type: model.type || SELECT_BY_TYPE.CUSTOM,
        quickActionFetch: false,
        providerCalendarFetch: true,
        roomCalendarFetch: false,
        hasEncounter: model.hasEncounter,

        dragAndDropStart: model.start || '',
        dragAndDropProviderId: model.providerId || '',
        dragAndDropLocationId: model.locationId || '',
      };

      break;
    case 'room-calendar-drag-drop':
      dragAndDropStart = model.splitRescheduleIndex
        ? getSplitRescheduleStart(model, details)
        : model.start || '';

      start = details.start ? parseDate(details.start) : parseDate(model.start);

      end = parseDate(details.end);

      schedulingDetails = {
        start: details.start,
        duration: end.diff(start),
        type: model.type || SELECT_BY_TYPE.CUSTOM,
        quickActionFetch: false,
        providerCalendarFetch: false,
        roomCalendarFetch: true,
        hasEncounter: model.hasEncounter,

        dragAndDropStart,
        dragAndDropResourceId: model.resourceId || '',
      };

      break;
    default:
  }

  const result = {
    id: details.id,
    patientId: details.patientId,
    providerId: details.providerId || '',
    locationId: details.locationId,
    resourceId: details.resourceId || '',
    roomId: details.roomId || '',
    caseId: details.caseId || '',
    status: details.status,
    patientAuthorizationId: details.patientAuthorizationId || '',
    walkIn: details.walkIn,
    rrule: details.rrule || '',
    note: details.note || '',
    cancelRescheduleReasonId: details.cancelRescheduleReasonId || '',
    rescheduleReason: details.rescheduleReason || '',
    appointmentTypeId: details.appointmentTypeId,
    splits: details.appointmentSplits
      ? mapSplitsToModel(details.appointmentSplits)
      : [],
    splitRescheduleIndex: model.splitRescheduleIndex || 0,
    ...schedulingDetails,
  };

  return result;
}

export async function quickCheckIn(event, patientPackageId = '') {
  const { isAtLimit } = event.resourceId
    ? await roomConflictCheck(event.resourceId)
    : {};

  let res;

  try {
    if (patientPackageId) {
      res = await quickCheckInAppointment(event.id, patientPackageId);
    } else {
      res = await quickCheckInAppointment(event.id);
    }

    if (res.forceStandardCheckIn) {
      await store.dispatch(
        openError(
          'Case associated to an inactive plan. Select an active plan to continue.',
        ),
      );

      return { secondaryAction: 'force-standard', mode: 'checkIn', ...res };
    }

    if (res.needsProvider || res.patientPackages) {
      return { secondaryAction: 'quick-check-in-update', ...res };
    }

    if (isAtLimit) {
      await store.dispatch(openWarning(BANNER_QUICK_CHECK_IN_MAX_OCC));
    } else {
      await store.dispatch(
        openSuccess(APPOINTMENT_ACTIONS.CHECK_IN.successMessage),
      );
    }
    return res;
  } catch (err) {
    return store.dispatch(openError(APPOINTMENT_ACTIONS.CHECK_IN.errorMessage));
  }
}

export async function quickCheckInUpdate(event, patientPackages = []) {
  const response = await openPopup(POPUP_RENDER_KEYS.QUICK_CHECK_IN, {
    appointment: !event.providerId ? event : null,
    patientPackages,
  });

  if (response && response.patientPackageId) {
    return quickCheckIn(event, response.patientPackageId);
  }

  if (response && response.data) {
    return quickCheckIn(event);
  }

  return false;
}

export async function quickCheckOut(apptId) {
  try {
    const res = await quickCheckOutAppointment(apptId);

    if (res.forceStandardCheckOut) {
      await store.dispatch(
        openWarning('Active plan required to check out appointment.'),
      );

      return {
        secondaryAction: 'force-standard',
        mode: 'checkOut',
        ...res,
      };
    }

    await store.dispatch(
      openSuccess(APPOINTMENT_ACTIONS.CHECK_OUT.successMessage),
    );

    return res;
  } catch (err) {
    return store.dispatch(
      openError(APPOINTMENT_ACTIONS.CHECK_OUT.errorMessage),
    );
  }
}

async function quickConfirm(apptId) {
  try {
    const res = await confirmAppointment(apptId);

    await store.dispatch(
      openSuccess(APPOINTMENT_ACTIONS.CONFIRM.successMessage),
    );

    return res;
  } catch (err) {
    return store.dispatch(openError(APPOINTMENT_ACTIONS.CONFIRM.errorMessage));
  }
}

async function quickUnconfirm(apptId) {
  try {
    const res = await unconfirmAppointment(apptId);

    await store.dispatch(
      openSuccess(APPOINTMENT_ACTIONS.UNCONFIRM.successMessage),
    );

    return res;
  } catch (err) {
    return store.dispatch(
      openError(APPOINTMENT_ACTIONS.UNCONFIRM.errorMessage),
    );
  }
}

async function quickArrive(apptId) {
  try {
    const res = await markPatientAsArrived(apptId);

    await store.dispatch(
      openSuccess(APPOINTMENT_ACTIONS.ARRIVED.successMessage),
    );

    return res;
  } catch (err) {
    return store.dispatch(openError(APPOINTMENT_ACTIONS.ARRIVED.errorMessage));
  }
}

export async function quickReturnToScheduled(apptId) {
  try {
    const res = await updateAppointment(
      apptId,
      APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED.action,
    );

    await store.dispatch(
      openSuccess(APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED.successMessage),
    );

    return res;
  } catch (err) {
    return store.dispatch(
      openError(APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED.errorMessage),
    );
  }
}

async function quickEdit(event) {
  if (
    event.encounter &&
    (event.encounter.signed || (await hasPostedCharges(event.encounter.id)))
  ) {
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.EDIT.title,
      message: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.EDIT.message,
      confirmText: 'OK',
    });

    return false;
  }

  const hasEditRescheduleFF = await hasFeatureOrBeta(
    FEATURE_FLAGS.DH_EDIT_RESCHEDULE,
  );

  let res;

  try {
    if (hasEditRescheduleFF) {
      const model = await fetchAndMapQuickActionAppointmentModel({
        ...NebFormAppointment.createModel(),
        id: event.id,
        quickActionFetch: true,
      });

      res = await openOverlay(OVERLAY_KEYS.APPOINTMENT_EDIT_RESCHEDULE_FORM, {
        ...model,
        hasEncounter: !!event.encounter,
        mode: EDIT_MODE.EDIT,
      });
    } else {
      res = await openOverlay(OVERLAY_KEYS.APPOINTMENT_EDIT_FORM, {
        appointmentId: event.id,
      });
    }

    if (res) {
      await store.dispatch(
        openSuccess(APPOINTMENT_ACTIONS.EDIT.successMessage),
      );

      return res;
    }
  } catch (err) {
    console.error(err);
    return store.dispatch(openError(APPOINTMENT_ACTIONS.EDIT.errorMessage));
  }

  return false;
}

async function quickReschedule(event) {
  if (event.encounter) {
    const charges = await hasPostedCharges(event.encounter.id);

    if (event.encounter.signed && charges) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE.title,
        message: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE.message,
        confirmText: 'OK',
      });

      return false;
    }

    if (event.encounter.signed) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title:
          APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE_SIGNED_ENCOUNTER.title,
        message:
          APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE_SIGNED_ENCOUNTER
            .message,
        confirmText: 'OK',
      });

      return false;
    }

    if (charges) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title:
          APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE_POSTED_CHARGES.title,
        message:
          APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE_POSTED_CHARGES.message,
        confirmText: 'OK',
      });

      return false;
    }
  }

  const hasEditRescheduleFF = await hasFeatureOrBeta(
    FEATURE_FLAGS.DH_EDIT_RESCHEDULE,
  );

  let res;

  try {
    const model = await fetchAndMapQuickActionAppointmentModel({
      ...NebFormAppointment.createModel(),
      id: event.id,
      quickActionFetch: true,
    });

    if (hasEditRescheduleFF) {
      res = await openOverlay(OVERLAY_KEYS.APPOINTMENT_EDIT_RESCHEDULE_FORM, {
        ...model,
        hasEncounter: !!event.encounter,
        mode: EDIT_MODE.RESCHEDULE,
      });
    } else {
      res = await openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, {
        ...model,
        hasEncounter: !!event.encounter,
      });
    }

    if (res) {
      await store.dispatch(
        openSuccess(APPOINTMENT_ACTIONS.RESCHEDULE.successMessage),
      );

      return res;
    }
  } catch (err) {
    console.error(err);
    return store.dispatch(
      openError(APPOINTMENT_ACTIONS.RESCHEDULE.errorMessage),
    );
  }

  return false;
}

async function quickCancel(event) {
  if (event.encounter && !event.encounter.archived) {
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.CANCEL.title,
      message: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.CANCEL.message,
      confirmText: 'OK',
    });

    return false;
  }

  try {
    const allReasons = await fetchReasons();
    const reasons = allReasons.filter(item => item.active);
    const result = await openPopup(POPUP_RENDER_KEYS.APPOINTMENT_CANCEL, {
      reasons,
    });

    if (result && (result.reason || result.note)) {
      const res = await updateAppointment(
        event.id,
        APPOINTMENT_ACTIONS.CANCEL.action,
        { cancelReason: result.note, cancelRescheduleReasonId: result.reason },
      );

      await store.dispatch(
        openSuccess(APPOINTMENT_ACTIONS.CANCEL.successMessage),
      );

      return res;
    }
  } catch (err) {
    console.error(err);
    return store.dispatch(openError(APPOINTMENT_ACTIONS.CANCEL.errorMessage));
  }

  return false;
}

async function quickNoShow(event) {
  if (event.encounter && !event.encounter.archived) {
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.NO_SHOW.title,
      message: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.NO_SHOW.message,
      confirmText: 'OK',
    });

    return false;
  }

  try {
    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'No Show Appointment',
      message: 'Are you sure you want to mark this appointment as no show?',
      confirmText: 'Yes',
      cancelText: 'No',
    });

    if (result) {
      const res = await updateAppointment(
        event.id,
        APPOINTMENT_ACTIONS.NO_SHOW.action,
      );

      await store.dispatch(
        openSuccess(APPOINTMENT_ACTIONS.NO_SHOW.successMessage),
      );

      return res;
    }
  } catch (err) {
    console.error(err);
    return store.dispatch(openError(APPOINTMENT_ACTIONS.NO_SHOW.errorMessage));
  }

  return false;
}

async function quickDelete(event) {
  if (event.encounter && !event.encounter.archived) {
    await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.DELETE.title,
      message: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.DELETE.message,
      confirmText: 'OK',
    });

    return false;
  }

  const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
    title: 'Delete Appointment',
    message: 'Are you sure you want to delete this appointment?',
    confirmText: 'Yes',
    cancelText: 'No',
  });

  if (result) {
    try {
      const res = await deleteAppointment(event.id, event.patientId);

      await store.dispatch(
        openSuccess(APPOINTMENT_ACTIONS.DELETE.successMessage),
      );

      return res;
    } catch (err) {
      console.error(err);
      return store.dispatch(openError(APPOINTMENT_ACTIONS.DELETE.errorMessage));
    }
  }

  return false;
}

async function quickChangeRoom(event) {
  const allRooms = await fetchManyRooms();
  const rooms = allRooms.filter(
    room => room.locationId === event.locationId && room.active,
  );

  const result = await openPopup(POPUP_RENDER_KEYS.APPOINTMENT_CHANGE_ROOM, {
    rooms,
    roomId: event.roomId,
    appointmentId: event.id,
  });

  if (result && result.room.id !== event.roomId) {
    const roomId = result.room.id;

    const { isAtLimit } = roomId ? await roomConflictCheck(roomId) : false;

    let confirm = true;

    if (isAtLimit) {
      confirm = await openPopup(
        POPUP_RENDER_KEYS.CONFIRM,
        MAX_OCCUPANCY_POPUP_MESSAGE,
      );
    }

    if (!confirm) {
      return false;
    }

    try {
      const res = await updateAppointmentRoom({
        id: event.id,
        body: { roomId },
      });

      store.dispatch(
        openSuccess(APPOINTMENT_ACTIONS.CHANGE_ROOM.successMessage),
      );

      return res;
    } catch (_) {
      store.dispatch(openError(APPOINTMENT_ACTIONS.CHANGE_ROOM.errorMessage));
    }
  }

  return false;
}

async function quickDeleteEntireSeries(event) {
  try {
    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Delete Entire Series',
      message:
        'Are you sure you want to delete this and all following appointments created within this series?',
      confirmText: 'Yes',
      cancelText: 'No',
    });

    if (result) {
      const res = await deleteSeries(event.id, event.patientId);

      await store.dispatch(
        openSuccess(APPOINTMENT_ACTIONS.DELETE_SERIES.successMessage),
      );

      return res;
    }
  } catch (err) {
    console.error(err);
    return store.dispatch(
      openError(APPOINTMENT_ACTIONS.DELETE_SERIES.errorMessage),
    );
  }

  return false;
}

async function quickEditBOT(event) {
  const blockedOffTime = await getBlockedOffTimeById(event.id);

  const res = await openOverlay(OVERLAY_KEYS.BLOCKED_OFF_TIME_FORM, {
    appointment: blockedOffTime,
    providerId: blockedOffTime.providerId,
    ...(!blockedOffTime.recurrenceEventId
      ? { providerIds: blockedOffTime.groupProviderIds }
      : {}),
  });

  if (res) {
    return { success: true };
  }

  return false;
}

async function quickDeleteBOT(event) {
  const blockedOffTime = await getBlockedOffTimeById(event.id);

  const deleteConfirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
    title: 'Delete Blocked Off Time',
    message: 'Are you sure you want to delete this blocked off time?',
    confirmText: 'DELETE',
    cancelText: 'CANCEL',
  });

  if (deleteConfirmed) {
    try {
      if (blockedOffTime.recurrenceEventId) {
        await updateBlockedOffTime(blockedOffTime.groupId, blockedOffTime, {
          action:
            recurringBlockedOffTimeActions.deleteSingleInstanceForSingleProvider,
        });
      } else {
        await deleteBlockedOffTime(blockedOffTime.groupId);
      }

      await store.dispatch(openSuccess(bannerMessage.success(true)));

      return { success: true };
    } catch (err) {
      console.error(err);
      return store.dispatch(openError(bannerMessage.error(true)));
    }
  }

  return false;
}

async function quickDeleteEntireSeriesBOT(event) {
  const blockedOffTime = await getBlockedOffTimeById(event.id);

  const deleteConfirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
    title: 'Delete Blocked Off Time',
    message:
      'Are you sure you want to delete this and all blocked off times created within this series?',
    confirmText: 'DELETE',
    cancelText: 'CANCEL',
  });

  if (deleteConfirmed) {
    try {
      updateBlockedOffTime(blockedOffTime.groupId, blockedOffTime, {
        action: recurringBlockedOffTimeActions.deleteSeriesForSingleProvider,
      });

      store.dispatch(openSuccess(bannerMessage.success(false)));

      return { success: true };
    } catch (err) {
      console.log(err);
      store.dispatch(openError(bannerMessage.error(false)));
    }
  }
  return false;
}

export function getQuickActionHandlers(event, actions) {
  const allActions = {
    quickCheckIn: {
      label: 'Quick Check-In',
      onSelect: () => quickCheckIn(event),
    },
    quickCheckOut: {
      label: 'Quick Check-Out',
      onSelect: () => quickCheckOut(event.id),
    },
    edit: {
      label: 'Edit',
      onSelect: () => ({
        secondaryAction: 'quick-edit',
      }),
    },
    reschedule: {
      label: 'Reschedule',
      onSelect: () => ({
        secondaryAction: 'quick-reschedule',
      }),
    },
    confirm: {
      label: 'Confirm',
      onSelect: () => quickConfirm(event.id),
    },
    returnToUnconfirmed: {
      label: 'Return to Unconfirmed',
      onSelect: () => quickUnconfirm(event.id),
    },
    arrived: {
      label: 'Arrived',
      onSelect: () => quickArrive(event.id),
    },
    returnToScheduled: {
      label: 'Return to Scheduled',
      onSelect: () => quickReturnToScheduled(event.id),
    },
    changeRoom: {
      label: 'Change Room',
      onSelect: () => ({
        secondaryAction: 'quick-change-room',
      }),
    },
    cancel: {
      label: 'Cancel',
      onSelect: () => ({
        secondaryAction: 'quick-cancel',
      }),
    },
    delete: {
      label: 'Delete',
      onSelect: () => ({
        secondaryAction: 'quick-delete',
      }),
    },
    deleteEntireSeries: {
      label: 'Delete Entire Series',
      onSelect: () => ({
        secondaryAction: 'quick-delete-entire-series',
      }),
    },
    noShow: {
      label: 'No Show',
      onSelect: () => ({
        secondaryAction: 'quick-no-show',
      }),
    },
    quickEditBOT: {
      label: 'Edit',
      onSelect: () => ({ secondaryAction: 'quick-edit-BOT' }),
    },
    quickDeleteBOT: {
      label: 'Delete',
      onSelect: () => ({ secondaryAction: 'quick-delete-BOT' }),
    },
    quickDeleteEntireSeriesBOT: {
      label: 'Delete Entire Series',
      onSelect: () => ({ secondaryAction: 'quick-delete-entire-series-BOT' }),
    },
  };

  const items = actions.data.map(v => allActions[v]);

  return items;
}

export async function handleSecondaryAction(event, res) {
  let result;

  switch (res.secondaryAction) {
    case 'quick-check-in-update':
      result = await quickCheckInUpdate(event, res.patientPackages);

      break;

    case 'quick-edit':
      result = await quickEdit(event);

      break;

    case 'quick-reschedule':
      result = await quickReschedule(event);

      break;

    case 'quick-cancel':
      result = await quickCancel(event);

      break;

    case 'quick-delete':
      result = await quickDelete(event);

      break;

    case 'quick-no-show':
      result = await quickNoShow(event);

      break;

    case 'quick-change-room':
      result = await quickChangeRoom(event);

      break;

    case 'quick-delete-entire-series':
      result = await quickDeleteEntireSeries(event);

      break;

    case 'quick-edit-BOT':
      result = await quickEditBOT(event);

      break;

    case 'quick-delete-BOT':
      result = await quickDeleteBOT(event);

      break;

    case 'quick-delete-entire-series-BOT':
      result = await quickDeleteEntireSeriesBOT(event);

      break;

    case 'force-standard':
      const [{ appointment, provider }, locations] = await Promise.all([
        getAppointmentById(event.id, {
          include: ['appointmentType'],
        }),
        getLocations(),
      ]);

      const { appointmentType } = appointment;

      const start = parseDate(appointment.start);
      const end = parseDate(appointment.end);
      const location =
        locations.find(item => item.id === appointment.locationId) || {};
      const appointmentTypeName = appointmentType.name;
      const providerDisplayName = provider
        ? `${provider.familyName}, ${provider.givenName}`
        : 'No Provider Scheduled';

      result = await openOverlayCheckInOut(OVERLAY_KEYS.CHECK_IN_OUT, {
        appointment: {
          ...appointment,
          start,
          end,
          details: {
            startDisplayDate: start.format('dddd, MMMM DD, YYYY'),
            durationDisplay: computeDurationDisplay(start, end),
            location: location.name,
            appointmentTypeName,
            providerDisplayName,
          },
        },
        patientId: appointment.patientId,
        mode: res.mode,
      });

      break;

    default:
  }

  return result;
}

export function getRescheduleQuickActionHandlers(appointmentId) {
  return {
    label: CURRENT_APPOINTMENT,
    onSelect: () => openAppointmentPage(appointmentId),
  };
}
