import '../../src/components/misc/neb-icon';
import './neb-nav-primary-item-lit';
import './src/components/neb-nav-primary-header';
import '../neb-lit-components/src/components/controls/neb-expansion-panel';

import { LitElement, html, css } from 'lit';

import { hasAddOn, ADD_ONS } from '../../src/utils/add-ons';
import { DOCTIBLE_LINKS, openDoctibleSsoLink } from '../../src/utils/sso';
import { store } from '../neb-redux/neb-redux-store';
import { BlacklistService } from '../neb-redux/services/blacklist';
import { navigate } from '../neb-route/neb-route-state';
import {
  CSS_PRIMARY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_BORDER_HIGHLIGHT,
  CSS_SPACING,
} from '../neb-styles/neb-variables';

const WIDTH_OPEN = css`200px`;
const WIDTH_CLOSED = css`80px`;
const SLIDE_DIRECTION = {
  UP: css`up`,
  DOWN: css`down`,
};

const NAV_ITEM_HEIGHT_VALUE = 60;
const NAV_ITEM_HEIGHT_CSS = css`60px`;
const NAV_ITEM_MARGIN_VALUE = 15;
const NAV_ITEM_MARGIN_CSS = css`15px`;
export const ELEMENTS = {
  toggleExpandButton: {
    id: 'button-toggle-expand',
  },
  header: {
    id: 'nav-header',
  },
  selectedBar: {
    id: 'selected-bar',
  },
  navItemsContainer: {
    id: 'nav-items-container',
  },
  navItems: {
    selector: 'neb-nav-primary-item-lit',
  },
  newSettings: {
    id: 'new-settings',
  },
  newSettingsLabel: {
    id: 'new-settings-label',
  },
  expansionPanels: {
    selector: 'neb-expansion-panel',
  },
  ctEngageCollapsedIcon: {
    id: 'ct-engage-collapsed-icon',
  },
  ctInformsMenuItem: {
    id: 'ct-informs-menu-item',
  },
  ctInformsCollapsedIcon: {
    id: 'ct-informs-collapsed-icon',
  },
  ctRemindMenuItem: {
    id: 'ct-remind-menu-item',
  },
  ctRemindCollapsedIcon: {
    id: 'ct-remind-collapsed-icon',
  },
};

class NebNavPrimaryLit extends LitElement {
  static get properties() {
    return {
      __shouldAnimateBar: Boolean,
      __storedSlideDirection: String,
      __slideDirection: String,
      __expandedIndex: Number,
      __hasAddOnCtEngage: Boolean,
      __hasAddOnCtInforms: Boolean,
      __hasAddOnCtRemind: Boolean,

      expanded: {
        type: Boolean,
        reflect: true,
      },
      hideBottomSettings: {
        type: Boolean,
        reflect: true,
        attribute: 'hide-bottom-settings',
      },
      layout: {
        type: String,
        reflect: true,
      },
      navItems: Array,
      opened: {
        type: Boolean,
        reflect: true,
      },
      selectedItem: String,
      user: { type: Object },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__shouldAnimateBar = false;
    this.__storedSlideDirection = '';
    this.__slideDirection = '';
    this.__hasAddOnCtEngage = false;
    this.__hasAddOnCtInforms = false;
    this.__hasAddOnCtRemind = false;
    this.__expandedIndex = -1;

    this.layout = '';
    this.expanded = true;
    this.hideBottomSettings = false;
    this.opened = false;
    this.navItems = [];
    this.selectedItem = 'analytics';
    this.user = {};
    this.visible = true;

    this.__blacklistService = new BlacklistService(
      ['/patients/:patientId/ledger', '/practice-ledger'],
      force => {
        if (force && this.expanded) this.__handlers.toggleExpand();
      },
    );

    this.onNavigateManually = () => {};

    this.onUserMenuOptions = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      animationEnd: () => {
        this.__slideDirection = '';
      },
      toggleExpand: () => {
        this.expanded = !this.expanded;
      },
      menuToggled: () => {
        this.opened = !this.opened;
      },
      userMenuOptions: (label, options) =>
        this.onUserMenuOptions(label, options),
      click: navItem => {
        this.opened = false;
        this.__shouldAnimateBar = true;
        this.__storedSlideDirection = this.getSlideDirection(navItem.label);

        this.__setLocationHash(navItem);
      },
      clickCTEngageSsoIcon: () => openDoctibleSsoLink(''),
      clickCTInforms: () => openDoctibleSsoLink(DOCTIBLE_LINKS.ctInforms),
      clickCTRemind: () => openDoctibleSsoLink(),
      clickMenu: async (redirectTo, index) => {
        this.__expandedIndex = index;

        if (redirectTo) {
          await openDoctibleSsoLink(redirectTo);
        }
      },
      newSettingsApp: () => {
        window.location.href = '/settings';
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__blacklistService.connect();

    window.addEventListener(
      'neb-route-hashchange',
      (this.__hashchangeListener = () => this._onPathChanged()),
    );

    const handler = this.__handlers.animationEnd;
    this.shadowRoot.addEventListener('animationend', handler);
    this.shadowRoot.addEventListener('webkitAnimationEnd', handler);

    this.__hasAddOnCtEngage = await hasAddOn(ADD_ONS.CT_ENGAGE);
    this.__hasAddOnCtInforms = await hasAddOn(ADD_ONS.CT_INFORMS);
    this.__hasAddOnCtRemind = await hasAddOn(ADD_ONS.CT_REMIND);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener(
      'neb-route-hashchange',
      this.__hashchangeListener,
    );

    this.__blacklistService.disconnect();
  }

  getSlideDirection(navItem) {
    const navItems = this.navItems.map(({ name }) => name);
    const newNavItem = navItems.indexOf(navItem);
    const currNavItem = navItems.indexOf(this.selectedItem);
    if (newNavItem > currNavItem) return SLIDE_DIRECTION.DOWN;
    if (newNavItem < currNavItem) return SLIDE_DIRECTION.UP;
    return '';
  }

  __setLocationHash(navItem) {
    const manualNavItems = this.navItems
      .filter(({ manualNav }) => manualNav)
      .map(({ name }) => name);

    if (navItem.name !== this.selectedItem || this.layout === 'small') {
      store.dispatch(navigate(navItem.hash));
    } else if (manualNavItems.includes(navItem.name)) {
      this.onNavigateManually(navItem.name);
    }
  }

  _onPathChanged() {
    if (this.__shouldAnimateBar) {
      this.__slideDirection = this.__storedSlideDirection;
    }

    this.__shouldAnimateBar = false;
  }

  __calculateSideBarHeight() {
    const navItemIndex = this.navItems
      .map(({ name }) => name)
      .indexOf(this.selectedItem);
    return `top: ${navItemIndex *
      (NAV_ITEM_HEIGHT_VALUE + NAV_ITEM_MARGIN_VALUE)}px`;
  }

  __genCtEngageItems() {
    return [
      {
        label: 'CT Engage',
        leadingIcon: 'engage',
        trailingIcon: 'open',
        subItems: [
          {
            label: 'Messages',
            id: '/providers/messaging',
            trailingIcon: 'open',
          },
          {
            label: 'Appointment Notifications',
            id: '/providers/appointments',
            trailingIcon: 'open',
          },
          {
            label: 'Campaigns',
            id: '/providers/campaigns/select-template',
            trailingIcon: 'open',
          },
          {
            label: 'Patient Recall',
            id: '/providers/recall/daily-review',
            trailingIcon: 'open',
          },
          {
            label: 'Review Management',
            id: '/providers/review_management/overview',
            trailingIcon: 'open',
          },
        ],
      },
    ];
  }

  static get styles() {
    return css`
      :host {
        flex: 0 0 ${WIDTH_CLOSED};
        width: ${WIDTH_CLOSED};
        height: 100%;
        transition: 100ms;
        overflow-y: auto;
        background-color: ${CSS_PRIMARY_1};
      }

      :host([expanded]) {
        width: ${WIDTH_OPEN};
        flex: 0 0 ${WIDTH_OPEN};
      }

      :host([layout='small']) {
        width: 100%;
        height: initial;
        overflow-y: visible;
      }

      .container {
        position: relative;
        display: flex;
        height: 100%;
        width: 100%;
      }

      .container-content {
        display: flex;
        flex-direction: column;

        height: 100%;
        width: 100%;
        z-index: 31;
      }

      .header {
        height: 75px;
      }

      :host([opened]) .header {
        height: auto;
      }

      .container-toggle-expand {
        position: absolute;
        display: flex;
        align-items: center;
        right: 0;
        height: 100%;
        width: ${CSS_SPACING};

        opacity: 0;
        transition: 200ms;
        border-right: 2px solid transparent;
        cursor: pointer;
        z-index: 100;
      }

      .container-toggle-expand:hover {
        opacity: 1;
        border-right: 4px solid ${CSS_COLOR_HIGHLIGHT};
      }

      .toggle-expand {
        margin-right: 7px;
        fill: ${CSS_COLOR_HIGHLIGHT};
        transform: rotate(-90deg);
        height: 10px;
        width: 10px;
      }

      :host([expanded]) .toggle-expand {
        transform: rotate(90deg);
      }

      .container-nav-items {
        position: relative;
      }

      .nav-item:first-of-type {
        margin: 0;
      }

      .nav-item {
        height: ${NAV_ITEM_HEIGHT_CSS};
        margin-top: ${NAV_ITEM_MARGIN_CSS};
      }

      .panel {
        height: ${NAV_ITEM_HEIGHT_CSS};
        margin-top: ${NAV_ITEM_MARGIN_CSS};
      }

      @-webkit-keyframes stretch {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1, 2);
        }
        100% {
          transform: scale(1);
        }
      }

      .selected-bar {
        border: 2px solid ${CSS_COLOR_HIGHLIGHT};
        height: ${NAV_ITEM_HEIGHT_CSS};
        position: absolute;
        box-sizing: border-box;
        transition: 250ms;
      }

      :host(:not([layout='small']))
        .selected-bar[slide='${SLIDE_DIRECTION.DOWN}'] {
        transition: 400ms;
        transform-origin: bottom;
        -webkit-animation: stretch 0.3s linear forwards;
      }

      :host(:not([layout='small']))
        .selected-bar[slide='${SLIDE_DIRECTION.UP}'] {
        transition: 400ms;
        transform-origin: top;
        -webkit-animation: stretch 0.3s linear forwards;
      }

      .nav-settings-container {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
      }

      .new-settings {
        box-sizing: border-box;
        align-items: center;
        color: ${CSS_COLOR_WHITE};
        display: flex;
        width: 100%;
        padding: 15px ${CSS_SPACING};
        cursor: pointer;
      }

      :host(:not([expanded]):not([layout='small'])) .new-settings{
        justify-content: center;
      }

      .icon {
        width: 30px;
        height: 30px;
        fill: ${CSS_COLOR_WHITE};
        flex: 0 0 30px;
      }

      .new-settings-text {
        padding-left: 12px;
        cursor: pointer;
      }

      .separator {
        margin: 0;
        box-sizing: border-box;
        width: 100%;
        border: ${CSS_BORDER_HIGHLIGHT};
      }

      .add-on-sso-icon {
        cursor: pointer;
        display:flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        margin-top: 15px;
      }

      :host([layout='small']) .separator {
          display: none;
      }

      :host([layout='small']) .container-nav {
        position: static;
      }

      .container-add-on {
        align-items: center;
        color: ${CSS_COLOR_WHITE};
        display: flex;
        padding: 15px ${CSS_SPACING};
        cursor: pointer;
      }

      .label {
        margin-left: 12px;
        text-transform: capitalize;
        font-size: 14px;
      }

      .container-icon-trailing {
        display: flex;
        align-items: center;
        padding-left: 5px;
      }

      .icon-trailing {
        width: 13px;
        height: 13px;
        fill: ${CSS_COLOR_WHITE};
      }
    `;
  }

  __renderToggleExpandButton() {
    return this.layout === 'large'
      ? html`
          <div
            id="${ELEMENTS.toggleExpandButton.id}"
            class="container-toggle-expand"
            @click="${this.__handlers.toggleExpand}"
          >
            <neb-icon class="toggle-expand" icon="neb:chevron"></neb-icon>
          </div>
        `
      : '';
  }

  __renderSelectedItemBar() {
    return html`
      <div
        id="${ELEMENTS.selectedBar.id}"
        class="selected-bar"
        .style="${this.__calculateSideBarHeight()}"
        .slide="${this.__slideDirection}"
      ></div>
    `;
  }

  __showLabel() {
    return !(
      this.layout === 'medium' ||
      (this.layout === 'large' && !this.expanded)
    );
  }

  __renderNavItem(item) {
    return html`
      <neb-nav-primary-item-lit
        id="${item.label}"
        class="nav-item"
        .showLabel="${this.__showLabel()}"
        .model="${item}"
        .selected="${this.selectedItem === item.name}"
        .onClick="${this.__handlers.click}"
      >
      </neb-nav-primary-item-lit>
    `;
  }

  __renderNavigationToSetting() {
    return this.layout === 'large'
      ? html`
          <div
            id="${ELEMENTS.newSettings.id}"
            class="new-settings"
            @click="${this.__handlers.newSettingsApp}"
          >
            <neb-icon class="icon" icon="neb:settingsNew"></neb-icon>

            ${
              this.__showLabel()
                ? html`
                    <div
                      id="${ELEMENTS.newSettingsLabel.id}"
                      class="new-settings-text"
                    >
                      Settings
                    </div>
                  `
                : ''
            }
          </div>
        `
      : '';
  }

  __renderCtEngageItemsOrIcon() {
    if (this.__hasAddOnCtEngage) {
      return (this.layout === 'large' && this.expanded) ||
        this.layout === 'small'
        ? this.__genCtEngageItems().map(
            (item, index) => html`
              <neb-expansion-panel
                class="panel"
                .item="${item}"
                .index="${index}"
                .expanded="${this.__expandedIndex === index}"
                .onChange="${this.__handlers.clickMenu}"
                .visible="${this.visible}"
              ></neb-expansion-panel>
            `,
          )
        : html`
            <div
              id="${ELEMENTS.ctEngageCollapsedIcon.id}"
              class="add-on-sso-icon"
              @click="${this.__handlers.clickCTEngageSsoIcon}"
            >
              <neb-icon class="icon" icon="neb:engage"></neb-icon>
            </div>
          `;
    }

    return '';
  }

  __renderCtInformsItem() {
    if (this.__hasAddOnCtInforms) {
      return (this.layout === 'large' && this.expanded) ||
        this.layout === 'small'
        ? html`
            <div
              class="nav-item"
              id="${ELEMENTS.ctInformsMenuItem.id}"
              @click="${this.__handlers.clickCTInforms}"
            >
              <div class="container-add-on">
                <neb-icon class="icon" icon="neb:informs"></neb-icon>
                <div class="label">CT InForms</div>
                <div class="container-icon-trailing">
                  <neb-icon class="icon-trailing" icon="neb:open"></neb-icon>
                </div>
              </div>
            </div>
          `
        : html`
            <div
              id="${ELEMENTS.ctInformsCollapsedIcon.id}"
              class="add-on-sso-icon"
              @click="${this.__handlers.clickCTInforms}"
            >
              <neb-icon class="icon" icon="neb:informs"></neb-icon>
            </div>
          `;
    }

    return '';
  }

  __renderCtRemindItem() {
    if (this.__hasAddOnCtRemind) {
      return (this.layout === 'large' && this.expanded) ||
        this.layout === 'small'
        ? html`
            <div
              class="nav-item"
              id="${ELEMENTS.ctRemindMenuItem.id}"
              @click="${this.__handlers.clickCTRemind}"
            >
              <div class="container-add-on">
                <neb-icon class="icon" icon="neb:engage"></neb-icon>
                <div class="label">CT Remind</div>
                <div class="container-icon-trailing">
                  <neb-icon class="icon-trailing" icon="neb:open"></neb-icon>
                </div>
              </div>
            </div>
          `
        : html`
            <div
              id="${ELEMENTS.ctRemindCollapsedIcon.id}"
              class="add-on-sso-icon"
              @click="${this.__handlers.clickCTRemind}"
            >
              <neb-icon class="icon" icon="neb:engage"></neb-icon>
            </div>
          `;
    }

    return '';
  }

  __renderNavItems() {
    return this.opened || this.layout !== 'small'
      ? html`
          <div
            id="${ELEMENTS.navItemsContainer.id}"
            class="container-nav-items"
          >
            ${this.__renderSelectedItemBar()}
            ${this.navItems.map(item => this.__renderNavItem(item))}
            ${this.__renderCtInformsItem()}
            ${this.__renderCtEngageItemsOrIcon()} ${this.__renderCtRemindItem()}
          </div>
        `
      : '';
  }

  __renderBig() {
    return html`
      <div class="container-content">
        <div class="nav-settings-container">
          <neb-nav-primary-header
            id="${ELEMENTS.header.id}"
            class="header"
            .expanded="${this.expanded}"
            .layout="${this.layout}"
            .apiOverride="${this.__apiOverride}"
            .user="${this.user}"
            .onMenuToggled="${this.__handlers.menuToggled}"
            .onUserMenuOptions="${this.__handlers.userMenuOptions}"
          ></neb-nav-primary-header>

          ${this.__renderNavItems()}
        </div>

        ${!this.hideBottomSettings ? this.__renderNavigationToSetting() : ''}
      </div>

      ${this.__renderToggleExpandButton()}
    `;
  }

  __renderSmall() {
    return html`
      <div class="container-content">
        <neb-nav-primary-header
          id="${ELEMENTS.header.id}"
          class="header"
          .expanded="${this.expanded}"
          .layout="${this.layout}"
          .apiOverride="${this.__apiOverride}"
          .user="${this.user}"
          .onMenuToggled="${this.__handlers.menuToggled}"
          .onUserMenuOptions="${this.__handlers.userMenuOptions}"
        ></neb-nav-primary-header>

        ${this.__renderNavItems()}
        ${!this.hideBottomSettings ? this.__renderNavigationToSetting() : ''}
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.layout === 'small' ? this.__renderSmall() : this.__renderBig()}
      </div>
    `;
  }
}
customElements.define('neb-nav-primary-lit', NebNavPrimaryLit);
