import '../../../../../packages/neb-lit-components/src/components/neb-text';

import { html, css } from 'lit';

import NebTable, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
} from '../../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  adjustment: { id: 'adjustment' },
  tooltip: { id: 'tooltip' },
};

class NebTablePaymentDetails extends NebTable {
  static get properties() {
    return {
      __isAdjustmentTruncated: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .adjustmentCell .tooltipText {
          display: none;
          width: auto;
          height: auto;
          background-color: ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_WHITE};
          text-align: center;
          border-radius: 6px;
          padding: 10px;
          position: absolute;
          z-index: 1;
          left: 50%;
          margin-left: -40px;
          white-space: normal;
        }
        .adjustmentCell:hover .tooltipText {
          display: block;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.emptyMessage = 'Select an item to see details.';
    this.preview = true;
    this.allowPropagation = true;

    this.__isAdjustmentTruncated = false;
  }

  __shouldShowAdjustmentTooltip() {
    const nebTextElement = this.shadowRoot.getElementById('adjustment');

    if (nebTextElement) {
      setTimeout(() => {
        const originalWidth = nebTextElement.style.width;
        nebTextElement.style.width = 'auto';
        const fullTextWidth = nebTextElement.scrollWidth;
        nebTextElement.style.width = originalWidth;
        this.__isAdjustmentTruncated =
          fullTextWidth > nebTextElement.clientWidth;
      }, 1000);
    }
  }

  updated(changed) {
    super.updated(changed);

    if (changed.has('model')) {
      this.__shouldShowAdjustmentTooltip();
    }
  }

  __renderAdjustmentTooltip(value) {
    return html`
      <span id="${ELEMENTS.tooltip.id}" class="tooltipText">${value}</span>
    `;
  }

  __renderAdjustmentCell(value) {
    return html`
      <div class="adjustmentCell">
        <neb-text id="${ELEMENTS.adjustment.id}" ?truncate="${true}"
          >${value}</neb-text
        >
        ${
          this.__isAdjustmentTruncated
            ? this.__renderAdjustmentTooltip(value)
            : ''
        }
      </div>
    `;
  }

  renderHeaderCell(headerCell) {
    return super.renderHeaderCell(headerCell);
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'adjustments':
        return this.__renderAdjustmentCell(value);

      default:
        return super.renderDataCell(value, columnConfig, rowIndex);
    }
  }
}

customElements.define('neb-table-payment-details', NebTablePaymentDetails);
