import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const billingApiClient = new ApiClient({
  microservice: 'billing',
});

export const saveLastLocation = ({
  patientId,
  lastLocation,
  additionalInformation,
}) =>
  billingApiClient.makeRequest({
    method: Method.POST,
    path: 'recent-record',
    body: JSON.stringify({
      patientId,
      lastLocation,
      additionalInformation,
    }),
    headers: { 'Content-Type': 'application/json' },
    version: 1,
  });

export const getAllRecentRecords = () =>
  billingApiClient.makeRequest({
    method: Method.GET,
    path: 'recent-record',
    version: 1,
  });
