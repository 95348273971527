import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-styles/neb-icons';

import '../../misc/neb-view-alert';

import { LitElement, html, css } from 'lit';

import {
  CSS_TYPE_COLUMN_WIDTH,
  CSS_ICON_COLUMN_WIDTH,
  CSS_MESSAGE_COLUMN_WIDTH,
} from '../../../../packages/neb-alert/components/neb-alert-table-styles';
import { NebDnDVerticalOrder } from '../../../../packages/neb-lit-components/src/components/neb-dnd-vertical-order';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import { baseTableStyles } from '../../../../packages/neb-styles/neb-table-styles';
import {
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_FAMILY,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
} from '../../../../packages/neb-styles/neb-variables';
import { NebFormAlert } from '../../forms/alerts/neb-form-alert';

const NO_ALERT_MSG = 'There are no Alerts or Notes for this patient.';

export const ELEMENTS = {
  addAlertButton: {
    id: 'add-alert-button',
  },
  noAlertMessage: {
    id: 'no-alert-message',
  },
  alertHeader: {
    id: 'alert-header',
  },
  alerts: {
    selector: '.alert',
    tag: 'neb-view-alert',
  },
  formAlert: {
    id: 'form-alert',
  },
  viewAlert: {
    id: 'view-alert',
  },
  typeHeader: {
    id: 'type-header',
  },
  messageHeader: {
    id: 'message-header',
  },
};

class NebPageAlerts extends NebDnDVerticalOrder(LitElement) {
  static get properties() {
    return {
      __countLabel: {
        type: String,
      },
      alerts: {
        type: Array,
      },
      editAlertId: {
        type: String,
      },
      hideHeader: {
        type: Boolean,
        attribute: 'hide-header',
      },
      small: {
        type: Boolean,
        reflect: true,
      },
      __editing: {
        type: Boolean,
      },
      _items: {
        type: Object,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      baseTableStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
        }

        .add-alert-button {
          cursor: pointer;
        }

        :host(:not([small])) .add-alert-button {
          padding-left: 23px;
        }

        .alert-row {
          display: flex;
          flex-direction: column;
          padding-bottom: 1px;
        }

        .no-alert-message {
          padding: 23px;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-family: ${CSS_FONT_FAMILY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }

        .neb-table-icon-column {
          width: 53px;
        }

        .alert-header {
          font-weight: bold;
          margin: -15px 0 10px 23px;
          font-size: ${CSS_FONT_SIZE_HEADER};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandler();
  }

  __initState() {
    this.alerts = [];
    this.hideHeader = false;
    this.editAlertId = null;
    this.__editing = false;

    this.onSave = () => {};

    this.onDelete = () => {};

    this.onDirty = () => {};

    this.onReorder = async () => {};

    this.onAdd = () => {};

    this.onCancelEdit = () => {};

    this.onEdit = () => {};
  }

  __initHandler() {
    this.__handlers = {
      add: () => this.onAdd(),
      save: alert => this.onSave(alert),
      cancel: () => this.onCancelEdit(),
      delete: alert => this.onDelete(alert),
      edit: alertId => this.onEdit(alertId),
      changeDirty: dirty => this.onDirty(dirty),
    };
  }

  async _afterDragEnd() {
    await this.onReorder(
      this._items.map((alert, index, { length }) => ({
        ...alert,
        order: length - index,
        type: Number(alert.type),
      })),
    );
  }

  __generateCountLabel() {
    this.__countLabel =
      this.alerts && this.alerts.length
        ? `
          (${this.alerts.length})
        `
        : '';
  }

  updated(changed) {
    if (changed.has('editAlertId')) {
      this.__editing = this.editAlertId !== null;
    }

    if (changed.has('alerts')) {
      this.__generateCountLabel();
      this._items = this.alerts;
    }

    if (changed.has('_items') || changed.has('__editing')) {
      this._update();
    }
  }

  __renderAlertHeader() {
    return !this.hideHeader && !this.small
      ? html`
          <div id="${ELEMENTS.alertHeader.id}" class="alert-header">
            Alerts & Notes ${this.__countLabel}
          </div>
        `
      : '';
  }

  __renderAlertTableHeader() {
    return html`
      <div class="neb-table-row neb-table-header">
        <div class="neb-table-cell  ${CSS_ICON_COLUMN_WIDTH}"></div>

        <div
          id="${ELEMENTS.typeHeader.id}"
          class="neb-table-cell ${CSS_TYPE_COLUMN_WIDTH}"
        >
          Type
        </div>

        <div
          id="${ELEMENTS.messageHeader.id}"
          class="neb-table-cell ${CSS_MESSAGE_COLUMN_WIDTH}"
        >
          Message
        </div>
      </div>
    `;
  }

  __renderNewAlert() {
    return this.editAlertId === 0
      ? html`
          <div class="neb-table-row alert-row">
            ${this.__renderEditForm(NebFormAlert.createModel(), 0)}
          </div>
        `
      : '';
  }

  __renderEditForm(alert, index) {
    delete alert.createdByUserName;
    delete alert.editedByUserName;

    return html`
      <neb-form-alert
        id="${ELEMENTS.formAlert.id}"
        .index="${index}"
        .model="${alert}"
        .onSave="${this.__handlers.save}"
        .onCancel="${this.__handlers.cancel}"
        .onDelete="${this.__handlers.delete}"
        .onChangeDirty="${this.__handlers.changeDirty}"
      ></neb-form-alert>
    `;
  }

  __renderViewAlert(alert, index) {
    return html`
      <neb-view-alert
        id="${ELEMENTS.viewAlert.id}"
        class="alert"
        reorder-row
        .index="${index}"
        .isEditing="${this.__editing}"
        .model="${alert}"
        ?small="${this.small}"
        .onEdit="${this.__handlers.edit}"
        .onDelete="${this.__handlers.delete}"
      ></neb-view-alert>
    `;
  }

  __renderAlerts() {
    if (
      (!this.alerts || (this.alerts && !this.alerts.length)) &&
      this.editAlertId !== 0
    ) {
      return html`
        <div
          id="${ELEMENTS.noAlertMessage.id}"
          class="no-alert-message neb-font-regular-secondary"
        >
          ${NO_ALERT_MSG}
        </div>
      `;
    }

    return html`
      ${this.__renderNewAlert()}
      ${
        this.alerts.map((alert, index) => {
          const isForm = this.editAlertId === alert.id;
          return html`
            <div class="neb-table-row alert-row">
              ${
                isForm
                  ? this.__renderEditForm(alert, index)
                  : this.__renderViewAlert(alert, index)
              }
            </div>
          `;
        })
      }
    `;
  }

  __renderAddButton() {
    if (this.__editing) {
      return '';
    }
    return html`
      <neb-button-action
        id="${ELEMENTS.addAlertButton.id}"
        class="add-alert-button"
        label="Add Alert or Note"
        .onClick="${this.__handlers.add}"
      ></neb-button-action>
    `;
  }

  render() {
    return html`
      ${this.__renderAlertHeader()} ${this.__renderAddButton()}
      ${this.__renderAlertTableHeader()} ${this.__renderAlerts()}
    `;
  }
}

customElements.define('neb-page-alerts', NebPageAlerts);
