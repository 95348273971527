import './neb-appointment-table';
import '../neb-lit-components/src/components/neb-pagination';

import { LitElement, html, css } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { calculatePageCount } from '../neb-lit-components/src/utils/pagination';
import { store, connect } from '../neb-redux/neb-redux-store';
import { baseStyles } from '../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../neb-styles/neb-variables';

export const APPOINTMENT_STATUS = Object.freeze({
  ACTIVE: 'Active',
  NO_SHOW: 'No-Show',
  CHECKED_IN: 'Checked-In',
  CHECKED_OUT: 'Checked-Out',
  CANCELED: 'Canceled',
  DELETED: 'Deleted',
});

export const ELEMENTS = {
  title: {
    id: 'title',
  },
  appointmentsTable: {
    id: 'table-appointments',
  },
  pagination: {
    id: 'pagination',
  },
  appointmentsPagination: {
    id: 'appointments-pagination',
  },
};

class NebAppointments extends connect(store)(LitElement) {
  static get properties() {
    return {
      title: String,
      emptyMessage: String,
      active: Boolean,
      patientId: String,
      query: Object,
      model: Object,
      cases: Array,
      locations: Array,
      rooms: Array,
      resetPage: Boolean,
      __small: Boolean,
      __layout: String,
      __hash: String,
      __currentPage: Number,
      isFromCase: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.title = 'Appointments';
    this.emptyMessage = '';
    this.active = false;
    this.patientId = '';
    this.query = {};
    this.model = {
      appointments: [],
      queryMetadata: {},
      count: 0,
    };

    this.resetPage = true;

    this.cases = [];
    this.locations = [];
    this.rooms = [];

    this.isPatientAppointments = false;

    this.__small = false;
    this.__layout = 'large';
    this.__hash = '';
    this.__currentPage = 0;
    this.isFromCase = false;

    this.onTableUpdated = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      tableUpdated: queryParam => this.onTableUpdated(queryParam),
      pageChanged: pageIndex => this.__handlePageChanged(pageIndex),
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('neb-sort-order-changed', e => this.__sortTable(e));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('neb-sort-order-changed', e =>
      this.__sortTable(e),
    );
  }

  __getCount(pageSize) {
    return pageSize ? this.model.count : this.model.appointments.length;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
        }

        .title {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin-bottom: ${CSS_SPACING};
        }

        .pagination-end {
          align-self: flex-end;
          margin-top: ${CSS_SPACING};
          height: 30px;
        }

        .pagination-start {
          align-self: flex-start;
          margin-top: ${CSS_SPACING};
          height: 30px;
        }

        .pagination-start-case {
          align-self: flex-start;
          margin-top: ${CSS_SPACING};
          height: 30px;
          padding-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  updated(changedProps) {
    if (
      changedProps.has('resetPage') &&
      Object.keys(this.model.queryMetadata).length
    ) {
      this.__currentPage = 0;
    }
    super.update(changedProps);
  }

  __renderTitle(count) {
    return html`
      <div id="${ELEMENTS.title.id}" class="title">
        ${this.title} (${count})
      </div>
    `;
  }

  render() {
    const paginationClasses = {
      'pagination-start-case': this.isFromCase,
      'pagination-start':
        this.__layout !== 'small' && this.isPatientAppointments,
      'pagination-end':
        this.__layout === 'small' || !this.isPatientAppointments,
    };

    const count = this.__getCount(10);

    const pageCount = calculatePageCount(count, 10);

    return html`
      ${!this.isFromCase ? this.__renderTitle(count) : ''}
      <neb-appointment-table
        id="${ELEMENTS.appointmentsTable.id}"
        .small="${this.__small}"
        .emptyMessage="${this.emptyMessage}"
        .data="${this.model.appointments}"
        .cases="${this.cases}"
        .rooms="${this.rooms}"
        .locations="${this.locations}"
        .hash="${this.__hash}"
        .columns="${this.query.columns}"
        .sort="${this.query.sort}"
        .isFromCase="${this.isFromCase}"
        .onDismiss="${this.__handlers.tableUpdated}"
      >
      </neb-appointment-table>

      <neb-pagination
        id="${ELEMENTS.appointmentsPagination.id}"
        class="${classMap(paginationClasses)}"
        .layout="${this.__layout}"
        .pageCount="${pageCount}"
        .currentPage="${this.__currentPage}"
        .onPageChanged="${this.__handlers.pageChanged}"
      ></neb-pagination>
    `;
  }

  async _stateChanged(state) {
    await this.updateComplete;
    this.__small = state.layoutMedia.small;
    this.__layout = state.layoutMedia.layout;
    this.__hash = state.route.hash;
  }

  __handlePageChanged(pageNumber) {
    if (this.active && this.patientId) {
      this.__currentPage = pageNumber;
      const offset = pageNumber * 10;
      const queryParam = { ...this.model.queryMetadata, offset };

      this.__handlers.tableUpdated(queryParam);
    }
  }

  __sortTable({ detail: { element } }) {
    if (element) {
      const currentPage = this.__currentPage;
      this.__setSort(element.sortField, element.sortOrder);
      this.__currentPage = currentPage;
    }
  }

  __setSort(sortField, sortOrder) {
    const hasSortChanged =
      this.__sortDirection !== sortOrder || this.__sortField !== sortField;

    if (hasSortChanged) {
      this.__sortDirection = sortOrder;
      this.__sortField = sortField;

      this.__fetchAppointments();
    }
  }

  __fetchAppointments() {
    if (this.active && this.patientId) {
      const queryParam = {
        ...this.model.queryMetadata,
        patientId: this.patientId,
        sortField: this.__sortField || this.model.queryMetadata.sortField,
        sortDir: this.__sortDirection || this.model.queryMetadata.sortDir,
        secondarySortField: this.model.queryMetadata.secondarySortField,
        secondarySortDir: this.model.queryMetadata.secondarySortDir,
        limit: 10,
        offset: this.__currentPage * 10,
        expand: 'appointmentType,splits',
      };

      this.__handlers.tableUpdated(queryParam);
    }
  }
}

customElements.define('neb-appointments', NebAppointments);
