import '../inputs/neb-floating-label';
import '../inputs/neb-text-helper';
import '../inputs/neb-textfield';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_FIELD_MARGIN } from '../../../../neb-styles/neb-variables';
import { alphanumeric } from '../../../../neb-utils/masks';

export const modifiersSelector = {
  validators: [
    {
      error: true,
      validate: v => !v || (v.length !== 1 && v.match(/^[0-9a-zA-Z]{1,2}$/)),
    },
  ],
};
export const ELEMENTS = {
  helperText: {
    id: 'helper-text',
  },
  label: {
    id: 'label',
  },
  modifiers: {
    selector: 'neb-textfield',
  },
  modifier1: {
    id: 'mod1',
  },
  modifier2: {
    id: 'mod2',
  },
  modifier3: {
    id: 'mod3',
  },
  modifier4: {
    id: 'mod4',
  },
};

export class NebModifiers extends LitElement {
  static get properties() {
    return {
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },
      label: String,
      helper: String,
      name: String,
      values: Array,
      errors: Array,
      readonly: {
        reflect: true,
        type: Boolean,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          --modifier-columns: repeat(4, 50.4px);
        }

        .container {
          display: flex;
          position: relative;
          padding-top: ${CSS_FIELD_MARGIN};
          width: 100%;
          height: 100%;
          min-width: 0;
          align-items: center;
          flex-flow: column nowrap;
        }

        :host([label='']) .container {
          padding-top: 0;
        }

        .container-fields {
          display: grid;
          grid-template-columns: var(--modifier-columns);
          grid-gap: 2.8px;
        }

        .textfield {
          --text-indent: 5.6px;
          --text-align: center;
        }
      `,
    ];
  }

  static createModel() {
    return ['', '', '', ''];
  }

  static createSelectors(opts = {}, errorMessage = '') {
    return {
      children: {
        $: {
          ...opts,
          validators: [
            {
              error: errorMessage || true,
              validate: v => !v || v.match(/^([0-9a-zA-Z]){2}$/),
            },
          ],
        },
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__focused = false;
    this.readonly = false;
    this.disabled = false;
    this.name = '';
    this.label = '';
    this.helper = '';
    this.values = ['', '', '', ''];
    this.errors = ['', '', '', ''];

    this.onChange = () => {};

    this.onBlur = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      focus: () => {
        this.__focused = true;
      },
      blur: e => {
        this.__focused = false;
        this.onBlur({
          name: `${this.name}.${e.name}`,
          value: e.value,
        });
      },
      change: ({ name, value }) =>
        this.onChange({
          name: `${this.name}.${name}`,
          value,
        }),
    };
  }

  hasErrors() {
    return this.errors.filter(err => err).map(err => Boolean(err)).length > 0;
  }

  render() {
    return html`
      <div class="container">
        <div class="container-fields">
          ${
            this.values.map(
              (item, index) => html`
                <neb-textfield
                  id="${ELEMENTS[`modifier${index + 1}`].id}"
                  class="textfield"
                  maxLength="2"
                  placeholder=" "
                  .name="${index}"
                  .mask="${alphanumeric}"
                  .value="${item}"
                  .error="${Boolean(this.errors[index])}"
                  .onFocus="${this.__handlers.focus}"
                  .onBlur="${this.__handlers.blur}"
                  .onChange="${this.__handlers.change}"
                  ?readonly="${this.readonly}"
                  ?disabled="${this.disabled}"
                ></neb-textfield>
              `,
            )
          }
        </div>

        <neb-floating-label
          id="${ELEMENTS.label.id}"
          .text="${this.label}"
          ?focused="${this.__focused}"
          ?invalid="${this.hasErrors()}"
          pinned
        ></neb-floating-label>

        <neb-text-helper
          id="${ELEMENTS.helperText.id}"
          text="${this.helper}"
          ?disabled="${this.disabled}"
          ?invalid="${this.hasErrors()}"
        ></neb-text-helper>
      </div>
    `;
  }
}

customElements.define('neb-modifiers', NebModifiers);
