import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const billingApiClient = new ApiClient({ microservice: 'billing' });
export const billingApiClientV2 = new ApiClientV2({ microservice: 'billing' });
export const appointmentsApiClient = new ApiClientV2({
  microservice: 'appointments',
});

const BASE_URL = 'appointments';
const APPOINTMENT_TYPES = {
  future: true,
  past: false,
};

export const APPOINTMENT_STATUS = {
  ACTIVE: 'Active',
  NO_SHOW: 'No-Show',
  CHECKED_IN: 'Checked-In',
  CHECKED_OUT: 'Checked-Out',
};

const DEFAULT_STATUSES = [
  APPOINTMENT_STATUS.ACTIVE,
  APPOINTMENT_STATUS.CHECKED_IN,
  APPOINTMENT_STATUS.CHECKED_OUT,
];

export const APPOINTMENTS_UPDATE_PATHS = {
  cancel: '/api/v1/tenants/:tenantId/appointments/:appointmentId/cancel',
  checkIn: '/api/v1/tenants/:tenantId/appointments/:appointmentId/check-in',
  checkOut: '/api/v1/tenants/:tenantId/appointments/:appointmentId/check-out',
  edit: '/api/v1/tenants/:tenantId/appointments/:appointmentId/edit',
  instantiateRecurrence:
    '/api/v1/tenants/:tenantId/appointments/:appointmentId/instantiate-recurrence',
  noShow: '/api/v1/tenants/:tenantId/appointments/:appointmentId/no-show',
  reschedule:
    '/api/v2/tenants/:tenantId/appointments/:appointmentId/reschedule',
  returnToScheduled:
    '/api/v1/tenants/:tenantId/appointments/:appointmentId/return-to-scheduled',
};

export const getCheckInSummary = async appointmentId => {
  const res = await billingApiClientV2.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path:
      '/api/v1/tenants/:tenantId/appointments/:appointmentId/check-in-summary',
    headers: {
      'Content-Type': 'application/json',
    },
    replacements: { appointmentId },
  });

  return res.data[0];
};

export async function quickCheckInAppointment(id, patientPackageId) {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: true,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `${BASE_URL}/${id}/quick-check-in`,
    body: JSON.stringify({ patientPackageId }),
    version: 1,
    updateNotificationDetails: {
      appointment: {
        id,
      },
    },
  });

  return res.data[0];
}

export const quickCheckOutAppointment = id =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: true,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `${BASE_URL}/${id}/quick-check-out`,
    version: 1,
    updateNotificationDetails: {
      appointment: {
        id,
      },
    },
  });

export const createAppointment = appointment =>
  appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/appointments',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(appointment),
    updateNotificationDetails: {
      appointment: { patientId: appointment.patientId },
    },
  });

export const getAppointments = (queryParams, optOutLoadingIndicator = false) =>
  appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/appointments',
    queryParams,
    cacheKey: `${JSON.stringify(queryParams)}-appointments`,
    optOutLoadingIndicator,
  });

export const getAppointmentById = async (
  appointmentId,
  includeOptions,
  optOutLoadingIndicator = false,
) => {
  const res = await billingApiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${appointmentId}`,
    queryParams: includeOptions,
    optOutLoadingIndicator,
    version: 2,
  });

  return {
    appointment: res.data[0],
    provider: res.provider,
  };
};

export const getQuickActions = async (
  appointmentId,
  optOutLoadingIndicator = false,
) => {
  const res = await appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/appointments/:appointmentId/quick-actions',
    replacements: { appointmentId },
    optOutLoadingIndicator,
  });

  return res;
};

export const SUPPORTED_UPDATE_ACTIONS = [
  'cancel',
  'checkIn',
  'checkOut',
  'edit',
  'instantiateRecurrence',
  'noShow',
  'reschedule',
  'returnToScheduled',
];

export const updateAppointment = (
  appointmentId,
  actionVerb,
  appointment = {},
  optOutLoadingIndicator = false,
) => {
  if (!SUPPORTED_UPDATE_ACTIONS.includes(actionVerb)) {
    throw new Error(
      `Unsupported action: ${actionVerb}, not in ${SUPPORTED_UPDATE_ACTIONS}`,
    );
  }

  const path = APPOINTMENTS_UPDATE_PATHS[actionVerb];

  return appointmentsApiClient.makeRequest({
    method: Method.PUT,
    path,
    replacements: {
      appointmentId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(appointment),
    optOutLoadingIndicator,
    updateNotificationDetails: {
      appointment: {
        patientId: appointment.patientId,
      },
    },
  });
};

export const deleteAppointment = (appointmentId, patientId) =>
  appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: '/api/v1/tenants/:tenantId/appointments/:appointmentId',
    replacements: {
      appointmentId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    updateNotificationDetails: {
      appointment: {
        patientId,
      },
      patient: patientId,
    },
  });

export const deleteSeries = (appointmentId, patientId) =>
  appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: '/api/v1/tenants/:tenantId/appointments/:appointmentId/delete-series',
    replacements: {
      appointmentId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    updateNotificationDetails: {
      appointment: {
        patientId,
      },
    },
  });

export const getProviderAppointments = (
  query = {},
  optOutLoadingIndicator = false,
) => {
  const sortParams = query.sortParams || { key: '', dir: '' };
  const sortDir = sortParams.dir;
  const sortField = sortParams.key;
  const start = query.start || '';
  const end = query.end || '';
  const expand = query.expand || '';
  const includeEncounterOnly = query.includeEncounterOnly || false;
  const startBefore = query.startBefore || '';
  const status = (query.statuses || DEFAULT_STATUSES).join(',');

  const raw = {
    ...query,
    sortField,
    sortDir,
    status,
    start,
    end,
    startBefore,
    includeEncounterOnly,
    expand,
  };

  const queryParams = Object.entries(raw).reduce(
    (accum, [k, v]) =>
      v && typeof v !== 'object' ? { ...accum, [k]: v } : accum,
    {},
  );

  return appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/appointments',
    version: 1,
    queryParams,
    cacheKey: `${JSON.stringify(queryParams)}-provider-appointments`,
    optOutLoadingIndicator,
  });
};

export const getBookingAppointments = ({ type, offset, limit }) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    version: 1,
    path: `${BASE_URL}/booking?future=${
      APPOINTMENT_TYPES[type]
    }&offset=${offset}&limit=${limit}`,
  });

export const getBookingAppointment = async appointmentId => {
  const res = await appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/appointments/:appointmentId/booking',
    replacements: { appointmentId },
    cacheKey: `${appointmentId}-booking-appointment`,
  });

  return res.data[0];
};

export const getGuestAppointments = async queryParams => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    version: 'public1',
    path: BASE_URL,
    queryParams,
  });

  return res.data;
};

export const getGuestAppointmentsWithPin = async selfCheckInPIN => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    version: 'public1',
    body: JSON.stringify({ selfCheckInPIN }),
    path: `${BASE_URL}`,
  });

  return res.data;
};

export const roomConflictCheck = id =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_URL}/calendar-resources/${id}/conflict`,
    version: 1,
  });

export const getBlockedOffTimeById = async id => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    version: 1,
    path: `${BASE_URL}/${id}/blocked-off-time`,
  });

  return res ? res.data[0] : res;
};

export const getRescheduledAppointmentsById = async (
  appointmentId,
  optOutLoadingIndicator = false,
) => {
  const res = await appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/appointments/:appointmentId/reschedule',
    replacements: { appointmentId },
    optOutLoadingIndicator,
  });

  return res;
};
