import '../../../../src/components/misc/neb-icon';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
} from '../../../neb-styles/neb-variables';
import { validateBlob } from '../../../neb-utils/blobProcessor';
import { validateFileType } from '../../../neb-utils/fileTypeValidator';

export const ID_LABEL = 'label';
export const ID_INPUT = 'input';
export const ID_TOUCH_OVERLAY = 'touch-overlay';
export const ID_OVERLAY = 'overlay';
export const ID_DROP_FILE_TEXT = 'dropFileText';
export const ID_SELECT_FILE_TEXT = 'selectFileText';
export const INVALID_FILE_TYPE_TEXT =
  'The file type must be PNG or JPG. Please convert this image to the appropriate file type. Note: While the extension may state .JPG or .PNG the actual file type does not meet our requirements.';
const TEXT_INVALID_SELECTION = 'Invalid Selection';

export const ELEMENTS = {
  overlay: {
    id: 'overlay',
  },
  input: {
    id: 'input',
  },
  dropFileText: {
    id: 'dropFileText',
  },
  selectFileText: {
    id: 'selectFileText',
  },
};

class NebFileSelect extends LitElement {
  static get properties() {
    return {
      showPreview: Boolean,
      allowedExtensions: Array,
      dragging: {
        reflect: true,
        type: Boolean,
      },
      touchDevice: Boolean,
      maxFileSize: Number,
      validMimeTypes: Array,
      invalidFileMessage: String,
      dropFileText: String,
      selectFileText: String,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.showPreview = false;
    this.allowedExtensions = [];
    this.touchDevice = false;
    this.maxFileSize = 0;
    this.validMimeTypes = [];
    this.invalidFileMessage = '';
    this.dropFileText = 'Drop file here to upload';
    this.selectFileText = 'select a file';

    this.onSelectFile = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      inputClicked: () => this.__elements.input.click(),
      drag: e => this.__handleDragEvent(e, true),
      dragFinished: e => this.__handleDragEvent(e, false),
      drop: async e => {
        this.__handleDragEvent(e, false);

        const file = e.dataTransfer.items[0].getAsFile();

        const isValid = this.__validateFile(file);
        const isValidType = await validateFileType(file);

        if (!isValid) {
          return isValidType
            ? this.__renderInvalidFilePopup()
            : this.__renderInvalidFileTypePopup();
        }

        return this.onSelectFile(file);
      },
    };
  }

  async _handleFileInput(e) {
    const input = e.currentTarget;
    const file = e.currentTarget.files[0];

    const isValid = this.__validateFile(file);
    const isValidType = await validateFileType(file);

    if (isValid && isValidType) {
      this.onSelectFile(file);
    } else if (!isValidType) {
      this.__renderInvalidFileTypePopup();
    } else {
      this.__renderInvalidFilePopup();
    }

    input.value = null;
  }

  __handleDragEvent(e, dragging) {
    e.preventDefault();
    this.dragging = dragging;
  }

  __validateFile(file) {
    return validateBlob(file, this.maxFileSize, this.validMimeTypes);
  }

  __renderInvalidFilePopup() {
    openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: TEXT_INVALID_SELECTION,
      message: this.invalidFileMessage,
    });
  }

  __renderInvalidFileTypePopup() {
    openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: TEXT_INVALID_SELECTION,
      message: INVALID_FILE_TYPE_TEXT,
    });
  }

  firstUpdated() {
    this.__elements = {
      input: this.shadowRoot.getElementById(ID_INPUT),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          cursor: pointer;
          overflow: hidden;
          height: 150px;
        }

        .icon {
          height: 55px;
          width: 65px;

          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .container {
          display: flex;
          position: relative;
          height: 100%;
          width: 100%;
          border: 1px dashed ${CSS_COLOR_HIGHLIGHT};
          border-width: 1px;
          font-size: ${CSS_FONT_SIZE_BODY};
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .container[dragging] {
          opacity: 0.5;
        }

        .container[preview] {
          border: none;
        }

        .centered {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: transparent;
        }

        .underline {
          text-decoration: underline;
        }
      `,
    ];
  }

  __renderFileInput(hidden) {
    return html`
      <input
        id="${ELEMENTS.input.id}"
        type="file"
        accept="${this.allowedExtensions.map(ext => `.${ext}`).join()}"
        @change="${this._handleFileInput}"
        ?hidden="${hidden}"
      />
    `;
  }

  __renderInstructions() {
    return this.touchDevice
      ? html`
          <label
            id="${ID_TOUCH_OVERLAY}"
            for="${ID_INPUT}"
            class="overlay centered"
            >${this.__renderFileInput(false)}</label
          >
        `
      : html`
          <neb-icon class="icon" icon="neb:upload"></neb-icon>

          <div id="${ELEMENTS.dropFileText.id}">${this.dropFileText}</div>

          <div>
            or ${this.__renderFileInput(true)}
            <span id="${ELEMENTS.selectFileText.id}" class="underline"
              >${this.selectFileText}</span
            >.
          </div>

          <div
            id="${ID_OVERLAY}"
            class="overlay"
            @dragover="${this.__handlers.drag}"
            @dragleave="${this.__handlers.dragFinished}"
            @drop="${this.__handlers.drop}"
            @click="${this.__handlers.inputClicked}"
          ></div>
        `;
  }

  render() {
    return html`
      <div
        id="${ID_LABEL}"
        class="container"
        ?dragging="${this.dragging}"
        ?preview="${this.showPreview}"
      >
        ${
          this.showPreview
            ? html`
                <slot></slot>
              `
            : this.__renderInstructions()
        }
      </div>
    `;
  }
}

customElements.define('neb-file-select', NebFileSelect);
