import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-image-selection-card';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/neb-text';

import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  CSS_COLOR_GREY_1,
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../../packages/neb-styles/neb-variables';
import { normalizeForSearch } from '../../../../packages/neb-utils/formatters';
import { TOKEN_KEY } from '../../../../packages/neb-www-practice-charting/src/macro-processor/token-replacement';
import {
  QUESTION_ANSWER_TOKEN,
  openQuestionAnswerOverlay,
  ADVANCED_TOKENS,
} from '../../../utils/macros';

export const ELEMENTS = {
  header: { id: 'header' },
  image: { selector: 'img' },
  selectionCards: { selector: 'neb-image-selection-card' },
  filterTypeDropdown: { id: 'filter-type-dropdown' },
  filterTypeSearch: { id: 'filter-type-search' },
  noItemsLabel: { id: 'label-no-items' },
};

export const ADVANCED_MACRO_TOKEN = {
  ...QUESTION_ANSWER_TOKEN,
  ...ADVANCED_TOKENS,
};

export const FILTER_TYPE = {
  ALL_MACRO_TYPES: 'All Macro Types',
  QUESTION_AND_ANSWER: 'Question & Answer',
  DEMOGRAPHICS: 'Demographics',
  PATIENT_HEALTH_INFORMATION: 'Patient Health Information',
  SYSTEM_INFORMATION: 'System Information',
};

export const FILTER_LIST = {
  [FILTER_TYPE.DEMOGRAPHICS]: [
    TOKEN_KEY.ADDRESS,
    TOKEN_KEY.AGE,
    TOKEN_KEY.BIRTH_DATE,
    TOKEN_KEY.EMAIL,
    TOKEN_KEY.EMPLOYMENT_STATUS,
    TOKEN_KEY.FIRST_NAME,
    TOKEN_KEY.HE_SHE,
    TOKEN_KEY.CAPITAL_HE_SHE,
    TOKEN_KEY.HIM_HER,
    TOKEN_KEY.HIS_HER,
    TOKEN_KEY.CAPITAL_HIS_HER,
    TOKEN_KEY.LAST_NAME,
    TOKEN_KEY.RELATIONSHIP_STATUS,
    TOKEN_KEY.MR_MRS,
    TOKEN_KEY.MR_MS,
    TOKEN_KEY.PHONE,
    TOKEN_KEY.GENDER,
  ],
  [FILTER_TYPE.PATIENT_HEALTH_INFORMATION]: [
    TOKEN_KEY.CURRENT_DX,
    TOKEN_KEY.CURRENT_LISTINGS,
    TOKEN_KEY.CURRENT_TX,
    TOKEN_KEY.INJURY_DATE,
  ],
  [FILTER_TYPE.QUESTION_AND_ANSWER]: [
    ADVANCED_MACRO_TOKEN.ask,
    ADVANCED_MACRO_TOKEN.chooseAny,
    ADVANCED_MACRO_TOKEN.chooseOne,
    ADVANCED_MACRO_TOKEN.date,
    ADVANCED_MACRO_TOKEN.number,
    ADVANCED_MACRO_TOKEN.region,
    ADVANCED_MACRO_TOKEN.spine,
  ],
  [FILTER_TYPE.SYSTEM_INFORMATION]: [
    TOKEN_KEY.CURRENT_DATE,
    TOKEN_KEY.CURRENT_TIME,
    TOKEN_KEY.CURRENT_USER,
  ],
};

const getSexTokenDescription = token =>
  `The "${token}" macro will insert the appropriate word into the chart note based on the Sex field in the patient's profile.`;

class NebOverlayAdvancedMacroType extends Overlay {
  static get properties() {
    return {
      __filterType: String,
      __searchText: String,
      __answerSets: Object,
      __filteredCards: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .header {
          margin: ${CSS_SPACING};
        }

        .content-container {
          overflow-y: auto;
        }

        .card {
          padding: 0 ${CSS_SPACING};
        }

        .dropdown {
          padding-right: ${CSS_SPACING};
        }

        .filter-container {
          display: grid;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
          align-items: flex-end;
          grid-template-columns: 2fr 2fr 1fr;
        }

        .text-no-items {
          margin: 0 ${CSS_SPACING};
          color: ${CSS_COLOR_GREY_1};
        }
      `,
    ];
  }

  get cardConfig() {
    return [
      {
        title: 'Ask',
        type: ADVANCED_MACRO_TOKEN.ask,
        imgSrc: '../src/neb-advanced-macro-ask.png',
        description:
          'The "Ask" macro will present a text input used to record answers that require text entry.',
        onClick: type => this.__openQuestionAnswerOverlay(type),
      },
      {
        title: 'Choose Any',
        type: ADVANCED_MACRO_TOKEN.chooseAny,
        imgSrc: '../src/neb-advanced-macro-choose-any.png',
        description:
          'The "Choose Any" macro will present a list of choices used to record answers for questions that can have multiple answers.',
        onClick: type => this.__openQuestionAnswerOverlay(type),
      },
      {
        title: 'Choose One',
        type: ADVANCED_MACRO_TOKEN.chooseOne,
        imgSrc: '../src/neb-advanced-macro-choose-one.png',
        description:
          'The "Choose One" macro will present a list of choices used to record answers for questions that require a single answer.',
        onClick: type => this.__openQuestionAnswerOverlay(type),
      },
      {
        title: 'Date',
        type: ADVANCED_MACRO_TOKEN.date,
        imgSrc: '../src/neb-advanced-macro-date.png',
        description:
          'The "Date" macro will present a date picker used to record answers for questions that require a date.',
        onClick: type => this.__openQuestionAnswerOverlay(type),
      },
      {
        title: 'Number',
        type: ADVANCED_MACRO_TOKEN.number,
        imgSrc: '../src/neb-advanced-macro-number.png',
        description:
          'The "Number" macro will present a number pad used to record answers for questions that require a numerical value.',
        onClick: type => this.__openQuestionAnswerOverlay(type),
      },
      {
        title: 'Region',
        type: ADVANCED_MACRO_TOKEN.region,
        imgSrc: '../src/neb-advanced-macro-region.png',
        description:
          'The "Region" macro will present a body image used to record answers that require body region selections.',
        onClick: type => this.__openQuestionAnswerOverlay(type),
      },
      {
        title: 'Spine',
        type: ADVANCED_MACRO_TOKEN.spine,
        imgSrc: '../src/neb-advanced-macro-spine.png',
        description:
          'The "Spine" macro will present a spine image used to record answers that require vertebrae selections.',
        onClick: type => this.__openQuestionAnswerOverlay(type),
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.ADDRESS],
        type: TOKEN_KEY.ADDRESS,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Address" macro will insert the patient\'s address into the chart note based on the Address 1, Address 2, City, State, and ZIP Code fields in the patient\'s profile.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.AGE],
        type: TOKEN_KEY.AGE,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Age" macro will insert the patient\'s current age into the chart note based on the Date of Birth field in the patient\'s profile.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.BIRTH_DATE],
        type: TOKEN_KEY.BIRTH_DATE,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Birth Date" macro will insert the patient\'s birth date into the chart note based on the Date of Birth field in the patient\'s profile.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.CURRENT_DATE],
        type: TOKEN_KEY.CURRENT_DATE,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Current Date" macro will insert the current date into the chart note.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.CURRENT_DX],
        type: TOKEN_KEY.CURRENT_DX,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Current Diagnosis" macro will insert the patient\'s diagnoses for the current encounter into the chart note.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.CURRENT_LISTINGS],
        type: TOKEN_KEY.CURRENT_LISTINGS,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Current Listings" macro will insert the patient\'s current listings into the chart note.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.CURRENT_TIME],
        type: TOKEN_KEY.CURRENT_TIME,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Current Time" macro will insert the current time into the chart note.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.CURRENT_TX],
        type: TOKEN_KEY.CURRENT_TX,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Current Treatment Plan" macro will insert the patient\'s current treatment plan into the chart note.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.CURRENT_USER],
        type: TOKEN_KEY.CURRENT_USER,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Current User" macro will insert the first and last name of the current user into the chart note.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.EMAIL],
        type: TOKEN_KEY.EMAIL,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Email" macro will insert the patient\'s email address into the chart note based on the Email field in the patient\'s profile.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.EMPLOYMENT_STATUS],
        type: TOKEN_KEY.EMPLOYMENT_STATUS,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Employment Status" macro will insert the patient\'s employment status into the chart note based on the Employment Status field in the patient\'s profile.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.FIRST_NAME],
        type: TOKEN_KEY.FIRST_NAME,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "First Name" macro will insert the patient\'s first name into the chart note based on the First Name field in the patient\'s profile.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.HE_SHE],
        type: TOKEN_KEY.HE_SHE,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description: getSexTokenDescription(
          ADVANCED_MACRO_TOKEN[TOKEN_KEY.HE_SHE],
        ),
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.CAPITAL_HE_SHE],
        type: TOKEN_KEY.CAPITAL_HE_SHE,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description: getSexTokenDescription(
          ADVANCED_MACRO_TOKEN[TOKEN_KEY.CAPITAL_HE_SHE],
        ),
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.HIM_HER],
        type: TOKEN_KEY.HIM_HER,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description: getSexTokenDescription(
          ADVANCED_MACRO_TOKEN[TOKEN_KEY.HIM_HER],
        ),
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.HIS_HER],
        type: TOKEN_KEY.HIS_HER,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description: getSexTokenDescription(
          ADVANCED_MACRO_TOKEN[TOKEN_KEY.HIS_HER],
        ),
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.CAPITAL_HIS_HER],
        type: TOKEN_KEY.CAPITAL_HIS_HER,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description: getSexTokenDescription(
          ADVANCED_MACRO_TOKEN[TOKEN_KEY.CAPITAL_HIS_HER],
        ),
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.INJURY_DATE],
        type: TOKEN_KEY.INJURY_DATE,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Injury Date" macro will insert the patient\'s date of current illness for the current encounter into the chart note.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.LAST_NAME],
        type: TOKEN_KEY.LAST_NAME,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Last Name" macro will insert the patient\'s last name into the chart note based on the Last Name field in the patient\'s profile.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.RELATIONSHIP_STATUS],
        type: TOKEN_KEY.RELATIONSHIP_STATUS,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Marital Status" macro will insert the patient\'s relationship status into the chart note based on the Relationship Status field in the patient\'s profile.',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.MR_MRS],
        type: TOKEN_KEY.MR_MRS,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description: getSexTokenDescription(
          ADVANCED_MACRO_TOKEN[TOKEN_KEY.MR_MRS],
        ),
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.MR_MS],
        type: TOKEN_KEY.MR_MS,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description: getSexTokenDescription(
          ADVANCED_MACRO_TOKEN[TOKEN_KEY.MR_MS],
        ),
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.PHONE],
        type: TOKEN_KEY.PHONE,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Phone" macro will insert the patient\'s phone number into the chart note based on the Phone Number field in the patient\'s profile',
      },
      {
        title: ADVANCED_MACRO_TOKEN[TOKEN_KEY.GENDER],
        type: TOKEN_KEY.GENDER,
        imgSrc: '../src/neb-advanced-macro-basic-text.png',
        description:
          'The "Sex" macro will insert the patient\'s sex into the chart note based on the Sex field in the patient\'s profile.',
      },
    ];
  }

  firstUpdated() {
    super.firstUpdated();

    setTimeout(
      () =>
        this.shadowRoot.getElementById(ELEMENTS.filterTypeSearch.id).focus(),
      0,
    );
  }

  initState() {
    super.initState();

    this.__filterType = FILTER_TYPE.ALL_MACRO_TYPES;
    this.__searchText = '';
    this.__filteredCards = this.cardConfig;
    this.__answerSets = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      dismiss: type => {
        if (type) {
          return this.dismiss({
            type,
            model: { answerSets: this.__answerSets },
          });
        }

        return this.dismiss({ model: { answerSets: this.__answerSets } });
      },
      dismissWithQuestionAnswerToken: result => {
        if (equal(Object.keys(result), ['answerSets'])) {
          this.__answerSets = result.answerSets;
          return;
        }

        if (!result) {
          return;
        }

        if (result.dismissWithoutResult) {
          this.dismiss({ model: { answerSets: result.answerSets } });
        } else {
          this.dismiss({ model: result });
        }
      },

      change: ({ value, event }) => {
        if (event === 'select') {
          this.__filterType = value;
          this.__setFilteredCards();
        }
      },

      search: ({ value }) => {
        this.__searchText = value;
        this.__setFilteredCards();
      },

      clearSearch: () => {
        this.__searchText = '';
        this.__setFilteredCards();
      },
    };
  }

  dismissWithBlocker() {
    this.handlers.dismiss();
  }

  __setFilteredCards() {
    const filteredCards =
      this.__filterType === FILTER_TYPE.ALL_MACRO_TYPES
        ? this.cardConfig
        : this.cardConfig.filter(({ type }) =>
            FILTER_LIST[this.__filterType].includes(type),
          );

    this.__filteredCards = filteredCards.filter(({ title }) =>
      normalizeForSearch(title).includes(normalizeForSearch(this.__searchText)),
    );
  }

  async __openQuestionAnswerOverlay(type) {
    const result = await openQuestionAnswerOverlay({
      ...this.model,
      type,
      answerSets: { ...this.model.answerSets, ...this.__answerSets },
    });

    if (result) {
      this.handlers.dismissWithQuestionAnswerToken(result);
    }
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        showCancelButton
        title="Advanced Macros"
        .onCancel="${this.handlers.dismiss}"
      ></neb-popup-header>
    `;
  }

  __renderCardContent(description, imgSrc) {
    return html`
      <div class="content-card">
        ${description} <img class="img" src="${imgSrc}" />
      </div>
    `;
  }

  __renderCard({ title, type, description, imgSrc, onClick }) {
    return html`
      <neb-image-selection-card
        class="card"
        .description="${description}"
        .imgSrc="${imgSrc}"
        .name="${type}"
        .title="${title}"
        .onClick="${onClick || this.handlers.dismiss}"
      >
      </neb-image-selection-card>
    `;
  }

  __renderFilter() {
    return html`
      <div class="filter-container">
        <neb-select
          id="${ELEMENTS.filterTypeDropdown.id}"
          class="dropdown"
          showFullText
          label="Filter Type"
          .value="${this.__filterType}"
          .items="${Object.values(FILTER_TYPE)}"
          .maxVisibleItems="${Object.values(FILTER_TYPE).length}"
          .onChange="${this.handlers.change}"
        >
        </neb-select>

        <neb-textfield
          id="${ELEMENTS.filterTypeSearch.id}"
          class="search"
          leadingIcon="neb:search"
          placeholder="Enter macro name to filter list below."
          .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
          .value="${this.__searchText}"
          .onChange="${this.handlers.search}"
          .onClickIcon="${this.handlers.clearSearch}"
        ></neb-textfield>
      </div>
    `;
  }

  __renderEmptyMessage() {
    return html`
      <neb-text id="${ELEMENTS.noItemsLabel.id}" class="text-no-items" italic
        >No results.</neb-text
      >
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderFilter()}
      <div class="content-container">
        ${
          this.__filteredCards.length
            ? this.__filteredCards.map(card => this.__renderCard(card))
            : this.__renderEmptyMessage()
        }
      </div>
    `;
  }
}

customElements.define(
  'neb-overlay-advanced-macro-type',
  NebOverlayAdvancedMacroType,
);
