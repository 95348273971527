import '../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_BLACK,
} from '../neb-styles/neb-variables';

export const DEFAULT_LABEL = 'dashboard';
export const ELEMENTS = {
  container: {
    id: 'container',
  },
  icon: {
    id: 'icon',
  },
  label: {
    id: 'label',
  },
};

class NebNavPrimaryItemLit extends LitElement {
  static get properties() {
    return {
      showLabel: {
        type: Boolean,
        reflect: true,
      },
      model: {
        type: Object,
      },
      selected: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.showLabel = true;
    this.model = null;
    this.selected = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => this.onClick(this.model),
    };
  }

  static get styles() {
    return css`
      :host {
        height: 60px;
        width: 100%;
        display: flex;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
      }

      :host([selected]) {
        background-color: ${CSS_COLOR_BLACK};
      }

      :host([selected]) .container {
        color: ${CSS_COLOR_HIGHLIGHT};
      }

      :host([selected]) .icon {
        fill: ${CSS_COLOR_HIGHLIGHT};
      }

      .container {
        align-items: center;
        color: ${CSS_COLOR_WHITE};
        display: flex;
        width: 100%;
        padding: 15px ${CSS_SPACING};
      }

      :host(:not([showLabel])) .container {
        justify-content: center;
        padding: 0;
      }

      .icon {
        display: flex;
        width: 30px;
        height: 30px;

        fill: ${CSS_COLOR_WHITE};
      }

      .label {
        margin-left: 12px;
        text-transform: capitalize;
        font-size: 14px;
      }
    `;
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.click}"
      >
        <neb-icon
          id="${ELEMENTS.icon.id}"
          class="icon"
          .icon="${this.model.icon}"
        ></neb-icon>
        ${
          this.showLabel
            ? html`
                <div id="${ELEMENTS.label.id}" class="label">
                  ${this.model.label.replace('-', ' ')}
                </div>
              `
            : ''
        }
      </div>
    `;
  }
}

customElements.define('neb-nav-primary-item-lit', NebNavPrimaryItemLit);
