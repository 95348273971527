import '../../../neb-app-layout/neb-nav-secondary-lit';
import '../../../neb-lit-components/src/components/neb-content-instruction';
import '../../../neb-lit-components/src/components/patients/neb-patient-list-controller';
import '../../../neb-lit-components/src/components/patients/neb-patient-condensed-info';
import './neb-patient-summary-view';
import './roster/neb-patient-roster-upload';

import { matchRouteSwitch } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { UpdateNotificationService } from '../../../../src/services/update-notifications';
import { getLastTrackedLocation } from '../../../../src/utils/recent-records';
import { ANY } from '../../../../src/utils/update-notifications';
import {
  DISPLAY_ON,
  createBannerController,
} from '../../../neb-alert/components/neb-alert-banner-controller';
import * as documentApi from '../../../neb-api-client/src/document-api-client';
import * as patientApiClient from '../../../neb-api-client/src/patient-api-client';
import * as caseApi from '../../../neb-api-client/src/patient-cases';
import { getPatientImage } from '../../../neb-api-client/src/patient-image-api-client';
import * as patientInsuranceApi from '../../../neb-api-client/src/patient-insurance-api-client';
import { isOverlayOpen } from '../../../neb-lit-components/src/utils/misc';
import {
  openOverlay,
  OVERLAY_KEYS,
  popOverlay,
} from '../../../neb-lit-components/src/utils/overlay-constants';
import { store, connect } from '../../../neb-redux/neb-redux-store';
import { navigate } from '../../../neb-route/neb-route-state';
import { CSS_SECONDARY_NAV_SEARCH_WIDTH } from '../../../neb-styles/neb-variables';
import { objToInitials } from '../../../neb-utils/formatters';
import {
  NEBULA_REFRESH_EVENT,
  REFRESH_CHANGE_TYPE,
  REFRESH_PATIENT_KEY,
} from '../../../neb-utils/neb-refresh';
import { fetchPatient, mapToPatientModel } from '../../../neb-utils/patient';
import {
  searchPatients,
  setDocumentCount,
  setCaseCount,
} from '../store/patientsAction';

export const ELEMENTS = {
  patientListController: {
    id: 'controller-patient-list',
  },
  instructionPage: {
    id: 'page-instruction',
  },
  infoPage: {
    id: 'page-info',
  },
  summaryPage: {
    id: 'page-summary',
  },
  rosterPage: {
    id: 'page-roster',
  },
  secondaryNav: {
    id: 'secondary-nav',
  },
  patientCondensedInfo: {
    id: 'patient-condensed-info',
  },
};

class NebPatientsPage extends connect(store)(LitElement) {
  static get properties() {
    return {
      __touchDevice: Boolean,
      __numAlerts: Number,
      __documentCount: Number,
      __insuranceCount: Number,
      __caseCount: Number,
      __patient: {
        type: Object,
      },
      __session: Object,
      __expanded: Boolean,
      __loading: Boolean,
      __searchBoxFocus: Boolean,

      route: String,
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initServices();
    this.__initHandlers();
  }

  __initState() {
    this.__expanded = false;
    this.__touchDevice = false;
    this.__loading = false;
    this.__numAlerts = 0;
    this.__documentCount = 0;
    this.__insuranceCount = 0;
    this.__caseCount = 0;
    this.__tenantId = '';
    this.__accessToken = '';
    this.__apiOverride = '';
    this.__session = {};
    this.__patient = null;
    this.__searchBoxFocus = false;
    this.__navItems = this.__genNavItems();
    this.__alertBanner = createBannerController(
      DISPLAY_ON.patientRecord,
      alerts => {
        this.__numAlerts = alerts.filter(alert => alert.alert === true).length;
      },
    );

    this.route = '';
    this.layout = '';

    this.onUserMenuOptions = () => {};
  }

  __initServices() {
    this.__updateNotificationService = new UpdateNotificationService({
      defaultQuery: {
        patientCase: ANY,
      },
      callback: () => this.__updateCaseCount(),
    });
  }

  __initHandlers() {
    this.__handlers = {
      selectPatient: patient => {
        if (patient) {
          this.__navigate(patient);
          store.dispatch(searchPatients(''));
        }
      },
      editPatient: async () => {
        if (!this.__expanded) {
          const patient = await openOverlay(
            OVERLAY_KEYS.PATIENT,
            await fetchPatient(this.__patient.id),
          );

          if (patient) this.__patient = { ...patient };
        } else {
          await popOverlay(OVERLAY_KEYS.PATIENT);
        }
      },
      refresh: ({ detail }) => {
        if (!this.__patient) return;

        const { id: patientId } = this.__patient;

        if (
          !patientId ||
          !Object.keys(detail).includes(REFRESH_PATIENT_KEY) ||
          !Object.keys(detail[REFRESH_PATIENT_KEY]).includes(patientId)
        ) {
          return;
        }

        const { changed } = detail[REFRESH_PATIENT_KEY][patientId];

        if (changed.includes(REFRESH_CHANGE_TYPE.PATIENT)) {
          this.__getPatient(patientId, true);
        } else {
          if (changed.includes(REFRESH_CHANGE_TYPE.PATIENT_INSURANCE)) {
            this.__updateInsuranceCount();
          }

          if (changed.includes(REFRESH_CHANGE_TYPE.DOCUMENTS)) {
            this.__updateDocumentCount();
          }
        }
      },
      updatePatient: async () => {
        const patient = await patientApiClient.fetchOne(
          this.__patient.id,
          false,
          true,
        );
        const photoSrc = await getPatientImage(patient.id, 'small', true);

        this.__patient = mapToPatientModel(patient, 0, photoSrc);

        this.__updateTabCounts();
      },
      userMenuOptions: (label, options) =>
        this.onUserMenuOptions(label, options),
      loadPatientList: (focus = true) => {
        this.__searchBoxFocus = focus;
      },
    };
  }

  _stateChanged({ layoutMedia, apiOverride, session, popup }) {
    this.__touchDevice = layoutMedia.touchDevice;
    this.__session = session.item;
    this.__apiOverride = apiOverride.value;
    this.__expanded = isOverlayOpen(popup.overlays, OVERLAY_KEYS.PATIENT.name);

    if (this.__session) {
      this.__tenantId = this.__session.tenantId;
      this.__accessToken = this.__session.accessToken;
    } else {
      this.__tenantId = '';
      this.__accessToken = '';
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.__updateNotificationService.connect();
    this.__alertBanner.connect();
    window.addEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__alertBanner.disconnect();
    this.__updateNotificationService.disconnect();
    window.removeEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);
  }

  __genNavItems() {
    return [
      {
        path: '/roster/',
        resolver: () => html`
          <neb-patient-roster-upload
            id="${ELEMENTS.rosterPage.id}"
            class="content"
            .touchDevice="${this.__touchDevice}"
          ></neb-patient-roster-upload>
        `,
      },
      {
        exact: false,
        path: '/:id/',
        resolver: tail =>
          this.__patient
            ? html`
                <neb-patient-summary-view
                  id="${ELEMENTS.summaryPage.id}"
                  class="content"
                  .layout="${this.layout}"
                  .route="${tail}"
                  .patient="${this.__patient}"
                  .documentCount="${this.__documentCount}"
                  .insuranceCount="${this.__insuranceCount}"
                  .caseCount="${this.__caseCount}"
                  .onEditPatient="${this.__handlers.editPatient}"
                  .onUpdatePatient="${this.__handlers.updatePatient}"
                ></neb-patient-summary-view>
              `
            : '',
      },
      {
        path: '/',
        resolver: () => html`
          <neb-patient-list-controller
            id="${ELEMENTS.patientListController.id}"
            class="list"
            layout="${this.layout}"
            .onAdd="${this.__handlers.selectPatient}"
            .onSelect="${this.__handlers.selectPatient}"
            .onLoadPatientList="${this.__handlers.loadPatientList}"
            .searchBoxFocus="${true}"
          ></neb-patient-list-controller>

          ${
            this.layout !== 'small'
              ? html`
                  <neb-content-instruction
                    id="${ELEMENTS.instructionPage.id}"
                    class="content"
                    icon="neb:patients"
                    label="Select a patient to view their profile"
                  ></neb-content-instruction>
                `
              : ''
          }
        `,
      },
    ];
  }

  navigate(route) {
    store.dispatch(navigate(`#/patients/${route}`));
  }

  __navigate({ id, lastLocation }) {
    const route = getLastTrackedLocation({
      id,
      lastLocation,
      isMobile: this.layout === 'small',
    });

    return this.navigate(route);
  }

  __updateTabCounts() {
    this.__updateDocumentCount();
    this.__updateInsuranceCount();
    this.__updateCaseCount();
  }

  async __updateDocumentCount() {
    this.__documentCount = await documentApi.getDocumentCount(
      this.__patient.id,
      true,
    );

    store.dispatch(setDocumentCount(this.__documentCount));
  }

  async __updateInsuranceCount() {
    this.__insuranceCount = (await patientInsuranceApi.getPatientInsurancesCount(
      this.__patient.id,
      { active: true },
      1,
      true,
    )).count;
  }

  async __updateCaseCount() {
    const count = await caseApi.getActiveCasesCount(this.__patient.id, true);
    this.__caseCount = count;
    store.dispatch(setCaseCount(this.__caseCount));
  }

  isPatientSelected() {
    const patientId = this.route.split('/')[2];
    return !patientId ? false : !['', 'new', 'roster'].includes(patientId);
  }

  __getUser(session) {
    if (session) {
      const {
        id,
        tenantId,
        tenantIds,
        firstName = '',
        lastName = '',
        profileImgUrl,
      } = session;

      return {
        id,
        tenantId,
        tenantIds,
        profileImgUrl,
        name: `${firstName} ${objToInitials({
          last: lastName,
        })}.`,
      };
    }
    return {};
  }

  __formatToPatientCondensedInfoModel(patient) {
    if (patient) {
      const {
        id,
        tenantId,
        name: {
          first: firstName,
          last: lastName,
          middle: middleName,
          preferred: preferredName,
          suffix,
        },
        dateOfBirth,
        hasPhoto: patientImage,
        sex,
      } = patient;
      return {
        patientImage,
        id,
        tenantId,
        firstName,
        lastName,
        middleName,
        preferredName,
        suffix,
        dateOfBirth,
        sex,
      };
    }

    return {};
  }

  __showCondensedInfo() {
    return (
      this.layout !== 'small' && this.__patient && this.isPatientSelected()
    );
  }

  __fetchPhotoSrc(patient) {
    return patient.hasPhoto && !patient.photoSrc
      ? getPatientImage(patient.id, 'small', true)
      : '';
  }

  setLoading(loading) {
    this.__loading = loading;
  }

  unblockDescendants(patientId) {
    this.__patient = { id: patientId, name: {} };
  }

  async __getPatient(id, force = false) {
    const patientChanged = this.__patient && this.__patient.id !== id;

    if ((id && (!this.__patient || patientChanged)) || force) {
      try {
        this.setLoading(true);

        if (!force) {
          this.unblockDescendants(id);
        }

        const patient = await patientApiClient.fetchOne(id, true, true);

        if (patient.patientImage) {
          getPatientImage(patient.id, 'small', true).then(value => {
            this.__patient = { ...this.__patient, photoSrc: value };
          });
        }

        this.__patient = mapToPatientModel(patient, 0);

        this.__alertBanner.update(this.__patient.id);
        this.__updateTabCounts();
      } catch (error) {
        console.error(error);
      } finally {
        this.setLoading(false);
      }
    }
  }

  async openPatientOverlay(id) {
    this.__handlers.loadPatientList();
    const patient = await openOverlay(OVERLAY_KEYS.PATIENT_LIST, {
      patientId: id,
    });
    this.__handlers.loadPatientList(false);

    if (patient) {
      this.__navigate(patient);
    }
  }

  update(changedProps) {
    if (
      (changedProps.has('__numAlerts') || changedProps.has('__patient')) &&
      this.__patient
    ) {
      this.__patient = {
        ...this.__patient,
        alertCount: this.__numAlerts,
      };
    }

    if (changedProps.has('route') && this.route) {
      const id = this.route.split('/')[1];
      const tab = this.route.split('/')[3];

      if (id !== 'roster') {
        if (tab === 'general') {
          this.__getPatient(id, true);
        } else {
          this.__getPatient(id);
        }
      }
    }

    super.update(changedProps);
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }

      .container {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        overflow-x: auto;
      }

      .content {
        flex: 1 0 0;
      }

      :host([layout='small']) .content {
        max-width: 100%;
      }

      :host([layout='small']) .container {
        background-color: white;
      }

      .list {
        width: ${CSS_SECONDARY_NAV_SEARCH_WIDTH};
      }

      .list[layout='small'] {
        width: 100%;
      }

      .secondary-nav {
        flex-shrink: 0;
      }
    `;
  }

  render() {
    return html`
      <neb-nav-secondary-lit
        has-actions
        id="${ELEMENTS.secondaryNav.id}"
        class="secondary-nav"
        .layout="${this.layout}"
        .apiOverride="${this.__apiOverride}"
        .user="${this.__getUser(this.__session)}"
        .onUserMenuOptions="${this.__handlers.userMenuOptions}"
        ?hasActions="${this.__showCondensedInfo()}"
        .searchBoxFocus="${this.__searchBoxFocus}"
      >
        ${
          this.__patient && this.layout !== 'small' && !this.__loading
            ? html`
                <neb-patient-condensed-info
                  id="${ELEMENTS.patientCondensedInfo.id}"
                  .model="${this.__patient}"
                  .layout="${this.layout}"
                  .onOpenOverlay="${this.__handlers.editPatient}"
                  ?expanded="${this.__expanded}"
                  slot="actions"
                ></neb-patient-condensed-info>
              `
            : ''
        }
      </neb-nav-secondary-lit>

      <div class="container">
        ${matchRouteSwitch(this.__navItems, this.route)}
      </div>
    `;
  }
}

window.customElements.define('neb-patients-page', NebPatientsPage);
