import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

const BASE_URL = 'patients';
const INSURANCE_PATH = 'insurance';

export const GET_INSURANCE_VERSION = 1;
export const EDIT_INSURANCE_VERSION = 2;

export const createPatientInsurance = (
  patientId,
  patientInsurance,
  version = EDIT_INSURANCE_VERSION,
) => {
  const res = apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `${BASE_URL}/${patientId}/${INSURANCE_PATH}`,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patientInsurance),
    updateNotificationDetails: { insurance: { patientId } },
  });

  return res;
};

export const getPatientInsurances = (
  patientId,
  queryParams = {},
  version = GET_INSURANCE_VERSION,
  optOutLoadingIndicator = false,
) => {
  const patientInsurances = apiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/${INSURANCE_PATH}`,
    queryParams,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator,
    cacheKey: `${BASE_URL}/${patientId}/${INSURANCE_PATH}-${JSON.stringify(
      queryParams,
    )}`,
  });

  return patientInsurances;
};

export const getPatientInsurancesCount = (
  patientId,
  queryParams = {},
  version = GET_INSURANCE_VERSION,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/${INSURANCE_PATH}/count`,
    queryParams,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator,
  });

export const getPatientInsurance = (
  patientId,
  id,
  version = GET_INSURANCE_VERSION,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/${INSURANCE_PATH}/${id}`,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `${patientId}-${id}-${version}-patient-insurance`,
    optOutLoadingIndicator,
  });

export const getMaxVisits = patientId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/max-visits`,
    version: 2,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const putPatientInsurance = async (patientInsurance, version = 3) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${BASE_URL}/${patientInsurance.patientId}/${INSURANCE_PATH}/${
      patientInsurance.id
    }`,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patientInsurance),
    updateNotificationDetails: {
      insurance: { patientId: patientInsurance.patientId },
    },
  });

  return {
    insurance: res.data[0],
    newBillType: res.newBillType,
    caseOverrideUpdated: res.caseOverrideUpdated,
  };
};

export const getAdditionalInsuranceInfo = async (patientId, insuranceId) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_URL}/${patientId}/${INSURANCE_PATH}/additional-info`,
    version: 1,
    headers: {
      'Content-Type': 'application/json',
    },
    queryParams: {
      insuranceId,
    },
  });

  return res.data[0];
};
