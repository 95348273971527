import { html, css, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_CAPTION,
  CSS_BORDER_GREY_1,
  CSS_WARNING_COLOR,
  CSS_WARNING_BACKGROUND_COLOR,
  CSS_COLOR_ERROR,
  CSS_ERROR_BACKGROUND_COLOR,
} from '../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  diagnosesCell: { id: 'diagnoses-cell' },
  problemText: { id: 'problem-text' },
};

const DIAGNOSES_LABELS = new Array(12)
  .fill()
  .map((_, index) => `${String.fromCharCode(65 + index)}.`);

const RECOMMENDED = 'Recommended';
const EMPTY = ' ';

class NebClaimFormCellFL21 extends LitElement {
  static get properties() {
    return {
      problemText: {
        type: String,
        reflect: true,
        converter: {
          toAttribute: v => v || null,
        },
      },
      diagnoses: Array,
    };
  }

  constructor() {
    super();
    this.__initState();
  }

  __initState() {
    this.problemText = '';
    this.diagnoses = [];
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container-recommended {
          border: 1px solid ${CSS_WARNING_COLOR};
          background-color: ${CSS_WARNING_BACKGROUND_COLOR};
        }

        .problem-text-recommended {
          color: ${CSS_WARNING_COLOR};
        }

        .container-error {
          border: 1px solid ${CSS_COLOR_ERROR};
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
        }

        .problem-text-error {
          color: ${CSS_COLOR_ERROR};
        }

        .container {
          display: grid;
          padding: 0 10px 10px 20px;
          grid-template-rows: 40px 1fr;
          border-right: ${CSS_BORDER_GREY_1};
          border-bottom: ${CSS_BORDER_GREY_1};
        }

        .header {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          font-size: ${CSS_FONT_SIZE_CAPTION};
        }

        .diagnoses {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(3, 1fr);
          margin-top: 10px;
        }

        .label {
          padding-right: ${CSS_SPACING};
        }

        .diagnoses-cell {
          display: flex;
        }

        .header-cell {
          display: flex;
          align-items: center;
        }

        .problem-text {
          height: 20px;
        }
      `,
    ];
  }

  __formatDiagnosis(diagnosis) {
    return diagnosis ? diagnosis.replace(/\./g, '') : '';
  }

  __renderHeader() {
    return html`
      <div class="header">
        <div class="header-cell">
          21. Diagnosis or Nature of Illness or Injury
        </div>
        <div class="header-cell">ICD Indicator 0</div>
      </div>
    `;
  }

  __renderDiagnoses() {
    return html`
      <div id="${ELEMENTS.diagnosesCell.id}" class="diagnoses">
        ${
          DIAGNOSES_LABELS.map(
            (label, index) =>
              html`
                <div class="diagnoses-cell">
                  <div class="label">${label}</div>
                  <div>${this.__formatDiagnosis(this.diagnoses[index])}</div>
                </div>
              `,
          )
        }
      </div>
    `;
  }

  render() {
    const problemTextContainer = {
      'container-recommended': this.problemText === RECOMMENDED,
      'container-error': this.problemText === EMPTY,
    };

    const problemText = {
      'problem-text-recommended': this.problemText === RECOMMENDED,
      'problem-text-error': this.problemText === EMPTY,
    };

    return html`
      <div class="${classMap(problemTextContainer)} container">
        ${this.__renderHeader()}${this.__renderDiagnoses()}
        ${
          this.problemText
            ? html`
                <div
                  id="${ELEMENTS.problemText.id}"
                  class="${classMap(problemText)}"
                >
                  ${this.problemText}
                </div>
              `
            : ''
        }
      </div>
    `;
  }
}

window.customElements.define('neb-claim-form-cell-fl-21', NebClaimFormCellFL21);
