import '../../../../src/components/misc/neb-icon';
import './neb-table-header';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_FONT_SIZE_BODY,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  header: {
    id: 'table-header',
  },
  noContentSlot: {
    id: 'no-content-slot',
  },
  rows: {
    selector: '.row',
  },
};

class NebTableReadonly extends LitElement {
  static get properties() {
    return {
      sortParams: Object,
      params: Array,
      config: Array,
      model: Array,
      showDetailArrow: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.showDetailArrow = false;
    this.layout = '';
    this.config = [];
    this.model = [];
    this.params = {};
    this.sortParams = this.params;

    this.onSelect = () => {};

    this.onSort = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      rowSelected: e => {
        const index = Number(e.currentTarget.index);
        const item = this.model[index];
        this.onSelect(index, item);
      },
      sort: params => this.onSort(params),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;

          --header-padding: 8px;
          --cell-spacing: ${CSS_SPACING};
        }

        .container {
          display: flex;
          flex-flow: nowrap column;
        }

        .row {
          display: flex;
          padding: 18px var(--cell-spacing);
          min-height: 30px;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
          font-size: ${CSS_FONT_SIZE_BODY};
          align-items: baseline;
        }

        .row[clickable] {
          cursor: pointer;
        }

        .cell {
          display: flex;
          width: 0;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          white-space: pre-line;
          word-break: break-all;
        }

        .cell:last-child {
          margin-right: 0;
        }

        .cell[truncate] {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .cell-icon {
          width: 12px;
          height: 12px;
          fill: ${CSS_COLOR_GREY_1};
          transform: rotate(-90deg);
        }

        .container-no-content {
          margin: ${CSS_SPACING} var(--cell-spacing);
        }

        .text-no-items {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }
      `,
    ];
  }

  __renderRow(item, index) {
    const rowCells = this.config.map(
      cell => html`
        <span
          class="cell"
          style="flex: ${cell.flex}"
          ?truncate="${cell.truncate}"
          >${
            cell.formatter
              ? cell.formatter(item[cell.key], item)
              : item[cell.key]
          }</span
        >
      `,
    );
    return html`
      <div
        class="row"
        .index="${index}"
        ?clickable="${this.showDetailArrow}"
        @click="${this.__handlers.rowSelected}"
      >
        ${
          this.showDetailArrow
            ? [
                ...rowCells,
                html`
                  <neb-icon
                    class="cell cell-icon"
                    icon="neb:chevron"
                  ></neb-icon>
                `,
              ]
            : rowCells
        }
      </div>
    `;
  }

  __renderContent() {
    return this.model.length
      ? this.model.map((item, index) => this.__renderRow(item, index))
      : html`
          <div class="container-no-content">
            <slot
              id="${ELEMENTS.noContentSlot.id}"
              class="text-no-items"
            ></slot>
          </div>
        `;
  }

  render() {
    return html`
      <div class="container">
        <neb-table-header
          id="${ELEMENTS.header.id}"
          .layout="${this.layout}"
          .config="${this.config}"
          .sortParams="${this.sortParams}"
          .onSort="${this.__handlers.sort}"
          ?showDetailIcon="${this.showDetailArrow}"
        ></neb-table-header>

        ${this.__renderContent()}
      </div>
    `;
  }
}

customElements.define('neb-table-readonly', NebTableReadonly);
