import equal from 'fast-deep-equal';
import { css, html, LitElement } from 'lit';

import { getProviderAppointments } from '../../../../packages/neb-api-client/src/appointment-api-client';
import { getAppointmentTypes } from '../../../../packages/neb-api-client/src/appointment-types';
import * as patientApiClient from '../../../../packages/neb-api-client/src/patient-api-client';
import { getPatientImage } from '../../../../packages/neb-api-client/src/patient-image-api-client';
import { getAccount } from '../../../../packages/neb-api-client/src/permissions-api-client';
import { getActiveProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import { fetchManyRooms } from '../../../../packages/neb-api-client/src/rooms-api-client';
import { forceFetchAppointments } from '../../../../packages/neb-calendar/neb-appointments-state';
import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { computeTime } from '../../../../packages/neb-input/nebFormatUtils';
import { APPOINTMENT_ACTIONS } from '../../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-options';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import '../../../../packages/neb-lit-components/src/components/controls/neb-pill-switch';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import { openMatchPatientAccountPopup } from '../../../../packages/neb-lit-components/src/utils/patients';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_BLUE_BORDER,
  CSS_COLOR_WHITE,
  CSS_FONT_FAMILY,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import { roomConflictOverridePopup } from '../../../../packages/neb-utils/calendar-resources-util';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { mapAppointment } from '../../../../packages/neb-utils/formatters';
import { formatDate } from '../../../../packages/neb-utils/moment-flag-conversion';
import { roundUpTo5MinuteIncrement } from '../../../../packages/neb-utils/neb-cal-util';
import { SERVICE_TIME_FORMAT } from '../../../../packages/neb-utils/neb-charting-util';
import { updateAppointmentRoom } from '../../../api-clients/appointments';
import * as insuranceStatusApi from '../../../api-clients/insurance-status';
import { openOverlayCheckInOut } from '../../../features/check-in-out/utils/open-overlay-check-in-out';
import { CSS_COLOR_HIGHLIGHT, layoutStyles } from '../../../styles';
import { ADD_ONS, hasAddOn } from '../../../utils/add-ons';
import { clearContexts } from '../../../utils/context/constants';
import { PollController } from '../../../utils/poll-controller';
import { SELECT_BY_TYPE } from '../../../utils/scheduling/appointments';
import { ROOM_CHECKED_IN_ERROR } from '../../../utils/user-message';
import '../../lists/scheduling/neb-list-drag-drop';
import '../../misc/neb-icon';
import NebFormAppointment from '../../forms/appointments/neb-form-appointment';

export const ELEMENTS = {
  date: { id: 'date' },
  roomsSelect: { id: 'select-rooms' },
  locationsSelect: { id: 'select-locations' },
  providersSelect: { id: 'select-providers' },
  scheduleRoomSelect: { id: 'select-scheduled-room' },
  activeList: { id: 'list-active' },
  roomList: { id: 'list-room' },
  walkInButton: { id: 'walk-in-button' },
  pillSwitch: { id: 'pill-switch' },
  filters: { id: 'filters' },
};

export const NO_ROOM_SCHEDULED = {
  label: 'No Room Scheduled',
  data: {
    id: null,
  },
};

export const NO_PROVIDER_SCHEDULED = {
  label: 'No Provider Scheduled',
  data: {
    id: null,
  },
};

const DEFAULT_APPOINTMENT_TYPE = {
  name: '',
  color: '',
};

const PILL_SWITCH_LABELS = {
  leftSwitch: 'Appointments',
  rightSwitch: 'Rooms List',
};

const uniq = arr => [...new Set(arr)];

const SUCCESS_MESSAGE = 'Online Booking Account linked';

export const STATUS = {
  ACTIVE: { value: 'Active' },
  CHECKED_IN: { value: 'Checked-In' },
};

const DRAG_ACTIONS = {
  UPDATE_ROOM_ID: 'updateRoomId',
  OPEN_CHECK_IN: 'checkIn',
  OPEN_APPT_DETAILS: 'apptDetails',
};

export function getSelectedRoomsKey(tenantId) {
  return `rooms-selected-rooms:${tenantId}`;
}

export function getSelectedScheduledRoomsKey(tenantId) {
  return `rooms-selected-scheduled-rooms:${tenantId}`;
}

export function getSelectedLocationsKey(tenantId) {
  return `rooms-selected-locations:${tenantId}`;
}

export function getSelectedViewKey(tenantId) {
  return `rooms-selected-view:${tenantId}`;
}

export function getSelectedProvidersKey(tenantId) {
  return `rooms-selected-providers:${tenantId}`;
}

function createModelAppointments() {
  return {
    [STATUS.ACTIVE.value]: [],
    [STATUS.CHECKED_IN.value]: [],
  };
}

function computeDurationDisplay(startDate, endDate) {
  const start = startDate.format('h:mm A');
  const end = endDate.format('h:mm A');
  const duration = computeTime(endDate.diff(startDate));
  const durationDisplay = `${start} - ${end} (${duration})`;

  return durationDisplay;
}

function setRealTimeEligibilityStatus(
  hasAddOnCTVerify,
  insuranceStatuses,
  appointment,
) {
  let realTimeEligibilityStatus = null;

  if (hasAddOnCTVerify) {
    const statusResult = insuranceStatuses.find(
      s => s.appointmentId === appointment.id,
    );

    if (statusResult && statusResult.status) {
      ({ status: realTimeEligibilityStatus } = statusResult);
    }
  }
  return realTimeEligibilityStatus;
}

function filterCheckedInNoRooms(apptRooms) {
  return apptRooms[STATUS.CHECKED_IN.value].filter(appt => !appt.roomId);
}

function filterScheduledApptRooms(apptRooms, selectedScheduledRooms) {
  return apptRooms[STATUS.ACTIVE.value].filter(appt => {
    let valid = selectedScheduledRooms.some(
      room => room.data.id === appt.resourceId,
    );

    if (appt.splits && appt.splits.length) {
      const selectedRoomIds = selectedScheduledRooms.map(room => room.data.id);

      if (
        appt.splits.some(split => selectedRoomIds.includes(split.resourceId))
      ) {
        valid = true;
      }
    }

    return valid;
  });
}

class NebSchedulingRooms extends LitElement {
  static get properties() {
    return {
      __date: String,
      __apptRooms: Object,
      __patients: Object,
      __potentialMatches: Object,
      __appointments: Array,
      __appointmentTypes: Array,
      __rooms: Array,
      __roomIndexIdMap: Object,
      __providers: Array,
      __userLocations: Array,
      __checkInRooms: Array,
      __scheduledRooms: Array,
      __selectedRooms: Array,
      __selectedScheduledRooms: Array,
      __selectedProviders: Array,
      __selectedLocation: Object,
      __toggleAppts: Boolean,
      __toggleList: Boolean,
      __toggleFilters: { type: Boolean, reflect: true },

      tenantId: String,
      layout: { type: String, reflect: true },
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        :host([layout='medium']) {
          background: ${CSS_COLOR_WHITE};
        }

        .date {
          color: ${CSS_COLOR_BLUE_BORDER};
          background: ${CSS_COLOR_WHITE};
          font: ${CSS_FONT_WEIGHT_BOLD} ${CSS_FONT_SIZE_HEADER}
            ${CSS_FONT_FAMILY};
        }

        .container {
          display: grid;
          grid-gap: ${CSS_SPACING};
          background: ${CSS_COLOR_WHITE};
          padding-top: ${CSS_SPACING};
          grid-auto-rows: min-content;
          width: 100%;
          height: 100%;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(6, auto) 1fr;
          padding: ${CSS_SPACING};
        }

        :host([layout='large']) .container {
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: auto 1fr auto;
          padding: ${CSS_SPACING};
        }

        :host([layout='small']) .container {
          grid-template-rows: repeat(20, auto);
        }

        .list-row {
          grid-row: span 5;
        }

        .large-list-row {
          overflow-y: auto;
          grid-row: span 2;
        }

        :host([layout='large']) .large-list-row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: ${CSS_SPACING};
          grid-area: 2 / 2 / 4 / 4;
          overflow-y: auto;
          grid-template-rows: repeat(auto-fit, minmax(250px, 28px));
        }

        .list-room {
          height: 230px;
          padding-bottom: ${CSS_SPACING};
        }

        .list-room:last-child {
          padding-bottom: unset;
        }

        .large-list-room {
          height: 250px;
        }

        .medium-list-room {
          height: 250px;
          padding-bottom: ${CSS_SPACING};
        }

        .medium-list-room:last-child {
          padding-bottom: unset;
        }

        .list {
          min-height: 230px;
          grid-row: span 19;
        }

        .large-list {
          grid-row: span 2;
        }

        .centered {
          grid-column: span 2;
          justify-self: center;
        }

        .span-2 {
          grid-column: span 2;
        }

        .end {
          grid-column-start: 2;
        }

        .start {
          grid-column: span 2;
          justify-self: start;
        }

        .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(0deg);
          height: 10px;
          width: 10px;
          margin: 0 6px;
        }

        :host([__toggleFilters]) .icon {
          transform: rotate(180deg);
        }

        .filters {
          color: ${CSS_COLOR_BLUE_BORDER};
          background: ${CSS_COLOR_WHITE};
          font: ${CSS_FONT_FAMILY};
          cursor: pointer;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initServices();
    this.__initHandlers();
  }

  __initState() {
    this.__date = parseDate();
    this.__apptRooms = createModelAppointments();
    this.__patients = {};
    this.__potentialMatches = {};
    this.__appointments = [];
    this.__appointmentTypes = [];
    this.__rooms = [];
    this.__roomIndexIdMap = {};
    this.__providers = [];
    this.__userLocations = [];
    this.__checkInRooms = [];
    this.__scheduledRooms = [];
    this.__selectedRooms = [];
    this.__selectedScheduledRooms = [];
    this.__selectedProviders = [];
    this.__selectedLocation = { data: { id: '' }, label: '' };
    this.__toggleAppts = false;
    this.__toggleList = true;
    this.__toggleFilters = false;

    this.tenantId = '';
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__userLocations = userLocations
        .filter(item => item.active)
        .map(data => ({
          label: `${data.name}`,
          data,
        }));

      this.__selectedLocation =
        this.__getSelectedLocationsFromLocalStorage() ||
        this.__userLocations[0];
    });

    this.__pollController = new PollController(this, async () => {
      await this.__loadAppointments(false, true);
    });
  }

  __initHandlers() {
    this.__handlers = {
      addWalkIn: async () => {
        const time = roundUpTo5MinuteIncrement(parseDate());
        const start = parseDate()
          .hours(time.hour)
          .minutes(time.min)
          .toISOString();

        await openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, {
          ...NebFormAppointment.createModel(),
          start,
          type: SELECT_BY_TYPE.CUSTOM,
          walkIn: true,
        });

        this.__loadAppointments();
      },
      displayCard: appointment => {
        if (this.__patients[appointment.patient.id]) {
          this.__getProfilePicture(appointment);
        }
      },
      selectAppointment: async item => {
        const start = parseDate(item.start);

        const appointment = {
          ...item,
          id: item.appointmentId || item.id,
          start,
          details: {
            startDisplayDate: start.format('dddd, MMMM DD, YYYY'),
            durationDisplay: computeDurationDisplay(start, parseDate(item.end)),
            location: item.locationName,
          },
        };

        const patientId = appointment.patientId || appointment.patient.id;

        if (!patientId) {
          await this.__openPatientMatchPopup(appointment);

          return;
        }

        await openOverlay(OVERLAY_KEYS.APPOINTMENT_PAGE, {
          appointmentId: item.appointmentId || item.id,
        });

        this.__loadAppointments();
      },
      changeTitle: () => {
        this.__toggleAppts = !this.__toggleAppts;
        localStorage.setItem(
          getSelectedViewKey(this.tenantId),
          this.__toggleAppts,
        );

        this.__loadAppointments();
      },
      checkInRoom: async item => {
        const start = parseDate(item.start);

        const appointment = {
          ...item,
          id: item.appointmentId || item.id,
          start,
          details: {
            startDisplayDate: start.format('dddd, MMMM DD, YYYY'),
            durationDisplay: computeDurationDisplay(start, parseDate(item.end)),
            location: item.locationName,
          },
        };

        const patientId = appointment.patientId || appointment.patient.id;

        if (!patientId) {
          await this.__openPatientMatchPopup(appointment);

          return;
        }

        const actionTaken = this.__getDragAction(item);
        const room = this.__roomIndexIdMap[item.to];

        await this.__executeDragAction(
          actionTaken,
          appointment,
          patientId,
          room,
        );

        this.__loadAppointments();
      },
      selectScheduledRooms: e => {
        if (e.value && equal(e.value, this.__selectedScheduledRooms)) {
          return;
        }

        this.__selectedScheduledRooms = e.value;

        if (e.value && e.value.length) {
          localStorage.setItem(
            getSelectedScheduledRoomsKey(this.tenantId),
            JSON.stringify(e.value),
          );
        }

        this.__loadAppointments();
      },
      updateLocation: ({ value }) => {
        if (this.__selectedLocation !== value) {
          this.__selectedLocation = { ...value };

          if (value) {
            localStorage.setItem(
              getSelectedLocationsKey(this.tenantId),
              JSON.stringify(value),
            );
          }

          this.__loadRooms();
          this.__loadAppointments();
        }
      },
      selectRooms: e => {
        if (e.value && equal(e.value, this.__selectedRooms)) {
          return;
        }

        this.__selectedRooms = e.value;

        this.__loadAppointments();

        if (e.value && e.value.length) {
          localStorage.setItem(
            getSelectedRoomsKey(this.tenantId),
            JSON.stringify(e.value),
          );
        }

        this.__roomIndexIdMap = this.__selectedRooms.reduce((acc, room) => {
          const key = room.data.id;

          return { ...acc, [key]: room.data };
        }, {});
      },
      selectProviders: e => {
        if (e.value && equal(e.value, this.__selectedProviders)) {
          return;
        }

        this.__selectedProviders = e.value;

        if (e.value && e.value.length) {
          localStorage.setItem(
            getSelectedProvidersKey(this.tenantId),
            JSON.stringify(e.value),
          );
        }

        this.__loadAppointments();
      },
      toggleFilters: () => {
        this.__toggleFilters = !this.__toggleFilters;
      },
      togglePillSwitch: onOff => {
        this.__toggleList = onOff;
      },
      quickAction: () => {
        this.__loadAppointments();
      },
      openPotentialMatch: appt => {
        this.__openPatientMatchPopup(appt);
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__appointmentTypes = (await getAppointmentTypes()).data;

    this.__locationsService.connect();

    await this.__loadTenantMeta();

    await this.__loadProviders(true);

    await this.__loadRooms(true);

    await this.__loadAppointments(true);
  }

  disconnectedCallback() {
    try {
      this.__locationsService.disconnect();
    } catch (_) {
      // noop
    }

    super.disconnectedCallback();
  }

  updated(changedProps) {
    if (changedProps.has('layout') && this.layout !== 'large') {
      this.__loadAppointments();
      this.__resetToggles();
    }
  }

  async __loadTenantMeta() {
    this.__hasAddOnCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
  }

  async __openPatientMatchPopup(appt) {
    try {
      if (this.__potentialMatches[appt.accountId]) {
        const result = await openMatchPatientAccountPopup(
          this.__potentialMatches[appt.accountId].cognitoAccount,
          this.__potentialMatches[appt.accountId].accountMatches,
          appt.id,
        );

        if (result && result.data && result.data[0].bookingAccountId) {
          this.__potentialMatches[appt.accountId] = null;

          store.dispatch(openSuccess(SUCCESS_MESSAGE));
        }
      }
    } catch (e) {
      // noop
    }

    this.__loadAppointments();
  }

  __getAppointmentType(appointment) {
    return (
      this.__appointmentTypes.find(
        type => type.id === appointment.appointmentTypeId,
      ) || DEFAULT_APPOINTMENT_TYPE
    );
  }

  __getAppointmentProvider(appointment) {
    return this.__selectedProviders.find(
      p => p.data.id === appointment.providerId,
    );
  }

  async __loadPatients(ids) {
    const query = { includeBooking: true, includeImage: true };
    const patients = await patientApiClient.fetchSome(ids, query);

    this.__patients = {
      ...this.__patients,
      ...patients.reduce((acc, patient) => {
        acc[patient.id] = patient;

        if (patient.bookingAccountId) {
          acc[patient.bookingAccountId] = acc[patient.id];
        }

        return acc;
      }, {}),
    };
  }

  __getApptViewFromLocalStorage() {
    const apptView = localStorage.getItem(getSelectedViewKey(this.tenantId));

    return apptView === 'true';
  }

  __getProvidersFromLocalStorage() {
    const storedProviders = JSON.parse(
      localStorage.getItem(getSelectedProvidersKey(this.tenantId)),
    );

    const providers =
      storedProviders && storedProviders.length
        ? storedProviders.filter(stored =>
            this.__providers.some(
              current => current.data.id === stored.data.id,
            ),
          )
        : [];

    return providers.length ? providers : [...this.__providers];
  }

  __getScheduledRoomsFromLocalStorage() {
    const storedRooms = JSON.parse(
      localStorage.getItem(getSelectedScheduledRoomsKey(this.tenantId)),
    );

    const rooms =
      storedRooms && storedRooms.length
        ? storedRooms.filter(stored =>
            this.__scheduledRooms.some(
              current => current.data.id === stored.data.id,
            ),
          )
        : [];

    return rooms.length ? rooms : [...this.__scheduledRooms];
  }

  __getSelectedRoomsFromLocalStorage() {
    const storedRooms = JSON.parse(
      localStorage.getItem(getSelectedRoomsKey(this.tenantId)),
    );

    const rooms =
      storedRooms && storedRooms.length
        ? storedRooms.filter(stored =>
            this.__checkInRooms.some(
              current => current.data.id === stored.data.id,
            ),
          )
        : [];

    return rooms.length ? rooms : [...this.__checkInRooms];
  }

  __getSelectedLocationsFromLocalStorage() {
    const storedLocation = JSON.parse(
      localStorage.getItem(getSelectedLocationsKey(this.tenantId)),
    );

    const validLocation =
      storedLocation &&
      storedLocation.data.id &&
      this.__userLocations.some(loc => loc.data.id === storedLocation.data.id);

    return validLocation ? storedLocation : false;
  }

  __getDragAction(item) {
    let action;

    if (this.__roomIndexIdMap[item.to]) {
      action =
        item.status === 'Active'
          ? DRAG_ACTIONS.OPEN_CHECK_IN
          : DRAG_ACTIONS.UPDATE_ROOM_ID;
    } else {
      action = this.__toggleAppts
        ? DRAG_ACTIONS.UPDATE_ROOM_ID
        : DRAG_ACTIONS.OPEN_APPT_DETAILS;
    }

    return action;
  }

  async __executeDragAction(actionTaken, appointment, patientId, room) {
    switch (actionTaken) {
      case DRAG_ACTIONS.OPEN_CHECK_IN:
        await openOverlayCheckInOut(OVERLAY_KEYS.CHECK_IN_OUT, {
          appointment: {
            ...appointment,
            patientId,
            roomId: room.id,
          },
          patientId,
          mode: 'checkIn',
        });

        break;

      case DRAG_ACTIONS.UPDATE_ROOM_ID:
        await this.__validateAndUpdateRoomId(
          appointment.id,
          room ? room.id : null,
        );

        break;

      case DRAG_ACTIONS.OPEN_APPT_DETAILS:
        await openOverlay(OVERLAY_KEYS.APPOINTMENT_PAGE, {
          appointmentId: appointment.id,
        });

        break;

      default:
    }
  }

  __getPatientImage(patient) {
    return patient.hasPhoto && !patient.photoSrc
      ? getPatientImage(patient.id, 'small', true)
      : patient.photoSrc;
  }

  async __getProfilePicture(appointment) {
    const { patient, status } = appointment;
    const photoSrc = await this.__getPatientImage(patient);

    this.__patients[patient.id] = patient;
    this.__patients[patient.id].photoSrc = photoSrc;
    this.__patients = { ...this.__patients };

    const selectedIndex = this.__apptRooms[status].findIndex(
      appt => appt.id === appointment.id,
    );

    this.__apptRooms[status][selectedIndex].patient.photoSrc = photoSrc;
    this.__apptRooms[status] = [...this.__apptRooms[status]];
  }

  __formatPatient(appointment) {
    const patientId = appointment.patientId || appointment.accountId;

    const patient = this.__patients[patientId] || {
      name: { first: appointment.firstName, last: appointment.lastName },
    };

    return patient;
  }

  __formatApptForRoom(appointment, insuranceStatuses = []) {
    const patient = this.__formatPatient(appointment);
    const start = parseDate(appointment.start);
    const end = parseDate(appointment.end);
    const duration = computeTime(end.valueOf() - start.valueOf());
    const provider = this.__getAppointmentProvider(appointment);
    const type = this.__getAppointmentType(appointment);
    const location = this.__userLocations.find(
      l => l.data.id === appointment.locationId,
    );
    const resource = this.__rooms.find(
      r => r.data.id === appointment.resourceId,
    );
    const room = this.__rooms.find(r => r.data.id === appointment.roomId);

    const realTimeEligibilityStatus = setRealTimeEligibilityStatus(
      this.__hasAddOnCTVerify,
      insuranceStatuses,
      appointment,
    );

    return {
      ...appointment,
      ...(appointment.encounter || { signed: false }),
      patient,
      duration,
      provider: provider.label,
      appointmentType: type.name,
      markerColor: type.color,
      formattedServiceTime: start.format(SERVICE_TIME_FORMAT),
      locationName: location ? location.data.name : '',
      realTimeEligibilityStatus,
      resource: resource ? resource.data : null,
      room: room ? room.data : null,
    };
  }

  async __loadProviders(initialLoad = false) {
    const providers = await getActiveProviderUsers();

    this.__providers = providers.map(data => ({
      label: `${data.lastName}, ${data.firstName}`,
      data,
    }));

    this.__providers = [NO_PROVIDER_SCHEDULED, ...this.__providers];

    this.__selectedProviders = initialLoad
      ? this.__getProvidersFromLocalStorage()
      : [...this.__providers];
  }

  async __validatePatients(appointments) {
    const appointmentsMissingPatients = appointments.filter(appt => {
      if (appt.patientId) {
        return !this.__patients[appt.patientId];
      }

      if (appt.patient) {
        return !this.__patients[appt.patient.id];
      }

      if (appt.accountId) {
        return !(
          this.__patients[appt.accountId] ||
          this.__potentialMatches[appt.accountId]
        );
      }

      return true;
    });

    appointmentsMissingPatients.forEach(async appt => {
      if (appt.accountId) {
        if (
          !this.__patients[appt.accountId] &&
          !this.__potentialMatches[appt.accountId]
        ) {
          try {
            const cognitoAccount = await getAccount(appt.accountId);

            if (cognitoAccount) {
              const accountMatches = await patientApiClient.getPotentialMatches(
                cognitoAccount,
              );

              this.__potentialMatches[appt.accountId] = {
                cognitoAccount,
                accountMatches,
              };
            } else {
              this.__potentialMatches[appt.accountId] = {
                cognitoAccount: null,
                accountMatches: [],
              };
            }
          } catch (e) {
            // noop
          }
        }
      }
    });

    if (appointmentsMissingPatients.length) {
      const patientIds = appointmentsMissingPatients
        .map(appt => appt.patientId || appt.accountId)
        .filter(item => item);

      await this.__loadPatients(uniq(patientIds));
    }
  }

  async __validateAndUpdateRoomId(id, roomId) {
    const appointmentAction = APPOINTMENT_ACTIONS.CHANGE_ROOM;

    try {
      if (await roomConflictOverridePopup(roomId)) {
        await updateAppointmentRoom({
          id,
          body: { roomId },
        });

        store.dispatch(
          forceFetchAppointments(store.getState().appointments.targetDate),
        );

        store.dispatch(openSuccess(appointmentAction.successMessage));
      }
    } catch (e) {
      store.dispatch(openError(ROOM_CHECKED_IN_ERROR));
    }
  }

  async __loadRooms(initialLoad = false) {
    const rooms = await fetchManyRooms();

    this.__rooms = rooms
      .filter(
        room =>
          room.locationId === this.__selectedLocation.data.id && room.active,
      )
      .map(data => ({
        label: `${data.name}`,
        data,
      }));

    this.__scheduledRooms = [
      NO_ROOM_SCHEDULED,
      ...this.__rooms.filter(room => room.data.scheduleAvailable),
    ];

    this.__checkInRooms = [
      ...this.__rooms.filter(room => room.data.checkInAvailable),
    ];

    this.__selectedScheduledRooms = initialLoad
      ? this.__getScheduledRoomsFromLocalStorage(this.__scheduledRooms)
      : [...this.__scheduledRooms];

    this.__selectedRooms = initialLoad
      ? this.__getSelectedRoomsFromLocalStorage(this.__checkInRooms)
      : [...this.__checkInRooms];

    this.__roomIndexIdMap = this.__selectedRooms.reduce((acc, room) => {
      const key = room.data.id;

      return { ...acc, [key]: room.data };
    }, {});
  }

  async __loadAppointments(
    initialLoad = false,
    optOutLoadingIndicator = false,
  ) {
    clearContexts();

    let insuranceStatuses = [];

    const startOfDay = parseDate(this.__date).startOf('day');
    const startBefore = parseDate(this.__date).endOf('day');
    const filterEnd = parseDate(this.__date)
      .add(1, 'days')
      .endOf('day');

    const res = await getProviderAppointments(
      {
        statuses: ['Active', 'Checked-In'],
        start: startOfDay.format(),
        startBefore: startBefore.format(),
        end: filterEnd.format(),
        expand: 'encounter,splits',
        sortParams: {
          key: 'start',
          dir: 'asc',
        },
        locationId: this.__selectedLocation.data.id,
      },
      optOutLoadingIndicator,
    );

    this.__appointments = res.data.filter(
      appt =>
        !appt.name &&
        Object.values(STATUS)
          .map(v => v.value)
          .includes(appt.status) &&
        appt.locationId === this.__selectedLocation.data.id,
    );

    if (this.__hasAddOnCTVerify) {
      insuranceStatuses = await insuranceStatusApi.fetchMany(
        this.__appointments.map(a => mapAppointment(a)),
        true,
      );
    }

    await this.__validatePatients(this.__appointments);

    this.__apptRooms = this.__appointments.reduce((acc, curr) => {
      if (!this.__getAppointmentProvider(curr)) return { ...acc };
      const key = curr.status;
      const appt = this.__formatApptForRoom(curr, insuranceStatuses);

      return { ...acc, [key]: [...acc[key], appt] };
    }, createModelAppointments());

    if (initialLoad) {
      this.__toggleAppts = this.__getApptViewFromLocalStorage();
    }
  }

  __resetToggles() {
    this.__toggleAppts = false;
    this.__toggleList = true;
    this.__toggleFilters = false;
  }

  __renderTodaysDate() {
    return formatDate(false, this.__date, 'dddd, MMMM D, YYYY');
  }

  __renderLargeHeader() {
    return this.layout === 'large'
      ? html`
          <div class="grid grid-2 grid-lean">
            <div id="${ELEMENTS.date.id}" class="date">
              ${this.__renderTodaysDate()}
            </div>
            <neb-select
              id="${ELEMENTS.scheduleRoomSelect.id}"
              class="end"
              placeholder="No Scheduled Rooms Selected"
              allLabel="Scheduled Rooms"
              label="Scheduled Rooms"
              .items="${this.__scheduledRooms}"
              .value="${this.__selectedScheduledRooms}"
              .onChange="${this.__handlers.selectScheduledRooms}"
              multiSelect
              pinLabel
            ></neb-select>
          </div>

          <div class="grid grid-2 grid-lean">
            ${
              html`
                <neb-select
                  id="${ELEMENTS.locationsSelect.id}"
                  class="end"
                  label="Location"
                  .items="${this.__userLocations}"
                  .value="${this.__selectedLocation}"
                  .onChange="${this.__handlers.updateLocation}"
                  pinLabel
                ></neb-select>
              `
            }
          </div>

          <div class="grid grid-2 grid-lean">
            ${
              html`
                <neb-select
                  id="${ELEMENTS.roomsSelect.id}"
                  placeholder="No Rooms Selected"
                  allLabel="Rooms"
                  label="Check In Rooms"
                  .items="${this.__checkInRooms}"
                  .value="${this.__selectedRooms}"
                  .onChange="${this.__handlers.selectRooms}"
                  multiSelect
                  pinLabel
                ></neb-select>
              `
            }

            <neb-select
              id="${ELEMENTS.providersSelect.id}"
              placeholder="No Providers Selected"
              allLabel="Providers"
              label="Providers"
              .items="${this.__providers}"
              .value="${this.__selectedProviders}"
              .onChange="${this.__handlers.selectProviders}"
              multiSelect
              pinLabel
            ></neb-select>
          </div>
        `
      : html`
          <div class="centered date"><b> ${this.__renderTodaysDate()}</b></div>

          <neb-button-action
            id="${ELEMENTS.walkInButton.id}"
            class="span-2"
            label="Add Walk-In Patient"
            .onClick="${this.__handlers.addWalkIn}"
          ></neb-button-action>

          <neb-select
            id="${ELEMENTS.providersSelect.id}"
            class="span-2"
            placeholder="No Providers Selected"
            allLabel="Providers"
            label="Providers"
            .items="${this.__providers}"
            .value="${this.__selectedProviders}"
            .onChange="${this.__handlers.selectProviders}"
            multiSelect
            pinLabel
          ></neb-select>

          <neb-select
            id="${ELEMENTS.locationsSelect.id}"
            class="span-2"
            label="Location"
            .items="${this.__userLocations}"
            .value="${this.__selectedLocation}"
            .onChange="${this.__handlers.updateLocation}"
            pinLabel
          ></neb-select>

          <neb-select
            id="${ELEMENTS.scheduleRoomSelect.id}"
            placeholder="No Scheduled Rooms Selected"
            allLabel="Scheduled Rooms"
            label="Scheduled Rooms"
            .items="${this.__scheduledRooms}"
            .value="${this.__selectedScheduledRooms}"
            .onChange="${this.__handlers.selectScheduledRooms}"
            multiSelect
            pinLabel
          ></neb-select>

          <neb-select
            id="${ELEMENTS.roomsSelect.id}"
            placeholder="No Rooms Selected"
            allLabel="Rooms"
            label="Check In Rooms"
            .items="${this.__checkInRooms}"
            .value="${this.__selectedRooms}"
            .onChange="${this.__handlers.selectRooms}"
            multiSelect
            pinLabel
          ></neb-select>
        `;
  }

  __renderSmallHeader() {
    return html`
      <div class="start">
        <neb-pill-switch
          id="${ELEMENTS.pillSwitch.id}"
          ?on="${this.__toggleList}"
          .labels="${PILL_SWITCH_LABELS}"
          .onToggle="${this.__handlers.togglePillSwitch}"
        ></neb-pill-switch>
      </div>

      <neb-button-action
        id="${ELEMENTS.walkInButton.id}"
        label="Add Walk-In Patient"
        .onClick="${this.__handlers.addWalkIn}"
      ></neb-button-action>

      <div
        id="${ELEMENTS.filters.id}"
        class="filters"
        @click="${this.__handlers.toggleFilters}"
      >
        <neb-icon class="icon" icon="neb:chevron"></neb-icon>
        <span> Filters </span>
      </div>
    `;
  }

  __renderSmallFilters() {
    return !this.__toggleFilters
      ? html`
          <neb-select
            id="${ELEMENTS.providersSelect.id}"
            class="span-2"
            placeholder="No Providers Selected"
            allLabel="Providers"
            label="Providers"
            .items="${this.__providers}"
            .value="${this.__selectedProviders}"
            .onChange="${this.__handlers.selectProviders}"
            multiSelect
            pinLabel
          ></neb-select>

          <neb-select
            id="${ELEMENTS.locationsSelect.id}"
            class="span-2"
            label="Location"
            .items="${this.__userLocations}"
            .value="${this.__selectedLocation}"
            .onChange="${this.__handlers.updateLocation}"
            pinLabel
          ></neb-select>

          <neb-select
            id="${ELEMENTS.scheduleRoomSelect.id}"
            class="span-2"
            placeholder="No Scheduled Rooms Selected"
            allLabel="Scheduled Rooms"
            label="Scheduled Rooms"
            .items="${this.__scheduledRooms}"
            .value="${this.__selectedScheduledRooms}"
            .onChange="${this.__handlers.selectScheduledRooms}"
            multiSelect
            pinLabel
          ></neb-select>

          <neb-select
            id="${ELEMENTS.roomsSelect.id}"
            class="span-2"
            placeholder="No Rooms Selected"
            allLabel="Rooms"
            label="Check In Rooms"
            .items="${this.__checkInRooms}"
            .value="${this.__selectedRooms}"
            .onChange="${this.__handlers.selectRooms}"
            multiSelect
            pinLabel
          ></neb-select>
        `
      : html``;
  }

  __getActiveTitle() {
    return this.__toggleAppts
      ? 'Checked-In Without Room'
      : "Today's Appointments";
  }

  __getActiveItems() {
    return this.__toggleAppts
      ? filterCheckedInNoRooms(this.__apptRooms)
      : filterScheduledApptRooms(
          this.__apptRooms,
          this.__selectedScheduledRooms,
        );
  }

  __getOccupancy(room) {
    const currentOccupancy = this.__appointments.filter(
      appt =>
        appt.status === STATUS.CHECKED_IN.value && appt.roomId === room.data.id,
    ).length;
    return [currentOccupancy, room.data.maxOccupancy];
  }

  __renderRoomHeader(room) {
    const [current, max] = this.__getOccupancy(room);
    return `(${current}/${max}) ${room.label}`;
  }

  __maxOccupancyWarning(room) {
    const [current, max] = this.__getOccupancy(room);
    return current >= max;
  }

  __renderLargeActiveList() {
    return html`
      <neb-list-drag-drop
        id="${ELEMENTS.activeList.id}"
        class="large-list"
        header="${this.__getActiveTitle()}"
        .layout="${this.layout}"
        .extend="${true}"
        .items="${this.__getActiveItems()}"
        .onAddWalkIn="${this.__handlers.addWalkIn}"
        .onDisplayCard="${this.__handlers.displayCard}"
        .onChangeTitle="${this.__handlers.changeTitle}"
        .onSelectItem="${this.__handlers.selectAppointment}"
        .onDrop="${this.__handlers.checkInRoom}"
        .onQuickAction="${this.__handlers.quickAction}"
        .onPotentialMatch="${this.__handlers.openPotentialMatch}"
        draggable
        changeable
        simpleDrag
        .showFooter="${this.layout === 'large'}"
      ></neb-list-drag-drop>
      <div id="${ELEMENTS.roomList.id}" class="large-list-row">
        ${
          this.__selectedRooms.map(
            room => html`
              <neb-list-drag-drop
                id="${room.data.id}"
                class="${
                  this.layout === 'medium'
                    ? 'medium-list-room'
                    : 'large-list-room'
                }"
                header="${this.__renderRoomHeader(room)}"
                .layout="${this.layout}"
                .items="${
                  this.__apptRooms[STATUS.CHECKED_IN.value].filter(
                    appt => appt.roomId === room.data.id,
                  )
                }"
                .onDisplayCard="${this.__handlers.displayCard}"
                .onSelectItem="${this.__handlers.selectAppointment}"
                .onDrop="${this.__handlers.checkInRoom}"
                .onQuickAction="${this.__handlers.quickAction}"
                .onPotentialMatch="${this.__handlers.openPotentialMatch}"
                ?warning="${this.__maxOccupancyWarning(room)}"
                draggable
                simpleDrag
              ></neb-list-drag-drop>
            `,
          )
        }
      </div>
    `;
  }

  __renderSmallActiveList() {
    return this.__toggleList
      ? html`
          <neb-list-drag-drop
            id="${ELEMENTS.activeList.id}"
            class="list span-2"
            header="${this.__getActiveTitle()}"
            .layout="${this.layout}"
            .extend="${true}"
            .items="${this.__getActiveItems()}"
            .onDisplayCard="${this.__handlers.displayCard}"
            .onChangeTitle="${this.__handlers.changeTitle}"
            .onSelectItem="${this.__handlers.selectAppointment}"
            changeable
            simpleDrag
          ></neb-list-drag-drop>
        `
      : html`
          <div id="${ELEMENTS.roomList.id}" class="list-row span-2">
            ${
              this.__selectedRooms.map(
                room => html`
                  <neb-list-drag-drop
                    id="${room.data.id}"
                    class="list-room"
                    header="${this.__renderRoomHeader(room)}"
                    .layout="${this.layout}"
                    .items="${
                      this.__apptRooms[STATUS.CHECKED_IN.value].filter(
                        appt => appt.roomId === room.data.id,
                      )
                    }"
                    .onDisplayCard="${this.__handlers.displayCard}"
                    .onSelectItem="${this.__handlers.selectAppointment}"
                    simpleDrag
                  ></neb-list-drag-drop>
                `,
              )
            }
          </div>
        `;
  }

  render() {
    return this.layout === 'small'
      ? html`
          <div class="container">
            ${this.__renderSmallHeader()} ${this.__renderSmallFilters()}
            ${this.__renderSmallActiveList()}
          </div>
        `
      : html`
          <div class="container">
            ${this.__renderLargeHeader()} ${this.__renderLargeActiveList()}
          </div>
        `;
  }
}

customElements.define('neb-scheduling-rooms', NebSchedulingRooms);
