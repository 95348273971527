import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { html, css } from 'lit';

import Table, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  CSS_FIELD_MARGIN,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  patient: { selector: '[id^=patient-]' },
  patientIcon: { selector: '[id^=patientIcon-]' },
  lastAccessed: { selector: '[id^=lastAccessed-]' },
  location: { selector: '[id^=location-]' },
  description: { selector: '[id^=description-]' },
  dateCreated: { selector: '[id^=dateCreated-]' },
};

export const CONFIG = [
  {
    key: 'dateCreated',
    label: 'Date Created',
    flex: css`1 0 0`,
    formatter: d => parseDate(d).format('MM/DD/YYYY'),
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
  },
  {
    key: 'patient',
    label: 'Patient Name',
    flex: css`1 0 0`,
  },
  {
    key: 'location',
    label: 'Location',
    flex: css`1 0 0`,
  },
  {
    key: 'lastAccessed',
    label: 'Last Accessed',
    flex: css`1 0 0`,
    formatter: d => parseDate(d).fromNow(),
  },
];

class NebTableRecentRecords extends Table {
  static get styles() {
    return [
      super.styles,
      css`
        :host {
          padding-top: ${CSS_FIELD_MARGIN};
          display: block;
          width: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        .link-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .row-data {
          cursor: pointer;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.config = CONFIG;
  }

  __renderPatientColumn({ value, rowIndex }) {
    return html`
      <div id="patient-${rowIndex}" class="button" index="${rowIndex}">
        <span class="link-text">${value}</span> ${
          value
            ? html`
                <neb-icon
                  id="patientIcon-${rowIndex}"
                  class="icon"
                  icon="neb:open"
                ></neb-icon>
              `
            : ''
        }
      </div>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    return columnConfig.key === 'patient'
      ? this.__renderPatientColumn({ value, rowIndex })
      : super.renderDataCell(value, columnConfig, rowIndex);
  }
}

window.customElements.define('neb-table-recent-records', NebTableRecentRecords);
