import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { html } from 'lit';

import saveCharges from '../../../packages/neb-api-client/src/services/encounter-charge/save-charges';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';

export const REMOVE_ALLOCATION_CONFIRMATION_POPUP = associatedInvoicesWarning => ({
  title: associatedInvoicesWarning ? 'Warning' : 'Remove Allocation',
  message: html`
    <div>
      Changing the billing details will unallocate all payments for these
      charges.
    </div>
    <br />
    ${associatedInvoicesWarning || ''}
    <div>Are you sure that you want to continue?</div>
  `,
  confirmText: 'Yes',
  cancelText: 'No',
});

export const hasBillingInfoChanged = ({
  checkedIn,
  billingInfo,
  appointmentBillingInfo,
  serverAppointmentBillingInfo,
  multiCarePackage = false,
}) => {
  if (checkedIn && !serverAppointmentBillingInfo) {
    return true;
  }

  const { appointmentId, ...appointmentBillingInfoState } = billingInfo;

  const {
    insuranceGuarantorId,
    ...appointmentBillingInfoModel
  } = appointmentBillingInfo;

  if (multiCarePackage) {
    const {
      patientPackageId: statePackageId,
      ...multiCpState
    } = appointmentBillingInfoState;

    const {
      patientPackageId: modelPackageId,
      ...multiCpModel
    } = appointmentBillingInfoModel;

    return !equal(multiCpState, multiCpModel);
  }

  return !equal(appointmentBillingInfoState, appointmentBillingInfoModel);
};

export const shouldRepost = ({
  checkedIn,
  lineItems,
  billingInfo,
  appointmentBillingInfo,
  serverAppointmentBillingInfo,
  multiCarePackage = false,
}) =>
  lineItems &&
  lineItems.length &&
  hasBillingInfoChanged({
    checkedIn,
    billingInfo,
    appointmentBillingInfo,
    serverAppointmentBillingInfo,
    multiCarePackage,
  });

export const repostPostedEncounterCharges = ({
  charges,
  pristineCharges,
  model,
  billingEncounterCharges,
  signed,
  diagnosesUpdated,
  diagnoses,
}) =>
  saveCharges({
    pristineCharges,
    charges: charges.map(c => ({
      ...c,
      repost: c.posted && !!c.postedToLedgerId,
    })),
    encounterId: model.encounterId,
    patientId: model.patientId,
    billingEncounterCharges,
    signed,
    diagnosesUpdated,
    diagnoses,
  });

export const findLineItemAllocations = lineItems =>
  !!lineItems.find(lineItem =>
    lineItem.lineItemDebits.find(lid => lid.debit.allocations.length),
  );

export const openRemoveAllocationPopup = async (lineItems, warning) => {
  if (!findLineItemAllocations(lineItems)) {
    return true;
  }

  const associatedInvoicesWarning =
    warning && warning.length ? warning[0] : null;

  const res = await openPopup(
    POPUP_RENDER_KEYS.CONFIRM,
    REMOVE_ALLOCATION_CONFIRMATION_POPUP(associatedInvoicesWarning),
  );

  return !!res;
};
