import { LitElement, html, css } from 'lit';

import { DATE_MONTH_FORMAT } from '../../../packages/neb-input/nebFormatUtils';
import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_8,
} from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';

import {
  AGENDA_TYPE_HEADER,
  AGENDA_TYPE_EVENT,
  AGENDA_TYPE_EMPTY,
} from './neb-calendar-agenda-view';

import '../cards/neb-event-card';

export const ELEMENTS = {
  emptyRow: { id: 'empty-row' },
  event: { id: 'event' },
  eventNav: { id: 'event-nav' },
  header: { id: 'header' },
};

class NebCalendarAgendaItem extends LitElement {
  static get properties() {
    return {
      eventItem: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          border-top: 1px solid ${CSS_COLOR_GREY_2};
        }

        .container-header {
          height: 72px;
          background: ${CSS_COLOR_GREY_4};
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          font-weight: bold;
        }

        .container-header,
        .container-empty {
          width: 100%;
          line-height: 72px;
          padding-left: ${CSS_SPACING};
        }

        .container-empty {
          flex-direction: row;
          height: 100%;
        }

        .today {
          background: ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.eventItem = { day: parseDate(), type: AGENDA_TYPE_EMPTY };

    this.onDisplayCard = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      displayCard: e => this.onDisplayCard(e),
    };
  }

  __isToday(date) {
    return parseDate().isSame(date, 'day');
  }

  __isTomorrow(date) {
    return parseDate()
      .add(1, 'day')
      .isSame(date, 'day');
  }

  __isYesterday(date) {
    return parseDate()
      .subtract(1, 'day')
      .isSame(date, 'day');
  }

  __formatYesterdayTodayTomorrowLabel(date) {
    if (this.__isToday(date)) return ' - Today';

    if (this.__isYesterday(date)) return '- Yesterday';

    if (this.__isTomorrow(date)) return '- Tomorrow';

    return '';
  }

  render() {
    switch (this.eventItem.type) {
      case AGENDA_TYPE_HEADER: {
        const { day } = this.eventItem;
        const todayStyle = this.__isToday(day) ? 'today' : '';

        return html`
          <div
            id="${ELEMENTS.header.id}"
            class="container-header ${todayStyle}"
          >
            ${day.format(DATE_MONTH_FORMAT)}
            ${this.__formatYesterdayTodayTomorrowLabel(day)}
          </div>
        `;
      }

      case AGENDA_TYPE_EMPTY:
        return html`
          <div id="${ELEMENTS.emptyRow.id}" class="container-empty">
            No Appointments
          </div>
        `;

      case AGENDA_TYPE_EVENT: {
        let { event } = this.eventItem;

        if (event.groupId) event = { ...event, markerColor: CSS_COLOR_GREY_8 };

        return html`
          <neb-event-card
            id="${ELEMENTS.event.id}"
            layout="small"
            .model="${event}"
            .onDisplay="${this.__handlers.displayCard}"
          ></neb-event-card>
        `;
      }

      default:
        return html``;
    }
  }
}

customElements.define('neb-calendar-agenda-item', NebCalendarAgendaItem);
