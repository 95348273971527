import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../../../../neb-utils/date-util';
import {
  formatCurrency,
  getMatchingServiceTypes,
  isEmpty,
  isMet,
} from '../../../../../utils/insurance-util';

export const ELEMENTS = {
  coverageLevelText: {
    id: 'coverage-level-text',
  },
  inNetworkAmountText: {
    id: 'in-network-amount-text',
  },
  inNetworkRemainingText: {
    id: 'in-network-remaining-text',
  },
  inNetworkStartText: {
    id: 'in-network-start-text',
  },
  inNetworkDeductibleMetText: {
    id: 'in-network-deductible-met-text',
  },
  outOfNetworkAmountText: {
    id: 'out-of-network-amount-text',
  },
  outOfNetworkRemainingText: {
    id: 'out-of-network-remaining-text',
  },
  outOfNetworkStartText: {
    id: 'out-of-network-start-text',
  },
  outOfNetworkDeductibleMetText: {
    id: 'out-of-network-deductible-met-text',
  },
  serviceTypesText: {
    id: 'service-types-text',
  },
  noContentText: {
    id: 'no-content-text',
  },
  isDefaultIndicator: {
    id: 'is-default-indicator',
  },
  title: {
    id: 'title',
  },
};
const SERVICE_TYPES_HEADER = 'Service Types';
export const NO_CONTENT_TEXT = 'There are no deductibles for this plan.';
const IN_NETWORK_AMOUNT_HEADER = 'In Network Amount';
const OUT_OF_NETWORK_AMOUNT_HEADER = 'Out Of Network Amount';
const IN_NETWORK_REMAINING_HEADER = 'In Network Remaining';
const OUT_OF_NETWORK_REMAINING_HEADER = 'Out Of Network Remaining';
const AS_OF_HEADER = 'As of';
export const MET_TEXT = 'Deductible Met';

class NebPatientInsuranceDeductiblesCoverageDetails extends LitElement {
  static get properties() {
    return {
      deductible: {
        type: Object,
      },
      allServiceTypes: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.deductible = {};
    this.allServiceTypes = [];
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .deductible-container {
          padding: 0 10px 10px 10px;
        }

        .deductible-row {
          display: flex;
          justify-content: space-between;
          padding-top: ${CSS_SPACING};
        }

        .flex-one {
          flex: 1;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .italic {
          font-style: italic;
        }

        .red {
          color: #bf3131;
        }

        .panel {
          border-style: solid;
          border-width: 1px;
          border-color: ${CSS_COLOR_GREY_2};
          border-radius: 5px;
          background-color: ${CSS_COLOR_WHITE};
          padding: 10px 0 14px;
          height: 100%;
        }

        .no-content {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: ${CSS_SPACING} 10px 0 10px;
        }

        .pill {
          padding: 0px 10px;
          color: ${CSS_COLOR_WHITE};
          background: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 10px;
          border-radius: 10px;
        }

        .title {
          display: flex;
          padding-left: 10px;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
      `,
    ];
  }

  __renderCurrencyAmount(value) {
    if (isEmpty(value)) return '-';
    return formatCurrency(value);
  }

  __renderInNetworkAmount() {
    return html`
      <div class="bold">${IN_NETWORK_AMOUNT_HEADER}</div>
      <div id="${ELEMENTS.inNetworkAmountText.id}">
        ${this.__renderCurrencyAmount(this.deductible.inNetworkAmount)}
      </div>
    `;
  }

  __renderOutOfNetworkAmount() {
    return html`
      <div class="bold">${OUT_OF_NETWORK_AMOUNT_HEADER}</div>
      <div id="${ELEMENTS.outOfNetworkAmountText.id}">
        ${this.__renderCurrencyAmount(this.deductible.outOfNetworkAmount)}
      </div>
    `;
  }

  __renderInNetworkRemaining() {
    return html`
      <div class="bold">${IN_NETWORK_REMAINING_HEADER}</div>
      <div id="${ELEMENTS.inNetworkRemainingText.id}">
        ${this.__renderCurrencyAmount(this.deductible.inNetworkRemaining)}
      </div>
    `;
  }

  __renderOutOfNetworkRemaining() {
    return html`
      <div class="bold">${OUT_OF_NETWORK_REMAINING_HEADER}</div>
      <div id="${ELEMENTS.outOfNetworkRemainingText.id}">
        ${this.__renderCurrencyAmount(this.deductible.outOfNetworkRemaining)}
      </div>
    `;
  }

  __renderInNetworkStart() {
    if (isEmpty(this.deductible.inNetworkRemaining)) return '';
    return html`
      <div id="${ELEMENTS.inNetworkStartText.id}">
        <span class="italic"
          >${AS_OF_HEADER}
          ${
            parseDate(this.deductible.inNetworkStart).format('MM/DD/YYYY')
          }</span
        >
      </div>
    `;
  }

  __renderOutOfNetworkStart() {
    if (isEmpty(this.deductible.outOfNetworkRemaining)) return '';
    return html`
      <div id="${ELEMENTS.outOfNetworkStartText.id}">
        <span class="italic"
          >${AS_OF_HEADER}
          ${
            parseDate(this.deductible.outOfNetworkStart).format('MM/DD/YYYY')
          }</span
        >
      </div>
    `;
  }

  __renderInNetworkDeductibleMet() {
    if (isMet(this.deductible.inNetworkRemaining)) {
      return html`
        <div
          class="italic bold red"
          id="${ELEMENTS.inNetworkDeductibleMetText.id}"
        >
          ${MET_TEXT}
        </div>
      `;
    }
    return '';
  }

  __renderOutOfNetworkDeductibleMet() {
    if (isMet(this.deductible.outOfNetworkRemaining)) {
      return html`
        <div
          class="italic bold red"
          id="${ELEMENTS.outOfNetworkDeductibleMetText.id}"
        >
          ${MET_TEXT}
        </div>
      `;
    }
    return '';
  }

  __renderInNetwork() {
    return html`
      <div class="flex-one">${this.__renderInNetworkAmount()}</div>

      <div class="flex-one">
        ${this.__renderInNetworkRemaining()} ${this.__renderInNetworkStart()}
        ${this.__renderInNetworkDeductibleMet()}
      </div>
    `;
  }

  __renderOutOfNetwork() {
    return html`
      <div class="flex-one">${this.__renderOutOfNetworkAmount()}</div>

      <div class="flex-one">
        ${this.__renderOutOfNetworkRemaining()}
        ${this.__renderOutOfNetworkStart()}
        ${this.__renderOutOfNetworkDeductibleMet()}
      </div>
    `;
  }

  __renderServiceTypes(serviceTypes) {
    if (!serviceTypes || serviceTypes.length === 0) return '';
    return html`
      <div class="deductible-row bold">${SERVICE_TYPES_HEADER}</div>

      <div id="${ELEMENTS.serviceTypesText.id}">
        ${getMatchingServiceTypes(serviceTypes, this.allServiceTypes)}
      </div>
    `;
  }

  __renderContent() {
    return html`
      <div class="deductible-container">
        <div class="deductible-row">${this.__renderInNetwork()}</div>

        <div class="deductible-row">${this.__renderOutOfNetwork()}</div>

        ${this.__renderServiceTypes(this.deductible.serviceTypes)}
      </div>
    `;
  }

  __renderNoContent() {
    return html`
      <div id="${ELEMENTS.noContentText.id}" class="no-content">
        ${NO_CONTENT_TEXT}
      </div>
    `;
  }

  __renderDefaultIndicator(cst) {
    return cst.isDefault
      ? html`
          <div id="${ELEMENTS.isDefaultIndicator.id}" class="pill">Default</div>
        `
      : '';
  }

  __renderTitle(deductible) {
    const title = `Deductibles${
      deductible.coverageLevel.code === 'NA'
        ? ''
        : ` - ${deductible.coverageLevel.description}`
    }`;

    return html`
      <div id="${ELEMENTS.title.id}" class="title">
        ${title} ${this.__renderDefaultIndicator(deductible)}
      </div>
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.coverageLevelText.id}" class="panel">
        ${this.__renderTitle(this.deductible)}
        ${
          this.deductible.coverageLevel.code === 'NA'
            ? this.__renderNoContent()
            : this.__renderContent()
        }
      </div>
    `;
  }
}

customElements.define(
  'neb-patient-insurance-deductibles-coverage-details',
  NebPatientInsuranceDeductiblesCoverageDetails,
);
