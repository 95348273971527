import { html, css } from 'lit';
import moment from 'moment-timezone';

import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/neb-date-picker';
import { POPOVER_POSITION } from '../../../../packages/neb-lit-components/src/components/neb-popover';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import * as selectors from '../../../../packages/neb-utils/selectors';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  caseDropdown: { id: 'case-dropdown' },
  dateOfOnsetPicker: { id: 'date-of-onset-picker' },
  initialTxDatePicker: { id: 'initial-tx-date-picker' },
};

const INVALID_DATE_MESSAGE = 'Initial Tx Date cannot be before Date of Onset';

const checkValidInitialTxDate = (v, state) => {
  const { dateOfOnset, initialTxDate } = state;

  if (!dateOfOnset || !initialTxDate) {
    return true;
  }
  const initialTxMoment = moment(initialTxDate);
  const dateOfOnsetMoment = moment(dateOfOnset);

  return !initialTxMoment.isBefore(dateOfOnsetMoment);
};

export class NebFormClaimErrorItdDoo extends NebForm {
  static get properties() {
    return {
      cases: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow: hidden;
        }

        .case {
          margin-bottom: 10px;
          width: 100%;
        }

        .date-picker {
          width: 100%;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.cases = [];

    this.onSave = () => {};

    this.onAddNewCase = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.__handlers = {
      ...this.handlers,
      changeCase: ({ value, event }) => {
        if (event !== 'select') {
          return;
        }

        const { onsetSymptomsDate, initialTxDate } = value.data;

        this.setDate('dateOfOnset', onsetSymptomsDate);
        this.setDate('initialTxDate', initialTxDate);

        this.formService.apply('patientCase', value);
      },
      addNewCase: async () => {
        const newCase = await openOverlay(OVERLAY_KEYS.CASE, {
          context: {
            patientId: this.model.patientId,
            isFirstCase: !this.cases.length,
          },
        });

        if (newCase) {
          const newCaseItem = {
            label: `${newCase.name} - ${
              newCase.onsetSymptomsDate
                ? moment.utc(newCase.onsetSymptomsDate).format('MM/DD/YYYY')
                : 'Gradual'
            }`,
            data: newCase,
          };

          this.formService.apply('patientCase', newCaseItem);
          this.setDate('dateOfOnset', newCase.onsetSymptomsDate);
          this.setDate('initialTxDate', newCase.initialTxDate);
          this.onAddNewCase(newCaseItem);
        }
      },
      changeDate: ({ name, value }) => {
        this.setDate(name, value);
        this.formService.validate();
      },
      save: () => {
        if (this.formService.validate()) {
          this.onSave(this.state);
        }
      },
      cancel: () => {
        this.formService.reset();
      },
    };
  }

  static createModel() {
    return {
      patientCase: {},
      initialTxDate: '',
      dateOfOnset: '',
      patientId: '',
    };
  }

  createSelectors() {
    return {
      children: {
        patientCase: {
          ...selectors.select(this.cases, this.model.patientCase),
        },
        dateOfOnset: {
          validators: [
            {
              error: INVALID_DATE_MESSAGE,
              validate: (v, _, state) => checkValidInitialTxDate(v, state),
            },
          ],
          clipPristine: true,
          ignorePristine: true,
        },
        initialTxDate: {
          validators: [
            {
              error: INVALID_DATE_MESSAGE,
              validate: (v, _, state) => checkValidInitialTxDate(v, state),
            },
          ],
          clipPristine: true,
          ignorePristine: true,
        },
      },
    };
  }

  setDate(name, value) {
    if (value instanceof moment) {
      this.formService.apply(
        name,
        parseDate(value)
          .startOf('day')
          .toISOString(),
      );
    } else {
      this.formService.apply(name, value);
    }
  }

  __getActiveCases() {
    return this.cases.filter(c => c.data.active);
  }

  __renderCaseDropdown() {
    return html`
      <div>
        <neb-select
          id="${ELEMENTS.caseDropdown.id}"
          class="case"
          label="Case"
          name="patientCase"
          addNewItemLabel="Case"
          .items="${this.__getActiveCases()}"
          .value="${this.state.patientCase}"
          .onChange="${this.__handlers.changeCase}"
          .onAddNewItem="${this.__handlers.addNewCase}"
        ></neb-select>
      </div>
    `;
  }

  __renderDateOfOnsetPicker() {
    return html`
      <div>
        <neb-date-picker
          id="${ELEMENTS.dateOfOnsetPicker.id}"
          class="date-picker"
          label="Date of Onset"
          name="dateOfOnset"
          .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${parseDate(this.state.dateOfOnset)}"
          .onChange="${this.__handlers.changeDate}"
          .invalidText="${this.errors.dateOfOnset}"
          ?invalid="${this.errors.dateOfOnset}"
          momentFlag
        ></neb-date-picker>
      </div>
    `;
  }

  __renderInitialTxDatePicker() {
    return html`
      <div>
        <neb-date-picker
          id="${ELEMENTS.initialTxDatePicker.id}"
          class="date-picker"
          label="Initial Tx Date"
          name="initialTxDate"
          .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${parseDate(this.state.initialTxDate)}"
          .onChange="${this.__handlers.changeDate}"
          .disabled="${!this.state.patientCase.data}"
          .invalidText="${this.errors.initialTxDate}"
          ?invalid="${this.errors.initialTxDate}"
          momentFlag
        ></neb-date-picker>
      </div>
    `;
  }

  renderActionBar() {
    return this.__dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="Save"
            cancelLabel="Cancel"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.cancel}"
          >
          </neb-action-bar>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderCaseDropdown()} ${this.__renderDateOfOnsetPicker()}
      ${this.__renderInitialTxDatePicker()}
    `;
  }
}

window.customElements.define(
  'neb-form-claim-error-itd-doo',
  NebFormClaimErrorItdDoo,
);
