import '../../../../../neb-treatment-plan/components/neb-treatment-plan-form';

import { LitElement, html } from 'lit';

import { store, connect } from '../../../../../neb-redux/neb-redux-store';
import { connectNavigation } from '../../../../../neb-route/neb-nav-mixin';
import { NebTreatmentPlanBaseController } from '../../../../../neb-treatment-plan/components/neb-treatment-plan-base-controller';

const path = '/patients/:id/clinical/treatment-plan';
export const ELEMENTS = {
  form: {
    id: 'treatment-plan-form',
  },
};
export const ID_FORM = 'treatment-plan-form';

class NebPatientTreatmentPlanController extends connectNavigation(store, path)(
  NebTreatmentPlanBaseController(connect(store)(LitElement)),
) {
  _shouldNotifyParent() {
    return false;
  }

  render() {
    return html`
      <neb-treatment-plan-form
        slot="detail"
        ?small="${this._small}"
        id="${ELEMENTS.form.id}"
        class="treatment-plan-form"
        .patientId="${this.patientId}"
        .treatmentPlan="${this._activeTreatmentPlan}"
        .completedTreatmentPlans="${this._completedTreatmentPlans}"
        .onComplete="${this._actions.complete}"
        .onSave="${this._actions.save}"
        .onCalculate="${this._actions.calculate}"
        .onDeletePhaseError="${this._deletePhaseError}"
        hide-appointment-history
      ></neb-treatment-plan-form>
    `;
  }

  _shouldNavigate() {
    const form = this.shadowRoot.getElementById(ID_FORM);
    return form ? !form.isDirty() : true;
  }
}

customElements.define(
  'neb-patient-treatment-plan-controller',
  NebPatientTreatmentPlanController,
);
