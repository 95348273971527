import '../../../../../src/components/misc/neb-icon';
import './neb-macro-button';

import { LitElement, html, css } from 'lit';

import { slideRight, slideLeft } from '../../../../neb-styles/neb-animation';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_CAPTION,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  macroGrid: { id: 'grid-buttons' },
  macroButtons: { selector: 'neb-macro-button' },
};
const ID_BUTTON_PREFIX = 'button-item-';

function constructGhostImage(target) {
  const buttonHeight = target.offsetHeight;
  const buttonWidth = target.offsetWidth;
  const buttonColor = target.color;
  const innerText = target.label;
  const elem = document.createElement('div');

  elem.id = 'drag-ghost';
  elem.innerText = innerText;

  Object.assign(elem.style, {
    position: 'absolute',
    top: '-1000px',
    height: `${buttonHeight}px`,
    width: `${buttonWidth}px`,
    fontSize: CSS_FONT_SIZE_CAPTION,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
    background: buttonColor,
    borderRadius: '3px',
  });

  document.body.appendChild(elem);

  return elem;
}

class NebMacroGrid extends LitElement {
  static get properties() {
    return {
      disableEmpty: Boolean,
      showExpander: Boolean,
      selectedIndex: Number,
      selectedId: String,
      slideDirection: String,
      folder: Object,
      reorder: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.reorder = false;
    this.slideDirection = '';
    this.folder = null;
    this.disableEmpty = false;
    this.selectedId = '';
    this.selectedIndex = -1;

    this.onReorder = () => {};

    this.onSelectItem = () => {};

    this.onDoubleClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      animationEnd: () => this.onAnimationEnd(),
      itemSelected: index => this.onSelectItem(this.folder.items[index], index),
      dragStart: e => {
        const ghostImage = constructGhostImage(e.currentTarget);

        e.dataTransfer.setDragImage(ghostImage, 0, 0);
        e.dataTransfer.effectAllowed = 'move';

        const { index } = e.currentTarget;
        this.__startDragIndex = index;
        this.__dragIndex = index;
        this.__dragging = true;

        e.stopPropagation();
      },

      dragEnd: () => {
        if (
          this.__startDragIndex !== this.__dragIndex &&
          this.__dragIndex !== null
        ) {
          this.onReorder(this.__startDragIndex, this.__dragIndex);
        }

        this.__startDragIndex = null;
        this.__dragIndex = null;
        this.__dragging = false;

        const ghost = document.getElementById('drag-ghost');

        if (ghost.parentNode) {
          ghost.parentNode.removeChild(ghost);
        }
      },

      dragOver: e => {
        this.__dragIndex = e.currentTarget.index;

        if (this.__startDragIndex !== this.__dragIndex) {
          e.currentTarget.classList.add('is-hovered-over');

          e.preventDefault();
          e.dataTransfer.dropEffect = 'move';
        }
      },

      dragLeave: e => {
        this.__dragIndex = null;
        e.currentTarget.classList.remove('is-hovered-over');
      },

      drop: e => {
        e.preventDefault();
        e.currentTarget.classList.remove('is-hovered-over');
      },

      doubleClick: () => this.onDoubleClick(),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .grid {
          display: grid;
          margin: 0 ${CSS_SPACING};
          grid-gap: 3px;
          grid-template-columns: repeat(5, 1fr);
        }

        .grid[slide='right'] {
          animation: slide-right 0.2s linear forwards;
          -webkit-animation: slide-right 0.2s linear forwards;
        }

        .grid[slide='left'] {
          animation: slide-left 0.2s linear forwards;
          -webkit-animation: slide-left 0.2s linear forwards;
        }

        .button {
          border: 1px solid transparent;
          border-radius: 3px;
        }

        .is-hovered-over {
          border: 1px dashed ${CSS_COLOR_HIGHLIGHT};
        }

        ${slideRight} ${slideLeft}
      `,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    const handler = this.__handlers.animationEnd;
    this.shadowRoot.addEventListener('animationend', handler);
    this.shadowRoot.addEventListener('webkitAnimationEnd', handler);
  }

  __isSelected(item, index) {
    const itemId = item && item.id ? item.id : '';

    return item
      ? this.selectedId === itemId && this.selectedIndex === index
      : false;
  }

  __getItemInformation(item, key) {
    return item && item[key] ? item[key] : '';
  }

  render() {
    const folder = this.folder || {
      id: null,
      name: '',
      items: Array(20).fill(null),
    };

    return html`
      <div
        id="${ELEMENTS.macroGrid.id}"
        class="grid"
        slide="${this.slideDirection}"
      >
        ${
          folder.items.map(
            (item, index) => html`
              <neb-macro-button
                id="${ID_BUTTON_PREFIX}${index}"
                class="button"
                draggable="${this.reorder && item ? 'true' : 'false'}"
                @dragstart="${this.__handlers.dragStart}"
                @dragend="${this.__handlers.dragEnd}"
                @dragover="${this.__handlers.dragOver}"
                @dragleave="${this.__handlers.dragLeave}"
                @drop="${this.__handlers.drop}"
                @dblclick="${this.__handlers.doubleClick}"
                ?selectable="${item ? { ...item } : item}"
                .index="${index}"
                .label="${this.__getItemInformation(item, 'name')}"
                .icon="${this.__getItemInformation(item, 'icon')}"
                .color="${this.__getItemInformation(item, 'color')}"
                .disableEmpty="${this.disableEmpty}"
                .selected="${this.__isSelected(item, index)}"
                .onClick="${this.__handlers.itemSelected}"
                .disabled="${item ? item.disabled : false}"
              >
              </neb-macro-button>
            `,
          )
        }
      </div>
    `;
  }
}

customElements.define('neb-macro-grid', NebMacroGrid);
