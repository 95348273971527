import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { POPOVER_POSITION } from '../../../packages/neb-lit-components/src/components/neb-date-picker';
import { ALL_STATUSES_EXCEPT_DELETED } from '../../../packages/neb-patient/src/components/neb-patient-appointments';
import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import createProviderCollection from '../../../packages/neb-utils/collections/providers';
import { parseDate } from '../../../packages/neb-utils/date-util';
import * as selectors from '../../../packages/neb-utils/selectors';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters, ELEMENTS as BASE_ELEMENTS } from './neb-filters';

export const ELEMENTS = {
  statusSelect: { id: 'status-select' },
  providerSelect: { id: 'provider-select' },
  dateRangeInput: { id: 'date-range-input' },
  ...BASE_ELEMENTS,
};

const FILTERS = Object.freeze({
  providerIds: 'providerIds',
  date: 'date',
  status: 'status',
});

const ALL_STATUSES = [
  {
    label: 'Active',
    data: { id: 'Active' },
  },
  {
    label: 'Checked-In',
    data: { id: 'Checked-In' },
  },
  {
    label: 'Checked-Out',
    data: { id: 'Checked-Out' },
  },
  {
    label: 'Canceled',
    data: { id: 'Canceled' },
  },
  {
    label: 'Deleted',
    data: { id: 'Deleted' },
  },
  {
    label: 'No-Show',
    data: { id: 'No-Show' },
  },
  {
    label: 'Rescheduled',
    data: { id: 'Rescheduled' },
  },
];

class NebPatientHistoryAppointmentFilters extends NebFilters {
  static get properties() {
    return {
      __providerItems: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(2, 1fr);
        }

        .select {
          padding-bottom: 16px;
        }

        .span-2 {
          grid-column: span 2;
        }
      `,
    ];
  }

  static createModel() {
    return {
      status: ALL_STATUSES_EXCEPT_DELETED,
      providerIds: [],
      date: NebDateRange.createModel(),
    };
  }

  initState() {
    super.initState();

    this.__today = new Date();
    this.__providerItems = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeDate: e => {
        const date = new Date(e.value);

        this.formService.apply(e.name, e.value ? date.toISOString() : null);
      },
      apply: () => {
        const model = this.formService.build();
        const { from, to } = model.date;
        const { status } = model;

        const includeRescheduled = !!status.find(s => s === 'Rescheduled');

        this.onApply({
          ...model,
          date: {
            from: from ? parseDate(from._i) : null,
            to: to ? parseDate(to._i) : null,
          },
          includeRescheduled,
        });
      },
    };
  }

  createSelectors() {
    return {
      children: {
        status: selectors.multiSelect(ALL_STATUSES),
        providerIds: selectors.multiSelect([]),
        date: NebDateRange.createSelectors(),
      },
    };
  }

  async load() {
    await createProviderCollection(
      ({ pageItems }) => {
        this.__providerItems = pageItems;
      },
      null,
      { hideInactive: false },
    );
  }

  renderContent() {
    const date = {
      from: this.state.date.from ? parseDate(this.state.date.from) : null,
      to: this.state.date.to ? parseDate(this.state.date.to) : null,
    };

    return html`
      <neb-select
        id="${ELEMENTS.statusSelect.id}"
        label="Appointment Status"
        allLabel="Statuses"
        name="${FILTERS.status}"
        .items="${ALL_STATUSES}"
        .value="${this.state.status}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-select
        id="${ELEMENTS.providerSelect.id}"
        label="Providers"
        allLabel="Providers"
        name="${FILTERS.providerIds}"
        .items="${this.__providerItems}"
        .value="${this.state.providerIds}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.dateRangeInput.id}"
        label="Date"
        class="span-2"
        name="${FILTERS.date}"
        .manualPosition="${
          this.layout === 'large'
            ? POPOVER_POSITION.BOTTOM
            : POPOVER_POSITION.CENTER
        }"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>
    `;
  }
}

window.customElements.define(
  'neb-filters-patient-history-appointments',
  NebPatientHistoryAppointmentFilters,
);
