import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { autoAllocatePayments } from '../../../../../../src/api-clients/auto-allocation';
import { getLocations } from '../../../../../../src/api-clients/locations';
import { ADD_ONS, hasAddOn } from '../../../../../../src/utils/add-ons';
import { getAutoAllocationBanner } from '../../../../../../src/utils/auto-allocation';
import { getPreferredPaymentAssociation } from '../../../../../../src/utils/payment-associations-util';
import { ERROR_AUTO_ALLOCATE_DISCOUNT } from '../../../../../../src/utils/user-message';
import {
  deleteAllocation,
  getAllocatedCharges,
} from '../../../../../neb-api-client/src/allocation-api-client';
import {
  postDiscount,
  putDiscount,
} from '../../../../../neb-api-client/src/discount-api-client';
import { getLineItemDetails } from '../../../../../neb-api-client/src/ledger/line-items';
import {
  getPaymentDetail,
  voidPayment,
} from '../../../../../neb-api-client/src/payments-api-client';
import { getPracticeUsers } from '../../../../../neb-api-client/src/practice-users-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { VOIDED_DISCOUNT_BANNER_MESSAGE } from '../../../../../neb-patient/src/components/ledger/payment/neb-patient-payment-controller';
import { openDirtyPopup } from '../../../../../neb-popup';
import { PAYMENT_ACTION_KEYS } from '../../../../../neb-popup/src/neb-popup-payment-action';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import {
  OVERLAY_WIDTH_EXTRA_LARGE,
  CSS_SPACING,
} from '../../../../../neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../../neb-utils/constants';
import { parseDate } from '../../../../../neb-utils/date-util';
import { mapLineItemsForDiscounts } from '../../../../../neb-utils/discounts';
import { PROVIDER_TYPE } from '../../../../../neb-utils/enums';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../../neb-utils/feature-util';
import {
  centsToCurrency,
  objToName,
} from '../../../../../neb-utils/formatters';
import { filterOpenCharges } from '../../../../../neb-utils/neb-ledger-util';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';
import NebFormDiscount from '../../forms/neb-form-discount';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  form: {
    id: 'form',
  },
  voidDiscountButton: {
    id: 'void-discount-button',
  },
  autoAllocateButton: {
    id: 'auto-allocate-button',
  },
};

const DISCOUNT_TYPES = {
  FIXED_DOLLAR: 'fixedDollar',
  PERCENTAGE: 'percentage',
};

const DISCOUNT_SUCCESS_MESSAGE = 'Discount saved successfully';
const DISCOUNT_ERROR_MESSAGE = 'An error occurred when saving the discount';

export const CONFIRM_REMOVE_ALLOCATION = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Remove Allocation',
  message: html`
    Are you sure you want to remove the discount allocated from the selected
    charge(s)?
  `,
};

export const REMOVE_ALLOCATION_BANNER_MESSAGE = {
  success: 'Allocation(s) successfully removed',
  error: 'An error has occurred while removing the allocated discount(s)',
};

class NebOverlayDiscount extends Overlay {
  static get properties() {
    return {
      __hasFifo: Boolean,
      __changingDate: Boolean,
      __formModel: Object,
      __topData: Object,
      __paymentDetail: Object,
      __transactionDateFrom: Date,
      __transactionDateTo: Date,
      __practiceUsers: Array,
      __autoAllocatePressed: Boolean,
      __locations: Array,
      __preferredProvider: Object,
      __preferredLocation: Object,
      __providerItems: Array,
      __locationItems: Array,
      __hasFitInvoiceOverlayPerformanceFF: Boolean,
    };
  }

  initState() {
    super.initState();

    this.__hasFifo = false;
    this.__changingDate = false;
    this.__transactionDateFrom = null;
    this.__transactionDateTo = null;
    this.__autoAllocatePressed = false;
    this.__hasFitInvoiceOverlayPerformanceFF = false;

    this.__paymentDetail = {
      allocations: [],
      id: '',
      patientId: '',
      amount: 0,
      payerName: '',
      patientName: '',
      paymentMethod: '',
      postedById: '',
      transactionDate: null,
      available: 0,
    };

    this.__practiceUsers = [];
    this.__locations = [];

    this.__formModel = NebFormDiscount.createModel();
    this.__topData = {};
    this.__preferredProvider = {};
    this.__preferredLocation = {};
    this.__providerItems = [];
    this.__locationItems = [];
  }

  async connectedCallback() {
    const features = await getFeatures();

    this.__hasFitInvoiceOverlayPerformanceFF = features.includes(
      FEATURE_FLAGS.FIT_INVOICE_OVERLAY_PERFORMANCE,
    );

    this.__hasFifo = await hasAddOn(ADD_ONS.CT_FIFO);

    this.__transactionDateFrom = this.model.transactionDateFrom || null;
    this.__transactionDateTo = this.model.transactionDateTo || null;

    this.__practiceUsers = await getPracticeUsers();

    const allLocations = await getLocations();

    this.__locations = allLocations;

    this.__providerItems = this.__getProviderItems();
    this.__locationItems = this.__getLocationItems();

    await this.__getPreferredPaymentAssociation();
    await this.__load();

    super.connectedCallback();
  }

  async __fetchOpenChargeInfo() {
    const lineItems = await getLineItemDetails({
      patientId: this.model.patientId,
      useRelationships: false,
      noPayerPlan: true,
      ...(this.__transactionDateFrom && {
        dateOfServiceFrom: parseDate(this.__transactionDateFrom)
          .startOf('day')
          .toISOString(),
      }),
      ...(this.__transactionDateTo && {
        dateOfServiceTo: parseDate(this.__transactionDateTo)
          .endOf('day')
          .toISOString(),
      }),
    });

    lineItems.sort(
      (a, b) =>
        b.dateOfService.localeCompare(a.dateOfService) ||
        b.chargeNumber - a.chargeNumber,
    );

    const openCharges = filterOpenCharges(lineItems, false);

    const outstandingCharges = mapLineItemsForDiscounts(
      openCharges,
      this.model.id,
    );

    this.__formModel = {
      ...this.__formModel,
      outstandingCharges: [...outstandingCharges],
    };
  }

  async __outstandingRefresh(
    codeDiscountId,
    note,
    type,
    percentageAmount,
    fixedDollarAmount,
  ) {
    await this.__fetchOpenChargeInfo();

    this.__topData = {
      codeDiscountId,
      note,
      type,
      percentageAmount,
      fixedDollarAmount,
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async (model, closeAfterSave) => {
        const {
          amount,
          codeDiscountId,
          note,
          provider,
          location,
          lineItems = [],
        } = model;

        try {
          let result;

          const body = {
            patientId: this.model.patientId,
            amount,
            codeDiscountId,
            note,
            providerId: provider,
            locationId: location,
            lineItems,
          };

          if (this.model.id) {
            result = await putDiscount({
              id: this.model.id,
              ...body,
            });
          } else {
            result = await postDiscount(body);
          }

          store.dispatch(openSuccess(DISCOUNT_SUCCESS_MESSAGE));

          this.isDirty = false;

          if (this.model.id && !closeAfterSave) {
            await this.__load();
          } else {
            this.dismiss(result);
          }
        } catch (error) {
          console.error(error);
          store.dispatch(openError(DISCOUNT_ERROR_MESSAGE));
        }
      },
      autoAllocate: async () => {
        try {
          this.__autoAllocatePressed = true;
          const { data: allocations } = await autoAllocatePayments({
            paymentIds: [this.__paymentDetail.id],
          });

          await this.__load();

          store.dispatch(getAutoAllocationBanner(allocations, true));
          this.__autoAllocatePressed = false;
        } catch (e) {
          this.__autoAllocatePressed = false;
          console.error(e);
          store.dispatch(openError(ERROR_AUTO_ALLOCATE_DISCOUNT));
        }
      },
      reload: async () => {
        await this.__load();
      },
      voidDiscount: async () => {
        const paymentAction = await openPopup(
          POPUP_RENDER_KEYS.PAYMENT_ACTION,
          {
            action: PAYMENT_ACTION_KEYS.VOID,
            paymentId: this.__paymentDetail.paymentNumber,
            payer: this.__paymentDetail.payerName,
            amount: centsToCurrency(this.__paymentDetail.amount),
            isDiscount: true,
          },
        );

        if (paymentAction) {
          const voidPaymentBody = {
            paymentId: this.__paymentDetail.id,
            codeRefundId: paymentAction.selectedReason.id,
          };

          try {
            await voidPayment(voidPaymentBody, {
              voidAllocations: true,
            });

            store.dispatch(openSuccess(VOIDED_DISCOUNT_BANNER_MESSAGE.success));

            await this.__load();
          } catch (e) {
            store.dispatch(openError(VOIDED_DISCOUNT_BANNER_MESSAGE.error));
          }
        }
      },
      changeFromDate: async ({
        value,
        outstandingCharges,
        codeDiscountId,
        type,
        note,
        percentageAmount,
        fixedDollarAmount,
      }) => {
        if (this.__isFromDateInvalid(value)) {
          return;
        }

        if (!this.__changingDate) {
          this.__changingDate = true;

          const changedCharges = outstandingCharges.reduce((memo, oc) => {
            if (oc.selected) {
              const foundMatchingCharge = this.__formModel.outstandingCharges.find(
                x => x.id === oc.id,
              );
              memo.push(
                foundMatchingCharge.discountAllocated === oc.discountAllocated,
              );
            }

            return memo;
          }, []);

          if (changedCharges.some(sc => !sc)) {
            const proceed = await openDirtyPopup();

            if (!proceed) {
              this.__transactionDateFrom = parseDate(
                this.__transactionDateFrom,
              );

              const discountForm = this.shadowRoot.getElementById(
                ELEMENTS.form.id,
              );
              discountForm.transactionDateFrom = this.__transactionDateFrom;
              this.__changingDate = false;

              return;
            }
          }

          this.__transactionDateFrom =
            value !== null ? value.toISOString() : value;

          await this.__outstandingRefresh(
            codeDiscountId,
            note,
            type,
            percentageAmount,
            fixedDollarAmount,
          );

          this.__changingDate = false;
        }
      },
      changeToDate: async ({
        value,
        outstandingCharges,
        codeDiscountId,
        type,
        note,
        percentageAmount,
        fixedDollarAmount,
      }) => {
        if (this.__isToDateInvalid(value)) {
          return;
        }

        if (!this.__changingDate) {
          this.__changingDate = true;

          const changedCharges = outstandingCharges.reduce((memo, oc) => {
            if (oc.selected) {
              const foundMatchingCharge = this.__formModel.outstandingCharges.find(
                x => x.id === oc.id,
              );
              memo.push(
                foundMatchingCharge.discountAllocated === oc.discountAllocated,
              );
            }

            return memo;
          }, []);

          if (changedCharges.some(sc => !sc)) {
            const proceed = await openDirtyPopup();

            if (!proceed) {
              this.__transactionDateTo = parseDate(this.__transactionDateTo);

              const discountForm = this.shadowRoot.getElementById(
                ELEMENTS.form.id,
              );
              discountForm.transactionDateTo = this.__transactionDateTo;
              this.__changingDate = false;

              return;
            }
          }

          this.__transactionDateTo =
            value !== null ? value.toISOString() : value;

          await this.__outstandingRefresh(
            codeDiscountId,
            note,
            type,
            percentageAmount,
            fixedDollarAmount,
          );

          this.__changingDate = false;
        }
      },
      clickNoteIcon: async () => {
        const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.PAYMENT,
          parentId: this.__paymentDetail.id,
          parentData: {
            transactionDate: parseDate(this.__paymentDetail.transactionDate)
              .startOf('day')
              .format('MM/DD/YYYY'),
            paymentId: `D-${this.__paymentDetail.paymentNumber}`,
            paymentType: 'Discount',
            amount: this.__paymentDetail.amount,
            payer: this.__paymentDetail.payerName,
          },
          patientId: this.__paymentDetail.patientId,
        });
        if (result) await this.__load();
      },
      removeAllocation: async ({ selectedItems }) => {
        const confirmed = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          CONFIRM_REMOVE_ALLOCATION,
        );

        const selectedLineItemsIds = selectedItems.map(si => si.id);

        const selectedLineItems = this.__paymentDetail.allocations.filter(a =>
          selectedLineItemsIds.includes(a.id),
        );

        if (confirmed) {
          const lineItems = selectedLineItems.reduce((memo, li) => {
            const debits = li.lineItemDebits.map(lid => ({
              id: lid.debit.id,
            }));

            memo.push({ id: li.id, debits });

            return memo;
          }, []);

          try {
            await deleteAllocation(this.__paymentDetail.id, lineItems);
            await this.__load();

            store.dispatch(
              openSuccess(REMOVE_ALLOCATION_BANNER_MESSAGE.success),
            );
          } catch (_) {
            store.dispatch(openError(REMOVE_ALLOCATION_BANNER_MESSAGE.error));
          }
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          min-width: ${OVERLAY_WIDTH_EXTRA_LARGE};
          width: 100%;
        }

        .header-container {
          padding: ${CSS_SPACING};
        }

        .header-container-with-buttons {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .header-container-with-bottom-margin {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
          margin-bottom: 10px;
        }

        .buttons {
          display: flex;
          gap: ${CSS_SPACING};
        }

        .action-button {
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  async __getPreferredPaymentAssociation() {
    const activeProviders = this.__getActiveProviderItems();
    const activeLocations = this.__getActiveLocationItems();

    const { providerId, locationId } = await getPreferredPaymentAssociation({
      providerItems: activeProviders,
      locationItems: activeLocations,
      patientId: this.model.patientId,
    });

    this.__preferredProvider = this.__getProviderOrLocation(
      this.__providerItems,
      providerId,
    );

    this.__preferredLocation = this.__getProviderOrLocation(
      this.__locationItems,
      locationId,
    );
  }

  __getProviderOrLocation(list, id) {
    return list.find(item => item.data.id === id);
  }

  __getActiveProviderItems() {
    return this.__providerItems.filter(provider => provider.data.active);
  }

  __getActiveLocationItems() {
    return this.__locationItems.filter(location => location.data.active);
  }

  __isFromDateInvalid(value) {
    return (
      (value &&
        this.__transactionDateFrom &&
        parseDate(value)
          .startOf('day')
          .isSame(parseDate(this.__transactionDateFrom).startOf('day'))) ||
      value === this.__transactionDateFrom
    );
  }

  __isToDateInvalid(value) {
    return (
      (value &&
        this.__transactionDateTo &&
        parseDate(value)
          .endOf('day')
          .isSame(parseDate(this.__transactionDateTo).endOf('day'))) ||
      value === this.__transactionDateTo
    );
  }

  __isDiscountVoidable() {
    return this.__paymentDetail.id && this.__paymentDetail.status !== 'Voided';
  }

  __isDiscountAllocatable() {
    const discountAllocated = this.__formModel.allocatedCharges.reduce(
      (totalAllocated, row) => totalAllocated + row.discountAllocated,
      0,
    );

    return (
      this.__paymentDetail.id &&
      this.__paymentDetail.status !== 'Voided' &&
      this.__formModel.fixedDollarAmount - discountAllocated > 0 &&
      !this.isDirty
    );
  }

  __getFormModel() {
    let paymentDetails = {};

    if (this.__paymentDetail.id) {
      paymentDetails = {
        id: this.__paymentDetail.id,
        amount: this.__paymentDetail.amount,
        type: DISCOUNT_TYPES.FIXED_DOLLAR,
        percentageAmount: '',
        fixedDollarAmount: this.__paymentDetail.amount,
        note: this.__paymentDetail.note,
        allocatedCharges: mapLineItemsForDiscounts(
          this.__paymentDetail.allocations,
          this.model.id,
        ),
        codeDiscountId: this.__paymentDetail.codeDiscountId,
        provider: this.__paymentDetail.providerId
          ? this.__paymentDetail.providerId
          : '',
        location: this.__paymentDetail.locationId
          ? this.__paymentDetail.locationId
          : '',
      };
    }

    return {
      ...NebFormDiscount.createModel(),
      ...paymentDetails,
    };
  }

  async __load() {
    if (this.model.id) {
      const paymentDetail = await getPaymentDetail(this.model.id, true);

      let allocations = [];

      if (paymentDetail.status !== 'Voided') {
        allocations = await getAllocatedCharges(this.model.id, {}, true);
      }

      this.__paymentDetail = {
        ...paymentDetail,
        allocations,
      };
    }

    this.__formModel = this.__getFormModel();

    await this.__fetchOpenChargeInfo();
  }

  __getTitle() {
    if (!this.model.id) return 'Add Discount';

    const discountNumber = this.__paymentDetail.paymentNumber
      ? `D-${this.__paymentDetail.paymentNumber}`
      : '';

    return `Discount ID ${discountNumber}`;
  }

  __getProviderItems() {
    return this.__practiceUsers
      .filter(
        user =>
          user.type === PROVIDER_TYPE.PROVIDER ||
          user.type === PROVIDER_TYPE.SPECIALIST,
      )
      .map(user => ({
        label: objToName(user.name, { reverse: true }),
        data: user,
      }));
  }

  __getLocationItems() {
    const { locations } = store.getState().session.item;

    return this.__locations.reduce((acc, location) => {
      if (locations.includes(location.id)) {
        acc.push({
          label: location.name,
          data: location,
        });
      }
      return acc;
    }, []);
  }

  __getHeaderStyle() {
    if (this.__isDiscountVoidable()) {
      return '-with-bottom-margin';
    }

    return '';
  }

  __renderHeader() {
    return html`
      <div class="header-container${this.__getHeaderStyle()}">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          title="${this.__getTitle()}"
          .onCancel="${this.handlers.dismiss}"
          .onClickNoteIcon="${this.handlers.clickNoteIcon}"
          ?showAddNoteIcon="${
            this.model.id && !this.__paymentDetail.hasBillingNote
          }"
          ?showEditNoteIcon="${
            this.model.id && !!this.__paymentDetail.hasBillingNote
          }"
          showCancelButton
        ></neb-popup-header>
        <div class="buttons">
          ${this.__renderVoidDiscountButton()}
          ${this.__renderAutoAllocationButton()}
        </div>
      </div>
    `;
  }

  __renderVoidDiscountButton() {
    return this.__isDiscountVoidable()
      ? html`
          <div class="action-button">
            <neb-button-action
              id="${ELEMENTS.voidDiscountButton.id}"
              leadingIcon="clear"
              label="Void Discount"
              .onClick="${this.handlers.voidDiscount}"
            ></neb-button-action>
          </div>
        `
      : '';
  }

  __renderAutoAllocationButton() {
    if (!this.__hasFifo) return '';
    return this.__paymentDetail.id && this.__paymentDetail.status !== 'Voided'
      ? html`
          <div class="action-button">
            <neb-button-action
              id="${ELEMENTS.autoAllocateButton.id}"
              leadingIcon="flashAuto"
              label="Auto Allocate"
              ?disabled="${
                !this.__isDiscountAllocatable() || this.__autoAllocatePressed
              }"
              .onClick="${this.handlers.autoAllocate}"
            ></neb-button-action>
          </div>
        `
      : '';
  }

  __renderForm() {
    return html`
      <neb-form-discount
        id="${ELEMENTS.form.id}"
        class="form"
        .layout="${this.layout}"
        .model="${this.__formModel}"
        .topData="${this.__topData}"
        .paymentModel="${this.__paymentDetail}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .onFromDateChange="${this.handlers.changeFromDate}"
        .onToDateChange="${this.handlers.changeToDate}"
        .onSave="${this.handlers.save}"
        .onClickLink="${this.handlers.reload}"
        .patientId="${this.model.patientId}"
        .practiceUsers="${this.__practiceUsers}"
        .allLocations="${this.__locations}"
        .locationItems="${this.__locationItems}"
        .providerItems="${this.__providerItems}"
        .transactionDateFrom="${this.__transactionDateFrom}"
        .transactionDateTo="${this.__transactionDateTo}"
        .onRemoveAllocation="${this.handlers.removeAllocation}"
        .preferredProvider="${this.__preferredProvider}"
        .preferredLocation="${this.__preferredLocation}"
        .hasFitInvoiceOverlayPerformanceFF="${
          this.__hasFitInvoiceOverlayPerformanceFF
        }"
      ></neb-form-discount>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}${this.__renderForm()}
    `;
  }
}

window.customElements.define('neb-overlay-discount', NebOverlayDiscount);
