import { html, css, LitElement } from 'lit';

import { ProviderService } from '../../../../neb-redux/services/provider';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { objToName, DEFAULT_NAME_OPTS } from '../../../../neb-utils/formatters';
import { activeProviders } from '../../../../neb-utils/provider';
import { BUTTON_ROLE } from '../neb-button';

import {
  DATE_ONSET,
  DATE_RESOLUTION,
  DROPDOWN_PROVIDER,
  DROPDOWN_TYPE,
  DROPDOWN_STATUS,
  STATUS_DROPDOWN_ITEMS,
  TYPE_DROPDOWN_ITEMS,
  TYPE_RESOLVED,
  NOTES,
} from './neb-problems-form-body';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
const STATUS_DEFAULT = {
  displayValue: 'Active',
  value: 'active',
};
const TEXT_BUTTON_CANCEL = 'cancel';
const TEXT_BUTTON_DELETE = 'delete';
const TEXT_BUTTON_ADD = 'add';
const TEXT_BUTTON_UPDATE = 'update';
const TEXT_DESCRIPTION = 'Description';
const TEXT_DIAGNOSIS = 'Diagnosis';
const BLANK_PROVIDER = {
  value: null,
  displayValue: ' ',
};
export const ELEMENTS = {
  cancelButton: {
    id: 'cancel-button',
  },
  deleteButton: {
    id: 'delete-button',
  },
  code: {
    id: 'code',
  },
  codeTitle: {
    id: 'title-code',
  },
  description: {
    id: 'description',
  },
  descriptionTitle: {
    id: 'description-title',
  },
  saveButton: {
    id: 'save-button',
  },
  formBody: {
    id: 'form-body',
  },
};

class NebProblemsForm extends LitElement {
  static get properties() {
    return {
      __invalidFields: Array,
      errors: Object,
      notes: String,
      selectedOnsetDate: Object,
      selectedProvider: String,
      selectedResolutionDate: Object,
      selectedStatus: String,
      selectedType: String,
      model: Object,
      small: {
        type: Boolean,
        reflect: true,
      },
      __providerDropdownData: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__invalidFields = [];
    this.selectedProvider = '';
    this.selectedType = '';
    this.selectedStatus = STATUS_DEFAULT;
    this.selectedOnsetDate = parseDate().startOf('day');
    this.selectedResolutionDate = null;
    this.notes = '';
    this.errors = {
      [DROPDOWN_STATUS]: '',
    };

    this.model = {};
    this.small = false;
    this.__providerDropdownData = {};
    this.__providerService = new ProviderService(providerDropdownData => {
      this.__providerDropdownData = this.__formatProviders(
        providerDropdownData,
      );
    });

    this.onSave = () => {};

    this.onDelete = () => {};

    this.onCancel = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      save: () => {
        if (this.__checkValidation()) {
          const result = {
            info: this.getCurrentInfo(),
          };
          this.onSave(result);
        }
      },
      cancel: () => {
        this.onCancel({
          info: null,
        });
      },
      delete: () => {
        const result = {
          deleteProblem: this.getCurrentInfo(),
        };
        this.onDelete(result);
      },
      bodyChanged: (field, value) => {
        switch (field) {
          case DROPDOWN_PROVIDER:
            this.selectedProvider = value;
            break;

          case DROPDOWN_TYPE:
            this.selectedType = value;
            break;

          case DATE_RESOLUTION:
            this.selectedResolutionDate = value;
            break;

          case NOTES:
            this.notes = value;
            break;

          case DROPDOWN_STATUS:
            this.selectedStatus = value;

            if (this.selectedStatus.value !== TYPE_RESOLVED) {
              this.__removeInvalidField(DATE_RESOLUTION);

              this.selectedResolutionDate = null;

              this.__validateStatus(this.selectedStatus);
            } else {
              this.errors = { ...this.errors, [DROPDOWN_STATUS]: '' };
            }

            break;

          case DATE_ONSET:
            this.selectedOnsetDate = value;

            if (
              !this.selectedOnsetDate ||
              this.selectedOnsetDate > this.selectedResolutionDate
            ) {
              this.selectedResolutionDate = null;

              this.__removeInvalidField(DATE_RESOLUTION);
            }

            break;

          default:
        }

        this.__removeInvalidField(field);
      },
    };
  }

  __formatProvider({ id, name }) {
    return {
      value: id,
      displayValue: objToName(name, DEFAULT_NAME_OPTS),
    };
  }

  __formatProviders({ selectedProvider, providers }) {
    const formattedItems = {
      selectedProvider: selectedProvider
        ? this.__formatProvider(selectedProvider)
        : '',
      providerDropdownItems: activeProviders(providers).map(
        this.__formatProvider,
      ),
    };
    return formattedItems;
  }

  static get styles() {
    return css`
      :host {
        height: 100%;
        width: 100%;
        background-color: ${CSS_COLOR_WHITE};
      }

      :host([small]) .content-container {
        display: flex;
        flex-direction: column;
        padding: ${CSS_SPACING};
      }

      :host([small]) .row {
        display: flex;
        flex-direction: column;
      }

      :host([small]) .columns:first-child {
        flex: 0;
      }

      :host([small]) .code {
        margin-bottom: 10px;
      }

      .title-header-row {
        margin-bottom: 10px;
        justify-content: space-between;
      }

      .description-text {
        font-size: ${CSS_FONT_SIZE_BODY};
      }

      .title-code {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .popup-footer {
        padding-top: 25px;
        display: flex;
        justify-content: space-between;
      }

      .button {
        width: 100px;
      }

      .button-save {
        margin-right: 12px;
      }

      .columns {
        display: flex;
        flex-direction: column;
      }

      .columns:first-child {
        flex: 0 0 110px;
      }

      .row {
        display: flex;
      }
    `;
  }

  connectedCallback() {
    super.connectedCallback();

    this.__providerService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__providerService.disconnect();
  }

  getCurrentInfo() {
    return {
      providerId: this.selectedProvider.value,
      providerName: this.selectedProvider.displayValue,
      code: this.model.problem.code,
      shortDescription: this.model.problem.shortDescription,
      type: this.selectedType.value,
      status: this.selectedStatus.value,
      onsetDate:
        this.selectedOnsetDate &&
        this.selectedOnsetDate.format(ISO_DATE_FORMAT),
      resolutionDate:
        this.selectedResolutionDate &&
        this.selectedResolutionDate.format(ISO_DATE_FORMAT),
      notes: this.notes,
    };
  }

  __removeInvalidField(field) {
    const fieldIndex = this.__invalidFields.indexOf(field);

    if (fieldIndex !== -1) this.__invalidFields.splice(fieldIndex, 1);
  }

  __enableResolutionDate() {
    return (
      this.selectedStatus.value === TYPE_RESOLVED && this.selectedOnsetDate
    );
  }

  __checkValidation() {
    this.__invalidFields = [];

    this.__checkRequiredField(DROPDOWN_TYPE, this.selectedType);

    this.__checkRequiredField(DATE_ONSET, this.selectedOnsetDate);

    this.__checkValidField(DROPDOWN_STATUS, this.selectedStatus);

    if (this.__enableResolutionDate()) {
      this.__checkRequiredField(DATE_RESOLUTION, this.selectedResolutionDate);
    }

    return this.__invalidFields.length === 0;
  }

  __validateStatus() {
    if (this.__isDuplicateProblem()) {
      this.errors = {
        ...this.errors,
        [DROPDOWN_STATUS]: 'Duplicate Problem in List',
      };
    }
  }

  __isDuplicate() {
    let problemsWithoutCurrent;
    problemsWithoutCurrent = [...this.model.problems];

    if (this.model.isUpdate) {
      problemsWithoutCurrent.splice(this.model.index, 1);
    }

    problemsWithoutCurrent = problemsWithoutCurrent.filter(
      problem => problem.status !== 'resolved',
    );

    if (!problemsWithoutCurrent.length) {
      return false;
    }

    return (
      problemsWithoutCurrent.findIndex(
        problem => problem.code === this.model.problem.code,
      ) !== -1
    );
  }

  __isDuplicateProblem() {
    if (this.model.problems) {
      return this.__isDuplicate();
    }

    return false;
  }

  __calculateOnsetSelectedDate() {
    return this.model.problem.onsetDate
      ? parseDate(this.model.problem.onsetDate).startOf('day')
      : null;
  }

  __calculateResolutionSelectedDate() {
    return this.model.problem.resolutionDate
      ? parseDate(this.model.problem.resolutionDate).startOf('day')
      : null;
  }

  __getSelectedType() {
    return (
      TYPE_DROPDOWN_ITEMS.find(i => i.value === this.model.problem.type) || ''
    );
  }

  __getSelectedStatus() {
    return (
      STATUS_DROPDOWN_ITEMS.find(i => i.value === this.model.problem.status) ||
      STATUS_DEFAULT
    );
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.selectedOnsetDate = this.__calculateOnsetSelectedDate();
      this.selectedResolutionDate = this.__calculateResolutionSelectedDate();
      this.selectedType = this.__getSelectedType();
      this.selectedStatus = this.__getSelectedStatus();
      this.selectedProvider =
        !!this.model.problem.providerName && !!this.model.problem.providerId
          ? {
              displayValue: this.model.problem.providerName,
              value: this.model.problem.providerId,
            }
          : BLANK_PROVIDER;

      this.notes = this.model.problem.notes || '';
    }

    if (changedProps.has('__providerDropdownData')) {
      if (this.__providerDropdownData.providerDropdownItems) {
        this.__providerDropdownData.providerDropdownItems = [
          BLANK_PROVIDER,
          ...this.__providerDropdownData.providerDropdownItems,
        ];
      }
    }
  }

  __checkRequiredField(field, fieldInput) {
    if (!fieldInput) this.__invalidFields.push(field);
  }

  __checkValidField(field) {
    if (this.errors[DROPDOWN_STATUS]) this.__invalidFields.push(field);
  }

  __renderFooter() {
    return html`
      <div class="popup-footer">
        <div>
          <neb-button
            id="${ELEMENTS.saveButton.id}"
            class="button button-save"
            .label="${
              this.model.isUpdate ? TEXT_BUTTON_UPDATE : TEXT_BUTTON_ADD
            }"
            role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.save}"
          ></neb-button>
          <neb-button
            id="${ELEMENTS.cancelButton.id}"
            class="button"
            role="${BUTTON_ROLE.CANCEL}"
            .label="${TEXT_BUTTON_CANCEL}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>

        ${
          this.model.isUpdate
            ? html`
                <neb-button
                  id="${ELEMENTS.deleteButton.id}"
                  class="button"
                  role="${BUTTON_ROLE.DELETE}"
                  .label="${TEXT_BUTTON_DELETE}"
                  @click="${this.__handlers.delete}"
                ></neb-button>
              `
            : html``
        }
      </div>
    `;
  }

  __renderHeader() {
    return html`
      <div class="title-header-row">
        <div class="row">
          <div class="columns">
            <span id="${ELEMENTS.codeTitle.id}" class="title-code">
              ${TEXT_DIAGNOSIS}
            </span>

            <span id="${ELEMENTS.code.id}" class="description-text code">
              ${this.model.problem.code}
            </span>
          </div>

          <div class="columns">
            <span id="${ELEMENTS.descriptionTitle.id}" class="title-code">
              ${TEXT_DESCRIPTION}
            </span>

            <span id="${ELEMENTS.description.id}" class="description-text">
              ${this.model.problem.shortDescription}
            </span>
          </div>
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="content-container">
        ${this.__renderHeader()}
        <neb-problems-form-body
          id="${ELEMENTS.formBody.id}"
          .errors="${this.errors}"
          .invalidFields="${this.__invalidFields}"
          .notes="${this.notes}"
          .onsetDate="${this.selectedOnsetDate}"
          .provider="${this.selectedProvider}"
          .resolutionDate="${this.selectedResolutionDate}"
          .status="${this.selectedStatus}"
          .type="${this.selectedType}"
          .small="${this.small}"
          .onChange="${this.__handlers.bodyChanged}"
          .providerDropdownData="${this.__providerDropdownData}"
        ></neb-problems-form-body>
        ${this.__renderFooter()}
      </div>
    `;
  }
}

customElements.define('neb-problems-form', NebProblemsForm);
