import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';
import * as selectors from '../../../packages/neb-utils/selectors';
import { BILLED_STATUS_LABELS } from '../../formatters/encounter-charge-details';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters, ELEMENTS as BASE_ELEMENTS } from './neb-filters';

export const BILLED_STATUS_ITEMS = [
  {
    label: BILLED_STATUS_LABELS.billedWaiting,
    data: { id: 'billedWaiting' },
  },
  {
    label: BILLED_STATUS_LABELS.billedPrimary,
    data: { id: 'billedPrimary' },
  },
  {
    label: BILLED_STATUS_LABELS.billedSecondary,
    data: { id: 'billedSecondary' },
  },
  {
    label: BILLED_STATUS_LABELS.billedPaymentReceived,
    data: { id: 'billedPaymentReceived' },
  },
  {
    label: BILLED_STATUS_LABELS.billedPaidInFull,
    data: { id: 'billedPaidInFull' },
  },
];

export const BALANCE_REMAINING_ITEMS = [
  {
    label: 'Primary',
    data: { id: 'primary' },
  },
  {
    label: 'Secondary',
    data: { id: 'secondary' },
  },
  {
    label: 'Patient',
    data: { id: 'patient' },
  },
];

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  dosRange: { id: 'range-date-of-service' },
  billedStatusSelect: { id: 'select-billed-status' },
  balanceRemainingSelect: { id: 'select-balance-remaining' },
};

export class NebChargesTableFilters extends NebFilters {
  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .collapsed-filters {
          grid-template-columns: initial;
          grid-auto-flow: column;
        }

        .title-container {
          align-self: center;
          margin-right: 5px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      dateOfService: NebDateRange.createModel(),
      billedStatus: [],
      balanceRemaining: [],
    };
  }

  initState() {
    super.initState();
    this.expanded = false;
    this.hasCollapsedFilters = true;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      changeDate: ({ name, value }) => {
        this.formService.apply(name, value ? value.toISOString() : '');
      },

      apply: () => {
        const model = this.formService.build();

        this.totalFiltersApplied = [
          model.balanceRemaining.length,
          model.billedStatus.length,
          model.dateOfService.from,
          model.dateOfService.to,
        ].reduce((count, v) => (v ? count + 1 : count), 0);

        this.onApply({
          ...model,
          dateOfService: {
            from: model.dateOfService.from ? model.dateOfService.from._i : null,
            to: model.dateOfService.to ? model.dateOfService.to._i : null,
          },
        });
      },

      reset: () => {
        this.totalFiltersApplied = 0;
        this.formService.reset();
        this.handlers.apply();
      },
    };
  }

  createSelectors() {
    return {
      children: {
        dateOfService: NebDateRange.createSelectors(),
        billedStatus: selectors.multiSelect([]),
        balanceRemaining: selectors.multiSelect([]),
      },
    };
  }

  __renderBilledStatusFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.billedStatusSelect.id}"
        name="billedStatus"
        label="Billed Status"
        helper=" "
        .value="${this.state.billedStatus}"
        .items="${BILLED_STATUS_ITEMS}"
        .maxVisibleItems="${BILLED_STATUS_ITEMS.length}"
        .enableClear="${true}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderBalanceRemainingFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.balanceRemainingSelect.id}"
        name="balanceRemaining"
        label="Has Balance Remaining"
        helper=" "
        .value="${this.state.balanceRemaining}"
        .items="${BALANCE_REMAINING_ITEMS}"
        .maxVisibleItems="${BALANCE_REMAINING_ITEMS.length}"
        .enableClear="${true}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderDosFilter() {
    const date = {
      from: this.state.dateOfService.from
        ? parseDate(this.state.dateOfService.from)
        : null,
      to: this.state.dateOfService.to
        ? parseDate(this.state.dateOfService.to)
        : null,
    };

    return html`
      <neb-range-date
        id="${ELEMENTS.dosRange.id}"
        name="dateOfService"
        label="Date"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
        .minGap="${true}"
      ></neb-range-date>
    `;
  }

  __renderTitle() {
    return html`
      <div class="title-container">
        <span class="title">Filters</span>${this.__renderTotalFiltersApplied()}
      </div>
    `;
  }

  renderCollapsedContent() {
    return html`
      ${this.__renderBilledStatusFilter()}${this.__renderDosFilter()}${
        this.__renderBalanceRemainingFilter()
      }
    `;
  }
}

window.customElements.define(
  'neb-filters-charges-table',
  NebChargesTableFilters,
);
