import '../../neb-tooltip';
import '../../neb-radio-button';
import '../../inputs/neb-select';
import '../../inputs/neb-textfield';
import '../../controls/neb-button-action';

import { LitElement, html, css } from 'lit';

import { baseStyles, layoutStyles } from '../../../../../../src/styles';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';

import NebProviderSpecificValuesFields from './neb-provider-specific-values-fields';

export const ELEMENTS = {
  items: {
    selector: '.item',
  },
  addButton: {
    id: 'add-button',
  },
};

export default class NebProviderSpecificValuesFieldsList extends LitElement {
  static get properties() {
    return {
      activeServiceLocations: Array,
      activeLocations: Array,
      billingInfo: Object,
      errors: Object,
      model: Object,
      selectedRenderingProvider: Object,
      selectedRenderingProviderIdentifiers: Array,
      showAddButton: Boolean,
      maxItems: Number,
      providerIndex: Number,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .add-provider-button-padding {
          padding: 0 0 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  static createModel(providerId, locations) {
    return NebProviderSpecificValuesFields.createModel(providerId, locations);
  }

  static createSelectors(providerId, locations) {
    return {
      createItem: () =>
        NebProviderSpecificValuesFields.createModel(providerId, locations),
      children: NebProviderSpecificValuesFields.createSelectors(locations),
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.activeServiceLocations = [];
    this.activeLocations = [];
    this.billingInfo = {};
    this.errors = {};
    this.maxItems = 1;
    this.model = {};
    this.providerIndex = -1;
    this.selectedRenderingProvider = {};
    this.selectedRenderingProviderIdentifiers = [];
    this.showAddButton = false;

    this.onAdd = () => {};

    this.onRemove = () => {};

    this.onProviderSpecificValuesChanged = () => {};

    this.onProviderOtherTaxIdTypeChanged = () => {};

    this.onProviderSpecificTaxIdValueChanged = () => {};

    this.onProviderSpecificIdentifierChanged = () => {};

    this.onLocationChanged = () => {};

    this.onProviderSpecificNPIChanged = () => {};
  }

  updated(changedProps) {
    if (
      changedProps.has('model') ||
      changedProps.has('selectedRenderingProvider')
    ) {
      this.maxItems = this.__calculateMaxItems(this.providerIndex);
      this.showAddButton =
        !this.maxItems ||
        !this.model[this.providerIndex] ||
        !this.model[this.providerIndex].items ||
        this.model[this.providerIndex].items.length < this.maxItems;
    }
  }

  __initHandlers() {
    this.handlers = {
      add: e => this.onAdd(e),
      remove: e => this.onRemove(e),
      providerSpecificValuesChanged: e =>
        this.onProviderSpecificValuesChanged(e),
      providerOtherTaxIdTypeChanged: e =>
        this.onProviderOtherTaxIdTypeChanged(e),
      providerSpecificTaxIdValueChanged: e =>
        this.onProviderSpecificTaxIdValueChanged(e),
      providerSpecificIdentifierChanged: e =>
        this.onProviderSpecificIdentifierChanged(e),
      locationChanged: e => this.onLocationChanged(e),
      providerSpecificNPIChanged: e => this.onProviderSpecificNPIChanged(e),
    };
  }

  __calculateMaxItems(providerIndex) {
    if (providerIndex !== -1) {
      const locationsUsed = this.model[providerIndex].items.reduce(
        (accum, curr) => {
          accum += (curr && curr.locations && curr.locations.length) || 0;

          return accum;
        },
        0,
      );

      if (locationsUsed >= this.activeServiceLocations.length) {
        return this.model[providerIndex].items.length;
      }
    }

    return this.activeServiceLocations.length;
  }

  __renderAddButton(index) {
    return this.showAddButton
      ? html`
          <neb-button-action
            id="${ELEMENTS.addButton.id}"
            name="${index}.addButton"
            class="add-provider-button-padding limit-width"
            label="Add Provider Values"
            .onClick="${this.handlers.add}"
          ></neb-button-action>
        `
      : '';
  }

  __renderItems(providerIndex) {
    return providerIndex !== -1
      ? this.model[providerIndex].items.map(
          (item, itemIndex) =>
            html`
              <div>
                <neb-provider-specific-values-fields
                  class="item"
                  name="providerSpecificValues.${providerIndex}"
                  .model="${this.model[providerIndex]}"
                  .errors="${this.errors[providerIndex]}"
                  .selectedRenderingProvider="${this.selectedRenderingProvider}"
                  .selectedRenderingProviderIdentifiers="${
                    this.selectedRenderingProviderIdentifiers
                  }"
                  .billingInfo="${this.billingInfo}"
                  .activeServiceLocations="${this.activeServiceLocations}"
                  .activeLocations="${this.activeLocations}"
                  .providerSpecificValuesChanged="${
                    this.handlers.providerSpecificValuesChanged
                  }"
                  .providerSpecificTaxIdValueChanged="${
                    this.handlers.providerSpecificTaxIdValueChanged
                  }"
                  .providerSpecificIdentifierChanged="${
                    this.handlers.providerSpecificIdentifierChanged
                  }"
                  .providerSpecificNPIChanged="${
                    this.handlers.providerSpecificNPIChanged
                  }"
                  .locationChanged="${this.handlers.locationChanged}"
                  .providerOtherTaxIdTypeChanged="${
                    this.handlers.providerOtherTaxIdTypeChanged
                  }"
                  .providerIndex="${providerIndex}"
                  .itemIndex="${itemIndex}"
                  .onRemove="${this.handlers.remove}"
                  .providerOtherTaxIds="${this.providerOtherTaxIds}"
                ></neb-provider-specific-values-fields>
              </div>
            `,
        )
      : [];
  }

  render() {
    return html`
      ${this.__renderItems(this.providerIndex)}
      ${this.__renderAddButton(this.providerIndex)}
    `;
  }
}

window.customElements.define(
  'neb-provider-specific-values-fields-list',
  NebProviderSpecificValuesFieldsList,
);
