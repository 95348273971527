import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/inputs/neb-textfield';

import { html, css } from 'lit';
import pkg from 'validator';

import Address from '../../neb-lit-components/src/components/field-groups/neb-address';
import { CSS_SPACING } from '../../neb-styles/neb-variables';
import { phone as phoneNum } from '../../neb-utils/masks';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  buttonLocation: { id: 'button-location' },
  buttonOther: { id: 'button-other' },
  addressOther: { id: 'address-other' },
  locationsSelect: { id: 'locations-select' },
  inputOtherBillingName: { id: 'input-other-billing-name' },
  phoneNumber: { id: 'phone-number' },
};

const NEW_ADDRESS_MODEL = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  phone: '',
};
class NebPopupClaimBillingProviderInformation extends NebPopupClaim {
  static get properties() {
    return {
      __locationValue: Object,
      __isOtherLocation: Boolean,
      __errors: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-height: 750px;
          height: 90%;
          max-width: 640px;
          width: 80%;
        }

        .content {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
        }

        .container-buttons {
          display: grid;
          grid-template-columns: 160px auto;
          row-gap: ${CSS_SPACING};
          align-items: flex-start;
          padding-bottom: 30px;
          grid-column-gap: 10px;
          flex: 1 1 0;
          overflow-y: auto;
          overflow-x: hidden;
          padding-right: 5px;
        }

        .billing-info {
          margin-top: 10px;
        }

        p {
          margin: 0;
        }

        .text-input,
        .select-input {
          width: 100%;
          padding-bottom: 10px;
          margin-top: 10px;
        }

        .container-other {
          display: grid;
          align-items: center;
          grid-column: 1 / -1;
          grid-template-columns: 160px auto;
          grid-column-gap: 10px;
        }

        .address {
          grid-column-start: 2;
        }

        .phoneNumber {
          grid-column-start: 2;
          margin-top: 10px;
          width: 48%;
        }

        .buttons-container {
          margin-top: 20px;
          display: flex;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.__formData = {
      billingName: '',
      billingAddress: Address.createModel(),
      billingPhone: '',
    };

    this.__values = Address.createModel();

    this.__state = {
      billingProviderPracticeName: '',
      billingProviderAddress: NEW_ADDRESS_MODEL,
    };

    this.__locationValue = {};

    this.__errors = {
      billingName: false,
      address1: false,
      city: false,
      state: false,
      zipcode: false,
      phone: false,
    };

    this.model = {
      title: '',
      tooltipText: '',
      billingProviderAddress: NEW_ADDRESS_MODEL,
      billingProviderPracticeName: '',
      locationOptions: [],
    };
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeLocationButtonEnabled: () => {
        this.__state = {
          billingProviderAddress: this.model.locationOptions[0].data
            .billingProviderAddress,
          billingProviderPracticeName: this.model.locationOptions[0].data
            .billingProviderPracticeName,
        };

        this.__formData = {
          billingName: '',
          billingAddress: Address.createModel(),
          billingPhone: '',
        };

        this.__locationValue = this.model.locationOptions[0];
      },
      changeLocationSelection: ({ value }) => {
        this.__state = {
          billingProviderAddress: value.data.billingProviderAddress,
          billingProviderPracticeName: value.data.billingProviderPracticeName,
        };

        this.__locationValue = value;
      },
      changeOtherEnabled: () => {
        this.__state = {
          billingProviderPracticeName: '',
          billingProviderAddress: NEW_ADDRESS_MODEL,
        };

        this.__validate();
      },
      changeOtherName: ({ value }) => {
        this.__formData.billingName = value;

        this.__state = {
          ...this.__state,
          billingProviderPracticeName: value,
        };

        this.__validate();
      },
      changeOtherAddress: ({ name, value }) => {
        const addressKey = name.split('.')[1];

        this.__values = {
          ...this.__formData.billingAddress,
          [addressKey]: value,
        };

        this.__formData.billingAddress = this.__values;

        this.__state.billingProviderAddress = {
          address1: this.__values.address1,
          address2: this.__values.address2,
          city: this.__values.city,
          state: this.__values.state,
          zipCode: this.__values.zipcode,
        };

        this.__validate();
      },
      changeOtherPhone: ({ value }) => {
        this.__formData.billingPhone = value;

        this.__state = {
          ...this.__state,
          billingProviderAddress: {
            ...this.__state.billingProviderAddress,
            phone: value,
          },
        };

        this.__validate();
      },
      confirm: () => {
        const {
          billingProviderAddress,
          billingProviderPracticeName,
        } = this.__state;

        if (!this.__hasErrors()) {
          this.onClose({
            billingProviderAddress,
            billingProviderPracticeName,
          });
        }
      },
    };
  }

  firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      const {
        billingProviderPracticeName,
        billingProviderAddress,
      } = this.model;

      this.__state = {
        billingProviderPracticeName,
        billingProviderAddress,
      };

      this.__locationValue = {
        data: {
          billingProviderAddress,
          billingProviderPracticeName,
        },
        label: this.model.billingProviderPracticeName,
      };

      if (this.__isOtherLocation) {
        this.__formData = {
          billingName: billingProviderPracticeName,
          billingAddress: {
            address1: billingProviderAddress.address1,
            address2: billingProviderAddress.address2,
            city: billingProviderAddress.city,
            state: billingProviderAddress.state,
            zipcode: billingProviderAddress.zipCode,
          },
          billingPhone: billingProviderAddress.phone,
        };
      }
    }
  }

  __validate() {
    const { address1, city, state, zipcode } = this.__formData.billingAddress;
    this.__errors = this.__isOtherLocation
      ? {
          billingName: !this.__formData.billingName,
          address1: !address1,
          city: !city,
          state: !state,
          zipcode: !zipcode || !pkg.isPostalCode(zipcode, 'US'),
          phone:
            !this.__formData.billingPhone ||
            !pkg.isMobilePhone(this.__formData.billingPhone),
        }
      : {
          billingName: false,
          address1: false,
          city: false,
          state: false,
          zipcode: false,
          phone: false,
        };
  }

  get __isOtherLocation() {
    const addresses = this.model.locationOptions.map(location =>
      JSON.stringify(location.data.billingProviderAddress),
    );

    const currentAddress = JSON.stringify(this.__state.billingProviderAddress);

    return !addresses.includes(currentAddress);
  }

  get __locationsOption() {
    return html`
      <neb-radio-button
        id="${ELEMENTS.buttonLocation.id}"
        label="Location"
        .onChange="${this.handlers.changeLocationButtonEnabled}"
        .value="${this.__locationValue}"
        .checked="${!this.__isOtherLocation}"
      >
      </neb-radio-button>

      <neb-select
        id="${ELEMENTS.locationsSelect.id}"
        class="select-input"
        .value="${this.__isOtherLocation ? '' : this.__locationValue}"
        .items="${this.model.locationOptions}"
        ?disabled="${this.__isOtherLocation}"
        .onChange="${this.handlers.changeLocationSelection}"
      ></neb-select>
    `;
  }

  get __otherOption() {
    return html`
      <div class="container-other">
        <neb-radio-button
          id="${ELEMENTS.buttonOther.id}"
          class="other-option"
          label="Other"
          .value="${this.__state}"
          .checked="${this.__isOtherLocation}"
          .onChange="${this.handlers.changeOtherEnabled}"
        ></neb-radio-button>

        <neb-textfield
          id="${ELEMENTS.inputOtherBillingName.id}"
          class="text-input"
          label="Provider Billing Name"
          helper="Required"
          .error="${this.__errors.billingName}"
          .value="${this.__formData.billingName}"
          ?disabled="${!this.__isOtherLocation}"
          .onChange="${this.handlers.changeOtherName}"
        ></neb-textfield>

        <neb-address
          id="${ELEMENTS.addressOther.id}"
          class="address"
          name="otherAddress"
          .errors="${this.__errors}"
          .helpers="${
            {
              address1: 'Required',
              city: 'Required',
              state: 'Required',
              zipcode:
                !this.__formData.billingAddress.zipcode ||
                !this.__errors.zipcode
                  ? 'Required'
                  : '#####(-####)',
            }
          }"
          .model="${this.__formData.billingAddress}"
          ?disabled="${!this.__isOtherLocation}"
          .onChange="${this.handlers.changeOtherAddress}"
        ></neb-address>

        <neb-textfield
          id="${ELEMENTS.phoneNumber.id}"
          class="phoneNumber"
          .mask="${phoneNum}"
          .inputMode="numeric"
          .value="${this.__formData.billingPhone}"
          .error="${this.__errors.phone}"
          .onChange="${this.handlers.changeOtherPhone}"
          label="Phone Number"
          helper="${
            !this.__formData.billingPhone || !this.__errors.phone
              ? 'Required'
              : '(###) ###-####'
          }"
          maxLength="14"
          validator="isMobilePhone"
          ?disabled="${!this.__isOtherLocation}"
        ></neb-textfield>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="container-buttons">
        ${this.__locationsOption} ${this.__otherOption}
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-billing-provider-information',
  NebPopupClaimBillingProviderInformation,
);
