import { openError } from '../neb-dialog/neb-banner-state';
import { store } from '../neb-redux/neb-redux-store';

export const getFileURL = buffer => {
  let byteArray;

  if (buffer.data) {
    byteArray = Uint8Array.from(buffer.data).buffer;
  } else {
    byteArray = buffer;
  }

  const file = new Blob([byteArray], {
    type: 'application/pdf',
  });

  return window.URL.createObjectURL(file);
};

export const showLoading = (targetWindow, message = 'Loading...') => {
  targetWindow.document.open();
  targetWindow.document.write(`
    <style type="text/css">
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes svg-animation {
        0% {
          transform: rotateZ(0deg);
        }
        100% {
          transform: rotateZ(360deg);
        }
      }

      @keyframes circle-animation {
        0%,
        25% {
          stroke-dashoffset: 280;
          transform: rotate(0);
        }

        50%,
        75% {
          stroke-dashoffset: 75;
          transform: rotate(45deg);
        }

        100% {
          stroke-dashoffset: 280;
          transform: rotate(360deg);
        }
      }

      .container {
        width: 50px;
        height: 50px;
        margin:auto;
        margin-top: 200px;
        color: rgb(11, 159, 203);
      }

      .circular {
        animation: 2s linear infinite svg-animation;
        height: 100%;
        position: relative;
        width: 100%;
      }
      
      .path {
        animation: 1.4s ease-in-out infinite both circle-animation;
        display: block;
        fill: transparent;
        stroke: currentcolor;
        stroke-dasharray: 303;
        stroke-dashoffset: 280;
        stroke-width: 10px;
        transform-origin: 50% 50%;
      }
    </style>

    <div class="container">
      <svg viewBox="0 0 100 100" class="circular">
        <circle cx="50" cy="50" r="45" class="path"></circle>
      </svg>
    </div>

    <div style="margin-top: 20px; text-align: center; font-family: sans-serif; color: black;">
      ${message}
    </div>
  `);

  targetWindow.document.close();
};

const escapeHtml = unsafe =>
  unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

export const showError = (targetWindow, message = 'Error!') => {
  targetWindow.document.open();
  targetWindow.document.write(`
    <div style="margin-top: 270px; text-align: center; font-family: sans-serif; color: #bf3131;">
      ${escapeHtml(message)}
    </div>
  `);

  targetWindow.document.close();
};

export const printPdf = promise => {
  // Avoid async/await to maintain trusted context
  const newWindow = window.open();
  showLoading(newWindow);
  return promise

    .then(buffer => {
      const fileUrl = getFileURL(buffer);
      newWindow.location = fileUrl;
    })

    .catch(error => {
      showError(newWindow, error.message);
      throw error;
    });
};

export const printStatementPdf = promise => {
  // Avoid async/await to maintain trusted context
  const newWindow = window.open();

  if (!newWindow) {
    return store.dispatch(
      openError('Cannot open PDF tab, please check your pop-up blocker'),
    );
  }

  showLoading(newWindow);
  return promise

    .then(buffer => {
      const fileUrl = getFileURL(buffer);
      newWindow.location = fileUrl;
    })

    .catch(error => {
      console.error(error);
    });
};
