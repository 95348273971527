import ApiClient from '../utils/api-client-utils';
import { strFromQuery } from '../utils/query-params';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const API_VERSION = 4;

// eslint-disable-next-line complexity
export async function fetchRollups(mode, patientId = '', defaultQuery = {}) {
  const query = { ...defaultQuery };

  delete query.hideInactive;
  delete query.limit;
  delete query.sortParams;

  const id = patientId || query.patientId;
  const prefix = id ? `patients/${id}/` : '';
  const suffix = `ledger/${mode}-charges`;
  const route = `${prefix}${suffix}`;
  const path = [route, strFromQuery(query)].filter(item => item).join('?');

  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    cacheKey: path,
    headers: {
      ...(query.providerIds && { providerIds: query.providerIds }),
      ...(query.payerIds && { payerIds: query.payerIds }),
      ...(query.locationIds && { locationIds: query.locationIds }),
      ...(query.caseIds && { caseIds: query.caseIds }),
    },
    version: API_VERSION,
  });

  if (res.totals) return res;

  return {
    count: res.length,
    data: res,
  };
}
