import { css, html, LitElement } from 'lit';

import NebIcon from '../../../../src/components/misc/neb-icon';
import {
  baseStyles,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
} from '../../../../src/styles';
import { RENDERER_SVGS } from '../../../neb-styles/icons';

import './neb-text';

NebIcon.iconSets = {
  neb: RENDERER_SVGS,
};

export const ELEMENTS = {
  checkMarkIcons: { selector: '.icon-checkmark' },
  progressLines: { selector: '.line' },
  stepContainers: { selector: '.container-step' },
  stepIcons: { selector: '.icon-step' },
  stepLabels: { selector: '.label' },
};

class ProgressTracker extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      items: Array,
      step: Number,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .container-step {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 12px;
        }

        .icon-step {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 38px;
          width: 38px;

          color: ${CSS_COLOR_HIGHLIGHT};
          border: 1px solid ${CSS_COLOR_HIGHLIGHT};
          border-radius: 50%;
          font-size: 14px;
        }

        .icon-checkmark {
          width: 36px;
          height: 36px;
          padding: 8px;

          background: ${CSS_COLOR_HIGHLIGHT};
          border-radius: 50%;
          stroke: ${CSS_COLOR_WHITE};
          stroke-width: 2px;
        }

        .label {
          position: absolute;
          width: max-content;
          margin-top: 4px;
          top: 100%;
        }

        .line {
          margin-top: 18px;
          border-top: 2px solid ${CSS_COLOR_GREY_2};
          flex-grow: 1;
        }

        .line[highlighted] {
          border-top: 2px solid ${CSS_COLOR_HIGHLIGHT};
        }

        :host([layout='small']) .icon-step {
          width: 30px;
          height: 30px;
        }

        :host([layout='small']) .icon-checkmark {
          width: 30px;
          height: 30px;
        }

        :host([layout='small']) .label {
          font-size: 12px;
        }

        :host([layout='small']) .line {
          margin-top: 16px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.layout = '';
    this.items = [];
    this.step = 1;
  }

  __renderIcon(index) {
    return this.step <= index
      ? html`
          <div class="icon-step">${index}</div>
        `
      : html`
          <neb-icon class="icon-checkmark" icon="neb:checkmark"></neb-icon>
        `;
  }

  __renderStep(label, index) {
    return html`
      <div id="container-step-${index}" class="container-step">
        ${this.__renderIcon(index)}

        <neb-text class="label">${label}</neb-text>
      </div>
    `;
  }

  __renderSteps() {
    return this.items.map((label, index) => {
      const updatedIndex = index + 1;

      return index === 0
        ? this.__renderStep(label, updatedIndex)
        : html`
            <div class="line" ?highlighted="${this.step > updatedIndex}"></div>

            ${this.__renderStep(label, updatedIndex)}
          `;
    });
  }

  render() {
    return html`
      <div class="container">${this.__renderSteps()}</div>
    `;
  }
}

customElements.define('neb-progress-tracker', ProgressTracker);
