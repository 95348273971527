import { LitElement, html } from 'lit';

import { NebSwipeDetection } from './NebSwipeDetection';

const CSS_TRANSITON_DELAY_SEC = 0.3;
const TRANSITON_DELAY_JS_MS = CSS_TRANSITON_DELAY_SEC * 1000;
const Mode = Object.freeze({
  START: 'START',
  DRAG: 'DRAG',
  SWIPING_RIGHT: 'SWIPING_RIGHT',
  SWIPING_LEFT: 'SWIPING_LEFT',
});
/**
 * This component provides 'swipe detection', specifically swiping left or swiping right.
 * Usage:
 * <neb-cal-swipe>
 *   child content will be 'swipable'.
 * </neb-cal-swipe>
 *
 * Callers can listen to 'neb-swipe-left' and 'neb-swipe-right' events and handle it accordingly.
 * This class also includes some optional rudimentary animation.
 */

class NebCalSwipe extends NebSwipeDetection(LitElement) {
  static get properties() {
    return {
      /**
       * If animating, this determines % of screen width before we start initial drag animation.
       * Defaults to 10, meaning the sideways drag animation will not start until the user has dragged
       * at least 10% of the screen width.
       */
      swipeSensitivity: {
        type: Number,
      },
      targetDate: {
        type: Date,
      },
      mode: {
        type: String,
        reflect: true,
      },
      __position: {
        type: Number,
      },
      __failedToSwipeThreshold: {
        type: Boolean,
      },
    };
  }

  render() {
    return html`
      <style is="custom-style">
        .outer {
          max-width: 100%;
          width: 100%;
          overflow-x: hidden;
          position: relative;
        }
        :host(:not([mode='START'])) .outer {
          border-left: 1px solid #dedfe0;
        }

        .left-div {
          position: absolute;
          right: 120%;
          width: 100%;
          top: 0;
        }
        .right-div {
          position: absolute;
          left: 120%;
          width: 100%;
          top: 0;
        }
        .center-div {
          width: 100%;
        }
      </style>
      <div id="outer" class="outer">
        <div
          id="container"
          style="${
            this.__computeContainerStyle(
              this.mode,
              this.__failedToSwipeThreshold,
              this.__position,
            )
          }"
        >
          <div id="leftDiv" class="left-div"><slot name="left"></slot></div>
          <div id="centerDiv" class="center-div">
            <slot name="center"></slot>
          </div>
          <div id="rightDiv" class="right-div"><slot name="right"></slot></div>
        </div>
      </div>
    `;
  }

  constructor() {
    super();
    this.swipeSensitivity = 10;
    this.mode = Mode.START;
    this.__position = 0;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('neb-swipe-abort', this.__handleNebSwipeAbort);
    this.addEventListener('neb-swipe-left', this.__handleSwipeLeft);
    this.addEventListener('neb-swipe-right', this.__handleSwipeRight);
  }

  __handleSwipeLeft() {
    this.mode = Mode.SWIPING_LEFT;

    const goLeft = () => {
      this.__position = -120;
      this.dispatchEvent(
        new CustomEvent('neb-swipe-left-complete', {
          bubbles: true,
          composed: true,
        }),
      );
    };

    setTimeout(goLeft, TRANSITON_DELAY_JS_MS);
  }

  __handleSwipeRight() {
    this.mode = Mode.SWIPING_RIGHT;

    const goRight = () => {
      this.__position = 120;
      this.dispatchEvent(
        new CustomEvent('neb-swipe-right-complete', {
          bubbles: true,
          composed: true,
        }),
      );
    };

    setTimeout(goRight, TRANSITON_DELAY_JS_MS);
  }

  __handleNebSwipeAbort() {
    this.__failedToSwipeThreshold = true;
  }

  updated(changed) {
    if (changed.has('targetDate')) {
      this.mode = Mode.START;
    }
  }

  _shouldHandleTouchEnd() {
    return this.mode !== Mode.SWIPING_LEFT && this.mode !== Mode.SWIPING_RIGHT;
  }

  __computeContainerStyle(mode, failedToSwipeThreshold, position) {
    let style = '';

    switch (mode) {
      case Mode.START:
        if (failedToSwipeThreshold) {
          style = 'transform: none; transition: transform .2s ease-out';
        } else {
          style = 'transform: none';
        }

        break;

      case Mode.SWIPING_LEFT:
        style = `transform: translateX(-120%); transition: transform ${CSS_TRANSITON_DELAY_SEC}s linear`;
        break;

      case Mode.SWIPING_RIGHT:
        style = `transform: translateX(120%); transition: transform ${CSS_TRANSITON_DELAY_SEC}s linear`;
        break;

      case Mode.DRAG:
      default:
        style = `transform: translateX(${position}%)`;
        break;
    }

    return style;
  }

  _onAfterTouchStart(_e) {
    this.__failedToSwipeThreshold = false;
  }

  _onAfterTouchMove(e) {
    const position = (e.detail.dx / this._getWidth()) * 100;

    if (this.mode === Mode.START) {
      if (Math.abs(position) >= this.swipeSensitivity) {
        this.__position = position;
        this.mode = Mode.DRAG;
      } // Else, no-op.  Wait until the user has at least swiped the threshold.
    } else if (this.mode === Mode.DRAG) {
      this.__position = position;
    }
  }

  _onAfterTouchEnd(_e) {
    if (this.mode === Mode.DRAG) {
      this.mode = Mode.START;
    }
  }
}

customElements.define('neb-cal-swipe', NebCalSwipe);
