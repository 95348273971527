import moment from 'moment-timezone';

import { KEY_LOCAL_PRACTICE_TIMEZONE } from '../neb-practice-information/actions/practice-information';
import { store } from '../neb-redux/neb-redux-store';

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const parseDate = (dateStr = moment()) => {
  let practiceTimezone;

  if (!dateStr) return null;

  if (window.IS_WTR_TESTING_CONTEXT) {
    return moment.tz(dateStr, DEFAULT_TIMEZONE);
  }

  if (store.getState().practiceInformation.item) {
    practiceTimezone = store.getState().practiceInformation.item.timezone;
  } else if (store.getState().practiceInfo.practiceInfoItems.length > 0) {
    practiceTimezone = store.getState().practiceInfo.practiceInfoItems[0]
      .timezone;
  } else {
    practiceTimezone = window.localStorage.getItem(KEY_LOCAL_PRACTICE_TIMEZONE);
  }

  return moment.tz(dateStr, practiceTimezone);
};

export const dateToRaw = (date, isEndDay = false) => {
  if (!date) return null;

  if (!moment.isMoment(date)) {
    return isEndDay
      ? parseDate(date)
          .endOf('day')
          .format()
      : parseDate(date)
          .startOf('day')
          .format();
  }

  return isEndDay ? date.endOf('day').format() : date.startOf('day').format();
};

export const FORMATS = Object.freeze({
  timeAmPm: 'h:mm A',
});
