import '../inputs/neb-textfield';

import { html, css, LitElement, unsafeCSS } from 'lit';
import { join } from 'lit/directives/join.js';

import { ChargeResponsibilityTable } from '../../../../../src/components/tables/charges/neb-table-charges-responsibility';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { centsToCurrency } from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import { getDebitPaymentIds } from '../../../../neb-utils/neb-ledger-util';
import { map } from '../../../../neb-utils/utils';

const CONFIG = [
  {
    label: 'Prim. Payer',
    width: 120,
  },
  {
    label: 'Prim. Owed',
    width: 128,
  },
  {
    label: 'Prim. Paid',
    width: 80,
  },
  {
    label: 'Pymt ID(s)',
    width: 100,
  },
];

export const getBaseConfig = showERAsAndEOBsAssociations => [
  ...CONFIG,
  ...(showERAsAndEOBsAssociations ? [{ label: 'ERA/EOB', width: 50 }] : []),
];

const CONFIG_ITEM_ALLOCATED = {
  label: 'Allocated',
  width: 96,
  flex: css`0 0 96px`,
};

export const ELEMENTS = {
  primaryPayerId: { id: 'primary-payer-id' },
  owedAmount: { id: 'owed-amount' },
  paidAmount: { id: 'paid-amount' },
  paymentIds: { id: 'payment-ids' },
  associatedERAsAndEOBs: { id: 'era-eob' },
  paymentNumbers: { selector: '[id^=payment-number]' },
  allocated: { id: 'allocated-id' },
};

export class NebPrimaryPayerCell extends LitElement {
  static get properties() {
    return {
      name: String,
      allocatableId: String,
      model: Object,
      errors: Object,
      primaryPayer: Object,
      showAllocated: { type: Boolean, reflect: true },
      disabled: { type: Boolean, reflect: true },
      isAllocatableByGroup: Boolean,
      showERAsAndEOBsAssociations: Boolean,
      associatedERAsAndEOBs: Array,
    };
  }

  static buildConfig(showAllocated, showERAsAndEOBsAssociations) {
    const BASE_CONFIG = getBaseConfig(showERAsAndEOBsAssociations);
    return showAllocated
      ? [
          ...BASE_CONFIG.slice(0, 2),
          CONFIG_ITEM_ALLOCATED,
          ...BASE_CONFIG.slice(2, BASE_CONFIG.length),
        ]
      : BASE_CONFIG;
  }

  static get styles() {
    const BASE_CONFIG = getBaseConfig(this.showERAsAndEOBsAssociations);
    const COLUMN_WIDTHS = unsafeCSS(
      BASE_CONFIG.map(v => `${v.width}px`).join(' '),
    );
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-gap: 0 ${CSS_SPACING};
          grid-template-columns: var(
            --primary-payer-grid-columns,
            ${COLUMN_WIDTHS}
          );
          align-items: baseline;
        }

        .nowrap {
          white-space: nowrap;
        }

        .payment-ids {
          display: flex;
          flex-wrap: wrap;
        }

        .separator {
          margin-right: 5px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      id: '',
      debit: {
        allocated: '$0.00',
        allocations: [],
        amount: '$0.00',
        id: '',
        payerId: null,
      },
      patientInsuranceId: { data: { id: '' }, label: '' },
      codePaymentId: { data: { id: '' }, label: '' },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.name = '';
    this.showAllocated = false;
    this.disabled = false;
    this.model = ChargeResponsibilityTable.createItem();
    this.errors = map(this.model, () => '');
    this.primaryPayer = null;
    this.allocatableId = null;
    this.isAllocatableByGroup = false;
    this.showERAsAndEOBsAssociations = false;
    this.associatedERAsAndEOBs = [];

    this.onChange = () => {};

    this.onBlur = () => {};

    this.onClickPaymentId = () => {};

    this.onSelectPayerPlan = () => {};

    this.onClickERAsEOBs = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e => {
        const namespace = [this.name, e.name].filter(item => item).join('.');
        this.onChange({ ...e, name: namespace });
      },
      blur: e => {
        const namespace = [this.name, e.name].filter(item => item).join('.');
        this.onBlur({ ...e, name: namespace });
      },
      clickPayerPlan: () => this.onSelectPayerPlan(this.primaryPayer),
      clickPrimaryPayerPymtId: async ({ name: paymentId }) => {
        const {
          credit: { payment },
        } = this.model.debit.allocations.find(
          allocation => allocation.credit.payment.id === paymentId,
        );

        await this.onClickPaymentId(payment);
      },
      clickERAsEOBs: () => this.onClickERAsEOBs(),
    };
  }

  update(changedProps) {
    if (
      changedProps.has('showAllocated') ||
      changedProps.has('showERAsAndEOBsAssociations')
    ) {
      const config = this.constructor.buildConfig(
        this.showAllocated,
        this.showERAsAndEOBsAssociations,
      );

      const columnsWidth = config.map(v => `${v.width}px`).join(' ');
      this.style.setProperty('--primary-payer-grid-columns', columnsWidth);
    }

    super.update(changedProps);
  }

  __renderOwedAmount() {
    return html`
      <neb-textfield
        id="${ELEMENTS.owedAmount.id}"
        label=" "
        helper=" "
        name="debit.amount"
        .value="${
          this.primaryPayer && this.model ? this.model.debit.amount : '$0.00'
        }"
        .error="${
          this.primaryPayer && this.model ? !!this.errors.debit.amount : false
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .onChange="${this.__handlers.change}"
        .onBlur="${this.__handlers.blur}"
        ?disabled="${this.disabled || !this.primaryPayer}"
      ></neb-textfield>
    `;
  }

  __checkAllocationDisabled() {
    if (this.disabled) return true;
    if (this.isAllocatableByGroup) return false;

    return (
      ((this.model && this.model.debit.payerId) || null) !==
      (this.allocatableId || null)
    );
  }

  __renderAllocated() {
    return this.showAllocated && this.primaryPayer
      ? html`
          <neb-textfield
            id="${ELEMENTS.allocated.id}"
            class="field"
            label=" "
            helper=" "
            name="debit.allocated"
            .value="${this.model && this.model.debit.allocated}"
            .error="${this.model && !!this.errors.debit.allocated}"
            .mask="${currency}"
            .inputMode="${'numeric'}"
            .onChange="${this.__handlers.change}"
            .onBlur="${this.__handlers.blur}"
            ?disabled="${this.__checkAllocationDisabled()}"
          ></neb-textfield>
        `
      : '';
  }

  __renderLinkCell({ value, onClick, id, name, icon }) {
    return html`
      <neb-text
        id="${id}"
        bold
        link
        .name="${name}"
        .onClick="${onClick}"
        trailingIcon="${icon}"
        class="link-cell"
        >${value}</neb-text
      >
    `;
  }

  __renderAlias() {
    return this.__renderLinkCell({
      value: this.primaryPayer ? this.primaryPayer.alias : '',
      id: ELEMENTS.primaryPayerId.id,
      name: ELEMENTS.primaryPayerId.id,
      onClick: this.__handlers.clickPayerPlan,
    });
  }

  __renderPaymentIds() {
    const paymentIds =
      this.primaryPayer && this.model && this.model.debit
        ? getDebitPaymentIds(this.model.debit.allocations)
        : [];

    return html`
      <div id="${ELEMENTS.paymentIds.id}" class="payment-ids">
        ${
          join(
            paymentIds.map(({ id, paymentNumber: value, icon }) =>
              this.__renderLinkCell({
                value,
                id: `payment-number-${id}`,
                icon,
                name: id,
                onClick: this.__handlers.clickPrimaryPayerPymtId,
              }),
            ),
            html`
              <span class="separator">,</span>
            `,
          )
        }
      </div>
    `;
  }

  __renderERAsAndEOBs() {
    return this.showERAsAndEOBsAssociations
      ? this.__renderLinkCell({
          value: this.associatedERAsAndEOBs.length ? '✓' : '',
          id: 'era-eob',
          name: 'era-eob',
          onClick: this.__handlers.clickERAsEOBs,
        })
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderAlias()} ${this.__renderOwedAmount()}
        ${this.__renderAllocated()}

        <span id="${ELEMENTS.paidAmount.id}"
          >${
            this.primaryPayer && this.model
              ? centsToCurrency(
                  this.model.debit.allocations.reduce(
                    (sum, a) => sum + a.amount,
                    0,
                  ),
                )
              : ''
          }</span
        >

        ${this.__renderPaymentIds()} ${this.__renderERAsAndEOBs()}
      </div>
    `;
  }
}

window.customElements.define('neb-primary-payer-cell', NebPrimaryPayerCell);
