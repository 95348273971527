import '../../../../../src/components/controls/inputs/neb-checkbox';

import { LitElement, html, css } from 'lit';

import {
  baseStyles,
  CSS_COLOR_DISABLED_TEXT,
  CSS_FONT_SIZE_BODY,
} from '../../../../../src/styles';

export const ELEMENTS = {
  checkbox: { id: 'checkbox' },
  container: { id: 'container' },
  text: { id: 'text' },
};

class MultiSelectItem extends LitElement {
  static get properties() {
    return {
      index: Number,
      model: Object,
      disabled: {
        reflect: true,
        type: Boolean,
      },
      selected: {
        reflect: true,
        type: Boolean,
      },
      wrapText: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
          min-width: 0;
        }

        .container {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 0 16px;
        }

        .checkbox {
          position: relative;
          margin-right: 12px;
        }

        .text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          min-width: 0;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        :host([disabled]) .text {
          color: ${CSS_COLOR_DISABLED_TEXT};
        }

        :host([wrapText]) .text {
          white-space: normal;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.index = -1;
    this.invalid = false;
    this.disabled = false;
    this.selected = false;
    this.model = {};
    this.wrapText = false;

    this.onChange = () => {};

    this.onRenderItem = null;
  }

  __initHandlers() {
    this.__handlers = {
      click: e => {
        if (!this.disabled) {
          if (e.stopPropagation) {
            e.stopPropagation();
          }

          this.onChange(this.index, !this.selected);
        }
      },
    };
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.click}"
      >
        <neb-checkbox
          id="${ELEMENTS.checkbox.id}"
          class="checkbox"
          ?checked="${this.selected}"
          ?disabled="${this.disabled}"
        ></neb-checkbox>

        ${
          this.onRenderItem
            ? this.onRenderItem(this.model, -1, this.index)
            : html`
                <span id="${ELEMENTS.text.id}" class="text">${this.model}</span>
              `
        }
      </div>
    `;
  }
}

window.customElements.define('neb-select-multi-item', MultiSelectItem);
