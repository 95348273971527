import '../neb-button';
import '../neb-popup-header';
import '../neb-table-readonly';
import '../tables/neb-table-payment-transaction';
import '../inputs/neb-textarea';
import '../neb-header';
import '../controls/neb-button-bar';
import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { getLocations } from '../../../../../src/api-clients/locations';
import { UpdateNotificationService } from '../../../../../src/services/update-notifications';
import {
  validateAllowedAgainstTotalOwedV2,
  owedGrandTotalValidatorV2,
  getPatientDebits,
  isPatientRespDebit,
  isPayerRespDebit,
  mapDebitsForEditPopup,
  mapDebitAmountsToCents,
  buildDebitAllocationAmounts,
  mapAdjustmentsForEditPopup,
  calculateDebitsOwed,
  calculateDebitsPaid,
  getPrimaryPayerDebits,
  hasOnlyOnePaidPkgDebit,
  getSecondaryPayerDebits,
  validatePatientAllocatedAmountAgainstAvailableAmount,
  validatePatientAllocatedAmountAgainstOwedAmount,
  validatePatientAllocatedAmountAgainstAvailableOwedAmount,
  validatePatientOwedAmountAgainstAllocatedAmount,
  mapPatientInsurancesForEditPopup,
  rowHasPackageCreditsOrAdjustments,
  calculateBalancedAllowedAmount,
  calculateBalancedAdjustmentAmount,
  calculateAdjustmentsSum,
} from '../../../../../src/utils/allocate-charges/neb-allocate-charge-util';
import { openPayment } from '../../../../../src/utils/payment-util';
import { getLedgerInvoiceItems } from '../../../../neb-api-client/src/invoice-api-client';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getPaymentDetail } from '../../../../neb-api-client/src/payments-api-client';
import { getProviderUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import {
  toFormatDate,
  MONTH_DAY_YEAR,
} from '../../../../neb-input/nebFormatUtils';
import { openDirtyPopup } from '../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_FONT_WEIGHT_BOLD,
  CSS_BORDER_GREY_1,
  CSS_FONT_SIZE_HEADER,
} from '../../../../neb-styles/neb-variables';
import {
  BILLING_NOTE_TYPES,
  CODE_WRITE_OFFS,
  CODE_PAYMENTS,
} from '../../../../neb-utils/constants';
import { parseDate } from '../../../../neb-utils/date-util';
import { PAYMENT_METHODS } from '../../../../neb-utils/enums';
import {
  centsToCurrency,
  centsToCurrencyWithNegative,
  currencyToCents,
  currencyToCentsWithNegative,
  objToName,
} from '../../../../neb-utils/formatters';
import {
  fetchInsuranceItems,
  validateAllowedAgainstBilled,
  checkForWarnings,
  BILL_TYPE,
} from '../../../../neb-utils/neb-ledger-util';
import {
  isPaymentRefunded,
  isElectronicPayment,
  isPartiallyOrFullyAllocated,
} from '../../../../neb-utils/neb-payment-util';
import * as selectors from '../../../../neb-utils/selectors';
import { map } from '../../../../neb-utils/utils';
import { required, genRequireSelect } from '../../../../neb-utils/validators';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { POPOVER_POSITION } from '../neb-date-picker';
import { AllocationChargesTable2 } from '../tables/neb-table-allocation-charges-2';

import Form, { ELEMENTS as ELEMENTS_BASE } from './neb-form';

const NO_CHARGES_TO_EDIT_OR_REMOVE_MESSAGE =
  'There are no charges for the selected parameters.';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  header: {
    id: 'header',
  },
  subheader: {
    id: 'subheader',
  },
  buttonBar: {
    id: 'button-bar',
  },
  paymentDetail: {
    id: 'payment-detail',
  },
  transactionDetail: {
    id: 'transaction-detail',
  },
  paymentActionDetail: {
    id: 'payment-action-detail',
  },
  headerAllocatedCharges: {
    id: 'allocated-charges-header',
    title: 'Allocated Charges',
    description:
      'Select charges to edit Allocated amounts, edit Payer and Patient Owed amounts and Adjustments as needed.',
  },
  headerPreviewAllocatedCharges: {
    id: 'allocated-preview-charges-header',
    title: 'Apply Recommended Allocation',
    description:
      'Select the charges to review and apply the recommended payment allocation.',
  },
  dateOfTransactionFrom: { id: 'date-of-transaction-from' },
  dateOfTransactionTo: { id: 'date-of-transaction-to' },
  selectPatient: { id: 'select-payer' },
  selectLocation: { id: 'select-location' },
  allocationChargesTable: { id: 'allocation-charges-table' },
  removeAllocation: {
    id: 'remove-allocation',
    label: 'Remove Allocation',
  },
};

const CONFIRM_REMOVE_ALLOCATION = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Remove Allocation',
  message: html`
    Are you sure you want to remove the selected payments allocated amount from
    the selected charge(s)?
  `,
};

const FOCUS = 'focus';

export default class NebFormPaymentView2 extends Form {
  static get properties() {
    return {
      __paymentModel: Array,
      __paymentActionDetail: Array,
      __selectIndexes: Array,
      __uncheckedIds: Array,
      __menuItemsMap: Object,
      __isAutoAllocated: Boolean,
      practiceUsers: Array,
      itemFilters: Object,
      locations: Array,
      patients: Array,
      paymentTypes: Array,
      adjustmentTypes: Array,
      paymentDetail: Object,
      readonly: { type: Boolean, reflect: true },
      hasRelationships: Boolean,
      hasFifo: Boolean,
      patientsHash: Object,
      payers: Array,
      title: Object,
      hasRCMSecondaryField: Boolean,
    };
  }

  static createModel() {
    return {
      paymentTransaction: [
        {
          amount: 0,
          cardSaleId: null,
          authEFT: null,
          maskedCardDescription: null,
          electronicPaymentId: null,
          electronicReferenceId: null,
          referenceId: null,
          dateOfServiceFrom: null,
          dateOfServiceTo: null,
          method: PAYMENT_METHODS.CASH,
          payer: null,
          payerPlanId: '',
          paymentType: null,
          transactionDate: null,
          patientName: null,
          patientOnline: false,
          postedById: null,
        },
      ],
      items: [],
      locations: [],
    };
  }

  createSelectors() {
    return {
      children: {
        paymentTransaction: {
          children: {
            $: {
              children: {
                amount: [
                  required(),
                  {
                    error: ' ',
                    validate: v => v > 0,
                  },
                ],
                authEFT: {
                  format: v => v || '',
                  unformat: v => (v === '' ? null : v),
                },
                method: [required()],
                paymentType: [required()],
                transactionDate: {
                  validators: [required()],
                },
                dateOfServiceFrom: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.model.paymentTransaction[0].dateOfServiceFrom
                          ? !!v
                          : true,
                    },
                  ],
                },
                dateOfServiceTo: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.model.paymentTransaction[0].dateOfServiceFrom
                          ? !!v
                          : true,
                    },
                  ],
                },
                payer: {
                  clipErrors: true,
                  clipPristine: true,
                  validators: [required(), genRequireSelect()],
                },
              },
            },
          },
        },
        items: {
          children: {
            $: {
              children: {
                allowedAmount: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    validateAllowedAgainstBilled(
                      'The total Owed amounts must equal the Allowed amount',
                    ),
                  ],
                }),
                patientAdjustment: selectors.currencyWithNegative({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    owedGrandTotalValidatorV2(
                      "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                    ),
                  ],
                }),
                patientOwed: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    validateAllowedAgainstTotalOwedV2(
                      'The total Owed amounts must be equal to or less than the Allowed amount.',
                    ),
                    owedGrandTotalValidatorV2(
                      "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                    ),
                    {
                      error:
                        'The allocated amount may not exceed the patient’s owed amount.',
                      validate: (_, idx, state) =>
                        validatePatientOwedAmountAgainstAllocatedAmount(
                          idx[1],
                          this.model.items,
                          state.items,
                        ),
                    },
                  ],
                }),
                primaryOwed: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    owedGrandTotalValidatorV2(
                      "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                    ),
                  ],
                }),
                patientAllocated: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [
                    {
                      error:
                        'The allocated amount may not exceed the owed or available amount.',
                      validate: (_, idx, state) =>
                        validatePatientAllocatedAmountAgainstAvailableOwedAmount(
                          idx[1],
                          this.paymentDetail.available,
                          this.model.items,
                          state.items,
                        ),
                    },
                    {
                      error:
                        'The allocated amount may not exceed the patient’s available amount.',
                      validate: (_, idx, state) =>
                        validatePatientAllocatedAmountAgainstAvailableAmount(
                          idx[1],
                          this.paymentDetail.available,
                          this.model.items,
                          state.items,
                        ),
                    },
                    {
                      error:
                        'The allocated amount may not exceed the patient’s owed amount.',
                      validate: (_, idx, state) =>
                        validatePatientAllocatedAmountAgainstOwedAmount(
                          idx[1],
                          this.model.items,
                          state.items,
                        ),
                    },
                  ],
                }),
                primaryAllocated: selectors.currency({
                  validateManually: true,
                  validateRaw: true,
                  validators: [],
                }),
                patientPaid: selectors.currency({
                  unsafe: true,
                  ignorePristine: true,
                }),
                primaryPaid: selectors.currency({
                  unsafe: true,
                  ignorePristine: true,
                }),
                nonPrimaryPayerDebits: {
                  createItem: () => ({
                    patientInsuranceId: '',
                    payerId: '',
                    secondaryAllocated: 0,
                    secondaryOwed: 0,
                    secondaryPaid: 0,
                    secondaryPayer: { alias: '' },
                  }),
                  children: {
                    $: {
                      children: {
                        secondaryOwed: {
                          validators: [
                            owedGrandTotalValidatorV2(
                              "The total Owed amounts plus Adjustments must equal the charge's Billed amount plus Tax.",
                            ),
                          ],
                        },
                      },
                    },
                  },
                },
                adjustments: AllocationChargesTable2.createAdjustmentsSelectors(),
                debits: AllocationChargesTable2.createDebitSelectors(),
                patient: { unsafe: true, clipPristine: true },
                locations: selectors.multiSelect(this.locations, []),
              },
            },
          },
        },
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.payers = [];
    this.locations = [];
    this.patients = [];
    this.readonly = false;
    this.hasFifo = false;
    this.hasRelationships = false;
    this.paymentTypes = [];
    this.adjustmentTypes = [];
    this.patientsHash = {};
    this.hasRCMSecondaryField = false;
    this.__isAutoAllocated = false;
    this.itemFilters = {
      dateOfTransactionFrom: null,
      dateOfTransactionTo: null,
      patient: '',
      locations: [],
    };

    this.paymentDetail = {
      paymentId: '',
      amount: 0,
    };

    this.__paymentModel = [];
    this.__preallocated = [];
    this.__selectIndexes = [];
    this.__uncheckedIds = [];
    this.__disablePaymentOptions = false;
    this.__disablePrintReceipt = false;

    this.title = {};

    this.__menuItemsMap = {
      insurances: [],
      adjustments: [],
      paymentTypes: [],
    };

    this.__paymentActionDetail = [
      {
        date: null,
        by: {
          name: {
            last: '',
            first: '',
          },
        },
        reason: {
          code: '',
          description: '',
        },
        method: '',
        transactionId: '',
        amount: 0,
      },
    ];

    this.practiceUsers = [];

    this.onDismiss = () => {};

    this.onVoid = () => {};

    this.onRefund = () => {};

    this.onPrint = () => {};

    this.onUpdateERAPayment = () => {};

    this.onEmail = () => {};

    this.onPrintForLocation = () => {};

    this.onAllocatePayment = () => {};

    this.onSplitPayment = () => {};

    this.onAutoAllocate = () => {};

    this.onRemoveAllocation = () => {};

    this.onItemFiltersChange = () => {};

    this.onUpdate = () => {};

    this.__notificationService = new UpdateNotificationService({
      callback: () => {
        this.rerenderPatientInfo();
      },
    });
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      dismissPayment: () => this.onDismiss(),
      voidPayment: () => this.onVoid(),
      refundPayment: () => this.onRefund(),
      allocatePayment: () => this.onAllocatePayment(),
      splitPayment: () => this.onSplitPayment(),
      autoAllocate: () => {
        if (!this.__isAutoAllocated) this.onAutoAllocate();
        this.__isAutoAllocated = true;
      },
      printPayment: () => this.onPrint(),
      emailPayment: () => this.onEmail(),
      edit: async () => {
        const result = await openOverlay(
          OVERLAY_KEYS.UPDATE_PAYER_PAYMENT,
          this.paymentDetail,
        );

        if (result) this.onUpdate();
      },
      checkItem: (index, checked) => {
        this.__selectIndexes.splice(index, 1, !checked);
        this.__selectIndexes = [...this.__selectIndexes];

        if (checked) this.__revertRow(index);
      },
      removeAllocation: async () => {
        const confirmed = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          CONFIRM_REMOVE_ALLOCATION,
        );

        if (confirmed) {
          const lineItems = this.formService.build().items;
          const selectedLineItems = lineItems.filter(
            (_, index) => this.__selectIndexes[index],
          );

          this.onRemoveAllocation(selectedLineItems);
        }
      },
      changeAllowed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);
        this.formService.apply(`items.${rowIndex}.allowedAmount`, value);

        const { debits } = getPatientDebits(row);

        if (!rowHasPackageCreditsOrAdjustments(row, debits)) {
          const newAdjustmentAmount = calculateBalancedAdjustmentAmount(
            row.billedAmount,
            row.taxAmount,
            currencyToCentsWithNegative(value),
          );

          this.__updateAdjustmentAmount(row, rowIndex, newAdjustmentAmount);
        }

        this.formService.validate();
      },
      openPatientOwedPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { debits } = getPatientDebits(row);

        const initialCodes = mapDebitsForEditPopup(debits);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Patient Responsibility',
            initialCodes,
            displayPaidAmount: true,
            paymentCodes: this.__menuItemsMap.paymentTypes,
            billType: row.billType,
          },
        );

        this.shadowRoot
          .getElementById(ELEMENTS.allocationChargesTable.id)
          .focusPatientOwed(rowIndex);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'debits',
            result.itemsToDelete,
          );

          this.__addIndividualItems(rowIndex, 'patientOwed', result.itemsToAdd);
          this.__editIndividualItems(rowIndex, 'debits', result.itemsToEdit);

          this.__syncOwedAndPaid({ rowIndex, type: 'patient' });
          this.__clearAllocatedIfDebitsRemoved({ rowIndex, type: 'patient' });
          this.__syncAllocated({ rowIndex, field: 'patientAllocated' });

          this.formService.validate();
        }
      },
      openSecondaryOwedPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const {
          nonPrimaryPayerDebitIdx,
        } = this.__getNonPrimaryPayerDebitIndexFromName(name);

        const {
          patientInsuranceId: nonPrimaryPatientInsuranceId,
          payerId,
        } = row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

        const currentInsurance = this.__menuItemsMap.insurances.find(
          ins => ins.data.id === nonPrimaryPatientInsuranceId,
        );

        const { debits } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        );

        const initialCodes = mapDebitsForEditPopup(debits);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Secondary Responsibility',
            initialCodes,
            displayPaidAmount: true,
            paymentCodes: this.__menuItemsMap.paymentTypes,
            plans: mapPatientInsurancesForEditPopup([currentInsurance]),
          },
        );

        this.shadowRoot
          .getElementById(ELEMENTS.allocationChargesTable.id)
          .focusSecondaryOwed(rowIndex, nonPrimaryPayerDebitIdx);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'debits',
            result.itemsToDelete,
          );

          const itemsToAdd = result.itemsToAdd.map(item => ({
            ...item,
            patientInsuranceId: nonPrimaryPatientInsuranceId,
            payerId,
          }));

          this.__addIndividualItems(
            rowIndex,
            'secondaryOwed',
            itemsToAdd,
            nonPrimaryPatientInsuranceId,
          );

          this.__editIndividualItems(rowIndex, 'debits', result.itemsToEdit);

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          if (this.__debitsChanged(result, debits)) {
            this.__syncAllocated({
              rowIndex,
              field: 'secondaryAllocated',
              nonPrimaryPatientInsuranceId,
              nonPrimaryPayerDebitIdx,
            });
          }
          this.formService.validate();
        }
      },
      changePatientOwed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (value === this.state.items[rowIndex].patientOwed) return;

        let { debits, debitIndexes } = getPatientDebits(row);

        if (debits.length === 0 && debitIndexes.length === 0) {
          this.formService.addItem(`items.${rowIndex}.debits`);

          ({ debits, debitIndexes } = getPatientDebits(row));
        }

        if (
          debits.length === 1 &&
          debitIndexes.length === 1 &&
          this.state.items[rowIndex].debits[debitIndexes].codePaymentId === ''
        ) {
          this.formService.apply(
            `items.${rowIndex}.debits.${debitIndexes}.codePaymentId`,
            CODE_PAYMENTS.GENERAL_PAYMENT.id,
          );
        }

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `items.${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(`items.${rowIndex}.patientOwed`, value);

        this.__syncAllocated({ rowIndex, field: 'patientAllocated' });

        this.formService.validate();
      },
      changePatientAllocated: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (value === this.state.items[rowIndex].patientAllocated) return;

        this.formService.apply(`items.${rowIndex}.patientAllocated`, value);

        this.__syncAllocated({ rowIndex, field: 'patientAllocated' });

        this.formService.validate();
      },
      changePrimaryOwed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const { debits, debitIndexes } = getPrimaryPayerDebits(row);

        if (debits.length !== 1 && debitIndexes.length !== 1) return;

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `items.${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(`items.${rowIndex}.primaryOwed`, value);

        this.__syncAllocated({ rowIndex, field: 'primaryAllocated' });

        this.formService.validate();
      },
      changeSecondaryOwed: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const {
          nonPrimaryPayerDebitIdx,
        } = this.__getNonPrimaryPayerDebitIndexFromName(name);

        const nonPrimaryPatientInsuranceId =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

        const { debits, debitIndexes } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        );

        if (debits.length > 1) return;

        if (
          debits.length > 1 ||
          (currencyToCents(value) === 0 && debits.length === 0)
        ) {
          return;
        }

        if (currencyToCents(value) > 0 && debits.length === 0) {
          const { payerId } = row.nonPrimaryPayerDebits[
            nonPrimaryPayerDebitIdx
          ];

          this.__addIndividualItems(rowIndex, 'secondaryOwed', [
            {
              amount: currencyToCents(value),
              codeId: CODE_PAYMENTS['PR-45'].id,
              individualRowId: '',
              patientInsuranceId: nonPrimaryPatientInsuranceId,
              payerId,
            },
          ]);

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          this.__syncAllocated({
            rowIndex,
            field: 'secondaryAllocated',
            nonPrimaryPatientInsuranceId,
            nonPrimaryPayerDebitIdx,
          });

          this.formService.validate();
          return;
        }

        const [debitIndex] = debitIndexes;

        this.formService.apply(
          `items.${rowIndex}.debits.${debitIndex}.amount`,
          value,
        );

        this.formService.apply(
          `items.${rowIndex}.nonPrimaryPayerDebits.${nonPrimaryPayerDebitIdx}.secondaryOwed`,
          currencyToCents(value),
        );

        this.__syncAllocated({
          rowIndex,
          field: 'secondaryAllocated',
          nonPrimaryPatientInsuranceId,
          nonPrimaryPayerDebitIdx,
        });

        this.formService.validate();
      },
      addSecondaryPayer: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const usedSecondaryInsuranceIds = row.nonPrimaryPayerDebits.map(
          debit => debit.patientInsuranceId,
        );
        const insuranceOptions = this.__menuItemsMap.insurances.filter(
          insurance =>
            !usedSecondaryInsuranceIds.includes(insurance.data.id) &&
            insurance.data.id !== row.primaryInsuranceId,
        );

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Secondary Responsibility',
            plans: mapPatientInsurancesForEditPopup(insuranceOptions),
            enablePlansDropdown: true,
            initialCodes: [],
            displayPaidAmount: true,
            paymentCodes: this.__menuItemsMap.paymentTypes,
          },
        );

        if (result) {
          const {
            patientInsurance: {
              id: nonPrimaryPatientInsuranceId,
              payer: { id: payerId, alias },
            },
          } = result;

          const indexToAdd = this.__addNonPrimaryPayerDebits(rowIndex, {
            patientInsuranceId: nonPrimaryPatientInsuranceId,
            payerId,
            alias,
          });

          this.__addIndividualItems(
            rowIndex,
            'secondaryOwed',
            result.itemsToAdd.map(item => ({
              ...item,
              patientInsuranceId: nonPrimaryPatientInsuranceId,
              payerId,
            })),
          );

          this.__syncOwedAndPaid({
            rowIndex,
            type: 'secondary',
            nonPrimaryPatientInsuranceId,
          });

          this.__syncAllocated({
            rowIndex,
            field: 'secondaryAllocated',
            nonPrimaryPatientInsuranceId,
            nonPrimaryPayerDebitIdx: indexToAdd,
          });

          this.formService.validate();
        }
      },
      changePrimaryAllocated: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { rowIndex } = this.__getRowAndRowIndexFromName(name);

        this.formService.apply(`items.${rowIndex}.primaryAllocated`, value);

        this.__syncAllocated({ rowIndex, field: 'primaryAllocated' });

        this.formService.validate();
      },
      changeSecondaryAllocated: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const {
          nonPrimaryPayerDebitIdx,
        } = this.__getNonPrimaryPayerDebitIndexFromName(name);

        if (
          value ===
          centsToCurrency(
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx]
              .secondaryAllocated,
          )
        ) {
          return;
        }

        const nonPrimaryPatientInsuranceId =
          row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

        this.formService.apply(name, currencyToCents(value));

        this.__syncAllocated({
          rowIndex,
          field: 'secondaryAllocated',
          nonPrimaryPatientInsuranceId,
          nonPrimaryPayerDebitIdx,
        });

        this.formService.validate();
      },
      openAdjustmentsPopup: async name => {
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        const result = await openPopup(
          POPUP_RENDER_KEYS.INDIVIDUAL_CODE_AND_AMOUNT,
          {
            title: 'Adjustments',
            initialCodes: mapAdjustmentsForEditPopup(row.adjustments),
            displayPaidAmount: false,
            billType: row.billType,
            codeWriteOffs: this.__menuItemsMap.adjustments,
          },
        );

        this.shadowRoot
          .getElementById(ELEMENTS.allocationChargesTable.id)
          .focusAdjustment(rowIndex);

        if (result) {
          this.__deleteIndividualItems(
            rowIndex,
            'adjustments',
            result.itemsToDelete,
          );

          this.__addIndividualItems(rowIndex, 'adjustments', result.itemsToAdd);
          this.__editIndividualItems(
            rowIndex,
            'adjustments',
            result.itemsToEdit,
          );

          this.__updateAllowedAmountFromAdjustments(
            this.state.items[rowIndex],
            rowIndex,
          );

          this.formService.validate();
        }
      },
      changeAdjustments: ({ name, value, event }) => {
        if (event === FOCUS) return;

        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);

        this.__updateAdjustmentAmount(
          row,
          rowIndex,
          currencyToCentsWithNegative(value),
        );

        this.__updateAllowedAmountFromAdjustments(
          this.state.items[rowIndex],
          rowIndex,
        );

        this.formService.validate();
      },
      itemsFilterChange: async ({ name, value }) => {
        const originalFilters = this.itemFilters;

        if (!equal(this.itemFilters[name], value)) {
          this.itemFilters = {
            ...this.itemFilters,
            [name]: value,
          };

          if (!this.paymentDetail.id) return;

          const accepted = this.__dirty ? await openDirtyPopup() : true;

          if (accepted) this.onItemFiltersChange(this.itemFilters);
          else this.itemFilters = originalFilters;

          if (name === 'dateOfTransactionFrom') {
            this.__updateDateOfTransactionFromSelectable();
          }

          if (name === 'dateOfTransactionTo') {
            this.__updateDateOfTransactionToSelectable();
          }
        }
      },
      navigateToPatientLedger: () => {
        navigate(`/patients/${this.patientId}/ledger/activity/encounters`);
      },
      viewCharge: async ({ id, invoiceId, patientId }) => {
        const accepted = this.__dirty ? await openDirtyPopup() : true;

        if (!accepted) return;

        const lineItemIds = invoiceId
          ? (await getLedgerInvoiceItems(invoiceId)).data.map(li => li.id)
          : [id];

        await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2, {
          patient: this.patientsHash[patientId],
          lineItemIds,
          selectedIds: [],
        });

        this.onUpdate();
      },
      viewPayment: async ({ paymentId, value, payerPlanId }) => {
        if (paymentId !== this.paymentDetail.id) {
          const accepted = this.__dirty ? await openDirtyPopup() : true;

          if (!accepted) return;

          if (payerPlanId) {
            const paymentDetails = await getPaymentDetail(paymentId);

            await openPayment({
              payment: {
                payment: {
                  id: paymentId,
                  patientId: this.paymentDetail.patientId,
                },
                ...paymentDetails,
              },
              readonly: false,
            });
          } else if (value.indexOf('D-') === 0) {
            await openOverlay(OVERLAY_KEYS.DISCOUNT, {
              id: paymentId,
              patientId: this.paymentDetail.patientId,
            });
          } else {
            await openOverlay(OVERLAY_KEYS.PAYMENT_DETAIL_2, {
              readonly: false,
              payment: {
                patientId: this.paymentDetail.patientId,
                id: paymentId,
              },
            });
          }
          this.onUpdate();
        }
      },
      selectPayer: async ({ __, payerPlanId }) => {
        const accepted = this.__dirty ? await openDirtyPopup() : true;
        if (!accepted) return;

        const res = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: payerPlanId,
        });

        if (res || this.__dirty) this.onUpdate();
      },
      save: async () => {
        const valid = this.formService.validate();

        const onlyWarnings = await checkForWarnings(this.errors);

        if (valid || onlyWarnings) {
          this.__deleteZeroValueNonPrimaryPayerDebits();
          this.__deleteZeroValueAdjustments();
          this.__resetZeroValuePatientDebit();

          const rawModel = this.formService.build();
          const model = map(rawModel, (_, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.__saving = true;
          this.onSave(model, this.__selectIndexes);
        }

        this.__saving = false;
      },
      clickNoteIcon: async () => {
        const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.PAYMENT,
          parentId: this.paymentDetail.id,
          parentData: {
            transactionDate: parseDate(this.paymentDetail.transactionDate)
              .startOf('day')
              .format('MM/DD/YYYY'),
            paymentId: this.paymentDetail.paymentNumber,
            amount: this.paymentDetail.amount,
            payer: this.paymentDetail.payerName,
          },
          patientId: this.paymentDetail.patientId,
        });

        if (result) this.onUpdate();
      },
      selectAll: () => {
        this.__selectIndexes = this.__selectIndexes.map(() => true);
      },
      deselectAll: () => {
        this.__selectIndexes = this.__selectIndexes.map(() => false);
        this.formService.reset();
        this.formService.validate();
      },
    };

    this.__updateDateOfTransactionFromSelectable();
    this.__updateDateOfTransactionToSelectable();
  }

  __getRow(rowIndex) {
    return this.state.items[rowIndex];
  }

  __getRowAndRowIndexFromName(name) {
    const rowIndex = name.split('.')[0];
    const row = this.__getRow(rowIndex);

    return { row, rowIndex };
  }

  __getNonPrimaryPayerDebitIndexFromName(name) {
    const nonPrimaryPayerDebitIdx = name.split('.')[2];

    return { nonPrimaryPayerDebitIdx };
  }

  __deleteZeroValueNonPrimaryPayerDebits() {
    this.state.items.forEach(({ debits, nonPrimaryPayerDebits }, rowIndex) => {
      for (
        let nonPrimaryPayerDebitIdx = nonPrimaryPayerDebits.length - 1;
        nonPrimaryPayerDebitIdx >= 0;
        nonPrimaryPayerDebitIdx -= 1
      ) {
        const {
          secondaryAllocated,
          secondaryOwed,
          secondaryPaid,
          payerId: nonPrimaryPayerId,
          patientInsuranceId: nonPrimaryPatientInsuranceId,
        } = nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx];

        if (
          secondaryAllocated === 0 &&
          secondaryOwed === 0 &&
          secondaryPaid === 0
        ) {
          for (let debitIdx = debits.length - 1; debitIdx >= 0; debitIdx -= 1) {
            const {
              allocated,
              amount,
              payerId: debitPayerId,
              patientInsuranceId: debitPatientInsuranceId,
            } = debits[debitIdx];

            if (
              currencyToCents(allocated) === 0 &&
              currencyToCents(amount) === 0 &&
              debitPayerId === nonPrimaryPayerId &&
              debitPatientInsuranceId === nonPrimaryPatientInsuranceId
            ) {
              this.formService.removeItem(`items.${rowIndex}.debits`, debitIdx);
            }
          }

          this.formService.removeItem(
            `items.${rowIndex}.nonPrimaryPayerDebits`,
            nonPrimaryPayerDebitIdx,
          );
        }
      }
    });
  }

  __deleteZeroValueAdjustments() {
    this.state.items.forEach(({ adjustments }, rowIndex) => {
      if (
        adjustments.length === 1 &&
        adjustments[0].id === '' &&
        currencyToCentsWithNegative(adjustments[0].amount) === 0
      ) {
        this.__deleteIndividualItems(rowIndex, 'adjustments', [
          {
            individualRowId: adjustments[0].id,
            codeId: adjustments[0].codePaymentId,
            amount: currencyToCentsWithNegative(adjustments[0].amount),
          },
        ]);
      }
    });
  }

  __resetZeroValuePatientDebit() {
    this.state.items.forEach((row, rowIndex) => {
      const { debits, debitIndexes } = getPatientDebits(row);

      if (
        debits.length === 1 &&
        currencyToCents(debits[0].amount) === 0 &&
        currencyToCents(debits[0].allocated) === 0
      ) {
        this.formService.apply(
          `items.${rowIndex}.debits.${debitIndexes}.codePaymentId`,
          '',
        );
      }
    });
  }

  __deleteIndividualItems(rowIndex, type, itemsToDelete) {
    itemsToDelete.forEach(itemToDelete => {
      const row = this.__getRow(rowIndex);
      const indexToDelete = row[type].findIndex(
        individualType => individualType.id === itemToDelete.individualRowId,
      );

      this.formService.removeItem(`items.${rowIndex}.${type}`, indexToDelete);
    });
  }

  __debitWithNoIdLength(rowIndex, type, nonPrimaryPatientInsuranceId) {
    let length;

    const row = this.__getRow(rowIndex);

    switch (type) {
      case 'patientOwed':
        length = row.debits.filter(
          debit => isPatientRespDebit(debit) && !debit.id,
        ).length;

        break;

      case 'secondaryOwed':
        length = row.debits.filter(
          debit =>
            isPayerRespDebit(debit) &&
            !debit.id &&
            debit.patientInsuranceId === nonPrimaryPatientInsuranceId,
        ).length;

        break;

      default:
        break;
    }

    return length;
  }

  __debitWithNoIdIndex(rowIndex, type, nonPrimaryPatientInsuranceId) {
    let index;

    const row = this.__getRow(rowIndex);

    switch (type) {
      case 'patientOwed':
        index = row.debits.findIndex(
          debit => isPatientRespDebit(debit) && !debit.id,
        );

        break;

      case 'secondaryOwed':
        index = row.debits.findIndex(
          debit =>
            isPayerRespDebit(debit) &&
            !debit.id &&
            debit.patientInsuranceId === nonPrimaryPatientInsuranceId,
        );

        break;

      default:
        break;
    }

    return index;
  }

  __adjustmentWithNoIdIndex(rowIndex) {
    const row = this.__getRow(rowIndex);

    return row.adjustments.findIndex(adj => !adj.id);
  }

  __existingDebitIndex(rowIndex, itemToEdit) {
    const row = this.__getRow(rowIndex);

    return row.debits.findIndex(
      debit => debit.id === itemToEdit.individualRowId,
    );
  }

  __existingAdjustmentIndex(rowIndex, itemToEdit) {
    const row = this.__getRow(rowIndex);

    return row.adjustments.findIndex(
      adj => adj.id === itemToEdit.individualRowId,
    );
  }

  __addNonPrimaryPayerDebits(rowIndex, { patientInsuranceId, payerId, alias }) {
    const row = this.__getRow(rowIndex);
    const indexToAdd = row.nonPrimaryPayerDebits.length;

    this.formService.addItem(
      `items.${rowIndex}.nonPrimaryPayerDebits`,
      indexToAdd,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.patientInsuranceId`,
      patientInsuranceId,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.payerId`,
      payerId,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryAllocated`,
      0,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryOwed`,
      0,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryPaid`,
      0,
    );

    this.formService.apply(
      `items.${rowIndex}.nonPrimaryPayerDebits.${indexToAdd}.secondaryPayer.alias`,
      alias,
    );

    return indexToAdd;
  }

  __deleteItemsBeforeAdding(rowIndex, type, nonPrimaryPatientInsuranceId) {
    const isDebit = type !== 'adjustments';
    const attribute = isDebit ? 'debits' : 'adjustments';
    const row = this.__getRow(rowIndex);

    let countAddedItemsToDelete = 0;

    if (isDebit) {
      countAddedItemsToDelete = this.__debitWithNoIdLength(
        rowIndex,
        type,
        nonPrimaryPatientInsuranceId,
      );
    } else {
      countAddedItemsToDelete = row.adjustments.filter(adj => !adj.id).length;
    }

    while (countAddedItemsToDelete) {
      const indexToDelete = isDebit
        ? this.__debitWithNoIdIndex(
            rowIndex,
            type,
            nonPrimaryPatientInsuranceId,
          )
        : this.__adjustmentWithNoIdIndex(rowIndex);

      if (indexToDelete !== -1) {
        this.formService.removeItem(
          `items.${rowIndex}.${attribute}`,
          indexToDelete,
        );
      }

      countAddedItemsToDelete -= 1;
    }
  }

  __addIndividualItems(
    rowIndex,
    type,
    itemsToAdd,
    nonPrimaryPatientInsuranceId,
  ) {
    const isDebit = type !== 'adjustments';
    const attribute = isDebit ? 'debits' : 'adjustments';

    this.__deleteItemsBeforeAdding(
      rowIndex,
      type,
      nonPrimaryPatientInsuranceId,
    );

    const codeId = isDebit ? 'codePaymentId' : 'codeId';

    itemsToAdd.forEach(itemToAdd => {
      const row = this.__getRow(rowIndex);
      const indexToAdd = row[attribute].length;
      this.formService.addItem(`items.${rowIndex}.${attribute}`);

      this.formService.apply(
        `items.${rowIndex}.${attribute}.${indexToAdd}.${codeId}`,
        itemToAdd.codeId,
      );

      this.formService.apply(
        `items.${rowIndex}.${attribute}.${indexToAdd}.amount`,
        centsToCurrency(itemToAdd.amount),
      );

      if (itemToAdd.patientInsuranceId && itemToAdd.payerId) {
        this.formService.apply(
          `items.${rowIndex}.${attribute}.${indexToAdd}.payerId`,
          itemToAdd.payerId,
        );

        this.formService.apply(
          `items.${rowIndex}.${attribute}.${indexToAdd}.patientInsuranceId`,
          itemToAdd.patientInsuranceId,
        );
      }
    });
  }

  __editIndividualItems(rowIndex, type, itemsToEdit) {
    const isDebit = type !== 'adjustments';

    itemsToEdit.forEach(itemToEdit => {
      const indexToEdit = isDebit
        ? this.__existingDebitIndex(rowIndex, itemToEdit)
        : this.__existingAdjustmentIndex(rowIndex, itemToEdit);

      const codeId = isDebit ? 'codePaymentId' : 'codeId';

      this.formService.apply(
        `items.${rowIndex}.${type}.${indexToEdit}.${codeId}`,
        itemToEdit.codeId,
      );

      this.formService.apply(
        `items.${rowIndex}.${type}.${indexToEdit}.amount`,
        centsToCurrencyWithNegative(itemToEdit.amount),
      );
    });
  }

  __applyAllocatedToDebits({
    rowIndex,
    debits,
    debitIndexes,
    allocationAmount,
  }) {
    const debitAllocationAmounts = buildDebitAllocationAmounts({
      debits: mapDebitAmountsToCents(debits),
      debitIndexes,
      allocationAmount,
    });

    debitAllocationAmounts.forEach(({ debitIndex, allocated }) => {
      this.formService.apply(
        `items.${rowIndex}.debits.${debitIndex}.allocated`,
        centsToCurrency(allocated),
      );
    });
  }

  __syncOwedAndPaid({ rowIndex, type, nonPrimaryPatientInsuranceId }) {
    const row = this.__getRow(rowIndex);

    let debits;

    switch (type) {
      case 'patient':
        ({ debits } = getPatientDebits(row));
        break;
      case 'primary':
        ({ debits } = getPrimaryPayerDebits(row));
        break;
      case 'secondary':
        ({ debits } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        ));

        break;
      default:
    }

    const owed = calculateDebitsOwed(debits, true);
    const paid = calculateDebitsPaid(debits, true);

    if (type === 'secondary') {
      row.nonPrimaryPayerDebits.forEach((value, index) => {
        if (value.patientInsuranceId === nonPrimaryPatientInsuranceId) {
          this.formService.apply(
            `items.${rowIndex}.nonPrimaryPayerDebits.${index}.secondaryOwed`,
            currencyToCents(owed),
          );

          this.formService.apply(
            `items.${rowIndex}.nonPrimaryPayerDebits.${index}.secondaryPaid`,
            currencyToCents(paid),
          );
        }
      });
    } else {
      this.formService.apply(`items.${rowIndex}.${type}Owed`, owed);
      this.formService.apply(`items.${rowIndex}.${type}Paid`, paid);
    }
  }

  __clearAllocatedIfDebitsRemoved({ rowIndex, type }) {
    const row = this.__getRow(rowIndex);

    let debits;

    if (type === 'patient') {
      ({ debits } = getPatientDebits(row));
    } else {
      return;
    }

    if (debits.length === 0 || hasOnlyOnePaidPkgDebit(debits)) {
      this.formService.apply(`items.${rowIndex}.${type}Allocated`, '$0.00');
    }
  }

  __syncAllocated({
    rowIndex,
    field,
    nonPrimaryPatientInsuranceId,
    nonPrimaryPayerDebitIdx,
  }) {
    const row = this.__getRow(rowIndex);

    let debits;
    let debitIndexes;

    switch (field) {
      case 'patientAllocated':
        ({ debits, debitIndexes } = getPatientDebits(row));
        break;
      case 'primaryAllocated':
        ({ debits, debitIndexes } = getPrimaryPayerDebits(row));
        break;
      case 'secondaryAllocated':
        ({ debits, debitIndexes } = getSecondaryPayerDebits(
          row,
          nonPrimaryPatientInsuranceId,
        ));

        break;
      default:
        return;
    }

    const allocationAmount =
      field === 'secondaryAllocated'
        ? row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx][field]
        : currencyToCents(row[field]);

    this.__applyAllocatedToDebits({
      rowIndex,
      debits,
      debitIndexes,
      allocationAmount,
    });
  }

  __debitsChanged({ itemsToAdd, itemsToEdit, itemsToDelete }, debits) {
    if (itemsToAdd.length || itemsToDelete.length) return true;

    return !itemsToEdit.every(
      (v, idx) =>
        v.individualRowId === debits[idx].id &&
        v.codeId === debits[idx].codePaymentId &&
        v.amount === currencyToCents(debits[idx].amount),
    );
  }

  __defaultAdjustmentCodeId(row) {
    return row.billType === BILL_TYPE.INSURANCE
      ? CODE_WRITE_OFFS.NEGOTIATED_RATE.id
      : CODE_WRITE_OFFS.WRITE_OFF.id;
  }

  __updateAdjustmentAmount(row, rowIndex, amount) {
    switch (row.adjustments.length) {
      case 0:
        if (amount) {
          this.__addIndividualItems(rowIndex, 'adjustments', [
            { codeId: this.__defaultAdjustmentCodeId(row), amount },
          ]);
        }

        break;

      case 1:
        if (amount) {
          this.__editIndividualItems(rowIndex, 'adjustments', [
            {
              individualRowId: row.adjustments[0].id,
              codeId: row.adjustments[0].codeId,
              amount,
            },
          ]);
        } else {
          const previousCode = row.adjustments[0].codeId;

          this.__deleteIndividualItems(rowIndex, 'adjustments', [
            {
              individualRowId: row.adjustments[0].id,
              codeId: previousCode,
              amount,
            },
          ]);

          this.__addIndividualItems(rowIndex, 'adjustments', [
            { codeId: previousCode, amount: 0 },
          ]);
        }

        break;

      default:
        break;
    }
  }

  __updateDateOfTransactionFromSelectable() {
    this.handlers.dateOfTransactionFromSelectable = date =>
      (this.itemFilters.dateOfTransactionTo === null ||
        date.isSameOrBefore(this.itemFilters.dateOfTransactionTo)) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  __updateDateOfTransactionToSelectable() {
    this.handlers.dateOfTransactionToSelectable = date =>
      (this.itemFilters.dateOfTransactionFrom === null ||
        date.isSameOrAfter(this.itemFilters.dateOfTransactionFrom)) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  __addOrRemoveRow(selectedIndex, li, propName) {
    const origLength = this.formService.__initialState.items[selectedIndex][
      propName
    ].length;

    const modLength = li[propName].length;

    const diffLength = modLength - origLength;
    const action = diffLength && (diffLength > 0 ? 'removeItem' : 'addItem');

    Array(Math.abs(diffLength))
      .fill()
      .forEach(() =>
        this.formService[action](`items.${selectedIndex}.${propName}`),
      );
  }

  __updateAllowedAmountFromAdjustments(row, rowIndex) {
    const { debits } = getPatientDebits(row);

    if (!rowHasPackageCreditsOrAdjustments(row, debits)) {
      const adjustmentsSum = calculateAdjustmentsSum(row.adjustments);
      const newAllowedAmount = calculateBalancedAllowedAmount(
        row.billedAmount,
        row.taxAmount,
        adjustmentsSum,
      );

      this.formService.apply(
        `items.${rowIndex}.allowedAmount`,
        centsToCurrencyWithNegative(newAllowedAmount),
      );
    }
  }

  __revertDebit(selectedIndex, initialSelectState) {
    initialSelectState.debits.forEach((_, index) => {
      const path = `items.${selectedIndex}.debits.${index}.`;
      const initialDebit = initialSelectState.debits[index];

      const fields = [
        'codePaymentId',
        'patientInsuranceId',
        'payerId',
        'id',
        'allocated',
        'amount',
      ];

      fields.forEach(field =>
        this.formService.apply(`${path}${field}`, initialDebit[field]),
      );
    });
  }

  __revertAdjustment(selectedIndex, initialSelectState) {
    initialSelectState.adjustments.forEach((_, index) => {
      const path = `items.${selectedIndex}.adjustments.${index}.`;
      const selectAdjustment = initialSelectState.adjustments[index];
      const fields = ['amount', 'codeId'];

      fields.forEach(field =>
        this.formService.apply(`${path}${field}`, selectAdjustment[field]),
      );
    });
  }

  __revertNonPrimaryPayerDebits(selectedIndex, initialSelectState) {
    let countAddedItemsToDelete = this.state.items[selectedIndex]
      .nonPrimaryPayerDebits.length;

    while (countAddedItemsToDelete !== -1) {
      this.formService.removeItem(
        `items.${selectedIndex}.nonPrimaryPayerDebits`,
        countAddedItemsToDelete,
      );

      countAddedItemsToDelete -= 1;
    }

    initialSelectState.nonPrimaryPayerDebits.forEach((_, index) => {
      this.formService.addItem(
        `items.${selectedIndex}.nonPrimaryPayerDebits`,
        index,
      );

      const path = `items.${selectedIndex}.nonPrimaryPayerDebits.${index}.`;
      const initialNonPrimaryPayerDebit =
        initialSelectState.nonPrimaryPayerDebits[index];

      const fields = [
        'patientInsuranceId',
        'payerId',
        'secondaryAllocated',
        'secondaryOwed',
        'secondaryPaid',
      ];

      fields.forEach(field => {
        this.formService.apply(
          `${path}${field}`,
          initialNonPrimaryPayerDebit[field],
        );

        this.formService.apply(
          `${path}secondaryPayer.alias`,
          initialNonPrimaryPayerDebit.secondaryPayer.alias,
        );
      });
    });
  }

  __revertLineItem(selectedIndex, initialSelectState) {
    const path = `items.${selectedIndex}.`;

    const fields = [
      'allowedAmount',
      'patientOwed',
      'primaryOwed',
      'patientAllocated',
      'primaryAllocated',
      'patientPaid',
      'primaryPaid',
    ];

    fields.forEach(field =>
      this.formService.apply(`${path}${field}`, initialSelectState[field]),
    );
  }

  __revertRow(selectedIndex) {
    if (this.formService.isDirty) {
      const li = this.state.items[selectedIndex];

      this.__addOrRemoveRow(selectedIndex, li, 'debits');
      this.__addOrRemoveRow(selectedIndex, li, 'adjustments');

      const initialSelectState = this.formService.__initialState.items[
        selectedIndex
      ];

      this.__revertDebit(selectedIndex, initialSelectState);
      this.__revertAdjustment(selectedIndex, initialSelectState);
      this.__revertLineItem(selectedIndex, initialSelectState);
      this.__revertNonPrimaryPayerDebits(selectedIndex, initialSelectState);

      this.formService.validate();
    }
  }

  __genPaymentActions() {
    const electronic = isElectronicPayment(this.paymentDetail);
    const notAllocatable = this.paymentDetail.status !== 'Unallocated';
    const disableAllocate = !['Unallocated', 'Allocated'].includes(
      this.paymentDetail.status,
    );
    const hasSplit = !!this.paymentDetail.parentPaymentId;
    const isPayerPayment = !!this.paymentDetail.payerPlanId;
    const fullyAllocated = this.paymentDetail.status === 'Allocated';
    const disableSplit = !hasSplit && notAllocatable;
    const hasSomeAllocation = isPartiallyOrFullyAllocated(
      this.__paymentModel[0],
    );
    const disableRefund = notAllocatable || hasSomeAllocation;
    const disableVoid = electronic || disableAllocate || hasSplit;

    const items = [
      {
        name: 'void',
        label: 'Void Payment',
        icon: 'clear',
        onClick: this.handlers.voidPayment,
        disabled: disableVoid || this.readonly || this.formService.isDirty,
      },
      {
        name: 'refund',
        label: 'Refund Payment',
        icon: 'refund',
        onClick: this.handlers.refundPayment,
        disabled: disableRefund || this.readonly || this.formService.isDirty,
      },
      {
        name: 'allocate',
        label: 'Allocate Payment',
        icon: 'allocate',
        onClick: this.handlers.allocatePayment,
        disabled:
          disableAllocate ||
          fullyAllocated ||
          this.readonly ||
          this.formService.isDirty,
      },
      ...(this.hasFifo
        ? [
            {
              name: 'auto',
              label: 'Auto Allocate',
              icon: 'flashAuto',
              onClick: this.handlers.autoAllocate,
              disabled:
                disableAllocate ||
                this.readonly ||
                fullyAllocated ||
                this.formService.isDirty ||
                this.__isAutoAllocated ||
                isPayerPayment,
            },
          ]
        : []),
      {
        name: 'split',
        label: 'Split Payment',
        icon: 'split',
        onClick: this.handlers.splitPayment,
        disabled: disableSplit || this.readonly || this.formService.isDirty,
      },
      {
        name: 'email-receipt',
        label: 'Email Receipt',
        icon: 'email',
        onClick: this.handlers.emailPayment,
        disabled: hasSplit,
      },
      {
        name: 'receipt',
        label: 'Print Receipt',
        icon: 'receipt',
        onClick: this.handlers.printPayment,
        disabled: !!this.paymentDetail.payerPlanId || hasSplit,
      },
    ];

    return items;
  }

  __toggleRemoveAllocation() {
    return !this.__selectIndexes.some(index => index);
  }

  async __getPatientInsurances() {
    const patients = this.patients.filter(p => p.data.id);
    const insurances = await Promise.all(
      patients.length ? patients.map(p => fetchInsuranceItems(p.data.id)) : [],
    );

    return patients.flatMap((p, index) =>
      insurances[index].map(insurance => ({
        ...insurance,
        patientId: p.data.id,
      })),
    );
  }

  async rerenderPatientInfo() {
    const patient = await patientApiClient.fetchOne(
      this.paymentDetail.patientId,
    );

    const patientName = objToName(patient.name, {
      reverse: true,
      middleInitial: true,
    });

    const isDirty = this.__dirty;

    this.state.paymentTransaction.forEach((payment, index) => {
      this.formService.apply(
        `paymentTransaction.${index}.patientName`,
        patientName,
      );

      if (!payment.payerId) {
        this.formService.apply(
          `paymentTransaction.${index}.payer`,
          patientName,
        );
      }
    });

    if (!isDirty) {
      const model = this.formService.build();
      this.formService.refresh(model);
    }
  }

  async __getMenuItemsMap() {
    const patientInsurances = await this.__getPatientInsurances();

    return {
      insurances: patientInsurances,
      adjustments: this.adjustmentTypes,
      paymentTypes: this.paymentTypes,
    };
  }

  async load() {
    this.__menuItemsMap = await this.__getMenuItemsMap();
  }

  connectedCallback() {
    super.connectedCallback();

    this.__notificationService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__notificationService.disconnect();
  }

  __setPresetValues() {
    this.__selectIndexes = this.model.items.map(
      x => !this.__uncheckedIds.includes(x.id),
    );

    this.__preallocated = this.model.items.map(li =>
      li.debits ? li.debits.reduce((sum, d) => sum + d.allocated, 0) : 0,
    );
  }

  update(changedProps) {
    if (changedProps.has('paymentDetail') && this.paymentDetail) {
      this.__paymentModel = [
        {
          paymentAmount: this.paymentDetail.amount,
          refunded: 0,
          allocated: !(
            this.paymentDetail.voidedAt || this.paymentDetail.refundedAt
          )
            ? this.paymentDetail.amount - this.paymentDetail.available
            : 0,
          split: 0,
          available: this.paymentDetail.available,
          recommendedAllocated: this.paymentDetail.recommendedAllocated
            ? this.paymentDetail.recommendedAllocated
            : 0,
          paymentMethod: this.paymentDetail.paymentMethod,
          postedById: this.paymentDetail.postedById,
        },
      ];
    }

    if (changedProps.has('model') && this.model.items) {
      this.__setPresetValues();

      this.__notificationService.update({
        patient: { id: this.paymentDetail.patientId },
      });
    }

    super.update(changedProps);
  }

  async updated(changedProps) {
    if (changedProps.has('paymentDetail') && this.paymentDetail) {
      if (this.paymentDetail.voidedAt) {
        const { voidPayment } = this.paymentDetail;

        this.__paymentActionDetail = [
          {
            date: this.paymentDetail.voidedAt,
            by: this.practiceUsers.find(u => u.id === voidPayment.voidedById),
            reason: voidPayment.codeRefund,
            amount: this.paymentDetail.amount,
          },
        ];
      }

      if (isPaymentRefunded(this.paymentDetail)) {
        const { refundPayment } = this.paymentDetail;

        this.__paymentActionDetail = [
          {
            date: refundPayment.updatedAt,
            by: this.practiceUsers.find(
              u => u.id === refundPayment.refundedById,
            ),
            reason: refundPayment.codeRefund,
            method: refundPayment.refundMethod,
            transactionId: refundPayment.cardRefundId || '-',
            amount: this.paymentDetail.amount,
          },
        ];
      }

      if (!this.paymentDetail.payerPlanId) {
        const [providers, locations] = await Promise.all([
          getProviderUsers(),
          getLocations(),
        ]);

        const providerName = providers.find(
          provider => provider.id === this.paymentDetail.providerId,
        )?.name;

        const locationName = locations.find(
          location => location.id === this.paymentDetail.locationId,
        )?.name;

        this.title = { providerName, locationName };
      }
    }

    if (changedProps.has('__selectIndexes')) {
      const uncheckedIds = [];
      this.__selectIndexes.forEach((checked, index) => {
        if (!checked) {
          uncheckedIds.push(this.state.items[index].id);
        }
      });

      this.__uncheckedIds = uncheckedIds;
    }

    if (changedProps.has('patients') && this.patients.length > 1) {
      this.__menuItemsMap = await this.__getMenuItemsMap();
      this.build();
    }

    if (this.formService.isDirty && !this.__disablePaymentOptions) {
      this.__disablePaymentOptions = true;
    } else if (!this.formService.isDirty && this.__disablePaymentOptions) {
      this.__disablePaymentOptions = false;
    }

    super.updated(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .subheader {
          margin: 0 ${CSS_SPACING} ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .table-spacer {
          padding-bottom: ${CSS_SPACING};
        }

        .container-filter {
          padding: ${CSS_SPACING};
          display: grid;
          grid-template-columns: repeat(4, 3fr);
          grid-gap: ${CSS_SPACING};
          width: 100%;
          padding-top: ${CSS_SPACING};
          z-index: 2;
        }

        .history-header {
          margin: ${CSS_SPACING} 0px;
        }

        .history {
          flex: 1 0 0;
          border: ${CSS_BORDER_GREY_1};
          border-radius: 5px;
        }

        history-table: {
          margin-top: 20px;
        }

        .field {
          width: 100%;
        }

        .content {
          position: relative;
        }

        .container-totals {
          display: grid;
          grid-template-columns: auto auto 3fr;
          grid-gap: ${CSS_SPACING};
          width: 100%;
          padding: ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_1};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin-bottom: ${CSS_SPACING};
        }

        .content-padding {
          padding: ${CSS_SPACING};
        }

        .button-remove-allocation {
          width: max-content;
          padding-left: ${CSS_SPACING};
          z-index: 1;
        }

        .buttons {
          display: flex;
          align-items: center;
        }

        .buttons-bar {
          padding-left: 20px;
        }

        .button-actions {
          width: 20px;
        }
      `,
    ];
  }

  __getPaymentConfig() {
    return [
      {
        key: 'paymentAmount',
        label: 'Payment Amount',
        truncate: true,
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      {
        truncate: true,
        key: 'allocated',
        label: 'Allocated',
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
      {
        truncate: true,
        key: 'available',
        label: 'Available',
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
    ];
  }

  __getPaymentActionDetailConfig() {
    const actionStr = isPaymentRefunded(this.paymentDetail)
      ? 'Refunded'
      : 'Voided';

    const otherActionStr = isPaymentRefunded(this.paymentDetail)
      ? 'Refund'
      : 'Void';

    return [
      {
        key: 'date',
        label: `${actionStr} Date`,
        flex: css`1 0 0`,
        formatter: value => (value ? toFormatDate(value, MONTH_DAY_YEAR) : ''),
      },
      {
        key: 'by',
        label: `${actionStr} By`,
        flex: css`1 0 0`,
        formatter: user => `${user.name.last}, ${user.name.first}`,
      },
      {
        key: 'reason',
        label: `${otherActionStr} Reason`,
        flex: css`1 0 0`,
        formatter: (codeRefund = {}) =>
          `${codeRefund.code} - ${codeRefund.description}`,
      },
      ...(isPaymentRefunded(this.paymentDetail)
        ? [
            {
              key: 'method',
              label: `${otherActionStr} Method`,
              flex: css`1 0 0`,
            },
          ]
        : []),
      ...(isPaymentRefunded(this.paymentDetail) &&
      this.paymentDetail.refundPayment.cardRefundId
        ? [
            {
              key: 'transactionId',
              label: `${otherActionStr} Transaction ID`,
              flex: css`1 0 0`,
            },
          ]
        : []),
      {
        key: 'amount',
        label: `${actionStr} Amount`,
        flex: css`1 0 0`,
        formatter: amount => centsToCurrency(amount),
      },
    ];
  }

  __renderButtonBar() {
    return html`
      <neb-button-bar
        id="${ELEMENTS.buttonBar.id}"
        class="buttons-bar"
        tabindex="-1"
        .config="${this.__genPaymentActions()}"
      ></neb-button-bar>
    `;
  }

  __renderHeaderButtons() {
    return html`
      <div class="buttons">${this.__renderButtonBar()}</div>
    `;
  }

  __renderHeader() {
    let title = `Payment ID ${this.paymentDetail.paymentNumber}`;

    if (this.title.providerName) {
      title += ` - ${objToName(this.title.providerName)}`;
    }

    if (this.title.locationName) {
      title += ` - ${this.title.locationName}`;
    }

    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${title}"
        .onCancel="${this.handlers.dismissPayment}"
        .onClickNoteIcon="${this.handlers.clickNoteIcon}"
        ?showAddNoteIcon="${!this.paymentDetail.hasBillingNote}"
        ?showEditNoteIcon="${!!this.paymentDetail.hasBillingNote}"
        showCancelButton
      >
      </neb-popup-header>
      ${this.__renderHeaderButtons()}
    `;
  }

  __renderLocationsSelect() {
    return html`
      <neb-select
        id="${ELEMENTS.selectLocation.id}"
        name="locations"
        class="field"
        tabindex="-1"
        label="Locations"
        .items="${this.locations}"
        .value="${this.itemFilters.locations}"
        .onChange="${this.handlers.itemsFilterChange}"
        multiSelect
      >
      </neb-select>
    `;
  }

  __renderPaymentDetails() {
    const isVoidedOrRefunded =
      this.paymentDetail.voidedAt || isPaymentRefunded(this.paymentDetail);

    const readOnlyDetails = isVoidedOrRefunded;

    return html`
      ${
        isVoidedOrRefunded
          ? html`
              <neb-table-readonly
                id="${ELEMENTS.paymentActionDetail.id}"
                .model="${this.__paymentActionDetail}"
                .config="${this.__getPaymentActionDetailConfig()}"
                .layout="${this.layout}"
              ></neb-table-readonly>
              <div class="table-spacer"></div>
            `
          : ''
      }

      <neb-table-readonly
        id="${ELEMENTS.paymentDetail.id}"
        .model="${this.__paymentModel}"
        .config="${this.__getPaymentConfig()}"
        .layout="${this.layout}"
      ></neb-table-readonly>

      <div class="table-spacer"></div>

      <neb-table-payment-transaction
        id="${ELEMENTS.transactionDetail.id}"
        name="paymentTransaction"
        .layout="${this.layout}"
        .paymentDetail="${this.paymentDetail}"
        .paymentTypes="${this.paymentTypes}"
        .practiceUsers="${this.practiceUsers}"
        .model="${this.state.paymentTransaction}"
        .errors="${this.errors.paymentTransaction}"
        .onChange="${this.handlers.change}"
        .onEdit="${this.handlers.edit}"
        .onSelectPatient="${this.handlers.navigateToPatientLedger}"
        ?readOnlyDetails="${readOnlyDetails}"
      ></neb-table-payment-transaction>

      <div class="table-spacer"></div>
      </div>
    `;
  }

  __renderEditAllocation() {
    return html`
      <neb-button
        id="${ELEMENTS.removeAllocation.id}"
        class="button-remove-allocation"
        tabindex="-1"
        role="outline"
        label="${ELEMENTS.removeAllocation.label}"
        ?disabled="${this.__toggleRemoveAllocation()}"
        .onClick="${this.handlers.removeAllocation}"
      ></neb-button>

      <neb-table-allocation-charges-2
        id="${ELEMENTS.allocationChargesTable.id}"
        name="items"
        emptyMessage="${NO_CHARGES_TO_EDIT_OR_REMOVE_MESSAGE}"
        .model="${this.state.items}"
        .patientId="${this.paymentDetail.patientId}"
        .paymentDetail="${this.paymentDetail}"
        .allocatableId="${null}"
        .preallocated="${this.__preallocated}"
        .selectIndexes="${this.__selectIndexes}"
        .menuItems="${this.__menuItemsMap}"
        .payers="${this.payers}"
        .errors="${this.errors.items}"
        .hasErrors="${this.formService.hasErrors}"
        .onItemCheck="${this.handlers.checkItem}"
        .onChangeAllowed="${this.handlers.changeAllowed}"
        .onClickInvoiceLink="${this.handlers.viewCharge}"
        .onClickPaymentLink="${this.handlers.viewPayment}"
        .onSelectPayer="${this.handlers.selectPayer}"
        .onOpenPatientOwedPopup="${this.handlers.openPatientOwedPopup}"
        .onChangePatientOwed="${this.handlers.changePatientOwed}"
        .onChangePatientAllocated="${this.handlers.changePatientAllocated}"
        .onChangePrimaryOwed="${this.handlers.changePrimaryOwed}"
        .onChangePrimaryAllocated="${this.handlers.changePrimaryAllocated}"
        .onOpenSecondaryOwedPopup="${this.handlers.openSecondaryOwedPopup}"
        .onAddSecondaryPayer="${this.handlers.addSecondaryPayer}"
        .onChangeSecondaryOwed="${this.handlers.changeSecondaryOwed}"
        .onOpenAdjustmentsPopup="${this.handlers.openAdjustmentsPopup}"
        .onChangeAdjustments="${this.handlers.changeAdjustments}"
        .onSelectAll="${this.handlers.selectAll}"
        .onDeselectAll="${this.handlers.deselectAll}"
        .hasRCMSecondaryField="${this.hasRCMSecondaryField}"
      ></neb-table-allocation-charges-2>
    `;
  }

  __renderAllocatedChargesHeader() {
    const { id, title, description } = ELEMENTS.headerAllocatedCharges;

    return html`
      <neb-header
        id="${id}"
        .label="${title}"
        .description="${description}"
      ></neb-header>
    `;
  }

  __renderAllocatedCharges() {
    if (!isPartiallyOrFullyAllocated(this.__paymentModel[0])) return '';

    return html`
      ${this.__renderAllocatedChargesHeader()}

      <div class="container-filter">
        <neb-date-picker
          id="${ELEMENTS.dateOfTransactionFrom.id}"
          name="dateOfTransactionFrom"
          class="field"
          tabindex="-1"
          label="Transaction From Date"
          helperText=" "
          manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${this.itemFilters.dateOfTransactionFrom}"
          .isDateSelectable="${this.handlers.dateOfTransactionFromSelectable}"
          .onChange="${this.handlers.itemsFilterChange}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.dateOfTransactionTo.id}"
          name="dateOfTransactionTo"
          class="field"
          tabindex="-1"
          label="Transaction To Date"
          helperText=" "
          manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${this.itemFilters.dateOfTransactionTo}"
          .isDateSelectable="${this.handlers.dateOfTransactionToSelectable}"
          .onChange="${this.handlers.itemsFilterChange}"
          momentFlag
        ></neb-date-picker>

        <neb-select
          id="${ELEMENTS.selectPatient.id}"
          name="patient"
          class="field"
          tabindex="-1"
          label="Patient"
          .items="${this.patients}"
          .value="${this.itemFilters.patient}"
          .onChange="${this.handlers.itemsFilterChange}"
          ?disabled="${this.paymentDetail.patientId && !this.hasRelationships}"
        ></neb-select>

        ${this.__renderLocationsSelect()}
      </div>
      ${this.__renderEditAllocation()}
    `;
  }

  __isActionBarEnabled() {
    return this.formService.isDirty;
  }

  renderActionBar() {
    if (this.__isActionBarEnabled()) {
      return html`
        <neb-action-bar
          id="${ELEMENTS.actionBar.id}"
          .onConfirm="${this.handlers.save}"
          .onCancel="${this.handlers.cancel}"
          confirmLabel="${this.confirmLabel}"
          cancelLabel="${this.cancelLabel}"
        ></neb-action-bar>
      `;
    }

    return '';
  }

  renderContent() {
    return html`
      ${this.__renderHeader()} ${this.__renderPaymentDetails()}
      ${this.__renderAllocatedCharges()}
    `;
  }
}

customElements.define('neb-form-payment-view-2', NebFormPaymentView2);
