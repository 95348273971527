import './neb-pagination';
import './neb-icd-code-table-row';

import { LitElement, html, css } from 'lit';

import {
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';
import { formatIcdSelectorIdCode } from '../../../neb-utils/neb-charting-util';

export const ELEMENTS = {
  pagination: {
    id: 'pagination',
  },
  noResultText: {
    id: 'no-result-text',
  },
  noSearchResultsText: {
    id: 'no-search-results',
  },
  tableRows: {
    selector: 'neb-icd-code-table-row',
  },
};
class NebIcdCodeTable extends LitElement {
  static get properties() {
    return {
      searchResults: Array,
      savedCodes: Array,
      pageCount: Number,
      currentPageIndex: Number,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.savedCodes = [];
    this.searchResults = null;
    this.pageCount = 0;
    this.currentPageIndex = 0;
    this.layout = '';

    this.onPageChanged = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      pageChanged: pageIndex => this.onPageChanged(pageIndex),
    };
  }

  static get styles() {
    return css`
      :host([layout='small']) .add-diagnosis-header-space {
        width: 40px;
        padding: 0;
      }

      :host([layout='small']) .icd-header {
        width: 73px;
        padding: 20px 5px;
      }

      :host([layout='small']) .description-header {
        padding: 20px 5px;
      }

      .add-diagnosis-header-space {
        width: 205px;
      }

      .icd-header {
        width: 156px;
      }

      .header-row {
        display: flex;
        border-bottom: 1px solid rgba(151, 151, 151, 0.3);
        margin-top: 11px;
      }

      .header-row > div {
        padding: ${CSS_SPACING};
      }

      .header-text {
        font-weight: bold;
      }

      .no-result-text {
        margin: 20px;
        font-size: ${CSS_FONT_SIZE_BODY};
        font-style: italic;
        color: ${CSS_COLOR_GREY_1};
      }

      .pagination {
        flex-direction: row-reverse;
        padding-top: ${CSS_SPACING};
        padding-right: 10px;
        margin-bottom: ${CSS_SPACING};
      }
    `;
  }

  __renderSearchResultRow(icd10Info) {
    const isAdded = this.isCodeAdded(icd10Info.code);
    return html`
      <neb-icd-code-table-row
        id="icd10-row-${formatIcdSelectorIdCode(icd10Info.code)}"
        .onButtonClick="${this.onButtonClick}"
        .icd10Info="${icd10Info}"
        .isAdded="${isAdded}"
        .buttonText="${this.buttonText}"
        .buttonToggledText="${this.buttonToggledText}"
        .layout="${this.layout}"
      ></neb-icd-code-table-row>
    `;
  }

  __renderNoSearchResults() {
    return html`
      <div id="${ELEMENTS.noSearchResultsText.id}" class="no-result-text">
        No results found.
      </div>
    `;
  }

  __renderSearchResults(results) {
    return results.length > 0
      ? results.map(result => this.__renderSearchResultRow(result))
      : this.__renderNoSearchResults();
  }

  isCodeAdded(icd10Code) {
    return !!this.savedCodes.find(
      currentInfo => currentInfo.code === icd10Code,
    );
  }

  __renderPagination() {
    return html`
      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        class="pagination"
        .pageCount="${this.pageCount}"
        .currentPage="${this.currentPageIndex}"
        .onPageChanged="${this.__handlers.pageChanged}"
        .layout="${this.layout}"
      ></neb-pagination>
    `;
  }

  render() {
    return html`
      <div class="header-row">
        <div class="add-diagnosis-header-space"></div>
        <div class="icd-header header-text">ICD10</div>
        <div class="description-header header-text">Description</div>
      </div>
      ${
        this.searchResults
          ? this.__renderSearchResults(this.searchResults)
          : html`
              <div id="${ELEMENTS.noResultText.id}" class="no-result-text">
                Begin your search.
              </div>
            `
      }
      ${this.pageCount > 0 ? this.__renderPagination() : ''}
    `;
  }
}
customElements.define('neb-icd-code-table', NebIcdCodeTable);
