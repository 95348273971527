import ApiClient, { Method } from './api-client-utils';

const billingApiClient = new ApiClient({ microservice: 'billing' });

const path = 'encounters-not-balanced-count';

export const encountersNotBalancedCount = async (patientId = null) => {
  const result = await billingApiClient.makeRequest({
    method: Method.GET,
    path,
    headers: { 'Content-Type': 'application/json' },
    version: 1,
    cacheKey: path,
    ...(patientId && {
      cacheKey: `${path}?patientId=${patientId}`,
      queryParams: { patientId },
    }),
  });

  return result.count;
};
