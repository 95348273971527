import ApiClient from '../utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });
const VERSION = 2;

export const getBillingEncounterCharges = encounterId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `encounters/${encounterId}/charges`,
    cacheKey: `encounters/${encounterId}/charges`,
    version: VERSION,
  });
