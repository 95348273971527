import '../../../../packages/neb-lit-components/src/components/neb-pagination';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import { v4 as uuid } from 'uuid';

import NebPopup from '../../../../packages/neb-popup/src/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { normalizeForSearch } from '../../../../packages/neb-utils/formatters';
import { deepCopy } from '../../../../packages/neb-utils/utils';
import { formatAnswerSetsToDict } from '../../../formatters/macros';
import { CONFIG } from '../../tables/macros/neb-table-macro-link-choice-template';

export const ELEMENTS = {
  table: { id: 'table' },
  button: { id: 'button' },
  pagination: { id: 'pagination' },
  filter: { id: 'filter' },
};

const PAGE_ITEM_COUNT = 5;

class NebPopupMacroLinkChoiceTemplate extends NebPopup {
  static get properties() {
    return {
      __currentPage: Number,
      __choiceTemplates: Array,
      __searchText: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 996px;
        }

        .container {
          padding: 0;
        }

        .header {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .button-container {
          padding: ${CSS_SPACING};
        }

        .pagination-container {
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }

        .pagination {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
          height: 30px;
        }

        .table {
          height: 343px;
        }

        .filter {
          width: 450px;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
        }
      `,
    ];
  }

  modelChanged() {
    this.__choiceTemplates = deepCopy(this.model.templates);
    this.title = this.model.hideChooseButton
      ? 'Edit Choice Templates'
      : 'Link Choice Template';
  }

  initState() {
    super.initState();

    this.__currentPage = 0;
    this.__choiceTemplates = [];
    this.__searchText = '';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      cancel: () =>
        this.onClose({
          answerSets: formatAnswerSetsToDict(this.__choiceTemplates),
        }),

      choose: choiceTemplate => {
        this.onClose({
          selectedAnswerSetId: choiceTemplate.id,
          answerSets: formatAnswerSetsToDict(this.__choiceTemplates),
        });
      },

      pageChanged: index => {
        this.__currentPage = index;
      },

      delete: item => {
        const index = this.__choiceTemplates.findIndex(
          ({ id }) => id === item.id,
        );

        this.__choiceTemplates[index] = {
          ...this.__choiceTemplates[index],
          deleted: true,
        };

        this.__choiceTemplates = [...this.__choiceTemplates];
      },

      search: ({ value }) => {
        this.__setFilteredChoiceTemplates(value);
      },

      clearSearch: () => {
        this.__setFilteredChoiceTemplates('');
      },

      copy: item => {
        const copiedChoiceTemplateId = uuid();
        const copiedChoiceTemplate = deepCopy(item);

        this.__choiceTemplates = [
          ...this.__choiceTemplates,
          {
            ...copiedChoiceTemplate,
            answers: copiedChoiceTemplate.answers.map(({ value }) => ({
              id: null,
              value,
            })),
            id: copiedChoiceTemplateId,
            name: `Copy of ${item.name}`,
          },
        ].sort((a, b) => a.name.localeCompare(b.name));

        this.__setCurrentPageToChoiceTemplate(copiedChoiceTemplateId);
      },

      edit: async item => {
        const index = this.__choiceTemplates.findIndex(
          ({ id }) => id === item.id,
        );

        const result = await openPopup(
          POPUP_RENDER_KEYS.MACRO_EDIT_CHOICE_TEMPLATE,
          item,
        );

        if (!result) {
          return;
        }

        this.__choiceTemplates[index] = {
          ...item,
          ...result,
        };

        this.__choiceTemplates = [...this.__choiceTemplates].sort((a, b) =>
          a.name.localeCompare(b.name),
        );

        this.__setCurrentPageToChoiceTemplate(item.id);
      },
    };
  }

  __setCurrentPageToChoiceTemplate(choiceTemplateId) {
    this.__setFilteredChoiceTemplates('');

    this.__currentPage = Math.floor(
      this.__choiceTemplates.findIndex(({ id }) => id === choiceTemplateId) /
        PAGE_ITEM_COUNT,
    );
  }

  get __pageCount() {
    const choiceTemplates = this.__searchText
      ? this.__filteredChoiceTemplates
      : this.__choiceTemplates;
    return Math.ceil(choiceTemplates.length / PAGE_ITEM_COUNT);
  }

  get __tableModel() {
    const startIndex = this.__currentPage * PAGE_ITEM_COUNT;
    const choiceTemplates = this.__searchText
      ? this.__filteredChoiceTemplates
      : this.__choiceTemplates;
    return choiceTemplates
      .filter(({ deleted }) => !deleted)
      .slice(startIndex, startIndex + PAGE_ITEM_COUNT);
  }

  get __tableConfig() {
    return this.model.hideChooseButton
      ? CONFIG.filter(({ key }) => key !== 'choose')
      : CONFIG;
  }

  __setFilteredChoiceTemplates(value) {
    this.__searchText = value;
    const normalizedSearchText = normalizeForSearch(value);
    this.__filteredChoiceTemplates = this.__choiceTemplates.filter(n =>
      normalizeForSearch(n.name).includes(normalizedSearchText),
    );
  }

  __renderPagination() {
    return this.__choiceTemplates.length
      ? html`
          <div class="pagination-container">
            <neb-pagination
              id="${ELEMENTS.pagination.id}"
              class="pagination"
              .pageCount="${this.__pageCount}"
              .currentPage="${this.__currentPage}"
              .onPageChanged="${this.handlers.pageChanged}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.filter.id}"
        class="filter"
        leadingIcon="neb:search"
        placeholder="Filter by name"
        .trailingIcon="${this.__searchText ? 'neb:clear' : ''}"
        .value="${this.__searchText}"
        .onChange="${this.handlers.search}"
        .onClickIcon="${this.handlers.clearSearch}"
      ></neb-textfield>

      <neb-table-macro-link-choice-template
        id="${ELEMENTS.table.id}"
        class="table"
        .emptyMessage="${
          this.__searchText && this.__tableModel.length === 0
            ? 'No results.'
            : 'There are no choice templates.'
        }"
        .config="${this.__tableConfig}"
        .model="${this.__tableModel}"
        .onChoose="${this.handlers.choose}"
        .onDelete="${this.handlers.delete}"
        .onCopy="${this.handlers.copy}"
        .onEdit="${this.handlers.edit}"
      ></neb-table-macro-link-choice-template>

      ${this.__renderPagination()}

      <div class="button-container">
        <neb-button
          id="${ELEMENTS.button.id}"
          label="Done"
          .onClick="${this.handlers.cancel}"
        >
        </neb-button>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-macro-link-choice-template',
  NebPopupMacroLinkChoiceTemplate,
);
