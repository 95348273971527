import ApiClient, {
  Method,
} from '../../../../neb-api-client/src/utils/api-client-utils';
import {
  objToName,
  centsToCurrency,
  DEFAULT_NAME_OPTS,
} from '../../../../neb-utils/formatters';
import { printPdf } from '../../../../neb-utils/neb-pdf-print-util';

export const apiClientPdf = new ApiClient({ microservice: 'pdf' });

const hasLocations = practiceInfo =>
  practiceInfo.locations && practiceInfo.locations.length;

const hasBillingLocation = billingAddress =>
  billingAddress && billingAddress.locationId;

export const findSelectedLocation = (practiceInfo, locationId) =>
  hasLocations(practiceInfo) && locationId
    ? practiceInfo.locations.find(({ id }) => id === locationId)
    : null;

export const findBillingLocation = (practiceInfo, billingAddress) =>
  hasLocations(practiceInfo) && hasBillingLocation(billingAddress)
    ? practiceInfo.locations.find(({ id }) => id === billingAddress.locationId)
    : null;

const getSelectedLocationOrBillingLocation = (
  practiceInfo,
  billingAddress,
  locationId,
) => {
  const result =
    findSelectedLocation(practiceInfo, locationId) ||
    findBillingLocation(practiceInfo, billingAddress);

  return result;
};

const formattedSelectedLocationAddress = location => ({
  address1: location.address1,
  address2: location.address2,
  city: location.city,
  state: location.state,
  zipcode: location.zipCode,
});

const getBillingAddressOrPracticeAddress = (billingAddress, practiceInfo) => {
  const result = billingAddress || {
    ...practiceInfo.address,
    zipcode: practiceInfo.address.zipCode,
  };

  return result;
};

const getLocationAddress = (
  practiceInfo,
  billingAddress,
  location,
  locationId,
) => {
  const locationAddress =
    location && locationId
      ? formattedSelectedLocationAddress(location)
      : getBillingAddressOrPracticeAddress(billingAddress, practiceInfo);

  return locationAddress;
};

const getLocationName = (practiceInfo, location, locationId) => {
  const locationName = locationId
    ? location.businessName || practiceInfo.name
    : practiceInfo.name;

  return locationName;
};

export const buildPracticeInformation = (
  practiceInfo,
  billingAddress,
  locationId,
) => {
  const location = getSelectedLocationOrBillingLocation(
    practiceInfo,
    billingAddress,
    locationId,
  );

  return {
    ...practiceInfo,
    practiceName: getLocationName(practiceInfo, location, locationId),
    address: getLocationAddress(
      practiceInfo,
      billingAddress,
      location,
      locationId,
    ),
    email: location ? location.emailAddress : practiceInfo.email,
    phoneNumber: location ? location.phoneNumber : practiceInfo.phoneNumber,
    hideLogo: location && locationId ? location.hideLogo : false,
  };
};

const buildBookingReceipt = async (
  tenantId,
  linkedPatient,
  payment,
  practiceInfo,
  billingAddress,
) => {
  const patientPayments = [
    {
      patientName: objToName(
        {
          ...linkedPatient,
          first: linkedPatient.firstName,
          last: linkedPatient.lastName,
          middle: linkedPatient.middleName,
        },
        DEFAULT_NAME_OPTS,
      ),
      patientPayment: {
        ...payment,
        amount: centsToCurrency(payment.amount),
      },
    },
  ];

  const body = {
    actionPayment: {},
    practiceInformation: buildPracticeInformation(
      practiceInfo,
      billingAddress,
      payment.locationId,
    ),
    patientPayments,
  };

  const response = await apiClientPdf.makeRequest({
    optOutLoadingIndicator: false,
    path: `tenants/${tenantId}/booking/receipts`,
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    version: 1,
  });

  return response.buffer;
};

export const printBookingReceipt = (
  tenantId,
  linkedPatient,
  payment,
  practiceInfo,
  billingAddress,
) =>
  printPdf(
    buildBookingReceipt(
      tenantId,
      linkedPatient,
      payment,
      practiceInfo,
      billingAddress,
    ),
  );
