import { navigate } from '@neb/router';
import { html, css } from 'lit';

import {
  getPatientPackages,
  getPatientPackageUsedCounts,
} from '../../../../../src/api-clients/patient-package';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../neb-lit-components/src/components/neb-page-collection';
import { SORT_DIR } from '../../../../neb-lit-components/src/components/neb-table-header';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../neb-lit-components/src/utils/overlay-constants';
import { CSS_COLOR_WHITE } from '../../../../neb-styles/neb-variables';
import {
  objToName,
  capitalize,
  normalizeForSearch,
  HIDE_PREFERRED_OPTS,
} from '../../../../neb-utils/formatters';
import { mapToPatientPackageModel } from '../../../../neb-utils/patientPackage';
import '../../../../neb-lit-components/src/components/tables/neb-table-packages-subscriptions';

export const ELEMENTS = { ...BASE_ELEMENTS };
export const NO_ITEMS_TEXT =
  'There are no packages. Click “Add New Package” to add a package for this patient.';

const uniq = arr => [...new Set(arr)];
class NebPackagesSubscriptionsCollectionPage extends CollectionPage {
  static get properties() {
    return {
      patientId: {
        type: String,
      },
    };
  }

  static get config() {
    return {
      unifyForm: true,
      singularName: 'New Package',
      pluralName: '',
      initialSortKey: 'name',
      initialSortOrder: SORT_DIR.ASC,
      overlayKey: OVERLAY_KEYS.PATIENT_PACKAGE_ADD,
      tableConfig: [],
      searchLabel: 'Enter package name to filter list below.',
    };
  }

  initState() {
    super.initState();
    this.patientId = '';
    this.__tableState.sortParams = {
      key: 'name',
      dir: SORT_DIR.ASC,
    };

    this.onPackagesSubscriptionsChange = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      addItem: async () => {
        await this.openItemOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_ADD);
        await this.changeOccurred(
          {
            hideInactive: this.__tableState.hideInactive,
          },
          false,
        );
      },
      sort: (_, result) => {
        this.service.setSortParams(result[0]);
      },
      selectSharedBy: async ({ patientId }) => {
        navigate(`/patients/${patientId}/billing/packages-subscriptions`);

        this.patientId = patientId;

        const items = await this.fetch();
        this.service.setItems(items);
      },
      selectItem: (_, item) => {
        this.openItemOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, {
          id: item.id,
          patientId: this.patientId,
        });
      },
    };
  }

  __buildConfig() {
    return [
      {
        sortable: true,
        truncate: true,
        key: 'name',
        label: 'Name',
        flex: css`1.5 0 0`,
      },
      {
        sortable: false,
        truncate: true,
        key: 'summary',
        label: 'Summary',
        flex: css`1.5 0 0`,
      },
      {
        truncate: true,
        key: 'remaining',
        label: 'Remaining',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'subscription',
        label: 'Subscription',
        flex: css`1 0 0`,
        formatter: val => (val ? 'Yes' : 'No'),
      },
      {
        truncate: true,
        key: 'type',
        label: 'Type',
        flex: css`1 0 0`,
        formatter: val => capitalize(val),
      },
      {
        key: 'displayPackage',
        label: 'Status',
        flex: css`.5 0 0`,
        formatter: val => (val ? 'Active' : 'Inactive'),
      },
      {
        truncate: true,
        key: 'isDefault',
        label: 'Default',
        flex: css`.5 0 0`,
        formatter: val => (val ? 'Default' : ''),
      },
      {
        truncate: true,
        key: 'patientPackageRelatedPatients',
        label: 'Shared',
        flex: css`1 0 0`,
        formatter: val =>
          val && val.filter(p => p.active).length > 0
            ? `Yes (${val.filter(p => p.active).length + 1})`
            : 'No',
      },
      {
        truncate: true,
        key: 'sharedByName',
        label: 'Shared By',
        flex: css`1 0 0`,
        link: 'true',
      },
    ];
  }

  async openItemOverlay(overlayKey, item) {
    const resultItem = await openOverlay(overlayKey, {
      context: this.buildContext(),
      item,
    });

    if (resultItem) {
      if (!item) {
        this.service.addItem(resultItem);
      } else {
        this.service.updateItem(resultItem);
      }
    }
  }

  formatSearchItem({ name }) {
    return normalizeForSearch(name);
  }

  filterSearchItem({ terms, item }) {
    return terms.every(term => item.includes(term));
  }

  async fetch() {
    const patientPackages = await getPatientPackages(
      this.patientId,
      {
        includeShared: true,
        hideInactive: this.__tableState.hideInactive,
      },
      true,
    );

    const ids = patientPackages.map(pkg => pkg.id);

    const usedCounts = await getPatientPackageUsedCounts(
      this.patientId,
      ids,
      null,
      true,
    );

    this.service.setPageSize(10);

    const results = patientPackages.map(entry => {
      const match = usedCounts.find(pkg => pkg.patientPackageId === entry.id);

      return mapToPatientPackageModel(entry, match);
    });

    const resultsWithShared = await this.__addSharedByName(results);

    return resultsWithShared;
  }

  async __addSharedByName(data) {
    if (data && data.length > 0) {
      const uniquePats = uniq(data.map(d => d.patientId));
      const pats = await patientApiClient.fetchSome(uniquePats);

      const newResData = data.map(row => ({
        ...row,
        sharedByName:
          row.patientPackageRelatedPatients.filter(p => p.active).length > 0
            ? objToName(
                pats[pats.findIndex(p => p.id === row.patientId)].name,
                HIDE_PREFERRED_OPTS,
              )
            : '',
      }));

      return newResData;
    }

    return data;
  }

  async handleDisconnected() {
    await this.changeOccurred(
      {
        hideInactive: true,
      },
      true,
    );
  }

  async disconnectedCallback() {
    await this.handleDisconnected();

    super.disconnectedCallback();
  }

  buildContext() {
    return {
      patientId: this.patientId,
      filteredCount: this.__tableState.filteredCount,
    };
  }

  getMasterCount() {
    if (this.__tableState.searchText.length > 0) {
      return this.service.getTotalCount();
    }

    return this.__tableState.filteredCount;
  }

  renderNoItemsContent() {
    return NO_ITEMS_TEXT;
  }

  async changeOccurred({ hideInactive }, navigateAway = false) {
    this.service.setItems(await this.fetch());

    this.onPackagesSubscriptionsChange(
      hideInactive,
      this.__tableState.filteredCount,
      navigateAway,
    );
  }

  sort(a, b, key) {
    if (a.default || b.default) return 0;

    return this.__handleSort(a, b, key);
  }

  __handleSort(a, b, key) {
    return super.sort(a, b, key);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          overflow-y: auto;
          background-color: ${CSS_COLOR_WHITE};
          height: 100%;
        }

        .row {
          padding-top: 0;
        }
      `,
    ];
  }

  renderTable() {
    return html`
      <neb-table-packages-subscriptions
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        .layout="${this.layout}"
        .config="${this.__buildConfig()}"
        .patientId="${this.patientId}"
        .model="${this.__tableState.pageItems}"
        .onSelectRow="${this.handlers.selectItem}"
        .onSelectSharedBy="${this.handlers.selectSharedBy}"
        .sortParams="${[this.__tableState.sortParams]}"
        .onSort="${this.handlers.sort}"
        .emptyMessage="${NO_ITEMS_TEXT}"
        >${this.renderTableSlot()}
      </neb-table-packages-subscriptions>
    `;
  }
}

customElements.define(
  'neb-packages-subscriptions-collection-page',
  NebPackagesSubscriptionsCollectionPage,
);
