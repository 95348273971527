import {
  decodeUserPermissions,
  validateAccessByPermissionKey,
} from '@neb/permissions';

import { store } from '../../neb-redux/neb-redux-store';

import {
  formatOnlineAccountMatches,
  formatOnlineAccount,
} from './formatters/permissions-formatter';
import {
  mapClientModelToUser,
  mapUserToClientModel,
} from './mappers/practice-user-mapper';
import { apiClient as practiceUsersApiClient } from './practice-users-api-client';
import ApiClient, {
  buildQueryString,
  Method,
  RESPONSE_TYPE,
} from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const decodePermissions = () => {
  const {
    session: { item: session },
  } = store.getState();

  const { access, tenantId } = session;
  const { p } = access[tenantId];

  return decodeUserPermissions(p);
};

export const hasPermission = key => {
  const {
    session: { item: session },
  } = store.getState();

  const { access, tenantId } = session;

  const { p } = access[tenantId];

  return validateAccessByPermissionKey(key, p);
};

export const getSchedulingPermissions = () => {
  const { permissions } = decodePermissions();

  return permissions.find(p => p.name === 'scheduling').access;
};

export const getSettingsPermissions = () => {
  const { permissions } = decodePermissions();

  return permissions.find(p => p.name === 'settings').access;
};

export const getChartingPermissions = () => {
  const { permissions } = decodePermissions();

  return permissions.find(p => p.name === 'charting').access;
};

export const getBillingPermissions = () => {
  const { permissions } = decodePermissions();

  return permissions.find(p => p.name === 'billing').access;
};

export const isSuperUser = () => {
  const { superUser } = decodePermissions();
  return superUser;
};

export const apiClientV2 = new ApiClientV2({
  microservice: 'permissions',
});

export const apiClient = new ApiClient({
  microservice: 'permissions',
});

export const tenantApiClient = new ApiClient({
  microservice: 'permissions',
  useTenant: true,
});

export const getPracticeUsersAttributes = attributes =>
  tenantApiClient.makeRequest({
    method: Method.GET,
    path: `practice-users-query?attributes=${attributes.join(',')}`,
  });

export const createOnlineBookingAccount = account =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'public/patient',
    method: Method.POST,
    body: JSON.stringify({ ...account, email: account.email.toLowerCase() }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const createOnlineBookingAccountV2 = account =>
  apiClientV2.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v3/public/tenant/:tenantId/patient',
    method: Method.POST,
    replacements: {},
    body: JSON.stringify({ ...account, email: account.email.toLowerCase() }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getProvidersPublic = async tenantId => {
  const providers = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    version: 'public2',
    path: `tenants/${tenantId}/providers`,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `public-${tenantId}`,
  });

  return providers.map(provider => ({
    ...provider,
    firstName: provider.name.first,
    lastName: provider.name.last,
  }));
};

export const getPotentialMatches = async (
  queryParams,
  optOutLoadingIndicator = false,
) => {
  const matches = await apiClient.makeRequest({
    optOutLoadingIndicator,
    path: 'patient/potential-matches',
    headers: {
      'Content-Type': 'application/json',
    },
    queryParams,
    cacheKey: `matches-${JSON.stringify(queryParams)}`,
  });
  return formatOnlineAccountMatches(matches);
};

export const searchAllPracticeUsers = async ({ tenantIds, ...query }) => {
  const path = `practice-users${buildQueryString(query)}`;

  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
      ...(tenantIds && { tenantIds }),
    },
    method: Method.GET,
    path,
  });

  return res.map(item => mapClientModelToUser(item, true));
};

export const getAccount = async (accountId, optOutLoadingIndicator = false) => {
  try {
    const account = await apiClient.makeRequest({
      version: 'tenant2',
      path: `patients/${accountId}`,
      cacheKey: `account-${accountId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      optOutLoadingIndicator,
    });
    return formatOnlineAccount(account);
  } catch (e) {
    return null;
  }
};
export const getCognitoConfig = (type = 'patient') =>
  apiClient.makeRequest({
    path: `config/${type}`,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `cognito-config-${type}`,
    requestMethod: 'permissionsPublicRequest',
    version: 'public2',
  });

const practiceUserRequest = async (
  [
    path,
    method,
    version = 1,
    returnRawResponse = false,
    cacheKey = null,
    optOutLoadingIndicator = false,
  ],
  body,
) => {
  const response = await apiClient.makeRequest({
    version: `tenant${version}`,
    path,
    method,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey,
    optOutLoadingIndicator,
  });
  if (returnRawResponse) return response;

  if (response) {
    return response.length
      ? response.map(user => mapClientModelToUser(user))
      : mapClientModelToUser(response);
  }
  return undefined;
};

export const resendUserInvitation = cognitoId => {
  practiceUsersApiClient.clearCache();
  return practiceUserRequest([
    `practice-users/${cognitoId}/resend-invitation`,
    Method.POST,
    2,
    true,
  ]);
};

export const resendUserInvitationByEmail = email =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'public/resend-invitation',
    method: Method.POST,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

export const resendPatientUserInvitationByEmail = email =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'public/resend-patient-invitation',
    method: Method.POST,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

export const resetPassword = userId =>
  practiceUserRequest(
    [`practice-users/${userId}/password-reset`, Method.POST, 2, true],
    {},
  );

export const setUserActive = (userId, active) => {
  practiceUsersApiClient.clearCache();

  return apiClient.makeRequest({
    version: 'tenant2',
    path: `practice-users/${userId}/activation`,
    method: Method.PUT,
    body: JSON.stringify({ active }),
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator: false,
    updateNotificationDetails: { user: { id: userId } },
  });
};

export const userLogin = cognitoId =>
  practiceUserRequest([
    `practice-users/${cognitoId}/login`,
    Method.POST,
    2,
    true,
  ]);

export const getPracticeUser = (cognitoId, optOutLoadingIndicator) =>
  practiceUserRequest([
    `practice-users/${cognitoId}`,
    Method.GET,
    2,
    false,
    `user-${cognitoId}`,
    optOutLoadingIndicator,
  ]);

export const createStaffUser = user => {
  practiceUsersApiClient.clearCache();
  return practiceUserRequest(
    ['staff', Method.POST, 2],
    mapUserToClientModel(user),
  );
};

export const updateStaffUser = async user => {
  practiceUsersApiClient.clearCache();

  const response = await apiClient.makeRequest({
    version: 'tenant1',
    path: `staff/${user.id}`,
    method: Method.PUT,
    body: JSON.stringify(mapUserToClientModel(user)),
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `user-${user.id}`,
    optOutLoadingIndicator: false,
    updateNotificationDetails: { user: { id: user.id } },
  });

  if (response) {
    return response.length
      ? response.map(res => mapClientModelToUser(res))
      : mapClientModelToUser(response);
  }
  return undefined;
};

export const createSpecialistUser = user => {
  practiceUsersApiClient.clearCache();
  return practiceUserRequest(
    ['specialist', Method.POST],
    mapUserToClientModel(user),
  );
};

export const updateSpecialistUser = async user => {
  practiceUsersApiClient.clearCache();

  const response = await apiClient.makeRequest({
    version: 'tenant1',
    path: `specialist/${user.id}`,
    method: Method.PUT,
    body: JSON.stringify(mapUserToClientModel(user)),
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `user-${user.id}`,
    optOutLoadingIndicator: false,
    updateNotificationDetails: { user: { id: user.id } },
  });

  if (response) {
    return response.length
      ? response.map(res => mapClientModelToUser(res))
      : mapClientModelToUser(response);
  }
  return undefined;
};

export const createProviderUser = user => {
  practiceUsersApiClient.clearCache();
  return practiceUserRequest(
    ['provider', Method.POST],
    mapUserToClientModel(user),
  );
};

export const updateProviderUser = async user => {
  practiceUsersApiClient.clearCache();

  const response = await apiClient.makeRequest({
    version: 'tenant1',
    path: `provider/${user.id}`,
    method: Method.PUT,
    body: JSON.stringify(mapUserToClientModel(user)),
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `user-${user.id}`,
    optOutLoadingIndicator: false,
    updateNotificationDetails: { user: { id: user.id } },
  });

  if (response) {
    return response.length
      ? response.map(res => mapClientModelToUser(res))
      : mapClientModelToUser(response);
  }
  return undefined;
};
