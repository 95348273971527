import { EDIT_MODE } from '../../../src/components/overlays/appointments/neb-overlay-edit-reschedule-appointment';
import {
  createAppointment,
  updateAppointment,
  forceFetchAppointments,
} from '../../neb-calendar/neb-appointments-state';
import { SaveAppointmentErrorType } from '../../neb-calendar/state/appointments/reducers/actions/base';
import { openSuccess, openError } from '../../neb-dialog/neb-banner-state';
import { APPOINTMENT_ACTIONS } from '../../neb-lit-components/src/components/scheduling/neb-appointment-options';
import { parseDate } from '../../neb-utils/date-util';
import { store } from '../neb-redux-store';

export class AppointmentStoreService {
  async createAppointment(appointment) {
    let success = false;
    let model;
    let error;
    await store
      .dispatch(createAppointment(appointment))
      .then(async ({ type, createError, item } = {}) => {
        if (createError) {
          error = createError;
          const message =
            type === SaveAppointmentErrorType.SAVE_APPT_CONFLICT_ERROR
              ? 'This time is no longer available. Select a new time.'
              : 'Appointment was not successfully created.';
          store.dispatch(openError(message));
        } else if (item && item.isDuplicate) {
          success = item.success;
          model = item;
        } else {
          if (appointment.rrule) {
            await store.dispatch(
              forceFetchAppointments(parseDate(appointment.start)),
            );
          }
          success = true;
          model = item;

          store.dispatch(openSuccess('Appointment Created Successfully'));
        }
      });

    return { success, model, error };
  }

  edit(appointment) {
    return this.__updateAppointment(
      appointment.id,
      APPOINTMENT_ACTIONS.EDIT,
      appointment,
    );
  }

  checkIn(appointment) {
    return this.__updateAppointment(
      appointment.id,
      APPOINTMENT_ACTIONS.CHECK_IN,
      appointment,
    );
  }

  checkOut(appointment) {
    return this.__updateAppointment(
      appointment.id,
      APPOINTMENT_ACTIONS.CHECK_OUT,
      appointment,
    );
  }

  instantiateRecurrence(appointmentId) {
    return this.__updateAppointment(
      appointmentId,
      APPOINTMENT_ACTIONS.INSTANTIATE_RECURRENCE,
    );
  }

  returnToScheduled(appointmentId) {
    return this.__updateAppointment(
      appointmentId,
      APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED,
    );
  }

  async __updateAppointment(appointmentId, option, appointment = {}) {
    let success = false;
    let model;
    let error;
    await store
      .dispatch(updateAppointment(appointmentId, option.action, appointment))
      .then(({ updateError, item } = {}) => {
        if (updateError) {
          error = updateError;

          if (option.errorMessage) {
            store.dispatch(openError(option.errorMessage));
          }
        } else if (item && item.isDuplicate) {
          success = item.success;
          model = item;
        } else {
          success = true;
          model = item;

          if (option.successMessage) {
            store.dispatch(openSuccess(option.successMessage));
          }
        }
      });

    return { success, model, error };
  }

  async rescheduleAppointment(appointment, mode) {
    let success = false;
    let model;
    let error;

    const action =
      mode === EDIT_MODE.EDIT
        ? APPOINTMENT_ACTIONS.EDIT
        : APPOINTMENT_ACTIONS.RESCHEDULE;

    await store
      .dispatch(updateAppointment(appointment.id, 'reschedule', appointment))
      .then(({ type, updateError, item } = {}) => {
        if (updateError) {
          error = updateError;

          const message =
            type === SaveAppointmentErrorType.SAVE_APPT_CONFLICT_ERROR
              ? 'This time is no longer available. Select a new time.'
              : action.errorMessage;

          store.dispatch(openError(message));
        } else if (item && item.isDuplicate) {
          success = item.success;
          model = item;
        } else {
          success = true;
          model = item;

          store.dispatch(openSuccess(action.successMessage));

          if (appointment.rrule) {
            store.dispatch(
              forceFetchAppointments(parseDate(appointment.start)),
            );
          }
        }
      });

    return { success, model, error };
  }
}
