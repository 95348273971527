import '../../../src/components/pages/self-check-in-questionnaire/neb-self-check-in-questionnaire-nav-bar';
import '../../../src/components/pages/self-check-in-questionnaire/neb-page-subjective-questionnaire';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { openDirtyPopup } from '..';
import { BODY_VIEW } from '../../../src/components/misc/neb-body-diagram';
import { CSS_COLOR_ERROR } from '../../../src/styles/index';
import { Dirty } from '../../neb-redux/services/dirty';
import {
  CSS_COLOR_GREY_2,
  CSS_SPACING,
  OVERLAY_WIDTH_EXTRA_LARGE,
} from '../../neb-styles/neb-variables';
import { deepCopy } from '../../neb-utils/utils';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';
import { POPUP_RENDER_KEYS } from './renderer-keys';

const POPUP_MESSAGE_NO_SYMPTOMS_TODAY = html`
  This will submit a 'No Symptoms Today' response to the practice. Once
  submitted, you will not be able to return to this page.<br /><br />Are you
  sure that you want to proceed?
`;

const POPUP_MESSAGE_NO_CHANGE_IN_SYMPTOMS = html`
  This will submit a 'No Change in Symptoms' response to the practice. Once
  submitted, you will not be able to return to this page.<br /><br />Are you
  sure that you want to proceed?
`;

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: {
    id: 'form',
  },
  subtitle: {
    id: 'subtitle',
  },
  noChangeInSymptomsSubtitleText: {
    id: 'no-change-in-symptoms-subtitle-text',
  },
  noSymptomsTodaySubtitleText: {
    id: 'no-symptoms-today-subtitle-text',
  },
  navBar: {
    id: 'nav-bar',
  },
  navBarInstructionalText: {
    id: 'nav-bar-instructional-text',
  },
  selectBodyAreaToBeginText: {
    id: 'select-body-area-to-begin-text',
  },
  noSymptomsTodayNavBarText: {
    id: 'no-symptoms-today-nav-bar-text',
  },
  noChangeInSymptomsNavBarText: {
    id: 'no-change-in-symptoms-nav-bar-text',
  },
};

class NebPopupSubjectiveQuestionnaire extends NebPopup {
  static get properties() {
    return {
      questionnaires: Array,
      selectedIndex: Number,
      highlightRequiredFields: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: ${OVERLAY_WIDTH_EXTRA_LARGE};
          overflow-y: auto;

          /* this fixes iOS being borked */
          /* when rotating from portrait */
          /* mode to landscape and back: */
          -webkit-text-size-adjust: none;
        }
        :host(:not([layout='large'])) {
          margin: 0;
        }
        :host([layout='small']) {
          width: 100%;
        }

        .link {
          color: rgb(12, 170, 220);
          text-decoration: underline;
          cursor: pointer;
        }

        .required {
          color: ${CSS_COLOR_ERROR};
          font-weight: bold;
        }

        .header {
          display: flex;
          align-items: center;
        }

        .nav-bar-instructional-text {
          min-height: 30px;
          display: flex;
          align-items: flex-end;
        }

        .nav-bar-text-links {
          display: flex;
          gap: ${CSS_SPACING};
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          overflow-y: auto;
          flex: unset;
          margin-top: 0;
        }

        neb-form-subjective-questionnaire {
          border-top: 1px solid ${CSS_COLOR_GREY_2};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.title = 'Questionnaire';

    this.__dirty = true;
    this.__dirtyService = new Dirty(() => true, () => this.__dirty);

    this.questionnaires = [];
    this.selectedIndex = 0;
    this.highlightRequiredFields = false;

    this.onSave = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      saveBodyDiagram: async () => {
        if (this.__currentQuestionnaireAnswered()) {
          const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Finish Questionnaire',
            confirmText: 'Finish',
            cancelText: 'Add Additional Complaint',
            message:
              'Do you have an additional complaint or do you want to submit the questionnaire? If you submit the questionnaire, you will not be able to return to this page.',
          });

          if (result) {
            const appointment = await this.onSave(
              this.__generateCompleteNarrative(),
            );

            if (appointment) {
              this.onClose(appointment);
            }
          } else {
            this.__addNewSymptom();
          }
        } else {
          openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Incomplete Questionnaire',
            message:
              'One or more required questions have not been answered. Please answer the highlighted questions and resubmit the questionnaire in order to proceed.',
          });

          this.highlightRequiredFields = true;
        }
        this.__dirty = false;
      },

      saveNoBodyDiagram: async () => {
        if (this.__currentQuestionnaireAnswered()) {
          const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Finish Questionnaire',
            confirmText: 'Yes',
            cancelText: 'No',
            message:
              'This will submit your responses, and you will not be able to return to this page. Are you sure?',
          });

          if (result) {
            const appointment = await this.onSave(
              this.__generateCompleteNarrative(),
            );

            if (appointment) {
              this.onClose(appointment);
            }
          }
        } else {
          openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Incomplete Questionnaire',
            message:
              'One or more required questions have not been answered. Please answer the highlighted questions and resubmit the questionnaire in order to proceed.',
          });

          this.highlightRequiredFields = true;
        }
        this.__dirty = false;
      },

      cancel: async () => {
        if (await openDirtyPopup()) {
          this.onClose();
        }
      },

      selectQuestionnaire: index => {
        if (this.__currentQuestionnaireAnswered()) {
          this.selectedIndex = index;
          this.__scrollUp();
        } else {
          openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Incomplete Questionnaire',
            message:
              'Please finish answering the current symptom before switching symptoms.',
          });

          this.highlightRequiredFields = true;
        }
      },

      answerChanged: newQuestionnaire => {
        this.questionnaires = this.questionnaires.map(
          (oldQuestionnaire, index) =>
            index === this.selectedIndex ? newQuestionnaire : oldQuestionnaire,
        );
      },

      addSymptom: () => {
        if (this.__currentQuestionnaireAnswered()) {
          this.__addNewSymptom();
        } else {
          openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Incomplete Questionnaire',
            message:
              'Please finish answering the current symptom before adding a new one.',
          });

          this.highlightRequiredFields = true;
        }
      },

      removeSymptom: () => {
        this.questionnaires = this.questionnaires.filter(
          (_, index) => index !== this.selectedIndex,
        );

        switch (this.questionnaires.length) {
          case 0:
            this.questionnaires = [this.__newQuestionnaire()];
            this.selectedIndex = 0;
            break;
          case this.selectedIndex:
            this.selectedIndex = this.questionnaires.length - 1;
            break;
          default:
            // this.selectedIndex does not need to change.
            break;
        }

        this.highlightRequiredFields = false;
        this.__scrollUp();
      },

      noSymptomsToday: async () => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Finish Questionnaire',
          message: POPUP_MESSAGE_NO_SYMPTOMS_TODAY,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (result) {
          this.__dirty = false;
          const appointment = await this.onSave(
            'The patient reports no symptoms today.',
          );

          if (appointment) {
            this.onClose(appointment);
          }
        } else {
          this.__dirty = false;
        }
      },

      noChangeInSymptoms: async () => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Finish Questionnaire',
          message: POPUP_MESSAGE_NO_CHANGE_IN_SYMPTOMS,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        this.__dirty = false;

        if (result) {
          const appointment = await this.onSave(
            'The patient reported no change in their complaints from last time.',
            true,
          );

          if (appointment) {
            this.onClose(appointment);
          }
        }
      },

      selectBodyPart: ({
        selectedBodyPartId,
        selectedBodyPartName,
        selectedBodyView,
      }) => {
        this.questionnaires = this.questionnaires.map((questionnaire, index) =>
          index === this.selectedIndex
            ? {
                ...questionnaire,
                selectedBodyView,
                selectedBodyPartName,
                selectedBodyPartId,
              }
            : questionnaire,
        );
      },
    };
  }

  modelChanged() {
    this.onSave = this.model.onSave;
    this.questionnaires = [this.__newQuestionnaire()];
    this.selectedIndex = 0;
  }

  connectedCallback() {
    super.connectedCallback();
    this.__dirtyService.connect();
  }

  disconnectedCallback() {
    this.__dirtyService.disconnect();
    super.disconnectedCallback();
  }

  __scrollUp() {
    this.shadowRoot
      .getElementById(ELEMENTS.form.id)
      .scrollTo({ top: 0, behavior: 'smooth' });
  }

  __addNewSymptom() {
    this.questionnaires = [...this.questionnaires, this.__newQuestionnaire()];
    this.selectedIndex = this.questionnaires.length - 1;
    this.highlightRequiredFields = false;
    this.__scrollUp();
  }

  __currentQuestionnaireAnswered() {
    const questionnaire = this.questionnaires[this.selectedIndex];
    return (
      questionnaire.questions.every(({ isAnswered }) => isAnswered) &&
      (questionnaire.selectedBodyPartName || !questionnaire.showBodyDiagram)
    );
  }

  __generateCompleteNarrative() {
    return this.questionnaires
      .map(this.__generateQuestionnaireNarrative)
      .filter(Boolean)
      .join('\n');
  }

  __generateQuestionnaireNarrative(questionnaire) {
    return `${
      questionnaire.showBodyDiagram
        ? `The patient indicates they have discomfort in the ${
            questionnaire.selectedBodyPartName
          }. `
        : ''
    }${questionnaire.questions
      .map(question => question.narrativeValue)
      .filter(Boolean)
      .join(' ')
      .replace(/\n/g, ' ')}`;
  }

  __newQuestionnaire() {
    return {
      ...deepCopy(this.model.questionnaire),
      selectedBodyView: this.questionnaires[this.selectedIndex]
        ? this.questionnaires[this.selectedIndex].selectedBodyView
        : BODY_VIEW.BACK,
      selectedBodyPartName: '',
      selectedBodyPartId: '',
    };
  }

  renderContent() {
    return html`
      ${this.__renderSubtitle()} ${this.__renderNavBar()}
      ${this.__renderQuestionnaire()}
    `;
  }

  __renderSubtitle() {
    return this.__renderSubtitleAndTextLinks();
  }

  __renderSubtitleAndTextLinks() {
    const { showBodyDiagram } = this.model.questionnaire;
    const prompt = 'Please complete the questionnaire to check in';
    const scheme = this.__determineRenderScheme();

    if (showBodyDiagram) {
      return html`
        <div id="${ELEMENTS.subtitle.id}">${prompt}.</div>
      `;
    }

    switch (scheme) {
      case 'noSymptoms':
        return html`
          <div id="${ELEMENTS.subtitle.id}">
            ${prompt}, or
            <span
              id="${ELEMENTS.noSymptomsTodaySubtitleText.id}"
              class="link"
              @click="${this.handlers.noSymptomsToday}"
              >click here if you have no symptoms today</span
            >.
          </div>
        `;

      case 'noChange':
        return html`
          <div id="${ELEMENTS.subtitle.id}">
            ${prompt}, or
            <span
              id="${ELEMENTS.noChangeInSymptomsSubtitleText.id}"
              class="link"
              @click="${this.handlers.noChangeInSymptoms}"
              >click here if you have no change in symptoms</span
            >.
          </div>
        `;

      case 'both':
        return html`
          <div id="${ELEMENTS.subtitle.id}">
            Please complete the questionnaire to check in. If you have no new
            symptoms or no changes in your symptoms select an option below.
          </div>
          <div class="nav-bar-text-links">
            <neb-text
              id="${ELEMENTS.noSymptomsTodaySubtitleText.id}"
              link
              .onClick="${this.handlers.noSymptomsToday}"
              >No Symptoms Today</neb-text
            >
            <neb-text
              id="${ELEMENTS.noChangeInSymptomsSubtitleText.id}"
              link
              .onClick="${this.handlers.noChangeInSymptoms}"
              >No Change in Symptoms</neb-text
            >
          </div>
        `;

      default:
        return html`
          <div id="${ELEMENTS.subtitle.id}">${prompt}.</div>
        `;
    }
  }

  __renderNavBar() {
    if (!this.model.questionnaire.showBodyDiagram) {
      return html``;
    }

    return this.__renderNavBarAndTextLinks();
  }

  __renderNavBarAndTextLinks() {
    const selectedBodyPartNames = this.questionnaires.map(
      ({ selectedBodyPartName }) => selectedBodyPartName,
    );

    if (selectedBodyPartNames.some(Boolean)) {
      return html`
        <neb-self-check-in-questionnaire-nav-bar
          id="${ELEMENTS.navBar.id}"
          .items="${selectedBodyPartNames}"
          .selectedIndex="${this.selectedIndex}"
          .onSelect="${this.handlers.selectQuestionnaire}"
          .highlightRequiredFields="${this.highlightRequiredFields}"
        ></neb-self-check-in-questionnaire-nav-bar>
      `;
    }

    // prettier-ignore
    const selectBodyAreaToBegin = html`
      <span
        id="${ELEMENTS.selectBodyAreaToBeginText.id}"
        class="${this.highlightRequiredFields ? 'required' : ''}"
        >Select a body area to begin</span>`;
    const scheme = this.__determineRenderScheme();

    switch (scheme) {
      case 'noSymptoms':
        return html`
          <div
            class="nav-bar-instructional-text"
            id="${ELEMENTS.navBarInstructionalText.id}"
          >
            <span>
              ${selectBodyAreaToBegin}, or
              <span
                id="${ELEMENTS.noSymptomsTodayNavBarText.id}"
                class="link"
                @click="${this.handlers.noSymptomsToday}"
                >click here if you have no symptoms today</span
              >.
            </span>
          </div>
        `;

      case 'noChange':
        return html`
          <div
            class="nav-bar-instructional-text"
            id="${ELEMENTS.navBarInstructionalText.id}"
          >
            <span>
              ${selectBodyAreaToBegin}, or
              <span
                id="${ELEMENTS.noChangeInSymptomsNavBarText.id}"
                class="link"
                @click="${this.handlers.noChangeInSymptoms}"
                >click here if you have no change in symptoms</span
              >.
            </span>
          </div>
        `;

      case 'both':
        return html`
          <span id="${ELEMENTS.navBarInstructionalText.id}">
            <span class="${this.highlightRequiredFields ? 'required' : ''}">
              Select a body area to begin reporting new symptoms.
            </span>
            If you have no new symptoms or no changes in your symptoms, select
            an option below.
          </span>
          <div class="nav-bar-text-links">
            <neb-text
              id="${ELEMENTS.noSymptomsTodayNavBarText.id}"
              link
              .onClick="${this.handlers.noSymptomsToday}"
              >No Symptoms Today</neb-text
            >
            <neb-text
              id="${ELEMENTS.noChangeInSymptomsNavBarText.id}"
              link
              .onClick="${this.handlers.noChangeInSymptoms}"
              >No Change in Symptoms</neb-text
            >
          </div>
        `;

      default:
        return html`
          <div
            class="nav-bar-instructional-text"
            id="${ELEMENTS.navBarInstructionalText.id}"
          >
            <span>${selectBodyAreaToBegin}.</span>
          </div>
        `;
    }
  }

  __determineRenderScheme() {
    const {
      allowNoSymptomsToday,
      allowNoChangeInSymptoms,
    } = this.model.questionnaire;

    if (allowNoChangeInSymptoms && allowNoSymptomsToday) {
      return 'both';
    }

    if (allowNoSymptomsToday) {
      return 'noSymptoms';
    }

    if (allowNoChangeInSymptoms) {
      return 'noChange';
    }

    return null;
  }

  __renderQuestionnaire() {
    const questionnaire = this.questionnaires[this.selectedIndex];

    const selectedBodyPartIds = this.questionnaires.map(
      ({ selectedBodyPartId }) => selectedBodyPartId,
    );

    return html`
      <neb-page-subjective-questionnaire
        id="${ELEMENTS.form.id}"
        .questionnaire="${questionnaire}"
        .selectedBodyPartIds="${selectedBodyPartIds}"
        .onSave="${
          this.model.questionnaire.showBodyDiagram
            ? this.handlers.saveBodyDiagram
            : this.handlers.saveNoBodyDiagram
        }"
        .onCancel="${this.handlers.cancel}"
        .onAnswerChanged="${this.handlers.answerChanged}"
        .onAddSymptom="${this.handlers.addSymptom}"
        .onRemoveSymptom="${this.handlers.removeSymptom}"
        .onSelectBodyPart="${this.handlers.selectBodyPart}"
        .layout="${this.layout}"
        .highlightRequiredFields="${this.highlightRequiredFields}"
      ></neb-page-subjective-questionnaire>
    `;
  }
}

customElements.define(
  'neb-popup-subjective-questionnaire',
  NebPopupSubjectiveQuestionnaire,
);
