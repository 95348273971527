import { mapToModel, mapToRaw } from './mappers/patient-case-mapper';
import ApiClient, { Method } from './utils/api-client-utils';

const buildBaseUrl = patientId => `patients/${patientId}/cases`;

export const apiClient = new ApiClient({ microservice: 'billing' });

export async function add(patientId, model) {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(mapToRaw(model)),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: buildBaseUrl(patientId),
    version: 1,
    updateNotificationDetails: { patientCase: patientId },
  });

  return mapToModel(res.data[0]);
}

export async function getActiveCasesCount(
  patientId,
  optOutLoadingIndicator = false,
) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: buildBaseUrl(patientId),
    queryParams: {
      activeCountOnly: true,
    },
    version: 1,
    optOutLoadingIndicator,
  });

  return res.count;
}

export async function fetchMany(patientId, optOutLoadingIndicator = false) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: buildBaseUrl(patientId),
    version: 1,
    optOutLoadingIndicator,
    cacheKey: `${patientId}-patient-cases`,
  });

  return res.data.map(raw => mapToModel(raw));
}

export async function fetchOne(
  patientId,
  caseId,
  optOutLoadingIndicator = false,
) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: `${buildBaseUrl(patientId)}/${caseId}`,
    optOutLoadingIndicator,
    version: 1,
  });

  return mapToModel(res.data[0]);
}

export async function update(
  patientId,
  model,
  shouldUpdateNotificationsDetails = true,
) {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(mapToRaw(model)),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `${buildBaseUrl(patientId)}/${model.id}`,
    version: 1,
    ...(shouldUpdateNotificationsDetails
      ? { updateNotificationDetails: { patientCase: { id: model.id } } }
      : {}),
  });

  return {
    patientCase: mapToModel(res.data[0]),
    caseOverrideUpdated: res.caseOverrideUpdated,
  };
}

export async function getCharges({
  caseId,
  patientId,
  optOutLoadingIndicator = false,
  offset = 0,
  limit = 100,
}) {
  const path = `${buildBaseUrl(patientId)}/${caseId}/charges`;
  const queryParams = {
    limit,
    offset,
  };
  const response = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path,
    queryParams,
    version: 1,
    optOutLoadingIndicator,
  });

  return response.data;
}
