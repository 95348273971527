import { html, LitElement, css } from 'lit';

import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  CSS_BORDER_GREY_3,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
} from '../../../../neb-styles/neb-variables';
import {
  hidePropIfEncounterOnly,
  renderDateOrFullDateBasedOnEncounterOnly,
} from '../../utils/encounter-only-util';

export const ELEMENTS = {
  title: {
    id: 'title',
  },
  dateTime: {
    id: 'date-time',
  },
  provider: {
    id: 'provider',
  },
  type: {
    id: 'type',
  },
  status: {
    id: 'status',
  },
  date: {
    selector: '.date',
  },
  providerName: {
    selector: '.provider-name',
  },
  aptType: {
    selector: '.appointment-type',
  },
  signed: {
    selector: '.cell-row-status',
  },
  messageNoEncounters: {
    id: 'message-no-encounters',
    text: 'There are no encounters for this patient.',
  },
  recentEncounters: {
    selector: '.row-item',
  },
};

class NebRecentEncounters extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      recentEncounters: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.recentEncounters = [];

    this.onRecentEncountersClicked = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      recentEncountersClicked: () => this.onRecentEncountersClicked(),
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        border: ${CSS_BORDER_GREY_2};
        border-radius: 5px;
        cursor: pointer;
        margin: ${CSS_SPACING} 0;
      }

      :host([layout='small']) {
        border: 0;
        border-radius: 0;
        margin: 5px 0;
      }

      .marker {
        width: 8px;
      }

      .title {
        text-decoration: underline;
        font-weight: bold;
        color: ${CSS_COLOR_HIGHLIGHT};
        margin: 10px;
      }

      .recent-encounters-row {
        display: flex;
        border-bottom: ${CSS_BORDER_GREY_3};
      }

      .header-item {
        flex: 1 0 0;
        font-weight: bold;
        padding: 5px 8px 5px 5px;
      }

      .header-date-time {
        padding-left: 10px;
        margin-right: 3px;
      }

      .cell {
        flex: 1 0 0;
        padding: 5px 8px 5px 5px;
      }

      .cell-row-status,
      .header-status {
        flex: 0.5 0 0;
      }

      .cell-row-type {
        flex: 1.5 0 0;
      }

      .date-time {
        min-width: 145px;
      }

      .cell-ellipsis {
        white-space: nowrap;
        width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .no-encounters {
        padding: 3px 10px;
        margin: ${CSS_SPACING};
        font-size: ${CSS_FONT_SIZE_BODY};
        font-style: italic;
        color: ${CSS_COLOR_GREY_1};
      }
    `;
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.title.id}" class="title">Recent Encounters</div>
      <div class="recent-encounters-row">
        <div
          id="${ELEMENTS.dateTime.id}"
          class="date-time header-item header-date-time"
        >
          Date & Time
        </div>
        <div id="${ELEMENTS.provider.id}" class="header-item">Provider</div>
        <div id="${ELEMENTS.type.id}" class="cell-row-type header-item">
          Type
        </div>
        ${this.__renderHeaderStatus()}
      </div>
    `;
  }

  __renderHeaderStatus() {
    return this.layout !== 'small'
      ? html`
          <div id="${ELEMENTS.status.id}" class="header-status header-item">
            Status
          </div>
        `
      : '';
  }

  __renderStatus(signed) {
    return this.layout !== 'small'
      ? html`
          <div class="cell-row-status cell">${signed ? 'Signed' : 'Open'}</div>
        `
      : '';
  }

  __renderRow(encounter, index) {
    const { markerColor, provider, signed } = encounter;

    return html`
      <div
        id="recent-encounter-${index}"
        class="recent-encounters-row row-item"
      >
        <div class="marker" style="background-color: ${markerColor};"></div>
        <div class="date-time cell date">
          ${renderDateOrFullDateBasedOnEncounterOnly(encounter)}
        </div>
        <div class="cell cell-ellipsis provider-name">${provider}</div>
        <div class="cell cell-ellipsis cell-row-type appointment-type">
          ${hidePropIfEncounterOnly(encounter, 'appointmentType')}
        </div>
        ${this.__renderStatus(signed)}
      </div>
    `;
  }

  __renderNoEncounters() {
    return html`
      <div id="${ELEMENTS.messageNoEncounters.id}" class="no-encounters">
        ${ELEMENTS.messageNoEncounters.text}
      </div>
    `;
  }

  __renderRecentEncounters() {
    return this.recentEncounters.length
      ? this.recentEncounters.map((encounter, index) =>
          this.__renderRow(encounter, index),
        )
      : this.__renderNoEncounters();
  }

  render() {
    return html`
      ${this.__renderHeader()} ${this.__renderRecentEncounters()}
    `;
  }
}

customElements.define('neb-recent-encounters', NebRecentEncounters);
