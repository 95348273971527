import '../../../../neb-lit-components/src/components/neb-toggle-button';
import '../shared/neb-prior-encounters-list';
import './neb-charting-diagnosis-prior-diagnoses-row';

import { LitElement, html } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  priorDiagnosesRows: {
    selector: 'neb-charting-diagnosis-prior-diagnoses-row',
  },
};
export const ID_HEADER_ROW = 'row-header';
export const ID_PREFIX_ROW = 'row-';
export const ID_NO_ROWS = 'no-rows-text';

class NebChartingDiagnosisPriorDiagnosesTable extends LitElement {
  static get properties() {
    return {
      codes: Array,
      searchText: String,
      message: String,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.codes = null;
    this.searchText = '';
    this.message = '';

    this.onAddCode = () => {};

    this.onAddAllCodes = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addCode: code => this.onAddCode(code),
      addAllCodes: () => {
        if (this.codes) {
          this.onAddAllCodes(this.codes.filter(item => !item.added));
        }
      },
    };
  }

  areAllAdded() {
    return (
      this.codes &&
      this.codes.length !== 0 &&
      this.codes.findIndex(item => !item.added) === -1
    );
  }

  __renderNoRowsText() {
    return html`
      <div id="${ID_NO_ROWS}" class="no-matching-encounter-text">
        ${this.message}
      </div>
    `;
  }

  __renderRows() {
    return !this.searchText.length && this.codes.length
      ? this.codes.map(
          (item, index) => html`
            <neb-charting-diagnosis-prior-diagnoses-row
              id="${ID_PREFIX_ROW}${index}"
              .model="${item}"
              .onClick="${this.__handlers.addCode}"
            ></neb-charting-diagnosis-prior-diagnoses-row>
          `,
        )
      : this.__renderNoRowsText();
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          display: flex;
          flex-direction: column;
        }

        .no-matching-encounter-text {
          margin: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
        }
      </style>
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <neb-charting-diagnosis-prior-diagnoses-row
        id="${ID_HEADER_ROW}"
        .model="${
          {
            added: this.areAllAdded(),
          }
        }"
        .onClick="${this.__handlers.addAllCodes}"
        header
      >
      </neb-charting-diagnosis-prior-diagnoses-row>

      ${this.codes !== null ? this.__renderRows() : ''}
    `;
  }
}

customElements.define(
  'neb-charting-diagnosis-prior-diagnoses-table',
  NebChartingDiagnosisPriorDiagnosesTable,
);
