import { CLAIM_BATCH_STATUS } from '../../neb-lit-components/src/components/tables/neb-table-claim-batches';
import { isClaimElectronic } from '../../neb-utils/claims';

import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';

export const BASE_URL = 'claim-batches';
export const PENDING_BATCHES_MINUTES = 24 * 60;
export const apiClient = new ApiClient({ microservice: 'billing' });

export function mapToRaw(model) {
  return {
    claims: model.map(claim =>
      claim.claimId
        ? {
            claimId: claim.claimId,
            insuranceId: claim.primaryInsuranceId,
            invoiceId: claim.invoiceId,
            isElectronic: isClaimElectronic(claim),
          }
        : {
            insuranceId: claim.primaryInsuranceId,
            invoiceId: claim.invoiceId,
            isElectronic: isClaimElectronic(claim),
          },
    ),
  };
}

export async function add(claims) {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(mapToRaw(claims)),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: BASE_URL,
    version: 2,
  });

  return res;
}

export function fetchMany(queryParams = {}, optOutLoadingIndicator = false) {
  return apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: BASE_URL,
    queryParams,
    version: 1,
  });
}

export function fetchOne(queryParams = {}, id, optOutLoadingIndicator = false) {
  return apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: `${BASE_URL}/${id}`,
    queryParams,
    version: 1,
  });
}

export function bulkPrintClaimBatches({ left, top, bold = false }, id) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    queryParams: { offsetX: left, offsetY: top, bold },
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: `${BASE_URL}/${id}/pdf`,
    version: 1,
  });
}

export function getPendingBatches(minutes = PENDING_BATCHES_MINUTES) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: `${BASE_URL}/pending/${minutes}`,
    version: 1,
  });
}

export function updateBatchStatus(
  batchId,
  status = CLAIM_BATCH_STATUS.COMPLETED,
) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: true,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `${BASE_URL}/${batchId}/status`,
    responseType: RESPONSE_TYPE.RAW,
    body: { status },
    version: 2,
  });
}
