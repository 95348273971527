import ApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const billingApiClient = new ApiClient({ microservice: 'billing' });
export const appointmentsApiClient = new ApiClient({
  microservice: 'appointments',
});

export const getUnmatchedAppointments = ({ limit, offset, sortParams }) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `appointments?patientId=&limit=${limit}&offset=${offset}&sortDir=${sortParams}`,
    version: 1,
  });

export const confirmAppointment = id =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `appointments/${id}/confirmation`,
    version: 1,
    updateNotificationDetails: {
      appointment: {
        id,
      },
    },
  });

export const unconfirmAppointment = id =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.DELETE,
    path: `appointments/${id}/confirmation`,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
    updateNotificationDetails: {
      appointment: {
        id,
      },
    },
  });

export const getAppointmentTotals = async ({
  date,
  locationIds,
  providerIds,
}) => {
  const res = await appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: true,
    method: Method.POST,
    path: 'appointments/totals',
    queryParams: { date },
    body: JSON.stringify({ locationIds, providerIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 2,
  });

  return res;
};

export const markPatientAsArrived = id =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `appointments/${id}/arrived`,
    version: 1,
    updateNotificationDetails: {
      appointment: {
        id,
      },
    },
  });

export const checkInAppointment = ({ id, body }) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `appointments/${id}/checked-in`,
    version: 'public1',
    body: JSON.stringify(body),
    responseType: RESPONSE_TYPE.RAW,
    updateNotificationDetails: {
      appointment: {
        id,
      },
    },
  });

export const createWalkInAppointment = body =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: 'walk-in-appointments',
    version: 1,
    body: JSON.stringify(body),
    responseType: RESPONSE_TYPE.JSON,
  });

export const updateAppointmentRoom = ({ id, body }) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `appointments/${id}/room`,
    version: 1,
    body: JSON.stringify(body),
    updateNotificationDetails: {
      appointment: {
        id,
      },
    },
  });
