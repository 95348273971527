import '../../../../../../src/components/controls/inputs/neb-checkbox';
import './neb-claim-form-cell';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { TOOLTIP_TEXT } from '../../../../../neb-utils/claims';
import { CLAIM_FORM_ERROR_TYPES } from '../../forms/neb-form-claim/create-selectors';

const CHECKBOX_CONFIG = [{ type: 'EIN' }, { type: 'SSN' }];

export const ELEMENTS = {
  cell: { id: 'tax-id-cell' },
  checkbox: { selector: 'neb-checkbox' },
};

class NebClaimFormCellFL25 extends LitElement {
  static get properties() {
    return {
      model: Object,
      errors: Object,
      renderExpandIcon: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = {};
    this.errors = {
      federalTaxIdNumber: '',
    };

    this.renderExpandIcon = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickCell: claimCellData => this.onClick(claimCellData),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .fl-25 {
          display: grid;
          grid-column: span 3;
        }

        .checkbox {
          float: right;
          display: inline;
          padding: 30px 5px 5px 5px;
        }
      `,
    ];
  }

  __taxTypeAndNumberExist({ federalTaxIdNumber, federalTaxIdType }) {
    return federalTaxIdNumber && federalTaxIdType ? federalTaxIdNumber : '';
  }

  __renderCheckbox({ federalTaxIdType }) {
    return html`
      ${
        CHECKBOX_CONFIG.map(
          ({ type }) => html`
            <neb-checkbox
              class="checkbox"
              disabled
              label="${type}"
              ?checked="${federalTaxIdType === type}"
            ></neb-checkbox>
          `,
        )
      }
    `;
  }

  render() {
    return html`
      <neb-claim-form-cell
        id="${ELEMENTS.cell.id}"
        class="fl-25"
        label="25. Federal Tax I.D. Number"
        borderRight
        borderBottom
        ?warn="${
          this.errors.federalTaxIdNumber === CLAIM_FORM_ERROR_TYPES.RECOMMENDED
        }"
        type="federalTaxId"
        ?renderExpandIcon="${this.renderExpandIcon}"
        .data="${this.__taxTypeAndNumberExist(this.model)}"
        .tooltipText="${TOOLTIP_TEXT.FL_25}"
        .problemText="${this.errors.federalTaxIdNumber}"
        .onClick="${this.__handlers.clickCell}"
      >
        ${this.__renderCheckbox(this.model)}
      </neb-claim-form-cell>
    `;
  }
}

window.customElements.define('neb-claim-form-cell-fl-25', NebClaimFormCellFL25);
