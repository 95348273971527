import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../pages/support/neb-practice-details-summary';
import '../../pages/support/neb-practice-details-data-migration';
import '../../pages/support/neb-practice-details-era';
import '../../../../packages/neb-www-settings/src/components/user-maintenance/neb-user-maintenance-page';
import '../../collection-pages/neb-collection-page-clearinghouse-settings';
import '../../../../packages/neb-lit-components/src/components/neb-search-bar';

import { html, css } from 'lit';

import { upsert } from '../../../../packages/neb-api-client/src/settings-api-client';
import { updatePractice } from '../../../../packages/neb-api-client/src/support/practice-api-client';
import {
  openSuccess,
  openError,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { openDirtyPopup } from '../../../../packages/neb-popup';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { FEATURE_FLAGS } from '../../../../packages/neb-utils/feature-util';
import * as featureFlagApiClient from '../../../api-clients/feature-flags';
import { updateDefault } from '../../../api-clients/support/billing-clearinghouse-api-client';
import {
  CSS_BORDER_GREY_2,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../styles';
import { ADD_ONS } from '../../../utils/add-ons';
import NebFormFeatureFlags from '../../forms/neb-form-feature-flags';
import NebFormPracticePackage from '../../forms/neb-form-practice-package';

export const ELEMENTS = {
  name: { id: 'name' },
  clientId: { id: 'client-id' },
  cancelButton: { id: 'button-cancel' },
  tabGroup: { id: 'tab-group' },
  summaryTab: { id: 'summary-tab' },
  summaryTabView: { id: 'summary-tab-view' },
  packageTab: { id: 'package-tab' },
  packageTabView: { id: 'package-tab-view' },
  featureTab: { id: 'feature-tab' },
  featureForm: { id: 'feature-form' },
  usersTab: { id: 'users-tab' },
  usersTabView: { id: 'users-tab-view' },
  clearinghouseTab: { id: 'clearinghouse-tab' },
  clearinghouseTabView: { id: 'clearinghouse-tab-view' },
  dataTab: { id: 'data-tab' },
  dataTabView: { id: 'data-tab-view' },
  eraTab: { id: 'era-tab' },
  eraTabView: { id: 'era-tab-view' },
  searchBar: { id: 'search-bar' },
};

const setItem = value => ({
  type: 'SESSION_SET_ITEM',
  value,
});
class NebOverlayPracticeDetails extends Overlay {
  static get properties() {
    return {
      __navItems: Object,
      __tabId: String,
      __packageFormModel: Object,
      __packageFormSavingError: Object,
      __featureFormModel: Array,
      __featureFormSavingError: Object,
      __userCount: Number,
      __enableFullAccess: Boolean,
      __filteredFlags: Array,
      __searchText: String,
      layout: {
        reflect: true,
        type: String,
      },
      model: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: 100%;
        }

        .content {
          width: 100%;
          height: 100%;
        }

        .container-header {
          display: flex;
          align-items: flex-start;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .header {
          border: 0;
          border-radius: 2px;
          border: ${CSS_BORDER_GREY_2};
          padding: 10px 24px;
          flex: 0 0 50%;
        }

        .name {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          font-size: ${CSS_FONT_SIZE_HEADER};
        }

        .spacer {
          flex: 1 0 0;
        }

        .icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .tabs {
          margin: ${CSS_SPACING} 0;
          display: table;
        }

        .page {
          height: 100%;
          overflow: hidden;
        }

        .summary-tab-view {
          overflow: scroll;
          padding-bottom: 20px;
        }

        .search-bar {
          width: auto;
          padding: 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__tabId = ELEMENTS.summaryTab.id;
    this.__packageFormModel = NebFormPracticePackage.createModel();
    this.__packageFormSavingError = null;
    this.__featureFormModel = NebFormFeatureFlags.createModel();
    this.__featureFormSavingError = null;
    this.__userCount = 0;
    this.__addOns = [];
    this.__enableFullAccess = false;
    this.__filteredFlags = [];
    this.__searchText = '';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      updateUserCount: state => {
        this.__userCount = state.filteredCount;
      },
      selectTab: id => {
        this.__tabId = id;
      },
      cancelPackageForm: async () => {
        if (this.isDirty) {
          if (await openDirtyPopup()) {
            this.isDirty = false;
          }
        }

        if (!this.isDirty) {
          this.__packageFormModel = { ...this.__packageFormModel };
        }
      },
      savePackageForm: async model => {
        try {
          const body = { ...this.model.item, ...model };
          await Promise.all([
            updatePractice(body),
            updateDefault(body),
            upsert({ autoAllocatePatientPayment: false }),
          ]);

          this.__packageFormSavingError = null;
          this.__packageFormModel = model;

          this.isDirty = false;

          store.dispatch(openSuccess('Package & Add-ons saved successfully'));
        } catch (e) {
          console.error(e);

          store.dispatch(
            openError('An error occurred when saving Package & Add-ons'),
          );

          this.__packageFormSavingError = e;
        }
      },
      cancelFeatureFlagForm: async () => {
        if (this.isDirty) {
          if (await openDirtyPopup()) {
            this.isDirty = false;
            this.__featureFormModel = [...this.__featureFormModel];
          }
        }
      },
      saveFeatureFlagForm: async features => {
        try {
          this.__savingError = null;

          await featureFlagApiClient.update(features);

          store.dispatch(openSuccess('Feature Access saved successfully'));

          this.__featureFormModel = features;
          this.requestUpdate();
        } catch (e) {
          store.dispatch(
            openError('An error occurred when saving Feature Access'),
          );

          console.error(e);
          this.__featureFormSavingError = e;
        }
      },
      searchFeatureFlags: searchText => {
        this.__searchText = searchText;

        const searchTerms = searchText
          .trim()
          .toLowerCase()
          .split(' ');

        const allFlags = Object.values(FEATURE_FLAGS);
        const filteredFlags = allFlags.filter(flag =>
          searchTerms.every(term => flag.includes(term)),
        );

        this.__filteredFlags =
          searchTerms && filteredFlags.length ? filteredFlags : ['none'];
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    const sessionItem = await store.getState().session.item;
    await store.dispatch(
      setItem({
        ...sessionItem,
        tenantId: this.model.item.id,
      }),
    );

    const userGroups =
      (sessionItem &&
        sessionItem.idToken &&
        sessionItem.idToken.payload &&
        sessionItem.idToken.payload['cognito:groups']) ||
      [];

    this.__enableFullAccess = !userGroups.includes('supportAgent');
  }

  __getActiveUserCount(items) {
    const users = items ? items.filter(user => user.active) : [];

    return Array.isArray(users) && users.length ? users.length : 0;
  }

  __renderUserCount(count) {
    return count !== 0 ? ` (${count})` : '';
  }

  get locations() {
    const {
      item: { locations },
    } = this.model;
    return locations.filter(({ addressOnly }) => !addressOnly);
  }

  __hasByoc() {
    return [
      ADD_ONS.CT_MAXCLEAR,
      ADD_ONS.CT_FLEXCLEAR,
      ADD_ONS.CT_PROCLEAR,
    ].some(item => this.__packageFormModel.addOns.includes(item));
  }

  genNavItems() {
    this.__hasAddOnCtBYOC = this.__hasByoc();

    const navItems = [
      {
        label: 'Summary',
        id: ELEMENTS.summaryTab.id,
        renderer: () => html`
          <neb-practice-details-summary
            id="${ELEMENTS.summaryTabView.id}"
            class="${ELEMENTS.summaryTabView.id}"
            .model="${this.model.item}"
          ></neb-practice-details-summary>
        `,
      },
      ...(this.__enableFullAccess
        ? [
            {
              label: 'Package & Add-ons',
              id: ELEMENTS.packageTab.id,
              renderer: () =>
                html`
                  <neb-form-practice-package
                    id="${ELEMENTS.packageTabView.id}"
                    .model="${this.__packageFormModel}"
                    .savingError="${this.__packageFormSavingError}"
                    .onSave="${this.handlers.savePackageForm}"
                    .onCancel="${this.handlers.cancelPackageForm}"
                    .onChangeDirty="${this.handlers.dirty}"
                  ></neb-form-practice-package>
                `,
            },
          ]
        : []),
      ...(this.__enableFullAccess
        ? [
            {
              label: 'Feature Access',
              id: ELEMENTS.featureTab.id,
              renderer: () => this.renderFeatureFlagForm(),
            },
          ]
        : []),

      ...(this.__enableFullAccess
        ? [
            {
              label: `Users${this.__renderUserCount(this.__userCount)}`,
              id: ELEMENTS.usersTab.id,
              renderer: () => html`
                <neb-user-maintenance-page
                  id="${ELEMENTS.usersTabView.id}"
                  class="page page-collection"
                  .layout="${this.layout}"
                  .onChange="${this.handlers.updateUserCount}"
                  .locations="${this.locations}"
                ></neb-user-maintenance-page>
              `,
            },
          ]
        : []),
      ...(this.__enableFullAccess && this.__hasAddOnCtBYOC
        ? [
            {
              label: 'Clearinghouse',
              id: ELEMENTS.clearinghouseTab.id,
              renderer: () => html`
                <neb-collection-page-clearinghouse-settings
                  id="${ELEMENTS.clearinghouseTabView.id}"
                  class="page page-collection"
                  .layout="${this.layout}"
                  .addOnsSupport="${this.__packageFormModel.addOns}"
                  .accessFromSupport="${true}"
                  readOnly
                ></neb-collection-page-clearinghouse-settings>
              `,
            },
          ]
        : []),
      ...(this.__enableFullAccess
        ? [
            {
              label: 'Data Migration',
              id: ELEMENTS.dataTab.id,
              renderer: () => html`
                <neb-practice-details-data-migration
                  id="${ELEMENTS.dataTabView.id}"
                  .model="${this.model.item}"
                >
                </neb-practice-details-data-migration>
              `,
            },
          ]
        : []),
      {
        label: 'ERA',
        id: ELEMENTS.eraTab.id,
        renderer: () => html`
          <neb-practice-details-era
            id="${ELEMENTS.eraTabView.id}"
            .model="${this.model.item}"
            .layout="${this.layout}"
          >
          </neb-practice-details-era>
        `,
      },
    ];

    this.__navItems = navItems;

    return navItems;
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__packageFormModel = {
        tier: this.model.item.tier,
        addOns: this.model.item.addOns,
        tenantId: this.model.item.id,
      };

      this.__featureFormModel = [...this.model.item.features];

      this.__userCount = this.__getActiveUserCount(this.model.item.users);

      this.__navItems = this.genNavItems();
    }

    super.update(changedProps);
  }

  renderFeatureFlagForm() {
    return html`
      <neb-search-bar
        id="${ELEMENTS.searchBar.id}"
        class="search-bar"
        placeholderText="Search Feature Flags"
        .searchText="${this.__searchText}"
        .onSearch="${this.handlers.searchFeatureFlags}"
      ></neb-search-bar>
      <neb-form-feature-flags
        id="${ELEMENTS.featureForm.id}"
        .model="${this.__featureFormModel}"
        .savingError="${this.__featureFormSavingError}"
        .onChangeDirty="${this.handlers.dirty}"
        .onSave="${this.handlers.saveFeatureFlagForm}"
        .onCancel="${this.handlers.cancelFeatureFlagForm}"
        .filteredFlags="${this.__filteredFlags}"
      ></neb-form-feature-flags>
    `;
  }

  renderClientId() {
    return this.model.item.salesforceId || 'No ID found';
  }

  renderTabContent() {
    const tabItem = this.genNavItems().find(item => item.id === this.__tabId);

    return tabItem.renderer();
  }

  renderContent() {
    const items = this.genNavItems();
    return html`
      <div class="container-header">
        <div class="header">
          <div id="${ELEMENTS.name.id}" class="name">
            ${this.model.item.name}
          </div>

          <span id="${ELEMENTS.clientId.id}">${this.renderClientId()}</span>
        </div>

        <div class="spacer"></div>

        <neb-icon
          id="${ELEMENTS.cancelButton.id}"
          class="icon"
          icon="neb:close"
          @click="${this.handlers.dismiss}"
        ></neb-icon>
      </div>

      <neb-tab-group
        id="${ELEMENTS.tabGroup.id}"
        class="tabs"
        .selectedId="${this.__tabId}"
        .items="${items}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>

      ${this.renderTabContent()}
    `;
  }
}

customElements.define(
  'neb-overlay-practice-details',
  NebOverlayPracticeDetails,
);
