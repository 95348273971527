import { css, unsafeCSS } from 'lit';

import { getTextWidthFromElement } from '../../../neb-utils/dom';
import { summate } from '../../../neb-utils/utils';

const SPACING = 40;
const HEADER_CONTENT = 28 + 20;
const EXPAND_ARROW = 12 + 20;
const TOTAL_SPACING = HEADER_CONTENT + EXPAND_ARROW + SPACING;

function buildFlex(flex, value) {
  const [grow] = flex.cssText.split(' ');

  const adjustedValue = value > 200 ? 200 : value;

  return css`${unsafeCSS(grow)} 0 ${unsafeCSS(adjustedValue)}px`;
}

function findContentWidths(config, items, node) {
  const headerItem = config.reduce(
    (accum, curr, _index) => ({ ...accum, [curr.key]: curr.label }),
    {},
  );

  const allItems = [headerItem, ...items];
  const sanitize = v => (v !== null && v !== undefined ? v : '');
  const formattedValue = v => (v.values ? v.values[0] : sanitize(v));

  return config.map(column => {
    const longestValue = allItems.reduce((longest, item, index) => {
      const raw = sanitize(item[column.key]);

      const value =
        index && column.formatter
          ? formattedValue(column.formatter(item[column.key]))
          : raw;

      return value && value.length > longest.length ? value : longest;
    }, '');

    const replaced = new Array(longestValue.length + 1).join('0');

    const width = column.iconWidth
      ? getTextWidthFromElement(replaced, node) + column.iconWidth
      : getTextWidthFromElement(replaced, node);

    return width;
  });
}

function transmuteConfig(config, widths) {
  return config.map((item, index) => ({
    ...item,
    flex: buildFlex(item.flex, widths[index]),
  }));
}

function equalizeSegments(inner, outer) {
  const fullInner = summate(inner);
  const fullOuter = summate(outer);

  if (fullInner > fullOuter) {
    const diff = fullInner - fullOuter;
    const extra = outer.length - (diff % outer.length);
    const segment = (diff + extra) / outer.length;

    return {
      outer: outer.map(item => item + segment),
      inner: [inner[0] + extra, ...inner.slice(1, inner.length)],
    };
  }

  if (fullInner < fullOuter) {
    const diff = fullOuter - fullInner;
    const extra = inner.length - (diff % inner.length);
    const segment = (diff + extra) / inner.length;

    return {
      outer: [outer[0] + extra, ...outer.slice(1, outer.length)],
      inner: inner.map(item => item + segment),
    };
  }

  return {
    inner,
    outer,
  };
}

function syncColumns(innerWidths, outerWidths, columnSegments) {
  const { segments } = columnSegments.reduce(
    (accum, curr, _index) => {
      const innerOffsetEnd = accum.innerOffset + curr.innerCount;
      const outerOffsetEnd = accum.outerOffset + curr.outerCount;

      const { inner, outer } = equalizeSegments(
        innerWidths.slice(accum.innerOffset, innerOffsetEnd),
        outerWidths.slice(accum.outerOffset, outerOffsetEnd),
      );

      return {
        innerOffset: innerOffsetEnd,
        outerOffset: outerOffsetEnd,
        segments: {
          inner: [...accum.segments.inner, ...inner],
          outer: [...accum.segments.outer, ...outer],
        },
      };
    },
    {
      innerOffset: 0,
      outerOffset: 0,
      segments: {
        inner: [],
        outer: [],
      },
    },
  );

  return segments;
}

export default class {
  constructor({ outerConfig, innerConfig, segments }) {
    this.__outerConfig = outerConfig;
    this.__innerConfig = innerConfig;
    this.__segments = segments;
  }

  connect() {
    this.__textNode = document.createElement('span');
    document.body.appendChild(this.__textNode);
  }

  disconnect() {
    document.body.removeChild(this.__textNode);
  }

  setConfigs({ outerConfig, innerConfig, segments }) {
    this.__outerConfig = outerConfig;
    this.__innerConfig = innerConfig;
    this.__segments = segments;
  }

  getOuterConfig() {
    return this.__outerConfig;
  }

  getInnerConfig() {
    return this.__innerConfig;
  }

  getSegments() {
    return this.__segments;
  }

  alignInnerOuterColumnWidths(innerItems, outerItems) {
    const innerWidths = findContentWidths(
      this.__innerConfig.slice(1, -1),
      innerItems,
      this.__textNode,
    );

    const outerWidths = findContentWidths(
      this.__outerConfig.slice(1),
      outerItems,
      this.__textNode,
    );

    const { inner, outer } = syncColumns(
      innerWidths,
      outerWidths,
      this.__segments,
    );

    return {
      minWidth: summate(inner) + TOTAL_SPACING,
      innerConfig: [
        this.__innerConfig[0],
        ...transmuteConfig(this.__innerConfig.slice(1, -1), inner),
        this.__innerConfig[this.__innerConfig.length - 1],
      ],
      outerConfig: [
        this.__outerConfig[0],
        ...transmuteConfig(this.__outerConfig.slice(1), outer),
      ],
    };
  }
}
