import { formatPaymentNumber } from './neb-ledger-util';

export const mapLineItemsForDiscounts = (lineItems, discountPaymentId = -1) =>
  lineItems.map(li => {
    const paymentDetails = li.lineItemDebits
      .filter(lid => !lid.patientInsuranceId)
      .reduce(
        (memo, lid) => {
          const { amount } = lid.debit;

          const paid = lid.debit.allocations.reduce(
            (aSum, alloc) => aSum + alloc.amount,
            0,
          );

          memo.patientOwed += amount;
          memo.patientPaid += paid;

          const debitPayments = lid.debit.allocations.reduce((aMemo, alloc) => {
            const { credit } = alloc;

            const { payment } = credit;

            if (payment) {
              const { id: paymentId } = payment;

              const paymentNumber = formatPaymentNumber(payment);

              aMemo.push({ paymentId, paymentNumber });

              if (payment.id === discountPaymentId) {
                memo.discountAllocated += alloc.amount;
                memo.patientPaid -= alloc.amount;
              }
            }

            return aMemo;
          }, []);

          memo.payments.push(...debitPayments);

          return memo;
        },

        {
          patientOwed: 0,
          patientPaid: 0,
          discountAllocated: 0,
          payments: [],
        },
      );

    const uniquePayments = paymentDetails.payments.reduce((memo, payment) => {
      const { paymentId } = payment;

      if (!memo[paymentId]) {
        memo[paymentId] = payment;
      }

      return memo;
    }, {});

    const { patientOwed, patientPaid, discountAllocated } = paymentDetails;

    const paymentIds = Object.values(uniquePayments);

    const patientBalance = patientOwed - patientPaid - discountAllocated;

    return {
      selected: false,
      id: li.id,
      invoiceId: li.invoiceId,
      dateOfService: li.dateOfService,
      procedure: li.code,
      description: li.description,
      patientOwed,
      patientPaid,
      paymentIds,
      patientBalance,
      discountAllocated,
      newPatientBalance: patientBalance,
    };
  });
