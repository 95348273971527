import { parseDate } from '../neb-utils/date-util';

export const HIGHLIGHTED_SLOT_DURATION = 300000;
const DASH_STYLING = '1px dashed #0CAADC';

let draggedState = null;
let droppedState = null;

export const updateDraggedState = state => {
  draggedState = state;
};

export const getDraggedState = () => draggedState;

const getTimeHoursAndMinutes = time => {
  const date = parseDate(time);
  return [date.hours(), date.minutes()];
};

const setHoursByTime = time => {
  const [hours, minutes] = getTimeHoursAndMinutes(time);
  return parseDate()
    .startOf('day')
    .hours(hours)
    .minutes(minutes);
};

export const startDragging = (element, yCoordinate) => {
  let { start, end } = element.model;

  start = parseDate(start);
  end = parseDate(end);

  element.style.opacity = '0.2';
  element.style.zIndex = '1000';
  const duration = end.valueOf() - start.valueOf();
  const eventEndTime = setHoursByTime(end);
  const eventStartTime = setHoursByTime(start);
  draggedState = {
    originY: yCoordinate,
    element,
    duration,
    eventEndTime,
    eventStartTime,
  };
};

export const updateDraggingPosition = yCoordinate => {
  const previousPosition = draggedState.isAboveOriginY;
  draggedState.diffY = draggedState.originY - yCoordinate;
  draggedState.isAboveOriginY = draggedState.diffY > 0;

  if (
    previousPosition !== draggedState.isAboveOriginY ||
    draggedState.diffY === 0
  ) {
    if (previousPosition !== undefined) {
      draggedState.dragDirectionHasChanged = true;
    }

    draggedState.hasInitialCalcEventAndSlotTimeDiff = false;
  }
};

export const stopDragging = () => {
  draggedState.element.style.opacity = '';
  draggedState.element.style.zIndex = '';
  draggedState.hasInitialCalcEventAndSlotTimeDiff = false;
  draggedState = null;
};

export const getDroppedState = () => droppedState;

export const removeHighlightedSlots = () => {
  if (droppedState) {
    droppedState.highlightedSlots.forEach(element => {
      element.style.border = '';
    });
  }
};

const getAdjustDurationWhenDirectionChanged = () =>
  draggedState.dragDirectionHasChanged ? HIGHLIGHTED_SLOT_DURATION : 0;

const adjustEventAndSlotTimeDifferent = (
  timeLinePosition,
  eventAndSlotTimeDifferent,
  isAboveOriginY,
  minimum,
) =>
  Math.max(
    timeLinePosition +
      draggedState.eventAndSLotTimeDiff +
      (isAboveOriginY
        ? getAdjustDurationWhenDirectionChanged() * -1
        : getAdjustDurationWhenDirectionChanged()),
    minimum,
  );

const addBorder = (element, borders) => {
  borders.forEach(border => {
    element.style[border] = DASH_STYLING;
  });
};

const addHighlightedSlots = (
  highlightedSlots,
  topLineTime,
  bottomLineTime,
  highlightedSlotsContainer,
) => {
  for (
    let i = topLineTime;
    i < bottomLineTime;
    i += HIGHLIGHTED_SLOT_DURATION
  ) {
    const [hour, minute] = getTimeHoursAndMinutes(i);
    const highlightedSlot = highlightedSlotsContainer.querySelector(
      `[query=time${hour}_${minute}]`,
    );

    if (draggedState.duration === HIGHLIGHTED_SLOT_DURATION) {
      addBorder(highlightedSlot, [
        'borderTop',
        'borderBottom',
        'borderLeft',
        'borderRight',
      ]);
    } else if (i === topLineTime) {
      addBorder(highlightedSlot, ['borderTop', 'borderLeft', 'borderRight']);
    } else if (i === bottomLineTime - HIGHLIGHTED_SLOT_DURATION) {
      addBorder(highlightedSlot, ['borderBottom', 'borderLeft', 'borderRight']);
    } else {
      addBorder(highlightedSlot, ['borderLeft', 'borderRight']);
    }

    highlightedSlots.push(highlightedSlot);
  }
};

const calcHighlightedSlots = (hour, minute, highlightedSlotsContainer) => {
  const { isAboveOriginY } = draggedState;

  if (isAboveOriginY) {
    let bottomLineTime = parseDate()
      .startOf('day')
      .hours(hour)
      .minutes(minute);

    let topLineTime = bottomLineTime - draggedState.duration;

    if (!draggedState.hasInitialCalcEventAndSlotTimeDiff) {
      draggedState.hasInitialCalcEventAndSlotTimeDiff = true;
      draggedState.eventAndSLotTimeDiff =
        draggedState.eventStartTime - topLineTime;
    }

    bottomLineTime = adjustEventAndSlotTimeDifferent(
      bottomLineTime,
      draggedState.eventAndSlotTimeDifferent,
      isAboveOriginY,
      parseDate()
        .startOf('day')
        .valueOf() + draggedState.duration,
    );

    topLineTime = adjustEventAndSlotTimeDifferent(
      topLineTime,
      draggedState.eventAndSlotTimeDifferent,
      isAboveOriginY,
      parseDate()
        .startOf('day')
        .valueOf(),
    );

    addHighlightedSlots(
      droppedState.highlightedSlots,
      topLineTime,
      bottomLineTime,
      highlightedSlotsContainer,
    );
  } else {
    let topLineTime = parseDate()
      .startOf('day')
      .hours(hour)
      .minutes(minute);

    let bottomLineTime = topLineTime + draggedState.duration;

    if (!draggedState.hasInitialCalcEventAndSlotTimeDiff) {
      draggedState.hasInitialCalcEventAndSlotTimeDiff = true;
      draggedState.eventAndSLotTimeDiff =
        draggedState.eventStartTime - topLineTime;
    }

    bottomLineTime = adjustEventAndSlotTimeDifferent(
      bottomLineTime,
      draggedState.eventAndSlotTimeDifferent,
      isAboveOriginY,
      0,
    );

    topLineTime = adjustEventAndSlotTimeDifferent(
      topLineTime,
      draggedState.eventAndSlotTimeDifferent,
      isAboveOriginY,
      0,
    );

    addHighlightedSlots(
      droppedState.highlightedSlots,
      topLineTime,
      bottomLineTime,
      highlightedSlotsContainer,
    );
  }
};

export const initializeDroppedState = (element, highlightedSlotsContainer) => {
  removeHighlightedSlots();
  droppedState = {};

  if (element) {
    const hour = element.getAttribute('hour');
    const minute = element.getAttribute('minute');
    droppedState.highlightedSlots = [];
    droppedState.element = element;
    calcHighlightedSlots(hour, minute, highlightedSlotsContainer);
  }
};

export const clearDroppedState = () => {
  droppedState = null;
};
