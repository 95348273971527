import '../../inputs/neb-textarea';
import '../../neb-radio-button';
import '../../neb-checkbox-old';
import '../../neb-tooltip';

import { LitElement, html, css } from 'lit';

import {
  TOOL_TIP_SUBSCRIPTION_NOT_ALLOWED,
  TOOL_TIP_SUBSCRIPTION_ALLOWED,
} from '../../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import { PACKAGE_TYPE } from '../../../../../neb-utils/enums';

import { MODEL_KEYS as LIMITS_MODEL_KEYS } from './neb-package-template-limits-form';

export const ELEMENTS = {
  noteField: {
    id: 'input-note',
  },
  serviceRadioButton: {
    id: 'radio-service',
  },
  subscriptionCheckbox: {
    id: 'checkbox-subscription',
  },
  tooltip: {
    id: 'tooltip',
  },
  typeDescription: {
    id: 'type-description',
  },
  visitsRadioButton: {
    id: 'radio-visits',
  },
};
export const MODEL_KEYS = {
  NOTES: 'notes',
  SUBSCRIPTION: 'subscription',
  TYPE: 'type',
};

class NebPackageTemplateTypeForm extends LitElement {
  static get properties() {
    return {
      dirty: Boolean,
      model: Object,
      disableSubscriptions: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.model = {
      [MODEL_KEYS.NOTES]: '',
      [MODEL_KEYS.SUBSCRIPTION]: false,
      [MODEL_KEYS.TYPE]: PACKAGE_TYPE.VISIT,
    };

    this.disableSubscriptions = false;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      changeType: value =>
        this.onChange({
          key: MODEL_KEYS.TYPE,
          value,
        }),
      changeSubscription: value => {
        if (!value) {
          this.onChange({
            key: LIMITS_MODEL_KEYS.UNLIMITED,
            value: false,
          });
        }

        this.onChange({
          key: MODEL_KEYS.SUBSCRIPTION,
          value,
        });
      },
      changeNote: ({ value }) =>
        this.onChange({
          key: MODEL_KEYS.NOTES,
          value,
        }),
    };
  }

  __isPackageBeingUpdated() {
    return !!this.model.id;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .section {
          display: grid;
          margin: ${CSS_SPACING};
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
        }

        .divider {
          display: grid;
          grid-gap: ${CSS_SPACING};
          align-items: center;
        }

        .divider-horizontal {
          grid-template-columns: auto 1fr;
        }

        .divider-vertical {
          grid-template-rows: 1fr auto;
        }

        .box-offset {
          position: relative;
          left: -10px;
        }

        .tooltip {
          margin-right: auto;
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="section">
        <div class="divider divider-vertical">
          <div class="divider divider-horizontal">
            <div>
              <neb-radio-button
                id="${ELEMENTS.visitsRadioButton.id}"
                class="box-offset"
                label="Visits"
                .value="${PACKAGE_TYPE.VISIT}"
                .onSelect="${this.__handlers.changeType}"
                ?checked="${this.model.type === PACKAGE_TYPE.VISIT}"
                ?disabled="${this.__isPackageBeingUpdated()}"
              ></neb-radio-button>

              <neb-radio-button
                id="${ELEMENTS.serviceRadioButton.id}"
                class="box-offset"
                label="Services"
                .value="${PACKAGE_TYPE.SERVICE}"
                .onSelect="${this.__handlers.changeType}"
                ?checked="${this.model.type === PACKAGE_TYPE.SERVICE}"
                ?disabled="${this.__isPackageBeingUpdated()}"
              ></neb-radio-button>
            </div>

            <p id="${ELEMENTS.typeDescription.id}">
              Package will define limit of
              ${this.model.type === PACKAGE_TYPE.VISIT ? 'visits' : 'services'}
              patient can use. Services covered will be adjusted against
              packages once rendered.
            </p>
          </div>

          <div class="divider divider-horizontal">
            <neb-checkbox-old
              id="${ELEMENTS.subscriptionCheckbox.id}"
              class="box-offset"
              label="Sell as Subscription"
              .onClick="${this.__handlers.changeSubscription}"
              ?checked="${this.model.subscription}"
              ?disabled="${
                this.disableSubscriptions || this.__isPackageBeingUpdated()
              }"
            ></neb-checkbox-old>

            <neb-tooltip
              id="${ELEMENTS.tooltip.id}"
              class="tooltip box-offset"
              defaultAnchor="right"
              ?disabled="${this.__isPackageBeingUpdated()}"
            >
              <p slot="tooltip">
                ${
                  this.disableSubscriptions
                    ? TOOL_TIP_SUBSCRIPTION_NOT_ALLOWED
                    : TOOL_TIP_SUBSCRIPTION_ALLOWED
                }
              </p>
            </neb-tooltip>
          </div>
        </div>

        <neb-textarea
          id="${ELEMENTS.noteField.id}"
          label="Note"
          maxLength="255"
          showCount
          .name="${ELEMENTS.noteField.id}"
          .value="${this.model.notes}"
          .onChange="${this.__handlers.changeNote}"
        ></neb-textarea>
      </div>
    `;
  }
}

customElements.define(
  'neb-package-template-type-form',
  NebPackageTemplateTypeForm,
);
