import '../../neb-selection-card';
import '../../neb-popup-header';

import { html, css } from 'lit';

import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
  CSS_COLOR_WHITE,
} from '../../../../../neb-styles/neb-variables';
import { OVERLAY_KEYS, openOverlay } from '../../../utils/overlay-constants';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  purchaseCard: {
    id: 'purchase-card',
  },
  feeCard: {
    id: 'fee-card',
  },
  encounterChargeCard: {
    id: 'encounter-charge-card',
  },
};

class NebOverlayPatientAddCharge extends Overlay {
  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      callLedgerEncounterChargeOverlay: async params => {
        const addedEncCharges = await openOverlay(
          OVERLAY_KEYS.LEDGER_ENCOUNTER_CHARGE,
          params,
        );

        return addedEncCharges
          ? this.handlers.dismiss(addedEncCharges)
          : this.handlers.dismiss();
      },
      callManageEncounterChargeOverlay: async params => {
        const saved = await openOverlay(OVERLAY_KEYS.MANAGE_ENCOUNTER, params);
        if (!saved) return;

        this.handlers.dismiss();
      },
    };
  }

  get __encounterChargeCardTitle() {
    if (this.model.chartingPermission) {
      return 'Manage Encounter Charges and Diagnoses';
    }
    return 'Encounter Charge';
  }

  get __encounterChargeCardContent() {
    return this.model.chartingPermission
      ? html`
          <p>Manage the patient’s charges for the selected encounter.</p>
        `
      : html`
          <p>Additional procedures that can be added to an encounter.</p>
        `;
  }

  get __encounterChargeCardHandler() {
    const { patientId, invoiceId, encounterIds } = this.model;
    const params = {
      patientId,
      invoiceId,
      encounterIds,
    };
    return this.model.chartingPermission
      ? this.handlers.callManageEncounterChargeOverlay(params)
      : this.handlers.callLedgerEncounterChargeOverlay(params);
  }

  get __cardsConfig() {
    return [
      {
        id: ELEMENTS.purchaseCard.id,
        title: 'Purchases',
        content: html`
          <p>
            Products sold to the patient, to include DME, that will not be
            billed to insurance.
          </p>
        `,
        icon: 'shoppingCart',
        handler: async () => {
          const addedPurchases = await openOverlay(
            OVERLAY_KEYS.LEDGER_PURCHASE,
            {
              patientId: this.model.patientId,
              invoiceId: this.model.invoiceId,
              guarantorId: this.model.guarantorId,
            },
          );

          if (addedPurchases) this.handlers.dismiss(addedPurchases);

          if (addedPurchases === null) this.handlers.dismiss();
        },
      },
      {
        id: ELEMENTS.feeCard.id,
        title: 'Fee',
        content: html`
          <p>
            An additional charge, such as a late fee, that can be appended to an
            invoice.
          </p>
        `,
        icon: 'fee',
        handler: async () => {
          const addedFees = await openOverlay(OVERLAY_KEYS.LEDGER_FEE, {
            patientId: this.model.patientId,
            invoiceId: this.model.invoiceId,
            guarantorId: this.model.guarantorId,
          });

          if (addedFees) this.handlers.dismiss(addedFees);

          if (addedFees === null) this.handlers.dismiss();
        },
      },
      {
        id: ELEMENTS.encounterChargeCard.id,
        title: this.__encounterChargeCardTitle,
        content: this.__encounterChargeCardContent,
        icon: 'charting',
        handler: () => this.__encounterChargeCardHandler,
      },
    ];
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .header,
        .container-cards {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .container-cards {
          display: flex;
          flex-direction: column;
        }

        :host([layout='small']) .container-cards {
          align-items: center;
          flex-shrink: 0;
          background-color: ${CSS_COLOR_WHITE};
        }

        .description {
          padding: 0 ${CSS_SPACING};
        }

        .card {
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Add Charge"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  __getChartingPermission() {
    return this.model.chartingPermission;
  }

  __encounterCardDisabled() {
    return !this.model.charges.some(c => !!c.encounterNumber);
  }

  getDisabled(card) {
    return this.model.addToLedgerGrouping &&
      card.id === ELEMENTS.encounterChargeCard.id
      ? this.__encounterCardDisabled()
      : false;
  }

  __renderCard(card) {
    return html`
      <neb-selection-card
        id="${card.id}"
        class="card"
        .title="${card.title}"
        .icon="${card.icon}"
        .onClick="${card.handler}"
        ?disabled="${this.getDisabled(card)}"
      >
        ${card.content}
      </neb-selection-card>
    `;
  }

  __renderDescription() {
    return html`
      <div class="description">
        Choose the charge type and proceed to enter charge details.
      </div>
    `;
  }

  __renderCards() {
    return html`
      <div class="container-cards">
        ${this.__cardsConfig.map(c => this.__renderCard(c))}
      </div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}${this.__renderDescription()}
      ${this.__renderCards()}
    `;
  }
}

customElements.define(
  'neb-overlay-patient-add-charge',
  NebOverlayPatientAddCharge,
);
