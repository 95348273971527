import './neb-recurring-day-bubble';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';

export const MONDAY = 'MONDAY';
export const TUESDAY = 'TUESDAY';
export const WEDNESDAY = 'WEDNESDAY';
export const THURSDAY = 'THURSDAY';
export const FRIDAY = 'FRIDAY';
export const SATURDAY = 'SATURDAY';
export const SUNDAY = 'SUNDAY';
export const DAY_NAMES = [
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
];
export const RRULE_BY_WEEK_DAY = DAY_NAMES.reduce(
  (accumulator, current) => ({
    ...accumulator,
    [current]: current.substring(0, 2),
  }),
  {},
);
export const ELEMENTS = {
  days: {
    selector: '.day',
  },
};

class NebRecurringDayPicker extends LitElement {
  static get properties() {
    return {
      selectedDays: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initHandler();

    this.__initState();
  }

  __initState() {
    this.selectedDays = [];

    this.onChange = () => {};
  }

  __initHandler() {
    this.__handlers = {
      dayClick: ({ target }) => {
        if (
          !(
            this.selectedDays.length === 1 &&
            this.selectedDays.indexOf(target.day) > -1
          )
        ) {
          target.selected = !target.selected;

          if (target.selected && this.selectedDays.indexOf(target.day) !== 1) {
            this.selectedDays.push(target.day);
          } else {
            this.selectedDays.splice(this.selectedDays.indexOf(target.day), 1);
            this.selectedDays = [...this.selectedDays];
          }

          this.onChange(this.selectedDays);
        }
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
        }
      `,
    ];
  }

  render() {
    return html`
      ${
        DAY_NAMES.map(
          name => html`
            <neb-recurring-day-bubble
              id="day-${name}"
              class="day"
              .day="${name}"
              @click="${this.__handlers.dayClick}"
              .selected="${this.selectedDays.indexOf(name) !== -1}"
            ></neb-recurring-day-bubble>
          `,
        )
      }
    `;
  }
}

window.customElements.define('neb-recurring-day-picker', NebRecurringDayPicker);
