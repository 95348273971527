import './neb-charting-encounter-header-controller';

import './problem-list/neb-unsigned-encounter-problem-list-detail';
import './problem-list/neb-unsigned-encounter-problem-list-summary';

import './notes/neb-unsigned-encounter-notes-detail';
import './notes/neb-unsigned-encounter-notes-summary';

import './charges/neb-unsigned-encounter-charges-detail';
import './charges/neb-unsigned-encounter-charges-summary';

import './diagnoses/neb-unsigned-encounter-diagnoses-detail';
import './diagnoses/neb-unsigned-encounter-diagnoses-summary';

import './listings/neb-unsigned-encounter-listings-detail';
import './listings/neb-unsigned-encounter-listings-summary';

import './documents/neb-unsigned-encounter-documents-detail';
import './documents/neb-unsigned-encounter-documents-summary';

import './treatment-plan/neb-unsigned-encounter-treatment-plan-detail';
import './treatment-plan/neb-unsigned-encounter-treatment-plan-summary';

import './provider-notes/neb-unsigned-encounter-provider-notes-summary';
import './provider-notes/neb-unsigned-encounter-provider-notes-detail';

import { animate, fadeIn, fadeOut, AnimateController } from '@lit-labs/motion';
import { matchRouteSwitch } from '@neb/router';
import { html, LitElement, css } from 'lit';
import { cache } from 'lit/directives/cache.js';

import { forceFetchAppointments } from '../../../packages/neb-calendar/neb-appointments-state';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { navigate } from '../../store';
import { baseStyles, CSS_SPACING } from '../../styles';

import { NebChargesWarningsDataController } from './charges/neb-charges-warnings-data-controller';
import { UnsignedEncounterDataController } from './neb-unsigned-encounter-data-controller';

export const ELEMENTS = {
  headerController: { id: 'header-controller' },
  problemListSummary: { id: 'summary-problem-list' },
  notesSummary: { id: 'summary-notes' },
  diagnosesSummary: { id: 'summary-diagnoses' },
  chargesSummary: { id: 'summary-charges' },
  treatmentPlanSummary: { id: 'summary-treatment-plan' },
  documentsSummary: { id: 'summary-documents' },
  listingsSummary: { id: 'summary-listings' },
  providerNotesSummary: { id: 'summary-provider-notes' },
  panels: { selector: '.summary-panel' },

  problemListDetail: { id: 'detail-problem-list' },
  notesDetail: { id: 'detail-notes' },
  diagnosesDetail: { id: 'detail-diagnoses' },
  chargesDetail: { id: 'detail-charges' },
  treatmentPlanDetail: { id: 'detail-treatment-plan' },
  documentsDetail: { id: 'detail-documents' },
  listingsDetail: { id: 'detail-listings' },
  providerNotesDetail: { id: 'detail-provider-notes' },

  detailContainer: { id: 'detail-container' },
};

export const PANELS = {
  CHARGES: 'charges',
  CHART_NOTES: 'notes',
  DIAGNOSES: 'diagnoses',
  DOCUMENTS: 'documents',
  LISTINGS: 'listings',
  PROBLEM_LIST: 'problem-list',
  TREATMENT_PLAN: 'plan',
  PROVIDER_NOTES: 'provider-notes',
};

const formatToRoute = str => `/${str}`;

export class NebPageUnsignedEncounter extends LitElement {
  static get properties() {
    return {
      __selectedDocumentIndex: { type: Number },

      encounterId: { type: String },
      layout: { type: String },
      patientId: { type: String },
      model: { type: Object },
      route: { type: String },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          justify-content: center;

          height: 100%;
          width: 100%;
          overflow: auto;
        }

        .container {
          display: flex;
          flex-direction: column;
          overflow: hidden;

          flex: 1 0 0;
          margin: ${CSS_SPACING};
        }

        .content-container {
          position: relative;
          display: flex;

          height: 100%;
          width: 100%;
        }

        .summary-panels {
          position: absolute;
          inset: 55px 0 0 0;

          display: flex;

          gap: ${CSS_SPACING};

          margin-top: ${CSS_SPACING};
          z-index: 1;

          overflow: auto;
        }

        .summary-panel {
          position: relative;
          will-change: transform;
        }

        .header {
          position: absolute;
          width: 100%;
        }

        .column {
          display: flex;
          flex-direction: column;
          gap: ${CSS_SPACING};
        }

        .column-1 {
          flex: 2 0 190px;
        }

        .column-2 {
          flex: 4 0 380px;

          overflow: hidden;
        }

        .column-3 {
          flex: 3 0 200px;
        }

        .detail-panel {
          flex: 1;

          z-index: 2;
        }

        .listings {
          flex: 2 0 0;

          overflow: auto;
        }

        .notes {
          flex: 3 0 0;
        }

        .charges,
        .diagnoses,
        .documents,
        .providerNotes,
        .problem-list,
        .treatment-plan {
          flex: 1 0 0;
        }
      `,
    ];
  }

  static createModel() {
    return {
      id: null,
      hasHistory: false,
      appointmentTypeId: null,
      locationId: null,
      patientId: null,
      provider: '',
      appointmentType: '',
      fullDate: '',
      formattedServiceDate: '',
      case: null,
      patientAuthorizationId: null,
      patient: null,
      encounterInfo: {},
    };
  }

  get __elements() {
    return {
      headerController: this.shadowRoot.getElementById(
        ELEMENTS.headerController.id,
      ),
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initReactiveControllers();
    this.__initPanels();
  }

  __initState() {
    this.__mostRecentlyClosedPanel = null;
    this.__panelToForceNavigateTo = null;
    this.__selectedDocumentIndex = -1;

    this.encounterId = '';
    this.layout = '';
    this.patientId = '';
    this.model = NebPageUnsignedEncounter.createModel();
    this.route = '/';

    this.onEncounterSigned = () => {};

    this.onUpdateEncounter = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      closeDetail: ({ navigateTo = '' } = {}) => {
        /* __panelToForceNavigateTo is used to prevent animation clipping.
         * First navigate to encounter and then navigate to panel immediately after the route changes
         */

        this.__panelToForceNavigateTo = navigateTo;
        this.__mostRecentlyClosedPanel = this.route.split('/')[1];

        return navigate(`#/charting/${this.encounterId}`);
      },

      encounterSigned: () => this.onEncounterSigned(),

      openDetail: ({ target }) => {
        const name = target.getAttribute('name');
        const isLoading = this.__panelDataController.loadingFlags[name];

        if (isLoading) {
          return;
        }

        this.__setHeaderControllerZIndexToPreventAnimationClipping();

        const path = target.getAttribute('detailPath');
        navigate(`#/charting/${this.encounterId}${path}`);
      },

      requestDocuments: () => this.__panelDataController.fetchDocuments(),

      refetch: refetchKey => this.__panelDataController.refetch(refetchKey),

      selectDocumentIndex: index => {
        this.__selectedDocumentIndex = index;
      },

      updateEncounter: () => this.onUpdateEncounter(),
      deleteEncounterFromOutside: async event => {
        if (event && event.detail && event.detail.deletedEncounterId) {
          const { deletedEncounterId } = event.detail;
          const openedEncounterId = this.encounterId;

          if (deletedEncounterId === openedEncounterId) {
            if (event.detail.onDismiss) {
              event.detail.onDismiss();
            }
            await this.__handleEncounterAfterDelete();
          }
        }
      },
    };
  }

  connectedCallback() {
    window.addEventListener(
      'neb-encounter-deleted',
      this.__handlers.deleteEncounterFromOutside,
    );

    super.connectedCallback();
  }

  disconnectedCallback() {
    window.removeEventListener(
      'neb-encounter-deleted',
      this.__handlers.deleteEncounterFromOutside,
    );

    super.disconnectedCallback();
  }

  async __handleEncounterAfterDelete() {
    const storeDate = store.getState().appointments.targetDate;
    await store.dispatch(forceFetchAppointments(storeDate));
    navigate('#/charting');
  }

  __initReactiveControllers() {
    this.__animateController = new AnimateController(this, {
      defaultOptions: {
        keyframeOptions: {
          duration: 200,
          fill: 'both',
        },
      },
    });

    this.__panelDataController = new UnsignedEncounterDataController(this);
    this.__chargesWarningsDataController = new NebChargesWarningsDataController(
      this,
    );

    this.__animateController.onComplete = () => {
      this.__resetHeaderControllerZIndex();
    };
  }

  __initPanels() {
    this.__detailPanels = [
      {
        path: formatToRoute(PANELS.PROBLEM_LIST),
        resolver: () => html`
          <neb-unsigned-encounter-problem-list-detail
            id="${ELEMENTS.problemListDetail.id}"
            class="detail-panel"
            .encounter="${this.model}"
            .layout="${this.layout}"
            .model="${this.__panelDataController.state.problemList}"
            .onClose="${this.__handlers.closeDetail}"
            .onRefetch="${this.__handlers.refetch}"
            ${
              animate({
                id: ELEMENTS.problemListDetail.id,
                inId: ELEMENTS.problemListSummary.id,
              })
            }
          ></neb-unsigned-encounter-problem-list-detail>
        `,
      },
      {
        path: formatToRoute(PANELS.CHART_NOTES),
        resolver: () => html`
          <neb-unsigned-encounter-notes-detail
            id="${ELEMENTS.notesDetail.id}"
            class="detail-panel"
            .encounter="${this.model}"
            .layout="${this.layout}"
            .model="${this.__panelDataController.state.chartNotes}"
            .onClose="${this.__handlers.closeDetail}"
            .onRefetch="${this.__handlers.refetch}"
            ${
              animate({
                id: ELEMENTS.notesDetail.id,
                inId: ELEMENTS.notesSummary.id,
              })
            }
          ></neb-unsigned-encounter-notes-detail>
        `,
      },
      {
        path: formatToRoute(PANELS.DIAGNOSES),
        resolver: () =>
          html`
            <neb-unsigned-encounter-diagnoses-detail
              id="${ELEMENTS.diagnosesDetail.id}"
              class="detail-panel"
              .encounter="${this.model}"
              .layout="${this.layout}"
              .model="${this.__panelDataController.state.diagnoses}"
              .problemList="${this.__panelDataController.state.problemList}"
              .onClose="${this.__handlers.closeDetail}"
              .onRefetch="${this.__handlers.refetch}"
              .onUpdateEncounter="${this.__handlers.updateEncounter}"
              ${
                animate({
                  id: ELEMENTS.diagnosesDetail.id,
                  inId: ELEMENTS.diagnosesSummary.id,
                })
              }
            ></neb-unsigned-encounter-diagnoses-detail>
          `,
      },
      {
        path: formatToRoute(PANELS.CHARGES),
        resolver: () => html`
          <neb-unsigned-encounter-charges-detail
            id="${ELEMENTS.chargesDetail.id}"
            class="detail-panel"
            .encounter="${this.model}"
            .layout="${this.layout}"
            .model="${this.__panelDataController.state.charges}"
            .diagnoses="${this.__panelDataController.state.diagnoses}"
            .warnings="${this.__chargesWarningsDataController.chargesWarnings}"
            .onClose="${this.__handlers.closeDetail}"
            .onRefetch="${this.__handlers.refetch}"
            ${
              animate({
                id: ELEMENTS.chargesDetail.id,
                inId: ELEMENTS.chargesSummary.id,
              })
            }
          ></neb-unsigned-encounter-charges-detail>
        `,
      },
      {
        path: formatToRoute(PANELS.TREATMENT_PLAN),
        resolver: () =>
          html`
            <neb-unsigned-encounter-treatment-plan-detail
              id="${ELEMENTS.treatmentPlanDetail.id}"
              class="detail-panel"
              .encounter="${this.model}"
              .layout="${this.layout}"
              .model="${this.__panelDataController.state.treatmentPlan}"
              .onClose="${this.__handlers.closeDetail}"
              .onRefetch="${this.__handlers.refetch}"
              ${
                animate({
                  id: ELEMENTS.treatmentPlanDetail.id,
                  inId: ELEMENTS.treatmentPlanSummary.id,
                })
              }
            ></neb-unsigned-encounter-treatment-plan-detail>
          `,
      },
      {
        path: formatToRoute(PANELS.DOCUMENTS),
        resolver: () =>
          html`
            <neb-unsigned-encounter-documents-detail
              id="${ELEMENTS.documentsDetail.id}"
              class="detail-panel"
              .encounter="${this.model}"
              .layout="${this.layout}"
              .initialModel="${this.__panelDataController.state.documents}"
              .onClose="${this.__handlers.closeDetail}"
              .onRefetch="${this.__handlers.refetch}"
              .selectedDocumentIndex="${this.__selectedDocumentIndex}"
              .onSelectDocumentIndex="${this.__handlers.selectDocumentIndex}"
              ${
                animate({
                  id: ELEMENTS.documentsDetail.id,
                  inId: ELEMENTS.documentsSummary.id,
                })
              }
            ></neb-unsigned-encounter-documents-detail>
          `,
      },
      {
        path: formatToRoute(PANELS.LISTINGS),
        resolver: () =>
          html`
            <neb-unsigned-encounter-listings-detail
              id="${ELEMENTS.listingsDetail.id}"
              class="detail-panel"
              .encounter="${this.model}"
              .layout="${this.layout}"
              .model="${this.__panelDataController.state.listings}"
              .onClose="${this.__handlers.closeDetail}"
              .onRefetch="${this.__handlers.refetch}"
              ${
                animate({
                  id: ELEMENTS.listingsDetail.id,
                  inId: ELEMENTS.listingsSummary.id,
                })
              }
            ></neb-unsigned-encounter-listings-detail>
          `,
      },
      {
        path: formatToRoute(PANELS.PROVIDER_NOTES),
        resolver: () =>
          html`
            <neb-unsigned-encounter-provider-notes-detail
              id="${ELEMENTS.providerNotesDetail.id}"
              class="detail-panel"
              .encounter="${this.model}"
              .layout="${this.layout}"
              .model="${this.__panelDataController.state.providerNotes}"
              .onClose="${this.__handlers.closeDetail}"
              .onRefetch="${this.__handlers.refetch}"
              ${animate({
                id: ELEMENTS.providerNotesDetail.id,
                inId: ELEMENTS.providerNotesSummary.id,
              })}
            ></neb-unsigned-encounter-listings-detail>
          `,
      },
    ];
  }

  __setHeaderControllerZIndexToPreventAnimationClipping() {
    this.__elements.headerController.style.zIndex = 0;
  }

  __resetHeaderControllerZIndex() {
    if (this.__elements.headerController) {
      this.__elements.headerController.style.zIndex = 2;
    }
  }

  __didDiagnosesPanelJustClose(changedProps) {
    return (
      !changedProps.has('encounterId') &&
      changedProps.has('route') &&
      changedProps.get('route') === '/diagnoses' &&
      this.route === '/'
    );
  }

  firstUpdated() {
    this.__resetHeaderControllerZIndex();
  }

  update(changedProps) {
    if (changedProps.has('route') && this.__panelToForceNavigateTo) {
      navigate(
        `#/charting/${this.encounterId}/${this.__panelToForceNavigateTo}`,
      );

      this.__panelToForceNavigateTo = null;
    }

    if (this.__didDiagnosesPanelJustClose(changedProps)) {
      this.__handlers.updateEncounter();
    }

    super.update(changedProps);
  }

  __renderColumn1() {
    const { LISTINGS, PROBLEM_LIST } = PANELS;

    return html`
      <div class="column column-1" ${animate({ out: fadeOut })}>
        <neb-unsigned-encounter-problem-list-summary
          id="${ELEMENTS.problemListSummary.id}"
          class="summary-panel problem-list"
          detailPath="${formatToRoute(PROBLEM_LIST)}"
          name="problemList"
          .loading="${this.__panelDataController.loadingFlags.problemList}"
          .model="${this.__panelDataController.state.problemList}"
          .shouldAnimate="${this.__mostRecentlyClosedPanel === PROBLEM_LIST}"
          @click="${this.__handlers.openDetail}"
          ${
            animate({
              out: fadeOut,
              id: ELEMENTS.problemListSummary.id,
              inId: ELEMENTS.problemListDetail.id,
            })
          }
        ></neb-unsigned-encounter-problem-list-summary>

        <neb-unsigned-encounter-listings-summary
          id="${ELEMENTS.listingsSummary.id}"
          class="summary-panel listings"
          detailPath="${formatToRoute(LISTINGS)}"
          name="listings"
          .loading="${this.__panelDataController.loadingFlags.listings}"
          .model="${this.__panelDataController.state.listings}"
          .shouldAnimate="${this.__mostRecentlyClosedPanel === LISTINGS}"
          @click="${this.__handlers.openDetail}"
          ${
            animate({
              out: fadeOut,
              id: ELEMENTS.listingsSummary.id,
              inId: ELEMENTS.listingsDetail.id,
            })
          }
        ></neb-unsigned-encounter-listings-summary>
      </div>
    `;
  }

  __renderColumn2() {
    const { CHART_NOTES, DOCUMENTS, PROVIDER_NOTES } = PANELS;

    return html`
      <div class="column column-2" ${animate({ out: fadeOut })}>
        <neb-unsigned-encounter-notes-summary
          id="${ELEMENTS.notesSummary.id}"
          class="summary-panel notes"
          detailPath="${formatToRoute(CHART_NOTES)}"
          name="notes"
          .loading="${this.__panelDataController.loadingFlags.notes}"
          .model="${this.__panelDataController.state.chartNotes}"
          .shouldAnimate="${this.__mostRecentlyClosedPanel === CHART_NOTES}"
          @click="${this.__handlers.openDetail}"
          ${
            animate({
              out: fadeOut,
              id: ELEMENTS.notesSummary.id,
              inId: ELEMENTS.notesDetail.id,
            })
          }
        ></neb-unsigned-encounter-notes-summary>
        <neb-unsigned-encounter-provider-notes-summary
          id="${ELEMENTS.providerNotesSummary.id}"
          class="summary-panel providerNotes"
          detailPath="${formatToRoute(PROVIDER_NOTES)}"
          name="providerNotes"
          .loading="${this.__panelDataController.loadingFlags.providerNotes}"
          .model="${this.__panelDataController.state.providerNotes}"
          .shouldAnimate="${this.__mostRecentlyClosedPanel === PROVIDER_NOTES}"
          @click="${this.__handlers.openDetail}"
          ${
            animate({
              out: fadeOut,
              id: ELEMENTS.providerNotesSummary.id,
              inId: ELEMENTS.providerNotesDetail.id,
            })
          }
        ></neb-unsigned-encounter-provider-notes-summary>
        <neb-unsigned-encounter-documents-summary
          id="${ELEMENTS.documentsSummary.id}"
          class="summary-panel documents"
          detailPath="${formatToRoute(DOCUMENTS)}"
          name="documents"
          .loading="${this.__panelDataController.loadingFlags.documents}"
          .model="${this.__panelDataController.state.documents}"
          .onRequestDocuments="${this.__handlers.requestDocuments}"
          .shouldAnimate="${this.__mostRecentlyClosedPanel === DOCUMENTS}"
          .onSelectDocumentIndex="${this.__handlers.selectDocumentIndex}"
          .selectedDocumentIndex="${this.__selectedDocumentIndex}"
          @click="${this.__handlers.openDetail}"
          ${
            animate({
              out: fadeOut,
              id: ELEMENTS.documentsSummary.id,
              inId: ELEMENTS.documentsDetail.id,
            })
          }
        ></neb-unsigned-encounter-documents-summary>
      </div>
    `;
  }

  __renderColumn3() {
    const { DIAGNOSES, CHARGES, TREATMENT_PLAN } = PANELS;
    return html`
      <div class="column column-3" ${animate({ out: fadeOut })}>
        <neb-unsigned-encounter-diagnoses-summary
          id="${ELEMENTS.diagnosesSummary.id}"
          class="summary-panel diagnoses"
          detailPath="${formatToRoute(DIAGNOSES)}"
          name="diagnoses"
          .loading="${this.__panelDataController.loadingFlags.diagnoses}"
          .encounter="${this.model}"
          .model="${this.__panelDataController.state.diagnoses}"
          .shouldAnimate="${this.__mostRecentlyClosedPanel === DIAGNOSES}"
          @click="${this.__handlers.openDetail}"
          ${
            animate({
              out: fadeOut,
              id: ELEMENTS.diagnosesSummary.id,
              inId: ELEMENTS.diagnosesDetail.id,
            })
          }
        ></neb-unsigned-encounter-diagnoses-summary>
        <neb-unsigned-encounter-charges-summary
          id="${ELEMENTS.chargesSummary.id}"
          class="summary-panel charges"
          detailPath="${formatToRoute(CHARGES)}"
          name="charges"
          .loading="${this.__panelDataController.loadingFlags.charges}"
          .model="${this.__panelDataController.state.charges}"
          .shouldAnimate="${this.__mostRecentlyClosedPanel === CHARGES}"
          .showWarnings="${
            this.__chargesWarningsDataController.chargesWarnings.length > 0
          }"
          @click="${this.__handlers.openDetail}"
          ${
            animate({
              out: fadeOut,
              id: ELEMENTS.chargesSummary.id,
              inId: ELEMENTS.chargesDetail.id,
            })
          }
        ></neb-unsigned-encounter-charges-summary>
        <neb-unsigned-encounter-treatment-plan-summary
          id="${ELEMENTS.treatmentPlanSummary.id}"
          class="summary-panel treatment-plan"
          detailPath="${formatToRoute(TREATMENT_PLAN)}"
          name="treatmentPlan"
          .loading="${this.__panelDataController.loadingFlags.treatmentPlan}"
          .model="${this.__panelDataController.state.treatmentPlan}"
          .shouldAnimate="${this.__mostRecentlyClosedPanel === TREATMENT_PLAN}"
          @click="${this.__handlers.openDetail}"
          ${
            animate({
              out: fadeOut,
              id: ELEMENTS.treatmentPlanSummary.id,
              inId: ELEMENTS.treatmentPlanDetail.id,
            })
          }
        ></neb-unsigned-encounter-treatment-plan-summary>
      </div>
    `;
  }

  __renderSummaryPanels() {
    return this.route && this.route === '/'
      ? cache(html`
          ${this.__renderColumn1()}${this.__renderColumn2()}${
            this.__renderColumn3()
          }
        `)
      : '';
  }

  __renderHeader() {
    return this.route && this.route === '/'
      ? html`
          <neb-charting-encounter-header-controller
            id="${ELEMENTS.headerController.id}"
            class="header"
            .charges="${this.__panelDataController.state.charges}"
            .diagnoses="${this.__panelDataController.state.diagnoses}"
            .model="${this.model}"
            .historyExists="${this.model.hasHistory}"
            .onSignEncounter="${this.__handlers.encounterSigned}"
            .onUpdateEncounter="${this.__handlers.updateEncounter}"
            .onRefetch="${this.__handlers.refetch}"
            ${
              animate({
                out: fadeOut,
                in: fadeIn,
                id: ELEMENTS.headerController.id,
                keyframeOptions: {
                  duration: 200,
                },
              })
            }
          ></neb-charting-encounter-header-controller>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <div class="content-container">
          ${this.__renderHeader()}
          <div class="summary-panels">${this.__renderSummaryPanels()}</div>

          ${matchRouteSwitch(this.__detailPanels, this.route)}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-page-unsigned-encounter', NebPageUnsignedEncounter);
