import '../../../../../packages/neb-lit-components/src/components/tables/neb-table-online-booking-link';

import { html, css, unsafeCSS, LitElement } from 'lit';
import moment from 'moment-timezone';

import { baseStyles } from '../../../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
} from '../../../../../packages/neb-styles/neb-variables';
import { getTextWidth } from '../../../../../packages/neb-utils/dom';
import { formatPhoneNumber } from '../../../../../packages/neb-utils/formatters';
import { renderMatchStrength } from '../../../../../packages/neb-utils/patient';
import { MODE } from '../../../../../packages/neb-utils/table';
import { summate } from '../../../../../packages/neb-utils/utils';

export const ELEMENTS = {
  accountTitle: {
    id: 'account-title',
  },
  matchTitle: {
    id: 'match-title',
  },
  patientRecordTable: {
    id: 'patient-record-table',
  },
  matchingTable: {
    id: 'matching-table',
  },
  patientTableContainer: {
    id: 'patient-record-table-container',
  },
  matchingTableContainer: {
    id: 'matching-table-container',
  },
  mobileMatchingTable: {
    id: 'mobile-matching-table',
  },
};

const NO_MATCH_TEXT =
  'This account does not have any suggested matches. Please create a new patient record.';

const CONFIG = [
  {
    key: 'firstName',
    label: 'First Name',
    flex: css`0 0 85px`,
  },
  {
    key: 'lastName',
    label: 'Last Name',
    flex: css`0 0 85px`,
  },
  {
    key: 'dateOfBirth',
    label: 'Date of Birth',
    flex: css`0 0 90px`,
    formatter: v => moment(v).format('L'),
  },
  {
    key: 'phoneNumbers',
    label: 'Phone',
    flex: css`0 0 100px`,
    formatter: v => v.map(v => formatPhoneNumber(v.number)),
  },
  {
    key: 'emailAddresses',
    label: 'Email Address',
    flex: css`0 0 115px`,
  },
  {
    key: 'matches',
    label: 'Match Strength',
    flex: css`0 0 115px`,
    formatter: v => renderMatchStrength(v),
  },
  {
    key: 'matchButton',
    label: '',
    flex: css`0 0 90px`,
  },
];

const MOBILE_CONFIG = [
  {
    key: 'firstName',
    label: 'First Name',
    flex: css`1 1 110px`,
  },
  {
    key: 'lastName',
    label: 'Last Name',
    flex: css`1 1 110px`,
  },
  {
    key: 'matches',
    label: 'Match Strength',
    flex: css`0 1 115px`,
    formatter: v => renderMatchStrength(v),
  },
];

class NebPatientOnlineBookingLinkForm extends LitElement {
  static get properties() {
    return {
      accountTitle: String,
      matchTitle: String,
      model: Object,
      layout: {
        type: String,
        reflect: true,
      },
      showHeader: Boolean,
      __config: Array,
      __minWidth: Number,
      __model: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.model = {};
    this.__model = {
      title: '',
      account: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        emailAddresses: [],
        phoneNumbers: [],
      },
      matches: [],
    };

    this.showHeader = true;
    this.__config = CONFIG;
    this.__minWidth = 0;

    this.onMatch = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      match: match => this.onMatch(match),
      back: () => this.onClose(),
      scroll: e => {
        this.__elements.patientTableContainer.scrollLeft =
          e.currentTarget.scrollLeft;
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__model = {
        account: this.__formatAccount(this.model.account),
        matches: this.__formatMatches(this.model.matches),
      };

      this.__alignTables([this.__model.account, ...this.__model.matches]);
    }

    if (changedProps.has('layout')) {
      this.__elements = {
        patientTableContainer: this.shadowRoot.getElementById(
          ELEMENTS.patientTableContainer.id,
        ),
      };
    }
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host([layout='small']) {
          display: flex;
          height: 100%;
          flex-direction: column;
          margin: 0;
        }

        .form-body {
          flex: 1 0 0;
        }

        :host([layout='small']) .form-body {
          flex: 0;
          padding: ${CSS_SPACING} 0;
        }

        .row-title {
          height: fit-content;
          flex: 1 0 0;
          font-weight: bold;
          padding: 0 17px 16px;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .row-body {
          margin-bottom: 15px;
          overflow-y: auto;
          max-height: 105px;
        }

        .potential-match-table {
          overflow-x: auto;
        }

        .match-title {
          padding-top: 27px;
        }

        .patient-record-table {
          overflow-x: hidden;
          width: 100%;
        }

        .matching-table {
          overflow-x: auto;
          width: 100%;
        }
      `,
    ];
  }

  __formatMatches(matches) {
    return matches.map(match => this.__formatAccount(match));
  }

  __formatAccount(account) {
    return account.cognitoId
      ? {
          ...account,
          phoneNumbers: [{ number: account.phoneNumber }],
          emailAddresses: [account.emailAddress],
        }
      : account;
  }

  __alignTables(rows) {
    const columnWidths = rows.reduce(
      (acc, { firstName, lastName, emailAddresses }) => {
        acc.firstName = Math.max(acc.firstName, getTextWidth(firstName));
        acc.lastName = Math.max(acc.lastName, getTextWidth(lastName));
        acc.emailAddresses = Math.max(
          acc.emailAddresses,
          ...emailAddresses.map(email => getTextWidth(email)),
        );

        return acc;
      },
      { firstName: 85, lastName: 85, emailAddresses: 115 },
    );

    Object.entries(columnWidths).forEach(([column, width]) => {
      const config = this.__config.find(({ key }) => key === column);
      config.flex = css`0 0 ${unsafeCSS(width)}px`;
    });

    this.__minWidth =
      summate(this.__config.map(({ flex }) => this.__getFlexBasis(flex))) + 40;
  }

  __getFlexBasis(flex) {
    const basis = flex.cssText.split(' ')[2];
    return Number(basis.replace('px', ''));
  }

  __renderAccountTable() {
    return html`
      <div id="${ELEMENTS.accountTitle.id}" class="row-title">
        ${this.accountTitle}
      </div>
      <div
        id="${ELEMENTS.patientTableContainer.id}"
        class="patient-record-table"
      >
        <neb-table-online-booking-link
          id="${ELEMENTS.patientRecordTable.id}"
          style="min-width: ${unsafeCSS(this.__minWidth)}px; width: 100%"
          .config="${this.__config}"
          .model="${[this.__model.account]}"
          .renderMatchColumn="${false}"
        ></neb-table-online-booking-link>
      </div>
    `;
  }

  __renderPotentialMatchesTable() {
    return html`
      <div class="match-title row-title" id="${ELEMENTS.matchTitle.id}">
        ${this.matchTitle}
      </div>
      <div
        id="${ELEMENTS.matchingTableContainer.id}"
        class="matching-table"
        @scroll="${this.__handlers.scroll}"
      >
        <neb-table-online-booking-link
          id="${ELEMENTS.matchingTable.id}"
          style="min-width: ${unsafeCSS(this.__minWidth)}px; width: 100%"
          .config="${this.__config}"
          .emptyMessage="${NO_MATCH_TEXT}"
          .model="${this.__model.matches}"
          .renderMatchColumn="${true}"
          .onMatch="${this.__handlers.match}"
        ></neb-table-online-booking-link>
      </div>
    `;
  }

  __renderMobilePotentialMatchesTable() {
    return html`
      <div
        id="${ELEMENTS.matchingTableContainer.id}"
        class="matching-table"
        @scroll="${this.__handlers.scroll}"
      >
        <neb-table-online-booking-link
          id="${ELEMENTS.mobileMatchingTable.id}"
          .config="${MOBILE_CONFIG}"
          .emptyMessage="${NO_MATCH_TEXT}"
          .model="${this.__model.matches}"
          .renderMatchColumn="${true}"
          .mode="${MODE.DETAIL}"
          .onMatch="${this.__handlers.match}"
        ></neb-table-online-booking-link>
      </div>
    `;
  }

  render() {
    return html`
      <div class="form-body">
        ${
          this.layout !== 'small'
            ? html`
                ${this.__renderAccountTable()}${
                  this.__renderPotentialMatchesTable()
                }
              `
            : this.__renderMobilePotentialMatchesTable()
        }
      </div>
    `;
  }
}

customElements.define(
  'neb-patient-online-booking-link-form',
  NebPatientOnlineBookingLinkForm,
);
