import { formatInvoicedClaims } from '../formatters/claims';
import ApiClient, { Method, RESPONSE_TYPE } from '../utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const BASE_URL = 'invoices';

export const removeLineItemsFromInvoice = (lineItemIds, invoiceIds) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify({
      lineItemIds,
      invoiceIds,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.DELETE,
    path: `${BASE_URL}/line-items`,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });

export const addLineItemsToInvoice = ({ lineItemIds, invoiceId, patientId }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify({
      lineItemIds,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: `${BASE_URL}/${invoiceId}/line-items/${patientId}`,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });

export async function getInvoiceClaims(
  invoiceId,
  optOutLoadingIndicator = false,
) {
  const claims = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: `${BASE_URL}/${invoiceId}/claims`,
    optOutLoadingIndicator,
    version: 2,
  });

  return formatInvoicedClaims(claims);
}
