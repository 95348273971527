import { intToTime } from '../../neb-utils/utils';

import ApiClient, { Method } from './utils/api-client-utils';

export const BASE_URL = 'calendar-resources';

export const billingApiClient = new ApiClient({ microservice: 'billing' });

export const DAY_NAME = {
  '1': 'Monday',
  '2': 'Tuesday',
  '3': 'Wednesday',
  '4': 'Thursday',
  '5': 'Friday',
  '6': 'Saturday',
  '7': 'Sunday',
};

export const DAY_INT = {
  Monday: '1',
  Tuesday: '2',
  Wednesday: '3',
  Thursday: '4',
  Friday: '5',
  Saturday: '6',
  Sunday: '7',
};

export const DEFAULT_AVAILABILITY = Array.from(
  ['1', '2', '3', '4', '5', '6', '7'],
  day => ({
    day,
    enabled: false,
    segments: [],
  }),
);

export async function updateRoom(model) {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: true,
    path: `${BASE_URL}/${model.id}`,
    method: Method.PUT,
    body: JSON.stringify(model),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 3,
  });

  return res.data[0];
}

export const createRoom = async body => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: true,
    path: `${BASE_URL}`,
    method: Method.POST,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 3,
  });

  return res.data[0];
};

export async function fetchManyRooms(queryParams = {}) {
  const res = await billingApiClient.makeRequest({
    path: `${BASE_URL}`,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 3,
    queryParams,
  });

  return res.data;
}

function calResAvailRawToModel(day) {
  const segments = day.segments.map(seg => ({
    ...seg,
    start: intToTime(seg.start),
    end: intToTime(seg.end),
    appointmentTypes: seg.allTypes
      ? [
          {
            allTypes: true,
          },
        ]
      : seg.appointmentTypes,
  }));

  return { ...day, segments };
}

export async function fetchRoomAvailability(roomId) {
  const res = await billingApiClient.makeRequest({
    path: `${BASE_URL}/${roomId}`,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 3,
    optOutLoadingIndicator: true,
  });

  return res.data.map(day => calResAvailRawToModel(day));
}
