import { atMin } from '@neb/form-validators';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { POPOVER_POSITION } from '../../../../packages/neb-lit-components/src/components/neb-date-picker';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { centsToCurrency } from '../../../../packages/neb-utils/formatters';
import { currency as currencyMask } from '../../../../packages/neb-utils/masks';
import {
  ITEM_EMPTY,
  select,
  currency,
} from '../../../../packages/neb-utils/selectors';
import { required } from '../../../../packages/neb-utils/validators';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  eraReport: { id: 'era-report' },
  payerFieldERA: { id: 'payer-field-era' },
  amountFieldERA: { id: 'amount-field-era' },
  transactionDateField: { id: 'transaction-date-field' },
  paymentTypeField: { id: 'payment-type' },
  paymentMethodField: { id: 'payment-method-field' },
  dateReceivedField: { id: 'date-received-field' },
  payerFieldPayment: { id: 'payer-field-payment' },
  amountFieldPayment: { id: 'amount-field-payment' },
  authEFT: { id: 'auth-eft-field' },
  eraHeader: { id: 'era-section-header' },
  paymentInfoHeader: { id: 'payment-info-section-header' },
};

const NO_RESULTS_FOUND = 'No results found';

class NebFormAbandonedERA extends NebForm {
  static get properties() {
    return {
      payerPlans: Array,
      __filteredPayerPlans: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .layout {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        .grid-4 {
          grid-template-columns: 1fr 2fr 1fr 1fr;
          align-items: flex-start;
        }

        .section-title {
          padding-bottom: 4px;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .section-title-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 5px ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_1};
        }

        .bold {
          font-weight: bold;
        }

        .date-picker {
          width: auto;
        }

        .link {
          cursor: pointer;
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
        }
      `,
    ];
  }

  static createModel() {
    return {
      amount: 0,
      payer: '',
      payerName: '',
      effectiveDate: moment(),
      authEFT: '',
    };
  }

  createSelectors() {
    const dateFormatter = date =>
      date
        ? parseDate(date)
            .startOf('day')
            .toISOString()
        : null;

    return {
      children: {
        payer: {
          ...select(this.payerPlans, this.model.payer || ITEM_EMPTY, {
            validators: [
              {
                error: 'Required',
                validate: v => v.data && v.data.id,
              },
            ],
          }),
          unformat: v => v.data,
        },
        amount: currency({
          validateRaw: true,
          validators: [atMin(0, true, 'Must be greater or equal to $0.00')],
        }),
        effectiveDate: {
          ignorePristine: true,
          validators: [required()],
          format: dateFormatter,
          unformat: dateFormatter,
        },
      },
    };
  }

  initState() {
    super.initState();

    this.__payerSearchValue = '';
    this.__filteredPayerPlans = [];
    this.payerPlans = [];

    this.onPrint = () => {};

    this.onDismissResponse = () => {};

    this.onClose = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      transactionDateSelectable: date => date <= parseDate().startOf('day'),
      transactionDateChanged: (date = null) => {
        this.formService.apply(
          'effectiveDate',
          date
            ? parseDate(date)
                .startOf('day')
                .toISOString()
            : date,
        );
      },
      searchPayer: e => {
        if (e.value !== this.__payerSearchValue) {
          this.__payerSearchValue = e.value;
          this.__filteredPayerPlans = e.value
            ? this.payerPlans.filter(({ label }) =>
                label.toLowerCase().includes(e.value.toLowerCase()),
              )
            : this.payerPlans;
        }
      },
      dismissResponse: () => this.onDismissResponse(),
      closeForm: () => this.onClose(),
      print: () => this.onPrint(),
    };
  }

  update(changedProps) {
    if (changedProps.has('payerPlans')) {
      this.__filteredPayerPlans = this.payerPlans;
    }
    super.update(changedProps);
  }

  __renderSectionHeader(title) {
    return html`
      <div class="section-title-container">
        <div id="${title.id}" class="section-title">${title.value}</div>
      </div>
    `;
  }

  __renderPayer() {
    return html`
      <neb-select-search
        id="${ELEMENTS.payerFieldERA.id}"
        name="payer"
        label="Payer"
        helper="Required"
        .error="${this.errors.payer}"
        .value="${this.state.payer}"
        search="${this.__payerSearchValue}"
        .emptyMessage="${NO_RESULTS_FOUND}"
        .items="${this.__filteredPayerPlans}"
        .onSearch="${this.handlers.searchPayer}"
        .onChange="${this.handlers.change}"
        showSearch
      ></neb-select-search>
    `;
  }

  __renderTransactionDatePicker() {
    const transactionDate = this.state.effectiveDate
      ? parseDate(this.state.effectiveDate).startOf('day')
      : null;

    return html`
      <neb-date-picker
        id="${ELEMENTS.transactionDateField.id}"
        name="effectiveDate"
        class="date-picker"
        label="Transaction Date"
        .selectedDate="${transactionDate}"
        .onClick="${this.handlers.transactionDateChanged}"
        .onClear="${this.handlers.transactionDateChanged}"
        .isDateSelectable="${this.handlers.transactionDateSelectable}"
        .invalidText="${this.errors.effectiveDate}"
        placeholder="Select Date"
        .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
        ?invalid="${this.errors.effectiveDate}"
        helperText="Required"
        required
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderField(fieldName, value, id) {
    return html`
      <div>
        <p class="bold">${fieldName}</p>
        <p id="${id}">${value || '-'}</p>
      </div>
    `;
  }

  __renderERASection() {
    return html`
      ${this.__renderSectionHeader({ id: ELEMENTS.eraHeader.id, value: 'ERA' })}
      <div class="grid grid-3">
        ${this.__renderPayer()}
        <neb-textfield
          id="${ELEMENTS.amountFieldERA.id}"
          name="amount"
          label="Amount"
          helper="Required"
          placeholder="Amount"
          .value="${this.state.amount}"
          .error="${this.errors.amount}"
          .onChange="${this.handlers.change}"
          .mask="${currencyMask}"
        ></neb-textfield>
        ${this.__renderTransactionDatePicker()}
      </div>

      <div class="grid grid-3">
        ${
          this.__renderField(
            'Payment Type',
            'Ins',
            ELEMENTS.paymentTypeField.id,
          )
        }
        ${
          this.__renderField(
            'Payment Method',
            'ERA',
            ELEMENTS.paymentMethodField.id,
          )
        }
        <neb-textfield
          id="${ELEMENTS.authEFT.id}"
          name="authEFT"
          label="Authorization/Check/EFT#"
          .value="${this.state.authEFT}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
      </div>
    `;
  }

  __renderPaymentInformationSection() {
    return html`
      ${
        this.__renderSectionHeader({
          id: ELEMENTS.paymentInfoHeader.id,
          value: 'Payment Information',
        })
      }

      <div class="grid grid-4">
        ${
          this.__renderField(
            'Date Recieved',
            parseDate(this.model.effectiveDate).format('MM/DD/YYYY'),
            ELEMENTS.dateReceivedField.id,
          )
        }
        ${
          this.__renderField(
            'Payer',
            this.model.payerName,
            ELEMENTS.payerFieldPayment.id,
          )
        }
        ${
          this.__renderField(
            'Amount',
            centsToCurrency(this.model.amount),
            ELEMENTS.amountFieldPayment.id,
          )
        }
        <div
          id="${ELEMENTS.eraReport.id}"
          class="section-title link"
          @click="${this.handlers.print}"
        >
          ERA Report
        </div>
      </div>
    `;
  }

  renderActionBar() {
    return this.model.dismissed
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .confirmLabel="${'POST'}"
            .cancelLabel="${'Cancel'}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.closeForm}"
          ></neb-action-bar>
        `
      : html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .doubleConfirm="${true}"
            .confirmLabel="${'POST'}"
            .cancelLabel="${'Dismiss Response'}"
            .removeLabel="${'Cancel'}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.dismissResponse}"
            .onRemove="${this.handlers.closeForm}"
          ></neb-action-bar>
        `;
  }

  renderContent() {
    return html`
      ${this.__renderPaymentInformationSection()} ${this.__renderERASection()}
    `;
  }
}

customElements.define('neb-form-abandoned-era', NebFormAbandonedERA);
