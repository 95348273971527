import '../../../../packages/neb-lit-components/src/components/neb-header';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../../packages/neb-lit-components/src/components/neb-radio-button';

import { isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { openOverlay } from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { createDefaultModifiers } from '../../../../packages/neb-utils/selectors';
import { getValueByPath } from '../../../../packages/neb-utils/utils';
import { CSS_SPACING } from '../../../styles';
import {
  getOverlayDetails,
  SCRUBBING_OVERLAYS,
} from '../../../utils/claim-scrubbing';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  name: { id: 'name' },
  activeSwitch: { id: 'switch-active' },
  payerGroupButton: { id: 'payer-group-button' },
  payerGroupTable: { id: 'payer-group-table' },
  tab: { id: 'tab' },
  payerButton: { id: 'payer-button' },
  payerTable: { id: 'payer-table' },
  restoreButton: { id: 'restore-button' },
  ruleButton: { id: 'rule-button' },
  ruleTable: { id: 'rule-table' },
  radioSelectPayers: { id: 'select-payers-radio-button' },
  radioAllPayers: { id: 'all-payers-radio-button' },
  radioMedicarePayers: { id: 'medicare-payers-radio-button' },
};

export const TABS = {
  PAYERS: 'payers',
  PAYER_GROUPS: 'payer-groups',
  RULES: 'rules',
};

const SELECT_PAYERS = {
  label: 'Select Payers',
  name: 'selectPayers',
};

const ALL_PAYER = {
  label: 'All Payers',
  name: 'allPayer',
};

const MEDICARE_PAYER = {
  label: 'Medicare Payers',
  name: 'medicarePayer',
};

export const DEFAULT_PAYER_RULE_SET_IDS = {
  PAYER: '8cbfce54-3227-11ee-95e3-2a2e549f948c',
  MEDICARE_PAYER: '1d6b2e18-4045-11ee-8ee1-f2a78722979f',
};

const APPEND_KEYS = {
  ruleSetPayers: ['id', 'status', 'alias', 'payerId', 'payerName'],
  ruleSetPayerGroups: ['id', 'name', 'payerPlans', 'active'],
  ruleSetRules: ['code', 'name', 'field', 'description'],
};

const PAYER_GROUP_CONFIG = [
  {
    key: 'name',
    label: 'Payer Group Name',
    flex: css`1 0 0`,
    truncate: true,
  },
  {
    key: 'payerPlans',
    label: 'Payers',
    flex: css`2 0 0`,
    formatter: v => v.join(', '),
    truncate: true,
  },
  {
    key: 'active',
    label: 'Status',
    flex: css`0 0 100px`,
    formatter: v => (v ? 'Active' : 'Inactive'),
  },
];

const PAYER_CONFIG = [
  {
    key: 'alias',
    label: 'Alias',
    flex: css`1 0 0`,
  },
  {
    key: 'payerName',
    label: 'Payer Name',
    flex: css`1 0 0`,
  },
  {
    key: 'payerId',
    label: 'Payer Id',
    flex: css`1 0 0`,
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`1 0 0`,
  },
];

const RULES_CONFIG = [
  {
    key: 'name',
    label: 'Rule Name',
    flex: css`1 0 0`,
  },
  {
    key: 'field',
    label: 'Field',
    flex: css`1 0 0`,
  },
];

const RESTORE_DEFAULT_RULES_MESSAGE = html`
  This will restore the default rules for this rule set. Any rules that you have
  added to this rule set will be removed. <br /><br />Are you sure that you want
  to restore the default rules?
`;

export default class FormPayerRuleSet extends NebForm {
  static get properties() {
    return {
      __selectedTab: String,
      __tabItems: Array,
      __payers: String,
      names: Array,
      defaultRules: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .grid-column {
          display: grid;
          grid-template-columns: 5fr 1fr;
        }

        .flex-center {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .flex {
          display: flex;
        }

        .pl-12 {
          padding-left: 12px;
        }

        .pr-5 {
          padding-right: 5px;
        }

        .button {
          padding-left: ${CSS_SPACING};
        }

        .pendo-spacer {
          padding-top: 60px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      name: '',
      active: true,
      level: 'Payer',
      ruleSetPayers: [],
      ruleSetPayerGroups: [],
      ruleSetRules: [],
      allPayer: false,
      medicarePayer: false,
    };
  }

  initState() {
    super.initState();

    this.__selectedTab = TABS.PAYERS;
    this.__tabItems = this.__genTabItems();
    this.__payers = SELECT_PAYERS.name;
    this.names = [];
    this.defaultRules = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      selectTab: tab => {
        this.__selectedTab = tab;
      },
      remove: (name, item) => {
        const index = this.state[name].findIndex(i => {
          if (this.__selectedTab === 'rules') return i.code === item.code;

          return i.id === item.id;
        });

        this.formService.removeItem(name, index);
      },
      removeAll: name => {
        const items = [...this.state[name]];
        items.forEach(item => this.handlers.removeItem(name, item));
      },
      associate: async ({ name }) => {
        const res = await openOverlay(
          SCRUBBING_OVERLAYS[name],
          getOverlayDetails(name, this.state),
        );

        if (res) {
          this.handlers.removeAll(name);
          this.__appendItems(name, res);
        }
      },
      setPayerRadioButton: item => {
        const currentTab =
          item === SELECT_PAYERS.name ? TABS.PAYERS : TABS.RULES;

        const allPayerValue = item === ALL_PAYER.name;
        const medicarePayerValue = item === MEDICARE_PAYER.name;
        this.__payers = item;

        this.formService.apply(ALL_PAYER.name, allPayerValue);
        this.formService.apply(MEDICARE_PAYER.name, medicarePayerValue);

        this.handlers.selectTab(currentTab);
      },
      restoreRules: async ({ name }) => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Restore Default Rules',
          message: RESTORE_DEFAULT_RULES_MESSAGE,
        });

        if (result) {
          this.handlers.removeAll(name);
          this.__appendItems(name, [...this.defaultRules[this.model.id]]);
        }
      },
    };
  }

  createSelectors() {
    return {
      children: {
        name: {
          ...createDefaultModifiers({
            validators: [
              isRequired(),
              {
                error: 'Duplicate name',
                validate: v => {
                  const name = v.trim();
                  return !this.names.includes(name);
                },
              },
            ],
          }),
          unformat: v => v.trim(),
        },
        ruleSetPayerGroups: {
          createItem: () => ({
            id: '',
            name: '',
            payerPlans: null,
            active: false,
          }),
          unformat: v => v.map(i => i.id),
        },
        ruleSetPayers: {
          createItem: () => ({
            id: '',
            status: 'Active',
            alias: '',
            payerId: '',
            payerName: '',
          }),
          unformat: v => v.map(i => i.id),
        },
        ruleSetRules: {
          createItem: () => ({
            code: '',
            name: '',
            field: '',
            description: '',
          }),
          unformat: v => v.map(r => r.code),
        },
      },
    };
  }

  updated(changedProps) {
    if (
      changedProps.has('model') &&
      (this.model.allPayer || this.model.medicarePayer)
    ) {
      this.__payers = this.model.allPayer
        ? ALL_PAYER.name
        : MEDICARE_PAYER.name;

      this.handlers.selectTab(TABS.RULES);
    }

    super.updated();
  }

  __isDefaultRuleSet() {
    return (
      this.state.id === DEFAULT_PAYER_RULE_SET_IDS.PAYER ||
      this.state.id === DEFAULT_PAYER_RULE_SET_IDS.MEDICARE_PAYER
    );
  }

  __appendItems(name, items) {
    items.forEach(item => {
      const index = this.state[name].length;
      this.formService.addItem(name);
      APPEND_KEYS[name].forEach(key =>
        this.formService.apply(
          `${name}.${index}.${key}`,
          getValueByPath(item, key.split('.')),
        ),
      );
    });
  }

  __renderPayerGroupsTab() {
    return html`
      <neb-button-action
        id="${ELEMENTS.payerGroupButton.id}"
        class="button"
        name="ruleSetPayerGroups"
        label="Associate Payer Group"
        .onClick="${this.handlers.associate}"
      ></neb-button-action>

      <neb-table
        id="${ELEMENTS.payerGroupTable.id}"
        name="ruleSetPayerGroups"
        emptyMessage="No Payer Groups Associated"
        showRemoveButton
        .showRemoveAll="${true}"
        .config="${PAYER_GROUP_CONFIG}"
        .model="${this.state.ruleSetPayerGroups}"
        .onRemove="${this.handlers.remove}"
        .onRemoveAll="${this.handlers.removeAll}"
      ></neb-table>
    `;
  }

  __renderPayersTab() {
    return html`
      <neb-button-action
        id="${ELEMENTS.payerButton.id}"
        class="button"
        name="ruleSetPayers"
        label="Associate Payer"
        .onClick="${this.handlers.associate}"
      ></neb-button-action>

      <neb-table
        id="${ELEMENTS.payerTable.id}"
        name="ruleSetPayers"
        emptyMessage="No Payers Associated"
        showRemoveButton
        .showRemoveAll="${true}"
        .config="${PAYER_CONFIG}"
        .model="${this.state.ruleSetPayers}"
        .onRemove="${this.handlers.remove}"
        .onRemoveAll="${this.handlers.removeAll}"
      ></neb-table>
    `;
  }

  __renderRestoreRecommendedRules() {
    return Object.values(DEFAULT_PAYER_RULE_SET_IDS).includes(this.model.id)
      ? html`
          <neb-button-action
            id="${ELEMENTS.restoreButton.id}"
            class="button"
            name="ruleSetRules"
            label="Restore Default Rules"
            leadingIcon="restore"
            .onClick="${this.handlers.restoreRules}"
          ></neb-button-action>
        `
      : html``;
  }

  __renderRulesTab() {
    return html`
      <div>
        <neb-button-action
          id="${ELEMENTS.ruleButton.id}"
          class="button"
          name="ruleSetRules"
          label="Associate Rule"
          .onClick="${this.handlers.associate}"
        ></neb-button-action>

        ${this.__renderRestoreRecommendedRules()}
      </div>

      <neb-table
        id="${ELEMENTS.ruleTable.id}"
        name="ruleSetRules"
        emptyMessage="No Rules Associated"
        showRemoveButton
        .showRemoveAll="${true}"
        .config="${RULES_CONFIG}"
        .model="${this.state.ruleSetRules}"
        .onRemove="${this.handlers.remove}"
        .onRemoveAll="${this.handlers.removeAll}"
      ></neb-table>
    `;
  }

  __genTabItems() {
    if (this.__payers !== SELECT_PAYERS.name) {
      return [
        {
          id: TABS.RULES,
          label: 'Rules',
          renderer: () => this.__renderRulesTab(),
        },
      ];
    }

    return [
      {
        id: TABS.PAYERS,
        label: 'Payers',
        renderer: () => this.__renderPayersTab(),
      },
      {
        id: TABS.PAYER_GROUPS,
        label: 'Payer Groups',
        renderer: () => this.__renderPayerGroupsTab(),
      },
      {
        id: TABS.RULES,
        label: 'Rules',
        renderer: () => this.__renderRulesTab(),
      },
    ];
  }

  __renderSelectedTab() {
    const item = this.__tabItems.find(i => i.id === this.__selectedTab);

    return item.renderer();
  }

  renderActionBar() {
    return this.__dirty
      ? this.__renderActionBar()
      : html`
          <div class="pendo-spacer"></div>
        `;
  }

  renderContent() {
    return html`
      <div class="grid-column">
        <neb-textfield
          id="${ELEMENTS.name.id}"
          class="grid"
          helper="Required"
          label="Name"
          maxLength="50"
          name="name"
          value="${this.state.name}"
          .error="${this.errors.name}"
          .items="${this.__items}"
          .onChange="${this.handlers.change}"
          .disabled="${this.__isDefaultRuleSet()}"
        ></neb-textfield>

        <neb-switch
          id="${ELEMENTS.activeSwitch.id}"
          class="flex-center"
          label="Active"
          name="active"
          .on="${this.state.active}"
          .onChange="${this.handlers.change}"
        ></neb-switch>
      </div>

      <div class="flex">
        <neb-radio-button
          id="${ELEMENTS.radioSelectPayers.id}"
          class="pr-5 pl-12"
          .onSelect="${this.handlers.setPayerRadioButton}"
          .value="${SELECT_PAYERS.name}"
          .label="${SELECT_PAYERS.label}"
          .checked="${this.__payers === SELECT_PAYERS.name}"
          .disabled="${this.__isDefaultRuleSet()}"
        >
        </neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.radioAllPayers.id}"
          class="pr-5"
          .onSelect="${this.handlers.setPayerRadioButton}"
          .value="${ALL_PAYER.name}"
          .label="${ALL_PAYER.label}"
          .checked="${this.__payers === ALL_PAYER.name}"
          .disabled="${this.__isDefaultRuleSet()}"
        >
        </neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.radioMedicarePayers.id}"
          class="pr-5"
          .onSelect="${this.handlers.setPayerRadioButton}"
          .value="${MEDICARE_PAYER.name}"
          .label="${MEDICARE_PAYER.label}"
          .checked="${this.__payers === MEDICARE_PAYER.name}"
          .disabled="${this.__isDefaultRuleSet()}"
        >
        </neb-radio-button>
      </div>

      <neb-tab-group
        id="${ELEMENTS.tab.id}"
        .items="${this.__genTabItems()}"
        .selectedId="${this.__selectedTab}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>

      ${this.__renderSelectedTab()}
    `;
  }
}

customElements.define('neb-form-payer-rule-set', FormPayerRuleSet);
