import '../controls/neb-button-action';
import '../controls/neb-tab-group';
import '../controls/neb-switch';
import '../inputs/neb-textarea';
import '../inputs/neb-textfield';
import '../inputs/neb-select';
import '../inputs/neb-select-search';
import '../tables/neb-table';
import '../neb-header';
import '../neb-pagination';
import '../../../../../src/components/misc/neb-icon';

import { isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import {
  getVendors,
  EMPTY_VENDOR_MODEL,
} from '../../../../../src/api-clients/vendor';
import { getModifiersFromChargeItem } from '../../../../../src/utils/neb-charges-util';
import { getBillingCodesCharges } from '../../../../neb-api-client/src/billing-codes';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_SPACING_ROW_LARGE,
  CSS_BUTTON_SPACING_ROW,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { SELECT_CHARGES_OVERLAY_TYPE } from '../../../../neb-utils/enums';
import {
  centsToCurrency,
  currencyToCents,
} from '../../../../neb-utils/formatters';
import { signedInteger, number } from '../../../../neb-utils/masks';
import * as selectors from '../../../../neb-utils/selectors';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { map } from '../../../../neb-utils/utils';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';
import {
  BLACKLISTED_CHARGE_CODES,
  CODE_CHARGE_TYPE,
} from '../../utils/purchase-charges';

import NebForm, { ELEMENTS as BASE_ELEMENTS } from './neb-form';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  item: {
    id: 'item',
  },
  chargesAndPurchases: {
    id: 'charges-and-purchases',
  },
  tabGroup: {
    id: 'tab-group',
  },
  name: { id: 'name' },
  active: { id: 'active-toggle' },
  quantity: { id: 'quantity' },
  reorderAt: { id: 'reorder-quantity' },
  upc: { id: 'universal-product-code' },
  notes: { id: 'notes' },
  website: { id: 'website' },
  vendor: { id: 'vendor-selection' },
  addNewVendorButton: { id: 'add-new-vendor' },
  vendorWebsite: { id: 'vendor-website' },
  copyVendorButton: {
    id: 'copy-vendor-button',
  },
  copyItemButton: {
    id: 'copy-item-button',
  },
  copyInput: {
    id: 'copy-input',
  },
  addChargesButton: {
    id: 'add-charges',
  },
  chargesTable: {
    id: 'charges-table',
  },
  chargesPagination: {
    id: 'charges-pagination',
  },
};

const getTypeFromChargeItem = item => {
  const { charge } = item;

  if (charge) return charge.availableForPurchase ? 'Purchase' : 'Charge';

  return 'Purchase';
};

const getCodeFromChargeItem = item => {
  const { charge, code } = item;

  if (charge) return charge.procedure;

  return code || '';
};

const getDescriptionFromChargeItem = item => {
  const { charge, codeCharge } = item;

  if (charge) return charge.description;
  if (codeCharge) return codeCharge.description;

  return '';
};

const getAmountFromChargeItem = item => {
  const { charge } = item;

  return charge ? charge.amount : '$0.00';
};

const CHARGES_CONFIG = [
  {
    key: 'type',
    skipRawValue: true,
    label: 'Type',
    flex: css`1 0 0`,
    formatter: (_, item) => getTypeFromChargeItem(item),
  },
  {
    key: 'code',
    skipRawValue: true,
    label: 'Code',
    flex: css`1 0 0`,
    formatter: (_, item) => getCodeFromChargeItem(item),
  },
  {
    key: 'description',
    skipRawValue: true,
    label: 'Description',
    flex: css`2 0 0`,
    formatter: (_, item) => getDescriptionFromChargeItem(item),
  },
  {
    key: 'modifiers',
    skipRawValue: true,
    label: 'Modifiers',
    flex: css`1 0 0`,
    formatter: (_, item) => getModifiersFromChargeItem(item),
  },
  {
    key: 'amount',
    skipRawValue: true,
    label: 'Amount',
    flex: css`1 0 0`,
    formatter: (_, item) => getAmountFromChargeItem(item),
  },
];

const EMPTY_MESSAGE_CHARGES =
  'There are no associated charges or purchases for this item. Click "Associate Charges & Purchases" to add charges and purchases to this item.';

export const SELECT_CHARGES_TITLE = 'Add Charges & Purchases';
export const SELECT_CHARGES_DESCRIPTION =
  'Select charges and purchases to associate with the inventory item.';

export default class NebFormInventoryItem extends NebForm {
  static get properties() {
    return {
      __navItems: Array,
      __selectedTab: Boolean,
      __showResults: Boolean,
      __vendorItems: Array,
      __billingCodeDictionary: Object,
      __vendorSearchValue: String,
      __chargesCollectionState: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .tabs {
          flex: 0 0 auto;
          padding-top: ${CSS_SPACING_ROW_LARGE};
        }

        .tab-content {
          flex: 1 0 0;
          overflow: auto;
        }

        .content-form {
          display: flex;
          flex-flow: row nowrap;
          padding: 0 ${CSS_SPACING};
        }

        .item-fields {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-auto-rows: minmax(min-content, max-content);
          flex: 1 0 0;
        }

        .notes {
          display: block;
          width: 100%;
          height: 200px;
        }

        .vendor-fields {
          display: inline-grid;
          grid-auto-rows: minmax(min-content, max-content);
          flex: 1 0 0;
        }

        .vendor-search {
          grid-column: span 6;
        }

        .add-button {
          grid-column: span 2;
          padding: ${CSS_BUTTON_SPACING_ROW} 0;
        }

        .grid-row-with-copy {
          display: grid;
          grid-template-columns: 1fr auto;
          grid-gap: 8px;
          align-items: center;
          grid-column: span 6;
        }

        .item-span {
          grid-column: span 2;
        }

        .icon-container {
          padding-top: ${CSS_SPACING};
        }

        .icon {
          width: 20px;
          height: 20px;
          margin: 5px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .active-switch {
          grid-column: span 2;
          padding-top: 20px;
        }

        .add-charge {
          padding: ${CSS_BUTTON_SPACING_ROW} 0 ${CSS_BUTTON_SPACING_ROW}
            ${CSS_SPACING};
          width: 270px;
        }

        .charges-pagination {
          flex-direction: row-reverse;
          padding-top: ${CSS_SPACING};
          padding-right: 10px;
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initServices();
  }

  static createModel() {
    return {
      name: '',
      active: true,
      quantity: '',
      reorderAt: '',
      upc: '',
      notes: '',
      website: '',
      vendorId: EMPTY_VENDOR_MODEL,
      inventoryCharges: [],
      inventoryChargeCodes: [],
    };
  }

  createSelectors() {
    return {
      children: {
        name: [isRequired()],
        quantity: selectors.numeric(0, {
          validators: [
            isRequired(),
            {
              error: 'Required',
              validate: v => v !== '-',
            },
          ],
        }),
        reorderAt: {
          clipPristine: true,
          format: v => `${v}`,
          unformat: v => (v.length ? Number(v) : null),
        },
        vendorId: {
          unsafe: true,
          clipPristine: true,
          validateRaw: true,
          validators: [isRequired()],
          format: v =>
            this.__vendorItems.find(item => item.data.id === v.data.id) ||
            EMPTY_VENDOR_MODEL,
          unformat: v => v.data.id,
        },
        inventoryCharges: {
          unsafe: true,
          createItem: () => ({
            id: '',
            chargeId: '',
            charge: {
              amount: '',
              description: '',
              procedure: '',
              modifiers: ['', '', '', ''],
              availableForPurchase: false,
            },
          }),
          children: {
            $: {
              children: {
                charge: {
                  clipPristine: true,
                },
              },
            },
          },
          unformat: charges =>
            charges.map(c => ({
              chargeId: c.chargeId,
            })),
        },
        inventoryChargeCodes: {
          unsafe: true,
          createItem: () => ({
            id: '',
            code: '',
            codeCharge: {
              code: '',
              description: '',
            },
          }),
          children: {
            $: {
              children: {
                codeCharge: {
                  clipPristine: true,
                },
              },
            },
          },
          unformat: codes =>
            codes.map(c => ({
              code: c.code,
            })),
        },
      },
    };
  }

  initState() {
    super.initState();

    this.__navItems = [
      {
        id: 'item',
        label: 'Item',
        renderer: () => this.__renderItemContent(),
      },
      {
        id: 'charges-and-purchases',
        label: 'Associated Charges & Purchases',
        renderer: () => this.__renderChargesAndPurchases(),
      },
    ];

    this.__showResults = false;
    this.__selectedTab = 'item';
    this.__vendorItems = [];
    this.__billingCodeDictionary = {};
    this.__vendorSearchValue = '';
    this.__chargesCollectionState = CollectionService.createModel();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      addVendor: async () => {
        const result = await openOverlay(OVERLAY_KEYS.VENDOR, {});

        if (result && result.active) {
          const newVendor = {
            data: result,
            label: result.name,
          };

          this.__vendorItems = [...this.__vendorItems, newVendor];

          this.formService.apply('vendorId', newVendor);
        }
      },
      copyVendorLink: () => this.__copyLink(this.state.vendorId.data.website),
      copyItemLink: () => this.__copyLink(this.state.website),
      save: async () => {
        const valid = this.formService.validate();

        if (valid) {
          const rawModel = this.formService.build();
          const model = map(rawModel, (keyPath, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.__saving = true;
          await this.onSave(model);
        } else {
          this.__selectedTab = 'item';
        }

        this.__saving = false;
      },
      search: e => {
        this.__vendorSearchValue = e.value;
        if (!this.__vendorSearchValue) this.__showResults = true;

        this.__searchVendors(this.__vendorSearchValue);
      },
      selectTab: tab => {
        this.__selectedTab = tab;
      },
      vendorChange: v => {
        if (!v.value) {
          this.formService.apply('vendorId', {
            ...EMPTY_VENDOR_MODEL,
          });
        } else {
          this.formService.apply('vendorId', v.value);
        }
      },
      addCharges: async () => {
        const selectedCharges = await openOverlay(OVERLAY_KEYS.SELECT_CHARGES, {
          charges: this.__mapInventoryChargesToCharges(),
          type: SELECT_CHARGES_OVERLAY_TYPE.SHOW_TYPE,
          title: SELECT_CHARGES_TITLE,
          description: SELECT_CHARGES_DESCRIPTION,
          labelProcedure: 'Code',
          hideAddAll: true,
          includeBillingCodes: true,
          requireAvailableForPurchase: false,
        });

        this.__applyCharges(selectedCharges);
      },
      removeCharge: async (_, __, idx) => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Remove Charge',
          message:
            'This will remove the charge or purchase from the inventory item. Are you sure that you want to proceed?',
          confirmText: 'YES',
          cancelText: 'NO',
        });

        if (result) {
          const selectedItem = this.__chargesCollectionState.pageItems[idx];

          const idxToRemove = this.__findChargeIdx(idx);

          if (selectedItem.charge) {
            this.formService.removeItem('inventoryCharges', idxToRemove);
            this.__collectionService.setPageIndex(0);
          } else {
            this.formService.removeItem('inventoryChargeCodes', idxToRemove);
            this.__collectionService.setPageIndex(0);
          }
        }
      },
      changeChargesCollectionState: state => {
        this.__chargesCollectionState = state;
      },
      sortChargesCollectionState: (a, b) => {
        const aCode = getCodeFromChargeItem(a);
        const bCode = getCodeFromChargeItem(b);

        const codeCompare = aCode.localeCompare(bCode);

        if (codeCompare === 0) {
          const aDesc = getDescriptionFromChargeItem(a);
          const bDesc = getDescriptionFromChargeItem(b);

          return aDesc.localeCompare(bDesc);
        }

        return codeCompare;
      },
      changeChargesPage: index => this.__collectionService.setPageIndex(index),
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__vendorItems = (await getVendors({ hideInactive: true })).map(v => ({
      data: v,
      label: v.name,
    }));

    this.__billingCodeDictionary = (await getBillingCodesCharges({
      hideInactive: true,
    }))
      .filter(
        cc =>
          cc.type === CODE_CHARGE_TYPE.PURCHASE &&
          !BLACKLISTED_CHARGE_CODES.includes(cc.code),
      )
      .reduce((memo, item) => {
        memo[item.code] = item;

        return memo;
      }, {});
  }

  update(changedProps) {
    if (changedProps.has('__state')) {
      if (
        this.state.inventoryCharges.length +
          this.state.inventoryChargeCodes.length !==
        this.__collectionService.getTotalCount()
      ) {
        const { pageIndex } = this.__chargesCollectionState;

        const combinedItems = [
          ...this.state.inventoryCharges,
          ...this.state.inventoryChargeCodes,
        ];

        this.__collectionService.setItems(combinedItems);

        this.__collectionService.setPageIndex(pageIndex);
      }
    }

    if (changedProps.has('model')) {
      this.__vendorItems = [
        {
          label: this.model.vendorId.label,
          data: { ...this.model.vendorId.data },
        },
      ];
    }

    super.update(changedProps);
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: this.handlers.changeChargesCollectionState,
        onSort: this.handlers.sortChargesCollectionState,
      },
      {
        hideInactive: false,
        sortParams: {
          key: '',
          dir: 'asc',
        },
      },
    );
  }

  __mapChargesToInventoryCharges(selectedCharges) {
    const charges = selectedCharges.filter(ch => !ch.type);
    const codes = selectedCharges.filter(ch => ch.type);

    const mappedCharges = charges
      .map(charge => ({
        id: charge.chargeId,
        chargeId: charge.chargeId,
        charge: {
          amount: centsToCurrency(charge.amount),
          description: charge.description,
          procedure: charge.procedure,
          modifiers: charge.modifiers,
          availableForPurchase: charge.availableForPurchase,
        },
      }))
      .sort((a, b) => a.charge.procedure.localeCompare(b.charge.procedure));

    const mappedCodes = codes
      .map(code => ({
        code: code.code,
        codeCharge: {
          code: code.code,
          description: code.description,
        },
      }))
      .sort((a, b) => a.code.localeCompare(b.code));

    return { charges: mappedCharges, codes: mappedCodes };
  }

  __mapInventoryChargesToCharges() {
    const mappedCharges = this.state.inventoryCharges.map(charge => ({
      chargeId: charge.chargeId,
      amount: currencyToCents(charge.charge.amount),
      description: charge.charge.description,
      procedure: charge.charge.procedure,
      modifiers: charge.charge.modifiers,
      availableForPurchase: charge.charge.availableForPurchase,
      active: true,
    }));

    const mappedCodes = this.state.inventoryChargeCodes
      .map(code => ({
        chargeId: this.__billingCodeDictionary[code.code]
          ? this.__billingCodeDictionary[code.code].id
          : null,
        amount: 0,
        description: code.codeCharge ? code.codeCharge.description : '',
        code: code.code,
        procedure: code.code,
        modifiers: ['', '', '', ''],
        availableForPurchase: true,
        active: true,
        type: CODE_CHARGE_TYPE.PURCHASE,
      }))
      .filter(ch => ch.chargeId);

    return [...mappedCharges, ...mappedCodes];
  }

  __findChargeIdx(pageItemsIdx) {
    const selectedItem = this.__chargesCollectionState.pageItems[pageItemsIdx];

    if (selectedItem.charge) {
      return this.state.inventoryCharges.findIndex(
        charge => charge.chargeId === selectedItem.chargeId,
      );
    }

    return this.state.inventoryChargeCodes.findIndex(
      code => code.code === selectedItem.code,
    );
  }

  __removeCharges() {
    for (
      let countCharges = this.state.inventoryCharges.length;
      countCharges > 0;
      countCharges--
    ) {
      this.formService.removeItem('inventoryCharges', countCharges - 1);
    }

    for (
      let countCharges = this.state.inventoryChargeCodes.length;
      countCharges > 0;
      countCharges--
    ) {
      this.formService.removeItem('inventoryChargeCodes', countCharges - 1);
    }
  }

  __applyCharges(selectedCharges) {
    const { charges, codes } = this.__mapChargesToInventoryCharges(
      selectedCharges,
    );

    this.__removeCharges();

    charges.forEach((charge, idx) => {
      this.formService.addItem('inventoryCharges');

      this.formService.apply(`inventoryCharges.${idx}.id`, charge.id);
      this.formService.apply(
        `inventoryCharges.${idx}.chargeId`,
        charge.chargeId,
      );

      this.formService.apply(`inventoryCharges.${idx}.charge`, charge.charge);
    });

    codes.forEach((code, idx) => {
      this.formService.addItem('inventoryChargeCodes');

      const billingCodeId = this.__billingCodeDictionary[code.code]
        ? this.__billingCodeDictionary[code.code].id
        : null;

      this.formService.apply(`inventoryChargeCodes.${idx}.id`, billingCodeId);
      this.formService.apply(`inventoryChargeCodes.${idx}.code`, code.code);

      this.formService.apply(
        `inventoryChargeCodes.${idx}.codeCharge`,
        code.codeCharge,
      );
    });

    this.__collectionService.setItems([
      ...this.state.inventoryCharges,
      ...this.state.inventoryChargeCodes,
    ]);

    this.__collectionService.setPageIndex(0);
  }

  async __searchVendors(search) {
    const filteredItems = await getVendors({
      search,
      hideInactive: true,
    });

    this.__vendorItems = filteredItems.map(item => ({
      data: item,
      label: `${item.name}`,
    }));
  }

  __copyLink(website) {
    const copyInputEl = this.shadowRoot.getElementById(ELEMENTS.copyInput.id);
    copyInputEl.style.display = '';
    copyInputEl.value = website;

    copyInputEl.select();

    document.execCommand('copy');

    copyInputEl.style.display = 'none';
  }

  __renderItemSection() {
    return html`
      <div class="content-form">
        <div class="item-fields">
          <neb-textfield
            id="${ELEMENTS.name.id}"
            class="item-span"
            name="name"
            label="Item Name"
            maxLength="255"
            helper="Required"
            .value="${this.state.name}"
            .error="${this.errors.name}"
            .onChange="${this.handlers.change}"
          >
          </neb-textfield>

          <neb-textfield
            id="${ELEMENTS.quantity.id}"
            class="field"
            name="quantity"
            label="Quantity"
            maxLength="5"
            helper="Required"
            .mask="${signedInteger}"
            .inputMode="${'numeric'}"
            .value="${this.state.quantity}"
            .error="${this.errors.quantity}"
            .onChange="${this.handlers.change}"
          >
          </neb-textfield>

          <neb-textfield
            id="${ELEMENTS.reorderAt.id}"
            class="field"
            name="reorderAt"
            label="Reorder At"
            maxLength="5"
            .mask="${number}"
            .inputMode="${'numeric'}"
            .value="${this.state.reorderAt}"
            .onChange="${this.handlers.change}"
          >
          </neb-textfield>

          <neb-textfield
            id="${ELEMENTS.upc.id}"
            class="field"
            name="upc"
            label="UPC"
            helper=" "
            maxLength="50"
            .value="${this.state.upc}"
            .onChange="${this.handlers.change}"
          >
          </neb-textfield>

          <neb-textarea
            id="${ELEMENTS.notes.id}"
            class="notes item-span"
            label="Note"
            name="notes"
            rows="4"
            maxLength="500"
            showCount="${true}"
            helper=" "
            .value="${this.state.notes}"
            .onChange="${this.handlers.change}"
          ></neb-textarea>
        </div>
      </div>
    `;
  }

  __renderVendorSection() {
    return html`
      <neb-header .label="${'Vendor Information'}"></neb-header>

      <div class="content-form">
        <div class="vendor-fields">
          <neb-select-search
            id="${ELEMENTS.vendor.id}"
            class="vendor-search"
            name="vendorId"
            label="Search for an existing vendor"
            helper="Required"
            emptyMessage="No items found"
            .items="${this.__vendorItems}"
            .search="${this.__vendorSearchValue}"
            .value="${this.state.vendorId}"
            .onChange="${this.handlers.vendorChange}"
            .onSearch="${this.handlers.search}"
            .error="${this.errors.vendorId}"
            showSearch
          ></neb-select-search>

          <div class="add-button">
            <neb-button-action
              id="${ELEMENTS.addNewVendorButton.id}"
              label="Add New Vendor"
              .onClick="${this.handlers.addVendor}"
            ></neb-button-action>
          </div>

          <div class="grid-row-with-copy">
            <neb-textfield
              id="${ELEMENTS.vendorWebsite.id}"
              helper=" "
              maxLength="1000"
              label="Vendor Website"
              .value="${this.state.vendorId.data.website}"
              .onChange="${this.handlers.change}"
              ?disabled="${true}"
            ></neb-textfield>

            <div
              id="${ELEMENTS.copyVendorButton.id}"
              @click="${this.handlers.copyVendorLink}"
            >
              <neb-icon class="icon" icon="neb:copy"></neb-icon>
            </div>
          </div>

          <div class="grid-row-with-copy">
            <neb-textfield
              id="${ELEMENTS.website.id}"
              name="website"
              helper=" "
              maxLength="1000"
              label="Item Ordering Website"
              .value="${this.state.website}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <div
              id="${ELEMENTS.copyItemButton.id}"
              @click="${this.handlers.copyItemLink}"
            >
              <neb-icon class="icon" icon="neb:copy"></neb-icon>
            </div>
          </div>

          <neb-switch
            id="${ELEMENTS.active.id}"
            class="active-switch"
            name="active"
            label="Active"
            helper="Required"
            ?on="${this.state.active}"
            .onChange="${this.handlers.change}"
          ></neb-switch>

          <input
            autocomplete="off"
            id="${ELEMENTS.copyInput.id}"
            style="display: none;"
          />
        </div>
      </div>
    `;
  }

  __renderItemContent() {
    return html`
      ${this.__renderItemSection()} ${this.__renderVendorSection()}
    `;
  }

  __renderChargesAndPurchases() {
    return html`
      <neb-button-action
        id="${ELEMENTS.addChargesButton.id}"
        class="add-charge"
        label="Associate Charges & Purchases"
        .onClick="${this.handlers.addCharges}"
      ></neb-button-action>

      <neb-table
        id="${ELEMENTS.chargesTable.id}"
        emptyMessage="${EMPTY_MESSAGE_CHARGES}"
        .config="${CHARGES_CONFIG}"
        .model="${this.__chargesCollectionState.pageItems}"
        .onRemove="${this.handlers.removeCharge}"
        ?showRemoveButton="${true}"
      ></neb-table>

      <neb-pagination
        id="${ELEMENTS.chargesPagination}"
        class="charges-pagination"
        .pageCount="${this.__chargesCollectionState.pageCount}"
        .currentPage="${this.__chargesCollectionState.pageIndex}"
        .onPageChanged="${this.handlers.changeChargesPage}"
      ></neb-pagination>
    `;
  }

  __renderTabContent() {
    const item = this.__navItems.find(i => i.id === this.__selectedTab);

    return item ? item.renderer() : '';
  }

  renderContent() {
    return html`
      <neb-tab-group
        class="tabs"
        id="${ELEMENTS.tabGroup.id}"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>
      ${this.__renderTabContent()}
    `;
  }
}
customElements.define('neb-form-inventory-item', NebFormInventoryItem);
