import '../../../neb-lit-components/src/components/patients/neb-patient-summary';
import './neb-patient-tabs-controller';

import { matchRouteSwitch } from '@neb/router';
import { LitElement, html, css } from 'lit';

import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../neb-lit-components/src/utils/overlay-constants';
import {
  CSS_BORDER_GREY_2,
  CSS_SPACING,
  CSS_FONT_FAMILY,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  summary: {
    id: 'summary',
  },
  patientTabs: {
    id: 'patient-tabs',
  },
};

class PatientSummaryView extends LitElement {
  static get properties() {
    return {
      documentCount: Number,
      insuranceCount: Number,
      caseCount: Number,
      route: String,
      patient: Object,
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__navItems = this.genNavItems();

    this.documentCount = 0;
    this.insuranceCount = 0;
    this.caseCount = 0;
    this.route = '';
    this.patient = {};

    this.onUpdatePatient = () => {};

    this.onEditPatient = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      editClicked: () => this.onEditPatient(),
      openAppointment: appointmentId =>
        openOverlay(OVERLAY_KEYS.APPOINTMENT_PAGE, {
          appointmentId,
        }),
      openAlerts: patientId =>
        openOverlay(OVERLAY_KEYS.PATIENT_ALERT, {
          patientId,
        }),
      openAppointmentHistory: patientId =>
        openOverlay(OVERLAY_KEYS.APPOINTMENT_HISTORY, {
          patientId,
        }),
      updatePatient: () => this.onUpdatePatient(),
    };
  }

  genNavItems() {
    return [
      {
        exact: false,
        path: '/',
        resolver: tail => html`
          <neb-patient-tabs-controller
            id="${ELEMENTS.patientTabs.id}"
            class="controller-tabs"
            .layout="${this.layout}"
            .route="${tail}"
            .patient="${this.patient}"
            .documentCount="${this.documentCount}"
            .insuranceCount="${this.insuranceCount}"
            .caseCount="${this.caseCount}"
            .onUpdatePatient="${this.__handlers.updatePatient}"
            .onEditPatient="${this.__handlers.editClicked}"
          ></neb-patient-tabs-controller>
        `,
      },
    ];
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        display: flex;
        width: 100%;
        height: 100%;
      }

      :host([layout='small']) .container {
        flex-direction: column;
      }

      .summary {
        padding: 30px 10px ${CSS_SPACING} 30px;
        font-family: ${CSS_FONT_FAMILY};
      }

      :host([layout='small']) .summary {
        width: 100%;
        border-bottom: ${CSS_BORDER_GREY_2};
        padding: 0;
      }

      .controller-tabs {
        flex: 1 0 0;
      }

      :host([layout='small']) .controller-tabs {
        margin: 0;
      }
    `;
  }

  render() {
    return html`
      <div id="patientSummaryDetails" class="container">
        ${
          this.layout === 'small'
            ? html`
                <neb-patient-summary-controller
                  id="${ELEMENTS.summary.id}"
                  class="summary"
                  .layout="${this.layout}"
                  .model="${this.patient}"
                  .patientId="${this.patient.id}"
                  .onEditPatient="${this.__handlers.editClicked}"
                  .onAppointmentSelect="${this.__handlers.openAppointment}"
                  .onOpenAlerts="${this.__handlers.openAlerts}"
                  .onOpenAppointmentHistory="${
                    this.__handlers.openAppointmentHistory
                  }"
                ></neb-patient-summary-controller>
              `
            : ''
        }
        ${matchRouteSwitch(this.__navItems, this.route)}
      </div>
    `;
  }
}

customElements.define('neb-patient-summary-view', PatientSummaryView);
