/* eslint-disable no-lone-blocks */
/* eslint-disable no-await-in-loop */
import {
  initSession,
  isServiceAvailable,
  fetchVersion,
  resetService,
  initialize,
  startScan,
  checkScanStatus,
  getPage,
  deleteAllPages,
  deletePage,
} from '../api/scan-api';
import { SCAN_ACTION_WAIT_TIME } from '../enums/scan-enums';
import { PLATFORM_OS } from '../mappers/platform-mapper';
import { mapScanConfigToApi } from '../mappers/scan-api-mapper';
import { sleep } from '../utils/sleep-util';

const NUMBER_OF_CHECK_SCAN_STATUS_RESULTS = 1000;
const DELAY_CHECK_SCAN_STATUS_RESULT = 5000;

export const getDefaultDocumentViewModel = () => ({
  pages: [],
  count: 0,
  documentType: '',
});

let documentViewModel = getDefaultDocumentViewModel();

const SUPPORTED_PLATFORMS = [PLATFORM_OS.WINDOWS, PLATFORM_OS.MAC];

export const resetDocument = (model = getDefaultDocumentViewModel()) => {
  documentViewModel = model;
};

export const SCAN_INSTALL = Object.freeze({
  mac: {
    version: '2.2.2',
    url:
      'https://neb-scanner-prod.s3.amazonaws.com/install/v2.2.2/K1ScanServiceV2.2.2.pkg',
  },
  windows: {
    version: '2.2.1',
    url:
      'https://neb-scanner-prod.s3.amazonaws.com/install/v2.2.1/K1ScanServiceV2.2.1.msi',
  },
});

export const getScannerInstallUrl = (platform = PLATFORM_OS.WINDOWS) =>
  platform === PLATFORM_OS.MAC
    ? SCAN_INSTALL.mac.url
    : SCAN_INSTALL.windows.url;

export const CHECK_SCAN_RESULT = Object.freeze({
  OK: 'OK',
  UPDATE: 'UPDATE',
  INSTALL: 'INSTALL',
  UNKNOWN_VERSION: 'UNKNOWN',
});

export const isPlatformSupported = platform =>
  SUPPORTED_PLATFORMS.includes(platform);

export const checkScanService = async (platformOS, isSafari) => {
  initSession({ platformOS, isSafari });
  const serviceAvailable = await isServiceAvailable();

  if (!serviceAvailable) {
    return { checkScanResult: CHECK_SCAN_RESULT.INSTALL, version: null };
  }

  const version = await fetchVersion();

  if (!version) {
    return {
      checkScanResult: CHECK_SCAN_RESULT.UNKNOWN_VERSION,
      version: null,
    };
  }

  const currentVersion =
    platformOS === PLATFORM_OS.WINDOWS
      ? SCAN_INSTALL.windows.version
      : SCAN_INSTALL.mac.version;

  if (version !== currentVersion) {
    return { checkScanResult: CHECK_SCAN_RESULT.UPDATE, version };
  }

  return { checkScanResult: CHECK_SCAN_RESULT.OK, version };
};

export const startSession = async (platform = PLATFORM_OS.WINDOWS) => {
  if (!(await isServiceAvailable())) return false;
  if (!(await resetService())) return false;

  const platformName = platform === PLATFORM_OS.WINDOWS ? 'windows' : 'mac';
  const { version } = SCAN_INSTALL[platformName];

  await sleep(SCAN_ACTION_WAIT_TIME.AFTER_RESET);
  const result = await initialize();
  const success = result?.version === version;
  return success;
};

export const getPageImage = async pageNumber => {
  const page = documentViewModel.pages.find(x => x.page === pageNumber);

  if (page) {
    return page.image;
  }

  const pageImageData = await getPage(pageNumber);

  documentViewModel.pages.push(pageImageData);

  return pageImageData.image;
};

const checkScanStatusWithDelay = async (
  delay = DELAY_CHECK_SCAN_STATUS_RESULT,
) => {
  await sleep(delay);
  const checkScanStatusResult = await checkScanStatus();
  return checkScanStatusResult;
};

export const scan = async config => {
  if (documentViewModel.count > 0) {
    await deleteAllPages();
  }
  resetDocument();

  const scanApiConfig = mapScanConfigToApi(config);

  const startScanResponse = await startScan(scanApiConfig);

  if (startScanResponse.error) {
    return documentViewModel;
  }

  for (let i = 0; i < NUMBER_OF_CHECK_SCAN_STATUS_RESULTS; i += 1) {
    {
      const checkScanStatusResult = await checkScanStatusWithDelay();

      if (checkScanStatusResult.success && !checkScanStatusResult.scanRunning) {
        documentViewModel.count = checkScanStatusResult.pageCount;
        documentViewModel.documentType = config.documentType;
        await getPageImage(1);
        break;
      }
    }
  }

  return documentViewModel;
};

export const getDocumentViewModel = async (loadAllPages = false) => {
  if (!loadAllPages) {
    return documentViewModel;
  }

  for (let i = 1; i <= documentViewModel.count; i += 1) {
    {
      await getPageImage(i);
    }
  }
  return documentViewModel;
};

export const saveImage = (base64Image, pageNumber) => {
  const page = documentViewModel.pages.find(x => x.page === pageNumber);

  if (!page) {
    console.error(`Cannot find image for page=${pageNumber}`);
    return false;
  }

  page.image = base64Image;
  return true;
};

export const deleteDocumentPage = async pageNumber => {
  const pageIndex = documentViewModel.pages.findIndex(
    x => x.page === pageNumber,
  );

  if (pageIndex < 0) {
    return { success: false, count: documentViewModel.count };
  }

  const result = await deletePage(pageNumber);

  if (result.success) {
    documentViewModel.pages.splice(pageIndex, 1);
    documentViewModel.count -= 1;

    for (let i = 0; i < documentViewModel.pages.length; i += 1) {
      {
        if (documentViewModel.pages[i].page > pageNumber) {
          documentViewModel.pages[i].page -= 1;
        }
      }
    }
  }
  return { success: result.success, count: documentViewModel.count };
};
