import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { css, html } from 'lit';

import {
  createEncounterHistory,
  getInvoicesFromEncounters,
  startEncounterHistory,
} from '../../../../packages/neb-api-client/src/encounters-api-client';
import * as encounterApi from '../../../../packages/neb-api-client/src/encounters-api-client';
import { getLedgerInvoiceItems } from '../../../../packages/neb-api-client/src/invoice-api-client';
import {
  getLineItemDetails,
  savePayerInfo,
} from '../../../../packages/neb-api-client/src/ledger/line-items';
import { getPracticeSettings } from '../../../../packages/neb-api-client/src/services/practice-settings';
import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-popup/src/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { removeAllAllocations } from '../../../../packages/neb-utils/neb-ledger-util';
import { filterAuthorizationsFromCases } from '../../../../packages/neb-utils/patientAuthorization';
import { checkIfSignedEncounterModsChange } from '../../../api-clients/encounters';
import {
  formatPayerInformationToForm,
  formatPayerInformationToSave,
} from '../../../formatters/payer-information';
import { openError, openSuccess } from '../../../store';
import '../../forms/neb-form-billing-header';
import { fetchBillingHeaderInfo } from '../../../utils/billing-header-form/neb-billing-header-form-util';
import {
  WARNING_MULTIPLE_INVOICES_ASSOCIATED_TO_ENCOUNTER_WARNING_LEDGER_2,
  SINGLE_INVOICE_ASSOCIATED_TO_ENCOUNTER_WARNING_LEDGER_2,
  UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_SUCCESS,
  UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_ERROR,
} from '../../../utils/user-message';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: { id: 'form' },
};

class NebPopupBillingHeader extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      __loading: Boolean,
      __dropdownItems: Object,
      __carePackageWithInsuranceEnabled: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 600px;
          overflow-y: auto;
        }

        .content {
          overflow-y: auto;
          overflow-x: clip;
          flex: unset;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.title = 'Billing Information';
    this.model = {
      payerInformation: {
        billType: '',
        guarantor: '',
        guarantorId: null,
        payerName: 'Self',
        payerId: null,
        case: '',
        caseId: null,
        authorizationId: null,
        authorizationNumber: '',
        primaryInsurance: '',
        primaryInsuranceId: null,
        secondaryInsurance: '',
        secondaryInsuranceId: null,
        patientPackageName: '',
        patientPackageId: null,
      },
      invoiceId: '',
      invoiceNumber: '',
      lineItemId: '',
      patientId: '',
      encounterCharge: null,
      triggerSaveAndClose: false,
      multiCarePackageEnabled: false,
      hasRCMChangeSecondary: false,
    };

    this.__loading = false;

    this.__dropdownItems = {
      insurances: [],
      guarantors: [],
      cases: [],
      authorizations: [],
      packages: [],
    };

    this.__carePackageWithInsuranceEnabled = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      cancel: value => this.onClose(value || false),
      // eslint-disable-next-line complexity
      save: async updatedPayerInformation => {
        let updateSignedEncounters = false;

        const { invoiceId, patientId, multiCarePackageEnabled } = this.model;

        try {
          if (this.model.encounterCharge && this.model.invoiceNumber) {
            const associatedInvoices = this.model.encounterCharge.id
              ? await getInvoicesFromEncounters([this.model.encounterCharge.id])
              : [];

            const associatedInvoiceNumbers = associatedInvoices.map(
              ({ invoiceNumber }) => invoiceNumber,
            );

            const hasMultipleInvoices = associatedInvoiceNumbers.length > 1;

            const result = await openPopup(POPUP_RENDER_KEYS.DIALOG, {
              title: 'Warning',
              message: hasMultipleInvoices
                ? WARNING_MULTIPLE_INVOICES_ASSOCIATED_TO_ENCOUNTER_WARNING_LEDGER_2(
                    associatedInvoiceNumbers,
                  )
                : SINGLE_INVOICE_ASSOCIATED_TO_ENCOUNTER_WARNING_LEDGER_2(
                    this.model.invoiceNumber,
                  ),
              buttons: [
                { name: 'save', label: 'YES' },
                { name: 'discard', label: 'NO' },
              ],
            });

            if (result !== 'save') {
              return;
            }
          }

          const ledgerInvoiceItems = await getLedgerInvoiceItems(invoiceId);
          const lineItemIds = this.getLineItemIds(ledgerInvoiceItems.data);

          const originalPayerInformation = formatPayerInformationToForm(
            this.model.payerInformation,
          );

          const formattedPayerInformation = formatPayerInformationToSave({
            ...updatedPayerInformation,
            secondaryPayerPlanId: this.__getSecondaryPayerId(
              updatedPayerInformation,
            ),
            multiCarePackageEnabled,
          });

          if (
            originalPayerInformation.billType.id !==
              updatedPayerInformation.billType.id ||
            originalPayerInformation.payer.id !==
              updatedPayerInformation.payer.id ||
            originalPayerInformation.patientPackage.id !==
              updatedPayerInformation.patientPackage.id
          ) {
            const lineItemDetails = await getLineItemDetails(
              {},
              { lineItemIds },
            );

            const allocations = lineItemDetails.flatMap(curr =>
              curr.lineItemDebits.flatMap(lid => lid.debit.allocations),
            );

            if (allocations.length) {
              const result = await openPopup(POPUP_RENDER_KEYS.DIALOG, {
                title: 'Remove Allocations',
                message: [
                  'Changing the Bill Type or Payer will unallocate all payments for all charges associated to this invoice.',
                  'Are you sure that you want to continue?',
                ],
                buttons: [
                  { name: 'yes', label: 'Yes' },
                  { name: 'no', label: 'No' },
                ],
              });

              if (result !== 'yes') {
                return;
              }

              await removeAllAllocations(allocations, lineItemDetails);
            }
          }

          let signedEncounterModsChange;
          let signedEncounterIds;

          if (
            originalPayerInformation.payer.id !==
            updatedPayerInformation.payer.id
          ) {
            ({
              signedEncounterModsChange,
              encounterIds: signedEncounterIds,
            } = await checkIfSignedEncounterModsChange({
              ...(updatedPayerInformation.payer.id
                ? { primaryPayerId: updatedPayerInformation.payer.id }
                : {}),
              patientId: this.model.patientId,
              lineItemIds: [this.model.lineItemId],
            }));

            if (signedEncounterModsChange) {
              updateSignedEncounters = await openPopup(
                POPUP_RENDER_KEYS.CONFIRM,
                {
                  title: 'Signed Encounter Modifiers',
                  message:
                    'Editing the payer on this invoice will update modifiers on a signed encounter. Do you wish to proceed?',
                  confirmText: 'Yes',
                  cancelText: 'No',
                },
              );

              if (!updateSignedEncounters) return;

              await Promise.all(
                signedEncounterIds.map(id => startEncounterHistory(id, true)),
              );
            }
          }

          await savePayerInfo({
            patientId,
            invoiceId,
            lineItemIds,
            model: formattedPayerInformation,
            multiCarePackageEnabled,
          });

          if (signedEncounterModsChange) {
            await Promise.all(
              signedEncounterIds.map(id => createEncounterHistory(id, true)),
            );
          }

          openSuccess('Billing information saved successfully');

          this.__syncEncounterDateOfOnsetWithCase(
            updatedPayerInformation.case.id,
          );

          this.onClose(true);
        } catch (e) {
          console.error(e);
          openError('An error occured while saving billing information');
        }
      },
      keydown: e => {
        if (e.key === 'Escape') {
          this.handlers.cancel();
        }
      },
    };
  }

  async __syncEncounterDateOfOnsetWithCase(caseId) {
    if (!equal(this.model.payerInformation.caseId, caseId) && caseId) {
      try {
        if (this.model.invoiceId) {
          await encounterApi.updateBulkEncounterCurrentIllnessDateWithCaseDOO({
            invoiceId: this.model.invoiceId,
            patientCaseId: caseId,
          });
        } else {
          await encounterApi.updateEncounterCurrentIllnessDateWithCaseDOO(
            this.model.encounterCharge.encounterId,
            {
              patientCaseId: caseId,
            },
          );
        }

        await store.dispatch(
          openSuccess(UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_SUCCESS),
        );
      } catch (e) {
        console.error(e);
        await store.dispatch(
          openError(UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_ERROR),
        );
      }
    }
  }

  async __fetchSettings() {
    const practiceSettings = await getPracticeSettings();
    const { carePackageWithInsurance } = practiceSettings;

    this.__carePackageWithInsuranceEnabled = carePackageWithInsurance;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keydown', this.handlers.keydown);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('keydown', this.handlers.keydown);
  }

  getLineItemIds(invoiceItems) {
    return invoiceItems.length
      ? invoiceItems.map(item => item.id)
      : [this.model.lineItemId];
  }

  async __loadData() {
    try {
      this.__loading = true;

      const { guarantorId, caseId } = this.model.payerInformation;
      const [{ insurances, guarantors, cases, packages }] = await Promise.all([
        fetchBillingHeaderInfo(this.model.patientId, guarantorId, caseId),
        this.__fetchSettings(),
      ]);

      const casesFormattedForUtil = cases.map(({ data }) => data);

      this.__dropdownItems = {
        insurances,
        guarantors,
        cases,
        authorizations: filterAuthorizationsFromCases(
          casesFormattedForUtil,
        ).map(auth => ({ data: auth, label: auth.label })),
        packages,
      };
    } catch (e) {
      console.error(e);
      openError('An error occurred when retrieving billing information');
    } finally {
      this.__loading = false;
    }
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__loadData();
    }
  }

  __formatPayerInformation() {
    return formatPayerInformationToForm(this.model.payerInformation);
  }

  __getSecondaryPayerId(payerInfo) {
    if (this.model.hasRCMChangeSecondary) {
      const { secondaryInsurance } = payerInfo;
      const { insurances } = this.__dropdownItems;

      const insurance = insurances.find(
        ins => ins.data.id === secondaryInsurance.id,
      );

      return insurance ? insurance.data.payerPlanId : null;
    }

    return null;
  }

  __secondaryChanged(original, updated) {
    return (
      this.model.hasRCMChangeSecondary &&
      original.secondaryInsurance.id !== updated.secondaryInsurance.id
    );
  }

  renderContent() {
    return html`
      <neb-form-billing-header
        id="${ELEMENTS.form.id}"
        .model="${this.__formatPayerInformation()}"
        .invoiceNumber="${this.model.invoiceNumber}"
        .triggerSaveAndClose="${this.model.triggerSaveAndClose}"
        .onCancel="${this.handlers.cancel}"
        .onSave="${this.handlers.save}"
        .dropdownItems="${this.__dropdownItems}"
        .loading="${this.__loading}"
        .carePackageWithInsuranceEnabled="${
          this.__carePackageWithInsuranceEnabled
        }"
        .multiCarePackageEnabled="${this.model.multiCarePackageEnabled}"
      >
      </neb-form-billing-header>
    `;
  }
}

customElements.define('neb-popup-billing-header', NebPopupBillingHeader);
