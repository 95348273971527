import '../../../packages/neb-lit-components/src/components/controls/neb-menu-button';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/neb-moment-calendar-view';
import '../../../packages/neb-lit-components/src/components/controls/neb-pill-switch';
import './neb-icon';
import './neb-row-calendar';
import { css, html, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { fetchManyRooms } from '../../../packages/neb-api-client/src/rooms-api-client';
import { APPOINTMENT_STATUS } from '../../../packages/neb-appointment/neb-appointments';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { LocationsService } from '../../../packages/neb-redux/services/locations';
import { ProviderService } from '../../../packages/neb-redux/services/provider';
import { CALENDAR_TYPES_SWITCH_LABELS } from '../../../packages/neb-utils/calendar-resources-util';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../packages/neb-utils/formatters';
import { getUnmatchedAppointments } from '../../api-clients/appointments';
import { UpdateNotificationService } from '../../services/update-notifications';
import {
  CSS_COLOR_ERROR,
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../styles';
import { ANY } from '../../utils/update-notifications';
import NebFormAppointment from '../forms/appointments/neb-form-appointment';

import { ADD_OPTIONS, DATE_FORMAT } from './neb-scheduling-calendar';

export const ELEMENTS = {
  selectLocation: { id: 'select-location' },
  selectProvider: { id: 'select-provider' },
  selectRoom: { id: 'select-room' },
  selectStatuses: { id: 'select-statuses' },
  addMenuButton: { id: 'add-menu-button' },
  calendarSingleRow: { id: 'calendar-single-row' },
  calendarExpanded: { id: 'calendar-expanded' },
  iconDay: { id: 'icon-day' },
  iconAgenda: { id: 'icon-agenda' },
  unmatchedAppointmentsLink: { id: 'unmatched-appointments-link' },
  pillSwitch: { id: 'pill-switch' },
};

const ADD_NEW_APPOINTMENT_LABEL = 'Add New Appointment';

export const STATUS_ITEMS = [
  { label: APPOINTMENT_STATUS.ACTIVE, value: APPOINTMENT_STATUS.ACTIVE },
  {
    label: APPOINTMENT_STATUS.CHECKED_IN,
    value: APPOINTMENT_STATUS.CHECKED_IN,
  },
  {
    label: APPOINTMENT_STATUS.CHECKED_OUT,
    value: APPOINTMENT_STATUS.CHECKED_OUT,
  },
  { label: APPOINTMENT_STATUS.CANCELED, value: APPOINTMENT_STATUS.CANCELED },
  { label: APPOINTMENT_STATUS.NO_SHOW, value: APPOINTMENT_STATUS.NO_SHOW },
  { label: 'Rescheduled', value: 'Rescheduled' },
];

class NebCalendarHeaderMobile extends LitElement {
  static get properties() {
    return {
      displayAgendaView: Boolean,
      targetDate: String,
      __locations: Array,
      __providers: Array,
      __locationRooms: Array,
      __selectedLocation: Object,
      __selectedProvider: Object,
      __selectedRoom: Object,
      __unmatchedAppointmentCount: Number,
      __calendarExpanded: Boolean,
      __hideFilters: Boolean,
      displayProviderFilter: Boolean,
      statuses: Array,
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      :host([scheduling-error]) {
        z-index: 1;
      }

      .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }

      .container-actions {
        display: grid;
        place-content: center;
        align-items: center;
        justify-content: center;
        padding: ${CSS_SPACING};
        border-top: 1px solid ${CSS_COLOR_GREY_2};
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        row-gap: 20px;
      }

      .container-link {
        display: flex;
        align-items: center;
        align-self: center;
      }

      .select {
        display: flex;
      }

      .calendar-single-row {
        margin-top: 20px;
      }

      .calendar-expanded {
        width: 100%;
      }

      .button-container {
        display: grid;
        grid-template-columns: 1.5fr 2.5fr;
        column-gaps: ${CSS_SPACING};
        gap: 20px;
      }

      .menu {
        width: 115px;
        grid-area: 1 / 2 / 1 / 2;
        align-self: center;
      }

      .icon-arrow {
        width: 14px;
        height: 14px;
        margin-left: 10px;
        fill: ${CSS_COLOR_WHITE};
        user-select: none;
      }

      .icon-container {
        display: grid;
        column-gap: ${CSS_SPACING};
        grid-template-columns: 2fr 2fr;
        grid-template-areas:
          'location provider'
          '. button';
        grid-area: 2/ 1 / 2 / 1;
      }

      .menu[open] .icon-arrow {
        transform: rotate(180deg);
      }

      .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${CSS_SPACING};
      }

      .icon {
        display: flex;
        width: 30px;
        height: 30px;
        fill: ${CSS_COLOR_HIGHLIGHT};
        grid-area: icon;
        align-self: center;
        justify-self: center;
      }

      .icon-warning {
        width: 24px;
        height: 24px;
        padding-right: 5px;
        fill: ${CSS_COLOR_ERROR};
      }

      .icon-agenda {
        fill: ${CSS_COLOR_HIGHLIGHT};
        stroke: ${CSS_COLOR_HIGHLIGHT};
        stroke-width: 3px;
        grid-area: 1 / 1 / 1 / 1;
        place-self: center start;
        cursor: pointer;
        padding-right: 5px;
      }

      .icon-day {
        stroke: ${CSS_COLOR_HIGHLIGHT};
        fill: none;
        stroke-width: 3px;
        grid-area: 1 / 1 / 1 / 1;
        place-self: center start;
        cursor: pointer;
        padding-right: 5px;
      }

      .neb-pill-switch {
        display: flex;
        height: 40px;
      }

      .filter {
        display: flex;
        height: 100%;
        align-items: center;
        place-self: end;
        gap: 6px;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
      }

      .icon-arrow {
        fill: ${CSS_COLOR_HIGHLIGHT};
        height: 14px;
        width: 14px;
        transition: 250ms ease;
      }

      .rotate {
        transform: rotate(180deg);
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.targetDate = '';
    this.displayAgendaView = false;

    this.onChangeView = () => {};

    this.onSelectDate = () => {};

    this.onSelectLocation = () => {};

    this.onSelectProvider = () => {};

    this.onSelectRoom = () => {};

    this.onSelectStatuses = () => {};

    this.onUpdateCalendar = () => {};

    this.onToggleFilter = () => {};

    this.__allRooms = [];
    this.__locationRooms = [];
    this.__locations = [];
    this.__providers = [];
    this.__selectedLocation = {};
    this.__selectedProvider = {};
    this.__selectedRoom = {};
    this.__unmatchedAppointmentCount = 0;
    this.__calendarExpanded = false;
    this.__hideFilters = false;

    this.displayProviderFilter = true;
    this.statuses = [];
  }

  __initHandlers() {
    this.__handlers = {
      selectLocation: e => {
        if (
          e.event !== 'blur' &&
          e.event !== 'focus' &&
          this.__selectedLocation !== e.value
        ) {
          this.__selectedLocation = e.value;

          this.onSelectLocation(e.value);

          this.__setRoomsDropdown();
        }
      },
      selectProvider: e => {
        if (e.event !== 'blur' && e.event !== 'focus') {
          this.__selectedProvider = e.value;
          this.onSelectProvider(e.value);
        }
      },
      selectRoom: e => {
        if (e.event !== 'blur' && e.event !== 'focus') {
          this.__selectedRoom = e.value;
          this.onSelectRoom(e.value);
        }
      },
      selectStatuses: e => {
        if (e.event !== 'blur' && e.event !== 'focus') {
          this.onSelectStatuses(e.value);
        }
      },
      addOptionSelected: async selected => {
        if (selected.value.label === ADD_NEW_APPOINTMENT_LABEL) {
          await openOverlay(
            OVERLAY_KEYS.APPOINTMENT_FORM,
            NebFormAppointment.createModel(),
          );
        } else {
          await openOverlay(OVERLAY_KEYS.BLOCKED_OFF_TIME_FORM, {});
        }

        this.onUpdateCalendar();
      },
      clickUnmatchedAppointments: async () => {
        await openOverlay(OVERLAY_KEYS.UNMATCHED_APPOINTMENTS);
      },
      toggleViewIcon: () => {
        this.onChangeView();
      },
      toggleExpanded: () => {
        this.__calendarExpanded = !this.__calendarExpanded;
      },
      selectDate: date => {
        this.onSelectDate(date.format(DATE_FORMAT));
        this.__calendarExpanded = false;
      },
      todayClick: () => {
        this.onSelectDate(parseDate().format(DATE_FORMAT));
        this.__calendarExpanded = false;
      },
      toggleFilter: value => {
        this.onToggleFilter(value);
      },
      collapseFilters: () => {
        this.__hideFilters = !this.__hideFilters;
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__locations = userLocations
        .filter(item => item.active)
        .map(location => ({
          data: location,
          label: location.name,
        }));

      this.__selectedLocation = this.__locations[0];

      this.onSelectLocation(this.__selectedLocation);

      this.__setRoomsDropdown();
    });

    this.__providersService = new ProviderService(({ providers }) => {
      this.__providers = providers
        .filter(item => item.active)
        .map(provider => ({
          data: provider,
          label: objToName(provider.name, DEFAULT_NAME_OPTS),
        }));

      this.__selectedProvider = this.__providers[0];

      this.onSelectProvider(this.__selectedProvider);
    });

    this.__notificationService = new UpdateNotificationService({
      callback: () => this.__fetchUnmatchedAppointmentCount(),
      defaultQuery: { patient: ANY },
    });
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__providersService.connect();
    this.__notificationService.connect();

    this.__allRooms = await fetchManyRooms();

    this.__locationsService.connect();

    await this.__fetchUnmatchedAppointmentCount();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__providersService.disconnect();
    this.__notificationService.disconnect();

    try {
      this.__locationsService.disconnect();
    } catch (_) {
      // noop
    }
  }

  async __fetchUnmatchedAppointmentCount() {
    const { count } = await getUnmatchedAppointments({
      limit: 0,
      offset: 0,
      sortParams: 'asc',
    });

    this.__unmatchedAppointmentCount = count;
  }

  __setRoomsDropdown() {
    this.__locationRooms = this.__allRooms
      .filter(
        room =>
          room.active &&
          room.scheduleAvailable &&
          room.locationId === this.__selectedLocation.data.id,
      )
      .map(data => ({
        label: data.name,
        data,
      }));

    this.__selectedRoom = this.__locationRooms[0];

    this.onSelectRoom(this.__selectedRoom);
  }

  __renderIcons() {
    return !this.displayAgendaView
      ? html`
          <neb-icon
            id="${ELEMENTS.iconAgenda.id}"
            class="icon icon-agenda"
            icon="neb:agenda"
            @click="${this.__handlers.toggleViewIcon}"
          ></neb-icon>
        `
      : html`
          <neb-icon
            id="${ELEMENTS.iconDay.id}"
            class="icon icon-day"
            icon="neb:day"
            @click="${this.__handlers.toggleViewIcon}"
          ></neb-icon>
        `;
  }

  __renderUnmatchedAppointmentsLink() {
    return this.__unmatchedAppointmentCount
      ? html`
          <div class="container-link">
            <neb-icon class="icon icon-warning" icon="neb:warning"></neb-icon>
            <neb-text
              id="${ELEMENTS.unmatchedAppointmentsLink.id}"
              @click="${this.__handlers.clickUnmatchedAppointments}"
              link
              bold
              >Unmatched Appointments (${this.__unmatchedAppointmentCount})
            </neb-text>
          </div>
        `
      : '';
  }

  __renderCalendar() {
    return this.__calendarExpanded
      ? html`
          <neb-moment-calendar-view
            id="${ELEMENTS.calendarExpanded.id}"
            class="calendar-expanded"
            .selectedDate="${parseDate(this.targetDate)}"
            .onChange="${this.__handlers.selectDate}"
            .onLabelClicked="${this.__handlers.toggleExpanded}"
            .onTodaySelected="${this.__handlers.todayClick}"
            ?label-clickable="${true}"
          ></neb-moment-calendar-view>
        `
      : html`
          <neb-row-calendar
            id="${ELEMENTS.calendarSingleRow.id}"
            class="calendar-single-row"
            .targetDate="${parseDate(this.targetDate)}"
            .onLabelClicked="${this.__handlers.toggleExpanded}"
            .onSelectDate="${this.__handlers.selectDate}"
          ></neb-row-calendar>
        `;
  }

  __renderTypeFilter() {
    return this.displayProviderFilter
      ? html`
          <neb-select
            id="${ELEMENTS.selectProvider.id}"
            class="select"
            placeholder="No Provider Selected"
            .items="${this.__providers}"
            .value="${this.__selectedProvider}"
            .onChange="${this.__handlers.selectProvider}"
          ></neb-select>
        `
      : html`
          <neb-select
            id="${ELEMENTS.selectRoom.id}"
            class="select"
            placeholder="No Room Selected"
            .items="${this.__locationRooms}"
            .value="${this.__selectedRoom}"
            .onChange="${this.__handlers.selectRoom}"
          ></neb-select>
        `;
  }

  __renderFilters() {
    return !this.__hideFilters
      ? html`
          <div class="grid-container">
            <neb-pill-switch
              id="${ELEMENTS.pillSwitch.id}"
              class="neb-pill-switch"
              ?on="${this.displayProviderFilter}"
              .labels="${CALENDAR_TYPES_SWITCH_LABELS}"
              .onToggle="${this.__handlers.toggleFilter}"
            ></neb-pill-switch>

            <neb-select
              id="${ELEMENTS.selectLocation.id}"
              class="select select-location"
              placeholder="No Location Selected"
              .items="${this.__locations}"
              .value="${this.__selectedLocation}"
              .onChange="${this.__handlers.selectLocation}"
            ></neb-select>
          </div>

          <div class="grid-container">
            ${this.__renderTypeFilter()}

            <neb-select
              id="${ELEMENTS.selectStatuses.id}"
              class="select"
              placeholder="No Statuses Selected"
              allLabel="Statuses"
              name="statuses"
              .items="${STATUS_ITEMS}"
              .value="${this.statuses}"
              .onChange="${this.__handlers.selectStatuses}"
              multiSelect
              wrapText
            ></neb-select>
          </div>
        `
      : html``;
  }

  __renderFilterIcon() {
    const icon = {
      'icon-arrow': true,
      rotate: this.__hideFilters,
    };

    return html`
      <div class="filter" @click="${this.__handlers.collapseFilters}">
        <neb-icon class="${classMap(icon)}" icon="neb:chevron"></neb-icon>
        <span>Filters</span>
      </div>
    `;
  }

  __renderTypeSwitch() {
    return html`
      <neb-pill-switch
        id="${ELEMENTS.pillSwitch.id}"
        class="neb-pill-switch"
        ?on="${this.displayProviderFilter}"
        .labels="${CALENDAR_TYPES_SWITCH_LABELS}"
        .onToggle="${this.__handlers.toggleFilter}"
      ></neb-pill-switch>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderCalendar()}

        <div class="container-actions">
          <div class="grid-container">
            <div class="button-container">
              ${this.__renderIcons()}

              <neb-menu-button
                id="${ELEMENTS.addMenuButton.id}"
                class="menu"
                align="left"
                vertical-offset="45"
                label="Add"
                .items="${ADD_OPTIONS}"
                .onSelect="${this.__handlers.addOptionSelected}"
              ></neb-menu-button>
            </div>

            ${this.__renderFilterIcon()}
          </div>

          ${this.__renderFilters()} ${this.__renderUnmatchedAppointmentsLink()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-calendar-header-mobile', NebCalendarHeaderMobile);
