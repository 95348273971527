import ApiClient from '../utils/api-client-utils';

const apiClient = new ApiClient({
  microservice: 'electronic-claims',
  useTenant: true,
});

export const getReports = () =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'reports',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
    version: 2,
  });

export const getReport = id =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `reports/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
    version: 2,
  });

export const getRawReport = reportId =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `reports/${reportId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    version: 3,
  });

export const deleteReport = id =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `reports/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    version: 1,
  });

export const updateReport = (id, body) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `reports/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body,
    version: 2,
  });

export const parseReport = id =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `reports/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    version: 1,
  });

export const parseReports = () =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'reports',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    version: 1,
  });

export const getReportLogs = id =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `reports/${id}/logs`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
    version: 1,
  });
