import { css, LitElement } from 'lit';

import { connect, store } from '../../../neb-redux/neb-redux-store';
import { initializePendo, loadPendo } from '../utils/pendo';

const PAGE_TYPES = {
  BOOKING: 'booking',
  STANDARD: 'standard',
};

const accessors = {
  [PAGE_TYPES.BOOKING]: {
    getPracticeInfo: ({
      practiceInfoItems: [{ id: tenantId, name: practiceName, salesforceId }],
    }) => ({
      practiceName,
      salesforceId,
      tenantId,
    }),
    getPracticeInformationFromState: ({ practiceInfo }) => practiceInfo,
    getSessionInfo: ({
      item: {
        idToken: {
          payload: { email, family_name: lastName, given_name: firstName },
        },
      },
    }) => ({ email, firstName, lastName, role: 'patient' }),
    isPracticeInformationReady: ({ practiceInfoItems: [item] }) =>
      Boolean(item),
  },

  [PAGE_TYPES.STANDARD]: {
    getPracticeInfo: ({
      item: { id: tenantId, name: practiceName, salesforceId, tier, addOns },
    }) => ({ practiceName, salesforceId, tenantId, tier, addOns }),
    getPracticeInformationFromState: ({ practiceInformation }) =>
      practiceInformation,
    getSessionInfo: session => {
      const {
        item: {
          firstName,
          idToken: {
            payload: { email },
          },
          lastName,
          tenantId,
        },
      } = session;

      const {
        item: {
          access: {
            [tenantId]: { r: role },
          },
        },
      } = session;

      return {
        email,
        firstName,
        lastName,
        role,
      };
    },
    isPracticeInformationReady: ({ item }) => Boolean(item),
  },
};

class NebPendo extends connect(store)(LitElement) {
  static get properties() {
    return {
      initialized: Boolean,
      pageType: { reflect: true, type: String },
      practiceInformation: Object,
      session: Object,
    };
  }

  static get styles() {
    return css`
      :host {
        display: none;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.initialized = false;
    this.pageType = PAGE_TYPES.STANDARD;
    this.session = null;
  }

  connectedCallback() {
    super.connectedCallback();

    loadPendo();
  }

  _stateChanged(state) {
    const {
      [this.pageType]: { getPracticeInformationFromState },
    } = accessors;

    const { session } = state;

    this.practiceInformation = getPracticeInformationFromState(state);
    this.session = session;
  }

  shouldUpdate() {
    if (this.initialized) {
      return false;
    }

    const { isAuthenticated } = this.session;

    if (!isAuthenticated) {
      return false;
    }

    const {
      [this.pageType]: { isPracticeInformationReady },
    } = accessors;

    if (!isPracticeInformationReady(this.practiceInformation)) {
      return false;
    }

    return true;
  }

  update() {
    const {
      [this.pageType]: { getPracticeInfo, getSessionInfo },
    } = accessors;

    const { email, firstName, lastName, role } = getSessionInfo(this.session);

    const {
      practiceName,
      salesforceId,
      tenantId,
      tier,
      addOns,
    } = getPracticeInfo(this.practiceInformation);

    this.initialized = true;

    initializePendo({
      email,
      firstName,
      lastName,
      practiceName,
      role,
      salesforceId,
      tenantId,
      ...(tier && addOns && { tier, addOns }),
    });
  }
}

customElements.define('neb-pendo', NebPendo);

export { PAGE_TYPES };
