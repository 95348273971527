import ApiClient, { Method } from '../utils/api-client-utils';
import ApiClientV2 from '../utils/api-client-v2';

export const billingApiClient = new ApiClient({ microservice: 'billing' });
export const chartingApiClient = new ApiClientV2({
  microservice: 'charting',
});

const chargeUnits = units => (units ? parseInt(units, 10) : '');

export const mapToRaw = (charge, encounterId) => ({
  order: charge.order,
  modifier_1: charge.modifiers[0].length ? charge.modifiers[0] : null,
  modifier_2: charge.modifiers[1].length ? charge.modifiers[1] : null,
  modifier_3: charge.modifiers[2].length ? charge.modifiers[2] : null,
  modifier_4: charge.modifiers[3].length ? charge.modifiers[3] : null,
  units: chargeUnits(charge.units),
  feeScheduleName: charge.feeScheduleName,
  feeScheduleCharge: charge.feeScheduleCharge,
  allowedAmount: charge.allowedAmount,
  diagnosisPointers: charge.diagnosisPointers.map(cd => ({
    diagnosisCode: cd.code,
  })),
  chargeId: charge.chargeId,
  procedure: charge.procedure,
  description: charge.description,
  unitCharge: charge.unitCharge,
  EPSDTCode: charge.EPSDTCode,
  suppressFromClaim: charge.suppressFromClaim,
  billWithTreatmentInitiationDate: charge.billWithTreatmentInitiationDate,
  billWithXrayDate: charge.billWithXrayDate,
  encounterId: encounterId || charge.encounterId,
  id: charge.id,
  postedToLedgerId: charge.postedToLedgerId,
  taxName: charge.taxName,
  taxRate: charge.taxRate,
});

export const mapChargesToRaw = (charges, encounterId) =>
  charges.map(charge => mapToRaw(charge, encounterId));

export const getEncounterCharges = (
  encounterId,
  optOutLoadingIndicator = false,
) =>
  chartingApiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: '/api/v2/tenants/:tenantId/encounters/:encounterId/charges',
    replacements: { encounterId },
    optOutLoadingIndicator,
  });

export const getLineItemsWithAllocationDetails = postedToLedgerIds =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
      ...(postedToLedgerIds &&
        postedToLedgerIds.length && { postedToLedgerIds }),
    },
    method: Method.GET,
    path: 'encounter-charges/allocation-details',
    version: 1,
  });

export const postEncounterChargesToLedger = (
  encounterId,
  body,
  optOutLoadingIndicator = false,
) =>
  chartingApiClient.makeRequest({
    body: JSON.stringify(body),
    cacheKey: encounterId,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: '/api/v2/tenants/:tenantId/encounters/:encounterId/ledger',
    replacements: { encounterId },
    optOutLoadingIndicator,
  });

export const saveAndPostEncounterCharges = (encounterId, body) =>
  chartingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
    cacheKey: encounterId,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/encounters/:encounterId/charges',
    replacements: { encounterId },
  });

export const postUnpostedEncounterChargesToLedger = (encounterId, body) =>
  chartingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
    cacheKey: encounterId,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/encounters/:encounterId/unposted-charges',
    replacements: { encounterId },
  });
