import cheerio from 'cheerio';
import moment from 'moment-timezone';

import {
  fetchNote,
  saveNote,
  BLANK_LINE,
} from '../../../../../neb-api-client/src/notes';
import { getProviderRichTextFont } from '../../../../../neb-api-client/src/provider-rich-text-font';
import { TAB_KEYS } from '../../../../../neb-utils/macro-sets';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const FIGURE_KEYS = {
  CURRENT_LISTINGS: 'listings',
  PROBLEM_LIST: 'problemList',
  CURRENT_TREATMENT_PLAN: 'treatmentPlan',
};

const reformatDate = date =>
  date ? moment(date).format(DATE_FORMAT) : '&nbsp;';

const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const style = (content, styleAttr) =>
  styleAttr ? `<span ${styleAttr}>${content}</span>` : content;

const wrapFigureTable = (title, type, tableMarkup, footerMarkup, styleAttr) =>
  [
    ...(type === FIGURE_KEYS.CURRENT_TREATMENT_PLAN ? [BLANK_LINE] : []),
    `<p data-table-header="${type}">`,
    `<strong>${style(title, styleAttr)}</strong>`,
    '</p>',
    `<figure class="table" data-type="${type}"><table>${tableMarkup}</table></figure>`,
    footerMarkup || '',
  ].join('');

const buildTreatmentPlanTable = (data, styleAttr) => {
  const header = [
    '<tr>',
    `<td>${style('Start Date:', styleAttr)}</td>`,
    `<td>${style(reformatDate(data.startDate), styleAttr)}</td>`,
    `<td>${style('&nbsp;', styleAttr)}</td>`,
    `<td>${style('&nbsp;', styleAttr)}</td>`,
    '</tr>',
  ].join('');

  const body = data.treatmentPhases
    .map((t, index) => {
      const phaseNum = index + 1;

      const infoStr = [
        `${t.occurrence}`,
        `${t.frequency}${t.frequencyType}`,
        `${t.duration}${t.durationType}`,
      ].join(' x ');

      return [
        '<tr>',
        `<td>${style(`Phase ${phaseNum}:`, styleAttr)}</td>`,
        `<td>${style(infoStr, styleAttr)}</td>`,
        `<td>${style('Completed Date:', styleAttr)}</td>`,
        `<td>${style(reformatDate(t.completedDate), styleAttr)}</td>`,
        '</tr>',
      ].join('');
    })
    .join('');

  const footer = [
    '<tr>',
    `<td>${style('End Date:', styleAttr)}</td>`,
    `<td>${style(reformatDate(data.completedDate), styleAttr)}</td>`,
    `<td>${style('&nbsp;', styleAttr)}</td>`,
    `<td>${style('&nbsp;', styleAttr)}</td>`,
    '</tr>',
  ].join('');

  const footerMarkup = data.note
    ? [
        `<p data-table-footer="${FIGURE_KEYS.CURRENT_TREATMENT_PLAN}">`,
        `<strong>${style('Treatment Plan Notes', styleAttr)}</strong>`,
        '</p>',
        `<p data-table-footer="${FIGURE_KEYS.CURRENT_TREATMENT_PLAN}">`,
        style(data.note, styleAttr),
        '</p>',
      ].join('')
    : '';

  return [['<tbody>', header, body, footer, '</tbody>'].join(''), footerMarkup];
};

const buildListingTable = (data, styleAttr) => {
  const header = [
    '<thead><tr>',
    `<th>${style('Spine', styleAttr)}</th>`,
    `<th>${style('Technique', styleAttr)}</th>`,
    '</tr></thead>',
  ].join('');

  const body = data
    .map(item =>
      [
        '<tr>',
        `<td>${style(item.name, styleAttr)}</td>`,
        `<td>${style(item.issue, styleAttr)}</td>`,
        '</tr>',
      ].join(''),
    )
    .join('');

  return [[header, '<tbody>', body, '</tbody>'].join('')];
};

const buildProblemListTable = (data, styleAttr) => {
  const header = [
    '<thead>',
    '<tr>',
    `<th>${style('Diagnosis', styleAttr)}</th>`,
    `<th>${style('Description', styleAttr)}</th>`,
    `<th>${style('Type', styleAttr)}</th>`,
    `<th>${style('Status', styleAttr)}</th>`,
    `<th>${style('Onset Date', styleAttr)}</th>`,
    `<th>${style('Resolution Date', styleAttr)}</th>`,
    '</tr>',
    '</thead>',
  ].join('');

  const body = data
    .map(item =>
      [
        '<tr>',
        `<td>${style(item.code, styleAttr)}</td>`,
        `<td>${style(item.shortDescription, styleAttr)}</td>`,
        `<td>${style(capitalize(item.type), styleAttr)}</td>`,
        `<td>${style(capitalize(item.status), styleAttr)}</td>`,
        `<td>${style(reformatDate(item.onsetDate), styleAttr)}</td>`,
        '<td>',
        style(
          item.resolutionDate ? reformatDate(item.resolutionDate) : '&nbsp;',
          styleAttr,
        ),
        '</td>',
        '</tr>',
      ].join(''),
    )
    .join('');

  return [[header, '<tbody>', body, '</tbody>'].join('')];
};

export const TABLE_BUILDERS = {
  [FIGURE_KEYS.CURRENT_LISTINGS]: {
    title: 'Listings',
    section: TAB_KEYS.OBJECTIVE,
    builder: buildListingTable,
  },
  [FIGURE_KEYS.CURRENT_TREATMENT_PLAN]: {
    title: 'Treatment Plan',
    section: TAB_KEYS.PLAN,
    builder: buildTreatmentPlanTable,
  },
  [FIGURE_KEYS.PROBLEM_LIST]: {
    title: 'Problem List',
    section: TAB_KEYS.SUBJECTIVE,
    builder: buildProblemListTable,
  },
};

const insertOrReplaceTable = ($, table, tableType) => {
  if ($('body').find(`*[data-type="${tableType}"]`).length) {
    $(`p[data-table-header="${tableType}"],
      *[data-type="${tableType}"],
      p[data-table-footer="${tableType}"]`)
      .replaceWith(table)
      .html();
  } else if ($('body').find('*[data-type]').length) {
    $('*[data-type]')
      .after(table)
      .html();
  } else {
    $('body')
      .prepend(table)
      .html();
  }

  return $('body')
    .html()
    .replace(/&#xA0;/g, '&nbsp;')
    .replace(/&apos;/g, "'");
};

export const buildFigureTableMarkup = (
  title,
  builder,
  data,
  type,
  styleAttr,
) => {
  const [tableMarkup, footerMarkup] = builder(data, styleAttr);
  return wrapFigureTable(title, type, tableMarkup, footerMarkup, styleAttr);
};

const buildStyleAttr = ({ richTextFontFamily, richTextFontSize }) => {
  const fontFamily = richTextFontFamily.includes(' ')
    ? `'${richTextFontFamily}'`
    : richTextFontFamily;

  const fontSize = `${richTextFontSize}px`;

  return `style="font-family:${fontFamily};font-size:${fontSize};"`;
};

export const updateNotesWithTable = async (
  encounterId,
  data,
  tableType,
  providerId,
) => {
  const { title, section, builder } = TABLE_BUILDERS[tableType];

  const [font, notes] = await Promise.all([
    getProviderRichTextFont(providerId),
    fetchNote(section, encounterId),
  ]);

  const styleAttr = buildStyleAttr(font);
  const tableMarkup = buildFigureTableMarkup(
    title,
    builder,
    data,
    tableType,
    styleAttr,
  );

  const html = cheerio.load(notes);
  const updatedNotes = insertOrReplaceTable(html, html(tableMarkup), tableType);

  return saveNote(encounterId, section, updatedNotes);
};
