import './neb-attorney-item';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../styles';

export const ELEMENTS = {
  addButton: {
    id: 'button-add',
  },
  items: {
    idPrefix: 'item',
    get selector() {
      return `[id|=${this.idPrefix}]`;
    },
  },
};

class NebAttorneyList extends LitElement {
  static get properties() {
    return {
      name: String,
      label: String,
      model: Array,
      errors: Array,
      organizations: Array,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.name = '';
    this.label = '';
    this.model = [];
    this.errors = [];
    this.organizations = [];

    this.onAdd = () => {};

    this.onRemove = () => {};

    this.onChange = () => {};

    this.onChangeOrganization = () => {};

    this.onSearchOrganization = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      add: () => {
        this.errors = this.model.map(({ name, type, organizationId }) => ({
          ...this.errors,
          name: !name ? 'Required' : '',
          type: !type ? 'Required' : '',
          organizationId: !organizationId ? 'Required' : '',
        }));

        const proceed = this.errors.every(
          ({ name, type, organizationId }) => !name && !type && !organizationId,
        );

        if (proceed) this.onAdd(this.name);
      },
      remove: index => this.onRemove(this.name, Number(index)),
      change: (name, value) =>
        this.onChange({ name: `${this.name}.${name}`, value }),
      searchOrganization: ({ value }) => this.onSearchOrganization({ value }),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
        }

        .button-add {
          align-self: flex-start;
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="container">
        ${
          this.model.map(
            (item, index) => html`
              <neb-attorney-item
                id="${ELEMENTS.items.idPrefix}-${index}"
                .name="${index}"
                .model="${item}"
                .errors="${this.errors[index] || ''}"
                .onRemove="${this.__handlers.remove}"
                .onChange="${this.__handlers.change}"
                .organizations="${this.organizations}"
                .onChangeOrganization="${this.__handlers.change}"
                .onSearchOrganization="${this.__handlers.searchOrganization}"
                ?showRemoveButton="${this.model.length > 1}"
                ?showRequiredField="${this.model.length > 1}"
              ></neb-attorney-item>
            `,
          )
        }

        <neb-button-action
          id="${ELEMENTS.addButton.id}"
          class="button-add"
          .label="${this.label}"
          .onClick="${this.__handlers.add}"
        ></neb-button-action>
      </div>
    `;
  }
}

window.customElements.define('neb-attorney-list', NebAttorneyList);
