import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../../packages/neb-styles/neb-variables';
import { REFERRING_PROVIDER_QUALIFIERS } from '../../../../packages/neb-utils/claims';
import { number } from '../../../../packages/neb-utils/masks';
import * as selectors from '../../../../packages/neb-utils/selectors';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  sendButton: { id: 'send-button' },
  cancelButton: { id: 'cancel-button' },
  textOrderingProviderFirstName: {
    id: 'orderingProviderFirstName',
  },
  textOrderingProviderMiddleName: {
    id: 'orderingProviderMiddleName',
  },
  textOrderingProviderLastName: {
    id: 'orderingProviderLastName',
  },
  textOrderingProviderCredentials: {
    id: 'orderingProviderCredentials',
  },
  textOrderingProviderOtherId: {
    id: 'textOrderingProviderOtherId',
  },
  orderingProviderQualifierDropdown: {
    id: 'orderingProviderQualifierDropdown',
  },
  textOrderingProviderNPI: {
    id: 'orderingProviderNPI',
  },
};

export const FORMATTED_REFERRING_PROVIDER_QUALIFIERS = REFERRING_PROVIDER_QUALIFIERS.map(
  ({ value, label }) => ({
    label,
    data: { id: value },
  }),
);

const invalidNPI = providerNPI => providerNPI && providerNPI.length !== 10;

export class NebFormOrderingProvider extends NebForm {
  static get properties() {
    return {
      ...super.properties,
    };
  }

  constructor() {
    super();
    this.initHandlers();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: ({ name, value }) => {
        this.formService.apply(name, value || null);
        this.formService.validate();
      },
      confirm: () => {
        if (this.formService.validate()) this.handlers.save();
      },
    };
  }

  static createModel() {
    return {
      orderingProviderCredentials: null,
      orderingProviderFirstName: null,
      orderingProviderLastName: null,
      orderingProviderMiddleName: null,
      orderingProviderNPI: null,
      orderingProviderOtherId: null,
      orderingProviderQualifier: selectors.ITEM_EMPTY,
    };
  }

  createSelectors() {
    return {
      children: {
        orderingProviderQualifier: selectors.select(
          FORMATTED_REFERRING_PROVIDER_QUALIFIERS,
          this.model.orderingProviderQualifier,
        ),
        orderingProviderFirstName: [isRequired()],
        orderingProviderLastName: [isRequired()],
        orderingProviderNPI: {
          validators: [
            {
              error: 'Must be 10 characters',
              validate: _ => !invalidNPI(this.state.orderingProviderNPI),
            },
          ],
        },
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow-y: unset;
          min-height: unset;
        }

        .container-radio {
          display: flex;
          align-items: baseline;
        }

        .spacing {
          margin-right: ${CSS_SPACING};
        }

        .buttons-container {
          margin-top: 30px;
          display: flex;
        }

        .add-button {
          padding-right: 10px;
        }

        .input {
          flex: 1 0 0px;
        }

        .grid-ordering-provider-fields {
          display: grid;
          width: 100%;
          column-gap: ${CSS_SPACING};
          row-gap: ${CSS_SPACING_ROW};
          grid-template-columns: 1fr 1fr;
        }
      `,
    ];
  }

  renderActionBar() {
    return html`
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.sendButton.id}"
          class="button add-button"
          label="DONE"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.confirm}"
          unelevated
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button"
          label="CANCEL"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="grid-ordering-provider-fields">
        <div class="grid">
          <neb-textfield
            id="${ELEMENTS.textOrderingProviderFirstName.id}"
            name="orderingProviderFirstName"
            label="Ordering Provider First Name"
            helper="Required"
            .value="${this.state.orderingProviderFirstName}"
            .error="${this.errors.orderingProviderFirstName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>

        <div class="grid">
          <neb-textfield
            id="${ELEMENTS.textOrderingProviderMiddleName.id}"
            name="orderingProviderMiddleName"
            label="Ordering Provider Middle Name"
            .value="${this.state.orderingProviderMiddleName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>

        <div class="grid">
          <neb-textfield
            id="${ELEMENTS.textOrderingProviderLastName.id}"
            name="orderingProviderLastName"
            label="Ordering Provider Last Name"
            helper="Required"
            .value="${this.state.orderingProviderLastName}"
            .error="${this.errors.orderingProviderLastName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>

        <div class="grid">
          <neb-textfield
            id="${ELEMENTS.textOrderingProviderCredentials.id}"
            name="orderingProviderCredentials"
            label="Ordering Provider Credentials"
            .value="${this.state.orderingProviderCredentials}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>

        <div class="grid">
          <neb-textfield
            id="${ELEMENTS.textOrderingProviderOtherId.id}"
            name="orderingProviderOtherId"
            label="Ordering Provider Other ID"
            maxLength="17"
            .value="${this.state.orderingProviderOtherId}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>

        <div class="grid">
          <neb-select
            id="${ELEMENTS.orderingProviderQualifierDropdown.id}"
            name="orderingProviderQualifier"
            label="Ordering Provider Qualifier"
            .items="${
              [selectors.ITEM_EMPTY, ...FORMATTED_REFERRING_PROVIDER_QUALIFIERS]
            }"
            .value="${this.state.orderingProviderQualifier}"
            .onChange="${this.handlers.change}"
          ></neb-select>
        </div>

        <div class="grid">
          <neb-textfield
            id="${ELEMENTS.textOrderingProviderNPI.id}"
            name="orderingProviderNPI"
            label="Ordering Provider NPI"
            maxLength="10"
            .error="${this.errors.orderingProviderNPI}"
            helper="${
              invalidNPI(this.state.orderingProviderNPI)
                ? 'Must be 10 characters'
                : ''
            }"
            .mask="${number}"
            .value="${this.state.orderingProviderNPI}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-form-ordering-provider', NebFormOrderingProvider);
