import '../../neb-lit-components/src/components/inputs/neb-select';
import '../../neb-lit-components/src/components/neb-button';

import { html, css } from 'lit';

import {
  fetchAllQuestionnairesByProvider,
  saveBulkQuestionnaires,
} from '../../../src/api-clients/questionnaires';
import { openError, openSuccess } from '../../neb-dialog/neb-banner-state';
import { store } from '../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../neb-styles/neb-variables';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const COPY_QUESTIONNAIRE_BANNER = {
  success: 'Questionnaire copied successfully',
  error: 'Unable to copy questionnaire',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  closeButton: {
    id: 'close-button',
  },

  copyQuestionnaireButton: {
    id: 'copy-questionnaire-button',
  },

  selectedProvider: {
    id: 'selected-provider',
  },

  selectedAppointmentTypes: {
    id: 'selected-appointment-types',
  },
};

class NebPopupSubjectiveQuestionnaireProviderCopy extends NebPopup {
  static get properties() {
    return {
      selectedProvider: Object,
      selectedAppointmentTypes: Array,
      appointmentTypeItems: Array,
      providerItems: Array,
    };
  }

  initState() {
    super.initState();

    this.title = 'Copy Existing Questionnaires';
    this.selectedProvider = null;
    this.providerItems = [];
    this.selectedAppointmentTypes = [];
    this.appointmentTypeItems = [];
    this.providerQuestionnaires = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeProvider: async ({ value }) => {
        if (value !== this.selectedProvider) {
          this.selectedProvider = value;

          await this.__updateAvailableAppointmentTypes();
          this.selectedAppointmentTypes = this.appointmentTypeItems;
        }
      },
      changeAppointmentTypeList: ({ value }) => {
        this.selectedAppointmentTypes = value;
      },
      save: () => {
        this.__saveQuestionnaireCopy();
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 480px;
        }

        .header {
          margin-bottom: 20px;
        }

        .content {
          margin-top: 0px;
        }

        .drop-downs {
          display: grid;
          gap: 20px;
        }

        .button-copy {
          margin-top: ${CSS_SPACING};
          margin-right: 10px;
        }
      `,
    ];
  }

  modelChanged() {
    this.providerItems = [...this.model.providerItems];
    const index = this.providerItems.indexOf(this.model.currentProvider);
    if (index > -1) this.providerItems.splice(index, 1);
  }

  async __updateAvailableAppointmentTypes() {
    this.providerQuestionnaires = await fetchAllQuestionnairesByProvider(
      this.selectedProvider.id,
    );

    this.appointmentTypeItems = this.model.currentAppointmentTypeItems.filter(
      ({ id }) =>
        this.providerQuestionnaires.find(
          questionnaire =>
            questionnaire.appointmentTypeId === id &&
            questionnaire.questions.length,
        ),
    );
  }

  async __saveQuestionnaireCopy() {
    const questionnairesToCopy = this.providerQuestionnaires.filter(
      ({ appointmentTypeId }) =>
        this.selectedAppointmentTypes.find(
          ({ id }) => appointmentTypeId === id,
        ),
    );

    const questionnaireIds = questionnairesToCopy.map(({ id }) => id);

    try {
      await saveBulkQuestionnaires(this.model.currentProvider.id, {
        questionnaireIds,
      });

      store.dispatch(openSuccess(COPY_QUESTIONNAIRE_BANNER.success));
      this.onClose(true);
    } catch (e) {
      store.dispatch(openError(COPY_QUESTIONNAIRE_BANNER.error));
    }
  }

  hasMutualAppointmentTypes() {
    return this.appointmentTypeItems.length > 0 || !this.selectedProvider;
  }

  renderContent() {
    return html`
      <div class="drop-downs">
        <neb-select
          id="${ELEMENTS.selectedProvider.id}"
          label="Provider"
          .value="${this.selectedProvider}"
          .items="${this.providerItems}"
          .onChange="${this.handlers.changeProvider}"
        ></neb-select>

        <neb-select
          id="${ELEMENTS.selectedAppointmentTypes.id}"
          .label="${
            this.hasMutualAppointmentTypes()
              ? 'Appointment Type'
              : 'No Mutual Appointment Types'
          }"
          allLabel="Appointments"
          .value="${this.selectedAppointmentTypes}"
          .items="${this.appointmentTypeItems}"
          .onChange="${this.handlers.changeAppointmentTypeList}"
          .disabled="${!this.hasMutualAppointmentTypes()}"
          multiSelect
        ></neb-select>
      </div>
      <neb-button
        id="${ELEMENTS.copyQuestionnaireButton.id}"
        label="Copy"
        class="button-copy"
        .onClick="${this.handlers.save}"
        ?disabled="${
          !this.selectedProvider || this.selectedAppointmentTypes.length <= 0
        }"
      ></neb-button>
      <neb-button
        id="${ELEMENTS.closeButton.id}"
        label="Cancel"
        class="button-close"
        .onClick="${this.handlers.cancel}"
      ></neb-button>
    `;
  }
}

customElements.define(
  'neb-popup-subjective-questionnaire-provider-copy',
  NebPopupSubjectiveQuestionnaireProviderCopy,
);
