import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';

export const DARK_COLORS = [
  '#5596E6',
  '#225091',
  '#41D6C3',
  '#185A63',
  '#8CD211',
  '#AF6EE8',
  '#7E3B8A',
  '#FF3CA0',
  '#FF5050',
  '#811E1E',
  '#FF7832',
  '#959595',
];

export const COLORS = [
  '#C0E6FF',
  '#7CC7FF',
  '#5596E6',
  '#C8FCEF',
  '#6EEDD8',
  '#41D6C3',
  '#D2F2A4',
  '#B4E051',
  '#8CD211',
  '#EED2FF',
  '#D7AAFF',
  '#AF6EE8',
  '#FFD2FF',
  '#FF9EEE',
  '#FF3CA0',
  '#FFD2DD',
  '#FF7D87',
  '#FF5050',
  '#FFEED2',
  '#FFA573',
  '#FF7832',
  '#FFFFFF',
  '#DEDFE0',
  '#959595',
];

export const ROW_LAYOUT = {
  SINGLE_ROW: 'single-row',
  MULTI_ROW: 'multi-row',
};

export const ELEMENTS = {
  label: { id: 'label' },
  popover: { id: 'popover' },
  picker: { id: 'picker' },
  colors: { selector: "[id^='color-']" },
};

class ColorPicker extends LitElement {
  static get properties() {
    return {
      __open: {
        reflect: true,
        type: Boolean,
        attribute: 'open',
      },
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },
      name: String,
      label: Number,
      value: String,
      disabled: {
        reflect: true,
        type: Boolean,
      },
      colorPalette: {
        reflect: true,
        type: Array,
      },
      rowLayout: {
        reflect: true,
        type: String,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          width: min-content;
        }

        .container {
          display: flex;
          position: relative;
          align-items: center;
        }

        .label {
          margin-right: 8px;
        }

        .icon {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_BLACK};
        }

        :host([focused]) .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .picker {
          display: flex;
          cursor: pointer;
          width: 40px;
          height: 40px;
          border: 1px solid ${CSS_COLOR_BLACK};
          border-radius: 4px;
          background-color: ${CSS_COLOR_WHITE};
          align-items: center;
          justify-content: center;
        }

        :host([focused]) .picker {
          border: 1px solid ${CSS_COLOR_HIGHLIGHT};
        }

        .popover {
          display: grid;
          cursor: pointer;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          grid-auto-flow: column;
          border-radius: 4px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
            0 3px 6px rgba(0, 0, 0, 0.23);
          z-index: 1000;
        }

        .single-row {
          grid-template-rows: 40px;
        }

        .multi-row {
          grid-template-rows: repeat(3, 40px);
        }

        .cell {
          width: 40px;
          height: 40px;
          transition: transform 200ms;
        }

        .cell:hover {
          transform: scale(1.2);
          z-index: 1;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__open = false;
    this.__focused = false;
    this.disabled = false;
    this.colorPalette = COLORS;
    this.rowLayout = ROW_LAYOUT.MULTI_ROW;
    this.value = '#0CAADC';
    this.label = '';
    this.name = '';

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      open: () => {
        this.__open = true;
      },
      select: e => {
        e.stopPropagation();
        this.__open = false;
        this.onChange({
          name: this.name,
          value: e.currentTarget.color,
        });
      },
      focus: () => {
        if (!this.disabled) {
          this.__focused = true;
          this.onChange({
            name: this.name,
            value: this.value,
          });
        }
      },
      blur: () => {
        if (!this.disabled) {
          this.__open = false;
          this.__focused = false;
          this.onChange({
            name: this.name,
            value: this.value,
          });
        }
      },
      keydown: e => {
        if (!this.disabled) {
          switch (e.key) {
            case 'Enter':
            case ' ':
              this.__open = !this.__open;
              break;

            case 'Escape':
              this.__open = false;
              break;

            default:
          }
        }
      },
    };
  }

  renderLabel() {
    return this.label
      ? html`
          <span id="${ELEMENTS.label.id}" class="label">${this.label}</span>
        `
      : '';
  }

  renderPopover() {
    return this.__open
      ? html`
          <div id="${ELEMENTS.popover.id}" class="popover ${this.rowLayout}">
            ${
              this.colorPalette.map(
                (color, index) => html`
                  <div
                    id="color-${index}"
                    class="cell"
                    style="background-color: ${color};"
                    .color="${color}"
                    @click="${this.__handlers.select}"
                  ></div>
                `,
              )
            }
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        ${this.renderLabel()}

        <div
          id="${ELEMENTS.picker.id}"
          tabindex="0"
          class="picker"
          style="background-color: ${this.value}"
          @blur="${this.__handlers.blur}"
          @focus="${this.__handlers.focus}"
          @click="${this.__handlers.open}"
          @keydown="${this.__handlers.keydown}"
        >
          <neb-icon class="icon" icon="neb:colorPicker"></neb-icon>

          ${this.renderPopover()}
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-picker-color', ColorPicker);
