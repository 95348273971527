import '../../../../packages/neb-lit-components/src/components/neb-dropdown-menu';
import '../../misc/neb-icon';
import { LitElement, html, css } from 'lit';

import {
  computeTime,
  FULL_DATE_FORMAT,
} from '../../../../packages/neb-input/nebFormatUtils';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { createOccurrenceNarrative } from '../../../../packages/neb-utils/recurring';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../styles';

export const ELEMENTS = {
  createdDate: {
    id: 'created-date',
  },
  createdTime: {
    id: 'created-time',
  },
  dateField: {
    id: 'blocked-off-time-date',
  },
  dropdownOptions: {
    id: 'dropdown-options',
  },
  iconClose: {
    id: 'icon-close',
  },
  nameField: {
    id: 'blocked-off-time-name',
  },
  noteField: {
    id: 'blocked-off-time-note',
  },
  names: {
    selector: '.name-container > p',
  },
  recurringNarrative: {
    id: 'recurring-status',
  },
  scheduledNarrative: {
    id: 'scheduled-narrative',
  },
  timeDurationField: {
    id: 'blocked-off-time-time',
  },
  locationField: {
    id: 'blocked-off-time-location',
  },
  providerField: {
    id: 'blocked-off-time-provider',
  },
  scheduledNamesContainer: { id: 'scheduled-names-container' },
};

const TEXT_HEADER = 'Update Blocked Off Time';
const APPOINTMENT_RECURRING_HEADER = 'Blocked Off Time Status';

class NebBlockedOffTimePage extends LitElement {
  static get properties() {
    return {
      model: Object,
      allLocations: Object,
      providers: Object,
      __groupProviderNames: Array,
      __menuItems: Array,
      __date: String,
      __duration: String,
      __locationName: String,
      __time: String,
    };
  }

  static get styles() {
    return css`
      :host {
        width: 100%;
        height: 100%;
      }

      .container {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;
        padding: ${CSS_SPACING};
      }

      .menu-container {
        cursor: pointer;
      }

      .menu-label {
        display: flex;
        align-items: center;
      }

      .label {
        font-size: 14px;
        font-weight: bold;
        color: ${CSS_COLOR_HIGHLIGHT};
        margin-right: 5px;
      }

      .action-menu {
        position: absolute;
      }

      .child-container {
        padding-top: ${CSS_SPACING};
      }

      .container-action-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .icon {
        cursor: pointer;
        width: 24px;
        height: 24px;

        fill: ${CSS_COLOR_GREY_1};
      }

      .icon-arrow {
        width: 12px;
        height: 12px;
        fill: ${CSS_COLOR_HIGHLIGHT};
      }

      .section-text {
        margin: 0;
        font-size: 14px;
      }

      .section-header {
        margin: 0;
        font-size: ${CSS_FONT_SIZE_HEADER};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }

      .text-note {
        margin-bottom: 5px;
      }

      .blocked-off-time-note {
        display: flex;

        width: 95%;
        min-height: 80px;
        padding: 10px;

        border: 1px solid ${CSS_COLOR_GREY_2};
        border-radius: 5px;
        word-break: break-word;
      }

      .action-item {
        font-weight: 200;
      }

      .container-recurring-narrative {
        display: flex;
        padding-top: 15px;
      }

      .recurring-narrative {
        padding-left: 5px;
        font-size: 14px;
      }

      .recurring-icon {
        cursor: default;
        min-width: 24px;
        width: 24px;
        height: 24px;
        padding-right: 0;

        fill: ${CSS_COLOR_HIGHLIGHT};
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = null;
    this.providers = {};
    this.allLocations = {};

    this.__menuItems = [];
    this.__groupProviderNames = [];
    this.__date = '';
    this.__duration = '';
    this.__locationName = '';
    this.__time = '';

    this.appointmentId = '';

    this.onDismiss = () => {};

    this.onEdit = () => {};

    this.onDelete = () => {};

    this.onDeleteEntireSeries = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      cancel: () => this.onDismiss(),
      edit: () => this.onEdit(),
      delete: () => this.onDelete(),
      deleteEntireSeries: () => this.onDeleteEntireSeries(),
    };
  }

  updated(changedProps) {
    if (changedProps.has('model') && this.model) {
      const start = parseDate(this.model.start);
      const end = parseDate(this.model.end);

      this.__date = start.format(FULL_DATE_FORMAT);

      this.__time = `${start.format('h:mm A')} - ${end.format('h:mm A')}`;

      this.__duration = computeTime(end.diff(start));

      this.__locationName = this.__getLocationName();

      if (!this.model.recurrenceEventId) {
        this.__groupProviderNames = this.__getGroupProviderNames();
      }

      this.__initMenuItems();
    }

    if (changedProps.has('__allLocations') && this.model) {
      this.__locationName = this.__getLocationName();
    }
  }

  __initMenuItems() {
    this.__menuItems = [
      {
        label: 'Edit',
        onSelect: this.__handlers.edit,
      },
      {
        label: 'Delete',
        onSelect: this.__handlers.delete,
      },
    ];

    if (
      this.model.recurrenceEventId &&
      !(
        this.model.recurrenceEvent &&
        parseDate(this.model.start).isBefore(parseDate())
      )
    ) {
      this.__menuItems.push({
        label: 'Delete Entire Series',
        onSelect: this.__handlers.deleteEntireSeries,
      });
    }
  }

  __getGroupProviderNames() {
    return this.model.groupProviderIds.map(providerId =>
      this.__getProviderName(providerId),
    );
  }

  __getProviderName(providerId) {
    const provider = this.providers[providerId];

    return html`
      ${provider.lastName}, ${provider.firstName} <br />
    `;
  }

  __getLocationName() {
    const location = this.allLocations[this.model.locationId];

    return location ? location.name : '';
  }

  __renderLocation() {
    return this.__locationName
      ? html`
          <p id="${ELEMENTS.locationField.id}" class="section-header">
            ${this.__locationName}
          </p>
        `
      : '';
  }

  __renderProvider() {
    return !this.model.recurrenceEventId
      ? html`
          <p id="${ELEMENTS.providerField.id}" class="section-text">
            ${this.__groupProviderNames}
          </p>
        `
      : this.__getProviderName(this.model.providerId);
  }

  __renderRoom() {
    return !this.model.recurrenceEventId
      ? html`
          <p id="${ELEMENTS.providerField.id}" class="section-text">
            ${
              this.model.resourceNames.map(
                r => html`
                  ${r} <br />
                `,
              )
            }
          </p>
        `
      : html`
          ${this.model.resourceNames[0]} <br />
        `;
  }

  __renderScheduledNarrative() {
    const createdAt = parseDate(this.model.createdAt);

    return html`
      <div
        class="container-scheduled-narrative"
        id="${ELEMENTS.scheduledNarrative.id}"
      >
        Scheduled on
        <span id="${ELEMENTS.createdDate.id}">
          ${createdAt.format(FULL_DATE_FORMAT)}
        </span>
        at
        <span id="${ELEMENTS.createdTime.id}">
          ${createdAt.format('h:mm A')}
        </span>
      </div>
    `;
  }

  __renderRecurringNarrative() {
    const recurrenceModel = this.model.recurrenceEvent || this.model;

    return recurrenceModel.rrule && recurrenceModel.firstOccurrence
      ? html`
          <div class="container-recurring-narrative">
            <neb-icon class="recurring-icon" icon="neb:loop"></neb-icon>
            <div
              id="${ELEMENTS.recurringNarrative.id}"
              class="recurring-narrative"
            >
              ${
                createOccurrenceNarrative(
                  recurrenceModel.rrule,
                  recurrenceModel.firstOccurrence,
                )
              }
            </div>
          </div>
        `
      : '';
  }

  render() {
    return this.model
      ? html`
          <div class="container">
            <div class="container-action-buttons">
              <neb-dropdown-menu
                id="${ELEMENTS.dropdownOptions.id}"
                label="${TEXT_HEADER}"
                .items="${this.__menuItems}"
                showFullText
              ></neb-dropdown-menu>

              <neb-icon
                id="${ELEMENTS.iconClose.id}"
                class="icon icon-close"
                icon="neb:close"
                @click="${this.__handlers.cancel}"
              ></neb-icon>
            </div>

            <div id="divider"></div>

            <div class="child-container container-date-time">
              <p id="${ELEMENTS.dateField.id}" class="section-header">
                ${this.__date}
              </p>

              <p id="${ELEMENTS.timeDurationField.id}" class="section-header">
                ${this.__time} (${this.__duration})
              </p>
              ${this.__renderLocation()}
            </div>

            <div class="child-container container-location-name">
              <p id="${ELEMENTS.nameField.id}" class="section-header">
                ${this.model.name}
              </p>

              <div class="name-container">
              <p id="${
                ELEMENTS.scheduledNamesContainer.id
              }" class="section-text">
                ${
                  this.model.providerId
                    ? this.__renderProvider()
                    : this.__renderRoom()
                }
              </p>
              </div>
            </div>

            <div class="blocked-off-time-status-container">
              <div class="child-container section-header">
                ${APPOINTMENT_RECURRING_HEADER}
              </div>
              ${this.__renderScheduledNarrative()}
              ${this.__renderRecurringNarrative()}
              </div>

              <div class="child-container container-note">
                <p class="text-note">Note</p>

                <div
                  id="${ELEMENTS.noteField.id}"
                  class="blocked-off-time-note"
                >
                  ${this.model.note ||
                    'This blocked off time does not have a note.'}
                </div>
              </div>
            </div>
          </div>
        `
      : '';
  }
}

customElements.define('neb-blocked-off-time-page', NebBlockedOffTimePage);
