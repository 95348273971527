import '../../../../packages/neb-lit-components/src/components/neb-popup-header';

import { html, css } from 'lit';

import {
  DISPLAY_ON,
  createBannerController,
} from '../../../../packages/neb-alert/components/neb-alert-banner-controller';
import NebOverlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { getEob } from '../../../api-clients/eob';
import {
  put as updatePayerPayment,
  post as savePayerPayment,
  get as getPayerPayment,
} from '../../../api-clients/payer-payment';
import { openError, openSuccess } from '../../../store';
import { PAYMENT_TYPE } from '../../../utils/payment-associations-util';
import {
  EOB_SAVE_ERROR,
  EOB_CREATE_SUCCESSFUL,
  EOB_UPDATE_SUCCESSFUL,
} from '../../../utils/user-message';
import NebFormEob from '../../forms/neb-form-eob';
import { NebFormPayment } from '../../forms/neb-form-payment';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
  formPayment: { id: 'form-payment' },
  description: { id: 'description' },
};

const DESCRIPTION = patientId =>
  `Select and enter EOB details to post against the ${
    patientId ? "patient's " : ''
  }ledger.`;

class NebOverlayEOB extends NebOverlay {
  static get properties() {
    return {
      __saveError: Object,
      __formModel: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header-container {
          padding: ${CSS_SPACING};
        }

        .content {
          display: grid;
          width: 880px;
          grid-template-rows: auto 1fr;
          grid-template-columns: 1fr;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__alertBanner = createBannerController(DISPLAY_ON.payment);
    this.__formModel = {
      ...NebFormEob.createModel(),
      ...NebFormPayment.createModel(),
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: async model => {
        if (this.model.isLegacy) {
          this.onClose();
          return;
        }

        try {
          const formattedPayment = {
            ...model,
            patientId: this.model.patientId,
          };
          const payerPayment = model.id
            ? await updatePayerPayment(model.id, formattedPayment)
            : await savePayerPayment(formattedPayment);
          const message = model.id
            ? EOB_UPDATE_SUCCESSFUL
            : EOB_CREATE_SUCCESSFUL;

          store.dispatch(openSuccess(message));

          this.onClose(payerPayment);
        } catch (e) {
          console.log(e);
          store.dispatch(openError(EOB_SAVE_ERROR));
        }
      },
    };
  }

  getTitle() {
    return this.__formModel.id ? 'Edit EOB' : 'Add EOB';
  }

  async __getPayerPayment() {
    if (this.model.paymentId) {
      return getPayerPayment(this.model.paymentId, this.model.eobId);
    }

    const {
      data: [eob],
    } = await getEob(this.model.eobId);
    return { eob, payment: {} };
  }

  firstUpdated() {
    super.firstUpdated();
    if (this.model.patientId) {
      this.__alertBanner.connect();

      this.__alertBanner.update(this.model.patientId);
    } else this.__alertBanner.disconnect();
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      if (this.model.eobId) {
        const { eob, payment } = await this.__getPayerPayment();
        this.__formModel = {
          ...eob,
          ...(!this.model.isLegacy ? payment : {}),
          file: null,
          eobId: this.model.eobId,
          deletePreviousFile: false,
          transactionDate: eob.transactionDate
            ? parseDate(eob.transactionDate).toISOString()
            : null,
          dateOfServiceFrom: eob.dosDateRangeFrom
            ? parseDate(eob.dosDateRangeFrom).toISOString()
            : null,
          dateOfServiceTo: eob.dosDateRangeTo
            ? parseDate(eob.dosDateRangeTo).toISOString()
            : null,
        };
      }
    }
    super.updated(changedProps);
  }

  renderContent() {
    return html`
      <div class="header-container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          title="${this.getTitle()}"
          .onCancel="${
            this.model.patientId
              ? this.handlers.dismissAll
              : this.handlers.dismiss
          }"
          .onBack="${this.handlers.dismiss}"
          .showBackButton="${this.model.patientId}"
          showCancelButton
        ></neb-popup-header>

        <div id="${ELEMENTS.description.id}">
          ${DESCRIPTION(this.model.patientId)}
        </div>
      </div>

      ${
        !this.model.isLegacy
          ? html`
              <neb-form-payment
                id="${ELEMENTS.formPayment.id}"
                .layout="${this.layout}"
                .model="${this.__formModel}"
                .patientId="${this.model.patientId}"
                .paymentType="${PAYMENT_TYPE.EOB}"
                .forPayer="${true}"
                .onSave="${this.handlers.save}"
                .onCancel="${this.handlers.dismiss}"
                .onChangeDirty="${this.handlers.dirty}"
              ></neb-form-payment>
            `
          : html`
              <neb-form-eob
                id="${ELEMENTS.form.id}"
                .layout="${this.layout}"
                .model="${this.__formModel}"
                .savingError="${this.__saveError}"
                .onSave="${this.handlers.save}"
                .onCancel="${this.handlers.dismiss}"
                .onChangeDirty="${this.handlers.dirty}"
              ></neb-form-eob>
            `
      }
    `;
  }
}

customElements.define('neb-overlay-eob', NebOverlayEOB);
