import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

const BASE_URL = 'encounter-balance';

export const getEncounterBalance = (
  patientId,
  body,
  optOutLoadingIndicator = true,
) =>
  apiClient.makeRequest({
    method: Method.POST,
    path: `patients/${patientId}/${BASE_URL}`,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    version: 1,
    optOutLoadingIndicator,
  });
