import '../../../../../src/components/misc/neb-icon';

import { css, html } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../neb-utils/constants';
import { parseDate } from '../../../../neb-utils/date-util';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { ELEMENTS as NEB_TEXT_ELEMENTS } from '../neb-text';

import NebTable from './neb-table';

export const ELEMENTS = {
  patientLinks: {
    selector: '[id^=patient-link-]',
  },
  batchLinks: {
    selector: '[id^=batch-link-]',
  },
  statementIds: {
    selector: '[id^=statement-id-]',
  },
};

class NebTableLedgerBalanceStatements extends NebTable {
  initState() {
    super.initState();

    this.onSelectPatient = () => {};

    this.onSelectBatch = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickPatient: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const { patientId } = this.model[rowIndex];
        return this.onSelectPatient(patientId);
      },
      clickBatch: value => this.onSelectBatch(value.name),
      openBillingNotesOverlay: async e => {
        const rowIndex = e.name;

        if (e.id === NEB_TEXT_ELEMENTS.trailingIcon.id) {
          const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
            parentType: BILLING_NOTE_TYPES.STATEMENT,
            parentId: this.model[rowIndex].id,
            patientId: this.model[rowIndex].patientId,
            parentData: {
              transactionDate: parseDate(this.model[rowIndex].createdAt)
                .startOf('day')
                .format('MM/DD/YYYY'),
              statement: this.model[rowIndex].statementId,
              amount: this.model[rowIndex].balance,
              payer: this.model[rowIndex].patientName,
            },
          });

          if (result) {
            this.onChange();
          }
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .patient-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'patientName':
        return this.__renderPatientCell({ value, rowIndex });
      case 'batchId':
        return this.__renderBatchCell({
          value,
          rowIndex,
        });
      case 'statementId':
        return this.__renderStatementCell({ value, rowIndex });
      default:
        return super.renderDataCell(value, columnConfig);
    }
  }

  __renderPatientCell({ value, rowIndex }) {
    return html`
      <div
        id="patient-link-${rowIndex}"
        class="button"
        index="${rowIndex}"
        @click="${this.handlers.clickPatient}"
      >
        <span class="patient-text">${value}</span>
        <neb-icon class="icon" icon="neb:open"></neb-icon>
      </div>
    `;
  }

  __renderBatchCell({ value, rowIndex }) {
    return this.model[rowIndex].batchId
      ? html`
          <neb-text
            id="batch-link-${rowIndex}"
            name="${value}"
            .onClick="${this.handlers.clickBatch}"
            bold
            link
            >B:${value}</neb-text
          >
        `
      : '';
  }

  __renderStatementCell({ value, rowIndex }) {
    if (this.model[rowIndex].statementId) {
      return html`
        <neb-text
          id="statement-id-${rowIndex}"
          trailingIcon="${
            this.model[rowIndex].hasBillingNote ? 'neb:editNote' : 'neb:addNote'
          }"
          .name="${rowIndex}"
          .onClick="${this.handlers.openBillingNotesOverlay}"
          >${value}</neb-text
        >
      `;
    }
    return '';
  }
}

customElements.define(
  'neb-table-ledger-balance-statements',
  NebTableLedgerBalanceStatements,
);
