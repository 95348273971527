import { isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import { v4 as uuid } from 'uuid';

import {
  formatAnswerSet,
  formatChooseAnswerSet,
} from '../../../../../src/formatters/macros';
import {
  mergeUniqueAnswerSets,
  getUnlinkPopupConfig,
  QUESTION_ANSWER_TOKEN,
  getLinkedChoiceTemplateName,
} from '../../../../../src/utils/macros';
import { getChoiceTemplates } from '../../../../neb-api-client/src/macro-set-api-client';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { getValueByPath } from '../../../../neb-utils/utils';
import { BUTTON_ROLE } from '../neb-button';
import NebTableMacroChoose from '../tables/neb-table-macro-choose';

import '../inputs/neb-textfield';
import '../controls/neb-button-action';

import NebForm from './neb-form';

export const ELEMENTS = {
  choiceTemplateName: { id: 'choice-template-name' },
  linkChoiceTemplateButton: { id: 'link-choice-template-button' },
  saveChoiceTemplateButton: { id: 'save-choice-template-button' },
  unlinkChoiceTemplateButton: { id: 'unlink-choice-template-button' },
  editChoiceTemplateButton: { id: 'edit-choice-template-button' },
  addButton: { id: 'add-button' },
  questionField: { id: 'question-field' },
  table: { id: 'table' },
};

export default class NebFormMacroChoose extends NebForm {
  static get properties() {
    return {
      chooseOne: Boolean,
      macroSetId: String,
      answerSetDict: Object,
    };
  }

  static createModel() {
    return {
      question: {
        text: '',
        answerSetId: uuid(),
      },

      answerSet: {
        name: '',
        deleted: false,
        answers: [NebTableMacroChoose.createModel()],
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 0 ${CSS_SPACING};
        }

        .cell {
          align-items: center;
        }

        .container-button {
          display: grid;
          justify-content: flex-end;
          grid-template-columns: auto auto;
          grid-gap: 10px;
        }

        .linked-container {
          display: flex;
          flex: 1 0 0;
          justify-content: space-between;
          align-items: center;
        }
      `,
    ];
  }

  createSelectors() {
    return {
      children: {
        question: {
          children: {
            text: [isRequired()],
          },
        },

        answerSet: {
          children: {
            answers: NebTableMacroChoose.createSelectors(),
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.chooseOne = false;
    this.macroSetId = '';
    this.answerSetDict = {};

    this.onChangeAnswerSets = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addItem: ({ name }) => this.formService.addItem(name),

      change: e => {
        if (this.chooseOne && this.__defaultChanged(e)) {
          this.__resetDefaults();

          return this.formService.apply(e.name, !e.value);
        }

        return this.formService.apply(e.name, e.value);
      },

      removeItem: (name, _, index) => this.formService.removeItem(name, index),

      saveChoiceTemplate: async () => {
        if (!this.formService.validate()) {
          return;
        }

        const result = await openPopup(POPUP_RENDER_KEYS.TEXT_FIELD, {
          title: 'Save Choice Template',
          label: 'Choice Template Title',
          confirmText: 'Done',
          cancelText: 'Cancel',
        });

        if (result) {
          this.formService.apply('answerSet.name', result);
        }
      },

      openLinkChoiceTemplatePopup: async () => {
        const filteredAnswerSet = formatAnswerSet(this.answerSetDict, 'choose');

        const templates = this.macroSetId
          ? await getChoiceTemplates(this.macroSetId, 'choose')
          : [];

        const orderedTemplates = mergeUniqueAnswerSets(
          filteredAnswerSet,
          templates,
        );

        const result = await openPopup(
          POPUP_RENDER_KEYS.MACRO_LINK_CHOICE_TEMPLATE,
          {
            templates: orderedTemplates,
          },
        );

        if (!result) {
          return;
        }

        const { answerSets, selectedAnswerSetId } = result;

        this.__setAnswerSet(answerSets, selectedAnswerSetId);

        this.onChangeAnswerSets(answerSets);
      },

      swapItems: (fromIndex, toIndex) =>
        this.formService.moveItem('answerSet.answers', fromIndex, toIndex),

      unlinkChoiceTemplate: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          getUnlinkPopupConfig(this.state.answerSet.name),
        );

        if (!result) {
          return;
        }

        this.onChangeAnswerSets({
          [this.state.question.answerSetId]: formatChooseAnswerSet(
            this.state.answerSet,
            this.chooseOne
              ? QUESTION_ANSWER_TOKEN.chooseOne
              : QUESTION_ANSWER_TOKEN.chooseAny,
          ),
        });

        this.formService.apply('answerSet.name', '');
        this.formService.apply('answerSet.deleted', false);
        this.formService.apply('question.answerSetId', uuid());

        this.state.answerSet.answers.forEach((_, index) => {
          this.formService.apply(`answerSet.answers.${index}.id`, null);
        });
      },

      editChoiceTemplate: async () => {
        const { name, answers } = this.state.answerSet;
        const { answerSetId } = this.state.question;

        const result = await openPopup(
          POPUP_RENDER_KEYS.MACRO_EDIT_CHOICE_TEMPLATE,
          {
            name,
            answers,
            type: this.chooseOne
              ? QUESTION_ANSWER_TOKEN.chooseOne
              : QUESTION_ANSWER_TOKEN.chooseAny,
          },
        );

        if (!result) {
          return;
        }

        this.__setAnswerSet({ [answerSetId]: result }, answerSetId);
      },
    };
  }

  __setAnswerSet(answerSets, selectedAnswerSetId) {
    if (!selectedAnswerSetId) {
      return;
    }

    const selectedAnswerSet = answerSets[selectedAnswerSetId];

    this.formService.apply('answerSet.name', selectedAnswerSet.name);

    const answerCount = getValueByPath(this.state, ['answerSet', 'answers'])
      .length;

    for (let i = 0; i < answerCount; i++) {
      this.formService.removeItem('answerSet.answers');
    }

    selectedAnswerSet.answers.forEach((a, index) => {
      this.formService.addItem('answerSet.answers');
      this.formService.apply(`answerSet.answers.${index}.value`, a.value);
      this.formService.apply(`answerSet.answers.${index}.id`, a.id);
    });

    this.formService.apply('question.answerSetId', selectedAnswerSetId);
  }

  __defaultChanged(e) {
    return (
      e.name.includes('default') &&
      getValueByPath(this.state, e.name.split('.')).default !== !e.value
    );
  }

  __resetDefaults() {
    this.state.answerSet.answers.forEach((_, i) =>
      this.formService.apply(`answerSet.answers.${i}.default`, false),
    );
  }

  __renderChoiceTemplateSection() {
    return this.state.answerSet.name
      ? html`
          <div class="linked-container">
            <neb-text id="${ELEMENTS.choiceTemplateName.id}" bold>
              ${getLinkedChoiceTemplateName(this.state.answerSet)}</neb-text
            >

            <div class="container-button">
              <neb-button
                id="${ELEMENTS.unlinkChoiceTemplateButton.id}"
                label="Unlink Choice Template"
                .role="${BUTTON_ROLE.OUTLINE}"
                .onClick="${this.handlers.unlinkChoiceTemplate}"
              ></neb-button>

              <neb-button
                id="${ELEMENTS.editChoiceTemplateButton.id}"
                label="Edit Choice Template"
                .role="${BUTTON_ROLE.OUTLINE}"
                .onClick="${this.handlers.editChoiceTemplate}"
              ></neb-button>
            </div>
          </div>
        `
      : html`
          <div class="container-button">
            <neb-button
              id="${ELEMENTS.linkChoiceTemplateButton.id}"
              label="Link Choice Template"
              .role="${BUTTON_ROLE.OUTLINE}"
              .onClick="${this.handlers.openLinkChoiceTemplatePopup}"
            >
            </neb-button>
            <neb-button
              id="${ELEMENTS.saveChoiceTemplateButton.id}"
              label="Save Choice Template"
              .role="${BUTTON_ROLE.OUTLINE}"
              .onClick="${this.handlers.saveChoiceTemplate}"
            ></neb-button>
          </div>
        `;
  }

  renderContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.questionField.id}"
        class="field-question"
        label="Question"
        helper="Required"
        name="question.text"
        maxLength="255"
        .value="${this.state.question.text}"
        .error="${this.errors.question.text}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      ${this.__renderChoiceTemplateSection()}

      <neb-table-macro-choose
        id="${ELEMENTS.table.id}"
        name="answerSet.answers"
        .chooseOne="${this.chooseOne}"
        .disabled="${!!this.state.answerSet.name}"
        .errors="${this.errors.answerSet.answers}"
        .model="${this.state.answerSet.answers}"
        .reorder="${!this.state.answerSet.name}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeItem}"
        .onReorder="${this.handlers.swapItems}"
      ></neb-table-macro-choose>
      ${
        !this.state.answerSet.name
          ? html`
              <neb-button-action
                id="${ELEMENTS.addButton.id}"
                label="Add Another Choice"
                name="answerSet.answers"
                .onClick="${this.handlers.addItem}"
              ></neb-button-action>
            `
          : ''
      }
    `;
  }
}

window.customElements.define('neb-form-macro-choose', NebFormMacroChoose);
