import '../../../../../src/components/controls/field-groups/neb-apply-hot-buttons';
import '../../../../neb-lit-components/src/components/neb-pagination';
import '../../../../neb-lit-components/src/components/neb-search-bar';
import '../../../../neb-lit-components/src/components/neb-tab-old';
import '../../../../neb-lit-components/src/components/neb-tabs-old';
import '../../../../neb-lit-components/src/components/neb-tooltip';
import './neb-encounter-charges-list-old';
import './neb-prior-encounters-controller';
import { LitElement, html, css } from 'lit';

import { INACTIVE_MESSAGES } from '../../../../../src/components/pages/encounters/neb-page-add-code-bundle-to-encounter';
import { NebHotButtonDataController } from '../../../../../src/features/charting/shared/neb-hot-button-data-controller';
import { HOT_BUTTON_CATEGORY_TYPE } from '../../../../../src/utils/hot-buttons';
import { openInfo } from '../../../../neb-dialog/neb-banner-state';
import { calculatePageCount } from '../../../../neb-lit-components/src/utils/pagination';
import { store } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
} from '../../../../neb-styles/neb-variables';

const NO_RESULTS = 'No results found.';
const BEGIN_YOUR_SEARCH = 'Begin your search.';
export const KEY_INPUT_DELAY = 800;

const HINT_TEXT =
  'You can filter the prior encounter list by service date, provider, appointment type, encounter charges codes and descriptions.';
const PAGE_SIZE = 10;
const errorMessage =
  'There are currently no active charges configured for the Practice. Please add a charge from';
const settingsLink = html`
  <a
    id="charges-settings-link"
    class="settings-link"
    onclick="window.location.href = '/settings/#/charges'"
  >
    Settings > Charges</a
  >.
`;
const PROCEDURE_CODES_TAB = 'procedure-codes';
const PRIOR_ENCOUNTERS_TAB = 'prior-encounters';
const HOT_BUTTONS_TAB = 'hot-buttons';
const PLACEHOLDER_TEXT = {
  [PROCEDURE_CODES_TAB]: 'Search Charges',
  [PRIOR_ENCOUNTERS_TAB]: 'Filter Prior Encounters',
};

export const ELEMENTS = {
  searchBar: { id: 'search-bar' },
  filterTooltip: { id: 'filter-tooltip' },
  pagination: { id: 'charges-pagination' },
  addChargesList: { id: 'charges' },
  procedureCodesTab: { id: 'procedure-codes' },
  priorEncountersTab: { id: 'prior-encounters' },
  priorEncountersController: { id: 'prior-encounter-controller' },
  hotButtons: { id: 'hot-buttons' },
  hotButtonsTab: { id: 'hot-buttons-tab' },
  nebTabs: { id: 'neb-tabs' },
};

class NebEncounterChargesAdd extends LitElement {
  static get properties() {
    return {
      model: Object,
      activeCharges: Array,
      encounterCharges: Array,
      encounter: Object,
      visible: Boolean,
      selectedTab: {
        type: String,
        reflect: true,
      },
      providerId: String,
      __settingsCharges: Array,
      __defaultTab: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .section-title {
          margin-top: 50px;
          padding: ${CSS_SPACING};
        }

        .section-filter {
          display: flex;
          padding: 0 ${CSS_SPACING};
          flex-direction: row;
          justify-content: space-between;
        }

        .text-caption {
          margin: ${CSS_SPACING};
          color: ${CSS_COLOR_GREY_1};
          font-style: italic;
        }

        .search-container {
          width: 460px;
        }

        .pagination {
          flex-direction: row-reverse;
          padding-top: ${CSS_SPACING};
          padding-right: 10px;
          margin-bottom: ${CSS_SPACING};
        }

        .hot-buttons-container,
        .procedure-codes-container,
        .prior-encounter-container {
          display: none;
        }

        .tooltip {
          padding: 10px 15px;
        }

        .hint {
          width: 420px;
          height: 40px;
        }

        :host([selectedTab='hot-buttons']) .hot-buttons-container {
          display: flex;
          flex-direction: column;
        }

        :host([selectedTab='hot-buttons']) .search-container {
          display: none;
        }

        :host([selectedTab='procedure-codes']) .procedure-codes-container {
          display: flex;
          flex-direction: column;
        }

        :host([selectedTab='prior-encounters']) .prior-encounter-container {
          display: flex;
          flex-direction: row;
        }

        :host([selectedTab='prior-encounters']) .search-container {
          display: flex;
          width: 510px;
        }

        :host([selectedTab='prior-encounters']) neb-search-bar {
          width: 490px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__resetSearch();

    this.__settingsCharges = [];

    this.model = {};
    this.activeCharges = [];
    this.encounterCharges = [];
    this.encounter = null;

    this.__defaultTab = HOT_BUTTONS_TAB;
    this.selectedTab = HOT_BUTTONS_TAB;

    this.visible = false;
    this.providerId = '';
    this.__loggedInUserId = '';

    this.__hotButtonDataController = new NebHotButtonDataController(this, {
      categoryType: HOT_BUTTON_CATEGORY_TYPE.CHARGES,
      onHasHotButtonItems: () => {
        this.selectedTab = HOT_BUTTONS_TAB;
        this.__defaultTab = HOT_BUTTONS_TAB;
      },
      onDoesNotHaveHotButtonItems: () => {
        this.selectedTab = PROCEDURE_CODES_TAB;
        this.__defaultTab = PROCEDURE_CODES_TAB;
      },
    });

    this.onFilterChanged = () => {};

    this.OnPageChanged = () => {};

    this.onAllPriorEncounterChargesSelected = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      pageChanged: pageIndex => this.onPageChanged(pageIndex),
      allPriorEncounterChargesSelected: allPriorEncounterCharges =>
        this.onAllPriorEncounterChargesSelected(allPriorEncounterCharges),
      priorEncounterSelected: encounterId => {
        if (this.__checkSelectedEncounter(encounterId)) {
          this.selectedEncounterId = encounterId;
        }
      },
      selectedTabChanged: selected => {
        this.selectedTab = selected;
        this.__setSearchBarFocus();
      },
      getSearchText: text => {
        if (this.__timeout) clearTimeout(this.__timeout);

        const buildSearchText = async () => {
          this.__timeout = null;
          await this.__searchOnText(text);
        };

        if (text === '') {
          return this.__searchOnText(text);
        }

        this.__timeout = setTimeout(buildSearchText, KEY_INPUT_DELAY);

        return undefined;
      },
      addCharge: charge => {
        if (!charge.active) {
          store.dispatch(openInfo(INACTIVE_MESSAGES.single('Charge')));
          return;
        }

        this.__dispatchChargeEvent(charge);
      },
      updateHotButtonModel: x => {
        this.__hotButtonDataController.updateHotButtonModel(x);
      },
    };
  }

  clear() {
    this.__resetSearch();

    this.selectedTab = this.__defaultTab;

    this.selectedEncounterId = '';
    this.elements.search.clear();
    this.elements.priorEncounters.clear();
  }

  __isChargeAdded(chargeId) {
    return !!this.encounterCharges.find(charge => charge.chargeId === chargeId);
  }

  __dispatchChargeEvent(model) {
    this.dispatchEvent(
      new CustomEvent('neb-encounter-charge-added', {
        detail: {
          model,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  __checkSelectedEncounter(encounterId) {
    return (
      !this.selectedEncounterId || this.selectedEncounterId !== encounterId
    );
  }

  async __searchOnText(text) {
    const prevSearch = this.__search[this.selectedTab];

    if (text !== prevSearch) {
      this.__search[this.selectedTab] = text;

      switch (this.selectedTab) {
        case PROCEDURE_CODES_TAB:
          this.onFilterChanged(this.__search[this.selectedTab]);
          break;

        case PRIOR_ENCOUNTERS_TAB:
          await this.elements.priorEncounters.filterPriorEncounters(
            this.__search[this.selectedTab],
          );

          break;

        default:
          console.error('TAB NOT HANDLED');
      }
    }
  }

  __resetSearch() {
    this.__search = {
      [PROCEDURE_CODES_TAB]: '',
      [PRIOR_ENCOUNTERS_TAB]: '',
      [HOT_BUTTONS_TAB]: '',
    };
  }

  __setSearchBarFocus() {
    if (this.elements && this.elements.search) {
      this.elements.search.searchText = this.__search[this.selectedTab];
    }

    this.elements.search.setFocus();
  }

  __setSearchBarUnfocus() {
    this.elements.search.setBlur();
  }

  firstUpdated() {
    this.elements = {
      search: this.shadowRoot.getElementById(ELEMENTS.searchBar.id),
      priorEncounters: this.shadowRoot.querySelector(
        'neb-prior-encounters-controller',
      ),
      tabs: this.shadowRoot.querySelector('neb-tabs-old'),
    };
  }

  __updateSettingsCharges() {
    this.__settingsCharges = this.model.results
      ? this.model.results.map(item =>
          Object.assign([], item, {
            isEncounter: this.__isChargeAdded(item.id),
          }),
        )
      : [];

    this.requestUpdate();
  }

  updated(changedProps) {
    if (changedProps.has('visible')) {
      if (this.visible) {
        this.__setSearchBarFocus();
      } else {
        this.__setSearchBarUnfocus();
      }
    }

    if (changedProps.has('selectedTab')) {
      this.__setSearchBarFocus();
    }

    if (changedProps.has('encounterCharges') || changedProps.has('model')) {
      this.__updateSettingsCharges();
    }
  }

  __renderSearchBar() {
    return html`
      <div class="search-container">
        ${
          this.selectedTab === PRIOR_ENCOUNTERS_TAB
            ? html`
                <neb-tooltip
                  id="${ELEMENTS.filterTooltip.id}"
                  class="tooltip"
                  defaultAnchor="top"
                >
                  <div class="hint" slot="tooltip">${HINT_TEXT}</div>
                </neb-tooltip>
              `
            : ''
        }
        <neb-search-bar
          id="${ELEMENTS.searchBar.id}"
          placeholderText="${PLACEHOLDER_TEXT[this.selectedTab]}"
          .searchText="${this.__search[this.selectedTab]}"
          .onSearch="${this.__handlers.getSearchText}"
        >
        </neb-search-bar>
      </div>
    `;
  }

  __renderChargesList() {
    const pageCount = calculatePageCount(this.model.count, PAGE_SIZE);

    return html`
      <div class="procedure-codes-container">
        <neb-encounter-charges-list-old
          id="${ELEMENTS.addChargesList.id}"
          show-add-button
          read-only
          .items="${this.__settingsCharges}"
          .message="${this.__getMessage()}"
        ></neb-encounter-charges-list-old>
        ${
          this.model.count !== 0
            ? html`
                <neb-pagination
                  id="${ELEMENTS.pagination.id}"
                  class="pagination"
                  .pageCount="${pageCount}"
                  .currentPage="${this.model.currentPage - 1}"
                  .onPageChanged="${this.__handlers.pageChanged}"
                ></neb-pagination>
              `
            : ''
        }
      </div>
    `;
  }

  __renderHotButtons() {
    return html`
      <div class="hot-buttons-container">
        <neb-apply-hot-buttons
          id="${ELEMENTS.hotButtons.id}"
          .model="${this.__hotButtonDataController.hotButtonModel}"
          .existingItems="${this.encounterCharges}"
          .activeCharges="${this.activeCharges}"
          .onApplyHotButton="${this.__handlers.addCharge}"
          .onSetModel="${this.__handlers.updateHotButtonModel}"
        ></neb-apply-hot-buttons>
      </div>
    `;
  }

  __getMessage() {
    if (this.activeCharges.length) {
      if (
        this.__search[this.selectedTab] &&
        this.__search[this.selectedTab].length &&
        !this.model.results.length
      ) {
        return NO_RESULTS;
      }

      return BEGIN_YOUR_SEARCH;
    }

    return html`
      ${errorMessage} ${settingsLink}
    `;
  }

  __renderPriorEncounters() {
    return html`
      <neb-prior-encounters-controller
        id="${ELEMENTS.priorEncountersController.id}"
        class="prior-encounter-container"
        .activeCharges="${this.activeCharges}"
        .encounterCharges="${this.encounterCharges}"
        .encounter="${this.encounter}"
        .onPriorEncounterSelected="${this.__handlers.priorEncounterSelected}"
        .onAllPriorEncounterChargesSelected="${
          this.__handlers.allPriorEncounterChargesSelected
        }"
      ></neb-prior-encounters-controller>
    `;
  }

  render() {
    return html`
      <div class="section-title" id="title"><strong>Add Charges</strong></div>

      <div class="section-filter">
        <neb-tabs-old
          id="${ELEMENTS.nebTabs.id}"
          .onChange="${this.__handlers.selectedTabChanged}"
          .selected="${this.selectedTab}"
        >
          <neb-tab-old
            name="${HOT_BUTTONS_TAB}"
            id="${ELEMENTS.hotButtonsTab.id}"
          >
            Hot Buttons
          </neb-tab-old>

          <neb-tab-old
            name="${PROCEDURE_CODES_TAB}"
            id="${ELEMENTS.procedureCodesTab.id}"
          >
            Procedure Codes
          </neb-tab-old>

          <neb-tab-old
            name="${PRIOR_ENCOUNTERS_TAB}"
            id="${ELEMENTS.priorEncountersTab.id}"
          >
            Prior Encounters
          </neb-tab-old>
        </neb-tabs-old>

        ${this.__renderSearchBar()}
      </div>

      ${this.__renderChargesList()}${this.__renderPriorEncounters()}
      ${this.__renderHotButtons()}
    `;
  }
}

window.customElements.define(
  'neb-encounter-charges-add',
  NebEncounterChargesAdd,
);
