import '../../../../../packages/neb-lit-components/src/components/patients/neb-patient-panel';
import '../../../misc/neb-patient-online-booking-link';
import '../../../misc/neb-patient-demographics';
import '../../../../../packages/neb-alert/components/neb-alert-summary';
import '../../../../../packages/neb-lit-components/src/components/tables/neb-table-old';
import '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../tables/neb-table-patient-insurance';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../../../packages/neb-lit-components/src/components/neb-loading-overlay';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { createAlertsForPatientState } from '../../../../../packages/neb-alert/components/neb-alerts-for-patient-state';
import * as patientApiClient from '../../../../../packages/neb-api-client/src/patient-api-client';
import {
  getPatientInsurances,
  GET_INSURANCE_VERSION,
} from '../../../../../packages/neb-api-client/src/patient-insurance-api-client';
import {
  getPatientRelationships,
  DEFAULT_VERSION,
} from '../../../../../packages/neb-api-client/src/patient-relationship-api-client';
import {
  getPotentialMatches,
  getAccount,
  getPracticeUser,
} from '../../../../../packages/neb-api-client/src/permissions-api-client';
import { getActiveProviderUsers } from '../../../../../packages/neb-api-client/src/practice-users-api-client';
import {
  ACTIVE_GUARANTOR_TABLE_CONFIG,
  formatGuarantorName,
} from '../../../../../packages/neb-lit-components/src/components/guarantor/guarantor-util';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import {
  store,
  connect,
} from '../../../../../packages/neb-redux/neb-redux-store';
import { LocationsService } from '../../../../../packages/neb-redux/services/locations';
import { navigate } from '../../../../../packages/neb-route/neb-route-state';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../packages/neb-utils/feature-util';
import {
  objToName,
  DEFAULT_NAME_OPTS,
  formatPhoneNumber,
} from '../../../../../packages/neb-utils/formatters';
import { fetchPatient } from '../../../../../packages/neb-utils/patient';
import { mapToPatientInsuranceModel } from '../../../../../packages/neb-utils/patientInsurance';
import { compareProviders } from '../../../../../packages/neb-utils/provider';
import { MODE } from '../../../../../packages/neb-utils/table';
import {
  deleteEmergencyContact,
  getEmergencyContacts,
} from '../../../../api-clients/emergency-contacts';
import {
  BASE_CATEGORIES,
  getReferralCategoryById,
} from '../../../../api-clients/referrals-api-client';
import { formatReferralSources } from '../../../../formatters/referral-sources';
import { UpdateNotificationService } from '../../../../services/update-notifications';
import { openError, openSuccess } from '../../../../store';
import {
  baseStyles,
  layoutStyles,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../styles';
import {
  PATIENT_STATEMENT_SETTINGS_ERROR,
  PATIENT_STATEMENT_SETTINGS_SUCCESS,
  PATIENT_STATEMENT_SETTINGS_SWITCH,
  PATIENT_STATEMENT_SETTINGS_TITLE,
} from '../../../../utils/user-message';

export const EMERGENCY_CONTACTS_TABLE_CONFIG = [
  {
    key: 'name',
    label: 'Name',
    flex: css`1 0 0`,
  },
  {
    key: 'relation',
    label: 'Relation',
    flex: css`1 0 0`,
  },
  {
    key: 'phone',
    label: 'Phone',
    flex: css`1 0 0`,
  },
];

const ACTIVE_RELATIONSHIP_TABLE_CONFIG = [
  {
    truncate: true,
    key: 'patientName',
    label: 'Name',
    flex: css`1.5 0 0`,
  },
  {
    truncate: true,
    key: 'type',
    label: 'Relation',
    flex: css`1 0 0`,
  },
];

export const TITLE_ALERTS_NOTES = 'Alerts & Notes';
export const TITLE_ONLINE_BOOKING = 'Online Booking Link';
export const TITLE_GUARANTOR = 'Active Guarantors';
export const TITLE_RELATIONSHIP = 'Active Relationships';
export const TITLE_EMERGENCY_CONTACTS = 'Emergency Contacts';
export const TITLE_INSURANCE = 'Insurance';
export const TITLE_DEMOGRAPHICS = 'Demographics';
const SUCCESS_MESSAGE = 'Online Booking Account linked';
export const TEXT_BANNER_SUCCESS = 'Online Booking Account linked';
export const TEXT_UNMATCH_SUCCESS = 'Account link removed';
export const EMPTY_RELATIONSHIP_MESSAGE =
  'There are no active relationships for this patient.';
export const EMPTY_EMERGENCY_CONTACTS_MESSAGE =
  'There are no emergency contacts for this patient.';

export const ELEMENTS = {
  demographics: { id: 'demographics' },
  patientDemographics: { id: 'patient-demographics' },
  patientDemographicReportLink: { id: 'patient-demographic-report-link' },
  online: { id: 'online' },
  patientBookingLink: { id: 'patient-booking-link' },
  recent: { id: 'recent' },
  alerts: { id: 'alerts' },
  alertsSummary: { id: 'alerts-summary' },
  insurance: { id: 'insurance' },
  tableInsurance: { id: 'table-insurance' },
  guarantor: { id: 'guarantor' },
  guarantorSummary: { id: 'guarantor-summary' },
  form: { id: 'form' },
  relationship: { id: 'relationship' },
  relationshipSummary: { id: 'relationship-summary' },
  statement: { id: 'statement' },
  statementSwitch: { id: 'statement-switch' },
  loading: { id: 'loading' },
  emergencyContactsPanel: { id: 'emergency-contacts-panel' },
  emergencyContactsTable: { id: 'emergency-contacts-table' },
  emergencyContactsButtonAdd: { id: 'emergency-contacts-button-add' },
};

export const initPatient = () => ({
  medicalRecordNumber: '',
  firstName: '',
  middleName: '',
  lastName: '',
  preferredName: null,
  dateOfBirth: '',
  emailAddresses: [],
  phoneNumbers: [],
  addresses: [],
  ssn: null,
  sex: null,
  relationshipStatus: null,
  employmentStatus: null,
});

class NebPatientProfilePage extends connect(store)(LitElement) {
  static get properties() {
    return {
      __activeGuarantors: Array,
      __activeInsurances: Array,
      __activeRelationships: Array,
      __emergencyContacts: Array,
      __alerts: Array,
      __patient: Object,
      __matchedEmail: String,
      __matches: Array,
      __preferredProviderName: String,
      __providers: Array,
      __preferredLocationName: String,
      __locations: Array,
      __referralCategorySource: Object,
      __hasEmergencyContactsFF: Boolean,
      __updating: Boolean,

      guarantors: Array,
      layout: {
        type: String,
        reflect: true,
      },
      model: Object,
      patientId: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        :host {
          display: flex;
          height: 100%;

          overflow: auto;
          background-color: ${CSS_COLOR_WHITE};
          --neb-panel-link-padding: 10px;
        }

        .grid {
          flex: 1 0 0;
          gap: ${CSS_SPACING};
          align-items: stretch;
        }

        :host([layout='small']) .grid {
          padding: 0;
        }

        .container {
          padding: ${CSS_SPACING} 0;
        }

        .table {
          padding-top: 15px;
        }

        .statement-panel {
          padding-left: 10px;
          padding-top: 10px;
          grid-template-columns: 20px auto;
          gap: 5px;
        }

        :host([layout='small']) .statement-panel {
          padding-left: 10px;
          padding-top: 10px;
        }

        .cursor-pointer {
          cursor: pointer;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();

    this.__initServices();
  }

  __initState() {
    this.layout = '';
    this.model = {};
    this.guarantors = [];
    this.__matches = [];
    this.__locations = [];
    this.__providers = [];
    this.__matchedEmail = null;
    this.__alerts = [];
    this.__activeInsurances = [];
    this.__activeRelationships = [];
    this.__rawEmergencyContacts = [];
    this.__emergencyContacts = [];
    this.__preferredProviderName = '-';
    this.__preferredLocationName = '-';
    this.__patient = initPatient();
    this.__referralCategorySource = {};
    this.__hasEmergencyContactsFF = false;
    this.__updating = false;

    this.onEditPatient = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      reviewMatches: async () => {
        this.model = {
          title: 'Matching Accounts',
          account: this.__patient,
          matches: this.__matches,
        };

        const result = await openPopup(
          POPUP_RENDER_KEYS.MATCH_ONLINE_BOOKING_ACCOUNT,
          this.model,
        );

        if (result && result.data && result.data[0].bookingAccountId) {
          openSuccess(SUCCESS_MESSAGE);
        }
      },
      unmatchBookingAccount: async () => {
        const confirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Remove Account Link',
          message: html`
            <div>
              By removing this link, this patient record will no longer be
              linked to their online booking account. Any future changes made in
              the patient record will not be reflected in the online booking
              site, and any appointments made from the online booking account
              will not be reflected in this patient record.
            </div>
            <br />
            <div>
              Are you sure that you wish to remove the linked online booking
              account from this patient record?
            </div>
          `,
        });
        if (!confirmed) return;
        const result = await patientApiClient.matchBookingAccountToPatient(
          this.__patient,
          null,
        );

        if (result && result.data && result.data.length) {
          openSuccess(TEXT_UNMATCH_SUCCESS);
        }
      },
      editPatient: () => this.onEditPatient(),
      openAlerts: async () => {
        if (this.patientId) {
          const alertsChanged = await openOverlay(OVERLAY_KEYS.PATIENT_ALERT, {
            patientId: this.patientId,
          });

          if (alertsChanged) {
            await this.__alertsService.fetchAll(this.__patient.id);
          }
        }
      },
      addEmergencyContact: async () => {
        const result = await openOverlay(OVERLAY_KEYS.EMERGENCY_CONTACT, {
          patientId: this.patientId,
        });

        if (result) {
          await this.__getEmergencyContacts();
        }
      },
      editEmergencyContact: async (_name, _item, index) => {
        const result = await openOverlay(
          OVERLAY_KEYS.EMERGENCY_CONTACT,
          this.__rawEmergencyContacts[index],
        );

        if (result) {
          await this.__getEmergencyContacts();
        }
      },
      deleteEmergencyContact: async (_name, _item, index) => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Remove Emergency Contact',
          message:
            'You are about to remove this emergency contact. Do you want to continue?',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        });

        if (result) {
          try {
            await deleteEmergencyContact(this.__rawEmergencyContacts[index]);
            await this.__getEmergencyContacts();
            openSuccess('Emergency Contact removed successfully');
          } catch (e) {
            console.error(e);
            openError('There was an error removing Emergency Contact');
          }
        }
      },
      navigateToInsurance: () =>
        store.dispatch(
          navigate(`#/patients/${this.patientId}/insurance/active`),
        ),
      navigateToGuarantors: () =>
        store.dispatch(
          navigate(`#/patients/${this.patientId}/billing/guarantor`),
        ),
      navigateToRelationship: () =>
        store.dispatch(
          navigate(`#/patients/${this.patientId}/billing/relationships`),
        ),
      alertsChanged: alerts => {
        this.__alerts = alerts;
      },
      updateStatementBatch: async () => {
        try {
          this.__updating = true;

          const excludeFromBatchStatement = +!this.__patient
            .excludeFromBatchStatement;

          const patient = { ...this.__patient, excludeFromBatchStatement };

          await patientApiClient.excludeFromBatchStatement({
            patientId: this.patientId,
            exclude: excludeFromBatchStatement,
          });

          openSuccess(PATIENT_STATEMENT_SETTINGS_SUCCESS);
          this.__patient = { ...patient };
        } catch (e) {
          console.error(e);
          openError(PATIENT_STATEMENT_SETTINGS_ERROR);
        } finally {
          this.__updating = false;
        }
      },
      openPatientDemographicReportParamPopup: () => {
        openPopup(POPUP_RENDER_KEYS.PATIENT_DEMOGRAPHIC_REPORT_PARAMS, {
          patientId: this.patientId,
        });
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });

    this.__alertsService = createAlertsForPatientState(
      this.__handlers.alertsChanged,
    );

    this.__notificationService = new UpdateNotificationService({
      defaultQuery: {},
      callback: () => this.__loadPatientInfo(),
    });
  }

  async connectedCallback() {
    this.__locationsService.connect();
    this.__notificationService.connect();

    this.__hasEmergencyContactsFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.LS_EMERGENCY_CONTACTS,
    );

    this.__hasPatientDemographicReport = await hasFeatureOrBeta(
      FEATURE_FLAGS.PATIENT_DEMOGRAPHIC_REPORT,
    );

    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__locationsService.disconnect();
    this.__notificationService.disconnect();
  }

  _stateChanged(state) {
    if (state.providers.item && this.__providers) {
      if (!compareProviders(this.__providers, state.providers.item)) {
        this.__providers = state.providers.item;
      }
    }
  }

  async __getActiveInsurances() {
    this.__activeInsurances = (await getPatientInsurances(
      this.patientId,
      {
        active: true,
      },
      GET_INSURANCE_VERSION,
      true,
    )).map(ins => mapToPatientInsuranceModel(ins));
  }

  async __getActiveRelationships() {
    const activeRelationships = await getPatientRelationships(
      this.patientId,
      {
        active: true,
      },
      DEFAULT_VERSION,
      true,
    );

    // Follow up via NEB-49300 - See if we can use fetchSome
    const relationships = await Promise.all(
      activeRelationships.map(async relationship => {
        const patient = await patientApiClient.fetchOne(
          relationship.relatedPatientId,
          true,
        );

        const patientName = objToName(patient.name, DEFAULT_NAME_OPTS);

        return { ...relationship, patientName };
      }),
    );

    this.__activeRelationships = relationships.sort((a, b) =>
      a.patientName.localeCompare(b.patientName),
    );
  }

  async __getEmergencyContacts() {
    this.__rawEmergencyContacts = await getEmergencyContacts(this.patientId);

    this.__emergencyContacts = this.__rawEmergencyContacts.map(contact => ({
      id: contact.id,

      name: `${objToName(contact.name, {
        reverse: true,
        middleInitial: true,
      })}${contact.isPrimary ? ' (Primary)' : ''}`,

      relation: contact.relation,
      phone: formatPhoneNumber(contact.phones[0]?.number),
    }));
  }

  __whenGuarantorsHaveChanged(changed) {
    if (changed.has('guarantors') || changed.has('__patient')) {
      const selfGuarantor = { ...this.guarantors[0] };

      this.__activeGuarantors = this.guarantors
        .filter(g => g.active)
        .sort((a, b) => {
          if (a.default || b.default) return 0;
          return formatGuarantorName(a).localeCompare(formatGuarantorName(b));
        });

      this.__activeGuarantors.unshift({
        ...selfGuarantor,
        person: { ...this.__patient },
      });
    }
  }

  __formTitle(panelName, count) {
    return count === 0 ? panelName : `${panelName} (${count})`;
  }

  __findPreferredProvider() {
    const preferredProvider = this.__patient.preferredProviderId
      ? this.__providers.find(
          provider => provider.id === this.__patient.preferredProviderId,
        )
      : '';

    this.__preferredProviderName = preferredProvider
      ? objToName(preferredProvider.name, DEFAULT_NAME_OPTS)
      : '-';
  }

  __findPreferredLocation() {
    const preferredLocation = this.__patient.preferredLocationId
      ? this.__locations.find(
          location => location.id === this.__patient.preferredLocationId,
        )
      : '';

    this.__preferredLocationName = preferredLocation
      ? preferredLocation.name
      : '-';
  }

  async __getReferralSource({ categoryId, sourceId, sourceType, description }) {
    let source;
    let category;
    let categorySource;
    let sourceName = null;

    category = sourceType;

    if (sourceId) {
      switch (sourceType) {
        case BASE_CATEGORIES.patient.name:
          source = await fetchPatient(sourceId);
          sourceName = objToName(source.name, DEFAULT_NAME_OPTS);

          break;
        case BASE_CATEGORIES.staff.name:
          source = await getPracticeUser(sourceId);
          sourceName = objToName(source.name, DEFAULT_NAME_OPTS);

          break;
        default:
          source = await getReferralCategoryById(categoryId);
          category = source.name;
          categorySource = formatReferralSources(source.sources);
          sourceName = categorySource.find(item => item.id === sourceId).name;

          break;
      }
    }

    this.__referralCategorySource = {
      category,
      source: sourceName,
      description,
    };
  }

  get __formattedPatient() {
    return {
      givenName: this.__patient.firstName,
      familyName: this.__patient.lastName,
      phoneNumbers: this.__patient.phoneNumbers
        ? JSON.stringify(this.__patient.phoneNumbers.map(phone => phone.number))
        : '',
      emailAddresses: this.__patient.emailAddresses
        ? JSON.stringify(this.__patient.emailAddresses)
        : '',
      birthdate: this.__patient.dateOfBirth,
    };
  }

  async __loadPatientInfo() {
    if (!this.patientId) return;
    this.__patient = await patientApiClient.fetchOne(
      this.patientId,
      true,
      true,
    );

    if (this.__patient.patientReferralSourceId) {
      const {
        categoryId,
        sourceId,
        sourceType,
        description,
      } = this.__patient.referralSource;

      this.__getReferralSource({
        categoryId,
        sourceId,
        sourceType,
        description,
      });
    }
  }

  update(changed) {
    this.__whenGuarantorsHaveChanged(changed);
    super.update(changed);
  }

  async firstUpdated() {
    this.__providers = await getActiveProviderUsers(true);
  }

  async updated(changedProps) {
    if (changedProps.has('patientId') && this.patientId) {
      this.__notificationService.update({
        patient: { id: this.patientId },
      });

      this.clearAllPanelsBeforeLoadingAnotherPatient();
      this.__loadPatientInfo();
    }

    if (changedProps.has('__providers') && this.__patient) {
      this.__findPreferredProvider();
      this.__findPreferredLocation();
    }

    if (changedProps.has('__patient') && this.__patient.firstName !== '') {
      await this.__alertsService.fetchAll(this.__patient.id);

      this.__findPreferredProvider();
      this.__findPreferredLocation();

      try {
        this.__matches = this.__patient.bookingAccountId
          ? [await getAccount(this.__patient.bookingAccountId, true)]
          : (await getPotentialMatches(this.__formattedPatient, true)) || [];

        const matchedAccount = this.__matches.find(
          match => match.cognitoId === this.__patient.bookingAccountId,
        );

        this.__matchedEmail = matchedAccount
          ? matchedAccount.emailAddress
          : null;

        await this.__getActiveInsurances();

        await this.__getActiveRelationships();

        await this.__getEmergencyContacts();
      } catch (e) {
        // noop
      }
    }
  }

  clearAllPanelsBeforeLoadingAnotherPatient() {
    this.__patient = initPatient();
    this.__activeRelationships = [];
    this.__emergencyContacts = [];
    this.__alerts = [];
    this.__activeInsurances = [];
    this.__activeGuarantors = [];
    this.__referralCategorySource = {};
  }

  __renderStatementPanel() {
    return html`
      <neb-patient-panel
        id="${ELEMENTS.statement.id}"
        .layout="${this.layout}"
        .title="${PATIENT_STATEMENT_SETTINGS_TITLE}"
      >
        <div class="grid grid-2 statement-panel">
          <neb-switch
            id="${ELEMENTS.statementSwitch.id}"
            name="statement-batch"
            class="checkbox"
            .layout="${this.layout}"
            .onChange="${this.__handlers.updateStatementBatch}"
            ?on="${this.__patient.excludeFromBatchStatement}"
            label="${PATIENT_STATEMENT_SETTINGS_SWITCH}"
          >
          </neb-switch>
        </div>
      </neb-patient-panel>
    `;
  }

  __renderEmergencyContactsPanel() {
    return html`
      <neb-patient-panel
        id="${ELEMENTS.emergencyContactsPanel.id}"
        .layout="${this.layout}"
        .title="${
          this.__formTitle(
            TITLE_EMERGENCY_CONTACTS,
            this.__emergencyContacts.length,
          )
        }"
      >
        <neb-button-action
          slot="panel-title"
          id="${ELEMENTS.emergencyContactsButtonAdd.id}"
          label="Add Emergency Contact"
          icon="neb:plus"
          .onClick="${this.__handlers.addEmergencyContact}"
        ></neb-button-action>

        <neb-table
          id="${ELEMENTS.emergencyContactsTable.id}"
          class="table"
          emptyMessage="${EMPTY_EMERGENCY_CONTACTS_MESSAGE}"
          .config="${EMERGENCY_CONTACTS_TABLE_CONFIG}"
          .model="${this.__emergencyContacts}"
          .mode="${MODE.DETAIL}"
          .onSelectRow="${this.__handlers.editEmergencyContact}"
          .onRemove="${this.__handlers.deleteEmergencyContact}"
          showRemoveButton
          preview
        >
        </neb-table>
      </neb-patient-panel>
    `;
  }

  __renderActiveRelationshipsPanel() {
    return html`
      <neb-patient-panel
        id="${ELEMENTS.relationship.id}"
        class="cursor-pointer"
        .layout="${this.layout}"
        .title="${
          this.__formTitle(
            TITLE_RELATIONSHIP,
            this.__activeRelationships.length,
          )
        }"
        .onClick="${this.__handlers.navigateToRelationship}"
      >
        <neb-table
          id="${ELEMENTS.relationshipSummary.id}"
          class="table"
          preview
          allowPropagation
          emptyMessage="${EMPTY_RELATIONSHIP_MESSAGE}"
          .config="${ACTIVE_RELATIONSHIP_TABLE_CONFIG}"
          .model="${this.__activeRelationships}"
        >
        </neb-table>
      </neb-patient-panel>
    `;
  }

  render() {
    return html`
      <div class="grid grid-2 grid-span-mobile">
        <div class="container grid grid-lean">
          <neb-patient-panel
            id="${ELEMENTS.demographics.id}"
            class="cursor-pointer"
            .layout="${this.layout}"
            .title="${TITLE_DEMOGRAPHICS}"
            .onClick="${this.__handlers.editPatient}"
          >
            ${
              this.__hasPatientDemographicReport
                ? html`
                    <neb-button-action
                      slot="panel-title"
                      id="${ELEMENTS.patientDemographicReportLink.id}"
                      label="Patient Demographics"
                      leadingIcon="print"
                      .onClick="${
                        this.__handlers.openPatientDemographicReportParamPopup
                      }"
                    ></neb-button-action>
                  `
                : ''
            }

            <neb-patient-demographics
              id="${ELEMENTS.patientDemographics.id}"
              layout="${this.layout}"
              .patient="${this.__patient}"
              .preferredProviderName="${this.__preferredProviderName}"
              .preferredLocationName="${this.__preferredLocationName}"
              .referralCategorySource="${this.__referralCategorySource}"
            ></neb-patient-demographics>
          </neb-patient-panel>

          <neb-patient-panel
            id="${ELEMENTS.online.id}"
            .layout="${this.layout}"
            .title="${TITLE_ONLINE_BOOKING}"
          >
            <neb-patient-online-booking-link
              id="${ELEMENTS.patientBookingLink.id}"
              .matches="${this.__matches}"
              .patient="${this.__patient}"
              .matchedEmail="${this.__matchedEmail}"
              .reviewMatches="${this.__handlers.reviewMatches}"
              .onUnmatchAccount="${this.__handlers.unmatchBookingAccount}"
              .layout="${this.layout}"
            ></neb-patient-online-booking-link>
          </neb-patient-panel>

          ${
            !this.__hasEmergencyContactsFF
              ? this.__renderActiveRelationshipsPanel()
              : ''
          }
        </div>

        <div class="container grid grid-lean">
          <neb-patient-panel
            id="${ELEMENTS.alerts.id}"
            class="cursor-pointer"
            .layout="${this.layout}"
            .title="${
              this.__formTitle(TITLE_ALERTS_NOTES, this.__alerts.length)
            }"
            .onClick="${this.__handlers.openAlerts}"
          >
            <neb-alert-summary
              id="${ELEMENTS.alertsSummary.id}"
              class="alert-summary"
              .alerts="${this.__alerts}"
            ></neb-alert-summary>
          </neb-patient-panel>

          ${this.__renderStatementPanel()}

          <neb-patient-panel
            id="${ELEMENTS.insurance.id}"
            class="cursor-pointer"
            .layout="${this.layout}"
            .title="${
              this.__formTitle(TITLE_INSURANCE, this.__activeInsurances.length)
            }"
            .onClick="${this.__handlers.navigateToInsurance}"
          >
            <neb-table-patient-insurance
              id="${ELEMENTS.tableInsurance.id}"
              class="table"
              .mode="${MODE.NONE}"
              .model="${this.__activeInsurances}"
              .showDetail="${false}"
              allowPropagation
              showDefault
              preview
            ></neb-table-patient-insurance>
          </neb-patient-panel>

          ${
            this.__hasEmergencyContactsFF
              ? this.__renderEmergencyContactsPanel()
              : ''
          }
          ${
            this.__hasEmergencyContactsFF
              ? this.__renderActiveRelationshipsPanel()
              : ''
          }

          <neb-patient-panel
            id="${ELEMENTS.guarantor.id}"
            class="cursor-pointer"
            .layout="${this.layout}"
            .title="${
              this.__formTitle(TITLE_GUARANTOR, this.__activeGuarantors.length)
            }"
            .onClick="${this.__handlers.navigateToGuarantors}"
          >
            <neb-table-old
              id="${ELEMENTS.guarantorSummary.id}"
              class="table"
              preview
              .layout="${this.layout}"
              .config="${ACTIVE_GUARANTOR_TABLE_CONFIG}"
              .model="${this.__activeGuarantors}"
            >
            </neb-table-old>
          </neb-patient-panel>
        </div>
      </div>

      <neb-loading-overlay
        id="${ELEMENTS.loading.id}"
        .show="${this.__updating}"
      ></neb-loading-overlay>
    `;
  }
}
customElements.define('neb-patient-profile-page', NebPatientProfilePage);
