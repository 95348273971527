import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import * as payerPlansApi from '../../../packages/neb-api-client/src/payer-plan-api-client';
import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { number } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { FetchService } from '../../../packages/neb-utils/services/fetch';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';

export const ELEMENTS = {
  dateRange: { id: 'range-date-of-service' },
  invoiceTextfield: { id: 'textfield-invoice' },
  claimTextfield: { id: 'textfield-claim' },
  payersSelect: { id: 'select-payers' },
  paymentStatusSelect: { id: 'select-payment-status' },
  payerAmountRange: { id: 'payer-range-amount' },
  balanceAmountRange: { id: 'balance-range-amount' },
  reportId: { id: 'report-id' },
  typeSelect: { id: 'select-type' },
  authEFT: { id: 'authEFT' },
  paymentId: { id: 'payment-id' },
  hasWarnings: { id: 'has-warnings' },
};

export const PAYMENT_STATUS_ITEMS = Object.freeze([
  selectors.ITEM_EMPTY,
  { label: 'Allocated', data: { id: 'allocated' } },
  { label: 'Unallocated', data: { id: 'unallocated' } },
]);

const REPORT_TYPE = { EOB: 'EOB', ERA: 'ERA' };

const TYPE_FILTER_OPTIONS = Object.freeze([
  selectors.ITEM_EMPTY,
  { label: REPORT_TYPE.EOB, data: { id: REPORT_TYPE.EOB } },
  { label: REPORT_TYPE.ERA, data: { id: REPORT_TYPE.ERA } },
]);

class NebEraEobManagementFilters extends NebFilters {
  static get properties() {
    return {
      isSupportApp: { type: Boolean, reflect: true },
      eraOverviewFF: { type: Boolean },

      __payerItems: Array,
      __payerPlansState: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(4, 1fr);
        }

        .has-warnings {
          padding-top: 27px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      date: NebDateRange.createModel(),
      claim: '',
      payerIds: [],
      invoice: '',
      payerAmount: NebCurrencyRange.createModel(),
      balanceAmount: NebCurrencyRange.createModel(),
      status: '',
      type: '',
      authEFT: '',
      paymentId: '',
      reportId: '',
      paymentStatus: '',
      hasWarnings: false,
    };
  }

  initState() {
    super.initState();

    this.isSupportApp = false;
    this.eraOverviewFF = false;

    this.__payerPlansState = FetchService.createModel();
    this.__payerPlansService = new FetchService(
      {
        onChange: state => {
          this.__payerPlansState = state;
        },
        onMapItem: data => ({ label: data.alias, data }),
      },
      payerPlansApi.fetchMany,
    );
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clearInvoiceSearch: () => this.formService.apply('invoice', ''),
      clearClaimSearch: () => this.formService.apply('claim', ''),
      clearReportIdSearch: () => this.formService.apply('reportId', ''),
      clearPaymentIdSearch: () => this.formService.apply('paymentId', ''),
      clearAuthEFTSearch: () => this.formService.apply('authEFT', ''),
      fetchMorePayers: () => this.__payerPlansService.fetchMore(),
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      apply: () => {
        const model = this.formService.build();
        const { from, to } = model.date;

        this.onApply({
          ...model,
          date: {
            from: from ? parseDate(from._i) : null,
            to: to ? parseDate(to._i) : null,
          },
        });
      },
    };
  }

  createSelectors() {
    return {
      children: {
        payerIds: selectors.multiSelect([]),
        date: NebDateRange.createSelectors(),
        payerAmount: NebCurrencyRange.createSelectors(),
        balanceAmount: NebCurrencyRange.createSelectors(),
        paymentStatus: selectors.select(PAYMENT_STATUS_ITEMS),
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
  }

  load() {
    this.__payerPlansService.fetch();
  }

  __renderDateFilter() {
    const date = {
      from: this.state.date.from ? parseDate(this.state.date.from) : null,
      to: this.state.date.to ? parseDate(this.state.date.to) : null,
    };
    return html`
      <neb-range-date
        id="${ELEMENTS.dateRange.id}"
        name="date"
        label="Date"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>
    `;
  }

  __renderClaimFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.claimTextfield.id}"
        name="claim"
        label="Claim #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.claim ? 'neb:clear' : ''}"
        .value="${this.state.claim}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearClaimSearch}"
      ></neb-textfield>
    `;
  }

  __renderPayerFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.payersSelect.id}"
        name="payerIds"
        label="Payers"
        helper=" "
        .value="${this.state.payerIds}"
        .items="${this.__payerPlansState.pageItems}"
        .onChange="${this.handlers.change}"
        .onRequest="${this.handlers.fetchMorePayers}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderInvoiceFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.invoiceTextfield.id}"
        name="invoice"
        label="Invoice #"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.invoice ? 'neb:clear' : ''}"
        .value="${this.state.invoice}"
        .mask="${number}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearInvoiceSearch}"
      ></neb-textfield>
    `;
  }

  __renderAmountFilter() {
    return html`
      <neb-range-currency
        id="${ELEMENTS.payerAmountRange.id}"
        name="payerAmount"
        label="${this.isSupportApp ? 'ERA Amount' : 'ERA/EOB Amount'}"
        .model="${this.state.payerAmount}"
        .onChange="${this.handlers.change}"
        .allowNegative="${true}"
      ></neb-range-currency>
    `;
  }

  __renderBalanceFilter() {
    return html`
      <neb-range-currency
        id="${ELEMENTS.balanceAmountRange.id}"
        name="balanceAmount"
        label="Payment Balance"
        .model="${this.state.balanceAmount}"
        .onChange="${this.handlers.change}"
      ></neb-range-currency>
    `;
  }

  __renderTypeFilter() {
    return !this.isSupportApp
      ? html`
          <neb-select
            id="${ELEMENTS.typeSelect.id}"
            name="type"
            label="Type"
            helper=" "
            .value="${this.state.type}"
            .items="${TYPE_FILTER_OPTIONS}"
            .onChange="${this.handlers.change}"
            .isSupportApp="${this.isSupportApp}"
          ></neb-select>
        `
      : '';
  }

  __renderIdFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.reportId.id}"
        name="reportId"
        label="${this.isSupportApp ? 'ERA ID' : 'ERA/EOB ID'}"
        helper=" "
        maxLength="7"
        leadingIcon="neb:search"
        .trailingIcon="${this.state.reportId ? 'neb:clear' : ''}"
        .mask="${number}"
        .inputMode="${'numeric'}"
        .value="${this.state.reportId}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearReportIdSearch}"
      ></neb-textfield>
    `;
  }

  __renderPaymentFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.paymentId.id}"
        name="paymentId"
        label="Payment ID"
        helper=" "
        maxLength="7"
        leadingIcon="neb:search"
        .trailingIcon="${this.state.paymentId ? 'neb:clear' : ''}"
        .mask="${number}"
        .inputMode="${'numeric'}"
        .value="${this.state.paymentId}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearPaymentIdSearch}"
      ></neb-textfield>
    `;
  }

  __renderAuthEFTFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.authEFT.id}"
        name="authEFT"
        label="Check/Auth/EFT"
        helper=" "
        maxLength="45"
        leadingIcon="neb:search"
        .trailingIcon="${this.state.authEFT ? 'neb:clear' : ''}"
        .value="${this.state.authEFT}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearAuthEFTSearch}"
      ></neb-textfield>
    `;
  }

  __renderPaymentStatusFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.paymentStatusSelect.id}"
        name="paymentStatus"
        label="Payment Status"
        helper=" "
        .value="${this.state.paymentStatus}"
        .onChange="${this.handlers.change}"
        .items="${PAYMENT_STATUS_ITEMS}"
      ></neb-select>
    `;
  }

  __renderHideItemsWithoutWarnings() {
    return this.eraOverviewFF
      ? html`
          <neb-checkbox
            id="${ELEMENTS.hasWarnings.id}"
            name="hasWarnings"
            class="has-warnings"
            label="Hide Items Without Warnings"
            .onChange="${this.handlers.change}"
            ?checked="${this.state.hasWarnings}"
          ></neb-checkbox>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderPayerFilter()} ${this.__renderDateFilter()}
      ${this.__renderIdFilter()} ${this.__renderAuthEFTFilter()}
      ${this.__renderTypeFilter()} ${this.__renderPaymentFilter()}
      ${this.__renderClaimFilter()} ${this.__renderInvoiceFilter()}
      ${this.__renderAmountFilter()} ${this.__renderBalanceFilter()}
      ${this.__renderPaymentStatusFilter()}
      ${this.__renderHideItemsWithoutWarnings()}
    `;
  }
}

window.customElements.define(
  'neb-filters-era-eob-management',
  NebEraEobManagementFilters,
);
