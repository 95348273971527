import '../../../../packages/neb-lit-components/src/components/guarantor/neb-organization-card';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../../packages/neb-lit-components/src/components/guarantor/neb-guarantor-summary';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../misc/neb-icon';
import { LitElement, html, css } from 'lit';

import { phone } from '../../../../packages/neb-utils/masks';
import {
  baseStyles,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../styles';

export const ELEMENTS = {
  containerOrganizationCard: {
    id: 'container-organization-card',
  },
  textOrganizationCardName: {
    id: 'text-organization-card-name',
  },
  textOrganizationCardPhone: {
    id: 'text-organization-card-phone',
  },
  textSearchOrganization: {
    id: 'text-search-organization',
  },
  textAttorneyName: {
    id: 'text-attorney-name',
  },
  textAttorneyType: {
    id: 'text-attorney-type',
  },
  textAttorneyPhone: {
    id: 'text-attorney-phone-number',
  },
  removeButton: {
    id: 'button-remove',
  },
  removeButtonList: {
    selector: '#button-remove',
  },
  viewGuarantorSummary: {
    id: 'view-guarantor-summary',
  },
};

export const ATTORNEY_TYPES = ['', 'Current', 'Previous', 'Third Party'];

class NebAttorneyItem extends LitElement {
  static get properties() {
    return {
      name: String,
      model: Object,
      errors: Object,
      organizations: Array,
      showRemoveButton: {
        reflect: true,
        type: Boolean,
      },
      showRequiredField: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.showRemoveButton = false;
    this.showRequiredField = false;

    this.name = '';
    this.errors = {
      name: '',
      type: '',
      phoneNumber: '',
      organizationId: '',
    };

    this.model = {
      name: '',
      type: '',
      phoneNumber: '',
      organizationId: null,
    };

    this.organizations = [];

    this.onRemove = () => {};

    this.onChange = () => {};

    this.onChangeOrganization = () => {};

    this.onSearchOrganization = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      remove: () => this.onRemove(this.name),
      change: ({ name, value }) => this.onChange(`${this.name}.${name}`, value),
      changeOrganization: ({ name, value }) => {
        this.onChangeOrganization(`${this.name}.${name}`, value.id || null);
      },
      searchOrganization: ({ value }) => this.onSearchOrganization({ value }),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          padding: ${CSS_SPACING} 0px;
        }

        .container-attorney {
          display: flex;
          width: 100%;
        }

        .container-organization {
          width: 100%;
        }

        .container-organization-card[disabled] {
          opacity: 0.65;
        }

        .text-attorney-name {
          margin-right: ${CSS_SPACING};
          width: 100%;
        }

        .text-attorney-type {
          margin-right: ${CSS_SPACING};
          width: 100%;
        }

        .text-attorney-phone {
          width: 100%;
        }

        .text-search-organization {
          margin: ${CSS_SPACING_ROW} 0;
          width: 100%;
          z-index: 1;
        }

        .guarantor-summary {
          margin-left: ${CSS_SPACING};
        }

        .icon {
          cursor: pointer;
          margin: 22px 0 22px 0;
          width: 27px;
          height: 24px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  __getSelectedOrganization() {
    return this.organizations.find(
      organization => organization.id === this.model.organizationId,
    );
  }

  __getActiveOrganizations() {
    return this.organizations.filter(organization => organization.active);
  }

  __renderOrganizationCard(item, index) {
    return html`
      <neb-organization-card
        id="${ELEMENTS.containerOrganizationCard.id}-dropdown-item-${index}"
        class="container-organization-card"
        .layout="${this.layout}"
        .model="${item}"
      ></neb-organization-card>
    `;
  }

  renderRemoveItemButton() {
    return this.showRemoveButton
      ? html`
          <neb-icon
            id="${ELEMENTS.removeButton.id}"
            class="icon"
            icon="neb:minus"
            @click="${this.__handlers.remove}"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <div class="container-attorney">
          <neb-textfield
            id="${ELEMENTS.textAttorneyName.id}"
            name="name"
            class="text text-attorney-name"
            label="Attorney Name"
            .helper="${this.showRequiredField ? 'Required' : ' '}"
            .value="${this.model.name}"
            .onChange="${this.__handlers.change}"
            .error="${this.errors.name}"
            ?valid="${!this.errors.name}"
            disableValidation
          ></neb-textfield>

          <neb-select
            id="${ELEMENTS.textAttorneyType.id}"
            class="text text-attorney-type"
            name="type"
            label="Attorney Type"
            helper="${this.showRequiredField ? 'Required' : ' '}"
            .items="${ATTORNEY_TYPES}"
            .value="${this.model.type}"
            .onChange="${this.__handlers.change}"
            .error="${this.errors.type}"
          ></neb-select>

          <neb-textfield
            id="${ELEMENTS.textAttorneyPhone.id}"
            name="phoneNumber"
            class="text text-attorney-phone"
            maxLength="14"
            label="Attorney Phone"
            helper=" "
            .mask="${phone}"
            .value="${this.model.phoneNumber}"
            .onChange="${this.__handlers.change}"
            .error="${this.errors.phoneNumber}"
          ></neb-textfield>

          ${this.renderRemoveItemButton()}
        </div>

        <div class="container-organization">
          <neb-select-search
            id="${ELEMENTS.textSearchOrganization.id}"
            class="text text-search-organization"
            name="organizationId"
            label="Search Organization"
            helper="${this.showRequiredField ? 'Required' : ' '}"
            error="${this.errors.organizationId}"
            emptyMessage="No organizations found"
            .itemHeight="${84}"
            .items="${this.__getActiveOrganizations()}"
            .value="${this.__getSelectedOrganization()}"
            .onChange="${this.__handlers.changeOrganization}"
            .onSearch="${this.__handlers.searchOrganization}"
            .onRenderItem="${this.__renderOrganizationCard}"
            showSearch
          ></neb-select-search>

          ${
            this.model.organizationId
              ? html`
                  <neb-guarantor-summary
                    id="${ELEMENTS.viewGuarantorSummary.id}"
                    class="guarantor-summary"
                    .model="${this.__getSelectedOrganization()}"
                    .showEdit="${false}"
                  ></neb-guarantor-summary>
                `
              : ''
          }
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-attorney-item', NebAttorneyItem);
