import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { POPOVER_POSITION } from '../../../packages/neb-lit-components/src/components/neb-date-picker';
import { ALL_STATUSES_EXCEPT_DELETED } from '../../../packages/neb-patient/src/components/neb-patient-appointments';
import { CSS_BORDER_GREY_2 } from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';
import * as selectors from '../../../packages/neb-utils/selectors';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters, ELEMENTS as BASE_ELEMENTS } from './neb-filters';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  statusSelect: { id: 'status-select' },
  dateRangeInput: { id: 'date-range-input' },
};

const FILTERS = Object.freeze({
  date: 'date',
  status: 'status',
});

const ALL_STATUSES = [
  {
    label: 'Active',
    data: { id: 'Active' },
  },
  {
    label: 'Checked-In',
    data: { id: 'Checked-In' },
  },
  {
    label: 'Checked-Out',
    data: { id: 'Checked-Out' },
  },
  {
    label: 'Canceled',
    data: { id: 'Canceled' },
  },
  {
    label: 'Deleted',
    data: { id: 'Deleted' },
  },
  {
    label: 'No-Show',
    data: { id: 'No-Show' },
  },
  {
    label: 'Rescheduled',
    data: { id: 'Rescheduled' },
  },
];

class NebPatientAppointmentFiltersMobile extends NebFilters {
  static get properties() {
    return {};
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          border: 0;
          border-radius: 0;
          border-top: ${CSS_BORDER_GREY_2};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .content {
          grid-template-columns: repeat(2, 1fr);
        }

        .select {
          padding-bottom: 16px;
        }

        .grid-mobile {
          display: flex;
          flex-wrap: wrap;
          padding: 0px 20px 10px;
          gap: 20px 12px;
        }

        .status-select {
          flex: 1 1 280px;
        }

        .range-date {
          flex: 1 1 280px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      status: ALL_STATUSES_EXCEPT_DELETED,
      date: NebDateRange.createModel(),
    };
  }

  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeDate: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : null);
      },
      apply: () => {
        const model = this.formService.build();
        const { from, to } = model.date;
        const { status } = model;

        const includeRescheduled = !!status.find(s => s === 'Rescheduled');

        this.onApply({
          ...model,
          date: {
            from: from ? parseDate(from._i) : null,
            to: to ? parseDate(to._i) : null,
          },
          includeRescheduled,
        });
      },
    };
  }

  createSelectors() {
    return {
      children: {
        status: selectors.multiSelect(ALL_STATUSES),
        date: NebDateRange.createSelectors(),
      },
    };
  }

  render() {
    return html`
      <div class="container">
        <div class="grid-mobile">${this.renderContent()}</div>
        ${this.__renderFilters()}
      </div>
    `;
  }

  __renderFilters() {
    return html`
      <div class="container-buttons">
        <neb-button
          id="${ELEMENTS.applyButton.id}"
          label="Apply"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.apply}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.resetButton.id}"
          label="Reset"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.reset}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    const date = {
      from: this.state.date.from ? parseDate(this.state.date.from) : null,
      to: this.state.date.to ? parseDate(this.state.date.to) : null,
    };

    return html`
      <neb-select
        id="${ELEMENTS.statusSelect.id}"
        class="status-select"
        label="Appointment Status"
        allLabel="Statuses"
        name="${FILTERS.status}"
        .items="${ALL_STATUSES}"
        .value="${this.state.status}"
        .onChange="${this.handlers.change}"
        multiSelect
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.dateRangeInput.id}"
        class="range-date"
        label="Date"
        name="${FILTERS.date}"
        .manualPosition="${POPOVER_POSITION.CENTER}"
        .model="${date}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>
    `;
  }
}

window.customElements.define(
  'neb-filters-patient-appointments-mobile',
  NebPatientAppointmentFiltersMobile,
);
