import { html, LitElement, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_DISABLED,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../neb-styles/neb-variables';
import { parseDate } from '../../../neb-utils/date-util';

export const ELEMENTS = {
  buttonDayPrefix: {
    id: 'button-day-',
  },
  dayButtons: {
    selector: '.button-day',
  },
};

const TYPE = {
  BAR: 'bar',
  BUBBLES: 'bubbles',
};

const FIRST_DAY_NUM = 1;
const ITEM_WIDTH = css`36px`;
const ITEM_RADIUS = css`18px`;
const DAY_RANGE = [1, 2, 3, 4, 5, 6, 7];

class NebCalendarViewRow extends LitElement {
  static get properties() {
    return {
      momentFlag: {
        type: Boolean,
        reflect: true,
      },
      availability: Object,
      displayPage: Object,
      index: Number,
      isDateSelectable: Function,
      selectedDate: Object,
      disableCalendarView: {
        type: Boolean,
        reflect: true,
      },
      type: {
        type: String,
        reflect: true,
      },
      clearSelectedDate: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.momentFlag = false;
    this.clearSelectedDate = false;
    this.index = 0;
    this.selectedDate = parseDate();
    this.type = TYPE.BAR;

    this.displayPage = {
      month: this.selectedDate.month() + 1,
      year: this.selectedDate.year(),
    };

    this.disableCalendarView = false;

    this.isDateSelectable = _date => true;

    this.onDateChanged = _date => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectDay: e => {
        const num = e.currentTarget.dayNum;
        const { year } = this.displayPage;
        const monthIndex = this.displayPage.month - 1;
        const prevDate = this.selectedDate;
        const selectedDate = parseDate()
          .year(year)
          .month(monthIndex)
          .date(num)
          .startOf('day');

        const toNull = prevDate !== null && selectedDate === null;
        const toValid = prevDate === null && selectedDate !== null;

        if (toNull || toValid || !!this.selectedDate) {
          this.onDateChanged(
            this.momentFlag ? selectedDate : selectedDate.toDate(),
          );
        }
      },
    };
  }

  __dayNumIsSelectable(dayNum) {
    const currentDateObject = parseDate()
      .year(this.displayPage.year)
      .month(this.displayPage.month - 1)
      .date(dayNum)
      .startOf('day');

    return this.isDateSelectable(
      this.momentFlag ? currentDateObject : currentDateObject.toDate(),
    );
  }

  __getCapValue(startIndex, endIndex, weekDayIndex) {
    if (startIndex === null || endIndex === null || this.clearSelectedDate) {
      return '';
    }

    switch (weekDayIndex) {
      case startIndex:
        return startIndex === endIndex ? 'only' : 'start';

      case endIndex:
        return startIndex === endIndex ? 'only' : 'end';

      default:
        return 'middle';
    }
  }

  __getMonthlyDayNumFromWeek(weekIndex, dayNum) {
    return weekIndex * 7 + dayNum - this.__getStartingDayOffset();
  }

  __getNumDaysInMonth() {
    const { year } = this.displayPage;
    const nextMonth = this.displayPage.month;
    const date = parseDate()
      .year(year)
      .month(nextMonth)
      .date(0);

    return date.date();
  }

  __getSelectedWeekRange(startDayNum) {
    const date = this.selectedDate;
    const valid = date && date.month() === this.displayPage.month - 1;

    if (valid) {
      const { year } = this.displayPage;
      const monthIndex = this.displayPage.month - 1;
      const startDate = parseDate()
        .year(year)
        .month(monthIndex)
        .date(startDayNum);

      const endDate = parseDate()
        .year(year)
        .month(monthIndex)
        .date(startDayNum + 6);

      if (this.selectedDate >= startDate && this.selectedDate <= endDate) {
        return this.__getWeekRangeIndices(startDayNum);
      }
    }

    return {
      start: null,
      end: null,
    };
  }

  __getStartingDayOffset() {
    const { year } = this.displayPage;
    const monthIndex = this.displayPage.month - 1;
    const date = parseDate()
      .year(year)
      .month(monthIndex)
      .date(1);

    return date.day();
  }

  __getWeekRangeIndices(startDayNum) {
    let startIndex = null;
    let endIndex = null;

    for (let i = 0; i < 7; ++i) {
      const num = startDayNum + i;

      const valid = this.__isValidDayNum(num);

      if (startIndex === null && valid) {
        startIndex = i;
      }

      if (valid && num <= this.__getNumDaysInMonth()) {
        endIndex = i;
      }
    }

    return {
      start: startIndex,
      end: endIndex,
    };
  }

  __isDateBeforeToday(num) {
    const date = parseDate()
      .year(this.displayPage.year)
      .month(this.displayPage.month - 1)
      .date(num);

    return date.isBefore(parseDate());
  }

  __isSelected(num) {
    if (this.clearSelectedDate) {
      return false;
    }

    return (
      this.selectedDate &&
      this.isDateSelectable(
        this.momentFlag ? this.selectedDate : this.selectedDate.toDate(),
      ) &&
      this.displayPage.year === this.selectedDate.year() &&
      this.displayPage.month - 1 === this.selectedDate.month() &&
      num === this.selectedDate.date()
    );
  }

  __isValidDayNum(num) {
    return num > 0 && num <= this.__getNumDaysInMonth();
  }

  update(changedProps) {
    if (changedProps.has('selectedDate') && this.selectedDate) {
      this.selectedDate = parseDate(this.selectedDate);
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 0;
        }

        .text {
          margin: 0;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .item {
          display: flex;
          flex: 1 0 0;
        }

        .item[cap='start'] {
          border-radius: ${ITEM_RADIUS} 0 0 ${ITEM_RADIUS};
          background-color: ${CSS_COLOR_GREY_4};
        }

        .item[cap='end'] {
          border-radius: 0 ${ITEM_RADIUS} ${ITEM_RADIUS} 0;
          background-color: ${CSS_COLOR_GREY_4};
        }

        .item[cap='middle'] {
          border-radius: 0 0 0 0;
          background-color: ${CSS_COLOR_GREY_4};
        }

        .item[cap='only'] {
          border-radius: ${ITEM_RADIUS};
          background-color: ${CSS_COLOR_GREY_4};
        }

        .button {
          cursor: pointer;
          outline: none;
          border: 0;
          background-color: transparent;
          text-align: center;
        }

        .button[disabled] {
          cursor: default;
        }

        .button-day {
          border-radius: 18px;
          color: ${CSS_COLOR_BLACK};
          height: ${ITEM_WIDTH};
          margin: 0 auto;
          width: ${ITEM_WIDTH};
          padding: 0;
        }

        .button-day[disabled] {
          color: ${CSS_COLOR_GREY_1};
        }

        .button-day[disabled]:hover {
          background-color: inherit;
          color: ${CSS_COLOR_GREY_1};
          font-weight: unset;
          cursor: default;
        }

        .button-day:hover,
        .button-day:focus,
        .button-day[selected] {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_WHITE};
        }

        :host([type='bubbles']) .button-day {
          border-radius: 18px;
          border: 2px solid ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_HIGHLIGHT};
          height: ${ITEM_WIDTH};
          width: ${ITEM_WIDTH};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        :host([type='bubbles']) .button-day[unavailable] {
          cursor: pointer;
          color: ${CSS_COLOR_GREY_1};
          border: 2px solid ${CSS_COLOR_GREY_1};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        :host([type='bubbles']) .button-day:hover,
        :host([type='bubbles']) .button-day:focus,
        :host([type='bubbles']) .button-day[selected] {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_WHITE};

          border: none;
        }

        :host([type='bubbles']) .button-day[unavailable][selected] {
          background-color: ${CSS_COLOR_GREY_1};
        }

        :host([type='bubbles']) .button-day[disabled] {
          color: ${CSS_COLOR_BLACK};
          border: none;
          font-weight: unset;
        }

        :host([type='bubbles']) .button-day[disabled]:hover {
          background-color: transparent;
        }

        :host([type='bubbles']) .button-day[unavailable]:hover {
          background-color: ${CSS_COLOR_GREY_1};
          color: ${CSS_COLOR_WHITE};
        }

        :host([disableCalendarView][type='bubbles']) .button-day[selected] {
          background-color: ${CSS_COLOR_DISABLED};
          color: ${CSS_COLOR_WHITE};
          cursor: default;
        }

        :host([disableCalendarView]) .button-day:not([selected]) {
          color: ${CSS_COLOR_DISABLED};
          cursor: default;
        }
      `,
    ];
  }

  __renderDayNum(weekDayNum, dayNum, capIndices) {
    const cap = capIndices
      ? this.__getCapValue(capIndices.start, capIndices.end, weekDayNum - 1)
      : '';

    const dayNumIsSelectable = this.__dayNumIsSelectable(dayNum);

    return html`
      <div class="item" cap="${cap}">
        <button
          id="${ELEMENTS.buttonDayPrefix.id}${dayNum}"
          type="button"
          class="button button-day"
          .dayNum="${dayNum}"
          ?disabled="${!dayNumIsSelectable}"
          ?selected="${this.__isSelected(dayNum)}"
          ?unavailable="${
            dayNumIsSelectable && this.__isDateBeforeToday(dayNum)
          }"
          @click="${this.__handlers.selectDay}"
        >
          <span class="text">${dayNum}</span>
        </button>
      </div>
    `;
  }

  render() {
    let capIndices;

    if (this.type === TYPE.BAR) {
      const weekStartDayNum = this.__getMonthlyDayNumFromWeek(
        this.index,
        FIRST_DAY_NUM,
      );

      capIndices = this.__getSelectedWeekRange(weekStartDayNum);
    }

    return html`
      ${
        DAY_RANGE.map(weekDayNum => {
          const dayNum = this.__getMonthlyDayNumFromWeek(
            this.index,
            weekDayNum,
          );

          return this.__isValidDayNum(dayNum)
            ? this.__renderDayNum(weekDayNum, dayNum, capIndices)
            : html`
                <div class="item"></div>
              `;
        })
      }
    `;
  }
}

window.customElements.define('neb-calendar-view-row', NebCalendarViewRow);
