import '../../../../packages/neb-lit-components/src/components/inputs/neb-floating-label';

import { isDate } from '@neb/form-validators';
import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';

import { POPOVER_POSITION } from '../../../../packages/neb-lit-components/src/components/neb-date-picker';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import { CSS_FIELD_MARGIN } from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';

export const ELEMENTS = {
  label: { id: 'label' },
  fromPicker: { id: 'picker-from' },
  toPicker: { id: 'picker-to' },
};

export class NebDateRange extends LitElement {
  static get properties() {
    return {
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },

      manualPosition: String,
      name: String,
      label: String,
      model: Object,
      errors: Object,
      helpers: Object,
      minGap: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
        }

        .container {
          display: grid;
          position: relative;
          padding-top: ${CSS_FIELD_MARGIN};
          grid-gap: 0 12px;
          grid-template-columns: 1fr 1fr;
        }

        :host([minGap]) .container {
          grid-gap: 0 5px;
          min-width: 270px;
        }

        .field {
          width: 100%;
        }
      `,
    ];
  }

  static createModel() {
    return {
      from: null,
      to: null,
    };
  }

  static createSelectors(fromValidators = [], toValidators = []) {
    const builder = validators => ({
      format: v => (v ? v.toISOString() : ''),
      unformat: v => (v ? moment(v) : null),
      validators: [...(validators || []), isDate('YYYY-MM-DD')],
    });

    return {
      children: {
        from: builder(fromValidators),
        to: builder(toValidators),
      },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__focused = false;
    this.manualPosition = false;

    this.label = '';
    this.model = this.constructor.createModel();
    this.errors = { from: '', to: '' };
    this.helpers = { from: '', to: '' };
    this.minGap = false;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      focus: () => {
        this.__focused = true;
      },
      blur: () => {
        this.__focused = false;
      },
      changeFrom: e => {
        const name = this.getName(e);
        const value = e.value ? parseDate(e.value).startOf('day') : '';
        this.onChange({ name, value });
      },
      changeTo: e => {
        const name = this.getName(e);
        const value = e.value ? parseDate(e.value).endOf('day') : '';
        this.onChange({ name, value });
      },
    };
  }

  getName(e) {
    return [this.name, e.name].filter(item => item !== '').join('.');
  }

  render() {
    return html`
      <div class="container">
        <neb-date-picker
          id="${ELEMENTS.fromPicker.id}"
          class="field"
          name="from"
          placeholder="From"
          .manualPopoverPosition="${
            this.manualPosition ? this.manualPosition : POPOVER_POSITION.BOTTOM
          }"
          .helperText="${this.helpers.from || ' '}"
          .selectedDate="${this.model.from}"
          .error="${this.errors.from}"
          .onBlur="${this.__handlers.blur}"
          .onFocus="${this.__handlers.focus}"
          .onChange="${this.__handlers.changeFrom}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.toPicker.id}"
          class="field"
          name="to"
          placeholder="To"
          .manualPopoverPosition="${
            this.manualPosition ? this.manualPosition : POPOVER_POSITION.BOTTOM
          }"
          .helperText="${this.helpers.to || ' '}"
          .selectedDate="${this.model.to}"
          .error="${this.errors.to}"
          .onBlur="${this.__handlers.blur}"
          .onFocus="${this.__handlers.focus}"
          .onChange="${this.__handlers.changeTo}"
          momentFlag
        ></neb-date-picker>

        <neb-floating-label
          id="${ELEMENTS.label.id}"
          .text="${this.label}"
          ?focused="${this.__focused}"
          pinned
        ></neb-floating-label>
      </div>
    `;
  }
}

window.customElements.define('neb-range-date', NebDateRange);
