import './neb-image-preview';
import './neb-pdf-preview';

import { LitElement, html } from 'lit';

export const ID_IMAGE_PREVIEW = 'image-preview';
export const ID_PDF_PREVIEW = 'pdf-preview';

class NebFilePreview extends LitElement {
  static get properties() {
    return {
      fileBlob: Object,
    };
  }

  constructor() {
    super();
    this.fileBlob = null;

    this.onInvalidFile = () => {};
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          height: 100%;
          width: 100%;
        }
      </style>
    `;
  }

  __renderPreview() {
    if (this.fileBlob == null) return '';

    switch (this.fileBlob.type) {
      case 'image/jpeg':
      case 'image/png':
        return html`
          <neb-image-preview
            id="${ID_IMAGE_PREVIEW}"
            .fileBlob="${this.fileBlob}"
            .onInvalidFile="${this.onInvalidFile}"
          ></neb-image-preview>
        `;

      case 'application/pdf':
        return html`
          <neb-pdf-preview
            id="${ID_PDF_PREVIEW}"
            .fileBlob="${this.fileBlob}"
            .onInvalidFile="${this.onInvalidFile}"
          ></neb-pdf-preview>
        `;

      default:
        return '';
    }
  }

  render() {
    return html`
      ${this.__renderStyles()} ${this.__renderPreview()}
    `;
  }
}

customElements.define('neb-file-preview', NebFilePreview);
