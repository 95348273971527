import { LitElement, html, css, unsafeCSS } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_COLOR_BLACK,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  caret: {
    id: 'caret',
  },
  container: {
    id: 'container',
  },
  popover: {
    id: 'popover',
  },
  blocker: {
    id: 'blocker',
  },
};

export const POPOVER_POSITION = {
  BOTTOM: 'bottom',
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
};

const TICK_SIZE = unsafeCSS(16);

class NebPopover extends LitElement {
  static get properties() {
    return {
      __showBlocker: Boolean,

      showCaret: Boolean,
      popoverPosition: String,
      hideArrow: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__showBlocker = true;
    this.hideArrow = false;

    this.showCaret = true;
    this.popoverPosition = POPOVER_POSITION.BOTTOM;

    this.onBlockerClicked = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      closePopover: () => {
        this.onBlockerClicked();
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          align-items: center;
        }

        .container-popover {
          color: ${CSS_COLOR_BLACK};
          border-radius: 4px;
          background-color: ${CSS_COLOR_WHITE};
          box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.14);
          z-index: 3;
        }

        .container[popoverposition='top'] .caret {
          transform: rotate(180deg);
        }

        .caret {
          height: 0;
          width: 0;
          border-bottom: ${TICK_SIZE}px solid ${CSS_COLOR_WHITE};
          border-left: ${TICK_SIZE}px solid transparent;
          border-right: ${TICK_SIZE}px solid transparent;
          z-index: 4;
          translate: var(--popover-caret-translate);
        }

        .hideArrow {
          height: 0;
          width: 0;
          border-bottom: ${TICK_SIZE}px solid ${CSS_COLOR_WHITE};
          z-index: 4;
          translate: var(--popover-caret-translate);
        }

        .blocker {
          position: fixed;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: transparent;
          z-index: 2;
        }
      `,
    ];
  }

  __handleFlexDirection() {
    return this.popoverPosition === POPOVER_POSITION.TOP
      ? 'flex-direction: column-reverse;'
      : 'flex-direction: column;';
  }

  __renderCaret() {
    const className = this.hideArrow ? 'hideArrow' : 'caret';
    return this.popoverPosition !== POPOVER_POSITION.CENTER && this.showCaret
      ? html`
          <div id="${ELEMENTS.caret.id}" class="${className}"></div>
        `
      : '';
  }

  render() {
    return html`
      ${
        this.__showBlocker
          ? html`
              <div
                id="${ELEMENTS.blocker.id}"
                class="blocker"
                @click="${this.__handlers.closePopover}"
              ></div>
            `
          : ''
      }
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        popoverPosition="${this.popoverPosition}"
        style="${this.__handleFlexDirection()}"
      >
        ${this.__renderCaret()}
        <div id="${ELEMENTS.popover.id}" class="container-popover">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popover', NebPopover);
