import moment from 'moment-timezone';

const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

const formatDateTime = date =>
  moment(date)
    .tz(getTimezone())
    .format('MM/DD/YYYY hh:mm A');

export { formatDateTime };
