/* eslint-disable complexity */
import '../../../../packages/neb-lit-components/src/components/neb-header';
import '../../misc/neb-icon';

import '../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import { MONTH_DAY_YEAR_TIME } from '../../../../packages/neb-input/nebFormatUtils';
import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../packages/neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../packages/neb-utils/constants';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  objToName,
  centsToCurrency,
} from '../../../../packages/neb-utils/formatters';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  claimLabel: { id: 'claim-label' },
  datesOfServiceLabel: { id: 'dates-of-service-label' },
  transactionDateLabel: { id: 'transaction-date-label' },
  invoiceLabel: { id: 'invoice-label' },
  statementLabel: { id: 'statement-label' },
  superbillLabel: { id: 'superbill-label' },
  paymentLabel: { id: 'payment-label' },
  eobEraLabel: { id: 'eob-era-label' },
  amountLabel: { id: 'amount-label' },
  payerLabel: { id: 'payer-label' },
  claim: { id: 'claim' },
  datesOfService: { id: 'dates-of-service' },
  transactionDate: { id: 'transaction-date' },
  payment: { id: 'payment' },
  eobEra: { id: 'eob-era' },
  amount: { id: 'amount' },
  payer: { id: 'payer' },
  chargeLabel: { id: 'charge-label' },
  codeLabel: { id: 'code-label' },
  descriptionLabel: { id: 'description-label' },
  invoice: { id: 'invoice' },
  statement: { id: 'statement' },
  superbill: { id: 'superbill' },
  charge: { id: 'charge' },
  code: { id: 'code' },
  description: { id: 'description' },
  entry: { id: 'entry' },
  createdContainer: { id: 'created-container' },
  createdBy: { id: 'created-by' },
  createdAt: { id: 'created-at' },
  updatedContainer: { id: 'updated-container' },
  updatedBy: { id: 'updated-by' },
  updatedAt: { id: 'updated-at' },
  removeButton: { id: 'remove-button' },
  addLedgerNoteButton: { id: 'add-ledger-note-button' },
};

export class NebFormBillingNote extends NebForm {
  static get properties() {
    return {
      parentType: String,
      parentId: String,
      parentData: Object,
      practiceUsers: Array,
    };
  }

  static createModel() {
    return {
      entries: [{ id: '', note: '', sortOrder: 0 }],
    };
  }

  createSelectors() {
    return {
      children: {
        entries: {
          createItem: () => ({
            id: '',
            note: '',
            sortOrder: 0,
          }),
          children: {
            $: {
              children: {
                note: [isRequired()],
              },
            },
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.parentType = '';
    this.parentId = '';
    this.parentData = {};
    this.practiceUsers = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      remove: idx => this.__removeEntry(idx),
      add: () => this.__addEntry(),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .label {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          white-space: nowrap;
        }

        .parent-data-fields {
          padding: ${CSS_SPACING} 0 0 ${CSS_SPACING};
          display: flex;
          gap: 10px;
        }

        .dates-of-service-container {
          width: 200px;
        }

        .transaction-date-container {
          width: 140px;
        }

        .payment-container,
        .amount-container,
        .payer-container,
        .statement-container,
        .superbill-container {
          width: 120px;
        }

        .patient-container {
          min-width: 120px;
          max-width: 160px;
        }

        .description-container {
          max-width: 300px;
          word-wrap: break-word;
        }

        .invoice-container {
          width: 90px;
        }

        .padding-top {
          padding-top: 10px;
        }

        .line {
          border-top: 1px solid ${CSS_COLOR_GREY_2};
        }

        .wider-gap {
          gap: 50px;
        }

        .icon-remove {
          fill: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
          width: ${CSS_SPACING};
          height: ${CSS_SPACING};
          margin-right: 10px;
          margin-left: -10px;
          margin-top: -10px;
        }

        .created-updated-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding-left: ${CSS_SPACING};
        }

        .note {
          display: flex;
          align-items: center;
        }

        .entry {
          display: block;
          width: 100%;
          height: 150px;
          padding: 8px ${CSS_SPACING} 4px;
        }

        .add-ledger-note-button {
          padding-left: ${CSS_SPACING};
          width: fit-content;
        }

        .payer {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      `,
    ];
  }

  __resetSortOrder() {
    this.state.entries.forEach((entry, idx) => {
      if (entry.sortOrder !== idx) {
        this.formService.apply(`entries.${idx}.sortOrder`, idx);
      }
    });
  }

  __removeEntry(idx) {
    this.formService.removeItem('entries', idx);
    this.__resetSortOrder();
  }

  __addEntry() {
    const idx = this.state.entries.length;
    this.formService.addItem('entries', idx);
    this.__resetSortOrder();
  }

  __getUser(id) {
    const user = this.practiceUsers.find(pu => pu.id === id);

    return user ? objToName(user.name, { reverse: true }) : '-';
  }

  __createdMatchesUpdated(idx) {
    return (
      this.state.entries[idx].createdAt === this.state.entries[idx].updatedAt
    );
  }

  __getUpdatedByUser(idx) {
    if (this.__createdMatchesUpdated(idx)) return 'N/A';

    return this.__getUser(this.state.entries[idx].updatedBy);
  }

  __isNewNote(idx) {
    return !this.state.entries[idx].id.length;
  }

  __renderDatesOfServices() {
    return html`
      <div class="dates-of-service-container">
        <div id="${ELEMENTS.datesOfServiceLabel.id}" class="label">
          Dates of Service
        </div>
        <div id="${ELEMENTS.datesOfService.id}" class="padding-top">
          ${this.parentData.datesOfService}
        </div>
      </div>
    `;
  }

  __renderTransactionDate() {
    return html`
      <div class="transaction-date-container">
        <div id="${ELEMENTS.transactionDateLabel.id}" class="label">
          ${
            this.parentType === BILLING_NOTE_TYPES.STATEMENT ||
            this.parentType === BILLING_NOTE_TYPES.SUPERBILL
              ? 'Date'
              : 'Transaction Date'
          }
        </div>
        <div id="${ELEMENTS.transactionDate.id}" class="padding-top">
          ${this.parentData.transactionDate}
        </div>
      </div>
    `;
  }

  __renderAmountAndPatient() {
    return html`
      <div class="amount-container">
        <div id="${ELEMENTS.amountLabel.id}" class="label">
          ${
            this.parentType === BILLING_NOTE_TYPES.STATEMENT
              ? 'Balance'
              : 'Total Charges'
          }
        </div>
        <div id="${ELEMENTS.amount.id}" class="padding-top">
          ${centsToCurrency(this.parentData.amount)}
        </div>
      </div>

      <div class="patient-container">
        <div id="${ELEMENTS.payerLabel.id}" class="label">Patient</div>
        <div id="${ELEMENTS.payer.id}" class="padding-top payer">
          ${this.parentData.payer}
        </div>
      </div>
    `;
  }

  __renderAmountAndPayer() {
    return html`
      <div class="amount-container">
        <div id="${ELEMENTS.amountLabel.id}" class="label">Amount</div>
        <div id="${ELEMENTS.amount.id}" class="padding-top">
          ${centsToCurrency(this.parentData.amount)}
        </div>
      </div>

      <div class="payer-container">
        <div id="${ELEMENTS.payerLabel.id}" class="label">Payer</div>
        <div id="${ELEMENTS.payer.id}" class="padding-top payer">
          ${this.parentData.payer}
        </div>
      </div>
    `;
  }

  __renderPaymentData() {
    const paymentLabel =
      this.parentData.paymentType === 'Discount' ? 'Discount ID' : 'Payment ID';

    if (
      this.parentData.paymentType === 'ERA' ||
      this.parentData.paymentType === 'EOB'
    ) {
      return html`
        <div class="parent-data-fields">
          ${this.__renderTransactionDate()}

          <div class="payment-container">
            <div id="${ELEMENTS.eobEraLabel.id}" class="label">
              ${this.parentData.paymentType} ID
            </div>
            <div id="${ELEMENTS.eobEra.id}" class="padding-top">
              ${this.parentData.eobEraId}
            </div>
          </div>

          ${this.__renderAmountAndPayer()}

          <div class="payment-container">
            <div id="${ELEMENTS.paymentLabel.id}" class="label">Payment ID</div>
            <div id="${ELEMENTS.payment.id}" class="padding-top">
              ${this.parentData.paymentId}
            </div>
          </div>
        </div>
      `;
    }
    return html`
      <div class="parent-data-fields">
        ${this.__renderTransactionDate()}

        <div class="payment-container">
          <div id="${ELEMENTS.paymentLabel.id}" class="label">
            ${paymentLabel}
          </div>
          <div id="${ELEMENTS.payment.id}" class="padding-top">
            ${this.parentData.paymentId}
          </div>
        </div>

        ${this.__renderAmountAndPayer()}
      </div>
    `;
  }

  __renderParentData() {
    switch (this.parentType) {
      case BILLING_NOTE_TYPES.CLAIM:
        return html`
          <div class="parent-data-fields">
            ${this.__renderDatesOfServices()}

            <div class="payment-container">
              <div id="${ELEMENTS.claimLabel.id}" class="label">Claim ID</div>
              <div id="${ELEMENTS.claim.id}" class="padding-top">
                ${this.parentData.claimNumber}
              </div>
            </div>

            ${this.__renderAmountAndPayer()}
          </div>
        `;

      case BILLING_NOTE_TYPES.INVOICE:
        return html`
          <div class="parent-data-fields">
            ${this.__renderDatesOfServices()}

            <div class="invoice-container">
              <div id="${ELEMENTS.invoiceLabel.id}" class="label">Invoice</div>
              <div id="${ELEMENTS.invoice.id}" class="padding-top">
                ${this.parentData.invoice}
              </div>
            </div>
          </div>
        `;

      case BILLING_NOTE_TYPES.STATEMENT:
        return html`
          <div class="parent-data-fields">
            ${this.__renderTransactionDate()}

            <div class="statement-container">
              <div id="${ELEMENTS.statementLabel.id}" class="label">
                Statement ID
              </div>
              <div id="${ELEMENTS.statement.id}" class="padding-top">
                ${this.parentData.statement}
              </div>
            </div>

            ${this.__renderAmountAndPatient()}
          </div>
        `;

      case BILLING_NOTE_TYPES.SUPERBILL:
        return html`
          <div class="parent-data-fields">
            ${this.__renderTransactionDate()}

            <div class="superbill-container">
              <div id="${ELEMENTS.superbillLabel.id}" class="label">
                Superbill ID
              </div>
              <div id="${ELEMENTS.superbill.id}" class="padding-top">
                ${this.parentData.superbill}
              </div>
            </div>

            ${this.__renderAmountAndPatient()}
          </div>
        `;

      case BILLING_NOTE_TYPES.PAYMENT:
        return this.__renderPaymentData();

      case BILLING_NOTE_TYPES.CHARGE:
        return html`
          <div class="parent-data-fields wider-gap">
            <div class="date-of-service-container">
              <div id="${ELEMENTS.datesOfServiceLabel.id}" class="label">
                Date of Service
              </div>
              <div id="${ELEMENTS.datesOfService.id}" class="padding-top">
                ${this.parentData.dateOfService}
              </div>
            </div>

            <div class="charge-container">
              <div id="${ELEMENTS.chargeLabel.id}" class="label">Charge</div>
              <div id="${ELEMENTS.charge.id}" class="padding-top">
                ${this.parentData.chargeNumber}
              </div>
            </div>

            <div class="code-container">
              <div id="${ELEMENTS.codeLabel.id}" class="label">Code</div>
              <div id="${ELEMENTS.code.id}" class="padding-top">
                ${this.parentData.code}
              </div>
            </div>

            <div class="description-container">
              <div id="${ELEMENTS.descriptionLabel.id}" class="label">
                Description
              </div>
              <div id="${ELEMENTS.description.id}" class="padding-top">
                ${this.parentData.description}
              </div>
            </div>
          </div>
        `;
      default:
        return '';
    }
  }

  __renderRemove(idx) {
    return this.state.entries.length > 1
      ? html`
          <neb-icon
            id="${ELEMENTS.removeButton.id}-${idx}"
            name="button.${idx}.remove"
            class="icon-remove"
            icon="neb:minus"
            @click="${() => this.handlers.remove(idx)}"
          ></neb-icon>
        `
      : '';
  }

  __renderEntry(idx) {
    return html`
      <div>
        <div class="note">
          <neb-textarea
            id="${ELEMENTS.entry.id}-${idx}"
            class="entry"
            name="entries.${idx}.note"
            maxLength="1000"
            showCount="${true}"
            .value="${this.state.entries[idx].note}"
            .error="${this.errors.entries[idx].note}"
            .onChange="${this.handlers.change}"
          ></neb-textarea>
          ${this.__renderRemove(idx)}
        </div>

        <div class="created-updated-container">
          <div
            id="${ELEMENTS.createdContainer.id}-${idx}"
            ?hidden="${this.__isNewNote(idx)}"
          >
            <div class="label">Created:</div>
            <div id="${ELEMENTS.createdBy.id}-${idx}">
              ${this.__getUser(this.state.entries[idx].createdBy)}
            </div>
            <div id="${ELEMENTS.createdAt.id}-${idx}">
              ${
                parseDate(this.state.entries[idx].createdAt).format(
                  MONTH_DAY_YEAR_TIME,
                )
              }
            </div>
          </div>

          <div
            id="${ELEMENTS.updatedContainer.id}-${idx}"
            ?hidden="${this.__isNewNote(idx)}"
          >
            <div class="label">Last Edited:</div>
            <div id="${ELEMENTS.updatedBy.id}-${idx}">
              ${this.__getUpdatedByUser(idx)}
            </div>
            <div
              id="${ELEMENTS.updatedAt.id}-${idx}"
              ?hidden="${this.__createdMatchesUpdated(idx)}"
            >
              ${
                parseDate(this.state.entries[idx].updatedAt).format(
                  MONTH_DAY_YEAR_TIME,
                )
              }
            </div>
          </div>
        </div>
      </div>

      <div class="line"></div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderParentData()}

      <div class="line"></div>

      ${this.state.entries.map((_, idx) => this.__renderEntry(idx))}

      <neb-button-action
        id="${ELEMENTS.addLedgerNoteButton.id}"
        class="add-ledger-note-button"
        label="Add Ledger Note"
        .onClick="${this.handlers.add}"
      ></neb-button-action>
    `;
  }
}

customElements.define('neb-form-billing-note', NebFormBillingNote);
