/* 
    WARNING!!!!

  This component extends NebLightDom which does not use shadowDom.

  Any styling or element changes can have global effects.
*/

import '../../../../../src/components/controls/inputs/neb-light-textfield';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import validator from 'validator';

import { LightDom } from '../../../../../src/components/misc/neb-light-dom';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_HEADER,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import {
  forgotPassword,
  confirmPassword,
  init,
} from '../../../../neb-utils/cognito-util';
import { BANNER_RESEND_SUCCESS } from '../../../../neb-www-settings/src/components/user-maintenance/neb-user-maintenance-page';
import { BUTTON_ROLE } from '../neb-button';

import { TEXT_ERROR_REPEATED_PASSWORD } from './neb-password-form';
import { validatePassword } from './password-validator';

export const ELEMENTS = {
  buttonCancel: { id: 'neb-forgot-password-button-cancel' },
  emailInput: { id: 'neb-forgot-password-input-email' },
  verificationInput: { id: 'neb-forgot-password-verification-code' },
  passwordInput: { id: 'neb-forgot-password-password-input' },
  verifyPasswordInput: { id: 'neb-forgot-password-verify-password-input' },
  sendVerificationButton: {
    id: 'neb-forgot-password-button-send-verification',
  },
  confirmPasswordButton: { id: 'neb-forgot-password-button-confirm-password' },
};

export const TEXT_SUCCESS_TOASTER_CONFIRM = 'Password changed successfully';
export const TEXT_SUCCESS_TOASTER_EMAIL =
  'A temporary password has been sent to your email';
export const TEXT_ERROR_TOASTER_CONFIRM = 'Error changing password';
export const TEXT_ERROR_TOASTER_EMAIL = 'An error occurred';
export const TEXT_REQUIRED = 'Required';
export const patientType = 'patient';

const TEXT_HEADER = 'Reset Password';
const TEXT_ERROR_EMAIL = 'example@mail.com';
const TEXT_INFO =
  'Please enter the e-mail address associated with your account and we will email you instructions to reset your password.';
const LABEL_BUTTON_CONFIRM_PASSWORD = 'Confirm Password';
const LABEL_BUTTON_VERIFICATION = 'Send Verification';
const LABEL_EMAIL = 'Email Address';
const LABEL_PASSWORD = 'New Password';
const LABEL_VERIFICATION = 'Verification Code';
const LABEL_VERIFY_PASSWORD = 'Confirm Password';

class NebForgotPassword extends LightDom {
  static get properties() {
    return {
      __emailSent: Boolean,
      __errors: Object,
      __password: Number,
      __verificationCode: Number,
      __verifyPassword: Number,

      byPassEmail: Boolean,
      email: String,
      type: String,

      bookingAccountInviteId: String,
      redirectTo: String,
      redirectFrom: String,
      emailFromInvite: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .neb-forgot-password-container {
          display: block;
          width: 100%;
          height: 100%;
        }

        .neb-forgot-password-label-header {
          margin-bottom: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .neb-forgot-password-label-info {
          margin-bottom: ${CSS_SPACING};
        }

        .neb-forgot-password-textfield {
          display: flex;
          margin-bottom: 12px;
        }

        .neb-forgot-password-footer {
          margin-top: 10px;
        }

        .neb-forgot-password-password-form {
          display: block;
          margin-bottom: 10px;
        }

        .neb-forgot-password-container-buttons {
          display: flex;
          padding-top: 10px;
        }

        .neb-forgot-password-button {
          display: flex;
          width: fit-content;
        }

        .neb-forgot-password-button-password,
        .neb-forgot-password-button-verification {
          margin-right: 10px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__emailSent = false;
    this.__verificationCode = '';
    this.__password = '';
    this.__verifyPassword = '';
    this.__errors = {
      email: '',
      verificationCode: '',
      password: '',
      verifyPassword: '',
    };

    this.byPassEmail = false;
    this.email = '';
    this.type = patientType;

    this.bookingAccountInviteId = '';
    this.redirectTo = '';
    this.redirectFrom = '';
    this.emailFromInvite = '';

    this.onCancel = () => {};

    this.onEmailSent = () => {};

    this.onPasswordResent = () => {};

    this.onPasswordChanged = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      cancel: async () => {
        if (this.__emailSent) {
          const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
            title: 'Password Reset',
            message: `A code has been sent to ${
              this.email
            }. Are you sure you want to leave?`,
          });

          if (result) {
            this.onCancel(this.__buildBookingInviteOpts());
          }
        } else {
          this.onCancel(this.__buildBookingInviteOpts());
        }
      },
      keyPressed: e => {
        if (e.key === 'Enter') {
          this.__handlers.sendVerificationCodeEmail();
        }
      },
      emailInputChanged: ({ value }) => {
        this.__errors = { ...this.__errors, email: '' };
        this.email = value;
      },
      verificationInputChanged: ({ value }) => {
        this.__errors = { ...this.__errors, verificationCode: '' };
        this.__verificationCode = value;
      },
      passwordInputChanged: ({ value }) => {
        this.__errors = { ...this.__errors, password: '' };
        this.__password = value;
      },
      verifyPasswordInputChanged: ({ value }) => {
        this.__errors = { ...this.__errors, verifyPassword: '' };
        this.__verifyPassword = value;
      },

      sendVerificationCodeEmail: async () => {
        if (this.__isEmailValid()) {
          try {
            await forgotPassword(this.email);
            this.__emailSent = true;
            this.onEmailSent(true);

            return store.dispatch(openSuccess(TEXT_SUCCESS_TOASTER_EMAIL));
          } catch (e) {
            if (e.code === 'UserNotFoundException') {
              this.__emailSent = true;
              this.onEmailSent(true);
              return store.dispatch(openSuccess(TEXT_SUCCESS_TOASTER_EMAIL));
            }

            if (e.code === 'NotAuthorizedException') {
              this.onPasswordResent(
                this.email,
                this.__buildBookingInviteOpts(),
              );

              return store.dispatch(openSuccess(BANNER_RESEND_SUCCESS));
            }

            return store.dispatch(openError(TEXT_ERROR_TOASTER_EMAIL));
          }
        }

        return undefined;
      },

      confirmPassword: async () => {
        if (!this.__validate()) return;
        let passwordConfirmed;

        try {
          passwordConfirmed = await confirmPassword(
            this.__password,
            this.__verificationCode,
            this.email,
          );

          if (passwordConfirmed) {
            await store.dispatch(openSuccess(TEXT_SUCCESS_TOASTER_CONFIRM));
            this.onPasswordChanged(
              this.email,
              this.__password,
              this.__buildBookingInviteOpts(),
            );
          } else {
            this.__errors = {
              ...this.__errors,
              password: ' ',
              verifyPassword: TEXT_ERROR_REPEATED_PASSWORD,
            };
          }
        } catch (e) {
          console.error(e);
          store.dispatch(openError(TEXT_ERROR_TOASTER_CONFIRM));
        }
      },
      validateVerificationCode: () => !this.__errors.verificationCode,
      validatePassword: () => !this.__errors.password,
      validateConfirmPassword: () => !this.__errors.verifyPassword,
    };
  }

  __buildBookingInviteOpts() {
    return {
      ...(this.bookingAccountInviteId
        ? { bookingAccountInviteId: this.bookingAccountInviteId }
        : {}),
      ...(this.redirectTo ? { redirectTo: this.redirectTo } : {}),
      ...(this.redirectFrom ? { redirectFrom: this.redirectFrom } : {}),
    };
  }

  __isEmailValid() {
    const error = validator.isEmail(this.email) ? '' : TEXT_ERROR_EMAIL;

    this.__errors = { ...this.__errors, email: error };

    return validator.isEmail(this.email);
  }

  __validate() {
    this.__errors = {
      verificationCode: this.__verificationCode ? '' : TEXT_REQUIRED,
      ...validatePassword(this.__password, this.__verifyPassword).errors,
    };

    return Object.values(this.__errors).every(v => !v);
  }

  update(changedProps) {
    if (changedProps.has('type') && this.type) init(this.type);
    if (changedProps.has('byPassEmail')) this.__emailSent = this.byPassEmail;

    if (changedProps.has('emailFromInvite') && this.emailFromInvite) {
      this.email = this.emailFromInvite;
    }

    super.update(changedProps);
  }

  __renderChangePasswordForm() {
    return this.__emailSent
      ? html`
          <div class="neb-forgot-password-password-form">
            <neb-light-textfield
              id="${ELEMENTS.verificationInput.id}"
              class="neb-forgot-password-textfield neb-forgot-password-textfield-code"
              autoComplete="one-time-code"
              maxLength="6"
              type="text"
              .error="${this.__errors.verificationCode}"
              .helper="${TEXT_REQUIRED}"
              .label="${LABEL_VERIFICATION}"
              .value="${this.__verificationCode}"
              .onblur="${this.__handlers.blurVerificationCode}"
              .onChange="${this.__handlers.verificationInputChanged}"
            ></neb-light-textfield>

            <neb-light-textfield
              id="${ELEMENTS.passwordInput.id}"
              class="neb-forgot-password-textfield neb-forgot-password-textfield-password"
              autoComplete="new-password"
              type="password"
              .error="${this.__errors.password}"
              .helper="${TEXT_REQUIRED}"
              .label="${LABEL_PASSWORD}"
              .value="${this.__password}"
              .onblur="${this.__handlers.blurPassword}"
              .onChange="${this.__handlers.passwordInputChanged}"
            ></neb-light-textfield>

            <neb-light-textfield
              id="${ELEMENTS.verifyPasswordInput.id}"
              class="neb-forgot-password-textfield neb-forgot-password-textfield-verify-password"
              autoComplete="new-password"
              type="password"
              .error="${this.__errors.verifyPassword}"
              .helper="${TEXT_REQUIRED}"
              .label="${LABEL_VERIFY_PASSWORD}"
              .value="${this.__verifyPassword}"
              .onblur="${this.__handlers.blurConfirmPassword}"
              .onChange="${this.__handlers.verifyPasswordInputChanged}"
            ></neb-light-textfield>
          </div>
        `
      : '';
  }

  __renderButton() {
    return html`
      <div class="neb-forgot-password-container-buttons">
        ${
          this.__emailSent
            ? html`
                <neb-button
                  id="${ELEMENTS.confirmPasswordButton.id}"
                  class="neb-forgot-password-button neb-forgot-password-button-password"
                  .label="${LABEL_BUTTON_CONFIRM_PASSWORD}"
                  .role="${BUTTON_ROLE.CONFIRM}"
                  .onClick="${this.__handlers.confirmPassword}"
                ></neb-button>
              `
            : html`
                <neb-button
                  id="${ELEMENTS.sendVerificationButton.id}"
                  class="neb-forgot-password-button neb-forgot-password-button-verification"
                  .label="${LABEL_BUTTON_VERIFICATION}"
                  .role="${BUTTON_ROLE.CONFIRM}"
                  .onClick="${this.__handlers.sendVerificationCodeEmail}"
                ></neb-button>
              `
        }

        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          class="neb-forgot-password-button neb-forgot-password-button-cancel"
          label="Cancel"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.__handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="neb-forgot-password-container">
        <div class="neb-forgot-password-label neb-forgot-password-label-header">
          ${TEXT_HEADER}
        </div>

        <div class="neb-forgot-password-label neb-forgot-password-label-info">
          ${TEXT_INFO}
        </div>

        <neb-light-textfield
          id="${ELEMENTS.emailInput.id}"
          class="neb-forgot-password-textfield neb-forgot-password-textfield-email"
          autoComplete="email"
          type="email"
          inputMode="email"
          maxLength="50"
          .error="${this.__errors.email}"
          .helper="${TEXT_REQUIRED}"
          .label="${LABEL_EMAIL}"
          .onBlur="${this.__handlers.blur}"
          .onChange="${this.__handlers.emailInputChanged}"
          .value="${this.emailFromInvite || this.email}"
          ?disabled="${this.__emailSent || !!this.emailFromInvite}"
          @keypress="${this.__handlers.keyPressed}"
          required
        ></neb-light-textfield>

        ${this.__renderChangePasswordForm()} ${this.__renderButton()}
      </div>
    `;
  }
}

customElements.define('neb-forgot-password', NebForgotPassword);
