import { ITEM_EMPTY } from '../../../neb-utils/selectors';

const TAXONOMY = Object.freeze({
  '101Y00000X - Counselor': {
    code: '101Y00000X',
    description: 'Counselor',
    label: '101Y00000X - Counselor',
  },
  '101YA0400X - Counselor - Addiction (Substance Use Disorder)': {
    code: '101YA0400X',
    description: 'Counselor',
    label: '101YA0400X - Counselor - Addiction (Substance Use Disorder)',
  },
  '101YM0800X - Counselor - Mental Health': {
    code: '101YM0800X',
    description: 'Counselor',
    label: '101YM0800X - Counselor - Mental Health',
  },
  '101YP1600X - Counselor - Pastoral': {
    code: '101YP1600X',
    description: 'Counselor',
    label: '101YP1600X - Counselor - Pastoral',
  },
  '101YP2500X - Counselor - Professional': {
    code: '101YP2500X',
    description: 'Counselor',
    label: '101YP2500X - Counselor - Professional',
  },
  '101YS0200X - Counselor - School': {
    code: '101YS0200X',
    description: 'Counselor',
    label: '101YS0200X - Counselor - School',
  },
  '102L00000X - Psychoanalyst': {
    code: '102L00000X',
    description: 'Psychoanalyst',
    label: '102L00000X - Psychoanalyst',
  },
  '102X00000X - Poetry Therapist': {
    code: '102X00000X',
    description: 'Poetry Therapist',
    label: '102X00000X - Poetry Therapist',
  },
  '103G00000X - Clinical Neuropsychologist': {
    code: '103G00000X',
    description: 'Clinical Neuropsychologist',
    label: '103G00000X - Clinical Neuropsychologist',
  },
  '103GC0700X - Clinical Neuropsychologist - Clinical': {
    code: '103GC0700X',
    description: 'Clinical Neuropsychologist',
    label: '103GC0700X - Clinical Neuropsychologist - Clinical',
  },
  '103K00000X - Behavior Analyst': {
    code: '103K00000X',
    description: 'Behavior Analyst',
    label: '103K00000X - Behavior Analyst',
  },
  '103T00000X - Psychologist': {
    code: '103T00000X',
    description: 'Psychologist',
    label: '103T00000X - Psychologist',
  },
  '103TA0400X - Psychologist - Addiction (Substance Use Disorder)': {
    code: '103TA0400X',
    description: 'Psychologist',
    label: '103TA0400X - Psychologist - Addiction (Substance Use Disorder)',
  },
  '103TA0700X - Psychologist - Adult Development & Aging': {
    code: '103TA0700X',
    description: 'Psychologist',
    label: '103TA0700X - Psychologist - Adult Development & Aging',
  },
  '103TB0200X - Psychologist - Cognitive & Behavioral': {
    code: '103TB0200X',
    description: 'Psychologist',
    label: '103TB0200X - Psychologist - Cognitive & Behavioral',
  },
  '103TC0700X - Psychologist - Clinical': {
    code: '103TC0700X',
    description: 'Psychologist',
    label: '103TC0700X - Psychologist - Clinical',
  },
  '103TC1900X - Psychologist - Counseling': {
    code: '103TC1900X',
    description: 'Psychologist',
    label: '103TC1900X - Psychologist - Counseling',
  },
  '103TC2200X - Psychologist - Clinical Child & Adolescent': {
    code: '103TC2200X',
    description: 'Psychologist',
    label: '103TC2200X - Psychologist - Clinical Child & Adolescent',
  },
  '103TE1000X - Psychologist - Educational': {
    code: '103TE1000X',
    description: 'Psychologist',
    label: '103TE1000X - Psychologist - Educational',
  },
  '103TE1100X - Psychologist - Exercise & Sports': {
    code: '103TE1100X',
    description: 'Psychologist',
    label: '103TE1100X - Psychologist - Exercise & Sports',
  },
  '103TF0000X - Psychologist - Family': {
    code: '103TF0000X',
    description: 'Psychologist',
    label: '103TF0000X - Psychologist - Family',
  },
  '103TF0200X - Psychologist - Forensic': {
    code: '103TF0200X',
    description: 'Psychologist',
    label: '103TF0200X - Psychologist - Forensic',
  },
  '103TH0004X - Psychologist - Health': {
    code: '103TH0004X',
    description: 'Psychologist',
    label: '103TH0004X - Psychologist - Health',
  },
  '103TH0100X - Psychologist - Health Service': {
    code: '103TH0100X',
    description: 'Psychologist',
    label: '103TH0100X - Psychologist - Health Service',
  },
  '103TM1700X - Psychologist - Men & Masculinity': {
    code: '103TM1700X',
    description: 'Psychologist',
    label: '103TM1700X - Psychologist - Men & Masculinity',
  },
  '103TM1800X - Psychologist - Mental Retardation & Developmental Disabilities': {
    code: '103TM1800X',
    description: 'Psychologist',
    label:
      '103TM1800X - Psychologist - Mental Retardation & Developmental Disabilities',
  },
  '103TP0016X - Psychologist - Prescribing (Medical)': {
    code: '103TP0016X',
    description: 'Psychologist',
    label: '103TP0016X - Psychologist - Prescribing (Medical)',
  },
  '103TP0814X - Psychologist - Psychoanalysis': {
    code: '103TP0814X',
    description: 'Psychologist',
    label: '103TP0814X - Psychologist - Psychoanalysis',
  },
  '103TP2700X - Psychologist - Psychotherapy': {
    code: '103TP2700X',
    description: 'Psychologist',
    label: '103TP2700X - Psychologist - Psychotherapy',
  },
  '103TP2701X - Psychologist - Group Psychotherapy': {
    code: '103TP2701X',
    description: 'Psychologist',
    label: '103TP2701X - Psychologist - Group Psychotherapy',
  },
  '103TR0400X - Psychologist - Rehabilitation': {
    code: '103TR0400X',
    description: 'Psychologist',
    label: '103TR0400X - Psychologist - Rehabilitation',
  },
  '103TS0200X - Psychologist - School': {
    code: '103TS0200X',
    description: 'Psychologist',
    label: '103TS0200X - Psychologist - School',
  },
  '103TW0100X - Psychologist - Women': {
    code: '103TW0100X',
    description: 'Psychologist',
    label: '103TW0100X - Psychologist - Women',
  },
  '104100000X - Social Worker': {
    code: '104100000X',
    description: 'Social Worker',
    label: '104100000X - Social Worker',
  },
  '1041C0700X - Social Worker - Clinical': {
    code: '1041C0700X',
    description: 'Social Worker',
    label: '1041C0700X - Social Worker - Clinical',
  },
  '1041S0200X - Social Worker - School': {
    code: '1041S0200X',
    description: 'Social Worker',
    label: '1041S0200X - Social Worker - School',
  },
  '106E00000X - Assistant Behavior Analyst': {
    code: '106E00000X',
    description: 'Assistant Behavior Analyst',
    label: '106E00000X - Assistant Behavior Analyst',
  },
  '106H00000X - Marriage & Family Therapist': {
    code: '106H00000X',
    description: 'Marriage & Family Therapist',
    label: '106H00000X - Marriage & Family Therapist',
  },
  '106S00000X - Behavior Technician': {
    code: '106S00000X',
    description: 'Behavior Technician',
    label: '106S00000X - Behavior Technician',
  },
  '111N00000X - Chiropractor': {
    code: '111N00000X',
    description: 'Chiropractor',
    label: '111N00000X - Chiropractor',
  },
  '111NI0013X - Chiropractor - Independent Medical Examiner': {
    code: '111NI0013X',
    description: 'Chiropractor',
    label: '111NI0013X - Chiropractor - Independent Medical Examiner',
  },
  '111NI0900X - Chiropractor - Internist': {
    code: '111NI0900X',
    description: 'Chiropractor',
    label: '111NI0900X - Chiropractor - Internist',
  },
  '111NN0400X - Chiropractor - Neurology': {
    code: '111NN0400X',
    description: 'Chiropractor',
    label: '111NN0400X - Chiropractor - Neurology',
  },
  '111NN1001X - Chiropractor - Nutrition': {
    code: '111NN1001X',
    description: 'Chiropractor',
    label: '111NN1001X - Chiropractor - Nutrition',
  },
  '111NP0017X - Chiropractor - Pediatric Chiropractor': {
    code: '111NP0017X',
    description: 'Chiropractor',
    label: '111NP0017X - Chiropractor - Pediatric Chiropractor',
  },
  '111NR0200X - Chiropractor - Radiology': {
    code: '111NR0200X',
    description: 'Chiropractor',
    label: '111NR0200X - Chiropractor - Radiology',
  },
  '111NR0400X - Chiropractor - Rehabilitation': {
    code: '111NR0400X',
    description: 'Chiropractor',
    label: '111NR0400X - Chiropractor - Rehabilitation',
  },
  '111NS0005X - Chiropractor - Sports Physician': {
    code: '111NS0005X',
    description: 'Chiropractor',
    label: '111NS0005X - Chiropractor - Sports Physician',
  },
  '111NT0100X - Chiropractor - Thermography': {
    code: '111NT0100X',
    description: 'Chiropractor',
    label: '111NT0100X - Chiropractor - Thermography',
  },
  '111NX0100X - Chiropractor - Occupational Health': {
    code: '111NX0100X',
    description: 'Chiropractor',
    label: '111NX0100X - Chiropractor - Occupational Health',
  },
  '111NX0800X - Chiropractor - Orthopedic': {
    code: '111NX0800X',
    description: 'Chiropractor',
    label: '111NX0800X - Chiropractor - Orthopedic',
  },
  '122300000X - Dentist': {
    code: '122300000X',
    description: 'Dentist',
    label: '122300000X - Dentist',
  },
  '1223D0001X - Dentist - Dental Public Health': {
    code: '1223D0001X',
    description: 'Dentist',
    label: '1223D0001X - Dentist - Dental Public Health',
  },
  '1223D0004X - Dentist - Dentist Anesthesiologist': {
    code: '1223D0004X',
    description: 'Dentist',
    label: '1223D0004X - Dentist - Dentist Anesthesiologist',
  },
  '1223E0200X - Dentist - Endodontics': {
    code: '1223E0200X',
    description: 'Dentist',
    label: '1223E0200X - Dentist - Endodontics',
  },
  '1223G0001X - Dentist - General Practice': {
    code: '1223G0001X',
    description: 'Dentist',
    label: '1223G0001X - Dentist - General Practice',
  },
  '1223P0106X - Dentist - Oral and Maxillofacial Pathology': {
    code: '1223P0106X',
    description: 'Dentist',
    label: '1223P0106X - Dentist - Oral and Maxillofacial Pathology',
  },
  '1223P0221X - Dentist - Pediatric Dentistry': {
    code: '1223P0221X',
    description: 'Dentist',
    label: '1223P0221X - Dentist - Pediatric Dentistry',
  },
  '1223P0300X - Dentist - Periodontics': {
    code: '1223P0300X',
    description: 'Dentist',
    label: '1223P0300X - Dentist - Periodontics',
  },
  '1223P0700X - Dentist - Prosthodontics': {
    code: '1223P0700X',
    description: 'Dentist',
    label: '1223P0700X - Dentist - Prosthodontics',
  },
  '1223S0112X - Dentist - Oral and Maxillofacial Surgery': {
    code: '1223S0112X',
    description: 'Dentist',
    label: '1223S0112X - Dentist - Oral and Maxillofacial Surgery',
  },
  '1223X0008X - Dentist - Oral and Maxillofacial Radiology': {
    code: '1223X0008X',
    description: 'Dentist',
    label: '1223X0008X - Dentist - Oral and Maxillofacial Radiology',
  },
  '1223X0400X - Dentist - Orthodontics and Dentofacial Orthopedics': {
    code: '1223X0400X',
    description: 'Dentist',
    label: '1223X0400X - Dentist - Orthodontics and Dentofacial Orthopedics',
  },
  '1223X2210X - Dentist - Orofacial Pain': {
    code: '1223X2210X',
    description: 'Dentist',
    label: '1223X2210X - Dentist - Orofacial Pain',
  },
  '122400000X - Denturist': {
    code: '122400000X',
    description: 'Denturist',
    label: '122400000X - Denturist',
  },
  '124Q00000X - Dental Hygienist': {
    code: '124Q00000X',
    description: 'Dental Hygienist',
    label: '124Q00000X - Dental Hygienist',
  },
  '125J00000X - Dental Therapist': {
    code: '125J00000X',
    description: 'Dental Therapist',
    label: '125J00000X - Dental Therapist',
  },
  '125K00000X - Advanced Practice Dental Therapist': {
    code: '125K00000X',
    description: 'Advanced Practice Dental Therapist',
    label: '125K00000X - Advanced Practice Dental Therapist',
  },
  '125Q00000X - Oral Medicinist': {
    code: '125Q00000X',
    description: 'Oral Medicinist',
    label: '125Q00000X - Oral Medicinist',
  },
  '126800000X - Dental Assistant': {
    code: '126800000X',
    description: 'Dental Assistant',
    label: '126800000X - Dental Assistant',
  },
  '126900000X - Dental Laboratory Technician': {
    code: '126900000X',
    description: 'Dental Laboratory Technician',
    label: '126900000X - Dental Laboratory Technician',
  },
  '132700000X - Dietary Manager': {
    code: '132700000X',
    description: 'Dietary Manager',
    label: '132700000X - Dietary Manager',
  },
  '133N00000X - Nutritionist': {
    code: '133N00000X',
    description: 'Nutritionist',
    label: '133N00000X - Nutritionist',
  },
  '133NN1002X - Nutritionist - Nutrition, Education': {
    code: '133NN1002X',
    description: 'Nutritionist',
    label: '133NN1002X - Nutritionist - Nutrition, Education',
  },
  '133V00000X - Dietitian, Registered': {
    code: '133V00000X',
    description: 'Dietitian, Registered',
    label: '133V00000X - Dietitian, Registered',
  },
  '133VN1004X - Dietitian, Registered - Nutrition, Pediatric': {
    code: '133VN1004X',
    description: 'Dietitian, Registered',
    label: '133VN1004X - Dietitian, Registered - Nutrition, Pediatric',
  },
  '133VN1005X - Dietitian, Registered - Nutrition, Renal': {
    code: '133VN1005X',
    description: 'Dietitian, Registered',
    label: '133VN1005X - Dietitian, Registered - Nutrition, Renal',
  },
  '133VN1006X - Dietitian, Registered - Nutrition, Metabolic': {
    code: '133VN1006X',
    description: 'Dietitian, Registered',
    label: '133VN1006X - Dietitian, Registered - Nutrition, Metabolic',
  },
  '133VN1101X - Dietitian, Registered - Nutrition, Gerontological': {
    code: '133VN1101X',
    description: 'Dietitian, Registered',
    label: '133VN1101X - Dietitian, Registered - Nutrition, Gerontological',
  },
  '133VN1201X - Dietitian, Registered - Nutrition, Obesity and Weight Management': {
    code: '133VN1201X',
    description: 'Dietitian, Registered',
    label:
      '133VN1201X - Dietitian, Registered - Nutrition, Obesity and Weight Management',
  },
  '133VN1301X - Dietitian, Registered - Nutrition, Oncology': {
    code: '133VN1301X',
    description: 'Dietitian, Registered',
    label: '133VN1301X - Dietitian, Registered - Nutrition, Oncology',
  },
  '133VN1401X - Dietitian, Registered - Nutrition, Pediatric Critical Care': {
    code: '133VN1401X',
    description: 'Dietitian, Registered',
    label:
      '133VN1401X - Dietitian, Registered - Nutrition, Pediatric Critical Care',
  },
  '133VN1501X - Dietitian, Registered - Nutrition, Sports Dietetics': {
    code: '133VN1501X',
    description: 'Dietitian, Registered',
    label: '133VN1501X - Dietitian, Registered - Nutrition, Sports Dietetics',
  },
  '136A00000X - Dietetic Technician, Registered': {
    code: '136A00000X',
    description: 'Dietetic Technician, Registered',
    label: '136A00000X - Dietetic Technician, Registered',
  },
  '146D00000X - Personal Emergency Response Attendant': {
    code: '146D00000X',
    description: 'Personal Emergency Response Attendant',
    label: '146D00000X - Personal Emergency Response Attendant',
  },
  '146L00000X - Emergency Medical Technician, Paramedic': {
    code: '146L00000X',
    description: 'Emergency Medical Technician, Paramedic',
    label: '146L00000X - Emergency Medical Technician, Paramedic',
  },
  '146M00000X - Emergency Medical Technician, Intermediate': {
    code: '146M00000X',
    description: 'Emergency Medical Technician, Intermediate',
    label: '146M00000X - Emergency Medical Technician, Intermediate',
  },
  '146N00000X - Emergency Medical Technician, Basic': {
    code: '146N00000X',
    description: 'Emergency Medical Technician, Basic',
    label: '146N00000X - Emergency Medical Technician, Basic',
  },
  '152W00000X - Optometrist': {
    code: '152W00000X',
    description: 'Optometrist',
    label: '152W00000X - Optometrist',
  },
  '152WC0802X - Optometrist - Corneal and Contact Management': {
    code: '152WC0802X',
    description: 'Optometrist',
    label: '152WC0802X - Optometrist - Corneal and Contact Management',
  },
  '152WL0500X - Optometrist - Low Vision Rehabilitation': {
    code: '152WL0500X',
    description: 'Optometrist',
    label: '152WL0500X - Optometrist - Low Vision Rehabilitation',
  },
  '152WP0200X - Optometrist - Pediatrics': {
    code: '152WP0200X',
    description: 'Optometrist',
    label: '152WP0200X - Optometrist - Pediatrics',
  },
  '152WS0006X - Optometrist - Sports Vision': {
    code: '152WS0006X',
    description: 'Optometrist',
    label: '152WS0006X - Optometrist - Sports Vision',
  },
  '152WV0400X - Optometrist - Vision Therapy': {
    code: '152WV0400X',
    description: 'Optometrist',
    label: '152WV0400X - Optometrist - Vision Therapy',
  },
  '152WX0102X - Optometrist - Occupational Vision': {
    code: '152WX0102X',
    description: 'Optometrist',
    label: '152WX0102X - Optometrist - Occupational Vision',
  },
  '156F00000X - Technician/Technologist': {
    code: '156F00000X',
    description: 'Technician/Technologist',
    label: '156F00000X - Technician/Technologist',
  },
  '156FC0800X - Technician/Technologist - Contact Lens': {
    code: '156FC0800X',
    description: 'Technician/Technologist',
    label: '156FC0800X - Technician/Technologist - Contact Lens',
  },
  '156FC0801X - Technician/Technologist - Contact Lens Fitter': {
    code: '156FC0801X',
    description: 'Technician/Technologist',
    label: '156FC0801X - Technician/Technologist - Contact Lens Fitter',
  },
  '156FX1100X - Technician/Technologist - Ophthalmic': {
    code: '156FX1100X',
    description: 'Technician/Technologist',
    label: '156FX1100X - Technician/Technologist - Ophthalmic',
  },
  '156FX1101X - Technician/Technologist - Ophthalmic Assistant': {
    code: '156FX1101X',
    description: 'Technician/Technologist',
    label: '156FX1101X - Technician/Technologist - Ophthalmic Assistant',
  },
  '156FX1201X - Technician/Technologist - Optometric Assistant': {
    code: '156FX1201X',
    description: 'Technician/Technologist',
    label: '156FX1201X - Technician/Technologist - Optometric Assistant',
  },
  '156FX1202X - Technician/Technologist - Optometric Technician': {
    code: '156FX1202X',
    description: 'Technician/Technologist',
    label: '156FX1202X - Technician/Technologist - Optometric Technician',
  },
  '156FX1700X - Technician/Technologist - Ocularist': {
    code: '156FX1700X',
    description: 'Technician/Technologist',
    label: '156FX1700X - Technician/Technologist - Ocularist',
  },
  '156FX1800X - Technician/Technologist - Optician': {
    code: '156FX1800X',
    description: 'Technician/Technologist',
    label: '156FX1800X - Technician/Technologist - Optician',
  },
  '156FX1900X - Technician/Technologist - Orthoptist': {
    code: '156FX1900X',
    description: 'Technician/Technologist',
    label: '156FX1900X - Technician/Technologist - Orthoptist',
  },
  '163W00000X - Registered Nurse': {
    code: '163W00000X',
    description: 'Registered Nurse',
    label: '163W00000X - Registered Nurse',
  },
  '163WA0400X - Registered Nurse - Addiction (Substance Use Disorder)': {
    code: '163WA0400X',
    description: 'Registered Nurse',
    label: '163WA0400X - Registered Nurse - Addiction (Substance Use Disorder)',
  },
  '163WA2000X - Registered Nurse - Administrator': {
    code: '163WA2000X',
    description: 'Registered Nurse',
    label: '163WA2000X - Registered Nurse - Administrator',
  },
  '163WC0200X - Registered Nurse - Critical Care Medicine': {
    code: '163WC0200X',
    description: 'Registered Nurse',
    label: '163WC0200X - Registered Nurse - Critical Care Medicine',
  },
  '163WC0400X - Registered Nurse - Case Management': {
    code: '163WC0400X',
    description: 'Registered Nurse',
    label: '163WC0400X - Registered Nurse - Case Management',
  },
  '163WC1400X - Registered Nurse - College Health': {
    code: '163WC1400X',
    description: 'Registered Nurse',
    label: '163WC1400X - Registered Nurse - College Health',
  },
  '163WC1500X - Registered Nurse - Community Health': {
    code: '163WC1500X',
    description: 'Registered Nurse',
    label: '163WC1500X - Registered Nurse - Community Health',
  },
  '163WC1600X - Registered Nurse - Continuing Education/Staff Development': {
    code: '163WC1600X',
    description: 'Registered Nurse',
    label:
      '163WC1600X - Registered Nurse - Continuing Education/Staff Development',
  },
  '163WC2100X - Registered Nurse - Continence Care': {
    code: '163WC2100X',
    description: 'Registered Nurse',
    label: '163WC2100X - Registered Nurse - Continence Care',
  },
  '163WC3500X - Registered Nurse - Cardiac Rehabilitation': {
    code: '163WC3500X',
    description: 'Registered Nurse',
    label: '163WC3500X - Registered Nurse - Cardiac Rehabilitation',
  },
  '163WD0400X - Registered Nurse - Diabetes Educator': {
    code: '163WD0400X',
    description: 'Registered Nurse',
    label: '163WD0400X - Registered Nurse - Diabetes Educator',
  },
  '163WD1100X - Registered Nurse - Dialysis, Peritoneal': {
    code: '163WD1100X',
    description: 'Registered Nurse',
    label: '163WD1100X - Registered Nurse - Dialysis, Peritoneal',
  },
  '163WE0003X - Registered Nurse - Emergency': {
    code: '163WE0003X',
    description: 'Registered Nurse',
    label: '163WE0003X - Registered Nurse - Emergency',
  },
  '163WE0900X - Registered Nurse - Enterostomal Therapy': {
    code: '163WE0900X',
    description: 'Registered Nurse',
    label: '163WE0900X - Registered Nurse - Enterostomal Therapy',
  },
  '163WF0300X - Registered Nurse - Flight': {
    code: '163WF0300X',
    description: 'Registered Nurse',
    label: '163WF0300X - Registered Nurse - Flight',
  },
  '163WG0000X - Registered Nurse - General Practice': {
    code: '163WG0000X',
    description: 'Registered Nurse',
    label: '163WG0000X - Registered Nurse - General Practice',
  },
  '163WG0100X - Registered Nurse - Gastroenterology': {
    code: '163WG0100X',
    description: 'Registered Nurse',
    label: '163WG0100X - Registered Nurse - Gastroenterology',
  },
  '163WG0600X - Registered Nurse - Gerontology': {
    code: '163WG0600X',
    description: 'Registered Nurse',
    label: '163WG0600X - Registered Nurse - Gerontology',
  },
  '163WH0200X - Registered Nurse - Home Health': {
    code: '163WH0200X',
    description: 'Registered Nurse',
    label: '163WH0200X - Registered Nurse - Home Health',
  },
  '163WH0500X - Registered Nurse - Hemodialysis': {
    code: '163WH0500X',
    description: 'Registered Nurse',
    label: '163WH0500X - Registered Nurse - Hemodialysis',
  },
  '163WH1000X - Registered Nurse - Hospice': {
    code: '163WH1000X',
    description: 'Registered Nurse',
    label: '163WH1000X - Registered Nurse - Hospice',
  },
  '163WI0500X - Registered Nurse - Infusion Therapy': {
    code: '163WI0500X',
    description: 'Registered Nurse',
    label: '163WI0500X - Registered Nurse - Infusion Therapy',
  },
  '163WI0600X - Registered Nurse - Infection Control': {
    code: '163WI0600X',
    description: 'Registered Nurse',
    label: '163WI0600X - Registered Nurse - Infection Control',
  },
  '163WL0100X - Registered Nurse - Lactation Consultant': {
    code: '163WL0100X',
    description: 'Registered Nurse',
    label: '163WL0100X - Registered Nurse - Lactation Consultant',
  },
  '163WM0102X - Registered Nurse - Maternal Newborn': {
    code: '163WM0102X',
    description: 'Registered Nurse',
    label: '163WM0102X - Registered Nurse - Maternal Newborn',
  },
  '163WM0705X - Registered Nurse - Medical-Surgical': {
    code: '163WM0705X',
    description: 'Registered Nurse',
    label: '163WM0705X - Registered Nurse - Medical-Surgical',
  },
  '163WM1400X - Registered Nurse - Nurse Massage Therapist (NMT)': {
    code: '163WM1400X',
    description: 'Registered Nurse',
    label: '163WM1400X - Registered Nurse - Nurse Massage Therapist (NMT)',
  },
  '163WN0002X - Registered Nurse - Neonatal Intensive Care': {
    code: '163WN0002X',
    description: 'Registered Nurse',
    label: '163WN0002X - Registered Nurse - Neonatal Intensive Care',
  },
  '163WN0003X - Registered Nurse - Neonatal, Low-Risk': {
    code: '163WN0003X',
    description: 'Registered Nurse',
    label: '163WN0003X - Registered Nurse - Neonatal, Low-Risk',
  },
  '163WN0300X - Registered Nurse - Nephrology': {
    code: '163WN0300X',
    description: 'Registered Nurse',
    label: '163WN0300X - Registered Nurse - Nephrology',
  },
  '163WN0800X - Registered Nurse - Neuroscience': {
    code: '163WN0800X',
    description: 'Registered Nurse',
    label: '163WN0800X - Registered Nurse - Neuroscience',
  },
  '163WN1003X - Registered Nurse - Nutrition Support': {
    code: '163WN1003X',
    description: 'Registered Nurse',
    label: '163WN1003X - Registered Nurse - Nutrition Support',
  },
  '163WP0000X - Registered Nurse - Pain Management': {
    code: '163WP0000X',
    description: 'Registered Nurse',
    label: '163WP0000X - Registered Nurse - Pain Management',
  },
  '163WP0200X - Registered Nurse - Pediatrics': {
    code: '163WP0200X',
    description: 'Registered Nurse',
    label: '163WP0200X - Registered Nurse - Pediatrics',
  },
  '163WP0218X - Registered Nurse - Pediatric Oncology': {
    code: '163WP0218X',
    description: 'Registered Nurse',
    label: '163WP0218X - Registered Nurse - Pediatric Oncology',
  },
  '163WP0807X - Registered Nurse - Psychiatric/Mental Health, Child & Adolescent': {
    code: '163WP0807X',
    description: 'Registered Nurse',
    label:
      '163WP0807X - Registered Nurse - Psychiatric/Mental Health, Child & Adolescent',
  },
  '163WP0808X - Registered Nurse - Psychiatric/Mental Health': {
    code: '163WP0808X',
    description: 'Registered Nurse',
    label: '163WP0808X - Registered Nurse - Psychiatric/Mental Health',
  },
  '163WP0809X - Registered Nurse - Psychiatric/Mental Health, Adult': {
    code: '163WP0809X',
    description: 'Registered Nurse',
    label: '163WP0809X - Registered Nurse - Psychiatric/Mental Health, Adult',
  },
  '163WP1700X - Registered Nurse - Perinatal': {
    code: '163WP1700X',
    description: 'Registered Nurse',
    label: '163WP1700X - Registered Nurse - Perinatal',
  },
  '163WP2201X - Registered Nurse - Ambulatory Care': {
    code: '163WP2201X',
    description: 'Registered Nurse',
    label: '163WP2201X - Registered Nurse - Ambulatory Care',
  },
  '163WR0006X - Registered Nurse - Registered Nurse First Assistant': {
    code: '163WR0006X',
    description: 'Registered Nurse',
    label: '163WR0006X - Registered Nurse - Registered Nurse First Assistant',
  },
  '163WR0400X - Registered Nurse - Rehabilitation': {
    code: '163WR0400X',
    description: 'Registered Nurse',
    label: '163WR0400X - Registered Nurse - Rehabilitation',
  },
  '163WR1000X - Registered Nurse - Reproductive Endocrinology/Infertility': {
    code: '163WR1000X',
    description: 'Registered Nurse',
    label:
      '163WR1000X - Registered Nurse - Reproductive Endocrinology/Infertility',
  },
  '163WS0121X - Registered Nurse - Plastic Surgery': {
    code: '163WS0121X',
    description: 'Registered Nurse',
    label: '163WS0121X - Registered Nurse - Plastic Surgery',
  },
  '163WS0200X - Registered Nurse - School': {
    code: '163WS0200X',
    description: 'Registered Nurse',
    label: '163WS0200X - Registered Nurse - School',
  },
  '163WU0100X - Registered Nurse - Urology': {
    code: '163WU0100X',
    description: 'Registered Nurse',
    label: '163WU0100X - Registered Nurse - Urology',
  },
  '163WW0000X - Registered Nurse - Wound Care': {
    code: '163WW0000X',
    description: 'Registered Nurse',
    label: '163WW0000X - Registered Nurse - Wound Care',
  },
  '163WW0101X - Registered Nurse - Women`s Health Care, Ambulatory': {
    code: '163WW0101X',
    description: 'Registered Nurse',
    label: "163WW0101X - Registered Nurse - Women's Health Care, Ambulatory",
  },
  '163WX0002X - Registered Nurse - Obstetric, High-Risk': {
    code: '163WX0002X',
    description: 'Registered Nurse',
    label: '163WX0002X - Registered Nurse - Obstetric, High-Risk',
  },
  '163WX0003X - Registered Nurse - Obstetric, Inpatient': {
    code: '163WX0003X',
    description: 'Registered Nurse',
    label: '163WX0003X - Registered Nurse - Obstetric, Inpatient',
  },
  '163WX0106X - Registered Nurse - Occupational Health': {
    code: '163WX0106X',
    description: 'Registered Nurse',
    label: '163WX0106X - Registered Nurse - Occupational Health',
  },
  '163WX0200X - Registered Nurse - Oncology': {
    code: '163WX0200X',
    description: 'Registered Nurse',
    label: '163WX0200X - Registered Nurse - Oncology',
  },
  '163WX0601X - Registered Nurse - Otorhinolaryngology & Head-Neck': {
    code: '163WX0601X',
    description: 'Registered Nurse',
    label: '163WX0601X - Registered Nurse - Otorhinolaryngology & Head-Neck',
  },
  '163WX0800X - Registered Nurse - Orthopedic': {
    code: '163WX0800X',
    description: 'Registered Nurse',
    label: '163WX0800X - Registered Nurse - Orthopedic',
  },
  '163WX1100X - Registered Nurse - Ophthalmic': {
    code: '163WX1100X',
    description: 'Registered Nurse',
    label: '163WX1100X - Registered Nurse - Ophthalmic',
  },
  '163WX1500X - Registered Nurse - Ostomy Care': {
    code: '163WX1500X',
    description: 'Registered Nurse',
    label: '163WX1500X - Registered Nurse - Ostomy Care',
  },
  '164W00000X - Licensed Practical Nurse': {
    code: '164W00000X',
    description: 'Licensed Practical Nurse',
    label: '164W00000X - Licensed Practical Nurse',
  },
  '164X00000X - Licensed Vocational Nurse': {
    code: '164X00000X',
    description: 'Licensed Vocational Nurse',
    label: '164X00000X - Licensed Vocational Nurse',
  },
  '167G00000X - Licensed Psychiatric Technician': {
    code: '167G00000X',
    description: 'Licensed Psychiatric Technician',
    label: '167G00000X - Licensed Psychiatric Technician',
  },
  '170100000X - Medical Genetics, Ph.D. Medical Genetics': {
    code: '170100000X',
    description: 'Medical Genetics, Ph.D. Medical Genetics',
    label: '170100000X - Medical Genetics, Ph.D. Medical Genetics',
  },
  '170300000X - Genetic Counselor, MS': {
    code: '170300000X',
    description: 'Genetic Counselor, MS',
    label: '170300000X - Genetic Counselor, MS',
  },
  '171000000X - Military Health Care Provider': {
    code: '171000000X',
    description: 'Military Health Care Provider',
    label: '171000000X - Military Health Care Provider',
  },
  '1710I1002X - Military Health Care Provider - Independent Duty Corpsman': {
    code: '1710I1002X',
    description: 'Military Health Care Provider',
    label:
      '1710I1002X - Military Health Care Provider - Independent Duty Corpsman',
  },
  '1710I1003X - Military Health Care Provider - Independent Duty Medical Technicians': {
    code: '1710I1003X',
    description: 'Military Health Care Provider',
    label:
      '1710I1003X - Military Health Care Provider - Independent Duty Medical Technicians',
  },
  '171100000X - Acupuncturist': {
    code: '171100000X',
    description: 'Acupuncturist',
    label: '171100000X - Acupuncturist',
  },
  '171M00000X - Case Manager/Care Coordinator': {
    code: '171M00000X',
    description: 'Case Manager/Care Coordinator',
    label: '171M00000X - Case Manager/Care Coordinator',
  },
  '171R00000X - Interpreter': {
    code: '171R00000X',
    description: 'Interpreter',
    label: '171R00000X - Interpreter',
  },
  '171W00000X - Contractor': {
    code: '171W00000X',
    description: 'Contractor',
    label: '171W00000X - Contractor',
  },
  '171WH0202X - Contractor - Home Modifications': {
    code: '171WH0202X',
    description: 'Contractor',
    label: '171WH0202X - Contractor - Home Modifications',
  },
  '171WV0202X - Contractor - Vehicle Modifications': {
    code: '171WV0202X',
    description: 'Contractor',
    label: '171WV0202X - Contractor - Vehicle Modifications',
  },
  '172A00000X - Driver': {
    code: '172A00000X',
    description: 'Driver',
    label: '172A00000X - Driver',
  },
  '172M00000X - Mechanotherapist': {
    code: '172M00000X',
    description: 'Mechanotherapist',
    label: '172M00000X - Mechanotherapist',
  },
  '172P00000X - Naprapath': {
    code: '172P00000X',
    description: 'Naprapath',
    label: '172P00000X - Naprapath',
  },
  '172V00000X - Community Health Worker': {
    code: '172V00000X',
    description: 'Community Health Worker',
    label: '172V00000X - Community Health Worker',
  },
  '173000000X - Legal Medicine': {
    code: '173000000X',
    description: 'Legal Medicine',
    label: '173000000X - Legal Medicine',
  },
  '173C00000X - Reflexologist': {
    code: '173C00000X',
    description: 'Reflexologist',
    label: '173C00000X - Reflexologist',
  },
  '173F00000X - Sleep Specialist, PhD': {
    code: '173F00000X',
    description: 'Sleep Specialist, PhD',
    label: '173F00000X - Sleep Specialist, PhD',
  },
  '174200000X - Meals': {
    code: '174200000X',
    description: 'Meals',
    label: '174200000X - Meals',
  },
  '174400000X - Specialist': {
    code: '174400000X',
    description: 'Specialist',
    label: '174400000X - Specialist',
  },
  '1744G0900X - Specialist - Graphics Designer': {
    code: '1744G0900X',
    description: 'Specialist',
    label: '1744G0900X - Specialist - Graphics Designer',
  },
  '1744P3200X - Specialist - Prosthetics Case Management': {
    code: '1744P3200X',
    description: 'Specialist',
    label: '1744P3200X - Specialist - Prosthetics Case Management',
  },
  '1744R1102X - Specialist - Research Study': {
    code: '1744R1102X',
    description: 'Specialist',
    label: '1744R1102X - Specialist - Research Study',
  },
  '1744R1103X - Specialist - Research Data Abstracter/Coder': {
    code: '1744R1103X',
    description: 'Specialist',
    label: '1744R1103X - Specialist - Research Data Abstracter/Coder',
  },
  '174H00000X - Health Educator': {
    code: '174H00000X',
    description: 'Health Educator',
    label: '174H00000X - Health Educator',
  },
  '174M00000X - Veterinarian': {
    code: '174M00000X',
    description: 'Veterinarian',
    label: '174M00000X - Veterinarian',
  },
  '174MM1900X - Veterinarian - Medical Research': {
    code: '174MM1900X',
    description: 'Veterinarian',
    label: '174MM1900X - Veterinarian - Medical Research',
  },
  '174N00000X - Lactation Consultant, Non-RN': {
    code: '174N00000X',
    description: 'Lactation Consultant, Non-RN',
    label: '174N00000X - Lactation Consultant, Non-RN',
  },
  '174V00000X - Clinical Ethicist': {
    code: '174V00000X',
    description: 'Clinical Ethicist',
    label: '174V00000X - Clinical Ethicist',
  },
  '175F00000X - Naturopath': {
    code: '175F00000X',
    description: 'Naturopath',
    label: '175F00000X - Naturopath',
  },
  '175L00000X - Homeopath': {
    code: '175L00000X',
    description: 'Homeopath',
    label: '175L00000X - Homeopath',
  },
  '175M00000X - Midwife, Lay': {
    code: '175M00000X',
    description: 'Midwife, Lay',
    label: '175M00000X - Midwife, Lay',
  },
  '175T00000X - Peer Specialist': {
    code: '175T00000X',
    description: 'Peer Specialist',
    label: '175T00000X - Peer Specialist',
  },
  '176B00000X - Midwife': {
    code: '176B00000X',
    description: 'Midwife',
    label: '176B00000X - Midwife',
  },
  '176P00000X - Funeral Director': {
    code: '176P00000X',
    description: 'Funeral Director',
    label: '176P00000X - Funeral Director',
  },
  '177F00000X - Lodging': {
    code: '177F00000X',
    description: 'Lodging',
    label: '177F00000X - Lodging',
  },
  '183500000X - Pharmacist': {
    code: '183500000X',
    description: 'Pharmacist',
    label: '183500000X - Pharmacist',
  },
  '1835C0205X - Pharmacist - Critical Care': {
    code: '1835C0205X',
    description: 'Pharmacist',
    label: '1835C0205X - Pharmacist - Critical Care',
  },
  '1835G0000X - Pharmacist - General Practice': {
    code: '1835G0000X',
    description: 'Pharmacist',
    label: '1835G0000X - Pharmacist - General Practice',
  },
  '1835G0303X - Pharmacist - Geriatric': {
    code: '1835G0303X',
    description: 'Pharmacist',
    label: '1835G0303X - Pharmacist - Geriatric',
  },
  '1835N0905X - Pharmacist - Nuclear': {
    code: '1835N0905X',
    description: 'Pharmacist',
    label: '1835N0905X - Pharmacist - Nuclear',
  },
  '1835N1003X - Pharmacist - Nutrition Support': {
    code: '1835N1003X',
    description: 'Pharmacist',
    label: '1835N1003X - Pharmacist - Nutrition Support',
  },
  '1835P0018X - Pharmacist - Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist': {
    code: '1835P0018X',
    description: 'Pharmacist',
    label:
      '1835P0018X - Pharmacist - Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist',
  },
  '1835P0200X - Pharmacist - Pediatrics': {
    code: '1835P0200X',
    description: 'Pharmacist',
    label: '1835P0200X - Pharmacist - Pediatrics',
  },
  '1835P1200X - Pharmacist - Pharmacotherapy': {
    code: '1835P1200X',
    description: 'Pharmacist',
    label: '1835P1200X - Pharmacist - Pharmacotherapy',
  },
  '1835P1300X - Pharmacist - Psychiatric': {
    code: '1835P1300X',
    description: 'Pharmacist',
    label: '1835P1300X - Pharmacist - Psychiatric',
  },
  '1835P2201X - Pharmacist - Ambulatory Care': {
    code: '1835P2201X',
    description: 'Pharmacist',
    label: '1835P2201X - Pharmacist - Ambulatory Care',
  },
  '1835X0200X - Pharmacist - Oncology': {
    code: '1835X0200X',
    description: 'Pharmacist',
    label: '1835X0200X - Pharmacist - Oncology',
  },
  '183700000X - Pharmacy Technician': {
    code: '183700000X',
    description: 'Pharmacy Technician',
    label: '183700000X - Pharmacy Technician',
  },
  '193200000X - Multi-Specialty': {
    code: '193200000X',
    description: 'Multi-Specialty',
    label: '193200000X - Multi-Specialty',
  },
  '193400000X - Single Specialty': {
    code: '193400000X',
    description: 'Single Specialty',
    label: '193400000X - Single Specialty',
  },
  '202C00000X - Independent Medical Examiner': {
    code: '202C00000X',
    description: 'Independent Medical Examiner',
    label: '202C00000X - Independent Medical Examiner',
  },
  '202K00000X - Phlebology': {
    code: '202K00000X',
    description: 'Phlebology',
    label: '202K00000X - Phlebology',
  },
  '204C00000X - Neuromusculoskeletal Medicine, Sports Medicine': {
    code: '204C00000X',
    description: 'Neuromusculoskeletal Medicine, Sports Medicine',
    label: '204C00000X - Neuromusculoskeletal Medicine, Sports Medicine',
  },
  '204D00000X - Neuromusculoskeletal Medicine & OMM': {
    code: '204D00000X',
    description: 'Neuromusculoskeletal Medicine & OMM',
    label: '204D00000X - Neuromusculoskeletal Medicine & OMM',
  },
  '204E00000X - Oral & Maxillofacial Surgery': {
    code: '204E00000X',
    description: 'Oral & Maxillofacial Surgery',
    label: '204E00000X - Oral & Maxillofacial Surgery',
  },
  '204F00000X - Transplant Surgery': {
    code: '204F00000X',
    description: 'Transplant Surgery',
    label: '204F00000X - Transplant Surgery',
  },
  '204R00000X - Electrodiagnostic Medicine': {
    code: '204R00000X',
    description: 'Electrodiagnostic Medicine',
    label: '204R00000X - Electrodiagnostic Medicine',
  },
  '207K00000X - Allergy & Immunology': {
    code: '207K00000X',
    description: 'Allergy & Immunology',
    label: '207K00000X - Allergy & Immunology',
  },
  '207KA0200X - Allergy & Immunology - Allergy': {
    code: '207KA0200X',
    description: 'Allergy & Immunology',
    label: '207KA0200X - Allergy & Immunology - Allergy',
  },
  '207KI0005X - Allergy & Immunology - Clinical & Laboratory Immunology': {
    code: '207KI0005X',
    description: 'Allergy & Immunology',
    label:
      '207KI0005X - Allergy & Immunology - Clinical & Laboratory Immunology',
  },
  '207L00000X - Anesthesiology': {
    code: '207L00000X',
    description: 'Anesthesiology',
    label: '207L00000X - Anesthesiology',
  },
  '207LA0401X - Anesthesiology - Addiction Medicine': {
    code: '207LA0401X',
    description: 'Anesthesiology',
    label: '207LA0401X - Anesthesiology - Addiction Medicine',
  },
  '207LC0200X - Anesthesiology - Critical Care Medicine': {
    code: '207LC0200X',
    description: 'Anesthesiology',
    label: '207LC0200X - Anesthesiology - Critical Care Medicine',
  },
  '207LH0002X - Anesthesiology - Hospice and Palliative Medicine': {
    code: '207LH0002X',
    description: 'Anesthesiology',
    label: '207LH0002X - Anesthesiology - Hospice and Palliative Medicine',
  },
  '207LP2900X - Anesthesiology - Pain Medicine': {
    code: '207LP2900X',
    description: 'Anesthesiology',
    label: '207LP2900X - Anesthesiology - Pain Medicine',
  },
  '207LP3000X - Anesthesiology - Pediatric Anesthesiology': {
    code: '207LP3000X',
    description: 'Anesthesiology',
    label: '207LP3000X - Anesthesiology - Pediatric Anesthesiology',
  },
  '207N00000X - Dermatology': {
    code: '207N00000X',
    description: 'Dermatology',
    label: '207N00000X - Dermatology',
  },
  '207ND0101X - Dermatology - MOHS-Micrographic Surgery': {
    code: '207ND0101X',
    description: 'Dermatology',
    label: '207ND0101X - Dermatology - MOHS-Micrographic Surgery',
  },
  '207ND0900X - Dermatology - Dermatopathology': {
    code: '207ND0900X',
    description: 'Dermatology',
    label: '207ND0900X - Dermatology - Dermatopathology',
  },
  '207NI0002X - Dermatology - Clinical & Laboratory Dermatological Immunology': {
    code: '207NI0002X',
    description: 'Dermatology',
    label:
      '207NI0002X - Dermatology - Clinical & Laboratory Dermatological Immunology',
  },
  '207NP0225X - Dermatology - Pediatric Dermatology': {
    code: '207NP0225X',
    description: 'Dermatology',
    label: '207NP0225X - Dermatology - Pediatric Dermatology',
  },
  '207NS0135X - Dermatology - Procedural Dermatology': {
    code: '207NS0135X',
    description: 'Dermatology',
    label: '207NS0135X - Dermatology - Procedural Dermatology',
  },
  '207P00000X - Emergency Medicine': {
    code: '207P00000X',
    description: 'Emergency Medicine',
    label: '207P00000X - Emergency Medicine',
  },
  '207PE0004X - Emergency Medicine - Emergency Medical Services': {
    code: '207PE0004X',
    description: 'Emergency Medicine',
    label: '207PE0004X - Emergency Medicine - Emergency Medical Services',
  },
  '207PE0005X - Emergency Medicine - Undersea and Hyperbaric Medicine': {
    code: '207PE0005X',
    description: 'Emergency Medicine',
    label: '207PE0005X - Emergency Medicine - Undersea and Hyperbaric Medicine',
  },
  '207PH0002X - Emergency Medicine - Hospice and Palliative Medicine': {
    code: '207PH0002X',
    description: 'Emergency Medicine',
    label: '207PH0002X - Emergency Medicine - Hospice and Palliative Medicine',
  },
  '207PP0204X - Emergency Medicine - Pediatric Emergency Medicine': {
    code: '207PP0204X',
    description: 'Emergency Medicine',
    label: '207PP0204X - Emergency Medicine - Pediatric Emergency Medicine',
  },
  '207PS0010X - Emergency Medicine - Sports Medicine': {
    code: '207PS0010X',
    description: 'Emergency Medicine',
    label: '207PS0010X - Emergency Medicine - Sports Medicine',
  },
  '207PT0002X - Emergency Medicine - Medical Toxicology': {
    code: '207PT0002X',
    description: 'Emergency Medicine',
    label: '207PT0002X - Emergency Medicine - Medical Toxicology',
  },
  '207Q00000X - Family Medicine': {
    code: '207Q00000X',
    description: 'Family Medicine',
    label: '207Q00000X - Family Medicine',
  },
  '207QA0000X - Family Medicine - Adolescent Medicine': {
    code: '207QA0000X',
    description: 'Family Medicine',
    label: '207QA0000X - Family Medicine - Adolescent Medicine',
  },
  '207QA0401X - Family Medicine - Addiction Medicine': {
    code: '207QA0401X',
    description: 'Family Medicine',
    label: '207QA0401X - Family Medicine - Addiction Medicine',
  },
  '207QA0505X - Family Medicine - Adult Medicine': {
    code: '207QA0505X',
    description: 'Family Medicine',
    label: '207QA0505X - Family Medicine - Adult Medicine',
  },
  '207QB0002X - Family Medicine - Obesity Medicine': {
    code: '207QB0002X',
    description: 'Family Medicine',
    label: '207QB0002X - Family Medicine - Obesity Medicine',
  },
  '207QG0300X - Family Medicine - Geriatric Medicine': {
    code: '207QG0300X',
    description: 'Family Medicine',
    label: '207QG0300X - Family Medicine - Geriatric Medicine',
  },
  '207QH0002X - Family Medicine - Hospice and Palliative Medicine': {
    code: '207QH0002X',
    description: 'Family Medicine',
    label: '207QH0002X - Family Medicine - Hospice and Palliative Medicine',
  },
  '207QS0010X - Family Medicine - Sports Medicine': {
    code: '207QS0010X',
    description: 'Family Medicine',
    label: '207QS0010X - Family Medicine - Sports Medicine',
  },
  '207QS1201X - Family Medicine - Sleep Medicine': {
    code: '207QS1201X',
    description: 'Family Medicine',
    label: '207QS1201X - Family Medicine - Sleep Medicine',
  },
  '207R00000X - Internal Medicine': {
    code: '207R00000X',
    description: 'Internal Medicine',
    label: '207R00000X - Internal Medicine',
  },
  '207RA0000X - Internal Medicine - Adolescent Medicine': {
    code: '207RA0000X',
    description: 'Internal Medicine',
    label: '207RA0000X - Internal Medicine - Adolescent Medicine',
  },
  '207RA0001X - Internal Medicine - Advanced Heart Failure and Transplant Cardiology': {
    code: '207RA0001X',
    description: 'Internal Medicine',
    label:
      '207RA0001X - Internal Medicine - Advanced Heart Failure and Transplant Cardiology',
  },
  '207RA0002X - Internal Medicine - Adult Congenital Heart Disease': {
    code: '207RA0002X',
    description: 'Internal Medicine',
    label: '207RA0002X - Internal Medicine - Adult Congenital Heart Disease',
  },
  '207RA0201X - Internal Medicine - Allergy & Immunology': {
    code: '207RA0201X',
    description: 'Internal Medicine',
    label: '207RA0201X - Internal Medicine - Allergy & Immunology',
  },
  '207RA0401X - Internal Medicine - Addiction Medicine': {
    code: '207RA0401X',
    description: 'Internal Medicine',
    label: '207RA0401X - Internal Medicine - Addiction Medicine',
  },
  '207RB0002X - Internal Medicine - Obesity Medicine': {
    code: '207RB0002X',
    description: 'Internal Medicine',
    label: '207RB0002X - Internal Medicine - Obesity Medicine',
  },
  '207RC0000X - Internal Medicine - Cardiovascular Disease': {
    code: '207RC0000X',
    description: 'Internal Medicine',
    label: '207RC0000X - Internal Medicine - Cardiovascular Disease',
  },
  '207RC0001X - Internal Medicine - Clinical Cardiac Electrophysiology': {
    code: '207RC0001X',
    description: 'Internal Medicine',
    label:
      '207RC0001X - Internal Medicine - Clinical Cardiac Electrophysiology',
  },
  '207RC0200X - Internal Medicine - Critical Care Medicine': {
    code: '207RC0200X',
    description: 'Internal Medicine',
    label: '207RC0200X - Internal Medicine - Critical Care Medicine',
  },
  '207RE0101X - Internal Medicine - Endocrinology, Diabetes & Metabolism': {
    code: '207RE0101X',
    description: 'Internal Medicine',
    label:
      '207RE0101X - Internal Medicine - Endocrinology, Diabetes & Metabolism',
  },
  '207RG0100X - Internal Medicine - Gastroenterology': {
    code: '207RG0100X',
    description: 'Internal Medicine',
    label: '207RG0100X - Internal Medicine - Gastroenterology',
  },
  '207RG0300X - Internal Medicine - Geriatric Medicine': {
    code: '207RG0300X',
    description: 'Internal Medicine',
    label: '207RG0300X - Internal Medicine - Geriatric Medicine',
  },
  '207RH0000X - Internal Medicine - Hematology': {
    code: '207RH0000X',
    description: 'Internal Medicine',
    label: '207RH0000X - Internal Medicine - Hematology',
  },
  '207RH0002X - Internal Medicine - Hospice and Palliative Medicine': {
    code: '207RH0002X',
    description: 'Internal Medicine',
    label: '207RH0002X - Internal Medicine - Hospice and Palliative Medicine',
  },
  '207RH0003X - Internal Medicine - Hematology & Oncology': {
    code: '207RH0003X',
    description: 'Internal Medicine',
    label: '207RH0003X - Internal Medicine - Hematology & Oncology',
  },
  '207RH0005X - Internal Medicine - Hypertension Specialist': {
    code: '207RH0005X',
    description: 'Internal Medicine',
    label: '207RH0005X - Internal Medicine - Hypertension Specialist',
  },
  '207RI0001X - Internal Medicine - Clinical & Laboratory Immunology': {
    code: '207RI0001X',
    description: 'Internal Medicine',
    label: '207RI0001X - Internal Medicine - Clinical & Laboratory Immunology',
  },
  '207RI0008X - Internal Medicine - Hepatology': {
    code: '207RI0008X',
    description: 'Internal Medicine',
    label: '207RI0008X - Internal Medicine - Hepatology',
  },
  '207RI0011X - Internal Medicine - Interventional Cardiology': {
    code: '207RI0011X',
    description: 'Internal Medicine',
    label: '207RI0011X - Internal Medicine - Interventional Cardiology',
  },
  '207RI0200X - Internal Medicine - Infectious Disease': {
    code: '207RI0200X',
    description: 'Internal Medicine',
    label: '207RI0200X - Internal Medicine - Infectious Disease',
  },
  '207RM1200X - Internal Medicine - Magnetic Resonance Imaging (MRI)': {
    code: '207RM1200X',
    description: 'Internal Medicine',
    label: '207RM1200X - Internal Medicine - Magnetic Resonance Imaging (MRI)',
  },
  '207RN0300X - Internal Medicine - Nephrology': {
    code: '207RN0300X',
    description: 'Internal Medicine',
    label: '207RN0300X - Internal Medicine - Nephrology',
  },
  '207RP1001X - Internal Medicine - Pulmonary Disease': {
    code: '207RP1001X',
    description: 'Internal Medicine',
    label: '207RP1001X - Internal Medicine - Pulmonary Disease',
  },
  '207RR0500X - Internal Medicine - Rheumatology': {
    code: '207RR0500X',
    description: 'Internal Medicine',
    label: '207RR0500X - Internal Medicine - Rheumatology',
  },
  '207RS0010X - Internal Medicine - Sports Medicine': {
    code: '207RS0010X',
    description: 'Internal Medicine',
    label: '207RS0010X - Internal Medicine - Sports Medicine',
  },
  '207RS0012X - Internal Medicine - Sleep Medicine': {
    code: '207RS0012X',
    description: 'Internal Medicine',
    label: '207RS0012X - Internal Medicine - Sleep Medicine',
  },
  '207RT0003X - Internal Medicine - Transplant Hepatology': {
    code: '207RT0003X',
    description: 'Internal Medicine',
    label: '207RT0003X - Internal Medicine - Transplant Hepatology',
  },
  '207RX0202X - Internal Medicine - Medical Oncology': {
    code: '207RX0202X',
    description: 'Internal Medicine',
    label: '207RX0202X - Internal Medicine - Medical Oncology',
  },
  '207SC0300X - Medical Genetics - Clinical Cytogenetics': {
    code: '207SC0300X',
    description: 'Medical Genetics',
    label: '207SC0300X - Medical Genetics - Clinical Cytogenetics',
  },
  '207SG0201X - Medical Genetics - Clinical Genetics (M.D.)': {
    code: '207SG0201X',
    description: 'Medical Genetics',
    label: '207SG0201X - Medical Genetics - Clinical Genetics (M.D.)',
  },
  '207SG0202X - Medical Genetics - Clinical Biochemical Genetics': {
    code: '207SG0202X',
    description: 'Medical Genetics',
    label: '207SG0202X - Medical Genetics - Clinical Biochemical Genetics',
  },
  '207SG0203X - Medical Genetics - Clinical Molecular Genetics': {
    code: '207SG0203X',
    description: 'Medical Genetics',
    label: '207SG0203X - Medical Genetics - Clinical Molecular Genetics',
  },
  '207SG0205X - Medical Genetics - Ph.D. Medical Genetics': {
    code: '207SG0205X',
    description: 'Medical Genetics',
    label: '207SG0205X - Medical Genetics - Ph.D. Medical Genetics',
  },
  '207SM0001X - Medical Genetics - Molecular Genetic Pathology': {
    code: '207SM0001X',
    description: 'Medical Genetics',
    label: '207SM0001X - Medical Genetics - Molecular Genetic Pathology',
  },
  '207T00000X - Neurological Surgery': {
    code: '207T00000X',
    description: 'Neurological Surgery',
    label: '207T00000X - Neurological Surgery',
  },
  '207U00000X - Nuclear Medicine': {
    code: '207U00000X',
    description: 'Nuclear Medicine',
    label: '207U00000X - Nuclear Medicine',
  },
  '207UN0901X - Nuclear Medicine - Nuclear Cardiology': {
    code: '207UN0901X',
    description: 'Nuclear Medicine',
    label: '207UN0901X - Nuclear Medicine - Nuclear Cardiology',
  },
  '207UN0902X - Nuclear Medicine - Nuclear Imaging & Therapy': {
    code: '207UN0902X',
    description: 'Nuclear Medicine',
    label: '207UN0902X - Nuclear Medicine - Nuclear Imaging & Therapy',
  },
  '207UN0903X - Nuclear Medicine - In Vivo & In Vitro Nuclear Medicine': {
    code: '207UN0903X',
    description: 'Nuclear Medicine',
    label:
      '207UN0903X - Nuclear Medicine - In Vivo & In Vitro Nuclear Medicine',
  },
  '207V00000X - Obstetrics & Gynecology': {
    code: '207V00000X',
    description: 'Obstetrics & Gynecology',
    label: '207V00000X - Obstetrics & Gynecology',
  },
  '207VB0002X - Obstetrics & Gynecology - Obesity Medicine': {
    code: '207VB0002X',
    description: 'Obstetrics & Gynecology',
    label: '207VB0002X - Obstetrics & Gynecology - Obesity Medicine',
  },
  '207VC0200X - Obstetrics & Gynecology - Critical Care Medicine': {
    code: '207VC0200X',
    description: 'Obstetrics & Gynecology',
    label: '207VC0200X - Obstetrics & Gynecology - Critical Care Medicine',
  },
  '207VE0102X - Obstetrics & Gynecology - Reproductive Endocrinology': {
    code: '207VE0102X',
    description: 'Obstetrics & Gynecology',
    label: '207VE0102X - Obstetrics & Gynecology - Reproductive Endocrinology',
  },
  '207VF0040X - Obstetrics & Gynecology - Female Pelvic Medicine and Reconstructive Surgery': {
    code: '207VF0040X',
    description: 'Obstetrics & Gynecology',
    label:
      '207VF0040X - Obstetrics & Gynecology - Female Pelvic Medicine and Reconstructive Surgery',
  },
  '207VG0400X - Obstetrics & Gynecology - Gynecology': {
    code: '207VG0400X',
    description: 'Obstetrics & Gynecology',
    label: '207VG0400X - Obstetrics & Gynecology - Gynecology',
  },
  '207VH0002X - Obstetrics & Gynecology - Hospice and Palliative Medicine': {
    code: '207VH0002X',
    description: 'Obstetrics & Gynecology',
    label:
      '207VH0002X - Obstetrics & Gynecology - Hospice and Palliative Medicine',
  },
  '207VM0101X - Obstetrics & Gynecology - Maternal & Fetal Medicine': {
    code: '207VM0101X',
    description: 'Obstetrics & Gynecology',
    label: '207VM0101X - Obstetrics & Gynecology - Maternal & Fetal Medicine',
  },
  '207VX0000X - Obstetrics & Gynecology - Obstetrics': {
    code: '207VX0000X',
    description: 'Obstetrics & Gynecology',
    label: '207VX0000X - Obstetrics & Gynecology - Obstetrics',
  },
  '207VX0201X - Obstetrics & Gynecology - Gynecologic Oncology': {
    code: '207VX0201X',
    description: 'Obstetrics & Gynecology',
    label: '207VX0201X - Obstetrics & Gynecology - Gynecologic Oncology',
  },
  '207W00000X - Ophthalmology': {
    code: '207W00000X',
    description: 'Ophthalmology',
    label: '207W00000X - Ophthalmology',
  },
  '207WX0009X - Ophthalmology - Glaucoma Specialist': {
    code: '207WX0009X',
    description: 'Ophthalmology',
    label: '207WX0009X - Ophthalmology - Glaucoma Specialist',
  },
  '207WX0107X - Ophthalmology - Retina Specialist': {
    code: '207WX0107X',
    description: 'Ophthalmology',
    label: '207WX0107X - Ophthalmology - Retina Specialist',
  },
  '207WX0108X - Ophthalmology - Uveitis and Ocular Inflammatory Disease': {
    code: '207WX0108X',
    description: 'Ophthalmology',
    label:
      '207WX0108X - Ophthalmology - Uveitis and Ocular Inflammatory Disease',
  },
  '207WX0109X - Ophthalmology - Neuro-ophthalmology': {
    code: '207WX0109X',
    description: 'Ophthalmology',
    label: '207WX0109X - Ophthalmology - Neuro-ophthalmology',
  },
  '207WX0110X - Ophthalmology - Pediatric Ophthalmology and Strabismus Specialist': {
    code: '207WX0110X',
    description: 'Ophthalmology',
    label:
      '207WX0110X - Ophthalmology - Pediatric Ophthalmology and Strabismus Specialist',
  },
  '207WX0120X - Ophthalmology - Cornea and External Diseases Specialist': {
    code: '207WX0120X',
    description: 'Ophthalmology',
    label:
      '207WX0120X - Ophthalmology - Cornea and External Diseases Specialist',
  },
  '207WX0200X - Ophthalmology - Ophthalmic Plastic and Reconstructive Surgery': {
    code: '207WX0200X',
    description: 'Ophthalmology',
    label:
      '207WX0200X - Ophthalmology - Ophthalmic Plastic and Reconstructive Surgery',
  },
  '207X00000X - Orthopaedic Surgery': {
    code: '207X00000X',
    description: 'Orthopaedic Surgery',
    label: '207X00000X - Orthopaedic Surgery',
  },
  '207XP3100X - Orthopaedic Surgery - Pediatric Orthopaedic Surgery': {
    code: '207XP3100X',
    description: 'Orthopaedic Surgery',
    label: '207XP3100X - Orthopaedic Surgery - Pediatric Orthopaedic Surgery',
  },
  '207XS0106X - Orthopaedic Surgery - Hand Surgery': {
    code: '207XS0106X',
    description: 'Orthopaedic Surgery',
    label: '207XS0106X - Orthopaedic Surgery - Hand Surgery',
  },
  '207XS0114X - Orthopaedic Surgery - Adult Reconstructive Orthopaedic Surgery': {
    code: '207XS0114X',
    description: 'Orthopaedic Surgery',
    label:
      '207XS0114X - Orthopaedic Surgery - Adult Reconstructive Orthopaedic Surgery',
  },
  '207XS0117X - Orthopaedic Surgery - Orthopaedic Surgery of the Spine': {
    code: '207XS0117X',
    description: 'Orthopaedic Surgery',
    label:
      '207XS0117X - Orthopaedic Surgery - Orthopaedic Surgery of the Spine',
  },
  '207XX0004X - Orthopaedic Surgery - Foot and Ankle Surgery': {
    code: '207XX0004X',
    description: 'Orthopaedic Surgery',
    label: '207XX0004X - Orthopaedic Surgery - Foot and Ankle Surgery',
  },
  '207XX0005X - Orthopaedic Surgery - Sports Medicine': {
    code: '207XX0005X',
    description: 'Orthopaedic Surgery',
    label: '207XX0005X - Orthopaedic Surgery - Sports Medicine',
  },
  '207XX0801X - Orthopaedic Surgery - Orthopaedic Trauma': {
    code: '207XX0801X',
    description: 'Orthopaedic Surgery',
    label: '207XX0801X - Orthopaedic Surgery - Orthopaedic Trauma',
  },
  '207Y00000X - Otolaryngology': {
    code: '207Y00000X',
    description: 'Otolaryngology',
    label: '207Y00000X - Otolaryngology',
  },
  '207YP0228X - Otolaryngology - Pediatric Otolaryngology': {
    code: '207YP0228X',
    description: 'Otolaryngology',
    label: '207YP0228X - Otolaryngology - Pediatric Otolaryngology',
  },
  '207YS0012X - Otolaryngology - Sleep Medicine': {
    code: '207YS0012X',
    description: 'Otolaryngology',
    label: '207YS0012X - Otolaryngology - Sleep Medicine',
  },
  '207YS0123X - Otolaryngology - Facial Plastic Surgery': {
    code: '207YS0123X',
    description: 'Otolaryngology',
    label: '207YS0123X - Otolaryngology - Facial Plastic Surgery',
  },
  '207YX0007X - Otolaryngology - Plastic Surgery within the Head & Neck': {
    code: '207YX0007X',
    description: 'Otolaryngology',
    label:
      '207YX0007X - Otolaryngology - Plastic Surgery within the Head & Neck',
  },
  '207YX0602X - Otolaryngology - Otolaryngic Allergy': {
    code: '207YX0602X',
    description: 'Otolaryngology',
    label: '207YX0602X - Otolaryngology - Otolaryngic Allergy',
  },
  '207YX0901X - Otolaryngology - Otology & Neurotology': {
    code: '207YX0901X',
    description: 'Otolaryngology',
    label: '207YX0901X - Otolaryngology - Otology & Neurotology',
  },
  '207YX0905X - Otolaryngology - Otolaryngology/Facial Plastic Surgery': {
    code: '207YX0905X',
    description: 'Otolaryngology',
    label:
      '207YX0905X - Otolaryngology - Otolaryngology/Facial Plastic Surgery',
  },
  '207ZB0001X - Pathology - Blood Banking & Transfusion Medicine': {
    code: '207ZB0001X',
    description: 'Pathology',
    label: '207ZB0001X - Pathology - Blood Banking & Transfusion Medicine',
  },
  '207ZC0006X - Pathology - Clinical Pathology': {
    code: '207ZC0006X',
    description: 'Pathology',
    label: '207ZC0006X - Pathology - Clinical Pathology',
  },
  '207ZC0008X - Pathology - Clinical Informatics': {
    code: '207ZC0008X',
    description: 'Pathology',
    label: '207ZC0008X - Pathology - Clinical Informatics',
  },
  '207ZC0500X - Pathology - Cytopathology': {
    code: '207ZC0500X',
    description: 'Pathology',
    label: '207ZC0500X - Pathology - Cytopathology',
  },
  '207ZD0900X - Pathology - Dermatopathology': {
    code: '207ZD0900X',
    description: 'Pathology',
    label: '207ZD0900X - Pathology - Dermatopathology',
  },
  '207ZF0201X - Pathology - Forensic Pathology': {
    code: '207ZF0201X',
    description: 'Pathology',
    label: '207ZF0201X - Pathology - Forensic Pathology',
  },
  '207ZH0000X - Pathology - Hematology': {
    code: '207ZH0000X',
    description: 'Pathology',
    label: '207ZH0000X - Pathology - Hematology',
  },
  '207ZI0100X - Pathology - Immunopathology': {
    code: '207ZI0100X',
    description: 'Pathology',
    label: '207ZI0100X - Pathology - Immunopathology',
  },
  '207ZM0300X - Pathology - Medical Microbiology': {
    code: '207ZM0300X',
    description: 'Pathology',
    label: '207ZM0300X - Pathology - Medical Microbiology',
  },
  '207ZN0500X - Pathology - Neuropathology': {
    code: '207ZN0500X',
    description: 'Pathology',
    label: '207ZN0500X - Pathology - Neuropathology',
  },
  '207ZP0007X - Pathology - Molecular Genetic Pathology': {
    code: '207ZP0007X',
    description: 'Pathology',
    label: '207ZP0007X - Pathology - Molecular Genetic Pathology',
  },
  '207ZP0101X - Pathology - Anatomic Pathology': {
    code: '207ZP0101X',
    description: 'Pathology',
    label: '207ZP0101X - Pathology - Anatomic Pathology',
  },
  '207ZP0102X - Pathology - Anatomic Pathology & Clinical Pathology': {
    code: '207ZP0102X',
    description: 'Pathology',
    label: '207ZP0102X - Pathology - Anatomic Pathology & Clinical Pathology',
  },
  '207ZP0104X - Pathology - Chemical Pathology': {
    code: '207ZP0104X',
    description: 'Pathology',
    label: '207ZP0104X - Pathology - Chemical Pathology',
  },
  '207ZP0105X - Pathology - Clinical Pathology/Laboratory Medicine': {
    code: '207ZP0105X',
    description: 'Pathology',
    label: '207ZP0105X - Pathology - Clinical Pathology/Laboratory Medicine',
  },
  '207ZP0213X - Pathology - Pediatric Pathology': {
    code: '207ZP0213X',
    description: 'Pathology',
    label: '207ZP0213X - Pathology - Pediatric Pathology',
  },
  '208000000X - Pediatrics': {
    code: '208000000X',
    description: 'Pediatrics',
    label: '208000000X - Pediatrics',
  },
  '2080A0000X - Pediatrics - Adolescent Medicine': {
    code: '2080A0000X',
    description: 'Pediatrics',
    label: '2080A0000X - Pediatrics - Adolescent Medicine',
  },
  '2080B0002X - Pediatrics - Obesity Medicine': {
    code: '2080B0002X',
    description: 'Pediatrics',
    label: '2080B0002X - Pediatrics - Obesity Medicine',
  },
  '2080C0008X - Pediatrics - Child Abuse Pediatrics': {
    code: '2080C0008X',
    description: 'Pediatrics',
    label: '2080C0008X - Pediatrics - Child Abuse Pediatrics',
  },
  '2080H0002X - Pediatrics - Hospice and Palliative Medicine': {
    code: '2080H0002X',
    description: 'Pediatrics',
    label: '2080H0002X - Pediatrics - Hospice and Palliative Medicine',
  },
  '2080I0007X - Pediatrics - Clinical & Laboratory Immunology': {
    code: '2080I0007X',
    description: 'Pediatrics',
    label: '2080I0007X - Pediatrics - Clinical & Laboratory Immunology',
  },
  '2080N0001X - Pediatrics - Neonatal-Perinatal Medicine': {
    code: '2080N0001X',
    description: 'Pediatrics',
    label: '2080N0001X - Pediatrics - Neonatal-Perinatal Medicine',
  },
  '2080P0006X - Pediatrics - Developmental - Behavioral Pediatrics': {
    code: '2080P0006X',
    description: 'Pediatrics',
    label: '2080P0006X - Pediatrics - Developmental - Behavioral Pediatrics',
  },
  '2080P0008X - Pediatrics - Neurodevelopmental Disabilities': {
    code: '2080P0008X',
    description: 'Pediatrics',
    label: '2080P0008X - Pediatrics - Neurodevelopmental Disabilities',
  },
  '2080P0201X - Pediatrics - Pediatric Allergy/Immunology': {
    code: '2080P0201X',
    description: 'Pediatrics',
    label: '2080P0201X - Pediatrics - Pediatric Allergy/Immunology',
  },
  '2080P0202X - Pediatrics - Pediatric Cardiology': {
    code: '2080P0202X',
    description: 'Pediatrics',
    label: '2080P0202X - Pediatrics - Pediatric Cardiology',
  },
  '2080P0203X - Pediatrics - Pediatric Critical Care Medicine': {
    code: '2080P0203X',
    description: 'Pediatrics',
    label: '2080P0203X - Pediatrics - Pediatric Critical Care Medicine',
  },
  '2080P0204X - Pediatrics - Pediatric Emergency Medicine': {
    code: '2080P0204X',
    description: 'Pediatrics',
    label: '2080P0204X - Pediatrics - Pediatric Emergency Medicine',
  },
  '2080P0205X - Pediatrics - Pediatric Endocrinology': {
    code: '2080P0205X',
    description: 'Pediatrics',
    label: '2080P0205X - Pediatrics - Pediatric Endocrinology',
  },
  '2080P0206X - Pediatrics - Pediatric Gastroenterology': {
    code: '2080P0206X',
    description: 'Pediatrics',
    label: '2080P0206X - Pediatrics - Pediatric Gastroenterology',
  },
  '2080P0207X - Pediatrics - Pediatric Hematology-Oncology': {
    code: '2080P0207X',
    description: 'Pediatrics',
    label: '2080P0207X - Pediatrics - Pediatric Hematology-Oncology',
  },
  '2080P0208X - Pediatrics - Pediatric Infectious Diseases': {
    code: '2080P0208X',
    description: 'Pediatrics',
    label: '2080P0208X - Pediatrics - Pediatric Infectious Diseases',
  },
  '2080P0210X - Pediatrics - Pediatric Nephrology': {
    code: '2080P0210X',
    description: 'Pediatrics',
    label: '2080P0210X - Pediatrics - Pediatric Nephrology',
  },
  '2080P0214X - Pediatrics - Pediatric Pulmonology': {
    code: '2080P0214X',
    description: 'Pediatrics',
    label: '2080P0214X - Pediatrics - Pediatric Pulmonology',
  },
  '2080P0216X - Pediatrics - Pediatric Rheumatology': {
    code: '2080P0216X',
    description: 'Pediatrics',
    label: '2080P0216X - Pediatrics - Pediatric Rheumatology',
  },
  '2080S0010X - Pediatrics - Sports Medicine': {
    code: '2080S0010X',
    description: 'Pediatrics',
    label: '2080S0010X - Pediatrics - Sports Medicine',
  },
  '2080S0012X - Pediatrics - Sleep Medicine': {
    code: '2080S0012X',
    description: 'Pediatrics',
    label: '2080S0012X - Pediatrics - Sleep Medicine',
  },
  '2080T0002X - Pediatrics - Medical Toxicology': {
    code: '2080T0002X',
    description: 'Pediatrics',
    label: '2080T0002X - Pediatrics - Medical Toxicology',
  },
  '2080T0004X - Pediatrics - Pediatric Transplant Hepatology': {
    code: '2080T0004X',
    description: 'Pediatrics',
    label: '2080T0004X - Pediatrics - Pediatric Transplant Hepatology',
  },
  '208100000X - Physical Medicine & Rehabilitation': {
    code: '208100000X',
    description: 'Physical Medicine & Rehabilitation',
    label: '208100000X - Physical Medicine & Rehabilitation',
  },
  '2081H0002X - Physical Medicine & Rehabilitation - Hospice and Palliative Medicine': {
    code: '2081H0002X',
    description: 'Physical Medicine & Rehabilitation',
    label:
      '2081H0002X - Physical Medicine & Rehabilitation - Hospice and Palliative Medicine',
  },
  '2081N0008X - Physical Medicine & Rehabilitation - Neuromuscular Medicine': {
    code: '2081N0008X',
    description: 'Physical Medicine & Rehabilitation',
    label:
      '2081N0008X - Physical Medicine & Rehabilitation - Neuromuscular Medicine',
  },
  '2081P0004X - Physical Medicine & Rehabilitation - Spinal Cord Injury Medicine': {
    code: '2081P0004X',
    description: 'Physical Medicine & Rehabilitation',
    label:
      '2081P0004X - Physical Medicine & Rehabilitation - Spinal Cord Injury Medicine',
  },
  '2081P0010X - Physical Medicine & Rehabilitation - Pediatric Rehabilitation Medicine': {
    code: '2081P0010X',
    description: 'Physical Medicine & Rehabilitation',
    label:
      '2081P0010X - Physical Medicine & Rehabilitation - Pediatric Rehabilitation Medicine',
  },
  '2081P0301X - Physical Medicine & Rehabilitation - Brain Injury Medicine': {
    code: '2081P0301X',
    description: 'Physical Medicine & Rehabilitation',
    label:
      '2081P0301X - Physical Medicine & Rehabilitation - Brain Injury Medicine',
  },
  '2081P2900X - Physical Medicine & Rehabilitation - Pain Medicine': {
    code: '2081P2900X',
    description: 'Physical Medicine & Rehabilitation',
    label: '2081P2900X - Physical Medicine & Rehabilitation - Pain Medicine',
  },
  '2081S0010X - Physical Medicine & Rehabilitation - Sports Medicine': {
    code: '2081S0010X',
    description: 'Physical Medicine & Rehabilitation',
    label: '2081S0010X - Physical Medicine & Rehabilitation - Sports Medicine',
  },
  '208200000X - Plastic Surgery': {
    code: '208200000X',
    description: 'Plastic Surgery',
    label: '208200000X - Plastic Surgery',
  },
  '2082S0099X - Plastic Surgery - Plastic Surgery Within the Head and Neck': {
    code: '2082S0099X',
    description: 'Plastic Surgery',
    label:
      '2082S0099X - Plastic Surgery - Plastic Surgery Within the Head and Neck',
  },
  '2082S0105X - Plastic Surgery - Surgery of the Hand': {
    code: '2082S0105X',
    description: 'Plastic Surgery',
    label: '2082S0105X - Plastic Surgery - Surgery of the Hand',
  },
  '2083A0100X - Preventive Medicine - Aerospace Medicine': {
    code: '2083A0100X',
    description: 'Preventive Medicine',
    label: '2083A0100X - Preventive Medicine - Aerospace Medicine',
  },
  '2083A0300X - Preventive Medicine - Addiction Medicine': {
    code: '2083A0300X',
    description: 'Preventive Medicine',
    label: '2083A0300X - Preventive Medicine - Addiction Medicine',
  },
  '2083B0002X - Preventive Medicine - Obesity Medicine': {
    code: '2083B0002X',
    description: 'Preventive Medicine',
    label: '2083B0002X - Preventive Medicine - Obesity Medicine',
  },
  '2083C0008X - Preventive Medicine - Clinical Informatics': {
    code: '2083C0008X',
    description: 'Preventive Medicine',
    label: '2083C0008X - Preventive Medicine - Clinical Informatics',
  },
  '2083P0011X - Preventive Medicine - Undersea and Hyperbaric Medicine': {
    code: '2083P0011X',
    description: 'Preventive Medicine',
    label:
      '2083P0011X - Preventive Medicine - Undersea and Hyperbaric Medicine',
  },
  '2083P0500X - Preventive Medicine - Preventive Medicine/Occupational Environmental Medicine': {
    code: '2083P0500X',
    description: 'Preventive Medicine',
    label:
      '2083P0500X - Preventive Medicine - Preventive Medicine/Occupational Environmental Medicine',
  },
  '2083P0901X - Preventive Medicine - Public Health & General Preventive Medicine': {
    code: '2083P0901X',
    description: 'Preventive Medicine',
    label:
      '2083P0901X - Preventive Medicine - Public Health & General Preventive Medicine',
  },
  '2083S0010X - Preventive Medicine - Sports Medicine': {
    code: '2083S0010X',
    description: 'Preventive Medicine',
    label: '2083S0010X - Preventive Medicine - Sports Medicine',
  },
  '2083T0002X - Preventive Medicine - Medical Toxicology': {
    code: '2083T0002X',
    description: 'Preventive Medicine',
    label: '2083T0002X - Preventive Medicine - Medical Toxicology',
  },
  '2083X0100X - Preventive Medicine - Occupational Medicine': {
    code: '2083X0100X',
    description: 'Preventive Medicine',
    label: '2083X0100X - Preventive Medicine - Occupational Medicine',
  },
  '2084A0401X - Psychiatry & Neurology - Addiction Medicine': {
    code: '2084A0401X',
    description: 'Psychiatry & Neurology',
    label: '2084A0401X - Psychiatry & Neurology - Addiction Medicine',
  },
  '2084A2900X - Psychiatry & Neurology - Neurocritical Care': {
    code: '2084A2900X',
    description: 'Psychiatry & Neurology',
    label: '2084A2900X - Psychiatry & Neurology - Neurocritical Care',
  },
  '2084B0002X - Psychiatry & Neurology - Obesity Medicine': {
    code: '2084B0002X',
    description: 'Psychiatry & Neurology',
    label: '2084B0002X - Psychiatry & Neurology - Obesity Medicine',
  },
  '2084B0040X - Psychiatry & Neurology - Behavioral Neurology & Neuropsychiatry': {
    code: '2084B0040X',
    description: 'Psychiatry & Neurology',
    label:
      '2084B0040X - Psychiatry & Neurology - Behavioral Neurology & Neuropsychiatry',
  },
  '2084D0003X - Psychiatry & Neurology - Diagnostic Neuroimaging': {
    code: '2084D0003X',
    description: 'Psychiatry & Neurology',
    label: '2084D0003X - Psychiatry & Neurology - Diagnostic Neuroimaging',
  },
  '2084F0202X - Psychiatry & Neurology - Forensic Psychiatry': {
    code: '2084F0202X',
    description: 'Psychiatry & Neurology',
    label: '2084F0202X - Psychiatry & Neurology - Forensic Psychiatry',
  },
  '2084H0002X - Psychiatry & Neurology - Hospice and Palliative Medicine': {
    code: '2084H0002X',
    description: 'Psychiatry & Neurology',
    label:
      '2084H0002X - Psychiatry & Neurology - Hospice and Palliative Medicine',
  },
  '2084N0008X - Psychiatry & Neurology - Neuromuscular Medicine': {
    code: '2084N0008X',
    description: 'Psychiatry & Neurology',
    label: '2084N0008X - Psychiatry & Neurology - Neuromuscular Medicine',
  },
  '2084N0400X - Psychiatry & Neurology - Neurology': {
    code: '2084N0400X',
    description: 'Psychiatry & Neurology',
    label: '2084N0400X - Psychiatry & Neurology - Neurology',
  },
  '2084N0402X - Psychiatry & Neurology - Neurology with Special Qualifications in Child Neurology': {
    code: '2084N0402X',
    description: 'Psychiatry & Neurology',
    label:
      '2084N0402X - Psychiatry & Neurology - Neurology with Special Qualifications in Child Neurology',
  },
  '2084N0600X - Psychiatry & Neurology - Clinical Neurophysiology': {
    code: '2084N0600X',
    description: 'Psychiatry & Neurology',
    label: '2084N0600X - Psychiatry & Neurology - Clinical Neurophysiology',
  },
  '2084P0005X - Psychiatry & Neurology - Neurodevelopmental Disabilities': {
    code: '2084P0005X',
    description: 'Psychiatry & Neurology',
    label:
      '2084P0005X - Psychiatry & Neurology - Neurodevelopmental Disabilities',
  },
  '2084P0015X - Psychiatry & Neurology - Psychosomatic Medicine': {
    code: '2084P0015X',
    description: 'Psychiatry & Neurology',
    label: '2084P0015X - Psychiatry & Neurology - Psychosomatic Medicine',
  },
  '2084P0301X - Psychiatry & Neurology - Brain Injury Medicine': {
    code: '2084P0301X',
    description: 'Psychiatry & Neurology',
    label: '2084P0301X - Psychiatry & Neurology - Brain Injury Medicine',
  },
  '2084P0800X - Psychiatry & Neurology - Psychiatry': {
    code: '2084P0800X',
    description: 'Psychiatry & Neurology',
    label: '2084P0800X - Psychiatry & Neurology - Psychiatry',
  },
  '2084P0802X - Psychiatry & Neurology - Addiction Psychiatry': {
    code: '2084P0802X',
    description: 'Psychiatry & Neurology',
    label: '2084P0802X - Psychiatry & Neurology - Addiction Psychiatry',
  },
  '2084P0804X - Psychiatry & Neurology - Child & Adolescent Psychiatry': {
    code: '2084P0804X',
    description: 'Psychiatry & Neurology',
    label:
      '2084P0804X - Psychiatry & Neurology - Child & Adolescent Psychiatry',
  },
  '2084P0805X - Psychiatry & Neurology - Geriatric Psychiatry': {
    code: '2084P0805X',
    description: 'Psychiatry & Neurology',
    label: '2084P0805X - Psychiatry & Neurology - Geriatric Psychiatry',
  },
  '2084P2900X - Psychiatry & Neurology - Pain Medicine': {
    code: '2084P2900X',
    description: 'Psychiatry & Neurology',
    label: '2084P2900X - Psychiatry & Neurology - Pain Medicine',
  },
  '2084S0010X - Psychiatry & Neurology - Sports Medicine': {
    code: '2084S0010X',
    description: 'Psychiatry & Neurology',
    label: '2084S0010X - Psychiatry & Neurology - Sports Medicine',
  },
  '2084S0012X - Psychiatry & Neurology - Sleep Medicine': {
    code: '2084S0012X',
    description: 'Psychiatry & Neurology',
    label: '2084S0012X - Psychiatry & Neurology - Sleep Medicine',
  },
  '2084V0102X - Psychiatry & Neurology - Vascular Neurology': {
    code: '2084V0102X',
    description: 'Psychiatry & Neurology',
    label: '2084V0102X - Psychiatry & Neurology - Vascular Neurology',
  },
  '2085B0100X - Radiology - Body Imaging': {
    code: '2085B0100X',
    description: 'Radiology',
    label: '2085B0100X - Radiology - Body Imaging',
  },
  '2085D0003X - Radiology - Diagnostic Neuroimaging': {
    code: '2085D0003X',
    description: 'Radiology',
    label: '2085D0003X - Radiology - Diagnostic Neuroimaging',
  },
  '2085H0002X - Radiology - Hospice and Palliative Medicine': {
    code: '2085H0002X',
    description: 'Radiology',
    label: '2085H0002X - Radiology - Hospice and Palliative Medicine',
  },
  '2085N0700X - Radiology - Neuroradiology': {
    code: '2085N0700X',
    description: 'Radiology',
    label: '2085N0700X - Radiology - Neuroradiology',
  },
  '2085N0904X - Radiology - Nuclear Radiology': {
    code: '2085N0904X',
    description: 'Radiology',
    label: '2085N0904X - Radiology - Nuclear Radiology',
  },
  '2085P0229X - Radiology - Pediatric Radiology': {
    code: '2085P0229X',
    description: 'Radiology',
    label: '2085P0229X - Radiology - Pediatric Radiology',
  },
  '2085R0001X - Radiology - Radiation Oncology': {
    code: '2085R0001X',
    description: 'Radiology',
    label: '2085R0001X - Radiology - Radiation Oncology',
  },
  '2085R0202X - Radiology - Diagnostic Radiology': {
    code: '2085R0202X',
    description: 'Radiology',
    label: '2085R0202X - Radiology - Diagnostic Radiology',
  },
  '2085R0203X - Radiology - Therapeutic Radiology': {
    code: '2085R0203X',
    description: 'Radiology',
    label: '2085R0203X - Radiology - Therapeutic Radiology',
  },
  '2085R0204X - Radiology - Vascular & Interventional Radiology': {
    code: '2085R0204X',
    description: 'Radiology',
    label: '2085R0204X - Radiology - Vascular & Interventional Radiology',
  },
  '2085R0205X - Radiology - Radiological Physics': {
    code: '2085R0205X',
    description: 'Radiology',
    label: '2085R0205X - Radiology - Radiological Physics',
  },
  '2085U0001X - Radiology - Diagnostic Ultrasound': {
    code: '2085U0001X',
    description: 'Radiology',
    label: '2085U0001X - Radiology - Diagnostic Ultrasound',
  },
  '208600000X - Surgery': {
    code: '208600000X',
    description: 'Surgery',
    label: '208600000X - Surgery',
  },
  '2086H0002X - Surgery - Hospice and Palliative Medicine': {
    code: '2086H0002X',
    description: 'Surgery',
    label: '2086H0002X - Surgery - Hospice and Palliative Medicine',
  },
  '2086S0102X - Surgery - Surgical Critical Care': {
    code: '2086S0102X',
    description: 'Surgery',
    label: '2086S0102X - Surgery - Surgical Critical Care',
  },
  '2086S0105X - Surgery - Surgery of the Hand': {
    code: '2086S0105X',
    description: 'Surgery',
    label: '2086S0105X - Surgery - Surgery of the Hand',
  },
  '2086S0120X - Surgery - Pediatric Surgery': {
    code: '2086S0120X',
    description: 'Surgery',
    label: '2086S0120X - Surgery - Pediatric Surgery',
  },
  '2086S0122X - Surgery - Plastic and Reconstructive Surgery': {
    code: '2086S0122X',
    description: 'Surgery',
    label: '2086S0122X - Surgery - Plastic and Reconstructive Surgery',
  },
  '2086S0127X - Surgery - Trauma Surgery': {
    code: '2086S0127X',
    description: 'Surgery',
    label: '2086S0127X - Surgery - Trauma Surgery',
  },
  '2086S0129X - Surgery - Vascular Surgery': {
    code: '2086S0129X',
    description: 'Surgery',
    label: '2086S0129X - Surgery - Vascular Surgery',
  },
  '2086X0206X - Surgery - Surgical Oncology': {
    code: '2086X0206X',
    description: 'Surgery',
    label: '2086X0206X - Surgery - Surgical Oncology',
  },
  '208800000X - Urology': {
    code: '208800000X',
    description: 'Urology',
    label: '208800000X - Urology',
  },
  '2088F0040X - Urology - Female Pelvic Medicine and Reconstructive Surgery': {
    code: '2088F0040X',
    description: 'Urology',
    label:
      '2088F0040X - Urology - Female Pelvic Medicine and Reconstructive Surgery',
  },
  '2088P0231X - Urology - Pediatric Urology': {
    code: '2088P0231X',
    description: 'Urology',
    label: '2088P0231X - Urology - Pediatric Urology',
  },
  '208C00000X - Colon & Rectal Surgery': {
    code: '208C00000X',
    description: 'Colon & Rectal Surgery',
    label: '208C00000X - Colon & Rectal Surgery',
  },
  '208D00000X - General Practice': {
    code: '208D00000X',
    description: 'General Practice',
    label: '208D00000X - General Practice',
  },
  '208G00000X - Thoracic Surgery (Cardiothoracic Vascular Surgery)': {
    code: '208G00000X',
    description: 'Thoracic Surgery (Cardiothoracic Vascular Surgery)',
    label: '208G00000X - Thoracic Surgery (Cardiothoracic Vascular Surgery)',
  },
  '208M00000X - Hospitalist': {
    code: '208M00000X',
    description: 'Hospitalist',
    label: '208M00000X - Hospitalist',
  },
  '208U00000X - Clinical Pharmacology': {
    code: '208U00000X',
    description: 'Clinical Pharmacology',
    label: '208U00000X - Clinical Pharmacology',
  },
  '208VP0000X - Pain Medicine - Pain Medicine': {
    code: '208VP0000X',
    description: 'Pain Medicine',
    label: '208VP0000X - Pain Medicine - Pain Medicine',
  },
  '208VP0014X - Pain Medicine - Interventional Pain Medicine': {
    code: '208VP0014X',
    description: 'Pain Medicine',
    label: '208VP0014X - Pain Medicine - Interventional Pain Medicine',
  },
  '209800000X - Legal Medicine': {
    code: '209800000X',
    description: 'Legal Medicine',
    label: '209800000X - Legal Medicine',
  },
  '211D00000X - Assistant, Podiatric': {
    code: '211D00000X',
    description: 'Assistant, Podiatric',
    label: '211D00000X - Assistant, Podiatric',
  },
  '213E00000X - Podiatrist': {
    code: '213E00000X',
    description: 'Podiatrist',
    label: '213E00000X - Podiatrist',
  },
  '213EG0000X - Podiatrist - General Practice': {
    code: '213EG0000X',
    description: 'Podiatrist',
    label: '213EG0000X - Podiatrist - General Practice',
  },
  '213EP0504X - Podiatrist - Public Medicine': {
    code: '213EP0504X',
    description: 'Podiatrist',
    label: '213EP0504X - Podiatrist - Public Medicine',
  },
  '213EP1101X - Podiatrist - Primary Podiatric Medicine': {
    code: '213EP1101X',
    description: 'Podiatrist',
    label: '213EP1101X - Podiatrist - Primary Podiatric Medicine',
  },
  '213ER0200X - Podiatrist - Radiology': {
    code: '213ER0200X',
    description: 'Podiatrist',
    label: '213ER0200X - Podiatrist - Radiology',
  },
  '213ES0000X - Podiatrist - Sports Medicine': {
    code: '213ES0000X',
    description: 'Podiatrist',
    label: '213ES0000X - Podiatrist - Sports Medicine',
  },
  '213ES0103X - Podiatrist - Foot & Ankle Surgery': {
    code: '213ES0103X',
    description: 'Podiatrist',
    label: '213ES0103X - Podiatrist - Foot & Ankle Surgery',
  },
  '213ES0131X - Podiatrist - Foot Surgery': {
    code: '213ES0131X',
    description: 'Podiatrist',
    label: '213ES0131X - Podiatrist - Foot Surgery',
  },
  '221700000X - Art Therapist': {
    code: '221700000X',
    description: 'Art Therapist',
    label: '221700000X - Art Therapist',
  },
  '222Q00000X - Developmental Therapist': {
    code: '222Q00000X',
    description: 'Developmental Therapist',
    label: '222Q00000X - Developmental Therapist',
  },
  '222Z00000X - Orthotist': {
    code: '222Z00000X',
    description: 'Orthotist',
    label: '222Z00000X - Orthotist',
  },
  '224900000X - Mastectomy Fitter': {
    code: '224900000X',
    description: 'Mastectomy Fitter',
    label: '224900000X - Mastectomy Fitter',
  },
  '224L00000X - Pedorthist': {
    code: '224L00000X',
    description: 'Pedorthist',
    label: '224L00000X - Pedorthist',
  },
  '224P00000X - Prosthetist': {
    code: '224P00000X',
    description: 'Prosthetist',
    label: '224P00000X - Prosthetist',
  },
  '224Y00000X - Clinical Exercise Physiologist': {
    code: '224Y00000X',
    description: 'Clinical Exercise Physiologist',
    label: '224Y00000X - Clinical Exercise Physiologist',
  },
  '224Z00000X - Occupational Therapy Assistant': {
    code: '224Z00000X',
    description: 'Occupational Therapy Assistant',
    label: '224Z00000X - Occupational Therapy Assistant',
  },
  '224ZE0001X - Occupational Therapy Assistant - Environmental Modification': {
    code: '224ZE0001X',
    description: 'Occupational Therapy Assistant',
    label:
      '224ZE0001X - Occupational Therapy Assistant - Environmental Modification',
  },
  '224ZF0002X - Occupational Therapy Assistant - Feeding, Eating & Swallowing': {
    code: '224ZF0002X',
    description: 'Occupational Therapy Assistant',
    label:
      '224ZF0002X - Occupational Therapy Assistant - Feeding, Eating & Swallowing',
  },
  '224ZL0004X - Occupational Therapy Assistant - Low Vision': {
    code: '224ZL0004X',
    description: 'Occupational Therapy Assistant',
    label: '224ZL0004X - Occupational Therapy Assistant - Low Vision',
  },
  '224ZR0403X - Occupational Therapy Assistant - Driving and Community Mobility': {
    code: '224ZR0403X',
    description: 'Occupational Therapy Assistant',
    label:
      '224ZR0403X - Occupational Therapy Assistant - Driving and Community Mobility',
  },
  '225000000X - Orthotic Fitter': {
    code: '225000000X',
    description: 'Orthotic Fitter',
    label: '225000000X - Orthotic Fitter',
  },
  '225100000X - Physical Therapist': {
    code: '225100000X',
    description: 'Physical Therapist',
    label: '225100000X - Physical Therapist',
  },
  '2251C2600X - Physical Therapist - Cardiopulmonary': {
    code: '2251C2600X',
    description: 'Physical Therapist',
    label: '2251C2600X - Physical Therapist - Cardiopulmonary',
  },
  '2251E1200X - Physical Therapist - Ergonomics': {
    code: '2251E1200X',
    description: 'Physical Therapist',
    label: '2251E1200X - Physical Therapist - Ergonomics',
  },
  '2251E1300X - Physical Therapist - Electrophysiology, Clinical': {
    code: '2251E1300X',
    description: 'Physical Therapist',
    label: '2251E1300X - Physical Therapist - Electrophysiology, Clinical',
  },
  '2251G0304X - Physical Therapist - Geriatrics': {
    code: '2251G0304X',
    description: 'Physical Therapist',
    label: '2251G0304X - Physical Therapist - Geriatrics',
  },
  '2251H1200X - Physical Therapist - Hand': {
    code: '2251H1200X',
    description: 'Physical Therapist',
    label: '2251H1200X - Physical Therapist - Hand',
  },
  '2251H1300X - Physical Therapist - Human Factors': {
    code: '2251H1300X',
    description: 'Physical Therapist',
    label: '2251H1300X - Physical Therapist - Human Factors',
  },
  '2251N0400X - Physical Therapist - Neurology': {
    code: '2251N0400X',
    description: 'Physical Therapist',
    label: '2251N0400X - Physical Therapist - Neurology',
  },
  '2251P0200X - Physical Therapist - Pediatrics': {
    code: '2251P0200X',
    description: 'Physical Therapist',
    label: '2251P0200X - Physical Therapist - Pediatrics',
  },
  '2251S0007X - Physical Therapist - Sports': {
    code: '2251S0007X',
    description: 'Physical Therapist',
    label: '2251S0007X - Physical Therapist - Sports',
  },
  '2251X0800X - Physical Therapist - Orthopedic': {
    code: '2251X0800X',
    description: 'Physical Therapist',
    label: '2251X0800X - Physical Therapist - Orthopedic',
  },
  '225200000X - Physical Therapy Assistant': {
    code: '225200000X',
    description: 'Physical Therapy Assistant',
    label: '225200000X - Physical Therapy Assistant',
  },
  '225400000X - Rehabilitation Practitioner': {
    code: '225400000X',
    description: 'Rehabilitation Practitioner',
    label: '225400000X - Rehabilitation Practitioner',
  },
  '225500000X - Specialist/Technologist': {
    code: '225500000X',
    description: 'Specialist/Technologist',
    label: '225500000X - Specialist/Technologist',
  },
  '2255A2300X - Specialist/Technologist - Athletic Trainer': {
    code: '2255A2300X',
    description: 'Specialist/Technologist',
    label: '2255A2300X - Specialist/Technologist - Athletic Trainer',
  },
  '2255R0406X - Specialist/Technologist - Rehabilitation, Blind': {
    code: '2255R0406X',
    description: 'Specialist/Technologist',
    label: '2255R0406X - Specialist/Technologist - Rehabilitation, Blind',
  },
  '225600000X - Dance Therapist': {
    code: '225600000X',
    description: 'Dance Therapist',
    label: '225600000X - Dance Therapist',
  },
  '225700000X - Massage Therapist': {
    code: '225700000X',
    description: 'Massage Therapist',
    label: '225700000X - Massage Therapist',
  },
  '225800000X - Recreation Therapist': {
    code: '225800000X',
    description: 'Recreation Therapist',
    label: '225800000X - Recreation Therapist',
  },
  '225A00000X - Music Therapist': {
    code: '225A00000X',
    description: 'Music Therapist',
    label: '225A00000X - Music Therapist',
  },
  '225B00000X - Pulmonary Function Technologist': {
    code: '225B00000X',
    description: 'Pulmonary Function Technologist',
    label: '225B00000X - Pulmonary Function Technologist',
  },
  '225C00000X - Rehabilitation Counselor': {
    code: '225C00000X',
    description: 'Rehabilitation Counselor',
    label: '225C00000X - Rehabilitation Counselor',
  },
  '225CA2400X - Rehabilitation Counselor - Assistive Technology Practitioner': {
    code: '225CA2400X',
    description: 'Rehabilitation Counselor',
    label:
      '225CA2400X - Rehabilitation Counselor - Assistive Technology Practitioner',
  },
  '225CA2500X - Rehabilitation Counselor - Assistive Technology Supplier': {
    code: '225CA2500X',
    description: 'Rehabilitation Counselor',
    label:
      '225CA2500X - Rehabilitation Counselor - Assistive Technology Supplier',
  },
  '225CX0006X - Rehabilitation Counselor - Orientation and Mobility Training Provider': {
    code: '225CX0006X',
    description: 'Rehabilitation Counselor',
    label:
      '225CX0006X - Rehabilitation Counselor - Orientation and Mobility Training Provider',
  },
  '225X00000X - Occupational Therapist': {
    code: '225X00000X',
    description: 'Occupational Therapist',
    label: '225X00000X - Occupational Therapist',
  },
  '225XE0001X - Occupational Therapist - Environmental Modification': {
    code: '225XE0001X',
    description: 'Occupational Therapist',
    label: '225XE0001X - Occupational Therapist - Environmental Modification',
  },
  '225XE1200X - Occupational Therapist - Ergonomics': {
    code: '225XE1200X',
    description: 'Occupational Therapist',
    label: '225XE1200X - Occupational Therapist - Ergonomics',
  },
  '225XF0002X - Occupational Therapist - Feeding, Eating & Swallowing': {
    code: '225XF0002X',
    description: 'Occupational Therapist',
    label: '225XF0002X - Occupational Therapist - Feeding, Eating & Swallowing',
  },
  '225XG0600X - Occupational Therapist - Gerontology': {
    code: '225XG0600X',
    description: 'Occupational Therapist',
    label: '225XG0600X - Occupational Therapist - Gerontology',
  },
  '225XH1200X - Occupational Therapist - Hand': {
    code: '225XH1200X',
    description: 'Occupational Therapist',
    label: '225XH1200X - Occupational Therapist - Hand',
  },
  '225XH1300X - Occupational Therapist - Human Factors': {
    code: '225XH1300X',
    description: 'Occupational Therapist',
    label: '225XH1300X - Occupational Therapist - Human Factors',
  },
  '225XL0004X - Occupational Therapist - Low Vision': {
    code: '225XL0004X',
    description: 'Occupational Therapist',
    label: '225XL0004X - Occupational Therapist - Low Vision',
  },
  '225XM0800X - Occupational Therapist - Mental Health': {
    code: '225XM0800X',
    description: 'Occupational Therapist',
    label: '225XM0800X - Occupational Therapist - Mental Health',
  },
  '225XN1300X - Occupational Therapist - Neurorehabilitation': {
    code: '225XN1300X',
    description: 'Occupational Therapist',
    label: '225XN1300X - Occupational Therapist - Neurorehabilitation',
  },
  '225XP0019X - Occupational Therapist - Physical Rehabilitation': {
    code: '225XP0019X',
    description: 'Occupational Therapist',
    label: '225XP0019X - Occupational Therapist - Physical Rehabilitation',
  },
  '225XP0200X - Occupational Therapist - Pediatrics': {
    code: '225XP0200X',
    description: 'Occupational Therapist',
    label: '225XP0200X - Occupational Therapist - Pediatrics',
  },
  '225XR0403X - Occupational Therapist - Driving and Community Mobility': {
    code: '225XR0403X',
    description: 'Occupational Therapist',
    label:
      '225XR0403X - Occupational Therapist - Driving and Community Mobility',
  },
  '226000000X - Recreational Therapist Assistant': {
    code: '226000000X',
    description: 'Recreational Therapist Assistant',
    label: '226000000X - Recreational Therapist Assistant',
  },
  '226300000X - Kinesiotherapist': {
    code: '226300000X',
    description: 'Kinesiotherapist',
    label: '226300000X - Kinesiotherapist',
  },
  '227800000X - Respiratory Therapist, Certified': {
    code: '227800000X',
    description: 'Respiratory Therapist, Certified',
    label: '227800000X - Respiratory Therapist, Certified',
  },
  '2278C0205X - Respiratory Therapist, Certified - Critical Care': {
    code: '2278C0205X',
    description: 'Respiratory Therapist, Certified',
    label: '2278C0205X - Respiratory Therapist, Certified - Critical Care',
  },
  '2278E0002X - Respiratory Therapist, Certified - Emergency Care': {
    code: '2278E0002X',
    description: 'Respiratory Therapist, Certified',
    label: '2278E0002X - Respiratory Therapist, Certified - Emergency Care',
  },
  '2278E1000X - Respiratory Therapist, Certified - Educational': {
    code: '2278E1000X',
    description: 'Respiratory Therapist, Certified',
    label: '2278E1000X - Respiratory Therapist, Certified - Educational',
  },
  '2278G0305X - Respiratory Therapist, Certified - Geriatric Care': {
    code: '2278G0305X',
    description: 'Respiratory Therapist, Certified',
    label: '2278G0305X - Respiratory Therapist, Certified - Geriatric Care',
  },
  '2278G1100X - Respiratory Therapist, Certified - General Care': {
    code: '2278G1100X',
    description: 'Respiratory Therapist, Certified',
    label: '2278G1100X - Respiratory Therapist, Certified - General Care',
  },
  '2278H0200X - Respiratory Therapist, Certified - Home Health': {
    code: '2278H0200X',
    description: 'Respiratory Therapist, Certified',
    label: '2278H0200X - Respiratory Therapist, Certified - Home Health',
  },
  '2278P1004X - Respiratory Therapist, Certified - Pulmonary Diagnostics': {
    code: '2278P1004X',
    description: 'Respiratory Therapist, Certified',
    label:
      '2278P1004X - Respiratory Therapist, Certified - Pulmonary Diagnostics',
  },
  '2278P1005X - Respiratory Therapist, Certified - Pulmonary Rehabilitation': {
    code: '2278P1005X',
    description: 'Respiratory Therapist, Certified',
    label:
      '2278P1005X - Respiratory Therapist, Certified - Pulmonary Rehabilitation',
  },
  '2278P1006X - Respiratory Therapist, Certified - Pulmonary Function Technologist': {
    code: '2278P1006X',
    description: 'Respiratory Therapist, Certified',
    label:
      '2278P1006X - Respiratory Therapist, Certified - Pulmonary Function Technologist',
  },
  '2278P3800X - Respiratory Therapist, Certified - Palliative/Hospice': {
    code: '2278P3800X',
    description: 'Respiratory Therapist, Certified',
    label: '2278P3800X - Respiratory Therapist, Certified - Palliative/Hospice',
  },
  '2278P3900X - Respiratory Therapist, Certified - Neonatal/Pediatrics': {
    code: '2278P3900X',
    description: 'Respiratory Therapist, Certified',
    label:
      '2278P3900X - Respiratory Therapist, Certified - Neonatal/Pediatrics',
  },
  '2278P4000X - Respiratory Therapist, Certified - Patient Transport': {
    code: '2278P4000X',
    description: 'Respiratory Therapist, Certified',
    label: '2278P4000X - Respiratory Therapist, Certified - Patient Transport',
  },
  '2278S1500X - Respiratory Therapist, Certified - SNF/Subacute Care': {
    code: '2278S1500X',
    description: 'Respiratory Therapist, Certified',
    label: '2278S1500X - Respiratory Therapist, Certified - SNF/Subacute Care',
  },
  '227900000X - Respiratory Therapist, Registered': {
    code: '227900000X',
    description: 'Respiratory Therapist, Registered',
    label: '227900000X - Respiratory Therapist, Registered',
  },
  '2279C0205X - Respiratory Therapist, Registered - Critical Care': {
    code: '2279C0205X',
    description: 'Respiratory Therapist, Registered',
    label: '2279C0205X - Respiratory Therapist, Registered - Critical Care',
  },
  '2279E0002X - Respiratory Therapist, Registered - Emergency Care': {
    code: '2279E0002X',
    description: 'Respiratory Therapist, Registered',
    label: '2279E0002X - Respiratory Therapist, Registered - Emergency Care',
  },
  '2279E1000X - Respiratory Therapist, Registered - Educational': {
    code: '2279E1000X',
    description: 'Respiratory Therapist, Registered',
    label: '2279E1000X - Respiratory Therapist, Registered - Educational',
  },
  '2279G0305X - Respiratory Therapist, Registered - Geriatric Care': {
    code: '2279G0305X',
    description: 'Respiratory Therapist, Registered',
    label: '2279G0305X - Respiratory Therapist, Registered - Geriatric Care',
  },
  '2279G1100X - Respiratory Therapist, Registered - General Care': {
    code: '2279G1100X',
    description: 'Respiratory Therapist, Registered',
    label: '2279G1100X - Respiratory Therapist, Registered - General Care',
  },
  '2279H0200X - Respiratory Therapist, Registered - Home Health': {
    code: '2279H0200X',
    description: 'Respiratory Therapist, Registered',
    label: '2279H0200X - Respiratory Therapist, Registered - Home Health',
  },
  '2279P1004X - Respiratory Therapist, Registered - Pulmonary Diagnostics': {
    code: '2279P1004X',
    description: 'Respiratory Therapist, Registered',
    label:
      '2279P1004X - Respiratory Therapist, Registered - Pulmonary Diagnostics',
  },
  '2279P1005X - Respiratory Therapist, Registered - Pulmonary Rehabilitation': {
    code: '2279P1005X',
    description: 'Respiratory Therapist, Registered',
    label:
      '2279P1005X - Respiratory Therapist, Registered - Pulmonary Rehabilitation',
  },
  '2279P1006X - Respiratory Therapist, Registered - Pulmonary Function Technologist': {
    code: '2279P1006X',
    description: 'Respiratory Therapist, Registered',
    label:
      '2279P1006X - Respiratory Therapist, Registered - Pulmonary Function Technologist',
  },
  '2279P3800X - Respiratory Therapist, Registered - Palliative/Hospice': {
    code: '2279P3800X',
    description: 'Respiratory Therapist, Registered',
    label:
      '2279P3800X - Respiratory Therapist, Registered - Palliative/Hospice',
  },
  '2279P3900X - Respiratory Therapist, Registered - Neonatal/Pediatrics': {
    code: '2279P3900X',
    description: 'Respiratory Therapist, Registered',
    label:
      '2279P3900X - Respiratory Therapist, Registered - Neonatal/Pediatrics',
  },
  '2279P4000X - Respiratory Therapist, Registered - Patient Transport': {
    code: '2279P4000X',
    description: 'Respiratory Therapist, Registered',
    label: '2279P4000X - Respiratory Therapist, Registered - Patient Transport',
  },
  '2279S1500X - Respiratory Therapist, Registered - SNF/Subacute Care': {
    code: '2279S1500X',
    description: 'Respiratory Therapist, Registered',
    label: '2279S1500X - Respiratory Therapist, Registered - SNF/Subacute Care',
  },
  '229N00000X - Anaplastologist': {
    code: '229N00000X',
    description: 'Anaplastologist',
    label: '229N00000X - Anaplastologist',
  },
  '231H00000X - Audiologist': {
    code: '231H00000X',
    description: 'Audiologist',
    label: '231H00000X - Audiologist',
  },
  '231HA2400X - Audiologist - Assistive Technology Practitioner': {
    code: '231HA2400X',
    description: 'Audiologist',
    label: '231HA2400X - Audiologist - Assistive Technology Practitioner',
  },
  '231HA2500X - Audiologist - Assistive Technology Supplier': {
    code: '231HA2500X',
    description: 'Audiologist',
    label: '231HA2500X - Audiologist - Assistive Technology Supplier',
  },
  '235500000X - Specialist/Technologist': {
    code: '235500000X',
    description: 'Specialist/Technologist',
    label: '235500000X - Specialist/Technologist',
  },
  '2355A2700X - Specialist/Technologist - Audiology Assistant': {
    code: '2355A2700X',
    description: 'Specialist/Technologist',
    label: '2355A2700X - Specialist/Technologist - Audiology Assistant',
  },
  '2355S0801X - Specialist/Technologist - Speech-Language Assistant': {
    code: '2355S0801X',
    description: 'Specialist/Technologist',
    label: '2355S0801X - Specialist/Technologist - Speech-Language Assistant',
  },
  '235Z00000X - Speech-Language Pathologist': {
    code: '235Z00000X',
    description: 'Speech-Language Pathologist',
    label: '235Z00000X - Speech-Language Pathologist',
  },
  '237600000X - Audiologist-Hearing Aid Fitter': {
    code: '237600000X',
    description: 'Audiologist-Hearing Aid Fitter',
    label: '237600000X - Audiologist-Hearing Aid Fitter',
  },
  '237700000X - Hearing Instrument Specialist': {
    code: '237700000X',
    description: 'Hearing Instrument Specialist',
    label: '237700000X - Hearing Instrument Specialist',
  },
  '242T00000X - Perfusionist': {
    code: '242T00000X',
    description: 'Perfusionist',
    label: '242T00000X - Perfusionist',
  },
  '243U00000X - Radiology Practitioner Assistant': {
    code: '243U00000X',
    description: 'Radiology Practitioner Assistant',
    label: '243U00000X - Radiology Practitioner Assistant',
  },
  '246Q00000X - Specialist/Technologist, Pathology': {
    code: '246Q00000X',
    description: 'Specialist/Technologist, Pathology',
    label: '246Q00000X - Specialist/Technologist, Pathology',
  },
  '246QB0000X - Specialist/Technologist, Pathology - Blood Banking': {
    code: '246QB0000X',
    description: 'Specialist/Technologist, Pathology',
    label: '246QB0000X - Specialist/Technologist, Pathology - Blood Banking',
  },
  '246QC1000X - Specialist/Technologist, Pathology - Chemistry': {
    code: '246QC1000X',
    description: 'Specialist/Technologist, Pathology',
    label: '246QC1000X - Specialist/Technologist, Pathology - Chemistry',
  },
  '246QC2700X - Specialist/Technologist, Pathology - Cytotechnology': {
    code: '246QC2700X',
    description: 'Specialist/Technologist, Pathology',
    label: '246QC2700X - Specialist/Technologist, Pathology - Cytotechnology',
  },
  '246QH0000X - Specialist/Technologist, Pathology - Hematology': {
    code: '246QH0000X',
    description: 'Specialist/Technologist, Pathology',
    label: '246QH0000X - Specialist/Technologist, Pathology - Hematology',
  },
  '246QH0401X - Specialist/Technologist, Pathology - Hemapheresis Practitioner': {
    code: '246QH0401X',
    description: 'Specialist/Technologist, Pathology',
    label:
      '246QH0401X - Specialist/Technologist, Pathology - Hemapheresis Practitioner',
  },
  '246QH0600X - Specialist/Technologist, Pathology - Histology': {
    code: '246QH0600X',
    description: 'Specialist/Technologist, Pathology',
    label: '246QH0600X - Specialist/Technologist, Pathology - Histology',
  },
  '246QI0000X - Specialist/Technologist, Pathology - Immunology': {
    code: '246QI0000X',
    description: 'Specialist/Technologist, Pathology',
    label: '246QI0000X - Specialist/Technologist, Pathology - Immunology',
  },
  '246QL0900X - Specialist/Technologist, Pathology - Laboratory Management': {
    code: '246QL0900X',
    description: 'Specialist/Technologist, Pathology',
    label:
      '246QL0900X - Specialist/Technologist, Pathology - Laboratory Management',
  },
  '246QL0901X - Specialist/Technologist, Pathology - Laboratory Management, Diplomate': {
    code: '246QL0901X',
    description: 'Specialist/Technologist, Pathology',
    label:
      '246QL0901X - Specialist/Technologist, Pathology - Laboratory Management, Diplomate',
  },
  '246QM0706X - Specialist/Technologist, Pathology - Medical Technologist': {
    code: '246QM0706X',
    description: 'Specialist/Technologist, Pathology',
    label:
      '246QM0706X - Specialist/Technologist, Pathology - Medical Technologist',
  },
  '246QM0900X - Specialist/Technologist, Pathology - Microbiology': {
    code: '246QM0900X',
    description: 'Specialist/Technologist, Pathology',
    label: '246QM0900X - Specialist/Technologist, Pathology - Microbiology',
  },
  '246R00000X - Technician, Pathology': {
    code: '246R00000X',
    description: 'Technician, Pathology',
    label: '246R00000X - Technician, Pathology',
  },
  '246RH0600X - Technician, Pathology - Histology': {
    code: '246RH0600X',
    description: 'Technician, Pathology',
    label: '246RH0600X - Technician, Pathology - Histology',
  },
  '246RM2200X - Technician, Pathology - Medical Laboratory': {
    code: '246RM2200X',
    description: 'Technician, Pathology',
    label: '246RM2200X - Technician, Pathology - Medical Laboratory',
  },
  '246RP1900X - Technician, Pathology - Phlebotomy': {
    code: '246RP1900X',
    description: 'Technician, Pathology',
    label: '246RP1900X - Technician, Pathology - Phlebotomy',
  },
  '246W00000X - Technician, Cardiology': {
    code: '246W00000X',
    description: 'Technician, Cardiology',
    label: '246W00000X - Technician, Cardiology',
  },
  '246X00000X - Specialist/Technologist Cardiovascular': {
    code: '246X00000X',
    description: 'Specialist/Technologist Cardiovascular',
    label: '246X00000X - Specialist/Technologist Cardiovascular',
  },
  '246XC2901X - Specialist/Technologist Cardiovascular - Cardiovascular Invasive Specialist': {
    code: '246XC2901X',
    description: 'Specialist/Technologist Cardiovascular',
    label:
      '246XC2901X - Specialist/Technologist Cardiovascular - Cardiovascular Invasive Specialist',
  },
  '246XC2903X - Specialist/Technologist Cardiovascular - Vascular Specialist': {
    code: '246XC2903X',
    description: 'Specialist/Technologist Cardiovascular',
    label:
      '246XC2903X - Specialist/Technologist Cardiovascular - Vascular Specialist',
  },
  '246XS1301X - Specialist/Technologist Cardiovascular - Sonography': {
    code: '246XS1301X',
    description: 'Specialist/Technologist Cardiovascular',
    label: '246XS1301X - Specialist/Technologist Cardiovascular - Sonography',
  },
  '246Y00000X - Specialist/Technologist, Health Information': {
    code: '246Y00000X',
    description: 'Specialist/Technologist, Health Information',
    label: '246Y00000X - Specialist/Technologist, Health Information',
  },
  '246YC3301X - Specialist/Technologist, Health Information - Coding Specialist, Hospital Based': {
    code: '246YC3301X',
    description: 'Specialist/Technologist, Health Information',
    label:
      '246YC3301X - Specialist/Technologist, Health Information - Coding Specialist, Hospital Based',
  },
  '246YC3302X - Specialist/Technologist, Health Information - Coding Specialist, Physician Office Based': {
    code: '246YC3302X',
    description: 'Specialist/Technologist, Health Information',
    label:
      '246YC3302X - Specialist/Technologist, Health Information - Coding Specialist, Physician Office Based',
  },
  '246YR1600X - Specialist/Technologist, Health Information - Registered Record Administrator': {
    code: '246YR1600X',
    description: 'Specialist/Technologist, Health Information',
    label:
      '246YR1600X - Specialist/Technologist, Health Information - Registered Record Administrator',
  },
  '246Z00000X - Specialist/Technologist, Other': {
    code: '246Z00000X',
    description: 'Specialist/Technologist, Other',
    label: '246Z00000X - Specialist/Technologist, Other',
  },
  '246ZA2600X - Specialist/Technologist, Other - Art, Medical': {
    code: '246ZA2600X',
    description: 'Specialist/Technologist, Other',
    label: '246ZA2600X - Specialist/Technologist, Other - Art, Medical',
  },
  '246ZB0301X - Specialist/Technologist, Other - Biomedical Engineering': {
    code: '246ZB0301X',
    description: 'Specialist/Technologist, Other',
    label:
      '246ZB0301X - Specialist/Technologist, Other - Biomedical Engineering',
  },
  '246ZB0302X - Specialist/Technologist, Other - Biomedical Photographer': {
    code: '246ZB0302X',
    description: 'Specialist/Technologist, Other',
    label:
      '246ZB0302X - Specialist/Technologist, Other - Biomedical Photographer',
  },
  '246ZB0500X - Specialist/Technologist, Other - Biochemist': {
    code: '246ZB0500X',
    description: 'Specialist/Technologist, Other',
    label: '246ZB0500X - Specialist/Technologist, Other - Biochemist',
  },
  '246ZB0600X - Specialist/Technologist, Other - Biostatistician': {
    code: '246ZB0600X',
    description: 'Specialist/Technologist, Other',
    label: '246ZB0600X - Specialist/Technologist, Other - Biostatistician',
  },
  '246ZC0007X - Specialist/Technologist, Other - Surgical Assistant': {
    code: '246ZC0007X',
    description: 'Specialist/Technologist, Other',
    label: '246ZC0007X - Specialist/Technologist, Other - Surgical Assistant',
  },
  '246ZE0500X - Specialist/Technologist, Other - EEG': {
    code: '246ZE0500X',
    description: 'Specialist/Technologist, Other',
    label: '246ZE0500X - Specialist/Technologist, Other - EEG',
  },
  '246ZE0600X - Specialist/Technologist, Other - Electroneurodiagnostic': {
    code: '246ZE0600X',
    description: 'Specialist/Technologist, Other',
    label:
      '246ZE0600X - Specialist/Technologist, Other - Electroneurodiagnostic',
  },
  '246ZG0701X - Specialist/Technologist, Other - Graphics Methods': {
    code: '246ZG0701X',
    description: 'Specialist/Technologist, Other',
    label: '246ZG0701X - Specialist/Technologist, Other - Graphics Methods',
  },
  '246ZG1000X - Specialist/Technologist, Other - Geneticist, Medical (PhD)': {
    code: '246ZG1000X',
    description: 'Specialist/Technologist, Other',
    label:
      '246ZG1000X - Specialist/Technologist, Other - Geneticist, Medical (PhD)',
  },
  '246ZI1000X - Specialist/Technologist, Other - Illustration, Medical': {
    code: '246ZI1000X',
    description: 'Specialist/Technologist, Other',
    label:
      '246ZI1000X - Specialist/Technologist, Other - Illustration, Medical',
  },
  '246ZN0300X - Specialist/Technologist, Other - Nephrology': {
    code: '246ZN0300X',
    description: 'Specialist/Technologist, Other',
    label: '246ZN0300X - Specialist/Technologist, Other - Nephrology',
  },
  '246ZS0410X - Specialist/Technologist, Other - Surgical Technologist': {
    code: '246ZS0410X',
    description: 'Specialist/Technologist, Other',
    label:
      '246ZS0410X - Specialist/Technologist, Other - Surgical Technologist',
  },
  '246ZX2200X - Specialist/Technologist, Other - Orthopedic Assistant': {
    code: '246ZX2200X',
    description: 'Specialist/Technologist, Other',
    label: '246ZX2200X - Specialist/Technologist, Other - Orthopedic Assistant',
  },
  '247000000X - Technician, Health Information': {
    code: '247000000X',
    description: 'Technician, Health Information',
    label: '247000000X - Technician, Health Information',
  },
  '2470A2800X - Technician, Health Information - Assistant Record Technician': {
    code: '2470A2800X',
    description: 'Technician, Health Information',
    label:
      '2470A2800X - Technician, Health Information - Assistant Record Technician',
  },
  '247100000X - Radiologic Technologist': {
    code: '247100000X',
    description: 'Radiologic Technologist',
    label: '247100000X - Radiologic Technologist',
  },
  '2471B0102X - Radiologic Technologist - Bone Densitometry': {
    code: '2471B0102X',
    description: 'Radiologic Technologist',
    label: '2471B0102X - Radiologic Technologist - Bone Densitometry',
  },
  '2471C1101X - Radiologic Technologist - Cardiovascular-Interventional Technology': {
    code: '2471C1101X',
    description: 'Radiologic Technologist',
    label:
      '2471C1101X - Radiologic Technologist - Cardiovascular-Interventional Technology',
  },
  '2471C1106X - Radiologic Technologist - Cardiac-Interventional Technology': {
    code: '2471C1106X',
    description: 'Radiologic Technologist',
    label:
      '2471C1106X - Radiologic Technologist - Cardiac-Interventional Technology',
  },
  '2471C3401X - Radiologic Technologist - Computed Tomography': {
    code: '2471C3401X',
    description: 'Radiologic Technologist',
    label: '2471C3401X - Radiologic Technologist - Computed Tomography',
  },
  '2471C3402X - Radiologic Technologist - Radiography': {
    code: '2471C3402X',
    description: 'Radiologic Technologist',
    label: '2471C3402X - Radiologic Technologist - Radiography',
  },
  '2471M1202X - Radiologic Technologist - Magnetic Resonance Imaging': {
    code: '2471M1202X',
    description: 'Radiologic Technologist',
    label: '2471M1202X - Radiologic Technologist - Magnetic Resonance Imaging',
  },
  '2471M2300X - Radiologic Technologist - Mammography': {
    code: '2471M2300X',
    description: 'Radiologic Technologist',
    label: '2471M2300X - Radiologic Technologist - Mammography',
  },
  '2471N0900X - Radiologic Technologist - Nuclear Medicine Technology': {
    code: '2471N0900X',
    description: 'Radiologic Technologist',
    label: '2471N0900X - Radiologic Technologist - Nuclear Medicine Technology',
  },
  '2471Q0001X - Radiologic Technologist - Quality Management': {
    code: '2471Q0001X',
    description: 'Radiologic Technologist',
    label: '2471Q0001X - Radiologic Technologist - Quality Management',
  },
  '2471R0002X - Radiologic Technologist - Radiation Therapy': {
    code: '2471R0002X',
    description: 'Radiologic Technologist',
    label: '2471R0002X - Radiologic Technologist - Radiation Therapy',
  },
  '2471S1302X - Radiologic Technologist - Sonography': {
    code: '2471S1302X',
    description: 'Radiologic Technologist',
    label: '2471S1302X - Radiologic Technologist - Sonography',
  },
  '2471V0105X - Radiologic Technologist - Vascular Sonography': {
    code: '2471V0105X',
    description: 'Radiologic Technologist',
    label: '2471V0105X - Radiologic Technologist - Vascular Sonography',
  },
  '2471V0106X - Radiologic Technologist - Vascular-Interventional Technology': {
    code: '2471V0106X',
    description: 'Radiologic Technologist',
    label:
      '2471V0106X - Radiologic Technologist - Vascular-Interventional Technology',
  },
  '247200000X - Technician, Other': {
    code: '247200000X',
    description: 'Technician, Other',
    label: '247200000X - Technician, Other',
  },
  '2472B0301X - Technician, Other - Biomedical Engineering': {
    code: '2472B0301X',
    description: 'Technician, Other',
    label: '2472B0301X - Technician, Other - Biomedical Engineering',
  },
  '2472D0500X - Technician, Other - Darkroom': {
    code: '2472D0500X',
    description: 'Technician, Other',
    label: '2472D0500X - Technician, Other - Darkroom',
  },
  '2472E0500X - Technician, Other - EEG': {
    code: '2472E0500X',
    description: 'Technician, Other',
    label: '2472E0500X - Technician, Other - EEG',
  },
  '2472R0900X - Technician, Other - Renal Dialysis': {
    code: '2472R0900X',
    description: 'Technician, Other',
    label: '2472R0900X - Technician, Other - Renal Dialysis',
  },
  '2472V0600X - Technician, Other - Veterinary': {
    code: '2472V0600X',
    description: 'Technician, Other',
    label: '2472V0600X - Technician, Other - Veterinary',
  },
  '247ZC0005X - Pathology - Clinical Laboratory Director, Non-physician': {
    code: '247ZC0005X',
    description: 'Pathology',
    label:
      '247ZC0005X - Pathology - Clinical Laboratory Director, Non-physician',
  },
  '251300000X - Local Education Agency (LEA)': {
    code: '251300000X',
    description: 'Local Education Agency (LEA)',
    label: '251300000X - Local Education Agency (LEA)',
  },
  '251B00000X - Case Management': {
    code: '251B00000X',
    description: 'Case Management',
    label: '251B00000X - Case Management',
  },
  '251C00000X - Day Training, Developmentally Disabled Services': {
    code: '251C00000X',
    description: 'Day Training, Developmentally Disabled Services',
    label: '251C00000X - Day Training, Developmentally Disabled Services',
  },
  '251E00000X - Home Health': {
    code: '251E00000X',
    description: 'Home Health',
    label: '251E00000X - Home Health',
  },
  '251F00000X - Home Infusion': {
    code: '251F00000X',
    description: 'Home Infusion',
    label: '251F00000X - Home Infusion',
  },
  '251G00000X - Hospice Care, Community Based': {
    code: '251G00000X',
    description: 'Hospice Care, Community Based',
    label: '251G00000X - Hospice Care, Community Based',
  },
  '251J00000X - Nursing Care': {
    code: '251J00000X',
    description: 'Nursing Care',
    label: '251J00000X - Nursing Care',
  },
  '251K00000X - Public Health or Welfare': {
    code: '251K00000X',
    description: 'Public Health or Welfare',
    label: '251K00000X - Public Health or Welfare',
  },
  '251S00000X - Community/Behavioral Health': {
    code: '251S00000X',
    description: 'Community/Behavioral Health',
    label: '251S00000X - Community/Behavioral Health',
  },
  '251T00000X - Program of All-Inclusive Care for the Elderly (PACE) Provider Organization': {
    code: '251T00000X',
    description:
      'Program of All-Inclusive Care for the Elderly (PACE) Provider Organization',
    label:
      '251T00000X - Program of All-Inclusive Care for the Elderly (PACE) Provider Organization',
  },
  '251V00000X - Voluntary or Charitable': {
    code: '251V00000X',
    description: 'Voluntary or Charitable',
    label: '251V00000X - Voluntary or Charitable',
  },
  '251X00000X - Supports Brokerage': {
    code: '251X00000X',
    description: 'Supports Brokerage',
    label: '251X00000X - Supports Brokerage',
  },
  '252Y00000X - Early Intervention Provider Agency': {
    code: '252Y00000X',
    description: 'Early Intervention Provider Agency',
    label: '252Y00000X - Early Intervention Provider Agency',
  },
  '253J00000X - Foster Care Agency': {
    code: '253J00000X',
    description: 'Foster Care Agency',
    label: '253J00000X - Foster Care Agency',
  },
  '253Z00000X - In Home Supportive Care': {
    code: '253Z00000X',
    description: 'In Home Supportive Care',
    label: '253Z00000X - In Home Supportive Care',
  },
  '261Q00000X - Clinic/Center': {
    code: '261Q00000X',
    description: 'Clinic/Center',
    label: '261Q00000X - Clinic/Center',
  },
  '261QA0005X - Clinic/Center - Ambulatory Family Planning Facility': {
    code: '261QA0005X',
    description: 'Clinic/Center',
    label: '261QA0005X - Clinic/Center - Ambulatory Family Planning Facility',
  },
  '261QA0006X - Clinic/Center - Ambulatory Fertility Facility': {
    code: '261QA0006X',
    description: 'Clinic/Center',
    label: '261QA0006X - Clinic/Center - Ambulatory Fertility Facility',
  },
  '261QA0600X - Clinic/Center - Adult Day Care': {
    code: '261QA0600X',
    description: 'Clinic/Center',
    label: '261QA0600X - Clinic/Center - Adult Day Care',
  },
  '261QA0900X - Clinic/Center - Amputee': {
    code: '261QA0900X',
    description: 'Clinic/Center',
    label: '261QA0900X - Clinic/Center - Amputee',
  },
  '261QA1903X - Clinic/Center - Ambulatory Surgical': {
    code: '261QA1903X',
    description: 'Clinic/Center',
    label: '261QA1903X - Clinic/Center - Ambulatory Surgical',
  },
  '261QA3000X - Clinic/Center - Augmentative Communication': {
    code: '261QA3000X',
    description: 'Clinic/Center',
    label: '261QA3000X - Clinic/Center - Augmentative Communication',
  },
  '261QB0400X - Clinic/Center - Birthing': {
    code: '261QB0400X',
    description: 'Clinic/Center',
    label: '261QB0400X - Clinic/Center - Birthing',
  },
  '261QC0050X - Clinic/Center - Critical Access Hospital': {
    code: '261QC0050X',
    description: 'Clinic/Center',
    label: '261QC0050X - Clinic/Center - Critical Access Hospital',
  },
  '261QC1500X - Clinic/Center - Community Health': {
    code: '261QC1500X',
    description: 'Clinic/Center',
    label: '261QC1500X - Clinic/Center - Community Health',
  },
  '261QC1800X - Clinic/Center - Corporate Health': {
    code: '261QC1800X',
    description: 'Clinic/Center',
    label: '261QC1800X - Clinic/Center - Corporate Health',
  },
  '261QD0000X - Clinic/Center - Dental': {
    code: '261QD0000X',
    description: 'Clinic/Center',
    label: '261QD0000X - Clinic/Center - Dental',
  },
  '261QD1600X - Clinic/Center - Developmental Disabilities': {
    code: '261QD1600X',
    description: 'Clinic/Center',
    label: '261QD1600X - Clinic/Center - Developmental Disabilities',
  },
  '261QE0002X - Clinic/Center - Emergency Care': {
    code: '261QE0002X',
    description: 'Clinic/Center',
    label: '261QE0002X - Clinic/Center - Emergency Care',
  },
  '261QE0700X - Clinic/Center - End-Stage Renal Disease (ESRD) Treatment': {
    code: '261QE0700X',
    description: 'Clinic/Center',
    label:
      '261QE0700X - Clinic/Center - End-Stage Renal Disease (ESRD) Treatment',
  },
  '261QE0800X - Clinic/Center - Endoscopy': {
    code: '261QE0800X',
    description: 'Clinic/Center',
    label: '261QE0800X - Clinic/Center - Endoscopy',
  },
  '261QF0050X - Clinic/Center - Family Planning, Non-Surgical': {
    code: '261QF0050X',
    description: 'Clinic/Center',
    label: '261QF0050X - Clinic/Center - Family Planning, Non-Surgical',
  },
  '261QF0400X - Clinic/Center - Federally Qualified Health Center (FQHC)': {
    code: '261QF0400X',
    description: 'Clinic/Center',
    label:
      '261QF0400X - Clinic/Center - Federally Qualified Health Center (FQHC)',
  },
  '261QG0250X - Clinic/Center - Genetics': {
    code: '261QG0250X',
    description: 'Clinic/Center',
    label: '261QG0250X - Clinic/Center - Genetics',
  },
  '261QH0100X - Clinic/Center - Health Service': {
    code: '261QH0100X',
    description: 'Clinic/Center',
    label: '261QH0100X - Clinic/Center - Health Service',
  },
  '261QH0700X - Clinic/Center - Hearing and Speech': {
    code: '261QH0700X',
    description: 'Clinic/Center',
    label: '261QH0700X - Clinic/Center - Hearing and Speech',
  },
  '261QI0500X - Clinic/Center - Infusion Therapy': {
    code: '261QI0500X',
    description: 'Clinic/Center',
    label: '261QI0500X - Clinic/Center - Infusion Therapy',
  },
  '261QL0400X - Clinic/Center - Lithotripsy': {
    code: '261QL0400X',
    description: 'Clinic/Center',
    label: '261QL0400X - Clinic/Center - Lithotripsy',
  },
  '261QM0801X - Clinic/Center - Mental Health (Including Community Mental Health Center)': {
    code: '261QM0801X',
    description: 'Clinic/Center',
    label:
      '261QM0801X - Clinic/Center - Mental Health (Including Community Mental Health Center)',
  },
  '261QM0850X - Clinic/Center - Adult Mental Health': {
    code: '261QM0850X',
    description: 'Clinic/Center',
    label: '261QM0850X - Clinic/Center - Adult Mental Health',
  },
  '261QM0855X - Clinic/Center - Adolescent and Children Mental Health': {
    code: '261QM0855X',
    description: 'Clinic/Center',
    label: '261QM0855X - Clinic/Center - Adolescent and Children Mental Health',
  },
  '261QM1000X - Clinic/Center - Migrant Health': {
    code: '261QM1000X',
    description: 'Clinic/Center',
    label: '261QM1000X - Clinic/Center - Migrant Health',
  },
  '261QM1100X - Clinic/Center - Military/U.S. Coast Guard Outpatient': {
    code: '261QM1100X',
    description: 'Clinic/Center',
    label: '261QM1100X - Clinic/Center - Military/U.S. Coast Guard Outpatient',
  },
  '261QM1101X - Clinic/Center - Military and U.S. Coast Guard Ambulatory Procedure': {
    code: '261QM1101X',
    description: 'Clinic/Center',
    label:
      '261QM1101X - Clinic/Center - Military and U.S. Coast Guard Ambulatory Procedure',
  },
  '261QM1102X - Clinic/Center - Military Outpatient Operational (Transportable) Component': {
    code: '261QM1102X',
    description: 'Clinic/Center',
    label:
      '261QM1102X - Clinic/Center - Military Outpatient Operational (Transportable) Component',
  },
  '261QM1103X - Clinic/Center - Military Ambulatory Procedure Visits Operational (Transportable)': {
    code: '261QM1103X',
    description: 'Clinic/Center',
    label:
      '261QM1103X - Clinic/Center - Military Ambulatory Procedure Visits Operational (Transportable)',
  },
  '261QM1200X - Clinic/Center - Magnetic Resonance Imaging (MRI)': {
    code: '261QM1200X',
    description: 'Clinic/Center',
    label: '261QM1200X - Clinic/Center - Magnetic Resonance Imaging (MRI)',
  },
  '261QM1300X - Clinic/Center - Multi-Specialty': {
    code: '261QM1300X',
    description: 'Clinic/Center',
    label: '261QM1300X - Clinic/Center - Multi-Specialty',
  },
  '261QM2500X - Clinic/Center - Medical Specialty': {
    code: '261QM2500X',
    description: 'Clinic/Center',
    label: '261QM2500X - Clinic/Center - Medical Specialty',
  },
  '261QM2800X - Clinic/Center - Methadone': {
    code: '261QM2800X',
    description: 'Clinic/Center',
    label: '261QM2800X - Clinic/Center - Methadone',
  },
  '261QM3000X - Clinic/Center - Medically Fragile Infants and Children Day Care': {
    code: '261QM3000X',
    description: 'Clinic/Center',
    label:
      '261QM3000X - Clinic/Center - Medically Fragile Infants and Children Day Care',
  },
  '261QP0904X - Clinic/Center - Public Health, Federal': {
    code: '261QP0904X',
    description: 'Clinic/Center',
    label: '261QP0904X - Clinic/Center - Public Health, Federal',
  },
  '261QP0905X - Clinic/Center - Public Health, State or Local': {
    code: '261QP0905X',
    description: 'Clinic/Center',
    label: '261QP0905X - Clinic/Center - Public Health, State or Local',
  },
  '261QP1100X - Clinic/Center - Podiatric': {
    code: '261QP1100X',
    description: 'Clinic/Center',
    label: '261QP1100X - Clinic/Center - Podiatric',
  },
  '261QP2000X - Clinic/Center - Physical Therapy': {
    code: '261QP2000X',
    description: 'Clinic/Center',
    label: '261QP2000X - Clinic/Center - Physical Therapy',
  },
  '261QP2300X - Clinic/Center - Primary Care': {
    code: '261QP2300X',
    description: 'Clinic/Center',
    label: '261QP2300X - Clinic/Center - Primary Care',
  },
  '261QP2400X - Clinic/Center - Prison Health': {
    code: '261QP2400X',
    description: 'Clinic/Center',
    label: '261QP2400X - Clinic/Center - Prison Health',
  },
  '261QP3300X - Clinic/Center - Pain': {
    code: '261QP3300X',
    description: 'Clinic/Center',
    label: '261QP3300X - Clinic/Center - Pain',
  },
  '261QR0200X - Clinic/Center - Radiology': {
    code: '261QR0200X',
    description: 'Clinic/Center',
    label: '261QR0200X - Clinic/Center - Radiology',
  },
  '261QR0206X - Clinic/Center - Radiology, Mammography': {
    code: '261QR0206X',
    description: 'Clinic/Center',
    label: '261QR0206X - Clinic/Center - Radiology, Mammography',
  },
  '261QR0207X - Clinic/Center - Radiology, Mobile Mammography': {
    code: '261QR0207X',
    description: 'Clinic/Center',
    label: '261QR0207X - Clinic/Center - Radiology, Mobile Mammography',
  },
  '261QR0208X - Clinic/Center - Radiology, Mobile': {
    code: '261QR0208X',
    description: 'Clinic/Center',
    label: '261QR0208X - Clinic/Center - Radiology, Mobile',
  },
  '261QR0400X - Clinic/Center - Rehabilitation': {
    code: '261QR0400X',
    description: 'Clinic/Center',
    label: '261QR0400X - Clinic/Center - Rehabilitation',
  },
  '261QR0401X - Clinic/Center - Rehabilitation, Comprehensive Outpatient Rehabilitation Facility (CORF)': {
    code: '261QR0401X',
    description: 'Clinic/Center',
    label:
      '261QR0401X - Clinic/Center - Rehabilitation, Comprehensive Outpatient Rehabilitation Facility (CORF)',
  },
  '261QR0404X - Clinic/Center - Rehabilitation, Cardiac Facilities': {
    code: '261QR0404X',
    description: 'Clinic/Center',
    label: '261QR0404X - Clinic/Center - Rehabilitation, Cardiac Facilities',
  },
  '261QR0405X - Clinic/Center - Rehabilitation, Substance Use Disorder': {
    code: '261QR0405X',
    description: 'Clinic/Center',
    label:
      '261QR0405X - Clinic/Center - Rehabilitation, Substance Use Disorder',
  },
  '261QR0800X - Clinic/Center - Recovery Care': {
    code: '261QR0800X',
    description: 'Clinic/Center',
    label: '261QR0800X - Clinic/Center - Recovery Care',
  },
  '261QR1100X - Clinic/Center - Research': {
    code: '261QR1100X',
    description: 'Clinic/Center',
    label: '261QR1100X - Clinic/Center - Research',
  },
  '261QR1300X - Clinic/Center - Rural Health': {
    code: '261QR1300X',
    description: 'Clinic/Center',
    label: '261QR1300X - Clinic/Center - Rural Health',
  },
  '261QS0112X - Clinic/Center - Oral and Maxillofacial Surgery': {
    code: '261QS0112X',
    description: 'Clinic/Center',
    label: '261QS0112X - Clinic/Center - Oral and Maxillofacial Surgery',
  },
  '261QS0132X - Clinic/Center - Ophthalmologic Surgery': {
    code: '261QS0132X',
    description: 'Clinic/Center',
    label: '261QS0132X - Clinic/Center - Ophthalmologic Surgery',
  },
  '261QS1000X - Clinic/Center - Student Health': {
    code: '261QS1000X',
    description: 'Clinic/Center',
    label: '261QS1000X - Clinic/Center - Student Health',
  },
  '261QS1200X - Clinic/Center - Sleep Disorder Diagnostic': {
    code: '261QS1200X',
    description: 'Clinic/Center',
    label: '261QS1200X - Clinic/Center - Sleep Disorder Diagnostic',
  },
  '261QU0200X - Clinic/Center - Urgent Care': {
    code: '261QU0200X',
    description: 'Clinic/Center',
    label: '261QU0200X - Clinic/Center - Urgent Care',
  },
  '261QV0200X - Clinic/Center - VA': {
    code: '261QV0200X',
    description: 'Clinic/Center',
    label: '261QV0200X - Clinic/Center - VA',
  },
  '261QX0100X - Clinic/Center - Occupational Medicine': {
    code: '261QX0100X',
    description: 'Clinic/Center',
    label: '261QX0100X - Clinic/Center - Occupational Medicine',
  },
  '261QX0200X - Clinic/Center - Oncology': {
    code: '261QX0200X',
    description: 'Clinic/Center',
    label: '261QX0200X - Clinic/Center - Oncology',
  },
  '261QX0203X - Clinic/Center - Oncology, Radiation': {
    code: '261QX0203X',
    description: 'Clinic/Center',
    label: '261QX0203X - Clinic/Center - Oncology, Radiation',
  },
  '273100000X - Epilepsy Unit': {
    code: '273100000X',
    description: 'Epilepsy Unit',
    label: '273100000X - Epilepsy Unit',
  },
  '273R00000X - Psychiatric Unit': {
    code: '273R00000X',
    description: 'Psychiatric Unit',
    label: '273R00000X - Psychiatric Unit',
  },
  '273Y00000X - Rehabilitation Unit': {
    code: '273Y00000X',
    description: 'Rehabilitation Unit',
    label: '273Y00000X - Rehabilitation Unit',
  },
  '275N00000X - Medicare Defined Swing Bed Unit': {
    code: '275N00000X',
    description: 'Medicare Defined Swing Bed Unit',
    label: '275N00000X - Medicare Defined Swing Bed Unit',
  },
  '276400000X - Rehabilitation, Substance Use Disorder Unit': {
    code: '276400000X',
    description: 'Rehabilitation, Substance Use Disorder Unit',
    label: '276400000X - Rehabilitation, Substance Use Disorder Unit',
  },
  '281P00000X - Chronic Disease Hospital': {
    code: '281P00000X',
    description: 'Chronic Disease Hospital',
    label: '281P00000X - Chronic Disease Hospital',
  },
  '281PC2000X - Chronic Disease Hospital - Children': {
    code: '281PC2000X',
    description: 'Chronic Disease Hospital',
    label: '281PC2000X - Chronic Disease Hospital - Children',
  },
  '282E00000X - Long Term Care Hospital': {
    code: '282E00000X',
    description: 'Long Term Care Hospital',
    label: '282E00000X - Long Term Care Hospital',
  },
  '282J00000X - Religious Nonmedical Health Care Institution': {
    code: '282J00000X',
    description: 'Religious Nonmedical Health Care Institution',
    label: '282J00000X - Religious Nonmedical Health Care Institution',
  },
  '282N00000X - General Acute Care Hospital': {
    code: '282N00000X',
    description: 'General Acute Care Hospital',
    label: '282N00000X - General Acute Care Hospital',
  },
  '282NC0060X - General Acute Care Hospital - Critical Access': {
    code: '282NC0060X',
    description: 'General Acute Care Hospital',
    label: '282NC0060X - General Acute Care Hospital - Critical Access',
  },
  '282NC2000X - General Acute Care Hospital - Children': {
    code: '282NC2000X',
    description: 'General Acute Care Hospital',
    label: '282NC2000X - General Acute Care Hospital - Children',
  },
  '282NR1301X - General Acute Care Hospital - Rural': {
    code: '282NR1301X',
    description: 'General Acute Care Hospital',
    label: '282NR1301X - General Acute Care Hospital - Rural',
  },
  '282NW0100X - General Acute Care Hospital - Women': {
    code: '282NW0100X',
    description: 'General Acute Care Hospital',
    label: '282NW0100X - General Acute Care Hospital - Women',
  },
  '283Q00000X - Psychiatric Hospital': {
    code: '283Q00000X',
    description: 'Psychiatric Hospital',
    label: '283Q00000X - Psychiatric Hospital',
  },
  '283X00000X - Rehabilitation Hospital': {
    code: '283X00000X',
    description: 'Rehabilitation Hospital',
    label: '283X00000X - Rehabilitation Hospital',
  },
  '283XC2000X - Rehabilitation Hospital - Children': {
    code: '283XC2000X',
    description: 'Rehabilitation Hospital',
    label: '283XC2000X - Rehabilitation Hospital - Children',
  },
  '284300000X - Special Hospital': {
    code: '284300000X',
    description: 'Special Hospital',
    label: '284300000X - Special Hospital',
  },
  '286500000X - Military Hospital': {
    code: '286500000X',
    description: 'Military Hospital',
    label: '286500000X - Military Hospital',
  },
  '2865C1500X - Military Hospital - Community Health': {
    code: '2865C1500X',
    description: 'Military Hospital',
    label: '2865C1500X - Military Hospital - Community Health',
  },
  '2865M2000X - Military Hospital - Military General Acute Care Hospital': {
    code: '2865M2000X',
    description: 'Military Hospital',
    label:
      '2865M2000X - Military Hospital - Military General Acute Care Hospital',
  },
  '2865X1600X - Military Hospital - Military General Acute Care Hospital. Operational (Transportable)': {
    code: '2865X1600X',
    description: 'Military Hospital',
    label:
      '2865X1600X - Military Hospital - Military General Acute Care Hospital. Operational (Transportable)',
  },
  '287300000X - Christian Science Sanitorium': {
    code: '287300000X',
    description: 'Christian Science Sanitorium',
    label: '287300000X - Christian Science Sanitorium',
  },
  '291900000X - Military Clinical Medical Laboratory': {
    code: '291900000X',
    description: 'Military Clinical Medical Laboratory',
    label: '291900000X - Military Clinical Medical Laboratory',
  },
  '291U00000X - Clinical Medical Laboratory': {
    code: '291U00000X',
    description: 'Clinical Medical Laboratory',
    label: '291U00000X - Clinical Medical Laboratory',
  },
  '292200000X - Dental Laboratory': {
    code: '292200000X',
    description: 'Dental Laboratory',
    label: '292200000X - Dental Laboratory',
  },
  '293D00000X - Physiological Laboratory': {
    code: '293D00000X',
    description: 'Physiological Laboratory',
    label: '293D00000X - Physiological Laboratory',
  },
  '302F00000X - Exclusive Provider Organization': {
    code: '302F00000X',
    description: 'Exclusive Provider Organization',
    label: '302F00000X - Exclusive Provider Organization',
  },
  '302R00000X - Health Maintenance Organization': {
    code: '302R00000X',
    description: 'Health Maintenance Organization',
    label: '302R00000X - Health Maintenance Organization',
  },
  '305R00000X - Preferred Provider Organization': {
    code: '305R00000X',
    description: 'Preferred Provider Organization',
    label: '305R00000X - Preferred Provider Organization',
  },
  '305S00000X - Point of Service': {
    code: '305S00000X',
    description: 'Point of Service',
    label: '305S00000X - Point of Service',
  },
  '310400000X - Assisted Living Facility': {
    code: '310400000X',
    description: 'Assisted Living Facility',
    label: '310400000X - Assisted Living Facility',
  },
  '3104A0625X - Assisted Living Facility - Assisted Living, Mental Illness': {
    code: '3104A0625X',
    description: 'Assisted Living Facility',
    label:
      '3104A0625X - Assisted Living Facility - Assisted Living, Mental Illness',
  },
  '3104A0630X - Assisted Living Facility - Assisted Living, Behavioral Disturbances': {
    code: '3104A0630X',
    description: 'Assisted Living Facility',
    label:
      '3104A0630X - Assisted Living Facility - Assisted Living, Behavioral Disturbances',
  },
  '310500000X - Intermediate Care Facility, Mental Illness': {
    code: '310500000X',
    description: 'Intermediate Care Facility, Mental Illness',
    label: '310500000X - Intermediate Care Facility, Mental Illness',
  },
  '311500000X - Alzheimer Center (Dementia Center)': {
    code: '311500000X',
    description: 'Alzheimer Center (Dementia Center)',
    label: '311500000X - Alzheimer Center (Dementia Center)',
  },
  '311Z00000X - Custodial Care Facility': {
    code: '311Z00000X',
    description: 'Custodial Care Facility',
    label: '311Z00000X - Custodial Care Facility',
  },
  '311ZA0620X - Custodial Care Facility - Adult Care Home': {
    code: '311ZA0620X',
    description: 'Custodial Care Facility',
    label: '311ZA0620X - Custodial Care Facility - Adult Care Home',
  },
  '313M00000X - Nursing Facility/Intermediate Care Facility': {
    code: '313M00000X',
    description: 'Nursing Facility/Intermediate Care Facility',
    label: '313M00000X - Nursing Facility/Intermediate Care Facility',
  },
  '314000000X - Skilled Nursing Facility': {
    code: '314000000X',
    description: 'Skilled Nursing Facility',
    label: '314000000X - Skilled Nursing Facility',
  },
  '3140N1450X - Skilled Nursing Facility - Nursing Care, Pediatric': {
    code: '3140N1450X',
    description: 'Skilled Nursing Facility',
    label: '3140N1450X - Skilled Nursing Facility - Nursing Care, Pediatric',
  },
  '315D00000X - Hospice, Inpatient': {
    code: '315D00000X',
    description: 'Hospice, Inpatient',
    label: '315D00000X - Hospice, Inpatient',
  },
  '315P00000X - Intermediate Care Facility, Mentally Retarded': {
    code: '315P00000X',
    description: 'Intermediate Care Facility, Mentally Retarded',
    label: '315P00000X - Intermediate Care Facility, Mentally Retarded',
  },
  '317400000X - Christian Science Facility': {
    code: '317400000X',
    description: 'Christian Science Facility',
    label: '317400000X - Christian Science Facility',
  },
  '320600000X - Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities': {
    code: '320600000X',
    description:
      'Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities',
    label:
      '320600000X - Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities',
  },
  '320700000X - Residential Treatment Facility, Physical Disabilities': {
    code: '320700000X',
    description: 'Residential Treatment Facility, Physical Disabilities',
    label: '320700000X - Residential Treatment Facility, Physical Disabilities',
  },
  '320800000X - Community Based Residential Treatment Facility, Mental Illness': {
    code: '320800000X',
    description:
      'Community Based Residential Treatment Facility, Mental Illness',
    label:
      '320800000X - Community Based Residential Treatment Facility, Mental Illness',
  },
  '320900000X - Community Based Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities': {
    code: '320900000X',
    description:
      'Community Based Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities',
    label:
      '320900000X - Community Based Residential Treatment Facility, Mental Retardation and/or Developmental Disabilities',
  },
  '322D00000X - Residential Treatment Facility, Emotionally Disturbed Children': {
    code: '322D00000X',
    description:
      'Residential Treatment Facility, Emotionally Disturbed Children',
    label:
      '322D00000X - Residential Treatment Facility, Emotionally Disturbed Children',
  },
  '323P00000X - Psychiatric Residential Treatment Facility': {
    code: '323P00000X',
    description: 'Psychiatric Residential Treatment Facility',
    label: '323P00000X - Psychiatric Residential Treatment Facility',
  },
  '324500000X - Substance Abuse Rehabilitation Facility': {
    code: '324500000X',
    description: 'Substance Abuse Rehabilitation Facility',
    label: '324500000X - Substance Abuse Rehabilitation Facility',
  },
  '3245S0500X - Substance Abuse Rehabilitation Facility - Substance Abuse Treatment, Children': {
    code: '3245S0500X',
    description: 'Substance Abuse Rehabilitation Facility',
    label:
      '3245S0500X - Substance Abuse Rehabilitation Facility - Substance Abuse Treatment, Children',
  },
  '331L00000X - Blood Bank': {
    code: '331L00000X',
    description: 'Blood Bank',
    label: '331L00000X - Blood Bank',
  },
  '332000000X - Military/U.S. Coast Guard Pharmacy': {
    code: '332000000X',
    description: 'Military/U.S. Coast Guard Pharmacy',
    label: '332000000X - Military/U.S. Coast Guard Pharmacy',
  },
  '332100000X - Department of Veterans Affairs (VA) Pharmacy': {
    code: '332100000X',
    description: 'Department of Veterans Affairs (VA) Pharmacy',
    label: '332100000X - Department of Veterans Affairs (VA) Pharmacy',
  },
  '332800000X - Indian Health Service/Tribal/Urban Indian Health (I/T/U) Pharmacy': {
    code: '332800000X',
    description:
      'Indian Health Service/Tribal/Urban Indian Health (I/T/U) Pharmacy',
    label:
      '332800000X - Indian Health Service/Tribal/Urban Indian Health (I/T/U) Pharmacy',
  },
  '332900000X - Non-Pharmacy Dispensing Site': {
    code: '332900000X',
    description: 'Non-Pharmacy Dispensing Site',
    label: '332900000X - Non-Pharmacy Dispensing Site',
  },
  '332B00000X - Durable Medical Equipment & Medical Supplies': {
    code: '332B00000X',
    description: 'Durable Medical Equipment & Medical Supplies',
    label: '332B00000X - Durable Medical Equipment & Medical Supplies',
  },
  '332BC3200X - Durable Medical Equipment & Medical Supplies - Customized Equipment': {
    code: '332BC3200X',
    description: 'Durable Medical Equipment & Medical Supplies',
    label:
      '332BC3200X - Durable Medical Equipment & Medical Supplies - Customized Equipment',
  },
  '332BD1200X - Durable Medical Equipment & Medical Supplies - Dialysis Equipment & Supplies': {
    code: '332BD1200X',
    description: 'Durable Medical Equipment & Medical Supplies',
    label:
      '332BD1200X - Durable Medical Equipment & Medical Supplies - Dialysis Equipment & Supplies',
  },
  '332BN1400X - Durable Medical Equipment & Medical Supplies - Nursing Facility Supplies': {
    code: '332BN1400X',
    description: 'Durable Medical Equipment & Medical Supplies',
    label:
      '332BN1400X - Durable Medical Equipment & Medical Supplies - Nursing Facility Supplies',
  },
  '332BP3500X - Durable Medical Equipment & Medical Supplies - Parenteral & Enteral Nutrition': {
    code: '332BP3500X',
    description: 'Durable Medical Equipment & Medical Supplies',
    label:
      '332BP3500X - Durable Medical Equipment & Medical Supplies - Parenteral & Enteral Nutrition',
  },
  '332BX2000X - Durable Medical Equipment & Medical Supplies - Oxygen Equipment & Supplies': {
    code: '332BX2000X',
    description: 'Durable Medical Equipment & Medical Supplies',
    label:
      '332BX2000X - Durable Medical Equipment & Medical Supplies - Oxygen Equipment & Supplies',
  },
  '332G00000X - Eye Bank': {
    code: '332G00000X',
    description: 'Eye Bank',
    label: '332G00000X - Eye Bank',
  },
  '332H00000X - Eyewear Supplier': {
    code: '332H00000X',
    description: 'Eyewear Supplier',
    label: '332H00000X - Eyewear Supplier',
  },
  '332S00000X - Hearing Aid Equipment': {
    code: '332S00000X',
    description: 'Hearing Aid Equipment',
    label: '332S00000X - Hearing Aid Equipment',
  },
  '332U00000X - Home Delivered Meals': {
    code: '332U00000X',
    description: 'Home Delivered Meals',
    label: '332U00000X - Home Delivered Meals',
  },
  '333300000X - Emergency Response System Companies': {
    code: '333300000X',
    description: 'Emergency Response System Companies',
    label: '333300000X - Emergency Response System Companies',
  },
  '333600000X - Pharmacy': {
    code: '333600000X',
    description: 'Pharmacy',
    label: '333600000X - Pharmacy',
  },
  '3336C0002X - Pharmacy - Clinic Pharmacy': {
    code: '3336C0002X',
    description: 'Pharmacy',
    label: '3336C0002X - Pharmacy - Clinic Pharmacy',
  },
  '3336C0003X - Pharmacy - Community/Retail Pharmacy': {
    code: '3336C0003X',
    description: 'Pharmacy',
    label: '3336C0003X - Pharmacy - Community/Retail Pharmacy',
  },
  '3336C0004X - Pharmacy - Compounding Pharmacy': {
    code: '3336C0004X',
    description: 'Pharmacy',
    label: '3336C0004X - Pharmacy - Compounding Pharmacy',
  },
  '3336H0001X - Pharmacy - Home Infusion Therapy Pharmacy': {
    code: '3336H0001X',
    description: 'Pharmacy',
    label: '3336H0001X - Pharmacy - Home Infusion Therapy Pharmacy',
  },
  '3336I0012X - Pharmacy - Institutional Pharmacy': {
    code: '3336I0012X',
    description: 'Pharmacy',
    label: '3336I0012X - Pharmacy - Institutional Pharmacy',
  },
  '3336L0003X - Pharmacy - Long Term Care Pharmacy': {
    code: '3336L0003X',
    description: 'Pharmacy',
    label: '3336L0003X - Pharmacy - Long Term Care Pharmacy',
  },
  '3336M0002X - Pharmacy - Mail Order Pharmacy': {
    code: '3336M0002X',
    description: 'Pharmacy',
    label: '3336M0002X - Pharmacy - Mail Order Pharmacy',
  },
  '3336M0003X - Pharmacy - Managed Care Organization Pharmacy': {
    code: '3336M0003X',
    description: 'Pharmacy',
    label: '3336M0003X - Pharmacy - Managed Care Organization Pharmacy',
  },
  '3336N0007X - Pharmacy - Nuclear Pharmacy': {
    code: '3336N0007X',
    description: 'Pharmacy',
    label: '3336N0007X - Pharmacy - Nuclear Pharmacy',
  },
  '3336S0011X - Pharmacy - Specialty Pharmacy': {
    code: '3336S0011X',
    description: 'Pharmacy',
    label: '3336S0011X - Pharmacy - Specialty Pharmacy',
  },
  '335E00000X - Prosthetic/Orthotic Supplier': {
    code: '335E00000X',
    description: 'Prosthetic/Orthotic Supplier',
    label: '335E00000X - Prosthetic/Orthotic Supplier',
  },
  '335G00000X - Medical Foods Supplier': {
    code: '335G00000X',
    description: 'Medical Foods Supplier',
    label: '335G00000X - Medical Foods Supplier',
  },
  '335U00000X - Organ Procurement Organization': {
    code: '335U00000X',
    description: 'Organ Procurement Organization',
    label: '335U00000X - Organ Procurement Organization',
  },
  '335V00000X - Portable X-ray and/or Other Portable Diagnostic Imaging Supplier': {
    code: '335V00000X',
    description:
      'Portable X-ray and/or Other Portable Diagnostic Imaging Supplier',
    label:
      '335V00000X - Portable X-ray and/or Other Portable Diagnostic Imaging Supplier',
  },
  '341600000X - Ambulance': {
    code: '341600000X',
    description: 'Ambulance',
    label: '341600000X - Ambulance',
  },
  '3416A0800X - Ambulance - Air Transport': {
    code: '3416A0800X',
    description: 'Ambulance',
    label: '3416A0800X - Ambulance - Air Transport',
  },
  '3416L0300X - Ambulance - Land Transport': {
    code: '3416L0300X',
    description: 'Ambulance',
    label: '3416L0300X - Ambulance - Land Transport',
  },
  '3416S0300X - Ambulance - Water Transport': {
    code: '3416S0300X',
    description: 'Ambulance',
    label: '3416S0300X - Ambulance - Water Transport',
  },
  '341800000X - Military/U.S. Coast Guard Transport': {
    code: '341800000X',
    description: 'Military/U.S. Coast Guard Transport',
    label: '341800000X - Military/U.S. Coast Guard Transport',
  },
  '3418M1110X - Military/U.S. Coast Guard Transport - Military or U.S. Coast Guard Ambulance, Ground Transport': {
    code: '3418M1110X',
    description: 'Military/U.S. Coast Guard Transport',
    label:
      '3418M1110X - Military/U.S. Coast Guard Transport - Military or U.S. Coast Guard Ambulance, Ground Transport',
  },
  '3418M1120X - Military/U.S. Coast Guard Transport - Military or U.S. Coast Guard Ambulance, Air Transport': {
    code: '3418M1120X',
    description: 'Military/U.S. Coast Guard Transport',
    label:
      '3418M1120X - Military/U.S. Coast Guard Transport - Military or U.S. Coast Guard Ambulance, Air Transport',
  },
  '3418M1130X - Military/U.S. Coast Guard Transport - Military or U.S. Coast Guard Ambulance, Water Transport': {
    code: '3418M1130X',
    description: 'Military/U.S. Coast Guard Transport',
    label:
      '3418M1130X - Military/U.S. Coast Guard Transport - Military or U.S. Coast Guard Ambulance, Water Transport',
  },
  '343800000X - Secured Medical Transport (VAN)': {
    code: '343800000X',
    description: 'Secured Medical Transport (VAN)',
    label: '343800000X - Secured Medical Transport (VAN)',
  },
  '343900000X - Non-emergency Medical Transport (VAN)': {
    code: '343900000X',
    description: 'Non-emergency Medical Transport (VAN)',
    label: '343900000X - Non-emergency Medical Transport (VAN)',
  },
  '344600000X - Taxi': {
    code: '344600000X',
    description: 'Taxi',
    label: '344600000X - Taxi',
  },
  '344800000X - Air Carrier': {
    code: '344800000X',
    description: 'Air Carrier',
    label: '344800000X - Air Carrier',
  },
  '347B00000X - Bus': {
    code: '347B00000X',
    description: 'Bus',
    label: '347B00000X - Bus',
  },
  '347C00000X - Private Vehicle': {
    code: '347C00000X',
    description: 'Private Vehicle',
    label: '347C00000X - Private Vehicle',
  },
  '347D00000X - Train': {
    code: '347D00000X',
    description: 'Train',
    label: '347D00000X - Train',
  },
  '347E00000X - Transportation Broker': {
    code: '347E00000X',
    description: 'Transportation Broker',
    label: '347E00000X - Transportation Broker',
  },
  '363A00000X - Physician Assistant': {
    code: '363A00000X',
    description: 'Physician Assistant',
    label: '363A00000X - Physician Assistant',
  },
  '363AM0700X - Physician Assistant - Medical': {
    code: '363AM0700X',
    description: 'Physician Assistant',
    label: '363AM0700X - Physician Assistant - Medical',
  },
  '363AS0400X - Physician Assistant - Surgical': {
    code: '363AS0400X',
    description: 'Physician Assistant',
    label: '363AS0400X - Physician Assistant - Surgical',
  },
  '363L00000X - Nurse Practitioner': {
    code: '363L00000X',
    description: 'Nurse Practitioner',
    label: '363L00000X - Nurse Practitioner',
  },
  '363LA2100X - Nurse Practitioner - Acute Care': {
    code: '363LA2100X',
    description: 'Nurse Practitioner',
    label: '363LA2100X - Nurse Practitioner - Acute Care',
  },
  '363LA2200X - Nurse Practitioner - Adult Health': {
    code: '363LA2200X',
    description: 'Nurse Practitioner',
    label: '363LA2200X - Nurse Practitioner - Adult Health',
  },
  '363LC0200X - Nurse Practitioner - Critical Care Medicine': {
    code: '363LC0200X',
    description: 'Nurse Practitioner',
    label: '363LC0200X - Nurse Practitioner - Critical Care Medicine',
  },
  '363LC1500X - Nurse Practitioner - Community Health': {
    code: '363LC1500X',
    description: 'Nurse Practitioner',
    label: '363LC1500X - Nurse Practitioner - Community Health',
  },
  '363LF0000X - Nurse Practitioner - Family': {
    code: '363LF0000X',
    description: 'Nurse Practitioner',
    label: '363LF0000X - Nurse Practitioner - Family',
  },
  '363LG0600X - Nurse Practitioner - Gerontology': {
    code: '363LG0600X',
    description: 'Nurse Practitioner',
    label: '363LG0600X - Nurse Practitioner - Gerontology',
  },
  '363LN0000X - Nurse Practitioner - Neonatal': {
    code: '363LN0000X',
    description: 'Nurse Practitioner',
    label: '363LN0000X - Nurse Practitioner - Neonatal',
  },
  '363LN0005X - Nurse Practitioner - Neonatal, Critical Care': {
    code: '363LN0005X',
    description: 'Nurse Practitioner',
    label: '363LN0005X - Nurse Practitioner - Neonatal, Critical Care',
  },
  '363LP0200X - Nurse Practitioner - Pediatrics': {
    code: '363LP0200X',
    description: 'Nurse Practitioner',
    label: '363LP0200X - Nurse Practitioner - Pediatrics',
  },
  '363LP0222X - Nurse Practitioner - Pediatrics, Critical Care': {
    code: '363LP0222X',
    description: 'Nurse Practitioner',
    label: '363LP0222X - Nurse Practitioner - Pediatrics, Critical Care',
  },
  '363LP0808X - Nurse Practitioner - Psychiatric/Mental Health': {
    code: '363LP0808X',
    description: 'Nurse Practitioner',
    label: '363LP0808X - Nurse Practitioner - Psychiatric/Mental Health',
  },
  '363LP1700X - Nurse Practitioner - Perinatal': {
    code: '363LP1700X',
    description: 'Nurse Practitioner',
    label: '363LP1700X - Nurse Practitioner - Perinatal',
  },
  '363LP2300X - Nurse Practitioner - Primary Care': {
    code: '363LP2300X',
    description: 'Nurse Practitioner',
    label: '363LP2300X - Nurse Practitioner - Primary Care',
  },
  '363LS0200X - Nurse Practitioner - School': {
    code: '363LS0200X',
    description: 'Nurse Practitioner',
    label: '363LS0200X - Nurse Practitioner - School',
  },
  '363LW0102X - Nurse Practitioner - Women`s Health': {
    code: '363LW0102X',
    description: 'Nurse Practitioner',
    label: "363LW0102X - Nurse Practitioner - Women's Health",
  },
  '363LX0001X - Nurse Practitioner - Obstetrics & Gynecology': {
    code: '363LX0001X',
    description: 'Nurse Practitioner',
    label: '363LX0001X - Nurse Practitioner - Obstetrics & Gynecology',
  },
  '363LX0106X - Nurse Practitioner - Occupational Health': {
    code: '363LX0106X',
    description: 'Nurse Practitioner',
    label: '363LX0106X - Nurse Practitioner - Occupational Health',
  },
  '364S00000X - Clinical Nurse Specialist': {
    code: '364S00000X',
    description: 'Clinical Nurse Specialist',
    label: '364S00000X - Clinical Nurse Specialist',
  },
  '364SA2100X - Clinical Nurse Specialist - Acute Care': {
    code: '364SA2100X',
    description: 'Clinical Nurse Specialist',
    label: '364SA2100X - Clinical Nurse Specialist - Acute Care',
  },
  '364SA2200X - Clinical Nurse Specialist - Adult Health': {
    code: '364SA2200X',
    description: 'Clinical Nurse Specialist',
    label: '364SA2200X - Clinical Nurse Specialist - Adult Health',
  },
  '364SC0200X - Clinical Nurse Specialist - Critical Care Medicine': {
    code: '364SC0200X',
    description: 'Clinical Nurse Specialist',
    label: '364SC0200X - Clinical Nurse Specialist - Critical Care Medicine',
  },
  '364SC1501X - Clinical Nurse Specialist - Community Health/Public Health': {
    code: '364SC1501X',
    description: 'Clinical Nurse Specialist',
    label:
      '364SC1501X - Clinical Nurse Specialist - Community Health/Public Health',
  },
  '364SC2300X - Clinical Nurse Specialist - Chronic Care': {
    code: '364SC2300X',
    description: 'Clinical Nurse Specialist',
    label: '364SC2300X - Clinical Nurse Specialist - Chronic Care',
  },
  '364SE0003X - Clinical Nurse Specialist - Emergency': {
    code: '364SE0003X',
    description: 'Clinical Nurse Specialist',
    label: '364SE0003X - Clinical Nurse Specialist - Emergency',
  },
  '364SE1400X - Clinical Nurse Specialist - Ethics': {
    code: '364SE1400X',
    description: 'Clinical Nurse Specialist',
    label: '364SE1400X - Clinical Nurse Specialist - Ethics',
  },
  '364SF0001X - Clinical Nurse Specialist - Family Health': {
    code: '364SF0001X',
    description: 'Clinical Nurse Specialist',
    label: '364SF0001X - Clinical Nurse Specialist - Family Health',
  },
  '364SG0600X - Clinical Nurse Specialist - Gerontology': {
    code: '364SG0600X',
    description: 'Clinical Nurse Specialist',
    label: '364SG0600X - Clinical Nurse Specialist - Gerontology',
  },
  '364SH0200X - Clinical Nurse Specialist - Home Health': {
    code: '364SH0200X',
    description: 'Clinical Nurse Specialist',
    label: '364SH0200X - Clinical Nurse Specialist - Home Health',
  },
  '364SH1100X - Clinical Nurse Specialist - Holistic': {
    code: '364SH1100X',
    description: 'Clinical Nurse Specialist',
    label: '364SH1100X - Clinical Nurse Specialist - Holistic',
  },
  '364SI0800X - Clinical Nurse Specialist - Informatics': {
    code: '364SI0800X',
    description: 'Clinical Nurse Specialist',
    label: '364SI0800X - Clinical Nurse Specialist - Informatics',
  },
  '364SL0600X - Clinical Nurse Specialist - Long-Term Care': {
    code: '364SL0600X',
    description: 'Clinical Nurse Specialist',
    label: '364SL0600X - Clinical Nurse Specialist - Long-Term Care',
  },
  '364SM0705X - Clinical Nurse Specialist - Medical-Surgical': {
    code: '364SM0705X',
    description: 'Clinical Nurse Specialist',
    label: '364SM0705X - Clinical Nurse Specialist - Medical-Surgical',
  },
  '364SN0000X - Clinical Nurse Specialist - Neonatal': {
    code: '364SN0000X',
    description: 'Clinical Nurse Specialist',
    label: '364SN0000X - Clinical Nurse Specialist - Neonatal',
  },
  '364SN0800X - Clinical Nurse Specialist - Neuroscience': {
    code: '364SN0800X',
    description: 'Clinical Nurse Specialist',
    label: '364SN0800X - Clinical Nurse Specialist - Neuroscience',
  },
  '364SP0200X - Clinical Nurse Specialist - Pediatrics': {
    code: '364SP0200X',
    description: 'Clinical Nurse Specialist',
    label: '364SP0200X - Clinical Nurse Specialist - Pediatrics',
  },
  '364SP0807X - Clinical Nurse Specialist - Psychiatric/Mental Health, Child & Adolescent': {
    code: '364SP0807X',
    description: 'Clinical Nurse Specialist',
    label:
      '364SP0807X - Clinical Nurse Specialist - Psychiatric/Mental Health, Child & Adolescent',
  },
  '364SP0808X - Clinical Nurse Specialist - Psychiatric/Mental Health': {
    code: '364SP0808X',
    description: 'Clinical Nurse Specialist',
    label: '364SP0808X - Clinical Nurse Specialist - Psychiatric/Mental Health',
  },
  '364SP0809X - Clinical Nurse Specialist - Psychiatric/Mental Health, Adult': {
    code: '364SP0809X',
    description: 'Clinical Nurse Specialist',
    label:
      '364SP0809X - Clinical Nurse Specialist - Psychiatric/Mental Health, Adult',
  },
  '364SP0810X - Clinical Nurse Specialist - Psychiatric/Mental Health, Child & Family': {
    code: '364SP0810X',
    description: 'Clinical Nurse Specialist',
    label:
      '364SP0810X - Clinical Nurse Specialist - Psychiatric/Mental Health, Child & Family',
  },
  '364SP0811X - Clinical Nurse Specialist - Psychiatric/Mental Health, Chronically Ill': {
    code: '364SP0811X',
    description: 'Clinical Nurse Specialist',
    label:
      '364SP0811X - Clinical Nurse Specialist - Psychiatric/Mental Health, Chronically Ill',
  },
  '364SP0812X - Clinical Nurse Specialist - Psychiatric/Mental Health, Community': {
    code: '364SP0812X',
    description: 'Clinical Nurse Specialist',
    label:
      '364SP0812X - Clinical Nurse Specialist - Psychiatric/Mental Health, Community',
  },
  '364SP0813X - Clinical Nurse Specialist - Psychiatric/Mental Health, Geropsychiatric': {
    code: '364SP0813X',
    description: 'Clinical Nurse Specialist',
    label:
      '364SP0813X - Clinical Nurse Specialist - Psychiatric/Mental Health, Geropsychiatric',
  },
  '364SP1700X - Clinical Nurse Specialist - Perinatal': {
    code: '364SP1700X',
    description: 'Clinical Nurse Specialist',
    label: '364SP1700X - Clinical Nurse Specialist - Perinatal',
  },
  '364SP2800X - Clinical Nurse Specialist - Perioperative': {
    code: '364SP2800X',
    description: 'Clinical Nurse Specialist',
    label: '364SP2800X - Clinical Nurse Specialist - Perioperative',
  },
  '364SR0400X - Clinical Nurse Specialist - Rehabilitation': {
    code: '364SR0400X',
    description: 'Clinical Nurse Specialist',
    label: '364SR0400X - Clinical Nurse Specialist - Rehabilitation',
  },
  '364SS0200X - Clinical Nurse Specialist - School': {
    code: '364SS0200X',
    description: 'Clinical Nurse Specialist',
    label: '364SS0200X - Clinical Nurse Specialist - School',
  },
  '364ST0500X - Clinical Nurse Specialist - Transplantation': {
    code: '364ST0500X',
    description: 'Clinical Nurse Specialist',
    label: '364ST0500X - Clinical Nurse Specialist - Transplantation',
  },
  '364SW0102X - Clinical Nurse Specialist - Women`s Health': {
    code: '364SW0102X',
    description: 'Clinical Nurse Specialist',
    label: "364SW0102X - Clinical Nurse Specialist - Women's Health",
  },
  '364SX0106X - Clinical Nurse Specialist - Occupational Health': {
    code: '364SX0106X',
    description: 'Clinical Nurse Specialist',
    label: '364SX0106X - Clinical Nurse Specialist - Occupational Health',
  },
  '364SX0200X - Clinical Nurse Specialist - Oncology': {
    code: '364SX0200X',
    description: 'Clinical Nurse Specialist',
    label: '364SX0200X - Clinical Nurse Specialist - Oncology',
  },
  '364SX0204X - Clinical Nurse Specialist - Oncology, Pediatrics': {
    code: '364SX0204X',
    description: 'Clinical Nurse Specialist',
    label: '364SX0204X - Clinical Nurse Specialist - Oncology, Pediatrics',
  },
  '367500000X - Nurse Anesthetist, Certified Registered': {
    code: '367500000X',
    description: 'Nurse Anesthetist, Certified Registered',
    label: '367500000X - Nurse Anesthetist, Certified Registered',
  },
  '367A00000X - Advanced Practice Midwife': {
    code: '367A00000X',
    description: 'Advanced Practice Midwife',
    label: '367A00000X - Advanced Practice Midwife',
  },
  '367H00000X - Anesthesiologist Assistant': {
    code: '367H00000X',
    description: 'Anesthesiologist Assistant',
    label: '367H00000X - Anesthesiologist Assistant',
  },
  '372500000X - Chore Provider': {
    code: '372500000X',
    description: 'Chore Provider',
    label: '372500000X - Chore Provider',
  },
  '372600000X - Adult Companion': {
    code: '372600000X',
    description: 'Adult Companion',
    label: '372600000X - Adult Companion',
  },
  '373H00000X - Day Training/Habilitation Specialist': {
    code: '373H00000X',
    description: 'Day Training/Habilitation Specialist',
    label: '373H00000X - Day Training/Habilitation Specialist',
  },
  '374700000X - Technician': {
    code: '374700000X',
    description: 'Technician',
    label: '374700000X - Technician',
  },
  '3747A0650X - Technician - Attendant Care Provider': {
    code: '3747A0650X',
    description: 'Technician',
    label: '3747A0650X - Technician - Attendant Care Provider',
  },
  '3747P1801X - Technician - Personal Care Attendant': {
    code: '3747P1801X',
    description: 'Technician',
    label: '3747P1801X - Technician - Personal Care Attendant',
  },
  '374J00000X - Doula': {
    code: '374J00000X',
    description: 'Doula',
    label: '374J00000X - Doula',
  },
  '374K00000X - Religious Nonmedical Practitioner': {
    code: '374K00000X',
    description: 'Religious Nonmedical Practitioner',
    label: '374K00000X - Religious Nonmedical Practitioner',
  },
  '374T00000X - Religious Nonmedical Nursing Personnel': {
    code: '374T00000X',
    description: 'Religious Nonmedical Nursing Personnel',
    label: '374T00000X - Religious Nonmedical Nursing Personnel',
  },
  '374U00000X - Home Health Aide': {
    code: '374U00000X',
    description: 'Home Health Aide',
    label: '374U00000X - Home Health Aide',
  },
  '376G00000X - Nursing Home Administrator': {
    code: '376G00000X',
    description: 'Nursing Home Administrator',
    label: '376G00000X - Nursing Home Administrator',
  },
  '376J00000X - Homemaker': {
    code: '376J00000X',
    description: 'Homemaker',
    label: '376J00000X - Homemaker',
  },
  '376K00000X - Nurse`s Aide': {
    code: '376K00000X',
    description: "Nurse's Aide",
    label: "376K00000X - Nurse's Aide",
  },
  '385H00000X - Respite Care': {
    code: '385H00000X',
    description: 'Respite Care',
    label: '385H00000X - Respite Care',
  },
  '385HR2050X - Respite Care - Respite Care Camp': {
    code: '385HR2050X',
    description: 'Respite Care',
    label: '385HR2050X - Respite Care - Respite Care Camp',
  },
  '385HR2055X - Respite Care - Respite Care, Mental Illness, Child': {
    code: '385HR2055X',
    description: 'Respite Care',
    label: '385HR2055X - Respite Care - Respite Care, Mental Illness, Child',
  },
  '385HR2060X - Respite Care - Respite Care, Mental Retardation and/or Developmental Disabilities': {
    code: '385HR2060X',
    description: 'Respite Care',
    label:
      '385HR2060X - Respite Care - Respite Care, Mental Retardation and/or Developmental Disabilities',
  },
  '385HR2065X - Respite Care - Respite Care, Physical Disabilities, Child': {
    code: '385HR2065X',
    description: 'Respite Care',
    label:
      '385HR2065X - Respite Care - Respite Care, Physical Disabilities, Child',
  },
  '390200000X - Student in an Organized Health Care Education/Training Program': {
    code: '390200000X',
    description:
      'Student in an Organized Health Care Education/Training Program',
    label:
      '390200000X - Student in an Organized Health Care Education/Training Program',
  },
  '405300000X - Prevention Professional': {
    code: '405300000X',
    description: 'Prevention Professional',
    label: '405300000X - Prevention Professional',
  },
});

export const TAXONOMY_DATA_EMPTY = {
  ...ITEM_EMPTY,
  data: { ...ITEM_EMPTY.data, description: '' },
};

export const TAXONOMY_DATA = [
  ...Object.keys(TAXONOMY).map(key => ({
    label: TAXONOMY[key].label,
    data: {
      id: TAXONOMY[key].code,
      description: TAXONOMY[key].description,
    },
  })),
  TAXONOMY_DATA_EMPTY,
];
