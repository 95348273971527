import * as router from '@neb/router';

import {
  DISCARD_BUTTON,
  openDirtyDialog,
  openDirtyPopup,
  SAVE_BUTTON,
  STAY_BUTTON,
} from '../neb-popup';

const ACTION_START_NAVIGATION = 'NEB_ROUTE_START_NAVIGATION';
const ACTION_WARN = 'NEB_ROUTE_WARN';
const ACTION_PREVENT_NAVIGATION = 'NEB_ROUTE_PREVENT_NAVIGATION';
const ACTION_MANUAL_UPDATE_HASH = 'ACTION_MANUAL_UPDATE_HASH';

export const ACTION_NAVIGATE = 'NEB_ROUTE_NAVIGATE';
/**
 * @typedef {Object} Route
 * @property {string} hash
 * @property {string} nextHash
 * @property {boolean} preventAppendHistory
 * @property {boolean} replaceHistoryIfPrevented
 * @property {boolean} navigating
 * @property {boolean} warn
 * @property {boolean} manualWarn
 */

/**
 * @param {Route} state
 * @param {Object} action
 * @returns {Route}
 */

export const routeReducer = (
  state = {
    hash: undefined,
    prevHash: undefined,
    nextHash: undefined,
    sync: true,
    preventAppendHistory: false,
    replaceHistoryIfPrevented: false,
    navigating: false,
    warn: false,
    manualWarn: false,
  },
  action,
) => {
  switch (action.type) {
    case ACTION_START_NAVIGATION:
      return {
        ...state,
        nextHash: action.hash,
        sync: action.sync,
        preventAppendHistory: action.preventAppendHistory,
        replaceHistoryIfPrevented: action.replaceHistoryIfPrevented,
        navigating: true,
        warn: false,
        manualWarn: false,
      };

    case ACTION_NAVIGATE:
      return {
        ...state,
        hash: action.hash,
        prevHash: action.prevHash,
        nextHash: null,
        sync: false,
        preventAppendHistory: false,
        replaceHistoryIfPrevented: false,
        navigating: false,
        warn: false,
        manualWarn: false,
      };

    case ACTION_WARN:
      return { ...state, warn: true, manualWarn: Boolean(action.manualWarn) };

    case ACTION_PREVENT_NAVIGATION:
      return {
        ...state,
        nextHash: null,
        preventAppendHistory: false,
        replaceHistoryIfPrevented: false,
        navigating: false,
        warn: false,
        manualWarn: false,
      };

    case ACTION_MANUAL_UPDATE_HASH:
      return { ...state, hash: action.hash };

    default:
      return state;
  }
};

/**
 * Dispatch this action afte a user has been warned and the navigation should proceed.
 *
 * @param {boolean} preventAppendHistory
 * @param {string} hash
 * @returns {Object}
 */
export const continueNavigation = (dispatch, getState) => {
  const {
    sync,
    preventAppendHistory,
    replaceHistoryIfPrevented,
    warn,
    hash,
    nextHash,
    manualWarn,
  } = getState().route;

  if (manualWarn) {
    window.dispatchEvent(new CustomEvent('neb-dirty-manual-proceed'));
    /*
     * Because when manually warning the user, the page is not navigating anywhere, so by
     * triggering the prevent navigation, the state will be set appropriatly.
     */

    return dispatch({
      type: ACTION_PREVENT_NAVIGATION,
    });
  }

  if (warn) {
    window.dispatchEvent(new CustomEvent('neb-dirty-proceed'));
  }

  if (!preventAppendHistory) {
    window.history.pushState({}, '', nextHash);
  }

  if (replaceHistoryIfPrevented && warn) {
    window.history.replaceState({}, '', nextHash);
  } else if (sync && hash !== nextHash) {
    router.syncRoute(hash);
  }

  window.dispatchEvent(new CustomEvent('neb-route-hashchange'));
  return dispatch({
    type: ACTION_NAVIGATE,
    prevHash: hash,
    hash: nextHash,
  });
};

/**
 * Dispatch this action to start the navigation process. It will first update the `navigating`
 * state to give the oportunity of other components to warn the user and prevent the navigation.
 *
 * If nothing prevented then navigation it will continue with the navigation, setting the `hash`
 * state.
 *
 * @param {string} hash
 * @returns {Promise}
 */
export const navigate = (
  hash,
  options = {
    sync: true,
    preventAppendHistory: false,
    replaceHistoryIfPrevented: false,
  },
) => (dispatch, getState) => {
  const { sync, preventAppendHistory, replaceHistoryIfPrevented } = options;
  dispatch({
    type: ACTION_START_NAVIGATION,
    hash,
    sync,
    preventAppendHistory,
    replaceHistoryIfPrevented,
  });

  const { navigating, warn, nextHash } = getState().route;

  if (navigating && !warn && nextHash !== null && nextHash !== undefined) {
    dispatch(continueNavigation);
  }
};
/**
 * Dispatch this action after a user has been warned and the navigation should be prevented.
 *
 * @returns {Object}
 */

export const preventNavigation = (dispatch, getState) => {
  const { manualWarn, hash } = getState().route;

  if (manualWarn) {
    window.dispatchEvent(new CustomEvent('neb-dirty-manual-canceled'));
  }

  window.dispatchEvent(new CustomEvent('neb-route-canceled'));
  dispatch({
    type: ACTION_PREVENT_NAVIGATION,
  });

  if (window.location.href.indexOf(hash) === -1) {
    window.history.back();
  }
};

/**
 * Dispatch this action to update the state so the user can be warned before navigating.
 *
 * @returns {Object}
 */
export const warn = (
  manualWarn = false,
  { useNewDirtyDialog, isDirty, formIsValid, onSave, onDiscard, onStay } = {
    manualWarn: false,
    useNewDirtyDialog: false,
    isDirty: false,
    formIsValid: false,
    onSave: async () => {},
    onDiscard: async () => {},
    onStay: async () => {},
  },
) => async dispatch => {
  dispatch({
    type: ACTION_WARN,
    manualWarn,
  });

  if (useNewDirtyDialog) {
    const option = !isDirty || (await openDirtyDialog({ formIsValid }));

    if (!option || option === STAY_BUTTON.name) {
      await onStay();
      dispatch(preventNavigation);
    }

    if (option === SAVE_BUTTON.name) {
      await onSave();
      dispatch(continueNavigation);
    }

    if (option === DISCARD_BUTTON.name) {
      await onDiscard();
      dispatch(continueNavigation);
    }
  }

  if (!useNewDirtyDialog) {
    if (await openDirtyPopup()) {
      dispatch(continueNavigation);
    } else {
      dispatch(preventNavigation);
    }
  }
};

/**
 * Dispatch this action to manually warn with no navigation
 *
 * @returns {Object}
 */
export const manuallyWarn = () => warn(true);

export const manuallyUpdateHash = hash => ({
  type: ACTION_MANUAL_UPDATE_HASH,
  hash,
});
