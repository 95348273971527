/* 
    WARNING!!!!

  This component does not render into a shadowDom and is specifically designed for the login page.

  Only use with approval of Team Crab
*/

import '../../../../packages/neb-lit-components/src/components/inputs/neb-textbox';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-text-helper';

import { html, css } from 'lit';

import {
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_COLOR_ERROR,
} from '../../../../packages/neb-styles/neb-variables';
import { baseStyles, CSS_COLOR_DISABLED } from '../../../styles';
import { LightDom } from '../../misc/neb-light-dom';

export const ELEMENTS = {
  floatingLabel: { selector: '.floating-label' },
  helper: { selector: 'neb-text-helper' },
  input: { selector: 'input' },
  textbox: { selector: 'neb-textbox' },
};

class NebLightTextfield extends LightDom {
  static get properties() {
    return {
      __focused: Boolean,

      autoComplete: String,
      disabled: { type: Boolean, reflect: true },
      error: String,
      helper: String,
      inputMode: String,
      label: String,
      manuallyPinLabel: Boolean,
      maxLength: Number,
      name: String,
      type: String,
      value: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .neb-light-textfield-container {
          height: 100%;
          width: 100%;
        }

        .input {
          border: none;
          width: 100%;
          height: 100%;
          min-width: 0;
          background-color: ${CSS_COLOR_WHITE};
          font-size: ${CSS_FONT_SIZE_BODY};
          flex: 1 0 0;
          padding: 0;
        }

        .input:-moz-ui-invalid {
          box-shadow: none !important;
        }

        .input::-webkit-outer-spin-button,
        .input::-webkit-inner-spin-button {
          margin: 0;
          -webkit-appearance: none;
        }

        .textbox {
          --indent: 12px;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${CSS_COLOR_WHITE} inset !important;
        }

        input:-webkit-autofill:first-line {
          font-size: ${CSS_FONT_SIZE_BODY} !important;
        }

        .floating-label {
          transition: top 200ms, left 200ms;
          position: relative;
          top: 29px;
          left: 15px;
          pointer-events: none;
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_GREY_1};
        }

        .input[focused] {
          caret-color: ${CSS_COLOR_HIGHLIGHT};
        }

        .floating-label[focused] {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .input[disabled],
        .floatingLabel[disabled] {
          color: ${CSS_COLOR_DISABLED} !important;
          opacity: 0.5;
        }

        .floating-label[pinned] {
          top: -2px;
          left: 4px;
          font-size: 12px;
        }

        .floating-label[invalid] {
          color: ${CSS_COLOR_ERROR};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__focused = false;

    this.autoComplete = '';
    this.disabled = false;
    this.error = '';
    this.helper = '';
    this.inputMode = 'text';
    this.label = '';
    this.manuallyPinLabel = false;
    this.maxLength = null;
    this.name = '';
    this.type = 'text';
    this.value = '';

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      blur: () => {
        this.__focused = false;
      },

      focus: () => {
        this.__focused = true;
      },

      input: e => {
        this.onChange({ name: this.name, value: e.currentTarget.value });
      },
    };
  }

  get __hasError() {
    return Boolean(this.error);
  }

  get __helperText() {
    return this.error ? this.error : this.helper;
  }

  get __labelPinned() {
    return this.value.length || this.__focused || this.manuallyPinLabel;
  }

  __renderFloatingLabel() {
    return html`
      <span
        class="floating-label"
        ?disabled="${this.disabled}"
        ?focused="${this.__focused}"
        ?invalid="${this.__hasError}"
        ?pinned="${this.__labelPinned}"
        >${this.label}</span
      >
    `;
  }

  __renderHelper() {
    return html`
      <neb-text-helper
        .text="${this.__helperText}"
        ?disabled="${this.disabled}"
        ?invalid="${this.__hasError}"
      ></neb-text-helper>
    `;
  }

  renderContent() {
    return html`
      <div class="neb-light-textfield-container">
        ${this.__renderFloatingLabel()}

        <neb-textbox
          class="textbox"
          ?disabled="${this.disabled}"
          ?focused="${this.__focused}"
          ?invalid="${this.__hasError}"
        >
          <input
            class="input"
            autocomplete="${this.autoComplete}"
            inputmode="${this.inputMode}"
            maxlength="${this.maxLength}"
            ?focused="${this.__focused}"
            ?disabled="${this.disabled}"
            .name="${this.type}"
            .type="${this.type}"
            .value="${this.value}"
            @blur="${this.__handlers.blur}"
            @focus="${this.__handlers.focus}"
            @input="${this.__handlers.input}"
          />
        </neb-textbox>

        ${this.__renderHelper()}
      </div>
    `;
  }
}

customElements.define('neb-light-textfield', NebLightTextfield);
