import './controls/neb-button-action';
import './neb-pagination';
import './neb-page-collection-header';
import './tables/neb-table-old';
import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
} from '../../../neb-styles/neb-variables';
import { CollectionService } from '../../../neb-utils/services/collection';
import { FetchService } from '../../../neb-utils/services/fetch';
import { openOverlay } from '../utils/overlay-constants';

import { SORT_DIR } from './neb-table-header';

export const LABEL_NONE_FOUND = 'No results.';
export const ELEMENTS = {
  container: { id: 'container' },
  header: { id: 'header', tag: 'neb-page-collection-header' },
  addButton: { id: 'button-add' },
  table: { id: 'table', tag: 'neb-table-old' },
  pagination: { id: 'pagination', tag: 'neb-pagination' },
};

export default class CollectionPage extends LitElement {
  static get properties() {
    return {
      __tableState: Object,
      __unifyForm: {
        reflect: true,
        type: Boolean,
        attribute: 'unifyform',
      },
      __ignoreRowFormPadding: {
        reflect: true,
        type: Boolean,
        attribute: 'ignorerowformpadding',
      },
      __headerSideButtonConfig: Object,
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  static get config() {
    return {
      description: '',
      hideDetailArrow: false,
      hideHeader: false,
      inactiveLabel: '',
      initialSortKey: '',
      initialSortOrder: SORT_DIR.ASC,
      lookupKey: 'id',
      overlayKey: '',
      pageSize: 10,
      pluralName: '',
      searchLabel: '',
      showInactiveFilter: true,
      singularName: '',
      tableConfig: [],
      unifyForm: true,
      ignoreRowFormPadding: false,
      useFetch: false,
      optOutCollectionPagination: false,
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
    this.initService();
  }

  initState() {
    this.__unifyForm = this.getConfig().unifyForm;
    this.__ignoreRowFormPadding = this.getConfig().ignoreRowFormPadding;
    this.__tableState = {
      hideInactive: true,
      filteredCount: 0,
      pageIndex: 0,
      pageCount: 1,
      pageSize: this.getConfig().pageSize || 10,
      searchText: '',
      pageItems: [],
      optOutPagination: this.getConfig().optOutCollectionPagination,
      sortParams: {
        key: this.getConfig().initialSortKey,
        dir: this.getConfig().initialSortOrder,
      },
    };

    this.__headerSideButtonConfig = null;

    this.layout = '';
  }

  initService() {
    this.service = this.getConfig().useFetch
      ? new FetchService(
          {
            onChange: state => {
              this.__tableState = state;
              this.changeOccurred(state);
            },
          },
          this.handlers.fetchData,
          this.__tableState,
        )
      : new CollectionService(
          {
            onChange: state => {
              this.__tableState = state;
              this.changeOccurred(state);
            },
            onSearch: ctx => this.filterSearchItem(ctx),
            onSort: (a, b, params) => this.sort(a, b, params),
            onCacheItem: item => this.formatSearchItem(item),
          },
          this.__tableState,
        );

    if (this.getConfig().showInactiveFilter !== undefined) {
      this.service.hideInactive(this.getConfig().showInactiveFilter);
    }
  }

  initHandlers() {
    this.handlers = {
      fetchData: _query => {
        throw new Error('client not implemented');
      },
      addItem: () => this.openItemOverlay(),
      selectItem: (_, item) => this.openItemOverlay({ ...item }),
      selectPage: pageIndex => this.service.setPageIndex(pageIndex),
      tableChange: () => {},
      hideInactive: hide => this.service.hideInactive(hide),
      search: text => this.service.search(text),
      sort: params => this.service.setSortParams(params),
      renderExpandableRow: () => null,
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    if (this.getConfig().disableInitialLoad) return;

    if (this.getConfig().useFetch) {
      this.service.fetch();
    } else {
      const items = await this.fetch();
      this.service.setItems(items);
    }
  }

  async openItemOverlay(item) {
    const resultItem = await openOverlay(this.getConfig().overlayKey, {
      context: this.buildContext(),
      item,
    });

    if (resultItem) {
      if (this.getConfig().fetchAllAfterAddOrUpdate) {
        const items = await this.fetch();
        this.service.setItems(items);
        return;
      }

      if (this.getConfig().useFetch) {
        this.service.fetch();
      } else if (!item) {
        this.service.addItem(resultItem);
      } else {
        this.service.updateItem(resultItem);
      }
    }
  }

  changeOccurred(_state) {}

  fetch() {
    throw new Error('fetch() not implemented');
  }

  buildContext() {
    return {};
  }

  formatSearchItem(item) {
    return item;
  }

  filterSearchItem(_ctx) {
    return true;
  }

  sort(a, b, key) {
    const configObj = this.getConfig().tableConfig.find(obj => key === obj.key);

    let { compare } = this;

    if (configObj && configObj.compare) {
      compare = configObj.compare;
    }

    return compare(a[key], b[key]);
  }

  compare(a, b) {
    const A = a != null ? a.toString().toUpperCase() : '';
    const B = b != null ? b.toString().toUpperCase() : '';

    if (A !== B) {
      return A < B ? -1 : 1;
    }

    return 0;
  }

  getMasterCount() {
    if (this.getConfig().useFetch) {
      throw new Error('getMasterCount not implemented');
    } else {
      return this.service.getTotalCount();
    }
  }

  getTitle() {
    const countStr = this.__tableState.pageItems.length
      ? ` (${this.__tableState.filteredCount})`
      : '';

    return this.getConfig().pluralName
      ? `${this.getConfig().pluralName}${countStr}`
      : '';
  }

  getConfig() {
    return this.constructor.config;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .container {
          display: flex;
          overflow: auto;
          padding: ${CSS_SPACING};
          width: 100%;
          height: 100%;
          min-height: 0;
          flex-flow: column nowrap;
        }

        :host([unifyform]) .container {
          padding: 0;
        }

        :host([layout='small']:not([unifyform])) .container {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 0;
        }

        .header {
          margin-bottom: ${CSS_SPACING};
        }

        :host([unifyform]) .header,
        :host([layout='small']:not([unifyform])) .header {
          padding-left: ${CSS_SPACING};
          padding-right: ${CSS_SPACING};
        }

        .row {
          display: flex;
          margin-bottom: ${CSS_SPACING};
        }

        .row:last-child {
          margin-bottom: 0;
        }

        .row-margins {
          margin-left: ${CSS_SPACING};
          margin-right: ${CSS_SPACING};
        }

        :host([ignoreRowFormPadding]) .row-form {
          padding: 0;
          border-radius: 4px;
          background-color: ${CSS_COLOR_WHITE};
          flex-flow: column nowrap;
          flex: 1 0 auto;
        }

        .row-form {
          padding: ${CSS_SPACING} 0;
          border-radius: 4px;
          background-color: ${CSS_COLOR_WHITE};
          flex-flow: column nowrap;
          flex: 1 0 auto;
        }

        .cell-spacer {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderHeader() {
    return !this.getConfig().hideHeader
      ? html`
          <neb-page-collection-header
            id="${ELEMENTS.header.id}"
            class="header"
            .hideInactive="${this.__tableState.hideInactive}"
            .layout="${this.layout}"
            .title="${this.getTitle()}"
            .description="${this.getConfig().description}"
            .inactiveLabel="${this.getConfig().inactiveLabel}"
            .searchLabel="${this.getConfig().searchLabel}"
            .searchText="${this.__tableState.searchText}"
            .onHideInactive="${this.handlers.hideInactive}"
            .onSearch="${this.handlers.search}"
            .headerSideButtonConfig="${this.__headerSideButtonConfig}"
          ></neb-page-collection-header>
        `
      : '';
  }

  __renderAddButton() {
    return this.getConfig().singularName
      ? html`
          <div class="row row-margins">
            <neb-button-action
              id="${ELEMENTS.addButton.id}"
              class="cell"
              .label="Add ${this.getConfig().singularName}"
              .onClick="${this.handlers.addItem}"
            ></neb-button-action>
            <div class="cell cell-spacer"></div>
          </div>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderAddButton()}

      <div class="row">${this.renderTable()}</div>

      ${this.renderFooter()} ${this.renderPagination()}
    `;
  }

  renderPagination() {
    return this.__tableState.pageCount > 1 &&
      !this.getConfig().optOutCollectionPagination
      ? html`
          <div class="row row-margins">
            <div class="cell cell-spacer"></div>
            <div class="cell">
              <neb-pagination
                id="${ELEMENTS.pagination.id}"
                .pageCount="${this.__tableState.pageCount}"
                .currentPage="${this.__tableState.pageIndex}"
                .onPageChanged="${this.handlers.selectPage}"
              ></neb-pagination>
            </div>
          </div>
        `
      : '';
  }

  renderTable() {
    return html`
      <neb-table-old
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        .layout="${this.layout}"
        .config="${this.getConfig().tableConfig}"
        .model="${this.__tableState.pageItems}"
        .sortParams="${this.__tableState.sortParams}"
        .lookupKey="${this.getConfig().lookupKey}"
        .onSort="${this.handlers.sort}"
        .onSelect="${this.handlers.selectItem}"
        .onChange="${this.handlers.tableChange}"
        ?showDetailArrow="${!this.getConfig().hideDetailArrow}"
        .getDynamicLeadingHeaderActions="${this.getDynamicLeadingHeaderActions}"
        .getDynamicTrailingHeaderActions="${
          this.getDynamicTrailingHeaderActions
        }"
        .getTrailingHeaderActions="${this.getTrailingHeaderActions}"
        .getTrailingActions="${this.getTrailingActions}"
        .onRenderExpandableRow="${
          this.handlers.renderExpandableRow() !== null
            ? this.handlers.renderExpandableRow
            : null
        }"
        >${this.renderTableSlot()}
      </neb-table-old>
    `;
  }

  renderFooter() {
    return html``;
  }

  renderTableSlot() {
    return this.getMasterCount()
      ? LABEL_NONE_FOUND
      : this.renderNoItemsContent();
  }

  renderNoItemsContent() {
    throw new Error('renderNoItemsContent() not implemented');
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        ${
          this.getConfig().unifyForm
            ? html`
                <div class="row row-form">
                  ${this.renderHeader()} ${this.renderContent()}
                </div>
              `
            : html`
                ${this.renderHeader()}

                <div class="row row-form">${this.renderContent()}</div>
              `
        }
      </div>
    `;
  }
}
