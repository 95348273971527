import '@polymer/polymer/polymer-legacy';

import { html } from 'lit';

const _documentContainer = document.createElement('div');

_documentContainer.setAttribute('style', 'display: none;');

export const nebButtonStyles = () => html`
  <style>
    /*
       * Focus or highlighted buttons where users will spend their focus on.
       */
    .neb-button-primary-focus {
      @apply --neb-button-round-focus;
      background-color: var(--neb-button-primary-focus-color);
      color: var(--neb-button-secondary-focus-color);
    }
    .neb-button-primary-focus:hover,
    .neb-button-primary-focus:focus {
      @apply --neb-button-hover;
    }
    .neb-button-primary-focus[disabled] {
      @apply --neb-button-disabled;
    }

    .neb-button-secondary-focus {
      @apply --neb-button-round-focus;
      background-color: var(--neb-button-secondary-focus-color);
      color: var(--neb-button-primary-focus-color);
    }
    .neb-button-secondary-focus:hover,
    .neb-button-secondary-focus:focus {
      @apply --neb-button-hover;
    }
    .neb-button-secondary-focus[disabled] {
      @apply --neb-button-disabled;
    }

    .neb-button-text-focus {
      @apply --neb-button-text-base;
      --paper-button: {
        background-color: none;
      }
      --paper-button-ink-color: transparent;
      @apply --neb-button-base;
      color: var(--neb-button-primary-focus-color);
    }
    .neb-button-text-focus:after {
      @apply --neb-button-text-underline;
      background: var(--neb-button-primary-focus-color);
    }
    .neb-button-text-focus:hover,
    .neb-button-text-focus:focus {
      @apply --neb-button-hover;
    }
    .neb-button-text-focus[disabled] {
      --paper-button-disabled: {
        background-color: none;
      }
      @apply --neb-button-disabled;
    }

    .neb-button-plain-text-focus {
      @apply --neb-button-text-base;
      @apply --neb-button-base;
      @apply --neb-button-plain-text;
      --paper-button: {
        background-color: none;
      }
      --paper-button-ink-color: transparent;
      color: var(--neb-button-primary-focus-color);
    }
    .neb-button-plain-text-focus:after {
      @apply --neb-button-text-underline;
      background: var(--neb-button-primary-focus-color);
    }
    .neb-button-plain-text-focus:hover,
    .neb-button-plain-text-focus:focus {
      @apply --neb-button-hover;
    }
    .neb-button-plain-text-focus[disabled] {
      --paper-button-disabled: {
        background-color: none;
      }
      @apply --neb-button-disabled;
    }

    /* 
        Regular or non-highlighted buttons.
       */
    .neb-button-primary {
      @apply --neb-button-round;
      background-color: var(--neb-button-primary-color);
      color: var(--neb-button-secondary-color);
    }
    .neb-button-primary:hover,
    .neb-button-primary:focus {
      @apply --neb-button-hover;
    }
    .neb-button-primary[disabled] {
      @apply --neb-button-disabled;
    }

    .neb-button-secondary {
      @apply --neb-button-round;
      background-color: var(--neb-button-secondary-color);
      color: var(--neb-button-primary-color);
    }
    .neb-button-secondary:hover,
    .neb-button-secondary:focus {
      @apply --neb-button-hover;
    }
    .neb-button-secondary[disabled] {
      @apply --neb-button-disabled;
    }

    .neb-button-text {
      @apply --neb-button-text-base;
      --paper-button: {
        background-color: none;
      }
      --paper-button-ink-color: transparent;
      @apply --neb-button-base;
      color: var(--neb-button-primary-color);
    }
    .neb-button-text:after {
      @apply --neb-button-text-underline;
      background: var(--neb-button-primary-color);
    }
    .neb-button-text:hover,
    .neb-button-text:focus {
      @apply --neb-button-hover;
    }
    .neb-button-text[disabled] {
      --paper-button-disabled: {
        background-color: none;
      }
      @apply --neb-button-disabled;
    }

    .neb-button-plain-text {
      @apply --neb-button-text-base;
      @apply --neb-button-base;
      @apply --neb-button-plain-text;
      --paper-button: {
        background-color: none;
      }
      --paper-button-ink-color: transparent;
      color: var(--neb-button-primary-color);
    }
    .neb-button-plain-text:after {
      @apply --neb-button-text-underline;
      background: var(--neb-button-primary-color);
    }
    .neb-button-plain-text:hover,
    .neb-button-plain-text:focus {
      @apply --neb-button-hover;
    }
    .neb-button-plain-text[disabled] {
      --paper-button-disabled: {
        background-color: none;
      }
      @apply --neb-button-disabled;
    }
  </style>
`;
_documentContainer.innerHTML = `
<custom-style>
  <style is="custom-style">
    html {

      --neb-button-height: 30px;
      --neb-button-border-radius: 18px;
      --neb-button-label-vertical-padding: 8px;
      --neb-button-label-side-padding: 20px;
    

      --neb-button-primary-focus-color: var(--neb-color-primary-4);
      --neb-button-secondary-focus-color: var(--neb-color-light-primary);
      
      --neb-button-primary-color: var(--neb-color-grey-1);
      --neb-button-secondary-color: var(--neb-color-light-primary);


      --neb-button-base: {
        font-weight: bold;
        font-family: var(--neb-font-family);
        opacity: 1;
      };

      --neb-button-text-base: {
        margin-left: 0;
        padding-left: 0;
        margin-right: 0;
        padding-right: 0;
        min-width: 10px;
        display: inline-block;
      }
      --neb-button-text-underline: {
        position: absolute;
        content: '';
        height: 1px;
        width: 100%;
        display: inline-block;
        margin: 16px 0;
        left: 0;
      }
      --neb-button-round-base: {
        @apply --neb-button-base;
        border-radius: var(--neb-button-border-radius);
        height: var(--neb-button-height);
        padding: var(--neb-button-label-vertical-padding) var(--neb-button-label-side-padding);
      };

      --neb-button-round-focus: {
        @apply --neb-button-round-base;
        border: 1px solid var(--neb-button-primary-focus-color);
      };

      --neb-button-round: {
        @apply --neb-button-round-base;
        border: 1px solid var(--neb-button-primary-color);
      };

      --neb-button-hover: {
        opacity: .65;
      }

      --neb-button-disabled: {
        opacity: .25;
      }      

      --neb-button-plain-text: {
        text-transform: none;
        font-weight: normal;
      }
      
    }
  </style>
</custom-style>

<dom-module id="neb-button-styles">
  <template>
    ${nebButtonStyles()}
  </template>
</dom-module>`;

document.head.appendChild(_documentContainer);
export const renderNebButtonStyles = nebButtonStyles;
