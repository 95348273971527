import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import {
  openError,
  openSuccess,
} from '../../../packages/neb-dialog/neb-banner-state';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/neb-page-collection';
import { SORT_DIR } from '../../../packages/neb-lit-components/src/components/tables/neb-table';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { downloadURLtoFile } from '../../../packages/neb-utils/blobProcessor';
import {
  deleteDownloadedFiles,
  downloadFile,
  getFileDownloads,
} from '../../api-clients/eclaim-file-download';
import { CSS_SPACING } from '../../styles';
import { ECLAIM_FILE_DOWNLOAD_STATUS } from '../../utils/eclaim-file-download';
import {
  CONFIRM_MESSAGE,
  DOWNLOAD_HISTORY_DELETE_POPUP_MESSAGE,
  DOWNLOAD_HISTORY_DELETE_POPUP_TITLE,
  DOWNLOAD_HISTORY_DELETE_MESSAGE_ERROR,
  DOWNLOAD_HISTORY_DELETE_MESSAGE_SUCCESS,
  DOWNLOAD_HISTORY_DELETE_MESSAGE_NONE_SELECTED,
} from '../../utils/user-message';
import TableEclaimFileDownloads from '../tables/practice/clearinghouse/neb-table-eclaim-file-downloads';
import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../../../packages/neb-lit-components/src/components/neb-button';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonRefresh: { id: 'button-refresh' },
};

export const DELETE_HISTORY_POPUP_CONFIRM_MESSAGE = html`
  ${DOWNLOAD_HISTORY_DELETE_POPUP_MESSAGE}<br /><br />${CONFIRM_MESSAGE()}
`;

class CollectionPageClaimFileDownloads extends CollectionPage {
  static get config() {
    return {
      hideHeader: true,
      showInactiveFilter: false,
      pluralName: 'Claim 837 File Downloads',
      initialSortKey: 'createdAt',
      initialSortOrder: SORT_DIR.DESC,
      description: 'Download Claim 837 Zip Files',
      tableConfig: TableEclaimFileDownloads.config,
      pageSize: 20,
    };
  }

  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          padding: 0 0 ${CSS_SPACING} 0;
        }
        .button-refresh {
          padding-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      downloadItem: async item => {
        try {
          const url = await downloadFile(item.id);
          downloadURLtoFile(url, item.description);

          const items = await this.fetch();
          this.service.setItems(items);
        } catch (e) {
          store.dispatch(
            openError('An error occurred when attempting to download the file'),
          );
        }
      },
      refreshAll: async () => {
        await this.__refreshAll();
      },
      tableChange: e => this.__handleRowCheck(e),
      selectAll: () => {
        this.__tableState = this.__setAllCheckboxes(true);
      },
      deselectAll: () => {
        this.__tableState = this.__setAllCheckboxes(false);
      },
      openNoFilesPopup: () =>
        openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: DOWNLOAD_HISTORY_DELETE_POPUP_TITLE,
          message: DOWNLOAD_HISTORY_DELETE_MESSAGE_NONE_SELECTED,
        }),
      deleteSelected: async () => {
        const response = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: DOWNLOAD_HISTORY_DELETE_POPUP_TITLE,
          message: DELETE_HISTORY_POPUP_CONFIRM_MESSAGE,
        });

        if (response) {
          try {
            const ids = this.__tableState.pageItems
              .filter(item => item.checked)
              .map(item => item.id);

            await deleteDownloadedFiles(ids);
            store.dispatch(
              openSuccess(DOWNLOAD_HISTORY_DELETE_MESSAGE_SUCCESS),
            );

            await this.__refreshAll();
          } catch (e) {
            console.error(e);
            store.dispatch(openError(DOWNLOAD_HISTORY_DELETE_MESSAGE_ERROR));
          }
        }
      },
    };
  }

  async fetch() {
    const data = await getFileDownloads();

    return data;
  }

  async __refreshAll() {
    const items = await this.fetch();
    this.service.setItems(items);
  }

  __setAllCheckboxes(checked) {
    this.__tableState.pageItems = this.__tableState.pageItems.map(item => ({
      ...item,
      checked:
        item.status === ECLAIM_FILE_DOWNLOAD_STATUS.DOWNLOADED.status
          ? checked
          : false,
    }));

    return { ...this.__tableState };
  }

  __handleRowCheck(e) {
    const terms = e.name.split('.');
    const index = terms[0];

    const updatedItem = { ...this.__tableState.pageItems[index] };
    updatedItem.checked = e.value;

    this.__tableState.pageItems.splice(index, 1, updatedItem);
    this.__tableState = {
      ...this.__tableState,
      pageItems: [...this.__tableState.pageItems],
    };
  }

  __renderAddButton() {
    return html`
      <div class="row">
        <neb-button
          id="${ELEMENTS.buttonRefresh.id}"
          class="button-refresh"
          label="Refresh All"
          .onClick="${this.handlers.refreshAll}"
        ></neb-button>
        <div class="cell cell-spacer"></div>
      </div>
    `;
  }

  renderNoItemsContent() {
    return html`
      <neb-text>No items.</neb-text>
    `;
  }

  renderTable() {
    return html`
      <neb-table-eclaim-file-downloads
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        .layout="${this.layout}"
        .config="${this.getConfig().tableConfig}"
        .model="${this.__tableState.pageItems}"
        .sortParams="${[this.__tableState.sortParams]}"
        .emptyMessage="${this.renderTableSlot()}"
        .onClickDownloadIcon="${this.handlers.downloadItem}"
        .onChange="${this.handlers.tableChange}"
        .onSelectAll="${this.handlers.selectAll}"
        .onDeselectAll="${this.handlers.deselectAll}"
        .onDeleteSelected="${this.handlers.deleteSelected}"
        .onOpenNoFilesPopup="${this.handlers.openNoFilesPopup}"
      ></neb-table-eclaim-file-downloads>
    `;
  }
}

window.customElements.define(
  'neb-page-claim-file-downloads',
  CollectionPageClaimFileDownloads,
);
