import { authenticateUser, renew } from '../../neb-utils/cognito-util';

export const UPDATE_USER = 'UPDATE_USER';
export const SESSION_AUTHENTICATING = 'SESSION_AUTHENTICATING';
export const SESSION_AUTHENTICATED = 'SESSION_AUTHENTICATED';
export const SESSION_REQUIRES_AUTHENTICATION =
  'SESSION_REQUIRES_AUTHENTICATION';
export const SESSION_AUTHENTICATION_FAILED = 'SESSION_AUTHENTICATION_FAILED';
export const SESSION_RENEWING = 'SESSION_RENEWING';
export const SESSION_RENEWED = 'SESSION_RENEWED';
export const SESSION_RENEW_FAILED = 'SESSION_RENEW_FAILED';
export const updateUser = user => ({
  type: UPDATE_USER,
  booking: {
    user,
  },
});

const updateSession = (dispatch, getState, actionType, authResult) => {
  dispatch({
    type: actionType,
    value: authResult,
  });

  dispatch(
    updateUser({
      id: authResult.idToken.payload.sub,
      firstName: authResult.idToken.payload.given_name,
      lastName: authResult.idToken.payload.family_name,
      clientId: authResult.idToken.payload.email,
      phoneNumber: authResult.idToken.payload.phone_number,
    }),
  );

  const { item } = getState().session;
  window.localStorage.setItem('neb-login-local', JSON.stringify(item));
};

export const authenticateBookingUser = credentials => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: SESSION_AUTHENTICATING,
  });

  try {
    const authResult = await authenticateUser(credentials);

    if (authResult.changePassword) {
      dispatch({
        type: SESSION_REQUIRES_AUTHENTICATION,
      });
    } else {
      updateSession(dispatch, getState, SESSION_AUTHENTICATED, authResult);
    }

    return authResult;
  } catch (e) {
    dispatch({
      type: SESSION_AUTHENTICATION_FAILED,
    });

    return {
      error: e,
    };
  }
};

export const renewBookingSession = credentials => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: SESSION_RENEWING,
  });

  try {
    const authResult = await renew(credentials);
    updateSession(dispatch, getState, SESSION_RENEWED, authResult);
    return authResult;
  } catch (e) {
    dispatch({
      type: SESSION_RENEW_FAILED,
    });

    return {
      error: e,
    };
  }
};
