import { getAllProvidersAvailability } from '../neb-api-client/src/provider-availability-api-client';

import { calculateTimeRange } from './nebAvailableTimeRangeUtil';

const PROVIDER_AVAILABILITY_FETCHING = 'PROVIDER_AVAILABILITY_FETCHING';
const PROVIDER_AVAILABILITY_FETCHED = 'PROVIDER_AVAILABILITY_FETCHED';
const PROVIDER_AVAILABILITY_FETCH_FAILED = 'PROVIDER_AVAILABILITY_FETCH_FAILED';
const PROVIDER_AVAILABILITY_SAVING = 'PROVIDER_AVAILABILITY_SAVING';
const PROVIDER_AVAILABILITY_SAVED = 'PROVIDER_AVAILABILITY_SAVED';
const PROVIDER_AVAILABILITY_SAVE_FAILED = 'PROVIDER_AVAILABILITY_SAVE_FAILED';
const PROVIDER_AVAILABILITY_CLONING = 'PROVIDER_AVAILABILITY_CLONING';
const PROVIDER_AVAILABILITY_CLONED = 'PROVIDER_AVAILABILITY_CLONED';
const PROVIDER_AVAILABILITY_CLONE_FAILED = 'PROVIDER_AVAILABILITY_CLONE_FAILED';

export const providersAvailabilityReducer = (
  state = {
    isFetching: false,
    isFetched: false,
    isSaving: false,
    isSaved: false,
    isCloning: false,
    isCloned: false,
    item: undefined,
    message: undefined,
  },
  action,
) => {
  switch (action.type) {
    case PROVIDER_AVAILABILITY_FETCHING:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isCloning: false,
        isCloned: false,
      };

    case PROVIDER_AVAILABILITY_FETCHED: {
      const fetchedAvailability = action.value;
      calculateTimeRange(fetchedAvailability);
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        isSaving: false,
        isSaved: false,
        isCloning: false,
        isCloned: false,
        item: fetchedAvailability,
      };
    }

    case PROVIDER_AVAILABILITY_FETCH_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isCloning: false,
        isCloned: false,
        message: action.message,
      };

    case PROVIDER_AVAILABILITY_SAVING:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isSaving: true,
        isCloning: false,
        isCloned: false,
        isSaved: false,
      };

    case PROVIDER_AVAILABILITY_SAVED: {
      const availabilityJustSaved = { ...state.item };
      availabilityJustSaved[action.providerId] = action.value;
      calculateTimeRange(availabilityJustSaved, action.providerId);
      const newState = {
        ...state,
        isFetching: false,
        isFetched: true,
        isSaving: false,
        isSaved: true,
        isCloning: false,
        isCloned: false,
        item: availabilityJustSaved,
      };
      return newState;
    }

    case PROVIDER_AVAILABILITY_SAVE_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isCloning: false,
        isCloned: false,
        message: action.message,
      };

    case PROVIDER_AVAILABILITY_CLONING:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isCloning: true,
        isCloned: false,
      };

    case PROVIDER_AVAILABILITY_CLONED: {
      const availabilityJustCloned = { ...state.item };
      availabilityJustCloned[action.providerId] = action.value;
      calculateTimeRange(availabilityJustCloned, action.providerId);
      const clonedState = {
        ...state,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isCloning: false,
        isCloned: true,
        item: availabilityJustCloned,
      };
      return clonedState;
    }

    case PROVIDER_AVAILABILITY_CLONE_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isCloning: false,
        isCloned: false,
        message: action.message,
      };

    default:
      return state;
  }
};

export const fetchProviderAvailability = () => async dispatch => {
  dispatch({
    type: PROVIDER_AVAILABILITY_FETCHING,
  });

  try {
    const res = await getAllProvidersAvailability();
    dispatch({
      type: PROVIDER_AVAILABILITY_FETCHED,
      value: res.data[0],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROVIDER_AVAILABILITY_FETCH_FAILED,
      message: 'An error occurred.',
    });
  }
};

export const saveProviderAvailability = (providerId, availability) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: PROVIDER_AVAILABILITY_SAVING,
  });

  try {
    const session = getState().session.item;
    const url = `${getState().apiOverride.value}/api/v1/tenants/${
      session.tenantId
    }/providerAvailability/${providerId}`;
    const res = await fetch(url, {
      body: JSON.stringify(availability),
      method: 'POST',
      headers: new Headers({
        'x-access-token': session.accessToken,
        'Content-Type': 'application/json',
      }),
    });

    if (res.ok) {
      const json = await res.json();
      dispatch({
        type: PROVIDER_AVAILABILITY_SAVED,
        providerId,
        value: json.data[0],
      });
    } else {
      dispatch({
        type: PROVIDER_AVAILABILITY_SAVE_FAILED,
        message: 'You do not have access to this application.',
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROVIDER_AVAILABILITY_SAVE_FAILED,
      message: 'An error occurred.',
    });
  }
};

export const cloneProviderAvailability = (
  sourceProviderId,
  targetProviderId,
) => async (dispatch, getState) => {
  dispatch({
    type: PROVIDER_AVAILABILITY_CLONING,
  });

  try {
    const session = getState().session.item;

    let url = `${getState().apiOverride.value}/api/v1/tenants/${
      session.tenantId
    }/providerAvailability/`;
    url += `${sourceProviderId}/clone/${targetProviderId}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'x-access-token': session.accessToken,
        'Content-Type': 'application/json',
      }),
    });

    if (res.ok) {
      const json = await res.json();
      const providerAvailability = json.data[0];
      dispatch({
        type: PROVIDER_AVAILABILITY_CLONED,
        providerId: targetProviderId,
        value: providerAvailability,
      });
    } else {
      dispatch({
        type: PROVIDER_AVAILABILITY_CLONE_FAILED,
        message: 'You do not have access to this application.',
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROVIDER_AVAILABILITY_CLONE_FAILED,
      message: 'An error occurred.',
    });
  }
};
