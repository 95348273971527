import { CODE_WRITE_OFFS } from '../../../packages/neb-utils/constants';
import {
  currencyToCents,
  currencyToCentsWithNegative,
} from '../../../packages/neb-utils/formatters';
import { BILL_TYPE } from '../../../packages/neb-utils/neb-ledger-util';

export function calculateResponsibleSum(lineItemDebits) {
  return lineItemDebits.reduce(
    (total, lineitemDebit) =>
      total +
      (typeof lineitemDebit.debit.amount === 'string'
        ? currencyToCents(lineitemDebit.debit.amount)
        : lineitemDebit.debit.amount),
    0,
  );
}

export function isPatientRespLineItemDebit(lineItemDebit) {
  return lineItemDebit.debit.payerId === null;
}

export function isSecondaryRespLineItemDebit(lineItemDebit, lineItem) {
  return (
    lineItemDebit.debit.payerId &&
    (lineItemDebit.debit.payerId !== lineItem.primaryPayerId ||
      lineItemDebit.patientInsuranceId !== lineItem.primaryInsuranceId)
  );
}

export function isPrimaryPayerLineItemDebit(lineItemDebit, lineItem) {
  return (
    lineItem.billType === BILL_TYPE.INSURANCE &&
    (lineItemDebit.debit.payerId === lineItem.primaryPayerId &&
      (lineItemDebit.patientInsuranceId === lineItem.primaryInsuranceId ||
        !lineItem.primaryInsuranceId))
  );
}

export function getPrimaryPayerLineItemDebits(lineItem) {
  const primaryLineItemDebit = lineItem.lineItemDebits.find(lineItemDebit =>
    isPrimaryPayerLineItemDebit(lineItemDebit, lineItem),
  );
  return primaryLineItemDebit ? [primaryLineItemDebit] : [];
}

export function getSecondaryPayerLineItemDebits(lineItem) {
  return lineItem.lineItemDebits.filter(lineItemDebit =>
    isSecondaryRespLineItemDebit(lineItemDebit, lineItem),
  );
}

export function getPatientLineItemDebits(lineItem) {
  return lineItem.lineItemDebits.filter(lineItemDebit =>
    isPatientRespLineItemDebit(lineItemDebit),
  );
}

export function getPrimaryOwedTotal(lineItem) {
  const primaryPayerLineItemDebits = getPrimaryPayerLineItemDebits(lineItem);
  return calculateResponsibleSum(primaryPayerLineItemDebits);
}

export function getSecondaryOwedTotal(lineItem) {
  const secondaryPayerLineItemDebits = getSecondaryPayerLineItemDebits(
    lineItem,
  );
  return calculateResponsibleSum(secondaryPayerLineItemDebits);
}

export function getPatientOwedTotal(lineItem) {
  const patientLineItemDebits = getPatientLineItemDebits(lineItem);
  return calculateResponsibleSum(patientLineItemDebits);
}

const getAmountValueWithNegative = amount =>
  typeof amount === 'string' ? currencyToCentsWithNegative(amount) : amount;

export function calculateAdjustmentsSum(adjustments) {
  const totalCents = adjustments
    ? adjustments.reduce(
        (total, adj) => total + getAmountValueWithNegative(adj.amount),
        0,
      )
    : 0;

  return totalCents;
}

export const getFormattedIdFromObj = value =>
  typeof value === 'string' || !value ? value : value.data.id;

export const getFormattedAmountValue = value =>
  typeof value === 'string' ? currencyToCents(value) : value;

export const formatAdjustments = adjustments =>
  adjustments.map(adjustment => {
    adjustment.amount = getFormattedAmountValue(adjustment.amount);
    adjustment.codeId = getFormattedIdFromObj(adjustment.codeId);
    return adjustment;
  });

export const getAdjustmentsWithCP = adjustments =>
  formatAdjustments(adjustments)
    .filter(adj => adj.codeId === CODE_WRITE_OFFS.PACKAGE.id)
    .reduce((total, adj) => total + adj.amount, 0);
