import ApiClient from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });
const path = 'appointments/billing-info';

export function fetchMany(appointmentIds) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    cacheKey: path,
    headers: {
      'Content-Type': 'application/json',
      appointmentIds,
    },
    version: 1,
  });
}
