import '../../neb-lit-components/src/components/forms/neb-form-new-superbill';
import '../../neb-lit-components/src/components/inputs/neb-select';
import '../../neb-lit-components/src/components/neb-tooltip';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import {
  CSS_FONT_FAMILY,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  invoiceDropdown: { id: 'invoice-dropdown' },
  buttonAdd: { id: 'add' },
  buttonCancel: { id: 'cancel' },
  tooltip: { id: 'tooltip' },
};

const NO_INVOICES_TEXT = 'No open invoices.';

class NebPopupAddToInvoice extends NebPopup {
  static get properties() {
    return {
      selectedInvoice: Object,
    };
  }

  initState() {
    super.initState();
    this.title = 'Add to Invoice';
    this.model = [];
    this.selectedInvoice = null;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      add: () => this.onClose(this.selectedInvoice.id),
      cancel: () => this.onClose(false),
      selectInvoice: ({ value }) => {
        if (value !== NO_INVOICES_TEXT) {
          this.selectedInvoice = value;
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
        }

        .container-dropdown {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 15px;
        }

        .invoice-dropdown {
          flex: 1 0 0;
        }

        .spacing {
          padding-top: 15px;
          padding-left: 10px;
        }

        .spacing-button {
          margin-right: 10px;
        }

        .container-buttons {
          margin-top: ${CSS_SPACING};
          display: flex;
        }
      `,
    ];
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      [this.selectedInvoice] = this.model.invoices;
    }

    super.update(changedProps);
  }

  __getMessageRenderer() {
    return !this.model.invoices.length
      ? ({ label }) => html`
          <p
            style="margin: 0 0 0 16px; font-size: 16px; color: ${
              CSS_COLOR_HIGHLIGHT
            }"
          >
            ${label}
          </p>
        `
      : null;
  }

  renderContent() {
    return html`
      Select the invoice you want to add the charge(s) to.

      <div class="container-dropdown">
        <neb-select
          id="${ELEMENTS.invoiceDropdown.id}"
          class="invoice-dropdown"
          label="Invoice"
          .value="${this.selectedInvoice}"
          .items="${
            this.model.invoices.length
              ? this.model.invoices
              : [NO_INVOICES_TEXT]
          }"
          .helper=" "
          .onChange="${this.handlers.selectInvoice}"
          .onRenderItem="${this.__getMessageRenderer()}"
        ></neb-select>
        <neb-tooltip
          id="${ELEMENTS.tooltip.id}"
          class="spacing"
          defaultAnchor="right"
          ><div slot="tooltip">
            Only invoices that match the charges' billing data of Guarantor,
            Case, Payers and Plans may be selected. If you are unable to select
            an invoice, review its billing data to ensure your selected charges
            match these values. Edit the invoice or charge accordingly or select
            an alternate invoice.
          </div></neb-tooltip
        >
      </div>

      <div class="container-buttons">
        <neb-button
          id="${ELEMENTS.buttonAdd.id}"
          class="spacing-button"
          label="Add"
          .onClick="${this.handlers.add}"
          ?disabled="${!this.selectedInvoice}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          role="${BUTTON_ROLE.CANCEL}"
          label="Cancel"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define('neb-popup-add-to-invoice', NebPopupAddToInvoice);
