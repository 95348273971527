import '../controls/neb-button-icon';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { NebTableRow, ELEMENTS as BASE_ELEMENTS } from '../neb-table-row';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  questionRow: {
    id: 'question-row',
  },
  previewQuestion: {
    id: 'preview-question',
  },
};

class NebCheckInQuestionTableRow extends NebTableRow {
  static get styles() {
    return [
      super.styles,
      css`
        .cell-icon {
          padding-left: 10px;
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      previewQuestion: () => {
        openPopup(POPUP_RENDER_KEYS.SUBJECTIVE_QUESTIONNAIRE_PREVIEW, {
          questions: [this.model.id],
        });
      },
    };
  }

  __renderPreview() {
    return html`
      <div class="cell cell-icon">
        <neb-button-icon
          id="${ELEMENTS.previewQuestion.id}"
          icon="neb:preview"
          .onClick="${this.handlers.previewQuestion}"
        ></neb-button-icon>
      </div>
    `;
  }

  renderColumn(key, value) {
    switch (key) {
      case 'displayText':
        return html`
          <div id="${ELEMENTS.questionRow.id}">${value}</div>
        `;
      default:
        return '';
    }
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderAddButton()} ${this.__renderColumns()}
        ${this.__renderPreview()}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-check-in-question-table-row',
  NebCheckInQuestionTableRow,
);
