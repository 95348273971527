import './neb-loading-spinner';

import { LitElement, html, css } from 'lit';

import { RENDERER_SVGS } from '../../../neb-styles/icons';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_SPACING,
  CSS_COLOR_GREY_4,
} from '../../../neb-styles/neb-variables';
import buttonStyle from '../../material_styles/mdc-button-css';

export const ELEMENTS = {
  button: {
    id: 'button',
  },
  icon: {
    id: 'button-icon',
  },
  label: {
    id: 'button-label',
  },
  spinner: {
    id: 'id-spinner',
  },
};

class NebMDButton extends LitElement {
  static get properties() {
    return {
      dark: {
        type: Boolean,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
      exactCase: {
        type: Boolean,
      },
      grey: {
        type: Boolean,
      },
      icon: {
        type: String,
      },
      label: {
        type: String,
      },
      layout: {
        type: String,
        reflect: true,
      },
      name: {
        type: String,
      },
      outlined: {
        type: Boolean,
      },
      processing: {
        type: Boolean,
      },
      selected: {
        type: Boolean,
      },
      thin: {
        type: Boolean,
      },
      trailingIcon: {
        type: Boolean,
      },
      unelevated: {
        type: Boolean,
      },
      value: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.dark = false;
    this.disabled = false;
    this.exactCase = false;
    this.grey = false;
    this.layout = '';
    this.name = '';
    this.outlined = false;
    this.processing = false;
    this.selected = false;
    this.thin = false;
    this.trailingIcon = false;
    this.unelevated = false;
    this.value = '';

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => {
        if (!this.processing) {
          this.onClick({
            name: this.name,
            value: this.value,
          });
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('transitionend', e => {
      e.stopPropagation();
    });
  }

  __iconTextButtonHTML(iconKey) {
    return html`
      <div class="icon-container">
        <svg
          id="${ELEMENTS.icon.id}"
          class="material-icons mdc-button__icon"
          aria-hidden="true"
          viewBox="${RENDERER_SVGS[iconKey].viewBox}"
        >
          ${RENDERER_SVGS[iconKey].src}
        </svg>
      </div>
    `;
  }

  __classes() {
    const classes = [];
    if (this.outlined) classes.push('mdc-button--outlined');
    if (this.unelevated) classes.push('mdc-button--unelevated');
    if (this.dark) classes.push('hover-dark');
    if (this.thin) classes.push('thin');
    if (this.exactCase) classes.push('exact-case');

    return classes.join(' ');
  }

  __getIcon() {
    if (!this.icon || this.trailingIcon) return '';

    return this.__iconTextButtonHTML(this.icon);
  }

  __getTrailingIcon() {
    if (!this.icon || !this.trailingIcon) return '';

    return this.__iconTextButtonHTML(this.icon);
  }

  static get styles() {
    return [
      buttonStyle,
      css`
        .mdc-button {
          min-width: 106.5px;
          border-radius: 20px;
          font-weight: bold;
          padding: 0 25px;
        }

        :host([layout='small']) .mdc-button {
          padding: 0 ${CSS_SPACING};
        }

        :host([grey]) .mdc-button {
          border: ${CSS_COLOR_GREY_4};
          background: ${CSS_COLOR_GREY_4};
        }

        .icon-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 5px;
          font-size: 12px;
        }

        .mdc-button__label {
          cursor: pointer;
        }

        .spinner {
          width: 20px;
          margin-right: 10px;
          color: currentColor;
        }

        .hover-dark:hover {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_WHITE};
        }

        .thin {
          padding: 0 15px;
          height: 30px;
        }

        .thin > label {
          font-size: 14px;
        }

        .exact-case {
          text-transform: none;
        }

        .mdc-button[selected],
        .hover-dark[selected],
        .thin[selected] {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  render() {
    return html`
      <button
        id="${ELEMENTS.button.id}"
        class="mdc-button ${this.__classes()}"
        ?selected="${this.selected}"
        ?disabled="${this.disabled}"
        @click="${this.__handlers.click}"
      >
        ${this.__getIcon()}
        ${
          this.processing
            ? html`
                <neb-loading-spinner
                  id="${ELEMENTS.spinner.id}"
                  class="spinner"
                ></neb-loading-spinner>
              `
            : ''
        }
        <label id="${ELEMENTS.label.id}" class="mdc-button__label"
          >${this.label}</label
        >

        ${this.__getTrailingIcon()}

        <slot></slot>
      </button>
    `;
  }
}

customElements.define('neb-md-button', NebMDButton);
