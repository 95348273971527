import { isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/neb-header';

import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  centsToCurrency,
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { map } from '../../../../packages/neb-utils/utils';
import { CSS_COLOR_GREY_7, CSS_SPACING } from '../../../styles';
import { LEGAL_ACKNOWLEDGEMENT } from '../../../utils/user-message';
import { CONFIG_FEES_AND_PURCHASES } from '../../misc/neb-card-visit-summary';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  practiceLogo: { id: 'practice-logo' },
  cards: { selector: '[id^=card-]' },
  textEmpty: { id: 'text-empty' },
  textPatientName: { id: 'text-patient-name' },
  textPatientEmail: { id: 'text-patient-email' },
  textPatientPhone: { id: 'text-patient-phone' },
  textPatientAddress: { id: 'text-patient-address' },
  textPracticeName: { id: 'text-practice-name' },
  textPracticeEmail: { id: 'text-practice-email' },
  textPracticePhone: { id: 'text-practice-phone' },
  textPracticeAddress: { id: 'text-practice-address' },
  textAcknowledgement: { id: 'text-acknowledgement' },
  textGrandTotal: { id: 'text-total' },
  textCreatedAt: { id: 'text-created-date' },
  textProviderName: { selector: '[id^=text-provider-name-]' },
  imageProviderSignature: { selector: '[id^=image-provider-signature-]' },
  tableFeesAndPurchases: { id: 'table-fees-and-purchases' },
  cardTotals: { id: 'card-totals' },
};

function formatAddress(address) {
  const address2 = html`
    ${address.address2 || ''} <br />
  `;

  return html`
    ${address.address1 || ''} <br />
    ${address.address2 ? address2 : ''} ${address.city || ''},
    ${address.state || ''} ${address.zipcode || ''}
  `;
}

export default class FormVisitSummaryPatientDetail extends NebForm {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .right {
          justify-items: end;
        }
        .info {
          grid-gap: 0px ${CSS_SPACING};
        }

        .logo {
          max-width: 300px;
          max-height: 200px;
          background: white;
          text-align: center;
          margin: 0px 0px ${CSS_SPACING} ${CSS_SPACING};
        }

        .signature {
          max-width: 200px;
        }

        .signature-line {
          width: 200px;
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
        }
      `,
    ];
  }

  static createModel() {
    return {
      patientId: '',
      practiceInfo: {},
      patient: {},
      encounters: [],
      providers: [],
      patientSignature: '',
      practiceLogo: '',
      createdAt: parseDate().toISOString(),
      legalAcknowledgement: LEGAL_ACKNOWLEDGEMENT,
      opts: {
        hidePracticeInfo: false,
        hidePatientInfo: false,
        hideAppointmentInfo: false,
        hideDiagnoses: false,
        hideCharges: false,
        hideAcknowledgement: false,
        hideSignatures: false,
        hideCreatedDate: false,
      },
      feesAndPurchases: {
        lineItems: [],
        totals: [],
      },
    };
  }

  createSelectors() {
    return {
      children: {
        encounters: {
          unformat: v => {
            v.forEach(encounter => {
              encounter.lineItems.pop();
            });

            return v;
          },
        },
        patient: {
          children: {
            phoneNumber: selectors.phoneNumber(),
          },
        },
        practiceInfo: {
          children: {
            phoneNumber: selectors.phoneNumber(),
          },
        },
        patientSignature: [isRequired()],
        feesAndPurchases: {
          unformat: v => {
            v.lineItems.pop();
            return v;
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.model = {};
    this.layout = '';
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: async () => {
        const result = await openPopup(POPUP_RENDER_KEYS.SIGNATURE_CREATE, {
          title: 'Service Acknowledgement',
          message: html`
            ${this.__renderAcknowledgement()} ${this.__renderCreatedDate()}
          `,
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        });

        if (result) {
          this.formService.apply('patientSignature', result);
          await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Thank you',
            message:
              'Thank you for reviewing and confirming the summary of your visit. To continue, please notify a staff member to assist you with the next steps.',
          });
        }

        if (this.formService.validate()) {
          const rawModel = this.formService.build();
          const model = map(rawModel, (keyPath, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.__saving = true;
          this.onSave(model);
        }
      },
      print: () => {
        const rawModel = this.formService.build();
        const model = map(rawModel, (keyPath, value) =>
          typeof value === 'string' ? value.trim() : value,
        );

        this.onPrint(model);
      },
    };
  }

  __renderSignature(item, index) {
    return html`
      <div class="grid">
        <div class="signature-line">
          <img
            id="image-provider-signature-${index}"
            class="signature"
            src="${item.signature}"
          />
        </div>

        <neb-text id="text-provider-name-${index}">
          ${
            objToName(
              {
                first: item.givenName,
                last: item.familyName,
                middle: item.middleName,
                suffix: item.suffix,
              },
              DEFAULT_NAME_OPTS,
            )
          }
        </neb-text>
      </div>
    `;
  }

  __renderSignatures() {
    return !this.state.opts.hideSignatures
      ? html`
          ${
            this.state.providers.map((item, index) =>
              this.__renderSignature(item, index),
            )
          }
        `
      : '';
  }

  __renderEmail(emailAddress, id) {
    return emailAddress
      ? html`
          <neb-text id="${id}"> ${emailAddress} </neb-text>
        `
      : '';
  }

  __renderPhoneNumber(phoneNumber, id) {
    return phoneNumber
      ? html`
          <neb-text id="${id}"> ${phoneNumber} </neb-text>
        `
      : '';
  }

  __renderAddress(address, id) {
    return address && Object.keys(address).length
      ? html`
          <neb-text id="${id}"> ${formatAddress(address)} </neb-text>
        `
      : '';
  }

  __renderPracticeLogo() {
    return this.state.practiceLogo
      ? html`
          <img
            id="${ELEMENTS.practiceLogo.id}"
            class="logo"
            sizing="contain"
            src="${this.state.practiceLogo}"
            position="left"
          />
        `
      : '';
  }

  __renderAcknowledgement() {
    return !this.state.opts.hideAcknowledgement
      ? html`
          <neb-text id="${ELEMENTS.textAcknowledgement.id}" bold>
            ${this.state.legalAcknowledgement}
          </neb-text>
        `
      : '';
  }

  __renderCreatedDate() {
    return !this.state.opts.hideCreatedDate
      ? html`
          <neb-text id="${ELEMENTS.textCreatedAt.id}">
            Created:
            ${parseDate(this.state.createdAt).format('MM/DD/YYYY hh:mm A')}
          </neb-text>
        `
      : '';
  }

  __renderFeeAndPurchaseTable() {
    return !this.state.opts.hideFeesAndPurchases
      ? html`
          <div class="grid grid-lean">
            <neb-header label="Fees and Purchases"></neb-header>

            <neb-table
              id="${ELEMENTS.tableFeesAndPurchases.id}"
              .config="${CONFIG_FEES_AND_PURCHASES}"
              .layout="${this.layout}"
              .model="${this.model.feesAndPurchases.lineItems}"
            ></neb-table>
          </div>
        `
      : '';
  }

  __renderCard(item, index) {
    const model = { ...item, opts: { ...this.state.opts } };
    return html`
      <div class="grid ">
        <neb-card-visit-summary
          id="card-${index}"
          layout="${this.layout}"
          .model="${model}"
        ></neb-card-visit-summary>
      </div>
    `;
  }

  __formatTotals() {
    const encounterChargeTotal = this.state.opts.hideCharges
      ? 0
      : this.state.encounters.reduce((acc, e) => {
          acc += e.displayedTotalBalance;
          return acc;
        }, 0);

    const feesAndPurchasesTotal = this.state.opts.hideFeesAndPurchases
      ? 0
      : this.state.feesAndPurchases.totals.reduce((acc, t) => {
          acc += t.displayedTotalBalance;
          return acc;
        }, 0);

    return `Grand Total: ${centsToCurrency(
      encounterChargeTotal + feesAndPurchasesTotal,
    )}`;
  }

  __renderTotals() {
    return this.state.opts.hideFeesAndPurchases && this.state.opts.hideCharges
      ? ''
      : html`
          <div class="grid right">
            <neb-text id="${ELEMENTS.textGrandTotal.id}" bold
              >${this.__formatTotals()}</neb-text
            >
          </div>
        `;
  }

  __renderCards() {
    return this.state.encounters.length
      ? html`
          ${
            this.state.encounters.map((item, index) =>
              this.__renderCard(item, index),
            )
          }
          ${this.__renderFeeAndPurchaseTable()} ${this.__renderTotals()}
          ${this.__renderSignatures()}

          <div class="grid">
            ${this.__renderAcknowledgement()} ${this.__renderCreatedDate()}
          </div>
        `
      : html`
          <div class="grid">
            <neb-text id="${ELEMENTS.textEmpty.id}" color="alert">
              No Encounters Selected.
            </neb-text>
          </div>
        `;
  }

  __renderPracticeInfo() {
    return !this.state.opts.hidePracticeInfo
      ? html`
          ${this.__renderPracticeLogo()}

          <neb-header label="Practice Information"></neb-header>

          <div class="grid info">
            <neb-text id="${ELEMENTS.textPracticeName.id}">
              ${this.state.practiceInfo.name}
            </neb-text>

            ${
              this.__renderAddress(
                this.state.practiceInfo.address,
                ELEMENTS.textPracticeAddress.id,
              )
            }
            ${
              this.__renderPhoneNumber(
                this.state.practiceInfo.phoneNumber,
                ELEMENTS.textPracticePhone.id,
              )
            }
            ${
              this.__renderEmail(
                this.state.practiceInfo.emailAddress,
                ELEMENTS.textPracticeEmail.id,
              )
            }
          </div>
        `
      : '';
  }

  __renderPatientInfo() {
    return !this.state.opts.hidePatientInfo
      ? html`
          <neb-header label="Patient Information"></neb-header>

          <div class="grid  info">
            <neb-text id="${ELEMENTS.textPatientName.id}">
              ${
                objToName(
                  {
                    first: this.state.patient.firstName,
                    last: this.state.patient.lastName,
                    preferred: this.state.patient.preferredName,
                    middle: this.state.patient.middleName,
                    suffix: this.state.patient.suffix,
                  },
                  DEFAULT_NAME_OPTS,
                )
              }
            </neb-text>

            ${
              this.__renderAddress(
                this.state.patient.address,
                ELEMENTS.textPatientAddress.id,
              )
            }
            ${
              this.__renderPhoneNumber(
                this.state.patient.phoneNumber,
                ELEMENTS.textPatientPhone.id,
              )
            }
            ${
              this.__renderEmail(
                this.state.patient.emailAddress,
                ELEMENTS.textPatientEmail.id,
              )
            }
          </div>
        `
      : '';
  }

  renderActionBar() {
    const printLabel = this.layout === 'small' ? 'Print' : 'Print Summary';

    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        removeLabel="${printLabel}"
        confirmLabel="Sign"
        .cancelLabel="${this.cancelLabel}"
        .onConfirm="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        .onRemove="${this.handlers.print}"
      ></neb-action-bar>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderPracticeInfo()} ${this.__renderPatientInfo()}
      ${this.__renderCards()}
    `;
  }
}

window.customElements.define(
  'neb-form-visit-summary-patient-detail',
  FormVisitSummaryPatientDetail,
);
