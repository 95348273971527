/* eslint-disable complexity */
import { html, css } from 'lit';

import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';
import { updateAppointment } from '../../../../../packages/neb-api-client/src/appointment-api-client';
import {
  updateEncounter,
  updateEncounterCurrentIllnessDateWithCaseDOO,
} from '../../../../../packages/neb-api-client/src/encounters-api-client';
import createEncounter from '../../../../../packages/neb-api-client/src/services/encounter/create-encounter';
import Overlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { APPOINTMENT_ACTIONS } from '../../../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-options';
import { store } from '../../../../../packages/neb-redux/neb-redux-store';
import { openError, openSuccess } from '../../../../store';
import { CSS_SPACING, CSS_COLOR_GREY_2 } from '../../../../styles';
import '../../../../../packages/neb-lit-components/src/components/patients/neb-patient-summary-controller';
import {
  SAVE_ENCOUNTER_SUCCESS,
  SAVE_ENCOUNTER_WITH_AUTOSALT_SUCCESS,
  UPDATE_ENCOUNTER_DATE_OF_ONSET_ERROR,
  UPDATE_ENCOUNTER_DATE_OF_ONSET_SUCCESS,
} from '../../../../utils/user-message';
import { getDefaultInsurances } from '../../utils/neb-form-check-in-util';

import { NebFormCheckIn } from './neb-form-check-in';

export const ELEMENTS = {
  patientSummary: {
    id: 'patient-summary',
  },
  header: {
    id: 'header',
  },
  formCheckIn: {
    id: 'form-check-in',
  },
};

class NebOverlayCheckIn extends Overlay {
  static get properties() {
    return {
      formModel: Object,
      formMetaData: Object,
      __savingError: Boolean,
      __defaultInsurances: Object,
    };
  }

  initState() {
    super.initState();

    this.formModel = NebFormCheckIn.createModel();
    this.formMetaData = NebFormCheckIn.createMetaData();

    this.__savingError = false;

    this.__defaultInsurances = null;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: async (model, createEncounterAtCheckIn, selectedProviderName) => {
        let checkInRes;
        let encounterUpdateSuccess;

        try {
          checkInRes = await updateAppointment(model.id, 'checkIn', model);
        } catch (e) {
          console.error(e);
          store.dispatch(openError(APPOINTMENT_ACTIONS.CHECK_IN.errorMessage));
        }

        if (checkInRes?.data && checkInRes.data[0]) {
          const { encounter, appointmentBillingInfo } = this.model.meta;
          const patientCaseId = appointmentBillingInfo?.patientCaseId;

          if (encounter?.id && !encounter.signed) {
            const { appointmentTypeId, providerId } = model;
            const updatedEncounter = {
              ...encounter,
              appointmentTypeId,
              providerId,
            };

            try {
              await updateEncounter(updatedEncounter.id, updatedEncounter);
              encounterUpdateSuccess = true;
            } catch (e) {
              console.error(e);
              store.dispatch(
                openError('Encounter was not successfully updated.'),
              );

              encounterUpdateSuccess = false;
            }

            const caseChanged =
              patientCaseId !== model.billingInfo.patientCaseId &&
              (!!patientCaseId || !!model.billingInfo.patientCaseId);

            if (encounterUpdateSuccess && caseChanged) {
              try {
                await updateEncounterCurrentIllnessDateWithCaseDOO(
                  encounter.id,
                  { patientCaseId: model.billingInfo.patientCaseId },
                );

                await store.dispatch(
                  openSuccess(UPDATE_ENCOUNTER_DATE_OF_ONSET_SUCCESS),
                );
              } catch (e) {
                console.error(e);
                store.dispatch(openError(UPDATE_ENCOUNTER_DATE_OF_ONSET_ERROR));
              }
            }
          }

          let encounterCreated = null;

          if (createEncounterAtCheckIn && !encounter?.id) {
            try {
              const currentUser = store.getState().session.item.id;

              encounterCreated = await createEncounter(
                model.id,
                currentUser,
                selectedProviderName,
              );
            } catch (e) {
              console.error(e);
              store.dispatch(openError('Error creating the encounter.'));
            }

            if (encounterCreated) {
              const banner = encounterCreated.res.withAutoSalt
                ? SAVE_ENCOUNTER_WITH_AUTOSALT_SUCCESS
                : SAVE_ENCOUNTER_SUCCESS;
              store.dispatch(openSuccess(banner));
            }
          }

          this.__savingError = false;
          this.isDirty = false;
          store.dispatch(
            openSuccess(APPOINTMENT_ACTIONS.CHECK_IN.successMessage),
          );

          this.dismiss({
            success: true,
            model: checkInRes.data[0],
            encounterCreated,
            cancelled: false,
            mode: 'checkIn',
          });
        }

        this.__savingError = true;
      },
      cancel: () =>
        this.dismiss({
          success: true,
          model: { id: this.formMetaData.appointment.id || '' },
          cancelled: true,
        }),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          flex-flow: row nowrap;
          width: 80rem;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .content-left {
          padding: ${CSS_SPACING};
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        .content-right {
          display: flex;
          flex-flow: column nowrap;
          width: 100%;
        }

        .form {
          flex: 1 0 0;
        }
      `,
    ];
  }

  updated(changedProps) {
    if (changedProps.has('model') && this.model.meta?.appointment?.id) {
      const { form, meta } = this.model;
      this.formModel = form;
      this.formMetaData = meta;

      this.__defaultInsurances = getDefaultInsurances(
        this.formMetaData.insurances,
      );
    }
  }

  renderContent() {
    return html`
      ${
        this.layout !== 'small'
          ? html`
              <neb-patient-summary-controller
                id="${ELEMENTS.patientSummary.id}"
                class="content-left"
                .patientId="${this.model.form.appointmentDetails.patientId}"
                .showTreatmentPlanLink="${true}"
                .initialModel="${this.model.patientSummaryModel}"
              >
              </neb-patient-summary-controller>
            `
          : ''
      }

      <div class="content-right">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          title="Check In"
          .onBack="${this.handlers.cancel}"
          .onCancel="${this.handlers.cancel}"
          .showBackButton="${this.layout === 'small'}"
          .showCancelButton="${this.layout !== 'small'}"
        ></neb-popup-header>

        <neb-form-check-in
          id="${ELEMENTS.formCheckIn.id}"
          class="form"
          .layout="${this.layout}"
          .model="${this.formModel}"
          .metaData="${this.formMetaData}"
          .savingError="${this.__savingError}"
          .defaultInsurances="${this.__defaultInsurances}"
          .onCancel="${this.handlers.cancel}"
          .onChangeDirty="${this.handlers.dirty}"
          .onSave="${this.handlers.save}"
        ></neb-form-check-in>
      </div>
    `;
  }
}

customElements.define('neb-overlay-check-in', NebOverlayCheckIn);
