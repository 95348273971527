import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const billingApiClient = new ApiClient({ microservice: 'billing' });

export const getChargesTable = async patientId => {
  const { data } = await billingApiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/charges-table`,
    version: 1,
  });

  return data;
};
