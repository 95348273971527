import '../../../packages/neb-lit-components/src/components/neb-button';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import './neb-icon';
import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import {
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  CSS_COLOR_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  container: { id: 'container' },
  icon: { id: 'icon' },
  labelDescription: { id: 'label-description' },
  labelTitle: { id: 'label-title' },
  select: { id: 'select' },
  list: { id: 'list' },
};

class NebCardSelect extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      description: String,
      icon: String,
      name: String,
      label: String,
      title: String,
      items: Array,
      values: Array,
      error: Object,
      readOnly: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.description = '';
    this.icon = '';
    this.layout = '';
    this.name = '';
    this.title = '';
    this.label = '';
    this.items = [];
    this.values = [];
    this.error = {};
    this.readOnly = false;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: ({ value }) => {
        this.onChange({ name: this.name, value });
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: auto auto;
          min-height: 100px;
          width: 100%;
          align-items: center;
          justify-content: space-between;

          padding: ${CSS_SPACING};

          border-radius: 4px;
          border: 1px solid ${CSS_COLOR_GREY_2};
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }

        .content {
          display: flex;
          align-items: center;
        }

        .label-title {
          width: 100px;
          margin-right: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .label-description {
          flex: 1 0 0;
          width: auto;
          max-width: 460px;
          font-size: ${CSS_FONT_SIZE_BODY};
          margin-right: ${CSS_SPACING};
        }

        .select {
          width: 300px;
        }

        .icon {
          flex: 0 0 24px;
          display: flex;
          width: 28px;
          height: 28px;
          margin-right: ${CSS_SPACING};
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([layout='small']) .container {
          grid-template-columns: 100%;
        }

        :host([layout='small']) .label-description {
          max-width: 80px;
        }

        :host([layout='small']) .icon {
          margin-right: 10px;
        }

        :host([layout='small']) .select {
          width: 100%;
        }
      `,
    ];
  }

  __renderList() {
    const valueIds = this.values.map(value => value.data.id);
    return html`
      <div id="${ELEMENTS.list.id}">
        ${
          this.items
            .filter(item => valueIds.includes(item.data.id))
            .map(
              item =>
                html`
                  ${item.label}<br />
                `,
            )
        }
      </div>
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <div class="content">
          <neb-icon
            id="${ELEMENTS.icon.id}"
            class="icon"
            .icon="${this.icon}"
          ></neb-icon>

          <div id="${ELEMENTS.labelTitle.id}" class="label label-title">
            ${this.title}
          </div>

          <div
            id="${ELEMENTS.labelDescription.id}"
            class="label label-description"
          >
            ${this.description}
          </div>
        </div>

        ${
          this.readOnly
            ? this.__renderList()
            : html`
                <neb-select
                  id="${ELEMENTS.select.id}"
                  class="select"
                  helper="Required"
                  label="${this.label}"
                  .items="${this.items}"
                  .error="${this.error}"
                  .value="${this.values}"
                  .onChange="${this.__handlers.change}"
                  wrapText
                  multiSelect
                ></neb-select>
              `
        }
      </div>
    `;
  }
}
customElements.define('neb-card-select', NebCardSelect);
