import {
  getPatientInsurance,
  getPatientInsurances,
} from '../../../packages/neb-api-client/src/patient-insurance-api-client';
import { CODE_WRITE_OFFS } from '../../../packages/neb-utils/constants';
import {
  currencyToCents,
  centsToCurrency,
  centsToCurrencyWithNegative,
  currencyToCentsWithNegative,
} from '../../../packages/neb-utils/formatters';
import {
  BILL_TYPE,
  getItem,
} from '../../../packages/neb-utils/neb-ledger-util';
import { DEFAULT_LEVEL } from '../../../packages/neb-utils/patientInsurance';
import { getValueByPath } from '../../../packages/neb-utils/utils';

const isPrimaryPayerDebit = (debit, lineItem) =>
  lineItem.billType === BILL_TYPE.INSURANCE &&
  (debit.payerId === lineItem.primaryPayerId &&
    (debit.patientInsuranceId === lineItem.primaryInsuranceId ||
      !lineItem.primaryInsuranceId));

export const getPrimaryPayerDebits = lineItem => {
  if (!lineItem) return { debits: [], debitIndexes: [] };

  const debitIndex = lineItem.debits.findIndex(debit =>
    isPrimaryPayerDebit(debit, lineItem),
  );

  if (debitIndex === -1) {
    return { debits: [], debitIndexes: [] };
  }

  const primaryDebit = lineItem.debits[debitIndex];

  return {
    debits: [primaryDebit],
    debitIndexes: [debitIndex],
  };
};

export function getPatientDebits(lineItem) {
  if (!lineItem) return { debits: [], debitIndexes: [] };

  return lineItem.debits.reduce(
    (memo, debit, debitIndex) => {
      if (!debit.payerId) {
        memo.debits.push(debit);
        memo.debitIndexes.push(debitIndex);
      }

      return memo;
    },
    { debits: [], debitIndexes: [] },
  );
}

export function getSecondaryPayerDebits(lineItem, secondaryInsuranceId) {
  if (!lineItem) return { debits: [], debitIndexes: [] };

  return lineItem.debits.reduce(
    (memo, debit, debitIndex) => {
      if (
        debit.payerId &&
        lineItem.primaryPayerId &&
        (debit.payerId !== lineItem.primaryPayerId ||
          (debit.patientInsuranceId &&
            debit.patientInsuranceId !== lineItem.primaryInsuranceId)) &&
        (!secondaryInsuranceId ||
          secondaryInsuranceId === debit.patientInsuranceId)
      ) {
        memo.debits.push(debit);
        memo.debitIndexes.push(debitIndex);
      }

      return memo;
    },
    { debits: [], debitIndexes: [] },
  );
}

export const createDebitModel = (
  payerId = null,
  patientInsuranceId = null,
) => ({
  allocations: [],
  amount: 0,
  id: '',
  payerId,
  allocated: 0,
  patientInsuranceId,
  codePaymentId: '',
});

const mapDebit = lid => ({
  allocations: lid.debit.allocations,
  amount: lid.debit.amount,
  id: lid.debit.id || '',
  payerId: lid.debit.payerId,
  allocated: lid.debit.allocated || 0,
  patientInsuranceId: lid.patientInsuranceId || null,
  codePaymentId: lid.codePaymentId || null,
});

const withAllocatedForPayment = (debit, payment = {}) => {
  const paymentAllocation = debit.allocations.find(
    a => a.credit && !!payment && a.credit.paymentId === payment.id,
  );

  return {
    ...debit,
    codePaymentId: debit.codePaymentId || '',
    patientInsuranceId: debit.patientInsuranceId || '',
    allocated: paymentAllocation ? paymentAllocation.amount : 0,
  };
};

const addDefaultDebit = (
  debits,
  { primaryPayerId = null, primaryInsuranceId = null } = {},
) => {
  if (debits.length === 0) {
    debits.push(createDebitModel(primaryPayerId, primaryInsuranceId));
  }

  return debits;
};

const getDebitsForNonPrimaryPayers = li =>
  addDefaultDebit(
    li.lineItemDebits
      .map(lid => mapDebit(lid))
      .filter(
        debit =>
          !li.primaryPayerId ||
          debit.payerId !== li.primaryPayerId ||
          debit.patientInsuranceId !== li.primaryInsuranceId,
      ),
  );

const getDebitsForPrimaryPayer = li =>
  li.primaryPayerId
    ? addDefaultDebit(
        li.lineItemDebits
          .map(lid => mapDebit(lid))
          .filter(
            debit =>
              li.primaryPayerId &&
              debit.payerId === li.primaryPayerId &&
              debit.patientInsuranceId === li.primaryInsuranceId,
          ),
        li,
      )
    : [];

const getPreviewDebitsForPrimaryPayer = li =>
  li.primaryPayerId
    ? addDefaultDebit(
        li.previewDebits.filter(
          previewDebit =>
            li.primaryPayerId &&
            previewDebit.payerId === li.primaryPayerId &&
            previewDebit.patientInsuranceId === li.primaryInsuranceId,
        ),
        li,
      )
    : [];

const getPreviewDebitsForNonPrimaryPayers = li =>
  addDefaultDebit(
    li.previewDebits.filter(
      previewDebit =>
        !li.primaryPayerId ||
        previewDebit.payerId !== li.primaryPayerId ||
        previewDebit.patientInsuranceId !== li.primaryInsuranceId,
    ),
  );

const withAllocatedForPreview = previewDebit => ({
  ...previewDebit,
  codePaymentId: previewDebit.codePaymentId || '',
  patientInsuranceId: previewDebit.patientInsuranceId || '',
  allocated: previewDebit.allocated,
  allocations: [],
  amount: previewDebit.amount,
  payerId: previewDebit.payerId || null,
});

const getDebits = (li, payment) =>
  [
    ...getDebitsForPrimaryPayer(li),
    ...getDebitsForNonPrimaryPayers(li).sort((a, b) => a.order - b.order),
  ].map(debit => withAllocatedForPayment(debit, payment));

const getDebitsWithPreview = li =>
  [
    ...getPreviewDebitsForPrimaryPayer(li),
    ...getPreviewDebitsForNonPrimaryPayers(li).sort(
      (a, b) => a.order - b.order,
    ),
  ].map(debits => withAllocatedForPreview(debits));

const formatAllocationLineItemBase = li => ({
  adjustments: li.adjustments.length
    ? li.adjustments.sort((a, b) => a.order - b.order)
    : [],
  allowedAmount: li.allowedAmount,
  billedAmount: li.billedAmount,
  billedPatient: li.billedPatient,
  billedPrimary: li.billedPrimary,
  billedSecondary: li.billedSecondary,
  billType: li.billType,
  chargeNumber: li.chargeNumber,
  code: li.code,
  codeType: li.codeType,
  dateOfService: li.dateOfService,
  encounterCharge: { ...li.encounterCharge },
  feeScheduleCharge: li.feeScheduleCharge,
  feeScheduleId: li.feeScheduleId || '',
  holdStatement: li.holdStatement,
  holdSuperBill: li.holdSuperBill,
  holdClaim: li.holdClaim,
  id: li.id || '',
  invoiceId: li.invoiceId,
  modifier_1: li.modifier_1 || '',
  modifier_2: li.modifier_2 || '',
  modifier_3: li.modifier_3 || '',
  modifier_4: li.modifier_4 || '',
  modifiers: li.modifiers || '',
  patientId: li.patientId,
  patientPackageId: li.patientPackageId,
  primaryPayerId: li.primaryPayerId,
  primaryInsuranceId: li.primaryInsuranceId,
  purchase: {
    ...li.purchase,
    chargeId: li.purchase ? li.purchase.chargeId || '' : '',
  },
  secondaryInsuranceId: li.secondaryInsuranceId,
  taxAmount: li.taxAmount,
  taxRate: {
    rate: li.taxRate || 0,
    name: li.taxName || '',
  },
  tertiaryInsuranceId: li.tertiaryInsuranceId,
  type: li.type,
  units: li.units,
  unitCharge: li.unitCharge,
  recoups: li.recoups || [],
});

const mapMinimalPatient = raw => ({
  id: raw.id || '',
  medicalRecordNumber: raw.medicalRecordNumber || '',
  firstName: raw.firstName || '',
  lastName: raw.lastName || '',
  middleName: raw.middleName || '',
  preferredName: raw.preferredName || '',
  suffix: raw.suffix || '',
});

const minimalPatient = (patientId, patient) => {
  if (patient) {
    return mapMinimalPatient(patient);
  }

  return mapMinimalPatient({ id: patientId });
};

const minimalProvider = provider => ({ id: provider.id, name: provider.name });

export const calculateAdjustmentsSum = adjustments =>
  adjustments.reduce(
    (sum, a) => sum + currencyToCentsWithNegative(a.amount),
    0,
  );

export const calculateDebitsAllocated = (debits, formatted = true) => {
  const allocated = debits
    ? debits.reduce((sum, debit) => sum + currencyToCents(debit.allocated), 0)
    : 0;
  return formatted ? centsToCurrency(allocated) : allocated;
};

export const calculateDebitsPaid = (debits, formatted = true) => {
  const paid = debits
    ? debits.reduce(
        (sum, debit) =>
          sum + debit.allocations.reduce((aSum, a) => aSum + a.amount, 0),
        0,
      )
    : 0;

  return formatted ? centsToCurrency(paid) : paid;
};

export const calculateDebitsOwed = (debits, formatted = true) => {
  const owed = debits
    ? debits.reduce((sum, debit) => sum + currencyToCents(debit.amount), 0)
    : 0;
  return formatted ? centsToCurrency(owed) : owed;
};

const sumDebitsAllocated = debits =>
  debits ? debits.reduce((sum, debit) => sum + debit.allocated, 0) : 0;

const sumNonPrimaryDebitsAllocated = debits =>
  debits ? debits.reduce((sum, debit) => sum + debit.secondaryAllocated, 0) : 0;

const sumDebitsOwed = debits =>
  debits ? debits.reduce((sum, debit) => sum + debit.amount, 0) : 0;

export const buildAllocationFields = (li, payerPlans = []) => {
  const { debits: patientDebits } = getPatientDebits(li);
  const { debits: primaryPayerDebits } = getPrimaryPayerDebits(li);
  const { debits: secondaryPayerDebits } = getSecondaryPayerDebits(li);

  const patientAllocated = sumDebitsAllocated(patientDebits);
  const primaryAllocated = sumDebitsAllocated(primaryPayerDebits);
  const patientAdjustment = '';
  const patientOwed = sumDebitsOwed(patientDebits);
  const primaryOwed = sumDebitsOwed(primaryPayerDebits);
  const patientPaid = calculateDebitsPaid(patientDebits, false);
  const primaryPaid = calculateDebitsPaid(primaryPayerDebits, false);

  const groupedSecondaryDebits = secondaryPayerDebits.reduce((memo, debit) => {
    const { patientInsuranceId, payerId } = debit;

    const key = `${payerId}-${patientInsuranceId}`;

    if (!memo[key]) {
      memo[key] = { patientInsuranceId, payerId, debits: [] };
    }

    if (memo[key]) {
      memo[key].debits.push(debit);
    }

    return memo;
  }, {});

  const nonPrimaryPayerDebits = Object.keys(groupedSecondaryDebits).reduce(
    (memo, key) => {
      const {
        payerId,
        patientInsuranceId,
        debits: commonSecondaryPayerDebits,
      } = groupedSecondaryDebits[key];

      const secondaryPayer = payerId
        ? payerPlans.find(p => p.id === payerId)
        : null;

      const secondaryAllocated = sumDebitsAllocated(commonSecondaryPayerDebits);
      const secondaryOwed = sumDebitsOwed(commonSecondaryPayerDebits);
      const secondaryPaid = calculateDebitsPaid(
        commonSecondaryPayerDebits,
        false,
      );

      return [
        ...memo,
        {
          patientInsuranceId,
          payerId,
          secondaryAllocated,
          secondaryOwed,
          secondaryPaid,
          secondaryPayer: secondaryPayer
            ? { alias: secondaryPayer.alias }
            : null,
        },
      ];
    },

    [],
  );

  // TODO: should add some sorting to nonPrimaryPayerDebits so debits matching lineItem.secondaryInsuranceId appear first

  return {
    patientAdjustment,
    patientOwed,
    patientAllocated,
    patientPaid,
    primaryOwed,
    primaryAllocated,
    primaryPaid,
    nonPrimaryPayerDebits,
  };
};

const formatAllocationLineItemV2 = (
  li,
  providers,
  payerPlans,
  payment,
  patients,
) => {
  const patient = li.patientId ? patients[li.patientId] : null;

  const primaryPayer = li.primaryPayerId
    ? payerPlans.find(p => p.id === li.primaryPayerId)
    : null;

  const provider = li.encounterCharge?.providerId
    ? minimalProvider(
        providers.find(p => p.id === li.encounterCharge.providerId),
      )
    : null;

  const baseLineItem = formatAllocationLineItemBase(li, payment);

  const debits = li.previewDebits?.length
    ? getDebitsWithPreview(li)
    : getDebits(li, payment);

  const baseLineItemWithDebits = { ...baseLineItem, debits };

  return {
    ...baseLineItemWithDebits,
    ...buildAllocationFields(baseLineItemWithDebits, payerPlans),
    patient: minimalPatient(li.patientId, patient),
    provider,
    primaryPayer,
  };
};

const findPlan = (payerPlans, planId, insuranceId) => {
  const plan = payerPlans.find(p => p.id === planId);
  return plan && plan.status !== 'Inactive'
    ? { ...plan, patientInsuranceId: insuranceId }
    : null;
};

const buildNonPrimaryPayerDebit = (plan, insuranceId) => ({
  patientInsuranceId: insuranceId,
  payerId: plan.id,
  secondaryAllocated: 0,
  secondaryOwed: 0,
  secondaryPaid: 0,
  secondaryPayer: {
    alias: plan.alias,
  },
});

const applyNonPrimaryPayerDebits = async (allocatedCharges, payerPlans) => {
  const newAllocatedCharges = await Promise.all(
    allocatedCharges.map(async charge => {
      const { nonPrimaryPayerDebits } = charge;

      if (nonPrimaryPayerDebits.length) {
        return charge;
      }

      const { patientId, secondaryInsuranceId } = charge;

      if (secondaryInsuranceId) {
        const { payerPlanId } = await getPatientInsurance(
          patientId,
          secondaryInsuranceId,
        );

        const plan = findPlan(payerPlans, payerPlanId, secondaryInsuranceId);
        const nonPrimaryPayerDebit = buildNonPrimaryPayerDebit(
          plan,
          secondaryInsuranceId,
        );

        return {
          ...charge,
          nonPrimaryPayerDebits: [nonPrimaryPayerDebit],
        };
      }
      const insurances = await getPatientInsurances(patientId, {
        active: true,
      });

      const secondaryInsurance = insurances.find(
        ins => ins.defaultLevel === DEFAULT_LEVEL.Secondary,
      );

      if (secondaryInsurance) {
        const { id, payerPlanId } = secondaryInsurance;
        const plan = findPlan(payerPlans, payerPlanId, id);
        const nonPrimaryPayerDebit = buildNonPrimaryPayerDebit(plan, id);

        return {
          ...charge,
          nonPrimaryPayerDebits: [nonPrimaryPayerDebit],
        };
      }

      return charge;
    }),
  );

  return newAllocatedCharges;
};

const applyAllNonPrimaryPayerDebits = async (allocatedCharges, payerPlans) => {
  if (!allocatedCharges.length) {
    return allocatedCharges;
  }

  const { patientId } = allocatedCharges[0];
  const patientInsurances = await getPatientInsurances(patientId, {
    active: true,
  });

  const newAllocatedCharges = allocatedCharges.map(charge => {
    const { nonPrimaryPayerDebits } = charge;

    const patientInsurancesAndPlans = patientInsurances.map(insurance => {
      const plan = payerPlans.find(p => p.id === insurance.payerPlanId);

      return {
        insurance,
        plan,
      };
    });

    const nonPrimaryPayerDebitPlanIds = nonPrimaryPayerDebits.map(
      npd => npd.payerId,
    );

    const nonPrimaryPayerPlans = patientInsurancesAndPlans.filter(item => {
      const { plan } = item;
      return (
        plan.id !== charge.primaryPayerId &&
        !nonPrimaryPayerDebitPlanIds.includes(plan.id)
      );
    });

    const newNonPrimaryPayerDebits = [
      ...nonPrimaryPayerDebits,
      ...nonPrimaryPayerPlans.map(item =>
        buildNonPrimaryPayerDebit(item.plan, item.insurance.id),
      ),
    ];

    return {
      ...charge,
      nonPrimaryPayerDebits: newNonPrimaryPayerDebits,
    };
  });

  return newAllocatedCharges;
};

export const formatAllocationLineItemsV2 = (
  lineItems,
  providers,
  payerPlans,
  payment,
  patients,
  hasRcmSecondaryField = false,
  hasShowNextInsurance = false,
) => {
  const allocationLineItems = lineItems.map(li =>
    formatAllocationLineItemV2(li, providers, payerPlans, payment, patients),
  );

  if (hasShowNextInsurance) {
    return applyAllNonPrimaryPayerDebits(allocationLineItems, payerPlans);
  }

  if (hasRcmSecondaryField) {
    return applyNonPrimaryPayerDebits(allocationLineItems, payerPlans);
  }

  return allocationLineItems;
};

export const getSelectedBalanceDueV2 = model => {
  const value = model.reduce((accum, curr) => {
    const { billedAmount, taxAmount, adjustments, debits } = curr;

    const adjustmentsSum = calculateAdjustmentsSum(adjustments);

    const allocationsSum = debits.reduce(
      (debitSum, debit) =>
        debitSum + debit.allocations.reduce((aSum, a) => aSum + a.amount, 0),
      0,
    );

    return accum + billedAmount + taxAmount - adjustmentsSum - allocationsSum;
  }, 0);

  return centsToCurrency(value);
};

export const getRowBalance = (row, paymentId, formatted = false) => {
  if (!row) return formatted ? '' : 0;

  const {
    billedAmount,
    taxAmount,
    adjustments,
    debits,
    patientAllocated,
    primaryAllocated,
    nonPrimaryPayerDebits,
  } = row;

  let adjustmentsSum = 0;

  if (adjustments) {
    adjustmentsSum = calculateAdjustmentsSum(adjustments);
  }

  let allocationsSum = 0;

  if (debits) {
    const allocationsTotal = debits.reduce(
      (sum, debit) =>
        sum +
        (debit.allocations
          ? debit.allocations.reduce(
              (aSum, a) =>
                aSum + (a.credit?.paymentId !== paymentId ? a.amount : 0),
              0,
            )
          : 0),
      0,
    );
    const secondaryAllocated = sumNonPrimaryDebitsAllocated(
      nonPrimaryPayerDebits,
    );
    const newAllocations =
      currencyToCents(patientAllocated) +
      currencyToCents(primaryAllocated) +
      secondaryAllocated;

    allocationsSum = allocationsTotal + newAllocations;
  }

  const balance = billedAmount + taxAmount - adjustmentsSum - allocationsSum;

  return formatted ? centsToCurrencyWithNegative(balance) : balance;
};

export const isPrimaryOwedCellDisabled = ({ debits }) => {
  if (!debits.length) return true;

  return false;
};

export const hasOnlyOnePaidPkgDebit = debits =>
  debits.length === 1 &&
  debits[0].allocations?.some(({ credit }) => credit.patientPackageId);

export const isAllocatedCellDisabled = ({
  allocatableId,
  debits,
  payers = [],
}) => {
  if (!debits.length) return true;

  const { payerId } = debits[0];

  if (!allocatableId) {
    return hasOnlyOnePaidPkgDebit(debits) || !!payerId;
  }

  if (payers.length) {
    const { payerGroupId: allocatableGroupId = '' } = {
      ...payers.find(({ id }) => id === allocatableId),
    };

    const { payerGroupId = '' } = {
      ...payers.find(({ id }) => id === payerId),
    };

    if (allocatableGroupId) return payerGroupId !== allocatableGroupId;
  }

  return payerId !== allocatableId;
};

export function validateAllowedAgainstTotalOwedV2(error) {
  return {
    error,
    validate: (_, keyPath, state) => {
      const item = getItem(keyPath, state);

      const totalOwedAmount = item.debits.reduce(
        (accum, curr) => accum + curr.amount,
        0,
      );

      const { allowedAmount } = item;

      return totalOwedAmount <= allowedAmount;
    },
  };
}

export function validateOwedAmountsV2(itemPath, state, service) {
  const adjustmentsPath = [...itemPath, 'adjustments'];
  const adjustments = getValueByPath(state, adjustmentsPath);

  for (let i = 0; i < adjustments.length; i++) {
    const path = [...adjustmentsPath, i, 'amount'];

    service.unsetPristine(path);
    service.validateKey(path, true);
  }

  const fields = [
    'allowedAmount',
    'patientOwed',
    'primaryOwed',
    'patientAllocated',
    'primaryAllocated',
  ];

  fields.forEach(field => {
    const path = [...itemPath, field];
    service.unsetPristine(path);
    service.validateKey(path, true);
  });
}

function sumNonPrimaryPayerDebitsAllocated(items, payerPlanId) {
  if (!items) return 0;
  return items.reduce((acc, row) => {
    const nonPrimary = row.nonPrimaryPayerDebits.find(
      np => np.payerId === payerPlanId,
    );
    return nonPrimary ? acc + nonPrimary.secondaryAllocated : acc;
  }, 0);
}

export function validatePatientAllocatedAmountAgainstAvailableOwedAmount(
  currentRow,
  available,
  model,
  state,
) {
  const startingSum = model.reduce((acc, row) => acc + row.patientAllocated, 0);
  const currentSum = state.reduce((acc, row) => acc + row.patientAllocated, 0);

  return (
    currentSum - startingSum <= available ||
    state[currentRow].patientAllocated <= state[currentRow].patientOwed ||
    state[currentRow].patientAllocated === model[currentRow].patientAllocated
  );
}

export function validatePrimaryAllocatedAmountAgainstAvailableOwedAmount(
  currentRow,
  available,
  model,
  state,
) {
  const startingSum = model.reduce((acc, row) => acc + row.primaryAllocated, 0);
  const currentSum = state.reduce((acc, row) => acc + row.primaryAllocated, 0);

  return (
    currentSum - startingSum <= available ||
    state[currentRow].primaryAllocated <= state[currentRow].primaryOwed ||
    state[currentRow].primaryAllocated === model[currentRow].primaryAllocated
  );
}

export function validateNonPrimaryAllocatedAmountAgainstAvailableOwedAmount(
  currentRow,
  subRow,
  selectedPayment,
  model,
  state,
) {
  const startingSum = sumNonPrimaryPayerDebitsAllocated(
    model,
    selectedPayment.payerPlanId,
  );
  const currentSum = sumNonPrimaryPayerDebitsAllocated(
    state,
    selectedPayment.payerPlanId,
  );

  return (
    currentSum - startingSum <= selectedPayment.available ||
    state[currentRow].nonPrimaryPayerDebits[subRow].secondaryAllocated <=
      state[currentRow].nonPrimaryPayerDebits[subRow].secondaryOwed ||
    state[currentRow].nonPrimaryPayerDebits[subRow].secondaryAllocated ===
      model[currentRow].nonPrimaryPayerDebits[subRow].secondaryAllocated
  );
}

export function validatePatientAllocatedAmountAgainstAvailableAmount(
  currentRow,
  available,
  model,
  state,
) {
  const startingSum = model.reduce((acc, row) => acc + row.patientAllocated, 0);
  const currentSum = state.reduce((acc, row) => acc + row.patientAllocated, 0);

  return (
    currentSum - startingSum <= available ||
    state[currentRow].patientAllocated === model[currentRow].patientAllocated
  );
}

export function validatePrimaryAllocatedAmountAgainstAvailableAmount(
  currentRow,
  available,
  model,
  state,
) {
  const startingSum = model.reduce((acc, row) => acc + row.primaryAllocated, 0);
  const currentSum = state.reduce((acc, row) => acc + row.primaryAllocated, 0);

  return (
    currentSum - startingSum <= available ||
    state[currentRow].primaryAllocated === model[currentRow].primaryAllocated
  );
}

export function validateNonPrimaryAllocatedAmountAgainstAvailableAmount(
  currentRow,
  subRow,
  selectedPayment,
  model,
  state,
) {
  const startingSum = sumNonPrimaryPayerDebitsAllocated(
    model,
    selectedPayment.payerPlanId,
  );
  const currentSum = sumNonPrimaryPayerDebitsAllocated(
    state,
    selectedPayment.payerPlanId,
  );

  return (
    currentSum - startingSum <= selectedPayment.available ||
    state[currentRow].nonPrimaryPayerDebits[subRow].secondaryAllocated ===
      model[currentRow].nonPrimaryPayerDebits[subRow].secondaryAllocated
  );
}

export function validatePatientAllocatedAmountAgainstOwedAmount(
  currentRow,
  model,
  state,
) {
  return (
    state[currentRow].patientAllocated <= state[currentRow].patientOwed ||
    state[currentRow].patientAllocated === model[currentRow].patientAllocated
  );
}

export function validatePatientOwedAmountAgainstAllocatedAmount(
  currentRow,
  model,
  state,
) {
  return (
    state[currentRow].patientAllocated <= state[currentRow].patientOwed ||
    state[currentRow].patientOwed === model[currentRow].patientOwed
  );
}

export function validatePrimaryAllocatedAmountAgainstOwedAmount(
  currentRow,
  model,
  state,
) {
  return (
    state[currentRow].primaryAllocated <= state[currentRow].primaryOwed ||
    state[currentRow].primaryAllocated === model[currentRow].primaryAllocated
  );
}

export function validatePrimaryOwedAmountAgainstAllocatedAmount(
  currentRow,
  model,
  state,
) {
  return (
    state[currentRow].primaryAllocated <= state[currentRow].primaryOwed ||
    state[currentRow].primaryOwed === model[currentRow].primaryOwed
  );
}

export function validateNonPrimaryAllocatedAmountAgainstOwedAmount(
  currentRow,
  subRow,
  model,
  state,
) {
  return (
    state[currentRow].nonPrimaryPayerDebits[subRow].secondaryAllocated <=
      state[currentRow].nonPrimaryPayerDebits[subRow].secondaryOwed ||
    state[currentRow].nonPrimaryPayerDebits[subRow].secondaryAllocated ===
      model[currentRow].nonPrimaryPayerDebits[subRow].secondaryAllocated
  );
}

export function validateNonPrimaryOwedAmountAgainstAllocatedAmount(
  currentRow,
  subRow,
  model,
  state,
) {
  return (
    state[currentRow].nonPrimaryPayerDebits[subRow].secondaryAllocated <=
      state[currentRow].nonPrimaryPayerDebits[subRow].secondaryOwed ||
    state[currentRow].nonPrimaryPayerDebits[subRow].secondaryOwed ===
      model[currentRow].nonPrimaryPayerDebits[subRow].secondaryOwed
  );
}

export function calculateGrandTotalV2(item) {
  const responsibilityAmountTotal = item.debits.reduce(
    (acc, curr) =>
      acc +
      (Number.isInteger(curr.amount)
        ? curr.amount
        : currencyToCents(curr.amount)),
    0,
  );

  const adjustmentAmountTotal = item.adjustments.reduce(
    (acc, curr) =>
      acc +
      (Number.isInteger(curr.amount)
        ? curr.amount
        : currencyToCentsWithNegative(curr.amount)),
    0,
  );

  return responsibilityAmountTotal + adjustmentAmountTotal;
}

export function owedGrandTotalValidatorV2(error = 'Required') {
  return {
    error,
    validate: (value, keyPath, state) => {
      const item = getItem(keyPath, state);

      const owedGrandTotal = calculateGrandTotalV2(item);

      return owedGrandTotal === item.billedAmount + item.taxAmount;
    },
  };
}

export const rowHasPackageCreditsOrAdjustments = (row, debits) =>
  debits.some(debit =>
    debit.allocations.some(({ credit }) => credit.patientPackageId),
  ) ||
  row.adjustments.some(({ codeId }) => {
    if (codeId.data) {
      return codeId.data.code === CODE_WRITE_OFFS.PACKAGE.code;
    }

    return codeId === CODE_WRITE_OFFS.PACKAGE.id;
  });

export const mapDebitsForEditPopup = debits =>
  debits.map(debit => ({
    id: debit.id,
    codePaymentId: debit.codePaymentId,
    debit: {
      amount: currencyToCentsWithNegative(debit.amount),
      allocations: debit.allocations,
    },
  }));

export const mapPatientInsurancesForEditPopup = plans =>
  plans.map(plan => ({
    id: plan.data.id,
    label: plan.label,
    payer: {
      id: plan.data.payerPlanId,
      alias: plan.data.payerPlan.alias,
    },
  }));

export const isPatientRespDebit = debit => debit.payerId === null;

export const isPayerRespDebit = debit => debit.payerId;

export const mapDebitAmountsToCents = debits =>
  debits.map(debit => ({
    ...debit,
    amount: currencyToCentsWithNegative(debit.amount),
    allocated: currencyToCentsWithNegative(debit.allocated),
  }));

export const buildDebitAllocationAmounts = ({
  debits,
  debitIndexes,
  allocationAmount,
}) => {
  let remaining = allocationAmount;

  const filteredDebits = [];
  const result = [];

  for (let ii = 0; ii < debits.length; ii += 1) {
    const debit = debits[ii];
    const debitIndex = debitIndexes[ii];

    const { allocations } = debit;

    const hasPackagePayments = allocations.some(
      ({ credit }) => credit.patientPackageId,
    );

    if (!hasPackagePayments) {
      filteredDebits.push([debit, debitIndex]);
    }
  }

  for (let ii = 0; ii < filteredDebits.length; ii += 1) {
    const [debit, debitIndex] = filteredDebits[ii];

    const { amount } = debit;

    let allocated = 0;

    if (amount > 0 && remaining > 0) {
      if (ii === filteredDebits.length - 1) {
        allocated = remaining;
      } else {
        allocated = amount > remaining ? remaining : amount;
      }

      remaining -= allocated;
    }

    result.push({ debitIndex, allocated });
  }

  return result;
};

export const mapAdjustmentsForEditPopup = adjustments =>
  adjustments.map(adjustment => ({
    id: adjustment.id,
    codeId: adjustment.codeId,
    amount: currencyToCentsWithNegative(adjustment.amount),
  }));

export const calculateBalancedAllowedAmount = (
  billedAmount,
  taxAmount,
  adjustmentAmount,
) => billedAmount + taxAmount - adjustmentAmount;

export const calculateBalancedAdjustmentAmount = (
  billedAmount,
  taxAmount,
  allowedAmount,
) => billedAmount + taxAmount - allowedAmount;

export const getPatientRespCodePaymentId = debits => {
  const patientDebit = debits.find(
    d => currencyToCents(d.amount) !== 0 && !d.payerId,
  );

  return [
    {
      id: '',
      codePaymentId: patientDebit ? patientDebit.codePaymentId : '',
      debit: {
        amount: 0,
        allocations: [],
      },
    },
  ];
};
