import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const getPayerInformation = id =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `line-items/${id}/payer-information`,
    version: 1,
  });
