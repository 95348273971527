import ApiClient, { Method } from '../utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'payments' });

const baseUrl = 'payfields';

export const getPayfieldsApiKey = async () => {
  try {
    const result = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      path: baseUrl,
      method: Method.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return result;
  } catch (err) {
    return null;
  }
};
