import { createTenantApiRequestAction } from '../utils';

import * as actions from './item-actions';

const __createFetchingAction = name => ({
  type: actions.ITEM_FETCHING,
  name,
});

const __createFetchedAction = (name, res, processor) => ({
  type: actions.ITEM_FETCHED,
  name,
  res,
  processor,
});

const __createFetchErrorAction = (name, fetchError) => ({
  type: actions.ITEM_FETCH_ERROR,
  name,
  fetchError,
});

const __createCreatingAction = name => ({
  type: actions.ITEM_CREATING,
  name,
});

const __createCreatedAction = (name, res) => ({
  type: actions.ITEM_CREATED,
  name,
  res,
});

const __createCreateErrorAction = (name, createError) => ({
  type: actions.ITEM_CREATE_ERROR,
  name,
  createError,
});

const __createUpdatingAction = name => ({
  type: actions.ITEM_UPDATING,
  name,
});

const __createUpdatedAction = (name, res) => ({
  type: actions.ITEM_UPDATED,
  name,
  res,
});

const __createUpdateErrorAction = (name, updateError) => ({
  type: actions.ITEM_UPDATE_ERROR,
  name,
  updateError,
});

const __createDeletingAction = name => ({
  type: actions.ITEM_DELETING,
  name,
});

const __createDeletedAction = (name, res) => ({
  type: actions.ITEM_DELETED,
  name,
  res,
});

const __createDeleteErrorAction = (name, fetchError) => ({
  type: actions.ITEM_DELETE_ERROR,
  name,
  fetchError,
});

const __createFetchingPageAction = (name, page) => ({
  type: actions.ITEM_FETCHING,
  name,
  page,
});

const __createFetchedPageAction = (name, res, page) => ({
  type: actions.ITEM_FETCHED,
  name,
  page,
  res,
});

const __createFetchPageErrorAction = (name, fetchError, page) => ({
  type: actions.ITEM_FETCH_ERROR,
  name,
  fetchError,
  page,
});

export const createFetchItemsAction = (
  name,
  url,
  query,
  processor,
  optOutLoadingIndicator = false,
  apiClientType,
) =>
  createTenantApiRequestAction(
    {
      url,
      query,
      optOutLoadingIndicator,
    },
    [
      () => __createFetchingAction(name),
      res => __createFetchedAction(name, res, processor),
      fetchError => __createFetchErrorAction(name, fetchError),
    ],
    apiClientType,
  );

export const createCreateItemsAction = ({
  name,
  url,
  item,
  query,
  opts = {},
}) =>
  createTenantApiRequestAction(
    {
      url,
      query,
      options: {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
          'Content-Type': 'application/json',
        },
        ...opts,
      },
    },
    [
      () => __createCreatingAction(name),
      res => __createCreatedAction(name, res),
      createError => __createCreateErrorAction(name, createError),
    ],
  );

export const createUpdateItemsAction = ({
  name,
  url,
  item,
  query,
  opts = {},
}) =>
  createTenantApiRequestAction(
    {
      url,
      query,
      options: {
        method: 'PUT',
        body: JSON.stringify(item),
        headers: {
          'Content-Type': 'application/json',
        },
        ...opts,
      },
    },
    [
      () => __createUpdatingAction(name),
      res => __createUpdatedAction(name, res),
      updateError => __createUpdateErrorAction(name, updateError),
    ],
  );

export const createDeleteItemsAction = ({ name, url, query, opts = {} }) =>
  createTenantApiRequestAction(
    {
      url,
      query,
      options: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        ...opts,
      },
    },
    [
      () => __createDeletingAction(name),
      res => __createDeletedAction(name, res),
      fetchError => __createDeleteErrorAction(name, fetchError),
    ],
  );

export const createFetchSingleAction = (
  name,
  id,
  url,
  query,
  force = false,
  optOutLoadingIndicator = false,
) => (dispatch, getState) => {
  const items = getState()[name];

  if (!force && items && id in items) {
    return dispatch({
      type: actions.ITEM_FETCH_EXISTS,
      name,
      id,
    });
  }
  return dispatch(
    createFetchItemsAction(name, url, query, undefined, optOutLoadingIndicator),
  );
};

export const createSetPaginationQueryAction = (name, limit, query) => ({
  type: actions.ITEM_PAGINATION_SET_QUERY,
  name,
  limit,
  query,
});

export const createFetchPageAction = (
  name,
  reducerName,
  url,
  page,
  apiClientType,
  optOutLoadingIndicator = false,
) => (dispatch, getState) => {
  const { limit, query } = getState()[reducerName];
  const offset = (page - 1) * limit;
  const queryParams = { ...query, offset, limit };
  return dispatch(
    createTenantApiRequestAction(
      {
        url,
        query: queryParams,
        optOutLoadingIndicator,
      },
      [
        () => __createFetchingPageAction(name, page),
        res => __createFetchedPageAction(name, res, page),
        fetchError => __createFetchPageErrorAction(name, fetchError, page),
      ],
      apiClientType,
    ),
  );
};
