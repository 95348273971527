import '../../../../packages/neb-lit-components/src/components/inputs/neb-floating-label';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/neb-patient-search';
import '../../../../packages/neb-lit-components/src/components/neb-tooltip';
import '../../../../packages/neb-material-design/src/components/neb-md-textfield';

import { html, css, LitElement } from 'lit';

import { computeTime } from '../../../../packages/neb-input/nebFormatUtils';
import { isRequired } from '../../../../packages/neb-lit-components/src/components/field-groups/neb-duration';
import { LAYOUT_TYPE } from '../../../../packages/neb-redux/services/layout';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  CSS_COLOR_ERROR,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../styles';

export const ELEMENTS = {
  removeAllSplitsButton: { id: 'button-remove-all-splits' },
  addSplitButton: { id: 'button-add-split' },
  splitRooms: { selector: '.split-room', tag: 'neb-select' },
  splitDurations: { selector: '.split-duration', tag: 'neb-duration' },
  removeSplitButtons: {
    selector: '.button-remove-split',
    tag: 'neb-button-action',
  },
  splitRows: { selector: '.split-row' },
  totalDuration: { id: 'total-duration' },
};

const getDuration = ({ hours, minutes }) =>
  Math.floor(hours * (1000 * 60 * 60) + minutes * (1000 * 60));

export default class NebFormSplitAppointment extends LitElement {
  static get properties() {
    return {
      totalDuration: Number,
      splits: Array,
      rooms: Array,
      errors: Object,
      appointmentRoom: Object,
      enableConflictCheck: Boolean,
      name: { type: String, reflect: true },
      layout: { type: String, reflect: true },
    };
  }

  static get styles() {
    return css`
      .split-row {
        padding-bottom: 20px;
      }
      .split-row-grid {
        display: flex;
        gap: ${CSS_SPACING};
        justify-content: space-between;
      }
      :host(:not([layout='large'])) .split-row-grid {
        display: grid;
        grid-template-columns: 1fr minmax(0px, 6fr);
      }
      .button-remove-split {
        align-self: center;
      }
      .split-time {
        position: relative;
        min-width: 60px;
        display: flex;
        place-content: center;
      }
      .split-time-text {
        place-self: center;
        white-space: nowrap;
        width: 50px;
      }
      .room-column {
        display: flex;
      }
      .split-header {
        display: grid;
        grid-template-columns: auto 1fr auto;
        justify-content: space-between;
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        padding-bottom: ${CSS_SPACING};
        gap: ${CSS_SPACING};
      }
      .total-duration {
        display: grid;
        justify-content: flex-end;
      }
      .button-remove-split {
        grid-row: 1;
        grid-column: 3;
      }
      .split-room {
        grid-row: 1;
        grid-column: 2;
        width: 90%;
      }
      .split-duration {
        grid-row: 2;
        grid-column: 2;
        width: 100%;
      }
      .floating-label {
        left: auto;
      }
      .split-conflict {
        color: ${CSS_COLOR_ERROR};
        font-size: 12px;
      }
    `;
  }

  static createModel(date) {
    return [
      {
        start: date.toISOString(),
        duration: 0,
        resourceId: null,
        providerValid: true,
        resourceValid: true,
      },
    ];
  }

  static createSelectors(rooms) {
    return {
      children: {
        $: {
          children: {
            resourceId: {
              validators: [
                {
                  error: 'Required',
                  validate: v => v && v.data.id,
                },
              ],
              format: v =>
                rooms.find(item => item.data.id === v) || { data: {} },
              unformat: v => v && v.data.id,
              unsafe: true,
              clipPristine: true,
            },
            duration: {
              validators: [isRequired()],
              format: v => ({
                hours: Math.floor(v / 3600000),
                minutes: Math.floor((v % 3600000) / 60000),
              }),
              unformat: getDuration,
              unsafe: true,
            },
          },
        },
      },

      createItem: (_, __, model) => {
        const duration =
          !model.splits.length && model.duration ? model.duration : 0;

        return {
          start: parseDate().toISOString(),
          duration,
          resourceId: null,
          providerValid: true,
          resourceValid: true,
        };
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.totalDuration = 0;
    this.layout = LAYOUT_TYPE.LARGE;
    this.errors = {};
    this.rooms = [];
    this.splits = [];
    this.name = '';
    this.appointmentRoom = null;
    this.enableConflictCheck = false;

    this.onChange = () => {};

    this.onChangeAppointmentRoom = () => {};

    this.onAddSplit = () => {};

    this.onRemoveSplit = () => {};

    this.onRemoveSplits = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addSplit: () => {
        this.onAddSplit();
      },
      removeSplit: index => {
        this.onRemoveSplit(index);

        if (index === 0 && this.splits[0]) {
          this.onChangeAppointmentRoom({
            name: 'resourceId',
            value: this.splits[0].resourceId,
          });
        }
      },
      removeSplits: () => {
        this.onRemoveSplits();
      },
      // eslint-disable-next-line complexity
      change: (e, index) => {
        if (e.event === 'focus' || e.event === 'blur') {
          return;
        }

        if (e.name === 'resourceId') {
          if (this.splits[index][e.name] === e.value) {
            return;
          }

          if (index === 0) {
            this.onChangeAppointmentRoom(e);
          }
        } else if (
          e.name === 'duration.hours' ||
          e.name === 'duration.minutes'
        ) {
          const [name, durationType] = e.name.split('.');

          if (this.splits[index][name][durationType] === e.value) {
            return;
          }
        }

        this.onChange({
          name: `${this.name}.${index}.${e.name}`,
          value: e.value,
        });
      },
    };
  }

  updated(changedProps) {
    if (
      changedProps.has('appointmentRoom') &&
      this.appointmentRoom &&
      this.splits[0] &&
      this.appointmentRoom.data.id !== this.splits[0].resourceId.data.id
    ) {
      this.onChange({
        name: `${this.name}.0.resourceId`,
        value: this.appointmentRoom,
      });
    }
  }

  __renderSplitConflictText(split) {
    let result = '';

    if (
      (split.providerValid && split.resourceValid) ||
      !this.enableConflictCheck
    ) {
      return result;
    }

    if (!split.providerValid) {
      result += "provider's";
    }

    if (!split.resourceValid) {
      if (result) {
        result += ' and ';
      }

      result += "room's";
    }

    return html`
      <div class="split-conflict">
        Selected duration conflicts with ${result} availability.
      </div>
    `;
  }

  render() {
    return html`
      <div class="split-header">
        <div class=>Appointment Room Split</div>

        <div id="${
          ELEMENTS.totalDuration.id
        }" class="total-duration">Total Duration: ${computeTime(
      this.totalDuration,
    )}</div>

        <neb-button-action
          id="${ELEMENTS.removeAllSplitsButton.id}"
          class="button-remove-split"
          leadingIcon="minus"
          label=""
          .onClick="${() => this.__handlers.removeSplits()}"
        ></neb-button-action>
      </div>

      <div>
        ${this.splits.map(
          (split, index) => html`
            <div class="split-row">
              <div class="split-row-grid">
                <div class="split-time">
                  <neb-floating-label
                    class="floating-label"
                    text="Start Time"
                    ?pinned="${true}"
                  >
                  </neb-floating-label>

                  <span class="split-time-text">
                    ${parseDate(split.start).format('h:mm a')}
                  </span>
                </div>

                <neb-select
                  label="Split Room"
                  name="resourceId"
                  class="split-room"
                  .helper="${'Required'}"
                  .items="${this.rooms}"
                  .value="${split.resourceId}"
                  .onChange="${value => this.__handlers.change(value, index)}"
                  .error="${
                    this.errors &&
                      this.errors.splits &&
                      this.errors.splits[index].resourceId
                  }"
                ></neb-select>

                <neb-duration
                  class="split-duration"
                  .helper="${'Required'}"
                  name="duration"
                  .model="${split.duration}"
                  .onChange="${value => this.__handlers.change(value, index)}"
                  .error="${
                    this.errors &&
                      this.errors.splits &&
                      this.errors.splits[index].duration
                  }"
                >
                </neb-duration>

                <neb-button-action
                  class="button-remove-split"
                  leadingIcon="minus"
                  label=""
                  .onClick="${() => this.__handlers.removeSplit(index)}"
                ></neb-button-action>
              </div>

              ${this.__renderSplitConflictText(split)}
            </div>
          `,
        )}

        <neb-button-action
          id="${ELEMENTS.addSplitButton.id}"
          label="Add Split Room"
          .onClick="${this.__handlers.addSplit}"
        ></neb-button-action>
      </div>
    `;
  }
}

customElements.define('neb-form-split-appointment', NebFormSplitAppointment);
