import '../../../../../src/components/misc/neb-icon';
import '../neb-text';

import { LitElement, html, css } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_ERROR_BORDER_COLOR,
  CSS_ERROR_BACKGROUND_COLOR,
  CSS_BANNER_INFO_COLOR,
  CSS_BANNER_INFO_BORDER_COLOR,
  CSS_BANNER_INFO_BACKGROUND_COLOR,
  CSS_WARNING_COLOR,
  CSS_WARNING_BACKGROUND_COLOR,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_FAMILY,
  CSS_SPACING,
  CSS_BORDER_GREY_1,
  CSS_BORDER_GREY_2,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  descriptions: { selector: '.description' },
  validationErrors: { selector: '.validation-errors' },
  container: { id: 'container' },
  titles: { selector: '.title' },
  label: { id: 'label' },
  ruleSetsLabel: { id: 'rule-sets-Label' },
  items: { selector: '.item' },
  icon: { id: 'icon' },
  visibleIcon: { id: 'icon-visible' },
};

export const THEME = {
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

class Category extends LitElement {
  static get properties() {
    return {
      open: { type: Boolean, reflect: true },
      index: { type: Number },
      icon: { type: String },
      theme: { type: String, reflect: true },
      label: { type: String },
      name: { type: String },
      items: { type: Array },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        :host([open]) .chevron {
          transform: rotate(180deg);
        }

        :host([open]) .container {
          border-radius: 3px 3px 0 0;
        }

        :host([theme='error']) .container {
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
          border: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([theme='error']) .label {
          color: ${CSS_BANNER_ERROR_COLOR};
        }

        :host([theme='error']) .icon {
          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        :host([theme='error']) .item {
          border-right: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
          border-left: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
          word-break: break-word;
        }

        :host([theme='error']) .item:last-child {
          border-bottom: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
        }

        :host([theme='info']) .container {
          background-color: ${CSS_BANNER_INFO_BACKGROUND_COLOR};
          border: 1px solid ${CSS_BANNER_INFO_BORDER_COLOR};
        }

        :host([theme='info']) .label {
          color: ${CSS_BANNER_INFO_COLOR};
        }

        :host([theme='info']) .icon {
          fill: ${CSS_BANNER_INFO_COLOR};
        }

        :host([theme='info']) .item {
          border-right: 1px solid ${CSS_BANNER_INFO_BORDER_COLOR};
          border-left: 1px solid ${CSS_BANNER_INFO_BORDER_COLOR};
        }

        :host([theme='info']) .item:last-child {
          border-bottom: 1px solid ${CSS_BANNER_INFO_BORDER_COLOR};
        }

        :host([theme='info']) .item:hover {
          background-color: initial;
        }

        :host([theme='info']) .item {
          cursor: initial;
        }

        :host([theme='warning']) .container {
          background-color: ${CSS_WARNING_BACKGROUND_COLOR};
          border: 1px solid ${CSS_WARNING_COLOR};
        }

        :host([theme='warning']) .label {
          color: ${CSS_WARNING_COLOR};
        }

        :host([theme='warning']) .icon {
          fill: ${CSS_WARNING_COLOR};
        }

        :host([theme='warning']) .item {
          border-right: 1px solid ${CSS_WARNING_COLOR};
          border-left: 1px solid ${CSS_WARNING_COLOR};
        }

        :host([theme='warning']) .item:last-child {
          border-bottom: 1px solid ${CSS_WARNING_COLOR};
        }

        .container {
          display: flex;
          align-items: center;
          width: 100%;
          height: 50px;
          border-radius: 3px;
          padding: ${CSS_SPACING} 5px ${CSS_SPACING} ${CSS_SPACING};

          background-color: ${CSS_COLOR_GREY_1};
          border: ${CSS_BORDER_GREY_2};

          cursor: pointer;
        }

        .item:hover {
          background-color: ${CSS_COLOR_GREY_4};
        }

        .label {
          font-family: ${CSS_FONT_FAMILY};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};

          color: ${CSS_BORDER_GREY_2};
        }

        .rules-label {
          font-family: ${CSS_FONT_FAMILY};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
        }

        .icon {
          height: ${CSS_SPACING};
          width: ${CSS_SPACING};
          margin-right: 12px;
          fill: ${CSS_BORDER_GREY_2};
        }

        .rules-icon {
          height: ${CSS_SPACING};
          width: ${CSS_SPACING};
          margin-right: 12px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .chevron {
          height: 13px;
          width: 13px;
        }

        .flex-1 {
          display: flex;
          flex: 1 0 0;
        }

        .flex-2 {
          display: flex;
          flex: 2.5;
        }

        .item {
          display: flex;
          padding: 15px 20px;
          border-bottom: ${CSS_BORDER_GREY_1};
          cursor: pointer;

          border-left: ${CSS_BORDER_GREY_2};
          border-right: ${CSS_BORDER_GREY_2};
        }

        .title {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          flex: 1 0 10px;
          padding-right: 10px;
        }

        .description {
          flex: 10 0 0;
        }

        .item:last-child {
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .clickable {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          cursor: pointer;
        }

        .validation-errors {
          word-break: break-word;
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.open = false;
    this.index = 0;
    this.theme = '';
    this.label = '';
    this.name = '';
    this.icon = '';
    this.items = [];

    this.onClick = () => {};

    this.onSelectItem = () => {};

    this.onViewRuleSets = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: e => {
        e.stopPropagation();

        return this.onClick(this.index);
      },

      viewRuleSets: e => {
        e.stopPropagation();
        this.onViewRuleSets();
      },

      selectItem: e => {
        e.stopPropagation();
        const index = parseInt(e.currentTarget.getAttribute('index'), 10);
        const value = this.items[index];

        return this.onSelectItem({
          index,
          value,
          name: this.name,
        });
      },
    };
  }

  get __iconTemplate() {
    return this.icon
      ? html`
          <neb-icon
            id="${ELEMENTS.icon.id}"
            class="icon"
            .icon="${`neb:${this.icon}`}"
          ></neb-icon>
        `
      : '';
  }

  __printValidationErrors(validationErrors) {
    return Object.entries(validationErrors).map(
      ([key, value]) => html`
        <div><b>${key}</b>: ${value} <br /></div>
      `,
    );
  }

  __renderViewRuleSets() {
    return this.name === 'Claim Scrubbing Errors'
      ? html`
          <div class="flex-1">
            <neb-icon
              id="${ELEMENTS.visibleIcon.id}"
              class="rules-icon"
              .icon="${'neb:visible'}"
            ></neb-icon>
            <div
              id="${ELEMENTS.ruleSetsLabel.id}"
              class="rules-label"
              @click="${this.__handlers.viewRuleSets}"
            >
              See Rule Sets
            </div>
          </div>
        `
      : '';
  }

  render() {
    const labelClasses = {
      'flex-1': this.name !== 'Claim Scrubbing Errors',
      'flex-2': this.name === 'Claim Scrubbing Errors',
    };

    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.click}"
      >
        <div class="${classMap(labelClasses)}">
          ${this.__iconTemplate}
          <div id="${ELEMENTS.label.id}" class="label">${this.label}</div>
        </div>

        ${this.__renderViewRuleSets()}

        <neb-icon class="icon chevron" icon="neb:chevron"></neb-icon>
      </div>

      <div class="content">
        ${
          this.open
            ? this.items.map(
                (item, index) => html`
                  <div
                    class="item"
                    @click="${this.__handlers.selectItem}"
                    index="${index}"
                  >
                    ${
                      item.title
                        ? html`
                            <div class="title">${item.title}</div>
                          `
                        : ''
                    }
                    ${
                      item.validationErrors
                        ? html`
                            <div class="validation-errors">
                              ${
                                this.__printValidationErrors(
                                  item.validationErrors,
                                )
                              }
                            </div>
                          `
                        : ''
                    }
                    <neb-text
                      class="description ${item.clickable ? 'clickable' : ''}"
                    >
                      ${item.description}
                    </neb-text>
                  </div>
                `,
              )
            : ''
        }
      </div>
    `;
  }
}

customElements.define('neb-category', Category);
