import { PRODUCTION_URL } from '../../../../packages/neb-utils/env';
import { PLATFORM_OS } from '../mappers/platform-mapper';
import { mapJsonToFormData } from '../mappers/scan-api-mapper';

export const SCAN_ACTION = Object.freeze({
  GET_DEVICES: 'Devices',
  INIT_SERVICE: 'InitService',
  SERVICE_AVAILABLE: 'ServiceAvailable',
  RESET_SERVICE: 'ResetService',
  INITIALIZE: 'Initialize',
  GET_DEVICE: 'Device',
  START_SCAN: 'StartScan',
  CHECK_STATUS: 'CheckScanStatus',
  GET_PAGE: 'GetPage',
  FETCH_VERSION: 'FetchVersion',
  DELETE_ALL_PAGES: 'DeleteAllPages',
  DELETE_PAGE: 'DeletePage',
});

const LICENSE_URL_FILE = Object.freeze({
  'http://192.168.1.4:8082': '192.168.1.4-8082.lic',
  'http://192.168.1.5:8082': '192.168.1.5-8082.lic',
  'http://192.168.1.122:8082': '192.168.1.122-8082.lic',
  'http://localhost:8082': 'localhost-8082.lic',
  'https://dev.nebula.care': 'dev.nebula.care.lic',
  'https://staging.nebula.care': 'staging.nebula.care.lic',
  'https://sales.nebula.care': 'sales.nebula.care.lic',
  [PRODUCTION_URL]: 'practice.chirotouch.com.lic',
});

const SCANNER_API_BASE_URL = 'http://localhost:9098';
const SCANNER_API_BASE_URL_SAFARI = 'https://local.webtwainsdk.com:9097';

const SCANNER_API_PATH = 'api/service';

const HEADER_FORM_POST_WINDOWS = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

const getScannerLicenseUrl = () => {
  const baseUrl = window.location.origin;

  if (LICENSE_URL_FILE[baseUrl]) {
    return `${baseUrl}/license/scanner/${LICENSE_URL_FILE[baseUrl]}`;
  }

  return '';
};

const session = {
  counter: Date.now(),
  clientId: Date.now(),
  baseUrl: SCANNER_API_BASE_URL,
  platformOS: PLATFORM_OS.WINDOWS,
  licenseFile: getScannerLicenseUrl(),
};

const getSessionCounterParam = () => {
  session.counter += 1;
  return { _: session.counter };
};

const getSessionClientIdParam = () => ({
  clientID: session.clientId,
});

const getSessionLicenseParam = () => ({
  licenseFile: session.licenseFile,
});

const getSessionClientIdAndCounterParam = () => ({
  ...getSessionClientIdParam(),
  ...getSessionCounterParam(),
});

const getBaseUrl = isSafari =>
  isSafari ? SCANNER_API_BASE_URL_SAFARI : SCANNER_API_BASE_URL;

export const executeRequest = async ({ url, method, body, headers }) => {
  try {
    const response = await fetch(url, {
      method,
      ...(body && { body }),
      ...(headers && { headers }),
    });

    return { response, error: !response.ok };
  } catch (err) {
    console.error(err);
  }
  return { response: null, error: true };
};

export const postData = ({ url, body = {}, headers = {} }) =>
  executeRequest({ url, method: 'POST', body, headers });

export const fetchData = url => executeRequest({ url, method: 'GET' });

export const buildUrl = (action, params = '') => {
  const url = new URL(`${SCANNER_API_PATH}/${action}`, session.baseUrl);
  url.search = new URLSearchParams(params).toString();
  return url;
};

export const parseJsonSuccess = json => {
  if (Object.hasOwn(json, 'success')) {
    const macOSJson = json;
    return macOSJson;
  }

  const windowsJson = { success: json };
  return windowsJson;
};

export const initSession = ({
  baseUrl,
  platformOS = PLATFORM_OS.WINDOWS,
  isSafari,
} = {}) => {
  session.counter = Date.now();
  session.clientId = Date.now();
  session.baseUrl = baseUrl || getBaseUrl(isSafari);
  session.platformOS = platformOS;
  session.licenseFile = getScannerLicenseUrl();
};

export const getSession = () => ({ ...session });

export const isServiceAvailable = async () => {
  const result = await fetchData(
    buildUrl(SCAN_ACTION.SERVICE_AVAILABLE, getSessionCounterParam()),
  );

  if (result.error) {
    return false;
  }

  return result.response.status === 200;
};

export const fetchVersion = async () => {
  const result = await fetchData(
    buildUrl(SCAN_ACTION.FETCH_VERSION, getSessionCounterParam()),
  );

  if (result.error) {
    return null;
  }

  const versionData = await result.response.json();
  return versionData.version.toString();
};

export const resetService = async () => {
  const result = await fetchData(
    buildUrl(SCAN_ACTION.RESET_SERVICE, getSessionCounterParam()),
  );

  if (result.error) {
    return false;
  }

  return result.response.status === 200;
};

export const initialize = async () => {
  session.clientId = Date.now();

  const result = await postData({
    url: buildUrl(SCAN_ACTION.INITIALIZE, {
      ...getSessionClientIdParam(),
      ...getSessionLicenseParam(),
    }),
  });

  if (result.error) {
    return null;
  }

  const json = await result.response.json();
  return json;
};

export const getDevices = async () => {
  const result = await fetchData(
    buildUrl(SCAN_ACTION.GET_DEVICES, getSessionClientIdAndCounterParam()),
  );

  if (result.error) {
    return [];
  }

  const json = await result.response.json();
  return json;
};

export const getDevice = async id => {
  const result = await fetchData(
    buildUrl(
      `${SCAN_ACTION.GET_DEVICE}/${id}`,
      getSessionClientIdAndCounterParam(),
    ),
  );

  if (result.error) {
    return null;
  }

  const json = await result.response.json();
  return json;
};

export const startScan = async body => {
  const formDataBody = mapJsonToFormData(
    {
      clientId: session.clientId,
      ...body,
    },
    session.platformOS === PLATFORM_OS.WINDOWS,
  );

  const headers =
    session.platformOS === PLATFORM_OS.WINDOWS ? HEADER_FORM_POST_WINDOWS : {};

  const result = await postData({
    url: buildUrl(SCAN_ACTION.START_SCAN),
    body: formDataBody,
    headers,
  });

  if (result.error) {
    return { error: true };
  }

  const json = await result.response.json();
  return json;
};

export const checkScanStatus = async () => {
  const result = await fetchData(
    buildUrl(SCAN_ACTION.CHECK_STATUS, getSessionClientIdAndCounterParam()),
  );

  if (result.error) {
    return { error: true };
  }

  const json = await result.response.json();
  return json;
};

export const getPage = async pageNumber => {
  const parameters = {
    ...getSessionClientIdParam(),
    page: pageNumber,
    ...getSessionCounterParam(),
  };

  const result = await fetchData(buildUrl(SCAN_ACTION.GET_PAGE, parameters));

  if (result.error) {
    return { error: true };
  }
  const json = await result.response.json();
  return json;
};

export const deleteAllPages = async () => {
  const result = await postData({
    url: buildUrl(
      SCAN_ACTION.DELETE_ALL_PAGES,
      getSessionClientIdAndCounterParam(),
    ),
  });

  if (result.error) {
    return { error: true };
  }

  const json = await result.response.json();

  return parseJsonSuccess(json);
};

export const deletePage = async pageNumber => {
  const result = await postData({
    url: buildUrl(SCAN_ACTION.DELETE_PAGE, {
      ...getSessionClientIdAndCounterParam(),
      page: pageNumber,
    }),
  });

  if (result.error) {
    return { error: true };
  }
  const json = await result.response.json();
  return parseJsonSuccess(json);
};
