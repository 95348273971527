import moment from 'moment-timezone';

import ApiClient, { getTenantId, Method } from './utils/api-client-utils';
import { initReduxStore } from './utils/dynamic-imports/lazy-redux-store';

export const apiClient = new ApiClient({
  microservice: 'permissions',
  useTenant: true,
});

export const headerJSON = { 'Content-Type': 'application/json' };

export const getPracticeUserMeta = async cognitoId => {
  await initReduxStore();
  const tenantId = getTenantId();
  return apiClient.makeRequest({
    method: Method.GET,
    path: `practice-user-meta/${cognitoId}`,
    headers: headerJSON,
    cacheKey: `${tenantId}-${cognitoId}-practice-user-meta`,
    version: 1,
  });
};

export const resetPracticeUserMeta = cognitoId => {
  const lastPasswordChangeAt = moment()
    .utc()
    .format();
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `practice-user-meta/${cognitoId}`,
    headers: headerJSON,
    body: JSON.stringify({
      lastPasswordChangeAt,
    }),
    version: 1,
  });
};
