import '../../../../neb-styles/neb-icons';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_BORDER_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  SERVICE_DATE_FORMAT,
  SERVICE_TIME_FORMAT,
} from '../../../../neb-utils/neb-charting-util';

export const ELEMENTS = {
  headerSignedDate: {
    id: 'header-signed-date',
    text: 'Signed Date',
  },
  headerProvider: {
    id: 'header-provider',
    text: 'Provider',
  },
  headerUpdatedOn: {
    id: 'header-updated-on',
    text: 'Updated On',
  },
  headerUpdatedBy: {
    id: 'header-updated-by',
    text: 'Updated By',
  },
  headerArrow: {
    id: 'header-arrow',
  },
  rows: {
    selector: '.row-item',
  },
  dates: {
    selector: '[date]',
  },
  providers: {
    selector: '[provider]',
  },
  changedOnDate: {
    selector: '[changedondate]',
  },
  changedOnHour: {
    selector: '[changedonhour]',
  },
  updatedNames: {
    selector: '[changedby]',
  },
  icons: {
    selector: '[icon]',
  },
};

class NebEncounterHistoryList extends LitElement {
  static get properties() {
    return {
      models: {
        type: Array,
      },
      selectedId: {
        type: Number,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.models = [];
    this.selectedId = '';

    this.onClickItem = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickItem: ({ currentTarget: { historyId } }) =>
        this.onClickItem(historyId),
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          position: relative;
          height: 100%;
          width: 100%;
          overflow-y: auto;
        }

        .row {
          display: flex;
          border-bottom: ${CSS_BORDER_GREY_2};
          padding: 6px;
        }

        .cell {
          margin: 8px 10px;
          min-width: 0;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .cell-1 {
          flex: 1;
        }

        .cell-header {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          padding-bottom: 6px;
        }

        .fixed-width {
          width: 10px;
        }

        .icon-arrow {
          height: 11px;
          width: 11px;
          color: ${CSS_COLOR_GREY_1};
          transform: rotate(-90deg);
        }

        .row-item {
          cursor: pointer;
        }

        :not(.selected).row-item:hover {
          background-color: ${CSS_COLOR_GREY_4};
        }

        .selected {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_WHITE};
        }

        .selected .icon-arrow {
          fill: ${CSS_COLOR_WHITE};
        }

        .d-inline-block {
          display: inline-block;
        }
      `,
    ];
  }

  __renderHeaders() {
    return html`
      <div class="row">
        <div
          id="${ELEMENTS.headerSignedDate.id}"
          class="cell cell-1 cell-header"
        >
          ${ELEMENTS.headerSignedDate.text}
        </div>
        <div id="${ELEMENTS.headerProvider.id}" class="cell cell-1 cell-header">
          ${ELEMENTS.headerProvider.text}
        </div>
        <div
          id="${ELEMENTS.headerUpdatedOn.id}"
          class="cell cell-1 cell-header"
        >
          ${ELEMENTS.headerUpdatedOn.text}
        </div>
        <div
          id="${ELEMENTS.headerUpdatedBy.id}"
          class="cell cell-1 cell-header"
        >
          ${ELEMENTS.headerUpdatedBy.text}
        </div>
        <div id="${ELEMENTS.headerArrow.id}" class="cell fixed-width"></div>
      </div>
    `;
  }

  __renderContent() {
    return html`
      ${
        this.models.length > 0
          ? this.models.map(
              (item, index) => html`
                <div
                  id="encounter-history-item-${index}"
                  class="row row-item ${
                    this.selectedId === item.id ? 'selected' : ''
                  }"
                  .historyId="${item.id}"
                  @click="${this.__handlers.clickItem}"
                >
                  <div id="signed-date-${index}" class="cell cell-1" date>
                    <div id="signed-date-${index}-date" class="d-inline-block">
                      ${parseDate(item.signedOn).format(SERVICE_DATE_FORMAT)}
                    </div>
                    <div id="signed-date-${index}-hour" class="d-inline-block">
                      ${parseDate(item.signedOn).format(SERVICE_TIME_FORMAT)}
                    </div>
                  </div>
                  <div id="provider-${index}" class="cell cell-1" provider>
                    ${item.providerName}
                  </div>
                  <div id="updated-on-${index}" class="cell cell-1">
                    <div
                      id="updated-on-${index}-date"
                      class="d-inline-block"
                      changedondate
                    >
                      ${
                        item.changedOn &&
                          parseDate(item.changedOn).format(SERVICE_DATE_FORMAT)
                      }
                    </div>
                    <div
                      id="updated-on-${index}-hour"
                      class="d-inline-block"
                      changedonhour
                    >
                      ${
                        item.changedOn &&
                          parseDate(item.changedOn).format(SERVICE_TIME_FORMAT)
                      }
                    </div>
                  </div>
                  <div id="updated-by-${index}" class="cell cell-1" changedby>
                    ${item.changedName}
                  </div>
                  <div id="arrow-${index}" class="cell fixed-width">
                    <neb-icon
                      class="icon-arrow"
                      icon="neb:chevron"
                      icon
                    ></neb-icon>
                  </div>
                </div>
              `,
            )
          : ''
      }
    `;
  }

  render() {
    return html`
      ${this.__renderHeaders()} ${this.__renderContent()}
    `;
  }
}

customElements.define('neb-encounter-history-list', NebEncounterHistoryList);
