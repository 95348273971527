import '@polymer/paper-input/paper-textarea';
import '@polymer/paper-button/paper-button';
import '../../neb-lit-components/src/components/controls/neb-button-action';
import '../../neb-lit-components/src/components/neb-action-bar';
import '../../neb-lit-components/src/components/neb-tooltip';
import './neb-treatment-plan-table';
import './neb-treatment-plans-table';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { ISO_DATE_FORMAT } from '../../neb-input/nebFormatUtils';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { POPOVER_HORIZ_ALIGN } from '../../neb-lit-components/src/components/neb-date-picker';
import { APPOINTMENT_TABLE_TYPES } from '../../neb-lit-components/src/components/overlays/neb-overlay-appointment-history';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../neb-popup/src/renderer-keys';
import { store } from '../../neb-redux/neb-redux-store';
import { manuallyWarn } from '../../neb-route/neb-route-state';
import { renderNebButtonStyles } from '../../neb-styles/neb-button-styles';
import { renderNebFormStyles } from '../../neb-styles/neb-form-styles';
import { renderTypographyStyles } from '../../neb-styles/neb-typography';
import {
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../neb-styles/neb-variables';
import { parseDate } from '../../neb-utils/date-util';

const ID_NEW = 'new';
export const INCOMPLETE_PHASES_TEXT =
  'All phases must be completed before ending the treatment plan.';
export const END_DATE_INVALID_TEXT =
  "The End Date cannot be before a phase's completed date.";
export const START_DATE_INVALID_TEXT =
  "The Start Date cannot be after a phase's Completed Date.";

export const ELEMENTS = {
  actionBar: {
    id: 'action-bar',
  },
  completeButton: {
    id: 'complete-button',
  },
  copyTreatmentPlanButton: {
    id: 'copy-treatment-plan-button',
  },
  endDateContainer: {
    id: 'end-and-complete-container',
  },
  planNoteContainer: {
    id: 'plan-note-container',
  },
  startDateContainer: {
    id: 'start-date-container',
  },
  endDateInput: {
    id: 'end-date-input',
    tag: 'neb-date-picker',
  },
  startDateInput: {
    id: 'start-date-input',
  },
  note: {
    id: 'note',
  },
  appointmentHistoryLink: {
    id: 'show-appointment-history',
  },
  treatmentPlanTable: {
    id: 'table',
    tag: 'neb-treatment-plan-table',
  },
  previousTreatmentPlansTable: {
    id: 'previous-treatment-plans-table',
    tag: 'neb-treatment-plans-table',
  },
  tooltip: {
    id: 'tooltip',
  },
  tooltipCopy: {
    id: 'tooltip-copy',
  },
};

const LABEL_TOOLTIP_COPY =
  'This will place a copy of the patient’s treatment plan data in the chart note for this encounter. This action is only active when there are no unsaved changes on this page.';
const TOOLTIP_TEXT =
  'A treatment plan can only be completed when all phases of a treatment plan have been completed.';
const DELETE_PHASE_ERROR =
  'At least one phase is required for a treatment plan.';
const COPY_TREATMENT_PLAN_LABEL = 'COPY TREATMENT PLAN TO CHART NOTE';
const EMPTY_PHASE = Object.freeze({
  encounterCount: 0,
  scheduledCount: 0,
  completedDate: null,
});

export class NebTreatmentPlanForm extends LitElement {
  static get properties() {
    return {
      __currentActiveTreatmentPlan: {
        type: Object,
      },
      __hideActionBar: {
        type: Boolean,
      },
      __invalidStartDate: {
        type: Boolean,
      },
      __invalidEndDate: {
        type: Boolean,
      },
      __processing: {
        type: Boolean,
      },
      __noPhase: {
        type: Boolean,
        reflect: true,
        attribute: 'no-phase',
      },

      treatmentPlan: {
        type: Object,
      },
      completedTreatmentPlans: {
        type: Array,
      },
      patientId: {
        type: String,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
      copyTreatmentPlanButtonVisible: {
        type: Boolean,
      },
      onDiscard: {
        type: Function,
      },
      enableSaveAndClose: {
        type: Boolean,
        attribute: 'enable-save-and-close',
        reflect: true,
      },
    };
  }

  static createModel() {
    return {
      startDate: parseDate()
        .startOf('day')
        .format(ISO_DATE_FORMAT),
      treatmentPhases: [],
    };
  }

  constructor() {
    super();

    this.__initHandlers();
    this.__initState();
  }

  __initState() {
    this.__hideActionBar = true;
    this.__currentActiveTreatmentPlan = this.constructor.createModel();
    this.__processing = false;

    this.__setNoPhase();

    this.copyTreatmentPlanButtonVisible = false;
    this.treatmentPlan = this.constructor.createModel();
    this.completedTreatmentPlans = [];
    this.enableSaveAndClose = false;

    this.onCopyTreatmentPlan = () => {};

    this.onDiscard = () => {};

    this.onChangeDirty = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      handleSelectStartDate: date => this.__handleSelectStartDate(date),
      handleClearStartDate: () => this.__handleClearStartDate(),
      handleSelectCompletedDate: date =>
        this.__handleSelectedCompletedDate(date),
      handleClearCompletedDate: () => this.__handleClearCompletedDate(),
      handleDeletePhase: index => this.__handleDeletePhase(index),
      handleSelectPhaseCompleteddate: date =>
        this.__handleSelectedPhaseCompletedDate(date),
      handleClearPhaseDate: () => this.__handleClearPhaseDate(),
      handleDiscard: () => this.__handleDiscard(),
      save: () => this.__handleSave({ closeAfterSave: false }),
      saveAndClose: () => this.__handleSave({ closeAfterSave: true }),
      cancel: () => this.__handleCancel(),
      handleCopyPlan: plan => this.__copyPlan(plan),
      copyTreatmentPlanClicked: () =>
        this.onCopyTreatmentPlan(this.__currentActiveTreatmentPlan),
      handleManualDiscard: async () => {
        await this.__handleDiscard();
        if (this.onDiscard) this.onDiscard();
      },
      showAppointmentHistory: () => this.__showAppointments(),
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(
      'neb-dirty-manual-proceed',
      this.__handlers.handleManualDiscard,
    );

    window.addEventListener('neb-dirty-proceed', this.__handlers.handleDiscard);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener(
      'neb-dirty-manual-proceed',
      this.__handlers.handleManualDiscard,
    );

    window.removeEventListener(
      'neb-dirty-proceed',
      this.__handlers.handleDiscard,
    );
  }

  __setPopoverAlign() {
    return POPOVER_HORIZ_ALIGN.LEFT;
  }

  updated(changedProps) {
    if (changedProps.has('treatmentPlan')) {
      this.__applyFormData();
    }

    if (changedProps.has('__currentActiveTreatmentPlan')) {
      this.__setNoPhase();
    }

    if (changedProps.has('__hideActionBar')) {
      this.onChangeDirty(!this.__hideActionBar);
    }
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        height: 100%;
        flex-direction: column;
      }

      [hidden],
      :host([small]) .hide-when-small,
      :host([no-phase]) .hide-when-no-phase {
        display: none;
      }

      :host([small]) .neb-textarea {
        margin-bottom: 0;
      }

      :host([small]) .date-input-label {
        padding: 5px;
        font-weight: bold;
      }

      .complete-plan-container {
        display: flex;
        align-items: center;
      }

      :host([small]) .complete-plan-container {
        padding-top: 5px;
      }

      :host([small]) .end-date-input-spacer {
        width: 53px;
      }

      :host([small]) .start-date-input-spacer {
        width: 46px;
      }

      :host([small]) .date-picker-spacer {
        width: 100%;
      }

      :host([small]) .date-input {
        white-space: normal;
        margin-bottom: 25px;
        width: 175px;
      }

      :host([hide-appointment-history]) .show-appointment-history {
        display: none;
      }

      :host([no-phase]) .hide-when-no-phase {
        display: none;
      }

      :host([no-phase]) .top-container {
        flex-direction: column;
      }

      :host([no-phase]) .button-container {
        flex-direction: column;
        align-items: flex-end;
      }

      :host([small]) .end-and-complete-container,
      .start-date-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: 15px;
        padding-left: ${CSS_SPACING};
      }

      .detail-container {
        flex: 1 0 0;
        overflow-y: auto;
      }

      :host([small]) .detail-container {
        overflow-x: hidden;
      }

      .date-input-label {
        margin-bottom: 7px;
      }

      .end-date-input {
        margin-right: 10px;
      }

      .start-date-container {
        margin: 10px 0 10px 0;
        display: flex;
        flex-direction: column;
      }

      .top-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .button-container {
        display: flex;
        flex-direction: row;
        padding-right: ${CSS_SPACING};
      }

      .show-appointment-history {
        font-weight: normal;
        text-transform: none;
        color: ${CSS_COLOR_HIGHLIGHT};
      }

      .neb-textarea {
        margin-bottom: ${CSS_SPACING};
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 2px;
        padding-right: 2px;
        --iron-autogrow-textarea: {
          height: 92px;
        }
      }

      .previous-header {
        font-weight: bold;
        padding-left: ${CSS_SPACING};
      }

      .date-input {
        --neb-pop-over-left: ${CSS_SPACING};
      }

      .previous-treatment-plan-container {
        margin-bottom: ${CSS_SPACING};
      }

      .plan-note-container {
        margin-top: ${CSS_SPACING};
        padding-left: ${CSS_SPACING};
        padding-right: ${CSS_SPACING};
      }

      .treatment-plan-note-header {
        margin-bottom: -20px;
      }

      .end-and-complete-container {
        display: flex;
        flex-wrap: wrap;
        padding-left: ${CSS_SPACING};
      }

      .date-input-spacer {
        width: 100%;
      }

      .date-picker-spacer {
        width: 0;
      }

      .complete-button {
        margin-right: 10px;
      }

      .container-button {
        display: flex;
        align-items: center;

        margin-bottom: ${CSS_SPACING};
      }

      .copy-treatment-plan-button {
        margin-right: 10px;
      }

      .show-appointment-history:hover,
      .show-appointment-history:focus {
        opacity: 0.65;
        text-decoration: underline;
      }
    `;
  }

  __renderTreatmentPlanHeader() {
    return html`
      <div id="top-container" class="top-container">
        <div
          id="${ELEMENTS.startDateContainer.id}"
          class="start-date-container hide-when-no-phase"
        >
          <div id="start-date-input-label" class="date-input-label">
            Start Date
          </div>

          <div
            id="start-date-input-spacer"
            class="start-date-input-spacer date-input-spacer"
          ></div>

          <neb-date-picker
            id="${ELEMENTS.startDateInput.id}"
            class="date-input"
            .selectedDate="${this.__setStartDate()}"
            .onClick="${this.__handlers.handleSelectStartDate}"
            .onClear="${this.__handlers.handleClearStartDate}"
            .invalidText="${this.__showInvalidStarDateText()}"
            .invalid="${this.__invalidStartDate}"
            .manualPopoverHorizontalAlignment="${this.__setPopoverAlign()}"
            momentFlag
          ></neb-date-picker>
        </div>

        <div class="button-container">
          <neb-button-action
            id="${ELEMENTS.appointmentHistoryLink.id}"
            class="hide-when-small show-appointment-history"
            .onClick="${this.__handlers.showAppointmentHistory}"
            label="Show Appointment History"
            leadingIcon=""
            showUnderline
          >
          </neb-button-action>
        </div>
      </div>
    `;
  }

  __renderEndAndCompleteContainer() {
    return html`
      <div
        id="${ELEMENTS.endDateContainer.id}"
        class="end-and-complete-container hide-when-no-phase"
      >
        <div id="end-date-input-label" class="date-input-label">End Date</div>

        <div
          id="end-date-input-spacer"
          class="end-date-input-spacer date-input-spacer"
        ></div>

        <neb-date-picker
          id="${ELEMENTS.endDateInput.id}"
          class="date-input end-date-input"
          .selectedDate="${this.__setCompleteDate()}"
          .onClick="${this.__handlers.handleSelectCompletedDate}"
          .onClear="${this.__handlers.handleClearCompletedDate}"
          .invalidText="${this.__showInvalidCompletedDateText()}"
          .disabled="${!this.__shouldDisableEndDate()}"
          .invalid="${this.__invalidEndDate}"
          .manualPopoverHorizontalAlignment="${this.__setPopoverAlign()}"
          .customBoundTop="${this.getBoundingClientRect().top}"
          momentFlag
        ></neb-date-picker>

        <div id="date-picker-spacer" class="date-picker-spacer"></div>

        <div class="complete-plan-container">
          ${
            this.__currentActiveTreatmentPlan.id
              ? html`
                  <paper-button
                    id="${ELEMENTS.completeButton.id}"
                    class="neb-button-secondary-focus complete-button"
                    @click="${this.__handleComplete}"
                    >Complete Plan</paper-button
                  >
                  <neb-tooltip
                    id="${ELEMENTS.tooltip.id}"
                    class="tooltip"
                    defaultAnchor="right"
                    .small="${this.small}"
                    ><div id="tooltip-text" slot="tooltip">${TOOLTIP_TEXT}</div>
                  </neb-tooltip>
                `
              : ''
          }
        </div>
      </div>
    `;
  }

  __renderTreatmentPlanNotes() {
    return html`
      <div
        id="${ELEMENTS.planNoteContainer.id}"
        class="plan-note-container hide-when-no-phase"
      >
        <p id="treatment-plan-note-header" class="treatment-plan-note-header">
          Treatment Plan Notes
        </p>

        <paper-textarea
          id="${ELEMENTS.note.id}"
          class="neb-textarea"
          name="note"
          label=""
          always-float-label
          rows="4"
          value="${this.__setNote()}"
          @keyup="${this.__onNoteChanged}"
        >
        </paper-textarea>
        ${
          this.copyTreatmentPlanButtonVisible
            ? html`
                <div class="container-button">
                  <neb-button
                    id="${ELEMENTS.copyTreatmentPlanButton.id}"
                    class="copy-treatment-plan-button"
                    role="${BUTTON_ROLE.OUTLINE}"
                    .label="${COPY_TREATMENT_PLAN_LABEL}"
                    .onClick="${this.__handlers.copyTreatmentPlanClicked}"
                    ?disabled="${
                      !this.__currentActiveTreatmentPlan.treatmentPhases
                        .length ||
                        this.__currentActiveTreatmentPlan.treatmentPhases.some(
                          tp => tp.id === ID_NEW,
                        ) ||
                        !this.__hideActionBar
                    }"
                  ></neb-button>

                  <neb-tooltip
                    id="${ELEMENTS.tooltipCopy.id}"
                    class="tooltip tooltip-copy"
                    defaultAnchor="right"
                    ><div style="width: 320px" slot="tooltip">
                      ${LABEL_TOOLTIP_COPY}
                    </div>
                  </neb-tooltip>
                </div>
              `
            : ''
        }
      </div>
    `;
  }

  __renderPreviousTreatmentPlans() {
    return html`
      <div id="container" class="previous-treatment-plan-container">
        <p id="previous-header" class="previous-header">
          Previous Treatment Plans
        </p>
        <neb-treatment-plans-table
          id="${ELEMENTS.previousTreatmentPlansTable.id}"
          ?small="${this.small}"
          .onCopy="${this.__handlers.handleCopyPlan}"
          .disableCopy="${this.__planExists()}"
          .treatmentPlans="${this.completedTreatmentPlans}"
        ></neb-treatment-plans-table>
      </div>
    `;
  }

  __renderActionBar() {
    return this.enableSaveAndClose
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.saveAndClose}"
            .onRemove="${this.__handlers.cancel}"
            confirmLabel="Save"
            cancelLabel="Save and Close"
            removeLabel="Cancel"
          ></neb-action-bar>
        `
      : html`
          <neb-action-bar
            .processing="${this.__processing}"
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="Save Treatment Plan"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.cancel}"
          ></neb-action-bar>
        `;
  }

  render() {
    return html`
      ${renderNebButtonStyles()} ${renderNebFormStyles()}
      ${renderTypographyStyles()}

      <div id="detail-container" class="detail-container">
        ${this.__renderTreatmentPlanHeader()}

        <neb-treatment-plan-table
          .items="${this.__currentActiveTreatmentPlan.treatmentPhases}"
          id="${ELEMENTS.treatmentPlanTable.id}"
          ?small="${this.small}"
          @neb-add-phase="${this.__handleAddPhase}"
          @neb-save-add-phase="${this.__handleSavePhase}"
          @neb-cancel-add-phase="${e => this.__handleCancelPhase(e)}"
          .onDeletePhaseRow="${this.__handlers.handleDeletePhase}"
          .onClearDate="${this.__handlers.handleClearPhaseDate}"
          .onClickDate="${this.__handlers.handleSelectPhaseCompleteddate}"
          ?hide-total="${this.__noPhase}"
        ></neb-treatment-plan-table>

        ${this.__renderEndAndCompleteContainer()}
        ${this.__renderTreatmentPlanNotes()}
        ${this.__renderPreviousTreatmentPlans()}
      </div>

      ${!this.__hideActionBar ? this.__renderActionBar() : ''}
    `;
  }

  __planExists() {
    return Boolean(
      this.__currentActiveTreatmentPlan.treatmentPhases &&
        this.__currentActiveTreatmentPlan.treatmentPhases.length,
    );
  }

  async __copyPlan(plan) {
    this.__currentActiveTreatmentPlan = this.__cleanPlan(
      plan,
      this.treatmentPlan,
    );

    await this.updateComplete;
    await this.__getTable().updateComplete; // workaround see NEB-8287

    return this.__calculateTP();
  }

  __cleanPlan(plan, treatmentPlan) {
    const newPlan = this.constructor.createModel();

    if (treatmentPlan.id) {
      newPlan.id = treatmentPlan.id;
      newPlan.status = treatmentPlan.status;
    }

    newPlan.treatmentPhases = this.__cleanPhases(plan.treatmentPhases);
    return newPlan;
  }

  __cleanPhases(phases) {
    const newPhases = JSON.parse(JSON.stringify(phases));
    newPhases.forEach(phase => {
      phase = Object.assign(phase, EMPTY_PHASE);
      delete phase.treatmentPlanId;
      delete phase.id;
      phase.tbsCount = phase.appointmentCount;
    });

    return newPhases;
  }

  __onNoteChanged() {
    const noteValue = this.shadowRoot.getElementById('note').value;
    this.__currentActiveTreatmentPlan.note =
      noteValue === '' ? undefined : noteValue;

    this.__isDirty();
  }

  isDirty() {
    return this.__isDirty();
  }

  async __applyFormData(isDiscarded = false) {
    this.__resetFormData();

    if (!isDiscarded) {
      await this.updateComplete;
    }

    this.__currentActiveTreatmentPlan = this.treatmentPlan
      ? JSON.parse(JSON.stringify(this.treatmentPlan))
      : this.constructor.createModel();

    this.requestUpdate();
  }

  __resetFormData() {
    this.__hideActionBar = true;

    const table = this.__getTable();

    table.resetTotals();
    table.resetInvalid();

    this.__resetStartDateValidation();

    this.__resetEndDateValidation();

    this.__currentActiveTreatmentPlan = this.constructor.createModel();
  }

  __getPlanWithCounts() {
    const treatmentPlan = { ...this.__currentActiveTreatmentPlan };

    const table = this.__getTable();

    treatmentPlan.appointmentCount = table.getAppointmentCount();
    treatmentPlan.phaseCount = table.getPhaseCount();
    return treatmentPlan;
  }

  __shouldDisableEndDate() {
    return (
      Boolean(this.__currentActiveTreatmentPlan.completedDate) ||
      this.__allPhasesComplete()
    );
  }

  __allPhasesComplete() {
    let allComplete = true;

    const table = this.__getTable();

    if (!table) return true;
    const { items } = table;
    items.forEach(item => {
      if (!item.completedDate) allComplete = false;
    });

    return allComplete;
  }

  async __handleSave(options = {}) {
    const hasUnsaved = this.__getTable().hasUnsavedPhase();

    const isInvalid = !this.__validateForm();
    if (isInvalid || hasUnsaved) return;
    const response = await this.onSave(this.__getPlanWithCounts(), options);

    if (response) {
      this.__resetFormData();

      this.treatmentPlan = this.constructor.createModel();
    }
  }

  async __handleDeletePhase(index) {
    if (this.__haveOnePhaseRow()) {
      this.onDeletePhaseError(DELETE_PHASE_ERROR);
      return;
    }

    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      cancelText: 'NO',
      confirmText: 'YES',
      message: 'Are you sure you want to delete this treatment plan phase?',
      title: 'Remove Treatment Plan Phase',
    });

    if (result) {
      this.__getTable().deletePhase(index);

      this.__calculateTP();
    }
  }

  __haveOnePhaseRow() {
    let phaseRow = 0;

    this.__getTable().items.forEach(item => {
      phaseRow += item.id !== 'new';
    });

    return phaseRow === 1;
  }

  async __calculateTP() {
    this.__processing = true;
    await this.updatecomplete;

    const dirty = this.__isDirty();

    if (this.__validateForm() && dirty) {
      this.__currentActiveTreatmentPlan = await this.onCalculate(
        this.__getPlanWithCounts(),
      );

      this.__isDirty();
    }

    this.__processing = false;
    await this.updatecomplete;
  }

  __handleClearPhaseDate() {
    this.__calculateTP();
  }

  __setNote() {
    return this.__currentActiveTreatmentPlan.note || '';
  }

  __setStartDate() {
    return this.__currentActiveTreatmentPlan.startDate
      ? parseDate(this.__currentActiveTreatmentPlan.startDate)
      : null;
  }

  __showInvalidStarDateText() {
    return this.__currentActiveTreatmentPlan.startDate
      ? START_DATE_INVALID_TEXT
      : 'Required';
  }

  __showInvalidCompletedDateText() {
    const endDate = this.__currentActiveTreatmentPlan.completedDate;

    if (this.__allPhasesComplete()) {
      return endDate ? END_DATE_INVALID_TEXT : 'Required';
    }
    return INCOMPLETE_PHASES_TEXT;
  }

  __setCompleteDate() {
    if (this.__currentActiveTreatmentPlan.completedDate) {
      return parseDate(this.__currentActiveTreatmentPlan.completedDate);
    }

    return null;
  }

  __handleSelectStartDate(date) {
    this.__currentActiveTreatmentPlan.startDate = parseDate(date).format(
      ISO_DATE_FORMAT,
    );

    this.__calculateTP();
  }

  __validateStartDate() {
    const { treatmentPhases, startDate } = this.__currentActiveTreatmentPlan;

    this.__resetStartDateValidation();

    if (!startDate) {
      this.__invalidStartDate = true;
    } else {
      treatmentPhases.forEach(phase => {
        const isValid = parseDate(startDate).isBefore(
          parseDate(phase.completedDate),
        );

        if (phase.completedDate && !isValid) {
          this.__invalidStartDate = true;
        }
      });
    }

    return !this.__invalidStartDate;
  }

  __resetStartDateValidation() {
    this.__invalidStartDate = false;
  }

  __resetEndDateValidation() {
    this.__invalidEndDate = false;
  }

  __handleClearStartDate() {
    this.__currentActiveTreatmentPlan.startDate = null;

    this.__validateForm();

    this.__isDirty();
  }

  __handleSelectedCompletedDate(date) {
    this.__currentActiveTreatmentPlan.completedDate = parseDate(date).format(
      ISO_DATE_FORMAT,
    );

    this.__validatePhaseDate();

    this.__validateCompletedDate();

    this.__isDirty();
  }

  __validateCompletedDate(completeTP) {
    const hasConflict = this.__getTable().hasEndDateConflict();

    const phasesComplete = this.__allPhasesComplete();

    const emptyPhaseError = Boolean(
      this.__currentActiveTreatmentPlan.completedDate && !phasesComplete,
    );
    this.__invalidEndDate = hasConflict || emptyPhaseError;
    this.__invalidEndDate = completeTP
      ? !this.__currentActiveTreatmentPlan.completedDate
      : this.__invalidEndDate;

    this.requestUpdate();
    return !this.__invalidEndDate;
  }

  __handleClearCompletedDate() {
    this.__currentActiveTreatmentPlan.completedDate = null;

    this.__calculateTP();
  }

  __handleSelectedPhaseCompletedDate() {
    this.__calculateTP();
  }

  __handleCancel() {
    if (this.__isDirty()) {
      store.dispatch(manuallyWarn());
    } else {
      this.__hideActionBar = true;
    }
  }

  __handleAddPhase(e) {
    e.stopPropagation();
    this.__currentActiveTreatmentPlan.treatmentPhases = this.__getTable().items;
    this.__hideActionBar = false;

    this.__setNoPhase();
  }

  __handleSavePhase(e) {
    e.stopPropagation();

    this.__currentActiveTreatmentPlan.treatmentPhases = this.__getTable().items;

    this.__calculateTP();
  }

  __getTable() {
    return this.shadowRoot.getElementById('table');
  }

  __handleCancelPhase(e) {
    e.stopPropagation();

    if (this.__currentActiveTreatmentPlan.treatmentPhases.length === 0) {
      this.shadowRoot.getElementById('note').value = '';

      this.__onNoteChanged();
    }

    this.__setNoPhase();

    this.__isDirty();
  }

  async __handleDiscard() {
    this.__applyFormData(true);

    await this.updateComplete;
  }

  __isDirty() {
    const isDirty =
      JSON.stringify(this.__currentActiveTreatmentPlan) !==
      JSON.stringify(this.treatmentPlan);

    this.__hideActionBar = !isDirty;

    return isDirty;
  }

  async __handleComplete() {
    if (!this.__validateForm(true)) return;
    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      cancelText: 'NO',
      confirmText: 'YES',
      message: html`
        Marking the current treatment plan as completed will move this plan to
        the Previous Treatment Plans section and allow you to create a new plan
        for the patient.<br />
        <br />
        Are you sure you want to complete the patient's treatment plan?
      `,
      title: 'Complete Treatment Plan',
    });

    if (result) {
      this.onComplete(this.__getPlanWithCounts());
    }
  }

  __validateForm(isCompleteTP) {
    // change later for all validations;
    const validStartDate = this.__validateStartDate();

    const validPhasesDate = this.__validatePhaseDate(isCompleteTP);

    const validEndDate = this.__validateCompletedDate(isCompleteTP);

    return validStartDate && validPhasesDate && validEndDate;
  }

  __validatePhaseDate(isCompleteTP) {
    return this.__getTable().validatePhases(
      this.__currentActiveTreatmentPlan.startDate,
      this.__currentActiveTreatmentPlan.completedDate,
      isCompleteTP,
    );
  }

  async __showAppointments() {
    await openOverlay(OVERLAY_KEYS.APPOINTMENT_HISTORY, {
      patientId: this.patientId,
      filter: APPOINTMENT_TABLE_TYPES.ALL,
    });
  }

  __setNoPhase() {
    this.__noPhase =
      this.__currentActiveTreatmentPlan.treatmentPhases.length === 0;
  }
}

customElements.define('neb-treatment-plan-form', NebTreatmentPlanForm);
