import {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const apiClient = new ApiClientV2({
  microservice: 'billing',
  useTenant: true,
});

export const post = data =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/eob',
    method: Method.POST,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
  });

export const updateEob = (eobId, data) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/eob/:eobId',
    method: Method.PUT,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    replacements: { eobId },
    responseType: RESPONSE_TYPE.RAW,
  });

export const getEob = async eobId => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/eob/:eobId',
    queryParams: { eobId },
    replacements: { eobId },
  });

  return res;
};

export const updateStatus = (eobId, status) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/eob/:eobId/:status',
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    replacements: { eobId, status },
  });

export const bulkUpdateStatus = data =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: '/api/v1/tenants/:tenantId/eob',
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    body: JSON.stringify(data),
  });

export const associateCharges = ({ version = 1, eobId, lineItemIds }) => {
  const path =
    version === 1
      ? '/api/v1/tenants/:tenantId/eob/:eobId/charges'
      : '/api/v2/tenants/:tenantId/eob/:eobId/charges';
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    body: JSON.stringify({ lineItemIds }),
    replacements: { eobId },
  });
};

export const associatePayments = async (
  eobId,
  paymentIds,
  optOutLoadingIndicator = false,
) => {
  await apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/eob/:eobId/payments',
    method: Method.POST,
    body: JSON.stringify({ paymentIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    replacements: { eobId },
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const removeEobCharges = async ({
  version = 1,
  eobId,
  chargeIds,
  optOutLoadingIndicator = false,
  claimStatus,
}) => {
  const rawObject = { chargeIds, ...(claimStatus ? { claimStatus } : {}) };
  const path =
    version === 1
      ? '/api/v1/tenants/:tenantId/eob/:eobId/charges'
      : '/api/v2/tenants/:tenantId/eob/:eobId/charges';
  await apiClient.makeRequest({
    path,
    method: Method.DELETE,
    body: JSON.stringify(rawObject),
    headers: {
      'Content-Type': 'application/json',
    },
    replacements: { eobId },
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const unlinkPayment = async (
  eobId,
  paymentId,
  optOutLoadingIndicator = false,
) => {
  await apiClient.makeRequest({
    path: '/api/v1/tenants/:tenantId/eob/:eobId/payments',
    method: Method.DELETE,
    body: JSON.stringify({ paymentId }),
    headers: {
      'Content-Type': 'application/json',
    },
    replacements: { eobId },
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const createSecondaryClaims = async ({
  eobId,
  lineItemIds,
  optOutLoadingIndicator = false,
  version = 1,
}) => {
  const path =
    version === 1
      ? '/api/v1/tenants/:tenantId/eob/:eobId/secondary-claims'
      : '/api/v2/tenants/:tenantId/eob/:eobId/secondary-claims';
  const result = await apiClient.makeRequest({
    path,
    method: Method.POST,
    body: JSON.stringify({ lineItemIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    replacements: { eobId },
    optOutLoadingIndicator,
  });
  return result;
};

export const getEobSecondaryClaimDraftsToBeCreated = async (
  eobId,
  lineItemIds,
  optOutLoadingIndicator = false,
) => {
  const result = await apiClient.makeRequest({
    path:
      '/api/v1/tenants/:tenantId/eob/:eobId/secondary-claims-to-create-total',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ lineItemIds }),
    replacements: { eobId },
    optOutLoadingIndicator,
  });
  return result;
};

export const getChargesAssociatedToEob = async eobId => {
  const path = '/api/v1/tenants/:tenantId/eob/:eobId/charges';
  const result = await apiClient.makeRequest({
    path,
    method: Method.GET,
    replacements: { eobId },
    optOutLoadingIndicator: false,
    headers: { 'Content-Type': 'application/json' },
  });
  return result && result.map(({ lineItemId }) => lineItemId);
};
