import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../../../../../src/components/misc/neb-icon';
import '../neb-button-actions';
import './neb-table-ledger-charge-expanded';

import { css, html } from 'lit';

import {
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
  CSS_COLOR_GREY_3,
} from '../../../../neb-styles/neb-variables';
import { MODE } from '../../../../neb-utils/table';
import { ELEMENTS as NEB_TEXT_ELEMENTS } from '../neb-text';

import NebTable from './neb-table';

export const ELEMENTS = {
  subHeader: { id: 'sub-header' },
  checkboxes: {
    selector: 'neb-checkbox',
  },
  bulkActionMenu: {
    id: 'bulk-action-menu',
  },
  patientLinks: {
    selector: '[id^=patient-]',
  },
  primaryPayerLinks: {
    selector: '[id^=primary-payer-]',
  },
  planLinks: {
    selector: '[id^=plan-]',
  },
  secondaryPayerLinks: {
    selector: '[id^=secondary-payer-]',
  },
  carePackageLinks: {
    selector: '[id^=care-package-]',
  },
  invoiceLinks: {
    selector: '[id^=invoice-]',
  },
  billingNotesIcons: {
    selector: '[id^=notes-icon-]',
  },
  caseLinks: {
    selector: '[id^=case-]',
  },
  expandedTables: {
    selector: 'neb-table-ledger-charge-expanded',
  },
};

class NebTableLedgerCharges extends NebTable {
  static get properties() {
    return {
      innerTableConfig: Array,
      changedPage: Boolean,
      showERAsAndEOBsAssociations: { type: Boolean },
      matchCharge: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .row-data[expanded] {
          background-color: ${CSS_COLOR_GREY_4};
        }

        .cell-checkbox {
          padding: 0 5px;
        }

        .ellipsis {
          padding-top: 1px;
          min-width: 0;
          width: 28px;
          font-weight: normal;
        }

        .cell[truncate] {
          display: flex;
        }

        .cell-header {
          padding-bottom: 10px;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .patient-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
        }

        .table-ledger {
          width: 100%;
        }

        .link:focus,
        .link:hover {
          opacity: 0.65;
        }

        .link-cell {
          display: inline-block;
          white-space: normal;
          overflow-wrap: break-word;
        }

        .cell-invoice {
          flex: 0 0 65px;
        }

        .sub-header {
          display: flex;
          padding: 18px ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
        }

        .sub-header-cell {
          display: flex;
          width: 0;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          white-space: nowrap;
          word-break: break-all;
        }

        .sub-header-cell:last-child {
          margin-right: 0;
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .sub-header-cell-spacer {
          max-width: 28px;
          margin-right: ${CSS_SPACING};
        }

        .sub-header-trailing-spacer {
          width: 12px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.mode = MODE.EXPAND;
    this.writable = false;
    this.innerTableConfig = [];
    this.changedPage = false;
    this.showERAsAndEOBsAssociations = false;
    this.matchCharge = false;

    this.onSelectExpandedRow = () => {};

    this.onBulkActionClick = () => {};

    this.onSelectCase = () => {};

    this.onSelectPatient = () => {};

    this.onSelectPayer = () => {};

    this.onSelectPlan = () => {};

    this.onSelectInvoice = () => {};

    this.onSelectInvoiceBillingNotesIcon = () => {};

    this.onSelectEncounter = () => {};

    this.onSelectPackage = () => {};

    this.onSelectClaim = () => {};

    this.setYOffset = () => {};

    this.resetChangedPage = () => {};

    this.onClickChargeNumber = () => {};

    this.onOpenPatientPackagesSummary = () => {};

    this.onClickERAsEOBs = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      clickCheckbox: e => e.stopPropagation(),

      clickCase: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { patientId, patientCaseId } = this.model[rowIndex];
        return this.onSelectCase({ patientId, patientCaseId });
      },

      clickPatient: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const { patientId } = this.model[rowIndex];
        return this.onSelectPatient({ patientId });
      },

      clickPrimaryPayer: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { primaryPayerId } = this.model[rowIndex];
        return this.onSelectPayer({ payerId: primaryPayerId });
      },

      clickSecondaryPayer: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { secondaryPayerId } = this.model[rowIndex];
        return this.onSelectPayer({ payerId: secondaryPayerId });
      },

      clickPrimaryPlan: ({ name }) => {
        const rowIndex = name.split('.')[1];
        const { patientId, primaryInsuranceId } = this.model[rowIndex];

        return this.onSelectPlan({ patientId, primaryInsuranceId });
      },

      clickInvoice: ({ id, name }) => {
        const rowIndex = name.split('.')[1];

        if (id.indexOf(NEB_TEXT_ELEMENTS.trailingIcon.id) === 0) {
          return this.onSelectInvoiceBillingNotesIcon(this.model[rowIndex]);
        }

        const { patientId } = this.model[rowIndex];

        return this.onSelectInvoice({ patientId, item: this.model[rowIndex] });
      },
      clickCarePackage: ({ name }) => {
        const rowIndex = name.split('.')[1];

        const { multiCarePackage, patientId, patientPackageId } = this.model[
          rowIndex
        ];

        if (multiCarePackage) {
          return this.onOpenPatientPackagesSummary({
            patientId,
            item: this.model[rowIndex],
          });
        }

        return this.onSelectPackage({
          patientId,
          patientPackageId,
        });
      },
    };
  }

  updated(changedProps) {
    if (this.changedPage) {
      const height = this.shadowRoot.host.scrollHeight;
      this.setYOffset(height);
      this.resetChangedPage();
    }

    super.updated(changedProps);
  }

  renderExpandedRow(index, row) {
    return html`
      <neb-table-ledger-charge-expanded
        class="table-ledger"
        .name="${index}.lineItems"
        .config="${this.innerTableConfig}"
        .model="${row.lineItems}"
        .layout="${this.layout}"
        .onSelectRow="${this.onSelectExpandedRow}"
        .onChange="${this.handlers.change}"
        .onSelectEncounter="${this.onSelectEncounter}"
        .onSelectClaim="${this.onSelectClaim}"
        .onClickChargeNumber="${this.onClickChargeNumber}"
        ?showERAsAndEOBsAssociations="${this.showERAsAndEOBsAssociations}"
        .onClickERAsEOBs="${this.onClickERAsEOBs}"
        .matchCharge="${this.matchCharge}"
      ></neb-table-ledger-charge-expanded>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'checked':
        return this.__renderCheckboxCell({ value, rowIndex });

      case 'patient':
        return this.__renderPatientCell({ value, rowIndex });

      case 'primaryPayer':
        return this.model[rowIndex].primaryPayerId
          ? this.__renderLinkCell({
              value,
              rowIndex,
              onClick: this.handlers.clickPrimaryPayer,
              id: 'primary-payer',
            })
          : super.renderDataCell(value, columnConfig, rowIndex);

      case 'plan':
        return this.__renderLinkCell({
          value,
          rowIndex,
          onClick: this.handlers.clickPrimaryPlan,
          id: 'plan',
        });

      case 'secondaryPayer':
        return this.__renderLinkCell({
          value,
          rowIndex,
          onClick: this.handlers.clickSecondaryPayer,
          id: 'secondary-payer',
        });

      case 'carePackage':
        return this.__renderCheckMarkLink({
          rowIndex,
          onClick: this.handlers.clickCarePackage,
          id: 'care-package',
        });

      case 'invoiceNumber':
        return this.__renderLinkCell({
          value,
          rowIndex,
          onClick: this.handlers.clickInvoice,
          id: 'invoice',
        });

      case 'case':
        return this.__renderLinkCell({
          value,
          rowIndex,
          onClick: this.handlers.clickCase,
          id: 'case',
        });

      default:
        return value;
    }
  }

  renderSubHeader() {
    if (this.config && this.body) {
      const cells = this.config.slice(1).map(cell => {
        const value = cell.formatter ? cell.formatter(this.body[cell.key]) : '';

        return html`
          <span class="sub-header-cell text-bold" style="flex: ${cell.flex}"
            >${value}</span
          >
        `;
      });

      return html`
        <div id="${ELEMENTS.subHeader.id}" class="sub-header">
          <div class="sub-header-cell-spacer text-bold">Totals</div>
          ${cells}
          <div class="sub-header-trailing-spacer"></div>
        </div>
      `;
    }

    return '';
  }

  renderHeaderCell({ key, label }) {
    if (key === 'checked') {
      return html`
        <neb-button-actions
          id="${ELEMENTS.bulkActionMenu.id}"
          class="ellipsis"
          align="left"
          maxVisibleItems="5"
          ?disabled="${!this.model.length}"
          .onClick="${this.onBulkActionClick}"
          .forceDownMenu="${true}"
        ></neb-button-actions>
      `;
    }

    return label;
  }

  __renderCheckboxCell({ value, rowIndex }) {
    return html`
      <neb-checkbox
        class="cell-checkbox"
        .name="${rowIndex}"
        .onChange="${this.handlers.change}"
        ?checked="${value}"
        @click="${this.handlers.clickCheckbox}"
      ></neb-checkbox>
    `;
  }

  __renderLinkCell({ value, rowIndex, onClick, id }) {
    return html`
      <neb-text
        id="${id}-${rowIndex}"
        bold
        link
        ?italic="${value === 'Not Invoiced'}"
        .name="rollUps.${rowIndex}"
        .onClick="${onClick}"
        class="link-cell cell-${id}"
        .trailingIcon="${this.__renderBillingNoteIcon({ rowIndex, id })}"
        >${value}</neb-text
      >
    `;
  }

  __renderBillingNoteIcon({ rowIndex, id }) {
    if (id !== 'invoice') return '';

    const { hasInvoiceBillingNote } = this.model[rowIndex];

    return hasInvoiceBillingNote ? 'neb:editNote' : '';
  }

  __renderPatientCell({ value, rowIndex }) {
    return html`
      <div
        id="patient-link-${rowIndex}"
        class="button link"
        index="${rowIndex}"
        @click="${this.handlers.clickPatient}"
      >
        <span class="patient-text">${value}</span>
        <neb-icon class="icon" icon="neb:open"></neb-icon>
      </div>
    `;
  }

  __renderCheckMarkLink({ rowIndex, onClick, id }) {
    return this.model[rowIndex].isCoveredByPackage
      ? html`
          <neb-text
            id="${id}-${rowIndex}"
            bold
            link
            .name="rollUps.${rowIndex}"
            .onClick="${onClick}"
            class="link-cell"
            >✓</neb-text
          >
        `
      : '';
  }
}

customElements.define('neb-table-ledger-charges', NebTableLedgerCharges);
