import '../../../packages/neb-lit-components/src/components/neb-text';
import './neb-icon';

import { html, LitElement, css } from 'lit';

import {
  CSS_MESSAGE_COLUMN_WIDTH,
  CSS_TYPE_COLUMN_WIDTH,
} from '../../../packages/neb-alert/components/neb-alert-table-styles';
import { renderNebTableStyles } from '../../../packages/neb-styles/neb-table-styles';
import {
  CSS_COLOR_ERROR,
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  CSS_FONT_FAMILY,
  CSS_BANNER_INFO_BORDER_COLOR,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_4,
} from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { formatOccurrenceText } from '../../utils/occurrence-alert-text';

export const ELEMENTS = {
  message: {
    id: 'message',
  },
  type: {
    id: 'type',
  },
  editLink: {
    id: 'edit-link',
  },
  deleteLink: {
    id: 'delete-link',
  },
  dragHandle: {
    id: 'icon',
  },
  alertOns: {
    id: 'alert-ons',
  },
  toggle: {
    id: 'toggle',
  },
  occurrenceText: {
    id: 'occurrence-text',
  },
  occurrenceContainer: {
    id: 'occurrence-container',
  },
  alertOnsContainer: {
    id: 'alert-ons-container',
  },
  createdMessage: {
    id: 'created-message',
  },
  updatedMessage: {
    id: 'updated-message',
  },
  createdUpdatedContainer: {
    id: 'created-updated-container',
  },
  messageContainer: {
    id: 'message-container',
  },
  linkContainer: {
    id: 'link-container',
  },
};

const CHECKBOX_LABELS = {
  scheduling: 'Scheduling',
  checkIn: 'Check In',
  checkOut: 'Check Out',
  charting: 'Charting',
  patientRecord: 'Patient Record',
  ledger: 'Ledger',
  payment: 'Payment',
};

class NebViewAlert extends LitElement {
  static get properties() {
    return {
      model: { type: Object },
      index: {
        type: Number,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
      isEditing: {
        type: Boolean,
      },
      __collapse: {
        type: Boolean,
        reflect: true,
        attribute: 'collapse',
      },
      __isAlert: {
        type: Boolean,
        reflect: true,
        attribute: 'is-alert',
      },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: flex;
          flex-direction: row;
        }

        :host([small]) {
          background-color: ${CSS_COLOR_GREY_4};
        }

        :host([collapse][small]) {
          background-color: white;
        }

        .textarea-container {
          display: flex;
          width: 100%;
          flex-direction: column;
          padding-left: 2.5%;
        }

        .cheveron-column {
          padding-left: 5px;
          min-width: 7px;
        }

        .checkbox-container {
          display: flex;
          flex-wrap: wrap;
        }

        .checkbox-container > * {
          flex: 1 1 150px;
        }

        :host([small]) .checkbox-container > * {
          flex: none;
        }

        .invalid {
          color: ${CSS_COLOR_ERROR};
          font-size: 11px;
        }

        .edit-delete-link {
          display: flex;
          gap: ${CSS_SPACING};
          padding-top: 10px;
        }

        .draggable {
          cursor: ns-resize;
        }

        .message-input {
          border: ${CSS_BORDER_GREY_2};
          resize: none;
          min-height: 165px;
          width: 95%;
          border-radius: 5px;
        }
        :host([small]) .message {
          word-break: break-word;
        }

        .alert-on-label {
          padding: 5px;
        }

        :host([is-alert]) .message {
          color: ${CSS_COLOR_ERROR};
        }
        :host([small]) .message-container {
          flex: 3;
        }

        :host([collapse][small]) .message-container {
          height: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        :host(:not([collapse])) .message {
          white-space: pre-wrap;
        }

        :host([is-alert]) .type {
          color: ${CSS_COLOR_ERROR};
        }
        textarea {
          box-sizing: border-box;
          padding-left: 10px;
          font-size: 16px;
          font-family: ${CSS_FONT_FAMILY};
        }
        textarea:focus {
          outline: none;
          border-color: ${CSS_BANNER_INFO_BORDER_COLOR};
        }

        iron-icon {
          margin-top: 2px;
          color: ${CSS_COLOR_HIGHLIGHT};
          height: 15px;
        }

        :host([invalid-msg]) #message-input {
          border-color: ${CSS_COLOR_ERROR};
        }

        [hidden] {
          display: none;
        }

        .icon {
          width: 18px;
          height: 18px;
          cursor: pointer;

          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-chevron {
          transform: rotate(180deg);
        }

        :host([collapse]) .icon-chevron {
          transform: rotate(0deg);
        }

        .neb-table-cell {
          word-break: normal;
        }

        .neb-table-small-width {
          min-width: 70px;
        }

        .radio-button-container {
          display: flex;
          align-items: center;
          width: 100%;
          white-space: nowrap;
        }

        .display-alert-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0 20px;
        }

        .type-message-container {
          display: flex;
        }

        .config-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .days-neb-textfield {
          width: 50px;
          min-width: 50px;
        }

        .checkbox-group-container {
          width: 90%;
          margin-bottom: 20px;
        }

        .created-updated-times {
          padding-top: 20px;
        }

        @media (min-width: 1200px) {
          .display-alert-container {
            flex-direction: row-reverse;
          }

          .checkbox-group-container {
            width: 30%;
          }

          .radio-button-group-container {
            width: 70%;
            margin-right: 20px;
          }
        }

        .display-alert-header {
          font-weight: bold;
        }

        :host([small]) .created-by-message {
          padding-bottom: 5px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initProperty();
    this.__initHandler();
  }

  __initProperty() {
    this.type = '';
    this.small = false;
    this.__isAlert = true;
    this.__collapse = false;
    this.isEditing = false;

    this.onEdit = () => {};

    this.onDelete = () => {};
  }

  updated(changed) {
    if (changed.has('small')) {
      this.__collapse = this.small;
    }

    if (changed.has('model')) {
      this.__isAlert = this.model.alert;
    }
  }

  __initHandler() {
    this.__handlers = {
      onEdit: () => {
        this.onEdit(this.model.id);
      },
      onDelete: () => {
        this.onDelete({
          id: this.model.id,
          alert: this.model.alert,
        });
      },
      onToggle: () => {
        this.__collapse = !this.__collapse;
      },
    };
  }

  __renderType() {
    return html`
      <div
        id="${ELEMENTS.type.id}"
        class="type neb-table-cell ${CSS_TYPE_COLUMN_WIDTH}"
        @click="${this.__handlers.onToggle}"
      >
        ${this.model.alert ? 'Alert' : 'Note'}
      </div>
    `;
  }

  __getAlertOn() {
    const alertOns = [];
    Object.keys(CHECKBOX_LABELS).forEach(label => {
      const alertOn = this.model[label];
      if (alertOn) alertOns.push(CHECKBOX_LABELS[label]);
    });

    return alertOns.join(', ');
  }

  __renderAlertOn() {
    return this.model.alert
      ? html`
          <div
            id="${ELEMENTS.alertOnsContainer.id}"
            ?hidden="${this.__collapse && this.small}"
          >
            <p></p>
            <div class="display-alert-header">Display Alert On:</div>
            <div
              id="${ELEMENTS.alertOns.id}"
              ?hidden="${this.__collapse && this.small}"
            >
              ${this.__getAlertOn()}
            </div>
          </div>
        `
      : '';
  }

  __renderTimes() {
    const createdAtMessage = this.model.createdByUserName
      ? `Created by ${this.model.createdByUserName} on`
      : 'Created on';

    const editedAtMessage = this.model.editedByUserName
      ? `Last edited by ${this.model.editedByUserName} on`
      : 'Last edited on';

    const createdAtTime = parseDate(this.model.createdAt).format(
      'dddd, MMMM D, YYYY [at] h:mm A',
    );

    const editedAtTime = this.model.editedAt
      ? parseDate(this.model.editedAt).format('dddd, MMMM D, YYYY [at] h:mm A')
      : null;

    return html`
      <div
        class="created-updated-times"
        id="${ELEMENTS.createdUpdatedContainer.id}"
        ?hidden="${this.__collapse && this.small}"
      >
        <div id="${ELEMENTS.createdMessage.id}" class="created-by-message">
          ${createdAtMessage} ${createdAtTime}
        </div>
        <div id="${ELEMENTS.updatedMessage.id}">
          ${editedAtTime ? editedAtMessage.concat(` ${editedAtTime}`) : ''}
        </div>
      </div>
    `;
  }

  __renderMessage() {
    return html`
    <div id="message-container"
      id="${ELEMENTS.messageContainer.id}"
      class="message-container neb-table-cell ${CSS_MESSAGE_COLUMN_WIDTH}"
      @click="${this.__handlers.onToggle}">
      <span id="${ELEMENTS.message.id}" class="message">${
      this.model.message
    }</span>
        ${this.__renderDisplayAlert()}
        ${this.__renderAlertOn()}
        ${this.__renderTimes()}
      <div
        class="edit-delete-link"
        id="${ELEMENTS.linkContainer.id}"
        ?hidden="${this.__collapse && this.small}"
      >
        ${
          !this.isEditing
            ? html`
                <neb-text
                  id="${ELEMENTS.editLink.id}"
                  link
                  .onClick="${this.__handlers.onEdit}"
                  >Edit
                </neb-text>

                <neb-text
                  id="${ELEMENTS.deleteLink.id}"
                  link
                  .onClick="${this.__handlers.onDelete}"
                  >Delete
                </neb-text>
              `
            : ''
        }
        </div>
      </div>
    </div>
    ${
      this.small
        ? html`
            <neb-icon
              id="${ELEMENTS.toggle.id}"
              class="neb-table-cell fixed-cell icon-chevron icon"
              icon="neb:chevron"
              @click="${this.__handlers.onToggle}"
            ></neb-icon>
          `
        : ''
    }
  `;
  }

  __renderReorderIcon() {
    return !this.isEditing
      ? html`
          <neb-icon
            reorder
            id="${ELEMENTS.dragHandle.id}"
            class="neb-table-cell fixed-cell draggable icon"
            icon="neb:reorder"
          ></neb-icon>
        `
      : '';
  }

  __renderDisplayAlert() {
    if (!this.model.alert) {
      return '';
    }

    const text = formatOccurrenceText({
      type: this.model.type,
      initialVisitDate: this.model.initialVisitDate
        ? this.model.initialVisitDate
        : this.model.createdAt,
      numOfDays: this.model.numOfDays,
      numOfVisits: this.model.numOfVisits,
    });

    return html`
      <div
        id="${ELEMENTS.occurrenceContainer.id}"
        ?hidden="${this.__collapse && this.small}"
      >
        <p></p>
        <div class="display-alert-header">Display Alert:</div>
        <div
          id="${ELEMENTS.occurrenceText.id}"
          ?hidden="${this.__collapse && this.small}"
        >
          ${text}
        </div>
      </div>
    `;
  }

  render() {
    return html`
      ${renderNebTableStyles()} ${this.__renderReorderIcon()}
      ${this.__renderType()} ${this.__renderMessage()}
    `;
  }
}

customElements.define('neb-view-alert', NebViewAlert);
