import '@polymer/polymer/polymer-legacy';
import './neb-variables';

import { html } from 'lit';

const _documentContainer = document.createElement('div');

_documentContainer.setAttribute('style', 'display: none;');

export const nebFormStyles = () => html`
  <style>
    .neb-form-field-border {
      @apply --neb-form-field-border-base;
    }

    .neb-form-field {
      --neb-input-input-font-size: 14px;
      /* Workaround for Edge browser */
      font-weight: normal !important;

      --paper-input-container-underline: {
        display: none;
      }
      --paper-input-container-underline-focus: {
        display: none;
      }
      --paper-input-container-color {
        color: var(--neb-color-dark-primary);
      }
      --paper-input-container-input: {
        @apply --neb-input-input;
        padding: var(--neb-input-container-input-padding, 0);
        cursor: var(--neb-input-container-input-cursor);
        font-size: var(--neb-input-input-font-size);
        /* Workaround for Edge browser */
        font-weight: normal !important;
      }
      --paper-input-container-label-floating: {
        @apply --neb-input-label-floating;
        font-weight: var(--neb-input-label-floating-font-wieght);
        font-size: var(
          --neb-input-label-floating-font-size,
          CSS_FONT_SIZE_BODY
        );
      }
      --paper-input-container-label: {
        @apply --neb-input-label;
        /* Workaround for Edge browser */
        font-weight: normal !important;
      }
    }
    .neb-textarea {
      outline: none;
      /* Workaround for Edge browser */
      font-weight: normal !important;

      --paper-input-container: {
        --error-color: var(--neb-font-color-primary);
      }

      --paper-input-container-underline: {
        display: none;
      }
      --paper-input-container-underline-focus: {
        display: none;
      }
      --paper-input-container-input: {
        @apply --neb-form-field-border-base;
        font-family: var(--neb-font-family);
        text-indent: var(--neb-text-indent-default);
        font-size: var(--neb-textarea-input-font-size);
        /* Workaround for Edge browser */
        font-weight: normal !important;
      }
      --paper-input-container-label-floating: {
        @apply --neb-input-label-floating;
        font-size: var(
          --neb-input-label-floating-font-size,
          CSS_FONT_SIZE_BODY
        );
      }
      --paper-input-container-label: {
        @apply --neb-input-label;
        /* Workaround for Edge browser */
        font-weight: normal !important;
      }
      --iron-autogrow-textarea: {
        padding-top: 10px;
        padding-left: -2px;
        box-shadow: none;
      }
      --paper-input-error: {
        margin-top: -5px;
        font-size: 11px;
        --paper-input-container-invalid-color: var(--neb-color-error);
      }
    }

    .neb-textarea[focused] {
      --paper-input-container-focus-color: var(--neb-color-dark-primary);
      --paper-input-container-input: {
        @apply --neb-form-field-focused;
      }
    }

    .neb-checkbox {
      line-height: 1.91;
      color: var(--neb-color-primary-1);
      --paper-checkbox-label-checked-color: var(--neb-font-color-highlight);
      --paper-checkbox-unchecked-color: var(--neb-color-grey-1);
      --paper-checkbox-checked-color: var(--neb-color-highlight);
    }

    .neb-radio-wizard {
      @apply --neb-font-header;
      @apply --neb-radio;
    }

    .neb-radio-form {
      @apply --neb-font-size-body;
      @apply --neb-radio;
    }

    .neb-radio-regular {
      @apply --neb-font-size-body;
      @apply --neb-radio;
      font-weight: normal;
    }

    .neb-dropdown {
      max-height: 40%;
      @apply --shadow-elevation-3dp;
    }

    /* Workaround for Edge browser */
    paper-item {
      font-weight: normal;
    }

    /* Workaround for Edge browser */
    paper-listbox paper-item {
      font-weight: normal;
    }

    /*
    * Fixed the iPhone auto-zoom issue on the input field
    */
    @media (max-width: 834px) {
      .neb-textarea {
        --neb-textarea-input-font-size: 16px;
      }
      .neb-textarea[focused] {
        --neb-textarea-input-font-size: 16px;
      }
    }

    .neb-toggle-button {
      cursor: pointer;
      --paper-toggle-button-checked-bar-color: var(
        --neb-toogle-button-checked-bar-color
      );
      --paper-toggle-button-checked-button-color: var(--neb-color-primary-4);
      --paper-toggle-button-unchecked-bar-color: var(
        --neb-toogle-button-checked-bar-color
      );
      --paper-toggle-button-unchecked-button-color: var(
        --neb-toggle-button-unchecked-button-color
      );
    }
  </style>
`;
_documentContainer.innerHTML = `
<custom-style>
  <style is="custom-style">
    html {

      --neb-form-field-border-radius: 3px;

      --neb-form-field-height: 38px;

      --neb-form-container-border-radius: 5px;

      --paper-checkbox-label_-_font-weight: normal;

      --neb-form-field-border-base: {
        border-radius: var(--neb-form-field-border-radius);
        border: solid 1px var(--neb-color-grey-2);
      };

      --neb-form-field-focused: {
        border: solid 1px var(--neb-color-highlight);
        border-radius: var(--neb-form-field-border-radius);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        font-family: var(--neb-font-family);
        text-indent: var(--neb-text-indent-default);
      };

      --neb-input-focused: {
        @apply --neb-form-field-focused;
        height: var(--neb-form-field-height);
      };

      --neb-input-invalid: {
        border: solid 1px var(--neb-color-error);
        height: var(--neb-form-field-height);
        border-radius: var(--neb-form-field-border-radius);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        text-indent: var(--neb-text-indent-default);
      };

      --neb-input-label-floating: {
        line-height: 1.78;
        text-align: left;
        color: var(--neb-color-dark-primary);
        margin-left: -8px;
        font-weight: var(--neb-input-label-floating-font-wieght);
        font-size: var(--neb-input-label-floating-font-size);
        font-family: var(--neb-font-family);
      };

      --neb-input-input: {
        @apply --neb-form-field-border-base;
        height: var(--neb-form-field-height);
        font-family: var(--neb-font-family);
        text-indent: var(--neb-text-indent-default);
        text-overflow: ellipsis;
      };

      --neb-input-label: {
        padding-top: 10px;
        text-indent: var(--neb-text-indent-default);
        font-size: var(--neb-font-size-body-regular);
        line-height: 1.5;
        text-align: left;
        color: var(--neb-font-color-secondary);
      };

      --iron-autogrow-textarea: {
        width: 97%;
      }

      --neb-radio: {
        font-weight: bold;
        --paper-radio-button-size: 20px;
        --paper-radio-button-checked-color: var(--neb-color-highlight);
        --paper-radio-button-unchecked-color: var(--neb-color-grey-1);
        --paper-radio-button-ink-size: 0;
      }

    }
  </style>
</custom-style>

<dom-module id="neb-form-styles">
  <template>
    ${nebFormStyles()}
  </template>
</dom-module>`;

document.head.appendChild(_documentContainer);
export const renderNebFormStyles = nebFormStyles;
