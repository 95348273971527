import '../controls/neb-button-action';
import '../inputs/neb-select';
import '../inputs/neb-textarea';
import '../tables/neb-table-authorized-procedures';
import '../tables/neb-table';
import '../neb-date-picker';
import '../neb-header';

import { html, css } from 'lit';

import { getMany } from '../../../../../src/api-clients/charges';
import { getServiceTypes } from '../../../../neb-api-client/src/service-types';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_CAPTION,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../neb-utils/feature-util';
import {
  objToName,
  DEFAULT_NAME_OPTS,
  currencyToCents,
} from '../../../../neb-utils/formatters';
import { currency, number } from '../../../../neb-utils/masks';
import {
  AUTHORIZATION_TYPE,
  AUTHORIZATION_TYPES,
  AUTHORIZATION_STATUS,
  AUTHORIZATION_STATUSES,
  createModelPatientAuth,
  rawRenderAmountToModel,
  renderAmountToRaw,
} from '../../../../neb-utils/patientAuthorization';
import {
  getSelectedServiceTypes,
  mapSelectedServiceTypesDisplay,
} from '../../../../neb-utils/patientInsurance';
import { activeProviders } from '../../../../neb-utils/provider';
import { required } from '../../../../neb-utils/validators';
import { sortServiceTypes } from '../../utils/insurance-util';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  authorizationNumberField: {
    id: 'authorization-number',
  },
  dateRequested: {
    id: 'date-requested',
  },
  serviceTypesDropdown: {
    id: 'service-types',
  },
  statusDropdown: {
    id: 'status',
  },
  dateApproved: {
    id: 'date-approved',
  },
  dateApprovedFrom: {
    id: 'date-approved-from',
  },
  dateApprovedTo: {
    id: 'date-approved-to',
  },
  authorizedProvidersDropdown: {
    id: 'authorized-providers',
  },
  authorizationTypeDropdown: {
    id: 'authorization-type',
  },
  totalAuthorizedField: {
    id: 'total-authorized',
  },
  renderedOutsideField: {
    id: 'rendered-outside',
  },
  renderedAtThisPracticeLabel: {
    id: 'rendered-at-this-practice',
  },
  totalRemainingSection: {
    id: 'total-remaining-section',
  },
  totalRemainingLabel: {
    id: 'total-remaining',
  },
  addDiagnosisButton: {
    id: 'add-diagnosis',
  },
  addProcedureButton: {
    id: 'add-procedure',
  },
  noteTextArea: {
    id: 'note',
  },
  diagnosisTable: {
    id: 'diagnosis-table',
  },
  chargesTable: {
    id: 'charges',
  },
};

export const TITLE_ADD_DX =
  "Search for and select diagnoses to add to the patient's authorization.";

const DIAGNOSIS_CONFIG = [
  {
    key: 'diagnosisCode',
    label: 'Diagnosis',
    flex: css`1 0 0`,
  },
  {
    key: 'shortDescription',
    label: 'Description',
    flex: css`3 0 0`,
  },
];

const requiredIfApprovedCompletedValidator = () => ({
  error: 'Required',
  validate: (v, _, state) =>
    (state.status !== AUTHORIZATION_STATUS.Approved &&
      state.status !== AUTHORIZATION_STATUS.Completed) ||
    v,
});

const requiredIfApprovedCompletedDateRangeValidator = () => ({
  error: 'Required',
  validate: (v, _, state) =>
    state.authorizationType === AUTHORIZATION_TYPE.DateRange ||
    (state.status !== AUTHORIZATION_STATUS.Approved &&
      state.status !== AUTHORIZATION_STATUS.Completed) ||
    v,
});

class NebFormAuthorization extends NebForm {
  static get properties() {
    return {
      __hasAuthorizationTypeDateRangeFeatureFlag: Boolean,
      __serviceTypes: Array,
      __providers: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr 1fr 1fr;
          grid-auto-rows: min-content;
          align-items: start;
        }

        .two-column {
          grid-template-columns: 1fr 1fr;
          padding: 0 ${CSS_SPACING};
        }

        .padding-top {
          padding-top: ${CSS_SPACING};
        }

        .padding-none {
          padding: 0;
        }

        .dividing-line {
          border-bottom: 1px solid ${CSS_COLOR_GREY_1};
          margin: 0 ${CSS_SPACING};
        }

        .column-one {
          grid-column: 1 / 2;
        }

        .span-2 {
          grid-column: span 2;
        }

        .date-picker {
          width: 100%;
          height: 70px;
        }

        .grid-tooltip {
          display: grid;
          grid-gap: 12px ${CSS_SPACING};
          grid-template-columns: 1fr auto;
          align-items: center;
        }

        .tooltip {
          padding-left: 12px;
        }

        .small-text {
          font-size: ${CSS_FONT_SIZE_CAPTION};
        }

        .read-only-text {
          padding-top: 8px;
        }

        .tooltip-row {
          display: flex;
          margin-top: -2px;
        }

        .flex-column {
          display: flex;
          flex-direction: column;
        }

        .add-button {
          padding-left: ${CSS_SPACING};
        }

        .note {
          height: 180px;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  static createModel() {
    return createModelPatientAuth();
  }

  initState() {
    super.initState();
    this.__hasAuthorizationTypeDateRangeFeatureFlag = false;
    this.__serviceTypes = [];
    this.__providers = [];
    this.confirmLabel = 'Save Authorization';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeStatus: e => {
        this.formService.apply(e.name, e.value);

        if (
          e.value !== AUTHORIZATION_STATUS.Approved &&
          e.value !== AUTHORIZATION_STATUS.Completed
        ) {
          if (e.value === AUTHORIZATION_STATUS.Inactive) {
            this.formService.apply('dateApproved', this.state.dateApproved);
            this.formService.apply(
              'dateApprovedFrom',
              this.state.dateApprovedFrom,
            );

            this.formService.apply('dateApprovedTo', this.state.dateApprovedTo);
          } else {
            this.formService.apply('dateApproved', null);
            this.formService.apply('dateApprovedFrom', null);
            this.formService.apply('dateApprovedTo', null);
          }

          this.formService.apply(
            'authorizedProviders',
            this.state.authorizedProviders,
          );

          this.formService.apply('totalAuthorized', this.state.totalAuthorized);
          this.formService.apply('renderedOutside', this.state.renderedOutside);

          this.__updateDateRequestedSelectable();
          this.__updateDateApprovedSelectable();
          this.__updateDateApprovedFromSelectable();
          this.__updateDateApprovedToSelectable();
        }
      },
      changeServiceTypes: e => {
        this.formService.apply(
          e.name,
          e.value.map(serviceType => ({
            ...serviceType,
            serviceTypeId: serviceType.id,
          })),
        );
      },
      changeProviders: e => {
        const selectedProviders = e.value || [];
        const mappedProviders = selectedProviders.map(p => ({
          providerId: p.id,
        }));

        this.formService.apply('authorizedProviders', mappedProviders);

        if (mappedProviders.length === this.__providers.length) {
          this.formService.apply('allProviders', true);
        } else {
          this.formService.apply('allProviders', false);
        }
      },
      changeDateRequested: ({ value }) => {
        this.formService.apply(
          'dateRequested',
          value !== null ? value.toISOString() : value,
        );

        this.__updateDateApprovedSelectable();
      },
      changeDateApproved: ({ value }) => {
        this.formService.apply(
          'dateApproved',
          value !== null ? value.toISOString() : value,
        );

        this.__updateDateRequestedSelectable();
      },
      changeDateApprovedFrom: ({ value }) => {
        this.formService.apply(
          'dateApprovedFrom',
          value !== null ? value.toISOString() : value,
        );

        this.__updateDateApprovedToSelectable();
      },
      changeDateApprovedTo: ({ value }) => {
        this.formService.apply(
          'dateApprovedTo',
          value !== null ? value.toISOString() : value,
        );

        this.__updateDateApprovedFromSelectable();
      },
      changeAuthorizationType: e => {
        if (e.value !== this.state.authorizationType) {
          this.formService.apply(e.name, e.value);
          this.formService.apply('totalAuthorized', '');
          this.formService.apply('renderedOutside', '');

          if (this.__usingDollarAmount()) {
            this.formService.apply('totalRendered', '$0.00');
          } else {
            this.formService.apply('totalRendered', '0');
          }

          this.state.charges.forEach((_, idx) => {
            this.formService.apply(`charges.${idx}.allowed`, '');

            if (this.__usingDollarAmount()) {
              this.formService.apply(`charges.${idx}.rendered`, '$0.00');
            } else {
              this.formService.apply(`charges.${idx}.rendered`, '0');
            }
          });
        }
      },
      addDiagnosis: async () => {
        const selectedDiagnosis = await openOverlay(
          OVERLAY_KEYS.ADD_DIAGNOSIS,
          {
            selectedDiagnosis: this.state.diagnoses.map(dx => ({
              diagnosisCode: dx.diagnosisCode,
              shortDescription: dx.shortDescription,
            })),
            description: TITLE_ADD_DX,
          },
        );

        if (selectedDiagnosis) {
          const oldDiagnoses = [...this.state.diagnoses];

          const sortedDiagnoses = selectedDiagnosis.sort((a, b) =>
            a.diagnosisCode.localeCompare(b.diagnosisCode),
          );

          sortedDiagnoses.forEach((dx, idx) => {
            const matchingPreviousDx = oldDiagnoses.find(
              previousDx => previousDx.diagnosisCode === dx.diagnosisCode,
            );

            if (matchingPreviousDx) {
              sortedDiagnoses[idx].id = matchingPreviousDx.id;
              sortedDiagnoses[idx].patientAuthorizationId =
                matchingPreviousDx.patientAuthorizationId;
            }
          });

          this.formService.apply('diagnoses', sortedDiagnoses);
        }
      },
      removeDiagnosis: (_, diagnosis) => {
        this.formService.apply(
          'diagnoses',
          this.state.diagnoses.filter(
            d => d.diagnosisCode !== diagnosis.diagnosisCode,
          ),
        );
      },
      addProcedure: async () => {
        const charges = await getMany({ hideInactive: true });

        const selectedCharges = await openOverlay(
          OVERLAY_KEYS.ADD_AUTHORIZED_PROCEDURES,
          {
            items: charges,
            selectedCharges: this.state.charges.map(c => ({
              ...c,
              id: c.chargeId,
            })),
          },
        );

        if (selectedCharges) this.__applyCharges(selectedCharges);
      },
      removeProcedure: (_, __, idx) =>
        this.formService.removeItem('charges', idx),
      changeAllowed: e => {
        const idx = e.name.split('.')[1];

        this.formService.apply(`charges.${idx}.allowed`, e.value);
        this.formService.validateKey(['totalAuthorized']);
      },
    };

    this.__updateDateRequestedSelectable();
    this.__updateDateApprovedSelectable();
    this.__updateDateApprovedFromSelectable();
    this.__updateDateApprovedToSelectable();
  }

  createSelectors() {
    return {
      children: {
        authorizationNumber: [required()],
        dateRequested: {
          validators: [required()],
          unformat: v =>
            v === null
              ? v
              : parseDate(v)
                  .startOf('day')
                  .toISOString(),
        },
        status: [required()],
        dateApproved: {
          validators: [requiredIfApprovedCompletedValidator()],
          unformat: v =>
            v === null
              ? v
              : parseDate(v)
                  .startOf('day')
                  .toISOString(),
        },
        dateApprovedFrom: {
          validators: [requiredIfApprovedCompletedValidator()],
          unformat: v =>
            v === null
              ? v
              : parseDate(v)
                  .startOf('day')
                  .toISOString(),
        },
        dateApprovedTo: {
          validators: [requiredIfApprovedCompletedValidator()],
          unformat: v =>
            v === null
              ? v
              : parseDate(v)
                  .endOf('day')
                  .toISOString(),
        },
        serviceTypes: {
          unsafe: true,
          clipPristine: true,
        },
        authorizedProviders: {
          unsafe: true,
          clipPristine: true,
          validators: [
            {
              error: 'Required',
              validate: (v, _, state) =>
                (state.status !== AUTHORIZATION_STATUS.Approved &&
                  state.status !== AUTHORIZATION_STATUS.Completed) ||
                v.length,
            },
          ],
        },
        totalAuthorized: {
          validators: [
            requiredIfApprovedCompletedDateRangeValidator(),
            {
              error:
                'Cannot be less than Rendered Outside Practice and total Allowed Procedures',
              validate: (v, _, state) => {
                if (!v.length) return true;

                const renderedOutside = state.renderedOutside.length
                  ? state.renderedOutside
                  : '0';

                let chargesAllowedSum = 0;

                if (this.__usingDollarAmount()) {
                  this.state.charges.forEach(charge => {
                    if (charge.allowed.length) {
                      chargesAllowedSum += currencyToCents(charge.allowed);
                    }
                  });

                  return (
                    currencyToCents(v) >=
                    currencyToCents(renderedOutside) + chargesAllowedSum
                  );
                }

                this.state.charges.forEach(charge => {
                  if (charge.allowed.length) {
                    chargesAllowedSum += parseInt(charge.allowed, 10);
                  }
                });

                return (
                  parseInt(v, 10) >=
                  parseInt(renderedOutside, 10) + chargesAllowedSum
                );
              },
            },
          ],
        },
        renderedOutside: {
          validators: [
            requiredIfApprovedCompletedDateRangeValidator(),
            {
              error: 'Required',
              validate: (v, _, state) => v || !state.totalAuthorized.length,
            },
          ],
        },
        diagnoses: {
          unsafe: true,
          clipPristine: true,
        },
        charges: {
          createItem: () => ({
            id: '',
            procedure: '',
            description: '',
            modifiers: null,
            allowed: '',
            rendered: this.__usingDollarAmount() ? '$0.00' : '0',
            encounters: [],
            chargeId: '',
          }),
          children: {
            $: {
              children: {
                allowed: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, _, state) => {
                        switch (state.authorizationType) {
                          case AUTHORIZATION_TYPE.Visits:
                          case AUTHORIZATION_TYPE.DateRange:
                            return true;
                          case AUTHORIZATION_TYPE.DollarAmount:
                            return currencyToCents(v) > 0;
                          default:
                            return v > 0;
                        }
                      },
                    },
                  ],
                },
                rendered: { unsafe: true, clipPristine: true },
                encounters: { unsafe: true, clipPristine: true },
              },
            },
          },
        },
        totalRendered: { unsafe: true, clipPristine: true },
      },
    };
  }

  async connectedCallback() {
    this.__hasAuthorizationTypeDateRangeFeatureFlag = await hasFeatureOrBeta(
      FEATURE_FLAGS.LS_AUTH_TYPE_DATE_RANGE,
    );

    this.__serviceTypes = sortServiceTypes(
      await getServiceTypes({
        hideInactive: true,
      }),
    );

    const state = store.getState();

    this.__providers = activeProviders(state.providers.item);

    super.connectedCallback();
  }

  __loadDiagnoses() {
    const oldDiagnoses = [...this.model.diagnoses];

    const sortedDiagnoses = this.model.diagnoses.sort((a, b) =>
      a.diagnosisCode.localeCompare(b.diagnosisCode),
    );

    sortedDiagnoses.forEach((dx, idx) => {
      const matchingPreviousDx = oldDiagnoses.find(
        previousDx => previousDx.diagnosisCode === dx.diagnosisCode,
      );

      if (matchingPreviousDx) {
        sortedDiagnoses[idx].id = matchingPreviousDx.id;
        sortedDiagnoses[idx].patientAuthorizationId =
          matchingPreviousDx.patientAuthorizationId;
      }
    });

    this.model.diagnoses = sortedDiagnoses;

    this.model.charges.sort((a, b) => a.procedure.localeCompare(b.procedure));
  }

  updated(changedProps) {
    if (changedProps.has('__providers') || changedProps.has('model')) {
      const { allProviders } = this.model;

      if (allProviders) {
        this.model.authorizedProviders = this.__providers.map(p => ({
          providerId: p.id,
        }));
      }

      this.__loadDiagnoses();

      this.formService.refresh(this.model);
    }

    super.updated(changedProps);
  }

  __usingDollarAmount() {
    return this.state.authorizationType === AUTHORIZATION_TYPE.DollarAmount;
  }

  __removeCharges() {
    for (
      let countCharges = this.state.charges.length;
      countCharges > 0;
      countCharges--
    ) {
      this.formService.removeItem('charges', countCharges - 1);
    }
  }

  __applyCharges(charges) {
    const previousCharges = [...this.state.charges];

    this.__removeCharges();

    const sortedCharges = charges.sort((a, b) =>
      a.procedure.localeCompare(b.procedure),
    );

    sortedCharges.forEach((charge, idx) => {
      this.formService.addItem('charges');
      this.formService.apply(`charges.${idx}.procedure`, charge.procedure);
      this.formService.apply(`charges.${idx}.description`, charge.description);
      this.formService.apply(`charges.${idx}.modifiers`, charge.modifiers);
      this.formService.apply(`charges.${idx}.chargeId`, charge.id);

      const matchingPreviousCharge = previousCharges.find(
        previousCharge => previousCharge.chargeId === charge.id,
      );

      if (matchingPreviousCharge) {
        this.formService.apply(`charges.${idx}.id`, matchingPreviousCharge.id);

        this.formService.apply(
          `charges.${idx}.allowed`,
          matchingPreviousCharge.allowed,
        );

        this.formService.apply(
          `charges.${idx}.rendered`,
          matchingPreviousCharge.rendered,
        );

        this.formService.apply(
          `charges.${idx}.encounters`,
          matchingPreviousCharge.encounters,
        );
      }
    });
  }

  __statusNotApprovedOrCompleted() {
    return (
      this.state.status !== AUTHORIZATION_STATUS.Approved &&
      this.state.status !== AUTHORIZATION_STATUS.Completed
    );
  }

  __updateDateRequestedSelectable() {
    this.handlers.dateRequestedSelectable = date =>
      this.state.dateApproved === null ||
      date.isSameOrBefore(this.state.dateApproved);
  }

  __updateDateApprovedSelectable() {
    this.handlers.dateApprovedSelectable = date =>
      this.state.dateRequested === null ||
      date.isSameOrAfter(this.state.dateRequested);
  }

  __updateDateApprovedFromSelectable() {
    this.handlers.dateApprovedFromSelectable = date =>
      this.state.dateApprovedTo === null ||
      date.isSameOrBefore(this.state.dateApprovedTo);
  }

  __updateDateApprovedToSelectable() {
    this.handlers.dateApprovedToSelectable = date =>
      this.state.dateApprovedFrom === null ||
      date.isSameOrAfter(this.state.dateApprovedFrom);
  }

  __getSelectedServiceTypes(items) {
    return getSelectedServiceTypes(this.__serviceTypes, items);
  }

  __getSelectedProviders(providerList, items) {
    if (providerList.length === 0) return [];
    if (!items || items.length === 0) return [];

    const result = items.reduce((memo, item) => {
      const match = providerList.find(
        provider => provider.id === item.providerId,
      );

      memo.push(match);

      return memo;
    }, []);

    return result;
  }

  __mapSelectedProvidersDisplay(providers) {
    return providers.map(provider => ({
      ...provider,
      label: objToName(provider.name, DEFAULT_NAME_OPTS),
      providerId: provider.id,
    }));
  }

  __calculateTotalRendered() {
    if (
      [AUTHORIZATION_TYPE.Visits, AUTHORIZATION_TYPE.DateRange].includes(
        this.state.authorizationType,
      )
    ) {
      return this.state.totalRendered;
    }

    return this.state.charges.reduce(
      (sum, item) => sum + renderAmountToRaw(this.state, item.rendered),
      0,
    );
  }

  __getTotalRendered() {
    if (
      this.state.totalAuthorized === '' &&
      this.state.authorizationType !== AUTHORIZATION_TYPE.DateRange
    ) {
      return '-';
    }

    const total = this.__calculateTotalRendered();

    return this.model.id ? rawRenderAmountToModel(this.state, total) : '-';
  }

  __getTotalRemaining() {
    if (
      this.state.totalAuthorized === '' ||
      this.state.renderedOutside === ''
    ) {
      return '-';
    }

    const total = this.__calculateTotalRendered();

    let remaining =
      renderAmountToRaw(this.state, this.state.totalAuthorized) -
      renderAmountToRaw(this.state, this.state.renderedOutside) -
      total;

    if (remaining < 0) remaining = 0;

    return this.model.id ? rawRenderAmountToModel(this.state, remaining) : '-';
  }

  __renderDiagnosisTable() {
    if (this.state.diagnoses.length > 0) {
      return html`
        <neb-table
          id="${ELEMENTS.diagnosisTable.id}"
          .config="${DIAGNOSIS_CONFIG}"
          .model="${this.state.diagnoses}"
          .onRemove="${this.handlers.removeDiagnosis}"
          showRemoveButton
        ></neb-table>
      `;
    }
    return html`
      <div></div>
    `;
  }

  __renderAuthorizedProceduresTable() {
    if (this.state.charges.length > 0) {
      return html`
        <neb-table-authorized-procedures
          id="${ELEMENTS.chargesTable.id}"
          name="charges"
          .layout="${this.layout}"
          .model="${this.state.charges}"
          .patientId="${this.model.patientId}"
          .authorizationType="${this.state.authorizationType}"
          .errors="${this.errors.charges}"
          .onChange="${this.handlers.changeAllowed}"
          .onRemove="${this.handlers.removeProcedure}"
          showRemoveButton
        ></neb-table-authorized-procedures>
      `;
    }

    return html``;
  }

  __getAuthorizationTypes() {
    let authorizationTypes = AUTHORIZATION_TYPES;

    if (!this.__hasAuthorizationTypeDateRangeFeatureFlag) {
      authorizationTypes = authorizationTypes.filter(
        type => type !== AUTHORIZATION_TYPE.DateRange,
      );
    }

    return authorizationTypes;
  }

  __renderTotalAuthorized() {
    if (this.state.authorizationType === AUTHORIZATION_TYPE.DateRange) {
      return '';
    }

    return html`
      <div class="grid-tooltip">
        <neb-textfield
          id="${ELEMENTS.totalAuthorizedField.id}"
          name="totalAuthorized"
          label="Total Authorized"
          helper="${this.__statusNotApprovedOrCompleted() ? ' ' : 'Required'}"
          maxLength="${this.__usingDollarAmount() ? '11' : '3'}"
          .mask="${this.__usingDollarAmount() ? currency : number}"
          .inputMode="${'numeric'}"
          .value="${this.state.totalAuthorized}"
          .error="${this.errors.totalAuthorized}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-tooltip
          ><div slot="tooltip">
            Total Authorized should equal the total number of allowed authorized
            procedures.
          </div></neb-tooltip
        >
      </div>
    `;
  }

  __renderBottomRow() {
    if (this.state.authorizationType === AUTHORIZATION_TYPE.DateRange) {
      return this.__renderRenderedAtThisPractice();
    }

    return html`
      <div class="grid span-2 padding-none">
        ${this.__renderRenderedOutsidePractice()}
        ${this.__renderRenderedAtThisPractice()}
        ${this.__renderTotalRemaining()}
      </div>
    `;
  }

  __renderRenderedOutsidePractice() {
    return html`
      <neb-textfield
        id="${ELEMENTS.renderedOutsideField.id}"
        class="column-one"
        name="renderedOutside"
        label="Rendered Outside Practice"
        helper="${
          this.__statusNotApprovedOrCompleted() &&
          !this.state.totalAuthorized.length
            ? ' '
            : 'Required'
        }"
        maxLength="${this.__usingDollarAmount() ? '11' : '3'}"
        .mask="${this.__usingDollarAmount() ? currency : number}"
        .inputMode="${'numeric'}"
        .value="${`${this.state.renderedOutside}`}"
        .error="${this.errors.renderedOutside}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderRenderedAtThisPractice() {
    return html`
      <div class="flex-column">
        <div class="tooltip-row">
          <span class="small-text">Rendered at this Practice</span>

          <neb-tooltip class="tooltip"
            ><div slot="tooltip">
              This value reflects the total number of rendered authorized
              procedures at this practice.
            </div></neb-tooltip
          >
        </div>
        <div
          id="${ELEMENTS.renderedAtThisPracticeLabel.id}"
          class="read-only-text"
        >
          ${this.__getTotalRendered()}
        </div>
      </div>
    `;
  }

  __renderTotalRemaining() {
    return html`
      <div id="${ELEMENTS.totalRemainingSection.id}" class="flex-column">
        <div class="tooltip-row">
          <span class="small-text">Total Remaining</span>

          <neb-tooltip class="tooltip" defaultAnchor="left"
            ><div slot="tooltip">
              This value reflects the number of remaining authorizations, based
              on the Total Authorized, Rendered Outside Practice, and Rendered
              at this Practice values.
            </div></neb-tooltip
          >
        </div>
        <div id="${ELEMENTS.totalRemainingLabel.id}" class="read-only-text">
          ${this.__getTotalRemaining()}
        </div>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="grid padding-top">
        <neb-textfield
          id="${ELEMENTS.authorizationNumberField.id}"
          class="span-2"
          name="authorizationNumber"
          label="Authorization Number (Or Name)"
          helper="Required"
          maxLength="255"
          .value="${this.state.authorizationNumber}"
          .error="${this.errors.authorizationNumber}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-date-picker
          id="${ELEMENTS.dateRequested.id}"
          name="dateRequested"
          class="date-picker"
          label="Date Requested"
          placeholder="Select Date"
          helperText="Required"
          manualPopoverPosition="center"
          .onChange="${this.handlers.changeDateRequested}"
          .selectedDate="${
            this.state.dateRequested
              ? parseDate(this.state.dateRequested)
              : null
          }"
          .isDateSelectable="${this.handlers.dateRequestedSelectable}"
          .invalidText="Required"
          ?invalid="${!!this.errors.dateRequested}"
          momentFlag
        ></neb-date-picker>

        <neb-select
          id="${ELEMENTS.serviceTypesDropdown.id}"
          name="serviceTypes"
          label="Service Types"
          allLabel="Service Types"
          .items="${mapSelectedServiceTypesDisplay(this.__serviceTypes)}"
          .value="${
            mapSelectedServiceTypesDisplay(
              this.__getSelectedServiceTypes(this.state.serviceTypes),
            )
          }"
          .onChange="${this.handlers.changeServiceTypes}"
          multiSelect
        ></neb-select>

        <neb-select
          id="${ELEMENTS.statusDropdown.id}"
          name="status"
          label="Status"
          helper="Required"
          .items="${AUTHORIZATION_STATUSES}"
          .value="${this.state.status}"
          .error="${this.errors.status}"
          .onChange="${this.handlers.changeStatus}"
        ></neb-select>

        <neb-date-picker
          id="${ELEMENTS.dateApproved.id}"
          name="dateApproved"
          class="date-picker"
          label="Date Approved"
          placeholder="Select Date"
          helperText="${
            this.__statusNotApprovedOrCompleted() ? ' ' : 'Required'
          }"
          manualPopoverPosition="center"
          .onChange="${this.handlers.changeDateApproved}"
          .selectedDate="${
            this.state.dateApproved ? parseDate(this.state.dateApproved) : null
          }"
          .isDateSelectable="${this.handlers.dateApprovedSelectable}"
          .invalidText="Required"
          ?disabled="${
            this.__statusNotApprovedOrCompleted() &&
              this.state.status !== 'Inactive'
          }"
          ?invalid="${!!this.errors.dateApproved}"
          momentFlag
        ></neb-date-picker>
      </div>

      <div class="dividing-line"></div>

      <div class="grid two-column">
        <neb-date-picker
          id="${ELEMENTS.dateApprovedFrom.id}"
          name="dateApprovedFrom"
          class="date-picker"
          label="Approved From"
          placeholder="Select Date"
          helperText="${
            this.__statusNotApprovedOrCompleted() ? ' ' : 'Required'
          }"
          manualPopoverPosition="center"
          .onChange="${this.handlers.changeDateApprovedFrom}"
          .selectedDate="${
            this.state.dateApprovedFrom
              ? parseDate(this.state.dateApprovedFrom).startOf('day')
              : null
          }"
          .isDateSelectable="${this.handlers.dateApprovedFromSelectable}"
          ?disabled="${
            this.__statusNotApprovedOrCompleted() &&
              this.state.status !== AUTHORIZATION_STATUS.Inactive
          }"
          ?invalid="${!!this.errors.dateApprovedFrom}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.dateApprovedTo.id}"
          name="dateApprovedTo"
          class="date-picker"
          label="Approved To"
          placeholder="Select Date"
          helperText="${
            this.__statusNotApprovedOrCompleted() ? ' ' : 'Required'
          }"
          manualPopoverPosition="center"
          .onChange="${this.handlers.changeDateApprovedTo}"
          .selectedDate="${
            this.state.dateApprovedTo
              ? parseDate(this.state.dateApprovedTo).endOf('day')
              : null
          }"
          .isDateSelectable="${this.handlers.dateApprovedToSelectable}"
          ?disabled="${
            this.__statusNotApprovedOrCompleted() &&
              this.state.status !== AUTHORIZATION_STATUS.Inactive
          }"
          ?invalid="${!!this.errors.dateApprovedTo}"
          momentFlag
        ></neb-date-picker>

        <neb-select
          id="${ELEMENTS.authorizedProvidersDropdown.id}"
          class="span-2"
          name="authorizedProviders"
          label="Authorized Providers"
          allLabel="Providers"
          helper="${this.__statusNotApprovedOrCompleted() ? ' ' : 'Required'}"
          emptyMessage="No results found"
          .items="${this.__mapSelectedProvidersDisplay(this.__providers)}"
          .value="${
            this.__mapSelectedProvidersDisplay(
              this.__getSelectedProviders(
                this.__providers,
                this.state.authorizedProviders,
              ),
            )
          }"
          .onChange="${this.handlers.changeProviders}"
          .error="${this.errors.authorizedProviders}"
          multiSelect
          wrapText
        ></neb-select>

        <neb-select
          id="${ELEMENTS.authorizationTypeDropdown.id}"
          name="authorizationType"
          label="Authorization Type"
          helper="${this.__statusNotApprovedOrCompleted() ? ' ' : 'Required'}"
          .items="${this.__getAuthorizationTypes()}"
          .value="${this.state.authorizationType}"
          .error="${this.errors.authorizationType}"
          .disabled="${!!this.model.id}"
          .onChange="${this.handlers.changeAuthorizationType}"
        ></neb-select>

        ${this.__renderTotalAuthorized()} ${this.__renderBottomRow()}
      </div>

      <neb-header label="Diagnoses"></neb-header>

      <neb-button-action
        id="${ELEMENTS.addDiagnosisButton.id}"
        class="add-button"
        label="Add Diagnosis"
        .onClick="${this.handlers.addDiagnosis}"
      ></neb-button-action>

      ${this.__renderDiagnosisTable()}

      <neb-header label="Authorized Procedures"></neb-header>

      <neb-button-action
        id="${ELEMENTS.addProcedureButton.id}"
        class="add-button"
        label="Add Procedure"
        .onClick="${this.handlers.addProcedure}"
      ></neb-button-action>

      ${this.__renderAuthorizedProceduresTable()}

      <neb-textarea
        id="${ELEMENTS.noteTextArea.id}"
        name="note"
        class="note"
        label="Note"
        .value="${this.state.note}"
        .onChange="${this.handlers.change}"
        maxlength="500"
        showCount
      ></neb-textarea>
    `;
  }
}

customElements.define('neb-form-authorization', NebFormAuthorization);
