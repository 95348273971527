import '../../misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_DISABLED,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_3,
  CSS_COLOR_GREY_5,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_BODY,
} from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  label: { id: 'label' },
  icon: { id: 'icon' },
};

class Pill extends LitElement {
  static get properties() {
    return {
      readonly: {
        type: Boolean,
        reflect: true,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          --max-width: 120px;
          --background-color: ${CSS_COLOR_HIGHLIGHT};

          display: inline-block;
          height: 24px;
          border-radius: 12px;
          background-color: var(--background-color);
        }

        :host([disabled]) {
          pointer-events: none;
          background-color: ${CSS_COLOR_DISABLED};
          cursor: default;
        }

        :host(:hover) {
          background-color: ${CSS_COLOR_GREY_2};
        }

        .container {
          display: flex;
          cursor: pointer;
          padding: 0 10px;
          width: 100%;
          height: 100%;
          align-items: center;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: 700;
          line-height: 16px;
          color: ${CSS_COLOR_WHITE};
        }

        :host([disabled]) .container {
          color: ${CSS_COLOR_GREY_5};
        }

        .icon {
          margin-left: 8px;
          width: 16px;
          height: 16px;
          fill: ${CSS_COLOR_GREY_3};
        }

        :host([disabled]) .icon {
          fill: ${CSS_COLOR_GREY_5};
        }

        .label {
          margin: 0;
          padding: 0;
          max-width: var(--max-width);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        :host([readonly]) {
          pointer-events: none;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.readonly = false;
    this.disabled = false;
  }

  render() {
    return html`
      <div class="container">
        <p id="${ELEMENTS.label.id}" class="label"><slot></slot></p>

        ${
          !this.readonly
            ? html`
                <neb-icon
                  id="${ELEMENTS.icon.id}"
                  class="icon"
                  icon="neb:close"
                ></neb-icon>
              `
            : ''
        }
      </div>
    `;
  }
}

window.customElements.define('neb-pill', Pill);
