import '../neb-radio-button';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../neb-tooltip';
import '../neb-date-picker';
import '../tables/neb-table-encounter-charges-details';
import '../tables/neb-table-patient-package-charges';
import '../inputs/neb-select';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import {
  getPatientPackageEncounterCharges,
  formatPatientPackageForPut,
  getPatientPackageExists,
  completePatientPackage,
  updatePatientPackage,
  getPatientPackages,
} from '../../../../../src/api-clients/patient-package';
import {
  BILL_TYPE_DEFAULT_INSURANCE,
  BILL_TYPE_DEFAULT_SELF,
} from '../../../../../src/utils/user-message';
import { getEncounter } from '../../../../neb-api-client/src/encounters-api-client';
import { BILL_TYPES } from '../../../../neb-api-client/src/mappers/patient-case-mapper';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getPatientRelationshipsActiveGroup } from '../../../../neb-api-client/src/patient-relationship-api-client';
import {
  getMerchantAccounts,
  TOOL_TIP_SUBSCRIPTION_NOT_ALLOWED,
  TOOL_TIP_SUBSCRIPTION_ALLOWED,
} from '../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import {
  getRecurringPaymentByPatientPackageId,
  deleteRecurringPaymentByPatientPackageId,
  updateRecurringPayment,
} from '../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import {
  openSuccess,
  openError,
  openInfo,
} from '../../../../neb-dialog/neb-banner-state';
import { updateCarePackageDefault } from '../../../../neb-patient/src/components/billing/neb-patient-general-page';
import { openDirtyPopup } from '../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { connect, store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { PACKAGE_TYPE, PAYMENT_FREQUENCY } from '../../../../neb-utils/enums';
import { objToName, DEFAULT_NAME_OPTS } from '../../../../neb-utils/formatters';
import { number } from '../../../../neb-utils/masks';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../neb-utils/neb-refresh';
import {
  createModel,
  chargesFormatters,
  getDescriptionLines,
  mapSharedHistory,
  hasSharedHistory,
  buildFormattedRelationships,
} from '../../../../neb-utils/patientPackage';
import * as selectors from '../../../../neb-utils/selectors';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { MODE } from '../../../../neb-utils/table';
import { required, range } from '../../../../neb-utils/validators';
import { openEncounterSummary } from '../../utils/encounter-overlays-util';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';

const PAGE_SIZE = 10;
export const ELEMENTS = {
  ...ELEMENTS_BASE,
  visitsRadioButton: {
    id: 'visits-radio',
  },
  servicesRadioButton: {
    id: 'services-radio',
  },
  templateDropdown: {
    id: 'template',
  },
  nameField: {
    id: 'name',
  },
  subscriptionCheckbox: {
    id: 'subscription-checkbox',
  },
  checkboxSharedCarePackage: {
    id: 'checkbox-shared-care-package',
  },
  packageHistory: {
    id: 'package-history',
  },
  patientPackageRelatedPatients: {
    id: 'patient-package-related-patients',
  },
  tooltip: {
    id: 'tooltip',
  },
  summary: {
    id: 'summary',
  },
  effectiveDate: {
    id: 'effective-date',
  },
  expirationDate: {
    id: 'expiration-date',
  },
  totalVisitsField: {
    id: 'total-visits',
  },
  totalPriceField: {
    id: 'total-price',
  },
  frequencyDropdown: {
    id: 'frequency',
  },
  unlimitedCheckbox: {
    id: 'unlimited',
  },
  unlimitedTooltip: {
    id: 'unlimited-tooltip',
  },
  completedDate: {
    id: 'completed-date',
  },
  completedButton: {
    id: 'completed-button',
  },
  completedTooltip: {
    id: 'completed-tooltip',
  },
  chargesTable: {
    id: 'charges-table',
  },
  encounterChargesTable: {
    id: 'encounter-charges-table',
  },
  pagination: {
    id: 'pagination',
  },
  encounterChargesPagination: {
    id: 'encounter-charges-pagination',
  },
  defaultCheckbox: {
    id: 'default-checkbox',
  },
};
const PACKAGE_SUMMARY_HEADER = 'Package Summary';
const TOTAL_VISITS_ERROR = 'Required, must be between 1 - 999';
const DUPLICATE_MSG = {
  confirmText: 'Yes',
  title: 'Duplicate Patient Package Name',
  message:
    'There is an existing package for this patient with the same name. Please change the name to save this package for the patient.',
};

const COMPLETE_PACKAGE_MSG = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Complete Package',
  message: html`
    Completing this package will update the status to inactive. Once the package
    is completed, it cannot be reactivated.<br /><br />Do you want to proceed?
  `,
};
const COMPLETE_SUBSCRIPTION_MSG = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Complete Subscription',
  message: html`
    Completing this package will update the status to inactive. Once the package
    is completed, it cannot be reactivated. <br /><br />Do you want to proceed?
  `,
};

const NO_ENCOUNTER_CHARGES_MESSAGE =
  'There are no encounter charges for this package';

export const PATIENT_PACKAGE_SUCCESS_MESSAGE =
  'Patient package updated successfully';
export const PATIENT_PACKAGE_ERROR_MESSAGE = 'Unable to update Patient package';
export const PATIENT_PACKAGE_COMPLETED_SUCCESS_MESSAGE =
  'Patient package completed';
export const CASE_OVERRIDE =
  "Case billing override disabled. This can be enabled from the patient's Billing tab.";
export const PATIENT_PACKAGE_COMPLETED_ERROR_MESSAGE =
  'An error occurred when completing the patient package';
export const PATIENT_PACKAGE_DEFAULT_ERROR_MESSAGE =
  'An error occurred when setting default patient package';

const ENCOUNTER_CHARGES_CONFIG = [
  {
    key: 'chargeNumber',
    label: 'Charge ID',
    flex: css`2 0 0`,
  },
  {
    key: 'providerId',
    label: 'Provider',
    flex: css`4 0 0`,
  },
  {
    key: 'encounterNumber',
    label: 'Encounter',
    flex: css`2 0 0`,
  },
  {
    key: 'patientPackageDeduct',
    label: 'Used',
    flex: css`1 0 0`,
  },
  {
    key: 'dxcodes',
    label: 'Diagnoses',
    flex: css`3 0 0`,
  },
  {
    key: 'code',
    label: 'Procedure',
    flex: css`3 0 0`,
  },
  {
    key: 'units',
    label: 'Units',
    flex: css`1 0 0`,
  },
  {
    key: 'dateOfService',
    label: 'Service Date',
    flex: css`3 0 0`,
    formatter: v => moment(v).format('MM/DD/YYYY'),
  },
];

const SHARED_PACKAGE_ENCOUNTER_CHARGES_CONFIG = [
  {
    key: 'patientName',
    label: 'Patient',
    flex: css`4 0 0`,
  },
  {
    key: 'chargeNumber',
    label: 'Charge ID',
    flex: css`2 0 0`,
  },
  {
    key: 'encounterNumber',
    label: 'Encounter',
    flex: css`2 0 0`,
  },
  {
    key: 'patientPackageDeduct',
    label: 'Used',
    flex: css`1 0 0`,
  },
  {
    key: 'dxcodes',
    label: 'Diagnoses',
    flex: css`3 0 0`,
  },
  {
    key: 'code',
    label: 'Procedure',
    flex: css`3 0 0`,
  },
  {
    key: 'units',
    label: 'Units',
    flex: css`1 0 0`,
  },
  {
    key: 'dateOfService',
    label: 'Service Date',
    flex: css`3 0 0`,
    formatter: v => moment(v).format('MM/DD/YYYY'),
  },
];

const uniq = arr => [...new Set(arr)];

class NebFormPatientPackageEdit extends connect(store)(NebForm) {
  static get properties() {
    return {
      fromRecurringPayment: Boolean,
      encountersAssociated: Boolean,
      disableSubscriptions: Boolean,
      disableDefault: Boolean,
      patientId: String,
      __collectionState: Object,
      __encounterChargesCollectionState: Object,
      __expandFlags: Array,
      __providers: Array,
      __expirationDateCompleted: Boolean,
      __activeRelationshipsGroup: Object,
      __relationships: Object,
      __relatedPatientHistory: Object,
      __hasSharedHistory: Boolean,
      __encounterChargesPageCount: Number,
      __currentEncounterPageIndex: Number,
    };
  }

  static createModel() {
    const defaultEmpty = createModel();
    const { type, subscription, unlimited, frequency } = defaultEmpty;

    return {
      ...defaultEmpty,
      usedCount: {
        type,
        subscription,
        unlimited,
        frequency,
        visitsUsed: 0,
        visitsRemaining: 0,
        visitsTotal: 0,
        unitsUsed: 0,
        unitsRemaining: 0,
        unitsTotal: 0,
        charges: [],
      },
    };
  }

  constructor() {
    super();

    this.__initServices();
  }

  initState() {
    super.initState();
    this.fromRecurringPayment = false;
    this.disableSubscriptions = false;
    this.disableDefault = false;
    this.encountersAssociated = false;
    this.patientId = '';
    this.__providers = [];
    this.__expandFlags = [];
    this.__collectionState = CollectionService.createModel();
    this.__encounterChargesCollectionState = CollectionService.createModel();
    this.__expirationDateCompleted = false;
    this.__ignoreNextToggle = false;
    this.__today = parseDate();
    this.__activeRelationshipsGroup = {};
    this.__relationships = [];
    this.__relatedPatientHistory = {};
    this.__hasSharedHistory = false;
    this.__currentEncounterPageIndex = 0;
    this.__encounterChargesPageCount = 0;
    this.__packageUnshareable = false;

    this.onCompleteFromRecurringPaymentLink = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeSharedPatients: e => {
        this.handlers.change(e);
        this.__updateDefaultPP();
      },
      changeEffectiveDate: ({ value }) => {
        const newEffectiveDate = value
          ? parseDate(moment(value)).format('YMD')
          : null;
        const oldEffectiveDate = parseDate(
          moment(this.state.effectiveDate),
        ).format('YMD');

        if (newEffectiveDate !== oldEffectiveDate) {
          this.formService.apply(
            'effectiveDate',
            value ? value.toISOString() : value,
          );

          this.__updateExpirationDateSelectable();
        }
      },
      clearEffectiveDate: () => {
        this.formService.apply('effectiveDate', null);

        this.__updateExpirationDateSelectable();
      },
      changeExpirationDate: ({ value }) => {
        const newExpirationDate = value
          ? parseDate(moment(value)).format('YMD')
          : null;
        const oldExpirationDate = parseDate(
          moment(this.state.expirationDate),
        ).format('YMD');

        if (newExpirationDate !== oldExpirationDate) {
          this.formService.apply(
            'expirationDate',
            value ? value.toISOString() : value,
          );

          this.__updateEffectiveDateSelectable();
        }
      },
      clearExpirationDate: () => {
        this.formService.apply('expirationDate', null);

        this.__updateEffectiveDateSelectable();
      },
      changeUnlimited: e => {
        this.formService.apply('unlimited', e.value);
        this.formService.apply('totalVisits', '');
      },
      changeName: ({ value }) => {
        this.formService.apply('name', value);
        this.__packageNameExists = false;
      },
      changeCollectionState: state => {
        this.__collectionState = state;
      },
      changeEncounterChargesCollectionState: async state => {
        if (this.__hasSharedHistory) {
          const stateWithPatientNames = state;

          const uniquePats = uniq(state.pageItems.map(d => d.patientId));

          const pats = await patientApiClient.fetchSome(uniquePats);

          stateWithPatientNames.pageItems = stateWithPatientNames.pageItems.map(
            item => ({
              ...item,
              patientName: objToName(
                pats[pats.findIndex(p => p.id === item.patientId)].name,
                DEFAULT_NAME_OPTS,
              ),
              patientInContext: item.patientId === this.patientId,
            }),
          );

          this.__encounterChargesCollectionState = stateWithPatientNames;
        } else {
          this.__encounterChargesCollectionState = state;
        }

        this.__encounterChargesPageCount =
          this.state.encounterChargeCount > 1
            ? Math.ceil(this.state.encounterChargeCount / 10)
            : 1;

        this.__expandFlags = this.__encounterChargesCollectionState.pageItems.map(
          () => false,
        );
      },
      sort: (a, b) => {
        const procedureCompare = a.charge.procedure.localeCompare(
          b.charge.procedure,
        );

        if (procedureCompare === 0) {
          return a.charge.description.localeCompare(b.charge.description);
        }

        return procedureCompare;
      },
      sortEncounterCharges: (a, b) => {
        if (a.chargeNumber > b.chargeNumber) return 1;

        return -1;
      },
      pageChanged: index => this.__collectionService.setPageIndex(index),
      pageChangedEncounterCharges: async index => {
        this.__currentEncounterPageIndex = index;

        const limit = PAGE_SIZE;
        const offset =
          this.__currentEncounterPageIndex === 0
            ? 0
            : this.__currentEncounterPageIndex * PAGE_SIZE;
        const carePackageId = this.model.id;

        const packageCharges = await getPatientPackageEncounterCharges(
          carePackageId,
          { offset, limit },
        );

        this.__encounterChargesCollectionService.setItems(
          packageCharges.encounterCharges,
        );

        this.state.encounterChargeCount = packageCharges.encounterChargeCount;
      },
      save: async () => {
        this.__completingPackage = false;
        const valid = await this.__validate();

        if (!valid) {
          this.__blurActionBar();
          return;
        }

        try {
          const putBody = formatPatientPackageForPut({
            ...this.state,
            defaultPatientId: this.patientId,
          });

          const result = await updatePatientPackage(putBody);

          if (this.state.subscription) {
            const didExpirationDateChange =
              (this.model.expirationDate === null &&
                result.expirationDate !== null) ||
              (this.model.expirationDate !== null &&
                result.expirationDate === null) ||
              (this.model.expirationDate !== null &&
                result.expirationDate !== null &&
                !parseDate(this.model.expirationDate).isSame(
                  parseDate(result.expirationDate),
                ));

            if (didExpirationDateChange) {
              const {
                recurringPayment,
              } = await getRecurringPaymentByPatientPackageId(
                this.model.patientId,
                this.model.id,
              );

              await updateRecurringPayment(this.model.patientId, {
                ...recurringPayment,
                end: result.expirationDate,
              });
            }
          }

          this.onChangeDirty(false);

          store.dispatch(openSuccess(PATIENT_PACKAGE_SUCCESS_MESSAGE));

          sendRefreshNotification(
            [
              REFRESH_CHANGE_TYPE.SCHEDULED_PAYMENTS,
              REFRESH_CHANGE_TYPE.PATIENT_PACKAGE,
              REFRESH_CHANGE_TYPE.PATIENT,
            ],
            this.model.patientId,
          );

          this.onSave(result);
        } catch (error) {
          console.error(error);
          store.dispatch(openError(PATIENT_PACKAGE_ERROR_MESSAGE));

          this.__blurActionBar();
        }
      },
      completePackage: async () => {
        const previousExpireDate = this.state.expirationDate;

        const restoreCompleteDates = () => {
          this.formService.apply('completedDate', null);
          this.formService.apply('expirationDate', previousExpireDate);
          this.__completingPackage = false;
          this.__validate();
          this.__blurCompleteButton();
        };

        const today = parseDate().toISOString();

        this.formService.apply('completedDate', today);
        this.formService.apply('expirationDate', today);

        this.__completingPackage = true;

        const valid = await this.__validate();

        if (!valid) {
          restoreCompleteDates();
          return;
        }

        const confirmed = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          this.state.subscription
            ? COMPLETE_SUBSCRIPTION_MSG
            : COMPLETE_PACKAGE_MSG,
        );

        if (confirmed) {
          try {
            if (this.state.isDefault) {
              const packages = (await getPatientPackages(
                this.patientId,
                {
                  hideInactive: true,
                  includeShared: true,
                },
                true,
              )).filter(p => p.id !== this.model.id);

              if (packages.length >= 2) {
                const items = packages.map(c => ({
                  data: c,
                  label: c.name,
                }));

                const newDefaultPackage = await openPopup(
                  POPUP_RENDER_KEYS.BILL_TYPE_SET_DEFAULT,
                  {
                    title: 'Set Default Care Package',
                    message:
                      'Patient has multiple care packages. Please set another care package as default to continue.',
                    confirmText: 'Save',
                    cancelText: 'Cancel',
                    name: BILL_TYPES.CARE_PACKAGE,
                    items,
                    saveAction: updateCarePackageDefault,
                    patientId: this.patientId,
                  },
                );

                if (!newDefaultPackage) {
                  restoreCompleteDates();
                  return;
                }
              }
            }
          } catch (e) {
            restoreCompleteDates();
            store.dispatch(openError(PATIENT_PACKAGE_DEFAULT_ERROR_MESSAGE));

            return;
          }

          try {
            const putBody = formatPatientPackageForPut({ ...this.state });

            await updatePatientPackage(putBody);

            const {
              patientPackage,
              newBillType,
              caseOverrideUpdated,
            } = await completePatientPackage(putBody, this.patientId);

            if (this.state.subscription) {
              await deleteRecurringPaymentByPatientPackageId(
                this.model.patientId,
                this.model.id,
              );
            }

            this.onChangeDirty(false);

            store.dispatch(
              openSuccess(PATIENT_PACKAGE_COMPLETED_SUCCESS_MESSAGE),
            );

            switch (newBillType) {
              case BILL_TYPES.INSURANCE:
                store.dispatch(openInfo(BILL_TYPE_DEFAULT_INSURANCE));
                break;

              case BILL_TYPES.SELF:
                store.dispatch(openInfo(BILL_TYPE_DEFAULT_SELF));
                break;

              default:
            }

            if (caseOverrideUpdated) {
              store.dispatch(openInfo(CASE_OVERRIDE));
            }

            sendRefreshNotification(
              [
                REFRESH_CHANGE_TYPE.SCHEDULED_PAYMENTS,
                REFRESH_CHANGE_TYPE.PATIENT_PACKAGE,
                REFRESH_CHANGE_TYPE.PATIENT,
              ],
              this.patientId,
            );

            if (this.fromRecurringPayment) {
              this.onCompleteFromRecurringPaymentLink();
            } else this.onSave(patientPackage);
          } catch (error) {
            restoreCompleteDates();

            store.dispatch(openError(PATIENT_PACKAGE_COMPLETED_ERROR_MESSAGE));
          }
        } else {
          restoreCompleteDates();
        }
      },
      toggleExpand: (_name, _item, index) => {
        if (this.__ignoreNextToggle) {
          this.__ignoreNextToggle = false;

          return;
        }

        const expandFlags = this.__expandFlags;

        expandFlags[index] = !expandFlags[index];

        this.__expandFlags = [...expandFlags];
      },
      encounterChargesLinkClick: async (rowIndex, column) => {
        if (column === 'encounter') {
          this.__ignoreNextToggle = true;

          if (this.__dirty && !(await openDirtyPopup())) return;

          const patient = await this.__getPatient(
            this.__encounterChargesCollectionState.pageItems[rowIndex]
              .patientId,
          );

          const {
            encounterId,
          } = this.__encounterChargesCollectionState.pageItems[rowIndex];

          const encounter = await getEncounter(encounterId);

          await openEncounterSummary({
            patient,
            encounterId,
            appointmentTypeId: encounter.appointmentTypeId,
          });

          this.model = { ...this.model };
        } else {
          this.__ignoreNextToggle = true;

          if (this.__dirty && !(await openDirtyPopup())) return;

          navigate(
            `/patients/${
              this.__encounterChargesCollectionState.pageItems[rowIndex]
                .patientId
            }/ledger/charges`,
          );
        }
      },
      historyClick: async () => {
        await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_HISTORY, {
          patientPackage: this.model,
          activeRelationshipGroup: this.__activeRelationshipsGroup,
        });
      },
    };

    this.__updateEffectiveDateSelectable();

    this.__updateExpirationDateSelectable();
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: this.handlers.changeCollectionState,
        onSort: this.handlers.sort,
      },
      { sortParams: { key: '', dir: 'asc' } },
    );

    this.__encounterChargesCollectionService = new CollectionService(
      {
        onChange: this.handlers.changeEncounterChargesCollectionState,
        onSort: this.handlers.sortEncounterCharges,
      },
      { hideInactive: false },
    );
  }

  async __validate() {
    if (this.shadowRoot.getElementById(ELEMENTS.expirationDate.id)) {
      const expirationDateErrors = this.shadowRoot.getElementById(
        ELEMENTS.expirationDate.id,
      ).invalidTextInput;

      if (expirationDateErrors) return false;
    }

    if (this.state.name !== this.model.name) {
      const { exists } = await getPatientPackageExists(this.model.patientId, {
        name: this.state.name,
      });
      this.__packageNameExists = exists;
    } else this.__packageNameExists = false;

    const valid = this.formService.validate();

    if (this.__packageNameExists) {
      await openPopup('message', DUPLICATE_MSG);
    }

    return valid;
  }

  __getPatient(patientId) {
    return patientApiClient.fetchOne(patientId);
  }

  createSelectors() {
    return {
      children: {
        name: {
          validators: [
            required('Required'),
            {
              error: 'Required',
              validate: _v => !this.__packageNameExists,
            },
          ],
        },
        effectiveDate: {
          unsafe: true,
          validators: [required()],
        },
        expirationDate: {
          unsafe: true,
          validators: [
            {
              error: 'Required',
              validate: v =>
                !this.state.active ||
                !this.__completingPackage ||
                (this.__completingPackage && v),
            },
          ],
        },
        completedDate: {
          unsafe: true,
          validators: [
            {
              error: 'Required',
              validate: v =>
                !this.state.active ||
                !this.__completingPackage ||
                (this.__completingPackage && v),
            },
          ],
        },
        totalVisits: selectors.numeric(0, {
          validators: [
            {
              error: TOTAL_VISITS_ERROR,
              validate: v => {
                if (
                  this.state.type !== PACKAGE_TYPE.VISIT ||
                  this.state.unlimited
                ) {
                  return true;
                }

                return v && range(1, 999).validate(v);
              },
            },
          ],
        }),
        patientPackageRelatedPatients: {
          unsafe: true,
          clipPristine: true,
          clipErrors: true,
          validators: [
            {
              error: 'Required',
              validate: v =>
                !this.state.sharedCarePackage ||
                !this.state.active ||
                (v && v.length > 1),
            },
          ],
        },
        charges: chargesFormatters,
      },
    };
  }

  async connectedCallback() {
    this.disableSubscriptions =
      (await getMerchantAccounts({ hideInactive: true })).length === 0;

    super.connectedCallback();
  }

  _stateChanged(state) {
    const {
      providers: { item: providers },
    } = state;

    this.__providers = providers;
  }

  firstUpdated(changedProps) {
    this.__expirationDateCompleted = !!this.model.completedDate;
    super.firstUpdated(changedProps);
  }

  async __loadRelationships() {
    const { patientId: packageOwnerPatientId } = this.model;

    const activeRelationshipsGroup = await getPatientRelationshipsActiveGroup(
      packageOwnerPatientId,
    );

    const patientIds = [
      ...activeRelationshipsGroup.related,
      activeRelationshipsGroup.primary,
    ];

    const patientNames = await patientApiClient.fetchSome(patientIds);

    const {
      relationshipsToSelect,
      relationships,
    } = buildFormattedRelationships(
      this.model,
      patientNames,
      activeRelationshipsGroup,
    );

    this.__relationships = relationships;
    this.__activeRelationshipsGroup = activeRelationshipsGroup;

    const model = this.formService.build();

    model.patientPackageRelatedPatients = relationshipsToSelect;

    this.formService.refresh(model);
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__relatedPatientHistory = mapSharedHistory(this.model);
      this.__hasSharedHistory = hasSharedHistory(this.model);

      this.__loadRelationships();
    }

    if (changedProps.has('__state')) {
      if (
        this.state.charges.length !== this.__collectionService.getTotalCount()
      ) {
        const { pageIndex } = this.__collectionState;

        this.__collectionService.setItems(this.state.charges);

        this.__collectionService.setPageIndex(pageIndex);
      }

      if (
        this.state.encounterCharges &&
        this.state.encounterCharges.length !==
          this.__encounterChargesCollectionService.getTotalCount()
      ) {
        const { pageIndex } = this.__encounterChargesCollectionState;

        this.__encounterChargesCollectionService.setItems(
          this.state.encounterCharges,
        );

        this.__encounterChargesCollectionService.setPageIndex(pageIndex);
      }
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      const { unlimited, usedCount, type } = this.model;
      const model = this.formService.build();

      if (unlimited) {
        model.totalVisits = '';
      }

      const { visitsUsed, unitsUsed } = usedCount;

      this.encountersAssociated =
        type === PACKAGE_TYPE.VISIT ? visitsUsed > 0 : unitsUsed > 0;

      this.formService.refresh(model);

      this.__updateExpirationDateSelectable();
    }

    super.updated(changedProps);
  }

  __blurActionBar() {
    this.shadowRoot.getElementById(ELEMENTS.actionBar.id).blur();
  }

  __blurCompleteButton() {
    this.shadowRoot.getElementById(ELEMENTS.completedButton.id).blur();
  }

  __updateEffectiveDateSelectable() {
    this.handlers.effectiveDateSelectable = date => {
      if (this.state.expirationDate === null) return true;

      return date.isSameOrBefore(parseDate(this.state.expirationDate), 'day');
    };
  }

  __interval() {
    switch (this.state.frequency) {
      case PAYMENT_FREQUENCY.WEEKLY:
        return [1, 'week'];
      case PAYMENT_FREQUENCY.BI_WEEKLY:
        return [2, 'weeks'];
      default:
        return [1, 'month'];
    }
  }

  __updateExpirationDateSelectable() {
    if (this.state.subscription) {
      this.handlers.expirationDateSelectable = date => {
        if (this.state.effectiveDate === null) return true;

        const isInFuture = date.isSameOrAfter(this.__today, 'day');

        const isOneMonthAfterEffectiveDate = date.isSameOrAfter(
          parseDate(this.state.effectiveDate).add(...this.__interval()),
          'day',
        );

        return isInFuture && isOneMonthAfterEffectiveDate;
      };
    } else {
      this.handlers.expirationDateSelectable = date => {
        if (this.state.effectiveDate === null) return true;

        const isAfterEffectiveDate = date.isSameOrAfter(
          parseDate(this.state.effectiveDate),
          'day',
        );

        return isAfterEffectiveDate;
      };
    }

    if (this.shadowRoot) {
      const expirationDateField = this.shadowRoot.getElementById(
        ELEMENTS.expirationDate.id,
      );

      if (expirationDateField) {
        expirationDateField.validateDate();
      }
    }
  }

  __updateDefaultPP() {
    this.__packageUnshareable =
      this.state.patientPackageRelatedPatients.length &&
      !this.state.patientPackageRelatedPatients.some(ppkg => {
        if (ppkg.relatedPatientId) {
          return ppkg.active && ppkg.relatedPatientId === this.patientId;
        }
        return ppkg.data.id === this.patientId;
      });

    if (this.__packageUnshareable) this.formService.apply('isDefault', false);
  }

  __subscriptionsDisabled() {
    return this.disableSubscriptions;
  }

  static get styles() {
    return [
      super.styles,
      css`
        .form {
          padding: 0 0 ${CSS_SPACING};
        }

        .grid {
          display: grid;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
          align-items: inherit;
        }

        .grid-2 {
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          padding: 0 ${CSS_SPACING};
        }

        .radio-buttons {
          padding-left: 10px;
        }

        .column-one {
          grid-column: 1 / 2;
          display: flex;
        }

        .flex-row {
          display: flex;
        }

        .unlimited-row {
          padding-top: 25px;
        }

        .shared-care-package-checkbox {
          flex: 1;
          padding-bottom: ${CSS_SPACING};
        }

        .shared-care-package-select {
          margin-top: -${CSS_SPACING};
          width: 100%;
        }

        .tooltip {
          padding-left: 10px;
        }

        .completed-date {
          grid-column: 1 / 2;
          width: 100%;
        }

        .completed-button-row {
          display: flex;
          padding-top: ${CSS_SPACING};
        }

        .completed-tooltip {
          padding-left: 10px;
          padding-top: 4px;
        }

        .expiration-date {
          width: 100%;
        }

        .effective-date {
          width: 100%;
        }

        .summary-container {
          grid-column: 1 / 3;
          display: flex;
          flex-direction: column;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .table {
          padding-top: ${CSS_SPACING};
        }

        .no-encounter-charges {
          padding-left: ${CSS_SPACING};
        }

        .pagination {
          flex-direction: row-reverse;
          padding-top: ${CSS_SPACING};
          padding-right: 10px;
          margin-bottom: ${CSS_SPACING};
        }

        .history {
          padding-left: 10px;
        }
      `,
    ];
  }

  __renderSummarySection() {
    const { type, unlimited, totalVisits, usedCount } = this.state;

    let { visitsRemaining } = usedCount;

    const totalVisitsValue =
      totalVisits.length > 0 ? parseInt(totalVisits, 10) : 0;

    if (type === PACKAGE_TYPE.VISIT && !unlimited) {
      visitsRemaining = totalVisitsValue - usedCount.visitsUsed;

      if (visitsRemaining < 0) visitsRemaining = 0;
    }

    const editedUsedCount = {
      ...usedCount,
      totalVisits: totalVisitsValue,
      visitsRemaining,
    };
    const description = getDescriptionLines(this.state, editedUsedCount);

    if (description === '' || description.length < 3) return '';

    return html`
      <div id="${ELEMENTS.summary.id}">
        ${
          description.map(
            data =>
              html`
                <div>${data}</div>
              `,
          )
        }
      </div>
    `;
  }

  __renderTotalPriceField() {
    return html`
      <neb-textfield
        id="${ELEMENTS.totalPriceField.id}"
        name="totalPrice"
        label="${this.state.subscription ? '' : 'Total '}Price"
        helper="Required"
        maxLength="10"
        .value="${this.state.totalPrice}"
        disabled
      ></neb-textfield>
    `;
  }

  __renderFrequencyDropdown() {
    return html`
      <neb-select
        id="${ELEMENTS.frequencyDropdown.id}"
        name="frequency"
        label="Frequency"
        helper="Required"
        .value="${this.state.frequency}"
        disabled
      ></neb-select>
    `;
  }

  __renderVisitSubscriptionFields() {
    return html`
      ${this.__renderFrequencyDropdown()}

      <div class="flex-row unlimited-row">
        <neb-checkbox
          id="${ELEMENTS.unlimitedCheckbox.id}"
          name="unlimited"
          label="Unlimited"
          .value="${this.state.unlimited}"
          .onChange="${this.handlers.changeUnlimited}"
          ?checked="${this.state.unlimited}"
          ?disabled="${this.encountersAssociated || !this.state.active}"
        ></neb-checkbox>

        <neb-tooltip
          id="${ELEMENTS.unlimitedTooltip.id}"
          class="tooltip"
          defaultAnchor="right"
        >
          <div slot="tooltip">
            Optionally enter 'Visits' to limit the number of visits allowed, or
            check 'Unlimited'.
          </div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderServiceBasedLimits() {
    return html`
      ${this.__renderTotalPriceField()}
      ${this.state.subscription ? this.__renderFrequencyDropdown() : ''}
    `;
  }

  __renderVisitBasedLimits() {
    return html`
      <neb-textfield
        id="${ELEMENTS.totalVisitsField.id}"
        name="totalVisits"
        label="${this.state.subscription ? '' : 'Total '}Visits"
        helper="Required"
        maxLength="3"
        .error="${this.errors.totalVisits}"
        .mask="${number}"
        .inputMode="${'numeric'}"
        .value="${this.state.totalVisits}"
        .onChange="${this.handlers.change}"
        ?disabled="${
          this.state.unlimited ||
            this.encountersAssociated ||
            !this.state.active
        }"
      ></neb-textfield>

      ${this.__renderTotalPriceField()}
      ${this.state.subscription ? this.__renderVisitSubscriptionFields() : ''}
    `;
  }

  __renderCompletePackageButton() {
    return this.state.active
      ? html`
          <div class="completed-button-row" ?dirty="${this.__dirty}">
            <neb-button
              id="${ELEMENTS.completedButton.id}"
              label="Complete Package"
              .onClick="${this.handlers.completePackage}"
              ?disabled="${!this.state.active}"
            ></neb-button>
            <neb-tooltip
              id="${ELEMENTS.completedTooltip.id}"
              class="completed-tooltip"
              defaultAnchor="top"
            >
              <div slot="tooltip">
                To complete the package, click 'Complete Package'. Once
                complete, charges may not be applied to this package, and any
                future scheduled payments for the package will be deleted.
              </div>
            </neb-tooltip>
          </div>
        `
      : '';
  }

  __renderPagination() {
    return html`
      <neb-pagination
        id="${ELEMENTS.pagination.id}"
        class="pagination"
        .pageCount="${this.__collectionState.pageCount}"
        .currentPage="${this.__collectionState.pageIndex}"
        .onPageChanged="${this.handlers.pageChanged}"
      ></neb-pagination>
    `;
  }

  __renderNoChargesText() {
    return 'There are no charges for this package';
  }

  __renderChargesTable() {
    return html`
      <neb-header label="Covered Charges"></neb-header>

      <neb-table-patient-package-charges
        id="${ELEMENTS.chargesTable.id}"
        class="table"
        .layout="${this.layout}"
        .type="${this.state.type}"
        .model="${this.__collectionState.pageItems}"
        >${this.__renderNoChargesText()}</neb-table-patient-package-charges
      >
      ${this.__renderPagination()}
    `;
  }

  __renderEncounterChargesPagination() {
    return html`
      <neb-pagination
        id="${ELEMENTS.encounterChargesPagination.id}"
        class="pagination"
        .pageCount="${this.__encounterChargesPageCount}"
        .currentPage="${this.__currentEncounterPageIndex}"
        .onPageChanged="${this.handlers.pageChangedEncounterCharges}"
      ></neb-pagination>
    `;
  }

  __renderNoEncounterChargesText() {
    return html`
      <div class="no-encounter-charges">${NO_ENCOUNTER_CHARGES_MESSAGE}</div>
    `;
  }

  __renderEncounterCharges() {
    return html`
      <neb-header label="Encounter Charges"></neb-header>

      <neb-table-encounter-charges-details
        id="${ELEMENTS.encounterChargesTable.id}"
        class="table"
        emptyMessage="${NO_ENCOUNTER_CHARGES_MESSAGE}"
        .model="${this.__encounterChargesCollectionState.pageItems}"
        .providers="${this.__providers}"
        .mode="${MODE.EXPAND}"
        .expandFlags="${this.__expandFlags}"
        .layout="${this.layout}"
        .config="${
          this.__hasSharedHistory
            ? SHARED_PACKAGE_ENCOUNTER_CHARGES_CONFIG
            : ENCOUNTER_CHARGES_CONFIG
        }"
        .onToggleExpand="${this.handlers.toggleExpand}"
        .onLinkClick="${this.handlers.encounterChargesLinkClick}"
        >${
          this.__renderNoEncounterChargesText()
        }</neb-table-encounter-charges-details
      >

      ${this.__renderEncounterChargesPagination()}
    `;
  }

  __renderSharedPatientsList() {
    return this.state.sharedCarePackage && this.__relationships.length > 0
      ? html`
          <neb-select
            id="${ELEMENTS.patientPackageRelatedPatients.id}"
            class="shared-care-package-select"
            name="patientPackageRelatedPatients"
            label="Select Patients"
            allLabel="Related Patients"
            helper="Required"
            .items="${this.__relationships}"
            .value="${this.state.patientPackageRelatedPatients || []}"
            .onChange="${this.handlers.changeSharedPatients}"
            .error="${this.errors.patientPackageRelatedPatients}"
            ?disabled="${!this.state.active}"
            multiSelect
            wrapText
          ></neb-select>
        `
      : '';
  }

  __renderCheckboxSharedCarePackage() {
    return html`
      <div class="column-one">
        <neb-checkbox
          id="${ELEMENTS.checkboxSharedCarePackage.id}"
          name="sharedCarePackage"
          label="Share with Patient Relationships"
          .onChange="${this.handlers.change}"
          ?disabled="${
            !this.state.active ||
              (!this.state.sharedCarePackage && this.__relationships.length < 2)
          }"
          ?checked="${this.state.sharedCarePackage}"
        ></neb-checkbox>
        <neb-text
          id="${ELEMENTS.packageHistory.id}"
          class="history"
          link
          caption
          .onClick="${this.handlers.historyClick}"
          >(View History)</neb-text
        >
      </div>

      ${this.__renderSharedPatientsList()}
    `;
  }

  __renderDefaultCheckBox() {
    return html`
      <div class="column-one">
        <neb-checkbox
          id="${ELEMENTS.defaultCheckbox.id}"
          name="isDefault"
          label="Default"
          ?checked="${!this.__packageUnshareable && this.state.isDefault}"
          .onChange="${this.handlers.change}"
          ?disabled="${
            this.disableDefault ||
              this.__packageUnshareable ||
              !this.model.active
          }"
        ></neb-checkbox>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="radio-buttons">
        <div class="flex-row">
          <neb-radio-button
            id="${ELEMENTS.visitsRadioButton.id}"
            name="type"
            label="Visits"
            .value="${PACKAGE_TYPE.VISIT}"
            ?checked="${this.state.type === PACKAGE_TYPE.VISIT}"
            disabled
          ></neb-radio-button>

          <neb-radio-button
            id="${ELEMENTS.servicesRadioButton.id}"
            name="type"
            label="Services"
            .value="${PACKAGE_TYPE.SERVICE}"
            ?checked="${this.state.type === PACKAGE_TYPE.SERVICE}"
            disabled
          ></neb-radio-button>
        </div>
      </div>
      <div class="grid">
        <neb-textfield
          id="${ELEMENTS.nameField.id}"
          name="name"
          label="Patient's Package Name"
          helper="Required"
          maxLength="50"
          .value="${this.state.name}"
          .error="${this.errors.name}"
          .onChange="${this.handlers.changeName}"
        ></neb-textfield>

        ${this.__renderDefaultCheckBox()}

        <div class="column-one">
          <neb-checkbox
            id="${ELEMENTS.subscriptionCheckbox.id}"
            name="subscription"
            label="Sell as Subscription"
            ?checked="${this.state.subscription}"
            disabled
          ></neb-checkbox>

          <neb-tooltip
            id="${ELEMENTS.tooltip.id}"
            class="tooltip"
            defaultAnchor="right"
          >
            <div slot="tooltip">
              ${
                this.__subscriptionsDisabled()
                  ? TOOL_TIP_SUBSCRIPTION_NOT_ALLOWED
                  : TOOL_TIP_SUBSCRIPTION_ALLOWED
              }
            </div>
          </neb-tooltip>
        </div>
        ${this.__renderCheckboxSharedCarePackage()}

        <div class="summary-container">
          <div class="bold">${PACKAGE_SUMMARY_HEADER}</div>

          ${this.__renderSummarySection()}
        </div>
      </div>
      <div class="grid grid-2">
        <neb-date-picker
          id="${ELEMENTS.effectiveDate.id}"
          name="effectiveDate"
          class="effective-date"
          helperText="Required"
          label="Effective Date"
          placeholder="Select Date"
          manualPopoverPosition="center"
          .onChange="${this.handlers.changeEffectiveDate}"
          .onClear="${this.handlers.clearEffectiveDate}"
          .isDateSelectable="${this.handlers.effectiveDateSelectable}"
          .selectedDate="${
            this.state.effectiveDate
              ? parseDate(this.state.effectiveDate)
              : this.state.effectiveDate
          }"
          ?disabled="${
            this.encountersAssociated ||
              !this.state.active ||
              this.state.subscription ||
              this.__hasSharedHistory
          }"
          ?invalid="${!!this.errors.effectiveDate}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.expirationDate.id}"
          name="expirationDate"
          class="expiration-date"
          label="Expiration Date"
          placeholder="Select Date"
          manualPopoverPosition="center"
          .onChange="${this.handlers.changeExpirationDate}"
          .onClear="${this.handlers.clearExpirationDate}"
          .isDateSelectable="${this.handlers.expirationDateSelectable}"
          .selectedDate="${
            this.state.expirationDate
              ? parseDate(this.state.expirationDate)
              : this.state.expirationDate
          }"
          ?disabled="${
            !this.state.effectiveDate ||
              this.encountersAssociated ||
              !this.state.active ||
              this.__expirationDateCompleted
          }"
          ?invalid="${!!this.errors.expirationDate}"
          momentFlag
        ></neb-date-picker>

        ${
          this.state.type === PACKAGE_TYPE.VISIT
            ? this.__renderVisitBasedLimits()
            : this.__renderServiceBasedLimits()
        }

        <neb-date-picker
          id="${ELEMENTS.completedDate.id}"
          name="completedDate"
          class="completed-date"
          label="Completion Date"
          placeholder="Select Date"
          manualPopoverPosition="center"
          .selectedDate="${
            this.state.completedDate
              ? parseDate(this.state.completedDate)
              : this.state.completedDate
          }"
          disabled="${true}"
          ?invalid="${!!this.errors.completedDate}"
          momentFlag
        ></neb-date-picker>

        ${this.__renderCompletePackageButton()}
      </div>

      ${this.__renderChargesTable()} ${this.__renderEncounterCharges()}
    `;
  }
}

customElements.define(
  'neb-form-patient-package-edit',
  NebFormPatientPackageEdit,
);
