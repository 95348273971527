import { formatEncounter } from './neb-charting-util';

export const ENCOUNTER_STATUS = {
  ALL: 'All',
  OPEN: 'Open',
  SIGNED: 'Signed',
};

export function filterEncounters(encountersArray, query) {
  const queryTerms = query.trim().split(' ');
  return encountersArray.filter(enc => {
    const search = `${enc.provider} ${enc.formattedServiceDate} ${
      enc.appointmentType
    }`;
    return queryTerms.every(text =>
      search.toLowerCase().includes(text.toLowerCase()),
    );
  });
}

export function cloneObjectArray(array) {
  return array.map(obj => ({ ...obj }));
}

export function filterEncountersByStatus(encountersArray, status) {
  const isSigned = encounter => encounter.signed;

  const isNotSigned = encounter => !encounter.signed;

  return encountersArray.filter(status === 'Open' ? isNotSigned : isSigned);
}

const __getPracticeInformation = ({
  practiceInformation: { item: practiceInfo },
}) => practiceInfo || {};

const __getFormattedEncounter = (
  encounter,
  { providers: { item: providers }, appointmentTypes: { items: apptTypes } },
  summary = false,
) =>
  providers.length > 0 && apptTypes.length > 0
    ? formatEncounter(encounter, providers, apptTypes, summary)
    : {};

export function formatEncounterSummaryData({
  encounter,
  notes,
  patient,
  state,
}) {
  const chartNotes = {
    subjective: '',
    objective: '',
    assessment: '',
    plan: '',
    ...notes,
  };
  return {
    chartNotes,
    practiceInfo: __getPracticeInformation(state),
    patient,
    encounter: __getFormattedEncounter(encounter, state, true),
  };
}

export const formatEncounterNumber = ({ encounterNumber, signed }) =>
  encounterNumber ? `${encounterNumber}${signed ? ' - Signed' : ''}` : '';

export const filterEncountersByServiceDate = (encounters, serviceDate) => {
  const { from, to } = serviceDate;

  const fromDate = from ? from.toISOString() : from;
  const toDate = to
    ? to
        .clone()
        .add(1, 'day')
        .toISOString()
    : to;

  const filtered = fromDate
    ? encounters.filter(encounter => encounter.serviceDate >= fromDate)
    : encounters;

  return toDate
    ? filtered.filter(encounter => encounter.serviceDate < toDate)
    : filtered;
};

export const filterEncountersByCases = (encounters, caseIds) =>
  caseIds.length
    ? encounters.filter(encounter => caseIds.includes(encounter.caseId))
    : encounters;

export const filterEncountersByProviders = (encounters, providerIds) =>
  providerIds.length
    ? encounters.filter(encounter => providerIds.includes(encounter.providerId))
    : encounters;

export const filterEncountersByAppointmentTypes = (
  encounters,
  appointmentTypeIds,
) =>
  appointmentTypeIds.length
    ? encounters.filter(encounter =>
        appointmentTypeIds.includes(encounter.appointmentTypeId),
      )
    : encounters;
