import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });
export const HEADERS = { 'Content-Type': 'application/json' };
export const BASE_URL = 'worklist-items';
export const API_VERSION = 1;

export const updateWorklistItems = ({
  invoiceIds = [],
  claimIds = [],
  hidden = false,
}) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: BASE_URL,
    headers: HEADERS,
    body: JSON.stringify({ invoiceIds, claimIds, hidden }),
    version: API_VERSION,
  });
