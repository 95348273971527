import {
  rawPatientAuthorizationToModel,
  rawPatientAuthorizationsToModel,
} from '../../neb-utils/patientAuthorization';

import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });
export const DEFAULT_VERSION = 1;
export const buildBaseAuthorizationUrl = (patientId, patientCaseId) =>
  `patients/${patientId}/cases/${patientCaseId}/authorization`;

export const getPatientAuthorizations = async (
  patientId,
  patientCaseId,
  version = DEFAULT_VERSION,
  optOutLoadingIndicator = false,
) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.GET,
    path: buildBaseAuthorizationUrl(patientId, patientCaseId),
    version,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return rawPatientAuthorizationsToModel(res);
};

export const createPatientAuthorization = async (
  patientId,
  patientCaseId,
  patientAuthorization,
  version = DEFAULT_VERSION,
) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: buildBaseAuthorizationUrl(patientId, patientCaseId),
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patientAuthorization),
    updateNotificationDetails: {
      patientCase: { id: patientCaseId },
    },
  });

  return rawPatientAuthorizationToModel(res);
};

export const putPatientAuthorization = async (
  patientAuthorization,
  version = DEFAULT_VERSION,
) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${buildBaseAuthorizationUrl(
      patientAuthorization.patientId,
      patientAuthorization.patientCaseId,
    )}/${patientAuthorization.id}`,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(patientAuthorization),
    updateNotificationDetails: {
      patientCase: { id: patientAuthorization.patientCaseId },
    },
  });

  return rawPatientAuthorizationToModel(res);
};
