import '../../../../../src/components/tables/user-maintenance/neb-table-user-maintenance';

import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import {
  USER_MANAGEMENT_ACTION_DISABLE_MESSAGE,
  USER_MANAGEMENT_ACTION_MESSAGE,
  USER_MANAGEMENT_ACTION_RE_ENABLE_MESSAGE,
} from '../../../../../src/utils/user-message';
import {
  resendUserInvitation,
  setUserActive,
  resetPassword,
} from '../../../../neb-api-client/src/permissions-api-client';
import { getPracticeUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../neb-lit-components/src/components/neb-page-collection';
import { OVERLAY_KEYS } from '../../../../neb-lit-components/src/utils/overlay-constants';
import { setItem } from '../../../../neb-login/neb-session-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { COGNITO_TYPES } from '../../../../neb-utils/cognito-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBetaSync,
} from '../../../../neb-utils/feature-util';
import {
  objToName,
  DEFAULT_NAME_OPTS,
  capitalize,
} from '../../../../neb-utils/formatters';
import { MODE } from '../../../../neb-utils/table';

export const BANNER_RESEND_ERROR =
  'There was an error while trying to send the invitation.';
export const BANNER_RESEND_SUCCESS = 'Invitation sent successfully.';
export const BANNER_REVOKE_SUCCESS = 'Invitation revoked successfully.';
export const BANNER_REVOKE_ERROR =
  'There was an error while trying to revoke the user’s invitation.';
export const BANNER_RESET_PASSWORD_SUCCESS =
  'The password has been reset successfully.';
export const BANNER_RESET_PASSWORD_ERROR =
  "There was an error resetting the user's password.";
export const CONFIRM_RESET_PASSWORD_MESSAGE =
  "Are you sure you want to reset the user's password?";
export const CONFIRM_REVOKE_MESSAGE = html`
  <span>
    This will revoke the invitation for the selected user. They will not be able
    to complete the registration and linking process, and will not be able to
    access the practice.
  </span>
  <br />
  <br />
  <span>Do you want to continue?</span>
`;
export const CONFIRM_RESEND_MESSAGE = html`
  <span>
    This will resend the invitation for the selected user. They will be able to
    complete the registration and linking process, and will be able to access
    the practice.
  </span>
  <br />
  <br />
  <span>Do you want to continue?</span>
`;
export const BANNER_INACTIVE_SUCCESS = 'User marked inactive successfully.';
export const BANNER_INACTIVE_ERROR =
  'There was an error marking the user inactive.';
export const CONFIRM_INACTIVE_MESSAGE = html`
  <span>
    This will mark the selected user as Inactive. The user will no longer be
    able to log in to the practice or access practice data.
  </span>
  <br />
  <br />
  <span>Do you want to continue?</span>
`;
export const CONFIRM_INACTIVE_TITLE = 'Disable User Access (Mark Inactive)';
export const RESEND_INVITATION_TITLE = 'Resend Invitation';
export const REVOKE_INVITATION_TITLE = 'Revoke Invitation';
export const BANNER_ACTIVE_SUCCESS = 'User marked active successfully.';
export const BANNER_ACTIVE_ERROR =
  'There was an error marking the user active.';
export const CONFIRM_ACTIVE_TITLE = 'Re-enable User Access (Mark Active)';
export const CONFIRM_ACTIVE_MESSAGE = html`
  <span>
    This will mark the selected user as Active. The user will be able to log in
    to the practice and will be able to access practice data.
  </span>
  <br />
  <br />
  <span>Do you want to continue?</span>
`;

export const ELEMENTS = { ...BASE_ELEMENTS };
const TABLE_CONFIG = [
  {
    sortable: false,
    truncate: true,
    key: 'name',
    label: 'Name',
    flex: css`2 0 0`,
    formatter: name => objToName(name, DEFAULT_NAME_OPTS),
  },
  {
    sortable: false,
    truncate: true,
    key: 'email',
    label: 'Email',
    flex: css`2 0 0`,
  },
  {
    sortable: false,
    truncate: true,
    key: 'type',
    label: 'Role',
    flex: css`1 0 0`,
    formatter: role => capitalize(role),
  },
  {
    sortable: false,
    truncate: true,
    key: 'NPI',
    label: 'NPI',
    flex: css`1 0 0`,
  },
  {
    sortable: false,
    truncate: true,
    key: 'superUser',
    label: 'Super User',
    flex: css`1 0 0`,
    formatter: superUser => (superUser ? 'Yes' : ''),
  },
  {
    sortable: false,
    truncate: true,
    key: 'status',
    label: 'Status',
    flex: css`1 0 0`,
    formatter: status => status || 'Pending',
  },
  {
    key: 'actionMenu',
    label: '',
    flex: css`0 0 40px`,
  },
];
const REVOKED = 'Pending (Revoked)';
const SORT_KEYS = ['last', 'suffix', 'first'];
const SEARCH_KEYS = [
  {
    parentKey: 'name',
    subKeys: ['first', 'middle', 'last', 'preferred', 'suffix'],
  },
  'email',
  'NPI',
];

class NebUserMaintenancePage extends CollectionPage {
  static get properties() {
    return {
      locations: Array,
    };
  }

  static get config() {
    return {
      description: 'View and manage all users for your practice.',
      initialSortKey: 'name',
      initialSortOrder: 'asc',
      overlayKey: OVERLAY_KEYS.USER_ADD,
      pluralName: 'User Maintenance',
      searchLabel: 'Enter Name, Email, or NPI to filter list below.',
      separateForm: true,
      singularName: 'New User',
      tableConfig: TABLE_CONFIG,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        neb-table-user-maintenance {
          --action-menu-min-width: 260px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();

    this.__initConfig();
  }

  __initState() {
    const {
      session: {
        item: { type: cognitoType },
      },
    } = store.getState();

    this.locations = [];

    const disableProviderActions =
      cognitoType !== COGNITO_TYPES.SUPPORT &&
      !hasFeatureOrBetaSync(FEATURE_FLAGS.ENABLE_PROVIDER_ACTIONS);

    this.getTrailingActions = item =>
      this.__config[item.status].getTrailingActions({
        item,
        disabled: disableProviderActions && item.type === 'provider',
      });

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      resend: async id => {
        try {
          await resendUserInvitation(id);
          return store.dispatch(openSuccess(BANNER_RESEND_SUCCESS));
        } catch (err) {
          console.log('err ', err);
          return store.dispatch(openError(BANNER_RESEND_ERROR));
        }
      },

      resendAfterRevoke: async id => {
        const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          message: CONFIRM_RESEND_MESSAGE,
          title: RESEND_INVITATION_TITLE,
          confirmText: 'Yes',
          cancelText: 'No',
        });
        if (!accepted) return;
        await this.__handlers.resend(id);
        const items = await this.fetch();
        this.service.setItems(items);
      },

      revoke: async id => {
        const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          message: CONFIRM_REVOKE_MESSAGE,
          title: REVOKE_INVITATION_TITLE,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (!accepted) {
          return undefined;
        }

        try {
          await setUserActive(id, false);
          const items = await this.fetch();
          this.service.setItems(items);
          return store.dispatch(openSuccess(BANNER_REVOKE_SUCCESS));
        } catch (err) {
          return store.dispatch(openError(BANNER_REVOKE_ERROR));
        }
      },

      resetPassword: async id => {
        const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          message: CONFIRM_RESET_PASSWORD_MESSAGE,
          title: 'Send Reset Password Email',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (!accepted) {
          return undefined;
        }

        try {
          await resetPassword(id);
          return store.dispatch(openSuccess(BANNER_RESET_PASSWORD_SUCCESS));
        } catch (err) {
          return store.dispatch(openError(BANNER_RESET_PASSWORD_ERROR));
        }
      },

      inactive: async id => {
        const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          message: CONFIRM_INACTIVE_MESSAGE,
          title: CONFIRM_INACTIVE_TITLE,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (!accepted) {
          return undefined;
        }

        try {
          await setUserActive(id, false);
          const items = await this.fetch();
          this.service.setItems(items);
          return store.dispatch(openSuccess(BANNER_INACTIVE_SUCCESS));
        } catch (err) {
          return store.dispatch(openError(BANNER_INACTIVE_ERROR));
        }
      },

      active: async id => {
        const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          message: CONFIRM_ACTIVE_MESSAGE,
          title: CONFIRM_ACTIVE_TITLE,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (!accepted) {
          return undefined;
        }

        try {
          await setUserActive(id, true);
          const items = await this.fetch();
          this.service.setItems(items);
          return store.dispatch(openSuccess(BANNER_ACTIVE_SUCCESS));
        } catch (err) {
          return store.dispatch(openError(BANNER_ACTIVE_ERROR));
        }
      },
      disabledUserAction: (title, message) =>
        openPopup(POPUP_RENDER_KEYS.MESSAGE, { title, message }),
    };
  }

  buildContext() {
    return {
      locations: this.locations,
    };
  }

  __initConfig() {
    this.__config = {
      Pending: {
        getTrailingActions: ({ item, disabled }) => [
          {
            icon: 'neb:resend',
            label: RESEND_INVITATION_TITLE,
            onSelect: () => this.__handlers.resend(item.id),
          },
          {
            icon: 'neb:userX',
            label: REVOKE_INVITATION_TITLE,
            disabled,
            onSelect: () =>
              disabled
                ? this.__handlers.disabledUserAction(
                    REVOKE_INVITATION_TITLE,
                    USER_MANAGEMENT_ACTION_MESSAGE('revoke'),
                  )
                : this.__handlers.revoke(item.id),
          },
        ],
      },
      Active: {
        getTrailingActions: ({ item, disabled }) => [
          {
            icon: 'neb:key',
            label: 'Send Reset Password Email',
            onSelect: () => this.__handlers.resetPassword(item.id),
          },
          {
            icon: 'neb:userX',
            label: CONFIRM_INACTIVE_TITLE,
            disabled,
            onSelect: () =>
              disabled
                ? this.__handlers.disabledUserAction(
                    CONFIRM_INACTIVE_TITLE,
                    USER_MANAGEMENT_ACTION_DISABLE_MESSAGE(),
                  )
                : this.__handlers.inactive(item.id),
          },
        ],
      },
      Inactive: {
        getTrailingActions: ({ item, disabled }) => [
          {
            icon: 'neb:userCheck',
            label: CONFIRM_ACTIVE_TITLE,
            disabled,
            onSelect: () =>
              disabled
                ? this.__handlers.disabledUserAction(
                    CONFIRM_ACTIVE_TITLE,
                    USER_MANAGEMENT_ACTION_RE_ENABLE_MESSAGE(),
                  )
                : this.__handlers.active(item.id),
          },
        ],
      },
      [REVOKED]: {
        getTrailingActions: ({ item, disabled }) => [
          {
            icon: 'neb:resend',
            label: RESEND_INVITATION_TITLE,
            disabled,
            onSelect: () =>
              disabled
                ? this.__handlers.disabledUserAction(
                    RESEND_INVITATION_TITLE,
                    USER_MANAGEMENT_ACTION_MESSAGE('resend'),
                  )
                : this.__handlers.resendAfterRevoke(item.id),
          },
        ],
      },
    };
  }

  renderNoItemsContent() {
    return '';
  }

  renderTable() {
    return html`
      <neb-table-user-maintenance
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        .config="${TABLE_CONFIG}"
        emptyMessage="No results."
        .model="${this.__tableState.pageItems}"
        .mode="${MODE.DETAIL}"
        .onSelectRow="${this.handlers.selectItem}"
        .getTrailingActions="${this.getTrailingActions}"
      >
      </neb-table-user-maintenance>
    `;
  }

  fetch() {
    return getPracticeUsers();
  }

  changeOccurred(state) {
    const {
      id,
      firstName,
      lastName,
      profileImgUrl,
    } = store.getState().session.item;

    const currentUser = state.allItems.find(user => user.id === id);

    if (currentUser) {
      const {
        name: { first, last },
        photo: { imageUrl },
      } = currentUser;

      if (
        firstName !== first ||
        lastName !== last ||
        profileImgUrl !== imageUrl
      ) {
        store.dispatch(
          setItem({
            ...store.getState().session.item,
            firstName: first,
            lastName: last,
            profileImgUrl: imageUrl,
          }),
        );
      }
    }
    this.onChange(state);
  }

  sort(a, b) {
    for (const key of SORT_KEYS) {
      const aValue = a.name[key];
      const bValue = b.name[key];
      if (aValue !== bValue) return aValue < bValue ? -1 : 1;
    }

    return 0;
  }

  filterSearchItem({ searchText, item, terms }) {
    if (!searchText) return true;
    const keyValues = SEARCH_KEYS.reduce((memo, key) => {
      const { parentKey, subKeys } = key;

      if (parentKey && subKeys) {
        const itemValue = item[parentKey];
        subKeys.forEach(subKey => memo.push(itemValue[subKey]));
        return memo;
      }

      return [...memo, item[key]];
    }, []);
    return terms.every(text =>
      keyValues.filter(Boolean).some(value =>
        value
          .toString()
          .toLowerCase()
          .includes(text.toLowerCase()),
      ),
    );
  }
}

customElements.define('neb-user-maintenance-page', NebUserMaintenancePage);
