import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_BLACK,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_DISABLED,
} from '../../../../neb-styles/neb-variables';

export const TAB_ROLE = {
  SECTION: 'section',
  HIGHLIGHT: 'highlight',
};

export const ELEMENTS = {
  tab: { id: 'tab' },
  underline: { id: 'underline' },
};

class NebTab extends LitElement {
  static get properties() {
    return {
      label: String,
      role: String,

      selected: {
        reflect: true,
        type: Boolean,
      },

      disabled: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          cursor: pointer;
          user-select: none;
          color: ${CSS_COLOR_BLACK};
        }

        :host([selected]) {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([disabled]) {
          cursor: default;
        }

        .container {
          position: relative;
          padding: 0 0 1rem 0;
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .container[role='section'] {
          padding: ${CSS_SPACING};
          border-radius: 4px 4px 0 0;
        }

        .container[selected][role='section'] {
          color: ${CSS_COLOR_HIGHLIGHT};
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([disabled]) .label {
          color: ${CSS_COLOR_DISABLED};
        }

        .label[role='section'] {
          font-weight: 700;
        }

        .underline {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 3px;
          opacity: 0;
          background-color: ${CSS_COLOR_HIGHLIGHT};
          transition: opacity 200ms ease-in;
        }

        .underline[selected] {
          opacity: 1;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.selected = false;
    this.label = '';
    this.role = TAB_ROLE.HIGHLIGHT;
    this.disabled = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clicked: () => {
        if (!this.disabled) {
          this.onClick(this.id);
        }
      },
    };
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.tab.id}"
        class="container"
        role="${this.role}"
        ?selected="${this.selected}"
        @click="${this.__handlers.clicked}"
      >
        <span class="label" role="${this.role}">${this.label}</span>

        ${
          this.role === TAB_ROLE.HIGHLIGHT
            ? html`
                <div
                  id="${ELEMENTS.underline.id}"
                  class="underline"
                  ?selected="${this.selected}"
                ></div>
              `
            : ''
        }
      </div>
    `;
  }
}

window.customElements.define('neb-tab', NebTab);
