import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_ERROR,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_1,
  CSS_COLOR_DISABLED,
  CSS_FONT_SIZE_BODY,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  text: {
    id: 'text',
  },
};

class FloatingLabel extends LitElement {
  static get properties() {
    return {
      focused: {
        reflect: true,
        type: Boolean,
      },
      invalid: {
        reflect: true,
        type: Boolean,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      pinned: {
        reflect: true,
        type: Boolean,
      },
      text: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.focused = false;
    this.invalid = false;
    this.disabled = false;
    this.pinned = false;
    this.text = '';
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          position: absolute;
          pointer-events: none;
          top: 0;
          left: 4px;
          width: 100%;
          height: 18px;

          --left-docked: 12px;
          --right-docked: 0px;
        }

        .container {
          position: relative;
          width: 100%;
          height: 100%;
        }

        .text {
          position: absolute;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          top: var(--neb-floating-label-top, 26px);
          left: var(--left-docked);
          margin: 0;
          padding: 0;
          width: calc(100% - var(--left-docked) - var(--right-docked) - 4px);
          min-width: 0;
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_GREY_1};
          transition: top 200ms, left 200ms;
        }

        :host([pinned]) .text {
          top: -2px;
          left: 2px;
          font-size: 12px;
          color: ${CSS_COLOR_BLACK};
          width: calc(100% - 2px);
        }

        :host([focused]:not([invalid])) .text {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        :host([invalid]:not([disabled])) .text {
          color: ${CSS_COLOR_ERROR};
        }

        :host([disabled]) .text {
          color: ${CSS_COLOR_DISABLED};
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="container">
        <p id="${ELEMENTS.text.id}" class="text">${this.text}</p>
      </div>
    `;
  }
}

window.customElements.define('neb-floating-label', FloatingLabel);
