import '../inputs/neb-textfield';

import { html, css } from 'lit';

import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { currency } from '../../../../neb-utils/masks';

import NebTableOld, { ELEMENTS as ELEMENTS_BASE } from './neb-table-old';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  amountFields: {
    selector: '.field-amount',
  },
};
class NebLedgerFeeChargesTable extends NebTableOld {
  initState() {
    super.initState();
    this.writable = true;
    this.showRemoveButton = true;
    this.config = this.__buildTableConfig();
  }

  __buildTableConfig() {
    return [
      {
        key: 'code',
        label: 'Code',
        flex: css`0 0 45px`,
        mobile: true,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`1 0 0`,
        mobile: true,
      },
      {
        key: 'amount',
        label: 'Amount',
        flex: css`0 0 110px`,
        mobile: true,
      },
    ];
  }

  update(changedProps) {
    if (changedProps.has('layout')) {
      this.config = this.__buildTableConfig();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          padding: 0 ${CSS_SPACING};
        }

        .row {
          align-items: center;
        }

        .cell {
          align-items: center;
          white-space: unset;
        }

        .description {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .field-amount {
          padding-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderCell(value, key, rowIndex) {
    const name = `${rowIndex}.${key}`;
    const errors = this.errors[rowIndex];

    switch (key) {
      case 'amount':
        return html`
          <neb-textfield
            class="field-amount"
            label=" "
            maxLength="11"
            .name="${name}"
            .mask="${currency}"
            .inputMode="${'numeric'}"
            .value="${value}"
            .error="${errors.amount}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        `;

      case 'description':
        return html`
          <div class="description">${value}</div>
        `;

      default:
        return value;
    }
  }
}
customElements.define('neb-table-ledger-fee-charges', NebLedgerFeeChargesTable);
