import { addDays, addMonths, format, parse, subDays } from 'date-fns';

import { parseDate } from './date-util';

export const formatDate = (momentFlag, date, formatSpec) =>
  momentFlag ? date.format(formatSpec) : format(date, formatSpec);

const computeWeekRange = (momentFlag, date, startOffset, endOffset) => {
  const mapper = () =>
    momentFlag ? parseDate(date) : new Date(date.getTime());
  const [start, end] = Array(2)
    .fill()
    .map(mapper);

  if (momentFlag) {
    start.date(start.date() - start.day() + startOffset);
    end.date(end.date() + (endOffset - end.day()));
  } else {
    start.setDate(start.getDate() - start.getDay() + startOffset);
    end.setDate(end.getDate() + (endOffset - end.getDay()));
  }

  return { start, end };
};

export const computeWorkWeekRange = (momentFlag, date) =>
  computeWeekRange(momentFlag, date, 1, 6);

export const computeFullWeekRange = (momentFlag, date) =>
  computeWeekRange(momentFlag, date, 0, 7);

export const getDatePickerLocaleDate = (momentFlag, date) => {
  if (momentFlag) {
    return date.format('L');
  }
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

export const parseDatePickerInput = (momentFlag, value) => {
  if (momentFlag) {
    const [month, day, year] = value.split('/');

    return parseDate()
      .year(year)
      .month(month - 1)
      .date(day)
      .startOf('day');
  }

  return parse(value);
};

export const formatSelectedTime = (momentFlag, date) => {
  if (momentFlag) {
    return date.format ? date.format('h:mm A') : 'Invalid Date';
  }
  return format(date, 'h:mm A');
};

export const calendarPickerUtils = {
  genericAdd: (momentFlag, date, [amount, unit]) => {
    switch (unit) {
      case 'day':
        if (momentFlag) {
          return date.clone().add(amount, 'days');
        }
        return addDays(date, amount);
      case 'month':
        if (momentFlag) {
          return date.clone().add(amount, 'months');
        }
        return addMonths(date, amount);

      default:
        throw Error('Illegal arguments');
    }
  },
  weekFormat: (momentFlag, { start, end }) => {
    const FORMAT = 'MMMM DD';

    if (momentFlag) {
      const dayBeforeEnd = end.clone().subtract(1, 'day');

      const startOfWeek = start.format(FORMAT);
      const endOfWeek = dayBeforeEnd.format(FORMAT);

      return `${startOfWeek} - ${endOfWeek}, ${dayBeforeEnd.year()}`;
    }
    const startOfWeek = format(start, FORMAT);
    const endOfWeek = format(subDays(end, 1), FORMAT);
    return `${startOfWeek} - ${endOfWeek}, ${end.getFullYear()}`;
  },
};
