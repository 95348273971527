import './neb-table-row';
import './neb-table-header';

import { html, css, LitElement } from 'lit';

export const ELEMENTS = {
  notItemsContainer: {
    id: 'no-items-container',
  },
  tableHeader: {
    id: 'table-header',
  },
  rows: {
    selector: 'neb-table-row',
  },
};

class NebTableAdd extends LitElement {
  static get properties() {
    return {
      allAdded: {
        type: Boolean,
      },
      config: {
        type: Object,
      },
      items: {
        type: Array,
      },
      selectedItems: {
        type: Array,
      },
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.allAdded = false;
    this.items = [];
    this.selectedItems = [];
    this.config = {
      addAllLabel: '',
      allAddedLabel: '',
      tableConfig: [],
    };

    this.onAdd = () => {};

    this.onAddAll = () => {};
  }

  initHandlers() {
    this.__handlers = {
      add: (model, index) => this.onAdd(model, index),
      addAll: () => this.onAddAll(),
    };
  }

  get __pluralName() {
    if (!this.config || !this.config.itemPluralName) return '';
    const lowercaseItemPluralName = this.config.itemPluralName;
    return this.__capitalize(lowercaseItemPluralName);
  }

  update(changedProps) {
    this.__headerAddLabel = this.allAdded
      ? `All ${this.__pluralName} Added`
      : `Add All ${this.__pluralName}`;

    super.update(changedProps);
  }

  __capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static get styles() {
    return css`
      :host([layout='small']) .row {
        --custom-add-button-column-width: 30px;
      }
    `;
  }

  __renderHeader() {
    return html`
      <neb-table-header
        id="${ELEMENTS.tableHeader.id}"
        class="header-table"
        .config="${
          this.layout === 'small'
            ? this.config.mobileTableConfig
            : this.config.tableConfig
        }"
        .layout="${this.layout}"
        .allAdded="${this.allAdded}"
        .addAllLabel="${this.__headerAddLabel}"
        .onAddAll="${this.__handlers.addAll}"
      ></neb-table-header>
    `;
  }

  __renderNoItemsContainer() {
    return html`
      <div id="${ELEMENTS.notItemsContainer.id}"><slot></slot></div>
    `;
  }

  __renderRows() {
    return this.items.map((item, index) => {
      const added = this.selectedItems.some(i => i.id === item.id);
      return html`
        <neb-table-row
          class="row"
          .config="${
            this.layout === 'small'
              ? this.config.mobileTableConfig
              : this.config.tableConfig
          }"
          .layout="${this.layout}"
          .model="${item}"
          .index="${index}"
          .topic="${this.__capitalize(this.config.itemName)}"
          .onAdd="${this.__handlers.add}"
          .added="${added}"
        ></neb-table-row>
      `;
    });
  }

  render() {
    return html`
      ${this.__renderHeader()}
      ${
        this.items && this.items.length
          ? this.__renderRows()
          : this.__renderNoItemsContainer()
      }
    `;
  }
}
customElements.define('neb-table-add', NebTableAdd);
