import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_2,
} from '../../../../neb-styles/neb-variables';

class LinearProgress extends LitElement {
  static get properties() {
    return {
      value: Number,
      indeterminate: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;

          --fill-color: ${CSS_COLOR_HIGHLIGHT};
        }

        .container {
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 4px;
          background-color: ${CSS_COLOR_GREY_2};
        }

        .progress {
          width: 0;
          height: 100%;
          background-color: var(--fill-color);
        }

        :host(:not([indeterminate])) .progress {
          max-width: 100%;
          transition: width 200ms;
        }

        :host([indeterminate]) .progress::before {
          content: '';
          position: absolute;
          background-color: inherit;
          top: 0;
          left: 0;
          bottom: 0;
          will-change: left, right;
          animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
            infinite;
        }

        :host([indeterminate]) .progress::after {
          content: '';
          position: absolute;
          background-color: inherit;
          top: 0;
          left: 0;
          bottom: 0;
          will-change: left, right;
          animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
            infinite;
          animation-delay: 1.15s;
        }
      `,
      css`
        @keyframes indeterminate {
          0% {
            left: -35%;
            right: 100%;
          }

          60% {
            left: 100%;
            right: -90%;
          }

          100% {
            left: 100%;
            right: -90%;
          }
        }

        @keyframes indeterminate-short {
          0% {
            left: -200%;
            right: 100%;
          }

          60% {
            left: 107%;
            right: -8%;
          }

          100% {
            left: 107%;
            right: -8%;
          }
        }
      `,
    ];
  }

  constructor() {
    super();

    this.indeterminate = false;
    this.value = 0.5;
  }

  render() {
    return html`
      <div class="container">
        ${
          this.indeterminate
            ? html`
                <div class="progress"></div>
              `
            : html`
                <div class="progress" style="width: ${this.value * 100}%"></div>
              `
        }
      </div>
    `;
  }
}

window.customElements.define('neb-progress-linear', LinearProgress);
