import './neb-tab-old';

import { LitElement, html, css } from 'lit';

import { CSS_SPACING } from '../../../neb-styles/neb-variables';

class NebTabsOld extends LitElement {
  static get properties() {
    return {
      small: {
        type: Boolean,
        reflect: true,
      },
      primary: {
        type: Boolean,
        reflect: true,
      },
      selected: {
        type: String,
        reflect: true,
      },
    };
  }

  get tabs() {
    const nodes = this.shadowRoot.querySelector('slot').assignedNodes();
    return nodes.filter(node => node.nodeName === 'NEB-TAB-OLD');
  }

  constructor() {
    super();
    this.selected = null;
    this.small = false;
    this.primary = false;

    this.__initHandlers();

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: name => {
        this.onChange(name);
      },
    };
  }

  __handleSelect(name) {
    this.__resetAllUnderLine();

    const selectedTab = this.tabs.find(tab => tab.name === name);
    if (selectedTab) selectedTab.underline = true;
  }

  __resetAllUnderLine() {
    this.tabs.forEach(tab => {
      tab.underline = false;
    });
  }

  __setupTabs() {
    this.tabs.forEach(tab => {
      tab.onClick = this.__handlers.click;
      tab.small = this.small;
      tab.primary = this.primary;
    });
  }

  reset() {
    this.selected = null;

    this.__resetAllUnderLine();

    this.tabs[0].underline = !this.small;
  }

  updated(changed) {
    if (changed.has('selected') && this.selected) {
      this.__handleSelect(this.selected);
    }

    this.__setupTabs();
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-grow: 1;
      }

      .tabs-container {
        display: flex;
        flex-direction: row;
      }

      :host([small]) .tabs-container {
        flex-direction: column;
        flex-grow: 1;
      }

      :host([small][selected]) .tabs-container {
        display: none;
      }

      :host([primary]) .tabs-container {
        padding-top: ${CSS_SPACING};
        flex-grow: 1;
      }
    `;
  }

  render() {
    return html`
      <div class="tabs-container"><slot></slot></div>
    `;
  }
}

window.customElements.define('neb-tabs-old', NebTabsOld);
