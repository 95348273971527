import '../neb-text';
import '../neb-radio-button';
import '../inputs/neb-textfield';

import { html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../../neb-styles/neb-variables';
import {
  formatDollarAmountWithNegative,
  centsToCurrency,
  currencyToCents,
} from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import * as selectors from '../../../../neb-utils/selectors';
import { required } from '../../../../neb-utils/validators';
import { BUTTON_ROLE } from '../neb-button';

import NebForm, { ELEMENTS as BASE_ELEMENTS } from './neb-form';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  paymentAmountText: {
    id: 'payment-amount-text',
  },
  currentBalanceText: {
    id: 'current-balance-text',
  },
  totalBalanceRadio: {
    id: 'total-balance-radio',
  },
  otherAmountRadio: {
    id: 'other-amount-radio',
  },
  otherAmountInput: {
    id: 'other-amount-input',
  },
  nextButton: {
    id: 'next-button',
  },
  cancelButton: {
    id: 'cancel-button',
  },
};

const DEFAULT_AMOUNT = '$0.00';
const OTHER_AMOUNT_LABEL = 'Other Amount';
const TOTAL_BALANCE_LABEL = 'Total Balance';

class NebFormPatientOnlinePayment extends NebForm {
  static get styles() {
    return css`
      .grid {
        display: grid;
        grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        align-items: center;
      }

      .grid-auto-left {
        grid-gap: 0 12px;
        grid-template-columns: auto 1fr;
      }

      .grid-padding {
        padding-top: ${CSS_SPACING};
      }

      .payment-amount-text {
        font-weight: bold;
        padding-top: 5px;
      }

      .total-balance {
        padding: 0 50px;
      }

      .other-amount {
        padding: 15px 0px 0px 40px;
      }

      .action-buttons {
        padding-top: ${CSS_SPACING};
      }

      .button-next {
        padding: ${CSS_SPACING} ${CSS_SPACING} 0 0;
      }
    `;
  }

  initState() {
    super.initState();

    this.onNext = () => {};
  }

  static createModel() {
    return {
      paymentAmount: 0,
      currentBalance: 0,
      otherAmount: 0,
      radioTotal: true,
      radioOther: false,
    };
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      changeRadioTotal: ({ name }) => {
        this.formService.apply(name, true);
        this.formService.apply('radioOther', false);

        this.formService.apply('paymentAmount', this.state.currentBalance);

        this.formService.apply(
          'otherAmount',
          centsToCurrency(this.model.otherAmount),
        );
      },
      changeRadioOther: ({ name }) => {
        this.formService.apply(name, true);
        this.formService.apply('radioTotal', false);

        this.formService.apply(
          'paymentAmount',
          currencyToCents(this.state.otherAmount),
        );
      },
      changeOtherAmount: ({ name, value }) => {
        this.formService.apply(name, value);

        this.formService.apply('paymentAmount', currencyToCents(value));
      },
      next: () => {
        if (this.formService.validate()) {
          const model = this.formService.build();
          this.onNext(model);
        }
      },
    };
  }

  createSelectors() {
    return {
      children: {
        otherAmount: selectors.currency({
          validators: [
            required(),
            {
              error: 'Must be greater than $0.00',
              validate: v => v !== DEFAULT_AMOUNT || this.state.radioTotal,
            },
            {
              error: 'Cannot exceed $200,000.00',
              validate: v =>
                parseInt(Number(v.replace(/[^0-9.]+/g, '') * 100), 10) <=
                20000000,
            },
          ],
        }),
      },
    };
  }

  renderActionBar() {
    return html`
      <div class="action-buttons">
        <neb-button
          id="${ELEMENTS.nextButton.id}"
          class="button-next"
          .label="${'Next'}"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.next}"
          ?disabled="${this.state.paymentAmount <= 0}"
          unelevated
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button-cancel"
          .label="${'Cancel'}"
          .role="${BUTTON_ROLE.CANCEL}"
          .onClick="${this.handlers.cancel}"
          unelevated
        ></neb-button>
      </div>
    `;
  }

  __renderBalanceSelection() {
    return html`
      <div class="grid grid-auto-left grid-padding">
        <neb-text
          id="${ELEMENTS.paymentAmountText.id}"
          name="paymentAmount"
          class="payment-amount-text"
          >Payment Amount:
          ${formatDollarAmountWithNegative(this.state.paymentAmount)}</neb-text
        >
      </div>

      <div class="grid grid-auto-left grid-padding">
        <neb-radio-button
          id="${ELEMENTS.totalBalanceRadio.id}"
          name="radioTotal"
          .label="${TOTAL_BALANCE_LABEL}"
          .value="${this.state.radioTotal}"
          .onChange="${this.handlers.changeRadioTotal}"
          ?checked="${!!this.state.radioTotal}"
        ></neb-radio-button>

        <neb-text class="total-balance" id="${ELEMENTS.currentBalanceText.id}"
          >${
            formatDollarAmountWithNegative(this.state.currentBalance)
          }</neb-text
        >
      </div>

      <div class="grid grid-auto-left">
        <neb-radio-button
          id="${ELEMENTS.otherAmountRadio.id}"
          name="radioOther"
          .label="${OTHER_AMOUNT_LABEL}"
          .value="${this.state.radioOther}"
          .onChange="${this.handlers.changeRadioOther}"
          ?checked="${!!this.state.radioOther}"
        ></neb-radio-button>

        <div class="other-amount">
          <neb-textfield
            id="${ELEMENTS.otherAmountInput.id}"
            name="otherAmount"
            maxLength="11"
            helper=" "
            .mask="${currency}"
            .inputMode="${'numeric'}"
            .value="${this.state.otherAmount}"
            .error="${this.errors.otherAmount}"
            .onChange="${this.handlers.changeOtherAmount}"
            ?disabled="${!this.state.radioOther}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="container-content">${this.__renderBalanceSelection()}</div>
    `;
  }
}

customElements.define(
  'neb-form-patient-online-payment',
  NebFormPatientOnlinePayment,
);
