import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_3,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../../packages/neb-styles/neb-variables';

import '../../neb-icon';

export const ELEMENTS = {
  container: { id: 'container' },
  columns: { selector: '[id^=column-]' },
  items: { selector: '[id^=item-]' },
};

class NebFolderFinder extends LitElement {
  static get properties() {
    return {
      selectedFolderId: String,
      selectedItemStack: Array,
      folderStack: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          overflow-x: auto;
          height: 100%;
        }

        .column {
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          flex-shrink: 0;
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        .column:first-of-type {
          font-weight: bold;
        }

        .item {
          display: grid;
          grid-template-columns: 1fr 10px;
          align-items: center;
          grid-column-gap: ${CSS_SPACING};
          padding: ${CSS_SPACING};
          cursor: pointer;
        }

        .item[selected] {
          background-color: ${CSS_COLOR_GREY_3};
        }

        .item[selected][targeted] {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_WHITE};
        }

        .item[selected][targeted] .chevron {
          fill: ${CSS_COLOR_WHITE};
        }

        .item:hover {
          background-color: ${CSS_COLOR_GREY_4};
          color: ${CSS_COLOR_BLACK};
        }

        .chevron {
          width: 10px;
          height: 10px;
          transform: rotate(-90deg);
          fill: ${CSS_COLOR_BLACK};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.selectedFolderId = '';
    this.selectedItemStack = [];
    this.folderStack = [];

    this.onSelectItem = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectItem: e => this.onSelectItem(e),
    };
  }

  updated(changedProps) {
    if (changedProps.has('folderStack') && this.folderStack.length) {
      const lastColumn = this.shadowRoot.getElementById(
        `column-${this.folderStack.length - 1}`,
      );
      lastColumn.scrollIntoView({ behavior: 'smooth' });
    }

    super.update(changedProps);
  }

  __renderIcon(item) {
    return item.folderCount > 0
      ? html`
          <neb-icon class="chevron" icon="neb:chevron"></neb-icon>
        `
      : '';
  }

  __isSelected(item, rowIndex, colIndex) {
    return this.selectedItemStack[colIndex]
      ? item.id === this.selectedItemStack[colIndex].folderId &&
          rowIndex === this.selectedItemStack[colIndex].rowIndex
      : false;
  }

  __isTargeted(item, rowIndex, colIndex) {
    return this.selectedItemStack[colIndex]
      ? this.selectedFolderId === item.id &&
          this.__isSelected(item, rowIndex, colIndex)
      : false;
  }

  __renderItems(items, colIndex) {
    return items.map(
      (item, rowIndex) => html`
        <div
          id="item-${colIndex}-${rowIndex}"
          class="item"
          .index="${colIndex}"
          .rowIndex="${rowIndex}"
          .item="${item}"
          ?selected="${this.__isSelected(item, rowIndex, colIndex)}"
          ?targeted="${this.__isTargeted(item, rowIndex, colIndex)}"
          @click="${this.__handlers.selectItem}"
        >
          <span> ${item.name || 'untitled'} </span>${this.__renderIcon(item)}
        </div>
      `,
    );
  }

  __renderColumns() {
    return this.folderStack.map(
      (col, index) =>
        html`
          <div id="column-${index}" class="column">
            ${this.__renderItems(col, index)}
          </div>
        `,
    );
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        ${this.__renderColumns()}
      </div>
    `;
  }
}

customElements.define('neb-folder-finder', NebFolderFinder);
