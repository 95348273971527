import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

const baseUrl = 'billing-information';

export const createBillingInfo = (
  billingInfo,
  optOutLoadingIndicator = false,
  version = 1,
) =>
  apiClient.makeRequest({
    method: Method.POST,
    path: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(billingInfo),
    optOutLoadingIndicator,
    version,
  });

export const updateBillingInfo = (
  billingInfo,
  optOutLoadingIndicator = false,
  version = 1,
) =>
  apiClient.makeRequest({
    method: Method.PUT,
    path: `${baseUrl}/${billingInfo.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(billingInfo),
    optOutLoadingIndicator,
    version,
  });

export const getBillingInfo = async (
  optOutLoadingIndicator = false,
  version = 1,
) => {
  try {
    const billingInfo = await apiClient.makeRequest({
      method: Method.GET,
      path: baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      version,
      cacheKey: 'billing-info',
      optOutLoadingIndicator,
    });

    return billingInfo;
  } catch (err) {
    return null;
  }
};
