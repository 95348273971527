import { hoursToObj, objToHours } from '../../neb-utils/utils';

import * as appointmentTypesApi from './appointment-types';
import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });
export const headerJSON = { 'Content-Type': 'application/json' };
export const baseUrl = 'provider-availability';

async function fetchTypeIds() {
  const rawTypes = await appointmentTypesApi.fetchMany(true);

  return rawTypes.map(item => item.id);
}

function mapToDayModel(rawDay, typeIds) {
  return rawDay
    ? {
        enabled: rawDay.enabled,
        segments: rawDay.periods.map(item => ({
          start: hoursToObj(item.start),
          end: hoursToObj(item.end),
          types: item.allTypes
            ? typeIds
            : item.types.filter(type => typeIds.includes(type)),
          locations: item.locations || [undefined],
          slotInterval: item.slotInterval,
          multiBookingCount: item.multiBookingCount,
          color: item.color,
        })),
      }
    : {
        enabled: false,
        segments: [],
      };
}

function mapItemToModel(raw, providerId, typeIds) {
  const input = raw.availability || {
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: null,
  };

  return {
    providerId,
    week: [
      mapToDayModel(input.Monday, typeIds),
      mapToDayModel(input.Tuesday, typeIds),
      mapToDayModel(input.Wednesday, typeIds),
      mapToDayModel(input.Thursday, typeIds),
      mapToDayModel(input.Friday, typeIds),
      mapToDayModel(input.Saturday, typeIds),
      mapToDayModel(input.Sunday, typeIds),
    ],
  };
}

function mapToModel(raw, typeIds) {
  const input = raw || {
    enabled: false,
    segments: [],
  };

  return Object.keys(input).map(key =>
    mapItemToModel(input[key], key, typeIds),
  );
}

function mapToDayRaw(day, typeIds) {
  return {
    enabled: day.enabled,
    periods: day.segments.map(item => {
      const allTypes = item.types.length === typeIds.length;

      return {
        allTypes,
        start: objToHours(item.start),
        end: objToHours(item.end),
        types: allTypes ? [] : item.types,
        locations: item.locations || [],
        slotInterval: item.slotInterval,
        multiBookingCount: item.multiBookingCount,
        color: item.color || '#ffffff',
      };
    }),
  };
}

function mapToRaw(model, typeIds) {
  return {
    availability: {
      Monday: mapToDayRaw(model.week[0], typeIds),
      Tuesday: mapToDayRaw(model.week[1], typeIds),
      Wednesday: mapToDayRaw(model.week[2], typeIds),
      Thursday: mapToDayRaw(model.week[3], typeIds),
      Friday: mapToDayRaw(model.week[4], typeIds),
      Saturday: mapToDayRaw(model.week[5], typeIds),
      Sunday: mapToDayRaw(model.week[6], typeIds),
    },
  };
}

export const getAllProvidersAvailability = (optOutLoadingIndicator = true) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: baseUrl,
    version: 'public1',
    headers: headerJSON,
    cacheKey: 'get-all-providers-availability',
    optOutLoadingIndicator,
  });

export const getProviderAvailability = (
  providerId,
  optOutLoadingIndicator = true,
) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `${baseUrl}/${providerId}`,
    version: 'public2',
    headers: headerJSON,
    optOutLoadingIndicator,
  });

export const saveProviderAvailability = (
  providerId,
  model,
  optOutLoadingIndicator = true,
) =>
  apiClient.makeRequest({
    method: Method.POST,
    path: `${baseUrl}/${providerId}`,
    headers: headerJSON,
    body: JSON.stringify(model),
    cacheKey: 'get-all-providers-availability',
    optOutLoadingIndicator,
    version: 2,
  });

export async function save(providerId, model) {
  const typeIds = await fetchTypeIds();
  const body = mapToRaw(model, typeIds);
  const res = await saveProviderAvailability(providerId, body);

  return mapItemToModel(res.data[0], providerId, typeIds);
}

export async function fetchMany() {
  const typeIds = await fetchTypeIds();
  const res = await getAllProvidersAvailability();
  return mapToModel(res.data[0], typeIds);
}

export async function fetchManyActiveApptTypes() {
  const types = await appointmentTypesApi.fetchMany(true);
  const res = await getAllProvidersAvailability();

  return mapToModel(
    res.data[0],
    types.filter(type => type.active === true).map(item => item.id),
  );
}

export async function fetchProviderActiveApptTypes(providerId) {
  const [types, res] = await Promise.all([
    appointmentTypesApi.fetchMany(true),
    getProviderAvailability(providerId),
  ]);

  return mapItemToModel(
    res.data[0],
    providerId,
    types.filter(type => type.active === true).map(item => item.id),
  );
}
