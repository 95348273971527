import '../../../packages/neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { POPOVER_POSITION } from '../../../packages/neb-lit-components/src/components/neb-date-picker';
import { CSS_FIELD_MARGIN } from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { number } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';

import { NebFilters } from './neb-filters';

export const ELEMENTS = {
  searchBar: {
    id: 'search-bar',
  },
  encounterNumber: {
    id: 'encounter-number',
  },
  appointmentType: {
    id: 'appointment-type',
  },
  fromPicker: { id: 'picker-from' },
  toPicker: { id: 'picker-to' },
};

class NebFiltersDocumentsMobile extends NebFilters {
  static get properties() {
    return {
      appointmentTypes: Array,
      advancedSearchModel: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          gap: 0;
        }

        .dos-container {
          display: grid;
          position: relative;
          padding-top: ${CSS_FIELD_MARGIN};
          grid-gap: 0 12px;
          grid-template-columns: 1fr 1fr;
        }

        .field {
          width: 100%;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.appointmentTypes = [];
    this.advancedSearchModel = {};

    this.onApply = () => {};
  }

  static createModel() {
    return {
      searchText: '',
      encounterNumber: '',
      appointmentTypeId: {},
      dosFrom: null,
      dosTo: null,
    };
  }

  createSelectors() {
    return {
      children: {
        encounterNumber: selectors.numeric(),
        appointmentTypeId: selectors.select([]),
        dosFrom: selectors.date({ unsafe: true }),
        dosTo: selectors.date({ unsafe: true }),
      },
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      apply: () => {
        const model = this.formService.build();
        const { dosFrom, dosTo } = model;
        this.onApply({
          ...model,
          serviceDate: {
            from: dosFrom ? parseDate(dosFrom) : null,
            to: dosTo ? parseDate(dosTo) : null,
          },
        });
      },
      clearEncounterNumberSearch: () =>
        this.formService.apply('encounterNumber', ''),
      clearSearchText: () => this.formService.apply('searchText', ''),
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
    };
  }

  update(changedProps) {
    if (changedProps.has('advancedSearchModel') && this.advancedSearchModel) {
      this.__autoSetFilters();
    }

    super.update(changedProps);
  }

  __autoSetFilters() {
    Object.keys(this.advancedSearchModel).forEach(key => {
      if (this.advancedSearchModel[key]) {
        this.handlers.change({
          name: key,
          value: this.advancedSearchModel[key],
        });
      }
    });
  }

  __formatAppointmentsToItems() {
    const apptsFormatted = this.appointmentTypes.map(data => ({
      label: data.name,
      data,
    }));
    return [selectors.ITEM_EMPTY, ...apptsFormatted];
  }

  __renderSearchBarFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.searchBar.id}"
        name="searchText"
        label="Filter by Name or Tags"
        helper=" "
        leadingIcon="neb:search"
        .trailingIcon="${this.state.searchText ? 'neb:clear' : ''}"
        .inputMode="text"
        .value="${this.state.searchText}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearSearchText}"
      ></neb-textfield>
    `;
  }

  __renderEncounterNumberFilter() {
    return html`
      <neb-textfield
        id="${ELEMENTS.encounterNumber.id}"
        name="encounterNumber"
        label="Encounter Number"
        helper=" "
        maxLength="7"
        leadingIcon="neb:search"
        .trailingIcon="${this.state.encounterNumber ? 'neb:clear' : ''}"
        .mask="${number}"
        .inputMode="${'numeric'}"
        .value="${this.state.encounterNumber}"
        .onChange="${this.handlers.change}"
        .onClickIcon="${this.handlers.clearEncounterNumberSearch}"
      ></neb-textfield>
    `;
  }

  __renderAppointmentTypeFilter() {
    return html`
      <neb-select
        id="${ELEMENTS.appointmentType.id}"
        name="appointmentTypeId"
        label="Appointment Type"
        helper=" "
        .value="${this.state.appointmentTypeId}"
        .items="${this.__formatAppointmentsToItems()}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  __renderServiceDateFilter() {
    return html`
      <div class="dos-container">
        <neb-date-picker
          id="${ELEMENTS.fromPicker.id}"
          class="field"
          name="dosFrom"
          placeholder="From"
          .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${
            this.state.dosFrom ? parseDate(this.state.dosFrom) : null
          }"
          .onChange="${this.handlers.changeDate}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.toPicker.id}"
          class="field"
          name="dosTo"
          placeholder="To"
          .manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .selectedDate="${
            this.state.dosTo ? parseDate(this.state.dosTo) : null
          }"
          .onChange="${this.handlers.changeDate}"
          momentFlag
        ></neb-date-picker>
      </div>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderSearchBarFilter()} ${this.__renderEncounterNumberFilter()}
      ${this.__renderAppointmentTypeFilter()}
      ${this.__renderServiceDateFilter()}
    `;
  }
}

window.customElements.define(
  'neb-filters-documents-mobile',
  NebFiltersDocumentsMobile,
);
