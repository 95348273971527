import './neb-calendar-event';
import '../../../packages/neb-lit-components/src/components/neb-loading-overlay';

import { LitElement, html, css } from 'lit';

import {
  openAppointmentPage,
  openRescheduledAppointmentPage,
} from '../../../packages/neb-lit-components/src/utils/appointment-overlays-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { fetchAndOpenMatchPopup } from '../../../packages/neb-lit-components/src/utils/patients';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_CAPTION,
  CSS_SPACING,
} from '../../styles';

export const ELEMENTS = {
  calendarEvents: { selector: 'neb-calendar-event' },
  dayCells: { selector: '.day-cell' },
  monthDaysContainer: { id: 'month-days-container' },
  monthHeadersContainer: { id: 'month-headers-container' },
  loadingOverlay: { id: 'loading-overlay' },
};

const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

class NebCalendarMonthView extends LitElement {
  static get properties() {
    return {
      model: Object,
      targetDate: String,
      isLoading: Boolean,
      __cells: Array,
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        position: absolute;
        top: 0;
        bottom: 30px;
        left: 0;
        right: 0;
        overflow: hidden;
      }

      .day-cell {
        min-width: 138px;
        min-height: 136px;
        border-top: solid 1px ${CSS_COLOR_GREY_2};
        border-left: solid 1px ${CSS_COLOR_GREY_2};
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .day-cell:hover {
        background-color: ${CSS_COLOR_GREY_4};
      }

      .day-cell[today] {
        border: solid 1px ${CSS_COLOR_HIGHLIGHT};
      }

      .day-cell:last-child {
        border-right: solid 1px ${CSS_COLOR_GREY_2};
      }

      .month-row {
        display: flex;
      }

      .month-row:last-child {
        border-bottom: solid 1px ${CSS_COLOR_GREY_2};
      }

      .month-day-name {
        text-align: center;
        min-width: 138px;
        margin-bottom: 25px;
        flex: 1;
      }

      .month-headers-container {
        position: absolute;
        left: ${CSS_SPACING};
        right: ${CSS_SPACING};
        overflow: hidden;
      }

      .month-days-container {
        position: absolute;
        left: ${CSS_SPACING};
        right: ${CSS_SPACING};
        top: 75px;
        bottom: 0;
        overflow: auto;
      }

      .appointment-count {
        display: flex;
        flex-direction: row-reverse;
        flex: 1;
        align-items: flex-end;
        padding: 10px;
        font-weight: bold;
        font-size: ${CSS_FONT_SIZE_CAPTION};
        color: ${CSS_COLOR_HIGHLIGHT};
      }

      .day-text {
        display: flex;
        flex-direction: row-reverse;
        padding-top: 7px;
        padding-right: 10px;
        font-size: ${CSS_FONT_SIZE_CAPTION};
      }

      .calendar-event {
        position: inherit;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        margin: 1px 0;
        white-space: nowrap;
        min-height: 24px;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = {};
    this.targetDate = '';
    this.isLoading = false;

    this.onClickDay = () => {};

    this.onUpdateCalendar = () => {};

    this.__cells = [];
  }

  __initHandlers() {
    this.__handlers = {
      scroll: () => {
        const monthHeadersContainer = this.shadowRoot.getElementById(
          ELEMENTS.monthHeadersContainer.id,
        );

        const monthDaysContainer = this.shadowRoot.getElementById(
          ELEMENTS.monthDaysContainer.id,
        );

        monthHeadersContainer.scrollLeft = monthDaysContainer.scrollLeft;
      },
      clickEvent: async e => {
        e.__ignore = true;

        const event = e.target.model;

        if (event.isBOT) {
          await openOverlay(OVERLAY_KEYS.BLOCKED_OFF_TIME_PAGE, {
            appointmentId: event.id,
          });
        } else {
          let popup = {};

          if (event.unmatched) {
            popup = await fetchAndOpenMatchPopup(event.accountId, event.id);
          }

          if (!popup.back) {
            if (event.isRescheduled) {
              await openRescheduledAppointmentPage(
                event.id,
                event.appointmentId,
              );
            } else {
              await openAppointmentPage(event.id);
            }
          }
        }

        this.onUpdateCalendar();
      },
      clickDay: e => {
        if (e.__ignore) return;

        const text = e.currentTarget.getAttribute('text');

        if (text) {
          const date = this.__getDateFromDay(text);

          this.onClickDay(date);
        }
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('targetDate')) {
      this.__cells = this.__getTableCells(parseDate(this.targetDate));
    }
  }

  __getDateFromDay(day) {
    const yearAndMonth = this.__targetDate.slice(0, -3);
    return day < 10 ? `${yearAndMonth}-0${day}` : `${yearAndMonth}-${day}`;
  }

  __getTableCells(date) {
    const firstDayOfMonth = date
      .clone()
      .startOf('month')
      .day();

    const cells = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      cells.push({
        text: '',
      });
    }

    const daysInMonth = date.daysInMonth();

    const nowDate = parseDate();

    const [targetYear, targetMonth] = this.targetDate.split('-');

    for (let i = 1; i <= daysInMonth; i++) {
      const isToday =
        targetMonth - 1 === nowDate.month() &&
        +targetYear === nowDate.year() &&
        i === nowDate.date();

      cells.push({
        text: i,
        isToday,
      });
    }

    const remainingCells =
      cells.length > 35 ? 42 - cells.length : 35 - cells.length;

    for (let j = 0; j < remainingCells; j++) {
      cells.push({
        text: '',
      });
    }

    return cells;
  }

  __renderMonthHeaders() {
    return DAY_NAMES.map(
      name => html`
        <div class="month-day-name">${name}</div>
      `,
    );
  }

  __renderEvents(date) {
    function getCount(count) {
      return count > 0 ? `${count} Appointment${count > 1 ? 's' : ''}` : '';
    }

    if (
      !this.model ||
      !this.model.appointments ||
      !this.model.appointments[date] ||
      !this.model.appointments[date].data
    ) {
      return '';
    }

    const { count, data: events } = this.model.appointments[date];

    return html`
      ${
        events.map(
          event => html`
            <neb-calendar-event
              class="calendar-event"
              month
              .model="${event}"
              @click="${this.__handlers.clickEvent}"
            >
            </neb-calendar-event>
          `,
        )
      }

      <div class="appointment-count">${getCount(count)}</div>
    `;
  }

  __renderMonthDays(row) {
    const days = this.__cells.slice(row * 7, row * 7 + 7);

    return days.map(day => {
      const date = this.__getDateFromDay(day.text);

      return html`
        <div
          class="day-cell"
          text="${day.text}"
          ?today="${day.isToday}"
          @click="${this.__handlers.clickDay}"
        >
          <div class="day-text">${day.text}</div>
          ${day.text ? this.__renderEvents(date) : ''}
        </div>
      `;
    });
  }

  __renderMonthRows() {
    const numberOfRows = this.__cells.length / 7;

    return [...new Array(numberOfRows)].map(
      (_, row) => html`
        <div class="month-row">${this.__renderMonthDays(row)}</div>
      `,
    );
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.monthHeadersContainer.id}"
        class="month-headers-container month-row"
      >
        ${this.__renderMonthHeaders()}
      </div>

      <div
        id="${ELEMENTS.monthDaysContainer.id}"
        class="month-days-container"
        @scroll="${this.__handlers.scroll}"
      >
        ${this.__renderMonthRows()}
      </div>

      <neb-loading-overlay
        id="${ELEMENTS.loadingOverlay.id}"
        title="Loading Appointments..."
        showDelay="0"
        .show="${this.isLoading}"
      ></neb-loading-overlay>
    `;
  }
}

customElements.define('neb-calendar-month-view', NebCalendarMonthView);
