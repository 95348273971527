import '../tables/neb-table-claims-worklist';
import './neb-claims-worklist-playground';
import './status-controllers/neb-controller-needs-attention';
import './status-controllers/neb-ready-to-submit-controller';
import './status-controllers/neb-claim-status-controller';

import { matchRouteSwitch, navigate, redirect } from '@neb/router';
import deepEqual from 'fast-deep-equal';
import { css, html, LitElement } from 'lit';

import { hasByocAddons } from '../../../../../src/utils/clearinghouse-settings';
import {
  ABANDONED_CLAIM_COUNT_ERROR,
  ECLAIM_STATUS_CHANGE_SUCCESS_MESSAGE,
  errorOccurred,
  CLAIM_STATUS_REQUEST_MESSAGE,
  CLAIM_STATUS_REQUEST_MESSAGE_TYPE,
  generateInternalErrorMessage,
  generateValidationErrorMessage,
  successfulGenerateBatch,
  CORRECT_CLAIM_ERROR,
  generateRebillErrorMessage,
  VOID_CLAIM_ERROR,
  failedCorrectedClaimBatch,
} from '../../../../../src/utils/user-message';
import {
  bulkPrintClaims,
  bulkRequestClaimStatus,
  rebillClaims,
  validateRefreshClaims,
  correctClaim,
  voidClaim,
} from '../../../../neb-api-client/src/claims';
import { getAbandonedCount } from '../../../../neb-api-client/src/electronic-claims/abandoned-responses';
import {
  getClaimsTotalsCountAndCharges,
  getClaimsTotalsApprovedBalance,
  getClaimsTotals,
} from '../../../../neb-api-client/src/electronic-claims/claim-totals';
import saveClaimsData from '../../../../neb-api-client/src/services/claims/save-claims-data';
import {
  openError,
  openInfo,
  openSuccess,
  openWarning,
} from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_ERROR,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import {
  CLAIM_STATUS,
  DESIGNATED_CLEARINGHOUSE_POPUP,
  EXCESS_CHARGE_POPUP,
  getPrintSettings,
} from '../../../../neb-utils/claims';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../neb-utils/feature-util';
import { centsToCurrency } from '../../../../neb-utils/formatters';
import {
  getFileURL,
  showError,
  showLoading,
} from '../../../../neb-utils/neb-pdf-print-util';
import { handleDirtyDialogLogic } from '../../../../neb-utils/utils';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import { CARDS } from './neb-claims-worklist-cards';
import {
  changeClaimStatus,
  openWorklistItemPopup,
  verifyClaimsWithPopups,
  WORKLIST_ITEMS_POPUP_ACTIONS,
  mapClaimTotalsChargesToCardTotals,
} from './utils';

export const SUBMITTED_CLAIM_STATUSES = [
  CLAIM_STATUS.GENERATED,
  CLAIM_STATUS.TRANSMITTED,
  CLAIM_STATUS.RECEIVED,
  CLAIM_STATUS.PENDING,
  CLAIM_STATUS.PRINTED,
  CLAIM_STATUS.MAILED,
  CLAIM_STATUS.ACCEPTED,
  CLAIM_STATUS.DOWNLOADED,
  CLAIM_STATUS.UPLOADED_TO_CLEARINGHOUSE,
  CLAIM_STATUS.UPLOADING_TO_CLEARINGHOUSE,
  CLAIM_STATUS.DOWNLOADED,
  CLAIM_STATUS.READY_TO_DOWNLOAD,
];

export const DENIED_AND_REJECTED_CLAIM_STATUSES = [
  CLAIM_STATUS.DENIED,
  CLAIM_STATUS.REJECTED,
  CLAIM_STATUS.ERA_EOB_RECEIVED_DENIED,
];

export const APPROVED_CLAIM_STATUSES = [
  CLAIM_STATUS.APPROVED,
  CLAIM_STATUS.ERA_RECEIVED,
  CLAIM_STATUS.ERA_EOB_RECEIVED_APPROVED,
];

export const ELEMENTS = {
  header: { id: 'header' },
  cards: { id: 'worklist-cards' },
  readyPage: { id: 'ready-to-submit-page' },
  needsAttentionPage: { id: 'needs-attention-page' },
  submittedPage: { id: 'submitted-page' },
  approvedPage: { id: 'approved-page' },
  deniedPage: { id: 'denied-page' },
  abandonedLink: { id: 'abandoned-link' },
  linkClaimBatches: { id: 'link-claim-batches' },
};

const INITIAL_CARD_VALUES = {
  readyToSubmitCount: 0,
  readyToSubmitAmount: 0,
  needsAttentionCount: 0,
  needsAttentionAmount: 0,
  submittedCount: 0,
  submittedAmount: 0,
  deniedCount: 0,
  deniedAmount: 0,
  approvedCount: 0,
  approvedAmount: 0,
};

export const EXCESS_CHARGE_POPUP_REBILL = {
  ...EXCESS_CHARGE_POPUP,
  ACTION_PAST_TENSE: 'Rebilled',
  ACTION_PRESENT_TENSE: 'Rebill',
};

export const DESIGNATED_CLEARINGHOUSE_POPUP_REBILL = {
  ...DESIGNATED_CLEARINGHOUSE_POPUP,
  ACTION_PAST_TENSE: 'Rebilled',
  ACTION_PRESENT_TENSE: 'Rebill',
};

const EXPAND_FLAG_DICT = {
  [CARDS.APPROVED.name]: {},
  [CARDS.DENIED.name]: {},
  [CARDS.NEEDS_ATTENTION.name]: {},
  [CARDS.SUBMITTED.name]: {},
};

const OPEN_TAB_ERROR_MESSAGE =
  'Cannot open claim tab, please check your pop-up blocker';

const INVALID_CLAIM_ERROR_MESSAGE = 'An error occurred when fetching claims';

class NebClaimsWorklistController extends LitElement {
  static get properties() {
    return {
      __abandonedCount: Number,
      __cardValues: Object,
      __userLocations: Array,
      __defaultLocationId: String,
      layout: {
        type: String,
        reflect: true,
      },
      __queryParams: Object,
      __selectedCard: String,
      __loading: Boolean,
      __expandFlagsDict: Object,
      __formIsDirty: Boolean,
      __formIsValid: Boolean,
      __formModel: Array,
      __patientCasesDict: Object,
      __hasMaxClear: Boolean,
      __hasRcmClaimFlagFF: Boolean,
      __hasRcmRelease2FF: Boolean,
      __hasFitInvoiceOverlayPerformanceFF: Boolean,

      patient: Object,
      patientId: String,
      route: String,
      refreshing: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
          flex: 1 0 0;
        }

        :host([layout='small']) {
          height: auto;
        }

        .header-container {
          display: flex;
          align-items: center;
          margin: 20px;
        }

        .header-text {
          flex: 1 0 0;
          height: fit-content;
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .container-links {
          display: flex;
          align-items: center;
        }

        .container-link {
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        .container-link-abandoned {
          margin-left: ${CSS_SPACING};
        }

        .container-link:focus,
        .container-link:hover {
          opacity: 0.65;
        }

        .icon {
          margin-right: 5px;
        }

        .icon-warning {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_ERROR};
        }

        .icon-batch {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .link {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-decoration: underline;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initServices();
    this.__initHandlers();
  }

  __initState() {
    this.__abandonedCount = 0;
    this.__cardValues = { ...INITIAL_CARD_VALUES };
    this.__defaultLocationId = '';
    this.__navItems = this.genNavItems();
    this.__queryParams = {};
    this.__selectedCard = '';
    this.__userLocations = [];
    this.__userHasAllLocations = false;
    this.__loading = false;
    this.__loadingApprovedBalance = false;
    this.__cardsFiltered = false;
    this.__expandFlagsDict = {
      ...EXPAND_FLAG_DICT,
    };

    this.__formIsDirty = false;
    this.__formIsValid = true;
    this.__formModel = [];
    this.__patientCasesDict = {};
    this.__hasMaxClear = false;
    this.__hasRcmClaimFlagFF = false;
    this.__hasRcmRelease2FF = false;
    this.__hasFitInvoiceOverlayPerformanceFF = false;

    this.patientId = '';
    this.patient = {
      id: '',
      name: '',
    };

    this.route = '';
    this.refreshing = false;

    this.onSelect = () => {};
  }

  __initServices() {
    this.__locationsService = new LocationsService(
      ({ userLocations, defaultLocationId, userHasAllLocations }) => {
        this.__userLocations = userLocations.map(data => ({
          label: `${data.name}`,
          data,
        }));

        this.__defaultLocationId = defaultLocationId;
        this.__userHasAllLocations = userHasAllLocations;
      },
    );
  }

  __initHandlers() {
    this.__handlers = {
      changeTotal: async amounts => {
        const proposedTotals = { ...this.__cardValues, ...amounts };

        if (!deepEqual(proposedTotals, this.__cardValues)) {
          await this.__getClaimTotals();

          this.__cardValues = { ...this.__cardValues, ...amounts };
          this.__cardsFiltered = true;
        }
      },
      addPatientPayment: () =>
        this.__openOverlay({
          key: OVERLAY_KEYS.ADD_PATIENT_PAYMENT,
          model: {
            patientId: this.patientId,
            patient: this.patient,
          },
        }),
      selectCard: card => navigate(this.__buildRoute(card)),
      addPayment: async card => {
        let paymentOverlay;

        if (card === CARDS.APPROVED.name) {
          const { patient, patientId } = this;

          paymentOverlay = patientId
            ? await openOverlay(OVERLAY_KEYS.ADD_PAYMENT, {
                patientId,
                patient,
              })
            : await openOverlay(OVERLAY_KEYS.EOB_FORM);

          this.refreshing = true;

          if (!patientId && paymentOverlay) {
            await openOverlay(OVERLAY_KEYS.ERA_EOB_MANAGEMENT, {
              readonly: false,
              payerPayment: paymentOverlay,
            });
          }
        }

        return paymentOverlay;
      },
      clickUnlinkedClaims: () =>
        this.__openOverlay({
          key: OVERLAY_KEYS.UNLINKED_CLAIM_RESPONSES,
          callback: () => {
            this.__fetchAbandonedCount();
            this.__getClaimTotals();
            this.__refreshClaimStatusController();
          },
        }),
      changeClaimStatus: async claims => {
        await changeClaimStatus(claims);
      },
      rebillClaims: async claims => {
        const claimIds = claims.map(c => c.id);

        const {
          excessChargeInvoiceNumbers = [],
          incorrectClearinghouseInvoiceNumbers = [],
          violatingInvoiceIds = [],
        } = await validateRefreshClaims(claimIds);

        const result = await verifyClaimsWithPopups(
          excessChargeInvoiceNumbers,
          incorrectClearinghouseInvoiceNumbers,
          EXCESS_CHARGE_POPUP_REBILL,
          DESIGNATED_CLEARINGHOUSE_POPUP_REBILL,
        );

        if (result) {
          const filteredClaimIds = claims
            .filter(c => !violatingInvoiceIds.includes(c.invoiceId))
            .map(c => c.id);

          try {
            const res = await rebillClaims(filteredClaimIds);

            if (res.rebilledErrorCount) {
              store.dispatch(
                openWarning(generateRebillErrorMessage(res.rebilledErrorCount)),
              );
            }

            if (res.internalErrorCount) {
              store.dispatch(
                openWarning(
                  generateInternalErrorMessage(res.internalErrorCount),
                ),
              );
            }

            if (!res.failedClaimsCount && !res.internalErrorCount) {
              store.dispatch(openSuccess(`${successfulGenerateBatch}`));

              this.__refreshClaimStatusController();
              await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
                patientId: this.patientId,
                onSelect: this.onSelect,
              });

              this.__refreshClaimStatusController();
              return;
            }

            if (res.failedClaimsCount) {
              store.dispatch(
                openWarning(
                  generateValidationErrorMessage(res.failedClaimsCount),
                ),
              );
            }

            this.__refreshClaimStatusController();
            if (res.successfulClaimsCount) {
              await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
                patientId: this.patientId,
                onSelect: this.onSelect,
              });
            } else return;

            this.__refreshClaimStatusController();
          } catch (e) {
            console.error(e);
            store.dispatch(
              openError('An error occurred when trying to rebill claims.'),
            );
          }

          this.__refreshClaimStatusController();
        }
      },
      requestStatus: async tableClaims => {
        let requestedClaimIds;

        const {
          claimIds,
          paperCount,
          invalidCount,
          invalidCountChc,
        } = this.__getRequestStatusClaimIdTypeCounts(tableClaims);

        if (claimIds.length) {
          requestedClaimIds = await bulkRequestClaimStatus({
            claimIds,
          });
        }

        if (requestedClaimIds?.length) this.refreshing = true;

        await this.__dispatchSuccessOrErrorBanner(
          claimIds ? claimIds.length : 0,
          requestedClaimIds ? requestedClaimIds.length : 0,
        );

        await this.__dispatchInfoBannerClaimStatusRequest(
          paperCount || 0,
          CLAIM_STATUS_REQUEST_MESSAGE_TYPE.paper,
        );

        await this.__dispatchInfoBannerClaimStatusRequest(
          invalidCount || 0,
          CLAIM_STATUS_REQUEST_MESSAGE_TYPE.status,
        );

        await this.__dispatchInfoBannerClaimStatusRequest(
          invalidCountChc || 0,
          CLAIM_STATUS_REQUEST_MESSAGE_TYPE.notCHC,
        );
      },

      openClaimBatchesOverlay: () =>
        this.__openOverlay({
          key: OVERLAY_KEYS.CLAIM_BATCHES,
          model: {
            patientId: this.patientId,
            onSelect: this.onSelect,
          },
          callback: () => {
            this.__getClaimTotals();
            this.__refreshClaimStatusController();
          },
        }),
      toggleReadyExpand: ([item, _, value]) => {
        let flags = this.__expandFlagsDict[this.__getRouteTail()];
        flags = {
          ...flags,
          [item.id]: value,
          expandAll: false,
        };

        this.__expandFlagsDict = {
          ...this.__expandFlagsDict,
          [this.__getRouteTail()]: flags,
        };
      },

      expandAll: (claimIds = [], collapse = true) => {
        const flags = this.__expandFlagsDict[this.__getRouteTail()];
        const expandAll = collapse ? !flags.expandAll : !!flags.expandAll;
        const expandFlags = claimIds.reduce(
          (acc, cur) => ({ ...acc, [cur]: expandAll }),
          {},
        );

        this.__expandFlagsDict = {
          ...this.__expandFlagsDict,
          [this.__getRouteTail()]: {
            ...flags,
            ...expandFlags,
            expandAll,
          },
        };
      },

      openConfirmHidePopup: (checkedRowItems = []) =>
        openWorklistItemPopup(
          WORKLIST_ITEMS_POPUP_ACTIONS.HIDE,
          checkedRowItems,
        ),

      openConfirmShowPopup: (checkedRowItems = []) =>
        openWorklistItemPopup(
          WORKLIST_ITEMS_POPUP_ACTIONS.SHOW,
          checkedRowItems,
        ),

      printClaims: async (claims = []) => {
        let claimWindow;
        let res;

        claimWindow = this.__openWindow();

        if (claimWindow) {
          try {
            const settings = getPrintSettings();
            res = await bulkPrintClaims(settings, claims.map(c => c.id));
          } catch (e) {
            console.error(e);
            const msg = 'An error occurred when printing the claim';
            store.dispatch(openError(msg));
            showError(claimWindow, msg);
          }
        } else {
          return;
        }

        if (res) {
          const { buffer, bufferWithCmsBackground } = res;

          if (buffer && bufferWithCmsBackground) {
            const msg =
              'Only 1 of 2 tabs could be opened, please check your pop-up blocker';

            claimWindow.location = getFileURL(res.buffer);
            claimWindow = this.__openWindow(msg);

            claimWindow.location = getFileURL(res.bufferWithCmsBackground);
          } else if (buffer) {
            claimWindow.location = getFileURL(res.buffer);
          } else if (bufferWithCmsBackground) {
            claimWindow.location = getFileURL(res.bufferWithCmsBackground);
          } else {
            store.dispatch(openError(INVALID_CLAIM_ERROR_MESSAGE));
          }
        }
      },

      dirtyDialog: handlers => this.__dirtyDialog(handlers),
      dirtyUpdated: (dirty, valid, model) => {
        this.__formIsDirty = dirty;
        this.__formIsValid = valid;
        this.__formModel = model;
      },
      saveClaimsData: async ({ filterIsApplied }) => {
        const res = await this.__saveClaimsData(this.__hasRcmClaimFlagFF);

        if (res) {
          this.__getClaimTotals();

          if (!filterIsApplied) {
            this.__refreshClaimStatusController();
          }
          this.__resetFormFlags();
        }
      },
      initStateChanged: initState => {
        this.__initState = initState;
      },
      patientsCasesDictUpdated: patientCasesDict => {
        this.__patientCasesDict = patientCasesDict;
      },
      correctClaim: async claim => {
        try {
          const body = {
            claimId: claim.id,
            invoiceId: claim.invoiceId,
            insuranceId: claim.insurance.id,
            paymentResponsibilityLevelCode:
              claim.paymentResponsibilityLevelCode,
            originalReferenceNumber: claim.originalReferenceNumber,
            submissionMethodPrimary: claim.submissionMethodPrimary,
            submissionMethodSecondary: claim.submissionMethodSecondary,
          };

          const res = await correctClaim(body);

          if (res.successfulClaimsCount) {
            store.dispatch(openSuccess(successfulGenerateBatch));

            await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
              patientId: claim.patientId,
              onSelect: this.onSelect,
            });
          } else store.dispatch(openWarning(failedCorrectedClaimBatch));

          this.__getClaimTotals();
          this.__refreshClaimStatusController();
          this.__resetFormFlags();
        } catch (e) {
          console.error(e);
          store.dispatch(openError(CORRECT_CLAIM_ERROR));
        }
      },
      voidClaim: async claim => {
        try {
          const body = {
            claimId: claim.id,
            paymentResponsibilityLevelCode:
              claim.paymentResponsibilityLevelCode,
            originalReferenceNumber: claim.originalReferenceNumber,
            submissionMethodPrimary: claim.submissionMethodPrimary,
            submissionMethodSecondary: claim.submissionMethodSecondary,
          };

          await voidClaim(body);

          this.__getClaimTotals();
          this.__refreshClaimStatusController();
          this.__resetFormFlags();
          store.dispatch(openSuccess(successfulGenerateBatch));
        } catch (e) {
          console.error(e);
          store.dispatch(openError(VOID_CLAIM_ERROR));
        }
      },
    };
  }

  __openWindow(error = OPEN_TAB_ERROR_MESSAGE) {
    try {
      const claimWindow = window.open();
      showLoading(claimWindow, 'Loading claim, please wait...');

      return claimWindow;
    } catch (e) {
      console.error(e);
      store.dispatch(openError(error));
    }

    return null;
  }

  __saveClaimsData(hasRcmClaimFlagFF) {
    return saveClaimsData(
      this.__initState,
      this.__formModel,
      this.__patientCasesDict,
      hasRcmClaimFlagFF,
    );
  }

  __resetFormFlags() {
    this.__formIsDirty = false;
    this.__formIsValid = true;
  }

  __openOverlay({ key, model = {}, callback = () => {} }) {
    return this.__dirtyDialog({
      onSave: async () => {
        await this.__saveClaimsData();
        await openOverlay(key, model);
        return callback();
      },
      onDiscard: async () => {
        await openOverlay(key, model);
        return callback();
      },
    });
  }

  __dirtyDialog({
    onSave = () => {},
    onStay = () => {},
    onDiscard = () => {},
  }) {
    return handleDirtyDialogLogic({
      isDirty: this.__formIsDirty,
      formIsValid: this.__formIsValid,
      onSave: async () => {
        await onSave();
        this.__resetFormFlags();
      },
      onDiscard: async () => {
        await onDiscard();
        this.__resetFormFlags();
      },
      onStay,
    });
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();

    this.__hasClaimsPlaygroundFeature = await hasFeatureOrBeta(
      FEATURE_FLAGS.CLAIMS_PLAYGROUND,
    );

    this.__hasMaxClear = (await hasByocAddons()).CT_MAXCLEAR;

    this.__hasRcmClaimFlagFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_CLAIM_FLAG,
    );

    this.__hasRcmRelease2FF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_RELEASE_2,
    );

    this.__hasFitInvoiceOverlayPerformanceFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.FIT_INVOICE_OVERLAY_PERFORMANCE,
    );

    this.__hasClaimTotalsV2 = await hasFeatureOrBeta(
      FEATURE_FLAGS.CLAIM_TOTALS_V2,
    );

    this.__fetchAbandonedCount();
  }

  disconnectedCallback() {
    this.__locationsService.disconnect();

    super.disconnectedCallback();
  }

  async __getClaimTotals() {
    if (this.__hasClaimTotalsV2) {
      await this.__loadClaimTotalsAndApprovedBalance();
      return;
    }

    this.__loading = true;

    try {
      const claimTotals = await getClaimsTotals(this.__queryParams);
      this.__cardValues = { ...INITIAL_CARD_VALUES, ...claimTotals };
    } catch (e) {
      console.error(e);
    }

    this.__loading = false;
  }

  async __loadApprovedBalance() {
    this.__loadingApprovedBalance = true;

    try {
      const approvedBalance = await getClaimsTotalsApprovedBalance(
        this.__queryParams,
      );
      this.__cardValues = {
        ...this.__cardValues,
        approvedCount: approvedBalance.approved.count,
        approvedAmount: approvedBalance.approved.balance,
      };
    } catch (e) {
      console.error(e);
    }

    this.__loadingApprovedBalance = false;
  }

  async __loadClaimsTotalsCountAndCharges() {
    this.__loading = true;

    try {
      const claimTotals = await getClaimsTotalsCountAndCharges(
        this.__queryParams,
      );
      this.__cardValues = {
        ...mapClaimTotalsChargesToCardTotals(claimTotals),
        approvedAmount: this.__loadingApprovedBalance
          ? null
          : this.__cardValues.approvedAmount,
      };
    } catch (e) {
      console.error(e);
    }

    this.__loading = false;
  }

  async __loadClaimTotalsAndApprovedBalance() {
    await Promise.all([
      this.__loadApprovedBalance(),
      this.__loadClaimsTotalsCountAndCharges(),
    ]);
  }

  __buildTableEmptyMessage(status = 'claims') {
    const typeName = this.patientId ? 'patient' : 'practice';

    return `There are no ${status} for this ${typeName}.`;
  }

  __buildTableConfig(status) {
    return [
      {
        key: 'hidden',
        label: '',
        flex: css`0 0 28px`,
        truncate: true,
      },
      {
        key: 'dateOfService',
        label: 'Service Date',
        flex: css`1.5 0 0`,
        sortable: true,
      },
      {
        key: 'locationName',
        label: 'Location',
        flex: css`1.25 0 0`,
      },
      {
        key: 'invoiceNumber',
        label: 'Invoice',
        flex: css`1 0 0`,
        truncate: false,
        sortable: true,
      },
      {
        key: 'claimNumber',
        label: 'Claim',
        flex: css`1.25 0 0`,
        truncate: false,
        sortable: true,
        customStyle: 'flex-direction: column',
      },
      {
        key: 'status',
        label: 'Status',
        flex: status === 'approved' ? css`1.35 0 0` : css`2 0 0`,
        truncate: false,
      },
      ...(status !== 'ready-to-submit'
        ? [
            {
              key: 'lastUpdated',
              label: 'Last Updated',
              flex: css`1.25 0 0`,
              truncate: false,
            },
          ]
        : []),
      ...(!this.patientId
        ? [
            {
              key: 'patientId',
              label: 'Patient',
              flex: css`1.25 0 0`,
            },
          ]
        : []),
      {
        key: 'primaryPayerAlias',
        label: 'Payer',
        flex: css`1 0 0`,
        truncate: false,
        sortable: true,
      },
      {
        key: 'primaryPlanName',
        label: 'Plan',
        flex: css`1 0 0`,
        truncate: false,
      },
      {
        key: 'providerId',
        label: 'Billing Provider',
        flex: css`1 0 0`,
      },
      ...(status === 'approved'
        ? [
            {
              key: 'amount',
              label: 'Billed',
              flex: css`1 0 0`,
              truncate: false,
            },
            {
              key: 'owedAmount',
              label: 'Owed',
              flex: css`1.75 0 0`,
            },
            {
              key: 'payments',
              label: 'Paid',
              flex: css`1.75 0 0`,
            },
            {
              key: 'balance',
              label: 'Balance',
              flex: css`1.5 0 0`,
            },
          ]
        : [
            {
              key: 'amount',
              label: 'Amount',
              flex: css`1 0 0`,
              truncate: false,
              sortable: true,
            },
          ]),
    ];
  }

  __buildRoute(card) {
    return `${this.baseRoute}${card}`;
  }

  __getRouteTail() {
    return this.route.split('/')[1];
  }

  __evaluateRoute() {
    const routeTail = this.__getRouteTail();

    return this.layout === 'large'
      ? !!this.__navItems.find(navItem => navItem.card === routeTail)
      : routeTail === '';
  }

  __dispatchSuccessOrErrorBanner(requestedCount, succeededCount) {
    const errorCount = requestedCount - succeededCount;

    if (succeededCount) {
      store.dispatch(
        openSuccess(
          `Status request sent successfully for ${succeededCount} ${
            succeededCount === 1 ? 'claim' : 'claims'
          }.`,
        ),
      );
    }

    if (errorCount) {
      const errorMsg = succeededCount
        ? `An error occurred when requesting status for ${errorCount} ${
            errorCount === 1 ? 'claim' : 'claims'
          }.`
        : 'An error occurred when requesting status.';
      store.dispatch(openError(errorMsg));
    }
  }

  __dispatchClaimStatusUpdateErrorMessage() {
    store.dispatch(openError(errorOccurred('updating', 'claim status')));
  }

  __dispatchClaimStatusUpdateSuccessMessage(claimCount, submissionMethod = '') {
    store.dispatch(
      openSuccess(
        ECLAIM_STATUS_CHANGE_SUCCESS_MESSAGE(claimCount, submissionMethod),
      ),
    );
  }

  __dispatchInfoBannerClaimStatusRequest(count, type) {
    return count
      ? store.dispatch(
          openInfo(CLAIM_STATUS_REQUEST_MESSAGE(count, type), true),
        )
      : '';
  }

  __getRequestStatusClaimIdTypeCounts(claims) {
    const claimIds = [];

    let paperCount = 0;
    let invalidCount = 0;
    let invalidCountChc = 0;
    claims.forEach(claim => {
      if (claim.isElectronic) {
        if (
          [...SUBMITTED_CLAIM_STATUSES, CLAIM_STATUS.NEEDS_ATTENTION].includes(
            claim.claimStatuses[0].status,
          ) &&
          claim.chcPayerId
        ) {
          claimIds.push(claim.id);
        } else if (!claim.chcPayerId) {
          invalidCountChc++;
        } else {
          invalidCount++;
        }
      } else {
        paperCount++;
      }
    });

    return {
      claimIds,
      paperCount,
      invalidCount,
      invalidCountChc,
    };
  }

  __refreshClaimStatusController() {
    switch (this.__selectedCard) {
      case 'ready-to-submit':
        this.shadowRoot.getElementById(ELEMENTS.readyPage.id).refresh();

        break;
      case 'needs-attention':
        this.shadowRoot
          .getElementById(ELEMENTS.needsAttentionPage.id)
          .refresh();

        break;

      case 'submitted':
        this.shadowRoot.getElementById(ELEMENTS.submittedPage.id).refresh();

        break;

      case 'denied':
        this.shadowRoot.getElementById(ELEMENTS.deniedPage.id).refresh();

        break;

      case 'approved':
        this.shadowRoot.getElementById(ELEMENTS.approvedPage.id).refresh();

        break;
      default:
        break;
    }
  }

  get __expandFlagsNeedAttentionPage() {
    return this.__expandFlagsDict[CARDS.NEEDS_ATTENTION.name];
  }

  get __expandFlagsSubmittedPage() {
    return this.__expandFlagsDict[CARDS.SUBMITTED.name];
  }

  get __expandFlagsDeniedPage() {
    return this.__expandFlagsDict[CARDS.DENIED.name];
  }

  get __expandFlagsApprovedPage() {
    return this.__expandFlagsDict[CARDS.APPROVED.name];
  }

  __getUserLocationsQueryParameter() {
    if (this.__defaultLocationId) {
      return [this.__defaultLocationId];
    }

    if (this.__hasClaimTotalsV2 && this.__userHasAllLocations) {
      return [];
    }

    return this.__userLocations.map(({ data: { id } }) => id);
  }

  genNavItems() {
    return [
      {
        card: CARDS.READY.name,
        exact: false,
        path: '/ready-to-submit/',
        resolver: () => html`
          <neb-ready-to-submit-controller
            id="${ELEMENTS.readyPage.id}"
            .emptyMessage="${this.__buildTableEmptyMessage('invoices')}"
            .patientId="${this.patientId}"
            .userLocations="${this.__userLocations}"
            .hasRcmClaimFlagFF="${this.__hasRcmClaimFlagFF}"
            .hasRcmRelease2FF="${this.__hasRcmRelease2FF}"
            .hasFitInvoiceOverlayPerformanceFF="${
              this.____hasFitInvoiceOverlayPerformanceFF
            }"
            .defaultLocationId="${this.__defaultLocationId}"
            .onTotalChange="${this.__handlers.changeTotal}"
            .onSelect="${this.onSelect}"
            .onDirtyDialog="${this.__handlers.dirtyDialog}"
            .onUpdateDirty="${this.__handlers.dirtyUpdated}"
            .onSave="${this.__handlers.saveClaimsData}"
            .onUpdateInitState="${this.__handlers.initStateChanged}"
            .onUpdatePatientCasesDict="${
              this.__handlers.patientsCasesDictUpdated
            }"
          >
          </neb-ready-to-submit-controller>
        `,
      },
      {
        card: CARDS.NEEDS_ATTENTION.name,
        exact: false,
        path: '/needs-attention/',
        resolver: () => html`
          <neb-controller-needs-attention
            id="${ELEMENTS.needsAttentionPage.id}"
            .emptyMessage="${this.__buildTableEmptyMessage()}"
            .patientId="${this.patientId}"
            .userLocations="${this.__userLocations}"
            .defaultLocationId="${this.__defaultLocationId}"
            .onChangeClaimStatus="${this.__handlers.changeClaimStatus}"
            .onTotalChange="${this.__handlers.changeTotal}"
            .onRequestStatus="${this.__handlers.requestStatus}"
            ?refreshing="${this.refreshing}"
            .status="${'needs-attention'}"
            .hasFitInvoiceOverlayPerformanceFF="${
              this.____hasFitInvoiceOverlayPerformanceFF
            }"
            .onSelect="${this.onSelect}"
            .expandFlags="${this.__expandFlagsNeedAttentionPage}"
            .onToggleExpand="${this.__handlers.toggleReadyExpand}"
            .onExpandAll="${this.__handlers.expandAll}"
            .hasMaxClear="${this.__hasMaxClear}"
          ></neb-controller-needs-attention>
        `,
      },
      {
        card: CARDS.SUBMITTED.name,
        exact: false,
        path: '/submitted/',
        resolver: () => html`
          <neb-claim-status-controller
            id="${ELEMENTS.submittedPage.id}"
            .config="${this.__buildTableConfig(CARDS.SUBMITTED.name)}"
            .emptyMessage="${this.__buildTableEmptyMessage()}"
            .patientId="${this.patientId}"
            .hasFitInvoiceOverlayPerformanceFF="${
              this.____hasFitInvoiceOverlayPerformanceFF
            }"
            .status="${'submitted'}"
            .statuses="${SUBMITTED_CLAIM_STATUSES}"
            .userLocations="${this.__userLocations}"
            .defaultLocationId="${this.__defaultLocationId}"
            .onChangeClaimStatus="${this.__handlers.changeClaimStatus}"
            .onRebillClaims="${this.__handlers.rebillClaims}"
            .onRequestStatus="${this.__handlers.requestStatus}"
            .onTotalChange="${this.__handlers.changeTotal}"
            .onSelect="${this.onSelect}"
            ?refreshing="${this.refreshing}"
            .expandFlags="${this.__expandFlagsSubmittedPage}"
            .onToggleExpand="${this.__handlers.toggleReadyExpand}"
            .onExpandAll="${this.__handlers.expandAll}"
            .onHidePopup="${this.__handlers.openConfirmHidePopup}"
            .onShowPopup="${this.__handlers.openConfirmShowPopup}"
            .onPrintClaims="${this.__handlers.printClaims}"
            .onCorrectClaim="${this.__handlers.correctClaim}"
            .onVoidClaim="${this.__handlers.voidClaim}"
            .onOpenClaimBatchesOverlay="${
              this.__handlers.openClaimBatchesOverlay
            }"
            .hasMaxClear="${this.__hasMaxClear}"
          ></neb-claim-status-controller>
        `,
      },
      {
        card: CARDS.DENIED.name,
        exact: false,
        path: '/denied/',
        resolver: () => html`
          <neb-claim-status-controller
            id="${ELEMENTS.deniedPage.id}"
            .config="${this.__buildTableConfig(CARDS.DENIED.name)}"
            .emptyMessage="${this.__buildTableEmptyMessage()}"
            .hasFitInvoiceOverlayPerformanceFF="${
              this.____hasFitInvoiceOverlayPerformanceFF
            }"
            .patientId="${this.patientId}"
            .status="${'denied'}"
            .statuses="${DENIED_AND_REJECTED_CLAIM_STATUSES}"
            .userLocations="${this.__userLocations}"
            .defaultLocationId="${this.__defaultLocationId}"
            .onChangeClaimStatus="${this.__handlers.changeClaimStatus}"
            .onRebillClaims="${this.__handlers.rebillClaims}"
            .onTotalChange="${this.__handlers.changeTotal}"
            .onSelect="${this.onSelect}"
            .expandFlags="${this.__expandFlagsDeniedPage}"
            .onToggleExpand="${this.__handlers.toggleReadyExpand}"
            .onExpandAll="${this.__handlers.expandAll}"
            .onHidePopup="${this.__handlers.openConfirmHidePopup}"
            .onShowPopup="${this.__handlers.openConfirmShowPopup}"
            .onPrintClaims="${this.__handlers.printClaims}"
            .onCorrectClaim="${this.__handlers.correctClaim}"
            .onVoidClaim="${this.__handlers.voidClaim}"
            .onOpenClaimBatchesOverlay="${
              this.__handlers.openClaimBatchesOverlay
            }"
          ></neb-claim-status-controller>
        `,
      },
      {
        card: CARDS.APPROVED.name,
        exact: false,
        path: '/approved/',
        resolver: () => html`
          <neb-claim-status-controller
            id="${ELEMENTS.approvedPage.id}"
            .config="${this.__buildTableConfig(CARDS.APPROVED.name)}"
            .emptyMessage="${this.__buildTableEmptyMessage()}"
            .patientId="${this.patientId}"
            .status="${'approved'}"
            .statuses="${APPROVED_CLAIM_STATUSES}"
            .userLocations="${this.__userLocations}"
            .defaultLocationId="${this.__defaultLocationId}"
            .onChangeClaimStatus="${this.__handlers.changeClaimStatus}"
            .onRebillClaims="${this.__handlers.rebillClaims}"
            .onTotalChange="${this.__handlers.changeTotal}"
            .onSelect="${this.onSelect}"
            ?refreshing="${this.refreshing}"
            .expandFlags="${this.__expandFlagsApprovedPage}"
            .onToggleExpand="${this.__handlers.toggleReadyExpand}"
            .onExpandAll="${this.__handlers.expandAll}"
            .onHidePopup="${this.__handlers.openConfirmHidePopup}"
            .onShowPopup="${this.__handlers.openConfirmShowPopup}"
            .onPrintClaims="${this.__handlers.printClaims}"
            .onCorrectClaim="${this.__handlers.correctClaim}"
            .onOpenClaimBatchesOverlay="${
              this.__handlers.openClaimBatchesOverlay
            }"
          ></neb-claim-status-controller>
        `,
      },
    ];
  }

  update(changedProps) {
    if (
      (changedProps.has('route') && this.route) ||
      changedProps.has('layout')
    ) {
      let card = this.__getRouteTail();

      if (!this.__evaluateRoute()) {
        card = this.layout === 'large' ? CARDS.READY.name : '';
        redirect(this.__buildRoute(card));
      }

      this.__selectedCard = card;
    }

    if (
      changedProps.has('patient') &&
      this.patient &&
      this.__currentPatientId !== this.patient.id
    ) {
      this.__currentPatientId = this.patient.id;
    }

    if (changedProps.has('patientId')) this.__navItems = this.genNavItems();

    super.update(changedProps);
  }

  firstUpdated() {
    this.__fetchAbandonedCount();
  }

  async updated(changedProps) {
    if (
      ((changedProps.has('patientId') && this.patientId) ||
        (changedProps.has('__userLocations') && this.__userLocations) ||
        (changedProps.has('__selectedCard') && this.__cardsFiltered)) &&
      this.__userLocations.length
    ) {
      this.__cardsFiltered = false;

      if (this.__hasClaimTotalsV2 === undefined) {
        this.__hasClaimTotalsV2 = await hasFeatureOrBeta(
          FEATURE_FLAGS.CLAIM_TOTALS_V2,
        );
      }

      this.__queryParams = {
        patientId: this.patientId,
        userLocationIds: this.__getUserLocationsQueryParameter(),
      };

      await this.__getClaimTotals();
    }
  }

  async __fetchAbandonedCount() {
    if (this.patientId || this.layout !== 'large') return;

    try {
      const { count } = await getAbandonedCount();
      this.__abandonedCount = count;
    } catch (err) {
      this.__abandonedCount = 0;
      store.dispatch(openError(ABANDONED_CLAIM_COUNT_ERROR));
    }
  }

  __buildCardValues() {
    const cardValues = Object.entries(this.__cardValues).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]:
          key.includes('Amount') && value !== null
            ? centsToCurrency(value)
            : value,
      }),
      {},
    );

    return cardValues;
  }

  __renderAbandonedCount() {
    return this.__abandonedCount !== 0 ? `(${this.__abandonedCount})` : '';
  }

  __renderAbandonedLink() {
    return !this.patientId && this.layout === 'large'
      ? html`
          <div
            id="${ELEMENTS.abandonedLink.id}"
            class="container-link container-link-abandoned"
            @click="${this.__handlers.clickUnlinkedClaims}"
          >
            <neb-icon class="icon icon-warning" icon="neb:warning"></neb-icon>

            <span class="link"
              >Unlinked Claim Responses ${this.__renderAbandonedCount()}</span
            >
          </div>
        `
      : '';
  }

  __renderClaimBatchesLink() {
    return this.layout !== 'small'
      ? html`
          <div
            id="${ELEMENTS.linkClaimBatches.id}"
            class="container-link"
            @click="${this.__handlers.openClaimBatchesOverlay}"
          >
            <neb-icon class="icon icon-batch" icon="neb:batch"></neb-icon>

            <span class="link">Claim Batches</span>
          </div>
        `
      : '';
  }

  render() {
    return html`
      <div class="header-container">
        <div id="${ELEMENTS.header.id}" class="header-text">
          ${
            this.patientId
              ? 'Patient Claims Worklist'
              : 'Practice Claims Worklist'
          }
        </div>

        <div class="container-links">
          ${this.__renderClaimBatchesLink()} ${this.__renderAbandonedLink()}
        </div>
      </div>

      <neb-claims-worklist-cards
        id="${ELEMENTS.cards.id}"
        .patient="${this.patientId ? this.patient : null}"
        .selectedCard="${this.__selectedCard}"
        .layout="${this.layout}"
        .cardValues="${this.__buildCardValues()}"
        .onCardSelect="${this.__handlers.selectCard}"
        .onAddPayment="${this.__handlers.addPayment}"
        .onAddPatientPayment="${this.__handlers.addPatientPayment}"
        ?disableCardClick="${this.layout !== 'large'}"
        ?loading="${this.__loading}"
      ></neb-claims-worklist-cards>

      ${matchRouteSwitch(this.__navItems, this.route)}
      ${
        this.__hasClaimsPlaygroundFeature
          ? html`
              <neb-claims-worklist-playground></neb-claims-worklist-playground>
            `
          : ''
      }
    `;
  }
}

customElements.define(
  'neb-claims-worklist-controller',
  NebClaimsWorklistController,
);
