import '../../neb-lit-components/src/components/neb-button';
import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/neb-checkbox-old';

import { html, css } from 'lit';

import { CSS_COLOR_BLACK } from '../../neb-styles/neb-variables';
import { arrayToSentence } from '../../neb-utils/array-to-sentence';

import { ELEMENTS as BASE_ELEMENTS, NebPopupMacros } from './neb-popup-macros';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  options: {
    selector: 'neb-checkbox-old',
  },
  preview: {
    id: 'preview',
  },
  actionButtons: {
    id: 'action-buttons',
  },
  clickRadio: {
    id: 'click-radio',
  },
  listRadio: {
    id: 'list-radio',
  },
};
export const ORDER = {
  CLICK: 'click',
  LIST: 'list',
};

class NebPopupMacrosMultiSelect extends NebPopupMacros {
  static get properties() {
    return {
      __orderBy: String,
      __selectedIndices: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .option-container {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          padding: 0;
          margin: 0 0 40px 0;
          list-style-type: none;
          overflow: auto;
        }

        .option {
          cursor: pointer;
          width: fit-content;
          --neb-checkbox-label-color: ${CSS_COLOR_BLACK};
        }

        .radio-container {
          display: flex;
          margin: 10px 0;
        }

        .list-radio {
          margin-left: 20px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__orderBy = ORDER.CLICK;
    this.__selectedIndices = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      click: (checked, { index }) => {
        const realIndex = this.getRealIndex(index);
        this.__selectedIndices = checked
          ? [...this.__selectedIndices, realIndex]
          : this.removeAtIndex(realIndex);

        this.handlers.change(this.genAnswerFromSelections());
      },

      select: orderBy => {
        this.__orderBy = orderBy;
        this.handlers.change(this.genAnswerFromSelections());
      },
    };
  }

  buildExtraAnswerProps() {
    return {
      order: this.__orderBy,
      selectedIndices: this.__selectedIndices,
    };
  }

  validateAnswer() {
    return (
      (this.__selectedIndices.length && this.answer !== '') || !!this.answer
    );
  }

  modelChanged() {
    this.title = this.model.title;

    if (this.model.answer) {
      this.__orderBy = this.model.answer.order;

      if (!this.answerIsSpecialDefault()) {
        this.__selectedIndices = this.model.answer.selectedIndices;
        this.answer = this.model.answer.text;
      }
    } else {
      this.__orderBy = ORDER.CLICK;
      this.__selectedIndices = [];
      this.answer = '';
    }
  }

  removeAtIndex(index) {
    const itemIndex = this.__selectedIndices.indexOf(index);

    return this.__selectedIndices
      .slice(0, itemIndex)
      .concat(this.__selectedIndices.slice(itemIndex + 1));
  }

  genAnswerFromSelections() {
    const indices =
      this.__orderBy === ORDER.CLICK
        ? this.__selectedIndices
        : this.__selectedIndices.slice().sort((a, b) => (a < b ? -1 : 1));

    const words = indices.map(index => this.model.options[index]);
    return arrayToSentence(words);
  }

  isSelected(index) {
    return (
      this.__selectedIndices.findIndex(i => i === this.getRealIndex(index)) !==
      -1
    );
  }

  isDefaultSpecial() {
    return (
      this.model.default &&
      this.model.default.length === 1 &&
      this.model.options[this.model.default[0]] === 'CHOOSE'
    );
  }

  answerIsSpecialDefault() {
    return (
      this.isDefaultSpecial() &&
      this.model.answer.selectedIndices.length === 1 &&
      this.model.answer.selectedIndices[0] === this.model.default[0]
    );
  }

  getRealIndex(index) {
    if (!this.isDefaultSpecial()) return index;
    return index >= this.model.default[0] ? index + 1 : index;
  }

  displayedOptions() {
    if (this.isDefaultSpecial()) {
      const options = [...this.model.options];
      options.splice(this.model.default[0], 1);
      return options;
    }

    return this.model.options;
  }

  __renderOptions() {
    return this.displayedOptions().map(
      (option, index) => html`
        <li>
          <neb-checkbox-old
            id="option-${index}"
            class="option"
            .index="${index}"
            .label="${option}"
            .checked="${this.isSelected(index)}"
            .onClick="${this.handlers.click}"
          >
          </neb-checkbox-old>
        </li>
      `,
    );
  }

  renderFooter() {
    return html`
      <div class="radio-container">
        <neb-radio-button
          id="${ELEMENTS.clickRadio.id}"
          label="Display in click order"
          .value="${ORDER.CLICK}"
          .checked="${this.__orderBy === ORDER.CLICK}"
          .onSelect="${this.handlers.select}"
        ></neb-radio-button>
        <neb-radio-button
          id="${ELEMENTS.listRadio.id}"
          class="${ELEMENTS.listRadio.id}"
          label="Display in list order"
          .value="${ORDER.LIST}"
          .checked="${this.__orderBy === ORDER.LIST}"
          .onSelect="${this.handlers.select}"
        ></neb-radio-button>
      </div>
    `;
  }

  renderMacroContent() {
    return html`
      <div class="content-container">
        ${
          this.model.options
            ? html`
                <ul class="option-container">
                  ${this.__renderOptions()}
                </ul>
              `
            : ''
        }
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-macros-multi-select',
  NebPopupMacrosMultiSelect,
);
