import '../../../packages/neb-lit-components/src/components/neb-loading-spinner-resizable';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import './neb-payfac-global-payments';
import './neb-payfac-fiserv';

import { html, css } from 'lit';

import { openDirtyPopup } from '../../../packages/neb-popup';
import NebPopup from '../../../packages/neb-popup/src/neb-popup';
import { CSS_SPACING } from '../../../packages/neb-styles/neb-variables';
import { MERCHANT_PROVIDERS } from '../../../packages/neb-utils/constants';
import {
  SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH,
  SMALL_LAYOUT_PAYMENTS_POPUP_HEIGHT,
} from '../../../packages/neb-utils/payment-popup-constants';

export const ELEMENTS = {
  subheaderText: {
    id: 'subheader-text',
  },
  amountText: {
    id: 'amount-text',
  },
  cardDescriptionInput: {
    id: 'card-description-input',
  },
  merchantAccountsDropdown: {
    id: 'merchant-accounts-dropdown',
  },
  payfacForm: {
    id: 'payfac-form',
  },
};

class NebPopupPayfacIframe extends NebPopup {
  static get properties() {
    return {
      __merchantAccountItems: Array,
      __selectedMerchantAccountItem: Object,
      __isDirty: Boolean,
      __hasDirtyPopup: Boolean,
      __isProcessing: Boolean,
      __cardDescription: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          height: auto;
          top: 140px;
          width: 525px;
        }

        :host([layout='small']) {
          margin: 40px 0;
          height: ${SMALL_LAYOUT_PAYMENTS_POPUP_HEIGHT}px;
          width: ${SMALL_LAYOUT_PAYMENTS_POPUP_WIDTH}px;
        }

        .container-content {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          gap: ${CSS_SPACING};
        }

        .text-message {
          font-weight: bold;
        }
      `,
    ];
  }

  modelChanged() {
    this.__merchantAccountItems = this.model.merchantAccounts
      .filter(v => v.active)
      .map(v => ({
        id: v.id,
        label: v.name,
      }));

    this.__selectedMerchantAccountItem = this.__merchantAccountItems[0];
    this.__setTitle();
  }

  initState() {
    super.initState();

    this.__merchantAccountItems = [];
    this.__selectedMerchantAccountItem = null;
    this.__isDirty = false;
    this.__hasDirtyPopup = false;
    this.__isProcessing = false;
    this.__cardDescription = '';

    this.model = {
      title: '',
      subheader: '',
      message: '',
      merchantAccounts: [],
      amount: '',
      holderId: '',
      holderType: '',
      postalCode: '',
      address1: '',
      logContent: {},
      confirmLabel: '',
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      merchantAccountChange: ({ value }) => {
        this.__selectedMerchantAccountItem = value;
      },

      changeCardDescription: ({ value }) => {
        if (value !== this.__cardDescription) {
          this.__cardDescription = value;
          this.__isDirty = true;
        }
      },

      setDirty: isDirty => {
        this.__isDirty = isDirty;
      },

      setProcessing: isProcessing => {
        this.__isProcessing = isProcessing;
        this.showCancelButton = !this.__isProcessing;
        this.__setTitle();
      },

      save: result => this.onClose(result),

      cancel: async () => {
        if (this.__isDirty) {
          this.__hasDirtyPopup = true;

          if (await openDirtyPopup()) {
            this.onClose(false);
          } else {
            this.__hasDirtyPopup = false;
          }
        } else {
          this.onClose(false);
        }
      },
    };
  }

  __setTitle() {
    if (this.__isProcessing) {
      this.title = this.model.amount
        ? 'Transaction Processing'
        : 'Authorizing Payment Method';
    } else {
      this.title = this.model.title;
    }
  }

  __renderSubheaderText() {
    return !this.__isProcessing
      ? html`
          <div id="${ELEMENTS.subheaderText.id}">${this.model.subheader}</div>
        `
      : '';
  }

  __renderAmountText() {
    return !this.__isProcessing && this.model.amount
      ? html`
          <div id="${ELEMENTS.amountText.id}" class="text-message">
            ${this.model.message}: ${this.model.amount}
          </div>
        `
      : '';
  }

  __renderCardDescriptionField() {
    return !this.__isProcessing && !this.model.amount
      ? html`
          <neb-textfield
            id="${ELEMENTS.cardDescriptionInput.id}"
            label="Card Description"
            maxLength="50"
            .value="${this.__cardDescription}"
            .onChange="${this.handlers.changeCardDescription}"
          ></neb-textfield>
        `
      : '';
  }

  __renderMerchantAccountsDropdown() {
    return this.__merchantAccountItems.length && !this.__isProcessing
      ? html`
          <neb-select
            id="${ELEMENTS.merchantAccountsDropdown.id}"
            label="Merchant Account"
            .items="${this.__merchantAccountItems}"
            .value="${this.__selectedMerchantAccountItem}"
            .onChange="${this.handlers.merchantAccountChange}"
          ></neb-select>
        `
      : '';
  }

  __renderPayfacForm() {
    if (!this.__selectedMerchantAccountItem) {
      return '';
    }

    const { merchantProvider } = this.model.merchantAccounts.find(
      ({ id }) => id === this.__selectedMerchantAccountItem.id,
    );

    switch (merchantProvider) {
      case MERCHANT_PROVIDERS.FISERV:
        return html`
          <neb-payfac-fiserv
            id="${ELEMENTS.payfacForm.id}"
            .merchantAccount="${this.__selectedMerchantAccountItem}"
            .amount="${this.model.amount}"
            .holderId="${this.model.holderId}"
            .holderType="${this.model.holderType}"
            .postalCode="${this.model.postalCode}"
            .address1="${this.model.address1}"
            .address2="${this.model.address2}"
            .city="${this.model.city}"
            .state="${this.model.state}"
            .firstName="${this.model.firstName}"
            .lastName="${this.model.lastName}"
            .cardDescription="${this.__cardDescription}"
            .logContent="${this.model.logContent}"
            .confirmLabel="${this.model.confirmLabel}"
            .layout="${this.layout}"
            .isProcessing="${this.__isProcessing}"
            .isDirty="${this.__isDirty}"
            .hasDirtyPopup="${this.__hasDirtyPopup}"
            .onDirty="${this.handlers.setDirty}"
            .onProcessing="${this.handlers.setProcessing}"
            .onSave="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-payfac-fiserv>
        `;

      default:
        return html`
          <neb-payfac-global-payments
            id="${ELEMENTS.payfacForm.id}"
            .merchantAccount="${this.__selectedMerchantAccountItem}"
            .amount="${this.model.amount}"
            .holderId="${this.model.holderId}"
            .holderType="${this.model.holderType}"
            .postalCode="${this.model.postalCode}"
            .address1="${this.model.address1}"
            .cardDescription="${this.__cardDescription}"
            .logContent="${this.model.logContent}"
            .confirmLabel="${this.model.confirmLabel}"
            .layout="${this.layout}"
            .isProcessing="${this.__isProcessing}"
            .isDirty="${this.__isDirty}"
            .hasDirtyPopup="${this.__hasDirtyPopup}"
            .onDirty="${this.handlers.setDirty}"
            .onProcessing="${this.handlers.setProcessing}"
            .onSave="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-payfac-global-payments>
        `;
    }
  }

  renderContent() {
    return html`
      <div class="container-content">
        ${this.__renderSubheaderText()}${this.__renderAmountText()}${
          this.__renderMerchantAccountsDropdown()
        }${this.__renderCardDescriptionField()}
        ${this.__renderPayfacForm()}
      </div>
    `;
  }
}

customElements.define('neb-popup-payfac-iframe', NebPopupPayfacIframe);
