import '../../../../../neb-material-design/src/components/neb-md-textfield-with-icon';
import '../../controls/neb-button-action';
import '../../neb-tooltip';
import '../../../../../../src/components/misc/neb-icon';
import '../../neb-bulk-update';
import './neb-packages-charges-table';

import { LitElement, css, html } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import { PACKAGE_TYPE } from '../../../../../neb-utils/enums';

export const ELEMENTS = {
  addChargeButton: {
    id: 'button-add-charge',
  },
  chargeTable: {
    id: 'charge-table',
  },
  searchField: {
    id: 'input-search',
  },
  bulkUpdate: {
    id: 'bulk-update',
  },
  bulkUpdateTooltip: {
    id: 'bulk-update-tooltip',
  },
};
export const MODEL_KEYS = {
  CHARGES: 'charges',
};
const HINT_TEXT =
  'To update multiple charges with the same value, enter the unit value and click the arrow icon to populate against all filtered charges below.';

class NebPackageTemplateCoveredCharges extends LitElement {
  static get properties() {
    return {
      pageCount: {
        type: Number,
      },
      currentPage: {
        type: Number,
      },
      searchText: {
        type: String,
      },
      tableType: {
        type: String,
      },
      hasCharges: {
        type: Boolean,
      },
      charges: {
        type: Array,
      },
      __maxUnits: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__elements = {};
    this.pageCount = 1;
    this.currentPage = 1;
    this.searchText = '';
    this.tableType = PACKAGE_TYPE.VISIT;
    this.hasCharges = false;
    this.charges = [];
    this.__maxUnits = '';

    this.onAddCharge = () => {};

    this.onUpdateCharge = () => {};

    this.onPageChanged = () => {};

    this.onFilterChanged = () => {};

    this.onRemoveCharge = () => {};

    this.onBulkUpdate = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addCharge: () => this.onAddCharge(),
      changeCharge: charge => this.onUpdateCharge(charge),
      search: ({ value }) => this.onFilterChanged(value),
      clearSearch: () => this.onFilterChanged(''),
      pageChanged: page => this.onPageChanged(page),
      removeCharge: (item, index) => this.onRemoveCharge(item, index),
      bulkUpdate: () => {
        this.onBulkUpdate(this.__maxUnits);
      },
      updateMaxUnits: value => {
        this.__maxUnits = value;
      },
    };
  }

  updated() {
    this.__elements = {
      chargeTable: this.shadowRoot.getElementById(ELEMENTS.chargeTable.id),
    };
  }

  validate() {
    return this.__elements.chargeTable.validate();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          margin: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
          flex-flow: column nowrap;
        }

        .add-and-update-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .update-and-tooltip-container {
          display: flex;
          align-items: center;
        }

        .left-padding {
          padding-left: 12px;
        }

        .tooltip {
          padding-top: 10px;
        }

        .button-add-charge {
          margin: 10px 0 ${CSS_SPACING} 0;
        }
      `,
    ];
  }

  __renderBulkUpdate() {
    const bulkDisabled = !(this.__maxUnits > 0);
    return html`
      <div class="update-and-tooltip-container">
        <neb-bulk-update
          id="${ELEMENTS.bulkUpdate.id}"
          .onUpdateMaxUnits="${this.__handlers.updateMaxUnits}"
          .onBulkUpdate="${this.__handlers.bulkUpdate}"
          .disabled="${bulkDisabled}"
          .maxUnits="${this.__maxUnits}"
        >
        </neb-bulk-update>
        <neb-tooltip
          id="${ELEMENTS.bulkUpdateTooltip.id}"
          class="left-padding"
          defaultAnchor="left"
        >
          <div class="tooltip" slot="tooltip">${HINT_TEXT}</div>
        </neb-tooltip>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        <neb-md-textfield-with-icon
          id="${ELEMENTS.searchField.id}"
          labelText="Enter procedure or description to filter list below."
          helperText=" "
          leadingIcon="search"
          leadingVisible
          trailingIcon="clear"
          trailingButton
          .trailingVisible="${!!this.__searchText}"
          .value="${this.__searchText}"
          .onClickTrailingIcon="${this.__handlers.clearSearch}"
          .onChange="${this.__handlers.search}"
        ></neb-md-textfield-with-icon>

        <div class="add-and-update-container">
          <neb-button-action
            id="${ELEMENTS.addChargeButton.id}"
            class="button-add-charge"
            label="Add Charge"
            .onClick="${this.__handlers.addCharge}"
          ></neb-button-action>

          ${
            this.tableType === PACKAGE_TYPE.SERVICE && this.hasCharges
              ? this.__renderBulkUpdate()
              : ''
          }
        </div>
      </div>

      <neb-packages-charges-table
        id="${ELEMENTS.chargeTable.id}"
        .tableType="${this.tableType}"
        .items="${this.charges}"
        .hasSearchText="${this.searchText}"
        .hasCharges="${this.hasCharges}"
        .pageCount="${this.pageCount}"
        .currentPage="${this.currentPage}"
        .onChange="${this.__handlers.changeCharge}"
        .onRemoveCharge="${this.__handlers.removeCharge}"
        .onPageChanged="${this.__handlers.pageChanged}"
      ></neb-packages-charges-table>
    `;
  }
}

customElements.define(
  'neb-package-template-covered-charges',
  NebPackageTemplateCoveredCharges,
);
