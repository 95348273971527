import { html, css, LitElement } from 'lit';

import '../neb-header';
import '../neb-tooltip';
import '../neb-radio-button';
import '../neb-pagination';
import '../inputs/neb-select';
import '../inputs/neb-textfield';
import '../inputs/neb-textarea';
import '../controls/neb-button-action';
import '../controls/neb-button-icon';
import '../controls/neb-switch';
import '../controls/neb-tab-group';
import '../tables/neb-table';
import '../../../../../src/components/misc/neb-icon';

import {
  UNLINKED_CLAIM_STATUS_DESCRIPTION,
  UNLINKED_ERA_DESCRIPTION,
} from '../../../../../src/utils/user-message';
import {
  CSS_BANNER_INFO_COLOR,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { centsToCurrency } from '../../../../neb-utils/formatters';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { MODE } from '../../../../neb-utils/table';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { SORT_DIR } from '../neb-table-header';

export const ELEMENTS = {
  claimStatusTable: { id: 'claim-status-table' },
  ERATable: { id: 'ERA-table' },
  claimStatusDescription: { id: 'claim-responses-description' },
  ERADescription: { id: 'ERA-description' },
  tabGroup: { id: 'tab-group' },
  pagination: { id: 'pagination' },
  viewButton: {
    selector: '[id^=view-button-]',
  },
};

const DISMISSED_ICON_CONFIG = {
  key: 'dismissed',
  flex: css`0 0 20px; margin: 0px 10px 0px -10px;`,
  formatter: value =>
    value
      ? html`
          <neb-icon
            style="
                width: 20px;
                height: 20px;
                fill: ${CSS_BANNER_INFO_COLOR};
              "
            ,
            icon="neb:dismissResponse"
          ></neb-icon>
        `
      : '',
};

const CLAIM_STATUS_CONFIG = [
  DISMISSED_ICON_CONFIG,
  {
    key: 'effectiveDate',
    label: 'Date',
    flex: css`1 0 0`,
    sortable: true,
    formatter: value => (value ? parseDate(value).format('MM/DD/YYYY') : ''),
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`1 0 0`,
  },
  {
    key: 'payerName',
    label: 'Payer',
    flex: css`1 0 0`,
  },
  {
    key: 'patientName',
    label: 'Patient',
    flex: css`1 0 0`,
  },
];

const ERA_CONFIG = [
  DISMISSED_ICON_CONFIG,
  {
    key: 'effectiveDate',
    label: 'Date',
    flex: css`1 0 0`,
    sortable: true,
    formatter: value => (value ? parseDate(value).format('MM/DD/YYYY') : ''),
  },
  {
    key: 'payerName',
    label: 'Payer',
    flex: css`3 0 0`,
  },
  {
    key: 'amount',
    label: 'Amount',
    flex: css`1 0 0`,
    formatter: value => (value ? centsToCurrency(value) : ''),
  },
];

class NebPageUnlinkedClaimResponses extends LitElement {
  static get properties() {
    return {
      abandonedClaimStatuses: Array,
      abandonedERAs: Array,
      __claimStatusItemsCollectionState: Object,
      __ERAItemsCollectionState: Object,
      __navItems: Object,
      __selectedTab: String,
      hideDismissedResponses: Boolean,
    };
  }

  static get styles() {
    return [
      css`
        .description {
          padding: ${CSS_SPACING};
        }

        .tabs {
          flex-grow: 1;
          padding-top: 7px;
        }

        .pagination-container {
          display: flex;
          justify-self: flex-end;
        }

        .pagination {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
    this.initServices();
  }

  initState() {
    this.__claimStatusItemsCollectionState = CollectionService.createModel();
    this.__ERAItemsCollectionState = CollectionService.createModel();
    this.__selectedTab = 'claimStatus';
    this.__navItems = this.__genNavItems();

    this.onRefresh = () => {};
    this.hideDismissedResponses = true;

    this.initialSortParams = {
      key: 'effectiveDate',
      dir: SORT_DIR.DESC,
    };

    this.abandonedERAs = [];
    this.abandonedClaimStatuses = [];
    this.claimStatusItemsSortParams = this.initialSortParams;
    this.ERAItemsSortParams = this.initialSortParams;
  }

  initHandlers() {
    this.__handlers = {
      changeClaimStatusItemsCollectionState: state => {
        this.__claimStatusItemsCollectionState = state;
      },
      changeERAItemsCollectionState: state => {
        this.__ERAItemsCollectionState = state;
      },
      changeClaimStatusItemsSort: (_name, result) => {
        this.claimStatusItemsSortParams = result[0];
        this.__claimStatusItemsCollectionService.setSortParams(
          this.claimStatusItemsSortParams,
        );
      },
      changeERAItemsSort: (_name, result) => {
        this.ERAItemsSortParams = result[0];
        this.__ERAItemsCollectionService.setSortParams(this.ERAItemsSortParams);
      },
      changeTab: tab => {
        this.__selectedTab = tab;
      },
      clickClaimStatusRow: async (_, data) => {
        await openOverlay(OVERLAY_KEYS.ABANDONED_CLAIM_STATUS, data);
        return this.onRefresh();
      },
      clickERARow: async (_, data) => {
        await openOverlay(OVERLAY_KEYS.ABANDONED_ERA, data);
        return this.onRefresh();
      },
      selectPage: page => {
        const collection =
          this.__selectedTab === 'claimStatus'
            ? this.__claimStatusItemsCollectionService
            : this.__ERAItemsCollectionService;

        collection.setPageIndex(page);
      },
      renderDataCell: (value, columnConfig, rowIndex) =>
        columnConfig.key === 'status'
          ? html`
              <neb-text>${value}</neb-text>
              ${
                html`
                  <neb-button-icon
                    id="view-button-${rowIndex}"
                    icon="neb:open"
                    class="icon"
                    name="${rowIndex}"
                    style="
                      width: 10%;
                      margin-left: 10px;
                      "
                    @click="${this.__handlers.clickViewIcon}"
                  ></neb-button-icon>
                `
              }
            `
          : html`
              <neb-text ?truncate="${columnConfig.truncate}">${value}</neb-text>
            `,

      clickViewIcon: e => {
        const claimStatus = this.__claimStatusItemsCollectionState.pageItems[
          Number(e.currentTarget.name)
        ];
        this.onViewClaimResponse(claimStatus.reportId);
        e.stopPropagation();
      },
    };
  }

  initServices() {
    this.__claimStatusItemsCollectionService = new CollectionService(
      {
        onChange: this.__handlers.changeClaimStatusItemsCollectionState,
        onSort: this.sort,
      },
      {
        sortParams: this.claimStatusItemsSortParams,
        hideInactive: false,
      },
    );

    this.__ERAItemsCollectionService = new CollectionService(
      {
        onChange: this.__handlers.changeERAItemsCollectionState,
        onSort: this.sort,
      },
      {
        sortParams: this.ERAItemsSortParams,
        hideInactive: false,
      },
    );
  }

  __genNavItems() {
    return [
      {
        id: 'claimStatus',
        label: `Claim Status${this.__getItemCount(
          this.__claimStatusItemsCollectionState.allItems,
        )}`,
        renderer: () => this.renderClaimStatusTab(),
      },
      {
        id: 'era',
        label: `ERA${this.__getItemCount(
          this.__ERAItemsCollectionState.allItems,
        )}`,
        renderer: () => this.renderERATab(),
      },
    ];
  }

  __setItems(filter = false) {
    if (filter) {
      this.__claimStatusItemsCollectionService.setItems(
        this.abandonedClaimStatuses.filter(i => !i.dismissed),
      );

      this.__ERAItemsCollectionService.setItems(
        this.abandonedERAs.filter(i => !i.dismissed),
      );
    } else {
      this.__claimStatusItemsCollectionService.setItems(
        this.abandonedClaimStatuses,
      );

      this.__ERAItemsCollectionService.setItems(this.abandonedERAs);
    }
  }

  update(changedProps) {
    if (
      changedProps.has('abandonedClaimStatuses') ||
      changedProps.has('abandonedERAs')
    ) {
      this.__setItems(this.hideDismissedResponses);
      this.__navItems = this.__genNavItems();
    }

    if (changedProps.has('__selectedTab')) {
      if (this.__selectedTab === 'claimStatus') {
        this.__handlers.changeClaimStatusItemsSort('', [
          this.initialSortParams,
        ]);
      } else this.__handlers.changeERAItemsSort('', [this.initialSortParams]);
    }

    if (changedProps.has('hideDismissedResponses')) {
      this.__setItems(this.hideDismissedResponses);
      this.__navItems = this.__genNavItems();
    }
    super.update(changedProps);
  }

  __getItemCount(item) {
    return item && item.length > 0 ? ` (${item.length})` : '';
  }

  sort(a, b, key) {
    const A = a[key];
    const B = b[key];

    if (A !== B) return A > B ? 1 : -1;
    return 0;
  }

  renderClaimStatusTab() {
    return html`
      <div id="${ELEMENTS.claimStatusDescription.id}" class="description">
        ${UNLINKED_CLAIM_STATUS_DESCRIPTION}
      </div>
      <neb-table
        id="${ELEMENTS.claimStatusTable.id}"
        emptyMessage="There are no unlinked claim responses."
        .config="${CLAIM_STATUS_CONFIG}"
        .model="${this.__claimStatusItemsCollectionState.pageItems}"
        .onSort="${this.__handlers.changeClaimStatusItemsSort}"
        .sortParams="${[this.claimStatusItemsSortParams]}"
        .mode="${MODE.DETAIL}"
        .onSelectRow="${this.__handlers.clickClaimStatusRow}"
        .renderDataCell="${this.__handlers.renderDataCell}"
      ></neb-table>

      ${this.renderPagination(this.__claimStatusItemsCollectionState)}
    `;
  }

  renderERATab() {
    return html`
      <div id="${ELEMENTS.ERADescription.id}" class="description">
        ${UNLINKED_ERA_DESCRIPTION}
      </div>
      <neb-table
        id="${ELEMENTS.ERATable.id}"
        emptyMessage="There are no unlinked ERAs."
        .config="${ERA_CONFIG}"
        .model="${this.__ERAItemsCollectionState.pageItems}"
        .onSort="${this.__handlers.changeERAItemsSort}"
        .sortParams="${[this.ERAItemsSortParams]}"
        mode="${MODE.DETAIL}"
        .onSelectRow="${this.__handlers.clickERARow}"
      ></neb-table>

      ${this.renderPagination(this.__ERAItemsCollectionState)}
    `;
  }

  renderPagination(tabState) {
    return tabState.pageCount > 1
      ? html`
          <div class="pagination-container">
            <neb-pagination
              id="${ELEMENTS.pagination.id}"
              class="pagination"
              .pageCount="${tabState.pageCount}"
              .currentPage="${tabState.pageIndex}"
              .onPageChanged="${this.__handlers.selectPage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  renderPage() {
    const item = this.__navItems.find(i => i.id === this.__selectedTab);

    return item ? item.renderer() : '';
  }

  render() {
    return html`
      <neb-tab-group
        class="tabs"
        id="${ELEMENTS.tabGroup.id}"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.__handlers.changeTab}"
      ></neb-tab-group>

      ${this.renderPage()}
    `;
  }
}

customElements.define(
  'neb-page-unlinked-claim-responses',
  NebPageUnlinkedClaimResponses,
);
