import { LitElement, html, css } from 'lit';

import { renderNebTableStyles } from '../../../../../neb-styles/neb-table-styles';
import {
  CSS_COLOR_ERROR,
  CSS_FONT_SIZE_CAPTION,
} from '../../../../../neb-styles/neb-variables';

import { FROM_RECORD } from './neb-patients-data-retriever';
import { recordDataSetter } from './neb-patients-data-setter';
import { DEFAULT_CELL_RENDERER } from './neb-patients-table-cell-renderer';
import { RECORD_TABLE_METADATA } from './neb-patients-table-meta';

const CSS_CELL_VALUE_HEIGHT = css`28px`;
class NebPatientsDataRow extends LitElement {
  static get properties() {
    return {
      index: {
        type: Number,
      },
      patient: {
        type: Object,
      },
      dataGetter: {
        type: Object,
      },
      dataSetter: {
        type: Object,
      },
      metadata: {
        type: Array,
      },
      cellRenderer: {
        type: Object,
      },
      editable: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.index = 0;
    this.patient = {};
    this.dataGetter = FROM_RECORD;
    this.dataSetter = recordDataSetter;
    this.metadata = RECORD_TABLE_METADATA;
    this.cellRenderer = DEFAULT_CELL_RENDERER;
    this.editable = false;
    this.__rowValues = {};

    this.onChange = (_dataRow, _index) => {};
  }

  __initHandlers() {
    this.__handlers = {
      valueChange: ({ value, id }) =>
        this.__valueChange({
          value,
          id,
        }),
    };
  }

  updated() {
    if (this.editable) {
      this.__rowValues = JSON.parse(JSON.stringify(this.patient));
    }
  }

  __renderDataCells() {
    return html`
      <div class="neb-table-row">
        ${
          this.metadata.map(meta =>
            this.cellRenderer.render({
              editable: this.editable,
              meta,
              value: this.__retrieveData(meta.data),
              onChange: this.__handlers.valueChange,
              validationNote: this.__retrieveValidationNotes(meta.data),
            }),
          )
        }
      </div>
    `;
  }

  static get styles() {
    return css`
      .neb-table-cell[invalid] .cell-value {
        border: 1px solid ${CSS_COLOR_ERROR};
        border-radius: 3px;
        min-height: ${CSS_CELL_VALUE_HEIGHT};
        padding: 5px;
        line-height: ${CSS_CELL_VALUE_HEIGHT};
        vertical-align: middle;
      }

      .neb-table-cell[invalid] {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .invalid-message {
        font-size: ${CSS_FONT_SIZE_CAPTION};
        color: ${CSS_COLOR_ERROR};
        word-break: normal;
      }
    `;
  }

  render() {
    return html`
      ${renderNebTableStyles()} ${this.__renderDataCells()}
    `;
  }

  __retrieveData(data) {
    const getter = this.dataGetter[data];
    return getter ? getter(this.patient) : '';
  }

  __retrieveValidationNotes(data) {
    return this.patient.validationNotes
      ? this.patient.validationNotes[data]
      : null;
  }

  __valueChange({ value, id }) {
    const cellComponent = this.shadowRoot.getElementById(id);
    this.dataSetter[cellComponent.dataProperty](this.__rowValues, value);
    this.onChange(this.__rowValues, this.index);
  }
}
customElements.define('neb-patients-data-row', NebPatientsDataRow);
