import '../neb-text';
import '../../../../../src/components/misc/neb-icon';
import '../controls/neb-progress-linear';
import '../controls/neb-button-action';
import '../neb-loading-spinner-resizable';

import { css, html } from 'lit';
import { map } from 'lit/directives/map.js';

import {
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  batchButtons: {
    selector: '[id^=batch-button-]',
  },
  batchStatus: {
    selector: '[id^=batch-status-]',
  },
  spinner: {
    id: 'spinner',
  },
};

const ACTION_BUTTONS = Object.freeze({
  Failed: [
    {
      icon: () => 'minus',
      action: 'delete',
    },
  ],
  Completed: [
    {
      icon: () => 'print',
      action: 'print',
    },
  ],
  'Ready to Generate': [
    {
      icon: () => 'export',
      action: 'export',
    },
    {
      icon: () => 'preview',
      action: 'preview',
    },
    {
      icon: () => 'checked',
      action: 'generate',
    },
    {
      icon: () => 'minus',
      action: 'delete',
    },
  ],
});

class NebTableBatches extends NebTable {
  static get properties() {
    return {
      generatingBatchId: {
        type: Number,
        reflect: true,
      },
    };
  }

  initState() {
    super.initState();

    this.config = this.buildConfig();
    this.generatingBatchId = null;
    this.emptyMessage =
      'There are no batches processing or completed at this time.';

    this.onPrint = () => {};

    this.onDelete = () => {};

    this.onExport = () => {};

    this.onGenerate = () => {};

    this.onPreview = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      print: e => this.onPrint(this.__getBatchId(e)),
      delete: e => this.onDelete(this.__getBatchId(e)),
      export: e => this.onExport(this.__getBatchId(e)),
      preview: e => this.onPreview(this.__getBatchModel(e)),
      generate: e => this.onGenerate(this.__getBatchId(e)),
    };
  }

  buildConfig() {
    return [
      {
        key: 'batchId',
        label: 'Batch ID',
        flex: css`0 0 60px`,
      },
      {
        key: 'status',
        label: 'Status',
        flex: css`0 0 140px`,
        formatter: (_, item) => item.completedCount / item.totalCount,
      },
      {
        key: 'dateRange',
        label: 'Batch Date Range',
        flex: css`0 0 220px`,
        formatter: (_, item) =>
          `${parseDate(item.dateOfServiceFrom).format(
            'MM/DD/YYYY',
          )} - ${parseDate(item.dateOfServiceTo).format('MM/DD/YYYY')}`,
      },
      {
        key: 'completedAt',
        label: 'Completion Date',
        flex: css`0 0 120px`,
        formatter: v => (v ? `${parseDate(v).format('MM/DD/YYYY')}` : '-'),
      },
      {
        key: 'action-buttons',
        label: '',
        flex: css`1 0 0`,
      },
    ];
  }

  __getBatchModel(item) {
    const { id } = item;
    const index = Number(id.split('-')[2]);
    return this.model[index];
  }

  __getBatchId(item) {
    const { id } = item;
    const index = Number(id.split('-')[2]);
    return this.model[index].batchId;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 100%;
        }

        :host .batch-buttons {
          display: grid;
          grid-template-columns: auto auto auto auto;
          gap: 5px;
          width: 100%;
        }

        :host .cell-data {
          padding-top: 13px;
          padding-bottom: 13px;
        }

        .container-spinner {
          display: flex;
          flex-direction: row;
          box-sizing: border-box;
          align-items: center;
        }

        .spinner {
          text-align: center;
          z-index: 1;
        }

        .text {
          margin: 0 6px;
          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-align: center;
        }

        neb-progress-linear {
          position: fixed;
          width: 150px;
          z-index: 1;
        }
      `,
    ];
  }

  __getStatus(rowIndex) {
    const { failed, status, completedCount, totalCount } = this.model[rowIndex];
    const progress = completedCount / totalCount;

    if (failed) {
      return 'Failed';
    }

    return progress < 1
      ? html`
          <neb-progress-linear .value="${progress}"></neb-progress-linear>
        `
      : status || 'Completed';
  }

  __renderStatus(rowIndex) {
    const status = this.__getStatus(rowIndex);

    return html`
      <span id="batch-status-${rowIndex}">${status}</span>
    `;
  }

  __renderButtons(rowIndex) {
    if (
      this.generatingBatchId &&
      this.generatingBatchId === this.model[rowIndex].batchId
    ) {
      return this.__renderSpinner();
    }
    const status = this.__getStatus(rowIndex);
    const buttons = ACTION_BUTTONS[status] || [];

    return map(buttons, (button, i) => {
      const label = `${button.action
        .charAt(0)
        .toUpperCase()}${button.action.slice(1)}`;
      return html`
        <neb-button-action
          id="batch-button-${rowIndex}-${i}"
          .leadingIcon="${button.icon()}"
          .label="${label}"
          .onClick="${this.handlers[`${button.action}`]}"
        ></neb-button-action>
      `;
    });
  }

  __renderSpinner() {
    return html`
      <div class="container-spinner">
        <neb-loading-spinner-resizable
          id="${ELEMENTS.spinner.id}"
          class="spinner"
          .radius="${8}"
        ></neb-loading-spinner-resizable>
        <span class="text"> Generating...</span>
      </div>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    switch (columnConfig.key) {
      case 'status':
        return this.__renderStatus(rowIndex);

      case 'action-buttons':
        return html`
          <div class="batch-buttons">${this.__renderButtons(rowIndex)}</div>
        `;

      default:
        return super.renderDataCell(value, columnConfig, rowIndex, name, error);
    }
  }
}
customElements.define('neb-table-batches', NebTableBatches);
