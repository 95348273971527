import '../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../packages/neb-lit-components/src/components/neb-progress-blocker';
import equal from 'fast-deep-equal';
import { html } from 'lit';

import { NebLayout } from '../../../packages/neb-lit-components/src/components/neb-layout';
import { setValueByPath } from '../../../packages/neb-utils/utils';

function getStateToModelKeys(selectors) {
  return Object.entries(selectors).reduce((acc, [key, { stateKey }]) => {
    acc[stateKey || key] = key;

    return acc;
  }, {});
}

function handleChildren(prop, children, convert) {
  let childValues = null;

  return Object.entries(children).reduce(
    (childState, [childKey]) => {
      if (childKey === '*') {
        if (prop && !prop.map) {
          throw new Error(
            'Property has wildcard selectors but is not an array',
          );
        }

        childState = prop?.map(item => convert(item, children)['*']) || [];
      } else {
        childValues = childValues ?? convert(prop, children);
        childState[childKey] = prop ? childValues[childKey] : {};
      }

      return childState;
    },
    typeof prop === 'object' ? { ...prop } : {},
  );
}

export const ELEMENTS = {
  loadingBlocker: { id: 'blocker-loading', tag: 'neb-progress-blocker' },
  saveBlocker: { id: 'blocker-save', tag: 'neb-progress-blocker' },
  actionBar: { id: 'action-bar', tag: 'neb-action-bar' },
};

export class NebSimpleForm extends NebLayout {
  static get properties() {
    return {
      model: Object,
      disableSaveIndicator: {
        type: Boolean,
        reflect: true,
        attribute: 'disable-save-indicator',
      },
      loading: {
        reflect: true,
        type: Boolean,
      },
      __saving: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.model = {};

    this.__selectors = {};
    this.__dirtySet = new Set();
    this.__isDirty = false;
    this.__errors = {};
    this.__initialState = {};
    this.__saving = false;
    this.__pristineValues = {};
    this.__hasErrors = false;
    this.__ignorePristine = false;

    this.loading = false;
    this.saveBlockerLabel = 'Saving';
    this.confirmLabel = 'Save';
    this.cancelLabel = 'Cancel';
    this.removeLabel = 'Remove';
    this.enableSaveAndClose = false;
    this.disableSaveIndicator = false;
    this.__alwaysValidateKeys = [];

    this.onSave = () => {};

    this.onError = () => {};

    this.onCancel = () => {};

    this.onChangeDirty = () => {};
  }

  initHandlers() {
    this.handlers = {
      cancel: () => this.onCancel(),
      save: (...args) => this.save(...args),
      saveAndClose: (...args) => this.save({ ...args, closeAfterSave: true }),
      change: e => {
        setValueByPath(this, e.name.split('.'), e.value);
        this.requestUpdate();
      },
    };
  }

  get errors() {
    return this.__errors;
  }

  save(...args) {
    this.setErrors();

    if (!this.__hasErrors) {
      const saveModel = this.convertStateToModel();

      this.__saving = true;
      return this.onSave(saveModel, ...args);
    }

    return this.onError();
  }

  /**
   * @abstract
   * @returns {Object} - selectors object
   * @description Must return an object with keys that match the model keys and values that are objects with the following properties:
   * - toState: a function that converts the model value to the state value, if function is not provided, the model value will be used as the state value
   * - toModel: a function that converts the state value to the model value, if function is not provided, the state value will be used as the model value
   * - validate: a function that validates the state value and returns an error message if invalid
   * - children: an object that defines children selectors, if provided children selectors will be used to convert
   * - alwaysValidate: a boolean that indicates if the selector should be validated every validation cycle
   * - stateKey: the key in the state object that the child value will be stored in
   * - '*': an object that defines selectors for array items
   * @example
   * createSelectors() {
   *  return {
   *   name: {
   *    toState: value => value + '!',
   *    toModel: value => value.slice(0, -1),
   *    validate: value => (value ? '' : 'Name is required'),
   *   },
   *  };
   * },
   */
  createSelectors() {
    throw new Error('createSelectors must be implemented');
  }

  initSelectors() {
    this.__selectors = this.createSelectors();
  }

  __setDirty(changedProps) {
    changedProps.forEach((_, key) => {
      const modelKey = this.__stateToModelKeys[key];

      if (!this.__selectors[modelKey]) return;

      if (!equal(this[key], this.__initialState[key])) {
        this.__dirtySet.add(key);
      } else {
        this.__dirtySet.delete(key);
      }
    });

    this.__isDirty = !!this.__dirtySet.size;

    this.onChangeDirty(this.__isDirty);
  }

  __setErrorsForChanged(changedProps) {
    const errors = {};
    const pristineValues = {};

    const stateKeys = [
      ...Array.from(changedProps.keys()),
      ...this.__alwaysValidateKeys,
    ];

    stateKeys.forEach(stateKey => {
      const modelKey = this.__stateToModelKeys[stateKey];

      if (!this.__selectors[modelKey]) return;

      const { error, pristine } = this.__validateProperty(
        this[stateKey],
        this.__selectors[modelKey],
        this.__pristineValues[modelKey],
      );

      errors[modelKey] = error;
      pristineValues[modelKey] = pristine;
    });

    this.__errors = { ...this.__errors, ...errors };
    this.__pristineValues = { ...this.__pristineValues, ...pristineValues };
  }

  setErrors() {
    this.__hasErrors = false;
    this.__ignorePristine = true;

    Object.entries(this.__selectors).forEach(([modelKey, selector]) => {
      const stateKey = selector.stateKey || modelKey;

      const { error, pristine } = this.__validateProperty(
        this[stateKey],
        selector,
        this.__pristineValues[modelKey],
      );

      this.__errors[modelKey] = error;
      this.__pristineValues[modelKey] = pristine;
    });

    this.__ignorePristine = false;

    this.requestUpdate();
  }

  // eslint-disable-next-line complexity
  __validateProperty(prop, selector, pristineValue) {
    if (!selector) return { error: '', pristine: undefined };

    if (selector.children) {
      return this.__validateChildren(prop, selector.children, pristineValue);
    }

    if (
      !this.__ignorePristine &&
      (pristineValue === undefined || equal(prop, pristineValue))
    ) {
      return { error: '', pristine: prop };
    }

    const error = selector.validate?.(prop) ?? '';

    this.__hasErrors = this.__hasErrors || !!error;

    return { error, pristine: false };
  }

  __validateChildren(prop, children, pristineValue) {
    return Object.entries(children).reduce(
      (acc, [childKey, childSelector]) => {
        if (childKey === '*') {
          if (!prop?.length) {
            return acc;
          }

          for (let i = 0; i < prop.length; i++) {
            const { error, pristine } = this.__validateProperty(
              prop[i],
              childSelector,
              pristineValue?.[i],
            );

            acc.error[i] = error;
            acc.pristine[i] = pristine;
          }
        } else {
          const stateKey = childSelector.stateKey || childKey;
          const { error, pristine } = this.__validateProperty(
            prop[stateKey],
            childSelector,
            pristineValue?.[stateKey],
          );

          acc.error[stateKey] = error;
          acc.pristine[stateKey] = pristine;
        }
        return acc;
      },
      { error: {}, pristine: {} },
    );
  }

  willUpdate(changedProps) {
    if (changedProps.has('model')) {
      this.initSelectors();
      this.__convertModelToState(this.model);
    }
  }

  update(changedProps) {
    if (!changedProps.has('model')) {
      this.__setDirty(changedProps);
    }

    this.__setErrorsForChanged(changedProps);
    super.update(changedProps);
  }

  __convertToModel(state, selectors) {
    return Object.entries(selectors).reduce(
      (model, [modelKey, { toModel, stateKey = modelKey, children }]) => {
        const stateValue = modelKey === '*' ? state : state[stateKey];

        if (children) {
          model[modelKey] = handleChildren(stateValue, children, (...args) =>
            this.__convertToModel(...args),
          );
        } else {
          model[modelKey] = toModel ? toModel(stateValue) : stateValue;
        }

        return model;
      },
      {},
    );
  }

  convertStateToModel() {
    return this.__convertToModel(this, this.__selectors);
  }

  __convertToState(model, selectors) {
    return Object.entries(selectors).reduce(
      (
        state,
        [modelKey, { toState, stateKey = modelKey, children, alwaysValidate }],
      ) => {
        if (alwaysValidate) {
          this.__alwaysValidateKeys = [...this.__alwaysValidateKeys, stateKey];
        }

        const modelValue = modelKey === '*' ? model : model[modelKey];

        if (children) {
          state[stateKey] = handleChildren(modelValue, children, (...args) =>
            this.__convertToState(...args),
          );
        } else {
          state[stateKey] = toState ? toState(modelValue) : modelValue;
        }

        return state;
      },
      {},
    );
  }

  __convertModelToState(model) {
    const state = this.__convertToState(model, this.__selectors, this);

    Object.entries(state).forEach(([stateKey, value]) => {
      this[stateKey] = value;
    });

    this.__initialState = Object.keys(this.__selectors).reduce(
      (acc, modelKey) => {
        const stateKey = this.__selectors[modelKey].stateKey || modelKey;

        try {
          acc[stateKey] = JSON.parse(JSON.stringify(this[stateKey]));
        } catch (e) {
          acc[stateKey] = structuredClone(this[stateKey]);
        }

        return acc;
      },
      {},
    );

    this.__dirtySet.clear();

    this.__stateToModelKeys = getStateToModelKeys(this.__selectors);
  }

  renderSaveBlocker() {
    return !this.disableSaveIndicator && this.__saving
      ? html`
          <neb-progress-blocker
            id="${ELEMENTS.saveBlocker.id}"
            .label="${this.saveBlockerLabel}"
          ></neb-progress-blocker>
        `
      : '';
  }

  renderLoadingBlocker() {
    return this.loading
      ? html`
          <neb-progress-blocker
            id="${ELEMENTS.loadingBlocker.id}"
            label="Loading"
          ></neb-progress-blocker>
        `
      : '';
  }

  __renderActionBar() {
    return this.enableSaveAndClose
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.saveAndClose}"
            .onRemove="${this.handlers.cancel}"
            confirmLabel="${this.confirmLabel}"
            cancelLabel="${this.cancelLabel}"
            removeLabel="${this.removeLabel}"
          ></neb-action-bar>
        `
      : html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
            confirmLabel="${this.confirmLabel}"
            cancelLabel="${this.cancelLabel}"
            ?hideBorderTop="${this.actionBarHideBorderTop}"
          ></neb-action-bar>
        `;
  }

  renderActionBar() {
    return this.__isDirty || this.alwaysRenderActionBar
      ? this.__renderActionBar()
      : '';
  }

  renderFooter() {
    return html`
      ${this.renderActionBar()} ${this.renderSaveBlocker()}
      ${this.renderLoadingBlocker()}
    `;
  }
}

window.customElements.define('neb-simple-form', NebSimpleForm);
