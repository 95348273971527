import { store } from '../../neb-redux/neb-redux-store';
import { objToName, HIDE_PREFERRED_OPTS } from '../../neb-utils/formatters';

import { getBillingInfo } from './billing-information-api-client';
import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';
import { buildStatementBody } from './utils/ledger-utils';

const NO_ASSOCIATED_PROVIDER_ID = 'null';
const BASE_URL = 'statements';
const OPTS_V2 = { version: 2 };
const OPTS_V4 = { version: 4 };
const headers = {
  'Content-Type': 'application/json',
};

function filterNoAssociatedProviderId(ids) {
  return ids.filter(item => item !== NO_ASSOCIATED_PROVIDER_ID);
}

function remapPerson(person) {
  return {
    first: person.firstName,
    last: person.lastName,
    middle: person.middleName,
    suffix: person.suffix,
  };
}

export function guarantorNameOrg(guarantor) {
  return guarantor.personId
    ? objToName(remapPerson(guarantor.person), HIDE_PREFERRED_OPTS)
    : guarantor.organization.name;
}

function buildRequestor(apiClient) {
  return (method, path, body = null, opts = {}) => {
    const {
      responseType = RESPONSE_TYPE.JSON,
      version,
      query,
      optOutLoadingIndicator = false,
    } = opts;

    return apiClient.makeRequest({
      path,
      method,
      version: version || undefined,
      responseType,
      queryParams: query || {},
      body: body ? JSON.stringify(body) : undefined,
      headers,
      optOutLoadingIndicator,
    });
  };
}

export const apiClientBilling = new ApiClient({ microservice: 'billing' });
export const apiClientBillingV2 = new ApiClientV2({ microservice: 'billing' });
export const apiClientPDF = new ApiClient({
  microservice: 'pdf',
  useTenant: true,
});

const makeBillingRequest = buildRequestor(apiClientBilling);
const makePdfRequest = buildRequestor(apiClientPDF);

// eslint-disable-next-line complexity
export async function add(
  patientId,
  model,
  preview,
  addressIds,
  optOutLoadingIndicator,
) {
  const billingInformation = await getBillingInfo(optOutLoadingIndicator);

  if (
    !billingInformation?.id ||
    (!billingInformation?.billingAddress || !billingInformation?.payToAddress)
  ) {
    return { missingbillingInfo: true };
  }

  const nullProvider = model.providerIds.includes(NO_ASSOCIATED_PROVIDER_ID);
  const providerIds = filterNoAssociatedProviderId(model.providerIds);

  const body = {
    preview,
    patientId,
    addressIds,
    statementBody: {
      agingTable: model.agingTable,
      allCharges: model.allCharges,
      groupStatements: model.groupStatements,
      previousBalance: model.previousBalance,
      nullProvider,
      providers: providerIds,
      relatedPatientIds: model.relatedPatientIds,
      dateOfServiceTo: model.dateOfServiceTo || undefined,
      dateOfServiceFrom: model.dateOfServiceFrom || undefined,
      dueByDate: model.dueByDate,
      includePaymentDetails: model.includePaymentDetails,
      showCarePackageChargesAsPatientPaid:
        model.showCarePackageChargesAsPatientPaid,
      includeCreditCardCutOff: model.includeCreditCardCutOff,
      minimumBalance: model.minimumBalance || 0,
      balanceEmail: model.balanceEmail,
    },
    comment: model.comment,
    ...(model.billingAddressId && { locationId: model.billingAddressId }),
  };

  const res = await makeBillingRequest('POST', BASE_URL, body, OPTS_V4);
  return res.data;
}

const getBusinessName = ({ location, pdfBody, locationSelected }) => {
  const shouldDisplayBusinessName =
    locationSelected && location && location.businessName;

  return shouldDisplayBusinessName
    ? location.businessName
    : pdfBody.practiceInformation.name;
};

function formatAddress(address) {
  return {
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    state: address.state,
    zipcode: address.zipCode || address.zipcode,
  };
}

const buildBillingInfo = ({
  pdfBody,
  billingInfoLocation,
  payToAddressLocation,
  billingLocationSelected,
  payToLocationSelected,
}) => {
  const payToAddress = formatAddress(payToAddressLocation);
  const billingAddress = formatAddress(billingInfoLocation);

  payToAddress.businessName = getBusinessName({
    location: payToAddressLocation,
    pdfBody,
    locationSelected: payToLocationSelected,
  });

  billingAddress.businessName = getBusinessName({
    location: billingInfoLocation,
    pdfBody,
    locationSelected: billingLocationSelected,
  });

  return { payToAddress, billingAddress };
};

const getAddressLocation = ({ pdfBody, addressId, addressKey }) => {
  if (addressId) {
    const addressLocation = pdfBody.practiceInformation.locations.find(
      l => l.id === addressId,
    );

    if (addressLocation) return addressLocation;
  }

  const addressLocationId = pdfBody.billingInformation[addressKey].locationId;

  if (addressLocationId) {
    return pdfBody.practiceInformation.locations.find(
      l => l.id === addressLocationId,
    );
  }

  return (
    pdfBody.billingInformation[addressKey] ||
    pdfBody.practiceInformation.locations[0]
  );
};

const setBillingAndPayToAddresses = (
  pdfBody,
  billingAddressId,
  payToAddressId,
) => {
  const billingInfoLocation = getAddressLocation({
    pdfBody,
    addressId: billingAddressId,
    addressKey: 'billingAddress',
  });

  const payToAddressLocation = getAddressLocation({
    pdfBody,
    addressId: payToAddressId,
    addressKey: 'payToAddress',
  });

  return { billingInfoLocation, payToAddressLocation };
};

export function buildStatementBodyWithLocations({
  body,
  billingAddressId,
  payToAddressId,
}) {
  const pdfBody = { ...body };
  const billingLocationSelected = !!billingAddressId;
  const payToLocationSelected = !!payToAddressId;

  const {
    billingInfoLocation,
    payToAddressLocation,
  } = setBillingAndPayToAddresses(pdfBody, billingAddressId, payToAddressId);

  pdfBody.practiceInformation.phoneNumber =
    billingInfoLocation.phoneNumber ||
    pdfBody.practiceInformation.locations[0].phoneNumber;

  pdfBody.practiceInformation.email =
    billingInfoLocation.emailAddress ||
    pdfBody.practiceInformation.locations[0].emailAddress;

  const { payToAddress, billingAddress } = buildBillingInfo({
    pdfBody,
    billingInfoLocation,
    payToAddressLocation,
    billingLocationSelected,
    payToLocationSelected,
  });

  return {
    ...pdfBody,
    billingInformation: {
      ...pdfBody.billingInformation,
      payToAddress,
      billingAddress,
      hideLogo: billingInfoLocation.hideLogo,
    },
  };
}

export async function print({
  patientId,
  statements,
  addressIds,
  comment,
  preview,
  optOutLoadingIndicator = false,
}) {
  const { billingAddressId, payToAddressId } = addressIds;
  const pdfBody = await buildStatementBody(
    patientId,
    statements,
    preview,
    comment,
    store.getState(),
  );

  const updatedPdfBody = await buildStatementBodyWithLocations({
    body: pdfBody,
    billingAddressId,
    payToAddressId,
  });

  try {
    const path = 'statement';
    const res = await makePdfRequest('POST', path, updatedPdfBody, {
      ...OPTS_V4,
      optOutLoadingIndicator,
    });

    if (!preview) {
      const billingBody = { statements: res.statementS3Key };

      await makeBillingRequest('PUT', BASE_URL, billingBody, OPTS_V2);
    }

    return res.buffer;
  } catch (err) {
    if (!preview) {
      const billingBody = {
        statementIds: statements.map(item => item.id),
      };

      await makeBillingRequest('DELETE', BASE_URL, billingBody, OPTS_V2);
    }
    return Promise.reject(Error('Error generating statement'));
  }
}

export async function fetchMany(query) {
  let queryParams;

  if (query.sortParams) {
    const { key, dir } = query.sortParams;
    queryParams = { ...query, sortField: key, sortDir: dir };
    delete queryParams.sortParams;
  }

  const res = await apiClientBilling.makeRequest({
    method: Method.GET,
    path: BASE_URL,
    queryParams,
    headers,
    version: 2,
  });

  return res;
}

export async function fetchOne(statementId) {
  const route = `${BASE_URL}/${statementId}`;
  const opts = {
    responseType: RESPONSE_TYPE.RAW,
    version: 2,
  };
  const response = await makeBillingRequest('GET', route, null, opts);

  return response.arrayBuffer();
}

export async function addBatch({
  model,
  preview,
  optOutLoadingIndicator = false,
  addressIds,
}) {
  const billingInformation = await getBillingInfo(optOutLoadingIndicator);

  if (
    !billingInformation?.id ||
    (!billingInformation?.billingAddress || !billingInformation?.payToAddress)
  ) {
    return { missingbillingInfo: true };
  }
  const path = `${BASE_URL}/batches`;
  const nullProvider = model.providerIds.includes(NO_ASSOCIATED_PROVIDER_ID);
  const providerIds = filterNoAssociatedProviderId(model.providerIds);
  const practiceInformation = store.getState().practiceInformation.item;

  const body = {
    preview,
    addressIds,
    statementBody: {
      agingTable: model.agingTable,
      allCharges: model.allCharges,
      groupStatements: model.groupStatements,
      previousBalance: model.previousBalance,
      nullProvider,
      providers: providerIds,
      dateOfServiceTo: model.dateOfServiceTo,
      dateOfServiceFrom: model.dateOfServiceFrom,
      dueByDate: model.dueByDate,
      includePaymentDetails: model.includePaymentDetails,
      showCarePackageChargesAsPatientPaid:
        model.showCarePackageChargesAsPatientPaid,
      includeCreditCardCutOff: model.includeCreditCardCutOff,
      minimumBalance: model.minimumBalance || 0,
      balanceEmail: model.balanceEmail,
    },
    practiceInformation,
    billingInformation,
    comment: model.comment,
    ...(model.billingAddressId && { locationId: model.billingAddressId }),
  };

  const { billingAddressId, payToAddressId } = addressIds;

  const updatedPdfBody = await buildStatementBodyWithLocations({
    body,
    billingAddressId,
    payToAddressId,
  });

  const res = await makeBillingRequest('POST', path, updatedPdfBody, {
    ...OPTS_V4,
    optOutLoadingIndicator,
  });

  return res.data;
}

export async function fetchBatch(batchId) {
  const route = `${BASE_URL}/batches/${batchId}`;
  const opts = {
    responseType: RESPONSE_TYPE.RAW,
  };
  const response = await makeBillingRequest('GET', route, null, opts);

  return response.arrayBuffer();
}

export function fetchBatches() {
  const path = `${BASE_URL}/batches`;

  return makeBillingRequest('GET', path);
}

export function deleteBatch(batchId) {
  const path = `${BASE_URL}/batches/${batchId}`;

  return makeBillingRequest('DELETE', path);
}

export async function getBatchStatements(batchId, query) {
  let queryParams;

  if (query.sortParams) {
    const { key, dir } = query.sortParams;
    queryParams = { ...query, sortField: key, sortDir: dir };
    delete queryParams.sortParams;
  }
  const res = await apiClientBillingV2.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/statements/batch/:batchId',
    queryParams,
    headers,
    replacements: { batchId },
    optOutLoadingIndicator: false,
    version: 1,
  });

  return res;
}

export async function removeOrAddStatementFromBatch(
  batchId,
  statementIds,
  excludedFromBatch,
) {
  const res = await apiClientBillingV2.makeRequest({
    method: Method.PUT,
    path: '/api/v1/tenants/:tenantId/statements/batch/:batchId',
    headers,
    replacements: { batchId },
    optOutLoadingIndicator: false,
    version: 1,
    body: { excludedFromBatch, statementIds },
    responseType: RESPONSE_TYPE.RAW,
  });

  return res;
}
