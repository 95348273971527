import { openPopup } from '@neb/popup';
import { html } from 'lit';
import moment from 'moment-timezone';

import * as feeScheduleApi from '../../src/api-clients/fee-schedules';
import { getPatientPackages } from '../../src/api-clients/patient-package';
import { getTaxRates } from '../../src/api-clients/tax-rates';
import { openPaymentDetailOverlay } from '../../src/utils/payment-util';
import { deleteAllocation } from '../neb-api-client/src/allocation-api-client';
import {
  fetch,
  TYPE,
  getBillingCodesWriteOffs,
  getBillingCodesPayments,
  toFlags,
} from '../neb-api-client/src/billing-codes';
import {
  getInvoice,
  getInvoices,
} from '../neb-api-client/src/invoice-api-client';
import { fetchMany } from '../neb-api-client/src/patient-cases';
import * as patientFeeScheduleApiClient from '../neb-api-client/src/patient-fee-schedules';
import { getPatientGuarantors } from '../neb-api-client/src/patient-guarantor-api-client';
import { getPatientInsurances } from '../neb-api-client/src/patient-insurance-api-client';
import {
  getPayerPlans,
  getPayerPlan,
} from '../neb-api-client/src/payer-plan-api-client';
import { getPracticeUsers } from '../neb-api-client/src/practice-users-api-client';
import { getTenantId } from '../neb-api-client/src/utils/api-client-utils';
import { NO_INVOICES_TEXT } from '../neb-lit-components/src/components/patients/ledger/neb-ledger-invoice-section';
import { openEncounterSummary } from '../neb-lit-components/src/utils/encounter-overlays-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../neb-lit-components/src/utils/overlay-constants';
import { getAdjustments } from '../neb-lit-components/src/utils/purchase-adjustments';
import { openDirtyDialog, SAVE_BUTTON, STAY_BUTTON } from '../neb-popup';
import { POPUP_RENDER_KEYS } from '../neb-popup/src/renderer-keys';

import { CODE_WRITE_OFFS, CODE_PAYMENTS } from './constants';
import { parseDate } from './date-util';
import {
  centsToCurrency,
  currencyToCents,
  currencyToCentsWithNegative,
  objToName,
} from './formatters';
import { getPatientDisplayName } from './neb-charting-util';
import {
  filterAuthorizationsFromCases,
  formatAuthorizationLabel,
  isAuthorizationValidForLinking,
} from './patientAuthorization';
import { mapToPatientInsuranceModel } from './patientInsurance';
import { openPayerPlanOverlay } from './payer-plan-util';
import { ITEM_EMPTY } from './selectors';
import { hasErrors, getValueByPath, traverse } from './utils';

export const PACKAGE_CODES = ['Pkg', 'Sub'];

export const TRANSFER_BALANCE_FROM = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  PATIENT: 'Patient',
};
export const TRANSFER_BALANCE_FROM_ITEMS = Object.values(TRANSFER_BALANCE_FROM);

export const TRANSFER_BALANCE_TO = {
  ...TRANSFER_BALANCE_FROM,
  ADJUSTMENT: 'Adjustment',
};

export const TRANSFER_BALANCE_TO_ITEMS = Object.values(TRANSFER_BALANCE_TO);

export const CODE_TYPE = {
  CHARGE: 'charge',
  CODE_CHARGE: 'codeCharge',
};

export const LINE_ITEM_TYPE = {
  ENCOUNTER_CHARGE: 'encounterCharge',
  FEE: 'fee',
  PURCHASE: 'purchase',
};

export const EPSDT_TYPE = {
  EMPTY: { label: '', data: { code: false, type: '' } },
  NO_REASON: { label: 'No Reason', data: { code: true, type: '' } },
  AV: { label: 'AV', data: { code: true, type: 'AV' } },
  S2: { label: 'S2', data: { code: true, type: 'S2' } },
  ST: { label: 'ST', data: { code: true, type: 'ST' } },
  NU: { label: 'NU', data: { code: true, type: 'NU' } },
};

export const EPSDT_ITEMS = [
  EPSDT_TYPE.NO_REASON,
  EPSDT_TYPE.AV,
  EPSDT_TYPE.S2,
  EPSDT_TYPE.ST,
  EPSDT_TYPE.NU,
];

export const ITEM_NONE = {
  label: 'None',
  data: { id: '' },
};

export const TAX_NONE = { label: 'None', data: { name: '', rate: 0 } };

export const ITEM_SELF = {
  label: 'Self',
  data: { id: '' },
};

export const BILL_TYPE = {
  SELF: 'selfPay',
  PACKAGE: 'carePackage',
  INSURANCE: 'insurance',
};

export const ITEM_SELF_PAY = {
  label: 'Self Pay',
  data: { id: BILL_TYPE.SELF },
};

export const ITEM_PACKAGE = {
  label: 'Care Package',
  data: { id: BILL_TYPE.PACKAGE },
};

export const ITEM_INSURANCE = {
  label: 'Insurance',
  data: { id: BILL_TYPE.INSURANCE },
};

export const BILL_TYPE_ITEMS = [ITEM_SELF_PAY, ITEM_PACKAGE, ITEM_INSURANCE];

export const DEFAULT_PATIENT_LEDGER_TOTALS = {
  activity: {
    encountersNotBalanced: 0,
    numberOfPurchases: 0,
  },
  payments: 0,
  charges: {
    open: 0,
    responsible: 0,
  },
  balance: {
    patientOwed: 0,
    patientPaid: 0,
    payerOwed: 0,
    payerPaid: 0,
  },
};

export const DEFAULT_LEDGER_TOTALS = {
  activity: {
    encountersNotBalanced: 0,
    numberOfPurchases: 0,
  },
  payments: { unallocated: 0, warning: 0 },
  charges: {
    open: 0,
    responsible: 0,
  },
  balance: {
    patientOwed: 0,
    patientPaid: 0,
    payerOwed: 0,
    payerPaid: 0,
  },
};

export function lineItemHasPackageCreditsOrAdjustments(lineItem) {
  return (
    lineItem.lineItemDebits.some(({ debit }) =>
      debit.allocations.some(({ credit }) => credit.patientPackageId),
    ) ||
    lineItem.adjustments.some(({ codeId }) => {
      if (codeId.data) {
        return codeId.data.code === CODE_WRITE_OFFS.PACKAGE.code;
      }

      return codeId === CODE_WRITE_OFFS.PACKAGE.id;
    })
  );
}

export function selectFormatters(items, empty) {
  return {
    format: v => items.find(item => item.data.id === v) || empty,
    unformat: v => v.data.id,
  };
}

export function calculateSubtotal(charges) {
  return charges.reduce(
    (subtotal, c) => subtotal + c.units * currencyToCents(c.unitCharge),
    0,
  );
}

export function calculateAdjustment(charges) {
  return charges.reduce(
    (adj, c) => adj + currencyToCentsWithNegative(c.adjustmentAmount),
    0,
  );
}

export function calculateTaxes(charges, taxRates) {
  let taxes = charges.reduce((taxes, charge) => {
    if (!charge.taxId.data.id) {
      return taxes;
    }

    const tax = taxRates.find(t => t.data.id === charge.taxId.data.id);
    const billedAmount = charge.units * currencyToCents(charge.unitCharge);

    taxes[charge.taxId.data.id] = {
      name: tax.data.name,
      rate: tax.data.rate,
      value:
        (taxes[charge.taxId.data.id] ? taxes[charge.taxId.data.id].value : 0) +
        billedAmount,
      taxedValue: Math.round(
        (taxes[charge.taxId.data.id]
          ? taxes[charge.taxId.data.id].taxedValue
          : 0) +
          (tax.data.rate / 100) * billedAmount,
      ),
    };

    return taxes;
  }, {});

  taxes = Object.values(taxes).sort((a, b) =>
    a.name.toUpperCase().localeCompare(b.name.toUpperCase()),
  );

  const total = taxes.reduce((result, t) => result + t.taxedValue, 0);
  return {
    total,
    taxes: taxes.filter(t => t.value && t.rate),
  };
}

export function formatDate(date = '') {
  return moment(parseDate(date)).format('L');
}

export function findDateOfService(items) {
  if (items.length) {
    const dates = items.map(item => parseDate(item.dateOfService));
    const minDate = moment.min(dates).format('L');
    const maxDate = moment.max(dates).format('L');

    return minDate === maxDate ? minDate : `${minDate} - ${maxDate}`;
  }

  return '';
}

export function calculateSummary({ items, claims }) {
  const invoice = items[0] ? items[0].invoiceNumber : 0;
  const datesOfService = findDateOfService(items);
  const claimStatus = claims.length ? claims[0].claimStatuses[0].status : '';

  const totals = items.reduce(
    (totals, lineItem) => {
      totals.totalCharges += lineItem.billedAmount + lineItem.taxAmount;
      totals.totalAdjustments += lineItem.adjustment;

      totals.totalPayments +=
        lineItem.primaryPaid + lineItem.secondaryPaid + lineItem.patientPaid;

      totals.totalPatientBalance += lineItem.patientOwed - lineItem.patientPaid;

      totals.totalPayerBalance +=
        lineItem.primaryOwed -
        lineItem.primaryPaid +
        lineItem.secondaryOwed -
        lineItem.secondaryPaid;

      return totals;
    },
    {
      totalCharges: 0,
      totalAdjustments: 0,
      totalPayments: 0,
      totalPatientBalance: 0,
      totalPayerBalance: 0,
    },
  );

  return {
    datesOfService,
    invoice,
    claimStatus,
    ...totals,
  };
}

export async function fetchPatientFeeSchedules(patientId) {
  if (!patientId) return [];

  const tenantId = await getTenantId();

  const res = await patientFeeScheduleApiClient.fetchMany(patientId, {
    cacheKey: `${tenantId}-${patientId}`,
  });

  return res;
}

export async function fetchFeeSchedules(
  optOutLoadingIndicator = false,
  version = 1,
) {
  let feeSchedules = [];

  switch (version) {
    case 1: {
      feeSchedules = await feeScheduleApi.fetchMany(optOutLoadingIndicator);
      break;
    }

    case 4: {
      feeSchedules = await feeScheduleApi.fetchManyV4(optOutLoadingIndicator);
      break;
    }

    default: {
      feeSchedules = await feeScheduleApi.fetchMany(optOutLoadingIndicator);
      break;
    }
  }

  return feeSchedules.map(data => ({
    label: data.name,
    data,
  }));
}

export async function fetchPayerItems(payerId, payerPlans) {
  const primaryPayer = payerId ? await getPayerPlan(payerId) : null;

  if (!payerPlans?.length) {
    const res = await getPayerPlans({ hideInactive: false }, undefined, true);
    payerPlans = res.payerPlan;
  }

  const filteredPayers = payerPlans.filter(p => p.id !== payerId);

  const payers = primaryPayer
    ? [primaryPayer, ...filteredPayers]
    : filteredPayers;

  return payers.map(data => ({
    label: `(${data.alias}) ${data.payerName}`,
    data,
  }));
}

export async function fetchCaseItems({
  patientId,
  caseId,
  filterActive = true,
  optOutLoadingIndicator = true,
}) {
  const fmtFn = date => (date ? parseDate(date).format('L') : 'Gradual');

  const res = await fetchMany(patientId, optOutLoadingIndicator);

  const cases = filterActive
    ? res.filter(c => c.id === caseId || c.active)
    : res;

  return cases.map(data => ({
    label: `${data.name} - ${fmtFn(data.onsetSymptomsDate)}`,
    data,
  }));
}

export async function fetchPackageItems(
  patientId,
  options = undefined,
  optOutLoadingIndicator,
) {
  const res = await getPatientPackages(
    patientId,
    options,
    optOutLoadingIndicator,
  );

  return res.map(data => ({
    label: data.name,
    data,
  }));
}

export async function fetchInsuranceItems(patientId) {
  const res = await getPatientInsurances(patientId, {}, 1, true);

  return res.map(data => ({
    label: data.planName,
    data: mapToPatientInsuranceModel(data),
  }));
}

export async function fetchGuarantorItems(
  patientId,
  guarantorId,
  formatName = getPatientDisplayName,
) {
  const res = await getPatientGuarantors(patientId, undefined, true);

  return res
    .filter(g => g.id === guarantorId || g.active)
    .map(data => ({
      label: data.person
        ? `${formatName(data.person)}`
        : data.organization.name,
      data,
    }));
}

export async function fetchPracticeUsers() {
  const res = await getPracticeUsers();

  return res.map(data => ({
    label: objToName(data.name, { reverse: true }),
    data: { ...data, taxonomy: data.taxonomy || [] },
  }));
}

export async function fetchPaymentTypeItems() {
  const res = await fetch(TYPE.PAYMENT, true);

  return res.map(data => ({
    label: data.code,
    data,
  }));
}

export async function fetchDiscountTypeItems() {
  const res = await fetch(TYPE.DISCOUNT, true);

  return res.map(data => ({
    label: `${data.code} (Discount)`,
    data,
  }));
}

export async function fetchAdjustments(optOutLoadingIndicator = false) {
  const res = await getBillingCodesWriteOffs({}, optOutLoadingIndicator);
  return res.map(data => ({
    label: `${data.codeGroup} ${data.code}`,
    data,
  }));
}

export async function fetchAdjustmentsWithDescription(
  optOutLoadingIndicator = false,
) {
  const res = await getBillingCodesWriteOffs({}, optOutLoadingIndicator);
  return res.map(data => ({
    label: `${data.codeGroup} ${data.code} - ${data.description}`,
    data,
  }));
}

export async function fetchPaymentCodesWithDescription(
  optOutLoadingIndicator = false,
) {
  const res = await getBillingCodesPayments({}, optOutLoadingIndicator);
  return res.map(data => ({
    label: `${data.code} - ${data.description}`,
    data,
  }));
}

export function formatTaxRate(data) {
  return {
    label: data.name && data.rate ? `${data.name} - ${data.rate}%` : 'None',
    data,
  };
}

export async function fetchTaxRateItems(optOutLoadingIndicator = false) {
  const res = await getTaxRates(optOutLoadingIndicator);
  const taxRates = res.filter(t => t.active).map(formatTaxRate);

  return [TAX_NONE, ...taxRates];
}

export async function fetchInvoiceItems(
  patientId,
  invoiceId,
  optOutLoadingIndicator = false,
) {
  const res = invoiceId
    ? [await getInvoice(patientId, invoiceId, optOutLoadingIndicator)]
    : await getInvoices(patientId, optOutLoadingIndicator);

  return res.map(data => ({
    label: `${data.invoiceNumber} - ${centsToCurrency(data.total)}`,
    data,
  }));
}

export async function fetchAdjustmentItems(optOutLoadingIndicator = false) {
  const res = await getAdjustments(optOutLoadingIndicator);

  const adjustments = res.map(data => ({
    data,
    label: data.code,
  }));

  return [ITEM_NONE, ...adjustments];
}

export async function fetchAttributeToItems() {
  const res = await getPracticeUsers();

  const formattedUsers = res
    .reduce(
      (users, user) =>
        user.status === 'Active'
          ? [
              ...users,
              {
                data: user,
                label: objToName(user.name, { reverse: true }),
              },
            ]
          : users,
      [],
    )
    .sort((a, b) => a.label.localeCompare(b.label));

  return [ITEM_NONE, ...formattedUsers];
}

export function getItem(keyPath, state) {
  if (!Array.isArray(state) && keyPath[0] !== 'items') {
    return state;
  }

  const rootCount = keyPath[0] === 'items' ? 2 : 1;
  const itemPath = keyPath.slice(0, rootCount);

  return getValueByPath(state, itemPath);
}

export function calculateGrandTotal(item) {
  const responsibilityAmountTotal = item.lineItemDebits.reduce(
    (acc, curr) => acc + curr.debit.amount,
    0,
  );
  const adjustmentAmountTotal = item.adjustments.reduce(
    (acc, curr) => acc + curr.amount,
    0,
  );
  return responsibilityAmountTotal + adjustmentAmountTotal;
}

export function owedGrandTotalValidator(error = 'Required') {
  return {
    error,
    validate: (value, keyPath, state) => {
      const item = getItem(keyPath, state);

      const owedGrandTotal = calculateGrandTotal(item);

      return owedGrandTotal === item.billedAmount + item.taxAmount;
    },
  };
}

export function validatePatientOwedTypes(error) {
  return {
    error,
    validate: (value, keyPath, state) => {
      const item = getItem(keyPath, state);

      return item.lineItemDebits.every(lineItem => {
        let valid = true;

        if (lineItem.debit.payerId === null) {
          const invalidCode =
            lineItem.codePaymentId === '' || lineItem.codePaymentId === null;

          const noType = invalidCode && lineItem.debit.amount > 0;
          valid = !noType;
        }
        return valid;
      });
    },
  };
}

export function validateAdjustmentCode(
  codeWriteOffs,
  codeId,
  billType,
  isCarePackageWithInsurance,
) {
  switch (billType) {
    case BILL_TYPE.INSURANCE:
      return (
        codeId !== '' &&
        codeWriteOffs.some(
          ({ id, active, forInsurance }) =>
            id === codeId &&
            (active && (forInsurance || isCarePackageWithInsurance)),
        )
      );

    case BILL_TYPE.SELF:
    case BILL_TYPE.PACKAGE:
      return (
        codeId !== '' &&
        codeWriteOffs.some(
          ({ id, active, forPatient }) => id === codeId && active && forPatient,
        )
      );

    default:
      return true;
  }
}

export function validateAdjustmentCodes(
  error,
  codeWriteOffs,
  billType,
  isCarePackageWithInsurance,
) {
  return {
    error,
    validate: (value, keyPath, state) => {
      const item = getItem(keyPath, state);

      return item.adjustments.every(adjustment =>
        validateAdjustmentCode(
          codeWriteOffs,
          adjustment.codeId,
          billType,
          isCarePackageWithInsurance,
        ),
      );
    },
  };
}

export function validateAllowedAgainstBilled(error) {
  return {
    error,
    validate: (value, keyPath, state) => {
      const item = getItem(keyPath, state);

      const adjustments = item.adjustments.reduce(
        (accum, adj) => accum + adj.amount,
        0,
      );
      return value + adjustments === item.billedAmount + item.taxAmount;
    },
  };
}

export function validateAllowedAgainstTotalOwed(error, checkEquals = false) {
  return {
    error,
    validate: (_, keyPath, state) => {
      const item = getItem(keyPath, state);

      const totalOwedAmount = item.lineItemDebits.reduce(
        (accum, curr) => accum + curr.debit.amount,
        0,
      );

      const { allowedAmount } = item;

      return !checkEquals
        ? totalOwedAmount <= allowedAmount
        : totalOwedAmount === allowedAmount;
    },
  };
}

export function validateOwedAmounts(itemPath, state, service) {
  const lineItemDebitsPath = [...itemPath, 'lineItemDebits'];
  const lineItemDebits = getValueByPath(state, lineItemDebitsPath);

  for (let i = 0; i < lineItemDebits.length; i++) {
    const path = [...lineItemDebitsPath, i, 'debit', 'amount'];

    service.unsetPristine(path);
    service.validateKey(path, true);
  }

  const adjustmentsPath = [...itemPath, 'adjustments'];
  const adjustments = getValueByPath(state, adjustmentsPath);

  for (let i = 0; i < adjustments.length; i++) {
    const path = [...adjustmentsPath, i, 'amount'];

    service.unsetPristine(path);
    service.validateKey(path, true);
  }

  const allowedAmountPath = [...itemPath, 'allowedAmount'];
  service.unsetPristine(allowedAmountPath);
  service.validateKey(allowedAmountPath, true);
}

export function validateAllocatedAgainstOwed({ value, keyPath, state }) {
  const amountPath = [...keyPath.slice(0, keyPath.length - 1), 'amount'];
  const owedAmount = getValueByPath(state, amountPath);

  return value <= owedAmount;
}

export function validateAllocatedAgainstAvailable(
  value,
  keyPath,
  state,
  payment,
) {
  const lineItems = state.items || state;

  const { totalAllocated, totalPaidAmount } = lineItems.reduce(
    (accLi, { lineItemDebits }) => {
      const lid = lineItemDebits.reduce(
        (accLid, { debit: { allocated, allocations } }) => {
          const paidAmount = allocations
            ? allocations.reduce(
                (sum, a) =>
                  sum +
                  (payment.id === (a.credit && a.credit.paymentId)
                    ? a.amount
                    : 0),
                0,
              )
            : 0;
          return {
            totalAllocated: accLid.totalAllocated + allocated,
            totalPaidAmount: accLid.totalPaidAmount + paidAmount,
          };
        },
        { totalAllocated: 0, totalPaidAmount: 0 },
      );

      return {
        totalAllocated: accLi.totalAllocated + lid.totalAllocated,
        totalPaidAmount: accLi.totalPaidAmount + lid.totalPaidAmount,
      };
    },
    { totalAllocated: 0, totalPaidAmount: 0 },
  );

  const allocationsPath = [
    ...keyPath.slice(0, keyPath.length - 1),
    'allocations',
  ];

  const allocations = getValueByPath(state, allocationsPath);
  const paidAmount = allocations.reduce(
    (sum, a) =>
      sum +
      (payment && payment.id === (a.credit && a.credit.paymentId)
        ? a.amount
        : 0),
    0,
  );

  return (
    value - paidAmount <= payment.available &&
    totalAllocated - totalPaidAmount <= payment.available
  );
}

export const LINE_ITEM_DETAIL = {
  EPSDTCode: false,
  EPSDTType: '',
  adjustments: [
    {
      amount: 0,
      codeId: { data: { id: '' }, label: '' },
      id: '',
    },
  ],
  allowedAmount: 0,
  billType: BILL_TYPE.SELF,
  billedAmount: 0,
  billedPatient: false,
  billedPrimary: false,
  billedSecondary: false,
  chargeNumber: '',
  code: '',
  codeType: 'charge',
  dateOfService: '1337-03-14',
  description: '',
  encounterCharge: null,
  feeScheduleCharge: 0,
  feeScheduleId: '',
  guarantorId: '',
  holdClaim: false,
  holdStatement: false,
  holdSuperBill: false,
  invoiceId: '',
  lineItemDebits: [
    {
      debit: {
        allocations: [],
        amount: 0,
        id: '',
        payerId: '',
        allocated: 0,
      },
      id: '',
      patientInsuranceId: '',
      codePaymentId: '',
    },
  ],
  modifier_1: '',
  modifier_2: '',
  modifier_3: '',
  modifier_4: '',
  modifiers: ['', '', '', ''],
  ndc: false,
  patientId: '',
  patient: {
    id: '',
    medicalRecordNumber: '',
    firstName: '',
    lastName: '',
  },
  patientCaseId: '',
  patientPackageDeduct: 0,
  patientPackageId: '',
  pos: 11,
  postedById: '',
  primaryInsuranceId: '',
  primaryPayer: '',
  primaryPayerId: '',
  provider: null,
  purchase: {
    attributeToUserId: '',
    chargeId: '',
  },
  secondaryInsuranceId: '',
  shaded24: '',
  taxAmount: 0,
  taxName: '',
  taxRate: '',
  tertiaryInsuranceId: '',
  type: 'purchase',
  unitCharge: 0,
  units: 1,
  updatedAt: '2020-09-19T20:25:19.000Z',
  voidedAt: '',
  voidedById: '',
  nationalDrugCodeQualifier: null,
  nationalDrugCode: null,
  nationalDrugCodeDosage: null,
  nationalDrugCodeUnitOfMeasurement: null,
  nationalDrugCodeNumberCategory: null,
  nationalDrugCodeSequenceOrPrescription: null,
  supplementalInformation: false,
  reportTypeCode: '',
  reportTransmissionCode: '',
  codeQualifier: '',
  identificationNumber: '',
  orderingProvider: false,
  orderingProviderCredentials: null,
  orderingProviderFirstName: null,
  orderingProviderLastName: null,
  orderingProviderMiddleName: null,
  orderingProviderNPI: null,
  orderingProviderOtherId: null,
  orderingProviderQualifier: null,
};

export function setHoverBarYOffset({
  context,
  top,
  chargesPageId,
  balancePageId,
  openChargesId,
  closedChargesId,
  outstandingChargesId,
}) {
  const chargesPage = context.shadowRoot.getElementById(chargesPageId);

  if (chargesPage) {
    const openCharges = chargesPage.shadowRoot.getElementById(openChargesId);

    const closedCharges = chargesPage.shadowRoot.getElementById(
      closedChargesId,
    );

    if (openCharges) {
      openCharges.setYOffset(top);
    }

    if (closedCharges) {
      closedCharges.setYOffset(top);
    }
  }

  const balancePage = context.shadowRoot.getElementById(balancePageId);

  if (balancePage) {
    const outstandingCharges = balancePage.shadowRoot.getElementById(
      outstandingChargesId,
    );

    if (outstandingCharges) outstandingCharges.setYOffset(top);
  }
}

export function invoiceSectionHandlers() {
  return {
    changeRadio: value => {
      this.formService.apply('guarantor', this.model.guarantor);
      this.formService.apply('invoice', ITEM_EMPTY);
      this.formService.apply('radio', value);
    },
    changeGuarantor: value => {
      this.formService.apply('guarantor', value);
    },
    changeInvoice: value => {
      if (value && value !== NO_INVOICES_TEXT) {
        this.formService.apply('invoice', value);

        if (value.data.guarantorId) {
          this.formService.apply(
            'guarantor',
            this.__menuItemsMap.guarantors.find(
              g => g.data.id === value.data.guarantorId,
            ),
          );
        } else {
          this.formService.apply('guarantor', ITEM_SELF);
        }
      }
    },
  };
}

export const mapCaseAuthorizations = cases =>
  cases
    .flatMap(({ data: { patientAuthorizations } }) => patientAuthorizations)
    .filter(a => a)
    .map(pa => ({
      label: formatAuthorizationLabel(pa),
      data: { ...pa, label: formatAuthorizationLabel(pa) },
    }));

export const mapFilteredCaseAuthorizations = cases =>
  filterAuthorizationsFromCases(cases.map(({ data }) => data)).map(auth => ({
    label: auth.label,
    data: auth,
  }));

export const filterValidAuthorizations = authorizations =>
  authorizations
    .filter(pa => isAuthorizationValidForLinking(pa.status))
    .sort((a, b) => a.label.localeCompare(b.label));

export const mapCasesWithAuthorizationReference = cases =>
  cases.map(c => {
    const auth = c.data.patientAuthorizations?.length
      ? c.data.patientAuthorizations[0]
      : c.data.patientAuthorization;
    return {
      ...c,
      data: {
        ...c.data,
        patientAuthorization: auth
          ? {
              id: auth.id,
              label: formatAuthorizationLabel(auth),
            }
          : null,
      },
    };
  });

export const mapInsurancePlansToEditPopupDropdown = plans =>
  plans.map(plan => ({
    id: plan.id,
    label: `${plan.label} (${plan.payer.alias})`,
  }));

export function flattenErrors(errors) {
  const set = new Set();

  traverse(errors, (keyPath, value) => {
    if (typeof value !== 'object' && value !== '') {
      set.add(value);
    }
  });

  return Array.from(set);
}

export function selectedChargesHasErrors(errors, selectionFlags) {
  const selectedErrors = errors.filter((_, idx) => selectionFlags[idx]);

  return hasErrors(selectedErrors);
}

export function checkForWarnings(errors) {
  const flattenedErrors = flattenErrors(errors);
  const onlyWarnings =
    flattenedErrors.length &&
    flattenedErrors.every(error => error.includes('(Warning)'));

  if (onlyWarnings) {
    return openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Warning',
      message: html`
        <p>
          The total Owed amounts should equal the Allowed amount for one or more
          charges.
        </p>

        <p>Are you sure that you want to proceed?</p>
      `,
      confirmText: 'YES',
      cancelText: 'NO',
    });
  }

  return false;
}

const formatPlanName = ({ planName }) =>
  planName > 50 ? `${planName.slice(0, 50)}...` : planName;

const coverageDatesInvalid = (startDate, endDate, dos) =>
  startDate.startOf('day').isAfter(parseDate(moment(dos))) ||
  endDate.endOf('day').isBefore(parseDate(moment(dos)));

const coverageDatesExist = insurance =>
  insurance && insurance.coverageDates && insurance.coverageDates !== ' - ';

export function getOutOfCoverageDatePlans(
  datesOfServiceStringArray,
  primaryInsurance,
  secondaryInsurance,
) {
  return [...new Set(datesOfServiceStringArray)].reduce((memo, dos) => {
    if (coverageDatesExist(primaryInsurance)) {
      const primaryCoverageStart = parseDate(
        moment(primaryInsurance.coverageDates.split(' - ')[0], 'MM/DD/YYYY'),
      );

      const primaryCoverageEnd = parseDate(
        moment(primaryInsurance.coverageDates.split(' - ')[1], 'MM/DD/YYYY'),
      );

      if (coverageDatesInvalid(primaryCoverageStart, primaryCoverageEnd, dos)) {
        memo.push({ label: formatPlanName(primaryInsurance.planInfo) });
      }
    }

    if (coverageDatesExist(secondaryInsurance)) {
      const secondaryCoverageStart = parseDate(
        moment(secondaryInsurance.coverageDates.split(' - ')[0], 'MM/DD/YYYY'),
      );
      const secondaryCoverageEnd = parseDate(
        moment(secondaryInsurance.coverageDates.split(' - ')[1], 'MM/DD/YYYY'),
      );

      if (
        coverageDatesInvalid(secondaryCoverageStart, secondaryCoverageEnd, dos)
      ) {
        memo.push({ label: formatPlanName(secondaryInsurance.planInfo) });
      }
    }

    return memo;
  }, []);
}

export function getSelectedBalanceDue(model) {
  const value = model.reduce((accum, curr) => {
    const { billedAmount, taxAmount, adjustments, lineItemDebits } = curr;

    const adjustmentsSum = adjustments.reduce(
      (sum, a) => sum + currencyToCents(a.amount),
      0,
    );

    const allocationsSum = lineItemDebits.reduce(
      (lidSum, lid) =>
        lidSum + lid.debit.allocations.reduce((aSum, a) => aSum + a.amount, 0),
      0,
    );

    return accum + billedAmount + taxAmount - adjustmentsSum - allocationsSum;
  }, 0);

  return centsToCurrency(value);
}

export function formatAndFilterFeeSchedules(feeSchedules) {
  return feeSchedules
    .filter(({ active }) => active)
    .map(data => ({
      label: data.name,
      data,
    }));
}

export function isActivePatientPaymentCode(item) {
  return (
    item.data.active &&
    toFlags(item.data.source).forPatient &&
    !PACKAGE_CODES.includes(item.data.code)
  );
}

export const formatPaymentNumber = ({ paymentNumber, codeDiscountId }) =>
  codeDiscountId ? `D-${paymentNumber}` : paymentNumber;

function changeFeeScheduleToNone(lineItem) {
  const { units, taxRate, unitCharge } = lineItem;

  const feeScheduleChargeAmount = unitCharge;
  const billedAmount = unitCharge * units;
  const taxAmount = Math.round(billedAmount * (taxRate / 100));
  const allowedAmount = billedAmount;
  const patientOwedAmount = billedAmount;

  return {
    feeScheduleChargeAmount,
    billedAmount,
    taxAmount,
    allowedAmount,
    patientOwed: [
      {
        codeId: CODE_PAYMENTS.GENERAL_PAYMENT.id,
        amount: patientOwedAmount,
      },
    ],
    adjustments: [],
  };
}

export function changeFeeScheduleCalculations({ lineItem, feeSchedule }) {
  if (!feeSchedule.id) {
    return changeFeeScheduleToNone(lineItem);
  }

  const { units, taxRate, chargeId } = lineItem;

  const charge = feeSchedule.charges.find(charge => charge.id === chargeId);

  const feeScheduleChargeAmount = charge.amount;
  const billedAmount = charge.amount * units;
  const taxAmount = Math.round(billedAmount * (taxRate / 100));
  const allowedAmount = charge.allowedAmount * units;
  const patientOwedAmount = charge.allowedAmount * units;
  const patientAdjustmentAmount = charge.adjustmentAmount * units;

  const { patientAdjustmentCodeId } = feeSchedule;

  return {
    feeScheduleChargeAmount,
    billedAmount,
    taxAmount,
    allowedAmount,
    patientOwed: [
      {
        codeId: CODE_PAYMENTS.FEE_FOR_SERVICE.id,
        amount: patientOwedAmount,
      },
    ],
    adjustments: [
      {
        codeId: patientAdjustmentCodeId,
        amount: patientAdjustmentAmount,
      },
    ],
  };
}

export function NDCPopupStateValidator(error) {
  return {
    error,
    validate: (value, _, state) => {
      if (!value) {
        return true;
      }

      return (
        !!state.nationalDrugCode &&
        !!state.nationalDrugCodeDosage &&
        !!state.nationalDrugCodeQualifier &&
        !!state.nationalDrugCodeUnitOfMeasurement
      );
    },
  };
}

export function supplementalInfoPopupStateValidator(error) {
  return {
    error,
    validate: (value, _, state) => {
      if (!value) {
        return true;
      }

      return !!state.reportTypeCode && !!state.reportTransmissionCode;
    },
  };
}

export function orderingProviderInfoPopupStateValidator(error) {
  return {
    error,
    validate: (value, _, state) => {
      if (!value) {
        return true;
      }

      return (
        !!state.orderingProviderFirstName && !!state.orderingProviderLastName
      );
    },
  };
}

function formatDetailItems(detailItems) {
  return detailItems.map(li => ({
    id: li.id,
    debits: li.lineItemDebits
      .map(lid => ({
        id: lid.debit.id,
      }))
      .filter(debit => debit.id),
  }));
}

export function removeAllAllocations(allocations, lineItems) {
  const paymentIds = [
    ...new Set(allocations.map(a => a.credit.paymentId).filter(Boolean)),
  ];

  return Promise.all(
    paymentIds.map(paymentId => {
      const detailItems = lineItems.filter(item =>
        item.lineItemDebits.find(
          lid =>
            lid.debit &&
            lid.debit.allocations &&
            lid.debit.allocations.find(
              a => a.credit.paymentId && a.credit.paymentId === paymentId,
            ),
        ),
      );

      return deleteAllocation(paymentId, formatDetailItems(detailItems));
    }),
  );
}

export function filterOpenCharges(lineItems, isPayerPayment) {
  const filtered = lineItems.filter(li => {
    const patientBalance = li.lineItemDebits
      .filter(lid => (isPayerPayment ? lid.debit.payerId : !lid.debit.payerId))
      .reduce((sum, lid) => {
        const allocated =
          lid.debit.allocations.reduce((aSum, a) => aSum + a.amount, 0) +
          (lid.debit.allocated || 0);

        return sum + lid.debit.amount - allocated;
      }, 0);

    return !!patientBalance;
  });

  return filtered;
}

export const getDebitPaymentIds = (allocations = []) =>
  allocations
    .filter(a => a.credit && !!a.credit.payment)
    .map(({ credit: { payment } }) => ({
      id: payment.id,
      paymentNumber: formatPaymentNumber(payment),
      icon: payment.paymentMethod === 'ERA' ? 'neb:receipt' : '',
    }));

export const getLidPaymentIds = lids =>
  lids.reduce(
    (lidMemo, { debit: { allocations } } = { debit: { allocations: [] } }) =>
      allocations
        .filter(({ credit }) => credit && credit.payment)
        .reduce((allocMemo, { credit: { payment } }) => {
          allocMemo.set(payment.id, {
            ...payment,
            displayCode: formatPaymentNumber(payment),
          });

          return allocMemo;
        }, lidMemo),
    new Map(),
  );

export const openLedgerLineItemOverlay = ({ patientId, id }) =>
  openOverlay(OVERLAY_KEYS.LEDGER_LINE_ITEM, {
    patientId,
    id,
  });

const handleDirtyDialogLogic = async ({
  params = {},
  isDirty = false,
  formIsValid = true,
  onLoad = () => {},
  onSave = () => {},
  onOpenOverlay = () => {},
}) => {
  const option = isDirty && (await openDirtyDialog({ formIsValid }));

  if (option === STAY_BUTTON.name) return;

  if (option === SAVE_BUTTON.name) {
    await onSave(false);
  }

  await onOpenOverlay(params);
  await onLoad();
};

export const viewERAsEOBs = ({
  associatedERAsAndEOBs,
  lineItemId,
  refetchData,
  isDirty = false,
  formIsValid = true,
  onLoad = () => {},
  onSave = () => {},
  onOpenOverlay = () => {},
}) =>
  handleDirtyDialogLogic({
    params: { associatedERAsAndEOBs, lineItemId, refetchData },
    isDirty,
    formIsValid,
    onLoad,
    onSave,
    onOpenOverlay,
  });

export const viewEncounterSummary = ({
  encounterId,
  appointmentTypeId,
  patientId,
  isDirty = false,
  formIsValid = true,
  onLoad = () => {},
  onSave = () => {},
}) => {
  const params = {
    patient: { id: patientId },
    appointmentTypeId,
    encounterId,
  };

  return handleDirtyDialogLogic({
    params,
    isDirty,
    formIsValid,
    onLoad,
    onSave,
    onOpenOverlay: openEncounterSummary,
  });
};

export const viewPayment = ({
  payment: params,
  isDirty = false,
  formIsValid = true,
  onLoad = () => {},
  onSave = () => {},
}) =>
  handleDirtyDialogLogic({
    params,
    isDirty,
    formIsValid,
    onLoad,
    onSave,
    onOpenOverlay: openPaymentDetailOverlay,
  });

export const viewPayerPlan = ({
  payerPlan: params,
  isDirty = false,
  formIsValid = true,
  onLoad = () => {},
  onSave = () => {},
}) =>
  handleDirtyDialogLogic({
    params,
    isDirty,
    formIsValid,
    onLoad,
    onSave,
    onOpenOverlay: openPayerPlanOverlay,
  });

export const viewLedgerLineItem = ({
  patientId,
  id,
  isDirty = false,
  formIsValid = true,
  onLoad = () => {},
  onSave = () => {},
}) => {
  const params = {
    patientId,
    id,
  };

  return handleDirtyDialogLogic({
    params,
    isDirty,
    formIsValid,
    onLoad,
    onSave,
    onOpenOverlay: openLedgerLineItemOverlay,
  });
};

export const calculateTaxAmount = ({
  taxRate,
  billedAmount,
  lineItemDebits,
}) => {
  const discountAmount = lineItemDebits
    .flatMap(({ debit }) => debit.allocations)
    .filter(({ credit }) => credit.payment?.codeDiscountId)
    .reduce((sum, { amount }) => sum + amount, 0);

  return Math.round((billedAmount - discountAmount) * (taxRate / 100));
};
