import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';

class NebRipple extends LitElement {
  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          top: 50%;
          left: 50%;
          position: absolute;
          display: inline;
        }

        :host([active]) .ripple::after {
          background-image: radial-gradient(
            circle,
            ${CSS_COLOR_GREY_1} 100%,
            transparent 5%
          );
          opacity: 0.2;
          border-radius: 50%;
        }

        .container {
          position: relative;
          width: 0;
          height: 0;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
        }

        .ripple {
          position: absolute;
          overflow: hidden;
          color: ${CSS_COLOR_GREY_1};
          border-radius: 50%;
          padding: ${CSS_SPACING};
        }

        .ripple::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          pointer-events: none;
          background-image: radial-gradient(
            circle,
            ${CSS_COLOR_GREY_1} 100%,
            transparent 5%
          );
          background-repeat: no-repeat;
          background-position: 50%;
          opacity: 0;
          transition: transform 100ms, opacity 1s;
          border-radius: 50%;
        }

        .ripple:active::after {
          transform: scale(0, 0);
          opacity: 0.2;
        }

        .ripple:active {
          background-size: 100%;
        }
      `,
    ];
  }

  render() {
    return html`
      <span class="container"> <div class="ripple"></div> </span>
    `;
  }
}

window.customElements.define('neb-ripple', NebRipple);
