import '../../../../packages/neb-lit-components/src/components/neb-toggle-button';
import '../../../../packages/neb-lit-components/src/components/neb-text';

import { LitElement, html, css } from 'lit';

import { CSS_COLOR_ERROR } from '../../../styles';

export const ELEMENTS = {
  items: {
    selector: 'neb-toggle-button',
  },
  text: {
    selector: 'neb-text',
  },
};

class NebSelfCheckInQuestionnaireNavBar extends LitElement {
  static get properties() {
    return {
      items: Array,
      selectedIndex: Number,
      highlightRequiredFields: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
        }

        neb-text {
          display: flex;
          align-items: center;
          min-height: 30px;
        }
        :host([highlightRequiredFields]) neb-text {
          color: ${CSS_COLOR_ERROR};
        }

        neb-toggle-button {
          width: initial;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.items = [];
    this.selectedIndex = 0;
    this.highlightRequiredFields = false;

    this.onSelect = () => {};
  }

  __initHandlers() {
    this.handlers = {
      select: (_, index) => {
        this.onSelect(index);
      },
    };
  }

  render() {
    return html`
      ${
        this.items.map((item, index) =>
          item
            ? html`
                <neb-toggle-button
                  .buttonText="${item}"
                  .index="${index}"
                  .hideIcon="${true}"
                  .canToggle="${index !== this.selectedIndex}"
                  .isToggled="${index === this.selectedIndex}"
                  .onClick="${this.handlers.select}"
                ></neb-toggle-button>
              `
            : html`
                <neb-text bold>Select a body area</neb-text>
              `,
        )
      }
    `;
  }
}

customElements.define(
  'neb-self-check-in-questionnaire-nav-bar',
  NebSelfCheckInQuestionnaireNavBar,
);
