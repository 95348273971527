import '../../neb-lit-components/src/components/neb-radio-button';

import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import NebPopupClaim from './neb-popup-claim';

export const ELEMENTS = {
  radioButtons: { selector: 'neb-radio-button' },
};

class NebPopupClaimSingleSelect extends NebPopupClaim {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          margin-top: 10px;
        }

        .container-buttons {
          display: flex;
          flex-direction: column;
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.model = {
      title: '',
      tooltipText: '',
      items: [],
      options: [],
    };
  }

  renderContent() {
    return html`
      <div class="container-buttons">
        ${
          this.model.options.map(
            ({ label, name, value }) =>
              html`
                <neb-radio-button
                  label="${label}"
                  .name="${name}"
                  .value="${value}"
                  .checked="${equal(this.__state[name], value)}"
                  .onChange="${this.handlers.change}"
                ></neb-radio-button>
              `,
          )
        }
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-claim-single-select',
  NebPopupClaimSingleSelect,
);
