import '../../../neb-table-readonly';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  table: {
    id: 'table',
  },
};
export const CONFIG = [
  {
    key: 'defaultLevel',
    label: 'Set as',
    flex: css`1.5`,
    truncate: true,
  },
  {
    key: 'payerPlan',
    label: 'Payer',
    flex: css`1.5`,
    truncate: true,
    formatter: payerPlan => payerPlan.payerName,
  },
  {
    key: 'coverageDates',
    label: 'Coverage Dates',
    flex: css`2`,
    truncate: true,
  },
  {
    key: 'policyHolderName',
    label: 'Policyholder',
    flex: css`2`,
    truncate: true,
  },
  {
    key: 'active',
    label: 'Status',
    flex: css`2`,
    truncate: true,
    formatter: active => (active ? 'Active' : 'Inactive'),
  },
];

class NebPatientInsuranceViewTable extends LitElement {
  static get properties() {
    return {
      patientInsurance: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.patientInsurance = null;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .table {
          border-radius: 5px;
          padding: ${CSS_SPACING} 0;
        }
      `,
    ];
  }

  __renderTable() {
    return html`
      <div class="table">
        <neb-table-readonly
          id="${ELEMENTS.table.id}"
          .config="${CONFIG}"
          .model="${[this.patientInsurance]}"
          .layout="${'large'}"
        >
        </neb-table-readonly>
      </div>
    `;
  }

  render() {
    return html`
      ${this.__renderTable()}
    `;
  }
}

customElements.define(
  'neb-patient-insurance-view-table',
  NebPatientInsuranceViewTable,
);
