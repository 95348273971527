import {
  getLocationValue,
  findLocation,
  LOCATION_KEYS,
} from '../../../../src/utils/locations/location-util';
import { objToName, centsToCurrency } from '../../../neb-utils/formatters';
import { buildPracticeInformation } from '../../../neb-utils/practice-information-util';
import * as patientApiClient from '../patient-api-client';

const formatAmount = amount =>
  Number.isInteger(amount) ? centsToCurrency(amount) : amount;

export function getPatientName(patient, includePreferred = false) {
  if (!patient) return '';

  const {
    firstName: first,
    lastName: last,
    middleName: middle,
    preferredName: preferred,
    suffix,
  } = patient;

  return objToName(
    { first, last, middle, preferred, suffix },
    { reverse: true, middleInitial: true, preferred: includePreferred },
  );
}

const getPatientIds = patientPayments => [
  ...patientPayments.reduce((acc, patientPayment) => {
    const id = patientPayment.patientId || patientPayment.credits[0].patientId;

    if (id) {
      acc.add(id);
    }
    return acc;
  }, new Set()),
];

const isObjectEmpty = obj => !obj || !Object.keys(obj).length;

const buildPaymentBodyPracticeInformation = ({
  billingInfo,
  practiceInformation,
}) => {
  const { name, practiceName, locations, email } = practiceInformation;

  let paymentBodyPracticeInformation = {
    ...practiceInformation,
    practiceName: practiceName || name,
    email: email || '',
  };

  if (!isObjectEmpty(billingInfo)) {
    const address = billingInfo.billingAddress;
    const { locationId } = address;

    const emailAddress = getLocationValue(
      locations,
      locationId || locations[0].id,
      LOCATION_KEYS.EMAIL_ADDRESS,
    );
    const phoneNumber = getLocationValue(
      locations,
      locationId || locations[0].id,
      LOCATION_KEYS.PHONE_NUMBER,
    );

    paymentBodyPracticeInformation = {
      ...practiceInformation,
      practiceName: practiceName || name,
      address,
      email: emailAddress,
      phoneNumber,
    };
  }

  return paymentBodyPracticeInformation;
};

export async function buildReceiptBody({
  patientPayments,
  practiceInformation,
  billingInfo = {},
}) {
  const patientIds = getPatientIds(patientPayments);
  const patients = await patientApiClient.fetchSome(patientIds, {}, true);
  const patientDict = patients.reduce((acc, pat) => {
    acc[pat.id] = pat;
    return acc;
  }, {});

  const payments = patientPayments.reduce((accum, payment) => {
    const patientId = payment.patientId || payment.credits[0].patientId;
    const patientName = getPatientName(patientDict[patientId]);
    const location = findLocation(
      payment.locationId,
      practiceInformation.locations,
    );

    const paymentBodyPracticeInformation = buildPaymentBodyPracticeInformation({
      billingInfo,
      practiceInformation,
    });

    const practiceInfo = buildPracticeInformation({
      practiceInformation: paymentBodyPracticeInformation,
      selectedLocation: location,
    });

    accum.push({
      payment: {
        ...payment,
        amount: formatAmount(payment.amount),
      },
      patientName,
      practiceInformation: practiceInfo,
    });

    return accum;
  }, []);
  return { patientPayments: payments };
}

export async function buildPaymentsBody({
  patientPayments,
  actionPayment,
  state: {
    session: {
      item: { tenantShortName },
    },
    practiceInformation: { item: practiceInformation },
  },
  billingInfo = {},
}) {
  const { locations } = practiceInformation;

  const paymentBodyPracticeInformation = buildPaymentBodyPracticeInformation({
    billingInfo,
    practiceInformation,
  });

  const patientIds = getPatientIds(patientPayments);
  const patients = await patientApiClient.fetchSome(patientIds, {}, true);
  const patientDict = patients.reduce((acc, pat) => {
    acc[pat.id] = pat;
    return acc;
  }, {});

  return {
    patientPayments: patientPayments.map(patientPayment => ({
      patientPayment: {
        ...patientPayment,
        amount: formatAmount(patientPayment.amount),
      },
      patientName: patientPayment.patientId
        ? getPatientName(patientDict[patientPayment.patientId])
        : getPatientName(patientDict[patientPayment.credits[0].patientId]),
    })),
    actionPayment,
    practiceInformation: {
      ...paymentBodyPracticeInformation,
    },
    locations: isObjectEmpty(billingInfo) ? locations : undefined,
    tenantShortName,
  };
}
