import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';

import { html, css } from 'lit';

import * as payerPlansApi from '../../../packages/neb-api-client/src/payer-plan-api-client';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { number } from '../../../packages/neb-utils/masks';
import * as selectors from '../../../packages/neb-utils/selectors';
import { FetchService } from '../../../packages/neb-utils/services/fetch';
import { NebCurrencyRange } from '../controls/field-groups/neb-range-currency';
import { NebDateRange } from '../controls/field-groups/neb-range-date';

import { NebFilters } from './neb-filters';
import { ITEM_PAYER_SELF } from './neb-filters-ledger-payments';

export const ELEMENTS = {
  payersSelect: { id: 'select-payers' },
  dosRange: { id: 'range-date-dos' },
  paymentAmountRange: { id: 'range-payment-amount' },
  paymentId: { id: 'payment-id' },
  authEFT: { id: 'authEFT' },
};

class NebFiltersEraEobAssociatePayment extends NebFilters {
  static get properties() {
    return {
      __payerPlansState: Object,
      __defaultPayerLoaded: Boolean,
      defaultPayer: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          grid-template-columns: repeat(3, 1fr);
        }
      `,
    ];
  }

  static createModel() {
    const today = parseDate().endOf('day');
    const thirtyDaysAgo = parseDate()
      .startOf('day')
      .subtract(1, 'month');

    const dateOfService = {
      ...NebDateRange.createModel(),
      from: new Date(thirtyDaysAgo),
      to: new Date(today),
    };

    return {
      payerIds: [],
      dateOfService,
      paymentAmount: NebCurrencyRange.createModel(),
      paymentId: '',
      authEFT: '',
    };
  }

  initState() {
    super.initState();

    this.defaultPayer = {};

    this.__defaultPayerLoaded = false;
    this.__payerPlansState = FetchService.createModel();

    this.__payerPlansService = new FetchService(
      {
        onChange: state => {
          state.pageItems = this.__completePayerFilterItems(state.pageItems);

          this.__payerPlansState = state;
          this.__setPayerFilterValue(state.pageItems);

          if (this.__defaultPayerLoaded) return;

          this.handlers.apply();
          this.__defaultPayerLoaded = true;
        },
        onMapItem: data => ({ label: data.alias, data }),
      },
      payerPlansApi.fetchMany,
    );
  }

  __hasDefaultPayer() {
    return this.defaultPayer && this.defaultPayer.id && this.defaultPayer.alias;
  }

  __completePayerFilterItems(payers) {
    const items = [ITEM_PAYER_SELF, ...payers];

    if (
      !this.__hasDefaultPayer() ||
      items.some(l => l.data.id === this.defaultPayer.id)
    ) {
      return items;
    }

    const defaultPayer = {
      label: this.defaultPayer.alias,
      data: this.defaultPayer,
    };

    return [...items, defaultPayer];
  }

  __setPayerFilterValue(payers) {
    if (!this.__hasDefaultPayer()) return;

    const selectedItems = this.__defaultPayerLoaded
      ? this.state.payerIds.map(payer => payer.data.id)
      : [this.defaultPayer.id];

    const value = payers.filter(payer => selectedItems.includes(payer.data.id));

    this.handlers.change({
      name: 'payerIds',
      value,
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      fetchMorePayers: () => this.__payerPlansService.fetchMore(),
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      apply: () => {
        const model = this.formService.build();

        const { from, to } = model.dateOfService;

        this.onApply({
          ...model,
          dateOfService: {
            from: from ? parseDate(from._i) : null,
            to: to ? parseDate(to._i) : null,
          },
        });
      },
    };
  }

  createSelectors() {
    return {
      children: {
        payerIds: selectors.multiSelect([]),
        dateOfService: NebDateRange.createSelectors(),
        paymentAmount: NebCurrencyRange.createSelectors(),
      },
    };
  }

  load() {
    this.__payerPlansService.fetch();
  }

  renderContent() {
    const dos = {
      from: this.state.dateOfService.from
        ? parseDate(this.state.dateOfService.from)
        : null,
      to: this.state.dateOfService.to
        ? parseDate(this.state.dateOfService.to)
        : null,
    };

    return html`
      <neb-select
        id="${ELEMENTS.payersSelect.id}"
        name="payerIds"
        label="Payer"
        helper=" "
        .value="${this.state.payerIds}"
        .items="${this.__payerPlansState.pageItems}"
        .onChange="${this.handlers.change}"
        .onRequest="${this.handlers.fetchMorePayers}"
        multiSelect
      ></neb-select>

      <neb-range-date
        id="${ELEMENTS.dosRange.id}"
        name="dateOfService"
        label="Date"
        .model="${dos}"
        .onChange="${this.handlers.changeDate}"
      ></neb-range-date>

      <neb-range-currency
        id="${ELEMENTS.paymentAmountRange.id}"
        name="paymentAmount"
        label="Payment Amount"
        .model="${this.state.paymentAmount}"
        .onChange="${this.handlers.change}"
      ></neb-range-currency>

      <neb-textfield
        id="${ELEMENTS.paymentId.id}"
        name="paymentId"
        label="Payment ID"
        helper=" "
        maxLength="7"
        .mask="${number}"
        .inputMode="${'numeric'}"
        .value="${this.state.paymentId}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.authEFT.id}"
        name="authEFT"
        label="Check/Auth/EFT"
        helper=" "
        .value="${this.state.authEFT}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }
}

window.customElements.define(
  'neb-filters-era-eob-associate-payments',
  NebFiltersEraEobAssociatePayment,
);
