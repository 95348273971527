import '../../../neb-tabs-old';
import '../../../neb-tab-old';
import '../../../neb-text';
import './neb-ledger-activity-encounters-page';
import './neb-ledger-activity-purchases-page';
import './neb-ledger-activity-transactions-page';
import '../../../../../../../src/components/pages/ledger/neb-page-running-ledger';

import { matchRouteSwitch, navigate, redirect } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
} from '../../../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  tabs: {
    id: 'tabs',
  },
  encountersPage: {
    id: 'encounters',
  },
  purchasesPage: {
    id: 'purchases',
  },
  transactionsPage: {
    id: 'transactions',
  },
  runningLedgerPage: {
    id: 'running-ledger',
  },
};

export const TABS = {
  ENCOUNTERS: 'encounters',
  PURCHASES: 'purchases',
  TRANSACTIONS: 'transactions',
  RUNNING_LEDGER: 'ledger',
};

class NebLedgerActivityController extends LitElement {
  static get properties() {
    return {
      patient: Object,
      layout: String,
      route: String,
      cases: Array,
      __selectedTab: String,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.route = '';
    this.layout = '';
    this.patient = {
      id: '',
      name: '',
    };

    this.cases = [];

    this.__navItems = this.genNavItems();
    this.__selectedTab = '';

    this.onReloadData = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => navigate(this.__buildRoute(tab)),
      reloadData: () => this.onReloadData(),
    };
  }

  __getRouteTail() {
    const segments = this.route.split('/');
    return segments.slice(2).join('/');
  }

  __buildRoute(tab) {
    return `#/patients/${this.patient.id}/ledger/activity/${tab}`;
  }

  __evaluateRoute() {
    const routeTail = this.__getRouteTail();

    return this.layout === 'large'
      ? !!this.__navItems.find(navItem => navItem.name === routeTail)
      : routeTail === '';
  }

  updated(changedProps) {
    if (
      (changedProps.has('route') && this.route) ||
      changedProps.has('layout')
    ) {
      let tab = this.__getRouteTail();

      if (!this.__evaluateRoute()) {
        tab = this.layout === 'large' ? TABS.RUNNING_LEDGER : '';
        redirect(this.__buildRoute(tab));
      }

      this.__selectedTab = tab;
    }
  }

  getRunningLedgerPage() {
    return this.shadowRoot.getElementById(ELEMENTS.runningLedgerPage.id);
  }

  getTransactionsPage() {
    return this.shadowRoot.getElementById(ELEMENTS.transactionsPage.id);
  }

  refetchLedgerItems() {
    const runningLedgerPage = this.getRunningLedgerPage();
    return runningLedgerPage ? runningLedgerPage.refetchLedgerItems() : '';
  }

  refetchTransactionItems() {
    const transactionPage = this.getTransactionsPage();
    return transactionPage ? transactionPage.refetchItems() : '';
  }

  fetch() {
    const encountersPage = this.shadowRoot.getElementById(
      ELEMENTS.encountersPage.id,
    );
    return encountersPage ? encountersPage.fetch() : '';
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
        }

        .tabs {
          padding: 0 ${CSS_SPACING};
          flex: 0 0 auto;
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .left {
          margin-left: ${CSS_SPACING};
        }

        .no-shrink {
          flex-shrink: 0;
        }

        .header {
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  genNavItems() {
    return [
      {
        exact: false,
        label: 'Transactions',
        name: `${TABS.RUNNING_LEDGER}`,
        path: `/activity/${TABS.RUNNING_LEDGER}/`,
        resolver: () => html`
          <neb-page-running-ledger
            id="${ELEMENTS.runningLedgerPage.id}"
            .patient="${this.patient}"
          ></neb-page-running-ledger>
        `,
      },
      {
        exact: false,
        label: 'Encounters',
        name: `${TABS.ENCOUNTERS}`,
        path: `/activity/${TABS.ENCOUNTERS}/`,
        resolver: () => html`
          <neb-ledger-activity-encounters-page
            id="${ELEMENTS.encountersPage.id}"
            .patientId="${this.patient.id}"
            .layout="${this.layout}"
            .cases="${this.cases}"
            .onReloadData="${this.__handlers.reloadData}"
          ></neb-ledger-activity-encounters-page>
        `,
      },
      {
        exact: false,
        label: 'Purchases',
        name: `${TABS.PURCHASES}`,
        path: `/activity/${TABS.PURCHASES}/`,
        resolver: () => html`
          <neb-ledger-activity-purchases-page
            id="${ELEMENTS.purchasesPage.id}"
            .patient="${this.patient}"
            .layout="${this.layout}"
          ></neb-ledger-activity-purchases-page>
        `,
      },
      {
        exact: false,
        label: 'Transaction History',
        name: `${TABS.TRANSACTIONS}`,
        path: `/activity/${TABS.TRANSACTIONS}/`,
        resolver: () => html`
          <neb-ledger-activity-transactions-page
            id="${ELEMENTS.transactionsPage.id}"
            .patientId="${this.patient.id}"
            .layout="${this.layout}"
          ></neb-ledger-activity-transactions-page>
        `,
      },
    ];
  }

  __renderTabs() {
    return html`
      <neb-tabs-old
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selected="${this.__selectedTab}"
        .onChange="${this.__handlers.selectTab}"
      >
        ${
          this.__navItems.map(
            item => html`
              <neb-tab-old id="tab-${item.name}" name="${item.name}"
                >${item.label}</neb-tab-old
              >
            `,
          )
        }
      </neb-tabs-old>
    `;
  }

  render() {
    return html`
      ${this.__renderTabs()} ${matchRouteSwitch(this.__navItems, this.route)}
    `;
  }
}

customElements.define(
  'neb-ledger-activity-controller',
  NebLedgerActivityController,
);
