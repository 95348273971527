import '../patients/ledger/neb-ledger-encounter-charges-mobile-row';
import '../inputs/neb-select-search';
import '../tables/neb-table-ledger-encounter-charges';
import '../controls/neb-button-action';
import '../neb-cell-fee-schedule';
import '../neb-header';
import '../neb-popup-header';

import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import {
  NO_CHARGES_SELECT_ADD_CHARGES,
  NO_OPEN_ENCOUNTERS,
  NO_RESULTS,
  NO_SELECTED_ENCOUNTER_CHARGES,
} from '../../../../../src/utils/user-message';
import { mapAddedChargeToEncounterCharge } from '../../../../neb-api-client/src/services/encounter-charge';
import {
  PRACTICE_SETTINGS,
  getPracticeSettings,
} from '../../../../neb-api-client/src/services/practice-settings';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { PROCEDURE_NOT_AUTHORIZED_POPUP_MODEL } from '../../../../neb-utils/patientAuthorization';
import { getValueByPath } from '../../../../neb-utils/utils';
import { required, range } from '../../../../neb-utils/validators';
import ExpandedTableService from '../../services/expanded-table';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';
import { NebModifiers } from '../field-groups/neb-modifiers';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  addButton: { id: 'add-button' },
  description: { id: 'description' },
  encounterDropdown: { id: 'encounter-dropdown' },
  header: { id: 'header' },
  table: { id: 'table' },
  autoPointDiagnosesButton: {
    id: 'auto-point-diagnoses-button',
  },
};

const APPEND_KEYS = [
  'chargeId',
  'procedure',
  'description',
  'modifiers.0',
  'modifiers.1',
  'modifiers.2',
  'modifiers.3',
  'units',
  'taxName',
  'taxRate',
  'diagnosisPointers',
  'unitCharge',
  'feeScheduleName',
  'feeScheduleCharge',
  'allowedAmount',
  'EPSDTCode',
  'suppressFromClaim',
  'billWithTreatmentInitiationDate',
  'billWithXrayDate',
];

const CARD_HEIGHT = 98;
const CARD_HEIGHT_LOCATION = 130;
const CARD_HEIGHT_MOBILE = 133;

class NebEncounterChargesForm extends NebForm {
  static get properties() {
    return {
      __encounterSearch: String,
      __expandedFlags: Array,

      autoSelectEncounter: Boolean,
      diagnosisItems: Array,
      settingsCharges: Array,
      initialEncounters: Array,
      filteredEncounters: Array,
      selectedEncounter: Object,
      selectedAuthorization: Object,
      __autoDiagnosisPointing: Boolean,
      __autoPostCharges: Boolean,
    };
  }

  static createModel() {
    return {
      items: [],
      diagnoses: [],
    };
  }

  initState() {
    super.initState();

    this.__initServices();

    this.__itemHeight = CARD_HEIGHT;
    this.__encounterSearch = '';
    this.__expandedFlags = [];
    this.__autoDiagnosisPointing = false;
    this.__autoPostCharges = false;

    this.autoSelectEncounter = false;
    this.settingsCharges = [];
    this.initialEncounters = [];
    this.filteredEncounters = [];
    this.selectedEncounter = {};
    this.diagnosisItems = [];
    this.selectedAuthorization = null;

    this.onSelectEncounter = () => {};

    this.onFilterEncounter = () => {};

    this.onBack = () => {};
  }

  __get4DiagnosisPointers() {
    return this.diagnosisItems.slice(
      0,
      Math.min(this.diagnosisItems.length, 4),
    );
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      toggleExpand: (_index, item) => {
        this.__expandedTableService.updateItem(item);
      },
      addCharges: async () => {
        const stateItemsLength = this.state.items.length;

        const selectedCharges = this.settingsCharges.filter(tc =>
          this.state.items.some(sc => tc.id === sc.chargeId),
        );

        const selection = await openOverlay(OVERLAY_KEYS.LEDGER_ADD_CHARGE, {
          config: this.__getTableConfig(),
          items: this.settingsCharges,
          selectedCharges,
          isDirty: this.__dirty,
        });

        const addedCharges = selection.filter(
          c => !selectedCharges.find(sc => sc.id === c.id),
        );

        const diagnosisPointers = this.__autoDiagnosisPointing
          ? this.__get4DiagnosisPointers()
          : [];

        const addedEncounterCharges = mapAddedChargeToEncounterCharge({
          charges: addedCharges,
          diagnosisPointers,
          autoPost: this.__autoPostCharges,
        });

        addedEncounterCharges.forEach((item, index) => {
          this.formService.addItem('items');

          APPEND_KEYS.forEach(key =>
            this.formService.apply(
              `items.${index + stateItemsLength}.${key}`,
              getValueByPath(item, key.split('.')),
            ),
          );
        });

        this.__expandedTableService.setItems(this.state.items);
      },
      autoPointDiagnoses: () => {
        if (!this.diagnosisItems.length) {
          return;
        }
        const diags4 = this.__get4DiagnosisPointers();

        this.state.items.forEach((item, index) => {
          if (!item.id && !item.diagnosisPointers.length) {
            this.formService.apply(`items.${index}.diagnosisPointers`, [
              ...diags4,
            ]);
          }
        });
      },
      removeCharge: (name, index) => {
        this.formService.removeItem(name, index);

        this.__expandedTableService.setItems(this.state.items);
      },
      save: async () => {
        if (!this.state.items.length && this.__dirty) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Encounter Charges',
            message:
              'You must have at least one charge to add an encounter charge. Please add at least one charge and save again.',
          });
        }

        if (
          this.formService.validate() &&
          (await this.__checkChargesInAuthorization())
        ) {
          const model = this.formService.build();

          this.__saving = true;
          this.onSave(model);
        }

        return undefined;
      },
      selectEncounter: e => this.onSelectEncounter(e),
      filterEncounter: e => {
        this.__encounterSearch = e.value || '';

        this.onFilterEncounter(e);
      },
      cancel: () => {
        this.onCancel();
      },
      back: () => {
        this.onBack();
      },
      renderEncounterItem: model => html`
        <neb-encounter-card
          .layout="${this.layout}"
          .model="${model}"
        ></neb-encounter-card>
      `,
      renderExpandableRow: (model, errors, index, changeHandler) => html`
        <neb-ledger-encounter-charges-mobile-row
          .model="${model}"
          .onChange="${changeHandler}"
          .errors="${errors}"
          .name="${index}"
          .diagnosisItems="${this.diagnosisItems}"
        ></neb-ledger-encounter-charges-mobile-row>
      `,
    };
  }

  __initServices() {
    this.__expandedTableService = new ExpandedTableService(
      flags => {
        this.__expandedFlags = flags;
      },
      [],
      'chargeId',
    );
  }

  createSelectors() {
    return {
      children: {
        items: {
          createItem: () => ({
            chargeId: '',
            procedure: '',
            description: '',
            modifiers: ['', '', '', ''],
            units: '1',
            taxName: '',
            taxRate: 0,
            diagnosisPointers: [],
            unitCharge: 0,
            feeScheduleName: null,
            feeScheduleCharge: null,
            allowedAmount: null,
            EPSDTCode: false,
            suppressFromClaim: false,
            billWithTreatmentInitiationDate: false,
            billWithXrayDate: false,
          }),
          children: {
            $: {
              children: {
                diagnosisPointers: {
                  unsafe: true,
                  clipPristine: true,
                  format: v =>
                    v.map(dx =>
                      this.diagnosisItems.find(
                        d => d.item.code === dx.diagnosisCode,
                      ),
                    ),
                  unformat: v =>
                    v.map(dx => ({
                      diagnosisCode: dx.item.code,
                    })),
                  validators: [],
                },
                modifiers: NebModifiers.createSelectors(),
                units: {
                  format: v => v.toString(),
                  unformat: v => parseInt(v, 10),
                  validators: [required('1 - 999'), range(1, 999)],
                },
              },
            },
          },
        },
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    const practiceSettings = await getPracticeSettings();

    this.__autoDiagnosisPointing =
      practiceSettings[PRACTICE_SETTINGS.AUTO_DIAGNOSIS_POINTING];

    this.__autoPostCharges =
      practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES];
  }

  update(changedProps) {
    if (changedProps.has('diagnoses')) {
      this.formService.refresh(this.model);
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__expandedTableService.setItems(this.state.items);
    }
    this.__itemHeight =
      this.layout === 'small' ? CARD_HEIGHT_MOBILE : CARD_HEIGHT_LOCATION;
  }

  static get styles() {
    return [
      super.styles,
      css`
        .encounter-dropdown {
          z-index: 2;
        }
      `,
    ];
  }

  __getTableConfig() {
    return {
      tableConfig: [
        {
          key: 'procedure',
          label: 'Procedure',
          flex: css`1 0 80px`,
        },
        {
          key: 'description',
          label: 'Description',
          flex: css`1 0 100px`,
          style: css`
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          `,
        },
        {
          key: 'modifiers',
          label: 'Modifiers',
          flex: css`1 0 80px`,
          formatter: value => (value ? value.filter(m => m).join(',') : ''),
        },
        {
          key: 'feeScheduleName',
          label: 'Fee Schedule',
          flex: css`1 0 120px`,
          style: css`
            flex-direction: column;
          `,
          formatter: (_, item) =>
            html`
              <neb-cell-fee-schedule .model="${item}"></neb-cell-fee-schedule>
            `,
        },
      ],
      mobileTableConfig: [
        {
          mobile: true,
          key: 'procedure',
          label: 'Procedure',
          flex: css`0 0 120px`,
        },
        {
          mobile: true,
          key: 'description',
          label: 'Description',
          flex: css`1 0 0`,
        },
        {
          mobile: true,
          key: 'modifiers',
          label: 'Mods',
          flex: css`0 0 40px`,
          style: 'white-space: pre-line',
          formatter: value => (value ? value.filter(m => m).join('\n') : ''),
        },
      ],
      itemName: 'charge',
      itemPluralName: 'charges',
      title: 'Add Charge - Encounter',
    };
  }

  __chargesInAuthorization() {
    const chargesFound = this.state.items.every(item => {
      if (item.encounterId === undefined) {
        const chargeInAuth = this.selectedAuthorization.charges.find(
          charge => charge.chargeId === item.chargeId,
        );

        if (chargeInAuth === undefined) return false;
      }

      return true;
    });

    return chargesFound;
  }

  async __checkChargesInAuthorization() {
    if (this.selectedAuthorization && !this.__chargesInAuthorization()) {
      const accepted = await openPopup(
        POPUP_RENDER_KEYS.CONFIRM,
        PROCEDURE_NOT_AUTHORIZED_POPUP_MODEL,
      );

      if (!accepted) return false;
    }

    return true;
  }

  renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Add Charge - Encounter"
        showBackButton
        showCancelButton
        .onCancel="${this.handlers.cancel}"
        .onBack="${this.handlers.back}"
      ></neb-popup-header>
    `;
  }

  renderActionBar() {
    return this.__dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="${this.confirmLabel}"
            cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.back}"
          ></neb-action-bar>
        `
      : '';
  }

  __renderAutoPointDiagnoses() {
    return html`
      <neb-button-action
        id="${ELEMENTS.autoPointDiagnosesButton.id}"
        class="header-buttons"
        leadingIcon="autoPoint"
        label="Auto Point Diagnoses"
        .disabled="${!this.selectedEncounter}"
        .onClick="${this.handlers.autoPointDiagnoses}"
      ></neb-button-action>
    `;
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.description.id}" class="pad">
        Select and enter charge details to post against the patient's ledger.
      </div>

      <neb-select-search
        id="${ELEMENTS.encounterDropdown.id}"
        class="encounter-dropdown pad"
        helper="Required"
        label="Select Encounter"
        .itemHeight="${this.__itemHeight}"
        .emptyMessage="${
          this.initialEncounters.length > 0 ? NO_RESULTS : NO_OPEN_ENCOUNTERS
        }"
        .items="${this.filteredEncounters}"
        .maxVisibleItems="${this.layout === 'small' ? 3 : 4}"
        .search="${this.__encounterSearch}"
        .value="${this.selectedEncounter}"
        .onChange="${this.handlers.selectEncounter}"
        .onRenderItem="${this.handlers.renderEncounterItem}"
        .onSearch="${this.handlers.filterEncounter}"
        ?disabled="${this.autoSelectEncounter}"
        showSearch
        forceDown
      >
      </neb-select-search>

      <div class="pad">
        <neb-button-action
          id="${ELEMENTS.addButton.id}"
          label="Add Charge"
          .onClick="${this.handlers.addCharges}"
          .disabled="${!this.selectedEncounter}"
        ></neb-button-action>
        ${this.__renderAutoPointDiagnoses()}
      </div>

      <neb-header label="Charge Details"></neb-header>

      <neb-table-ledger-encounter-charges
        id="${ELEMENTS.table.id}"
        name="items"
        .model="${this.state.items}"
        .diagnoses="${this.diagnosisItems}"
        .errors="${this.errors.items}"
        .layout="${this.layout}"
        .expandedFlags="${this.__expandedFlags}"
        .onToggleExpand="${this.handlers.toggleExpand}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeCharge}"
        .onRenderExpandableRow="${
          this.layout === 'small' ? this.handlers.renderExpandableRow : null
        }"
        >${
          this.selectedEncounter
            ? NO_CHARGES_SELECT_ADD_CHARGES
            : NO_SELECTED_ENCOUNTER_CHARGES
        }
      </neb-table-ledger-encounter-charges>
    `;
  }
}

customElements.define('neb-form-encounter-charges', NebEncounterChargesForm);
