import '../../../../neb-styles/neb-icons';
import '../../../../neb-lit-components/src/components/neb-toggle-button';
import '../../../../../src/components/misc/neb-icon';
import '../../../../neb-lit-components/src/components/neb-tooltip';

import { LitElement, html, css } from 'lit';

import { CSS_COLOR_YELLOW } from '../../../../../src/styles/index';
import { DIAGNOSIS_WARNING } from '../../../../../src/utils/user-message';
import { renderNebTableStyles } from '../../../../neb-styles/neb-table-styles';
import {
  CSS_COLOR_DISABLED,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../neb-styles/neb-variables';
import { formatIcdSelectorIdCode } from '../../../../neb-utils/neb-charting-util';

const BUTTON_TEXT_ADD_PROBLEM = 'Add to Problems';
const BUTTON_TOGGLED_TEXT_ADD_PROBLEM = 'Added to Problems';
export const ELEMENTS = {
  addToProblemsButton: {
    id: 'button-add-problems',
  },
  dragHandleCell: {
    id: 'drag-handle-cell',
  },
  icdCodeCell: {
    id: 'icd-code-cell',
  },
  shortDescriptionCell: {
    id: 'short-description-cell',
  },
  removeIcon: {
    id: 'remove-icon',
  },
  icd10Warning: { id: 'icd10-warning' },
};

class NebEncounterDiagnosisTableRow extends LitElement {
  static get properties() {
    return {
      icd10Info: Object,
      isInProblemList: Boolean,
      showAddToProblems: Boolean,

      hasChartingPermissions: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initHandlers();

    this.isInProblemList = false;
    this.icd10Info = {};
    this.showAddToProblems = false;
    this.hasChartingPermissions = false;

    this.onAddToProblems = () => {};

    this.onRemoveDiagnosis = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addToProblems: () => this.onAddToProblems(this.icd10Info),
      removeDiagnosis: () => this.onRemoveDiagnosis(this.icd10Info),
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
      }

      .neb-encounter-diagnosis-table-row {
        border-bottom: 1px solid rgba(151, 151, 151, 0.3);
        width: 100%;
        display: flex;
      }

      .icd-code-cell {
        max-width: 140px;
      }

      .remove-diagnosis-cell {
        text-align: right;
        padding-right: 5px;
      }

      .remove-icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
        fill: ${CSS_COLOR_HIGHLIGHT};
      }

      .remove-icon[disabled] {
        fill: ${CSS_COLOR_DISABLED};
        pointer-events: none;
      }

      .drag-handle-cell {
        display: flex;
        justify-content: center;
      }

      .drag-handle-cell neb-icon {
        fill: ${CSS_COLOR_HIGHLIGHT};
        cursor: ns-resize;
        height: 20px;
        width: 20px;
      }

      .problem-list-cell {
        min-width: 200px;
      }

      .neb-table-cell {
        word-break: normal;
      }

      .icon-warning {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        margin-top: 17px;

        fill: ${CSS_COLOR_YELLOW};
      }
    `;
  }

  __renderIcd10WarningCell() {
    return this.hasChartingPermissions ? this.__renderIcd10Warning() : '';
  }

  __renderIcd10Warning() {
    return this.icd10Info.showICD10Warning
      ? html`
          <neb-tooltip
            id="${ELEMENTS.icd10Warning.id}"
            class="icon-warning"
            icon="neb:warning"
            iconColor="${CSS_COLOR_YELLOW}"
          >
            <div slot="tooltip">${DIAGNOSIS_WARNING}.</div>
          </neb-tooltip>
        `
      : html`
          <div class="icon-warning"></div>
        `;
  }

  __renderProblemListCell() {
    return this.showAddToProblems
      ? html`
          <div class="neb-table-cell neb-table-medium-width problem-list-cell">
            <neb-toggle-button
              id="${ELEMENTS.addToProblemsButton.id}"
              .buttonText="${
                this.isInProblemList
                  ? BUTTON_TOGGLED_TEXT_ADD_PROBLEM
                  : BUTTON_TEXT_ADD_PROBLEM
              }"
              .isToggled="${this.isInProblemList}"
              .onClick="${this.__handlers.addToProblems}"
            >
            </neb-toggle-button>
          </div>
        `
      : '';
  }

  __renderReorderIcon() {
    return this.hasChartingPermissions
      ? html`
          <div
            reorder
            id="${ELEMENTS.dragHandleCell.id}"
            class="neb-table-cell drag-handle-cell"
          >
            <neb-icon icon="neb:reorder"></neb-icon>
          </div>
        `
      : '';
  }

  __renderRemoveIcon() {
    return this.hasChartingPermissions
      ? html`
          <neb-icon
            id="${ELEMENTS.removeIcon.id}"
            class="remove-icon"
            icon="neb:minus"
            @click="${this.__handlers.removeDiagnosis}"
          ></neb-icon>
        `
      : '';
  }

  render() {
    return html`
      ${renderNebTableStyles()}
      <div
        class="neb-encounter-diagnosis-table-row neb-table-row"
        id="icd10-${formatIcdSelectorIdCode(this.icd10Info.code)}"
      >
        ${this.__renderReorderIcon()} ${this.__renderIcd10WarningCell()}
        <div
          id="${ELEMENTS.icdCodeCell.id}"
          class="neb-table-cell neb-table-small-width icd-code-cell"
        >
          ${this.icd10Info.code}
        </div>
        <div
          id="${ELEMENTS.shortDescriptionCell.id}"
          class="neb-table-cell neb-table-large-width short-description-cell"
        >
          ${this.icd10Info.shortDescription}
        </div>
        ${this.__renderProblemListCell()}

        <div class="neb-table-cell remove-diagnosis-cell">
          ${this.__renderRemoveIcon()}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-encounter-diagnosis-table-row',
  NebEncounterDiagnosisTableRow,
);
