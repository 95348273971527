import '../../neb-lit-components/src/components/neb-date-picker';
import '../../../src/components/misc/neb-icon';
import '../../neb-lit-components/src/components/neb-toggle-chevron';

import { LitElement, html } from 'lit';

import { ISO_DATE_FORMAT } from '../../neb-input/nebFormatUtils';
import { renderNebTableStyles } from '../../neb-styles/neb-table-styles';
import { CSS_COLOR_GREY_1, CSS_SPACING } from '../../neb-styles/neb-variables';
import { parseDate } from '../../neb-utils/date-util';

import {
  CSS_FIXED_CELL_WIDTH,
  CSS_PHASE_CELL_MIN_WIDTH,
  CSS_LAST_COLUMN_FLEX,
  CSS_CELL_PADDING_SUMMARY,
  CSS_NUMBER_COLUMN_WIDTH,
} from './neb-treatment-plan-table-css-variables';

export const ELEMENTS = {
  completeDate: {
    id: 'completed-date',
  },
  completeDateMobile: {
    id: 'completed-date-mobile',
  },
  deleteButton: {
    id: 'delete-phase',
  },
  phaseText: {
    id: 'phase-text',
  },
  aptCount: {
    id: 'apt',
  },
  comCount: {
    id: 'com',
  },
  schCount: {
    id: 'sch',
  },
  tbsCount: {
    id: 'tbs',
  },
  deleteButtonMobile: {
    id: 'delete-phase-mobile',
  },
  toggle: {
    id: 'toggle',
  },
};

export const BEFORE_PREV_PHASE_DATE_TEXT =
  "The Completed Date cannot be before or be the same as a previous phase's Completed Date.";

export const AFTER_FUTURE_DATE_TEXT =
  "The Completed Date cannot be the same or come after a future phase's Completed Date.";

export const BEFORE_START_DATE_TEXT =
  'The Completed Date cannot be before or be the same as the Start Date.';

export const AFTER_END_DATE_TEXT =
  'The Completed Date cannot come after the End Date.';

class NebTreatmentPhaseRow extends LitElement {
  static get properties() {
    return {
      expandable: {
        type: Boolean,
        reflect: true,
      },
      index: {
        type: Number,
      },
      invalidType: {
        type: String,
      },
      isTotal: {
        type: Boolean,
        reflect: true,
        attribute: 'is-total',
      },
      phase: {
        type: Object,
      },
      small: {
        type: Boolean,
      },
      summary: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.__onSelectedDate = this.__handleSelectDate.bind(this);
    this.__onClearDate = this.__handleClearDate.bind(this);
    this.__handleToggle = this.__toggle.bind(this);

    this.invalidType = '';

    this.onClearDate = () => {};

    this.onClick = () => {};

    this.onDeletePhase = () => {};
  }

  __invalidText() {
    const types = {
      beforePrevPhaseDate: BEFORE_PREV_PHASE_DATE_TEXT,
      afterFutureDate: AFTER_FUTURE_DATE_TEXT,
      beforeStartDate: BEFORE_START_DATE_TEXT,
      afterEndDate: AFTER_END_DATE_TEXT,
    };

    return types[this.invalidType] || '';
  }

  __shouldHideDate() {
    return this.summary || this.expandable || this.small;
  }

  __toggle(toggle) {
    if (toggle.expanded) {
      this.__expandRow(toggle.index);
    } else {
      this.__collapseRow(toggle.index);
    }
  }

  __expandRow() {
    this.shadowRoot
      .getElementById('date-and-delete-container')
      .removeAttribute('hidden');
  }

  __collapseRow() {
    this.shadowRoot
      .getElementById('date-and-delete-container')
      .setAttribute('hidden', '');
  }

  __getPhaseText() {
    if (!this.phase) return '';
    return this.isTotal
      ? 'Total'
      : `P${this.index + 1}: ${this.phase.occurrence} x ${
          this.phase.frequency
        }${this.phase.frequencyType} x ${this.phase.duration}${
          this.phase.durationType
        }`;
  }

  __sendEvent(eventName) {
    this.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        composed: true,
        detail: this.phase,
      }),
    );
  }

  __onDeletePhase() {
    this.onDeletePhase(this.index);
  }

  __setPhaseCompleteDate() {
    if (this.phase && this.phase.completedDate) {
      return parseDate(this.phase.completedDate);
    }

    return null;
  }

  __handleSelectDate(date) {
    this.phase.completedDate = date.format(ISO_DATE_FORMAT);
    this.onClick();
  }

  __handleClearDate() {
    this.phase.completedDate = null;
    this.onClearDate();
  }

  __getInvalidText() {
    return this.invalidType === 'Required' ? 'Required' : this.__invalidText();
  }

  render() {
    return html`
      ${renderNebTableStyles()}

      <style>
        :host {
          --icon-height: 20px;
          --number-color: #d2f2a4;
          display: block;
        }

        .neb-table-row {
          width: 100%;
        }

        .phase-text {
          min-width: ${CSS_PHASE_CELL_MIN_WIDTH};
          align-self: center;
        }

        .number {
          width: ${CSS_NUMBER_COLUMN_WIDTH};
          height: 30px;
          text-align: center;
          line-height: 27px;
          border-radius: 3px;
          background-color: var(--number-color);
        }

        .completed-date-cell {
          width: ${CSS_FIXED_CELL_WIDTH};
        }

        .icon-minus {
          cursor: pointer;
          display: flex;
          width: 20px;
          height: 20px;

          fill: ${CSS_COLOR_GREY_1};
        }

        #com {
          --number-color: #c8fcef;
        }

        #sch {
          --number-color: #ffeed2;
        }
        #tbs {
          --number-color: #c0e6ff;
        }

        #phase-item-container {
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        #input-container {
          display: flex;
          align-items: center;
        }

        .delete-container {
          flex: ${CSS_LAST_COLUMN_FLEX};
        }

        .invalid-text {
          font-size: 11px;
          color: var(--neb-color-error);
          padding-left: ${CSS_SPACING};
        }

        #completed-date-header {
          font-weight: bold;
          line-height: 34px;
          white-space: nowrap;
        }

        :host([small]) .invalid-text {
          margin-left: 130px;
          white-space: normal;
          width: 200px;
          margin-bottom: 7px;
          padding-left: 0;
        }

        :host([small]) #date-and-delete-container {
          border-bottom: 0;
          padding-left: 0;
        }

        :host([expandable]) #input-container {
          max-width: 100vw;
        }

        :host([expandable]) .delete-container {
          flex: 1;
        }

        :host([small][is-total]) .delete-container {
          flex: 1;
          min-width: 30px;
        }

        :host([is-total]) .phase-text {
          font-weight: bold;
        }

        :host([is-total]) .totalBar {
          border-bottom: 0;
          border-top: 2px solid #959595;
        }

        :host([summary]) .number {
          height: 20px;
          max-width: ${CSS_NUMBER_COLUMN_WIDTH};
          line-height: 19px;
        }

        :host([summary]) .neb-table-cell {
          padding: ${CSS_CELL_PADDING_SUMMARY};
        }

        :host([small]) .neb-table-cell {
          padding: 5px;
        }

        [hidden],
        :host([small]) .hide-when-small {
          display: none;
        }

        :host([summary]) .phase-text {
          min-width: ${CSS_PHASE_CELL_MIN_WIDTH};
          padding-left: 10px;
        }

        :host([expandable]) .completed-date-cell {
          width: 170px;
        }

        :host([small]) .neb-table-row {
          padding-left: ${CSS_SPACING};
        }

        :host([small]) .toggle-container {
          padding-right: ${CSS_SPACING};
        }
      </style>
      <div class="phaseItem neb-table-row totalBar">
        <div id="phase-item-container">
          <div id="input-container">
            <div
              id="${ELEMENTS.phaseText.id}"
              class="neb-table-cell phase-text"
            >
              ${this.__getPhaseText()}
            </div>
            <div class="neb-table-cell neb-table-small-width">
              <div id="${ELEMENTS.aptCount.id}" class="number">
                ${this.phase.appointmentCount}
              </div>
            </div>
            <div class="neb-table-cell neb-table-small-width">
              <div id="${ELEMENTS.comCount.id}" class="number">
                ${this.phase.encounterCount}
              </div>
            </div>
            <div class="neb-table-cell neb-table-small-width">
              <div id="${ELEMENTS.schCount.id}" class="number">
                ${this.phase.scheduledCount}
              </div>
            </div>
            <div class="neb-table-cell neb-table-small-width">
              <div id="${ELEMENTS.tbsCount.id}" class="number">
                ${this.phase.tbsCount}
              </div>
            </div>

            <div
              id="completed-date-container"
              class="neb-table-cell completed-date-cell"
              ?hidden="${this.__shouldHideDate()}"
            >
              ${
                this.isTotal
                  ? ''
                  : html`
                      <neb-date-picker
                        id="${ELEMENTS.completeDate.id}"
                        .selectedDate="${this.__setPhaseCompleteDate()}"
                        .onClick="${this.__onSelectedDate}"
                        .onClear="${this.__onClearDate}"
                        .invalid="${this.invalidType}"
                        class="completed hide-when-small"
                        .invalidText="${this.__getInvalidText()}"
                        .manualPopoverPosition="${'center'}"
                        momentFlag
                      >
                      </neb-date-picker>
                    `
              }
            </div>

            <div
              id="delete-container"
              class="neb-table-cell neb-table-large-width delete-container"
              ?hidden="${this.summary || this.expandable}"
            >
              <neb-icon
                id="${ELEMENTS.deleteButton.id}"
                class="icon icon-minus"
                icon="neb:minus"
                @click="${this.__onDeletePhase}"
                ?hidden="${this.isTotal}"
              ></neb-icon>
            </div>

            ${
              this.expandable
                ? html`
                    <div
                      class="neb-table-cell neb-table-small-width toggle-container"
                    >
                      <neb-toggle-chevron
                        id="${ELEMENTS.toggle.id}"
                        .index="${this.index}"
                        .onToggle="${this.__handleToggle}"
                      ></neb-toggle-chevron>
                    </div>
                  `
                : ''
            }
          </div>
          <div
            class="neb-table-row neb-table-large-width"
            hidden
            id="date-and-delete-container"
          >
            <div
              id="completed-date-header"
              class="neb-table-cell fixed-cell header "
            >
              Completed Date
            </div>
            <div
              id="completedDate"
              class="neb-table-cell completed-date-cell"
              ?hidden="${this.summary}"
            >
              ${
                this.isTotal
                  ? ''
                  : html`
                      <neb-date-picker
                        id="${ELEMENTS.completeDateMobile.id}"
                        .selectedDate="${this.__setPhaseCompleteDate()}"
                        .onClick="${this.__onSelectedDate}"
                        .onClear="${this.__onClearDate}"
                        .invalid="${this.invalidType}"
                        class="completed completed-date-cell"
                        .invalidText="${this.__getInvalidText()}"
                        momentFlag
                      >
                      </neb-date-picker>
                    `
              }
            </div>
            <div
              id="delete-phase-container"
              class="neb-table-cell neb-table-small-width delete-container"
              ?hidden="${this.summary}"
            >
              <neb-icon
                id="${ELEMENTS.deleteButtonMobile.id}"
                class="icon icon-minus"
                icon="neb:minus"
                @click="${this.__onDeletePhase}"
                ?hidden="${this.isTotal}"
              ></neb-icon>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-treatment-phase-row', NebTreatmentPhaseRow);
