import ApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

const formatQuestionnaireSettings = res => {
  const questionnaire = res.data[0];
  return questionnaire || null;
};

const formatQuestionnaireBooking = res => {
  const questionnaire = res.data[0];

  if (questionnaire && questionnaire.questions.length && questionnaire.active) {
    return questionnaire;
  }

  return null;
};

export const billingApiClient = new ApiClient({ microservice: 'billing' });

export const getAppointmentQuestionnaire = async appointmentId => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `appointments/${appointmentId}/questionnaire`,
    version: 'public1',
  });

  return formatQuestionnaireSettings(res);
};

export const getAppointmentQuestionnaireBooking = async appointmentId => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `appointments/${appointmentId}/questionnaire`,
    version: 'public1',
  });

  return formatQuestionnaireBooking(res);
};

export const fetchAllQuestions = async () => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: 'self-check-in-questions',
    version: 1,
  });

  return res.data;
};

export const fetchQuestionnaireByProviderAndApptType = async (
  providerId,
  appointmentTypeId,
) => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `providers/${providerId}/appointment-type/${appointmentTypeId}/questionnaire`,
    version: 1,
  });

  return formatQuestionnaireSettings(res);
};

export const fetchQuestionnaireByProviderAndApptTypeBooking = async (
  providerId,
  appointmentTypeId,
) => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `providers/${providerId}/appointment-type/${appointmentTypeId}/questionnaire`,
    version: 1,
  });

  return formatQuestionnaireBooking(res);
};

export const fetchAllQuestionnairesByProvider = async providerId => {
  const res = await billingApiClient.makeRequest({
    method: Method.GET,
    path: `providers/${providerId}/questionnaires`,
    version: 1,
  });

  return res.data;
};

export const saveQuestionnaire = (
  providerId,
  appointmentTypeId,
  questionnaire,
) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `providers/${providerId}/appointment-type/${appointmentTypeId}/questionnaire`,
    body: questionnaire,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });

export const saveBulkQuestionnaires = (providerId, questionnaires) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `providers/${providerId}/questionnaires`,
    body: questionnaires,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });
