import '../../neb-info';
import '../../neb-action-bar';
import '../../neb-popup-header';
import '../../controls/neb-button-action';
import '../../inputs/neb-select';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import {
  openPayfacIframePopup,
  ADD_PAYMENT_METHOD_TEMPLATE,
} from '../../../../../../src/features/payfac/utils';
import { TYPE, fetch } from '../../../../../neb-api-client/src/billing-codes';
import { fetchOne } from '../../../../../neb-api-client/src/patient-api-client';
import { getPatientRelationshipsActiveGroup } from '../../../../../neb-api-client/src/patient-relationship-api-client';
import { getMerchantAccounts } from '../../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import { getPaymentMethods } from '../../../../../neb-api-client/src/payments/payment-methods-api-client';
import {
  getScheduleStatus,
  reprocessScheduledPayment,
  markAsPaid,
} from '../../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
} from '../../../../../neb-styles/neb-variables';
import { SCHEDULED_PAYMENT_TYPE } from '../../../../../neb-utils/enums';
import { centsToCurrency } from '../../../../../neb-utils/formatters';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: { id: 'header' },
  merchantAccountDropdown: { id: 'merchant-account' },
  paymentName: { id: 'payment-name' },
  paymentType: { id: 'payment-type' },
  paymentAmount: { id: 'payment-amount' },
  paymentMethodDropdown: { id: 'payment-method' },
  addPaymentMethodButton: { id: 'add-payment-method' },
  failReason: { id: 'fail-reason' },
  recurringInfo: { id: 'recurring-info' },
  actionBar: {
    id: 'action-bar',
  },
};

export const MARK_AS_PAID_SUCCESS = 'Scheduled Payment updated successfully';
const MARK_AS_PAID_ERROR =
  'An error occurred when updating the Scheduled Payment';
const REPROCESS_SUCCESS = 'Scheduled Payment processed successfully';
const REPROCESS_ERROR =
  'An error occurred when processing the Scheduled Payment';
const HELPER_TEXT =
  'To resolve this failed payment you can re-process the scheduled payment now by updating the payment method if needed, and clicking on the "Re-Process" button.';
const MARK_AS_PAID_HELPER_TEXT =
  'You can also mark this scheduled payment as paid if you have received payment separately.';
const RECURRING_HELPER_TEXT =
  'This scheduled payment is part of a recurring series. Updating the payment method and clicking on re-process will update the payment method for this and all scheduled payments in the series.';
const TITLE = 'Update Failed Payment';

class NebOverlayFailedScheduledPayment extends Overlay {
  static get properties() {
    return {
      __selectedMerchantAccount: Object,
      __selectedPaymentMethod: Object,
      __merchantAccountNames: Array,
      __paymentMethodNames: Array,
      __paymentType: String,
      __failReason: String,
    };
  }

  initState() {
    super.initState();

    this.__merchantAccountNames = [];
    this.__paymentMethodNames = [];
    this.__selectedMerchantAccount = null;
    this.__selectedPaymentMethod = null;
    this.__paymentType = '';
    this.__failReason = '';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      reProcess: async () => {
        if (!this.__selectedPaymentMethod) return;

        const res = await openPopup(POPUP_RENDER_KEYS.TRANSACTION_PROCESSING, {
          promise: reprocessScheduledPayment({
            patientId: this.model.patientId,
            scheduleId: this.model.schedule.id,
            paymentMethodId: this.__selectedPaymentMethod.id,
            merchantAccountId: this.__selectedMerchantAccount.id,
            logContent: {
              action:
                'payment - reProcess - neb-overlay-failed-scheduled-payment',
              rawModel: {
                patientId: this.model.patientId,
                scheduleId: this.model.schedule.id,
                paymentMethodId: this.__selectedPaymentMethod.id,
                merchantAccountId: this.__selectedMerchantAccount.id,
              },
            },
          }),
        });

        const result = {
          ...res,
          paymentMethod: this.__selectedPaymentMethod,
          merchantAccount: this.__selectedMerchantAccount,
        };

        if (result.status === 'Failed') {
          store.dispatch(openError(REPROCESS_ERROR));
          this.handlers.dismiss(result);
        } else {
          store.dispatch(openSuccess(REPROCESS_SUCCESS));
          this.handlers.dismiss(result);
        }
      },
      markAsPaid: async () => {
        const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Mark as Paid Manually',
          message: html`
            This will manually mark this scheduled payment as “Paid Manually”.
            This will not re-process this payment, and will not affect any
            future scheduled payments in the series. <br /><br />Do you want to
            continue?
          `,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (!res) return;

        try {
          const updatedSchedule = await markAsPaid({
            patientId: this.model.patientId,
            scheduleId: this.model.schedule.id,
          });

          if (updatedSchedule.status === 'Failed') {
            store.dispatch(openError(MARK_AS_PAID_ERROR));
            this.handlers.dismiss(updatedSchedule);
          } else {
            store.dispatch(openSuccess(MARK_AS_PAID_SUCCESS));
            this.handlers.dismiss(updatedSchedule);
          }
        } catch (e) {
          store.dispatch(openError(MARK_AS_PAID_ERROR));
          this.handlers.dismiss();
        }
      },
      addPaymentMethod: async () => {
        const { addresses, firstName, lastName } = await fetchOne(
          this.model.patientId,
          true,
          true,
        );

        const holderAddress =
          addresses.length > 0
            ? {
                postalCode: addresses[0].zipcode,
                address1: addresses[0].address1,
                address2: addresses[0].address2,
                city: addresses[0].city,
                state: addresses[0].state,
              }
            : {
                postalCode: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
              };

        const result = await openPayfacIframePopup({
          ...ADD_PAYMENT_METHOD_TEMPLATE,
          merchantAccounts: this.__merchantAccountNames,
          firstName,
          lastName,
          holderId: this.model.patientId,
          ...holderAddress,
        });

        if (result) {
          const paymentMethods = await this.__fetchPaymentMethods();

          this.__paymentMethodNames = paymentMethods.map(v => ({
            ...v,
            label: v.description,
          }));

          const addedPaymentMethod = this.__paymentMethodNames.find(
            v => v.id === result.id,
          );

          this.__selectedPaymentMethod = addedPaymentMethod;
        }
      },
      closeAll: () => this.handlers.dismiss(true),
      change: e => {
        if (e.id === ELEMENTS.paymentMethodDropdown.id) {
          if (e.value?.id === this.__selectedPaymentMethod?.id) return;

          this.__selectedPaymentMethod = this.__paymentMethodNames.find(
            ({ id }) => id === e.value.id,
          );
        }

        if (e.id === ELEMENTS.merchantAccountDropdown.id) {
          if (e.value.id === this.__selectedMerchantAccount.id) return;

          this.__selectedMerchantAccount = this.__merchantAccountNames.find(
            ({ id }) => id === e.value.id,
          );
        }
      },
    };
  }

  async __fetchPaymentMethods() {
    if (!this.__merchantAccountNames.length) return [];

    const relationshipGroup = await getPatientRelationshipsActiveGroup(
      this.model.patientId,
    );

    const relatedPatientIds = relationshipGroup.related;

    const paymentMethods = await getPaymentMethods(
      this.__selectedMerchantAccount,
      this.model.patientId,
      {
        fetchAll: true,
        deleted: false,
      },
      relatedPatientIds.length
        ? [relationshipGroup.primary, ...relatedPatientIds]
        : null,
    );

    return paymentMethods;
  }

  async __fetchBillingCodes() {
    const originalItems = await fetch(TYPE.PAYMENT);

    return originalItems;
  }

  __formatError(status) {
    let reason = 'Failed';

    if (status) {
      if (status.error) {
        const error = JSON.parse(status.error);

        if (error.error && error.error.message) {
          reason = error.error.message;
        } else if (error.status) {
          reason = error.status;
        }
      }
    }

    return reason;
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      let status;

      const {
        patientId,
        schedule: { id: scheduleId },
        item: { paymentTypeId, paymentMethodId, merchantAccountId, message },
      } = this.model;

      if (!message) status = await getScheduleStatus(patientId, { scheduleId });

      this.__failReason = message || this.__formatError(status);

      this.__merchantAccountNames = (await getMerchantAccounts({
        hideInactive: true,
      })).map(v => ({
        ...v,
        label: v.name,
      }));

      this.__selectedMerchantAccount = this.__merchantAccountNames.find(
        ({ id }) => id === merchantAccountId,
      );

      const paymentTypes = await this.__fetchBillingCodes();

      const paymentType = paymentTypes.find(({ id }) => id === paymentTypeId);

      this.__paymentType = `${paymentType.code} - ${paymentType.description}`;

      const paymentMethods = await this.__fetchPaymentMethods();

      this.__paymentMethodNames = paymentMethods.map(v => ({
        ...v,
        label: v.description,
      }));

      this.__selectedPaymentMethod = this.__paymentMethodNames.find(
        ({ id }) => id === paymentMethodId,
      );
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header {
          padding: ${CSS_SPACING};
        }

        .sub-header {
          padding: 0 ${CSS_SPACING};
          font-weight: bold;
        }

        .recurring-helper {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .info-container {
          display: grid;
          padding-bottom: ${CSS_SPACING};
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          flex: 1 0 0;
          overflow-y: auto;
          background-color: ${CSS_COLOR_WHITE};
        }

        .info {
          padding: 0 ${CSS_SPACING} 0 ${CSS_SPACING};
          display: flex;
        }

        .content {
          width: 700px;
          overflow: auto;
        }

        .sub-content {
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
        }

        .caption {
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
          min-height: 39px;
        }

        .form {
          overflow: auto;
        }

        .select {
          width: 320px;
        }

        .grid {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          align-items: center;
        }

        .grid-2 {
          grid-template-columns: 1fr 1fr;
        }

        .grid-row-tooltip {
          display: flex;
        }

        .padding-left {
          padding-left: ${CSS_SPACING};
        }

        .padding-top {
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${TITLE}"
        .onBack="${this.handlers.dismiss}"
        .onCancel="${this.handlers.closeAll}"
        ?showBackButton="${this.index}"
        showCancelButton
      ></neb-popup-header>

      <div class="info-container">
        <span class="sub-content"
          >${
            this.model.item.patientPackageId
              ? HELPER_TEXT
              : `${HELPER_TEXT} ${MARK_AS_PAID_HELPER_TEXT}`
          }</span
        >

        <span class="sub-header">Scheduled Payment Name</span>
        <span id="${ELEMENTS.paymentName.id}" class="caption"
          >${this.model.item.name}</span
        >

        <span class="sub-header">Payment Type</span>
        <span id="${ELEMENTS.paymentType.id}" class="caption"
          >${this.__paymentType}</span
        >

        <span class="sub-header">Payment Amount</span>
        <span id="${ELEMENTS.paymentAmount.id}" class="caption"
          >${
            typeof this.model.item.amount === 'string'
              ? this.model.item.amount
              : centsToCurrency(this.model.item.amount)
          }</span
        >

        <div class="grid grid-2">
          <neb-select
            id="${ELEMENTS.paymentMethodDropdown.id}"
            class="padding-left"
            name="paymentMethod"
            helper="Required"
            label="Payment Method"
            .items="${this.__paymentMethodNames}"
            .value="${this.__selectedPaymentMethod}"
            .error="${!this.__selectedPaymentMethod}"
            .onChange="${this.handlers.change}"
          ></neb-select>

          <neb-button-action
            id="${ELEMENTS.addPaymentMethodButton.id}"
            label="Add Payment Method"
            .onClick="${this.handlers.addPaymentMethod}"
          ></neb-button-action>

          ${
            this.__merchantAccountNames.length > 1
              ? html`
                  <neb-select
                    id="${ELEMENTS.merchantAccountDropdown.id}"
                    class="padding-left"
                    name="merchantAccount"
                    helper="Required"
                    label="Merchant Account"
                    .items="${this.__merchantAccountNames}"
                    .value="${this.__selectedMerchantAccount}"
                    .onChange="${this.handlers.change}"
                  ></neb-select>
                `
              : ''
          }
        </div>

        ${
          this.model.type !== SCHEDULED_PAYMENT_TYPE.SINGLE
            ? html`
                <div id="${ELEMENTS.recurringInfo.id}" class="info padding-top">
                  <neb-info></neb-info>
                  <span class="recurring-helper">
                    ${RECURRING_HELPER_TEXT}</span
                  >
                </div>
              `
            : html``
        }

        <span class="sub-header padding-top">Payment Failure Reason</span>
        <span id="${ELEMENTS.failReason.id}" class="caption"
          >${this.__failReason}</span
        >
      </div>

      ${
        this.model.item.patientPackageId
          ? html`
              <neb-action-bar
                id="${ELEMENTS.actionBar.id}"
                confirmLabel="RE-PROCESS"
                cancelLabel="CANCEL"
                .onConfirm="${this.handlers.reProcess}"
                .onCancel="${this.handlers.dismiss}"
              ></neb-action-bar>
            `
          : html`
              <neb-action-bar
                id="${ELEMENTS.actionBar.id}"
                confirmLabel="RE-PROCESS"
                cancelLabel="MARK AS PAID"
                removeLabel="CANCEL"
                .onConfirm="${this.handlers.reProcess}"
                .onCancel="${this.handlers.markAsPaid}"
                .onRemove="${this.handlers.dismiss}"
              ></neb-action-bar>
            `
      }
    `;
  }
}

window.customElements.define(
  'neb-overlay-failed-scheduled-payment',
  NebOverlayFailedScheduledPayment,
);
