import '../neb-popup-header';
import '../../../../../src/components/controls/inputs/neb-checkbox';

import { html, css } from 'lit';

import { getAbandonedResponses } from '../../../../neb-api-client/src/electronic-claims/abandoned-responses';
import { getRawReport } from '../../../../neb-api-client/src/electronic-claims/reports';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import '../pages/neb-page-unlinked-claim-responses';
import { openRawClaimStatusTab } from '../../../../neb-utils/electronic-claims';

import Overlay from './neb-overlay';

export const ELEMENTS = {
  header: { id: 'header' },
  description: { id: 'description' },
  unlinkedClaimsPage: { id: 'unlinked-claims-page' },
  dismissedResponsesCheckbox: { id: 'dismissed-responses-checkbox' },
};
const TITLE = 'Unlinked Claim Responses';

class NebOverlayUnlinkedClaimResponses extends Overlay {
  static get properties() {
    return {
      __abandonedClaimStatuses: Array,
      __abandonedERAs: Array,
      __hideDismissedResponses: Boolean,
      __claimStatusItemsCollectionState: Object,
      __ERAItemsCollectionState: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 750px;
          flex-flow: column nowrap;
          height: 100%;
          overflow-y: auto;
        }

        .header-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 10px;
          display: flex;
          flex-direction: column;
        }

        .header {
          padding-bottom: ${CSS_SPACING};
        }

        .container {
          display: flex,
          flex-direction: column;
        }

        .checkbox {
          padding: 0 ${CSS_SPACING} ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.__abandonedClaimStatuses = [];
    this.__abandonedERAs = [];
    this.__hideDismissedResponses = true;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      hideDismissedResponses: e => {
        this.__hideDismissedResponses = e.value;
      },
      openClaimResponse: async reportId => {
        const reportData = await getRawReport(reportId);
        openRawClaimStatusTab(
          JSON.stringify(reportData, null, 2),
          'Claim Status Response',
        );
      },
      refetch: () => this.__fetch(),
    };
  }

  async __fetch() {
    const {
      abandonedClaimStatuses,
      abandonedERAs,
    } = await getAbandonedResponses();
    this.__abandonedClaimStatuses = abandonedClaimStatuses;
    this.__abandonedERAs = abandonedERAs;
  }

  firstUpdated() {
    super.firstUpdated();
    return this.__fetch();
  }

  __renderUnlinkedClaimResponses() {
    return html`
      <neb-page-unlinked-claim-responses
        id="${ELEMENTS.unlinkedClaimsPage.id}"
        class="container"
        .hideDismissedResponses="${this.__hideDismissedResponses}"
        .onViewClaimResponse="${this.handlers.openClaimResponse}"
        .abandonedClaimStatuses="${this.__abandonedClaimStatuses}"
        .abandonedERAs="${this.__abandonedERAs}"
        .onRefresh="${this.handlers.refetch}"
      ></neb-page-unlinked-claim-responses>
    `;
  }

  renderContent() {
    return html`
      <div class="header-container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          title="${TITLE}"
          .onCancel="${this.handlers.dismiss}"
          .onBack="${this.handlers.dismiss}"
          showCancelButton
          showBackButton
        ></neb-popup-header>
      </div>

      <neb-checkbox
        id="${ELEMENTS.dismissedResponsesCheckbox.id}"
        class="checkbox"
        label="Hide dismissed responses"
        .onChange="${this.handlers.hideDismissedResponses}"
        ?checked="${this.__hideDismissedResponses}"
      ></neb-checkbox>
      ${this.__renderUnlinkedClaimResponses()}
    `;
  }
}

customElements.define(
  'neb-overlay-unlinked-claim-responses',
  NebOverlayUnlinkedClaimResponses,
);
