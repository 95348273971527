import '../neb-button-actions';
import '../neb-text';
import '../neb-radio-button';

import { css, html } from 'lit';

import { objToName, DEFAULT_NAME_OPTS } from '../../../../neb-utils/formatters';

import NebTable, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  radio: { selector: '.radio' },
  patient: { selector: '.patient' },
  primary: { selector: '.primary' },
  plan: { selector: '.plan' },
  secondary: { selector: '.secondary' },
  guarantor: { selector: '.guarantor' },
  case: { selector: '.case' },
  invoice: { selector: '.invoice' },
  procedureCode: {
    selector: '.procedure-code',
  },
  procedureCodeItem: {
    selector: '.procedure-code-item',
  },
};

class NebTableLedgerActivityEncountersChargeGroups extends NebTable {
  static get properties() {
    return {
      __selectedGroupRow: Number,
      patient: Object,
      includePatient: { type: Boolean },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();

    this.patient = {};
    this.includePatient = false;
  }

  __initState() {
    super.initState();

    this.config = [];
    this.__selectedGroupRow = 0;

    this.onSelect = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectGroup: index => {
        this.__selectedGroupRow = index;
        this.onSelect(this.model[index]);
      },
    };
  }

  __buildConfig() {
    return [
      { key: 'radio', label: '', flex: css`1 0 0`, truncate: true },
      ...(this.includePatient
        ? [
            {
              key: 'patient',
              label: 'Patient',
              flex: css`2 0 0`,
            },
          ]
        : []),
      { key: 'primary', label: 'Primary', flex: css`2 0 0` },
      { key: 'plan', label: 'Plan', flex: css`2 0 0` },
      {
        key: 'secondary',
        label: 'Secondary',
        flex: css`2 0 0`,
      },
      {
        key: 'guarantor',
        label: 'Guarantor',
        flex: css`2 0 0`,
      },
      { key: 'case', label: 'Case', flex: css`2 0 0` },
      { key: 'invoice', label: 'Invoice', flex: css`2 0 0` },
      { key: 'procedureCode', label: 'Proc + Mod', flex: css`4 0 0` },
    ];
  }

  update(changedProps) {
    if (changedProps.has('includePatient')) {
      this.config = this.__buildConfig();
    }

    super.update(changedProps);
  }

  __formatName(data) {
    return objToName(
      {
        last: data.lastName || data.last,
        first: data.firstName || data.first,
        middle: data.middleName || data.middle,
        preferred: data.preferredName || data.preferred,
        suffix: data.suffix,
      },
      DEFAULT_NAME_OPTS,
    );
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
        }
        .row-header {
          position: sticky;
          top: 0;
          z-index: 1;
        }
        .row-text {
          word-break: break-word;
          white-space: normal;
        }
        .radio {
          min-width: 40px;
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    const {
      primaryPayerAlias,
      caseName,
      caseOnsetDate,
      guarantorName,
      invoiceNumber,
      primaryPlanName,
      secondaryPayerAlias,
      procedures,
    } = this.model[rowIndex];

    const patientName = this.__formatName(this.patient);

    switch (columnConfig.key) {
      case 'radio':
        return html`
          <neb-radio-button
            class="radio"
            .value="${rowIndex}"
            .checked="${this.__selectedGroupRow === rowIndex}"
            .onSelect="${this.__handlers.selectGroup}"
          >
          </neb-radio-button>
        `;
      case 'patient':
        return html`
          <neb-text class="patient row-text">${patientName}</neb-text>
        `;
      case 'primary':
        return html`
          <neb-text class="primary row-text"
            >${primaryPayerAlias || patientName}</neb-text
          >
        `;
      case 'plan':
        return html`
          <neb-text class="plan row-text">${primaryPlanName || '-'}</neb-text>
        `;
      case 'secondary':
        return html`
          <neb-text class="secondary row-text"
            >${secondaryPayerAlias || '-'}</neb-text
          >
        `;
      case 'guarantor':
        return html`
          <neb-text class="guarantor row-text"
            >${
              guarantorName ? this.__formatName(guarantorName) : patientName
            }</neb-text
          >
        `;
      case 'case':
        return html`
          <neb-text class="case row-text"
            >${caseName ? `${caseName} ${caseOnsetDate}` : '-'}</neb-text
          >
        `;
      case 'invoice':
        return html`
          <div class="invoice">
            <neb-text> ${invoiceNumber || '-'}</neb-text>
          </div>
        `;
      case 'procedureCode':
        return procedures
          ? html`
              <div class="procedure-code">
                ${
                  procedures.map(
                    procedure => html`
                      <div class="procedure-code-item">${procedure}</div>
                    `,
                  )
                }
              </div>
            `
          : html`
              <div class="procedure-code">-</div>
            `;
      default:
        return value;
    }
  }
}

customElements.define(
  'neb-table-ledger-activity-encounters-charge-groups',
  NebTableLedgerActivityEncountersChargeGroups,
);
