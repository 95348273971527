import '../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../neb-styles/neb-styles';
import {
  CSS_BANNER_SUCCESS_COLOR,
  CSS_BANNER_SUCCESS_BORDER_COLOR,
  CSS_BANNER_SUCCESS_BACKGROUND_COLOR,
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_ERROR_BORDER_COLOR,
  CSS_ERROR_BACKGROUND_COLOR,
  CSS_BANNER_INFO_COLOR,
  CSS_BANNER_INFO_BORDER_COLOR,
  CSS_BANNER_INFO_BACKGROUND_COLOR,
  CSS_WARNING_COLOR,
  CSS_WARNING_BACKGROUND_COLOR,
  CSS_PRIMARY_NAV_WIDTH,
  CSS_FONT_WEIGHT_BOLD,
  CSS_ALERT_HOVER_BACKGROUND_COLOR,
} from '../neb-styles/neb-variables';

import { MESSAGES_TYPE } from './neb-banner-state';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  containerMessage: {
    id: 'container-message',
  },
  iconClose: {
    id: 'icon-close',
  },
  iconMessage: {
    id: 'icon-message',
  },
  linkMessage: {
    id: 'link-message',
  },
  textMessage: {
    id: 'text-message',
  },
};
const TYPE_ICON_MAP = Object.freeze({
  alert: 'neb:notification',
  close: 'neb:close',
  error: 'neb:warning',
  info: 'neb:info',
  success: 'neb:checked',
  warning: 'neb:warning',
});

const wait = delay =>
  new Promise(resolve => {
    setTimeout(resolve, delay);
  });

const DELAY_HIDE = 500;
class NebBannerMessage extends LitElement {
  static get properties() {
    return {
      delay: {
        type: Number,
      },
      index: {
        type: Number,
        reflect: true,
      },
      layout: {
        type: String,
        reflect: true,
      },
      link: {
        type: String,
      },
      linkMessage: {
        type: String,
      },
      message: {
        type: String,
      },
      messageId: {
        type: Number,
      },
      messageType: {
        type: String,
        reflect: true,
        attribute: 'type',
      },
      patientId: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.delay = 0;
    this.layout = '';
    this.link = '';
    this.linkMessage = '';
    this.message = '';
    this.messageId = -1;
    this.messageType = MESSAGES_TYPE.INFO;
    this.patientId = '';

    this.onClick = _bannerMessageEl => {};

    this.onClose = _messageId => {};

    this.onBannerClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => this.onClick(this),
      close: () => this.onClose(this.messageId),
    };
  }

  show() {
    setTimeout(() => {
      this.style.setProperty('--neb-banner-message-position', `${this.index}`);
      this.setAttribute('moved-in', '');
    }, wait);
  }

  async hide(delay = DELAY_HIDE) {
    this.setAttribute('moved-out', '');
    setTimeout(() => {
      this.removeAttribute('moved-out');
    }, delay);

    await wait(delay);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          --neb-banner-message-height: 52px;
          --neb-banner-message-position: 0;

          position: fixed;
          display: block;
          width: 500px;
          height: var(--neb-banner-message-height);
          margin: 0;
          padding: 0;

          top: -120px;
          left: calc(50% - ${CSS_PRIMARY_NAV_WIDTH});

          line-height: var(--neb-banner-message-height);
          opacity: 1;
          transition: top 500ms, opacity 1000ms;
          box-shadow: none;
          border-radius: 3px;
          overflow: hidden;
        }

        :host([layout='large']) {
          left: calc(50% - 200px);
          width: 500px;
        }

        :host([layout='small']) {
          width: 100%;
          height: auto;
          left: 0;
          line-height: 20px;
        }

        :host([type='success']) {
          border: 1px solid ${CSS_BANNER_SUCCESS_BORDER_COLOR};
          background-color: ${CSS_BANNER_SUCCESS_BACKGROUND_COLOR};
          color: ${CSS_BANNER_SUCCESS_COLOR};
          line-height: normal;
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }

        :host([type='error']) {
          border: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
          color: ${CSS_BANNER_ERROR_COLOR};
          line-height: normal;
          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        :host([type='info']) {
          border: 1px solid ${CSS_BANNER_INFO_BORDER_COLOR};
          background-color: ${CSS_BANNER_INFO_BACKGROUND_COLOR};
          color: ${CSS_BANNER_INFO_COLOR};
          fill: ${CSS_BANNER_INFO_COLOR};
          line-height: normal;
        }

        :host([type='alert']) {
          border: 1px solid ${CSS_BANNER_ERROR_BORDER_COLOR};
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
          color: ${CSS_BANNER_ERROR_COLOR};
          fill: ${CSS_BANNER_ERROR_COLOR};
          line-height: normal;
          cursor: pointer;
        }

        :host([type='alert']:hover) {
          background-color: ${CSS_ALERT_HOVER_BACKGROUND_COLOR};
        }

        :host([type='warning']) {
          border: 1px solid ${CSS_WARNING_COLOR};
          background-color: ${CSS_WARNING_BACKGROUND_COLOR};
          color: ${CSS_WARNING_COLOR};
          line-height: normal;
          fill: ${CSS_WARNING_COLOR};
        }

        :host([moved-out]) {
          opacity: 0;
        }

        :host([moved-in]) {
          top: calc(
            var(--neb-banner-message-height) *
              var(--neb-banner-message-position)
          );
        }

        .container {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 18px;
        }

        .container-message {
          justify-content: space-between;
          align-items: center;
          flex: 2;
          padding: 0 10px;
        }

        :host([layout='small']) .container-message {
          margin: auto;
          height: 50%;
        }

        :host([layout='small'][type='alert']) .container-message {
          max-width: 97ch;
        }

        :host([type='error']) .container-message {
          display: flex;
          align-items: center;
        }

        .text {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        :host([layout='small']) .text {
          -webkit-line-clamp: 1;
        }

        .icon {
          display: block;
        }

        .icon-close {
          cursor: pointer;
          width: 22px;
          height: 22px;
        }

        .icon-message {
          width: 20px;
          height: 20px;
        }

        .link-message {
          font-weight: normal;
          text-decoration: underline;
        }
      `,
    ];
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <neb-icon
          id="${ELEMENTS.iconMessage.id}"
          class="icon icon-message"
          .icon="${TYPE_ICON_MAP[this.messageType]}"
          @click="${this.__handlers.click}"
        ></neb-icon>

        <div
          id="${ELEMENTS.containerMessage.id}"
          class="container-message"
          @click="${this.__handlers.click}"
        >
          <span id="${ELEMENTS.textMessage.id}" class="text"
            >${this.message} ${this.linkMessage ? ':' : ''}</span
          >

          <a id="${ELEMENTS.linkMessage.id}" class="link" href="${this.link}"
            >${this.linkMessage}</a
          >
        </div>

        <neb-icon
          id="${ELEMENTS.iconClose.id}"
          class="icon icon-close"
          .icon="${TYPE_ICON_MAP.close}"
          @click="${this.__handlers.close}"
        ></neb-icon>
      </div>
    `;
  }
}
customElements.define('neb-banner-message', NebBannerMessage);
