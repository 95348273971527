import '../controls/neb-button-action';
import '../inputs/neb-select';
import '../inputs/neb-textfield';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../../../../../src/components/misc/neb-icon';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import { join } from 'lit/directives/join.js';

import {
  getPrimaryPayerDebits,
  createDebitModel,
  getRowBalance,
  isPrimaryOwedCellDisabled,
  isAllocatedCellDisabled,
  getPatientDebits,
  rowHasPackageCreditsOrAdjustments,
  getSecondaryPayerDebits,
} from '../../../../../src/utils/allocate-charges/neb-allocate-charge-util';
import { MONTH_DAY_YEAR } from '../../../../neb-input/nebFormatUtils';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_COLOR_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
  CSS_ERROR_BACKGROUND_COLOR,
  CSS_COLOR_ERROR,
  CSS_SPACING,
  CSS_COLOR_GREY_3,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  objToName,
  centsToCurrency,
  currencyToCentsWithNegative,
  centsToCurrencyWithNegative,
} from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import * as masks from '../../../../neb-utils/masks';
import { formatPaymentNumber } from '../../../../neb-utils/neb-ledger-util';
import { mapPatientName } from '../../../../neb-utils/patient';
import * as selectors from '../../../../neb-utils/selectors';
import { uniq, uniqArrayWithObjects } from '../../../../neb-utils/utils';
import { TABS } from '../forms/neb-form-allocation-charges';
import { formatAdjustmentAmount } from '../patients/ledger/charges/neb-ledger-charges-util';
import '../neb-calendar-popover';

import Table, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const RECOUP = {
  title: 'Reverse Payment',
  message: 'Are you sure you want to reverse this allocation?',
  confirmText: 'Yes',
};

export const REMOVE_RECOUP_HISTORY = {
  title: 'Remove Reversal History',
  message: 'Removing the reversal will remove the history permanently',
  confirmText: 'Yes',
};

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  checkboxes: { selector: '[id^=checkbox-]' },
  patientCells: { selector: '[id^=patient-cell-]' },
  validationCells: { selector: '[id^=validation-cell-]' },
  payerCells: { selector: '[id^=payer-cell-]' },
  secondaryPayerCells: { selector: '[id^=secondary-payer-cell-]' },
  editChargeButtons: { selector: '[id^=edit-charge-button-cell-]' },
  dosCells: { selector: '[id^=dos-cell-]' },
  procedureModCells: { selector: '[id^=proc-mod-cell-]' },
  billedCells: { selector: '[id^=billed-cell-]' },
  allowedCells: { selector: '[id^=allowed-cell-]' },
  primaryPayerCells: { selector: '[id^=primary-payer-cell-]' },
  primaryOwedCells: { selector: '[id^=primary-owed-cell-]' },
  primaryAllocatedCells: { selector: '[id^=primary-allocated-cell-]' },
  primaryPaidCells: { selector: '[id^=primary-paid-cell-]' },
  secondaryOwedCells: { selector: '[id^=secondary-owed-cell-]' },
  secondaryAllocatedCells: { selector: '[id^=secondary-allocated-cell-]' },
  secondaryPaidCells: { selector: '[id^=secondary-paid-cell-]' },
  addSecondaryButton: { selector: '[id^=add-secondary-button-]' },
  patientOwedCells: { selector: '[id^=patient-owed-cell-]' },
  patientAllocatedCells: { selector: '[id^=patient-allocated-cell-]' },
  patientPaidCells: { selector: '[id^=patient-paid-cell-]' },
  adjustmentsCells: { selector: '[id^=adjustments-cell-]' },
  balanceCells: { selector: '[id^=balance-cell-]' },
  bulkActionMenu: { id: 'bulk-action-menu' },
  checkboxHeaderLabel: { id: 'checkbox-header-label' },
  totalsFooter: { id: 'totals-footer' },
  totalsLabel: { id: 'totals-label' },
  totalsBilledLabel: { id: 'totals-billed-label' },
  totalsBilledValue: { id: 'totals-billed-value' },
  totalsAllowedLabel: { id: 'totals-allowed-label' },
  totalsAllowedValue: { id: 'totals-allowed-value' },
  totalsPayerOwedLabel: { id: 'totals-payer-owed-label' },
  totalsPayerOwedValue: { id: 'totals-payer-owed-value' },
  totalsPayerAllocLabel: { id: 'totals-payer-alloc-label' },
  totalsPayerAllocValue: { id: 'totals-payer-alloc-value' },
  totalsPayerPaidLabel: { id: 'totals-payer-paid-label' },
  totalsPayerPaidValue: { id: 'totals-payer-paid-value' },
  totalsPatientOwedLabel: { id: 'totals-patient-owed-label' },
  totalsPatientOwedValue: { id: 'totals-patient-owed-value' },
  totalsPatientAllocLabel: { id: 'totals-patient-alloc-label' },
  totalsPatientAllocValue: { id: 'totals-patient-alloc-value' },
  totalsPatientPaidLabel: { id: 'totals-patient-paid-label' },
  totalsPatientPaidValue: { id: 'totals-patient-paid-value' },
  totalsAdjustmentsLabel: { id: 'totals-adjustments-label' },
  totalsAdjustmentsValue: { id: 'totals-adjustments-value' },
  totalsBalanceLabel: { id: 'totals-balance-label' },
  totalsBalanceValue: { id: 'totals-balance-value' },
  paymentIds: {
    selector: '[id^=payment-id-]',
  },
  searchCalendarIcon: { id: 'search-calendar-icon' },
  calendarPopover: { id: 'calendar-popover' },
  blocker: { id: 'blocker' },
  noActiveSecondaryLabel: { id: 'no-active-secondary-label' },
  noAdditionalSecondariesLabel: { id: 'no-additional-secondaries-label' },
  recoupButtons: { selector: '[id^=recoup-button-]' },
  recoupedIndicators: { selector: '[id^=recouped-indicator-]' },
  removeRecoupIndicators: { selector: '[id^=remove-recoup-indicator-]' },
};

const CONFIG = [
  {
    key: 'checked',
    label: '',
    flex: css`0 0 15px`,
  },
  {
    key: 'patient',
    flex: css`3 0 275px`,
  },
  {
    key: 'payer',
    flex: css`3 0 240px`,
  },
  {
    key: 'patientOwed',
    label: 'Pt. Owed',
    flex: css`1 0 100px`,
    id: 'patient-owed',
  },
  {
    key: 'patientAllocated',
    label: 'Pt. Alloc',
    flex: css`1 0 90px`,
    id: 'patient-alloc',
  },
  {
    key: 'patientPaid',
    label: 'Pt. Paid',
    flex: css`1 0 60px`,
    id: 'patient-paid',
  },
  {
    key: 'adjustments',
    label: 'Adj.',
    flex: css`1 0 100px`,
  },
  {
    key: 'balance',
    label: 'Balance',
    flex: css`1 0 60px`,
  },
];

const HEADER_NAME = Object.freeze({
  TOTALS: 'Totals',
  PATIENT: 'Patient',
  BILLED: 'Billed + Tax',
  ALLOWED: 'Allowed',
  PAYER_OWED: 'Payer Owed',
  PAYER_ALLOC: 'Payer Alloc',
  PAYER_PAID: 'Payer Paid',
});

export class AllocationChargesTable2 extends Table {
  static get properties() {
    return {
      patientId: String,
      preallocated: Array,
      selectIndexes: Array,
      paymentDetail: Object,
      allocatableId: String,
      menuItems: Object,
      payers: Array,
      disabled: { type: Boolean, reflect: true },
      hasRCMSecondaryField: Boolean,
      hasRcmRecoupsFeatureFlag: Boolean,
      hasShowNextInsurance: Boolean,
      showAssociateLabel: {
        type: Boolean,
        reflect: true,
      },
      __totals: Object,
      __showCalendar: Boolean,
      hasOwl0DollarPaymentEnhancementsFF: Boolean,
      isPayerPayment: Boolean,
      selectedTab: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .row-header {
          padding: 0 ${CSS_SPACING};
          position: sticky;
          top: 0;
          background-color: ${CSS_COLOR_WHITE};
          z-index: 1;
        }

        .row-data {
          padding: 0 20px;
        }

        .ellipsis {
          font-weight: 300;
        }

        .content {
          height: 100%;
        }

        .cell-sub {
          display: flex;
          align-items: top;
          padding: 0px;
        }

        .cell-name {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }

        .cell:not(:last-child) {
          margin-right: 10px;
        }

        .cell-header[key='checked'] {
          width: 25px;
        }

        .cell-data[key='checked'],
        .cell-data[key='patient'] {
          height: 100%;
        }

        .dos-cell {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          cursor: pointer;
        }

        .add-secondary-button {
          color: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
          display: flex;
          margin-bottom: 12px;
        }

        .cell-data[key='patient'] {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          padding-right: 10px;
          height: 100%;
          padding-top: 10px;
        }

        .cell-data[key='adjustments'] {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          padding-right: 10px;
          height: 100%;
          padding-top: 26px;
        }

        .cell-data[key='payer'] {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          padding-right: 10px;
          height: 100%;
          padding-top: 10px;
        }

        .cell-data[key='primaryOwed'],
        .cell-data[key='primaryAllocated'] {
          height: 100%;
          padding-top: 18px;
        }

        .cell-data[key='patientAllocated'],
        .cell-data[key='patientOwed'] {
          height: 100%;
          padding-top: 26px;
        }

        .cell-data[key='patientPaid'] {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          padding-right: 5px;
          height: 100%;
          padding-top: 53px;
        }

        .cell-data[key='balance'] {
          padding-right: 5px;
          height: 100%;
          padding-top: 53px;
        }

        .cell-patient-row1 {
          white-space: nowrap;
          width: 50px;
          padding: 10px 0px 16px;
        }

        .cell-allowed {
          margin-top: -7px;
        }

        .cell-payer {
          margin-top: -13px;
        }

        .field-payer {
          padding-top: 14px;
        }
        .cell-patient {
          padding-bottom: 10px;
        }

        .checkbox-padding {
          padding-top: 53px;
        }

        :host([showAssociateLabel]) .checkbox-padding {
          padding-left: 24px;
        }

        .bottom-padding {
          padding-bottom: 20px;
        }

        .icon {
          margin-right: 6px;
          width: 20px;
          height: 20px;
          stroke-width: 1;
          fill: ${CSS_COLOR_HIGHLIGHT};
          stroke-width: 0;
        }

        .searchIcon {
          width: 16px;
          height: 13px;
          margin-left: 2px;
        }

        .recoup-icon {
          width: 16px;
          height: 16px;
          stroke-width: 1;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 2px;
          cursor: pointer;
        }

        .payment-link {
          display: flex;
          align-items: center;
        }
        .recouped-icon {
          width: 16px;
          height: 16px;
          stroke-width: 1;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 2px;
        }

        .remove-recoup-icon {
          width: 16px;
          height: 16px;
          stroke-width: 1;
          fill: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 2px;
          cursor: pointer;
        }

        .blocker {
          position: fixed;
          inset: 0;
          background-color: transparent;
        }

        @keyframes fade {
          from {
            background: rgb(192, 230, 255);
          }
          to {
            background: transparent;
          }
        }

        .item-highlight {
          animation: fade 3s;
        }

        .calendar-popover {
          translate: -131px 20px;
          position: absolute;
          left: 140px;
          top: 0;
        }

        .patient-three-column-header {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          width: 100%;
        }

        .patient-three-column {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 23px 65px auto;
          width: 100%;
        }

        .payer-three-column {
          display: grid;
          grid-template-columns: 10fr 8fr 6fr;
          width: 100%;
          grid-template-rows: 29px;
        }

        .payer-three-column-header {
          align-items: center;
          display: grid;
          grid-template-columns: 10fr 8fr 6fr;
          width: 100%;
        }

        .span-three-column {
          grid-column: span 3;
        }

        .top-padding {
          padding-top: 20px;
        }

        .top-padding-2 {
          margin-top: 0px;
        }

        .right-padding {
          padding-right: 10px;
        }

        .cell-code {
          padding-bottom: 10px;
        }

        .primary-name {
          height: 0px;
        }

        .error-box {
          background-color: ${CSS_ERROR_BACKGROUND_COLOR};
          color: ${CSS_COLOR_ERROR};
          border: 1px solid ${CSS_COLOR_ERROR};
          margin-bottom: 10px;
          height: fit-content;
        }

        .error-list {
          padding: 0px;
          margin: 5px 10px 0px 25px;
        }

        .totals {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
          background-color: ${CSS_COLOR_GREY_3};
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .margin-left-12px {
          margin-left: 12px;
        }
      `,
    ];
  }

  static createAllocation() {
    return {
      amount: 0,
      credit: {
        amount: 0,
        patientPackageId: null,
      },
    };
  }

  static createDebitSelectors() {
    return {
      createItem: () => createDebitModel(),
      children: {
        $: {
          children: {
            amount: selectors.currency(),
            allocated: selectors.currency(),
            allocations: {
              createItem: () => this.createAllocation(),
            },
          },
        },
      },
    };
  }

  static createAdjustmentsSelectors() {
    return {
      createItem: () => ({
        codeId: '',
        amount: 0,
        id: '',
      }),
      children: {
        $: {
          children: {
            amount: selectors.currencyWithNegative(),
          },
        },
      },
    };
  }

  initState() {
    super.initState();
    this.__totals = {
      billedAmount: 0,
      allowedAmount: 0,
      payerOwed: 0,
      payerAllocated: 0,
      payerPaid: 0,
      patientOwed: 0,
      patientAllocated: 0,
      patientPaid: 0,
      adjustments: 0,
      balance: 0,
    };

    this.writable = true;
    this.patientId = null;
    this.disabled = false;
    this.config = CONFIG;
    this.selectIndexes = [];
    this.paymentDetail = {};
    this.allocatableId = null;
    this.menuItems = {
      insurances: [],
      adjustments: [],
      paymentTypes: [],
    };

    this.__selectedDate = null;
    this.__showCalendar = false;

    this.hasRCMSecondaryField = false;
    this.hasShowNextInsurance = false;
    this.hasRcmRecoupsFeatureFlag = false;
    this.showAssociateLabel = false;
    this.hasOwl0DollarPaymentEnhancementsFF = false;
    this.isPayerPayment = false;
    this.selectedTab = TABS.OUTSTANDING;

    this.payers = [];

    this.onItemCheck = () => {};

    this.onChangeAllowed = () => {};

    this.onChangePatientOwed = () => {};

    this.onOpenPatientOwedPopup = () => {};

    this.onChangePatientAllocated = () => {};

    this.onChangePrimaryOwed = () => {};

    this.onChangeSecondaryOwed = () => {};

    this.onChangePrimaryAllocated = () => {};

    this.onChangeSecondaryAllocated = () => {};

    this.onOpenSecondaryOwedPopup = () => {};

    this.onAddSecondaryPayer = () => {};

    this.onOpenAdjustmentsPopup = () => {};

    this.onChangeAdjustments = () => {};

    this.onClickInvoiceLink = () => {};

    this.onClickEditChargeLink = () => {};

    this.onClickPaymentLink = () => {};

    this.onSelectPayer = () => {};

    this.onSelectAll = () => {};

    this.onDeselectAll = () => {};

    this.onSearchDate = () => {};

    this.onRecoup = () => {};

    this.onRemoveRecoupHistory = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      checkItem: e => {
        if (!this.disabled) {
          const index = Number(e.currentTarget.getAttribute('index'));
          const { checked } = e.currentTarget;
          this.onItemCheck(index, checked);
        }
      },
      clickInvoiceLink: e => {
        const rowIndex = e.currentTarget.getAttribute('index');
        const { id, invoiceId, patientId } = this.model[rowIndex];

        this.onClickInvoiceLink({ id, invoiceId, patientId, rowIndex });
      },
      clickEditChargeLink: rowIndex => {
        const { id, patientId } = this.model[rowIndex];

        this.onClickEditChargeLink({ id, patientId });
      },
      changeAllowed: e => this.onChangeAllowed(e),
      changePatientOwed: async e => {
        const { name, value, event } = e;
        const { row, rowIndex } = this.__getRowAndRowIndexFromName(name);
        const { debits } = getPatientDebits(row);

        if (debits.length > 1) {
          return;
        }

        await this.onChangePatientOwed({
          name: `${rowIndex}.patientOwed`,
          value,
          event,
        });
      },
      openPatientOwedPopup: name => this.onOpenPatientOwedPopup(name),
      searchDate: date => this.onSearchDate(date),
      openCalendar: () => {
        this.__showCalendar = true;
      },
      dateSelected: date => {
        this.__selectedDate = date;
        this.handlers.searchDate(this.__selectedDate);
        this.__showCalendar = false;
      },
      closeCalendar: () => {
        this.__showCalendar = false;
      },
      isDateSelectable: date => {
        if (!this.__selectableDatesCache) {
          this.__selectableDatesCache = new Set();
          this.model.forEach(({ dateOfService, transactionDate }) => {
            this.__selectableDatesCache.add(
              parseDate(dateOfService || transactionDate).format(
                MONTH_DAY_YEAR,
              ),
            );
          });
        }

        return this.__selectableDatesCache.has(date.format(MONTH_DAY_YEAR));
      },
      changePatientAllocated: e => this.onChangePatientAllocated(e),
      changePrimaryOwed: e => this.onChangePrimaryOwed(e),
      changeSecondaryOwed: e => this.onChangeSecondaryOwed(e),
      changePrimaryAllocated: e => this.onChangePrimaryAllocated(e),
      changeSecondaryAllocated: e => this.onChangeSecondaryAllocated(e),
      changeAdjustments: ({ name, value, event }) => {
        const { rowIndex } = this.__getRowAndRowIndexFromName(name);

        if (!this.__hasMultipleAdjustments(rowIndex)) {
          this.onChangeAdjustments({ name, value, event });
        }
      },
      openAdjustmentsPopup: name => this.onOpenAdjustmentsPopup(name),
      openSecondaryOwedPopup: name => this.onOpenSecondaryOwedPopup(name),
      addSecondaryPayer: ({ name }) => this.onAddSecondaryPayer(name),
      selectPayer: e => {
        const payerPlanId = e.currentTarget.getAttribute('payerPlanId');
        const rowIndex = e.currentTarget.getAttribute('rowIndex');
        this.onSelectPayer({ payerPlanId, rowIndex });
      },
      keydown: ({ key, name, event }) => {
        const { rowIndex } = this.__getRowAndRowIndexFromName(name);

        const {
          nonPrimaryPayerDebitIdx,
        } = this.__getNonPrimaryPayerDebitIndexFromName(name);

        if (key === 'ArrowDown' || key === 'ArrowUp') {
          event.preventDefault();
          event.stopPropagation();
        }

        switch (name) {
          case `${rowIndex}.patientOwed`:
            if (
              key === 'Enter' ||
              (this.__hasMultipleDebits(rowIndex) && key === 'click')
            ) {
              this.onOpenPatientOwedPopup(name);
            }

            if (key === 'ArrowDown' || key === 'ArrowUp') {
              this.__focusNextValidRow(key, rowIndex, 'patient-owed-cell');
            }

            break;
          case `${rowIndex}.patientAdjustment`:
            if (
              key === 'Enter' ||
              (this.__hasMultipleAdjustments(rowIndex) && key === 'click')
            ) {
              this.onOpenAdjustmentsPopup(name);
            }

            if (key === 'ArrowDown' || key === 'ArrowUp') {
              this.__focusNextValidRow(key, rowIndex, 'adjustments-cell');
            }

            break;
          case `${rowIndex}.patientAllocated`:
            if (key === 'ArrowDown' || key === 'ArrowUp') {
              this.__focusNextValidRow(key, rowIndex, 'patient-allocated-cell');
            }

            break;
          case `${rowIndex}.allowedAmount`:
            if (key === 'ArrowDown' || key === 'ArrowUp') {
              this.__focusNextValidRow(key, rowIndex, 'allowed-cell');
            }

            break;
          case `${rowIndex}.primaryOwed`:
            if (key === 'ArrowDown' || key === 'ArrowUp') {
              this.__focusNextValidPrimary(
                key,
                rowIndex,
                'primary-owed-cell',
                'secondary-owed-cell',
              );
            }

            break;
          case `${rowIndex}.primaryAllocated`:
            if (key === 'ArrowDown' || key === 'ArrowUp') {
              this.__focusNextValidPrimary(
                key,
                rowIndex,
                'primary-allocated-cell',
                'secondary-allocated-cell',
              );
            }

            break;
          case `${rowIndex}.nonPrimaryPayerDebits.${nonPrimaryPayerDebitIdx}.secondaryAllocated`:
            if (key === 'ArrowDown' || key === 'ArrowUp') {
              this.__focusNextValidSecondary(
                key,
                rowIndex,
                nonPrimaryPayerDebitIdx,
                'primary-allocated-cell',
                'secondary-allocated-cell',
              );
            }

            break;
          case `${rowIndex}.nonPrimaryPayerDebits.${nonPrimaryPayerDebitIdx}.secondaryOwed`:
            if (
              key === 'Enter' ||
              (this.__hasMultipleSecondaryPayerDebits(
                rowIndex,
                nonPrimaryPayerDebitIdx,
              ) &&
                key === 'click')
            ) {
              this.onOpenSecondaryOwedPopup(name);
            }

            if (key === 'ArrowDown' || key === 'ArrowUp') {
              this.__focusNextValidSecondary(
                key,
                rowIndex,
                nonPrimaryPayerDebitIdx,
                'primary-owed-cell',
                'secondary-owed-cell',
              );
            }
            break;
          default:
            break;
        }
      },
      getBulkActions: () => [
        {
          id: 'selectAll',
          label: 'Select All',
          onSelect: this.onSelectAll,
        },
        {
          id: 'deselectAll',
          label: 'Deselect All',
          onSelect: this.onDeselectAll,
        },
      ],
      clickPaymentLink: e => {
        const paymentId = e.currentTarget.getAttribute('paymentId');
        const value = e.currentTarget.getAttribute('value');
        const patientId = e.currentTarget.getAttribute('patientId');
        const payerPlanId = e.currentTarget.getAttribute('payerPlanId');
        this.onClickPaymentLink({
          paymentId,
          value,
          patientId,
          payerPlanId,
        });
      },
      recoup: async ({ allocationId, paymentId }) => {
        const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, RECOUP);

        if (accepted) {
          this.onRecoup({ allocationId, paymentId });
        }
      },
      removeRecoupHistory: async ({ paymentId, recoupInfo }) => {
        const accepted = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          REMOVE_RECOUP_HISTORY,
        );

        if (accepted) {
          this.onRemoveRecoupHistory({
            allocationId: recoupInfo.allocationId,
            paymentId,
            recoupId: recoupInfo.recoupId,
          });
        }
      },
    };
  }

  __getConfig() {
    return CONFIG.map(c => {
      if (c.key === 'checked') {
        return {
          ...c,
          flex: this.showAssociateLabel ? css`0 0 90px` : css`0 0 30px`,
        };
      }

      return c;
    });
  }

  __focusNextValidRow(direction, rowIndex, id) {
    const debitsLength = this.model.length;

    if (direction === 'ArrowDown') {
      for (let i = Number(rowIndex) + 1; i < debitsLength + 1; i += 1) {
        if (i === debitsLength) i = -1;

        const element = this.shadowRoot.getElementById(`${id}-${i}`);

        if (element !== null && !element.disabled) {
          element.focus();
          element.select();

          return;
        }
      }
    } else if (direction === 'ArrowUp') {
      for (let i = Number(rowIndex) - 1; i >= -1; i--) {
        if (i === -1) i = debitsLength;

        const element = this.shadowRoot.getElementById(`${id}-${i}`);

        if (element !== null && !element.disabled) {
          element.focus();
          element.select();

          return;
        }
      }
    }
  }

  __focusNextValidPrimary(direction, rowIndex, idPrimary, idSecondary) {
    const debitsLength = this.model.length;

    if (direction === 'ArrowDown') {
      for (let i = Number(rowIndex) + 1; i < debitsLength + 1; i += 1) {
        if (
          this.model[i - 1] &&
          this.model[i - 1].nonPrimaryPayerDebits.length
        ) {
          for (
            let j = 0;
            j <= this.model[i - 1].nonPrimaryPayerDebits.length;
            j += 1
          ) {
            const secondaryElement = this.shadowRoot.getElementById(
              `${idSecondary}-${i - 1}-${j}`,
            );

            if (secondaryElement !== null && !secondaryElement.disabled) {
              secondaryElement.focus();
              secondaryElement.select();

              return;
            }
          }
        }

        if (i === debitsLength && this.model[0]) {
          const primaryElement = this.shadowRoot.getElementById(
            `${idPrimary}-${0}`,
          );

          if (primaryElement !== null && !primaryElement.disabled) {
            primaryElement.focus();
            primaryElement.select();

            return;
          }
        }

        if (i === debitsLength) i = -1;

        const primaryElement = this.shadowRoot.getElementById(
          `${idPrimary}-${i}`,
        );

        if (primaryElement !== null && !primaryElement.disabled) {
          primaryElement.focus();
          primaryElement.select();

          return;
        }
      }
    } else if (direction === 'ArrowUp') {
      for (let i = Number(rowIndex) - 1; i >= -1; i--) {
        if (this.model[i]) {
          const nonPrimaryLength = this.model[i].nonPrimaryPayerDebits.length;

          for (let j = Number(nonPrimaryLength) - 1; j >= -1; j--) {
            const secondaryElement = this.shadowRoot.getElementById(
              `${idSecondary}-${i}-${j}`,
            );

            if (secondaryElement !== null && !secondaryElement.disabled) {
              secondaryElement.focus();
              secondaryElement.select();

              return;
            }
          }
        }

        if (i === -1) i = debitsLength;

        const primaryElement = this.shadowRoot.getElementById(
          `${idPrimary}-${i}`,
        );

        if (primaryElement !== null && !primaryElement.disabled) {
          primaryElement.focus();
          primaryElement.select();

          return;
        }
      }
    }
  }

  __focusNextValidSecondary(
    direction,
    rowIndex,
    nonPrimaryPayerDebitIdx,
    idPrimary,
    idSecondary,
  ) {
    if (direction === 'ArrowDown') {
      if (this.model[rowIndex]) {
        const row = this.model[rowIndex];
        const secondaryLength = row.nonPrimaryPayerDebits.length;

        for (
          let j = Number(nonPrimaryPayerDebitIdx) + 1;
          j <= secondaryLength;
          j += 1
        ) {
          const secondaryElement = this.shadowRoot.getElementById(
            `${idSecondary}-${rowIndex}-${j}`,
          );

          if (secondaryElement !== null && !secondaryElement.disabled) {
            secondaryElement.focus();
            secondaryElement.select();

            return;
          }

          if (j === secondaryLength) {
            this.__focusNextValidField(rowIndex, idPrimary, idSecondary);

            return;
          }
        }
      }
    } else if (direction === 'ArrowUp') {
      if (this.model[rowIndex]) {
        for (let j = Number(nonPrimaryPayerDebitIdx) - 1; j >= -1; j--) {
          if (j === -1) {
            const primaryElement = this.shadowRoot.getElementById(
              `${idPrimary}-${rowIndex}`,
            );

            if (primaryElement !== null && !primaryElement.disabled) {
              primaryElement.focus();
              primaryElement.select();

              return;
            }

            this.__focusNextValidPrimary(
              'ArrowUp',
              rowIndex,
              idPrimary,
              idSecondary,
            );

            return;
          }

          const secondaryElement = this.shadowRoot.getElementById(
            `${idSecondary}-${rowIndex}-${j}`,
          );

          if (secondaryElement !== null && !secondaryElement.disabled) {
            secondaryElement.focus();
            secondaryElement.select();

            return;
          }
        }
      }
    }
  }

  __focusNextValidField(rowIndex, idPrimary, idSecondary) {
    const debitsLength = this.model.length;

    for (let i = Number(rowIndex) + 1; i < debitsLength + 1; i += 1) {
      if (i === debitsLength) i = -1;

      const primaryElement = this.shadowRoot.getElementById(
        `${idPrimary}-${i}`,
      );

      if (primaryElement !== null && !primaryElement.disabled) {
        primaryElement.focus();
        primaryElement.select();

        return;
      }

      if (this.model[i]) {
        for (
          let j = 0;
          j <= this.model[i].nonPrimaryPayerDebits.length - 1;
          j += 1
        ) {
          const secondaryElement = this.shadowRoot.getElementById(
            `${idSecondary}-${i}-${j}`,
          );

          if (secondaryElement !== null && !secondaryElement.disabled) {
            secondaryElement.focus();
            secondaryElement.select();

            return;
          }
        }
      }
    }
  }

  focusAdjustment(rowIndex) {
    this.shadowRoot.getElementById(`adjustments-cell-${rowIndex}`).focus();
  }

  focusPatientOwed(rowIndex) {
    this.shadowRoot.getElementById(`patient-owed-cell-${rowIndex}`).focus();
  }

  focusSecondaryOwed(rowIndex, nonPrimaryPayerDebitIdx) {
    this.shadowRoot
      .getElementById(
        `secondary-owed-cell-${rowIndex}-${nonPrimaryPayerDebitIdx}`,
      )
      .focus();
  }

  __getNamePrefix() {
    return this.name ? `${this.name}.` : '';
  }

  __getDestinationPathPrefix(rowIndex, entryName) {
    const prefix = this.__getNamePrefix();

    return `${prefix}${rowIndex}.${entryName}`;
  }

  __getSingleEntryDestinationPath(rowIndex, entryName, entryIndex, name) {
    const prefix = this.__getDestinationPathPrefix(rowIndex, entryName);

    return `${prefix}.${entryIndex}.${name}`;
  }

  __getNonPrimaryPayerDebitIndexFromName(name) {
    const nonPrimaryPayerDebitIdx = name.split('.')[2];

    return { nonPrimaryPayerDebitIdx };
  }

  __getRowAndRowIndexFromName(name) {
    const rowIndex = name.split('.')[0];
    const row = this.model[rowIndex];

    return { row, rowIndex };
  }

  __shouldAccountForCheckbox() {
    return (
      this.hasOwl0DollarPaymentEnhancementsFF &&
      this.isPayerPayment &&
      this.selectedTab === TABS.OUTSTANDING
    );
  }

  __isRowDisabled(rowIndex) {
    if (this.__shouldAccountForCheckbox()) {
      return this.disabled;
    }
    return this.disabled || !this.selectIndexes[rowIndex];
  }

  __renderAllowed(rowIndex) {
    const isDisabled = this.__isRowDisabled(rowIndex);

    return html`
      <div class="cell-sub">
        <neb-textfield
          id="allowed-cell-${rowIndex}"
          class="cell-allowed"
          maxlength="11"
          label=" "
          helper=" "
          name="${rowIndex}.allowedAmount"
          .value="${this.model[rowIndex].allowedAmount}"
          .error="${!!this.errors[rowIndex].allowedAmount}"
          .onKeydown="${this.handlers.keydown}"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .onChange="${this.handlers.changeAllowed}"
          ?disabled="${isDisabled}"
        ></neb-textfield>
      </div>
    `;
  }

  __renderPrimaryPayerCell(rowIndex) {
    const row = this.model[rowIndex];

    if (!row.primaryPayer) return '';

    return html`
      <span id="primary-payer-cell-${rowIndex}" class="cell-primary-payer">
        ${row.primaryPayer.alias}
      </span>
    `;
  }

  __renderSearchIcon() {
    return html`
      <neb-icon
        id="${ELEMENTS.searchCalendarIcon.id}"
        class="searchIcon"
        icon="neb:search"
        @click="${this.handlers.openCalendar}"
      ></neb-icon
      >${this.__renderCalendarPopover()}
    `;
  }

  __renderCalendarPopover() {
    return this.__showCalendar
      ? html`
          <div
            id="${ELEMENTS.blocker.id}"
            class="blocker"
            @click="${this.handlers.closeCalendar}"
          ></div>
          <neb-calendar-popover
            id="${ELEMENTS.calendarPopover.id}"
            class="calendar-popover"
            .selectedDate="${this.__selectedDate}"
            .onTodaySelected="${this.handlers.dateSelected}"
            .onDateSelected="${this.handlers.dateSelected}"
            .onClosePopover="${this.handlers.closeCalendar}"
            .isDateSelectable="${this.handlers.isDateSelectable}"
            .isPickerable="${false}"
            ?momentFlag="${true}"
            ?hideArrow="${true}"
          ></neb-calendar-popover>
        `
      : '';
  }

  __renderPrimaryOwedCell(rowIndex) {
    const row = this.model[rowIndex];

    const { debits } = getPrimaryPayerDebits(row);

    const isDisabled =
      this.__isRowDisabled(rowIndex) ||
      isPrimaryOwedCellDisabled({ row, debits });

    return html`
      <neb-textfield
        id="primary-owed-cell-${rowIndex}"
        maxlength="11"
        label=" "
        helper=" "
        name="${rowIndex}.primaryOwed"
        .value="${row.primaryOwed}"
        .error="${!!this.errors[rowIndex].primaryOwed}"
        .mask="${currency}"
        .onKeydown="${this.handlers.keydown}"
        .inputMode="${'numeric'}"
        .onChange="${this.handlers.changePrimaryOwed}"
        ?disabled="${isDisabled}"
      ></neb-textfield>
    `;
  }

  __renderPrimaryAllocatedCell(rowIndex) {
    const row = this.model[rowIndex];

    const { debits } = getPrimaryPayerDebits(row);

    const isDisabled =
      this.__isRowDisabled(rowIndex) ||
      isAllocatedCellDisabled({
        allocatableId: this.allocatableId,
        debits,
        payers: this.payers,
      });

    return html`
      <neb-textfield
        id="primary-allocated-cell-${rowIndex}"
        maxlength="11"
        label=" "
        helper=" "
        name="${rowIndex}.primaryAllocated"
        .value="${row.primaryAllocated}"
        .error="${!!this.errors[rowIndex].primaryAllocated}"
        .mask="${currency}"
        .onKeydown="${this.handlers.keydown}"
        .inputMode="${'numeric'}"
        .onChange="${this.handlers.changePrimaryAllocated}"
        ?disabled="${isDisabled}"
      ></neb-textfield>
    `;
  }

  __getPayersFromGroup(payerId) {
    if (!payerId) return [];

    const payers = this.payers || [];
    const { payerGroupId = '' } = {
      ...payers.find(({ id }) => id === payerId),
    };

    return payers.filter(({ id, payerGroupId: groupId }) =>
      payerGroupId ? groupId === payerGroupId : id === payerId,
    );
  }

  __shouldAllowReversalFromPayerOrGroup(payerId) {
    const paymentPayerPlan = this.paymentDetail?.payerPlan;
    if (!paymentPayerPlan?.id) return false;

    const payersFromGroup = this.__getPayersFromGroup(payerId);
    return payersFromGroup.some(({ id }) => id === paymentPayerPlan.id);
  }

  __getRecoupPaymentIds(recoups = [], selectedPayerId) {
    return recoups
      .filter(({ fromPayment }) => fromPayment.payerPlanId === selectedPayerId)
      .map(({ fromPayment, id, allocation }) => ({
        paymentId: fromPayment.id,
        paymentNumber: formatPaymentNumber(fromPayment),
        patientId: fromPayment.patientId,
        payerPlanId: fromPayment.payerPlanId,
        rawPaymentNumber: fromPayment.paymentNumber,
        recoupInfo: { recoupId: id, allocationId: allocation.id },
        recouped: true,
      }));
  }

  __processPaymentsWithRecoups(debits, recoups, selectedPayerId) {
    const payments = [];

    for (let i = 0; i < debits.length; i += 1) {
      const { allocations } = debits[i];

      if (this.hasRcmRecoupsFeatureFlag) {
        payments.push(...this.__getRecoupPaymentIds(recoups, selectedPayerId));
      }

      payments.push(...this.__getDebitPaymentIds(allocations));
    }

    return payments;
  }

  __renderPrimaryPaidCell(rowIndex) {
    const row = this.model[rowIndex];
    const recoups = row.recoups || [];

    const { debits } = getPrimaryPayerDebits(row);

    const selectedPayerId = row.primaryPayerId;

    let payments = this.__processPaymentsWithRecoups(
      debits,
      recoups,
      selectedPayerId,
    );

    if (payments.length) {
      payments = uniqArrayWithObjects(
        payments.sort((a, b) => a.rawPaymentNumber - b.rawPaymentNumber),
      );

      return html`
      <neb-text class="cell-sub" id="primary-paid-cell-${rowIndex}">
        ${row.primaryPaid}</br>
        ${join(
          payments.map(
            ({
              paymentId,
              paymentNumber: value,
              patientId,
              payerPlanId,
              icon,
              allocationId,
              recoupInfo,
              recouped,
            }) => {
              const allocationNotRecouped = !recoups.some(
                recoup => recoup.allocationId === allocationId,
              );

              const shouldRenderRecoupButton =
                this.hasRcmRecoupsFeatureFlag &&
                allocationNotRecouped &&
                this.paymentDetail.id !== paymentId &&
                this.__shouldAllowReversalFromPayerOrGroup(selectedPayerId);

              const payload = {
                value,
                paymentId,
                patientId,
                payerPlanId,
                icon,
                allocationId,
                shouldRenderRecoupButton,
                shouldRenderRecoupedIndicator: recouped,
                recoupInfo,
              };
              return this.__renderLink(payload);
            },
          ),
        )}
      </neb-text>
    `;
    }

    return html`
      <neb-text class="cell-sub" id="primary-paid-cell-${rowIndex}">
        ${row.primaryPaid}
      </neb-text>
    `;
  }

  __renderNoActiveSecondaryLabel() {
    return html`
      <span
        id="${ELEMENTS.noActiveSecondaryLabel.id}"
        class="span-three-column"
      >
        Secondary: No active secondary available
      </span>
    `;
  }

  __renderNoAdditionalSecondariesLabel() {
    return html`
      <span
        id="${ELEMENTS.noAdditionalSecondariesLabel.id}"
        class="span-three-column"
      >
        No Additional Secondaries
      </span>
    `;
  }

  __renderSecondarySection(rowIndex) {
    const row = this.model[rowIndex];
    const { nonPrimaryPayerDebits } = row;
    const showNoSecondaryLabel =
      !nonPrimaryPayerDebits.length && row.primaryPayerId;

    if (showNoSecondaryLabel) {
      if (this.hasShowNextInsurance) {
        return this.__renderNoAdditionalSecondariesLabel();
      }

      if (this.hasRCMSecondaryField) {
        return this.__renderNoActiveSecondaryLabel();
      }
    }

    if (!nonPrimaryPayerDebits.length) return '';

    const uniqueNonPrimaryPayerDebits = uniqArrayWithObjects(
      nonPrimaryPayerDebits,
    );

    return uniqueNonPrimaryPayerDebits.map(
      (_, cellIndex) => html`
        <div class="payer-three-column span-three-column">
          <div
            id="secondary-payer-cell-${rowIndex}-${cellIndex}"
            class="primary-name span-three-column"
          >
            <div class="cell-name">
              ${
                row.nonPrimaryPayerDebits[cellIndex].secondaryPayer
                  ? 'Secondary: '
                  : ''
              }
              <neb-text
                bold
                link
                tabIndex="-1"
                payerPlanId="${
                  nonPrimaryPayerDebits[cellIndex].secondaryPayer
                    ? nonPrimaryPayerDebits[cellIndex].payerId
                    : ''
                }"
                rowIndex="${rowIndex}"
                @click="${this.handlers.selectPayer}"
              >
                ${
                  nonPrimaryPayerDebits[cellIndex].secondaryPayer
                    ? nonPrimaryPayerDebits[cellIndex].secondaryPayer.alias
                    : ''
                }
              </neb-text>
            </div>
          </div>
          <span class="cell-sub cell-payer right-padding"
            >${this.__renderSecondaryOwedCell(rowIndex, cellIndex)}</span
          >
          <span class="cell-sub cell-payer right-padding">
            ${this.__renderSecondaryAllocatedCell(rowIndex, cellIndex)}
          </span>

          <span class="cell-sub field-payer">
            ${this.__renderSecondaryPaidCell(rowIndex, cellIndex)}
          </span>
        </div>
      `,
    );
  }

  __renderSecondaryOwedCell(rowIndex, nonPrimaryPayerDebitIdx) {
    const row = this.model[rowIndex];

    const isDisabled = this.__isRowDisabled(rowIndex);

    return html`
      <neb-textfield
        id="secondary-owed-cell-${rowIndex}-${nonPrimaryPayerDebitIdx}"
        trailingIcon="neb:expand"
        maxlength="11"
        label=" "
        helper=" "
        name="${rowIndex}.nonPrimaryPayerDebits.${
          nonPrimaryPayerDebitIdx
        }.secondaryOwed"
        .value="${
          centsToCurrency(
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].secondaryOwed,
          )
        }"
        .error="${
          !!this.errors[rowIndex].nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx]
            .secondaryOwed
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .onChange="${this.handlers.changeSecondaryOwed}"
        .onClickIcon="${this.handlers.openSecondaryOwedPopup}"
        .onKeydown="${this.handlers.keydown}"
        .onClick="${this.handlers.keydown}"
        ?disabled="${isDisabled}"
        ?readonly="${
          this.__hasMultipleSecondaryPayerDebits(
            rowIndex,
            nonPrimaryPayerDebitIdx,
          )
        }"
      ></neb-textfield>
    `;
  }

  __renderSecondaryAllocatedCell(rowIndex, nonPrimaryPayerDebitIdx) {
    const row = this.model[rowIndex];

    const nonPrimaryInsuranceId =
      row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

    const { debits } = getSecondaryPayerDebits(row, nonPrimaryInsuranceId);

    const isDisabled =
      this.__isRowDisabled(rowIndex) ||
      isAllocatedCellDisabled({
        allocatableId: this.allocatableId,
        debits,
        payers: this.payers,
      });

    return html`
      <neb-textfield
        id="secondary-allocated-cell-${rowIndex}-${nonPrimaryPayerDebitIdx}"
        maxlength="11"
        label=" "
        helper=" "
        name="${rowIndex}.nonPrimaryPayerDebits.${
          nonPrimaryPayerDebitIdx
        }.secondaryAllocated"
        .value="${
          centsToCurrency(
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx]
              .secondaryAllocated,
          )
        }"
        .error="${
          !!this.errors[rowIndex].nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx]
            .secondaryAllocated
        }"
        .mask="${currency}"
        .onKeydown="${this.handlers.keydown}"
        .inputMode="${'numeric'}"
        .onChange="${this.handlers.changeSecondaryAllocated}"
        ?disabled="${isDisabled}"
      ></neb-textfield>
    `;
  }

  __renderSecondaryPaidCell(rowIndex, nonPrimaryPayerDebitIdx) {
    const row = this.model[rowIndex];
    const recoups = row.recoups || [];

    const selectedNonPrimaryDebitInsuranceId =
      row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

    const selectedPayerId =
      row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].payerId;

    const { debits } = getSecondaryPayerDebits(
      row,
      selectedNonPrimaryDebitInsuranceId,
    );

    let payments = this.__processPaymentsWithRecoups(
      debits,
      recoups,
      selectedPayerId,
    );

    if (payments.length) {
      payments = uniqArrayWithObjects(payments);

      return html`
      <neb-text
          class="cell-sub"
          id="secondary-paid-cell-${rowIndex}-${nonPrimaryPayerDebitIdx}"
          >${centsToCurrency(
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].secondaryPaid,
          )}</br>${join(
        payments.map(
          ({
            paymentId,
            paymentNumber: value,
            patientId,
            payerPlanId,
            icon,
            allocationId,
            recoupInfo,
            recouped,
          }) => {
            const allocationNotRecouped = !recoups.some(
              recoup => recoup.allocationId === allocationId,
            );

            const shouldRenderRecoupButton =
              this.hasRcmRecoupsFeatureFlag &&
              allocationNotRecouped &&
              this.paymentDetail.id !== paymentId &&
              this.__shouldAllowReversalFromPayerOrGroup(selectedPayerId);

            return this.__renderLink({
              value,
              paymentId,
              patientId,
              payerPlanId,
              icon,
              allocationId,
              shouldRenderRecoupButton,
              shouldRenderRecoupedIndicator: recouped,
              recoupInfo,
            });
          },
        ),
      )}
      </neb-text>
    `;
    }

    return html`
      <neb-text
        class="cell-sub"
        id="secondary-paid-cell-${rowIndex}-${nonPrimaryPayerDebitIdx}"
        >${
          centsToCurrency(
            row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].secondaryPaid,
          )
        }
      </neb-text>
    `;
  }

  __renderAddSecondaryButton(rowIndex) {
    if (this.hasShowNextInsurance) {
      return '';
    }

    if (this.hasRCMSecondaryField) {
      return '';
    }

    const row = this.model[rowIndex];
    const isDisabled = this.__isRowDisabled(rowIndex);

    if (!row.primaryPayerId) return '';

    return html`
      <neb-button-action
        id="add-secondary-button-${rowIndex}"
        class="add-secondary-button"
        label="Add Secondary"
        tabindex="-1"
        name="${rowIndex}.addSecondaryButton"
        .onClick="${this.handlers.addSecondaryPayer}"
        ?disabled="${isDisabled}"
      ></neb-button-action>
    `;
  }

  __renderPatientOwedCell(rowIndex) {
    const row = this.model[rowIndex];

    const { debits } = getPatientDebits(row);

    const isDisabled = this.__isRowDisabled(rowIndex);

    return html`
      <div class="cell-sub">
        <neb-textfield
          id="patient-owed-cell-${rowIndex}"
          trailingIcon="neb:expand"
          name="${rowIndex}.patientOwed"
          label=" "
          helper=" "
          maxlength="11"
          .error="${!!this.errors[rowIndex].patientOwed}"
          .value="${row.patientOwed}"
          .mask="${currency}"
          .onChange="${this.handlers.changePatientOwed}"
          .onClickIcon="${this.handlers.openPatientOwedPopup}"
          .onKeydown="${this.handlers.keydown}"
          .onClick="${this.handlers.keydown}"
          ?disabled="${isDisabled}"
          ?readonly="${debits.length > 1}"
        ></neb-textfield>
      </div>
    `;
  }

  __renderPatientAllocatedCell(rowIndex) {
    const row = this.model[rowIndex];

    const { debits } = getPatientDebits(row);

    const isDisabled =
      this.__isRowDisabled(rowIndex) ||
      isAllocatedCellDisabled({
        allocatableId: this.allocatableId,
        debits,
        payers: this.payers,
      });

    return html`
      <div class="cell-sub">
        <neb-textfield
          id="patient-allocated-cell-${rowIndex}"
          name="${rowIndex}.patientAllocated"
          label=" "
          helper=" "
          maxlength="11"
          .error="${!!this.errors[rowIndex].patientAllocated}"
          .value="${row.patientAllocated}"
          .onKeydown="${this.handlers.keydown}"
          .mask="${currency}"
          .onChange="${this.handlers.changePatientAllocated}"
          ?disabled="${isDisabled}"
        ></neb-textfield>
      </div>
    `;
  }

  __getDebitPaymentIds(allocations = []) {
    const payments = allocations
      .filter(a => a.credit && !!a.credit.payment)
      .map(({ id: allocationId, credit: { payment } }) => ({
        paymentId: payment.id,
        paymentNumber: formatPaymentNumber(payment),
        patientId: payment.patientId,
        payerPlanId: payment.payerPlanId,
        icon: payment.paymentMethod === 'ERA' ? 'neb:receipt' : '',
        allocationId,
        rawPaymentNumber: payment.paymentNumber,
      }));

    return payments;
  }

  __renderPatientPaidCell(rowIndex) {
    const row = this.model[rowIndex];

    const { debits } = getPatientDebits(row);

    let payments = [];

    for (let i = 0; i < debits.length; i += 1) {
      const { allocations } = debits[i];

      payments.push(...this.__getDebitPaymentIds(allocations));
    }

    if (payments.length) {
      payments = uniqArrayWithObjects(payments);

      return html`
      <neb-text class="cell-sub" id="patient-paid-cell-${rowIndex}">
        ${row.patientPaid}</br>
        ${join(
          payments.map(
            ({ paymentId, paymentNumber: value, patientId }) =>
              html`
                ${
                  this.__renderLink({
                    value,
                    paymentId,
                    patientId,
                  })
                }
              `,
          ),
        )}
      </neb-text>
    `;
    }

    return html`
      <neb-text class="cell-sub" id="patient-paid-cell-${rowIndex}">
        ${row.patientPaid}
      </neb-text>
    `;
  }

  __renderLink({
    value,
    paymentId,
    patientId,
    payerPlanId,
    icon,
    allocationId,
    shouldRenderRecoupButton,
    shouldRenderRecoupedIndicator,
    recoupInfo,
  }) {
    return html`
      <div class="payment-link">
        <neb-text
          bold
          link
          id="payment-id-${paymentId}"
          paymentId="${paymentId}"
          payerPlanId="${payerPlanId}"
          tabindex="-1"
          value="${value}"
          patientId="${patientId}"
          trailingIcon="${icon}"
          @click="${this.handlers.clickPaymentLink}"
          >${value}</neb-text
        >
        ${
          shouldRenderRecoupedIndicator
            ? this.__renderRecoupedIndicator({
                paymentId,
                recoupInfo,
              })
            : ''
        }
        ${
          shouldRenderRecoupButton
            ? this.__renderRecoupButton({ paymentId, allocationId })
            : ''
        }
      </div>
    `;
  }

  __renderRecoupButton({ paymentId, allocationId }) {
    return html`
      <neb-icon
        @click="${() => this.handlers.recoup({ paymentId, allocationId })}"
        id="recoup-button-${allocationId}"
        class="recoup-icon"
        icon="neb:undo"
        title="Reverse"
      ></neb-icon>
    `;
  }

  __renderRecoupedIndicator({ paymentId, recoupInfo }) {
    return html`
      <neb-icon
        id="recouped-indicator-${paymentId}"
        class="recouped-icon"
        icon="neb:swapVerticalCircle"
        title="Reversed"
      ></neb-icon>
      <neb-icon
        id="remove-recoup-indicator-${paymentId}"
        class="remove-recoup-icon"
        icon="neb:close"
        title="Remove Reversal History"
        @click="${
          () =>
            this.handlers.removeRecoupHistory({
              paymentId,
              recoupInfo,
            })
        }"
      ></neb-icon>
    `;
  }

  __hasMultipleDebits(rowIndex) {
    const row = this.model[rowIndex];

    const { debits } = getPatientDebits(row);

    return debits.length > 1;
  }

  __hasMultipleSecondaryPayerDebits(rowIndex, nonPrimaryPayerDebitIdx) {
    const row = this.model[rowIndex];

    const selectedNonPrimaryDebitInsuranceId =
      row.nonPrimaryPayerDebits[nonPrimaryPayerDebitIdx].patientInsuranceId;

    const { debits } = getSecondaryPayerDebits(
      row,
      selectedNonPrimaryDebitInsuranceId,
    );

    return debits.length > 1;
  }

  __hasMultipleAdjustments(rowIndex) {
    const row = this.model[rowIndex];
    const { debits } = getPatientDebits(row);

    return (
      row.adjustments.length > 1 ||
      rowHasPackageCreditsOrAdjustments(row, debits)
    );
  }

  __renderAdjustmentsCell(rowIndex) {
    const { adjustments } = this.model[rowIndex];

    const totalAdjustmentAmounts = centsToCurrencyWithNegative(
      adjustments.reduce(
        (total, adjustment) =>
          total + currencyToCentsWithNegative(adjustment.amount),
        0,
      ),
    );

    const isDisabled = this.__isRowDisabled(rowIndex);

    return html`
      <div class="cell-sub">
        <neb-textfield
          id="adjustments-cell-${rowIndex}"
          name="${rowIndex}.patientAdjustment"
          label=" "
          helper=" "
          trailingIcon="neb:expand"
          maxlength="11"
          .error="${!!this.errors[rowIndex].patientAdjustment}"
          .value="${totalAdjustmentAmounts}"
          .mask="${masks.currencyNegative}"
          .inputMode="${'numeric'}"
          .onChange="${this.handlers.changeAdjustments}"
          .onClickIcon="${this.handlers.openAdjustmentsPopup}"
          .onKeydown="${this.handlers.keydown}"
          .onClick="${this.handlers.keydown}"
          ?disabled="${isDisabled}"
          ?readonly="${this.__hasMultipleAdjustments(rowIndex)}"
        ></neb-textfield>
      </div>
    `;
  }

  __renderBalanceCell(rowIndex) {
    const row = this.model[rowIndex];

    const balance = getRowBalance(row, this.paymentDetail.id, true);

    return html`
      <span id="balance-cell-${rowIndex}" class="cell-sub"> ${balance} </span>
    `;
  }

  __renderProcedureModifiers(rowIndex) {
    const modifiers = [
      this.model[rowIndex].modifier_1,
      this.model[rowIndex].modifier_2,
      this.model[rowIndex].modifier_3,
      this.model[rowIndex].modifier_4,
    ]
      .filter(m => m)
      .join(', ');

    return html`
      <span id="proc-mod-cell-${rowIndex}"
        >${this.model[rowIndex].code} ${modifiers.length ? ' - ' : ''}
        ${modifiers}
      </span>
    `;
  }

  __errorMessage(messages) {
    const uniqMessages = uniq(messages);
    return uniqMessages.map(
      m =>
        html`
          <li>${m}</li>
        `,
    );
  }

  __showErrors(rowIndex) {
    if (!this.selectIndexes[rowIndex]) return '';

    const errorList = [];

    if (this.errors[rowIndex].allowedAmount) {
      errorList.push(this.errors[rowIndex].allowedAmount);
    }

    if (this.errors[rowIndex].patientOwed) {
      errorList.push(this.errors[rowIndex].patientOwed);
    }

    if (this.errors[rowIndex].patientAllocated) {
      errorList.push(this.errors[rowIndex].patientAllocated);
    }

    if (this.errors[rowIndex].primaryAllocated) {
      errorList.push(this.errors[rowIndex].primaryAllocated);
    }

    if (this.errors[rowIndex].primaryOwed) {
      errorList.push(this.errors[rowIndex].primaryOwed);
    }

    if (this.errors[rowIndex].nonPrimaryPayerDebits) {
      this.errors[rowIndex].nonPrimaryPayerDebits.forEach(errorDebit => {
        if (errorDebit.secondaryAllocated) {
          errorList.push(errorDebit.secondaryAllocated);
        }
        if (errorDebit.secondaryOwed) errorList.push(errorDebit.secondaryOwed);
      });
    }

    return errorList.length > 0
      ? html`
          <div
            id="validation-cell-${rowIndex}"
            class="top-padding-2 span-three-column cell-code error-box"
          >
            <ul class="error-list">
              ${this.__errorMessage(errorList)}
            </ul>
          </div>
        `
      : '';
  }

  __renderPatientSection(rowIndex) {
    return html`
      <div class="patient-three-column">
        <div id="patient-cell-${rowIndex}" class="span-three-column">
          <div class="cell-name">
            ${
              this.model[rowIndex].patient &&
                objToName(mapPatientName(this.model[rowIndex].patient), {
                  reverse: true,
                  middleInitial: true,
                })
            }
          </div>
        </div>
        <div
          id="dos-cell-${rowIndex}"
          class="dos-cell cell-sub top-padding"
          index="${rowIndex}"
          @click="${this.handlers.clickInvoiceLink}"
        >
          <span
            >${
              parseDate(this.model[rowIndex].dateOfService).format('MM/DD/YYYY')
            }</span
          >
        </div>
        <span id="billed-cell-${rowIndex}" class="cell-sub top-padding"
          >${
            centsToCurrency(
              this.model[rowIndex].billedAmount +
                (this.model[rowIndex].taxAmount || 0),
            )
          }
        </span>
        ${this.__renderAllowed(rowIndex)}
        <div class="top-padding-2 span-three-column cell-code">
          ${this.__renderProcedureModifiers(rowIndex)}
          ${
            this.showAssociateLabel
              ? html`
                  <neb-button-icon
                    id="edit-charge-button-cell-${rowIndex}"
                    name="${rowIndex}"
                    icon="neb:edit"
                    class="edit-icon margin-left-12px"
                    .onClick="${this.handlers.clickEditChargeLink}"
                  ></neb-button-icon>
                `
              : ''
          }
        </div>
        ${this.__showErrors(rowIndex)}
      </div>
    `;
  }

  __renderPayerAlias(rowIndex) {
    if (this.model[rowIndex].primaryPayer) {
      return html`
        <neb-text
          id="primary-payer-cell-${rowIndex}"
          bold
          link
          tabIndex="-1"
          payerPlanId="${this.model[rowIndex].primaryPayer.id}"
          rowIndex="${rowIndex}"
          @click="${this.handlers.selectPayer}"
        >
          ${
            this.model[rowIndex].primaryPayer
              ? this.model[rowIndex].primaryPayer.alias
              : ''
          }
        </neb-text>
      `;
    }
    return html`
      <neb-text></neb-text>
    `;
  }

  __renderPayerSection(rowIndex) {
    return html`
      <div class="payer-three-column">
        <div id="payer-cell-${rowIndex}" class="span-three-column primary-name">
          <div class="cell-name">
            ${this.model[rowIndex].primaryPayer ? 'Primary: ' : ''}
            ${this.__renderPayerAlias(rowIndex)}
          </div>
        </div>
        <span class="cell-sub cell-payer right-padding"
          >${this.__renderPrimaryOwedCell(rowIndex)}</span
        >
        <span class="cell-sub cell-payer right-padding"
          >${this.__renderPrimaryAllocatedCell(rowIndex)}
        </span>
        <span class="cell-sub field-payer"
          >${this.__renderPrimaryPaidCell(rowIndex)}
        </span>
        ${this.__renderSecondarySection(rowIndex)}
        <span class="span-three-column">
          ${this.__renderAddSecondaryButton(rowIndex)}
        </span>
      </div>
    `;
  }

  renderHeaderCell(columnConfig) {
    switch (columnConfig.key) {
      case 'checked':
        return this.showAssociateLabel
          ? html`
              <div id="${ELEMENTS.checkboxHeaderLabel.id}">Associate</div>
            `
          : html`
              <neb-button-actions
                id="${ELEMENTS.bulkActionMenu.id}"
                class="ellipsis"
                align="left"
                .forceDownMenu="${true}"
                .onClick="${this.handlers.getBulkActions}"
                ?disabled="${this.disabled}"
              ></neb-button-actions>
            `;
      case 'patient':
        return html`
          <div class="patient-three-column-header">
            <div class="label">
              ${HEADER_NAME.PATIENT} ${this.__renderSearchIcon()}
            </div>
            <div class="label">${HEADER_NAME.BILLED}</div>
            <div class="label">${HEADER_NAME.ALLOWED}</div>
          </div>
        `;

      case 'payer':
        return html`
          <div class="payer-three-column-header">
            <div class="label">${HEADER_NAME.PAYER_OWED}</div>
            <div class="label">${HEADER_NAME.PAYER_ALLOC}</div>
            <div class="label">${HEADER_NAME.PAYER_PAID}</div>
          </div>
        `;
      default:
        return super.renderHeaderCell(columnConfig);
    }
  }

  // we will need errors later on
  // renderDataCell(value, columnConfig, rowIndex, name, errors) {
  renderDataCell(value, columnConfig, rowIndex, name) {
    switch (columnConfig.key) {
      case 'checked':
        return html`
          <div class="checkbox-padding">
            <neb-checkbox
              id="checkbox-${rowIndex}"
              class="cell-sub"
              index="${rowIndex}"
              name="${name}"
              tabindex="-1"
              ?checked="${this.selectIndexes[rowIndex]}"
              ?disabled="${this.disabled}"
              @click="${this.handlers.checkItem}"
            ></neb-checkbox>
          </div>
        `;
      case 'patient':
        return this.__renderPatientSection(rowIndex);

      case 'payer':
        return this.__renderPayerSection(rowIndex);

      case 'allowed':
        return this.__renderAllowedCell(rowIndex);

      case 'patientOwed':
        return this.__renderPatientOwedCell(rowIndex);

      case 'patientAllocated':
        return this.__renderPatientAllocatedCell(rowIndex);

      case 'patientPaid':
        return this.__renderPatientPaidCell(rowIndex);

      case 'adjustments':
        return this.__renderAdjustmentsCell(rowIndex);

      case 'balance':
        return this.__renderBalanceCell(rowIndex);

      default:
        return value;
    }
  }

  __renderTotalValue(total = { key: '', label: '', value: 0 }) {
    return html`
      <div>
        <div id="totals-${total.key}-label">${total.label}</div>
        <div class="text-bold" id="totals-${total.key}-value">
          ${formatAdjustmentAmount(total.value)}
        </div>
      </div>
    `;
  }

  __renderCellTotal(columnConfig) {
    const { key } = columnConfig;

    switch (key) {
      case 'checked':
        return html``;

      case 'patient':
        return html`
          <div class="patient-three-column-header label">
            <div class="text-bold">${HEADER_NAME.TOTALS}</div>
            ${
              this.__renderTotalValue({
                key: 'billed',
                label: HEADER_NAME.BILLED,
                value: this.__totals.billedAmount,
              })
            }
            ${
              this.__renderTotalValue({
                key: 'allowed',
                label: HEADER_NAME.ALLOWED,
                value: this.__totals.allowedAmount,
              })
            }
          </div>
        `;

      case 'payer':
        return html`
          <div class="payer-three-column-header label">
            ${
              this.__renderTotalValue({
                key: 'payer-owed',
                label: HEADER_NAME.PAYER_OWED,
                value: this.__totals.payerOwed,
              })
            }
            ${
              this.__renderTotalValue({
                key: 'payer-alloc',
                label: HEADER_NAME.PAYER_ALLOC,
                value: this.__totals.payerAllocated,
              })
            }
            ${
              this.__renderTotalValue({
                key: 'payer-paid',
                label: HEADER_NAME.PAYER_PAID,
                value: this.__totals.payerPaid,
              })
            }
          </div>
        `;
      default:
        return this.__renderTotalValue({
          key: columnConfig.id || key,
          label: columnConfig.label,
          value: this.__totals[columnConfig.key],
        });
    }
  }

  __calculateTotals(items) {
    const totals = {
      billedAmount: 0,
      taxAmount: 0,
      allowedAmount: 0,
      payerOwed: 0,
      payerAllocated: 0,
      payerPaid: 0,
      patientOwed: 0,
      patientAllocated: 0,
      patientPaid: 0,
      adjustments: 0,
      balance: 0,
    };

    items.forEach(item => {
      totals.billedAmount += item.billedAmount + item.taxAmount;
      totals.allowedAmount += currencyToCentsWithNegative(item.allowedAmount);

      let nonPrimaryOwed = 0;
      let nonPrimaryAllocated = 0;
      let nonPrimaryPaid = 0;

      item.nonPrimaryPayerDebits.forEach(nonPrimaryPayerDebit => {
        nonPrimaryOwed += nonPrimaryPayerDebit.secondaryOwed;
        nonPrimaryAllocated += nonPrimaryPayerDebit.secondaryAllocated;
        nonPrimaryPaid += nonPrimaryPayerDebit.secondaryPaid;
      });

      totals.payerOwed +=
        currencyToCentsWithNegative(item.primaryOwed) + nonPrimaryOwed;

      totals.payerAllocated +=
        currencyToCentsWithNegative(item.primaryAllocated) +
        nonPrimaryAllocated;

      totals.payerPaid +=
        currencyToCentsWithNegative(item.primaryPaid) + nonPrimaryPaid;

      totals.patientOwed += currencyToCentsWithNegative(item.patientOwed);
      totals.patientAllocated += currencyToCentsWithNegative(
        item.patientAllocated,
      );

      totals.patientPaid += currencyToCentsWithNegative(item.patientPaid);

      item.adjustments.forEach(itemAdjustment => {
        totals.adjustments += currencyToCentsWithNegative(
          itemAdjustment.amount,
        );
      });

      totals.balance += getRowBalance(item, this.paymentDetail.id, false);
    });

    return totals;
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__totals = this.__calculateTotals(this.model);
    }

    if (changedProps.has('showAssociateLabel')) {
      this.config = this.__getConfig();
    }

    super.update(changedProps);
  }

  renderFooter() {
    if (!this.config || !this.model.length) {
      return '';
    }

    const cells = this.config
      .filter(item => this.layout !== 'small' || item.mobile)
      .map(
        item => html`
          <div
            id="footer-${item.key}"
            class="cell cell-header"
            style="flex: ${item.flex}"
          >
            ${this.__renderCellTotal(item)}
          </div>
        `,
      );

    return html`
      <div id="${ELEMENTS.totalsFooter.id}" class="row totals">${cells}</div>
    `;
  }
}

window.customElements.define(
  'neb-table-allocation-charges-2',
  AllocationChargesTable2,
);
