import '../../../../neb-lit-components/src/components/neb-file-select';
import '../../../../neb-material-design/src/components/neb-md-linear-progress';
import '../../../../neb-lit-components/src/components/neb-info';

import { LitElement, html, css } from 'lit';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';

export const ID_ROSTER_UPLOAD_FILE_SELECT = 'roster-upload-file-select';
export const ID_TEMPLATE_LINK = 'download-link';
export const ID_PROGRESS_INDICATOR = 'progress-indicator';
export const PROGRESS_INDICATOR_TEXT = 'Your file is being processed...';
const VALID_FILE_EXTENSIONS = ['csv'];
const MAX_FILE_SIZE = 15 * 1024 * 1024;
const INVALID_FILE_MESSAGE =
  'File size must be less than 15 MB and filetype must be CSV.';
export const VALID_MIME_TYPES = [
  'text/csv',
  'csv',
  'application/csv',
  'text/comma-separated-values',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
];

export const ELEMENTS = {
  fileSelect: {
    id: ID_ROSTER_UPLOAD_FILE_SELECT,
  },
  progressIndicator: {
    id: ID_PROGRESS_INDICATOR,
  },
};

class NebPatientRosterFileUpload extends LitElement {
  static get properties() {
    return {
      onFileSelected: {
        type: Function,
      },
      touchDevice: {
        type: Boolean,
      },
      indicateProgress: {
        type: Boolean,
      },
      progressValue: {
        type: Number,
      },
    };
  }

  constructor() {
    super();
    this.indicateProgress = false;
    this.progressValue = 0;

    this.onFileSelected = () => {};

    this.__initHandlers();
  }

  __initHandlers() {
    this.__handlers = {
      selectFile: file => this.onFileSelected(file),
    };
  }

  static get styles() {
    return css`
      :host {
        background: ${CSS_COLOR_WHITE};
        padding: ${CSS_SPACING};
      }

      .upload-input {
        padding-bottom: ${CSS_SPACING};
      }

      .info {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .progress-indicator-container {
        display: flex;
        position: relative;
        width: 100%;
        border: 1px dashed ${CSS_COLOR_HIGHLIGHT};
        border-width: 1px;
        font-size: ${CSS_FONT_SIZE_BODY};
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: ${CSS_COLOR_HIGHLIGHT};
        height: 148px;
      }
    `;
  }

  render() {
    return html`
      <div class="roster-uploader">
        ${
          this.indicateProgress
            ? html`
                <div class="progress-indicator-container">
                  <neb-md-linear-progress
                    id="${ELEMENTS.progressIndicator.id}"
                    class="progress-indicator"
                    .value="${this.progressValue}"
                    .label="${PROGRESS_INDICATOR_TEXT}"
                  ></neb-md-linear-progress>
                </div>
              `
            : html`
                <neb-file-select
                  id="${ELEMENTS.fileSelect.id}"
                  .allowedExtensions="${VALID_FILE_EXTENSIONS}"
                  class="upload-input"
                  .maxFileSize="${MAX_FILE_SIZE}"
                  .validMimeTypes="${VALID_MIME_TYPES}"
                  .invalidFileMessage="${INVALID_FILE_MESSAGE}"
                  .onSelectFile="${this.__handlers.selectFile}"
                  .touchDevice="${this.touchDevice}"
                ></neb-file-select>
              `
        }
        <neb-info class="info">
          <span>${this.__renderUploadInstruction()}</span>
        </neb-info>
      </div>
    `;
  }

  __renderUploadInstruction() {
    return html`
      The patient roster upload file must be in comma separated value (CSV)
      format. A sample file can be
      <a
        id="${ID_TEMPLATE_LINK}"
        download="SamplePatientRosterUpload_v2.csv"
        href="https://neb-templates.s3.amazonaws.com/SamplePatientRosterUpload_v2.csv"
        >downloaded here</a
      >
      .
    `;
  }
}

customElements.define(
  'neb-patient-roster-file-upload',
  NebPatientRosterFileUpload,
);
