import './neb-toggle-button';

import { LitElement, html, css } from 'lit';

import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  row: {
    id: 'row',
  },
  code: {
    id: 'code',
  },
  button: {
    id: 'button',
  },
  moreIcon: {
    id: 'view-more',
  },
  description: {
    id: 'description',
  },
};

class NebIcdCodeTableRow extends LitElement {
  static get properties() {
    return {
      icd10Info: Object,
      isAdded: Boolean,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();

    this.onButtonClick = () => {};
  }

  __initState() {
    this.buttonText = '';
    this.buttonToggledText = '';
    this.icd10Info = {};
    this.isAdded = false;
    this.layout = '';
  }

  __initHandlers() {
    this.__handlers = {
      addClicked: () => {
        this.onButtonClick(this.icd10Info);
      },
      rowClicked: () => {
        if (this.layout === 'small' && !this.isAdded) {
          this.onButtonClick(this.icd10Info);
        }
      },
    };
  }

  static get styles() {
    return css`
      :host([layout='small']) .add-icd-code-header-space {
        padding: 5px;
        flex: 0 0 30px;
      }

      :host([layout='small']) .code-description-header-space {
        flex: 1;
        padding: 10px 5px;
      }

      :host([layout='small']) .icd-code {
        flex: 0 0 73px;
      }

      :host([layout='small']) .neb-table-cell {
        padding: 10px 5px;
      }

      .add-icd-code-header-space {
        flex: 0 0 205px;
        padding: 8px ${CSS_SPACING};
      }

      .icd-code {
        flex: 0 0 156px;
      }

      .result-row {
        display: flex;
        border-bottom: 1px solid rgba(151, 151, 151, 0.3);
      }

      .code-description-header-space {
        padding: 13px ${CSS_SPACING} 3px ${CSS_SPACING};
      }

      .view-icon {
        padding: 5px;
        height: 12px;
        width: 12px;
        transform: rotate(-90deg);
        fill: ${CSS_COLOR_GREY_1};
      }

      .column-spacer {
        width: 32px;
      }
    `;
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.row.id}"
        class="result-row"
        @click="${this.__handlers.rowClicked}"
      >
        <div class="add-icd-code-header-space">
          <neb-toggle-button
            id="${ELEMENTS.button.id}"
            .buttonText="${
              this.isAdded ? this.buttonToggledText : this.buttonText
            }"
            .isToggled="${this.isAdded}"
            .onClick="${this.__handlers.addClicked}"
            .layout="${this.layout}"
          ></neb-toggle-button>
        </div>
        <div
          id="${ELEMENTS.code.id}"
          class="icd-code code-description-header-space"
        >
          ${this.icd10Info.code}
        </div>
        <div
          id="${ELEMENTS.description.id}"
          class="short-description code-description-header-space"
        >
          ${this.icd10Info.shortDescription}
        </div>
        ${
          this.layout === 'small' && !this.isAdded
            ? html`
                <div class="neb-table-cell cell-view-problem">
                  <neb-icon
                    id="${ELEMENTS.moreIcon.id}"
                    class="view-icon"
                    icon="neb:chevron"
                  ></neb-icon>
                </div>
              `
            : html`
                <div class="column-spacer"></div>
              `
        }
      </div>
    `;
  }
}

customElements.define('neb-icd-code-table-row', NebIcdCodeTableRow);
