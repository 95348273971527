import ApiClient, { Method } from '../utils/api-client-utils';

const BASE_URL = 'payer-groups';
export const apiClient = new ApiClient({ microservice: 'billing' });
const BASE_HEADER = {
  'Content-Type': 'application/json',
};

const getSaveMethod = ({ id }) => (id ? Method.PUT : Method.POST);
const getSavePath = ({ id }) => `${BASE_URL}${id ? `/${id}` : ''}`;

const mapToRaw = ({ name, active, payerPlanIds: payerIds = [] }) =>
  JSON.stringify({ name, active, payerIds });

export const getPayerGroups = (
  aliasOnly = false,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    path: BASE_URL,
    headers: BASE_HEADER,
    queryParams: { aliasOnly },
    cacheKey: `${BASE_URL}?${aliasOnly}`,
    optOutLoadingIndicator,
    version: 1,
  });

export const savePayerGroup = (payerGroup, optOutLoadingIndicator = false) =>
  apiClient.makeRequest({
    method: getSaveMethod(payerGroup),
    path: getSavePath(payerGroup),
    body: mapToRaw(payerGroup),
    headers: BASE_HEADER,
    optOutLoadingIndicator,
    version: 1,
  });
