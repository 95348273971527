import '../../../neb-styles/neb-icons';

import { html, LitElement } from 'lit';

import { CSS_COLOR_GREY_1 } from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  button: {
    id: 'button',
  },
};

class NebRippleButton extends LitElement {
  static get properties() {
    return {
      disabled: {
        type: Boolean,
      },
      __focused: {
        type: Boolean,
      },
      __ripplePropagate: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.disabled = false;
    this.__ripplePropagate = false;

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      click: () => this.onClick(),
      focus: () => {
        this.__focused = true;
      },
      blur: () => {
        this.__focused = false;
      },
      mouseup: e => {
        e.preventDefault();
        this.__ripplePropagate = false;
      },
      mousedown: e => {
        e.preventDefault();
        this.__ripplePropagate = true;
      },
    };
  }

  click() {
    this.__elements.button.click();
  }

  firstUpdated() {
    this.__elements = {
      button: this.shadowRoot.getElementById(ELEMENTS.button.id),
    };
  }

  __renderStyles() {
    return html`
      <style>
        .button {
          width: 100%;
          height: 100%;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
        }

        .button:focus > .ripple::after {
          background-image: radial-gradient(
            circle,
            ${CSS_COLOR_GREY_1} 100%,
            transparent 5%
          );
          opacity: 0.2;
          transition: 0.25s;
        }

        .ripple {
          position: relative;
          overflow: hidden;
          transform: translate3d(0, 0, 0);
          z-index: 0;
          color: ${CSS_COLOR_GREY_1};
          border-radius: 50%;
          padding: 20px;
        }

        .ripple::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          pointer-events: none;
          background-image: radial-gradient(
            circle,
            ${CSS_COLOR_GREY_1} 100%,
            transparent 5%
          );
          background-repeat: no-repeat;
          background-position: 50%;
          transform: scale(10, 10);
          opacity: 0;
          transition: transform 0.4s, opacity 1s;
        }

        .ripple:active {
          background-size: 100%;
        }

        .content {
          top: 0;
          left: 0;
          position: absolute;
        }
      </style>
      ${
        this.__ripplePropagate && !this.disabled
          ? html`
              <style>
                .ripple::after {
                  transform: scale(0, 0);
                  opacity: 0.2;
                  transition: 0s;
                }
              </style>
            `
          : ''
      }
    `;
  }

  render() {
    return html`
      ${this.__renderStyles()}

      <span
        id="${ELEMENTS.button.id}"
        class="button"
        type="button"
        tabindex="0"
        @blur="${this.__handlers.blur}"
        @focus="${this.__handlers.focus}"
        @click="${this.__handlers.click}"
        @mouseup="${this.__handlers.mouseup}"
        @mousedown="${this.__handlers.mousedown}"
      >
        <div class="ripple" ?expanded="${this.__focused}">
          <div class="content"><slot name="content"></slot></div>
        </div>
      </span>
    `;
  }
}

window.customElements.define('neb-ripple-button', NebRippleButton);
