import { css, html } from 'lit';

import '../../../../packages/neb-lit-components/src/components/forms/neb-form-patient-insurance-edit';
import '../../../../packages/neb-lit-components/src/components/patients/neb-patient-form-controller';
import { fetchOne } from '../../../../packages/neb-api-client/src/patient-api-client';
import {
  getPatientInsurances,
  getAdditionalInsuranceInfo,
} from '../../../../packages/neb-api-client/src/patient-insurance-api-client';
import { getProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import { openError } from '../../../../packages/neb-dialog/neb-banner-state';
import { openDirtyPopup } from '../../../../packages/neb-popup';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  createModelNewPatientInsurance,
  mapToPatientInsuranceModel,
} from '../../../../packages/neb-utils/patientInsurance';
import { CSS_SMALL_SPACING, CSS_SPACING } from '../../../styles';
import { ADD_ONS, hasAddOn } from '../../../utils/add-ons';
import '../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';

import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';

export const LOADING_INSURANCE_INFO_ERROR =
  'Error loading insurance information';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  formEditInsurance: {
    id: 'form-edit-insurance',
  },
  formEditPatient: {
    id: 'form-edit-patient',
  },
  insuranceHeader: {
    id: 'insurance-header',
  },
  tabGroup: {
    id: 'tab-group',
  },
};

const TABS = {
  INSURANCE: {
    id: 'insurance',
    label: 'Insurance',
  },
  PATIENT: {
    id: 'patient',
    label: 'Patient',
  },
};

class NebOverlayClaimErrorInsurance extends NebOverlayClaimError {
  static get properties() {
    return {
      __patientInsurances: Array,
      __patientInsurance: Object,
      __patientModel: Object,
      __providers: Array,
      __hasAddOnCTVerify: Boolean,
      __displayForm: Boolean,
      __selectedTab: String,
      __navItems: Array,
    };
  }

  initState() {
    super.initState();

    this.widthRatio = '50/50';

    this.__patientInsurances = [];
    this.__patientInsurance = { ...createModelNewPatientInsurance() };
    this.__patientModel = {};
    this.__providers = [];
    this.__hasAddOnCTVerify = false;
    this.__displayForm = false;
    this.__navItems = [];
    this.__selectedTab = TABS.INSURANCE.id;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      saveInsurance: () => {
        this.isDirty = false;
        this.dismiss(true);
      },
      selectTab: async tab => {
        if (this.isDirty) {
          const result = await openDirtyPopup();

          if (result) {
            this.isDirty = false;
            this.__selectedTab = tab;
          }
        } else {
          this.__selectedTab = tab;
        }
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .form {
          flex: 1 0 0;
          padding: 0;
        }

        .insurance-header {
          display: flex;
          gap: ${CSS_SPACING};
          padding: ${CSS_SMALL_SPACING} ${CSS_SPACING} 0;
        }

        .insurance-title {
          font-size: 16px;
          font-weight: bold;
          text-overflow: ellipsis;
          margin-right: ${CSS_SPACING};
        }

        .insurance-header-field {
          max-width: 200px;
        }

        .text-bold {
          font-weight: bold;
        }
      `,
    ];
  }

  async firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      await this.__getEditFormData();
      this.__patientModel = await fetchOne(this.model.patient.id);
    }

    super.firstUpdated();
  }

  async __getEditFormData() {
    const insuranceId = this.model.claim.insurance.id;

    try {
      const [
        additionalInfo,
        patientInsurances,
        providers,
        hasAddOnCTVerify,
      ] = await Promise.all([
        getAdditionalInsuranceInfo(this.model.patientId, insuranceId),
        getPatientInsurances(this.model.patientId),
        getProviderUsers(),
        hasAddOn(ADD_ONS.CT_VERIFY),
      ]);

      this.__patientInsurances = patientInsurances.map(ins =>
        mapToPatientInsuranceModel(ins),
      );

      this.__providers = providers;

      this.__hasAddOnCTVerify = hasAddOnCTVerify;

      const patientInsurance = patientInsurances.find(
        ins => ins.id === insuranceId,
      );

      this.__patientInsurance = {
        ...mapToPatientInsuranceModel({
          ...patientInsurance,
          ...additionalInfo,
        }),
      };

      this.__displayForm = true;
    } catch (error) {
      this.__displayForm = false;
      store.dispatch(openError(LOADING_INSURANCE_INFO_ERROR));
    }
  }

  __getNavItems() {
    return [
      {
        id: TABS.INSURANCE.id,
        label: TABS.INSURANCE.label,
        resolver: () => this.__renderInsuranceForm(),
      },
      {
        id: TABS.PATIENT.id,
        label: TABS.PATIENT.label,
        resolver: () => this.__renderPatientForm(),
      },
    ];
  }

  __renderInsuranceForm() {
    return this.__displayForm
      ? html`
          <neb-form-patient-insurance-edit
            id="${ELEMENTS.formEditInsurance.id}"
            class="form"
            .layout="${this.layout}"
            .patientId="${this.model.patientId}"
            .patientInsurances="${this.__patientInsurances}"
            .patientInsurance="${this.__patientInsurance}"
            .hasAddOnCTVerify="${this.__hasAddOnCTVerify}"
            .providers="${this.__providers}"
            .onCancel="${this.handlers.dismiss}"
            .onSave="${this.handlers.saveInsurance}"
            .onChangeDirty="${this.handlers.dirty}"
            .claimErrorOverlayView="${true}"
          ></neb-form-patient-insurance-edit>
        `
      : '';
  }

  __renderPatientForm() {
    return html`
      <neb-patient-form-controller
        id="${ELEMENTS.formEditPatient.id}"
        class="form"
        .patient="${this.__patientModel}"
        .onSave="${this.handlers.dismiss}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .restrictedView="${true}"
      >
      </neb-patient-form-controller>
    `;
  }

  __renderInsuranceHeader() {
    return html`
      <div id="${ELEMENTS.insuranceHeader.id}" class="insurance-header">
        <div class="insurance-title">
          Update Insurance - ${this.__patientInsurance.planInfo.planName}
        </div>
        <div class="insurance-header-field">
          <div class="text-bold">Group ID</div>
          <div class="header-text">
            ${this.__patientInsurance.planInfo.groupIdentifier}
          </div>
        </div>
        <div class="insurance-header-field">
          <div class="text-bold">Insured ID</div>
          <div class="header-text">
            ${this.__patientInsurance.planInfo.memberIdentifier}
          </div>
        </div>
      </div>
      ${this.__renderTabs()}
    `;
  }

  __renderTabs() {
    this.__navItems = this.__getNavItems();

    return html`
      <neb-tab-group
        id="${ELEMENTS.tabGroup.id}"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  __renderInsuranceOrPatientForm() {
    return this.__selectedTab === TABS.INSURANCE.id
      ? this.__renderInsuranceForm()
      : this.__renderPatientForm();
  }

  renderForm() {
    return this.__displayForm
      ? html`
          ${this.__renderInsuranceHeader()}
          ${this.__renderInsuranceOrPatientForm()}
        `
      : '';
  }
}

customElements.define(
  'neb-overlay-claim-error-insurance',
  NebOverlayClaimErrorInsurance,
);
