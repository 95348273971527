import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/neb-file-select';
import '../../../packages/neb-lit-components/src/components/neb-file-preview';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/controls/neb-button-bar';

import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';
import mime from 'mime-types';
import moment from 'moment-timezone';

import { fetchPdf } from '../../../packages/neb-api-client/src/ledger-superbill-api-client';
import { getPayerPlans } from '../../../packages/neb-api-client/src/payer-plan-api-client';
import {
  openError,
  openSuccess,
} from '../../../packages/neb-dialog/neb-banner-state';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { POPOVER_POSITION } from '../../../packages/neb-lit-components/src/components/neb-date-picker';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  readDataUrlFromBlob,
  validateBlob,
  validateImageResolution,
  getMimeTypeFromDataUrl,
} from '../../../packages/neb-utils/blobProcessor';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  currency as currencyMask,
  currencyNegative as negativeCurrencyMask,
} from '../../../packages/neb-utils/masks';
import { printPdf } from '../../../packages/neb-utils/neb-pdf-print-util';
import * as selectors from '../../../packages/neb-utils/selectors';
import * as eobApiClient from '../../api-clients/eob';
import {
  EOB_SAVE_SUCCESSFUL,
  EOB_UPDATE_SUCCESSFUL,
  EOB_SAVE_ERROR,
  NO_RESULTS_FOUND_MESSAGE,
  TEXT_BIG_FILE_EOB,
  IMG_BAD_FILE_ERROR,
} from '../../utils/user-message';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  transactionDate: {
    id: 'transactionDate',
    label: 'Date',
    helperText: 'Required',
  },
  authEFT: { id: 'authEFT', label: 'Check/EFT/Auth' },
  selectPayer: { id: 'select-payer', label: 'Payer' },
  amount: { id: 'amount', label: 'EOB Amount' },
  adjustmentAmount: { id: 'adjustmentAmount', label: 'Adjustment Amount' },
  dateOfServiceFrom: {
    id: 'date-of-service-from',
    label: 'Date of Service From',
  },
  dateOfServiceTo: {
    id: 'date-of-service-to',
    label: 'Date of Service To',
  },
  fileSelect: { id: 'file-select' },
  contentPreview: {
    id: 'content-preview-image',
  },
  imagePreview: { id: 'image-preview' },
  buttonBar: {
    id: 'button-bar',
  },
  deletePdfButton: {
    id: 'button-pdf-delete',
  },
};

const DEFAULT_EOB = {
  transactionDate: moment(),
  authEFT: '',
  payerPlanId: selectors.ITEM_EMPTY,
  amount: 0,
  dosDateRangeFrom: '',
  dosDateRangeTo: '',
  adjustmentAmount: 0,
  s3key: null,
  file: null,
  deletePreviousFile: false,
};

const VALID_MIME_TYPE = ['application/pdf'];
const VALID_FILE_EXTENSIONS = [...VALID_MIME_TYPE.map(t => mime.extension(t))];
const MAX_FILE_SIZE = 7 * 1024 * 1024;
const DROP_FILE_TEXT = 'Drop a PDF file here to upload';
const SELECT_FILE_TEXT = 'select a PDF file';

export default class NebFormEob extends NebForm {
  static get properties() {
    return {
      __state: Object,
      __payerPlans: Array,
      __payerSearchValue: String,
      __payerPlanItems: Array,
      __filteredPayerPlanItems: Array,
      __fileBlob: File,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .single-column {
          width: 100%;
        }

        .textfield {
          width: 100%;
        }

        .icon {
          height: 30px;
          width: 30px;
          margin-bottom: 15px;
        }

        .grid-item {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        .file-item {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-gap: 20ch;
        }

        .container-file-upload {
          display: flex;
          flex-direction: column;

          box-sizing: border-box;
        }

        .preview {
          height: 100%;
        }

        .left {
          position: absolute;
          left: 0px;
          padding: 10px;
        }

        .input-file-upload {
          margin: 0;
        }
      `,
    ];
  }

  static createModel() {
    return { ...DEFAULT_EOB };
  }

  createSelectors() {
    return {
      children: {
        transactionDate: {
          ignorePristine: true,
          validators: [
            {
              error: 'Required',
              validate: v => v,
            },
          ],
          format: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
          unformat: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
        },
        authEFT: [isRequired()],
        payerPlanId: selectors.select(
          this.__filteredPayerPlanItems,
          selectors.ITEM_EMPTY,
          {
            validateRaw: true,
            validators: [isRequired()],
          },
        ),
        amount: selectors.currencyWithNegative(),
        dosDateRangeFrom: {
          validators: [
            {
              error: 'Required',
              validate: v => v || (!v && !this.state.dosDateRangeTo),
            },
          ],
          format: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
          unformat: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
        },
        dosDateRangeTo: {
          validators: [
            {
              error: 'Required',
              validate: v => v || (!v && !this.state.dosDateRangeFrom),
            },
          ],
          format: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
          unformat: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
        },
        adjustmentAmount: selectors.currency(),
      },
    };
  }

  initState() {
    super.initState();

    this.__payerPlans = [];
    this.__payerSearchValue = '';
    this.__filteredPayerPlanItems = [];
    this.__fileBlob = null;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeDate: e =>
        this.formService.apply(e.name, e.value ? e.value.toISOString() : ''),
      changePayer: e => {
        if (e.value) {
          return this.formService.apply(e.name, e.value);
        }

        return this.formService.apply(e.name, selectors.ITEM_EMPTY);
      },
      transactionDateSelectable: date => date <= parseDate().startOf('day'),
      transactionDateChanged: (date = null) => {
        this.formService.apply(
          'transactionDate',
          date
            ? parseDate(date)
                .startOf('day')
                .toISOString()
            : date,
        );
      },
      dateOfServiceFromChanged: (date = null) => {
        this.formService.apply(
          'dosDateRangeFrom',
          date
            ? parseDate(date)
                .startOf('day')
                .toISOString()
            : date,
        );

        this.__updateDateOfServiceToSelectable();
      },
      dateOfServiceToChanged: (date = null) => {
        this.formService.apply(
          'dosDateRangeTo',
          date
            ? parseDate(date)
                .startOf('day')
                .toISOString()
            : date,
        );

        this.__updateDateOfServiceFromSelectable();
      },
      searchPayer: e => {
        const searchTerms = e.value
          .toLowerCase()
          .trim()
          .split(' ');

        this.__filteredPayerPlanItems = this.__payerPlanItems.filter(f =>
          searchTerms.every(v => f.label.toLowerCase().search(v) !== -1),
        );

        this.formService.apply('payerPlanId', this.state.payerPlanId);
      },
      save: async () => {
        if (this.formService.validate()) {
          const model = this.formService.build();

          if (this.__fileBlob) {
            let pdf = await readDataUrlFromBlob(this.__fileBlob);
            pdf = pdf.replace(
              `data:${getMimeTypeFromDataUrl(pdf)};base64,`,
              '',
            );

            model.file = pdf;
          }

          try {
            if (model.id) {
              await eobApiClient.updateEob(model.id, model);
              store.dispatch(openSuccess(EOB_UPDATE_SUCCESSFUL));
            } else {
              await eobApiClient.post(model);
              store.dispatch(openSuccess(EOB_SAVE_SUCCESSFUL));
            }

            this.onSave();
          } catch (e) {
            console.log(e);
            store.dispatch(openError(EOB_SAVE_ERROR));
          }
        }
      },
      invalidFile: () => this.__invalidFile(),
      printReport: () => this.__printReport(),
      deletePdfFromEob: () => {
        this.__fileBlob = null;
        this.formService.apply('s3key', null);
        this.formService.apply('deletePreviousFile', 'true');
      },
    };

    this.__checkSetTransactionDateToToday();
    this.__updateDateOfServiceFromSelectable();
    this.__updateDateOfServiceToSelectable();
  }

  async __getPayerPlans() {
    const { payerPlan } = await getPayerPlans({
      search: '',
      hideInactive: true,
    });

    this.__payerPlanItems = payerPlan.map(item => ({
      data: item,
      label: `(${item.alias}) ${item.payerName}`,
    }));

    this.__filteredPayerPlanItems = this.__payerPlanItems;
  }

  updated(changedProps) {
    super.updated(changedProps);
  }

  async load() {
    await this.__getPayerPlans();
  }

  __checkSetTransactionDateToToday() {
    if (this.state.transactionDate) {
      this.formService.apply(
        'transactionDate',
        parseDate()
          .startOf('day')
          .toISOString(),
      );
    }
  }

  __updateDateOfServiceFromSelectable() {
    this.handlers.dateOfServiceFromSelectable = date =>
      (!this.state.dosDateRangeTo ||
        date <= parseDate(this.state.dosDateRangeTo).startOf('day')) &&
      date <= parseDate().startOf('day');
  }

  __updateDateOfServiceToSelectable() {
    this.handlers.dateOfServiceToSelectable = date =>
      (!this.state.dosDateRangeFrom ||
        date >= parseDate(this.state.dosDateRangeFrom).startOf('day')) &&
      date <= parseDate().startOf('day');
  }

  __computeTransactionDate() {
    return this.state.transactionDate
      ? parseDate(this.state.transactionDate).startOf('day')
      : null;
  }

  __computeDateOfServiceTo() {
    const { dosDateRangeTo } = this.state;
    return dosDateRangeTo ? parseDate(dosDateRangeTo).startOf('day') : null;
  }

  __computeDateOfServiceFrom() {
    const { dosDateRangeFrom } = this.state;
    return dosDateRangeFrom ? parseDate(dosDateRangeFrom).startOf('day') : null;
  }

  __renderDateOfServiceFromPicker() {
    return html`
      <neb-date-picker
        id="${ELEMENTS.dateOfServiceFrom.id}"
        class="single-column"
        name="date-of-service-from"
        label="${ELEMENTS.dateOfServiceFrom.label}"
        .selectedDate="${this.__computeDateOfServiceFrom()}"
        .onClick="${this.handlers.dateOfServiceFromChanged}"
        .onClear="${this.handlers.dateOfServiceFromChanged}"
        .isDateSelectable="${this.handlers.dateOfServiceFromSelectable}"
        .invalidText="${this.errors.dosDateRangeFrom}"
        helperText=" "
        placeholder="Select Date"
        .manualPopoverPosition="${POPOVER_POSITION.BOTTOM}"
        ?invalid="${this.errors.dosDateRangeFrom}"
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderDateOfServiceToPicker() {
    return html`
      <neb-date-picker
        id="${ELEMENTS.dateOfServiceTo.id}"
        class="single-column"
        name="date-of-service-to"
        label="${ELEMENTS.dateOfServiceTo.label}"
        .selectedDate="${this.__computeDateOfServiceTo()}"
        .onClick="${this.handlers.dateOfServiceToChanged}"
        .onClear="${this.handlers.dateOfServiceToChanged}"
        .isDateSelectable="${this.handlers.dateOfServiceToSelectable}"
        .invalidText="${this.errors.dosDateRangeTo}"
        helperText=" "
        placeholder="Select Date"
        .manualPopoverPosition="${POPOVER_POSITION.BOTTOM}"
        ?invalid="${this.errors.dosDateRangeTo}"
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderDate() {
    return html`
      <neb-date-picker
        id="${ELEMENTS.transactionDate.id}"
        class="single-column"
        name="transactionDate"
        label="${ELEMENTS.transactionDate.label}"
        .selectedDate="${this.__computeTransactionDate()}"
        .onClick="${this.handlers.transactionDateChanged}"
        .onClear="${this.handlers.transactionDateChanged}"
        .isDateSelectable="${this.handlers.transactionDateSelectable}"
        .invalidText="${this.errors.transactionDate}"
        placeholder="Select Date"
        .manualPopoverPosition="${POPOVER_POSITION.BOTTOM}"
        ?invalid="${this.errors.transactionDate}"
        helperText="Required"
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderCheck() {
    return html`
      <neb-textfield
        id="${ELEMENTS.authEFT.id}"
        name="authEFT"
        maxLength="45"
        class="textfield"
        label="${ELEMENTS.authEFT.label}"
        .onChange="${this.handlers.change}"
        .value="${this.state.authEFT}"
        .error="${this.errors.authEFT}"
        required
        helper="Required"
        ?invalid="${!!this.errors.authEFT}"
      ></neb-textfield>
    `;
  }

  __renderPayer() {
    return html`
      <neb-select-search
        id="${ELEMENTS.selectPayer.id}"
        name="payerPlanId"
        class="grid-item"
        label="${ELEMENTS.selectPayer.label}"
        helper="Required"
        .error="${this.errors.payerPlanId}"
        search="${this.__payerSearchValue}"
        .emptyMessage="${NO_RESULTS_FOUND_MESSAGE}"
        .items="${this.__filteredPayerPlanItems}"
        .value="${this.state.payerPlanId}"
        .onSearch="${this.handlers.searchPayer}"
        .onChange="${this.handlers.changePayer}"
        showSearch
      ></neb-select-search>
    `;
  }

  __getAmountMaxLength() {
    return this.state.amount.includes('-') ? 16 : 15;
  }

  __renderAmount() {
    return html`
      <neb-textfield
        id="${ELEMENTS.amount.id}"
        class="textfield"
        label="${ELEMENTS.amount.label}"
        helper=" "
        name="amount"
        maxLength="${this.__getAmountMaxLength()}"
        .value="${this.state.amount}"
        .error="${this.errors.amount}"
        .mask="${negativeCurrencyMask}"
        .inputMode="${'numeric'}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderAdjustmentAmount() {
    return html`
      <neb-textfield
        id="${ELEMENTS.adjustmentAmount.id}"
        name="${ELEMENTS.adjustmentAmount.id}"
        class="textfield"
        label="${ELEMENTS.adjustmentAmount.label}"
        helper=" "
        maxLength="15"
        .value="${this.state.adjustmentAmount}"
        .error="${this.errors.adjustmentAmount}"
        .mask="${currencyMask}"
        .inputMode="${'numeric'}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __renderFileUpload() {
    return html`
      <div id="containerFileUpload" class="container-file-upload file-item">
        <neb-file-select
          id="${ELEMENTS.fileSelect.id}"
          class="input-file spacer"
          .onSelectFile="${this.__processFileBlobForPreview.bind(this)}"
          .touchDevice="${this.touchDevice}"
          .showPreview="${this.__fileBlob !== null}"
          .allowedExtensions="${VALID_FILE_EXTENSIONS}"
          .validMimeTypes="${VALID_MIME_TYPE}"
          .maxFileSize="${MAX_FILE_SIZE}"
          .invalidFileMessage="${TEXT_BIG_FILE_EOB}"
          .onChange="${this.handlers.change}"
          .dropFileText="${DROP_FILE_TEXT}"
          .selectFileText="${SELECT_FILE_TEXT}"
        >
          ${this.__fileBlob ? this.__renderPreview() : ''}
        </neb-file-select>
      </div>
    `;
  }

  __renderReportEobLinK() {
    return html`
      <neb-button-bar
        id="${ELEMENTS.buttonBar.id}"
        class="pad"
        .config="${this.__genActions()}"
      ></neb-button-bar>
    `;
  }

  __genActions() {
    return [
      {
        name: 'reportID',
        label: 'EOB Report',
        icon: 'receipt',
        onClick: this.handlers.printReport,
        disabled: false,
      },
    ];
  }

  async __processFileBlobForPreview(fileBlob) {
    if (
      validateBlob(fileBlob, MAX_FILE_SIZE, VALID_MIME_TYPE) &&
      (await validateImageResolution(fileBlob))
    ) {
      this.__readFile(fileBlob);
    }
  }

  __readFile(blob) {
    this.__fileBlob = blob;
    this.__dirty = true;
  }

  __renderPreview() {
    return html`
      <div id="${ELEMENTS.contentPreview.id}" class="content-container left">
        <neb-file-preview
          id="${ELEMENTS.imagePreview.id}"
          class="preview"
          .fileBlob="${this.__fileBlob}"
          .onInvalidFile="${this.handlers.invalidFile}"
        ></neb-file-preview>
      </div>
    `;
  }

  __invalidFile() {
    store.dispatch(openError(IMG_BAD_FILE_ERROR));
    this.__fileBlob = null;
  }

  __printReport() {
    return printPdf(fetchPdf(this.model.s3key));
  }

  isNewDocument() {
    return this.state.s3key === null;
  }

  __renderUploadArea() {
    return html`
      ${
        this.isNewDocument()
          ? this.__renderFileUpload()
          : this.__renderReportEobLinK()
      }
    `;
  }

  __showDeleteButton() {
    return this.__fileBlob !== null || this.state.s3key !== null;
  }

  __renderDeleteButton() {
    return this.__showDeleteButton()
      ? html`
          <div class="container-button">
            <neb-button
              id="${ELEMENTS.deletePdfButton.id}"
              class="button button-delete"
              label="Remove document"
              .role="${BUTTON_ROLE.DELETE}"
              ?disabled="${!this.__showDeleteButton()}"
              .onClick="${this.handlers.deletePdfFromEob}"
            ></neb-button>
          </div>
        `
      : html``;
  }

  renderFields() {
    return html`
      ${this.__renderDate()} ${this.__renderCheck()} ${this.__renderPayer()}
      ${this.__renderAmount()} ${this.__renderAdjustmentAmount()}
      ${this.__renderDateOfServiceFromPicker()}
      ${this.__renderDateOfServiceToPicker()} ${this.__renderUploadArea()}
      ${this.__renderDeleteButton()}
    `;
  }

  renderContent() {
    return html`
      <div id="add-eob-container" class="grid grid-2">
        ${this.renderFields()}
      </div>
    `;
  }
}
customElements.define('neb-form-eob', NebFormEob);
