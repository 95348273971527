import '../../../../neb-lit-components/src/components/neb-pagination';
import '../shared/neb-prior-encounters-list';
import './neb-encounter-charges-list-old';

import { LitElement, html } from 'lit';

import {
  FilterType,
  NebPriorEncountersDataController,
} from '../../../../../src/features/charting/shared/neb-prior-encounters-data-controller';
import { getEncounterCharges } from '../../../../neb-api-client/src/charting/encounter-charge';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';

export const ID_PRIOR_CHARGES = 'prior-charges';

export const NO_RESULT_FOUND = 'No results found.';

export const NO_PRIOR_ENCOUNTER_CHARGES =
  'There are no charges for this encounter.';

export const NO_PRIOR_ENCOUNTERS =
  'This patient does not have any prior encounters.';

export const PAGE_SIZE = 10;

export const ELEMENTS = {
  priorPagination: { id: 'prior-pagination' },
  priorList: { id: 'prior-encounters' },
  chargeList: { id: 'prior-charges' },
  noResults: { text: 'No results found.' },
  noCharges: { text: 'There are no charges for this encounter.' },
  noEncounters: { text: 'This patient does not have any prior encounters.' },
};

class NebPriorEncountersController extends LitElement {
  static get properties() {
    return {
      activeCharges: Array,
      encounterCharges: Array,
      encounter: Object,
      priorEncounterChargesModel: Array,
    };
  }

  constructor() {
    super();

    this.__initHandlers();
    this.__initModels();
    this.__initServices();

    this.onAllPriorEncounterChargesSelected = () => {};

    this.onPriorEncounterSelected = () => {};
  }

  updated(changes) {
    if (changes.has('encounterCharges')) {
      this.mapCharges(this.priorEncounterChargesModel);
    }
  }

  __initHandlers() {
    this.__handlers = {
      priorEncounterClicked: async ({ id }) => {
        await this.getPriorEncounterCharges(id);
        this.onPriorEncounterSelected(id);
        this.requestUpdate();
      },
      onAllPriorEncounterChargesSelected: allPriorEncounterCharges => {
        this.onAllPriorEncounterChargesSelected(allPriorEncounterCharges);
      },
      pageChanged: pageNumber => {
        this.__resetLists();

        return this.__dataController.applyPriorEncountersFilters({
          pageNumber,
        });
      },
    };
  }

  __initModels() {
    this.__message = NO_PRIOR_ENCOUNTERS;

    this.activeCharges = [];
    this.encounterCharges = [];
    this.encounter = null;
    this.priorEncounterChargesModel = [];
  }

  __initServices() {
    this.__dataController = new NebPriorEncountersDataController(this, {
      filterType: FilterType.CHARGE,
    });
  }

  __isChargeAdded(chargeId) {
    return !!this.encounterCharges.find(charge => charge.chargeId === chargeId);
  }

  async getPriorEncounterCharges(encounterId) {
    const data = await getEncounterCharges(encounterId);
    this.mapCharges(data);
  }

  mapCharges(charges) {
    const chargesMapped = charges.map(charge => {
      charge.modifiers = [
        charge.modifier_1 || '',
        charge.modifier_2 || '',
        charge.modifier_3 || '',
        charge.modifier_4 || '',
      ];

      charge.isEncounter = this.__isChargeAdded(charge.chargeId);
      return charge;
    });
    this.priorEncounterChargesModel = chargesMapped;
  }

  __renderStyles() {
    return html`
      <style>
        :host {
          display: flex;
          flex-direction: row;
        }
        .panel-left {
          margin-right: 10px;
          flex: 0.4 0 0;
        }
        .panel-right {
          margin-left: 10px;
          flex: 0.6 0 0;
        }
        .pagination {
          flex-direction: row-reverse;
          margin: ${CSS_SPACING} 0;
          padding-right: 10px;
        }
      </style>
    `;
  }

  __renderPanelLeft() {
    return html`
      <div class="panel-left">
        <neb-prior-encounters-list
          id="${ELEMENTS.priorList.id}"
          .model="${this.__dataController.priorEncountersModel}"
          .onItemClicked="${this.__handlers.priorEncounterClicked}"
          .message="${this.__message}"
        ></neb-prior-encounters-list>
        <neb-pagination
          id="${ELEMENTS.priorPagination.id}"
          class="pagination"
          .pageCount="${this.__dataController.pageCount}"
          .currentPage="${this.__dataController.currentPage}"
          ?hidden="${this.__dataController.count === 0}"
          .onPageChanged="${this.__handlers.pageChanged}"
        ></neb-pagination>
      </div>
    `;
  }

  __renderPanelRight() {
    return html`
      <div class="panel-right">
        <neb-encounter-charges-list-old
          id="${ELEMENTS.chargeList.id}"
          class="search-prior-charges-text"
          read-only
          show-add-button
          show-add-all-button
          prior-charge
          .message="${NO_PRIOR_ENCOUNTER_CHARGES}"
          .items="${this.priorEncounterChargesModel}"
          .activeCharges="${this.activeCharges}"
          .onAllPriorEncounterChargesSelected="${
            this.__handlers.onAllPriorEncounterChargesSelected
          }"
        ></neb-encounter-charges-list-old>
      </div>
    `;
  }

  async filterPriorEncounters(searchText) {
    this.__resetLists();

    if (searchText === '') {
      this.__message = NO_PRIOR_ENCOUNTERS;
    } else {
      this.__message = NO_RESULT_FOUND;
    }
    await this.__dataController.applyPriorEncountersFilters({ searchText });

    return this.updateComplete;
  }

  __resetLists() {
    this.shadowRoot.getElementById(ELEMENTS.priorList.id).resetSelected();
    this.priorEncounterChargesModel = [];
  }

  clear() {
    this.__initModels();
  }

  render() {
    return html`
      ${this.__renderStyles()} ${this.__renderPanelLeft()}
      ${this.__renderPanelRight()}
    `;
  }
}

customElements.define(
  'neb-prior-encounters-controller',
  NebPriorEncountersController,
);
