import './neb-form-reschedule-view';
import { css, html } from 'lit';

import {
  createBannerController,
  DISPLAY_ON,
} from '../../../../packages/neb-alert/components/neb-alert-banner-controller';
import { getValidAvailability } from '../../../../packages/neb-api-client/src/availability-api-client';
import { getCalendarSummaryIntegrated } from '../../../../packages/neb-api-client/src/calendar-summary-api-client';
import { fetchRoomAvailability } from '../../../../packages/neb-api-client/src/rooms-api-client';
import { openWarning } from '../../../../packages/neb-dialog/neb-banner-state';
import { toFraction } from '../../../../packages/neb-input/util/nebDatetimeUtil';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { AvailabilityService } from '../../../../packages/neb-redux/services/availability';
import { ProviderHoursService } from '../../../../packages/neb-redux/services/provider-hours';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import {
  hasAuthorizationRemaining,
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
} from '../../../../packages/neb-utils/patientAuthorization';
import { ITEM_EMPTY } from '../../../../packages/neb-utils/selectors';
import {
  findInSelect,
  requiredSelect,
  isRequired,
} from '../../../../packages/neb-utils/simple-form-util';
import { objToHours } from '../../../../packages/neb-utils/utils';
import {
  EMPTY_RESCHEDULE_REASON,
  MAX_DURATION,
  SELECT_BY_TYPE,
} from '../../../utils/scheduling/appointments';
import { DATE_FORMAT } from '../../misc/neb-scheduling-calendar';
import { EDIT_MODE } from '../../overlays/appointments/neb-overlay-edit-reschedule-appointment';
import { ELEMENTS as ELEMENTS_BASE, NebSimpleForm } from '../neb-simple-form';

import {
  COLOR_VIEW,
  COLOR_VIEW_KEY,
  getColorViewFromLocalStorage,
} from './neb-form-appointment';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  view: { id: 'view' },
};

const getDuration = ({ hours, minutes }) =>
  Math.floor(hours * (1000 * 60 * 60) + minutes * (1000 * 60));

export const getSplitsDuration = splits =>
  splits.reduce((acc, split) => acc + getDuration(split.duration), 0);

const NEW_SPLIT_ITEM = {
  start: '',
  duration: { hours: 0, minutes: 0 },
  resourceId: ITEM_EMPTY,
  providerValid: true,
  resourceValid: true,
};

class NebFormRescheduleController extends NebSimpleForm {
  static get properties() {
    return {
      __selectedProvider: { type: Object },
      __selectedLocation: { type: Object },
      __selectedAppointmentType: { type: Object },
      __selectedDate: { type: String },
      __selectedDuration: { type: Object },
      __selectedRoom: { type: Object },
      __selectedCase: { type: Object },
      __selectedAuthorization: { type: Object },
      __appointmentNote: { type: String },
      __selectedRescheduleReason: { type: Object },
      __rescheduleNote: { type: String },
      __selectedSplits: { type: Array },
      __walkIn: { type: Boolean },
      __selectByType: { type: String },
      __preventFetching: { type: Boolean },
      __colorView: { type: String },
      __filteredAppointmentTypes: { type: Array },
      __filteredRooms: { type: Array },
      __filteredAuthorizations: { type: Array },
      __timeFrameAvailabilities: { type: Array },
      __availability: { type: Object },
      __splitAvailability: { type: Array },
      __calendarSummary: { type: Object },
      __roomAvailability: { type: Array },
      __hasEditRescheduleFF: { type: Boolean },

      mode: { type: String },
      splits: { type: Array },
      providers: { type: Array },
      locations: { type: Array },
      model: { type: Object },
      appointmentTypes: { type: Array },
      rooms: { type: Array },
      cases: { type: Array },
      authorizations: { type: Array },
      providerRequired: { type: Boolean },
      resourceRequired: { type: Boolean },
      appointmentDragAndDropSettings: { type: Object },
    };
  }

  static get styles() {
    return css`
      .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    `;
  }

  constructor() {
    super();

    this.__initServices();
  }

  createSelectors() {
    return {
      providerId: {
        stateKey: '__selectedProvider',
        toModel: v => v.data.id,
        toState: v => findInSelect(v, this.providers),
        validate: v => (this.__providerRequired ? isRequired(v) : ''),
      },
      locationId: {
        stateKey: '__selectedLocation',
        toModel: v => v.data.id,
        toState: v => findInSelect(v, this.locations),
        validate: isRequired,
      },
      appointmentTypeId: {
        stateKey: '__selectedAppointmentType',
        toModel: v => v.data.id,
        toState: v => findInSelect(v, this.appointmentTypes),
        validate: isRequired,
      },
      start: {
        stateKey: '__selectedDate',
        alwaysValidate: true,
        validate: value => {
          if (
            !this.__availability ||
            this.__preventFetching ||
            this.__selectByType === SELECT_BY_TYPE.CUSTOM
          ) {
            return '';
          }

          const start = parseDate(value);
          const formattedDate = start.format('YYYY-MM-DD');
          const dayAvailability = this.__availability[formattedDate];

          const availableDay = dayAvailability && dayAvailability.length > 0;

          if (!availableDay) {
            return 'Selected date not available. Choose a different date or select Override.';
          }

          const hours = start.hours();
          const minutes = start.minutes();

          const timeSlot = toFraction(hours, minutes, true);
          const availableSlot = dayAvailability.includes(timeSlot);

          if (!availableSlot) {
            return 'Selected time not available. Choose a different time or select Override.';
          }

          return '';
        },
      },
      duration: {
        stateKey: '__selectedDuration',
      },
      resourceId: {
        stateKey: '__selectedRoom',
        toModel: v => v.data.id,
        toState: v => findInSelect(v, this.rooms),
        validate: value => (this.__resourceRequired ? isRequired(value) : ''),
      },
      caseId: {
        stateKey: '__selectedCase',
        toModel: v => v.data.id,
        toState: v => findInSelect(v, this.cases),
      },
      patientAuthorizationId: {
        stateKey: '__selectedAuthorization',
        toModel: v => v.data.id,
        toState: v => findInSelect(v, this.authorizations),
      },
      note: { stateKey: '__appointmentNote' },
      cancelRescheduleReasonId: {
        stateKey: '__selectedRescheduleReason',
        toModel: v => v.data.id,
        toState: v =>
          findInSelect(v, this.rescheduleReasons, EMPTY_RESCHEDULE_REASON),
      },
      rescheduleReason: {
        stateKey: '__rescheduleNote',
      },
      walkIn: {
        stateKey: '__walkIn',
      },
      splits: {
        stateKey: '__selectedSplits',
        children: {
          '*': {
            children: {
              duration: {
                validate: v => (!objToHours(v) ? 'Required' : ''),
                toState: v => ({
                  hours: Math.floor(v / 3600000),
                  minutes: Math.floor((v % 3600000) / 60000),
                }),
                toModel: getDuration,
              },
              resourceId: requiredSelect(this.rooms),
            },
          },
        },
      },
    };
  }

  initState() {
    super.initState();
    this.__selectedProvider = ITEM_EMPTY;
    this.__selectedLocation = ITEM_EMPTY;
    this.__selectedAppointmentType = ITEM_EMPTY;
    this.__selectedDate = '';
    this.__selectedDuration = {};
    this.__selectedRoom = ITEM_EMPTY;
    this.__selectedCase = ITEM_EMPTY;
    this.__selectedAuthorization = ITEM_EMPTY;
    this.__appointmentNote = '';
    this.__selectedRescheduleReason = EMPTY_RESCHEDULE_REASON;
    this.__rescheduleNote = '';
    this.__selectedSplits = [];
    this.__selectByType = SELECT_BY_TYPE.DATE;
    this.__filteredRooms = [];
    this.__filteredAppointmentTypes = [];
    this.__filteredAuthorizations = [];
    this.__walkIn = false;
    this.__timeFrameAvailabilities = [];
    this.__roomAvailability = [];
    this.__calendarSummary = {};
    this.__availability = {};
    this.__splitAvailability = [];
    this.__hasEditRescheduleFF = false;

    this.mode = EDIT_MODE.RESCHEDULE;
    this.appointmentDragAndDropSettings = {};
    this.splits = [];
    this.providers = [];
    this.locations = [];
    this.appointmentTypes = [];
    this.rooms = [];
    this.cases = [];
    this.authorizations = [];
    this.rescheduleReasons = [];
    this.providerRequired = false;
    this.resourceRequired = false;
    this.confirmLabel = 'RESCHEDULE';

    this.onCancel = () => {};

    this.onChangeDirty = () => {};

    this.onSave = () => {};

    this.onToggleMode = () => {};

    this.onAddCase = () => {};

    this.onLoadCases = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: () => {
        const roomId =
          this.__selectedRoom.data.id &&
          this.__selectedRoom.data.checkInAvailable
            ? this.__selectedRoom.data.id
            : '';

        this.save({
          roomId,
          selectByType: this.__selectByType,
        });
      },
      toggleMode: () => {
        this.onToggleMode();
      },
      updateProperty: e => {
        if (e.name === 'duration') {
          this.__selectedDuration = e.value;
          return;
        }

        const name = `__${e.name}`;
        this[name] = e.value;
      },
      changeDate: date => {
        this.__selectedDate = date.toISOString();
      },
      changeMonth: e => {
        const { month, year } = e;

        const newDate = parseDate(this.__selectedDate)
          .year(year)
          .month(month - 1)
          .date(1);

        this.__selectedDate = newDate.toISOString();
      },
      changeDateType: e => {
        this.__selectByType = e.value;
      },
      addSplit: () => {
        if (this.__selectedSplits.length === 0) {
          this.__selectedSplits = [this.__getFirstSplit()];
        } else {
          this.__selectedSplits = [...this.__selectedSplits, NEW_SPLIT_ITEM];
        }
      },
      changeAppointmentRoom: e => {
        this.__selectedRoom = e.value;
      },
      changeSplit: e => {
        const [, index, key, secondKey] = e.name.split('.');
        const { value } = e;

        if (secondKey) {
          this.__selectedSplits[index][key] = {
            ...this.__selectedSplits[index][key],
            [secondKey]: value,
          };
        } else {
          this.__selectedSplits[index][key] = value;
        }

        this.__selectedSplits = [...this.__selectedSplits];
      },
      removeSplit: index => {
        this.__selectedSplits = this.__selectedSplits.filter(
          (_, i) => i !== +index,
        );
      },
      removeSplits: () => {
        this.__selectedSplits = [];
      },
      changeDuration: duration => {
        this.__selectedDuration = duration;
      },
      resizeUp: duration => {
        const newDuration = Math.min(duration, MAX_DURATION);
        const durationDelta = newDuration - this.__selectedDuration;
        const newStart = parseDate(this.__selectedDate).subtract(
          durationDelta,
          'ms',
        );

        if (newStart.isSame(this.__selectedDate, 'day')) {
          this.__selectedDuration = duration;
          this.__selectedDate = newStart.toISOString();
        }
      },
      updatePreventFetching: v => {
        this.__preventFetching = v;
      },
      updateCalendar: () => this.__fetchAvailabilityAndSummary(),
      toggleColorView: e => {
        const view = e ? COLOR_VIEW.PROVIDER : COLOR_VIEW.ROOM;
        this.__colorView = view;

        localStorage.setItem(COLOR_VIEW_KEY, view);
      },
      addCase: async () => {
        const newCase = await this.onAddCase();

        if (newCase) {
          this.__selectedCase = newCase;
          this.__selectedAuthorization = ITEM_EMPTY;
        }
      },
      authorizationWarningClick: () => this.__showAuthorizationWarning(),
      authorizationBannerClick: async () => {
        const result = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          id: this.__selectedAuthorization.data.id,
          patientId: this.model.patientId,
          patientCaseId: this.__selectedCase.data.id,
        });

        if (result) {
          this.onLoadCases();
        }
      },
    };
  }

  __initServices() {
    this.__providerHoursService = new ProviderHoursService(timeFrames => {
      this.__timeFrameAvailabilities = this.__selectedProvider.data.id
        ? timeFrames[this.__selectedProvider.data.id]
        : timeFrames.roomOnlyHours;
    });

    this.__availabilityService = new AvailabilityService(() => {});

    this.__alertBanner = createBannerController(DISPLAY_ON.scheduling);
  }

  __getFirstSplit() {
    return {
      ...NEW_SPLIT_ITEM,
      duration: {
        hours: Math.floor(this.__selectedDuration / 3600000),
        minutes: Math.floor((this.__selectedDuration % 3600000) / 60000),
      },
    };
  }

  __schedulingRequirementsFilled() {
    return (
      (!this.__providerRequired || !!this.__selectedProvider.data.id) &&
      (!this.__resourceRequired || !!this.__selectedRoom.data.id) &&
      !!this.__selectedAppointmentType.data.id &&
      !!this.__selectedLocation.data.id &&
      this.__selectByType !== SELECT_BY_TYPE.CUSTOM
    );
  }

  __handleDefaultApptType(typeIncluded) {
    if (
      (typeIncluded || !this.__selectedAppointmentType.data.active) &&
      this.__selectedAppointmentType.data.id
    ) {
      return;
    }

    const defaultType = this.__filteredAppointmentTypes.find(
      r => !!r.data.isDefault,
    );

    if (defaultType) {
      this.__selectedAppointmentType = defaultType;

      if (defaultType.data.duration) {
        this.__selectedDuration = defaultType.data.duration;
      }
    } else {
      this.__selectedAppointmentType = ITEM_EMPTY;
    }
  }

  __filterAppointmentTypes() {
    const providerId = this.__selectedProvider.data.id;
    const locationId = this.__selectedLocation.data.id;
    const appointmentTypeId = this.__selectedAppointmentType.data.id;

    if (this.appointmentTypes.length > 0) {
      if (this.__providerRequired && !this.__selectedProvider.data.id) {
        this.__filteredAppointmentTypes = [];
        return;
      }

      if (this.__resourceRequired && !this.__selectedRoom.data.id) {
        this.__filteredAppointmentTypes = [];
        return;
      }

      const {
        allTypes,
        appointmentTypes: roomAppointmentTypes = [],
      } = this.__selectedRoom.data;

      this.__filteredAppointmentTypes = this.appointmentTypes.filter(
        // eslint-disable-next-line complexity
        ({ data }) => {
          const isActive = data.active;

          const hasMatchingProvider =
            !providerId || data.providers.includes(providerId);

          const hasMatchingLocation = locationId
            ? data.locations.includes(locationId)
            : false;

          const hasMatchingAppointmentType =
            !this.__selectedRoom.data.id ||
            !!allTypes ||
            roomAppointmentTypes.includes(data.id);

          return (
            isActive &&
            hasMatchingLocation &&
            hasMatchingProvider &&
            hasMatchingAppointmentType
          );
        },
      );
    }
    const typeIncluded = this.__filteredAppointmentTypes.find(
      t => t.data.id === appointmentTypeId,
    );

    this.__handleDefaultApptType(typeIncluded);
  }

  __filterRooms() {
    const resourceId = this.__selectedRoom.data.id;
    const locationId = this.__selectedLocation.data.id;

    this.__filteredRooms = this.rooms.filter(
      ({ data }) =>
        data.active && data.scheduleAvailable && data.locationId === locationId,
    );

    if (!this.__resourceRequired) {
      this.__filteredRooms = [ITEM_EMPTY, ...this.__filteredRooms];
    }

    const roomIncluded = resourceId
      ? this.__filteredRooms.find(r => r.data.id === resourceId)
      : null;

    if (!roomIncluded) {
      this.__selectedRoom = ITEM_EMPTY;
      this.__roomAvailability = [];
    }
  }

  __showAuthorizationWarning() {
    if (
      this.__selectedAuthorization.data.id &&
      !hasAuthorizationRemaining(this.__selectedAuthorization.data)
    ) {
      if (this.layout === 'small') {
        store.dispatch(openWarning(NO_REMAINING_AUTHORIZATIONS_MESSAGE));
      } else {
        store.dispatch(
          openWarning(
            NO_REMAINING_AUTHORIZATIONS_MESSAGE,
            this.handlers.authorizationBannerClick,
          ),
        );
      }
    }
  }

  __filterAuthorizationsByCase() {
    if (this.__selectedCase?.data.id) {
      this.__filteredAuthorizations = this.authorizations.filter(
        ({ data }) => data.patientCaseId === this.__selectedCase.data.id,
      );

      if (this.model.patientAuthorizationId) {
        const foundAuth = this.__filteredAuthorizations.find(
          a => a.data.id === this.model.patientAuthorizationId,
        );

        if (!foundAuth) {
          this.__selectedAuthorization = this.__filteredAuthorizations.length
            ? this.__filteredAuthorizations[0]
            : ITEM_EMPTY;
        } else {
          this.__selectedAuthorization = foundAuth;
        }
      } else {
        this.__selectedAuthorization = this.__filteredAuthorizations.length
          ? this.__filteredAuthorizations[0]
          : ITEM_EMPTY;
      }
    } else {
      this.__selectedAuthorization = ITEM_EMPTY;
      this.__filteredAuthorizations = [...this.authorizations];
    }

    this.__showAuthorizationWarning();
  }

  __setCaseByAuthorization() {
    if (
      !this.__selectedAuthorization.data.id &&
      this.__selectedCase?.data?.patientAuthorization?.id
    ) {
      this.__selectedCase = ITEM_EMPTY;
    } else {
      this.__selectedCase =
        this.cases.find(
          c => c.data.id === this.__selectedAuthorization.data.patientCaseId,
        ) || ITEM_EMPTY;
    }

    if (this.__selectedCase.data.id) {
      this.__filteredAuthorizations = this.authorizations.filter(
        auth => auth.data.patientCaseId === this.__selectedCase.data.id,
      );
    } else {
      this.__filteredAuthorizations = [...this.authorizations];
    }

    this.__showAuthorizationWarning();
  }

  __updateSplits() {
    const newStart = parseDate(this.__selectedDate);

    if (!this.__selectedSplits.length) {
      return;
    }

    let durationSum = 0;

    this.__selectedSplits = this.__selectedSplits.map(split => {
      const splitStart = newStart.clone().add(durationSum, 'ms');
      durationSum += getDuration(split.duration);

      return {
        ...split,
        start: splitStart.toISOString(),
      };
    });

    const totalSplitDuration = getSplitsDuration(this.__selectedSplits);

    if (this.__selectedDuration !== totalSplitDuration) {
      this.__selectedDuration = totalSplitDuration;
    }
  }

  __updateLabelAndSelectByType() {
    this.confirmLabel =
      this.mode === EDIT_MODE.RESCHEDULE ? 'RESCHEDULE' : 'SAVE';

    if (this.mode === EDIT_MODE.RESCHEDULE) {
      this.__selectByType = SELECT_BY_TYPE.DATE;
    } else {
      this.__selectByType = SELECT_BY_TYPE.CUSTOM;
    }
  }

  async connectedCallback() {
    this.__hasEditRescheduleFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DH_EDIT_RESCHEDULE,
    );

    this.__colorView = getColorViewFromLocalStorage();
    super.connectedCallback();

    this.__providerHoursService.connect();
    this.__availabilityService.connect();
    this.__alertBanner.connect();
  }

  disconnectedCallback() {
    try {
      this.__providerHoursService.disconnect();
      this.__availabilityService.disconnect();
      this.__alertBanner.disconnect();
    } catch (_) {
      // noop
    }
  }

  async __fetchRoomAvailability() {
    if (!this.__selectedRoom?.data.id) {
      this.__roomAvailability = [];
      return;
    }

    const res = await fetchRoomAvailability(this.__selectedRoom.data.id);

    this.__roomAvailability = res;
  }

  __resetSplitsForLocationChange() {
    if (this.__selectedSplits.length) {
      this.__selectedSplits = [this.__getFirstSplit()];
    }
  }

  // eslint-disable-next-line complexity
  __handleDragAndDropValues() {
    const {
      dragAndDropProviderId,
      dragAndDropLocationId,
      dragAndDropResourceId,
      dragAndDropStart,
      splitRescheduleIndex,
    } = this.model;

    if (dragAndDropProviderId) {
      this.__selectedProvider =
        this.providers.find(p => p.data.id === dragAndDropProviderId) ||
        ITEM_EMPTY;
    }

    if (dragAndDropLocationId) {
      this.__selectedLocation =
        this.locations.find(l => l.data.id === dragAndDropLocationId) ||
        ITEM_EMPTY;
    }

    if (dragAndDropResourceId) {
      const newResource = this.rooms.find(
        p => p.data.id === dragAndDropResourceId,
      );

      if (splitRescheduleIndex && newResource) {
        this.__selectedSplits[splitRescheduleIndex].resourceId = newResource;
        this.__selectedSplits = [...this.__selectedSplits];
      } else {
        this.__selectedRoom = newResource || ITEM_EMPTY;
      }
    }

    if (dragAndDropStart) {
      this.__selectedDate = dragAndDropStart;
    }
  }

  firstUpdated() {
    this.__alertBanner.update(this.model.patientId);
  }

  updated(changedProps) {
    if (changedProps.has('model') && this.model) {
      this.__handleDragAndDropValues();
    }
  }

  // eslint-disable-next-line complexity
  update(changedProps) {
    if (changedProps.has('rooms')) {
      this.__filterRooms();
    }

    if (changedProps.has('__selectedLocation')) {
      this.__filterRooms();

      if (!changedProps.has('model')) {
        this.__resetSplitsForLocationChange();
      }
    }

    if (
      [
        '__selectedProvider',
        '__selectedLocation',
        '__selectedRoom',
        'appointmentTypes',
      ].some(prop => changedProps.has(prop))
    ) {
      this.__filterAppointmentTypes();
    }

    if (
      changedProps.has('authorizations') ||
      changedProps.has('__selectedCase')
    ) {
      this.__filterAuthorizationsByCase();
    } else if (changedProps.has('__selectedAuthorization')) {
      this.__setCaseByAuthorization();
    }

    if (
      changedProps.has('__selectedSplits') ||
      changedProps.has('__selectedDate')
    ) {
      this.__updateSplits();
    }

    if (changedProps.has('__selectedRoom')) {
      this.__fetchRoomAvailability();
    }

    if (
      changedProps.has('__selectedAppointmentType') ||
      changedProps.has('__roomAvailability')
    ) {
      this.__providerHoursService.update(
        this.__selectedAppointmentType.data.id,
        this.__roomAvailability,
      );
    }

    if (
      changedProps.has('__selectedAppointmentType') &&
      !this.__selectedSplits.length &&
      this.__selectedAppointmentType.data.duration &&
      !changedProps.has('model')
    ) {
      this.__selectedDuration = this.__selectedAppointmentType.data.duration;
    }

    if (changedProps.has('mode')) {
      this.__updateLabelAndSelectByType();
    }

    if (
      [
        '__selectedDate',
        '__selectedDuration',
        '__selectedProvider',
        '__selectedLocation',
        '__selectedRoom',
        '__selectedSplits',
        '__selectedAppointmentType',
        '__colorView',
        ...(!this.__preventFetching ? ['__preventFetching'] : []),
        ...(this.__selectByType !== SELECT_BY_TYPE.CUSTOM
          ? ['__selectByType']
          : []),
      ].some(prop => changedProps.has(prop))
    ) {
      this.__fetchAvailabilityAndSummary();
    }

    super.update(changedProps);
  }

  async __updateAvailability() {
    if (!this.__schedulingRequirementsFilled()) {
      this.__availability = null;
      return;
    }

    const date = parseDate(this.__selectedDate);

    try {
      this.__availability = await this.__availabilityService.updateAvailability(
        {
          appointmentId: this.model.id,
          appointmentTypeId: this.__selectedAppointmentType.data.id,
          date,
          disableFetch: this.__selectByType === SELECT_BY_TYPE.CUSTOM,
          providerId: this.__selectedProvider.data.id || null,
          resourceId: this.__selectedRoom.data.id,
          duration: this.__selectedSplits.length
            ? getDuration(this.__selectedSplits[0].duration)
            : this.__selectedDuration,
          locationId: this.locations?.length
            ? this.__selectedLocation.data.id
            : undefined,
        },
        this.__abortController.signal,
      );
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  }

  __isCalendarVisible() {
    return (
      this.__selectedDate &&
      this.__selectedLocation?.data.id &&
      (this.__selectedProvider?.data.id || this.__selectedRoom?.data.id) &&
      this.layout !== 'small'
    );
  }

  async __updateCalendarSummary() {
    if (!this.__isCalendarVisible()) return;

    const date = parseDate(this.__selectedDate).format(DATE_FORMAT);

    try {
      this.__calendarSummary = await getCalendarSummaryIntegrated({
        start: date,
        end: date,
        providerId: this.__selectedProvider.data.id,
        locationId: this.__selectedLocation.data.id,
        resourceId: this.__selectedRoom.data.id,
        signal: this.__abortController.signal,
        colorView: this.__colorView,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  }

  async __updateSplitAvailability() {
    const start = parseDate(this.__selectedDate);

    const providerId = this.__selectedProvider.data.id;
    const resourceId = this.__selectedRoom.data.id;
    const appointmentTypeId = this.__selectedAppointmentType.data.id;
    const duration = this.__selectedDuration;
    const locationId = this.locations?.length
      ? this.__selectedLocation.data.id
      : undefined;

    if (
      !this.__selectedSplits.length ||
      !this.__selectedRoom.data.id ||
      !this.__schedulingRequirementsFilled()
    ) {
      return;
    }

    try {
      const res = await getValidAvailability(
        {
          forAppointmentId: this.model.id,
          appointmentTypeId,
          start,
          duration,
          providerId,
          locationId,
          resourceId,
          splits: this.__selectedSplits.map(split => ({
            start: split.start,
            duration: getDuration(split.duration),
            resourceId: split.resourceId.data.id,
          })),
        },
        this.__abortController.signal,
      );

      res.forEach((r, index) => {
        if (index < this.__selectedSplits.length) {
          this.__selectedSplits[index].providerValid = r.providerValid;
          this.__selectedSplits[index].resourceValid = r.resourceValid;
        }
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  }

  async __fetchAvailabilityAndSummary() {
    if (this.__preventFetching) {
      return;
    }

    if (this.__abortController) {
      this.__abortController.abort();
    }

    this.__abortController = new AbortController();

    await Promise.all([
      this.__updateAvailability(),
      this.__updateSplitAvailability(),
      this.__updateCalendarSummary(),
    ]);
  }

  render() {
    return html`
      <div class="container">
        <neb-form-reschedule-view
          id="${ELEMENTS.view.id}"
          class="container"
          .selectedProvider="${this.__selectedProvider}"
          .selectedLocation="${this.__selectedLocation}"
          .selectedAppointmentType="${this.__selectedAppointmentType}"
          .selectedDate="${this.__selectedDate}"
          .selectedDuration="${this.__selectedDuration}"
          .selectedRoom="${this.__selectedRoom}"
          .selectedCase="${this.__selectedCase}"
          .selectedAuthorization="${this.__selectedAuthorization}"
          .appointmentNote="${this.__appointmentNote}"
          .selectedRescheduleReason="${this.__selectedRescheduleReason}"
          .rescheduleNote="${this.__rescheduleNote}"
          .calendarSummary="${this.__calendarSummary}"
          .rescheduleReasons="${this.rescheduleReasons}"
          .availability="${this.__availability}"
          .selectByType="${this.__selectByType}"
          .selectedSplits="${this.__selectedSplits}"
          .timeFrameAvailabilities="${this.__timeFrameAvailabilities}"
          .walkIn="${this.__walkIn}"
          .mode="${this.mode}"
          .providers="${this.providers}"
          .locations="${this.locations}"
          .appointmentTypes="${this.__filteredAppointmentTypes}"
          .rooms="${this.__filteredRooms}"
          .cases="${this.cases}"
          .appointmentDragAndDropSettings="${
            this.appointmentDragAndDropSettings
          }"
          .authorizations="${this.__filteredAuthorizations}"
          .layout="${this.layout}"
          .status="${this.model.status}"
          .patientId="${this.model.patientId}"
          .appointmentId="${this.model.id}"
          .hasEncounter="${this.model.hasEncounter}"
          .errors="${this.errors}"
          .providerRequired="${this.__providerRequired}"
          .resourceRequired="${this.__resourceRequired}"
          .colorView="${this.__colorView}"
          .preventFetching="${this.__preventFetching}"
          .onCancel="${this.handlers.cancel}"
          .onChangeDirty="${this.handlers.dirty}"
          .onSave="${this.handlers.save}"
          .onToggleMode="${this.handlers.toggleMode}"
          .onAddCase="${this.handlers.addCase}"
          .onAddSplit="${this.handlers.addSplit}"
          .onAuthorizationWarningClick="${
            this.handlers.authorizationWarningClick
          }"
          .onAuthorizationBannerClick="${
            this.handlers.authorizationBannerClick
          }"
          .onChange="${this.handlers.updateProperty}"
          .onChangeDate="${this.handlers.changeDate}"
          .onChangeDateType="${this.handlers.changeDateType}"
          .onChangeMonth="${this.handlers.changeMonth}"
          .onChangeSplit="${this.handlers.changeSplit}"
          .onRemoveSplit="${this.handlers.removeSplit}"
          .onRemoveSplits="${this.handlers.removeSplits}"
          .onChangeAppointmentRoom="${this.handlers.changeAppointmentRoom}"
          .onChangeDuration="${this.handlers.changeDuration}"
          .onResizeUp="${this.handlers.resizeUp}"
          .onUpdatePreventFetching="${this.handlers.updatePreventFetching}"
          .onToggleColorView="${this.handlers.toggleColorView}"
          .onUpdateCalendar="${this.handlers.updateCalendar}"
          .hasEditRescheduleFF="${this.__hasEditRescheduleFF}"
        ></neb-form-reschedule-view>
        ${this.renderFooter()}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-form-reschedule-controller',
  NebFormRescheduleController,
);
