import '../../encounter/neb-encounter-list-controller';

import { html, css } from 'lit';

import Overlay, { TRANSITION_SIDE } from '../neb-overlay';

export const ELEMENTS = {
  controller: { id: 'controller' },
};

class NebOverlayEncounterList extends Overlay {
  static get properties() {
    return {
      model: { type: Object },
      __filteredPatient: { type: Object },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .controller {
          width: 525px;
          height: 100%;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__filteredPatient = null;

    this.side = TRANSITION_SIDE.LEFT;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      select: ({ value }) => {
        this.handlers.dismiss(value);
      },
      updateFilteredPatient: patient => {
        this.__filteredPatient = patient;
      },
    };
  }

  __buildAppointmentTypesMap(appointmentTypes) {
    return appointmentTypes.reduce((acc, appointmentType) => {
      acc[appointmentType.id] = appointmentType;

      return acc;
    }, {});
  }

  update(changedProps) {
    if (changedProps.has('__filteredPatient') && this.model.filteredPatient) {
      this.__filteredPatient = this.model.filteredPatient;
      this.model.filteredPatient = null;
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <neb-encounter-list-controller
        id="${ELEMENTS.controller.id}"
        class="controller"
        .appointmentTypes="${
          this.__buildAppointmentTypesMap(this.model.appointmentTypes)
        }"
        .encounterId="${this.model.encounterId}"
        .layout="${this.layout}"
        .selectedDate="${this.model.selectedDate}"
        .onSelect="${this.handlers.select}"
        .filteredPatient="${this.__filteredPatient}"
        .onUpdateFilteredPatient="${this.handlers.updateFilteredPatient}"
      ></neb-encounter-list-controller>
    `;
  }
}

window.customElements.define(
  'neb-overlay-encounter-list',
  NebOverlayEncounterList,
);
