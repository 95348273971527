import ApiClient, { Method } from '../utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'payments' });

const baseUrl = 'card-reader';

export const createCardReader = reader =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reader),
  });

export const updateCardReader = reader =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${baseUrl}/${reader.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reader),
  });

export const getCardReader = async id => {
  try {
    const reader = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      path: `${baseUrl}/${id}`,
      method: Method.GET,
      headers: {
        'Content-Type': 'application/json',
      },
      cacheKey: `${id}-cardreader`,
    });
    return reader;
  } catch (err) {
    return null;
  }
};

export const getCardReaders = async (
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  try {
    const readers = await apiClient.makeRequest({
      method: Method.GET,
      path: baseUrl,
      queryParams,
      headers: {
        'Content-Type': 'application/json',
      },
      cacheKey: `${queryParams}-cardreaders`,
      optOutLoadingIndicator,
    });
    return readers;
  } catch (err) {
    return [];
  }
};

export const getActiveCardReaderCount = async () => {
  try {
    const { count } = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.GET,
      path: `${baseUrl}/activeCount`,
      headers: {
        'Content-Type': 'application/json',
      },
      cacheKey: 'count-cardreader',
    });
    return count;
  } catch (err) {
    return 0;
  }
};

export const getGeniusKeys = async (id, body) => {
  try {
    const geniusKeys = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.POST,
      path: `account/${id}/genius`,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      cacheKey: `${id}-geniuskeys`,
    });
    return geniusKeys;
  } catch (err) {
    return [];
  }
};
