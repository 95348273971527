import './neb-icon';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { openSuccess } from '../../../packages/neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import { CSS_COLOR_HIGHLIGHT } from '../../../packages/neb-styles/neb-variables';

export const TEXT_BANNER_SUCCESS = 'Online Booking Account linked';
export const TEXT_EMAIL_INVITE = 'Send Invitation Email to Patient';
const TEXT_REMOVE_ACCOUNT = 'Remove Account Link';
export const TEXT_TOASTER_EMAIL_SENT = 'Invitation email sent';
export const ELEMENTS = {
  matchingContainer: {
    id: 'container-matches',
  },
  reviewMatchesLink: {
    id: 'text-review-matching',
  },
  matchedEmail: {
    id: 'matched-email',
  },
  removeAccountLink: {
    id: 'remove-account-link',
  },
  inviteEmailLink: {
    id: 'email-link',
  },
  resetEmailLink: {
    id: 'reset-email-link',
  },
  noMatchingContainer: {
    id: 'container-no-matching',
  },
};
class NebPatientOnlineBookingLink extends LitElement {
  static get properties() {
    return {
      matches: Array,
      layout: {
        type: String,
        reflect: true,
      },
      matchedEmail: String,
      patient: Object,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.matches = [];
    this.layout = '';
    this.patient = {};
    this.matchedEmail = '';

    this.reviewMatches = () => {};

    this.onUnmatchAccount = () => {};

    this.onPatientInvited = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      reviewMatches: () => this.reviewMatches(),
      removeLink: () => {
        this.onUnmatchAccount();
      },
      invitePatient: async () => {
        const result = await openPopup(POPUP_RENDER_KEYS.INVITE_PATIENT, {
          patient: this.patient,
        });
        if (result.cancel) return;

        if (result.success) {
          store.dispatch(openSuccess(TEXT_TOASTER_EMAIL_SENT));
          this.onPatientInvited();
        }
      },
    };
  }

  get __matchState() {
    if (this.matchedEmail) return 'matched';
    if (this.matches.length) return 'matches-available';
    return 'no-match';
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .content {
          margin-top: 13px;
          display: flex;
          flex-direction: column;
          padding-left: var(--neb-panel-link-padding);
        }

        .text-container {
          display: flex;
        }

        .item {
          margin-bottom: 10px;
        }

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .link {
          color: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .text-underline {
          text-decoration: underline;
        }

        .text-email {
          font: 14px Open Sans;
          margin: 0 20px 8px 0;
        }

        .matched-container {
          display: flex;
        }

        :host([layout='small']) .matched-container {
          flex-direction: column;
          margin-bottom: 13px;
        }
      `,
    ];
  }

  __renderMatched() {
    return html`
      <div class="matched-container">
        <div id="${ELEMENTS.matchedEmail.id}" class="text-email">
          ${this.matchedEmail}
        </div>

        <div
          id="${ELEMENTS.removeAccountLink.id}"
          @click="${this.__handlers.removeLink}"
          class="link text-underline "
        >
          ${TEXT_REMOVE_ACCOUNT}
        </div>
      </div>
    `;
  }

  __renderPotentialMatch() {
    return html`
      <div id="${ELEMENTS.matchingContainer.id}">
        <div class="text-container item">
          <neb-icon class="icon" icon="neb:info"></neb-icon>
          <div>Potential Matching Online Account Found</div>
        </div>
        <div class="text-container item">
          <a
            id="${ELEMENTS.reviewMatchesLink.id}"
            class="link text-underline"
            @click="${this.__handlers.reviewMatches}"
            >Review Matching Account</a
          >
        </div>
      </div>
      ${this.__renderNoMatch()}
    `;
  }

  __renderNoMatch() {
    return html`
      <div id="${ELEMENTS.noMatchingContainer.id}" class="text-container">
        <neb-icon class="icon" icon="neb:email"></neb-icon>
        <div
          id="${ELEMENTS.inviteEmailLink.id}"
          class="link"
          @click="${this.__handlers.invitePatient}"
        >
          ${TEXT_EMAIL_INVITE}
        </div>
      </div>
    `;
  }

  __renderMatchState() {
    switch (this.__matchState) {
      case 'matched':
        return this.__renderMatched();

      case 'matches-available':
        return this.__renderPotentialMatch();

      default:
        return this.__renderNoMatch();
    }
  }

  render() {
    return html`
      <div class="content">${this.__renderMatchState()}</div>
    `;
  }
}
customElements.define(
  'neb-patient-online-booking-link',
  NebPatientOnlineBookingLink,
);
