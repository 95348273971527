import ApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

const BASE_URL = 'eclaim-files';
const microservice = 'electronic-claims';

export const apiClient = new ApiClient({
  microservice,
  useTenant: true,
});

export const getEClaimFiles = (
  queryParams = {},
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    version: 1,
    queryParams,
    optOutLoadingIndicator,
  });

export const downloadFile = async (
  eClaimFileId,
  queryParams = {},
  optOutLoadingIndicator = true,
) => {
  const response = await apiClient.makeRequest({
    path: `${BASE_URL}/download/${eClaimFileId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version: 2,
    responseType: RESPONSE_TYPE.RAW,
    queryParams,
    optOutLoadingIndicator,
  });

  const blob = await response.blob();
  return window.URL.createObjectURL(blob);
};

export const resendClearinghouseClaims = async (
  eClaimFileIds,
  optOutLoadingIndicator = false,
) => {
  const response = await apiClient.makeRequest({
    path: `${BASE_URL}/resend-clearinghouse-claims`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      eClaimFileIds,
    }),
    method: Method.POST,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
    optOutLoadingIndicator,
  });

  return response;
};
