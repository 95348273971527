import ApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient({
  microservice: 'registry',
  useTenant: true,
});

export const PATH = 'features';

export const mapToModel = raw => ({ id: raw.id, name: raw.name });

export const create = async body => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: `${PATH}`,
    version: 1,
  });

  return mapToModel(res.data[0]);
};

export const update = features =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify({ features }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `${PATH}`,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });

export const remove = async body => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.DELETE,
    path: `${PATH}`,
    version: 1,
  });

  return mapToModel(res.data[0]);
};
