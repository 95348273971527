import { html, css } from 'lit';

import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/neb-radio-button';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { LocationsService } from '../../../packages/neb-redux/services/locations';
import { CSS_SPACING } from '../../../packages/neb-styles/neb-variables';
import * as selectors from '../../../packages/neb-utils/selectors';
import {
  required,
  genRequireSelect,
  isEmail,
} from '../../../packages/neb-utils/validators';

const EMAIL_TYPE = {
  EXISTING: 'existing',
  NEW: 'new',
};

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  cancelButton: { id: 'button-cancel' },
  emailDropdown: { id: 'email-dropdown' },
  emailRadioButton: { id: 'email-radio-button' },
  newEmailInput: { id: 'new-email-input' },
  newEmailRadioButton: { id: 'new-email-radio-button' },
  locationCheckbox: { id: 'location-checkbox' },
  locationDropdown: { id: 'location-dropdown' },
  sendButton: { id: 'button-send' },
};

class NebFormEmailPatient extends NebForm {
  static get properties() {
    return {
      __emailItems: Array,
      __selectedRadioButton: String,
      __showLocationDropdown: Boolean,
      __activeLocations: Array,

      paymentDetail: Object,
      secondaryEmailLabel: String,
      emailAddresses: Array,
    };
  }

  constructor() {
    super();

    this.initServices();
  }

  static createModel() {
    return {
      emailAddress: selectors.ITEM_EMPTY,
      newEmailAddress: '',
      locationId: selectors.ITEM_EMPTY,
    };
  }

  getDefaultItem() {
    if (
      this.__selectedRadioButton === EMAIL_TYPE.EXISTING &&
      this.__emailItems.length
    ) {
      return this.__emailItems[0];
    }
    return selectors.ITEM_EMPTY;
  }

  get locationItems() {
    return [selectors.ITEM_EMPTY, ...this.__activeLocations];
  }

  createSelectors() {
    const location = this.__findLocation({
      locationId: this.paymentDetail.locationId,
      locations: this.__activeLocations,
    });

    return {
      children: {
        emailAddress: selectors.select(
          this.__emailItems,
          this.getDefaultItem(),
          {
            validators:
              this.__selectedRadioButton === EMAIL_TYPE.EXISTING
                ? [genRequireSelect()]
                : [],
          },
        ),
        newEmailAddress: selectors.createDefaultModifiers({
          validators:
            this.__selectedRadioButton === EMAIL_TYPE.NEW
              ? [required(), isEmail]
              : [],
        }),
        locationId: selectors.select(
          this.locationItems,
          location || selectors.ITEM_EMPTY,
        ),
      },
    };
  }

  initState() {
    super.initState();

    this.__selectedRadioButton = EMAIL_TYPE.NEW;
    this.__emailItems = [];
    this.__activeLocations = [];
    this.__showLocationDropdown = false;

    this.secondaryEmailLabel = 'New Email';
    this.paymentDetail = {};
  }

  initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__activeLocations = this.__formatLocations(userLocations).filter(
        location => location.data.active,
      );
    });
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,

      selectEmailRadio: () => {
        if (this.__selectedRadioButton !== EMAIL_TYPE.EXISTING) {
          this.__selectedRadioButton = EMAIL_TYPE.EXISTING;
          this.build();
        }
      },

      selectNewEmailRadio: () => {
        if (this.__selectedRadioButton !== EMAIL_TYPE.NEW) {
          this.__selectedRadioButton = EMAIL_TYPE.NEW;
          this.build();
        }
      },

      toggleLocationDropdown: () => {
        this.__showLocationDropdown = !this.__showLocationDropdown;
        this.__setDefaultLocation();
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      label: location.name,
      data: location,
    }));
  }

  __findLocation({ locationId, locations }) {
    return locations.find(location => location.data.id === locationId);
  }

  __setDefaultLocation() {
    const location = this.__findLocation({
      locationId: this.paymentDetail.locationId,
      locations: this.__activeLocations,
    });

    if (location) {
      this.formService.apply('locationId', location);
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow-y: unset;
          min-height: unset;
        }

        .container-radio {
          display: flex;
          align-items: baseline;
        }

        .spacing {
          margin-right: ${CSS_SPACING};
        }

        .buttons-container {
          margin-top: 30px;
          display: flex;
        }

        .add-button {
          padding-right: 10px;
        }

        .input {
          flex: 1 0 0px;
        }

        .checkbox {
          margin-top: 15px;
          margin-left: 10px;
        }

        .location-dropdown {
          padding-left: 10px;
        }
      `,
    ];
  }

  setDefaultLocationCheckboxValue() {
    const location = this.__findLocation({
      locationId: this.paymentDetail.locationId,
      locations: this.__activeLocations,
    });

    if (location) {
      this.__showLocationDropdown = true;
    }
  }

  update(changedProps) {
    if (changedProps.has('emailAddresses')) {
      this.__emailItems = this.emailAddresses.map(e => ({
        label: e,
        data: { id: e },
      }));

      if (this.__emailItems.length) {
        this.__selectedRadioButton = EMAIL_TYPE.EXISTING;

        this.formService.apply('emailAddress', this.__emailItems[0]);
      }
    }

    if (changedProps.has('paymentDetail') && this.paymentDetail?.locationId) {
      this.build();
      this.setDefaultLocationCheckboxValue();
    }

    super.update(changedProps);
  }

  __renderLocationCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.locationCheckbox.id}"
        class="checkbox"
        name="locations"
        label="Select Location"
        .checked="${this.__showLocationDropdown}"
        .onChange="${this.handlers.toggleLocationDropdown}"
      ></neb-checkbox>
    `;
  }

  __renderLocationDropdown() {
    return this.__showLocationDropdown
      ? html`
          <neb-select
            id="${ELEMENTS.locationDropdown.id}"
            class="location-dropdown"
            name="locationId"
            label="Location"
            select
            wrapText
            .items="${this.locationItems}"
            .value="${this.state.locationId}"
            .onChange="${this.handlers.change}"
          ></neb-select>
        `
      : '';
  }

  renderActionBar() {
    return html`
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.sendButton.id}"
          class="button add-button"
          ?disabled="${this.confirmDisabled}"
          .label="${this.processing ? 'Sending' : 'Send'}"
          .processing="${this.processing}"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.save}"
          unelevated
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="button"
          .label="${this.cancelLabel}"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.cancel}"
          ?disabled="${this.processing}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="container-radio">
        <neb-radio-button
          class="spacing"
          id="${ELEMENTS.emailRadioButton.id}"
          .value="${this.__selectedRadioButton === EMAIL_TYPE.EXISTING}"
          .disabled="${!this.__emailItems.length}"
          .onChange="${this.handlers.selectEmailRadio}"
          .checked="${this.__selectedRadioButton === EMAIL_TYPE.EXISTING}"
        ></neb-radio-button>

        <neb-select
          id="${ELEMENTS.emailDropdown.id}"
          class="input"
          name="emailAddress"
          label="Email"
          helper="${
            this.__selectedRadioButton === EMAIL_TYPE.EXISTING ? 'Required' : ''
          }"
          .disabled="${this.__selectedRadioButton !== EMAIL_TYPE.EXISTING}"
          .items="${this.__emailItems}"
          .value="${this.state.emailAddress}"
          .error="${this.errors.emailAddress}"
          .onChange="${this.handlers.change}"
        ></neb-select>
      </div>

      <div class="container-radio">
        <neb-radio-button
          class="spacing"
          id="${ELEMENTS.newEmailRadioButton.id}"
          .value="${this.__selectedRadioButton === EMAIL_TYPE.NEW}"
          .disabled="${this.__emailItems.length > 2}"
          .onChange="${this.handlers.selectNewEmailRadio}"
          .checked="${this.__selectedRadioButton === EMAIL_TYPE.NEW}"
        ></neb-radio-button>

        <neb-textfield
          id="${ELEMENTS.newEmailInput.id}"
          class="input"
          name="newEmailAddress"
          label="${this.secondaryEmailLabel}"
          helper="${
            this.__selectedRadioButton === EMAIL_TYPE.NEW ? 'Required' : ''
          }"
          .value="${this.state.newEmailAddress}"
          .error="${this.errors.newEmailAddress}"
          .disabled="${this.__selectedRadioButton !== EMAIL_TYPE.NEW}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
      </div>

      ${this.__renderLocationCheckbox()} ${this.__renderLocationDropdown()}
    `;
  }
}

customElements.define('neb-form-email-patient', NebFormEmailPatient);
