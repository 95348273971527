import moment from 'moment-timezone';

import {
  _blockedOffTimeCreating,
  _blockedOffTimeCreated,
  _blockedOffTimeCreateError,
  _blockedOffTimeUpdating,
  _blockedOffTimeUpdated,
  _blockedOffTimeUpdateError,
  _blockedOffTimeDeleting,
  _blockedOffTimeDeleted,
  _blockedOffTimeDeleteError,
} from './actions/blocked-off-time';
import {
  _cancelingDelete,
  _cancelDeleted,
  _cancelDeleteError,
} from './actions/cancel';
import { _creating, _created, _createError } from './actions/create';
import {
  _fetching,
  _fetched,
  _setTargetDate,
  _fetchingError,
} from './actions/fetch';
import { _updating, _updated, _updateError } from './actions/update';

/**
 * @typedef {Object} Appointment
 */

/**
 * @param {Object} state
 * @param {boolean} state.fetching
 * @param {Object} state.fetchError
 * @param {boolean} state.creating
 * @param {Object} state.createError
 * @param {boolean} state.created
 * @param {boolean} state.updating
 * @param {Object} state.updateError
 * @param {boolean} state.updated
 * @param {boolean} state.blockedOffTimeCreating
 * @param {Object} state.blockedOffTimeCreateError
 * @param {boolean} state.blockedOffTimeCreated
 * @param {boolean} state.blockedOffTimeUpdating
 * @param {Object} state.blockedOffTimeUpdateError
 * @param {boolean} state.blockedOffTimeUpdated
 * @param {boolean} state.blockedOffTimeDeleting
 * @param {Object} state.blockedOffTimeDeleteError
 * @param {boolean} state.blockedOffTimeDeleted
 * @param {Date} state.targetDate
 * @param {Date} state.start
 * @param {Date} state.end
 * @param {Array<Appointment>} state.items
 */
export const appointmentsReducer = (
  state = {
    fetching: false,
    fetchError: null,
    creating: false,
    createError: null,
    created: false,
    updating: false,
    updateError: null,
    updated: false,
    isCancelingDeleting: false,
    cancelDeleteSuccess: false,
    cancelDeleteError: null,
    blockedOffTimeCreating: false,
    blockedOffTimeCreateError: null,
    blockedOffTimeCreated: false,
    blockedOffTimeUpdating: false,
    blockedOffTimeUpdateError: null,
    blockedOffTimeUpdated: false,
    blockedOffTimeDeleting: false,
    blockedOffTimeDeleteError: null,
    blockedOffTimeDeleted: false,
    targetDate: moment(),
    start: moment()
      .startOf('day')
      .subtract(7, 'days'),
    end: moment()
      .startOf('day')
      .add(1, 'month')
      .add(7, 'days'),
    items: null,
    itemsByGroupId: null,
  },
  action,
) => {
  switch (action.type) {
    case 'APPOINTMENTS_FETCHING':
      return _fetching(state);

    case 'APPOINTMENT_SET_FETCH_ERROR':
      return _fetchingError(state, action);

    case 'APPOINTMENTS_SET_TARGET_DATE':
      return _setTargetDate(state, action);

    case 'APPOINTMENTS_SET_ITEMS_FETCH':
      return _fetched(state, action);

    case 'APPOINTMENT_CREATING':
      return _creating(state);

    case 'APPOINTMENT_CREATED':
      return _created(state, action);

    case 'APPOINTMENT_CREATE_ERROR':
      return _createError(state, action);

    case 'APPOINTMENT_UPDATING':
      return _updating(state);

    case 'APPOINTMENT_UPDATED':
      return _updated(state, action);

    case 'APPOINTMENT_UPDATE_ERROR':
      return _updateError(state, action);

    case 'APPOINTMENT_CANCELING_DELETING':
      return _cancelingDelete(state);

    case 'APPOINTMENT_CANCEL_DELETE_SUCCESS':
      return _cancelDeleted(state, action);

    case 'APPOINTMENT_CANCEL_DELETE_ERROR':
      return _cancelDeleteError(state, action);

    case 'BLOCKED_OFF_TIME_CREATING':
      return _blockedOffTimeCreating(state);

    case 'BLOCKED_OFF_TIME_CREATE_SUCCESS':
      return _blockedOffTimeCreated(state, action);

    case 'BLOCKED_OFF_TIME_CREATE_ERROR':
      return _blockedOffTimeCreateError(state, action);

    case 'BLOCKED_OFF_TIME_UPDATING':
      return _blockedOffTimeUpdating(state);

    case 'BLOCKED_OFF_TIME_UPDATE_SUCCESS':
      return _blockedOffTimeUpdated(state, action);

    case 'BLOCKED_OFF_TIME_UPDATE_ERROR':
      return _blockedOffTimeUpdateError(state, action);

    case 'BLOCKED_OFF_TIME_DELETING':
      return _blockedOffTimeDeleting(state);

    case 'BLOCKED_OFF_TIME_DELETE_SUCCESS':
      return _blockedOffTimeDeleted(state, action);

    case 'BLOCKED_OFF_TIME_DELETE_ERROR':
      return _blockedOffTimeDeleteError(state, action);

    default:
      return state;
  }
};
