import { openPopup } from '@neb/popup';
import { html } from 'lit';

import { CLEARINGHOUSES } from '../../../../../../src/utils/clearinghouse-settings';
import {
  CORRECT_CLAIM_SELECTED_MEDICARE_MESSAGE,
  CORRECT_CLAIM_SELECTED_MEDICARE_TITLE,
  DELETE_DRAFT_CLAIM_SUCCESS,
  DELETE_DRAFT_CLAIM_UNABLE,
  NO_CLAIMS_PRIMARY_MESSAGE,
  NO_CLAIMS_SECONDARY_MESSAGE,
  NO_CLAIMS_SUBMISSION_METHOD_TITLE,
  NO_CLAIMS_ERROR_PRIMARY,
  NO_CLAIMS_ERROR_SECONDARY,
  REFRESH_SINGLE_CLAIM_DRAFT_SUCCESS,
  VOID_CLAIM_SELECTED_MEDICARE_TITLE,
  VOID_CLAIM_SELECTED_MEDICARE_MESSAGE,
  CLAIM_STATUS_BILLED_UPDATE_SUCCESS,
  CLAIM_STATUS_UPDATE_SUCCESS,
} from '../../../../../../src/utils/user-message';
import { getBillingInfo } from '../../../../../neb-api-client/src/billing-information-api-client';
import {
  createStatus,
  updateStatus,
} from '../../../../../neb-api-client/src/claim-status';
import * as claimsApi from '../../../../../neb-api-client/src/claims';
import {
  USER_ACTION_KEY,
  createPDF,
  deleteClaim,
  updateClaimStatus,
} from '../../../../../neb-api-client/src/claims';
import { requestClaimStatus } from '../../../../../neb-api-client/src/electronic-claims/claims';
import { printEncounterSummaries } from '../../../../../neb-api-client/src/encounters-api-client';
import { getPayerPlan } from '../../../../../neb-api-client/src/payer-plan-api-client';
import { getPracticeInformation } from '../../../../../neb-api-client/src/practice-information';
import { getSignatureFile } from '../../../../../neb-api-client/src/provider-signature-api-client';
import {
  openError,
  openInfo,
  openSuccess,
} from '../../../../../neb-dialog/neb-banner-state';
import { openClaimRefreshDirtyDialog } from '../../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../../neb-redux/services/locations';
import {
  CLAIM_STATUS,
  isEditable,
  isCancelable,
  formatPracticeInfoForClaims,
  SUBMISSION_METHOD,
  hasStatus,
  getPrintSettings,
  PAYMENT_RESPONSIBILITY_LEVEL_CODE,
} from '../../../../../neb-utils/claims';
import {
  getFileURL,
  showLoading,
  showError,
} from '../../../../../neb-utils/neb-pdf-print-util';
import { FINANCIAL_CLASSES } from '../../../../../neb-utils/patientInsurance';
import { CATEGORY, NebClaimFormView } from '../../claims/neb-claim-form-view';
import NebForm from '../neb-form';

import {
  createRecommendedSelectors,
  createRequiredSelectors,
  createOptionalSelectors,
  createConfigurableSelectors,
  createScrubbingValidators,
} from './create-selectors';
import openPopupBillingProvider from './open-popup-billing-provider';
import openPopupBillingProviderInformation from './open-popup-billing-provider-information';
import openPopupDateRange from './open-popup-date-range';
import openPopupDropdown from './open-popup-dropdown';
import openPopupDropdownTextInput from './open-popup-dropdown-text-input';
import openPopupFederalTaxId from './open-popup-federal-tax-id';
import openPopupMultiSelect from './open-popup-multi-select';
import openPopupOutsideLabs from './open-popup-outside-labs';
import openPopupRadioDatePicker from './open-popup-radio-date-picker';
import openPopupRadioNameFields from './open-popup-radio-name-fields';
import openPopupServiceFacility from './open-popup-service-facility';
import openPopupSingleSelect from './open-popup-single-select';
import openPopupSpinalManipulation from './open-popup-spinal-manipulation';
import openPopupSupplementalInformation from './open-popup-supplemental-information';
import openPopupTextInput from './open-popup-text-input';
import openPopupYesNo from './open-popup-yes-no';

export const ELEMENTS = {
  actionBar: { id: 'action-bar' },
  claimView: { id: 'claim-view' },
};

const flatten = object =>
  Object.values(object).flatMap(v => (typeof v === 'object' ? flatten(v) : v));

export const SUCCESS_MESSAGE_CLAIM_STATUS_PENDING =
  'Status update requested successfully. Response pending.';
export const SUCCESS_MESSAGE_CLAIM_STATUS =
  'Status update requested successfully. Response updated.';

export class NebFormClaim extends NebForm {
  static get properties() {
    return {
      __signatureSrc: String,
      __allLocations: Array,
      __serviceLocations: Array,
      __actionKey: String,
      isElectronic: Boolean,
      payerPlan: Object,
      type: String,
      validationErrors: Object,
      clearinghouseErrors: Object,
      otherChcErrors: Object,
      scrubbingErrors: Object,
      hasMaxClear: Boolean,
    };
  }

  static createModel() {
    return NebClaimFormView.createModel();
  }

  createSelectors() {
    const scrubbingValidators = createScrubbingValidators(this.scrubbingErrors);

    return {
      children: {
        fl4: {
          validators: [
            {
              error: 'Required',
              validate: () =>
                (this.state.insuredFirstName && this.state.insuredLastName) ||
                this.state.insuredName,
            },
          ],
        },
        ...createRecommendedSelectors({
          scrubbingValidators,
        }),
        ...createRequiredSelectors({
          type: this.type,
          scrubbingValidators,
        }),
        ...createOptionalSelectors({
          scrubbingValidators,
        }),
        ...createConfigurableSelectors(),
      },
    };
  }

  constructor() {
    super();

    this.__initServices();
  }

  initState() {
    super.initState();
    this.__allLocations = [];
    this.__serviceLocations = [];
    this.__signatureSrc = null;
    this.__actionKey = null;

    this.onRefresh = () => {};

    this.onRefreshAndSubmit = () => {};

    this.onRebill = () => {};

    this.onCancelResubmit = () => {};

    this.onCorrectClaim = () => {};

    this.onVoidClaim = () => {};

    this.onScrubClaim = () => {};

    this.isElectronic = null;
    this.payerPlan = { providerSpecificValues: [] };
    this.validationErrors = {};
    this.clearinghouseErrors = {};
    this.otherChcErrors = {};
    this.scrubbingErrors = {};
    this.hasMaxClear = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      deleteClaim: async () => {
        const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: 'Delete Claim Draft',
          message: 'Are you sure you want to delete the claim draft?',
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (result) {
          try {
            const count = await deleteClaim(this.model.id);
            const fn =
              count === 1
                ? openSuccess(DELETE_DRAFT_CLAIM_SUCCESS)
                : openInfo(DELETE_DRAFT_CLAIM_UNABLE);
            store.dispatch(fn);
            this.handlers.cancel();
          } catch (e) {
            console.error(e);
            store.dispatch(
              openError('An error occurred when deleting the claim draft'),
            );
          }
        }

        this.onChangeDirty(false);
        return undefined;
      },

      generateClaim: () => {
        this.__generateClaim(false);
      },

      generateWithOverride: () => {
        this.__generateClaim(true);
      },

      refreshAndSubmit: async () => {
        await this.onRefresh(true, USER_ACTION_KEY.REFRESH_AND_SUBMIT);
        await this.__generateClaim(false, true);
      },

      printClaim: async () => {
        const claim = this.formService.build();

        let claimWindow;
        let notesWindow;

        if (claim.chartNotesRequired) {
          try {
            notesWindow = window.open();
            showLoading(notesWindow, 'Loading chart notes, please wait...');
          } catch (e) {
            console.error(e);
            store.dispatch(
              openError(
                'Cannot open chart notes tab, please check your pop-up blocker',
              ),
            );
          }
        }

        try {
          claimWindow = window.open();
          showLoading(claimWindow, 'Loading claim, please wait...');
        } catch (e) {
          console.error(e);
          store.dispatch(
            openError(
              'Cannot open claim tab, please check your pop-up blocker',
            ),
          );
        }

        if (claimWindow) {
          const { printCmsBackground } = this.payerPlan;

          try {
            const settings = getPrintSettings();

            const buffer = await createPDF(
              {
                offset: {
                  left: parseInt(settings.left, 10),
                  top: parseInt(settings.top, 10),
                },
                claims: [
                  {
                    ...claim,
                    originalReferenceNumber:
                      claim.resubmissionCode ||
                      hasStatus(
                        [CLAIM_STATUS.CANCELED],
                        this.model.claimStatuses[0].status,
                      )
                        ? claim.originalReferenceNumber
                        : '',
                    providerSignatureImage: this.__signatureSrc,
                  },
                ],
                printCmsBackground,
                bold: settings.bold,
              },
              this.isElectronic,
            );
            claimWindow.location = getFileURL(buffer);
          } catch (e) {
            console.error(e);
            const msg = 'An error occurred when printing the claim';
            store.dispatch(openError(msg));
            showError(claimWindow, msg);
          }
        }

        if (notesWindow) {
          try {
            const buffer = await printEncounterSummaries({
              patientId: claim.patientId,
              encounterIds: [
                ...new Set(
                  claim.claimCharges.map(({ encounterId }) => encounterId),
                ),
              ],
            });
            notesWindow.location = getFileURL(buffer);
          } catch (e) {
            console.error(e);
            const msg = 'An error occurred when printing chart notes';
            store.dispatch(openError(msg));
            showError(notesWindow, msg);
          }
        }
      },

      openClaimPopup: async claimCellData => {
        const claimStatus = this.state.claimStatuses[0].status;

        if (
          isCancelable(claimStatus) &&
          claimCellData.name === 'resubmissionCode'
        ) {
          return openPopupDropdownTextInput({
            claimCellData,
            state: this.state,
            handlers: this.handlers,
            formService: this.formService,
          });
        }

        if (!isEditable(claimStatus)) {
          return undefined;
        }

        switch (claimCellData.type) {
          case 'billingProvider': {
            const {
              billingInfo,
              practiceInfo,
            } = await this.__fetchPracticeInfo();

            return openPopupBillingProvider({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              nebFormClaim: this,
              payerPlan: this.payerPlan,
              billingInfo,
              practiceInfo,
              formService: this.formService,
            });
          }

          case 'dateRange':
            return openPopupDateRange({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              formService: this.formService,
            });

          case 'dropdown': {
            const {
              billingInfo,
              practiceInfo,
            } = await this.__fetchPracticeInfo();

            return openPopupDropdown({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              nebFormClaim: this,
              payerPlan: this.payerPlan,
              billingInfo,
              practiceInfo,
              formService: this.formService,
            });
          }

          case 'dropdownTextInput':
            return openPopupDropdownTextInput({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              formService: this.formService,
            });

          case 'federalTaxId': {
            const state = { ...this.state, locations: this.__serviceLocations };
            return openPopupFederalTaxId({
              claimCellData,
              state,
              handlers: this.handlers,
              formService: this.formService,
            });
          }

          case 'multiSelect':
            return openPopupMultiSelect({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              formService: this.formService,
            });

          case 'outsideLabs':
            return openPopupOutsideLabs({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              formService: this.formService,
            });

          case 'radioDatePicker':
            return openPopupRadioDatePicker({
              claimCellData,
              state: this.state,
              model: this.model,
              handlers: this.handlers,
              formService: this.formService,
            });

          case 'radioNameFields':
            return openPopupRadioNameFields({
              claimCellData,
              state: this.state,
              model: this.model,
              handlers: this.handlers,
              formService: this.formService,
            });

          case 'supplementalFields':
            return openPopupSupplementalInformation({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              formService: this.formService,
            });

          case 'serviceFacility': {
            const {
              billingInfo,
              practiceInfo,
            } = await this.__fetchPracticeInfo();

            return openPopupServiceFacility({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              billingInfo,
              practiceInfo,
              locations: this.__allLocations,
              formService: this.formService,
            });
          }

          case 'billingProviderInformation': {
            return openPopupBillingProviderInformation({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              locations: this.__allLocations,
              formService: this.formService,
            });
          }

          case 'singleSelect':
            return openPopupSingleSelect({
              claimCellData,
              state: this.state,
              model: this.model,
              handlers: this.handlers,
              formService: this.formService,
            });

          case 'spinalManipulation':
            return openPopupSpinalManipulation({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              formService: this.formService,
            });

          case 'textInput':
            return openPopupTextInput({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              formService: this.formService,
            });

          case 'yesNo':
            return openPopupYesNo({
              claimCellData,
              state: this.state,
              handlers: this.handlers,
              formService: this.formService,
            });

          default:
            return undefined;
        }
      },

      cancelResubmit: () => {
        this.onCancelResubmit(this.state.id);
      },

      saveDraft: () => {
        if (this.validate()) {
          const { id } = store.getState().session.item;
          this.formService.apply('claimStatuses.0.status', CLAIM_STATUS.DRAFT);
          this.formService.apply('claimStatuses.0.updatedBy', id);

          return this.__save();
        }

        return undefined;
      },

      refreshDraft: async () => {
        await this.__dirtyGuard();
      },

      scrollToError: ({ name, value }) =>
        name !== CATEGORY.NOTIFICATION &&
        this.__elements.claimView.scrollToCell(value),

      updateStatus: async (status, effectiveDate = null) => {
        const { id } = store.getState().session.item;

        try {
          let body = { status, updatedBy: id };

          if (effectiveDate) {
            body = { ...body, effectiveDate };
          }

          const { billedToUpdated } = await updateClaimStatus(
            this.state.id,
            body,
          );

          const userMessage = billedToUpdated
            ? CLAIM_STATUS_BILLED_UPDATE_SUCCESS
            : CLAIM_STATUS_UPDATE_SUCCESS;

          store.dispatch(openSuccess(userMessage));

          this.formService.addItem('claimStatuses', 0);
          this.formService.apply('claimStatuses.0.status', status);
          this.formService.refresh(this.formService.build());
          this.formService.validate();
          this.onRefresh();
        } catch (e) {
          console.error(e);
          store.dispatch(
            openError('An error occurred when updating the claim status'),
          );
        }
      },

      requestStatus: async pending => {
        try {
          const status = await this.__requestStatusAndUpdateRefNumber(pending);
          await this.__handleStatus(status);
          const successMessage = pending
            ? SUCCESS_MESSAGE_CLAIM_STATUS_PENDING
            : SUCCESS_MESSAGE_CLAIM_STATUS;
          store.dispatch(openSuccess(successMessage));

          this.onRefresh();
        } catch (_) {
          store.dispatch(openError('Error occurred. Please try again'));
        }
      },

      refreshClaim: () => {
        this.onRefresh(true, USER_ACTION_KEY.REFRESH_CLAIM);
      },

      rebillClaim: async () => {
        this.__actionKey = USER_ACTION_KEY.REBILL_CLAIM;

        await this.onRebill(this.state.id);
      },

      correctClaim: async () => {
        if (await this.__shouldCorrectClaim()) {
          if (!this.state.originalReferenceNumber) {
            const { originalReferenceNumber } = await openPopup(
              POPUP_RENDER_KEYS.ORIGINAL_REFERENCE_NUMBER,
            );

            if (!originalReferenceNumber) return;

            this.formService.apply(
              'originalReferenceNumber',
              originalReferenceNumber,
            );
          }

          this.__actionKey = USER_ACTION_KEY.CORRECT_CLAIM;

          this.onCorrectClaim({
            claimId: this.state.id,
            originalReferenceNumber: this.state.originalReferenceNumber,
          });
        }
      },
      voidClaim: async () => {
        let status;
        let tradingPartnerClaimNumber;

        const { isElectronic } = this.state;

        if (await this.__shouldVoidClaim()) {
          if (isElectronic && this.payerPlan.chcPayerId) {
            try {
              status = await this.__requestStatusAndUpdateRefNumber(
                false,
                true,
              );

              if (status && status.length && status[0].eClaimStatus) {
                tradingPartnerClaimNumber =
                  status[0].eClaimStatus.tradingPartnerClaimNumber;
              }
            } catch (e) {
              console.error(e);
            }
          }

          if (
            !(
              (!isElectronic && this.state.originalReferenceNumber) ||
              (isElectronic &&
                (this.state.originalReferenceNumber ||
                  tradingPartnerClaimNumber))
            )
          ) {
            const { originalReferenceNumber } = await openPopup(
              POPUP_RENDER_KEYS.ORIGINAL_REFERENCE_NUMBER,
            );

            if (!originalReferenceNumber) return;

            this.formService.apply(
              'originalReferenceNumber',
              originalReferenceNumber,
            );
          }
          this.formService.apply(
            'claimStatuses.0.status',
            CLAIM_STATUS.CANCELED_VOIDED,
          );

          await this.onVoidClaim({
            ...this.state,
            originalReferenceNumber:
              this.state.originalReferenceNumber || tradingPartnerClaimNumber,
          });
        }
      },
      scrubClaim: async () => {
        const model = this.formService.build();

        await this.onScrubClaim(model);
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(
      ({ locations, allLocations }) => {
        this.__serviceLocations = locations;
        this.__allLocations = allLocations;
      },
    );
  }

  connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    this.__locationsService.disconnect();
    super.disconnectedCallback();
  }

  async __dirtyGuard() {
    if (!this.__dirty) {
      return this.__refreshDraft();
    }

    const result = await openClaimRefreshDirtyDialog();

    switch (result) {
      case 'saveRefresh':
        await this.__save();
        await this.__refreshDraft();
        break;
      case 'discardRefresh':
        await this.__refreshDraft();
        break;
      default:
        return false;
    }
    return false;
  }

  async __refreshDraft() {
    const claimId = this.model.id;

    try {
      const response = await claimsApi.refreshClaimData(claimId, 2);

      if (response) {
        await this.onRefresh(true);
      }

      store.dispatch(openSuccess(REFRESH_SINGLE_CLAIM_DRAFT_SUCCESS));
    } catch (err) {
      if (err.message.includes('generation of primary claims.')) {
        store.dispatch(openError(NO_CLAIMS_ERROR_PRIMARY));
      } else if (err.message.includes('generation of secondary claims.')) {
        store.dispatch(openError(NO_CLAIMS_ERROR_SECONDARY));
      }
    }
  }

  async __generateClaim(overrideScrubbing = false) {
    if (await this.__noClaimsAllowed()) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, this.__genNoClaimsConfig());
    } else if (this.validate() || overrideScrubbing) {
      if (this.state.claimStatuses[0].status !== CLAIM_STATUS.UNSAVED_DRAFT) {
        this.formService.addItem('claimStatuses', 0);
      }

      if (
        this.state.claimStatuses.length > 1 &&
        this.state.claimStatuses[1].status === CLAIM_STATUS.ERROR
      ) {
        new Array(this.state.claimStatuses.length)
          .fill()
          .map((_, index) => index)
          .forEach(() => this.formService.removeItem('claimStatuses'));

        this.formService.addItem('claimStatuses', 0);
      }
      const { id } = store.getState().session.item;

      if (
        this.state.claimStatuses.length > 1 &&
        this.state.claimStatuses[1].status === CLAIM_STATUS.VALIDATION_ERROR
      ) {
        this.formService.apply(
          'claimStatuses.0.status',
          CLAIM_STATUS.OVERRIDDEN,
        );

        this.formService.addItem('claimStatuses', 0);
      }

      this.formService.apply('claimStatuses.0.status', CLAIM_STATUS.GENERATED);

      this.formService.apply('claimStatuses.0.updatedBy', id);

      return this.__save(false, overrideScrubbing);
    }

    return undefined;
  }

  async __requestStatusAndUpdateRefNumber(
    pending = false,
    autoPopulate = false,
  ) {
    const status = await requestClaimStatus({
      ...this.model,
      tradingPartnerServiceId: this.payerPlan.chcPayerId,
      pending,
    });

    if (!autoPopulate) {
      if (status.length && status[0].eClaimStatus) {
        await this.__updateOriginalReferenceNumber(
          status[0].eClaimStatus.tradingPartnerClaimNumber,
        );
      }
    }
    return status;
  }

  async __shouldCorrectClaim() {
    if (
      this.state.paymentResponsibilityLevelCode ===
        PAYMENT_RESPONSIBILITY_LEVEL_CODE.PRIMARY &&
      this.payerPlan.submissionMethodPrimary === SUBMISSION_METHOD.NO_CLAIMS
    ) {
      return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: NO_CLAIMS_SUBMISSION_METHOD_TITLE(),
        message: NO_CLAIMS_PRIMARY_MESSAGE,
      });
    }

    if (
      this.state.paymentResponsibilityLevelCode ===
        PAYMENT_RESPONSIBILITY_LEVEL_CODE.SECONDARY &&
      this.payerPlan.submissionMethodSecondary === SUBMISSION_METHOD.NO_CLAIMS
    ) {
      return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: NO_CLAIMS_SUBMISSION_METHOD_TITLE(),
        message: NO_CLAIMS_SECONDARY_MESSAGE,
      });
    }

    if (this.payerPlan.financialClass === FINANCIAL_CLASSES.Medicare) {
      const rebillClaim = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
        title: CORRECT_CLAIM_SELECTED_MEDICARE_TITLE(),
        message: CORRECT_CLAIM_SELECTED_MEDICARE_MESSAGE(),
        confirmText: 'Rebill Claim',
        cancelText: 'Close',
      });

      if (rebillClaim) {
        this.handlers.rebillClaim();
      }

      return false;
    }

    return true;
  }

  __shouldVoidClaim() {
    if (this.payerPlan.financialClass === FINANCIAL_CLASSES.Medicare) {
      return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
        title: VOID_CLAIM_SELECTED_MEDICARE_TITLE(),
        message: VOID_CLAIM_SELECTED_MEDICARE_MESSAGE(),
      });
    }

    return true;
  }

  __updateOriginalReferenceNumber(
    tradingPartnerClaimNumber,
    autoPopulate = false,
  ) {
    if (
      tradingPartnerClaimNumber &&
      this.state.originalReferenceNumber !== tradingPartnerClaimNumber
    ) {
      this.formService.apply(
        'originalReferenceNumber',
        tradingPartnerClaimNumber,
      );

      if (!autoPopulate) return this.__save(true);
    }
    return undefined;
  }

  __handleStatus(status) {
    return status.length && status[0].eClaimStatus
      ? createStatus({
          ...status[0].eClaimStatus,
          claimId: this.model.id,
          clearinghousePartner: CLEARINGHOUSES.CHC,
        })
      : updateStatus({
          ...this.model.claimStatuses[0],
          statusPending: true,
        });
  }

  validate() {
    this.formService.validate();

    const validClearinghouse = !(
      this.clearinghouseErrors.errors && this.clearinghouseErrors.errors.length
    );

    const validChcPayerPlan = !(
      this.otherChcErrors.errors && this.otherChcErrors.errors.length
    );

    return (
      !flatten(this.errors)
        .filter(v => v !== 'Recommended')
        .some(v => v) &&
      validClearinghouse &&
      validChcPayerPlan
    );
  }

  __save(isUpdateOnly = false, overrideScrubbing = false) {
    const model = this.formService.build();
    this.__saving = true;

    return this.onSave(
      model,
      isUpdateOnly,
      overrideScrubbing,
      this.__actionKey,
    );
  }

  async __fetchPracticeInfo() {
    const { tenantId } = store.getState().session.item;

    const [billingInfo, practiceInfoRaw] = await Promise.all([
      getBillingInfo(),
      getPracticeInformation(tenantId),
    ]);

    return {
      billingInfo,
      practiceInfo: formatPracticeInfoForClaims(practiceInfoRaw),
    };
  }

  async __noClaimsAllowed() {
    const payerPlan = await getPayerPlan(this.payerPlan.id, 2);

    return (
      payerPlan[`submissionMethod${this.type}`] === SUBMISSION_METHOD.NO_CLAIMS
    );
  }

  __genNoClaimsConfig() {
    const type = this.type.toLowerCase();

    return {
      title: 'Unable to Generate Claim For Payer',
      message: html`
        <div>
          ${
            `This Payer is not configured to support the generation of ${type} claims.`
          }
        </div>
        <br />
        <div>
          ${
            `To enable the generation of ${type} claims, update settings for this Payer in Payer Plan Maintenance.`
          }
        </div>
      `,
    };
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      this.formService.validate();
    }

    if (changedProps.has('model') && this.model.billingProviderSignatureS3Key) {
      this.__signatureSrc = await getSignatureFile(
        this.model.billingProviderId,
        {
          signatureKey: this.model.billingProviderSignatureS3Key,
        },
      );
    } else if (
      changedProps.has('model') &&
      !this.model.billingProviderSignatureS3Key
    ) {
      this.__signatureSrc = null;
    }

    this.__elements = {
      claimView: this.shadowRoot.getElementById(ELEMENTS.claimView.id),
    };
  }

  renderActionBar() {
    return isEditable(this.state.claimStatuses[0].status)
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .confirmLabel="${this.confirmLabel}"
            .cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.saveDraft}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  renderContent() {
    return html`
      <neb-claim-form-view
        id="${ELEMENTS.claimView.id}"
        .errors="${this.errors}"
        .validationErrors="${this.validationErrors}"
        .scrubbingErrors="${this.scrubbingErrors}"
        .clearinghouseErrors="${this.clearinghouseErrors}"
        .otherChcErrors="${this.otherChcErrors}"
        .model="${this.state}"
        .payerPlan="${this.payerPlan}"
        .isElectronic="${this.isElectronic}"
        .signatureSrc="${this.__signatureSrc}"
        .dirty="${this.__dirty}"
        .onClickCell="${this.handlers.openClaimPopup}"
        .onDelete="${this.handlers.deleteClaim}"
        .onGenerate="${this.handlers.generateClaim}"
        .onPrint="${this.handlers.printClaim}"
        .onCancelResubmit="${this.handlers.cancelResubmit}"
        .onSelectError="${this.handlers.scrollToError}"
        .onStatusChange="${this.handlers.updateStatus}"
        .onRequestStatus="${this.handlers.requestStatus}"
        .onRefreshClaim="${this.handlers.refreshClaim}"
        .onRebillClaim="${this.handlers.rebillClaim}"
        .onCorrectClaim="${this.handlers.correctClaim}"
        .onVoidClaim="${this.handlers.voidClaim}"
        .onScrubClaim="${this.handlers.scrubClaim}"
        .onDisplayS3Claim="${this.handlers.displayS3Claim}"
        .onGenerateWithOverride="${this.handlers.generateWithOverride}"
        .onRefreshAndSubmit="${this.handlers.refreshAndSubmit}"
        .onRefreshDraft="${this.handlers.refreshDraft}"
        .hasMaxClear="${this.hasMaxClear}"
      ></neb-claim-form-view>
    `;
  }
}

customElements.define('neb-form-claim', NebFormClaim);
