import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import Address from '../../packages/neb-lit-components/src/components/field-groups/neb-address';
import {
  formatPhoneNumber,
  formatPhoneNumberToRaw,
} from '../../packages/neb-utils/formatters';

export const apiClient = new ApiClient({ microservice: 'billing' });

const VENDOR_BASE_URL = 'vendors';

export const API_VERSION = 1;

export const HEADERS = { 'Content-Type': 'application/json' };

export const EMPTY_VENDOR_MODEL = {
  label: '',
  data: { id: '', website: '' },
};

export const createModel = () => ({
  id: '',
  name: '',
  website: '',
  active: true,
  notes: '',
  address: Address.createModel(),
  email: '',
  phone: '',
  fax: '',
});

export const formatVendorToModel = vendor => ({
  ...vendor,
  phone: formatPhoneNumber(vendor.phone),
  fax: formatPhoneNumber(vendor.fax),
});

export const formatVendorsToModel = vendors =>
  vendors ? vendors.map(vendor => formatVendorToModel(vendor)) : [];

export const formatVendorToRaw = vendor => ({
  ...vendor,
  phone: formatPhoneNumberToRaw(vendor.phone),
  fax: formatPhoneNumberToRaw(vendor.fax),
});

export const getVendors = async (
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  const vendors = await apiClient.makeRequest({
    method: Method.GET,
    path: VENDOR_BASE_URL,
    queryParams,
    headers: HEADERS,
    version: API_VERSION,
    cacheKey: `vendors-${JSON.stringify(queryParams)}`,
    optOutLoadingIndicator,
  });

  return formatVendorsToModel(vendors);
};

export const getVendor = async (id, optOutLoadingIndicator = false) => {
  const vendor = await apiClient.makeRequest({
    method: Method.GET,
    path: `${VENDOR_BASE_URL}/${id}`,
    headers: HEADERS,
    version: API_VERSION,
    optOutLoadingIndicator,
  });

  return formatVendorToModel(vendor);
};

export const getVendorExists = (name, optOutLoadingIndicator = false) =>
  apiClient.makeRequest({
    method: Method.POST,
    path: `${VENDOR_BASE_URL}/exists`,
    body: JSON.stringify({ name }),
    headers: HEADERS,
    version: API_VERSION,
    optOutLoadingIndicator,
  });

export const getVendorCount = (
  queryParams = {},
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.GET,
    path: `${VENDOR_BASE_URL}/count`,
    queryParams,
    headers: HEADERS,
    version: API_VERSION,
    optOutLoadingIndicator,
    cacheKey: `vendor-count-${JSON.stringify(queryParams)}`,
  });

export const createVendor = async body => {
  const payload = formatVendorToRaw(body);

  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: VENDOR_BASE_URL,
    headers: HEADERS,
    body: JSON.stringify(payload),
    version: API_VERSION,
  });

  return formatVendorToModel(result);
};

export const updateVendor = async body => {
  const payload = formatVendorToRaw(body);

  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${VENDOR_BASE_URL}/${body.id}`,
    headers: HEADERS,
    body: JSON.stringify(payload),
    version: API_VERSION,
  });

  return formatVendorToModel(result);
};
