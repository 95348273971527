import { html, css } from 'lit';

import Table, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { TABLE_CONFIG } from '../../../../packages/neb-patient/src/components/billing/neb-patient-relationships-page';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  patientLinks: {
    selector: '[id^=patient-link-]',
  },
};

class NebTablePatientRelationships extends Table {
  static get properties() {
    return {
      patientId: String,
    };
  }

  initState() {
    super.initState();

    this.patientId = '';
    this.config = TABLE_CONFIG;

    this.onSelectPatient = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      clickPatient: e => {
        e.stopPropagation();

        const rowIndex = e.currentTarget.getAttribute('index');
        const { relatedPatientId: patientId } = this.model[rowIndex];

        return this.onSelectPatient(patientId);
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .patient-text {
          margin-right: 6px;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - ${CSS_SPACING});
        }

        .patient-link {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          max-width: calc(100% - ${CSS_SPACING});
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
          inline-size: min-content;
          overflow: hidden;
          max-width: calc(100% - ${CSS_SPACING});
        }
      `,
    ];
  }

  __canClickRow(item, index) {
    return index > 0 && item.type !== 'Via Primary';
  }

  renderModeCell(item, index) {
    if (this.__canClickRow(item, index)) {
      return html`
        <neb-icon
          id="icon-detail-${index}"
          class="cell cell-detail"
          icon="neb:chevron"
          key="SPECIAL.detail"
          row="${index}"
          @click="${this.handlers.clickCell}"
        ></neb-icon>
      `;
    }
    return html`
      <div class="cell cell-detail"></div>
    `;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'patientName':
        return rowIndex > 0
          ? html`
              <div
                id="patient-link-${rowIndex}"
                class="button"
                index="${rowIndex}"
                @click="${this.handlers.clickPatient}"
              >
                <span class="patient-text patient-link">${value}</span>
                <neb-icon class="icon" icon="neb:open"></neb-icon>
              </div>
            `
          : html`
              <div id="patient-link-${rowIndex}" index="${rowIndex}">
                <span class="patient-text">${value}</span>
              </div>
            `;
      case 'active':
        return rowIndex > 0 ? value : '';
      default:
        return value;
    }
  }

  renderRow(item, index) {
    if (this.__canClickRow(item, index)) {
      return html`
        <div
          id="row-${index}"
          class="row row-data"
          row="${index}"
          @click="${this.handlers.clickRow}"
        >
          <div class="content" ?clickable="${true}">
            ${this.__renderDataCells(item, index)}
            ${this.renderModeCell(item, index)}
          </div>
        </div>
      `;
    }

    return html`
      <div id="row-${index}" class="row row-data" row="${index}">
        <div class="content">
          ${this.__renderDataCells(item, index)}
          ${this.renderModeCell(item, index)}
        </div>
      </div>
    `;
  }
}

customElements.define(
  'neb-table-patient-relationships',
  NebTablePatientRelationships,
);
