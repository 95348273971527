import './inputs/neb-menu';

import { LitElement, html, css } from 'lit';

import { CSS_COLOR_HIGHLIGHT } from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  containerLabel: {
    id: 'container-label',
  },
  iconArrow: {
    id: 'icon-arrow',
  },
  textLabel: {
    id: 'text-label',
  },
  menu: {
    id: 'menu',
  },
};
class NebDropdownMenu extends LitElement {
  static get properties() {
    return {
      __openMenu: {
        type: Boolean,
        reflect: true,
        attribute: 'openMenu',
      },
      align: {
        type: String,
        reflect: true,
      },
      items: {
        type: Array,
      },
      label: {
        type: String,
      },
      showFullText: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__openMenu = false;
    this.align = 'left';
    this.items = [];
    this.label = '';
    this.showFullText = false;
  }

  __initHandlers() {
    this.__handlers = {
      blur: () => {
        this.__openMenu = false;
      },
      click: e => {
        this.__openMenu = !this.__openMenu;
        e.stopPropagation();
      },
      select: index => {
        this.__openMenu = false;
        this.items[index].onSelect();
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    document.body.addEventListener('click', this.__handlers.blur);
  }

  disconnectedCallback() {
    document.body.removeEventListener('click', this.__handlers.blur);
    super.disconnectedCallback();
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        position: relative;
        cursor: pointer;
      }

      .container-label {
        display: flex;
        align-items: center;
      }

      .label {
        font-size: 14px;
        font-weight: bold;
        color: ${CSS_COLOR_HIGHLIGHT};
        margin-right: 5px;
      }

      .icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      .icon-arrow {
        width: 12px;
        height: 12px;
        fill: ${CSS_COLOR_HIGHLIGHT};

        animation: spin 250ms ease-in-out;
        -moz-animation: spin 250ms ease-in-out;
        -webkit-animation: spin 250ms ease-in-out;
        -webkit-transition-duration: 250ms;
        -webkit-transition-timing-function: ease-in-out;
        -webkit-transition-delay: 0;
      }

      :host([openMenu]) .icon-arrow {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }

      .menu {
        position: absolute;
        width: 200px;
        height: fit-content;
        top: 30px;
      }

      :host([align='left']) .menu {
        left: 0;
      }

      :host([align='right']) .menu {
        right: 0;
      }

      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }

      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);

          transform: rotate(360deg);
        }
      }
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <div
          id="${ELEMENTS.containerLabel.id}"
          class="container-label"
          @click="${this.__handlers.click}"
        >
          <div id="${ELEMENTS.textLabel.id}" class="label">${this.label}</div>

          <neb-icon
            id="${ELEMENTS.iconArrow.id}"
            class="icon icon-arrow"
            icon="neb:arrow"
          ></neb-icon>
        </div>

        <neb-menu
          id="${ELEMENTS.menu.id}"
          class="menu"
          align="right"
          .items="${this.items}"
          .maxVisibleItems="${this.items.length}"
          .onChange="${this.__handlers.select}"
          ?open="${this.__openMenu && this.items.length > 0}"
          ?showFullText="${this.showFullText}"
        ></neb-menu>
      </div>
    `;
  }
}
customElements.define('neb-dropdown-menu', NebDropdownMenu);
