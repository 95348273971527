import '../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { CSS_COLOR_HIGHLIGHT } from '../neb-styles/neb-variables';

export const ELEMENTS = {
  iconLock: {
    id: 'icon-lock',
  },
  iconBrokenLink: {
    id: 'icon-broken-link',
  },
  noAccessMessage: {
    id: 'no-access-message',
  },
  noAccessMessageTwo: {
    id: 'no-acess-message-two',
  },
  pageDoesNotExistMessage: {
    id: 'does-not-exist-message',
  },
};

class NebNoAccessPage extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      pageExists: {
        type: Boolean,
      },
      settingsNoAccess: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.__initState();
  }

  __initState() {
    this.layout = '';
    this.pageExists = true;
    this.settingsNoAccess = false;
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      .container-settings {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        margin-top: 10%;
      }

      .icon {
        display: flex;
        width: 60px;
        height: 60px;

        fill: ${CSS_COLOR_HIGHLIGHT};

        margin-bottom: 10px;
      }

      .container-label-lock {
        width: fit-content;
      }

      .label {
        color: ${CSS_COLOR_HIGHLIGHT};
        font-size: 14px;
      }

      :host([layout='small']) .label {
        font-size: 14px;
      }
    `;
  }

  __renderIcon() {
    return html`
      ${
        this.pageExists
          ? html`
              <neb-icon
                id="${ELEMENTS.iconLock.id}"
                class="icon icon-lock"
                icon="neb:lock"
              ></neb-icon>
            `
          : html`
              <neb-icon
                id="${ELEMENTS.iconBrokenLink.id}"
                class="icon icon-broken-link"
                icon="neb:brokenLink"
              ></neb-icon>
            `
      }
    `;
  }

  __renderText() {
    return this.pageExists
      ? html`
          <div class="container-label-lock">
            <div id="${ELEMENTS.noAccessMessage.id}" class="label">
              You do not have access to view this page.
            </div>

            <div id="${ELEMENTS.noAccessMessageTwo.id}" class="label">
              Please contact your practice's Super User.
            </div>
          </div>
        `
      : html`
          <div id="${ELEMENTS.pageDoesNotExistMessage.id}" class="label">
            This page does not exist.
          </div>
        `;
  }

  render() {
    return html`
      <div
        class="${this.settingsNoAccess ? 'container-settings' : 'container'}"
      >
        ${this.__renderIcon()} ${this.__renderText()}
      </div>
    `;
  }
}

customElements.define('neb-no-access-page', NebNoAccessPage);
