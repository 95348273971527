import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const billingApiClient = new ApiClient({ microservice: 'billing' });

export const autoAllocatePayments = (body, optOutLoadingIndicator = true) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.POST,
    path: '/payments/auto-allocate',
    body,
    version: 1,
  });
