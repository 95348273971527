import './neb-claim-form-cell';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_3,
  CSS_SPACING,
} from '../../../../../neb-styles/neb-variables';
import { TOOLTIP_TEXT } from '../../../../../neb-utils/claims';

export const ELEMENTS = {
  practiceInfoCell: { id: 'practice-info' },
  NPICell: { id: 'NPI-cell' },
  otherIdCell: { id: 'other-id-cell' },
};

const formatToCamelCase = title => {
  const str = title.replace(/\s/g, '');
  return `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
};

class NebClaimFormCellPracticeInfo extends LitElement {
  static get properties() {
    return {
      renderExpandIcon: { type: Boolean, reflect: true },
      label: String,
      name: String,
      NPI: String,
      otherIdentifier: String,
      popupTitle: String,
      type: String,
      typeA: String,
      typeB: String,
      address: Object,
      errors: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, minmax(90px, auto));
        }

        .span-column-2 {
          grid-column: span 2;
        }

        .span-row-2 {
          grid-row: span 2;
        }

        .practice-info {
          padding: 13px ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .cell {
          display: grid;
          width: 100%;
        }

        .shaded {
          background-color: ${CSS_COLOR_GREY_3};
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.renderExpandIcon = false;
    this.label = '';
    this.name = '';
    this.NPI = '';
    this.otherIdentifier = '';
    this.popupTitle = '';
    this.type = '';
    this.typeA = '';
    this.typeB = '';
    this.address = {};
    this.errors = {
      address: '',
      NPI: '',
      otherId: '',
      otherIdQualifier: '',
    };

    this.onClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickPracticeInfoCell: claimCellData => {
        if (this.renderExpandIcon) {
          this.onClick({
            ...claimCellData,
            title: `${this.__FLNumber}. ${this.popupTitle}`,
          });
        }
      },
      clickACell: claimCellData => {
        if (this.renderExpandIcon) {
          this.onClick({
            ...claimCellData,
            title: `${this.__FLNumber}a. ${this.popupTitle}`,
          });
        }
      },
      clickBCell: claimCellData => {
        if (this.renderExpandIcon) {
          this.onClick({
            ...claimCellData,
            title: `${this.__FLNumber}b. ${this.popupTitle}`,
          });
        }
      },
    };
  }

  get __FLNumber() {
    if (!this.label) {
      return '';
    }

    return this.label.split('.')[0];
  }

  __renderPracticeInfo(
    name,
    { address1, address2, city, state, zipCode, phone },
  ) {
    return html`
      <div class="practice-info">
        <div>${phone}</div>
        <div>${name}</div>
        <div>${address1} ${address2}</div>
        <div>${city} ${state} ${zipCode}</div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container">
        <neb-claim-form-cell
          id="${ELEMENTS.practiceInfoCell.id}"
          class="cell span-column-2 span-row-2"
          name="${formatToCamelCase(this.popupTitle)}"
          .label="${this.label}"
          .problemText="${this.errors.address}"
          .tooltipText="${TOOLTIP_TEXT[`FL_${this.__FLNumber}`]}"
          .type="${this.type}"
          .onClick="${this.__handlers.clickPracticeInfoCell}"
          ?renderExpandIcon="${this.renderExpandIcon}"
          borderBottom
          borderRight
          >${
            this.__renderPracticeInfo(this.name, this.address)
          }</neb-claim-form-cell
        >

        <neb-claim-form-cell
          id="${ELEMENTS.NPICell.id}"
          class="cell"
          name="${formatToCamelCase(this.popupTitle)}NPI"
          .data="${this.NPI}"
          .label="${this.__FLNumber}a."
          .problemText="${this.errors.NPI}"
          .tooltipText="${TOOLTIP_TEXT[`FL_${this.__FLNumber}A`]}"
          .onClick="${this.__handlers.clickACell}"
          ?renderExpandIcon="${this.renderExpandIcon && this.typeA}"
          type="${this.typeA}"
          borderBottom
          borderRight
        ></neb-claim-form-cell>

        <neb-claim-form-cell
          id="${ELEMENTS.otherIdCell.id}"
          class="cell shaded"
          name="${formatToCamelCase(this.popupTitle)}OtherId"
          type="${this.typeB}"
          .data="${this.otherIdentifier}"
          .label="${this.__FLNumber}b."
          .tooltipText="${TOOLTIP_TEXT[`FL_${this.__FLNumber}B`]}"
          .onClick="${this.__handlers.clickBCell}"
          .problemText="${this.errors.otherId || this.errors.otherIdQualifier}"
          ?renderExpandIcon="${this.renderExpandIcon && this.typeB}"
          borderBottom
          borderRight
        ></neb-claim-form-cell>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-claim-form-cell-practice-info',
  NebClaimFormCellPracticeInfo,
);
