/* eslint-disable consistent-return */
/* eslint-disable complexity */
import '../../tables/claims/neb-table-all-claims';
import '../../filters/neb-filters-all-claims';
import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { html, css } from 'lit';

import { getAllClaims } from '../../../../packages/neb-api-client/src/claims';
import { getLedgerInvoiceItems } from '../../../../packages/neb-api-client/src/invoice-api-client';
import { getProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import { ISO_DATE_FORMAT } from '../../../../packages/neb-input/nebFormatUtils';
import {
  APPROVED_CLAIM_STATUSES,
  DENIED_AND_REJECTED_CLAIM_STATUSES,
  SUBMITTED_CLAIM_STATUSES,
} from '../../../../packages/neb-lit-components/src/components/claims/neb-claims-worklist-controller';
import { NEEDS_ATTENTION_CLAIM_STATUSES } from '../../../../packages/neb-lit-components/src/components/claims/status-controllers/neb-controller-needs-attention';
import {
  WORKLIST_ITEMS_POPUP_ACTIONS,
  openWorklistItemPopup,
} from '../../../../packages/neb-lit-components/src/components/claims/utils';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/neb-page-collection';
import { ELEMENTS as NEB_TEXT_ELEMENTS } from '../../../../packages/neb-lit-components/src/components/neb-text';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { BILLING_NOTE_TYPES } from '../../../../packages/neb-utils/constants';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import { EMPTY_RESPONSE } from '../../../../packages/neb-utils/services/fetch';
import { MODE } from '../../../../packages/neb-utils/table';
import { SORT_DIR } from '../../../utils/sort/sort';
import { NO_ITEMS_INITIAL_LOAD } from '../../../utils/user-message';

const CLAIM_BUCKET_NAVIGATION_STATUS = {
  READY_TO_SUBMIT: 'ready-to-submit',
  NEEDS_ATTENTION: 'needs-attention',
  SUBMITTED: 'submitted',
  DENIED: 'denied',
  APPROVED: 'approved',
};

const DATE_KEYS = ['dosFrom', 'dosTo', 'dateCreatedTo', 'dateCreatedFrom'];

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  filters: { id: 'filters' },
};

class NebPageAllClaims extends CollectionPage {
  static get properties() {
    return {
      patientId: String,
      __selectAll: Boolean,
      __hasDisableInitialLoadFF: Boolean,
      __hasAppliedFilters: Boolean,
      __retainedCheckedItems: Object,
      __userLocations: Array,
      __defaultLocationId: String,
      __providers: Array,
      __hasFitInvoiceOverlayPerformanceFF: Boolean,
      filterDefaults: Object,
    };
  }

  static get config() {
    return {
      unifyForm: true,
      useFetch: true,
      pageSize: 100,
      initialSortKey: 'dateOfService',
      initialSortOrder: SORT_DIR.DESC,
      overlayKey: OVERLAY_KEYS.LEDGER_GENERATE_CLAIM,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .table {
          flex: 1 0 0;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initServices();
    this.initHandlers();
  }

  initState() {
    super.initState();

    this.__hasDisableInitialLoadFF = false;
    this.__hasAppliedFilters = false;
    this.__defaultLocationId = '';
    this.__selectAll = false;
    this.__retainedCheckedItems = {};
    this.__userLocations = [];
    this.__providers = [];
    this.__hasFitInvoiceOverlayPerformanceFF = false;

    this.patientId = '';
    this.filterDefaults = {};
  }

  initServices() {
    this.__locationsService = new LocationsService(
      ({ userLocations, defaultLocationId }) => {
        this.__userLocations = userLocations.map(data => ({
          label: `${data.name}`,
          data,
        }));

        this.__defaultLocationId = defaultLocationId;
      },
    );
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      tableChange: e => {
        const terms = e.name.split('.');
        const index = terms[0];

        const updatedItem = { ...this.__tableState.pageItems[index] };
        updatedItem.checked = e.value;

        this.__tableState.pageItems.splice(index, 1, updatedItem);
        this.__tableState = {
          ...this.__tableState,
          pageItems: [...this.__tableState.pageItems],
        };
      },

      fetchData: async queries => {
        if (
          this.__hasDisableInitialLoadFF &&
          !this.__hasAppliedFilters &&
          !this.patientId
        ) {
          return EMPTY_RESPONSE;
        }

        const formattedQueries = this.formatQueries(queries);
        const { count, data } = await getAllClaims(formattedQueries);

        const claimsWithCheckboxes = data.reduce((acc, value) => {
          let checked = this.__selectAll;

          const retainedKeys = Object.keys(this.__retainedCheckedItems);

          if (retainedKeys.length && retainedKeys.includes(value.id)) {
            checked = this.__retainedCheckedItems[value.id];
          }
          acc.push({ ...value, checked });
          return acc;
        }, []);

        return { count, data: claimsWithCheckboxes };
      },

      selectAll: () => {
        this.__selectAll = true;
        this.__retainedCheckedItems = {};
        this.__tableState = this.__selectAllCheckboxesOnPage(true);
      },
      selectAllOnCurrentPage: () => {
        this.__selectAll = false;
        this.__tableState = this.__selectAllCheckboxesOnPage(true);
        this.__retainCheckedItems();
      },
      deselectAll: () => {
        this.__selectAll = false;
        this.__tableState = this.__selectAllCheckboxesOnPage(false);
      },
      confirmHide: async () => {
        if (this.__noneChecked()) return;

        await this.__openConfirmHidePopup();
      },
      confirmShow: async () => {
        if (this.__noneChecked()) return;

        await this.__openConfirmShowPopup();
      },
      openLink: config => this.__openLink(config),

      sort: (_name, result) => {
        this.service.setSortParams(result[0]);
      },

      applyFilters: (filterQueries, isButtonPress) => {
        if (isButtonPress) {
          this.__hasAppliedFilters = true;
        }

        Object.entries(this.filterDefaults).forEach(([k]) => {
          this.service.unsetQuery(k);
        });

        this.filterDefaults = {};

        Object.entries(filterQueries).forEach(([k, v]) => {
          if (v) {
            this.service.setQuery(k, v);
          } else {
            this.service.unsetQuery(k);
          }
        });

        this.__selectAll = false;
        this.__tableState = this.__selectAllCheckboxesOnPage(false);
        this.service.setPageIndex(0);
      },

      checkItem: ({ idx, id, value }) => {
        this.__tableState = this.__checkItem(idx);

        this.__retainedCheckedItems = {
          ...this.__retainedCheckedItems,
          [id]: !value,
        };
      },
    };
  }

  async connectedCallback() {
    this.__locationsService.connect();
    this.__providers = await getProviderUsers();

    this.__hasDisableInitialLoadFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    this.__hasFitInvoiceOverlayPerformanceFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.FIT_INVOICE_OVERLAY_PERFORMANCE,
    );

    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__locationsService.disconnect();
  }

  formatQueries(queries) {
    const { sortParams, params, headers, ...pagination } = queries;

    let newParams = params;

    if (newParams) {
      newParams = Object.fromEntries(
        Object.entries(newParams).map(([key, value]) => {
          if (DATE_KEYS.includes(key)) {
            const formattedDate = value.format(ISO_DATE_FORMAT);
            return [key, formattedDate];
          }

          return [key, value];
        }),
      );
    }

    const formattedQueries = {
      query: {
        ...(this.patientId && { patientId: this.patientId }),
        ...pagination,
        ...newParams,
        ...(sortParams.dir && { sortDir: sortParams.dir }),
        ...(sortParams.key && { sortKey: sortParams.key }),
        ...(!this.filterDefaults.notFirst && this.filterDefaults),
      },
      headers,
    };

    Object.entries(this.filterDefaults).forEach(([k, v]) => {
      this.service.setQuery(k, v);
    });

    this.filterDefaults.notFirst = true;

    return formattedQueries;
  }

  __getLocations() {
    return this.__userLocations.reduce((acc, value) => {
      acc.push({ ...value.data });
      return acc;
    }, []);
  }

  __getEmptyMessage() {
    return this.__hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patientId
      ? NO_ITEMS_INITIAL_LOAD
      : `There are no claims for this ${
          this.patientId ? 'patient' : 'practice'
        }.`;
  }

  __getClaimBucketNavigationStatus(claimStatus) {
    if (claimStatus) {
      switch (true) {
        case NEEDS_ATTENTION_CLAIM_STATUSES.some(
          status => status === claimStatus,
        ):
          return CLAIM_BUCKET_NAVIGATION_STATUS.NEEDS_ATTENTION;

        case SUBMITTED_CLAIM_STATUSES.some(status => status === claimStatus):
          return CLAIM_BUCKET_NAVIGATION_STATUS.SUBMITTED;

        case DENIED_AND_REJECTED_CLAIM_STATUSES.some(
          status => status === claimStatus,
        ):
          return CLAIM_BUCKET_NAVIGATION_STATUS.DENIED;

        case APPROVED_CLAIM_STATUSES.some(status => status === claimStatus):
          return CLAIM_BUCKET_NAVIGATION_STATUS.APPROVED;

        default:
          return CLAIM_BUCKET_NAVIGATION_STATUS.READY_TO_SUBMIT;
      }
    }

    return CLAIM_BUCKET_NAVIGATION_STATUS.READY_TO_SUBMIT;
  }

  __selectAllCheckboxesOnPage(checked) {
    this.__tableState.pageItems = this.__tableState.pageItems.map(item => ({
      ...item,
      checked,
    }));

    if (!checked) {
      this.__retainedCheckedItems = {};
    }

    return { ...this.__tableState };
  }

  __retainCheckedItems() {
    this.__retainedCheckedItems = this.__tableState.pageItems.reduce(
      (acc, row) => {
        acc[row.id] = row.checked;
        return acc;
      },
      this.__retainedCheckedItems,
    );
  }

  __checkItem(idx) {
    const checkedItem = this.__tableState.pageItems[idx];
    this.__tableState.pageItems[idx] = {
      ...checkedItem,
      checked: !checkedItem.checked,
    };

    return { ...this.__tableState };
  }

  async __openLink(config) {
    let overlayKey;
    let overlayAttributes;

    const {
      id,
      name: { key, rowIndex },
    } = config;
    const row = this.__tableState.pageItems[rowIndex];

    switch (key) {
      case 'claimNumber':
        if (id === NEB_TEXT_ELEMENTS.trailingIcon.id) {
          overlayKey = OVERLAY_KEYS.BILLING_NOTE;
          overlayAttributes = {
            parentType: BILLING_NOTE_TYPES.CLAIM,
            parentId: row.id,
            parentData: {
              datesOfService: parseDate(row.dateOfService).format('MM/DD/YYYY'),
              claimNumber: row.claimNumber,
              amount: row.amount,
              payer: row.insurancePayerAlias,
            },
            patientId: row.patientId,
          };
        } else {
          overlayKey = OVERLAY_KEYS.LEDGER_GENERATE_CLAIM;
          overlayAttributes = { claimId: row.id };
        }

        break;

      case 'patient': {
        const route = `/patients/${
          row.patientId
        }/claims/worklist/${this.__getClaimBucketNavigationStatus(row.status)}`;

        navigate(route);
        break;
      }

      case 'insurancePayerAlias':
        overlayKey = OVERLAY_KEYS.PAYER_PLAN;
        overlayAttributes = {
          id: row.payerPlanId,
        };

        break;

      case 'invoiceNumber':
        if (id === NEB_TEXT_ELEMENTS.trailingIcon.id) {
          overlayKey = OVERLAY_KEYS.BILLING_NOTE;
          overlayAttributes = {
            parentType: BILLING_NOTE_TYPES.INVOICE,
            parentId: row.invoiceId,
            parentData: {
              datesOfService: parseDate(row.dateOfService).format('MM/DD/YYYY'),
              invoice: row.invoiceNumber,
            },
            patientId: row.patientId,
          };
        } else {
          const ledgerInvoiceItems = await getLedgerInvoiceItems(row.invoiceId);
          const lineItemIds = ledgerInvoiceItems.data.map(li => li.id);

          overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
            ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
            : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

          overlayAttributes = {
            patient: {
              id: row.patientId,
            },
            lineItemIds,
            selectedIds: [],
          };
        }
        break;

      default:
    }

    if (overlayKey && overlayAttributes) {
      const result = await openOverlay(overlayKey, overlayAttributes);

      if (
        result ||
        overlayKey === OVERLAY_KEYS.LEDGER_GENERATE_CLAIM ||
        overlayKey === OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES ||
        overlayKey === OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
      ) {
        this.service.fetch();
      }
    }
  }

  async __openConfirmHidePopup() {
    const result = await openWorklistItemPopup(
      WORKLIST_ITEMS_POPUP_ACTIONS.HIDE,
      this.__checkedRowItems,
    );

    if (result) {
      this.service.fetch();
    }
  }

  async __openConfirmShowPopup() {
    const result = await openWorklistItemPopup(
      WORKLIST_ITEMS_POPUP_ACTIONS.SHOW,
      this.__checkedRowItems,
    );

    if (result) {
      this.service.fetch();
    }
  }

  async openItemOverlay(item) {
    await openOverlay(this.getConfig().overlayKey, {
      claimId: item.id,
    });

    this.service.fetch();
  }

  __noneChecked() {
    if (this.__checkedRowItems.length) {
      return false;
    }

    openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: 'Bulk Actions',
      message: 'Please select one or more claims before performing an action.',
    });

    return true;
  }

  get __checkedRowItems() {
    return this.__tableState.pageItems.filter(row => row.checked);
  }

  renderHeader() {
    return html`
      <neb-filters-all-claims
        id="${ELEMENTS.filters.id}"
        class="filters"
        ?enablePatient="${!this.patientId}"
        .userLocations="${this.__userLocations}"
        .defaultLocationId="${this.__defaultLocationId}"
        .providers="${this.__providers}"
        .onApply="${this.handlers.applyFilters}"
        .filterDefaults="${{ ...this.filterDefaults }}"
        .defaultLocationId="${this.__defaultLocationId}"
      ></neb-filters-all-claims>
    `;
  }

  renderPagination() {
    return this.__tableState.pageCount > 1 &&
      !this.getConfig().optOutCollectionPagination
      ? html`
          <div class="row row-margins">
            <div class="cell cell-spacer"></div>
            <div class="cell">
              <neb-pagination
                id="${ELEMENTS.pagination.id}"
                .pageCount="${this.__tableState.pageCount}"
                .currentPage="${this.__tableState.pageIndex}"
                .onPageChanged="${this.handlers.selectPage}"
                .debouncerDelay="${300}"
              ></neb-pagination>
            </div>
          </div>
        `
      : '';
  }

  renderTable() {
    return html`
      <neb-table-all-claims
        id="${ELEMENTS.table.id}"
        class="table"
        .mode="${MODE.DETAIL}"
        .model="${this.__tableState.pageItems}"
        .patientId="${this.patientId ? this.patientId : ''}"
        .locations="${this.__getLocations()}"
        .sortParams="${[this.__tableState.sortParams]}"
        .onSort="${this.handlers.sort}"
        .onChange="${this.handlers.tableChange}"
        .onCheck="${this.handlers.checkItem}"
        .onSelectRow="${this.handlers.selectItem}"
        .onSelectAll="${this.handlers.selectAll}"
        .onSelectAllOnCurrentPage="${this.handlers.selectAllOnCurrentPage}"
        .onDeselectAll="${this.handlers.deselectAll}"
        .onConfirmHide="${this.handlers.confirmHide}"
        .onConfirmShow="${this.handlers.confirmShow}"
        .onStoreCheckedItems="${this.handlers.confirmShow}"
        .onOpenLink="${this.handlers.openLink}"
        .emptyMessage="${this.__getEmptyMessage()}"
      ></neb-table-all-claims>
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        ${
          html`
            ${this.renderHeader()}

            <div class="row row-form">${this.renderContent()}</div>
          `
        }
      </div>
    `;
  }
}

customElements.define('neb-page-all-claims', NebPageAllClaims);
