/*
 remove workarounds once feature/select is merged
 https://github.com/material-components/material-components-web/tree/feature/select
                   BODY
                 _,--=--._
               ,'    _    `.
              -    _(_)_o   -
         ____'    /_  _/]    `____
  -=====::(+):::::::::::::::::(+)::=====-
           (+).""""""""""""",(+)
               .           ,
                 `  -=-
                  /      \
                 /        \
                /          \
                 select-menu
*/
import { MDCSelect } from '@material/select/index';
import { html } from 'lit';

import {
  NebMDBaseSelect,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-md-base-select';

export const ELEMENTS = { ...BASE_ELEMENTS };

class NebMDSelect extends NebMDBaseSelect {
  static get properties() {
    return {
      layout: {
        type: String,
      },
      value: {
        type: String,
      },
      useMinWidth: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    super.__initState();

    this._valid = true;
    this.layout = 'large';
    this.value = '';
    this.useMinWidth = false;
    this.externalValidator = null;
  }

  firstUpdated() {
    this.__initSelect();

    this._addCssToMenu();
  }

  async updated(changedProperties) {
    if (changedProperties.get('layout')) this.__refreshSelect();

    if (changedProperties.has('value') || changedProperties.has('items')) {
      this.select.selectedIndex = this.__getItemIndex();
    }
    this.select.disabled = this.disabled;
    this.select.helperTextContent = this.helperText;
    this.select.required = this.required;
    this.select.valid = this._valid;
    await this.__renderLayout();
  }

  updateValue() {
    if (this.__getItemIndex() === this.select.selectedIndex) return;
    this._valid = this.select.valid;
    const itemIndex =
      this.layout === 'small'
        ? this.select.selectedIndex - 1
        : this.select.selectedIndex;
    this.onChange({
      value: this.items[itemIndex],
      id: this.id,
      name: this.name,
    });
  }

  __validateSelect() {
    const itemIndex =
      this.layout === 'small'
        ? this.select.selectedIndex - 1
        : this.select.selectedIndex;

    return !!this.items[itemIndex];
  }

  __validateRequired() {
    return !this.required || (this.required && !!this.value);
  }

  __evaluateValidation() {
    let valid = true;
    if (this.externalValidator) valid = this.externalValidator();
    if (valid) valid = this.__validateSelect();

    return valid;
  }

  validate() {
    super.validate();

    const isValid =
      (this._valid || this.__evaluateValidation()) && this.__validateRequired();

    this._valid = isValid;
    this.select.valid = isValid;

    return isValid;
  }

  __getItemIndexIfItemsExists() {
    return this.items
      ? this.items.findIndex(
          item => JSON.stringify(item) === JSON.stringify(this.value),
        )
      : -1;
  }

  __getItemIndex() {
    const index = this.__getItemIndexIfItemsExists();

    if (index === -1) return index;
    if (this.layout === 'small') return index + 1;

    return index;
  }

  __initSelect() {
    this.select = MDCSelect.attachTo(
      this.shadowRoot.querySelector('.mdc-select'),
    );

    this.select.disabled = this.disabled;
    this.select.helperTextContent = this.helperText;
    this.select.required = this.required;
    this.select.valid = this._valid;
    this.select.selectedIndex = this.__getItemIndex();
    this.select.listen('MDCSelect:change', () => this.updateValue());
  }

  __refreshSelect() {
    this.__initSelect();

    this.select.valid = true;
  }

  __renderList() {
    if (this.layout === 'small') {
      return html`
        <i class="mdc-select__dropdown-icon"></i>
        <select class="mdc-select__native-control" id="${ELEMENTS.input.id}">
          <option value="" disabled selected></option>
          ${this.__renderOptionItems()}
        </select>
      `;
    }

    return html`
      <input type="hidden" name="enhanced-select" id="${ELEMENTS.input.id}" />
      <i class="mdc-select__dropdown-icon"></i>
      <div
        class="mdc-select__selected-text"
        id="${ELEMENTS.select.id}"
        @click="${() => this._calcCss()}"
      ></div>
      <div
        class="mdc-select__menu mdc-menu-surface"
        style="${this.useMinWidth ? 'min-' : ''}width:${
          this.renderWidth
        }px; z-index: ${this.zIndex};"
        id="${this.menuId}"
      >
        <ul class="mdc-list" id="${this.id}-list">
          ${this.__renderListItems()}
        </ul>
      </div>
    `;
  }

  __renderOptionItems() {
    return this.items
      ? this.items.map(item => {
          const itemText = this.computeDisplayValue(item);
          const idText =
            typeof itemText === 'string'
              ? `row_${itemText.replace(/[^a-zA-Z0-9]/g, '_')}`
              : `row_${itemText}`;

          return html`
            <option id="${idText}" value="${itemText}">${itemText}</option>
          `;
        })
      : '';
  }

  __renderListItems() {
    return this.items
      ? this.items.map(item => {
          const itemText = this.computeDisplayValue(item);
          const idText =
            typeof itemText === 'string'
              ? `row_${itemText.replace(/[^a-zA-Z0-9]/g, '_')}`
              : `row_${itemText}`;

          return html`
            <li class="mdc-list-item" id="${idText}" data-value="${itemText}">
              ${itemText}
            </li>
          `;
        })
      : '';
  }

  render() {
    return html`
      ${this._renderMDCStaticLabel()}
      <div
        class="mdc-select mdc-select--outlined"
        style="width:${this.width}"
        id="${ELEMENTS.selectInput.id}"
        aria-controls="${ELEMENTS.helperText.id}"
        aria-describedby="${ELEMENTS.helperText.id}"
      >
        ${this.__mdcNotchHTML()} ${this.__renderList()}
      </div>

      ${this.__mdcHelperTextHTML()}
    `;
  }
}

customElements.define('neb-md-select', NebMDSelect);
