import '../../../packages/neb-lit-components/src/components/neb-text';

import { LitElement, html, css } from 'lit';

import { INSURANCE_LEVELS } from '../../../packages/neb-lit-components/src/utils/insurance-util';
import { formatPatientInsurances } from '../../formatters/patient-insurance';
import { CSS_BORDER_GREY_2, CSS_COLOR_GREY_1, CSS_SPACING } from '../../styles';
import './neb-icon';
import './neb-insurance-card';

export const ELEMENTS = {
  container: { id: 'container' },
  label: { id: 'label' },
  icon: { id: 'icon' },
  insuranceCards: { selector: '[id^=insurance-card-' },
  insuranceCard: { id: 'insurance-card' },
  noItemsText: { id: 'no-items-text' },
};

class NebInsuranceHeader extends LitElement {
  static get properties() {
    return {
      icon: String,
      patientId: String,
      expanded: {
        type: Boolean,
        reflect: true,
      },
      patientInsurances: Array,
      __primaryInsurance: Object,
      __secondaryInsurance: Object,
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    this.expanded = false;
    this.patientId = '';

    this.patientInsurances = [];

    this.__primaryInsurance = null;
    this.__secondaryInsurance = null;

    this.onOpenInsuranceOverlay = () => {};
  }

  initHandlers() {
    this.__handlers = {
      toggleExpand: () => {
        this.expanded = !this.expanded;
      },
      openInsuranceOverlay: id => {
        this.onOpenInsuranceOverlay(id);
      },
    };
  }

  static get styles() {
    return [
      css`
        .container {
          padding: ${CSS_SPACING};
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
          cursor: pointer;
        }

        :host([expanded]) .icon {
          transform: rotate(180deg);
        }

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .icon {
          height: 10px;
          width: 10px;
        }

        .insurance-cards-container {
          margin-top: ${CSS_SPACING};
          display: flex;
          gap: 20px;
        }

        .insurance-card {
          flex: 1;
        }

        .label {
          text-decoration: underline;
        }

        .no-items {
          color: ${CSS_COLOR_GREY_1};
          padding-top: ${CSS_SPACING};
          font-style: italic;
        }
      `,
    ];
  }

  updated(changedProps) {
    if (changedProps.has('patientInsurances')) {
      if (this.patientInsurances.length) {
        const formattedInsurances = formatPatientInsurances(
          this.patientInsurances,
        );

        this.__primaryInsurance = formattedInsurances.find(
          insurance => insurance.defaultLevel === INSURANCE_LEVELS.PRIMARY,
        );

        this.__secondaryInsurance = formattedInsurances.find(
          insurance => insurance.defaultLevel === INSURANCE_LEVELS.SECONDARY,
        );
      }
    }
  }

  __renderInsuranceInformation() {
    if (!this.expanded) {
      return '';
    }

    if (!this.__primaryInsurance && !this.__secondaryInsurance) {
      return html`
        <div id="${ELEMENTS.noItemsText.id}" class="no-items">
          There are no insurances for this patient.
        </div>
      `;
    }

    const cards = [this.__primaryInsurance, this.__secondaryInsurance].map(
      insurance =>
        insurance
          ? html`
              <neb-insurance-card
                id="insurance-card-${insurance.defaultLevel.toLowerCase()}"
                class="insurance-card"
                .model="${insurance}"
                .onOpenInsuranceOverlay="${
                  this.__handlers.openInsuranceOverlay
                }"
              ></neb-insurance-card>
            `
          : '',
    );

    return html`
      <div id="${ELEMENTS.insuranceCards.id}" class="insurance-cards-container">
        ${cards}
      </div>
    `;
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.container.id}"
        class="container"
        @click="${this.__handlers.toggleExpand}"
      >
        <div class="header">
          <neb-text id="${ELEMENTS.label.id}" class="label" bold
            >Insurance Information</neb-text
          >
          <div>
            <neb-icon
              id="${ELEMENTS.icon.id}"
              icon="neb:chevron"
              class="icon"
            ></neb-icon>
          </div>
        </div>
        ${this.__renderInsuranceInformation()}
      </div>
    `;
  }
}

window.customElements.define('neb-insurance-header', NebInsuranceHeader);
