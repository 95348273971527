import { css, html, LitElement } from 'lit';

import {
  baseStyles,
  CSS_SPACING,
  layoutStyles,
  CSS_SPACING_ROW,
} from '../../../../../../src/styles';
import { alphanumeric } from '../../../../../neb-utils/masks';
import '../../../../../../src/components/controls/inputs/neb-checkbox';

import '../../controls/neb-switch';
import '../../inputs/neb-textfield';
import '../../neb-header';
import '../../neb-tooltip';
import '../../neb-radio-button';

export const ELEMENTS = {
  header: { id: 'header' },
  radioButtonPayerId: { id: 'radio-button-payer-id' },
  radioButtonPortal: { id: 'radio-button-portal' },
  switch: { id: 'switch-real-time-eligibility' },
  textfieldPayerId: { id: 'textfield-payer-id' },
  textfieldPortalId: { id: 'textfield-portal-id' },
  textfieldPortalPassword: { id: 'textfield-portal-password' },
  textfieldPortalUsername: { id: 'textfield-portal-username' },
  headerPayerSpecific: {
    id: 'header-payer-specific',
    title: 'Payer Specific',
  },
  checkboxNoFutureDates: { id: 'checkbox-no-future-dates' },
  headerAutoUpdate: {
    id: 'header-auto-update',
    title: 'Auto-Update Insurance Policy Benefits Coverage Amounts',
  },
  checkboxCopay: { id: 'checkbox-copay' },
  checkboxCoinsurance: { id: 'checkbox-coinsurance' },
  checkboxDeductible: { id: 'checkbox-deductible' },
  checkboxMoop: { id: 'checkbox-moop' },
};

class ViewRealTimeEligibility extends LitElement {
  static get properties() {
    return {
      errors: Object,
      model: Object,
      hasRteFF: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .grid {
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
        }

        .container {
          display: flex;
          align-items: center;
        }

        .tooltip {
          margin-left: 10px;
        }

        .padding-top {
          padding-top: ${CSS_SPACING};
        }

        .checkboxes {
          margin-left: ${CSS_SPACING};
        }

        .payer-specific-and-auto-update-checkboxes {
          padding-left: ${CSS_SPACING};
        }

        .auto-update-group {
          padding-top: 40px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.hasRteFF = false;
    this.errors = {
      realTimeEligibilityEnabled: '',
      realTimeEligibilityPayerId: '',
      realTimeEligibilityPortalId: '',
      realTimeEligibilityPortalPassword: '',
      realTimeEligibilityPortalUsername: '',
      useRealTimeEligibilityPortal: '',
      realTimeEligibilityNoFutureDates: '',
    };

    this.model = {
      realTimeEligibilityEnabled: false,
      realTimeEligibilityPayerId: '',
      realTimeEligibilityPortalId: '',
      realTimeEligibilityPortalPassword: '',
      realTimeEligibilityPortalUsername: '',
      useRealTimeEligibilityPortal: false,
      realTimeEligibilityNoFutureDates: false,
      autoUpdateCopay: false,
    };

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e => this.onChange(e),
      changePayerId: e => this.onChange({ ...e, value: false }),
      changePortal: e => this.onChange({ ...e, value: true }),
    };
  }

  __getPortalError(error) {
    return this.model.useRealTimeEligibilityPortal ? error : '';
  }

  __renderAutoUpdateOptions() {
    return this.hasRteFF
      ? html`
          <neb-header
            class="auto-update-group"
            .label="${ELEMENTS.headerAutoUpdate.title}"
          ></neb-header>

          <div class="payer-specific-and-auto-update-checkboxes">
            <neb-checkbox
              id="${ELEMENTS.checkboxCopay.id}"
              class="checkboxes"
              label="Copay"
              name="autoUpdateCopay"
              .checked="${!!this.model.autoUpdateCopay}"
              .onChange="${this.__handlers.change}"
            ></neb-checkbox>

            <neb-checkbox
              id="${ELEMENTS.checkboxCoinsurance.id}"
              class="checkboxes"
              label="Coinsurance"
              name="autoUpdateCoinsurance"
              .checked="${!!this.model.autoUpdateCoinsurance}"
              .onChange="${this.__handlers.change}"
            ></neb-checkbox>

            <neb-checkbox
              id="${ELEMENTS.checkboxDeductible.id}"
              class="checkboxes"
              label="Deductible"
              name="autoUpdateDeductible"
              .checked="${!!this.model.autoUpdateDeductible}"
              .onChange="${this.__handlers.change}"
            ></neb-checkbox>

            <neb-checkbox
              id="${ELEMENTS.checkboxMoop.id}"
              class="checkboxes"
              label="Max Out Of Pocket"
              name="autoUpdateOutOfPocket"
              .checked="${!!this.model.autoUpdateOutOfPocket}"
              .onChange="${this.__handlers.change}"
            ></neb-checkbox>
          </div>
        `
      : '';
  }

  __renderRealTimeEligibility() {
    return this.model.realTimeEligibilityEnabled
      ? html`
          <div class="grid grid-2 grid-lean" style="align-items: start">
            <div class="grid grid-lean">
              <div class="grid grid-lean grid-auto-left">
                <neb-radio-button
                  id="${ELEMENTS.radioButtonPayerId.id}"
                  name="useRealTimeEligibilityPortal"
                  label="Real-Time Eligibility ID"
                  .onChange="${this.__handlers.changePayerId}"
                  ?checked="${!this.model.useRealTimeEligibilityPortal}"
                  ?disabled="${!this.model.realTimeEligibilityEnabled}"
                ></neb-radio-button>
              </div>

              <neb-textfield
                id="${ELEMENTS.textfieldPayerId.id}"
                name="realTimeEligibilityPayerId"
                label="Real-Time Eligibility ID"
                .error="${
                  !this.model.useRealTimeEligibilityPortal
                    ? this.errors.realTimeEligibilityPayerId
                    : ''
                }"
                .helper="${
                  this.model.useRealTimeEligibilityPortal ? '' : 'Required'
                }"
                .mask="${alphanumeric}"
                .value="${this.model.realTimeEligibilityPayerId}"
                .onChange="${this.__handlers.change}"
                ?disabled="${this.model.useRealTimeEligibilityPortal}"
              ></neb-textfield>
            </div>

            <div class="grid grid-lean">
              <div class="grid grid-lean grid-auto-left">
                <neb-radio-button
                  id="${ELEMENTS.radioButtonPortal.id}"
                  name="useRealTimeEligibilityPortal"
                  label="Real-Time Eligibility Portal"
                  .onChange="${this.__handlers.changePortal}"
                  ?checked="${this.model.useRealTimeEligibilityPortal}"
                  ?disabled="${!this.model.realTimeEligibilityEnabled}"
                ></neb-radio-button>
              </div>

              <neb-textfield
                id="${ELEMENTS.textfieldPortalId.id}"
                name="realTimeEligibilityPortalId"
                label="Payer RTE ID"
                .error="${
                  this.__getPortalError(this.errors.realTimeEligibilityPortalId)
                }"
                .helper="${
                  !this.model.useRealTimeEligibilityPortal ? ' ' : 'Required'
                }"
                .mask="${alphanumeric}"
                .value="${this.model.realTimeEligibilityPortalId}"
                .onChange="${this.__handlers.change}"
                ?disabled="${!this.model.useRealTimeEligibilityPortal}"
              ></neb-textfield>

              <neb-textfield
                id="${ELEMENTS.textfieldPortalUsername.id}"
                name="realTimeEligibilityPortalUsername"
                label="Payer Portal Username"
                .error="${
                  this.__getPortalError(
                    this.errors.realTimeEligibilityPortalUsername,
                  )
                }"
                .helper="${
                  !this.model.useRealTimeEligibilityPortal ? ' ' : 'Required'
                }"
                .value="${this.model.realTimeEligibilityPortalUsername}"
                .onChange="${this.__handlers.change}"
                ?disabled="${!this.model.useRealTimeEligibilityPortal}"
              ></neb-textfield>

              <neb-textfield
                id="${ELEMENTS.textfieldPortalPassword.id}"
                name="realTimeEligibilityPortalPassword"
                label="Payer Portal Password"
                type="password"
                .error="${
                  this.__getPortalError(
                    this.errors.realTimeEligibilityPortalPassword,
                  )
                }"
                .helper="${
                  !this.model.useRealTimeEligibilityPortal ? ' ' : 'Required'
                }"
                .value="${this.model.realTimeEligibilityPortalPassword}"
                .onChange="${this.__handlers.change}"
                ?disabled="${!this.model.useRealTimeEligibilityPortal}"
              ></neb-textfield>
            </div>
          </div>

          <neb-header
            .label="${ELEMENTS.headerPayerSpecific.title}"
          ></neb-header>

          <div class="payer-specific-and-auto-update-checkboxes">
            <neb-checkbox
              id="${ELEMENTS.checkboxNoFutureDates.id}"
              class="checkboxes"
              label="No Future Dates Allowed"
              name="realTimeEligibilityNoFutureDates"
              .checked="${!!this.model.realTimeEligibilityNoFutureDates}"
              .onChange="${this.__handlers.change}"
            ></neb-checkbox>
          </div>
          ${this.__renderAutoUpdateOptions()}
        `
      : '';
  }

  render() {
    return html`
      <div class="grid grid-lean">
        <neb-header
          id="${ELEMENTS.header.id}"
          class="padding-top"
          label="Real-Time Eligibility"
          description="Enables the ability to add a new insurance or verify a patient's existing insurance at any time."
        ></neb-header>

        <div class="grid">
          <neb-switch
            id="${ELEMENTS.switch.id}"
            name="realTimeEligibilityEnabled"
            label="Enable Real-Time Eligibility"
            .onChange="${this.__handlers.change}"
            ?on="${this.model.realTimeEligibilityEnabled}"
          ></neb-switch>

          ${this.__renderRealTimeEligibility()}
        </div>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-view-real-time-eligibility',
  ViewRealTimeEligibility,
);
