import '../../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { CSS_COLOR_GREY_1 } from '../../../../../src/styles';
import { openWarning } from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_FONT_SIZE_HEADER,
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_WARNING_COLOR,
} from '../../../../neb-styles/neb-variables';
import {
  hasAuthorizationRemaining,
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
} from '../../../../neb-utils/patientAuthorization';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import '../neb-text';

export const ELEMENTS = {
  appointmentType: {
    id: 'type',
  },
  provider: {
    id: 'provider',
  },
  startDate: {
    id: 'start-date',
  },
  location: {
    id: 'location',
  },
  timeDuration: {
    id: 'time',
  },
  patientCase: {
    id: 'case',
  },
  patientAuthorization: {
    id: 'authorization',
  },
  scheduledRoom: {
    id: 'scheduled-room',
  },
  iconAuthorizationWarning: {
    id: 'authorization-warning',
  },
  address: {
    id: 'address',
  },
};

class NebAppointmentDetailsLit extends LitElement {
  static get properties() {
    return {
      layout: {
        type: String,
        reflect: true,
      },
      appointmentDetails: {
        type: Object,
      },
      hideDate: {
        type: Boolean,
        reflect: true,
      },
      hideProvider: {
        type: Boolean,
        reflect: true,
      },
      patientCaseName: {
        type: String,
        reflect: true,
      },
      patientAuthorizationName: {
        type: String,
        reflect: true,
      },
      scheduledRoomName: {
        type: String,
      },
      address: {
        type: String,
      },
      selectedAuthorization: {
        type: Object,
      },
      showAuthorizationWarningOnLoad: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.appointmentDetails = {};
    this.selectedAuthorization = null;
    this.hideDate = false;
    this.hideProvider = false;
    this.patientCaseName = '';
    this.patientAuthorizationName = '';
    this.scheduledRoomName = '';
    this.address = '';
    this.showAuthorizationWarningOnLoad = false;

    this.onAuthorizationDetailChanged = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      openUpdateAuthorization: async () => {
        const result = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          patientId: this.selectedAuthorization.patientId,
          patientCaseId: this.selectedAuthorization.patientCaseId,
          id: this.selectedAuthorization.id,
        });

        if (result) this.onAuthorizationDetailChanged();
      },
      authorizationWarningClick: () => this.__showAuthorizationWarning(),
    };
  }

  __showAuthorizationWarning() {
    if (this.layout === 'small') {
      store.dispatch(openWarning(NO_REMAINING_AUTHORIZATIONS_MESSAGE));
    } else {
      store.dispatch(
        openWarning(
          NO_REMAINING_AUTHORIZATIONS_MESSAGE,
          this.__handlers.openUpdateAuthorization,
        ),
      );
    }
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }

      :host(:not([hideProvider])) .container-date-time,
      :host(:not([patientCaseName=''])) .container-date-time {
        margin-bottom: ${CSS_SPACING};
      }

      :host(:not([hideProvider])) .container-location,
      :host(:not([patientCaseName=''])) .container-location {
        margin-bottom: ${CSS_SPACING};
      }

      :host(:not([patientCaseName=''])) .container-provider {
        margin-bottom: ${CSS_SPACING};
      }

      :host(:not([patientAuthorizationName=''])) .container-patient-case {
        margin-bottom: ${CSS_SPACING};
      }

      .label-display-date,
      .label-display-time,
      .label-appointment-type,
      .label-patient-case-title,
      .label-patient-auth-title,
      .label-location {
        margin: 0;
        font-size: ${CSS_FONT_SIZE_HEADER};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }

      .label-address,
      .label-provider-name {
        margin: 0;
        font-size: ${CSS_FONT_SIZE_HEADER};
      }

      .label-patient-case,
      .label-scheduled-room,
      .label-patient-auth {
        margin: 0;
        font-size: ${CSS_FONT_SIZE_HEADER};
        overflow-wrap: break-word;
      }

      .icon-authorization-warning {
        display: block;
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-right: 10px;

        fill: ${CSS_WARNING_COLOR};
      }

      .container-authorization {
        display: flex;
      }

      .provider-placeholder {
        color: ${CSS_COLOR_GREY_1};
        font-style: italic;
      }

      .label-split {
        display: flex;
        flex-direction: row;
        gap: 16px;
        margin: 0;
        font-size: ${CSS_FONT_SIZE_HEADER};
        overflow-wrap: break-word;
      }

      .label-split-item {
        word-spacing: normal;
      }
    `;
  }

  updated(changedProps) {
    if (
      changedProps.has('selectedAuthorization') &&
      this.showAuthorizationWarningOnLoad &&
      !hasAuthorizationRemaining(this.selectedAuthorization)
    ) {
      this.__showAuthorizationWarning();
    }
  }

  __renderDateTime() {
    return !this.hideDate
      ? html`
          <div class="container-date-time">
            <p id="${ELEMENTS.startDate.id}" class="label-display-date">
              ${this.appointmentDetails.startDisplayDate}
            </p>

            <p id="${ELEMENTS.timeDuration.id}" class="label-display-time">
              ${this.appointmentDetails.durationDisplay}
            </p>
          </div>
        `
      : '';
  }

  __renderLocation() {
    let scheduledRoomHTML = html``;

    if (this.scheduledRoomName && !this.appointmentDetails.appointmentSplits) {
      scheduledRoomHTML = html`
        <div id="${ELEMENTS.scheduledRoom.id}" class="label-scheduled-room">
          ${this.scheduledRoomName}
        </div>
      `;
    }

    if (this.appointmentDetails.appointmentSplits) {
      scheduledRoomHTML = this.appointmentDetails.appointmentSplits.map(
        split => html`
          <div id="${ELEMENTS.scheduledRoom.id}" class="label-split">
            <div class="label-split-item">${split.start}</div>
            <div class="label-split-item">${split.roomName}</div>
            <div class="label-split-item">${split.duration}</div>
          </div>
        `,
      );
    }

    return html`
      <div class="container-location">
        <p id="${ELEMENTS.location.id}" class="label-location">
          ${this.appointmentDetails.location}
        </p>

        ${
          this.address
            ? html`
                <div id="${ELEMENTS.address.id}" class="label-address">
                  ${this.address}
                </div>
              `
            : ''
        }
        ${scheduledRoomHTML}
      </div>
    `;
  }

  __renderProvider() {
    const {
      appointmentTypeName,
      providerDisplayName,
    } = this.appointmentDetails;

    return !this.hideProvider
      ? html`
          <div class="container-provider">
            <p
              id="${ELEMENTS.appointmentType.id}"
              class="label-appointment-type"
            >
              ${appointmentTypeName}
            </p>

            <p id="${ELEMENTS.provider.id}" class="label-provider-name">
              ${
                providerDisplayName ||
                  html`
                    <span class="provider-placeholder"
                      >No Provider Scheduled</span
                    >
                  `
              }
            </p>
          </div>
        `
      : '';
  }

  __renderAppointmentCase() {
    return this.patientCaseName
      ? html`
          <div class="container-patient-case">
            <div class="label-patient-case-title">Case</div>

            <div id="${ELEMENTS.patientCase.id}" class="label-patient-case">
              ${this.patientCaseName}
            </div>
          </div>
        `
      : '';
  }

  __renderNoRemainingAuthorizations() {
    return hasAuthorizationRemaining(this.selectedAuthorization)
      ? ''
      : html`
          <neb-icon
            id="${ELEMENTS.iconAuthorizationWarning.id}"
            class="icon-authorization-warning"
            icon="neb:warning"
            @click="${this.__handlers.authorizationWarningClick}"
          ></neb-icon>
        `;
  }

  __renderAuthorizationLink() {
    return this.layout === 'small'
      ? html`
          ${this.__renderNoRemainingAuthorizations()}
          <neb-text
            id="${ELEMENTS.patientAuthorization.id}"
            class="label-patient-auth"
            >${this.patientAuthorizationName}</neb-text
          >
        `
      : html`
          ${this.__renderNoRemainingAuthorizations()}
          <neb-text
            id="${ELEMENTS.patientAuthorization.id}"
            class="label-patient-auth"
            link
            .onClick="${this.__handlers.openUpdateAuthorization}"
            >${this.patientAuthorizationName}</neb-text
          >
        `;
  }

  __renderAppointmentAuthorization() {
    return this.patientAuthorizationName && this.patientCaseName
      ? html`
          <div class="label-patient-auth-title">Authorization</div>
          <div class="container-authorization">
            ${this.__renderAuthorizationLink()}
          </div>
        `
      : '';
  }

  render() {
    return this.appointmentDetails
      ? html`
          <div class="container">
            ${this.__renderDateTime()} ${this.__renderLocation()}
            ${this.__renderProvider()} ${this.__renderAppointmentCase()}
            ${this.__renderAppointmentAuthorization()}
          </div>
        `
      : '';
  }
}

customElements.define('neb-appointment-details-lit', NebAppointmentDetailsLit);
