import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../../packages/neb-lit-components/src/components/patients/neb-patient-card';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../../../../packages/neb-lit-components/src/components/neb-header';
import '../../../tables/ledger/payments/neb-table-ledger-split-payments';
import '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../tables/ledger/payments/neb-table-ledger-split-history';

import { getValueByPath } from '@neb/form-service/src/utils';
import { atMax, isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { html, css } from 'lit';

import * as patientApiClient from '../../../../../packages/neb-api-client/src/patient-api-client';
import {
  getPayerPlan,
  getPayerPlans,
} from '../../../../../packages/neb-api-client/src/payer-plan-api-client';
import { getElectronicPayment } from '../../../../../packages/neb-api-client/src/payments/electronic-payments-api-client';
import {
  printReceipt,
  updatePaymentLocation,
} from '../../../../../packages/neb-api-client/src/payments-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../packages/neb-dialog/neb-banner-state';
import { getClaimsToChangeStatus } from '../../../../../packages/neb-lit-components/src/components/claims/utils';
import Form, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../../packages/neb-redux/neb-redux-store';
import { CLAIM_STATUS } from '../../../../../packages/neb-utils/claims';
import { createServerPatientsCollection } from '../../../../../packages/neb-utils/collections/server-patients';
import { parseDate } from '../../../../../packages/neb-utils/date-util';
import {
  centsToCurrency,
  currencyToCents,
  currencyToCentsWithNegative,
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../../packages/neb-utils/formatters';
import { fetchPracticeUsers } from '../../../../../packages/neb-utils/neb-ledger-util';
import { formatRefundedSplitPayment } from '../../../../../packages/neb-utils/neb-payment-util';
import { printPdf } from '../../../../../packages/neb-utils/neb-pdf-print-util';
import * as selectors from '../../../../../packages/neb-utils/selectors';
import { padArray } from '../../../../../packages/neb-utils/utils';
import { CSS_SPACING } from '../../../../styles';
import { openPayment } from '../../../../utils/payment-util';
import { SELECT_LOCATION_MESSAGE_FOR_RECEIPTS } from '../../../../utils/user-message';

export const TABS = {
  SPLITS: 'splits',
  HISTORY: 'history',
};

const PAYER_TYPE = {
  PATIENT: 'patient',
  BOUND: 'bound',
  UNBOUND: 'unbound',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  tabGroup: { id: 'tab-group' },
  parentPaymentTable: { id: 'parent-payment-table' },
  header: { id: 'header' },
  splitsTable: { id: 'splits-table' },
  historyTable: { id: 'history-table' },
  editButton: { id: 'edit-button' },
  addButton: { id: 'add-button' },
  remainderButton: { id: 'remainder-button' },
  paymentId: { id: 'payment-id' },
  patientSearch: { id: 'patient-search' },
  receiptButton: { id: 'receipt-button' },
  payerSearch: { id: 'payer-search' },
  buttonActions: { id: 'button-actions' },
  parentPaymentLink: { id: 'parent-payment-link' },
};

export const SUCCESS_MESSAGE = 'Payment split successfully.';

export const ERROR_MESSAGE = 'Error occurred when splitting payment.';

class NebFormSplitPayment extends Form {
  static get properties() {
    return {
      __edit: Boolean,
      __patientSearchItems: Array,
      __payerPlans: Array,
      __payerPlanSearchItems: Array,
      __splitPatients: Array,
      __patientDisplayValue: String,
      __remainder: String,
      __searchError: String,
      __selectedTab: String,
      __payerType: String,
      __selectedPatient: Object,
      __selectedPayer: Object,
      __payerPlan: Object,
      __parentTableModel: Object,
      __locationId: String,

      locations: Array,
      hasSplits: Boolean,
      history: Array,
      parentPayment: Object,
      fromEraEobPage: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          flex: 1 0 auto;
        }

        .container {
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
        }

        .add-row {
          padding-left: ${CSS_SPACING};
          display: grid;
          grid-template-columns: min-content min-content min-content min-content;
          grid-column-gap: ${CSS_SPACING};
          white-space: nowrap;
          align-items: center;
        }

        .search {
          width: 400px;
        }

        .remainder-value {
          padding-left: 7px;
        }

        .text-link {
          max-width: 250px;
        }

        .buttons-bar {
          display: flex;
          align-items: center;
          height: ${CSS_SPACING};
        }

        .button-actions {
          width: ${CSS_SPACING};
        }

        .receipt {
          padding-left: ${CSS_SPACING};
          width: fit-content;
        }
      `,
    ];
  }

  static createModel() {
    return {
      splits: [NebFormSplitPayment.createItem()],
    };
  }

  static createItem() {
    return {
      patientId: '',
      payerPlanId: '',
      amount: 0,
      preserve: 0,
      status: '',
      progenitor: false,
    };
  }

  createSelectors() {
    return {
      children: {
        splits: {
          createItem: () => NebFormSplitPayment.createItem(),
          format: v => padArray(v, NebFormSplitPayment.createItem()),
          unformat: v => v.filter(item => item.patientId && item.amount),
          children: {
            $: {
              children: {
                patientId: {
                  ...selectors.select(
                    this.__splitPatients || [],
                    selectors.ITEM_EMPTY,
                  ),
                  format: v => {
                    const patient = this.__splitPatients.find(
                      item => item.data.id === v,
                    );
                    return patient || selectors.ITEM_EMPTY;
                  },
                  unformat: v => v.data.id,
                  validators: [],
                },
                payerPlanId: {
                  ...selectors.select(
                    this.__payerPlans || [],
                    selectors.ITEM_EMPTY,
                  ),
                  format: v => {
                    const payerPlan = this.__payerPlans.find(
                      item => item.data.id === v,
                    );
                    return payerPlan || selectors.ITEM_EMPTY;
                  },
                  unformat: v => v.data.id,
                  validators: [],
                },
                preserve: selectors.currency(),
                amount: selectors.currency({
                  validateRaw: true,
                  validators: [
                    isRequired(),
                    atMax(this.parentPayment.amount, true, '<= Total'),
                    {
                      error: 'Remainder not 0',
                      validate: (v, _, rawData) => {
                        const diff = rawData.splits.reduce(
                          (sum, a) => sum + a.amount,
                          0,
                        );

                        return this.parentPayment.amount - diff === 0;
                      },
                    },
                    {
                      error: '>= Minimum',
                      validate: (v, keyPath, rawData) => {
                        const checkPath = [...keyPath];
                        checkPath.splice(2, 1, 'preserve');
                        const preserve = getValueByPath(rawData, checkPath);

                        return v >= preserve;
                      },
                    },
                  ],
                }),
                progenitor: {},
              },
            },
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.__locationId = '';
    this.__edit = false;
    this.__patientSearchItems = [];
    this.__payerPlanSearchItems = [];
    this.__splitPatients = [];
    this.__splitPayerPlans = [];
    this.__payerPlans = [];
    this.__patientDisplayValue = '';
    this.__payerType = '';
    this.__remainder = '$0.00';
    this.__searchError = '';
    this.__selectedTab = TABS.SPLITS;
    this.__selectedPatient = selectors.ITEM_EMPTY;
    this.__selectedPayer = { data: { id: '', payerName: '' }, label: '' };
    this.__payerPlan = {};
    this.__parentTableModel = {
      patientName: '',
      transactionDate: '',
      paymentNumber: '',
      payerName: '',
      paymentMethod: '',
      amount: 0,
    };

    this.__navItems = [];
    this.__users = [];
    this.__removedSplitsWithAssociatedClaims = [];

    this.locations = [];
    this.hasSplits = false;
    this.confirmLabel = 'Split';
    this.parentPayment = {
      patientId: '',
      paymentNumber: '',
      amount: 0,
      payerName: '',
    };

    this.history = [];
    this.fromEraEobPage = false;
    this.model = NebFormSplitPayment.createModel();
    this.__menuItems = Object.freeze({
      SELECT_LOCATION: {
        label: 'Select Location',
        onSelect: () => this.handlers.printPaymentForLocation(),
      },
    });

    this.onDismiss = () => {};

    this.onRefetchSplits = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: e => {
        const path = e.name.split('.');
        const key = path[2];

        this.formService.apply(e.name, e.value);

        if (key === 'amount') {
          this.__setRemainder();
          this.__validateAmounts();
        }
      },
      addItem: () => {
        switch (this.__payerType) {
          case PAYER_TYPE.PATIENT: {
            this.__addPatientRow();
            break;
          }

          case PAYER_TYPE.BOUND: {
            this.__addBoundRow();
            break;
          }

          case PAYER_TYPE.UNBOUND: {
            this.__addUnboundRow();
            break;
          }
          default:
            break;
        }
      },
      addItemRemainder: () => {
        if (!this.__hasNoRemainder()) {
          switch (this.__payerType) {
            case PAYER_TYPE.PATIENT: {
              this.__addPatientRow(true);
              break;
            }

            case PAYER_TYPE.BOUND: {
              this.__addBoundRow(true);
              break;
            }

            case PAYER_TYPE.UNBOUND: {
              this.__addUnboundRow(true);
              break;
            }
            default:
              break;
          }
        }

        this.__setRemainder();
        this.__validateAmounts();
      },
      removeItem: (name, _, index) => {
        if (this.__canSplitBeRemoved(index)) {
          if (
            this.state.splits[index].claims &&
            this.state.splits[index].claims.length
          ) {
            this.__removedSplitsWithAssociatedClaims.push(
              this.state.splits[index],
            );
          }
          this.formService.removeItem(name, index);
          this.__setRemainder();
        } else {
          this.errors.splits[index].amount = 'Payment partially allocated';

          this.requestUpdate();
        }
      },
      split: async () => {
        const model = this.formService.build();

        if (this.formService.validate()) {
          this.__searchError = '';
          this.__saving = true;

          let claimsToUpdateStatus = null;

          if (this.__removedSplitsWithAssociatedClaims.length) {
            const filteredClaims = this.__removedSplitsWithAssociatedClaims
              .flatMap(split =>
                split.claims.map(claim => ({
                  ...claim,
                  id: claim.claimId,
                  status: claim.claimStatus,
                })),
              )
              .filter(
                c => c.claimStatus !== CLAIM_STATUS.ERA_EOB_RECEIVED_APPROVED,
              );

            if (filteredClaims.length) {
              const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
                title:
                  'A claim has been generated and associated for some of the removed split payments',
                message:
                  'Would you like to update the status of these associated claims?',
                confirmText: 'Yes',
                cancelText: 'No',
              });

              if (result) {
                claimsToUpdateStatus = await getClaimsToChangeStatus({
                  claims: filteredClaims,
                });
              }
            }
          }

          const success = await this.onSave(model, claimsToUpdateStatus);

          if (success) {
            this.__edit = false;
            this.__selectedPatient = selectors.ITEM_EMPTY;
            this.__selectedPayer = {
              data: { id: '', payerName: '' },
              label: '',
            };

            store.dispatch(openSuccess(SUCCESS_MESSAGE));
          } else {
            store.dispatch(openError(ERROR_MESSAGE));
          }
        }
      },
      changePatient: ({ value }) => {
        this.__searchError = '';
        this.__selectedPatient = value;
      },
      requestPatients: () => this.__patientService.fetchMore(),
      searchPatients: e => this.__patientService.search(e.value),
      renderPatient: model => html`
        <neb-patient-card
          tabindex="0"
          .model="${model.data}"
        ></neb-patient-card>
      `,
      changePayer: ({ value }) => {
        this.__searchError = '';
        this.__selectedPayer = value;
      },
      searchPayers: e => {
        const searchTerms = e.value
          .toLowerCase()
          .trim()
          .split(' ');

        this.__payerPlanSearchItems = this.__payerPlans.filter(f =>
          searchTerms.every(v => f.label.toLowerCase().search(v) !== -1),
        );
      },
      changeMode: () => {
        this.__edit = true;
        this.__setRemainder();

        if (this.__payerType === PAYER_TYPE.BOUND) {
          this.__selectedPatient = this.__splitPatients[0];
          this.__selectedPayer = this.__payerPlans[0];
        }
      },
      cancel: () => {
        this.formService.reset();
        this.__edit = false;
        this.__searchError = '';
        this.__removedSplitsWithAssociatedClaims = [];
        this.__selectedPatient = selectors.ITEM_EMPTY;
        this.__selectedPayer = {
          data: { id: '', payerName: '' },
          label: '',
        };
      },
      navigateToPatientLedger: index => {
        this.onDismiss();
        const { patientId } = this.model.splits[index];
        navigate(`/patients/${patientId}/ledger/activity/encounters`);
      },
      selectTab: tab => {
        this.__selectedTab = tab;
      },
      printPaymentForLocation: () => {
        this.__printPaymentForLocation();
      },
      printPayment: () =>
        this.__handlePrintPayment({ overrideLocation: false }),
      clickPaymentId: async index => {
        let payment = this.parentPayment;

        if (index !== undefined) {
          payment = this.state.splits[index];
        }
        await openPayment({ payment, readonly: false });
        return this.onRefetchSplits();
      },
    };
  }

  __canSplitBeRemoved(index) {
    return this.state.splits[index].preserve === '$0.00';
  }

  __renderParentPaymentId(paymentNumber) {
    return this.__edit
      ? paymentNumber
      : html`
          <neb-text
            id="${ELEMENTS.parentPaymentLink.id}"
            bold
            link
            .onClick="${() => this.handlers.clickPaymentId()}"
            >${paymentNumber}</neb-text
          >
        `;
  }

  __getHeaderTableConfig() {
    return [
      {
        truncate: true,
        key: 'patientName',
        label: 'Patient',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'transactionDate',
        label: 'Date',
        flex: css`1 0 0`,
        formatter: d => (d ? parseDate(d).format('MM/DD/YYYY') : ''),
      },
      {
        truncate: true,
        key: 'paymentNumber',
        label: 'Payment ID',
        flex: css`1 0 0`,
        link: true,
        formatter: (rawValue, item) =>
          rawValue ? this.__renderParentPaymentId(item.paymentNumber) : '-',
      },
      {
        truncate: true,
        key: 'payerName',
        label: 'Payer',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'paymentMethod',
        label: 'Method',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'amount',
        label: 'Payment Amount',
        flex: css`1 0 0`,
        formatter: a => (a ? centsToCurrency(a) : ''),
      },
    ];
  }

  async __handlePrintPayment({ location = null, overrideLocation = false }) {
    let receipt;

    if (this.parentPayment.electronicPaymentId) {
      const electronicPayment = await getElectronicPayment(
        this.parentPayment.electronicPaymentId,
      );

      const parsed = JSON.parse(electronicPayment.receipt);

      if (parsed) {
        receipt = { text: parsed.text.customer_receipt };
      }
    }

    const currLocation = this.locations.find(l => l.id === this.__locationId);

    return printPdf(
      printReceipt({
        patientPayments: [
          formatRefundedSplitPayment(
            this.__parentTableModel,
            this.model.splits,
            receipt,
          ),
        ],

        selectedLocation: overrideLocation ? location : currLocation,
      }),
    );
  }

  __getPayerType() {
    if (this.parentPayment.patientId) {
      return this.parentPayment.payerPlanId
        ? PAYER_TYPE.BOUND
        : PAYER_TYPE.PATIENT;
    }

    if (this.parentPayment.payerPlanId) {
      return PAYER_TYPE.UNBOUND;
    }

    return '';
  }

  async __printPaymentForLocation() {
    const { location, canceled } = await openPopup(
      POPUP_RENDER_KEYS.SELECT_LOCATION,
      {
        locationId: this.__locationId,
        message: SELECT_LOCATION_MESSAGE_FOR_RECEIPTS,
      },
    );

    this.paymentId = this.model.splits[0].id;

    if (!canceled) {
      this.__locationId = location ? location.id : null;

      await Promise.all(
        this.state.splits.map(s =>
          updatePaymentLocation(s.id, this.__locationId),
        ),
      );

      await this.__handlePrintPayment({ location, overrideLocation: true });
    }
  }

  async __getSplitPayerPlans() {
    if (this.__payerType === PAYER_TYPE.BOUND) {
      const payerPlan = await getPayerPlan(this.parentPayment.payerPlanId, 2);
      return [payerPlan].map(item => ({
        data: item,
        label: `(${item.alias}) ${item.payerName}`,
      }));
    }

    if (this.__payerType === PAYER_TYPE.UNBOUND) {
      const { payerPlan } = await getPayerPlans(
        {
          search: '',
          hideInactive: true,
        },
        2,
      );

      return payerPlan.map(item => ({
        data: item,
        label: `(${item.alias}) ${item.payerName}`,
      }));
    }

    return [];
  }

  async __setupPatientPayment(splits) {
    this.__patientService = createServerPatientsCollection({
      onChange: ({ pageItems }) => {
        this.__patientSearchItems = pageItems.map(data => ({
          label: objToName(data.name, DEFAULT_NAME_OPTS),
          data,
        }));
      },
    });

    const defaultSelectedPatient = await patientApiClient.fetchOne(
      this.parentPayment.patientId,
    );
    this.__selectedPatient = {
      label: objToName(defaultSelectedPatient.name, DEFAULT_NAME_OPTS),
      data: defaultSelectedPatient,
    };

    const patientIds = splits.length
      ? this.model.splits.map(s => s.patientId).filter(v => v)
      : [this.parentPayment.patientId];

    const historyIds = this.history
      ? this.history.map(h => h.patientId).filter(v => v)
      : [];

    const splitPatientIds = [...new Set([...patientIds, ...historyIds])];

    const splitPatientData = this.parentPayment.patientId
      ? await patientApiClient.fetchSome(splitPatientIds)
      : [];

    if (splitPatientData.length) {
      this.__splitPatients = splitPatientData.map(data => ({
        label: objToName(data.name, DEFAULT_NAME_OPTS),
        data,
      }));

      const patientName = this.__splitPatients.find(
        p => p.data.id === this.parentPayment.patientId,
      ).label;

      this.__parentTableModel = {
        ...this.parentPayment,
        patientName,
        payerName: patientName,
      };
    }
  }

  async __setupBoundPayment() {
    const splitPatientData = await patientApiClient.fetchSome([
      this.parentPayment.patientId,
    ]);

    this.__splitPatients = splitPatientData.map(data => ({
      label: objToName(data.name, DEFAULT_NAME_OPTS),
      data,
    }));

    this.__payerPlans = await this.__getSplitPayerPlans();

    this.__parentTableModel = {
      ...this.parentPayment,
      patientName: this.__splitPatients[0].label,
      payerName: this.__payerPlans[0].data.payerName,
    };
  }

  async __setupUnboundPayment() {
    this.__payerPlans = await this.__getSplitPayerPlans();
    this.__payerPlanSearchItems = [...this.__payerPlans];

    const payer = this.__payerPlans.find(
      p => p.data.id === this.parentPayment.payerPlanId,
    );

    this.__selectedPayer = payer;

    this.__parentTableModel = {
      ...this.parentPayment,
      payerName: payer.data.payerName,
    };
  }

  __addPatientRow(isRemainderSplit) {
    if (this.__selectedPatient && this.__selectedPatient.label) {
      this.__searchError = '';
      this.formService.addItem('splits', -1);
      const newIndex = this.state.splits.length - 1;

      this.formService.apply(
        `splits.${newIndex}.patientId`,
        this.__selectedPatient,
      );

      if (isRemainderSplit) {
        this.formService.apply(`splits.${newIndex}.amount`, this.__remainder);
      }
    } else {
      this.__searchError = 'Required';
    }
  }

  __addBoundRow(isRemainderSplit) {
    this.formService.addItem('splits', -1);
    const newIndex = this.state.splits.length - 1;

    this.formService.apply(
      `splits.${newIndex}.patientId`,
      this.__selectedPatient,
    );

    this.formService.apply(
      `splits.${newIndex}.payerPlanId`,
      this.__selectedPayer,
    );

    if (isRemainderSplit) {
      this.formService.apply(`splits.${newIndex}.amount`, this.__remainder);
    }
  }

  __addUnboundRow(isRemainderSplit) {
    if (this.__selectedPayer && this.__selectedPayer.label) {
      this.__searchError = '';
      this.formService.addItem('splits', -1);
      const newIndex = this.state.splits.length - 1;

      this.formService.apply(
        `splits.${newIndex}.payerPlanId`,
        this.__selectedPayer,
      );

      if (isRemainderSplit) {
        this.formService.apply(`splits.${newIndex}.amount`, this.__remainder);
      }
    } else {
      this.__searchError = 'Required';
    }
  }

  async load() {
    this.__payerType = this.__getPayerType();
    this.__users = await fetchPracticeUsers();

    const { splits } = this.model;

    switch (this.__payerType) {
      case PAYER_TYPE.PATIENT: {
        await this.__setupPatientPayment(splits);
        break;
      }

      case PAYER_TYPE.BOUND: {
        await this.__setupBoundPayment();
        break;
      }

      case PAYER_TYPE.UNBOUND: {
        await this.__setupUnboundPayment();
        break;
      }
      default:
        break;
    }
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.reload();
      this.__locationId = this.model.splits[0].locationId;
    }
  }

  __setRemainder() {
    const diff = this.state.splits.reduce(
      (sum, a) => sum + currencyToCents(a.amount),
      0,
    );
    this.__remainder = centsToCurrency(this.parentPayment.amount - diff);
  }

  __hasNoRemainder() {
    return currencyToCentsWithNegative(this.__remainder) <= 0;
  }

  __hasAZeroAmount() {
    return !!this.state.splits.find(s => currencyToCents(s.amount) === 0);
  }

  __validateAmounts() {
    this.state.splits.forEach((split, i) => {
      this.formService.validateKey(['splits', i, 'amount']);
    });
  }

  __shouldDisableSearch() {
    return (
      this.fromEraEobPage ||
      !this.__edit ||
      (!!this.parentPayment.payerPlanId && !!this.parentPayment.patientId)
    );
  }

  __shouldDisablePrintReceipt() {
    return (
      (!!this.parentPayment.payerPlanId && !this.parentPayment.eRA) ||
      !this.hasSplits
    );
  }

  __getSplitsTableModel() {
    if (!this.hasSplits && !this.__edit) {
      return [];
    }

    if (this.__edit) {
      const remainderItem = {
        patientId: selectors.ITEM_EMPTY,
        payerPlanId: selectors.ITEM_EMPTY,
        preserve: 'Remainder:',
        amount: '',
      };
      return [...this.state.splits, remainderItem];
    }

    return this.state.splits;
  }

  __getHistoryPayerName(history) {
    if (this.parentPayment.payerPlanId) {
      const payer = this.__payerPlans.find(
        p => p.data && p.data.id === history.payerPlanId,
      );
      return payer && payer.data ? payer.data.payerName : '';
    }
    const payer = this.__splitPatients.find(
      splitPatient =>
        splitPatient.data && splitPatient.data.id === history.patientId,
    );

    return payer && payer.data ? payer.label : '';
  }

  __getHistoryTableModel() {
    return this.history.map(h => {
      const payerName = this.__getHistoryPayerName(h);

      return {
        ...h,
        payerName,
        userName: this.__users.find(user => user.data.id === h.userId).label,
        preValue: h.preValue ? centsToCurrency(h.preValue) : '',
        postValue: h.postValue ? centsToCurrency(h.postValue) : '',
        createdAt: parseDate(h.createdAt).format('MM/DD/YY hh:mm:ss A'),
      };
    });
  }

  __genNavItems() {
    return [
      {
        id: TABS.SPLITS,
        label: 'Splits',
        resolver: () => this.__renderSplitsTab(),
      },
      {
        id: TABS.HISTORY,
        label: 'History',
        disabled: this.__selectedTab === TABS.SPLITS && this.__edit,
        resolver: () => this.__renderHistoryTab(),
      },
    ];
  }

  __getMenuItems() {
    return [this.__menuItems.SELECT_LOCATION];
  }

  __renderTabs() {
    this.__navItems = this.__genNavItems();

    return html`
      <neb-tab-group
        id="${ELEMENTS.tabGroup.id}"
        class="tabs"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  __renderTabContent() {
    const item = this.__navItems.find(({ id }) => id === this.__selectedTab);

    return item.resolver();
  }

  __renderSearch() {
    return this.parentPayment && this.parentPayment.payerPlanId
      ? html`
          <neb-select-search
            id="${ELEMENTS.payerSearch.id}"
            name="payerPlanId"
            class="search"
            label="Payer"
            helper=" "
            .items="${this.__payerPlanSearchItems}"
            .error="${this.__searchError}"
            .value="${this.__selectedPayer}"
            .onChange="${this.handlers.changePayer}"
            .onSearch="${this.handlers.searchPayers}"
            .onRequest="${this.handlers.requestPayers}"
            .disabled="${this.__shouldDisableSearch()}"
            showSearch
            forceDown
          ></neb-select-search>
        `
      : html`
          <neb-select-search
            id="${ELEMENTS.patientSearch.id}"
            class="search"
            label="Patient"
            itemHeight="80"
            helper=" "
            .items="${this.__patientSearchItems}"
            .error="${this.__searchError}"
            .value="${this.__selectedPatient}"
            .onChange="${this.handlers.changePatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRequest="${this.handlers.requestPatients}"
            .onRenderItem="${this.handlers.renderPatient}"
            .disabled="${this.__shouldDisableSearch()}"
            showSearch
            forceDown
          >
          </neb-select-search>
        `;
  }

  __renderSplitsTab() {
    return html`
      <neb-header label="Splits" description="Add or edit splits."></neb-header>
      <div class="add-row">
        <neb-button-action
          id="${ELEMENTS.editButton.id}"
          label="Edit Splits"
          leadingIcon="edit"
          .onClick="${this.handlers.changeMode}"
          .disabled="${this.__edit}"
        ></neb-button-action>
        ${this.__renderSearch()}

        <neb-button-action
          id="${ELEMENTS.addButton.id}"
          label="Add Split"
          .disabled="${!this.__edit}"
          .onClick="${this.handlers.addItem}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.remainderButton.id}"
          label="Add Remainder Split"
          .disabled="${
            !this.__edit || this.__hasNoRemainder() || this.__hasAZeroAmount()
          }"
          .onClick="${this.handlers.addItemRemainder}"
        ></neb-button-action>
      </div>

      <neb-table-ledger-split-payments
        id="${ELEMENTS.splitsTable.id}"
        name="splits"
        .model="${this.__getSplitsTableModel()}"
        .remainder="${this.__remainder}"
        .errors="${this.errors.splits}"
        .edit="${this.__edit}"
        .onChange="${this.handlers.change}"
        .onRemove="${this.handlers.removeItem}"
        .showRemoveButton="${this.__edit}"
        .onSelectPatient="${this.handlers.navigateToPatientLedger}"
        .onClickPaymentId="${this.handlers.clickPaymentId}"
      ></neb-table-ledger-split-payments>
    `;
  }

  __renderHistoryTab() {
    return html`
      <neb-header label="History" description=""></neb-header>
      <neb-table-ledger-split-history
        id="${ELEMENTS.historyTable.id}"
        name="History"
        .model="${this.__getHistoryTableModel()}"
        .isPatientPayment="${!this.parentPayment.payerPlanId}"
      ></neb-table-ledger-split-history>
    `;
  }

  __renderPrintReceiptButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.receiptButton.id}"
        class="receipt"
        name="receipt"
        label="Print Receipt"
        leadingIcon="receipt"
        ?disabled="${this.__shouldDisablePrintReceipt()}"
        .onClick="${this.handlers.printPayment}"
      ></neb-button-action>
    `;
  }

  __renderDropdownMenu() {
    return html`
      <neb-button-actions
        id="${ELEMENTS.buttonActions.id}"
        name="buttonActions"
        class="button-actions"
        align="left"
        maxVisibleItems="10"
        vertical
        .forceDownMenu="${true}"
        .value="${this.__getMenuItems()}"
        ?disabled="${this.__shouldDisablePrintReceipt()}"
        iconHeight="20px"
        iconWidth="20px"
      ></neb-button-actions>
    `;
  }

  renderContent() {
    return html`
      <div class="buttons-bar">
        ${this.__renderPrintReceiptButton()} ${this.__renderDropdownMenu()}
      </div>
      <neb-table
        id="${ELEMENTS.parentPaymentTable.id}"
        .config="${this.__getHeaderTableConfig()}"
        .model="${[this.__parentTableModel]}"
      ></neb-table>

      ${this.__renderTabs()} ${this.__renderTabContent()}
    `;
  }

  renderActionBar() {
    return this.__edit
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .confirmLabel="${this.confirmLabel}"
            .cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.split}"
            .onCancel="${this.handlers.cancel}"
            .confirmDisabled="${!this.__dirty}"
          ></neb-action-bar>
        `
      : '';
  }
}
customElements.define('neb-form-split-payment', NebFormSplitPayment);
