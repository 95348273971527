import { buildSelectedLocation } from './location-util';

const isObjectEmpty = obj => !obj || !Object.keys(obj).length;

export const buildPracticeInformation = ({
  practiceInformation,
  selectedLocation = null,
}) => {
  const useSelectedLocation = !isObjectEmpty(selectedLocation);

  let selectedLocationInfo = {};

  if (useSelectedLocation) {
    selectedLocationInfo = buildSelectedLocation(selectedLocation);
  }

  const name =
    selectedLocationInfo.name ||
    practiceInformation.name ||
    practiceInformation.practiceName;

  return {
    ...practiceInformation,
    name,
    practiceName: name,
    ...(useSelectedLocation ? selectedLocationInfo : {}),
    id: practiceInformation.id,
  };
};
