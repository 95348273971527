import '../../../src/components/questions/neb-question';
import '../../neb-lit-components/src/components/neb-text';
import '../../neb-lit-components/src/components/neb-button';

import { html, css } from 'lit';

import { fetchAllQuestions } from '../../../src/api-clients/questionnaires';
import {
  CSS_COLOR_GREY_1,
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../neb-styles/neb-variables';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  subtitle: {
    id: 'subtitle',
  },
  closeButton: {
    id: 'close-button',
  },
  questions: {
    selector: 'neb-question',
  },
  narrativeHeader: {
    id: 'narrative-header',
  },
  narrativePreview: {
    id: 'narrative-preview',
  },
  noSymptomsTodaySubtitleText: {
    id: 'no-symptoms-today-subtitle-text',
  },
  noChangeInSymptomsSubtitleText: {
    id: 'no-change-in-symptoms-subtitle-text',
  },
  noSymptomsTodayNavBarText: {
    id: 'no-symptoms-today-nav-bar-text',
  },
  noChangeInSymptomsNavBarText: {
    id: 'no-change-in-symptoms-nav-bar-text',
  },
  navBarInstructionalText: {
    id: 'nav-bar-instructional-text',
  },
};

class NebPopupSubjectiveQuestionnairePreview extends NebPopup {
  static get properties() {
    return {
      __model: Array,
      __questions: Array,
    };
  }

  initState() {
    super.initState();

    this.__questions = [];
    this.model = [];

    this.title = 'Questionnaire';
    this.subtitle =
      "Prior to completing the check in process, please complete the following questionnaire for today's visit.";
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: ${OVERLAY_WIDTH_LARGE};
          overflow-y: auto;
        }

        .header-container {
          display: flex;
          flex-direction: column;
          gap: ${CSS_SPACING};
          margin-bottom: ${CSS_SPACING};
        }

        .content {
          flex: unset;
          display: flex;
          flex-direction: column;
          margin-top: 0px;
        }

        .link {
          color: rgb(12, 170, 220);
          text-decoration: underline;
        }

        .nav-bar-text-links {
          display: flex;
          gap: ${CSS_SPACING};
        }

        .questions-container {
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          border-top: 1px solid ${CSS_COLOR_GREY_1};
          margin-bottom: ${CSS_SPACING};
        }
        .questions-container > * {
          margin-top: 40px;
        }
        .questions-container > *:first-child {
          margin-top: ${CSS_SPACING};
        }

        .narrative-header {
          margin: ${CSS_SPACING} 0;
        }

        .narrative-preview {
          border-top: 1px solid ${CSS_COLOR_GREY_1};
          margin-top: ${CSS_SPACING};
        }

        .button-close {
          align-self: flex-start;
        }
      `,
    ];
  }

  async modelChanged() {
    const allQuestions = await fetchAllQuestions();
    this.__questions = this.model.questions.map(questionId =>
      allQuestions.find(question => question.id === questionId),
    );
  }

  __renderQuestions() {
    return this.__questions.map(
      question => html`
        <neb-question .model="${question}"></neb-question>
      `,
    );
  }

  __renderNarrativePreview() {
    const narrativeTexts = this.__questions
      .map(
        question =>
          question.narrativeText ||
          question.options.find(option => option.narrativeText).narrativeText,
      )
      .join(' ');

    return html`
      <div class="narrative-preview">
        <neb-text
          id="${ELEMENTS.narrativeHeader.id}"
          class="narrative-header"
          bold
          >Narrative:</neb-text
        >
        <neb-text id="${ELEMENTS.narrativePreview.id}"
          >${narrativeTexts}</neb-text
        >
      </div>
    `;
  }

  __renderButton() {
    return html`
      <neb-button
        id="${ELEMENTS.closeButton.id}"
        label="Close"
        class="button-close"
        .onClick="${this.handlers.cancel}"
      ></neb-button>
    `;
  }

  __renderSubtitle() {
    const prompt = 'Please complete the questionnaire to check in';
    const scheme = this.__determineRenderScheme();
    const { showBodyDiagram } = this.model;

    if (showBodyDiagram) {
      return html`
        <div id="${ELEMENTS.subtitle.id}">${prompt}.</div>
      `;
    }

    switch (scheme) {
      case 'noSymptoms':
        return html`
          <div id="${ELEMENTS.subtitle.id}">
            ${prompt}, or
            <span id="${ELEMENTS.noSymptomsTodaySubtitleText.id}" class="link"
              >click here if you have no symptoms today</span
            >.
          </div>
        `;
      case 'noChange':
        return html`
          <div id="${ELEMENTS.subtitle.id}">
            ${prompt}, or
            <span
              id="${ELEMENTS.noChangeInSymptomsSubtitleText.id}"
              class="link"
              >click here if you have no change in symptoms</span
            >.
          </div>
        `;
      case 'both':
        return html`
          <div id="${ELEMENTS.subtitle.id}">
            Please complete the questionnaire to check in. If you have no new
            symptoms or no changes in your symptoms select an option below.
          </div>
          <div class="nav-bar-text-links">
            <neb-text id="${ELEMENTS.noSymptomsTodaySubtitleText.id}" link
              >No Symptoms Today</neb-text
            >
            <neb-text id="${ELEMENTS.noChangeInSymptomsSubtitleText.id}" link
              >No Change in Symptoms</neb-text
            >
          </div>
        `;
      default:
        return html`
          <div id="${ELEMENTS.subtitle.id}">${prompt}.</div>
        `;
    }
  }

  __renderNavBarText() {
    const prompt = 'Select a body area to begin';
    const scheme = this.__determineRenderScheme();
    const { showBodyDiagram } = this.model;

    if (!showBodyDiagram) {
      return '';
    }

    switch (scheme) {
      case 'noSymptoms':
        return html`
          <div
            class="nav-bar-instructional-text"
            id="${ELEMENTS.navBarInstructionalText.id}"
          >
            <span>
              ${prompt}, or
              <span id="${ELEMENTS.noSymptomsTodayNavBarText.id}" class="link"
                >click here if you have no symptoms today</span
              >.
            </span>
          </div>
        `;
      case 'noChange':
        return html`
          <div
            class="nav-bar-instructional-text"
            id="${ELEMENTS.navBarInstructionalText.id}"
          >
            <span>
              ${prompt}, or
              <span
                id="${ELEMENTS.noChangeInSymptomsNavBarText.id}"
                class="link"
                >click here if you have no change in symptoms</span
              >.
            </span>
          </div>
        `;
      case 'both':
        return html`
          <div id="${ELEMENTS.navBarInstructionalText.id}">
            Select a body area to begin reporting new symptoms. If you have no
            new symptoms or no changes in your symptoms, select an option below.
          </div>
          <div class="nav-bar-text-links">
            <neb-text id="${ELEMENTS.noSymptomsTodayNavBarText.id}" link
              >No Symptoms Today</neb-text
            >
            <neb-text id="${ELEMENTS.noChangeInSymptomsNavBarText.id}" link
              >No Change in Symptoms</neb-text
            >
          </div>
        `;
      default:
        return html`
          <div
            class="nav-bar-instructional-text"
            id="${ELEMENTS.navBarInstructionalText.id}"
          >
            <span>${prompt}.</span>
          </div>
        `;
    }
  }

  __determineRenderScheme() {
    const { allowNoSymptomsToday, allowNoChangeInSymptoms } = this.model;

    if (allowNoChangeInSymptoms && allowNoSymptomsToday) {
      return 'both';
    }

    if (allowNoSymptomsToday) {
      return 'noSymptoms';
    }

    if (allowNoChangeInSymptoms) {
      return 'noChange';
    }
    return null;
  }

  renderContent() {
    return html`
      <div class="header-container">
        ${this.__renderSubtitle()} ${this.__renderNavBarText()}
      </div>
      <div class="questions-container">
        ${this.__renderQuestions()} ${this.__renderNarrativePreview()}
      </div>
      ${this.__renderButton()}
    `;
  }
}

customElements.define(
  'neb-popup-subjective-questionnaire-preview',
  NebPopupSubjectiveQuestionnairePreview,
);
