import { parseDate } from '../neb-utils/date-util';

export const AppointmentColumn = Object.freeze({
  CASE: 'case',
  RTE: 'rte',
  CONFIRMED: 'confirmed',
  DATEANDTIME: 'dateAndTime',
  TYPE: 'type',
  PROVIDER: 'provider',
  LOCATION: 'location',
  ROOM: 'room',
  STATUS: 'status',
});
export class AppointmentQueryMeta {
  constructor(type) {
    this.pageSize = 10;
    this.sort = {
      field: 'start',
      dir: 'asc',
    };

    if (type === 'future' || type === 'past' || type === 'all') {
      this.columns = [
        AppointmentColumn.DATEANDTIME,
        AppointmentColumn.RTE,
        AppointmentColumn.TYPE,
        AppointmentColumn.PROVIDER,
        AppointmentColumn.LOCATION,
        AppointmentColumn.ROOM,
        AppointmentColumn.CASE,
        AppointmentColumn.STATUS,
      ];

      if (type === 'future') {
        this.filters = {
          start: parseDate()
            .startOf('day')
            .toISOString(),
        };
      }

      if (type === 'past') {
        this.sort = { ...this.sort, dir: 'desc' };
        this.filters = {
          startBefore: parseDate()
            .startOf('day')
            .toISOString(),
        };
      }
    }
  }
}
export const APPOINTMENT_QUERY_META = () => new AppointmentQueryMeta();
export const FUTURE_QUERY_META = () => new AppointmentQueryMeta('future');
export const PAST_QUERY_META = () => new AppointmentQueryMeta('past');
export const ALL_QUERY_META = () => new AppointmentQueryMeta('all');
