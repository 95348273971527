import '../../../src/components/forms/neb-form-email-patient';

import { html, css } from 'lit';

import * as emailApiClient from '../../neb-api-client/src/payments-api-client';
import { openError, openSuccess } from '../../neb-dialog/neb-banner-state';
import { store } from '../../neb-redux/neb-redux-store';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  form: { id: 'form' },
  mobileHeader: {
    id: 'mobile-header',
  },
};
const TEXT_TITLE = 'Send Receipt';
const TEXT_INFO =
  'Please select or enter an email below to send the payment receipt.';

const ERROR_BANNER_MESSAGE =
  'There was an error when trying to email the payment receipt. Try again.';

const SUCCESS_BANNER = 'Payment receipt was successfully sent.';

const SECONDARY_EMAIL_LABEL = 'Other Email';

class NebPopupEmailReceipt extends NebPopup {
  static get properties() {
    return {
      __emailAddresses: Array,
      __paymentDetail: Object,
    };
  }

  initState() {
    super.initState();
    this.title = TEXT_TITLE;
    this.__emailAddresses = [];
    this.__paymentDetail = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.cancel = () =>
      this.onClose({
        cancel: true,
      });

    this.handlers.emailReceipt = async ({
      emailAddress,
      newEmailAddress,
      locationId,
    }) => {
      let res;

      try {
        const email = emailAddress || newEmailAddress;

        const patients = await emailApiClient.getUniquePatientsFromPatientPayments(
          [this.__paymentDetail],
        );

        res = await emailApiClient.emailPatientPaymentReceipt({
          paymentDetails: this.__paymentDetail,
          email,
          patients,
          locationId,
          optOutLoadingIndicator: true,
        });

        this.onClose({ success: true });
        store.dispatch(openSuccess(SUCCESS_BANNER));
      } catch (e) {
        console.error(e);
        store.dispatch(openError(ERROR_BANNER_MESSAGE));
      }

      return res;
    };
  }

  modelChanged() {
    this.__paymentDetail = this.model.paymentDetail;
    this.__emailAddresses = this.model.patientEmailAddresses || [];
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          position: fixed;
          width: 600px;
        }

        :host([layout='small']) {
          width: 100%;
          height: 100%;
          top: -40px;
        }

        .header {
          margin-bottom: 10px;
          font: Bold 16px/22px Open Sans;
        }

        .content {
          width: 100%;
          height: 100%;
          min-height: unset;
          margin: 0;
        }

        .popup-body {
          display: flex;
          flex-direction: column;
        }

        .info-text {
          margin-bottom: 20px;
          font: Regular 14px/19px Open Sans;
        }
      `,
    ];
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.mobileHeader.id}" class="header">${this.title}</div>
    `;
  }

  renderContent() {
    return html`
      <div class="popup-body">
        <div class="info-text">${TEXT_INFO}</div>
        <neb-form-email-patient
          id="${ELEMENTS.form.id}"
          .onCancel="${this.handlers.cancel}"
          .onSave="${this.handlers.emailReceipt}"
          .emailAddresses="${this.__emailAddresses}"
          .secondaryEmailLabel="${SECONDARY_EMAIL_LABEL}"
          .paymentDetail="${this.__paymentDetail}"
        ></neb-form-email-patient>
      </div>
    `;
  }
}

customElements.define('neb-popup-email-receipt', NebPopupEmailReceipt);
