import '../../../../packages/neb-lit-components/src/components/neb-toggle-chevron';
import '../../misc/neb-icon';
import '../../../../packages/neb-lit-components/src/components/neb-button';
import '../../../../packages/neb-lit-components/src/components/neb-dropdown';
import '../../../../packages/neb-lit-components/src/components/neb-radio-button';
import '../../../../packages/neb-lit-components/src/components/neb-date-picker';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../../packages/neb-lit-components/src/components/neb-text';
import '../../../../packages/neb-lit-components/src/components/neb-checkbox-old';

import { isRequired } from '@neb/form-validators';
import { css, html } from 'lit';

import {
  CSS_TYPE_COLUMN_WIDTH,
  CSS_MESSAGE_COLUMN_WIDTH,
} from '../../../../packages/neb-alert/components/neb-alert-table-styles';
import Form, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { baseTableStyles } from '../../../../packages/neb-styles/neb-table-styles';
import {
  CSS_BORDER_GREY_2,
  CSS_BANNER_INFO_BORDER_COLOR,
  CSS_FONT_FAMILY,
  CSS_COLOR_ERROR,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { number } from '../../../../packages/neb-utils/masks';
import { CSS_SPACING } from '../../../styles';

export const CHECKBOX_LABELS = {
  scheduling: 'Scheduling',
  checkIn: 'Check In',
  checkOut: 'Check Out',
  charting: 'Charting',
  patientRecord: 'Patient Record',
  ledger: 'Ledger',
  payment: 'Payment',
};
export const ALERT_TYPE = Object.freeze({
  alwaysStartingOn: 0,
  onTheDayOfTheNextVisitOnly: 1,
  startingOnTheThVisitOnOrAfter: 2,
  everyThVisitOnOrAfter: 3,
  everyFirstVisitOccurringDaysOnOrAfter: 4,
});
const ALERT_DISPLAY_AREAS = [
  'scheduling',
  'checkIn',
  'checkOut',
  'charting',
  'patientRecord',
  'ledger',
  'payment',
];

const ALERT_RELATED_FIELDS_DEFAULT_VALUES = Object.freeze({
  scheduling: true,
  checkIn: true,
  checkOut: true,
  charting: true,
  patientRecord: true,
  ledger: true,
  payment: true,
  type: 0,
  displayDate: null,
  initialVisitDate: null,
  numOfVisits: null,
  numOfDays: null,
});

const DISPLAY_LABEL = 'Display Alert On';
export const TYPES = ['Alert', 'Note'];

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  scheduling: {
    id: 'scheduling',
  },
  checkIn: {
    id: 'checkIn',
  },
  checkOut: {
    id: 'checkOut',
  },
  charting: {
    id: 'charting',
  },
  patientRecord: {
    id: 'patientRecord',
  },
  ledger: {
    id: 'ledger',
  },
  payment: {
    id: 'payment',
  },
  messageInput: {
    id: 'message-input',
  },
  message: {
    id: 'message',
  },
  type: {
    id: 'type',
  },
  invalidMsg: {
    id: 'invalid-msg',
  },
  dropdown: {
    id: 'dropdown-input',
  },
  radioButtonAlwaysStartingOn: {
    id: 'radioButtonAlwaysStartingOn',
  },
  radioButtonOnTheDayOfTheNextVisitOnly: {
    id: 'radioButtonOnTheDayOfTheNextVisitOnly',
  },
  radioButtonStartingOnTheThVisitOnOrAfter: {
    id: 'radioButtonStartingOnTheThVisitOnOrAfter',
  },
  radioButtonEveryThVisitOnOrAfter: {
    id: 'radioButtonEveryThVisitOnOrAfter',
  },
  radioButtonEveryFirstVisitOccurringDaysOnOrAfter: {
    id: 'radioButtonEveryFirstVisitOccurringDaysOnOrAfter',
  },
  datePickerAlwaysStartingOn: {
    id: 'date-picker-always-starting-on',
  },
  textFieldStartingOnTheThVisitOnOrAfter: {
    id: 'text-field-starting-on-the-th-visit-on-or-after',
  },
  datePickerStartingOnTheThVisitOnOrAfter: {
    id: 'date-picker-starting-on-the-th-visit-on-or-after',
  },
  textFieldEveryThVisitOnOrAfter: {
    id: 'text-field-every-th-visit-on-or-after',
  },
  datePickerEveryThVisitOnOrAfter: {
    id: 'date-picker-every-th-visit-on-or-after',
  },
  textFieldEvery1stVisitOccurringDaysOnOrAfter: {
    id: 'text-field-every-1st-visit-occurring-days-on-or-after',
  },
  datePickerEvery1stVisitOccurringDaysOnOrAfter: {
    id: 'date-picker-every-1st-visit-occurring-days-on-or-after',
  },
  saveButton: {
    id: 'save',
  },
  cancelButton: {
    id: 'cancel',
  },
  deleteButton: {
    id: 'delete',
  },
  invalidText: {
    id: 'invalid-text',
  },
};

export const CHECKBOX_ALERT = 'At least one display option must be selected.';

export class NebFormAlert extends Form {
  static get properties() {
    return {
      index: {
        type: Number,
      },
      model: {
        type: Object,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
      __collapse: {
        type: Boolean,
        reflect: true,
        attribute: 'collapse',
      },
      __isAlert: {
        type: Boolean,
        reflect: true,
        attribute: 'is-alert',
      },
      __invalidCheckboxes: {
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      css`
        ${baseTableStyles}
        :host {
          display: flex;
          flex-direction: row;
          background-color: white;
        }

        .textarea-container {
          display: flex;
          width: 100%;
          flex-direction: column;
          padding-left: 2.5%;
        }

        .cheveron-column {
          padding-left: 5px;
          min-width: 7px;
        }

        .checkbox-container {
          display: flex;
          flex-wrap: wrap;
        }

        .checkbox-container > * {
          flex: 1 1 150px;
        }

        :host([small]) .checkbox-container > * {
          flex: none;
        }

        .invalid {
          color: ${CSS_COLOR_ERROR};
          font-size: 11px;
        }

        .draggable {
          cursor: ns-resize;
        }

        .message-input {
          border: ${CSS_BORDER_GREY_2};
          resize: none;
          min-height: 165px;
          width: 95%;
          border-radius: 5px;
        }
        :host([small]) .message {
          word-break: break-word;
        }

        .alert-on-label {
          padding: 5px;
        }

        :host([is-alert]) .message {
          color: ${CSS_COLOR_ERROR};
        }
        :host([small]) .message-container {
          flex: 3;
        }

        :host([collapse][small]) .message-container {
          height: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        :host(:not([collapse])) .message {
          white-space: pre-wrap;
        }

        :host([is-alert]) .type {
          color: ${CSS_COLOR_ERROR};
        }
        textarea {
          box-sizing: border-box;
          padding-left: 10px;
          font-size: 16px;
          font-family: ${CSS_FONT_FAMILY};
        }
        textarea:focus {
          outline: none;
          border-color: ${CSS_BANNER_INFO_BORDER_COLOR};
        }

        iron-icon {
          margin-top: 2px;
          color: ${CSS_COLOR_HIGHLIGHT};
          height: 15px;
        }

        :host([invalid-msg]) #message-input {
          border-color: ${CSS_COLOR_ERROR};
        }

        [hidden] {
          display: none;
        }

        .icon {
          width: 18px;
          height: 18px;

          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .neb-table-cell {
          word-break: normal;
        }

        .neb-table-small-width {
          min-width: 70px;
        }

        .radio-button-container {
          display: flex;
          align-items: center;
          width: 100%;
          white-space: nowrap;
          padding-right: ${CSS_SPACING};
        }

        .display-alert-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0 20px;
        }

        .type-message-container {
          display: flex;
        }

        .config-container {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .days-neb-textfield {
          width: 50px;
          min-width: 50px;
        }

        .checkbox-group-container {
          width: 90%;
          margin-bottom: 20px;
        }

        @media (min-width: 1200px) {
          .display-alert-container {
            flex-direction: row-reverse;
          }

          .checkbox-group-container {
            width: 30%;
          }

          .radio-button-group-container {
            width: 70%;
            margin-right: 20px;
          }
        }

        .button-container {
          display: flex;
          justify-content: end;
        }

        .date-picker {
          margin-right: 20px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandler();
  }

  static createModel() {
    return {
      id: null,
      patientId: null,
      alert: true,
      message: '',
      order: 0,
      scheduling: true,
      checkIn: true,
      checkOut: true,
      charting: true,
      patientRecord: true,
      ledger: true,
      payment: true,
      type: 0,
      displayDate: null,
      initialVisitDate: null,
      numOfVisits: null,
      numOfDays: null,
      description: null,
      providerRecord: false,
    };
  }

  createSelectors() {
    return {
      children: {
        initialVisitDate: {
          validators: [
            {
              error: 'Required',
              validate: v => {
                if (
                  !this.__state.alert ||
                  this.__state.type === ALERT_TYPE.onTheDayOfTheNextVisitOnly
                ) {
                  return true;
                }
                return v;
              },
            },
          ],
        },
        numOfVisits: {
          validators: [
            {
              error: 'Required',
              validate: v => {
                if (
                  !this.__state.alert ||
                  ![
                    ALERT_TYPE.startingOnTheThVisitOnOrAfter,
                    ALERT_TYPE.everyThVisitOnOrAfter,
                  ].includes(this.__state.type)
                ) {
                  return true;
                }

                return Number(v);
              },
            },
          ],
          unformat: value => (value !== null ? Number(value) : null),
        },
        numOfDays: {
          validators: [
            {
              error: 'Required',
              validate: v => {
                if (
                  !this.__state.alert ||
                  this.__state.type !==
                    ALERT_TYPE.everyFirstVisitOccurringDaysOnOrAfter
                ) {
                  return true;
                }

                return Number(v);
              },
            },
          ],
          unformat: value => (value !== null ? Number(value) : null),
        },
        message: {
          ignorePristine: true,
          validators: [isRequired()],
        },
        scheduling: {
          ignorePristine: true,
          validators: [this.__validateCheckboxes()],
        },
        checkIn: {
          ignorePristine: true,
          validators: [this.__validateCheckboxes()],
        },
        checkOut: {
          ignorePristine: true,
          validators: [this.__validateCheckboxes()],
        },
        charting: {
          ignorePristine: true,
          validators: [this.__validateCheckboxes()],
        },
        patientRecord: {
          ignorePristine: true,
          validators: [this.__validateCheckboxes()],
        },
        ledger: {
          ignorePristine: true,
          validators: [this.__validateCheckboxes()],
        },
        payment: {
          ignorePristine: true,
          validators: [this.__validateCheckboxes()],
        },
        type: {
          unformat: value => Number(value),
        },
      },
    };
  }

  firstUpdated() {
    super.firstUpdated();

    this.__isAlert = this.__state.alert;
  }

  get __invalidCheckboxes() {
    return ALERT_DISPLAY_AREAS.some(key => this.__errors[key]);
  }

  __initState() {
    this.__isAlert = true;

    this.onDelete = () => {};

    this.onDirty = () => {};

    this.onSave = () => {};

    this.onDelete = () => {};

    this.onCancel = () => {};
  }

  __initHandler() {
    super.initHandlers();
    this.__handlers = {
      ...this.handlers,
      check: (checked, checkbox) => {
        this.formService.apply(checkbox.id, checked);

        ALERT_DISPLAY_AREAS.forEach(key => {
          this.formService.validateKey([key], true);
        });
      },
      setMessage: ({ value }) => {
        this.formService.apply('message', value);
      },
      itemSelected: ({ selectedItem }) => {
        this.__isAlert = selectedItem === 'Alert';
        this.formService.apply('alert', this.__isAlert);

        Object.keys(ALERT_RELATED_FIELDS_DEFAULT_VALUES).forEach(key => {
          this.formService.apply(key, ALERT_RELATED_FIELDS_DEFAULT_VALUES[key]);
        });
      },
      delete: () => this.onDelete(this.model),
      save: () => {
        if (!this.formService.validate()) {
          return;
        }

        const alert = this.formService.build();

        this.onSave(alert);
      },
      cancel: () => this.onCancel(),
      selectAlertType: value => {
        this.formService.apply('type', value);

        const initialVisitDate =
          value === ALERT_TYPE.onTheDayOfTheNextVisitOnly
            ? parseDate()
                .add(1, 'day')
                .startOf('day')
                .toISOString()
            : null;

        this.formService.apply('initialVisitDate', initialVisitDate);

        this.formService.apply('numOfVisits', null);
        this.formService.apply('numOfDays', null);
      },
      changeInitialVisitDate: ({ value }) => {
        this.formService.apply(
          'initialVisitDate',
          value ? parseDate(value).toISOString() : null,
        );
      },
      clearInitialVisitDate: () => {
        this.formService.apply('initialVisitDate', null);
      },
      changeNumOfVisits: ({ value }) => {
        if (!Number.isNaN(parseFloat(value))) {
          this.formService.apply('numOfVisits', value);
        }
      },
      changeNumOfDays: ({ value }) => {
        if (!Number.isNaN(parseFloat(value))) {
          this.formService.apply('numOfDays', value);
        }
      },
      validateDate(date) {
        return date.isSameOrAfter(parseDate().subtract(1, 'year'));
      },
    };
  }

  __validateCheckboxes() {
    return {
      error: CHECKBOX_ALERT,
      validate: () =>
        !this.__state.alert ||
        ALERT_DISPLAY_AREAS.some(key => this.__state[key]),
    };
  }

  __isSelected(alertType) {
    return this.__state.type === alertType;
  }

  __getInitialVisitDateByAlertType(alertType) {
    return this.__isSelected(alertType)
      ? parseDate(this.__state.initialVisitDate)
      : null;
  }

  __getErrorByAlertTypeAndKey(alertType, key) {
    return this.__isSelected(alertType) && this.__errors[key];
  }

  __renderTypeSelect() {
    return html`
      <neb-dropdown
        id="${ELEMENTS.dropdown.id}"
        class="neb-table-cell ${CSS_TYPE_COLUMN_WIDTH}"
        .items="${TYPES}"
        .selectedItem="${this.__state.alert ? TYPES[0] : TYPES[1]}"
        .onItemSelected="${this.__handlers.itemSelected}"
      ></neb-dropdown>
    `;
  }

  __renderAlwaysStartingOnRadioButton() {
    return html`
      <div class="radio-button-container">
        <neb-radio-button
          id="${ELEMENTS.radioButtonAlwaysStartingOn.id}"
          .onSelect="${this.__handlers.selectAlertType}"
          .value="${ALERT_TYPE.alwaysStartingOn}"
          .checked="${this.__state.type === ALERT_TYPE.alwaysStartingOn}"
        ></neb-radio-button>
        always starting on &nbsp;
        <neb-date-picker
          id="${ELEMENTS.datePickerAlwaysStartingOn.id}"
          name="alwaysStartingOnDate"
          class="date-picker"
          .selectedDate="${
            this.__getInitialVisitDateByAlertType(ALERT_TYPE.alwaysStartingOn)
          }"
          .invalid="${
            this.__getErrorByAlertTypeAndKey(
              ALERT_TYPE.alwaysStartingOn,
              'initialVisitDate',
            )
          }"
          .isDateSelectable="${this.__handlers.validateDate}"
          .momentFlag="${true}"
          .onChange="${this.__handlers.changeInitialVisitDate}"
          .onClear="${this.__handlers.clearInitialVisitDate}"
          .disabled="${this.__state.type !== ALERT_TYPE.alwaysStartingOn}"
        ></neb-date-picker>
      </div>
    `;
  }

  __renderOnTheDayOfTheNextVisitRadioButton() {
    return html`
      <div class="radio-button-container">
        <neb-radio-button
          id="${ELEMENTS.radioButtonOnTheDayOfTheNextVisitOnly.id}"
          .onSelect="${this.__handlers.selectAlertType}"
          .value="${ALERT_TYPE.onTheDayOfTheNextVisitOnly}"
          .checked="${
            this.__state.type === ALERT_TYPE.onTheDayOfTheNextVisitOnly
          }"
        ></neb-radio-button>
        on the day of the next visit only
      </div>
    `;
  }

  __renderStartingOnTheVisitRadioButton() {
    return html`
      <div class="radio-button-container">
        <neb-radio-button
          id="${ELEMENTS.radioButtonStartingOnTheThVisitOnOrAfter.id}"
          .onSelect="${this.__handlers.selectAlertType}"
          .value="${ALERT_TYPE.startingOnTheThVisitOnOrAfter}"
          .checked="${
            this.__state.type === ALERT_TYPE.startingOnTheThVisitOnOrAfter
          }"
        ></neb-radio-button>
        starting on the &nbsp;
        <neb-textfield
          id="${ELEMENTS.textFieldStartingOnTheThVisitOnOrAfter.id}"
          class="days-neb-textfield"
          .value="${
            this.__isSelected(ALERT_TYPE.startingOnTheThVisitOnOrAfter)
              ? this.__state.numOfVisits
              : null
          }"
          .error="${
            this.__isSelected(ALERT_TYPE.startingOnTheThVisitOnOrAfter)
              ? this.__errors.numOfVisits
              : null
          }"
          .helper="${' '}"
          .inputMode="${'numeric'}"
          .maxLength="${'3'}"
          .mask="${number}"
          .disabled="${
            this.__state.type !== ALERT_TYPE.startingOnTheThVisitOnOrAfter
          }"
          .onChange="${this.__handlers.changeNumOfVisits}"
        ></neb-textfield>
        &nbsp; visit on or after &nbsp;
        <neb-date-picker
          id="${ELEMENTS.datePickerStartingOnTheThVisitOnOrAfter.id}"
          class="date-picker"
          .selectedDate="${
            this.__getInitialVisitDateByAlertType(
              ALERT_TYPE.startingOnTheThVisitOnOrAfter,
            )
          }"
          .invalid="${
            this.__getErrorByAlertTypeAndKey(
              ALERT_TYPE.startingOnTheThVisitOnOrAfter,
              'initialVisitDate',
            )
          }"
          .isDateSelectable="${this.__handlers.validateDate}"
          .momentFlag="${true}"
          .onChange="${this.__handlers.changeInitialVisitDate}"
          .onClear="${this.__handlers.clearInitialVisitDate}"
          .disabled="${
            this.__state.type !== ALERT_TYPE.startingOnTheThVisitOnOrAfter
          }"
        ></neb-date-picker>
      </div>
    `;
  }

  __renderEveryVisitOnOrAfterRadioButton() {
    return html`
      <div class="radio-button-container">
        <neb-radio-button
          id="${ELEMENTS.radioButtonEveryThVisitOnOrAfter.id}"
          .onSelect="${this.__handlers.selectAlertType}"
          .value="${ALERT_TYPE.everyThVisitOnOrAfter}"
          .checked="${this.__state.type === ALERT_TYPE.everyThVisitOnOrAfter}"
        ></neb-radio-button>
        every &nbsp;
        <neb-textfield
          id="${ELEMENTS.textFieldEveryThVisitOnOrAfter.id}"
          class="days-neb-textfield"
          .value="${
            this.__isSelected(ALERT_TYPE.everyThVisitOnOrAfter)
              ? this.__state.numOfVisits
              : null
          }"
          .error="${
            this.__isSelected(ALERT_TYPE.everyThVisitOnOrAfter)
              ? this.__errors.numOfVisits
              : ''
          }"
          .helper="${' '}"
          .inputMode="${'numeric'}"
          .maxLength="${'3'}"
          .mask="${number}"
          .disabled="${this.__state.type !== ALERT_TYPE.everyThVisitOnOrAfter}"
          .onChange="${this.__handlers.changeNumOfVisits}"
        ></neb-textfield
        >&nbsp; visit on or after &nbsp;
        <neb-date-picker
          id="${ELEMENTS.datePickerEveryThVisitOnOrAfter.id}"
          class="date-picker"
          .selectedDate="${
            this.__getInitialVisitDateByAlertType(
              ALERT_TYPE.everyThVisitOnOrAfter,
            )
          }"
          .invalid="${
            this.__getErrorByAlertTypeAndKey(
              ALERT_TYPE.everyThVisitOnOrAfter,
              'initialVisitDate',
            )
          }"
          .isDateSelectable="${this.__handlers.validateDate}"
          .momentFlag="${true}"
          .onChange="${this.__handlers.changeInitialVisitDate}"
          .onClear="${this.__handlers.clearInitialVisitDate}"
          .disabled="${this.__state.type !== ALERT_TYPE.everyThVisitOnOrAfter}"
        ></neb-date-picker>
      </div>
    `;
  }

  __renderEveryFirstVisitOccuringRadioButton() {
    return html`
      <div class="radio-button-container">
        <neb-radio-button
          id="${ELEMENTS.radioButtonEveryFirstVisitOccurringDaysOnOrAfter.id}"
          .onSelect="${this.__handlers.selectAlertType}"
          .value="${ALERT_TYPE.everyFirstVisitOccurringDaysOnOrAfter}"
          .checked="${
            this.__state.type ===
              ALERT_TYPE.everyFirstVisitOccurringDaysOnOrAfter
          }"
        ></neb-radio-button>
        every 1st visit occurring &nbsp;
        <neb-textfield
          id="${ELEMENTS.textFieldEvery1stVisitOccurringDaysOnOrAfter.id}"
          class="days-neb-textfield"
          .value="${
            this.__isSelected(ALERT_TYPE.everyFirstVisitOccurringDaysOnOrAfter)
              ? this.__state.numOfDays
              : null
          }"
          .error="${
            this.__isSelected(ALERT_TYPE.everyFirstVisitOccurringDaysOnOrAfter)
              ? this.__errors.numOfDays
              : null
          }"
          .helper="${' '}"
          .inputMode="${'numeric'}"
          .maxLength="${'3'}"
          .mask="${number}"
          .disabled="${
            this.__state.type !==
              ALERT_TYPE.everyFirstVisitOccurringDaysOnOrAfter
          }"
          .onChange="${this.__handlers.changeNumOfDays}"
        ></neb-textfield>
        &nbsp; days on or after &nbsp;
        <neb-date-picker
          id="${ELEMENTS.datePickerEvery1stVisitOccurringDaysOnOrAfter.id}"
          class="date-picker"
          .selectedDate="${
            this.__getInitialVisitDateByAlertType(
              ALERT_TYPE.everyFirstVisitOccurringDaysOnOrAfter,
            )
          }"
          .invalid="${
            this.__getErrorByAlertTypeAndKey(
              ALERT_TYPE.everyFirstVisitOccurringDaysOnOrAfter,
              'initialVisitDate',
            )
          }"
          .disabled="${
            this.__state.type !==
              ALERT_TYPE.everyFirstVisitOccurringDaysOnOrAfter
          }"
          .isDateSelectable="${this.__handlers.validateDate}"
          .momentFlag="${true}"
          .onChange="${this.__handlers.changeInitialVisitDate}"
          .onClear="${this.__handlers.clearInitialVisitDate}"
        ></neb-date-picker>
      </div>
    `;
  }

  __renderAlertTypeRadioButtons() {
    if (!this.__state.alert) {
      return '';
    }

    return html`
      <div class="radio-button-group-container">
        <neb-text>Display Alert:</neb-text>
        ${this.__renderAlwaysStartingOnRadioButton()}
        ${this.__renderOnTheDayOfTheNextVisitRadioButton()}
        ${this.__renderStartingOnTheVisitRadioButton()}
        ${this.__renderEveryVisitOnOrAfterRadioButton()}
        ${this.__renderEveryFirstVisitOccuringRadioButton()}
      </div>
    `;
  }

  __renderMessageColumn() {
    return html`
      <div class="textarea-container neb-table-cell neb-table-large-width">
        <neb-textarea
          id="${ELEMENTS.messageInput.id}"
          class="message-input"
          .value="${this.__state.message}"
          .onChange="${this.__handlers.setMessage}"
          .error="${Boolean(this.errors.message)}"
        ></neb-textarea>
        <div
          class="invalid"
          id="${ELEMENTS.invalidMsg.id}"
          ?hidden="${!this.errors.message}"
        >
          Required
        </div>
      </div>
    `;
  }

  __renderCheckbox() {
    if (!this.__state.alert) {
      return '';
    }

    return html`
      <div class="checkbox-group-container">
        <div class="alert-on-label">${DISPLAY_LABEL}:</div>
        <div id="checkbox-container" class="checkbox-container">
          ${
            Object.keys(CHECKBOX_LABELS).map(
              label => html`
                <neb-checkbox-old
                  id="${ELEMENTS[label].id}"
                  class="checkbox"
                  .label="${CHECKBOX_LABELS[label]}"
                  .checked="${this.__isChecked(label)}"
                  .onClick="${this.__handlers.check}"
                ></neb-checkbox-old>
              `,
            )
          }
        </div>
        <div
          class="invalid"
          id="${ELEMENTS.invalidText.id}"
          ?hidden="${!this.__invalidCheckboxes}"
        >
          ${CHECKBOX_ALERT}
        </div>
      </div>
    `;
  }

  __isChecked(label) {
    return this.__state[label];
  }

  __renderSaveButton() {
    return this.__dirty
      ? html`
          <neb-button
            id="${ELEMENTS.saveButton.id}"
            class="neb-table-cell"
            .role="${'confirm'}"
            .label="${'SAVE'}"
            index="${this.index}"
            @click="${this.__handlers.save}"
          ></neb-button>
        `
      : html`
          <div class="neb-table-cell button"></div>
        `;
  }

  __renderDeleteButton() {
    return this.__state.id
      ? html`
          <neb-button
            id="${ELEMENTS.deleteButton.id}"
            class="neb-table-cell button"
            .role="${'cancel'}"
            .label="${'DELETE'}"
            index="${this.index}"
            @click="${this.__handlers.delete}"
          ></neb-button>
        `
      : html`
          <div class="neb-table-cell button"></div>
        `;
  }

  __renderFooter() {
    return html`
      <div
        id="message-column"
        class="button-container ${CSS_MESSAGE_COLUMN_WIDTH}"
      >
        ${this.__renderSaveButton()}
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          class="neb-table-cell"
          .role="${'cancel'}"
          .label="${'CANCEL'}"
          index="${this.index}"
          @click="${this.__handlers.cancel}"
        ></neb-button>
        ${this.__renderDeleteButton()}
      </div>
    `;
  }

  render() {
    return html`
      <div class="config-container">
        <div class="type-message-container">
          ${this.__renderTypeSelect()} ${this.__renderMessageColumn()}
        </div>
        <div class="display-alert-container">
          ${this.__renderCheckbox()} ${this.__renderAlertTypeRadioButtons()}
        </div>
        ${this.__renderFooter()}
      </div>
    `;
  }
}

customElements.define('neb-form-alert', NebFormAlert);
