import ApiClient, {
  getTenantId,
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const BASE_URL = 'tax';

export const apiClient = new ApiClient({ microservice: 'billing' });

export async function getTaxRates(optOutLoadingIndicator = false) {
  const tenantId = await getTenantId();

  return apiClient.makeRequest({
    method: Method.GET,
    path: BASE_URL,
    optOutLoadingIndicator,
    cacheKey: `${tenantId}-${BASE_URL}`,
  });
}

export function saveTaxRates(model) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(model),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: BASE_URL,
  });
}
