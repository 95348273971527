import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import { html, css } from 'lit';

import {
  createPatientRelationship,
  putPatientRelationship,
} from '../../../packages/neb-api-client/src/patient-relationship-api-client';
import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_MEDIUM,
} from '../../../packages/neb-styles/neb-variables';
import {
  getPostBodyFromRaw,
  patientRelationshipToRaw,
} from '../../../packages/neb-utils/patientRelationship';
import FormRelationship from '../forms/neb-form-relationship';

export const BANNER_SUCCESS = 'Patient Relationship saved successfully';
export const BANNER_ERROR =
  'An error occurred when saving the Patient Relationship';

export const ELEMENTS = {
  description: {
    id: 'description',
  },
  form: {
    id: 'form',
  },
  header: {
    id: 'header',
  },
};

class NebOverlayRelationship extends Overlay {
  static get properties() {
    return {
      __relationshipModel: Object,
    };
  }

  initState() {
    super.initState();
    this.__relationshipModel = FormRelationship.createModel();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: async model => {
        const { patientId } = this.model.context;

        const rawModel = patientRelationshipToRaw(model);
        const body = getPostBodyFromRaw(rawModel);

        try {
          let result;

          if (!model.id) {
            result = await createPatientRelationship(patientId, body);
          } else {
            result = await putPatientRelationship(model.id, patientId, body);
          }

          this.isDirty = false;
          store.dispatch(openSuccess(BANNER_SUCCESS));
          this.dismiss(result);
        } catch (e) {
          console.error(e);
          store.dispatch(openError(BANNER_ERROR));
        }
      },
    };
  }

  update(changedProps) {
    if (this.model.type) {
      this.__relationshipModel = this.model;
    }
    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_MEDIUM};
          padding-top: ${CSS_SPACING};
        }

        .inset {
          margin: 0 ${CSS_SPACING};
          padding-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderDescription() {
    return !this.model.relatedPatient
      ? html`
          <div id="${ELEMENTS.description.id}" class="inset">
            Select from the patient roster to add a relationship.
          </div>
        `
      : '';
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="inset"
        title="${
          this.model.relatedPatient ? 'Update' : 'Add'
        } Patient Relationship"
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
        .showBackButton="${true}"
        showCancelButton
      ></neb-popup-header>
      ${this.renderDescription()}
      <neb-form-relationship
        id="${ELEMENTS.form.id}"
        .model="${this.__relationshipModel}"
        .patientId="${this.model.context ? this.model.context.patientId : ''}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-relationship>
    `;
  }
}

customElements.define('neb-overlay-relationship', NebOverlayRelationship);
