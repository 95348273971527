import { html, css, LitElement } from 'lit';
import '../../../packages/neb-lit-components/src/components/neb-header';
import '../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-details-lit';

import { computeTime } from '../../../packages/neb-input/nebFormatUtils';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  centsToCurrency,
  formatAddress,
} from '../../../packages/neb-utils/formatters';
import {
  CSS_COLOR_WHITE,
  baseStyles,
  layoutStyles,
  CSS_SPACING,
} from '../../styles';

const CONFIG_DIAGNOSES = [
  {
    key: 'code',
    label: 'Diagnosis',
    flex: css`1.8 0 0`,
    mobile: true,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`5 0 0`,
    mobile: true,
  },
];

const CONFIG_CHARGES = [
  {
    key: 'service',
    label: 'Service',
    flex: css`2 0 0`,
    mobile: true,
  },
  {
    key: 'unit',
    label: 'Unit',
    flex: css`1 0 0`,
  },
  {
    key: 'charge',
    label: 'Charge',
    flex: css`1 0 0`,
    formatter: v => centsToCurrency(v, true),
  },
  {
    key: 'tax',
    label: 'Tax',
    flex: css`1 0 0`,
    formatter: v => centsToCurrency(v, true),
  },
  {
    key: 'payerPayment',
    label: 'Payer Amount',
    flex: css`1 0 0`,
    formatter: v => centsToCurrency(v, true),
  },
  {
    key: 'patientPayment',
    label: 'Patient Amount',
    flex: css`1 0 0`,
    formatter: v => centsToCurrency(v, true),
  },
  {
    key: 'adjustment',
    label: 'Adjustment',
    flex: css`1 0 0`,
    formatter: v => centsToCurrency(v, true),
  },
  {
    key: 'patientBalance',
    label: 'Patient Balance',
    flex: css`1 0 0`,
    mobile: true,
    formatter: v => centsToCurrency(v, true),
  },
];
export const CONFIG_FEES_AND_PURCHASES = [
  {
    key: 'dateOfService',
    label: 'Date',
    flex: css`1.2 0 0`,
    mobile: true,
    formatter: v => (v ? parseDate(v).format('L') : ''),
  },

  ...CONFIG_CHARGES,
];

function computeDurationDisplay(startDate, endDate) {
  const start = startDate.format('h:mm A');
  const end = endDate.format('h:mm A');
  const duration = computeTime(endDate.diff(startDate));
  const durationDisplay = `${start} - ${end} (${duration})`;

  return durationDisplay;
}

export function formatAppointmentDetails(appointmentDetails) {
  return {
    startDisplayDate: parseDate(appointmentDetails.start).format(
      'dddd, MMMM DD, YYYY',
    ),
    durationDisplay: computeDurationDisplay(
      parseDate(appointmentDetails.start),
      parseDate(appointmentDetails.end),
    ),
    location: appointmentDetails.locationName,
    appointmentTypeName: appointmentDetails.appointmentTypeName,
    providerDisplayName: appointmentDetails.providerDisplayName,
  };
}

export const ELEMENTS = {
  summaryAppointmentDetails: { id: 'summary-appointment-details' },
  tableDiagnoses: { id: 'table-diagnoses' },
  tableCharges: { id: 'table-charges' },
};

export class CardVisitSummary extends LitElement {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();
    this.initState();
  }

  initState() {
    this.model = {};
    this.layout = '';
  }

  static createModel() {
    return {
      appointmentDetails: {
        id: '',
        start: '',
        end: '',
        locationId: '',
        locationName: '',
        appointmentTypeId: '',
        appointmentTypeName: '',
        providerDisplayName: '',
      },
      encounterDiagnoses: [],
      lineItems: [],
      opts: {
        hideAppointmentInfo: false,
        hideDiagnoses: false,
        hideCharges: false,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: auto;
        }

        .container {
          grid-gap: ${CSS_SPACING} ${CSS_SPACING};
          height: 100%;
          padding: 15px 0px;
          border-radius: 4px;
          background: ${CSS_COLOR_WHITE};
          box-shadow: 0 1px 3px #00000033;
        }
      `,
    ];
  }

  __renderAppointmentInfo() {
    return !this.model.opts.hideAppointmentInfo
      ? html`
          <neb-header label="Appointment Information"> </neb-header>

          <div class="grid">
            <neb-appointment-details-lit
              id="${ELEMENTS.summaryAppointmentDetails.id}"
              .layout="${this.layout}"
              .appointmentDetails="${
                formatAppointmentDetails(this.model.appointmentDetails)
              }"
              .address="${
                formatAddress({
                  ...this.model.location,
                  zipcode: this.model.location.zipCode,
                })
              }"
            ></neb-appointment-details-lit>
          </div>
        `
      : '';
  }

  __renderDiagnoses() {
    return !this.model.opts.hideDiagnoses
      ? html`
          <neb-header label="Diagnoses"></neb-header>

          <neb-table
            id="${ELEMENTS.tableDiagnoses.id}"
            .config="${CONFIG_DIAGNOSES}"
            .layout="${this.layout}"
            .model="${this.model.encounterDiagnoses}"
          ></neb-table>
        `
      : '';
  }

  __renderCharges() {
    return !this.model.opts.hideCharges
      ? html`
          <neb-header label="Encounter Charges"></neb-header>

          <neb-table
            id="${ELEMENTS.tableCharges.id}"
            .config="${CONFIG_CHARGES}"
            .layout="${this.layout}"
            .model="${this.model.lineItems}"
          ></neb-table>
        `
      : '';
  }

  render() {
    return html`
      <div class="grid container">
        ${this.__renderAppointmentInfo()} ${this.__renderDiagnoses()}
        ${this.__renderCharges()}
      </div>
    `;
  }
}
window.customElements.define('neb-card-visit-summary', CardVisitSummary);
