import '../../../../../packages/neb-lit-components/src/components/neb-header';
import '../../../../../packages/neb-lit-components/src/components/neb-tooltip';
import '../../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../../../packages/neb-lit-components/src/components/neb-radio-button';
import '../../../../../packages/neb-lit-components/src/components/neb-pagination';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../../packages/neb-lit-components/src/components/tables/neb-table-payer-plans';
import '../../../tables/settings/billing/neb-table-fee-schedule-charges';

import FormService from '@neb/form-service';
import { atMin, isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import * as billingCodesApi from '../../../../../packages/neb-api-client/src/billing-codes';
import { NebModifiers } from '../../../../../packages/neb-lit-components/src/components/field-groups/neb-modifiers';
import NebForm from '../../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../../../packages/neb-lit-components/src/components/neb-button';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../../packages/neb-popup/src/renderer-keys';
import { TYPE } from '../../../../../packages/neb-utils/fee-schedule';
import {
  centsToCurrency,
  currencyToCents,
  normalizeForSearch,
} from '../../../../../packages/neb-utils/formatters';
import {
  currency,
  numberNoLeadingZero,
} from '../../../../../packages/neb-utils/masks';
import * as selectors from '../../../../../packages/neb-utils/selectors';
import {
  CollectionService,
  SORT_DIR,
} from '../../../../../packages/neb-utils/services/collection';
import { getValueByPath } from '../../../../../packages/neb-utils/utils';
import * as chargesApi from '../../../../api-clients/charges';
import * as feeSchedulesApi from '../../../../api-clients/fee-schedules';
import { CSS_SPACING, CSS_COLOR_GREY_2 } from '../../../../styles';
import { ASSOCIATE_PAYER, FEE_SCHEDULE } from '../../../../utils/user-message';

const APPEND_KEYS = {
  charges: [
    'id',
    'active',
    'procedure',
    'description',
    'modifiers.0',
    'modifiers.1',
    'modifiers.2',
    'modifiers.3',
    'suppressFromClaim',
    'nonCovered',
    'baseAmount',
    'amount',
    'allowedAmount',
    'adjustmentAmount',
  ],
  payerPlans: [
    'id',
    'active',
    'alias',
    'payerId',
    'payerName',
    'providerIds',
    'availableProviders',
  ],
};

const PAYER_PLANS_PAGE_SIZE = 10;

export const ELEMENTS = {
  tabGroup: { id: 'tab-group' },
  generalTab: { id: 'tab-general' },
  payerPlansTab: { id: 'tab-payer-plans' },
  generalTabContent: { id: 'tab-content-general' },
  payerPlansTabContent: { id: 'tab-content-payer-plans' },
  activeSwitch: { id: 'switch-active' },
  addChargesButton: { id: 'button-add-charges' },
  addPayerPlansButton: { id: 'button-add-payer-plan' },
  adjustmentCodeToolTip: { id: 'tooltip-adjustment' },
  amount: { id: 'amount' },
  amountBulkUpdate: { id: 'bulk-update-amount' },
  capitatedRadio: { id: 'radio-capitated' },
  capitatedTooltip: { id: 'tooltip-capitated' },
  chargesPagination: { id: 'pagination-charges' },
  chargesSearch: { id: 'search-charges' },
  chargesTable: { id: 'table-charges' },
  feeRadio: { id: 'radio-fee' },
  feeTooltip: { id: 'tooltip-fee' },
  name: { id: 'name' },
  allowedAmount: { id: 'allowed-amount' },
  allowedBulkUpdate: { id: 'bulk-update-allowed' },
  payerPlansPagination: { id: 'pagination-payer-plans' },
  payerPlansTable: { id: 'table-payer-plans' },
  percentRadio: { id: 'button-percent' },
  percentTooltip: { id: 'tooltip-percent' },
  rate: { id: 'rate' },
  referenceId: { id: 'reference-id' },
  refreshButton: { id: 'button-refresh' },
  copyButton: { id: 'button-copy' },
  payerAdjustmentCodeId: { id: 'payer-adjustment-code-id' },
  patientAdjustmentCodeId: { id: 'patient-adjustment-code-id' },
};

function buildChargeType(isCapitatedType, plural = true) {
  return `${!isCapitatedType ? 'Covered' : 'Carved Out'} Charge${
    plural ? 's' : ''
  }`;
}

function genAddChargesConfig(isCapitatedType) {
  const chargeType = buildChargeType(isCapitatedType);

  const headers = {
    title: `Add ${chargeType}`,
    description: `Select ${chargeType.toLowerCase()} to associate with the fee schedule.`,
  };

  return {
    itemName: 'charge',
    itemPluralName: 'charges',
    title: headers.title,
    description: headers.description,
    tableConfig: [
      {
        key: 'procedure',
        label: 'Procedure',
        flex: css`0 0 120px`,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`2 0 0`,
      },
      {
        key: 'modifiers',
        label: 'Modifiers',
        flex: css`1 0 0`,
      },
      {
        key: 'baseAmount',
        label: 'Amount',
        flex: css`1 0 0`,
      },
    ],
  };
}

function genChargesTableConfig(isPercentType) {
  return [
    {
      key: 'procedure',
      label: 'Procedure',
      flex: css`0 1 80px`,
    },
    {
      key: 'description',
      label: 'Description',
      flex: css`2 1 0`,
    },
    {
      key: 'modifiers',
      label: 'Modifiers',
      flex: css`1 4 0`,
    },
    {
      key: 'suppressFromClaim',
      label: 'Suppress from Claim',
      flex: css`0 1 96px`,
      customStyle: 'justify-content: center',
    },
    {
      key: 'nonCovered',
      label: 'Non-Covered',
      flex: css`0 1 93px`,
      customStyle: 'justify-content: center',
    },
    {
      key: 'baseAmount',
      label: 'Base',
      flex: css`0 1 80px`,
    },
    {
      key: 'amount',
      label: 'Fee Sch Charge',
      flex: css`0 1 106px`,
    },
    {
      key: 'allowedAmount',
      label: 'Allowed Amount',
      flex: css`0 1 106px`,
    },
    {
      key: 'adjustmentAmount',
      label: 'Adjustment Amount',
      flex: css`0 1 96px`,
    },
    ...(isPercentType
      ? [
          {
            key: 'spacer',
            label: '',
            flex: css`0 0 ${CSS_SPACING}`,
          },
        ]
      : []),
  ];
}

function validateRate() {
  return {
    error: '1-999',
    validate: (v, _, state) =>
      state.type === TYPE.PERCENT ? v && v > 0 : true,
  };
}

function isRequiredIfPercentType() {
  return {
    error: 'Required',
    validate: (v, _, state) =>
      state.type === TYPE.PERCENT ? v && v.data.id : true,
  };
}

function isLessThanOrEqualToFsc() {
  return {
    error: '≤ Fee Sch Chrg',
    validate: (v, keyPath, state) => {
      const index = keyPath.slice(1, 2);
      const { amount } = state.charges[index];

      return v <= amount;
    },
  };
}

export class NebFormFeeSchedules extends NebForm {
  static get properties() {
    return {
      __tabId: String,
      __adjustmentCodes: Array,
      __charges: Array,
      __feeSchedules: Array,
      __bulkUpdateState: Object,
      __bulkUpdateErrors: Object,
      __chargesState: Object,
      __payerPlansPagination: Object,
      payers: Array,
      providers: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .grid-bulk-update {
          grid-gap: 0;
          grid-template-columns: 1fr 96px 30px 96px 30px 96px 30px 10px;
        }

        .grid-adjustment {
          grid-template-columns: 2fr 1fr 1fr ${CSS_SPACING};
        }

        .grid-tabs {
          align-items: flex-end;
          grid-gap: 0;
          grid-template-columns: auto 1fr auto auto;
        }

        .label {
          text-align: right;
          margin-right: ${CSS_SPACING};
          margin-bottom: 15px;
        }

        .field {
          width: 96px;
        }

        .icon {
          height: 30px;
          width: 30px;
          margin-bottom: 15px;
        }

        .button {
          padding-right: ${CSS_SPACING};
          padding-bottom: 10px;
        }

        .tabs {
          margin-top: 13px;
        }

        .underline {
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }
      `,
    ];
  }

  static createModel() {
    return {
      name: '',
      active: true,
      type: TYPE.FEE,
      rate: 100,
      referenceId: '',
      payerAdjustmentCodeId: '',
      patientAdjustmentCodeId: '',
      charges: [],
      payerPlans: [],
    };
  }

  createSelectors() {
    return {
      children: {
        name: [isRequired()],
        rate: selectors.numeric(0, { validators: [validateRate()] }),
        referenceId: selectors.select(
          this.getReferenceFeeSchedules(),
          selectors.ITEM_EMPTY,
          { validators: [isRequiredIfPercentType()] },
        ),
        payerAdjustmentCodeId: selectors.select(
          this.getPayerAdjustmentCodeItems(),
          selectors.ITEM_EMPTY,
          { validateRaw: true, validators: [isRequired()] },
        ),
        patientAdjustmentCodeId: selectors.select(
          this.getPatientAdjustmentCodeItems(),
          selectors.ITEM_EMPTY,
          { validateRaw: true, validators: [isRequired()] },
        ),
        charges: {
          createItem: () => ({
            id: '',
            active: true,
            procedure: '',
            description: '',
            modifiers: ['', '', '', ''],
            suppressFromClaim: false,
            nonCovered: false,
            baseAmount: 0,
            amount: 0,
            allowedAmount: 0,
            adjustmentAmount: 0,
          }),
          children: {
            $: {
              children: {
                baseAmount: selectors.currency(),
                amount: selectors.currency({
                  validateRaw: true,
                  validators: [
                    atMin(0, true, 'Must be greater or equal to $0.00'),
                  ],
                }),
                modifiers: {
                  ...NebModifiers.createSelectors(),
                  unformat: v => v.map(modifier => modifier || null),
                },
                allowedAmount: selectors.currency({
                  validateRaw: true,
                  validators: [isLessThanOrEqualToFsc()],
                }),
                adjustmentAmount: selectors.currency(),
              },
            },
          },
        },
        payerPlans: {
          createItem: () => ({
            id: '',
            active: true,
            alias: '',
            payerId: '',
            payerName: '',
            providerIds: [],
            availableProviders: [],
          }),
          children: {
            $: {
              children: {
                providerIds: selectors.nonStrictMultiSelect(
                  this.providers,
                  [],
                  { validators: [isRequired()] },
                ),
                availableProviders: selectors.nonStrictMultiSelect(
                  this.providers,
                  [],
                  {},
                ),
              },
            },
          },
          unformat: payerPlans =>
            payerPlans.map(payerPlan => ({
              ...payerPlan,
              allProviders:
                payerPlan.providerIds.length === this.providers.length,
            })),
        },
      },
    };
  }

  constructor() {
    super();
    this.__initServices();
  }

  initState() {
    super.initState();

    this.__tabId = ELEMENTS.generalTab.id;
    this.__adjustmentCodes = [];
    this.__charges = [];
    this.__feeSchedules = [];
    this.__chargesState = CollectionService.createModel();
    this.__payerPlansPagination = {
      pageIndex: 0,
      pageCount: 1,
    };

    this.__chargesService = new CollectionService({
      onChange: state => {
        this.__chargesState = state;
      },
      onSearch: ({ terms, item }) => terms.every(term => item.includes(term)),
      onCacheItem: ({ procedure, description }) =>
        normalizeForSearch([procedure, description].join(' ')),
    });

    this.__chargesService.setSortParams({
      key: 'procedure',
      dir: SORT_DIR.DESC,
    });

    this.payers = [];
    this.providers = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      selectTab: id => {
        this.__tabId = id;
      },
      changeBulkUpdate: e => this.__bulkUpdateService.apply(e.name, e.value),
      bulkUpdateAmount: name => this.__bulkUpdate(name, 'Fee Schedule Charge'),
      bulkUpdateAllowed: name => this.__bulkUpdate(name, 'Allowed'),
      searchCharges: e => this.__chargesService.search(e.value),
      clearChargesSearch: () => this.__chargesService.search(''),
      selectChargesPage: index => this.__chargesService.setPageIndex(index),
      selectPayerPlansPage: index => {
        this.__payerPlansPagination = {
          ...this.__payerPlansPagination,
          pageIndex: index,
        };
      },
      refresh: async () => {
        const accepted = await this.__openWarningPopup(
          'Refresh Reference Fee Schedule',
          'Refreshing this % of Fee for Service fee schedule will account for new changes made to the referenced fee schedule. These changes include newly added charges, charge base amount updates, and the re-calculating of this % of Fee for Service fee schedule.',
        );

        if (accepted) {
          this.__applyReference(this.state.referenceId.data);
        }
      },
      selectType: e => {
        if (e.value !== this.state.type) {
          this.formService.apply('type', e.value);

          if (e.value !== TYPE.PERCENT) {
            this.formService.apply('rate', '100');
            this.formService.apply('referenceId', selectors.ITEM_EMPTY);
          }

          this.__resetItems();
        }
      },
      selectReferenceId: e => {
        const { id } = e.value.data;

        if (id !== this.state.referenceId.data.id) {
          this.formService.apply('referenceId', e.value);

          this.__applyReference(this.state.referenceId.data);
        }
      },
      changeRate: e => {
        this.formService.apply('rate', e.value);

        this.__applyRate();
      },
      clickCheckbox: e => {
        const [localIndex, key] = e.name.split('.');
        const localItem = this.__chargesState.pageItems[localIndex];
        const index = this.getChargeIndex(localItem);
        const { pageIndex } = this.__chargesState;
        const otherKey =
          key === 'nonCovered' ? 'suppressFromClaim' : 'nonCovered';

        if (e.value) {
          this.formService.apply(`charges.${index}.${otherKey}`, !e.value);
        }
        this.formService.apply(`charges.${index}.${key}`, e.value);
        this.__syncCharges();
        this.__chargesService.setPageIndex(pageIndex);
      },
      addCharge: async () => {
        const currentItems = [...this.state.charges];
        const items = (await openOverlay(OVERLAY_KEYS.LEDGER_ADD_CHARGE, {
          config: genAddChargesConfig(this.isType('CAPITATED')),
          items: this.__charges,
          selectedCharges: currentItems,
        }))
          .filter(item => !this.state.charges.some(c => c.id === item.id))
          .map(item =>
            !this.isType('CAPITATED')
              ? {
                  ...item,
                  nonCovered: false,
                }
              : {
                  ...item,
                  allowedAmount: '$0.00',
                  adjustmentAmount: item.amount,
                  suppressFromClaim: false,
                  nonCovered: true,
                },
          );

        this.__appendChargeItems(items);
      },
      editCharge: e => {
        const key = e.name.split('.')[2];

        const localIndex = e.name.split('.')[1];
        const localItem = this.__chargesState.pageItems[localIndex];
        const index = this.getChargeIndex(localItem);

        if (key !== 'modifiers') {
          const { pageIndex } = this.__chargesState;
          const otherKey = key === 'amount' ? 'allowedAmount' : 'amount';

          this.formService.apply(`charges.${index}.${key}`, e.value);
          this.formService.validateKey(['charges', index, otherKey], true);
          this.__updateAdjustmentAmount(this.state.charges[index], index);
          this.__syncCharges();
          this.__chargesService.setPageIndex(pageIndex);
        } else {
          const modifierIndex = e.name.split('.')[3];

          this.handlers.change({
            name: `charges.${index}.${key}.${modifierIndex}`,
            value: e.value,
          });
        }
      },
      removeCharge: async (name, item) => {
        const chargeType = buildChargeType(this.isType('CAPITATED'), false);
        const title = `Remove ${chargeType}`;
        const message = `This will remove the ${chargeType.toLowerCase()} from the fee schedule.`;
        const accepted = await this.__openWarningPopup(title, message);

        if (accepted) {
          const index = this.getChargeIndex(item);

          this.formService.removeItem(name, index);
          this.__syncCharges();
        }
      },
      addPayerPlan: async () => {
        const { payerPlans: associatedPayers } = this.formService.build();
        const selectedPayers = await openOverlay(OVERLAY_KEYS.ASSOCIATE_PAYER, {
          associatedPayers,
          payers: this.payers,
          parentItem: FEE_SCHEDULE.toLowerCase(),
        });

        if (selectedPayers) {
          this.handlers.removeAll();

          const formattedPayers = selectedPayers.map(payer => ({
            ...payer,
            providerIds: selectors
              .nonStrictMultiSelect(this.providers, [], [])
              .format(payer.providerIds),
            availableProviders: selectors
              .nonStrictMultiSelect(this.providers, [], [])
              .format(payer.availableProviders),
          }));

          this.__appendPayerPlanItems(formattedPayers);
        }
      },
      removePayerPlan: (_, item) => {
        const index = this.state.payerPlans.findIndex(pp => pp.id === item.id);
        this.formService.removeItem('payerPlans', index);
        this.__updatePayerPagination();
      },
      copy: async () => {
        const item = await openPopup(POPUP_RENDER_KEYS.COPY, {
          name: 'Fee Schedule',
          items: this.getCopyFeeSchedules(),
          dirty: this.formService.isDirty,
        });

        if (item) {
          this.__copyFeeSchedule(item.data);
        }
      },
      // eslint-disable-next-line complexity
      save: async () => {
        if (this.formService.validate()) {
          if (!this.state.active && this.state.payerPlans.length) {
            if (this.model.id && this.model.active) {
              const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
                title: 'Inactivate Fee Schedule',
                message:
                  'Inactivating this fee schedule will remove associated payers from this fee schedule.  Do you wish to continue?',
                confirmText: 'Yes',
                cancelText: 'No',
              });

              if (!res) return;
            } else {
              await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
                title: 'Notice',
                message:
                  'Payers can only be associated to active fee schedules. Please reactivate the fee schedule to add payers.',
              });

              return;
            }
          }

          if (this.state.charges.length) {
            const model = this.formService.build();

            this.__saving = true;
            this.onSave(model);
          } else {
            this.__tabId = ELEMENTS.generalTab.id;

            const chargeType = buildChargeType(this.isType('CAPITATED'), false);
            const title = `At Least One ${chargeType}`;
            const message = `There must be at least one ${chargeType.toLowerCase()} tied to a fee schedule. Please add a charge and try again.`;

            await openPopup(POPUP_RENDER_KEYS.MESSAGE, { title, message });
          }
        } else {
          this.__navigateToErrorTab();
        }
      },
      removeAll: () => {
        const { payerPlans } = this.formService.build();
        payerPlans.forEach(payer => this.handlers.removePayerPlan('', payer));
      },
      changeAssociatedProvider: e => {
        const { pageIndex } = this.__payerPlansPagination;

        const localIndex = parseInt(e.name.split('.')[1], 10);

        const index = pageIndex * PAYER_PLANS_PAGE_SIZE + localIndex;

        this.handlers.change({
          name: `payerPlans.${index}.providerIds`,
          value: e.value,
        });
      },
    };
  }

  __initServices() {
    const MODIFIERS = selectors.currency({
      validateRaw: true,
      validators: [atMin(0, true, 'Must be greater or equal to $0.00')],
    });

    this.__bulkUpdateService = new FormService(
      {
        amount: 0,
        allowedAmount: 0,
      },
      {
        children: {
          amount: MODIFIERS,
          allowedAmount: MODIFIERS,
        },
      },
      (_, state, errors) => {
        this.__bulkUpdateState = state;
        this.__bulkUpdateErrors = errors;
      },
    );
  }

  load() {
    return Promise.all([
      this.__loadChargeItems(),
      this.__loadFeeScheduleItems(),
      this.__loadAdjustmentCodes(),
    ]);
  }

  genNavItems() {
    return [
      {
        id: ELEMENTS.generalTab.id,
        label: 'General',
        validationPaths: [
          'name',
          'rate',
          'referenceId',
          'payerAdjustmentCodeId',
          'patientAdjustmentCodeId',
          ...this.state.charges.flatMap((item, index) => [
            `charges.${index}.amount`,
            `charges.${index}.allowedAmount`,
            `charges.${index}.adjustmentAmount`,
            `charges.${index}.modifiers`,
          ]),
        ],
        renderer: () => html`
          <div id="${ELEMENTS.generalTabContent.id}" class="grid grid-lean">
            ${this.renderGeneralSection()} ${this.renderPercentSection()}
            ${this.renderChargesSection()}
          </div>
        `,
      },
      {
        id: ELEMENTS.payerPlansTab.id,
        label: 'Payer Plans',
        validationPaths: [
          ...this.state.payerPlans.map(
            (_, index) => `payerPlans.${index}.providerIds`,
          ),
        ],
        renderer: () => html`
          <div id="${ELEMENTS.payerPlansTabContent.id}" class="grid grid-lean">
            ${this.renderPayerPlansSection()}
          </div>
        `,
      },
    ];
  }

  async __loadAdjustmentCodes() {
    this.__adjustmentCodes = (await billingCodesApi.fetch(
      billingCodesApi.TYPE.WRITEOFF,
      true,
    ))
      .filter(item => item.active && item.code !== 'Pkg')
      .map(data => ({ data, label: `${data.code} - ${data.description}` }));

    this.__adjustmentCodes.unshift(selectors.ITEM_EMPTY);
  }

  async __loadChargeItems() {
    this.__charges = (await chargesApi.getMany({ hideInactive: true })).map(
      c => ({
        id: c.id,
        active: c.active,
        procedure: c.procedure,
        description: c.description,
        modifiers: c.modifiers,
        baseAmount: centsToCurrency(c.amount),
        amount: centsToCurrency(c.amount),
        allowedAmount: centsToCurrency(c.amount),
        adjustmentAmount: centsToCurrency(0),
        suppressFromClaim: c.suppressFromClaim,
      }),
    );
  }

  async __loadFeeScheduleItems() {
    this.__feeSchedules = (await feeSchedulesApi.fetchManyV4()).map(data => ({
      label: data.name,
      data,
    }));
  }

  __updateAdjustmentAmount(item, index) {
    const amount = currencyToCents(item.amount);
    const allowedAmount = currencyToCents(item.allowedAmount);
    const adjustmentAmount = centsToCurrency(amount - allowedAmount);

    this.formService.apply(
      `charges.${index}.adjustmentAmount`,
      adjustmentAmount,
    );
  }

  async __bulkUpdate(key, label) {
    const value = this.__bulkUpdateState[key];
    const accepted = await this.__openWarningPopup(
      `Bulk Update - ${label}`,
      `This action will update the ${label} amounts for all currently displayed charges to ${value}.`,
    );

    if (accepted) {
      const otherKey = key === 'amount' ? 'allowedAmount' : 'amount';
      const { pageIndex } = this.__chargesState;

      this.__chargesService.getFilteredItems().forEach(item => {
        const index = this.getChargeIndex(item);
        const updatedItem = { ...item, [key]: value };

        this.formService.apply(`charges.${index}.${key}`, value);
        this.formService.validateKey(['charges', index, key], true);
        this.formService.validateKey(['charges', index, otherKey], true);
        this.__updateAdjustmentAmount(updatedItem, index);
      });

      this.__syncCharges();
      this.__chargesService.setPageIndex(pageIndex);
    }
  }

  __syncCharges() {
    this.__chargesService.setItems([...this.state.charges]);
  }

  __updatePayerPagination() {
    this.__payerPlansPagination = {
      ...this.__payerPlansPagination,
      pageCount: Math.ceil(
        this.state.payerPlans.length / PAYER_PLANS_PAGE_SIZE,
      ),
    };
  }

  __resetList(key) {
    new Array(this.state[key].length)
      .fill()
      .map((_, index) => index)
      .forEach(() => this.formService.removeItem(key));
  }

  __resetItems() {
    this.__resetList('charges');
    this.__syncCharges();

    this.__resetList('payerPlans');
    this.__updatePayerPagination();
  }

  __appendItems(name, items) {
    items.forEach(item => {
      const index = this.state[name].length;

      this.formService.addItem(name);
      APPEND_KEYS[name].forEach(key =>
        this.formService.apply(
          `${name}.${index}.${key}`,
          getValueByPath(item, key.split('.')),
        ),
      );
    });
  }

  __appendChargeItems(items) {
    this.__appendItems('charges', items);
    this.__syncCharges();
  }

  __appendPayerPlanItems(items) {
    this.__appendItems('payerPlans', items);
    this.__updatePayerPagination();
  }

  __copyFeeSchedule(feeSchedule) {
    const payerAdjustmentCodeId = this.findItemById(
      this.getPayerAdjustmentCodeItems(),
      feeSchedule,
      'payerAdjustmentCodeId',
    );

    const patientAdjustmentCodeId = this.findItemById(
      this.getPatientAdjustmentCodeItems(),
      feeSchedule,
      'patientAdjustmentCodeId',
    );

    this.formService.apply('name', `Copy of ${feeSchedule.name}`);
    this.formService.apply('type', feeSchedule.type);
    this.formService.apply('payerAdjustmentCodeId', payerAdjustmentCodeId);
    this.formService.apply('patientAdjustmentCodeId', patientAdjustmentCodeId);

    if (feeSchedule.type === TYPE.PERCENT) {
      const referenceId = this.findItemById(
        this.__feeSchedules,
        feeSchedule,
        'referenceId',
      );

      this.formService.apply('rate', feeSchedule.rate);
      this.formService.apply('referenceId', referenceId);
    }

    this.__updateCharges(feeSchedule);
  }

  __updateCharges(item) {
    const charges = item.charges.map(c => ({
      ...c,
      baseAmount: centsToCurrency(c.baseAmount),
      amount: centsToCurrency(c.amount),
      allowedAmount: centsToCurrency(c.allowedAmount),
      adjustmentAmount: centsToCurrency(c.adjustmentAmount),
      suppressFromClaim: c.suppressFromClaim,
      nonCovered: c.nonCovered,
    }));

    this.__resetList('charges');
    this.__appendChargeItems(charges);
  }

  __applyRate() {
    this.__chargesState.allItems.forEach((item, index) => {
      const factor = Number(this.state.rate) / 100;
      const amount = currencyToCents(item.baseAmount) * factor;
      const str = centsToCurrency(amount);

      ['amount', 'allowedAmount'].forEach(key =>
        this.formService.apply(`charges.${index}.${key}`, str),
      );

      this.formService.apply(
        `charges.${index}.adjustmentAmount`,
        centsToCurrency(0),
      );
    });

    this.__syncCharges();
  }

  __applyReference(item) {
    this.__updateCharges(item);
    this.__applyRate();
  }

  __openWarningPopup(title, message) {
    return openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title,
      confirmText: 'Yes',
      cancelText: 'No',
      message: html`
        <p>${message}</p>
        <p>Are you sure that you want to proceed?</p>
      `,
    });
  }

  __navigateToErrorTab() {
    const selectedItem = this.genNavItems().find(item =>
      item.validationPaths.some(path => {
        const error = getValueByPath(this.errors, path.split('.'));

        if (Array.isArray(error)) {
          return error.some(e => e);
        }

        return error;
      }),
    );

    if (selectedItem) {
      this.__tabId = selectedItem.id;
    }
  }

  findItemById(items, feeSchedule, idKey) {
    return (
      items.find(item => item.data.id === feeSchedule[idKey]) ||
      selectors.ITEM_EMPTY
    );
  }

  getPayerAdjustmentCodeItems() {
    return this.__adjustmentCodes.filter(
      item => item.data.type === 1 || item.data.type === 2,
    );
  }

  getPatientAdjustmentCodeItems() {
    return this.__adjustmentCodes.filter(
      item => item.data.type === 0 || item.data.type === 2,
    );
  }

  getReferenceFeeSchedules() {
    return this.__feeSchedules.filter(
      fs =>
        (fs.data.active && fs.data.type !== TYPE.PERCENT) ||
        fs.data.id === this.model.referenceId,
    );
  }

  getCopyFeeSchedules() {
    return this.__feeSchedules.filter(
      fs => fs.data.active && fs.data.type === this.state.type,
    );
  }

  isType(type) {
    return this.state.type === TYPE[type];
  }

  getChargeIndex(localItem) {
    return this.__chargesState.allItems.findIndex(c => c.id === localItem.id);
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.reload();
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__syncCharges();
      this.__updatePayerPagination();
    }
  }

  renderChargesTable() {
    const masterCount = this.__chargesService
      ? this.__chargesService.getTotalCount()
      : 0;

    const chargeType = buildChargeType(this.isType('CAPITATED'));

    const emptyMessage = masterCount
      ? 'No results.'
      : `There are no ${chargeType.toLowerCase()}. Click "Add Charges" to add ${chargeType.toLowerCase()}.`;

    const errors = this.__chargesState.pageItems.map(item => {
      const index = this.getChargeIndex(item);

      return this.errors.charges[index];
    });

    return html`
      <neb-table-fee-schedule-charges
        id="${ELEMENTS.chargesTable.id}"
        name="charges"
        .layout="${this.layout}"
        .type="${this.state.type}"
        .config="${genChargesTableConfig(this.isType('PERCENT'))}"
        .model="${this.__chargesState.pageItems}"
        .errors="${errors}"
        .emptyMessage="${emptyMessage}"
        .onChange="${this.handlers.editCharge}"
        .onClickCheckbox="${this.handlers.clickCheckbox}"
        .onRemove="${this.handlers.removeCharge}"
        ?showRemoveButton="${!this.isType('PERCENT')}"
      ></neb-table-fee-schedule-charges>
    `;
  }

  renderChargesPagination() {
    return this.__chargesState.pageCount > 1
      ? html`
          <div class="grid grid-auto-right pad">
            <div></div>
            <neb-pagination
              id="${ELEMENTS.chargesPagination.id}"
              .pageCount="${this.__chargesState.pageCount}"
              .currentPage="${this.__chargesState.pageIndex}"
              .onPageChanged="${this.handlers.selectChargesPage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  renderPayerPlansPagination() {
    return this.__payerPlansPagination.pageCount > 1
      ? html`
          <div class="grid grid-auto-right pad">
            <div></div>
            <neb-pagination
              id="${ELEMENTS.payerPlansPagination.id}"
              .pageCount="${this.__payerPlansPagination.pageCount}"
              .currentPage="${this.__payerPlansPagination.pageIndex}"
              .onPageChanged="${this.handlers.selectPayerPlansPage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  renderPercentSection() {
    return this.isType('PERCENT')
      ? html`
          <div class="grid grid-2">
            <neb-select
              id="${ELEMENTS.referenceId.id}"
              name="referenceId"
              label="Reference Fee Schedule"
              helper="Required"
              .value="${this.state.referenceId}"
              .error="${this.errors.referenceId}"
              .items="${this.getReferenceFeeSchedules()}"
              .onChange="${this.handlers.selectReferenceId}"
              ?disabled="${this.isEditing()}"
            ></neb-select>

            <div class="grid grid-3 grid-lean">
              <neb-textfield
                id="${ELEMENTS.rate.id}"
                name="rate"
                label="Rate"
                helper="1-999"
                maxLength="3"
                .value="${this.state.rate}"
                .error="${this.errors.rate}"
                .mask="${numberNoLeadingZero}"
                .inputMode="${'numeric'}"
                .onChange="${this.handlers.changeRate}"
              ></neb-textfield>
              <div>%</div>
            </div>
          </div>
        `
      : '';
  }

  renderGeneralSection() {
    return html`
      <div class="grid grid-2">
        <div class="grid grid-lean">
          <neb-textfield
            id="${ELEMENTS.name.id}"
            name="name"
            label="Name"
            helper="Required"
            .value="${this.state.name}"
            .error="${this.errors.name}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <div class="grid grid-auto-left">
            <neb-switch
              id="${ELEMENTS.activeSwitch.id}"
              label="Active"
              name="active"
              ?on="${this.state.active}"
              .onChange="${this.handlers.change}"
            ></neb-switch>
          </div>
        </div>

        <div>
          <div class="grid grid-auto-left">
            <neb-radio-button
              id="${ELEMENTS.feeRadio.id}"
              label="Fee for Service"
              .value="${TYPE.FEE}"
              .checked="${this.isType('FEE')}"
              .onChange="${this.handlers.selectType}"
              ?disabled="${this.isEditing()}"
            ></neb-radio-button>

            <neb-tooltip id="${ELEMENTS.feeTooltip.id}" class="pad">
              <div slot="tooltip">
                Establish this Fee for Service schedule by selecting covered
                charges and entering their negotiated "Allowed Amount" rates.
              </div>
            </neb-tooltip>
          </div>

          <div class="grid grid-auto-left">
            <neb-radio-button
              id="${ELEMENTS.percentRadio.id}"
              label="% of Fee for Service"
              .value="${TYPE.PERCENT}"
              .checked="${this.isType('PERCENT')}"
              .onChange="${this.handlers.selectType}"
              ?disabled="${
                this.isEditing() || !this.getReferenceFeeSchedules().length
              }"
            ></neb-radio-button>

            <neb-tooltip id="${ELEMENTS.percentTooltip.id}">
              <div slot="tooltip">
                Establish this % of Fee for Service schedule by selecting a
                reference fee schedule, followed by the desired percent amount.
              </div>
            </neb-tooltip>
          </div>

          <div class="grid grid-auto-left">
            <neb-radio-button
              id="${ELEMENTS.capitatedRadio.id}"
              label="Capitated"
              .value="${TYPE.CAPITATED}"
              .checked="${this.isType('CAPITATED')}"
              .onChange="${this.handlers.selectType}"
              ?disabled="${this.isEditing()}"
            ></neb-radio-button>

            <neb-tooltip id="${ELEMENTS.capitatedTooltip.id}">
              <div slot="tooltip">
                Establish this capitated fee schedule by selecting carved out
                charges and entering the desired "Allowed Amount" rates.
              </div>
            </neb-tooltip>
          </div>
        </div>
      </div>
    `;
  }

  renderBulkUpdate() {
    const state = this.__bulkUpdateState;
    const errors = this.__bulkUpdateErrors;
    const noCharges = !this.__chargesState.allItems.length;
    const percentOrNoCharges = this.isType('PERCENT') || noCharges;

    const disableAmountButton = percentOrNoCharges || errors.amount;

    const disableAllowedAmountButton = noCharges || errors.allowedAmount;

    return html`
      <div class="grid grid-bulk-update">
        <span class="label">
          Bulk Update Fee Schedule Charge and Allowed Amounts
        </span>

        <neb-textfield
          id="${ELEMENTS.amount.id}"
          class="field"
          name="amount"
          inputMode="numeric"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .value="${state.amount}"
          .error="${errors.amount}"
          .onChange="${this.handlers.changeBulkUpdate}"
          ?disabled="${percentOrNoCharges}"
        ></neb-textfield>

        <neb-button-icon
          id="${ELEMENTS.amountBulkUpdate.id}"
          class="icon"
          name="amount"
          icon="neb:updateAll"
          .onClick="${this.handlers.bulkUpdateAmount}"
          ?disabled="${disableAmountButton}"
        ></neb-button-icon>

        <neb-textfield
          id="${ELEMENTS.allowedAmount.id}"
          class="field"
          name="allowedAmount"
          inputMode="numeric"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .value="${state.allowedAmount}"
          .error="${errors.allowedAmount}"
          .onChange="${this.handlers.changeBulkUpdate}"
          ?disabled="${noCharges}"
        ></neb-textfield>

        <neb-button-icon
          id="${ELEMENTS.allowedBulkUpdate.id}"
          class="icon"
          name="allowedAmount"
          icon="neb:updateAll"
          .onClick="${this.handlers.bulkUpdateAllowed}"
          ?disabled="${disableAllowedAmountButton}"
        ></neb-button-icon>
        <div></div>
      </div>
    `;
  }

  renderChargesSection() {
    const label = buildChargeType(this.isType('CAPITATED'));

    return html`
      <neb-header label="${label}"></neb-header>

      <div class="grid grid-adjustment">
        <neb-textfield
          id="${ELEMENTS.chargesSearch.id}"
          class="search"
          label=" "
          placeholder="Enter procedure or description to filter list below."
          leadingIcon="neb:search"
          helper=" "
          .trailingIcon="${this.__chargesState.searchText ? 'neb:clear' : ''}"
          .value="${this.__chargesState.searchText}"
          .onChange="${this.handlers.searchCharges}"
          .onClickIcon="${this.handlers.clearChargesSearch}"
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.payerAdjustmentCodeId.id}"
          class="select"
          label="Payer Adjustment Code"
          name="payerAdjustmentCodeId"
          helper="Required"
          .items="${this.getPayerAdjustmentCodeItems()}"
          .value="${this.state.payerAdjustmentCodeId}"
          .error="${this.errors.payerAdjustmentCodeId}"
          .onChange="${this.handlers.change}"
        ></neb-select>

        <neb-select
          id="${ELEMENTS.patientAdjustmentCodeId.id}"
          class="select"
          label="Patient Adjustment Code"
          name="patientAdjustmentCodeId"
          helper="Required"
          .items="${this.getPatientAdjustmentCodeItems()}"
          .value="${this.state.patientAdjustmentCodeId}"
          .error="${this.errors.patientAdjustmentCodeId}"
          .onChange="${this.handlers.change}"
        ></neb-select>

        <neb-tooltip id="${ELEMENTS.adjustmentCodeToolTip.id}">
          <div slot="tooltip">
            For adjustment amounts entered below, set a default write-off or
            adjustment code for both Payer and Patient charge responsibility
            types. Values entered here will automatically populate with their
            corresponding adjustment amounts once posted against the Ledger.
          </div>
        </neb-tooltip>
      </div>

      <div class="grid grid-auto-left">
        <neb-button-action
          id="${ELEMENTS.addChargesButton.id}"
          class="pad"
          label="Add Charges"
          .onClick="${this.handlers.addCharge}"
          ?disabled="${this.isType('PERCENT')}"
        ></neb-button-action>
      </div>

      ${this.renderBulkUpdate()} ${this.renderChargesTable()}
      ${this.renderChargesPagination()}
    `;
  }

  __getPagedPayerPlans() {
    const { pageIndex } = this.__payerPlansPagination;

    const startIndex = pageIndex * PAYER_PLANS_PAGE_SIZE;
    const endIndex = startIndex + PAYER_PLANS_PAGE_SIZE;

    return this.state.payerPlans.slice(startIndex, endIndex);
  }

  renderPayerPlansSection() {
    const masterCount = this.state.payerPlans
      ? this.state.payerPlans.length
      : 0;

    const emptyMessage = masterCount
      ? 'No results.'
      : 'There are no payer plans. Click "Add Payer Plans" to associate payer plans.';

    const pagedPayerPlans = this.__getPagedPayerPlans();

    return html`
      <div class="grid grid-auto-left">
        <neb-button-action
          id="${ELEMENTS.addPayerPlansButton.id}"
          name="payerPlans"
          class="pad"
          label="${ASSOCIATE_PAYER}"
          .onClick="${this.handlers.addPayerPlan}"
        ></neb-button-action>
      </div>

      <neb-table-payer-plans
        id="${ELEMENTS.payerPlansTable.id}"
        name="payerPlans"
        .errors="${this.errors.payerPlans}"
        .emptyMessage="${emptyMessage}"
        .model="${pagedPayerPlans}"
        .providers="${this.providers}"
        .showRemoveAll="${true}"
        showRemoveButton
        .onRemove="${this.handlers.removePayerPlan}"
        .onRemoveAll="${this.handlers.removeAll}"
        .onChange="${this.handlers.changeAssociatedProvider}"
        showProviders
      ></neb-table-payer-plans>

      ${this.renderPayerPlansPagination()}
    `;
  }

  renderUnderline() {
    return html`
      <div class="underline"></div>
    `;
  }

  renderRefreshButton() {
    const isGeneralTab = this.__tabId === ELEMENTS.generalTab.id;

    return this.isEditing() && this.isType('PERCENT') && isGeneralTab
      ? html`
          <neb-button
            id="${ELEMENTS.refreshButton.id}"
            class="button underline"
            label="Refresh Reference Fee Schedule"
            role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.handlers.refresh}"
          ></neb-button>
        `
      : this.renderUnderline();
  }

  renderCopyButton() {
    const isGeneralTab = this.__tabId === ELEMENTS.generalTab.id;

    return this.getCopyFeeSchedules().length && isGeneralTab
      ? html`
          <neb-button
            id="${ELEMENTS.copyButton.id}"
            class="button underline"
            label="Copy Existing Fee Schedule"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.handlers.copy}"
          ></neb-button>
        `
      : this.renderUnderline();
  }

  renderTabRow() {
    return html`
      <div class="grid grid-3 grid-lean grid-tabs">
        <neb-tab-group
          id="${ELEMENTS.tabGroup.id}"
          class="tabs"
          .selectedId="${this.__tabId}"
          .items="${this.genNavItems()}"
          .onSelect="${this.handlers.selectTab}"
        ></neb-tab-group>

        ${this.renderUnderline()} ${this.renderRefreshButton()}
        ${this.renderCopyButton()}
      </div>
    `;
  }

  renderTabContent() {
    const tabItem = this.genNavItems().find(item => item.id === this.__tabId);

    return tabItem.renderer();
  }

  renderContent() {
    return html`
      ${this.renderTabRow()} ${this.renderTabContent()}
    `;
  }
}

customElements.define('neb-form-fee-schedules', NebFormFeeSchedules);
