import '../../../tables/neb-table-ledger-charges';
import '../../../controls/neb-horizontal-scroll';
import '../../../../../../../src/components/filters/neb-filters-ledger-charges';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { html, css, LitElement, unsafeCSS } from 'lit';
import { v4 as uuid } from 'uuid';

import { bulkUpdateCase } from '../../../../../../../src/api-clients/line-item';
import { createNewCase } from '../../../../../../../src/features/charting/neb-charting-util';
import {
  formatBilled,
  formatHold,
} from '../../../../../../../src/formatters/line-items';
import { viewERAsEOBs } from '../../../../../../../src/utils/era-eob';
import sortRollups from '../../../../../../../src/utils/sort/rollups';
import {
  NO_ITEMS_INITIAL_LOAD,
  UPDATE_ENCOUNTER_CASE_BANNER_ERROR,
  UPDATE_ENCOUNTER_CASE_BANNER_SUCCESS,
  WARNING_INVOICES_ASSOCIATED_CHARGE_LEVEL,
} from '../../../../../../../src/utils/user-message';
import { getEncounter } from '../../../../../../neb-api-client/src/encounters-api-client';
import { getInvoicesWithMatchingHeader } from '../../../../../../neb-api-client/src/invoice-api-client';
import { fetchRollups } from '../../../../../../neb-api-client/src/ledger/charges';
import { addLineItemsToInvoice } from '../../../../../../neb-api-client/src/ledger/invoices';
import { getLineItems } from '../../../../../../neb-api-client/src/ledger/line-items';
import { addFromLineItems } from '../../../../../../neb-api-client/src/ledger-superbill-api-client';
import { mapToPatientCaseName } from '../../../../../../neb-api-client/src/mappers/patient-case-mapper';
import * as patientApiClient from '../../../../../../neb-api-client/src/patient-api-client';
import * as patientCasesApiClient from '../../../../../../neb-api-client/src/patient-cases';
import { getPatientPayers } from '../../../../../../neb-api-client/src/payers';
import { getChartingPermissions } from '../../../../../../neb-api-client/src/permissions-api-client';
import getBulkActionList from '../../../../../../neb-api-client/src/services/line-items/get-bulk-action-list';
import { openOverlayPatientInsuranceView } from '../../../../../../neb-app-layout/neb-open-overlay';
import {
  openSuccess,
  openError,
} from '../../../../../../neb-dialog/neb-banner-state';
import { ISO_DATE_FORMAT } from '../../../../../../neb-input/nebFormatUtils';
import { POPUP_RENDER_KEYS } from '../../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_3,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_WHITE,
} from '../../../../../../neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../../../neb-utils/constants';
import { parseDate } from '../../../../../../neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../../neb-utils/feature-util';
import { getPatientDisplayName } from '../../../../../../neb-utils/neb-charting-util';
import { LINE_ITEM_TYPE } from '../../../../../../neb-utils/neb-ledger-util';
import { URL_NO_ACCESS } from '../../../../../../neb-utils/neb-request-security';
import {
  EMPTY_RESPONSE,
  FetchService,
} from '../../../../../../neb-utils/services/fetch';
import ExpandedTableService from '../../../../services/expanded-table';
import SyncTableColumnsService from '../../../../services/sync-table-columns';
import { openEncounterSummary } from '../../../../utils/encounter-overlays-util';
import { handleBulkAction } from '../../../../utils/ledger-charges-bulk-actions';
import { openOverlay, OVERLAY_KEYS } from '../../../../utils/overlay-constants';

import {
  formatResponsibility,
  formatCode,
  createProviderDict,
  getConfigs,
  getFormattedDate,
} from './neb-ledger-charges-util';

export const TABS = {
  CHARGES: 'charges',
  CLOSED: 'closed',
  OPEN: 'open',
};

export const ELEMENTS = {
  filters: { id: 'filters' },
  content: { id: 'content' },
  table: { id: 'table' },
  footer: { id: 'footer' },
  footerCells: { selector: '.footer-cell' },
  description: { id: 'description' },
  scrollContainer: { id: 'scroll-container' },
  hoverHorizontalScrollBar: { id: 'hover-horizontal-scroll-bar' },
  nebTableLedgerChargeExpanded: {
    selector: 'neb-table-ledger-charge-expanded',
  },
  pagination: { id: 'pagination' },
};

export const MENU_HEIGHT = 416;

const OPEN_CHARGES_FETCH_ERROR =
  'An error occurred while fetching open charges data';

export const NO_BULK_ACTIONS_AVAILABLE = {
  title: 'Bulk Actions',
  message: 'There are no bulk actions that can be taken on these charges.',
};

export const PAGE_SIZE = 100;

class NebLedgerCharges extends LitElement {
  static get properties() {
    return {
      __tableState: Object,
      __firstScrollHandler: Boolean,
      __secondScrollHandler: Boolean,
      __hasPopupCallback: Boolean,
      __locations: Array,
      __defaultLocationId: String,
      __marginBottom: Number,
      __minWidth: Number,
      __scrollLeft: Number,
      __rectTop: Number,
      __totals: Object,
      __rollUps: Array,
      __expandedFlags: Array,
      __outerTableConfig: Array,
      __innerTableConfig: Array,
      __checkedLineItemIds: Array,
      __selectedCharges: Array,
      __patientPayers: Array,
      __showERAsAndEOBsAssociations: Boolean,
      __hasDisableInitialLoadFF: Boolean,
      __hasFitInvoiceOverlayPerformanceFF: Boolean,
      patient: Object,
      cases: Array,
      mode: String,
      description: String,
      noChargesMessage: String,
      showResponsibilityBalance: Boolean,
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__firstScrollHandler = false;
    this.__secondScrollHandler = false;
    this.__hasPopupCallback = true;
    this.__locations = [];
    this.__defaultLocationId = '';

    this.__minWidth = 1400;
    this.__yLayoutPosition = 0;

    this.__totals = {
      invoiceNumber: '',
    };

    this.__tableState = {
      body: {},
      pageIndex: 0,
      pageCount: 1,
      pageSize: PAGE_SIZE,
      pageItems: [],
    };

    this.__patients = [];
    this.__rollUps = [];
    this.__expandedFlags = [];
    this.__checkedLineItemIds = [];
    this.__selectedCharges = [];
    this.__outerTableConfig = [];
    this.__innerTableConfig = [];
    this.__marginBottom = 0;
    this.__rectTop = 0;
    this.__changedPage = false;
    this.__showERAsAndEOBsAssociations = false;
    this.__hasDisableInitialLoadFF = false;
    this.__hasFitInvoiceOverlayPerformanceFF = false;
    this.__hasAppliedFilters = false;

    this.showResponsibilityBalance = false;
    this.layout = '';
    this.mode = TABS.OPEN;
    this.description = 'Review open charges and perform updates.';
    this.noChargesMessage = 'There are no open charges for this patient.';
    this.patient = null;
    this.cases = [];
    this.__patientPayers = [];

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      selectPage: pageIndex => {
        this.__setTableState({ pageIndex });
        this.__changedPage = true;
      },

      filter: (model, isButtonPress) => {
        if (isButtonPress) {
          this.__hasAppliedFilters = true;
        }

        const userLocationIds = this.__locations.map(l => l.id);
        const invoice = model.notInvoiced ? 'NOT_INVOICED' : model.invoice;
        const encounterNumber = model.encounterNumber || undefined;

        const locationIds = model.locationIds.length
          ? model.locationIds
          : userLocationIds;

        const caseIds = model.caseIds.length ? model.caseIds : undefined;

        const patientId = model.patientId || undefined;

        const providerIds = model.providerIds.length
          ? model.providerIds
          : undefined;

        const payerIds = model.payerIds.length ? model.payerIds : undefined;

        const dosFrom = model.dateOfService.from
          ? model.dateOfService.from.format(ISO_DATE_FORMAT)
          : undefined;

        const dosTo = model.dateOfService.to
          ? model.dateOfService.to.format(ISO_DATE_FORMAT)
          : undefined;

        const billedFrom =
          model.billed.min !== null ? model.billed.min : undefined;

        const billedTo =
          model.billed.max !== null ? model.billed.max : undefined;

        const balanceFrom =
          model.balance.min !== null ? model.balance.min : undefined;

        const balanceTo =
          model.balance.max !== null ? model.balance.max : undefined;

        this.__fetchService.setQuery('invoice', invoice);
        this.__fetchService.setQuery('encounterNumber', encounterNumber);
        this.__fetchService.setQuery('patientId', patientId);
        this.__fetchService.setQuery('providerIds', providerIds);
        this.__fetchService.setQuery('locationIds', ['null', ...locationIds]);
        this.__fetchService.setQuery('caseIds', caseIds);
        this.__fetchService.setQuery('payerIds', payerIds);
        this.__fetchService.setQuery('dosFrom', dosFrom);
        this.__fetchService.setQuery('dosTo', dosTo);
        this.__fetchService.setQuery('billedFrom', billedFrom);
        this.__fetchService.setQuery('billedTo', billedTo);
        this.__fetchService.setQuery('balanceFrom', balanceFrom);
        this.__fetchService.setQuery('balanceTo', balanceTo);
      },

      toggleExpand: async (_name, item, _index, expand) => {
        if (expand && item.lineItems.length === 0) {
          const providerDict = createProviderDict();
          const lineItems = await getLineItems(item.lineItemIds);

          item.lineItems = lineItems.map(c => ({
            ...c,
            units: c.units || 1,
            billedAmount: c.billedAmount + c.taxAmount,
            patientResponsibility: formatResponsibility(
              c.patientPaid,
              c.patientOwed,
            ),
            primary: formatResponsibility(c.primaryPaid, c.primaryOwed),
            secondary: formatResponsibility(c.secondaryPaid, c.secondaryOwed),
            code: formatCode(c.code, c.modifiers),
            provider: providerDict[c.providerId] || '',
            encounter: c.encounterNumber || '',
            ...(!this.patient && { mrn: this.__getPatientInfo(c).mrn }),
            billed: formatBilled(c),
            hold: formatHold(c),
          }));

          item.lineItems.forEach(lineItem => {
            lineItem.checked = item.checked;
          });
        }

        this.__expandedTableService.updateItem(item);

        if (expand) {
          this.__alignTableColumnWidths();
        }
      },

      createSuperbill: async () => {
        try {
          const lineItems = await getLineItems(this.__checkedLineItemIds, 1);

          const providerDict = createProviderDict();

          const uniquePatientIds = [
            ...new Set(lineItems.map(item => item.patientId)),
          ];

          const patients = await patientApiClient.fetchSome(
            uniquePatientIds,
            {},
            true,
            true,
          );

          const superbillItems = lineItems.map(item => {
            const patient = patients.find(p => p.id === item.patientId);

            return {
              id: item.id,
              patientId: patient.id,
              patientName: getPatientDisplayName(patient, {
                preferred: true,
                reverse: true,
                suffix: true,
                middleInitial: true,
              }),
              providerName: providerDict[item.providerId],
            };
          });

          await addFromLineItems({
            lineItems: superbillItems,
          });

          store.dispatch(openSuccess('Superbill(s) saved successfully'));
        } catch (e) {
          store.dispatch(
            openError('An error has occurred when creating superbill(s)'),
          );
        }
      },

      viewCharges: async (_, lineItem) => {
        const { lineItemIds, patient } = this.__getOverlayLineItemIds(lineItem);
        const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

        await openOverlay(overlayKey, {
          patient,
          lineItemIds,
          selectedIds: [],
        });

        await this.onChange();
        this.fetch();
      },

      editCharges: async () => {
        const { lineItemIds, patient } = this.__getOverlayLineItemIds();
        const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

        await openOverlay(overlayKey, {
          patient,
          lineItemIds,
          selectedIds: this.__checkedLineItemIds,
          editTable: true,
        });

        await this.onChange();
        this.fetch();
      },

      addToInvoice: async () => {
        const {
          patient: { id: patientId },
        } = this.__getOverlayLineItemIds();

        const invoices = await getInvoicesWithMatchingHeader(
          patientId,
          this.__checkedLineItemIds,
        );

        const invoiceId = await openPopup(POPUP_RENDER_KEYS.ADD_TO_INVOICE, {
          invoices,
        });

        if (!invoiceId) return;

        const invoice = invoices.find(i => i.id === invoiceId);

        this.__hasPopupCallback = await this.__renderLocationsWarningPopup(
          invoice,
        );

        if (this.__hasPopupCallback) {
          try {
            await addLineItemsToInvoice({
              lineItemIds: this.__checkedLineItemIds,
              invoiceId,
              patientId,
            });

            this.fetch();
            store.dispatch(
              openSuccess('Charge(s) added successfully to Invoice'),
            );
          } catch (e) {
            console.error(e);
            store.dispatch(
              openError(
                'An error has occurred when adding charge(s) to Invoice',
              ),
            );
          }
        }
      },

      handleAction: async ({ id: actionId }) => {
        if (this.__handlers[actionId]) {
          return this.__handlers[actionId]();
        }

        const selectedChargeGroups = this.__tableState.pageItems.filter(item =>
          item.lineItemIds.some(id => this.__checkedLineItemIds.includes(id)),
        );

        const {
          patient: { id: patientId },
        } = this.__getOverlayLineItemIds();

        const selectedRows = this.__tableState.pageItems.filter(
          r =>
            !!r.lineItemIds.find(id => this.__checkedLineItemIds.includes(id)),
        );

        const uniqueInvoiceIds = selectedRows.reduce((accum, row) => {
          if (!accum.includes(row.invoiceId)) {
            accum.push(row.invoiceId);
          }

          return accum;
        }, []);

        if (actionId !== 'removeFromInvoice') {
          this.__hasPopupCallback = await this.__renderLocationsWarningPopup();

          if (!this.__hasPopupCallback) return undefined;
        }

        const someAllocated = selectedChargeGroups.some(group => {
          if (group.lineItems && group.lineItems.length) {
            const selectedLineItems = group.lineItems.filter(li =>
              this.__checkedLineItemIds.includes(li.id),
            );

            return selectedLineItems.some(
              li => li.primaryPaid || li.secondaryPaid || li.patientPaid,
            );
          }

          return group.payerPaidTotal || group.patientPaidTotal;
        });

        const actionDetails = {
          patientId,
          invoiceIds: uniqueInvoiceIds,
          selectedLineItemIds: this.__checkedLineItemIds,
          someAllocated,
        };

        await handleBulkAction(actionId, actionDetails, () => {
          this.fetch();

          return this.onChange();
        });

        return undefined;
      },

      updateCase: async () => {
        const selectedRows = this.__tableState.pageItems.filter(
          r =>
            !!r.lineItemIds.find(id => this.__checkedLineItemIds.includes(id)),
        );

        const lineItemIds = selectedRows.flatMap(j => j.lineItemIds);

        const caseIds = [
          ...new Set(selectedRows.map(charge => charge.patientCaseId)),
        ];

        const authIds = [
          ...new Set(selectedRows.map(charge => charge.patientAuthorizationId)),
        ];

        const defaultCaseId = caseIds.length > 1 ? null : caseIds[0];
        const patientAuthorizationId =
          authIds.length > 1 || !authIds[0] ? '' : authIds[0];

        const { patientId } = selectedRows[0];

        const result = await openPopup(
          POPUP_RENDER_KEYS.UPDATE_ASSOCIATED_CASE,
          {
            patientId,
            defaultCaseId,
            patientAuthorizationId,
            encounterIds: [],
            isFromCharges: true,
          },
        );

        if (!result) return;

        const invoiceNumbers = [
          ...new Set(
            selectedRows
              .filter(charge => charge.invoiceNumber)
              .map(charge => charge.invoiceNumber),
          ),
        ];

        if (invoiceNumbers.length) {
          const popupResult = await this.__handleConfirmationPopup(
            invoiceNumbers,
          );

          if (!popupResult) {
            return;
          }
        }

        let newCaseId = result.caseId;

        const newPatientAuthId = result.patientAuthorizationId;

        if (result.type === 'addNewCase') {
          const newCase = await createNewCase(patientId);
          if (!newCase) return;

          newCaseId = newCase.id;
        }

        try {
          await bulkUpdateCase({ lineItemIds, newCaseId, newPatientAuthId });

          store.dispatch(openSuccess(UPDATE_ENCOUNTER_CASE_BANNER_SUCCESS));
          this.fetch();
        } catch (e) {
          store.dispatch(openError(UPDATE_ENCOUNTER_CASE_BANNER_ERROR));
        }
      },

      dynamicLeadingHeaderActions: async () => {
        if (!this.__checkedLineItemIds.length) {
          openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: 'Bulk Actions',
            message:
              'Please select one or more charges before performing an action.',
          });

          return [];
        }

        try {
          const { actions } = await getBulkActionList({
            lineItemIds: this.__checkedLineItemIds,
          });

          if (actions.length) {
            if (this.patient) {
              const TRANSFER_BALANCE = {
                id: 'transferBalance',
                label: 'Transfer Balance',
              };

              actions.push(TRANSFER_BALANCE);
            }

            return actions.map(a => ({
              ...a,
              onSelect: this.__handlers.handleAction || (() => {}),
            }));
          }

          await openPopup(POPUP_RENDER_KEYS.MESSAGE, NO_BULK_ACTIONS_AVAILABLE);
          return [];
        } catch (e) {
          console.error(e);
          return [
            {
              label: '',
            },
          ];
        }
      },

      changeTable: e => {
        const terms = e.name.split('.');
        const lastIndex = terms[terms.length - 1];

        switch (terms.length) {
          case 2:
            const rollup = this.__tableState.pageItems[lastIndex];

            rollup.checked = e.value;

            rollup.lineItems = rollup.lineItems.map(item => ({
              ...item,
              checked: e.value,
            }));

            this.__tableState = {
              ...this.__tableState,
              pageItems: [...this.__tableState.pageItems],
            };

            break;

          case 4:
            const rollupIndex = terms[1];

            const lineItem = this.__tableState.pageItems[rollupIndex].lineItems[
              lastIndex
            ];

            lineItem.checked = e.value;

            this.__tableState.pageItems[
              rollupIndex
            ].checked = this.__tableState.pageItems[
              rollupIndex
            ].lineItems.every(item => item.checked === true);

            this.__tableState = {
              ...this.__tableState,
              pageItems: [...this.__tableState.pageItems],
            };

            break;

          default:
            break;
        }

        this.__checkedLineItemIds = this.__tableState.pageItems.reduce(
          (acc, curr, _i) => {
            const lineItemIds = curr.checked
              ? curr.lineItemIds
              : curr.lineItems.filter(li => li.checked).map(li => li.id);
            return [...acc, ...lineItemIds];
          },
          [],
        );
      },

      clickCheckbox: e => {
        e.stopPropagation();
      },

      resizeWindow: () => {
        this.__alignTableColumnWidths();
      },

      viewInvoice: async ({ item, patientId }) => {
        let patient = { id: patientId };

        if (this.__patients.length) {
          patient = {
            ...patient,
            ...this.__getPatientInfo(item),
          };
        }
        const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

        await openOverlay(overlayKey, {
          patient,
          lineItemIds: item.lineItemIds,
          selectedIds: [],
        });

        await this.onChange();
        this.fetch();
      },

      clickBillingNoteIcon: async ({
        invoiceId,
        patientId,
        dateOfAllServices,
        invoiceNumber,
      }) => {
        const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.INVOICE,
          parentId: invoiceId,
          parentData: {
            datesOfService: dateOfAllServices,
            invoice: invoiceNumber,
          },
          patientId,
        });

        if (result) this.fetch();
      },

      clickChargeNumber: async lineItem => {
        const { id, patientId, type } = lineItem;

        if (type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE) {
          await openOverlay(OVERLAY_KEYS.LEDGER_LINE_ITEM, {
            patientId,
            id,
          });
        } else {
          const { patient } = this.__getOverlayLineItemIds(lineItem);
          const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
            ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
            : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

          await openOverlay(overlayKey, {
            patient,
            lineItemIds: [id],
            selectedIds: [],
          });
        }

        await this.onChange();
        this.fetch();
      },

      editCase: async ({ patientId, patientCaseId }) => {
        const patientCase = await patientCasesApiClient.fetchOne(
          patientId,
          patientCaseId,
        );

        await openOverlay(OVERLAY_KEYS.CASE, {
          item: patientCase,
          context: {
            patientId,
            onAuthorizationChange: () => {},
          },
        });

        await this.onChange();
        this.fetch();
      },

      editPayer: ({ payerId }) => this.__openPayerOverlay(payerId),

      editPackage: async ({ patientPackageId }) => {
        await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, {
          item: {
            id: patientPackageId,
            patientId: this.patient ? this.patient.id : '',
          },
        });

        await this.onChange();
        this.fetch();
      },

      editPlan: async ({ patientId, primaryInsuranceId }) => {
        await openOverlayPatientInsuranceView({
          patientId,
          patientInsurance: { id: primaryInsuranceId },
        });

        await this.onChange();
        this.fetch();
      },

      viewEncounterSummary: async ({ encounterId, patientId }) => {
        if (await getChartingPermissions()) {
          const encounter = await getEncounter(encounterId);

          await openEncounterSummary({
            encounterId,
            patient: { id: patientId },
            appointmentTypeId: encounter.appointmentTypeId,
          });

          await this.onChange();
          this.fetch();
        } else {
          navigate(URL_NO_ACCESS);
        }
      },

      viewClaim: async ({ claimId }) => {
        await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, { claimId });
        this.fetch();
      },

      viewERAsEOBs: async ({ associatedERAsAndEOBs, lineItemId }) => {
        const response = await viewERAsEOBs({
          associatedERAsAndEOBs,
          lineItemId,
        });

        if (response) {
          await this.fetch();
        }
      },

      navigateToPatientLedger: ({ patientId }) => {
        navigate(`/patients/${patientId}/ledger/activity`);
      },

      scroll: e => {
        if (this.__firstScrollHandler) {
          this.__firstScrollHandler = false;
          return;
        }

        this.__secondScrollHandler = true;

        this.__elements.hoverHorizontalScrollBar.setScrollLeft(
          e.currentTarget.scrollLeft,
        );
      },

      hoverBarScroll: scrollLeft => {
        if (this.__secondScrollHandler) {
          this.__secondScrollHandler = false;
          return;
        }

        this.__firstScrollHandler = true;

        this.__elements.scrollContainer.scrollLeft = scrollLeft;
      },

      setYOffset: offset => this.setYOffset(offset),

      resetChangedPage: () => {
        this.__changedPage = false;
      },

      openPatientPackagesSummary: async ({ item, patientId }) => {
        await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGES_SUMMARY, {
          patientId,
          lineItems: item.lineItemIds.map(id => ({ id })),
        });

        await this.onChange();
        this.fetch();
      },
    };
  }

  __initServices() {
    this.__buildFetchService();

    this.__expandedTableService = new ExpandedTableService(flags => {
      this.__expandedFlags = flags;
    });

    this.__locationsService = new LocationsService(
      ({ userLocations, defaultLocationId }) => {
        this.__locations = userLocations;
        this.__defaultLocationId = defaultLocationId;
      },
    );

    this.__syncInterTableColumnsService = new SyncTableColumnsService(
      getConfigs({
        patient: this.patient,
        showERAsAndEOBsAssociations: this.__showERAsAndEOBsAssociations,
      }),
    );
  }

  async connectedCallback() {
    this.__hasDisableInitialLoadFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    this.__hasFitInvoiceOverlayPerformanceFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.FIT_INVOICE_OVERLAY_PERFORMANCE,
    );

    super.connectedCallback();

    this.__syncInterTableColumnsService.connect();

    window.addEventListener('resize', this.__handlers.resizeWindow);

    this.__showERAsAndEOBsAssociations = await hasFeatureOrBeta(
      FEATURE_FLAGS.SHOW_ERA_EOB_ASSOCIATIONS,
    );

    this.__syncInterTableColumnsService.setConfigs(
      getConfigs({
        patient: this.patient,
        showERAsAndEOBsAssociations: this.__showERAsAndEOBsAssociations,
      }),
    );

    this.__alignTableColumnWidths();

    this.__locationsService.connect();

    if (this.patient) {
      this.__getPatientPayers();
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__syncInterTableColumnsService.disconnect();
    window.removeEventListener('resize', this.__handlers.resizeWindow);
    this.__locationsService.disconnect();
  }

  __hasMultipleLocations(locationIds) {
    return locationIds.some(locationId => locationId !== locationIds[0]);
  }

  __hasDiffChargeLocations(invoice) {
    const [
      invoiceLocations,
      lineItemLocations,
    ] = this.__tableState.pageItems.reduce(
      ([pageItemLocations, liLocations], pageItem) => {
        if (pageItem.checked) {
          pageItemLocations.push(...pageItem.locationIds);
        } else {
          liLocations.push(
            ...pageItem.lineItems
              .filter(li => li.checked)
              .map(li => li.locationId),
          );
        }

        return [pageItemLocations, liLocations];
      },
      [[], []],
    );

    return this.__hasMultipleLocations([
      ...invoiceLocations,
      ...lineItemLocations,
      ...(invoice && invoice.locationIds ? invoice.locationIds : []),
    ]);
  }

  __renderLocationsWarningPopup(invoice) {
    return this.__hasDiffChargeLocations(invoice)
      ? openPopup(POPUP_RENDER_KEYS.LOCATIONS_WARNING, {
          invoice,
        })
      : true;
  }

  __getOverlayLineItemIds(lineItem) {
    const rollUp = this.__tableState.pageItems.find(r =>
      r.lineItemIds.includes(
        lineItem ? lineItem.id : this.__checkedLineItemIds[0],
      ),
    );

    const selectedLineItemIds = lineItem
      ? [lineItem.id]
      : this.__checkedLineItemIds;

    let patient = { id: rollUp.patientId };

    if (this.__patients.length) {
      patient = { ...patient, ...this.__getPatientInfo(rollUp) };
    }

    return {
      lineItemIds:
        rollUp.invoiceId && selectedLineItemIds !== this.__checkedLineItemIds
          ? rollUp.lineItemIds
          : selectedLineItemIds,
      patient,
    };
  }

  async __openPayerOverlay(payerId) {
    await openOverlay(OVERLAY_KEYS.PAYER_PLAN, { id: payerId });

    await this.onChange();

    this.fetch();
  }

  __getPatientInfo(item) {
    const patient = this.__patients.find(p => p.id === item.patientId);
    return {
      name: getPatientDisplayName(patient, {
        preferred: false,
        reverse: true,
        suffix: true,
        middleInitial: true,
      }),
      mrn: patient.medicalRecordNumber,
    };
  }

  async __getPatientPayers() {
    const patientPayers = await getPatientPayers(this.patient.id);
    const uniquePayers = new Map();

    patientPayers.forEach(payer => {
      uniquePayers.set(payer.alias, { label: payer.alias, data: payer });
    });

    this.__patientPayers = Array.from(uniquePayers.values());
  }

  __formatRollUps(rollUp) {
    const patient = this.patient
      ? this.patient.name
      : this.__getPatientInfo(rollUp).name;

    return {
      ...rollUp,
      id: uuid(),
      checked: false,
      lineItems: [],
      billedAmountTotal: rollUp.billedAmountTotal + rollUp.taxAmountTotal,
      patientResponsibility: formatResponsibility(
        rollUp.patientPaidTotal,
        rollUp.patientOwedTotal,
        this.showResponsibilityBalance,
      ),
      dateOfAllServices: getFormattedDate(rollUp),
      payerResponsibility: formatResponsibility(
        rollUp.payerPaidTotal,
        rollUp.payerOwedTotal,
        this.showResponsibilityBalance,
      ),
      primaryPayer: rollUp.primaryPayerId
        ? `(${rollUp.primaryPayerAlias}) ${rollUp.primaryPayerName}`
        : patient,
      secondaryPayer: rollUp.secondaryPayerId
        ? `(${rollUp.secondaryPayerAlias}) ${rollUp.secondaryPayerName}`
        : '',
      case: rollUp.patientCaseId
        ? mapToPatientCaseName(rollUp.caseName, rollUp.caseOnsetSymptomsDate)
        : '',
      guarantor: rollUp.guarantorPerson
        ? `${getPatientDisplayName(rollUp.guarantorPerson)}`
        : rollUp.guarantorOrganization || '',
      plan: rollUp.primaryInsuranceId ? rollUp.primaryPlanName : '',
      patient,
    };
  }

  __isInitialLoadDisabled() {
    return (
      this.__hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patient
    );
  }

  __getEmptyMessage() {
    return this.__isInitialLoadDisabled()
      ? NO_ITEMS_INITIAL_LOAD
      : this.noChargesMessage;
  }

  __calculateMarginBottom() {
    const { length } = this.__fetchService.__items;

    if (!length) {
      this.__marginBottom = MENU_HEIGHT;
    } else if (length > 2) {
      this.__marginBottom = 0;
    }
  }

  __getExpandedLineItems() {
    return this.__tableState.pageItems.reduce(
      (accumulator, item, index) =>
        this.__expandedFlags[index]
          ? [...accumulator, ...(item.lineItems || [])]
          : accumulator,
      [],
    );
  }

  __alignTableColumnWidths() {
    const lineItems = this.__getExpandedLineItems();

    const {
      minWidth,
      innerConfig,
      outerConfig,
    } = this.__syncInterTableColumnsService.alignInnerOuterColumnWidths(
      lineItems,
      [...this.__tableState.pageItems, this.__totals],
    );

    this.__minWidth = minWidth;
    this.__innerTableConfig = innerConfig;
    this.__outerTableConfig = outerConfig;
  }

  __setTableState({ pageIndex }) {
    const totalCount = this.__rollUps.length;
    const pageCount = Math.ceil(totalCount / PAGE_SIZE);

    const start = pageIndex * PAGE_SIZE;
    const end = start + Math.min(totalCount - start, PAGE_SIZE);

    const items = this.__rollUps.slice(start, end);

    this.__tableState = {
      ...this.__tableState,
      pageIndex,
      pageCount,
      pageSize: PAGE_SIZE,
      pageItems: items.map(item => ({
        ...item,
        checked: false,
        lineItems: item.lineItems.map(li => ({ ...li, checked: false })),
      })),
    };

    this.__checkedLineItemIds = [];
    this.__expandedTableService.setItems(this.__tableState.pageItems);

    if (
      Object.keys(this.__tableState.body).length &&
      Object.keys(this.__tableState.body.totals).length
    ) {
      this.__totals = {
        ...this.__tableState.body.totals,
        invoiceNumber: '',
        patientResponsibility: formatResponsibility(
          this.__tableState.body.totals.patientPaidTotal,
          this.__tableState.body.totals.patientOwedTotal,
          this.showResponsibilityBalance,
        ),
        payerResponsibility: formatResponsibility(
          this.__tableState.body.totals.payerPaidTotal,
          this.__tableState.body.totals.payerOwedTotal,
          this.showResponsibilityBalance,
        ),
      };
    }
  }

  __buildFetchService() {
    const client = async query => {
      if (this.__isInitialLoadDisabled()) {
        return EMPTY_RESPONSE;
      }

      const rollups = await fetchRollups(
        this.mode,
        this.patient && this.patient.id,
        query,
      );

      if (!this.patient) {
        this.__patients = await patientApiClient.fetchSome(
          rollups.data.map(({ patientId }) => patientId),
          undefined,
          true,
          true,
          true,
        );
      }

      return rollups;
    };

    if (this.__fetchService) {
      this.__fetchService.cancel();
    }

    this.__fetchService = new FetchService(
      {
        onChange: state => {
          this.__tableState = state;
          const { pageItems } = state;

          const formatted = pageItems.map(ru => this.__formatRollUps(ru));
          this.__rollUps = sortRollups(formatted);

          this.__setTableState({ pageIndex: 0 });
        },
        onError: e => {
          console.error(e);
          store.dispatch(openError(OPEN_CHARGES_FETCH_ERROR));
        },
      },
      client,
      {
        initialQuery: {
          dosFrom: parseDate()
            .subtract(1, 'month')
            .format(ISO_DATE_FORMAT),
          dosTo: parseDate().format(ISO_DATE_FORMAT),
        },
      },
    );
  }

  fetch() {
    this.__fetchService.fetch();
  }

  setYOffset(offset = 0) {
    this.__elements.hoverHorizontalScrollBar.setScrollOffsetTop(offset);
  }

  updated(changedProps) {
    if (changedProps.has('__tableState')) {
      this.__calculateMarginBottom();

      if (this.patient) {
        this.__getPatientPayers();
      }
    }

    if (
      changedProps.has('__defaultLocationId') &&
      !this.__hasDisableInitialLoadFF &&
      this.__defaultLocationId &&
      this.__locations.length
    ) {
      this.__fetchService.setQuery('locationIds', [
        'null',
        this.__defaultLocationId,
      ]);

      this.fetch();
    }

    super.updated(changedProps);
  }

  firstUpdated() {
    this.__elements = {
      hoverHorizontalScrollBar: this.shadowRoot.getElementById(
        ELEMENTS.hoverHorizontalScrollBar.id,
      ),
      scrollContainer: this.shadowRoot.getElementById(
        ELEMENTS.scrollContainer.id,
      ),
      content: this.shadowRoot.getElementById(ELEMENTS.content.id),
    };

    this.__rectTop = this.getBoundingClientRect().top;
  }

  update(changed) {
    if (changed.has('__tableState') && this.__tableState.pageItems.length) {
      this.__selectedCharges = [];
      this.__alignTableColumnWidths();
    }

    super.update(changed);
  }

  async __handleConfirmationPopup(invoiceNumbers) {
    const result = await openPopup(POPUP_RENDER_KEYS.DIALOG, {
      title: 'Warning',
      message: WARNING_INVOICES_ASSOCIATED_CHARGE_LEVEL(invoiceNumbers),
      buttons: [
        { name: 'save', label: 'YES' },
        { name: 'discard', label: 'NO' },
      ],
    });

    return result === 'save';
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          background-color: ${CSS_COLOR_WHITE};
        }

        .description {
          padding: 0 0 ${CSS_SPACING} ${CSS_SPACING};
          margin: 0;
        }

        .filters {
          margin-bottom: ${CSS_SPACING};
        }

        .container {
          display: grid;
          width: 100%;
          height: auto;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: 1fr;
          overflow: auto;
          background-color: ${CSS_COLOR_WHITE};
          scrollbar-width: none;
        }

        .container::-webkit-scrollbar {
          width: 0;
        }

        .content {
          display: grid;
          min-width: 0;
          grid-template-columns: 1fr;
        }

        .footer {
          display: flex;
          padding: 18px ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
        }

        .pagination {
          display: flex;
          padding: ${CSS_SPACING};
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .footer-cell {
          display: flex;
          width: 0;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          white-space: nowrap;
          word-break: break-all;
        }

        .footer-cell:last-child {
          margin-right: 0;
        }

        .footer-cell-spacer {
          max-width: 28px;
          margin-right: ${CSS_SPACING};
        }

        .footer-trailing-spacer {
          width: 12px;
        }
      `,
    ];
  }

  renderHeader() {
    return html`
      <p class="description" id="${ELEMENTS.description.id}">
        ${this.description}
      </p>

      <neb-filters-ledger-charges
        id="${ELEMENTS.filters.id}"
        class="filters"
        .onApply="${this.__handlers.filter}"
        .mode="${this.mode}"
        ?enablePatient="${!this.patient}"
        .locations="${this.__locations}"
        .cases="${this.cases}"
        .defaultLocationId="${this.__defaultLocationId}"
        .patientPayers="${this.__patientPayers}"
      ></neb-filters-ledger-charges>
    `;
  }

  renderFooter() {
    if (this.__tableState.pageItems.length) {
      const cells = this.__outerTableConfig.slice(1).map(cell => {
        const value = cell.formatter
          ? cell.formatter(this.__totals[cell.key])
          : this.__totals[cell.key] || '';

        return html`
          <span
            class="footer-cell"
            style="flex: ${cell.flex}"
            ?truncate="${cell.truncate}"
            >${value}</span
          >
        `;
      });

      return html`
        <div id="${ELEMENTS.footer.id}" class="footer">
          <div class="footer-cell-spacer text-bold">Totals</div>
          ${cells}
          <div class="footer-trailing-spacer"></div>
        </div>
      `;
    }

    return '';
  }

  renderPagination() {
    return this.__tableState.pageCount > 1
      ? html`
          <div class="pagination">
            <neb-pagination
              id="${ELEMENTS.pagination.id}"
              .pageCount="${this.__tableState.pageCount}"
              .currentPage="${this.__tableState.pageIndex}"
              .onPageChanged="${this.__handlers.selectPage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  renderTable() {
    const emptyMessage = this.__getEmptyMessage();

    return html`
      <div
        id="${ELEMENTS.scrollContainer.id}"
        class="container"
        @scroll="${this.__handlers.scroll}"
      >
        <div
          id="${ELEMENTS.content.id}"
          class="content"
          style="margin-bottom: ${unsafeCSS(this.__marginBottom)}px;"
        >
          <neb-table-ledger-charges
            id="${ELEMENTS.table.id}"
            class="table"
            style="min-width: ${unsafeCSS(this.__minWidth)}px; width: 100%"
            name="charges"
            .layout="${this.layout}"
            .config="${this.__outerTableConfig}"
            .body="${this.__totals}"
            .model="${this.__tableState.pageItems}"
            .expandFlags="${this.__expandedFlags}"
            .onChange="${this.__handlers.changeTable}"
            .onToggleExpand="${this.__handlers.toggleExpand}"
            .innerTableConfig="${this.__innerTableConfig}"
            .onSelectExpandedRow="${this.__handlers.viewCharges}"
            .onBulkActionClick="${this.__handlers.dynamicLeadingHeaderActions}"
            .onSelectCase="${this.__handlers.editCase}"
            .onSelectPatient="${this.__handlers.navigateToPatientLedger}"
            .onSelectPayer="${this.__handlers.editPayer}"
            .onSelectInvoice="${this.__handlers.viewInvoice}"
            .onSelectInvoiceBillingNotesIcon="${
              this.__handlers.clickBillingNoteIcon
            }"
            .onSelectPlan="${this.__handlers.editPlan}"
            .onSelectPackage="${this.__handlers.editPackage}"
            .onSelectEncounter="${this.__handlers.viewEncounterSummary}"
            .onSelectClaim="${this.__handlers.viewClaim}"
            .onClickChargeNumber="${this.__handlers.clickChargeNumber}"
            .emptyMessage="${emptyMessage}"
            .setYOffset="${this.__handlers.setYOffset}"
            .changedPage="${this.__changedPage}"
            .resetChangedPage="${this.__handlers.resetChangedPage}"
            .onOpenPatientPackagesSummary="${
              this.__handlers.openPatientPackagesSummary
            }"
            ?showERAsAndEOBsAssociations="${this.__showERAsAndEOBsAssociations}"
            .onClickERAsEOBs="${this.__handlers.viewERAsEOBs}"
          ></neb-table-ledger-charges>

          ${this.renderFooter()} ${this.renderPagination()}
        </div>
      </div>

      <neb-horizontal-scroll
        id="${ELEMENTS.hoverHorizontalScrollBar.id}"
        .onChange="${this.__handlers.hoverBarScroll}"
        .minWidth="${this.__minWidth}"
        .rectTop="${this.__rectTop}"
      ></neb-horizontal-scroll>
    `;
  }

  render() {
    return html`
      ${this.renderHeader()} ${this.renderTable()}
    `;
  }
}

customElements.define('neb-ledger-charges', NebLedgerCharges);
