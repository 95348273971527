import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import { store } from '../../packages/neb-redux/neb-redux-store';
import { parseDate } from '../../packages/neb-utils/date-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../packages/neb-utils/formatters';

import * as practiceUserApiClient from './practice-users/providers';

export const apiClient = new ApiClient({ microservice: 'billing' });

const formatProvider = async providerId => {
  const { providers } = store.getState();

  let provider;

  if (providers && providers.item.length) {
    provider = providers.item.find(p => p.id === providerId);
  } else {
    provider = await practiceUserApiClient.fetchOne(providerId, {
      optOutLoadingIndicator: true,
    });
  }

  return objToName(provider.name, DEFAULT_NAME_OPTS);
};

const formatEncounter = async encounter => {
  const {
    appointmentType,
    providerId,
    serviceDate,
    signerId,
    signedOn,
  } = encounter;

  const momentServiceDate = parseDate(serviceDate);

  return {
    ...encounter,
    appointmentType: appointmentType ? appointmentType.name : '',
    fullDate: momentServiceDate.format('MM/DD/YYYY h:mm A'),
    formattedServiceDate: momentServiceDate.format('MM/DD/YYYY'),
    provider: providerId ? await formatProvider(providerId) : '',
    signerName: signerId ? await formatProvider(signerId) : '',
    signedDateTime: signedOn
      ? parseDate(signedOn).format('MM/DD/YYYY h:mm A')
      : '',
  };
};

export const getEncounterById = async encounterId => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: true,
    method: Method.GET,
    path: `encounters/${encounterId}`,
    cacheKey: encounterId,
    version: 1,
  });

  return formatEncounter(res.data[0]);
};

export const checkIfSignedEncounterModsChange = async queryParams => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: true,
    method: Method.GET,
    path: 'encounters/check-mods-change',
    queryParams,
    version: 1,
  });

  return {
    signedEncounterModsChange: res.data[0],
    encounterIds: res.encounterIds,
  };
};
