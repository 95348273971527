import '../field-groups/neb-duration';
import '../inputs/neb-textfield';
import '../inputs/neb-textarea';
import '../inputs/neb-select';
import '../neb-calendar-view';
import '../neb-radio-button';
import '../neb-appointment-card';
import '../neb-loading-overlay';

import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { LitElement, html, css } from 'lit';
import moment from 'moment-timezone';

import {
  createBlockedOffTime,
  updateBlockedOffTime,
} from '../../../../neb-api-client/src/blocked-off-time-api-client';
import { getActiveProviderUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import { fetchManyRooms } from '../../../../neb-api-client/src/rooms-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_BLACK,
  CSS_FONT_SIZE_HEADER,
  CSS_COLOR_GREY_1,
} from '../../../../neb-styles/neb-variables';
import { CALENDAR_TYPES } from '../../../../neb-utils/calendar-resources-util';
import { parseDate } from '../../../../neb-utils/date-util';
import { DEFAULT_NAME_OPTS, objToName } from '../../../../neb-utils/formatters';
import { PERIOD } from '../../../../neb-utils/utils';
import NebFormRecurringAppointment from '../forms/neb-form-recurring-appointment';

export const ELEMENTS = {
  cardOneTimeBlockedOffTime: {
    id: 'one-time-appointment-card',
  },
  cardRecurringSeries: {
    id: 'recurring-series-card',
  },
  calendar: {
    id: 'calendar',
  },
  containerButtonAction: {
    id: 'container-button-action',
  },
  formRecurringAppointment: {
    id: 'form-recurring-appointment',
  },
  iconClose: {
    id: 'icon-close',
  },
  timeDuration: {
    id: 'input-time-duration',
  },
  timeStart: {
    id: 'input-time-start',
  },
  selectInputProvider: {
    id: 'dropdown-provider',
  },
  selectInputLocation: {
    id: 'dropdown-location',
  },
  roomsSelect: {
    id: 'rooms-select',
  },
  providerSelected: {
    id: 'provider-name',
  },
  spinnerLoading: {
    id: 'spinner-loading',
  },
  textHeader: {
    id: 'text-header',
  },
  textInputName: {
    id: 'text-input-name',
  },
  textInputNote: {
    id: 'text-input-note',
  },
  textSelectedDate: {
    id: 'text-selected-date',
  },
  providerTypeRadio: {
    id: 'provider-type-radio',
  },
  roomTypeRadio: {
    id: 'room-type-radio',
  },
  checkboxAllDay: {
    id: 'checkbox-all-day',
  },
};
const TEXT_HEADER = 'Blocked Off Time';
const TEXT_NAME = 'Title';
const PROVIDER_DROPDOWN_ERROR = 'At least one provider required';
const ROOM_DROPDOWN_ERROR = 'At least one room required';

const INITIAL_APPOINTMENT = {
  name: '',
  start: moment(),
  end: moment().add(30, 'minutes'),
  note: '',
  providers: [],
};
export const UPDATED_SUCCESS_MESSAGE = 'Blocked off time updated successfully';
const CREATED_SUCCESS_MESSAGE = 'Blocked off time created Successfully';

export const TIME_OPTS = {
  START: 'start',
  DURATION: 'duration',
};

const getTime = date => ({
  hours: parseDate(date).hours() % 12 || 12,
  minutes:
    (parseDate(date).minutes() < 10 ? '0' : '') + parseDate(date).minutes(),
  period: parseDate(date).format('a'),
});

const getDuration = (start, end) => ({
  hours: parseDate(end).diff(start, 'hours'),
  minutes: parseDate(end).diff(start, 'minutes') % 60,
  period: null,
});

const getDateFromDuration = (start, duration) =>
  parseDate(start)
    .add(duration.hours, 'hours')
    .add(duration.minutes, 'minutes')
    .toDate();

export const ALL_DAY_DURATION = { hours: '23', minutes: '55', period: null };
class NebBlockedOffTimeForm extends LitElement {
  static get properties() {
    return {
      appointment: Object,
      providerId: String,
      providerIds: Array,
      layout: String,

      __oneTimeAppointment: Boolean,
      __saving: Boolean,
      __isDirty: Boolean,
      __name: String,
      __note: String,
      __selectedType: String,
      __dateStart: Object,
      __timeDuration: Object,
      __recurringModel: Object,
      __errors: Object,
      __providerItems: Array,
      __masterRooms: Array,
      __rooms: Array,
      __selectedRooms: Array,
      __selectedProviders: Array,
      __saveIsEditAppointments: Array,
      __selectedLocation: Array,
      __activeLocations: Array,
      __locationPristine: Boolean,
      __allDay: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();

    this.__initServices();
  }

  __initState() {
    const dateRoundCoeff = 1000 * 60 * 5;

    this.__dateStart = parseDate(
      Math.ceil(new Date().getTime() / dateRoundCoeff) * dateRoundCoeff,
    )
      .startOf('minute')
      .toDate();

    this.__timeStart = getTime(this.__dateStart);
    this.__timeDuration = { hours: 0, minutes: 30, period: null };
    this.__selectedLocation = { item: null, label: null };

    this.__initialValues = {
      dateStart: this.__dateStart,
      timeStart: this.__timeStart,
      timeDuration: this.__timeDuration,
      selectedLocation: this.__selectedLocation,
      type: CALENDAR_TYPES.PROVIDER,
    };

    this.__activeLocations = [];
    this.__locationPristine = true;
    this.__name = '';
    this.__note = '';
    this.__selectedType = CALENDAR_TYPES.PROVIDER;
    this.__selectedProviders = [];
    this.__providerItems = [];
    this.__masterRooms = [];
    this.__rooms = [];
    this.__selectedRooms = [];
    this.__isDirty = false;
    this.__saving = false;
    this.__saveIsEditAppointments = [];
    this.__allDay = false;
    this.__errors = {
      name: '',
      time: '',
      providers: '',
      rooms: '',
    };

    this.__oneTimeAppointment = true;
    this.__initialRecurringModel = NebFormRecurringAppointment.createModel();
    this.__recurringModel = this.__initialRecurringModel;

    this.appointment = null;
    this.providerId = '';
    this.providerIds = [];

    this.onDirty = () => {};

    this.onDismiss = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      cancel: () => {
        if (this.__isFormDirty()) {
          this.__openPopupDirty();
        } else {
          this.onDirty(false);
          this.onDismiss();
        }
      },
      changeSelectedType: value => {
        if (value !== this.__selectedType) {
          this.__selectedType = value;

          if (this.__selectedType === CALENDAR_TYPES.PROVIDER) {
            this.__selectedProviders = this.__providerItems;
            this.__selectedRooms = [];
          } else {
            if (this.__selectedLocation && this.__selectedLocation.item) {
              this.__filterRooms();
            }

            this.__selectedRooms = [];
            this.__selectedProviders = [];
          }

          this.__validateMultiselects();
          this.__isDirty = this.__isFormDirty();
        }
      },
      isDateSelectable: date => this.__isDateSelectable(date),
      save: () => {
        let validReccurence = true;

        const formRecurringAppointment = this.shadowRoot.getElementById(
          ELEMENTS.formRecurringAppointment.id,
        );

        if (formRecurringAppointment) {
          validReccurence = formRecurringAppointment.formService.validate();
        }

        if (validReccurence) {
          this.__saveAppointment();
        }
      },
      updateDate: date => this.__updateDate(date),
      updateTimeDuration: time => this.__updateTime(time, TIME_OPTS.DURATION),
      updateTimeStart: time => this.__updateTime(time, TIME_OPTS.START),
      recurringModelChange: model => {
        this.__recurringModel = model;
        this.__isDirty = this.__isFormDirty();
      },
      cardClick: () => {
        this.__oneTimeAppointment = !this.__oneTimeAppointment;

        this.__allDay = false;

        if (this.__oneTimeAppointment) {
          this.__dateStart = this.__initialValues.dateStart;
          this.__timeStart = this.__initialValues.timeStart;
          this.__timeDuration = this.__initialValues.timeDuration;
        }

        this.__isDirty = this.__isFormDirty();
      },
      updateForm: e => {
        const id = e.id || e.name;

        switch (id) {
          case ELEMENTS.textInputName.id:
            this.__name = e.value;

            this.__validateName();

            break;

          case ELEMENTS.textInputNote.id:
            this.__note = e.value;
            break;

          case ELEMENTS.selectInputProvider.id:
            this.__selectedProviders = e.value;
            break;

          case ELEMENTS.roomsSelect.id:
            this.__selectedRooms = e.value;
            this.__validateMultiselects();
            break;

          case ELEMENTS.selectInputLocation.id:
            if (e.value.item && this.__selectedLocation !== e.value) {
              this.__selectedLocation = e.value;
              this.__selectedRooms = [];
              this.__locationPristine = false;
            }
            break;

          default:
        }

        this.__isDirty = this.__isFormDirty();
      },
      updateAllDay: e => {
        this.__allDay = e.value;

        if (!this.__oneTimeAppointment) return;

        if (this.__allDay) {
          this.__timeDuration = ALL_DAY_DURATION;
          this.__timeStart = { hours: '12', minutes: '00', period: PERIOD.AM };
        }

        if (!this.__allDay) {
          this.__timeDuration = this.__initialValues.timeDuration;
          this.__timeStart = this.__initialValues.timeStart;
        }

        this.__setDateTimeStart();
        this.__isDirty = this.__isFormDirty();
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ activeLocations }) => {
      this.__activeLocations = this.__formatLocations(activeLocations);

      if (this.__activeLocations.length === 1) {
        this.__selectedLocation = this.__activeLocations[0];
      }
    });
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__locationsService.connect();
    const providers = await getActiveProviderUsers();
    const rooms = await fetchManyRooms();

    this.__providerItems = providers.map(provider => ({
      label: objToName(provider.name, DEFAULT_NAME_OPTS),
      provider,
    }));

    this.__masterRooms = rooms.map(room => ({
      label: room.name,
      ...room,
    }));
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__locationsService.disconnect();
  }

  __isDateSelectable(date) {
    return (
      date >=
      parseDate()
        .startOf('day')
        .toDate()
    );
  }

  __updateDate(date) {
    this.__dateStart = this.__createDate(date, this.__dateStart);

    this.__updateSelectedDate();

    this.__isDirty = this.__isFormDirty();
  }

  __createDate(newDate, oldDate) {
    return parseDate(newDate)
      .hours(oldDate.getHours())
      .minutes(oldDate.getMinutes())
      .startOf('minute')
      .toDate();
  }

  __setDateTimeStart() {
    let newHours;

    const { hours, minutes, period } = this.__timeStart;
    const startHours = Number(hours);

    if (period === PERIOD.AM) {
      if (startHours === 12) {
        newHours = 0;
      } else {
        newHours = startHours;
      }
    } else if (startHours === 12) {
      newHours = 12;
    } else {
      newHours = startHours + 12;
    }

    this.__dateStart = parseDate(this.__dateStart)
      .hours(newHours)
      .minutes(minutes)
      .toDate();
  }

  __updateTime({ name, value }, opt) {
    const TIME = opt === TIME_OPTS.START ? '__timeStart' : '__timeDuration';

    this[TIME] = {
      ...this[TIME],
      [name.split('.')[1]]: value,
    };

    if (TIME === '__timeStart') {
      this.__setDateTimeStart();
    }

    this.__validateTime();

    this.__isDirty = this.__isFormDirty();
  }

  __updateSelectedDate() {
    this.__setDateTimeStart();
    this.__dateStart = parseDate(this.__dateStart).toDate();
  }

  __validateName() {
    this.__errors = {
      ...this.__errors,
      name: this.__name ? '' : 'Required',
    };
  }

  __validateTime() {
    const { hours, minutes } = this.__timeDuration;

    this.__errors = {
      ...this.__errors,
      time: (Number(minutes) === 0
      ? Number(hours) > 0
      : Number(hours) >= 0)
        ? ''
        : 'Required',
    };
  }

  __formatDate(date) {
    return parseDate(date).format('YYYY-MM-DDTHH:mm:ss');
  }

  __getAppointmentNote() {
    return this.appointment.note || '';
  }

  __doProvidersMatch() {
    const appointmentProviders = this.providerId
      ? this.__saveIsEditAppointments
      : this.__providerItems;

    this.__selectedProviders.sort();

    let providersMatch = true;

    if (this.__selectedProviders.length === appointmentProviders.length) {
      for (let i = 0; i < this.__selectedProviders.length; i++) {
        if (this.__selectedProviders[i] !== appointmentProviders[i]) {
          providersMatch = false;
          break;
        }
      }
    } else {
      providersMatch = false;
    }

    return providersMatch;
  }

  __doRoomsMatch() {
    const appointmentRooms = this.__saveIsEditAppointments;

    this.__selectedRooms.sort();

    let roomsMatch = true;

    if (this.__selectedRooms.length === appointmentRooms.length) {
      for (let i = 0; i < this.__selectedRooms.length; i++) {
        if (this.__selectedRooms[i] !== appointmentRooms[i]) {
          roomsMatch = false;
          break;
        }
      }
    } else {
      roomsMatch = false;
    }

    return roomsMatch;
  }

  __isFormDirty() {
    let providerIdsMatch = true;
    let roomIdsMatch = true;

    const isEditResource = this.appointment && this.appointment.resourceId;

    if (isEditResource) {
      roomIdsMatch = this.__doRoomsMatch();
    } else {
      providerIdsMatch = this.__doProvidersMatch();
    }

    const startDatesMatch =
      parseDate(this.appointment.start)
        .startOf('minute')
        .toISOString() ===
      parseDate(this.__dateStart)
        .startOf('minute')
        .toISOString();

    const isDirty =
      this.appointment.name !== this.__name ||
      !startDatesMatch ||
      !equal(this.__initialValues.timeStart, this.__timeStart) ||
      !equal(this.__initialValues.type, this.__selectedType) ||
      !equal(this.__initialValues.timeDuration, this.__timeDuration) ||
      !equal(this.__initialValues.selectedLocation, this.__selectedLocation) ||
      this.__getAppointmentNote() !== this.__note ||
      !providerIdsMatch ||
      !roomIdsMatch ||
      !equal(this.__initialRecurringModel, this.__recurringModel);

    this.onDirty(isDirty);

    return isDirty;
  }

  __getFormValue() {
    const providerIds = this.__selectedProviders.map(item => item.provider.id);
    const resourceIds = this.__selectedRooms.map(item => item.id);
    const resourceNames = this.__selectedRooms.map(item => item.name);

    let startDate;
    let durationTime;

    if (this.__oneTimeAppointment) {
      startDate = this.__dateStart;
      durationTime = this.__timeDuration;
    } else {
      ({ startDate, durationTime } = this.__recurringModel);
    }

    return {
      name: this.__name,
      start: parseDate(startDate).toDate(),
      end: getDateFromDuration(startDate, durationTime),
      note: this.__note,
      rrule: !this.__oneTimeAppointment
        ? this.__recurringModel.rrule.toString()
        : '',
      providerIds,
      resourceIds,
      resourceNames,
      locationId: this.__selectedLocation.item
        ? this.__selectedLocation.item.id
        : null,
      allSelected:
        this.__selectedProviders.length === this.__providerItems.length,
    };
  }

  __validateMultiselects() {
    const validProviders =
      this.__selectedType === CALENDAR_TYPES.PROVIDER
        ? this.__selectedProviders && this.__selectedProviders.length
        : true;

    const validRooms =
      this.__selectedType === CALENDAR_TYPES.ROOM
        ? this.__selectedRooms && this.__selectedRooms.length
        : true;

    this.__errors = {
      ...this.__errors,
      providers: validProviders ? '' : PROVIDER_DROPDOWN_ERROR,
      rooms: validRooms ? '' : ROOM_DROPDOWN_ERROR,
    };
  }

  __validate() {
    this.__validateTime();
    this.__validateName();
    this.__validateMultiselects();

    this.__locationPristine = false;

    const validLocation =
      !!this.__selectedLocation.item && this.__selectedLocation.item.id;

    const validRecurring = !this.__oneTimeAppointment
      ? this.__recurringModel.rrule
      : true;

    return (
      validRecurring &&
      validLocation &&
      Object.values(this.__errors).every(v => !v)
    );
  }

  __saveAppointment() {
    if (this.__validate()) {
      let model = this.__getFormValue();

      model = {
        ...model,
        start: this.__formatDate(model.start),
        end: this.__formatDate(model.end),
      };

      if (!this.providerId && !this.appointment.resourceId) {
        this.__createNewBlockedOffTime(model);
      } else {
        this.__updateBlockedOffTime(model);
      }
    }
  }

  __onSuccess(model = {}) {
    if (this.providerId || this.appointment.resourceId) {
      store.dispatch(openSuccess(UPDATED_SUCCESS_MESSAGE));
    } else {
      store.dispatch(openSuccess(CREATED_SUCCESS_MESSAGE));
    }

    this.onDirty(false);
    this.onDismiss(model);
  }

  __onError() {
    store.dispatch(openError('Blocked off time was not successfully created'));
  }

  async __createNewBlockedOffTime(model) {
    this.__saving = true;

    try {
      await createBlockedOffTime(model, true);
    } catch (e) {
      console.error(e);
      this.__onError();
      return;
    }

    this.__onSuccess();
    this.__saving = false;
  }

  async __updateBlockedOffTime(model) {
    this.__saving = true;
    const changeTypeToProvider =
      model.providerIds.length &&
      this.__initialValues.type === CALENDAR_TYPES.ROOM;
    const changeTypeToRoom =
      model.resourceIds.length &&
      this.__initialValues.type === CALENDAR_TYPES.PROVIDER;

    let updateModel = { ...this.appointment, ...model };

    if (changeTypeToProvider) {
      updateModel = { ...updateModel, resourceId: null };
    }

    if (changeTypeToRoom) {
      updateModel = { ...updateModel, providerId: null };
    }

    try {
      await updateBlockedOffTime(
        this.appointment.groupId,
        updateModel,
        this.appointment.recurrenceEventId
          ? { action: 'updateSingleInstanceForAllProvider' }
          : {},
      );
    } catch (e) {
      console.error(e);
      this.__onError();
      return;
    }

    this.__onSuccess(model);

    this.__saving = false;
  }

  async __openPopupDirty() {
    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Warning',
      message:
        'You have unsaved changes that will be lost if you navigate away. Are you sure you wish to discard these changes?',
      confirmText: 'Discard Changes',
      cancelText: 'Stay on Page',
    });

    if (result) {
      this.onDirty(false);
      this.onDismiss();
    }
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      item: location,
      label: location.name,
    }));
  }

  __filterRooms() {
    this.__rooms = this.__masterRooms.filter(
      r =>
        r.scheduleAvailable &&
        r.active &&
        r.locationId === this.__selectedLocation.item.id,
    );
  }

  update(changedProps) {
    if (changedProps.has('__providerItems')) {
      if (this.providerIds && this.providerIds.length) {
        this.__selectedProviders = this.__providerItems.filter(item =>
          this.providerIds.includes(item.provider.id),
        );

        this.__selectedRooms = [];

        this.__saveIsEditAppointments = this.__selectedProviders;
      } else {
        this.__selectedProviders = this.__providerItems;
      }
    }

    if (changedProps.has('__masterRooms')) {
      if (
        this.appointment &&
        this.appointment.resourceIds &&
        this.appointment.resourceIds.length
      ) {
        this.__filterRooms();

        this.__selectedRooms = this.__rooms.filter(room =>
          this.appointment.resourceIds.includes(room.id),
        );

        this.__selectedProviders = [];

        this.__saveIsEditAppointments = this.__selectedRooms;
      }
    }

    if (
      changedProps.has('__activeLocations') &&
      this.__activeLocations.length
    ) {
      if (this.appointment && this.appointment.locationId) {
        this.__selectedLocation = this.__activeLocations.find(
          location => location.item.id === this.appointment.locationId,
        );

        this.__initialValues = {
          ...this.__initialValues,
          selectedLocation: this.__selectedLocation,
        };
      }
    }

    if (
      changedProps.has('__selectedLocation') &&
      this.__selectedLocation &&
      this.__selectedLocation.item &&
      this.__masterRooms.length
    ) {
      this.__filterRooms();
    }

    if (changedProps.has('appointment')) {
      if (this.appointment) {
        const newStartDate = parseDate(this.appointment.start).toDate();

        const newEndDate = parseDate(this.appointment.end).toDate();

        this.__initialValues = {
          ...this.__initialValues,
          dateStart: newStartDate,
          timeStart: getTime(newStartDate),
          timeDuration: getDuration(newStartDate, newEndDate),
        };

        this.__dateStart = this.__initialValues.dateStart;
        this.__timeStart = this.__initialValues.timeStart;

        this.__timeDuration = this.__initialValues.timeDuration;
        this.__name = this.appointment.name;
        this.__note = this.__getAppointmentNote();

        this.__selectedType = this.providerId
          ? CALENDAR_TYPES.PROVIDER
          : CALENDAR_TYPES.ROOM;

        this.__initialValues = {
          ...this.__initialValues,
          type: this.__selectedType,
        };

        if (
          Number(this.__timeDuration.hours) === Number(ALL_DAY_DURATION.hours)
        ) {
          this.__allDay = true;
        }

        this.__updateSelectedDate();
      } else {
        this.appointment = INITIAL_APPOINTMENT;
        this.appointment.start = parseDate(this.__dateStart).toDate();
        this.appointment.end = getDateFromDuration(
          this.__dateStart,
          this.__timeDuration,
        );
      }
    }

    if (
      changedProps.has('__dateStart') ||
      (changedProps.has('__timeStart') && this.__oneTimeAppointment)
    ) {
      this.__initialRecurringModel = NebFormRecurringAppointment.createModel(
        this.__dateStart,
        this.__timeStart,
      );

      this.__recurringModel = this.__initialRecurringModel;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          width: 100%;
          height: 100%;
        }

        .container {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          width: 100%;
          height: 100%;
        }

        .content {
          height: 100%;

          padding: ${CSS_SPACING};

          overflow-y: scroll;
          overflow-x: hidden;
        }

        .content::-webkit-scrollbar {
          width: 0;
        }

        .input-name {
          width: 100%;
          margin-bottom: 16px;
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          margin-bottom: ${CSS_SPACING};
        }

        .text-header {
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: bold;

          color: ${CSS_COLOR_BLACK};
        }

        .icon-close {
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_COLOR_GREY_1};
        }

        .type-container {
          display: grid;
          grid-template-columns: min-content min-content min-content 1fr;
        }

        .type-padding {
          align-self: center;
          padding-right: ${CSS_SPACING};
        }

        .appointment-card {
          height: 100px;
          margin-top: ${CSS_SPACING};
        }

        .calendar {
          width: 100%;
          margin: ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .display-selected-date {
          margin-bottom: 30px;
        }

        .provider-dropdown {
          width: 100%;

          margin: 12px 0 16px;
        }

        .location-dropdown {
          width: 100%;
          margin-bottom: 16px;
        }

        .container-input-time {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;

          margin-bottom: ${CSS_SPACING};
        }

        .container-input-time-duration {
          display: flex;
          flex-direction: column;
          gap: ${CSS_SPACING};
        }

        .input-time-duration,
        .input-time-start {
          width: 100%;
        }

        .input-time-duration {
          margin-bottom: 15px;
        }

        .text-label {
          font-size: 12px;
          padding-left: 5px;
        }

        .note {
          width: 100%;

          margin: ${CSS_SPACING} 0;
        }

        .container-action-buttons {
          position: sticky;
        }

        .button:not(:last-child) {
          margin-right: 5px;
        }

        .loading-overlay {
          position: absolute;

          width: 100%;
          height: 100%;
        }

        .form-recurring {
          height: auto;
        }

        .duration-title-row {
          display: flex;
          align-items: center;
          gap: 20px;
        }
      `,
    ];
  }

  renderSelectedDate() {
    return this.__dateStart != null && this.__dateStart !== ''
      ? html`
          <div
            id="${ELEMENTS.textSelectedDate.id}"
            class="display-selected-date"
          >
            ${parseDate(this.__dateStart).format('dddd, MMMM D, YYYY')}
          </div>
        `
      : '';
  }

  renderActionButtons() {
    return this.__isDirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.containerButtonAction.id}"
            class="container-action-buttons"
            confirmLabel="Save"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  __renderAppointmentCards() {
    const hasResourceId = this.appointment && this.appointment.resourceId;

    return !this.providerId && !hasResourceId
      ? html`
          <div class="appointment-card-container">
            <neb-appointment-card
              id="${ELEMENTS.cardOneTimeBlockedOffTime.id}"
              class="appointment-card"
              title="One-Time Blocked Off Time"
              icon="calendar"
              ?selected="${this.__oneTimeAppointment}"
              .onClick="${this.__handlers.cardClick}"
            ></neb-appointment-card>
            <neb-appointment-card
              id="${ELEMENTS.cardRecurringSeries.id}"
              class="appointment-card"
              title="Recurring Series"
              ?selected="${!this.__oneTimeAppointment}"
              icon="loop"
              .onClick="${this.__handlers.cardClick}"
            ></neb-appointment-card>
          </div>
        `
      : '';
  }

  __renderOneTimeAppointmentSection() {
    return html`
      <neb-calendar-view
        id="${ELEMENTS.calendar.id}"
        class="calendar"
        .isDateSelectable="${this.__handlers.isDateSelectable}"
        .isPickerable="${this.layout === 'small'}"
        .isToolbarVisible="${false}"
        .selectedDate="${this.__dateStart}"
        .onChange="${this.__handlers.updateDate}"
      ></neb-calendar-view>

      ${this.renderSelectedDate()}

      <div class="container-input-time">
        <div class="container-input-time-duration">
          <div class="duration-title-row">
            <span>Duration</span>

            <neb-checkbox
              id="${ELEMENTS.checkboxAllDay.id}"
              label="All Day"
              .checked="${this.__allDay}"
              .onChange="${this.__handlers.updateAllDay}"
            ></neb-checkbox>
          </div>

          <neb-duration
            id="${ELEMENTS.timeDuration.id}"
            class="input input-time-duration"
            name="input-time-duration"
            helper="Required"
            .model="${this.__timeDuration}"
            .onChange="${this.__handlers.updateTimeDuration}"
            .error="${this.__errors.time}"
            ?disabled="${this.__allDay}"
            ?fullDuration="${true}"
          >
          </neb-duration>
        </div>

        <div>
          <span class="text-label">Start Time</span>
          <neb-duration
            id="${ELEMENTS.timeStart.id}"
            class="input input-time-start"
            name="input-time-start"
            .model="${this.__timeStart}"
            .onChange="${this.__handlers.updateTimeStart}"
            ?disabled="${this.__allDay}"
          >
          </neb-duration>
        </div>
      </div>
    `;
  }

  __renderRecurringSeriesSection() {
    return html`
      <neb-form-recurring-appointment
        id="${ELEMENTS.formRecurringAppointment.id}"
        class="form-recurring"
        .layout="${this.layout}"
        .selectedDate="${this.__dateStart}"
        .startTime="${this.__timeStart}"
        .onRecurringModelChange="${this.__handlers.recurringModelChange}"
        .allDay="${this.__allDay}"
        .onUpdateAllDay="${this.__handlers.updateAllDay}"
        blockedOffTime
      ></neb-form-recurring-appointment>
    `;
  }

  __renderProviderLabel() {
    const target = this.__providerItems.find(
      item => item.provider.id === this.providerId,
    );
    return target ? ` - ${target.label}` : '';
  }

  __renderEditBlockedOffTimeProvider() {
    return this.appointment.recurrenceEventId
      ? this.__renderProviderLabel()
      : '';
  }

  __renderEditBlockedOffTimeResource() {
    const isRecurrence = this.appointment && this.appointment.recurrenceEventId;
    const hasResourceName =
      this.appointment.resourceNames && this.appointment.resourceNames.length;

    return isRecurrence && hasResourceName && this.__selectedLocation.label
      ? ` - ${this.__selectedLocation.label} - ${
          this.appointment.resourceNames[0]
        }`
      : '';
  }

  __renderNewBlockedOffTimeProviders() {
    return !this.appointment.recurrenceEventId
      ? html`
          <neb-select
            id="${ELEMENTS.selectInputProvider.id}"
            class="provider-dropdown"
            name="providers"
            label="Providers"
            allLabel="Providers"
            helper="Select any or all providers"
            .value="${this.__selectedProviders}"
            .items="${this.__providerItems}"
            .error="${this.__errors.providers}"
            .disabled="${this.__selectedType !== CALENDAR_TYPES.PROVIDER}"
            .onChange="${this.__handlers.updateForm}"
            multiSelect
            required
            wrapText
          ></neb-select>
        `
      : '';
  }

  __renderNewBlockedOffTimeRooms() {
    const disabled =
      !this.__rooms.length || this.__selectedType !== CALENDAR_TYPES.ROOM;
    return !this.appointment.recurrenceEventId
      ? html`
          <neb-select
            id="${ELEMENTS.roomsSelect.id}"
            class="location-dropdown"
            name="rooms"
            label="Scheduled Rooms"
            helper="Select any or all rooms"
            .value="${this.__selectedRooms}"
            .items="${this.__rooms}"
            .error="${disabled ? '' : this.__errors.rooms}"
            .disabled="${disabled}"
            .onChange="${this.__handlers.updateForm}"
            allLabel="Rooms"
            multiSelect
            wrapText
          ></neb-select>
        `
      : '';
  }

  __renderType() {
    return !this.appointment.recurrenceEventId
      ? html`
          <div class="type-container">
            <span class="type-padding">Type</span>
            <neb-radio-button
              id="${ELEMENTS.providerTypeRadio.id}"
              class="type-padding"
              label="Provider"
              .value="${CALENDAR_TYPES.PROVIDER}"
              .checked="${this.__selectedType === CALENDAR_TYPES.PROVIDER}"
              .onSelect="${this.__handlers.changeSelectedType}"
            ></neb-radio-button>
            <neb-radio-button
              id="${ELEMENTS.roomTypeRadio.id}"
              class="type-padding"
              label="Room"
              .value="${CALENDAR_TYPES.ROOM}"
              .checked="${this.__selectedType === CALENDAR_TYPES.ROOM}"
              .onSelect="${this.__handlers.changeSelectedType}"
            ></neb-radio-button>
          </div>
        `
      : '';
  }

  __renderLocation() {
    const recurringResourceEvent =
      this.appointment.resourceId && this.appointment.recurrenceEventId;
    return !recurringResourceEvent
      ? html`
          <neb-select
            id="${ELEMENTS.selectInputLocation.id}"
            class="location-dropdown"
            label="Location"
            .value="${this.__selectedLocation}"
            .items="${this.__activeLocations}"
            .error="${
              this.__locationPristine || this.__selectedLocation.item
                ? ''
                : 'Required'
            }"
            helper="Required"
            .onChange="${this.__handlers.updateForm}"
            required
            wrapText
          ></neb-select>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <div class="content">
          <div id="header" class="header">
            <div id="${ELEMENTS.textHeader.id}" class="text text-header">
              ${TEXT_HEADER}
              ${
                this.providerId
                  ? this.__renderEditBlockedOffTimeProvider()
                  : this.__renderEditBlockedOffTimeResource()
              }
            </div>

            <neb-icon
              id="${ELEMENTS.iconClose.id}"
              class="icon icon-close"
              icon="neb:close"
              @click="${this.__handlers.cancel}"
            ></neb-icon>
          </div>

          <neb-textfield
            id="${ELEMENTS.textInputName.id}"
            class="input-name"
            name="text-input-name"
            label="${TEXT_NAME}"
            helper="Required"
            .error="${this.__errors.name}"
            .value="${this.__name}"
            .onChange="${this.__handlers.updateForm}"
            maxLength="50"
          ></neb-textfield>

          ${this.__renderType()} ${this.__renderNewBlockedOffTimeProviders()}
          ${this.__renderLocation()} ${this.__renderNewBlockedOffTimeRooms()}
          ${this.__renderAppointmentCards()}
          ${
            this.__oneTimeAppointment
              ? this.__renderOneTimeAppointmentSection()
              : this.__renderRecurringSeriesSection()
          }

          <neb-textarea
            id="${ELEMENTS.textInputNote.id}"
            class="note"
            label="Note"
            maxlength="500"
            name="text-input-note"
            showCount
            .value="${this.__note}"
            .onChange="${this.__handlers.updateForm}"
          >
          </neb-textarea>
        </div>

        ${this.renderActionButtons()}

        <neb-loading-overlay
          id="${ELEMENTS.spinnerLoading.id}"
          title="Saving Blocked Off Time"
          showDelay="0"
          .show="${this.__saving}"
        ></neb-loading-overlay>
      </div>
    `;
  }
}

customElements.define('neb-blocked-off-time-form', NebBlockedOffTimeForm);
