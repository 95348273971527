import { LitElement, html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';

export const ELEMENTS = {
  saveButton: {
    id: 'save-button',
  },
  cancelButton: {
    id: 'cancel-button',
  },
  backButton: {
    id: 'back-button',
  },
  nextButton: {
    id: 'next-button',
  },
};
const SAVE_LABEL = 'Save';
const NEXT_LABEL = 'Next';
const BACK_LABEL = 'Back';
const CANCEL_LABEL = 'Cancel';

class NebPopupActionsButtons extends LitElement {
  static get properties() {
    return {
      isFirst: Boolean,
      isLast: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.isFirst = true;
    this.isLast = true;

    this.onSaveClick = () => {};

    this.onCancelClick = () => {};

    this.onNextClick = () => {};

    this.onBackClick = () => {};
  }

  __initHandlers() {
    this.handlers = {
      save: () => {
        this.onSaveClick();
      },
      cancel: () => {
        this.onCancelClick();
      },
      next: () => {
        this.onNextClick();
      },
      back: () => {
        this.onBackClick();
      },
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        justify-content: space-between;
      }

      .step-button-container {
        display: flex;
      }

      .step-button-container > neb-button:last-child {
        margin-left: 10px;
      }

      .cancel-button {
        width: 105px;
      }

      .back-button {
        width: 77px;
      }

      .next-button,
      .save-button {
        width: 90px;
      }
    `;
  }

  renderButton(id, label, buttonRole, onClick) {
    return html`
      <neb-button
        id="${id}"
        class="${id}"
        role="${buttonRole}"
        .label="${label}"
        .onClick="${onClick}"
      ></neb-button>
    `;
  }

  render() {
    return html`
      ${
        this.renderButton(
          ELEMENTS.cancelButton.id,
          CANCEL_LABEL,
          BUTTON_ROLE.CANCEL,
          this.handlers.cancel,
        )
      }

      <div class="step-button-container">
        ${
          !this.isFirst
            ? this.renderButton(
                ELEMENTS.backButton.id,
                BACK_LABEL,
                BUTTON_ROLE.CANCEL,
                this.handlers.back,
              )
            : ''
        }
        ${
          this.isLast
            ? this.renderButton(
                ELEMENTS.saveButton.id,
                SAVE_LABEL,
                BUTTON_ROLE.CONFIRM,
                this.handlers.save,
              )
            : this.renderButton(
                ELEMENTS.nextButton.id,
                NEXT_LABEL,
                BUTTON_ROLE.CONFIRM,
                this.handlers.next,
              )
        }
      </div>
    `;
  }
}

customElements.define('neb-popup-action-buttons', NebPopupActionsButtons);
