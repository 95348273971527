import { css, html } from 'lit';

import NebForm from '../../../../../packages/neb-lit-components/src/components/forms/neb-form';

class NebFormCheckOut extends NebForm {
  static createModel() {
    return {};
  }

  static get styles() {
    return [super.styles, css``];
  }

  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
    };
  }

  createSelectors() {
    return {};
  }

  renderContent() {
    return html`
      Appointment Details
    `;
  }
}

customElements.define('neb-form-check-out', NebFormCheckOut);
