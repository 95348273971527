import './neb-appointment-details-lit';
import '../patients/neb-patient-summary';
import '../patients/cases/neb-patient-case-search';
import '../inputs/neb-textarea';
import '../neb-tooltip';
import '../neb-loading-overlay';
import '../neb-duration-field-lit';
import '../inputs/neb-select';

import { LitElement, html, css } from 'lit';

import '../../../../../src/components/forms/appointments/neb-form-split-appointment';
import {
  createBannerController,
  DISPLAY_ON,
} from '../../../../neb-alert/components/neb-alert-banner-controller';
import * as caseApi from '../../../../neb-api-client/src/patient-cases';
import { openWarning } from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { LAYOUT_TYPE } from '../../../../neb-redux/services/layout';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_SIZE_HEADER,
  CSS_COLOR_BLACK,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_WARNING_COLOR,
} from '../../../../neb-styles/neb-variables';
import {
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
  filterAuthorizationsFromCases,
  hasAuthorizationRemaining,
} from '../../../../neb-utils/patientAuthorization';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import {
  EMPTY_ROOM,
  getDuration,
} from './neb-appointment-edit-form-controller';
import { APPOINTMENT_ACTIONS } from './neb-appointment-options';
import { CSS_BASE_CONTENT_WIDTH } from './neb-appointment-styles';

const { LARGE, SMALL } = LAYOUT_TYPE;

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  details: {
    id: 'details',
  },
  caseSearch: {
    id: 'case-search',
  },
  selectAuthorizations: {
    id: 'select-authorizations',
  },
  selectLocation: {
    id: 'select-location',
  },
  selectRoom: {
    id: 'select-room',
  },
  selectAppointmentType: {
    id: 'select-appointment-type',
  },
  selectInputDuration: {
    id: 'select-input-duration',
  },
  note: {
    id: 'note',
  },
  tooltipCase: {
    id: 'tooltip-case',
  },
  tooltipAuth: {
    id: 'tooltip-auth',
  },
  actionBar: {
    id: 'action-bar',
  },
  buttonSave: {
    id: 'save',
  },
  buttonCancel: {
    id: 'cancel',
  },
  iconClose: {
    id: 'icon-close',
  },
  iconAuthorizationWarning: {
    id: 'icon-authorization-warning',
  },
  patientSummary: {
    id: 'patient-summary',
  },
  spinnerLoading: {
    id: 'spinner-loading',
  },
  addSplitButton: {
    id: 'add-split-button',
  },
  splitForm: {
    id: 'split-form',
  },
};
export const HEADER = APPOINTMENT_ACTIONS.EDIT.title;

class NebAppointmentEditFormView extends LitElement {
  static get properties() {
    return {
      locations: Array,
      rooms: Array,
      appointmentTypes: Array,
      splits: Array,
      splitErrors: Object,
      schedulingSettings: Object,
      appointment: Object,
      patientId: String,
      saving: Boolean,
      hasEditRescheduleFF: Boolean,
      layout: {
        type: String,
        reflect: true,
      },
      dirty: {
        type: Boolean,
        reflect: true,
      },

      __authorizations: Array,
      __displayAuthorizations: Array,
      __selectedAuthorization: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .container {
          position: relative;
          background-color: ${CSS_COLOR_WHITE};

          width: 100%;
          height: 100%;
        }

        .content {
          display: flex;
          height: 100%;
        }

        :host([layout='small']) .content {
          flex-direction: column;
        }

        .content-right {
          display: flex;
          flex-direction: column;
          overflow-y: auto;
        }

        .content-form {
          display: flex;
          flex-direction: column;
          padding: 0 ${CSS_SPACING};
        }

        :host(:not([layout='small'])) .content-form {
          min-width: ${CSS_BASE_CONTENT_WIDTH};
        }

        :host([layout='small']) .content-form {
          padding: ${CSS_SPACING};
        }

        .details {
          margin-bottom: ${CSS_SPACING};
        }

        .header {
          display: flex;
          align-items: center;

          padding: ${CSS_SPACING};
        }

        .text-header {
          color: ${CSS_COLOR_BLACK};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: bold;
        }

        .content-summary {
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        .patient-summary {
          padding: ${CSS_SPACING};
        }

        .case-search {
          position: relative;
          padding-bottom: ${CSS_SPACING};
          z-index: 1;
        }

        :host([layout='small']) .patient-summary {
          padding: ${CSS_SPACING} 0 0 ${CSS_SPACING};
        }

        .spacer {
          flex: 1 0 0;
        }

        .icon {
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_COLOR_GREY_1};
        }

        .content::-webkit-scrollbar {
          width: 0;
        }

        .container-action-buttons {
          position: sticky;
          width: inherit;

          padding: ${CSS_SPACING};

          border-top: solid 1px ${CSS_COLOR_HIGHLIGHT};
        }

        .button:not(:last-child) {
          margin-right: 5px;
        }

        .loading-overlay {
          position: absolute;

          width: 100%;
          height: 100%;
        }

        :host .action-bar {
          position: sticky;
          bottom: 0;
          width: 100%;
          z-index: 1;
        }

        .select {
          padding-bottom: ${CSS_SPACING};
        }

        .duration {
          display: block;
          width: 100%;
          margin-top: 10px;
          margin-bottom: ${CSS_SPACING};
        }

        .row-with-tooltip {
          display: grid;
          grid-template-columns: 1fr auto;
        }

        .tooltip {
          padding: 25px 0 0 10px;
        }

        .icon-authorization-warning {
          display: block;
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_WARNING_COLOR};
        }

        .add-split-button {
          padding-bottom: ${CSS_SPACING};
        }

        .split-form {
          padding-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__alertBanner = createBannerController(DISPLAY_ON.scheduling);
    this.__authorizations = [];
    this.__displayAuthorizations = [];
    this.__selectedAuthorization = null;
    this.hasEditRescheduleFF = false;

    this.rooms = [];
    this.locations = [];
    this.appointmentTypes = [];
    this.splits = [];

    this.schedulingSettings = {
      resourceRequired: false,
    };

    this.layout = LARGE;
    this.appointment = null;
    this.patientId = null;
    this.dirty = false;
    this.saving = false;

    this.onCancel = () => {};

    this.onClose = () => {};

    this.onSave = () => {};

    this.onAppointmentSelect = _id => {};

    this.onOpenAlerts = _patientId => {};

    this.onChange = () => {};

    this.onOpenAppointmentHistory = () => {};

    this.onChangeSearch = () => {};

    this.onAddSplit = () => {};

    this.onRemoveSplit = () => {};

    this.onRemoveSplits = () => {};

    this.onUpdateSplit = () => {};

    this.loadedPatientId = '';
  }

  async __loadCases(patientId) {
    if (this.loadedPatientId === patientId) return;

    this.loadedPatientId = patientId;

    const cases = await caseApi.fetchMany(patientId);

    this.__authorizations = filterAuthorizationsFromCases(cases);

    this.__displayAuthorizations = [...this.__authorizations];

    if (this.appointment) {
      this.__changeCaseAndAuthorization(this.appointment.caseId);
    }
  }

  async updated(changedProps) {
    if (changedProps.has('patientId')) {
      if (this.patientId) {
        this.__alertBanner.connect();
        this.__alertBanner.update(this.patientId);
        await this.__loadCases(this.patientId);
      } else {
        this.__alertBanner.disconnect();
      }
    }

    super.updated(changedProps);
  }

  __initHandlers() {
    this.__handlers = {
      cancel: () => this.onCancel(),
      close: () => this.onClose(),
      save: () => {
        if (this.splits.length) {
          this.__handlers.updateDuration(this.__getSplitsDuration());
        }

        const isValid = this.validate();

        if (isValid) {
          this.onSave();
        }
      },
      appointmentSelect: id => this.onAppointmentSelect(id),
      openAlerts: patientId => this.onOpenAlerts(patientId),
      changeNote: e => this.onChange({ ...this.appointment, note: e.value }),

      changeCase: caseId => {
        this.__changeCaseAndAuthorization(caseId);
      },

      updateAuthorization: ({ value: authorization }) => {
        if (!authorization) {
          return;
        }

        if (
          this.__selectedAuthorization &&
          this.__selectedAuthorization.id === authorization.id
        ) {
          return;
        }

        const caseId = authorization.patientCaseId;

        this.__changeCaseAndAuthorization(caseId, authorization);
      },

      updateLocation: e => {
        if (this.appointment.locationId !== e.value.data.id) {
          this.onChange({
            ...this.appointment,
            locationId: e.value.data.id,
            resourceId: EMPTY_ROOM.data.id,
          });

          this.onRemoveSplits();
        }
      },
      updateResource: e => {
        if (e.value && this.appointment.resourceId !== e.value.data.id) {
          this.onChange({
            ...this.appointment,
            resourceId: e.value.data.id,
          });
        }
      },
      updateAppointmentType: e => {
        if (e.value && this.appointment.appointmentTypeId !== e.value.data.id) {
          this.onChange({
            ...this.appointment,
            appointmentTypeId: e.value.data.id,
          });
        }
      },
      updateDuration: duration => {
        const currentDuration =
          this.appointment.end.valueOf() - this.appointment.start.valueOf();

        if (currentDuration !== duration) {
          this.onChange({
            ...this.appointment,
            end: this.appointment.start.clone().add(duration, 'ms'),
            duration,
          });
        }
      },
      openAppointmentHistory: patientId =>
        this.onOpenAppointmentHistory(patientId),
      authorizationBannerClick: async () => {
        const result = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          patientId: this.patientId,
          patientCaseId: this.appointment.caseId,
          id: this.appointment.patientAuthorizationId,
        });

        if (result) {
          this.loadedPatientId = null;
          await this.__loadCases(this.patientId);
        }
      },
      authorizationWarningClick: () => this.__showAuthorizationWarning(),
      addSplit: () => this.onAddSplit(),
      removeSplit: index => this.onRemoveSplit(index),
      removeSplits: () => this.onRemoveSplits(),
      updateSplit: e => this.onUpdateSplit(e),
    };
  }

  __showAuthorizationWarning() {
    if (this.layout === SMALL) {
      store.dispatch(openWarning(NO_REMAINING_AUTHORIZATIONS_MESSAGE));
    } else {
      store.dispatch(
        openWarning(
          NO_REMAINING_AUTHORIZATIONS_MESSAGE,
          this.__handlers.authorizationBannerClick,
        ),
      );
    }
  }

  __changeCaseAndAuthorization(caseId, authorization = null) {
    this.__displayAuthorizations = caseId
      ? this.__authorizations.filter(auth => auth.patientCaseId === caseId)
      : [...this.__authorizations];

    if (caseId) {
      this.__selectedAuthorization =
        authorization ||
        this.__displayAuthorizations.find(
          a => a.id === this.appointment.patientAuthorizationId,
        ) ||
        null;
    }

    this.onChange({
      ...this.appointment,
      caseId,
      patientAuthorizationId: this.__selectedAuthorization?.id || null,
    });

    if (!hasAuthorizationRemaining(this.__selectedAuthorization)) {
      this.__showAuthorizationWarning();
    }
  }

  __validateSchedulingSettings() {
    return this.schedulingSettings.resourceRequired
      ? !!this.appointment.resourceId
      : true;
  }

  __roomsError() {
    const resourceValid = this.__validateSchedulingSettings();

    return this.__disableRooms && resourceValid ? '' : 'Required';
  }

  validate() {
    const resourceValid = this.__validateSchedulingSettings();
    const appointmentTypeValid = !!this.appointment.appointmentTypeId;
    const locationValid = !!this.appointment.locationId;

    const splitsValid = this.splits.every(
      split =>
        split.resourceId.data.id &&
        (split.duration.hours > 0 || split.duration.minutes > 0),
    );

    return (
      resourceValid && appointmentTypeValid && locationValid && splitsValid
    );
  }

  __renderActionBar() {
    return this.dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            class="action-bar"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.cancel}"
            confirmLabel="Save"
          ></neb-action-bar>
        `
      : '';
  }

  __renderPatientSummary() {
    return this.patientId
      ? html`
          <neb-patient-summary-controller
            id="${ELEMENTS.patientSummary.id}"
            class="patient-summary"
            .layout="${this.layout}"
            .patientId="${this.patientId}"
            .onAppointmentSelect="${this.__handlers.appointmentSelect}"
            .onOpenAlerts="${this.__handlers.openAlerts}"
            .onOpenAppointmentHistory="${
              this.__handlers.openAppointmentHistory
            }"
          ></neb-patient-summary-controller>
        `
      : html``;
  }

  __renderHeader() {
    return html`
      <div class="header">
        <div id="${ELEMENTS.header.id}" class="text text-header">${HEADER}</div>

        <div class="spacer"></div>

        <neb-icon
          id="${ELEMENTS.iconClose.id}"
          class="icon"
          icon="neb:close"
          @click="${this.__handlers.close}"
        ></neb-icon>
      </div>
    `;
  }

  __renderContentForm() {
    return html`
      <neb-appointment-details-lit
        id="${ELEMENTS.details.id}"
        class="details"
        .appointmentDetails="${this.appointment.details}"
      >
      </neb-appointment-details-lit>

      ${this.__renderLocation()} ${this.__renderRoom()}
      ${this.__renderAppointmentType()} ${this.__renderPatientCaseSearch()}
      ${this.__renderPatientAuthorizations()} ${this.__renderSelectDuration()}

      <neb-textarea
        id="${ELEMENTS.note.id}"
        label="Appointment Note"
        maxlength="500"
        name="note"
        showCount
        .value="${this.appointment.note}"
        .onChange="${this.__handlers.changeNote}"
      >
      </neb-textarea>
    `;
  }

  __renderPatientCaseSearch() {
    return html`
      <div class="row-with-tooltip">
        <neb-patient-case-search
          id="${ELEMENTS.caseSearch.id}"
          class="case-search"
          .caseId="${this.appointment ? this.appointment.caseId : null}"
          .patientId="${this.patientId}"
          .onChange="${this.__handlers.changeCase}"
        ></neb-patient-case-search>

        <neb-tooltip class="tooltip" id="${ELEMENTS.tooltipCase.id}">
          <div slot="tooltip">
            Selecting a Case will also apply a valid associated Authorization to
            the appointment.
          </div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderAuthorizationTooltip() {
    return hasAuthorizationRemaining(this.__selectedAuthorization)
      ? html`
          <neb-tooltip class="tooltip" id="${ELEMENTS.tooltipAuth.id}">
            <div slot="tooltip">
              Selecting an Authorization will also apply the associated Case to
              the appointment.
            </div>
          </neb-tooltip>
        `
      : html`
          <div class="tooltip">
            <neb-icon
              id="${ELEMENTS.iconAuthorizationWarning.id}"
              class="icon-authorization-warning"
              icon="neb:warning"
              @click="${this.__handlers.authorizationWarningClick}"
            ></neb-icon>
          </div>
        `;
  }

  __disableTypes() {
    return this.appointmentTypes.length < 1;
  }

  __disableRooms() {
    return this.schedulingSettings.resourceRequired
      ? this.rooms.length < 1
      : this.rooms.length < 2;
  }

  __disableLocation() {
    return this.locations.length === 1;
  }

  __getLocation() {
    return this.locations.find(
      location => location.data.id === this.appointment.locationId,
    );
  }

  __getRoom() {
    return this.rooms.find(
      room => room.data.id === this.appointment.resourceId,
    );
  }

  __getSplitRooms() {
    return this.rooms.filter(room => room.data.id);
  }

  __getSplitsDuration() {
    return this.splits.reduce(
      (acc, split) => acc + getDuration(split.duration),
      0,
    );
  }

  __getAppointmentType() {
    if (!this.appointment.appointmentTypeId) return null;
    const appointmentType = this.appointmentTypes.find(
      type => type.data.id === this.appointment.appointmentTypeId,
    );

    if (!appointmentType) {
      this.onChange({
        ...this.appointment,
        appointmentTypeId: null,
      });
    }

    return appointmentType;
  }

  __renderLocation() {
    return html`
      <neb-select
        id="${ELEMENTS.selectLocation.id}"
        class="select"
        label="Location"
        helper="Required"
        .items="${this.locations}"
        .value="${this.__getLocation()}"
        .onChange="${this.__handlers.updateLocation}"
        .error="${this.__getLocation() ? '' : 'Required'}"
        .disabled="${this.__disableLocation()}"
      ></neb-select>
    `;
  }

  __renderRoom() {
    return !this.splits.length
      ? html`
          <neb-select
            id="${ELEMENTS.selectRoom.id}"
            class="select"
            label="Scheduled Room"
            .helper="${
              this.schedulingSettings.resourceRequired ? 'Required' : ''
            }"
            .items="${this.rooms}"
            .value="${this.__getRoom()}"
            .onChange="${this.__handlers.updateResource}"
            .error="${this.__roomsError()}"
            .disabled="${this.__disableRooms()}"
          ></neb-select>

          <neb-button-action
            id="${ELEMENTS.addSplitButton.id}"
            class="add-split-button"
            label="Add Split Room"
            .onClick="${this.__handlers.addSplit}"
          ></neb-button-action>
        `
      : this.__renderSplits();
  }

  __renderSplits() {
    return html`
      <neb-form-split-appointment
        id="${ELEMENTS.splitForm.id}"
        class="split-form"
        .layout="${this.layout === SMALL ? SMALL : LARGE}"
        .enableConflictCheck="${false}"
        .totalDuration="${this.appointment.duration}"
        .appointmentRoom="${this.__getRoom()}"
        .rooms="${this.__getSplitRooms()}"
        .errors="${this.splitErrors}"
        .splits="${this.splits}"
        .onAddSplit="${this.__handlers.addSplit}"
        .onRemoveSplit="${this.__handlers.removeSplit}"
        .onRemoveSplits="${this.__handlers.removeSplits}"
        .onChange="${this.__handlers.updateSplit}"
        .onChangeAppointmentRoom="${this.__handlers.updateResource}"
      >
      </neb-form-split-appointment>
    `;
  }

  __renderAppointmentType() {
    return html`
      <neb-select
        id="${ELEMENTS.selectAppointmentType.id}"
        class="select"
        label="Appointment Type"
        helper="Required"
        .items="${this.appointmentTypes}"
        .value="${this.__getAppointmentType()}"
        .onChange="${this.__handlers.updateAppointmentType}"
        .error="${this.__getAppointmentType() ? '' : 'Required'}"
        .disabled="${this.__disableTypes()}"
      ></neb-select>
    `;
  }

  __renderSelectDuration() {
    return !this.splits.length
      ? html`
          <neb-duration-field-lit
            id="${ELEMENTS.selectInputDuration.id}"
            class="duration"
            .duration="${this.appointment.duration}"
            .onChange="${this.__handlers.updateDuration}"
            .hasEditRescheduleFF="${this.hasEditRescheduleFF}"
          >
          </neb-duration-field-lit>
        `
      : '';
  }

  __renderPatientAuthorizations() {
    return html`
      <div class="row-with-tooltip">
        <neb-select
          id="${ELEMENTS.selectAuthorizations.id}"
          class="select"
          label="Authorization"
          helper=""
          .items="${this.__displayAuthorizations}"
          .value="${this.__selectedAuthorization}"
          .onChange="${this.__handlers.updateAuthorization}"
        ></neb-select>

        ${this.__renderAuthorizationTooltip()}
      </div>
    `;
  }

  __renderSmall() {
    return html`
      <div class="content">
        ${this.__renderHeader()}
        <div class="content-summary">${this.__renderPatientSummary()}</div>
        <div class="content-form">${this.__renderContentForm()}</div>
        <div class="spacer"></div>
        ${this.__renderActionBar()}
      </div>
    `;
  }

  __renderBig() {
    return html`
      <div class="content">
        <div class="content-summary">${this.__renderPatientSummary()}</div>
        <div class="content-right">
          ${this.__renderHeader()}
          <div class="content-form">${this.__renderContentForm()}</div>
          <div class="spacer"></div>
          ${this.__renderActionBar()}
        </div>
      </div>
    `;
  }

  render() {
    return this.appointment
      ? html`
          <div class="container">
            ${this.layout === SMALL ? this.__renderSmall() : this.__renderBig()}

            <neb-loading-overlay
              id="${ELEMENTS.spinnerLoading.id}"
              title="Saving Appointment"
              showDelay="0"
              .show="${this.saving}"
            ></neb-loading-overlay>
          </div>
        `
      : html``;
  }
}

window.customElements.define(
  'neb-appointment-edit-form-view',
  NebAppointmentEditFormView,
);
