import equal from 'fast-deep-equal';
import moment from 'moment-timezone';

import { SUBMISSION_METHOD } from '../../../../neb-utils/claims';
import { mapFilteredCaseAuthorizations } from '../../../../neb-utils/neb-ledger-util';
import { CARE_TYPE_CODE } from '../../mappers/patient-case-mapper';

const QUALIFIER = Object.freeze({
  INITIAL_TX_DATE: '454',
  LAST_SEEN_DATE: '304',
  ACUTE_MANIFESTATION_DATE: '453',
  LAST_XRAY_DATE: '455',
  PRESCRIPTION_DATE: '471',
  REPORT_START_DATE: '090',
  REPORT_END_DATE: '091',
  FIRST_VISIT_DATE: '444',
  ACCIDENT_DATE: '439',
});

const createOrAppendItemToUpdate = ({
  accum,
  value,
  item,
  uniqueIdKey,
  propertyKey,
  sourceKey,
  defaultValue = null,
}) => {
  if (!item[uniqueIdKey]) return;

  if (!accum[sourceKey][item[uniqueIdKey]]) {
    accum[sourceKey][item[uniqueIdKey]] = {
      id: item[uniqueIdKey],
      [propertyKey]: value || defaultValue,
    };
  } else {
    accum[sourceKey][item[uniqueIdKey]] = {
      ...accum[sourceKey][item[uniqueIdKey]],
      [propertyKey]: value || defaultValue,
    };
  }
};

const getDateValue = ({ patientCase, key }) =>
  patientCase[key] ? moment(patientCase[key]) : null;

const getOtherDate = ({ patientCase, stateInitialTxDate }) => {
  if (!patientCase) {
    return { otherDate: null, otherDateQualifier: null };
  }

  if (patientCase.careType === CARE_TYPE_CODE.AUTO_ACCIDENT) {
    return {
      otherDate: patientCase.accidentDate,
      otherDateQualifier: QUALIFIER.ACCIDENT_DATE,
    };
  }

  if (patientCase.initialTxDate) {
    return {
      otherDate: stateInitialTxDate,
      otherDateQualifier: QUALIFIER.INITIAL_TX_DATE,
    };
  }

  const dates = {
    ...(stateInitialTxDate && {
      INITIAL_TX_DATE: moment(stateInitialTxDate),
    }),
    LAST_SEEN_DATE: getDateValue({ patientCase, key: 'lastSeenDate' }),
    ACUTE_MANIFESTATION_DATE: getDateValue({
      patientCase,
      key: 'acuteManifestationDate',
    }),
    LAST_XRAY_DATE: getDateValue({ patientCase, key: 'lastXRayDate' }),
    PRESCRIPTION_DATE: getDateValue({
      patientCase,
      key: 'prescriptionDate',
    }),
    REPORT_START_DATE: getDateValue({
      patientCase,
      key: 'reportStartDate',
    }),
    REPORT_END_DATE: getDateValue({
      patientCase,
      key: 'reportEndDate',
    }),
    FIRST_VISIT_DATE: getDateValue({
      patientCase,
      key: 'firstVisitDate',
    }),
  };

  if (Object.values(dates).every(date => !date || !date.isValid())) {
    return {
      otherDate: null,
      otherDateQualifier: null,
    };
  }

  const maxDate = moment.max(
    Object.values(dates).filter(date => date && date.isValid()),
  );

  const [qualifierName] = Object.entries(dates).find(
    ([, date]) => date && date.isSame(maxDate),
  );

  return {
    otherDate: maxDate.toISOString(),
    otherDateQualifier: QUALIFIER[qualifierName],
  };
};

const setDefaultDraftBox15Date = ({
  accum,
  item,
  value: initialTxDate,
  patientCasesDict,
}) => {
  const patientCase = patientCasesDict[item.patientId].find(
    pc => pc.id === item.patientCaseId,
  );

  const { otherDate, otherDateQualifier } = getOtherDate({
    patientCase,
    stateInitialTxDate: initialTxDate,
  });

  createOrAppendItemToUpdate({
    accum,
    value: otherDate || null,
    item,
    sourceKey: 'claimDrafts',
    uniqueIdKey: 'claimId',
    propertyKey: 'otherDate',
  });

  createOrAppendItemToUpdate({
    accum,
    value: otherDate ? otherDateQualifier : null,
    item,
    sourceKey: 'claimDrafts',
    uniqueIdKey: 'claimId',
    propertyKey: 'otherDateQualifier',
  });
};

const buildUpdatedDraftLineItems = ({
  accum,
  initialStateItem,
  lineItem,
  item,
  index,
}) => {
  const initialDxPointers =
    initialStateItem.lineItems[index]?.diagnosesPointers || [];

  const currentDxPointers =
    accum.lineItems[lineItem.id]?.diagnosesPointers || [];

  if (equal(initialDxPointers, currentDxPointers)) {
    return;
  }

  const claimDraftEntry = accum.claimDrafts[`${item.claimId}`];

  if (!claimDraftEntry) {
    accum.claimDrafts[`${item.claimId}`] = {
      id: item.claimId,
      lineItems: [
        {
          id: lineItem.id,
          diagnosesPointers: currentDxPointers,
        },
      ],
    };
  } else {
    accum.claimDrafts[`${item.claimId}`].lineItems = [
      ...(claimDraftEntry.lineItems ? claimDraftEntry.lineItems : []),
      {
        id: lineItem.id,
        diagnosesPointers: currentDxPointers,
      },
    ];
  }
};

const defaultLineItemFieldValue = field =>
  ['patientCaseId', 'patientAuthorizationId'].includes(field) ? null : [];

const shouldUpdateLineItems = (lineItemsProcessed, key, isDraft) =>
  !lineItemsProcessed &&
  (['lineItems', 'patientCaseId'].includes(key) ||
    (key === 'patientAuthorizationId' && !isDraft));

const mapUpdateKeys = (keyToUpdate, isDraft) =>
  keyToUpdate === 'patientCaseId' ||
  (keyToUpdate === 'patientAuthorizationId' && !isDraft);

const handleUpdateLineItems = ({ accum, item, isDraft, initialStateItem }) => {
  const keysToUpdate = [
    'patientCaseId',
    'patientAuthorizationId',
    'diagnosesPointers',
    'encounterChargeId',
  ];

  item.lineItems.forEach((li, index) =>
    keysToUpdate.forEach(keyToUpdate => {
      createOrAppendItemToUpdate({
        accum,
        item: li,
        value: mapUpdateKeys(keyToUpdate, isDraft)
          ? item[keyToUpdate]
          : li[keyToUpdate] || defaultLineItemFieldValue(keyToUpdate),
        sourceKey: 'lineItems',
        uniqueIdKey: 'id',
        propertyKey: keyToUpdate,
      });

      if (isDraft && keyToUpdate === 'diagnosesPointers') {
        buildUpdatedDraftLineItems({
          accum,
          isDraft,
          keyToUpdate,
          initialStateItem,
          lineItem: li,
          item,
          index,
        });
      }
    }),
  );
};

const handleUpdatePatientCaseOnDraft = ({
  accum,
  value,
  item,
  key,
  isDraft,
  patientCasesDict,
}) => {
  if (key !== 'patientCaseId') return;

  if (isDraft) {
    createOrAppendItemToUpdate({
      accum,
      value,
      item,
      sourceKey: 'claimDrafts',
      uniqueIdKey: 'claimId',
      propertyKey: 'patientCaseId',
    });

    if (!value) {
      createOrAppendItemToUpdate({
        accum,
        value: null,
        item,
        sourceKey: 'claimDrafts',
        uniqueIdKey: 'claimId',
        propertyKey: 'otherDateQualifier',
      });
    }

    setDefaultDraftBox15Date({
      accum,
      item,
      value: item.initialTxDate,
      patientCasesDict,
    });
  }
};

const handleUpdateAuthorization = ({
  accum,
  value,
  item,
  key,
  isDraft,
  patientCasesDict,
}) => {
  if (key !== 'patientAuthorizationId') return;

  if (isDraft) {
    const patientAuthorizations = mapFilteredCaseAuthorizations(
      patientCasesDict[item.patientId].map(data => ({ data })),
    ).flatMap(pc => pc.data);

    const selectedPatientAuthorization = patientAuthorizations
      ? patientAuthorizations.find(({ id }) => id === value)
      : null;

    createOrAppendItemToUpdate({
      accum,
      value: selectedPatientAuthorization
        ? selectedPatientAuthorization.authorizationNumber
        : null,
      item,
      sourceKey: 'claimDrafts',
      uniqueIdKey: 'claimId',
      propertyKey: 'authorizationNumber',
    });
  }
};

const handleUpdateInitialTreatmentDate = ({
  accum,
  value,
  item,
  key,
  isDraft,
  patientCasesDict,
}) => {
  if (key !== 'initialTxDate') return;

  const patientCase = patientCasesDict[item.patientId].find(
    pc => pc.id === item.patientCaseId,
  );

  if (patientCase && value !== patientCase.initialTxDate) {
    createOrAppendItemToUpdate({
      accum,
      value,
      item,
      sourceKey: 'patientCases',
      uniqueIdKey: 'patientCaseId',
      propertyKey: key,
    });
  }

  if (isDraft) {
    setDefaultDraftBox15Date({
      accum,
      item,
      value: item.initialTxDate,
      patientCasesDict,
    });
  }
};

const __handleUpdateBox14Selection = ({ accum, value, item }) => {
  if (!item.dateOfCurrentIllnessQualifier && value) {
    createOrAppendItemToUpdate({
      accum,
      value: '431',
      item,
      sourceKey: 'claimDrafts',
      uniqueIdKey: 'claimId',
      propertyKey: 'dateOfCurrentIllnessQualifier',
    });
  } else if (item.dateOfCurrentIllnessQualifier && !value) {
    createOrAppendItemToUpdate({
      accum,
      value: null,
      item,
      sourceKey: 'claimDrafts',
      uniqueIdKey: 'claimId',
      propertyKey: 'dateOfCurrentIllnessQualifier',
    });
  }
};

const handleUpdateOnsetDate = ({ accum, value, item, key, isDraft }) => {
  if (key !== 'dateOfCurrentIllness') return;

  createOrAppendItemToUpdate({
    accum,
    value,
    item,
    sourceKey: 'encounters',
    uniqueIdKey: 'encounterId',
    propertyKey: 'currentIllnessDate',
  });

  createOrAppendItemToUpdate({
    accum,
    value: !value,
    item,
    sourceKey: 'encounters',
    uniqueIdKey: 'encounterId',
    propertyKey: 'currentIllnessGradual',
    defaultValue: false,
  });

  if (item.patientCaseId) {
    createOrAppendItemToUpdate({
      accum,
      value,
      item,
      sourceKey: 'patientCases',
      uniqueIdKey: 'patientCaseId',
      propertyKey: 'onsetSymptomsDate',
    });
  }

  if (isDraft && item.dateOfCurrentIllnessQualifier !== '484') {
    createOrAppendItemToUpdate({
      accum,
      value,
      item,
      sourceKey: 'claimDrafts',
      uniqueIdKey: 'claimId',
      propertyKey: 'dateOfCurrentIllness',
    });

    __handleUpdateBox14Selection({ accum, value, item });
  }
};

const handleUpdateClaimFlag = ({ accum, item, key }) => {
  if (key !== 'claimFlag') return;

  if (item.claimFlag) {
    accum.flaggedClaims[item.invoiceId] = {
      invoiceId: item.invoiceId,
      insuranceId: item.primaryInsuranceId,
      claimId: item.claimId || null,
      isElectronic:
        item.submissionMethod === SUBMISSION_METHOD.ELECTRONIC_CLAIMS,
      details: item.claimFlagDetails,
    };
  }
};

const shouldUpdateItem = ({ keysToCompare, value, initialStateItem, key }) => {
  if (![...keysToCompare, 'diagnosesPointers'].includes(key)) return false;
  let result;

  switch (key) {
    case 'lineItems': {
      const keyToCompare = 'diagnosesPointers';

      return value.some((li, i) =>
        shouldUpdateItem({
          keysToCompare,
          value: li[keyToCompare],
          initialStateItem: initialStateItem[key][i],
          key: keyToCompare,
        }),
      );
    }
    default:
      result = !equal(value, initialStateItem[key]);
  }

  return result;
};

export default (state, initialState, patientCasesDict) => {
  const keysToCompare = [
    'dateOfCurrentIllness',
    'patientCaseId',
    'initialTxDate',
    'patientAuthorizationId',
    'lineItems',
    'claimFlag',
    'claimFlagDetails',
  ];

  const {
    encounters,
    patientCases,
    lineItems,
    claimDrafts,
    flaggedClaims,
  } = state.reduce(
    (accum, item, index) => {
      const initialStateItem = initialState[item.id];
      Object.entries(item).reduce((lineItemsProcessed, [key, value]) => {
        if (
          shouldUpdateItem({
            keysToCompare,
            value,
            initialStateItem,
            key,
          })
        ) {
          const isDraft =
            item.claimId && item.status && item.status === 'Draft';

          handleUpdateOnsetDate({ accum, value, item, key, isDraft });
          handleUpdateInitialTreatmentDate({
            accum,
            value,
            item,
            key,
            isDraft,
            patientCasesDict,
          });

          handleUpdateAuthorization({
            accum,
            value,
            item,
            key,
            isDraft,
            index,
            patientCasesDict,
          });

          handleUpdatePatientCaseOnDraft({
            accum,
            value,
            item,
            key,
            isDraft,
            patientCasesDict,
          });

          handleUpdateClaimFlag({
            accum,
            item,
            key,
          });

          if (shouldUpdateLineItems(lineItemsProcessed, key, isDraft)) {
            handleUpdateLineItems({
              accum,
              item,
              isDraft,
              initialStateItem,
              key,
            });

            return true;
          }
        }

        return lineItemsProcessed;
      }, false);

      return accum;
    },
    {
      encounters: {},
      patientCases: {},
      lineItems: {},
      claimDrafts: {},
      flaggedClaims: {},
    },
  );

  return {
    encounters: Object.values(encounters),
    patientCases: Object.values(patientCases),
    lineItems: Object.values(lineItems),
    claimDrafts: Object.values(claimDrafts),
    flaggedClaims: Object.values(flaggedClaims),
  };
};
