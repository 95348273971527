import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_GREY_1,
  CSS_BANNER_ERROR_COLOR,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
} from '../../../../../../../neb-styles/neb-variables';
import {
  formatCurrency,
  isEmpty,
  isMet,
} from '../../../../../utils/insurance-util';

export const ELEMENTS = {
  coverageLevelText: {
    id: 'coverage-level-text',
  },
  noContentText: {
    id: 'no-content-text',
  },
  isDefaultIndicator: {
    id: 'is-default-indicator',
  },
  inNetworkAmount: {
    id: 'in-network-amount',
  },
  outOfNetworkAmount: {
    id: 'out-of-network-amount',
  },
  inNetworkRemainingAmount: {
    id: 'in-network-remaining-amount',
  },
  outOfNetworkRemainingAmount: {
    id: 'out-of-network-remaining-amount',
  },
  deductibleContainers: {
    selector: '.deductible-container',
  },
};

export const NO_CONTENT_TEXT = 'There are no deductibles for this plan.';

class NebPatientInsuranceDeductiblesSummary extends LitElement {
  static get properties() {
    return {
      layout: {
        reflect: true,
        type: String,
      },
      deductibles: {
        type: Array,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.deductibles = [];
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .box-container {
          padding: 0 10px;
        }

        :host([layout='small']) .box-container {
          padding: 0;
        }

        .deductible-container {
          padding-top: ${CSS_SPACING};
        }

        .deductible-row {
          display: flex;
          justify-content: space-between;
        }

        .flex-one {
          flex: 1;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .red {
          color: ${CSS_BANNER_ERROR_COLOR};
        }

        .left-padding {
          padding-left: 4px;
        }

        .pill {
          padding: 0px 10px;
          color: ${CSS_COLOR_WHITE};
          background: ${CSS_COLOR_HIGHLIGHT};
          margin-left: 10px;
          border-radius: 10px;
        }

        .same-line {
          display: flex;
        }

        .no-content {
          font-size: ${CSS_FONT_SIZE_BODY};
          font-style: italic;
          color: ${CSS_COLOR_GREY_1};
          padding: ${CSS_SPACING} 10px 0 0;
        }
      `,
    ];
  }

  __checkRemaining(amount) {
    return isMet(amount) ? 'bold red' : '';
  }

  __renderDefaultIndicator(cst) {
    return cst.isDefault
      ? html`
          <div id="${ELEMENTS.isDefaultIndicator.id}" class="bold pill">
            Default
          </div>
        `
      : '';
  }

  __renderInNetworkAmount(deductible, idx) {
    if (isEmpty(deductible.inNetworkAmount)) return '';
    return html`
      <div id="${ELEMENTS.inNetworkAmount.id}_${idx}">
        In Network: ${formatCurrency(deductible.inNetworkAmount)}
      </div>
    `;
  }

  __renderInNetworkRemaining(deductible, idx) {
    if (isEmpty(deductible.inNetworkRemaining)) return '';
    return html`
      <div class="same-line">
        <div
          id="${ELEMENTS.inNetworkRemainingAmount.id}_${idx}"
          class="left-padding ${
            this.__checkRemaining(deductible.inNetworkRemaining)
          }"
        >
          Remaining: ${formatCurrency(deductible.inNetworkRemaining)}
        </div>
      </div>
    `;
  }

  __renderOutOfNetworkAmount(deductible, idx) {
    if (isEmpty(deductible.outOfNetworkAmount)) return '';
    return html`
      <div id="${ELEMENTS.outOfNetworkAmount.id}_${idx}">
        Out of Network: ${formatCurrency(deductible.outOfNetworkAmount)}
      </div>
    `;
  }

  __renderOutOfNetworkRemaining(deductible, idx) {
    if (isEmpty(deductible.outOfNetworkRemaining)) return '';
    return html`
      <div class="same-line">
        <div
          id="${ELEMENTS.outOfNetworkRemainingAmount.id}_${idx}"
          class="left-padding ${
            this.__checkRemaining(deductible.outOfNetworkRemaining)
          }"
        >
          Remaining: ${formatCurrency(deductible.outOfNetworkRemaining)}
        </div>
      </div>
    `;
  }

  __renderDeductibles() {
    return this.deductibles.map((deductible, idx) => {
      if (
        !deductible.coverageLevel ||
        !deductible.coverageLevel.code ||
        deductible.coverageLevel.code === 'NA'
      ) {
        return html`
          <div id="${ELEMENTS.noContentText.id}" class="no-content">
            ${NO_CONTENT_TEXT}
          </div>
        `;
      }
      return html`
        <div class="deductible-container">
          <div
            class="bold same-line"
            id="${ELEMENTS.coverageLevelText.id}_${idx}"
          >
            ${deductible.coverageLevel.description}
            ${this.__renderDefaultIndicator(deductible)}
          </div>
          <div class="deductible-row">
            <div class="flex-one">
              ${this.__renderInNetworkAmount(deductible, idx)}
            </div>
            <div class="flex-one">
              ${this.__renderInNetworkRemaining(deductible, idx)}
            </div>
          </div>
          <div class="deductible-row">
            <div class="flex-one">
              ${this.__renderOutOfNetworkAmount(deductible, idx)}
            </div>
            <div class="flex-one">
              ${this.__renderOutOfNetworkRemaining(deductible, idx)}
            </div>
          </div>
        </div>
      `;
    });
  }

  render() {
    return html`
      <div class="box-container">${this.__renderDeductibles()}</div>
    `;
  }
}

customElements.define(
  'neb-patient-insurance-deductibles-summary',
  NebPatientInsuranceDeductiblesSummary,
);
