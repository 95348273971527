import '../../neb-lit-components/src/components/neb-button';
import '../../neb-lit-components/src/components/neb-radio-button';
import '../../neb-lit-components/src/components/neb-macro-answer-preview';

import { html, css } from 'lit';

import { ELEMENTS as BASE_ELEMENTS, NebPopupMacros } from './neb-popup-macros';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  options: {
    selector: 'neb-radio-button',
  },
  radioButtonContainer: {
    id: 'radio-button-container',
  },
};

class NebPopupMacrosSingleSelect extends NebPopupMacros {
  static get properties() {
    return {
      __selectedIndex: Number,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .option-container {
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          padding: 0;
          list-style-type: none;
          overflow: auto;
          margin: 0 0 10px 0;
        }

        .preview {
          display: flex;
          min-height: 0;
          flex: 0 0 95px;
          margin-bottom: 60px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__selectedIndex = null;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      click: value => {
        this.__selectedIndex = value;
        this.handlers.change(this.genAnswerFromSelection());
      },
    };
  }

  buildExtraAnswerProps() {
    return {
      selectedIndex: this.__selectedIndex,
    };
  }

  validateAnswer() {
    return (
      (this.__selectedIndex != null && this.answer !== '') || !!this.answer
    );
  }

  modelChanged() {
    this.title = this.model.title;

    if (this.model.answer) {
      this.__selectedIndex = this.model.answer.selectedIndex;
      this.answer = this.model.answer.text;
    } else {
      this.__selectedIndex = null;
      this.answer = '';
    }
  }

  genAnswerFromSelection() {
    return this.__selectedIndex != null
      ? this.model.options[this.__selectedIndex]
      : '';
  }

  __renderOptions() {
    return this.model.options.map(
      (option, index) => html`
        <li>
          <neb-radio-button
            id="option-${index}"
            .value="${index}"
            .label="${option}"
            .checked="${this.__selectedIndex === index}"
            .onSelect="${this.handlers.click}"
          /></neb-radio-button>
        </li>
      `,
    );
  }

  renderMacroContent() {
    return html`
      ${
        this.model.options
          ? html`
              <ul
                id="${ELEMENTS.radioButtonContainer.id}"
                class="option-container"
              >
                ${this.__renderOptions()}
              </ul>
            `
          : ''
      }
    `;
  }
}

customElements.define(
  'neb-popup-macros-single-select',
  NebPopupMacrosSingleSelect,
);
