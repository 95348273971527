import '../neb-popover';
import '../inputs/neb-textfield';
import '../../../../../src/components/misc/neb-icon';

import { html, css, LitElement } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_BANNER_INFO_BACKGROUND_COLOR,
  CSS_BANNER_INFO_COLOR,
} from '../../../../neb-styles/neb-variables';
import { COLOR } from '../neb-text';

export const ELEMENTS = {
  container: { id: 'container' },
  notifications: { selector: '.notification' },
  notificationsContainer: { id: 'notification-container' },
  notificationText: { id: 'notification-text' },
  notificationTextContainer: { id: 'notification-text-container' },
  popover: { id: 'popover' },
};

class NebNotificationsCell extends LitElement {
  static get properties() {
    return {
      __displayed: Boolean,
      notifications: Array,
      notificationText: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          width: 100%;
          height: 100%;
          background: ${CSS_BANNER_INFO_BACKGROUND_COLOR};
        }

        .popover-container {
          width: 500px;
          position: relative;
        }

        .container {
          display: flex;
          color: ${CSS_BANNER_INFO_COLOR};
          padding: ${CSS_SPACING};
        }

        .container-notification-text {
          display: grid;
          height: fit-content;
          grid-template-columns: 20px 1fr;
          column-gap: 10px;
          cursor: pointer;
        }

        .notification {
          margin: 10px 0;
          flex-shrink: 0;
        }

        .notification:first-of-type {
          margin-top: 0;
        }

        .notification:last-of-type {
          margin-bottom: 0;
        }

        .notification-text {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          text-decoration: underline;
          white-space: nowrap;
        }

        .popover {
          left: ${CSS_SPACING};
          position: absolute;
        }

        .container-notifications {
          display: flex;
          flex-direction: column;
          padding: ${CSS_SPACING};
        }

        .icon {
          fill: ${CSS_BANNER_INFO_COLOR};
          height: 20px;
          width: 20px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__displayed = false;

    this.notifications = [];
    this.notificationText = '';
  }

  __initHandlers() {
    this.__handlers = {
      toggle: () => {
        this.__displayed = !this.__displayed;
      },
    };
  }

  get __text() {
    if (this.notificationText) return this.notificationText;

    return this.notifications.length === 1
      ? '1 notification'
      : `${this.notifications.length} notifications`;
  }

  __renderNotifications() {
    return html`
      <div class="container-notifications">
        ${
          this.notifications.map(
            t => html`
              <neb-text class="notification" .color="${COLOR.DEFAULT}">
                &bull; ${t}
              </neb-text>
            `,
          )
        }
      </div>
    `;
  }

  __renderPopover() {
    return this.__displayed
      ? html`
          <div id="${ELEMENTS.popover.id}" class="popover-container">
            <neb-popover
              class="popover"
              .showCaret="${false}"
              .onBlockerClicked="${this.__handlers.toggle}"
            >
              ${this.__renderNotifications()}
            </neb-popover>
          </div>
        `
      : '';
  }

  render() {
    return Array.isArray(this.notifications) && this.notifications.length
      ? html`
          <div id="${ELEMENTS.container.id}" class="container">
            <div
              id="${ELEMENTS.notificationTextContainer.id}"
              class="container-notification-text"
              @click="${this.__handlers.toggle}"
            >
              <neb-icon class="icon" icon="neb:info"></neb-icon>
              <span
                id="${ELEMENTS.notificationText.id}"
                class="notification-text"
              >
                ${this.__text}
              </span>
            </div>
            ${this.__renderPopover()}
          </div>
        `
      : html``;
  }
}

window.customElements.define('neb-notifications-cell', NebNotificationsCell);
