import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const postDiscount = async data => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `patients/${data.patientId}/discounts`,
    method: Method.POST,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return result.data[0];
};

export const putDiscount = async data => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `patients/${data.patientId}/discounts/${data.id}`,
    method: Method.PUT,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return result.data[0];
};
