import { html } from 'lit';

import { parseDate } from '../../packages/neb-utils/date-util';

export const formatSubTitle = lineItem => {
  const { dateOfService, code, modifiers = [], encounterCharge } = lineItem;
  const dosLabel = html`
    <b>DOS: </b>
  `;

  const codeLabel = html`
    <b style="padding-left: 60px;">Code: </b>
  `;

  const appointmentTypeLabel = html`
    <b style="padding-left: 60px;">Appointment Type: </b>
  `;

  const titleSegments = [
    dateOfService && dosLabel,
    dateOfService && parseDate(dateOfService).format('MM/DD/YYYY'),
    code && codeLabel,
    code,
    modifiers.filter(Boolean).length
      ? html`
          - ${modifiers.filter(Boolean).join(', ')}
        `
      : '',
    encounterCharge
      ? html`
          ${appointmentTypeLabel} ${encounterCharge.appointmentTypeName}
        `
      : '',
  ].filter(Boolean);

  return titleSegments.length ? [...titleSegments] : '';
};
