import {
  mapNextAppointmentToServerModel,
  mapServerModelToNextAppointment,
} from './mappers/next-appointment-mapper';
import { Method } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

export const coreApiClient = new ApiClientV2({ microservice: 'core' });

export const createNextAppointment = (nextAppointment, encounterId) =>
  coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/encounters/:encounterId/next-appointment',
    replacements: { encounterId },
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(nextAppointment),
  });

export const updateNextAppointment = async (nextAppointment, encounterId) => {
  const serverModel = mapNextAppointmentToServerModel(nextAppointment);

  const json = await coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path:
      '/api/v1/tenants/:tenantId/encounters/:encounterId/next-appointment/:id',
    replacements: { encounterId, id: nextAppointment.id },
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(serverModel),
  });

  return mapServerModelToNextAppointment(json.data[0]);
};

export const getNextAppointment = async encounterId => {
  const json = await coreApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/encounters/:encounterId/next-appointment',
    replacements: { encounterId },
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return json.count === 0
    ? null
    : mapServerModelToNextAppointment(json.data[0]);
};
