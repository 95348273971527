import { LitElement, css, html } from 'lit';

import {
  CSS_FONT_FAMILY,
  CSS_COLOR_DISABLED,
  CSS_COLOR_DISABLED_TEXT,
  CSS_COLOR_GREY_5,
  CSS_COLOR_BLACK,
  CSS_FIELD_MARGIN,
} from '../../../neb-styles/neb-variables';
import checkboxStyle from '../../material_styles/mdc-checkbox-css';
import listStyle from '../../material_styles/mdc-list-css';
import menuStyle from '../../material_styles/mdc-menu-css';
import menuSurfaceStyle from '../../material_styles/mdc-menu-surface-css';
import notchedOutlineStyle from '../../material_styles/mdc-notched-outline-css';
import selectStyle from '../../material_styles/mdc-select-css';
import helperTextStyle from '../../material_styles/mdc-select-helper-text-css';

const ELEMENTS = {
  input: {
    id: 'input',
  },
  selectInput: {
    id: 'select-input',
  },
  select: {
    id: 'enhanced-select',
  },
  helperText: {
    id: 'helper-text',
  },
  label: {
    id: 'select-label',
  },
  option: {
    selector: 'option',
  },
  staticLabel: {
    id: 'static-label',
  },
};
const EXTERNAL_ELEMENTS = {
  menuStyle: {
    id: 'md-menu-style',
  },
};

class NebMDBaseSelect extends LitElement {
  static get properties() {
    return {
      menuId: {
        type: String,
        converter: {
          fromAttribute: value =>
            `${value}-menu-${NebMDBaseSelect.menuIds.length + 1}`,
        },
        reflect: true,
      },
      id: {
        type: String,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
      labelText: {
        type: String,
      },
      helperText: {
        type: String,
      },
      emptyText: {
        type: String,
      },
      name: {
        type: String,
      },
      items: {
        type: Array,
      },
      renderWidth: {
        type: Number,
      },
      required: {
        type: Boolean,
        reflect: true,
      },
      _valid: {
        type: Boolean,
        reflect: true,
        attribute: 'valid',
      },
      width: {
        type: String,
      },
      zIndex: {
        type: Number,
      },
      isStaticLabel: {
        type: Boolean,
        reflect: true,
        attribute: 'is-static-label',
      },
    };
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.staticCss = this.__compileStaticCss();
    this.disabled = false;
    this.labelText = '';
    this.isStaticLabel = false;
    this.items = [];
    this.name = '';

    this.onChange = () => {};

    this.computeDisplayValue = item => {
      if (!item) return '';

      return item.value || item;
    };

    this.renderWidth = 0;
    this.select = {};
    this._valid = true;
    this.width = '100%';
    this.zIndex = 0;
    this.emptyText = 'No Items';
    this.select = {};
  }

  static get styles() {
    return [
      selectStyle,
      listStyle,
      helperTextStyle,
      notchedOutlineStyle,
      menuSurfaceStyle,
      menuStyle,
      checkboxStyle,
      css`
        /* DO NOT TOUCH - Chirotouch spec - Fixes issue with height not fitting child height */
        :host {
          display: flex;
          flex-direction: column;

          font-size: 14px;
        }

        .mdc-select {
          margin-top: ${CSS_FIELD_MARGIN};
        }

        :host([is-static-label]) .mdc-select {
          margin-top: 5px;
        }

        :host(:not([valid])) .select-static-label {
          color: var(--mdc-theme-error);
        }

        :host([disabled]) .mdc-select-helper-text {
          color: ${CSS_COLOR_DISABLED};
        }

        :host([disabled]) .select-static-label {
          color: ${CSS_COLOR_DISABLED};
        }

        /* DO NOT TOUCH - Chirotouch Spec - Changes the height of the text-input to 40px */
        .mdc-select,
        .mdc-select__native-control,
        .mdc-select__selected-text {
          height: 40px;
        }

        .mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
          color: ${CSS_COLOR_GREY_5};
        }

        .mdc-select--outlined
          .mdc-notched-outline--upgraded
          .mdc-floating-label--float-above {
          color: ${CSS_COLOR_BLACK};
        }

        .mdc-select.mdc-select--disabled .mdc-floating-label {
          color: ${CSS_COLOR_DISABLED};
        }

        .mdc-select--disabled .mdc-select__selected-text {
          color: ${CSS_COLOR_DISABLED_TEXT};
        }

        .mdc-select:not(.mdc-select--disabled).mdc-select--focused
          .mdc-floating-label {
          color: var(--mdc-theme-primary);
        }

        .mdc-select:not(.mdc-select--disabled).mdc-select--invalid
          .mdc-floating-label {
          color: var(--mdc-theme-error);
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixes the default label text positioning in the text area before hightlight */
        .mdc-select.mdc-select--outlined .mdc-floating-label {
          top: 12px;
        }
        .mdc-notched-outline .mdc-floating-label {
          top: 12px;
        }
        .mdc-select--outlined .mdc-select__native-control,
        .mdc-select--outlined .mdc-select__selected-text {
          padding: 8px 0 10px 15px;
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixes the margins for the helper text */
        .mdc-select-helper-text {
          margin: 0 !important;
          padding-left: 2px;
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed the default label text height floating on highlight */
        .mdc-select--outlined.mdc-notched-outline--upgraded
          .mdc-floating-label--float-above,
        .mdc-select--outlined
          .mdc-notched-outline--upgraded
          .mdc-floating-label--float-above {
          transform: translateY(-180%) translateX(-11px) scale(0.8);
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed the above missing line on highlight */
        .mdc-notched-outline--notched .mdc-notched-outline__notch {
          border-top: 1px solid rgba(0, 0, 0, 0.38);
        }

        .mdc-select--disabled.mdc-select--outlined
          .mdc-notched-outline__leading,
        .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__notch,
        .mdc-select--disabled.mdc-select--outlined
          .mdc-notched-outline__trailing {
          border-color: ${CSS_COLOR_DISABLED};
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed the icon rotation offset */
        .mdc-select__dropdown-icon {
          bottom: 8px;
        }
        .mdc-select--activated .mdc-select__dropdown-icon {
          transform: rotate(180deg);
        }

        /* DO NOT TOUCH - Chirotouch Spec - Fixed helper text offset */
        .mdc-select--outlined + .mdc-select-helper-text {
          margin-left: 2px;
        }

        .mdc-select--disabled .mdc-select__dropdown-icon {
          opacity: 0.06;
        }

        .mdc-select__selected-text {
          flex: none;
          max-width: 100%;
          min-width: var(--neb-mdc-select__selected-text, 50px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block !important;
          padding: 10px 0 10px 20px;
        }

        .mdc-select__native-control {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }

        .mdc-select-helper-text {
          margin-bottom: 6px;
        }

        .select-static-label {
          font-size: 11.2px;
          font-family: ${CSS_FONT_FAMILY};
          color: ${CSS_COLOR_BLACK};
        }

        .mdc-select-helper-text::before {
          display: inline-block;
          width: 0;
          height: 10px;
          content: '';
          vertical-align: 0;
        }

        option:disabled {
          display: none;
        }
      `,
    ];
  }

  static addMenuId(id) {
    NebMDBaseSelect.menuIds.push(id);
  }

  static removeMenuId(id) {
    NebMDBaseSelect.menuIds = NebMDBaseSelect.menuIds.filter(_id => _id === id);
  }

  set menuId(val) {
    const oldVal = this._menuId;
    this._menuId = val;
    this.requestUpdate('menuId', oldVal);
  }

  get menuId() {
    return this._menuId;
  }

  async updated() {
    await this.__renderLayout();
  }

  async __renderLayout() {
    await new Promise(resolve =>
      setTimeout(() => {
        this.select.valid = this._valid;
        this.select.layout();
        resolve();
      }, 0),
    );
  }

  validate() {
    this._valid = !this.required || !this.items.length;
  }

  __setMenuId() {
    this.menuId = `${this.id}-menu-${NebMDBaseSelect.menuIds.length + 1}`;
    NebMDBaseSelect.addMenuId(this.menuId);
  }

  connectedCallback() {
    super.connectedCallback();

    this.__setMenuId();
  }

  disconnectedCallback() {
    const menu = document.getElementById(this.menuId);
    if (!menu) return;
    menu.parentNode.removeChild(menu);
    NebMDBaseSelect.removeMenuId(this.menuId);
  } // remove when practice app becomes own stacking context

  __getPopupZIndex() {
    const practiceApp = document.body.getElementsByTagName(
      'neb-practice-app',
    )[0];
    const popupStack = practiceApp
      ? practiceApp.querySelector(
          'zen-popup-stack.neb-practice-app-popup-stack',
        )
      : '';

    return popupStack ? window.getComputedStyle(popupStack).zIndex + 1 : 1;
  }

  __getSettingsZIndex() {
    try {
      const settingsApp = document.body.querySelector('neb-settings-app');
      const settingsController = settingsApp.querySelector(
        'neb-settings-controller',
      );

      const stackOverlay = settingsController.shadowRoot.querySelector(
        '.stack-overlay',
      );

      const popupStack = settingsApp.querySelector(
        'zen-popup-stack.neb-settings-app-popup-stack',
      );

      return Math.max(
        parseInt(window.getComputedStyle(popupStack).zIndex, 10) + 1,
        parseInt(window.getComputedStyle(stackOverlay).zIndex, 10) + 1,
      );
    } catch (e) {
      return null;
    }
  }
  /*
    iron-backdrop has a z-index of 102, so the select needs
    to calculate the highest z-index for itself
   */

  __getZIndex() {
    const settingsZIndex = this.__getSettingsZIndex();

    if (settingsZIndex) return settingsZIndex;

    const popupStackZIndex = this.__getPopupZIndex();

    const dialogZIndex =
      Array.from(document.querySelectorAll('body *:not(.mdc-select__menu)'))
        .map(a => parseFloat(window.getComputedStyle(a).zIndex) || 0)
        .sort()
        .pop() + 1;

    return popupStackZIndex > dialogZIndex ? popupStackZIndex : dialogZIndex;
  }

  __compileStaticCss() {
    return [menuStyle, menuSurfaceStyle, selectStyle, listStyle, checkboxStyle]
      .map(({ cssText }) => cssText)
      .join(' ');
  }

  __addFocus() {
    this.select.foundation_.adapter_.addClass('mdc-select--focused');
  }

  openMenu() {
    this.select.foundation_.adapter_.openMenu();
  }

  closeMenu() {
    this.select.foundation_.adapter_.closeMenu();
  } // workaround: issue - menu is hoisted to body

  _calcCss() {
    this.renderWidth = Math.floor(
      this.shadowRoot.getElementById(ELEMENTS.selectInput.id).offsetWidth,
    );

    this.zIndex = this.__getZIndex();

    this.__addFocus();
  } // workaround: issue - menu is hoisted to body

  _addCssToMenu() {
    const style = document.createElement('style');
    style.setAttribute('id', EXTERNAL_ELEMENTS.menuStyle.id);
    const mdMenuStyle = document.getElementById(EXTERNAL_ELEMENTS.menuStyle.id);

    if (!mdMenuStyle) {
      document.head.append(style);
      style.innerHTML = this.staticCss;
    }
  }

  __mdcNotchLabelHTML() {
    if (!this.labelText.length || this.isStaticLabel) return '';

    return html`
      <label
        id="${ELEMENTS.label.id}"
        for="${ELEMENTS.input.id}"
        class="mdc-floating-label"
        >${this.labelText}</label
      >
    `;
  }

  __mdcNotchHTML() {
    return html`
      <div class="mdc-notched-outline">
        <div class="mdc-notched-outline__leading"></div>

        <div class="mdc-notched-outline__notch">
          ${this.__mdcNotchLabelHTML()}
        </div>

        <div class="mdc-notched-outline__trailing"></div>
      </div>
    `;
  }

  __mdcHelperTextHTML() {
    return html`
      <div
        class="mdc-select-helper-text
          mdc-select-helper-text--validation-msg
          mdc-select-helper-text--persistent"
        id="${ELEMENTS.helperText.id}"
        aria-hidden="true"
      >
        ${this.helperText}
      </div>
    `;
  }

  _renderMDCStaticLabel() {
    return this.isStaticLabel
      ? html`
          <label id="${ELEMENTS.staticLabel.id}" class="select-static-label"
            >${this.labelText}</label
          >
        `
      : '';
  }
}

NebMDBaseSelect.menuIds = [];
export { NebMDBaseSelect, ELEMENTS, EXTERNAL_ELEMENTS };
