import equal from 'fast-deep-equal';
import { css, html } from 'lit';

import { getBillingCodesWriteOffs } from '../../../../packages/neb-api-client/src/billing-codes';
import { getClaimsByInvoice } from '../../../../packages/neb-api-client/src/claims';
import {
  createEncounterHistory,
  startEncounterHistory,
} from '../../../../packages/neb-api-client/src/encounters-api-client';
import { getLedgerInvoiceItems } from '../../../../packages/neb-api-client/src/invoice-api-client';
import { saveLineItems } from '../../../../packages/neb-api-client/src/ledger/line-items';
import { getPatientAuthorizations } from '../../../../packages/neb-api-client/src/patient-authorization-api-client';
import { getPatientInsurances } from '../../../../packages/neb-api-client/src/patient-insurance-api-client';
import { getPracticeUser } from '../../../../packages/neb-api-client/src/permissions-api-client';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { openEncounterSummary } from '../../../../packages/neb-lit-components/src/utils/encounter-overlays-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  CSS_BORDER_GREY_2,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../packages/neb-utils/constants';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../packages/neb-utils/feature-util';
import {
  BILL_TYPE,
  fetchPaymentTypeItems,
  fetchTaxRateItems,
  ITEM_NONE,
} from '../../../../packages/neb-utils/neb-ledger-util';
import { sendRefreshNotification } from '../../../../packages/neb-utils/neb-refresh';
import { getOne } from '../../../api-clients/charges';
import { getDataByLineItemId } from '../../../api-clients/era-eob';
import { fetchManyV4 } from '../../../api-clients/fee-schedules';
import { getLineItem } from '../../../api-clients/line-item';
import { getLocations } from '../../../api-clients/locations';
import { getPayerInformation } from '../../../api-clients/payer-information';
import { formatSubTitle } from '../../../formatters/ledger-line-item-overlay';
import { formatPayerInformation } from '../../../formatters/payer-information';
import { openSuccess, openError } from '../../../store';
import { viewERAsEOBs } from '../../../utils/era-eob';
import { openPaymentDetailOverlay } from '../../../utils/payment-util';
import { NebFormLineItem } from '../../forms/neb-form-line-item';

import '../../misc/neb-insurance-header';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

class NebOverlayLedgerLineItem extends Overlay {
  static get properties() {
    return {
      __loading: Boolean,
      __lineItem: Object,
      __savingError: Object,
      __payerInformation: Object,
      __provider: Object,
      __codePayments: Array,
      __codeWriteOffs: Array,
      __patientInsurances: Array,
      __payments: Object,
      __hasBillingNote: Boolean,
      __lineItems: Array,
      __showERAsAndEOBsAssociations: Boolean,
      __hasAutoCalculateAllowedAmountForCarePackageFeatureFlag: Boolean,
      __associatedERAsAndEOBs: Array,
      __settingsCharge: Object,
      __hasRCMChangeSecondary: Boolean,
      __hasRcmRecoupsFeatureFlag: Boolean,
      __hasFitInvoiceOverlayPerformanceFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }

        .header {
          padding: ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
        }

        .header-insurance {
          margin: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__loading = false;
    this.__lineItem = NebFormLineItem.createModel();
    this.__savingError = {};
    this.__refreshRunningLedger = false;
    this.__payerInformation = {};
    this.__provider = null;
    this.__taxRates = [];
    this.__feeSchedules = [];
    this.__claims = [];
    this.__locations = [];
    this.__authorizations = [];
    this.__codePayments = [];
    this.__codeWriteOffs = [];
    this.__patientInsurances = [];
    this.__payments = {};
    this.__hasBillingNote = false;
    this.__lineItems = [];
    this.__showERAsAndEOBsAssociations = false;
    this.__hasAutoCalculateAllowedAmountForCarePackageFeatureFlag = false;
    this.__associatedERAsAndEOBs = [];
    this.__hasRCMChangeSecondary = false;
    this.__hasRcmRecoupsFeatureFlag = false;
    this.__hasFitInvoiceOverlayPerformanceFF = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      dismiss: () => this.dismiss(this.__refreshRunningLedger),

      save: async (lineItem, dismiss = true) => {
        try {
          const editedAndSignedEncounterChargeId = this.__getEditedAndSignedEncounterChargeId(
            lineItem,
            this.__lineItem,
          );

          if (editedAndSignedEncounterChargeId) {
            await startEncounterHistory(editedAndSignedEncounterChargeId, true);
          }

          await saveLineItems([lineItem]);

          if (editedAndSignedEncounterChargeId) {
            await createEncounterHistory(
              editedAndSignedEncounterChargeId,
              true,
            );
          }

          openSuccess('Charge saved successfully');
          this.__savingError = {};
          this.isDirty = false;

          if (dismiss) this.dismiss({ result: true, lineItemId: lineItem.id });
          else this.__fetchLineItemInformation();

          sendRefreshNotification();

          return true;
        } catch (e) {
          openError('An error has occurred while saving this charge');

          this.__savingError = e;
          console.error(e);
          return false;
        }
      },
      refresh: async () => {
        await this.__fetchLineItemInformation();
      },
      billingHeaderUpdated: async () => {
        this.__refreshRunningLedger = true;
        sendRefreshNotification();
        await this.__fetchLineItemInformation();
      },
      openPaymentDetailOverlay: async paymentId => {
        const { patientId } = this.__lineItem;
        await openPaymentDetailOverlay({
          ...this.__payments[paymentId],
          patientId,
        });

        this.__refreshRunningLedger = true;

        await this.__fetchLineItemInformation();

        sendRefreshNotification();
      },
      openEncounterOverlay: async () => {
        const { patientId, encounterCharge } = this.__lineItem;

        const result = await openEncounterSummary({
          patient: {
            id: patientId,
          },
          appointmentTypeId: encounterCharge.appointmentTypeId,
          encounterId: encounterCharge.encounterId,
          runningLedger: true,
        });

        if (result === 'Encounter deleted') {
          this.dismiss(true);
          sendRefreshNotification();
          return;
        }

        await this.__fetchLineItemInformation();
        sendRefreshNotification();
      },
      openClaimOverlay: async claimId => {
        await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, { claimId });

        await this.__fetchLineItemInformation();

        sendRefreshNotification();
      },
      openInvoiceOverlay: async () => {
        const { invoiceId, patientId } = this.__lineItem;
        const ledgerInvoiceItems = await getLedgerInvoiceItems(invoiceId);
        const lineItemIds = ledgerInvoiceItems.data.map(li => li.id);

        const overlayKey = this.__hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;
        await openOverlay(overlayKey, {
          patient: {
            id: patientId,
          },
          lineItemIds,
          selectedIds: [],
        });

        await this.__fetchLineItemInformation();

        sendRefreshNotification();
      },
      dismissWithRefresh: () => {
        this.dismiss(true);
        sendRefreshNotification();
      },
      openNotesOverlay: async () => {
        const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.CHARGE,
          parentId: this.__lineItem.id,
          parentData: {
            dateOfService: parseDate(this.__lineItem.dateOfService).format(
              'MM/DD/YYYY',
            ),
            chargeNumber: this.__lineItem.chargeNumber,
            code: this.__lineItem.code,
            description: this.__lineItem.description,
          },
          patientId: this.__lineItem.patientId,
        });

        if (result) {
          if (result === true) {
            // if result is true, that means that the billingNotes were deleted
            this.__hasBillingNote = false;
          } else {
            this.__hasBillingNote = true;
          }
        }
      },
      viewERAsEOBs: async () => {
        const response = await viewERAsEOBs({
          associatedERAsAndEOBs: this.__associatedERAsAndEOBs,
          lineItemId: this.model.id,
          fetchData: false,
        });

        if (response) await this.__fetchLineItemInformation();
      },
    };
  }

  async firstUpdated() {
    super.firstUpdated();

    await this.__fetchLineItemInformation();
  }

  async connectedCallback() {
    const features = await getFeatures();
    this.__showERAsAndEOBsAssociations = features.includes(
      FEATURE_FLAGS.SHOW_ERA_EOB_ASSOCIATIONS,
    );

    this.__hasAutoCalculateAllowedAmountForCarePackageFeatureFlag = features.includes(
      FEATURE_FLAGS.AUTO_CALCULATE_ALLOWED_AMOUNT_FOR_CARE_PACKAGE,
    );

    this.__hasRCMChangeSecondary = features.includes(
      FEATURE_FLAGS.RCM_CHANGE_SECONDARY,
    );

    this.__hasRcmRecoupsFeatureFlag = features.includes(
      FEATURE_FLAGS.OWL_RCM_RECOUPS,
    );

    this.__hasFitInvoiceOverlayPerformanceFF = features.includes(
      FEATURE_FLAGS.FIT_INVOICE_OVERLAY_PERFORMANCE,
    );

    super.connectedCallback();
  }

  __getModifiersAndUnits(charge) {
    const { modifiers, units } = charge;

    return {
      modifiers,
      units,
    };
  }

  __getEditedAndSignedEncounterChargeId(model) {
    if (model.encounterCharge && model.encounterCharge.signed) {
      const pristineCharge = {
        modifiers: this.__lineItem.modifiers,
        units: this.__lineItem.units,
      };

      const editedCharge = {
        modifiers: model.modifiers,
        units: model.units,
      };

      if (!equal(pristineCharge, editedCharge)) {
        return model.encounterCharge.encounterId;
      }
    }

    return false;
  }

  async __fetchLineItemInformation() {
    try {
      this.__loading = true;
      await this.__doFetchLineItemInformation();
    } catch (e) {
      if (![400, 404].includes(e.statusCode)) {
        openError('An error has occurred while loading charge');
        console.error(e);
      }

      this.dismiss(true);
    } finally {
      this.__loading = false;
    }
  }

  async __doFetchLineItemInformation() {
    const [
      lineItem,
      payerInformation,
      taxRates,
      feeSchedules,
      codePayments,
      codeWriteOffs,
      locations,
      patientInsurances,
      associatedERAsAndEOBs,
    ] = await Promise.all([
      getLineItem(this.model.id, 3),
      getPayerInformation(this.model.id),
      fetchTaxRateItems(true),
      fetchManyV4(true),
      fetchPaymentTypeItems(),
      getBillingCodesWriteOffs({}, true),
      getLocations({}, true),
      getPatientInsurances(this.model.patientId, { active: true }, 1, true),
      getDataByLineItemId(this.model.id),
    ]);

    this.__lineItem = lineItem;
    this.__hasBillingNote = this.__lineItem.billingNotes.length;
    this.__patientInsurances = patientInsurances;
    this.__payerInformation = formatPayerInformation(payerInformation);
    this.__taxRates = taxRates;

    this.__feeSchedules = [
      ITEM_NONE,
      ...feeSchedules.map(data => ({
        label: data.name,
        data,
      })),
    ];

    this.__associatedERAsAndEOBs = associatedERAsAndEOBs;

    this.__codePayments = codePayments;
    this.__codeWriteOffs = codeWriteOffs;
    this.__locations = locations;
    this.__lineItems = this.model.lineItems;

    const [
      provider,
      claims,
      authorizations,
      settingsCharge,
    ] = await Promise.all([
      getPracticeUser(lineItem.encounterCharge.providerId, true),
      getClaimsByInvoice(lineItem.invoiceId, true),
      getPatientAuthorizations(
        lineItem.patientId,
        this.__payerInformation.caseId,
        1,
        true,
      ),
      getOne(lineItem.encounterCharge.chargeId, true),
    ]);

    this.__provider = provider;
    this.__claims = claims.data;
    this.__authorizations = authorizations;
    this.__settingsCharge = settingsCharge;

    this.__payments = this.__lineItem.lineItemDebits.reduce((memo, lid) => {
      const allocationPayments = lid.debit.allocations
        .map(alcn => alcn.credit.payment)
        .filter(Boolean);

      allocationPayments.forEach(payment => {
        if (!memo[payment.id]) memo[payment.id] = payment;
      });

      return memo;
    }, {});

    if (this.__hasRcmRecoupsFeatureFlag) {
      const recoups = this.__lineItem?.recoups || [];
      const recoupedPayments = recoups.reduce((memo, recoup) => {
        if (!this.__payments[recoup.fromPayment.id]) {
          memo[recoup.fromPayment.id] = recoup.fromPayment;
        }
        return memo;
      }, {});

      this.__payments = {
        ...this.__payments,
        ...recoupedPayments,
      };
    }
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="Charge - ${this.__lineItem.chargeNumber}"
        .subTitle="${formatSubTitle(this.__lineItem)}"
        .onCancel="${this.handlers.dismiss}"
        ?showAddNoteIcon="${!this.__hasBillingNote}"
        ?showEditNoteIcon="${this.__hasBillingNote}"
        .onClickNoteIcon="${this.handlers.openNotesOverlay}"
      ></neb-popup-header>

      <neb-form-line-item
        id="${ELEMENTS.form.id}"
        .loading="${this.__loading}"
        .model="${this.__lineItem}"
        .isCarePackageWithInsurance="${
          this.__lineItem.billType === BILL_TYPE.INSURANCE &&
            this.__lineItem.patientPackageId !== null
        }"
        .patientInsurances="${this.__patientInsurances}"
        .payerInformation="${this.__payerInformation}"
        .providerInformation="${this.__provider}"
        .taxRates="${this.__taxRates}"
        .feeSchedules="${this.__feeSchedules}"
        .settingsCharge="${this.__settingsCharge}"
        .claims="${this.__claims}"
        .locations="${this.__locations}"
        .authorizations="${this.__authorizations}"
        .codePayments="${this.__codePayments}"
        .codeWriteOffs="${this.__codeWriteOffs}"
        .lineItems="${this.__lineItems}"
        .savingError="${this.__savingError}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
        .onRefresh="${this.handlers.refresh}"
        .onOpenPaymentDetailOverlay="${this.handlers.openPaymentDetailOverlay}"
        .onOpenEncounterOverlay="${this.handlers.openEncounterOverlay}"
        .onOpenInvoiceOverlay="${this.handlers.openInvoiceOverlay}"
        .onOpenClaimOverlay="${this.handlers.openClaimOverlay}"
        .onUnpost="${this.handlers.dismissWithRefresh}"
        .onUpdateBillingHeader="${this.handlers.billingHeaderUpdated}"
        .multiCarePackageEnabled="${this.__lineItem.multiCarePackage}"
        .showERAsAndEOBsAssociations="${this.__showERAsAndEOBsAssociations}"
        .hasAutoCalculateAllowedAmountForCarePackageFeatureFlag="${
          this.__hasAutoCalculateAllowedAmountForCarePackageFeatureFlag
        }"
        .associatedERAsAndEOBs="${this.__associatedERAsAndEOBs}"
        .onClickERAsEOBs="${this.handlers.viewERAsEOBs}"
        .hasRCMChangeSecondary="${this.__hasRCMChangeSecondary}"
        .hasRcmRecoupsFeatureFlag="${this.__hasRcmRecoupsFeatureFlag}"
      ></neb-form-line-item>
    `;
  }
}

customElements.define('neb-overlay-ledger-line-item', NebOverlayLedgerLineItem);
