import * as patientApiClient from '../../packages/neb-api-client/src/patient-api-client';
import ApiClient from '../../packages/neb-api-client/src/utils/api-client-utils';
import { strFromQuery } from '../../packages/neb-api-client/src/utils/query-params';

export const apiClient = new ApiClient({ microservice: 'billing' });

export async function fetchMany(patientId, query = {}) {
  const id = patientId || query.patientId;
  const prefix = id ? `patients/${id}/` : '';
  const route = `${prefix}ledger/activity/purchases`;
  const path = [route, strFromQuery(query)].filter(item => item).join('?');

  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    cacheKey: path,
    version: 3,
  });

  let patients = [];
  let patient;

  if (id) {
    patient = await patientApiClient.fetchOne(id);
  } else {
    const patientIds = [...new Set(res.results.map(item => item.patientId))];

    if (patientIds.length) {
      patients = await patientApiClient.fetchSome(patientIds);
    }
  }

  return {
    count: res.count,
    data: res.results.map(item => ({
      ...item,
      patient: patient || patients.find(p => p.id === item.patientId),
    })),
  };
}
