import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

const BASE_URL = 'locations';

export const registryClient = new ApiClient({
  microservice: 'registry',
  useTenant: true,
});

export const billingClient = new ApiClient({
  microservice: 'billing',
  useTenant: true,
});

export const getLocations = async (
  queryParams,
  optOutLoadingIndicator = false,
) => {
  const res = await registryClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.GET,
    path: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    version: 1,
    queryParams,
    cacheKey: `${BASE_URL}-${JSON.stringify(queryParams)}`,
  });

  return res.data;
};

export const createLocation = async location => {
  const res = await registryClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: BASE_URL,
    body: JSON.stringify(location),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 2,
  });

  return res.data[0];
};

export const editLocation = async body => {
  const res = await registryClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${BASE_URL}/${body.id}`,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 2,
  });

  return res.data[0];
};

export const getLocationInactivation = async locationId => {
  const res = await billingClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_URL}/${locationId}/inactivation`,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return res;
};
