import '../../../src/components/misc/neb-icon';

import { Popup } from '@neb/popup';
import { html, css } from 'lit';

import { baseStyles } from '../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_1,
} from '../../neb-styles/neb-variables';

export const ELEMENTS = {
  cancelButton: {
    id: 'button-cancel-base',
  },
  header: {
    id: 'header',
  },
  title: {
    id: 'title',
  },
};

export default class NebPopup extends Popup {
  static get properties() {
    return {
      __displayHeader: Boolean,

      collapsing: {
        reflect: true,
        type: Boolean,
      },
      dynamic: {
        reflect: true,
        type: Boolean,
      },
      showHeader: Boolean,
      showCancelButton: Boolean,
      title: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      baseStyles,
      css`
        :host {
          margin: 40px 24px;
          box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.4);
        }

        :host([collapsing][layout='small']) {
          margin: 0;
          box-shadow: none;
          height: 100%;
          width: 100%;
        }

        .spacer {
          flex: 1 0 0;
        }

        .container {
          display: flex;
          padding: ${CSS_SPACING};
          height: 100%;
          min-height: 0;
          background-color: ${CSS_COLOR_WHITE};
          flex-direction: column;
        }

        :host([dynamic]) .container {
          height: unset;
        }

        .header {
          display: flex;
          align-items: center;
        }

        .content {
          min-height: 0;
          margin-top: ${CSS_SPACING};
          flex: 1 0 auto;
        }

        :host([dynamic]) .content {
          display: flex;
          flex: 0;
        }

        .button-cancel {
          outline: none;
          cursor: pointer;
          display: flex;
          border: none;
          margin: 0;
          padding: 0;
          background-color: transparent;
          color: ${CSS_COLOR_GREY_1};
        }

        .icon-cancel {
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .title {
          margin: 0;
          padding: 0;
          font-size: 16px;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
  }

  initState() {
    this.__displayHeader = true;

    this.showHeader = true;
    this.showCancelButton = true;
    this.title = 'TITLE';
    this.dynamic = false;
    this.collapsing = false;
  }

  initHandlers() {
    this.handlers = {
      cancel: () => this.onClose(),
    };
  }

  modelChanged() {}

  update(changedProps) {
    if (changedProps.has('model')) {
      this.modelChanged(changedProps.get('model'));
    }

    if (changedProps.has('showHeader')) {
      this.__displayHeader = this.showHeader;
    }

    if (changedProps.has('layout') && this.showHeader) {
      const collapsed = this.collapsing && this.layout === 'small';
      this.__displayHeader = !collapsed;
    }

    super.update(changedProps);
  }

  renderHeader() {
    return this.__displayHeader
      ? html`
          <div id="${ELEMENTS.header.id}" class="header">
            <h2 id="${ELEMENTS.title.id}" class="title">${this.title}</h2>

            <div class="spacer"></div>
            ${
              this.showCancelButton
                ? html`
                    <button
                      id="${ELEMENTS.cancelButton.id}"
                      class="button-cancel"
                      @click="${this.handlers.cancel}"
                    >
                      <neb-icon class="icon-cancel" icon="neb:close"></neb-icon>
                    </button>
                  `
                : ''
            }
          </div>
        `
      : '';
  }

  renderContent() {
    throw new Error('renderContent() not implemented');
  }

  render() {
    return html`
      <div class="container">
        ${this.renderHeader()}
        <div class="content">${this.renderContent()}</div>
      </div>
    `;
  }
}
