import '../../forms/appointments/neb-form-reschedule-controller';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { getAppointmentTypes } from '../../../../packages/neb-api-client/src/appointment-types';
import { fetchMany as fetchCases } from '../../../../packages/neb-api-client/src/patient-cases';
import { getPatientGuarantors } from '../../../../packages/neb-api-client/src/patient-guarantor-api-client';
import { fetchMany } from '../../../../packages/neb-api-client/src/reasons-api-client';
import { fetchManyRooms } from '../../../../packages/neb-api-client/src/rooms-api-client';
import { fetchOne as fetchSettings } from '../../../../packages/neb-api-client/src/settings-api-client';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { AppointmentStoreService } from '../../../../packages/neb-redux/services/appointment-store';
import { AvailabilityService } from '../../../../packages/neb-redux/services/availability';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { MdcProviderService } from '../../../../packages/neb-redux/services/mdc-provider';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { filterAuthorizationsFromCases } from '../../../../packages/neb-utils/patientAuthorization';
import { ITEM_EMPTY } from '../../../../packages/neb-utils/selectors';
import {
  EMPTY_RESCHEDULE_REASON,
  formatSelectItems,
  SELECT_BY_TYPE,
} from '../../../utils/scheduling/appointments';
import NebFormAppointment from '../../forms/appointments/neb-form-appointment';

export const ELEMENTS = {
  form: {
    id: 'form',
  },
};

const formatGradualDate = date => {
  if (!date) return 'Gradual';

  return parseDate(date).format('MM/DD/YYYY');
};

export const EDIT_MODE = {
  EDIT: 'Edit',
  RESCHEDULE: 'Reschedule',
};

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
});

class NebOverlayEditRescheduleAppointment extends Overlay {
  static get properties() {
    return {
      __providers: Array,
      __locations: Array,
      __appointmentTypes: Array,
      __rooms: Array,
      __cases: Array,
      __authorizations: Array,
      __availability: Object,
      __calendarSummary: Object,
      __rescheduleReasons: Array,
      __splitAvailability: Array,
      __providerRequired: Boolean,
      __resourceRequired: Boolean,
      __mode: String,
      __disableSaveIndicator: Boolean,
      __appointmentDragAndDropSettings: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          overflow-y: auto;
        }
        :host(:not([layout='small'])).content {
          display: grid;
          overflow-y: auto;
          min-width: 800px;
        }

        .controller {
          overflow: hidden;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
    this.__initServices();
  }

  initState() {
    super.initState();

    this.__mode = EDIT_MODE.RESCHEDULE;
    this.__providers = [];
    this.__locations = [];
    this.__appointmentDragAndDropSettings = {};
    this.__appointmentTypes = [];
    this.__rooms = [];
    this.__cases = [];
    this.__authorizations = [];
    this.__availability = {};
    this.__calendarSummary = {};
    this.__rescheduleReasons = [];
    this.__splitAvailability = [];
    this.__providerRequired = false;
    this.__resourceRequired = false;

    this.model = NebFormAppointment.createModel();
  }

  __initServices() {
    this.__providerService = new MdcProviderService(({ activeProviders }) => {
      this.__providers = activeProviders.map(data => ({
        data,
        label: data.value,
      }));
    });

    this.__locationsService = new LocationsService(({ activeLocations }) => {
      this.__locations = activeLocations.map(data => ({
        data,
        label: data.name,
      }));
    });

    this.__availabilityService = new AvailabilityService(() => {});

    this.__appointmentStoreService = new AppointmentStoreService();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      // eslint-disable-next-line complexity
      save: async (formModel, { roomId, selectByType } = {}) => {
        this.__disableSaveIndicator = false;
        const isEdit = this.__mode === EDIT_MODE.EDIT;

        const custom = selectByType === SELECT_BY_TYPE.CUSTOM || isEdit;

        try {
          const model = {
            ...this.model,
            ...formModel,
            resourceId: formModel.resourceId || null,
            providerId: formModel.providerId || null,
            rrule: formModel.rrule ? formModel.rrule.toString() : '',
            caseId: formModel.caseId || null,
            patientAuthorizationId: formModel.patientAuthorizationId || null,
            isEdit,
            custom,
            roomId: roomId || null,
          };

          let result = await this.__appointmentStoreService.rescheduleAppointment(
            {
              ...model,
              checkDuplicate: true,
            },
            this.__mode,
          );

          if (result.model && result.model.isDuplicate) {
            const appointmentType = this.__appointmentTypes.find(
              apptType => apptType.data.id === result.model.appointmentTypeId,
            );

            const accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
              title: 'Possible Duplicate Appointment',
              message: html`
                <p>
                  This patient already has an appointment of the same type
                  (${appointmentType.label}) scheduled for
                  ${parseDate(result.model.start).format('dddd, MMM DD, YYYY')}
                  at
                  ${this.__formatLocationsForPopup(result.model.locationIds)}.
                </p>

                <p>
                  Are you sure you want to schedule another appointment for this
                  patient?
                </p>
              `,
              confirmText: 'Yes',
              cancelText: 'No',
            });

            if (accepted) {
              result = await this.__appointmentStoreService.rescheduleAppointment(
                model,
                this.__mode,
              );

              this.isDirty = false;
              this.dismiss(result);
            }

            this.__disableSaveIndicator = true;
          } else {
            this.isDirty = false;
            this.dismiss(result);
          }
        } catch (e) {
          console.error(e);
        }
      },

      toggleMode: () => {
        this.__mode =
          this.__mode === EDIT_MODE.EDIT
            ? EDIT_MODE.RESCHEDULE
            : EDIT_MODE.EDIT;
      },
      addCase: async () => {
        const activeCasesCount = this.__cases.filter(c => c.data.active).length;
        const { patientId } = this.model;

        const guarantors = await getPatientGuarantors(patientId, {}, true);

        const newCase = await openOverlay(OVERLAY_KEYS.CASE, {
          context: {
            patientId,
            guarantors: guarantors.filter(g => g.relation === 'Self'),
            isFirstCase: activeCasesCount === 0,
          },
        });

        if (newCase) {
          await this.__loadCases(patientId);

          return this.__cases.find(c => c.data.id === newCase.id);
        }

        return null;
      },
      loadCases: async () => {
        await this.__loadCases(this.model.patientId);
      },
    };
  }

  async __loadAppointmentTypes() {
    const res = await getAppointmentTypes();

    this.__appointmentTypes = res.data.map(a => ({ data: a, label: a.name }));
  }

  async __loadRooms() {
    const res = await fetchManyRooms({ includeAppointmentTypes: true });

    this.__rooms = [ITEM_EMPTY, ...formatSelectItems(res)];
  }

  async __loadCases() {
    if (this.model.patientId) {
      const cases = await fetchCases(this.model.patientId);
      const activeOrCurrentCases = cases.filter(
        c => c.active || c.id === this.model.caseId,
      );

      this.__cases = [
        ITEM_EMPTY,
        ...activeOrCurrentCases.map(c => ({
          data: c,
          label: `${c.name} - ${formatGradualDate(c.onsetSymptomsDate)}`,
        })),
      ];

      this.__authorizations = [
        ITEM_EMPTY,
        ...filterAuthorizationsFromCases(activeOrCurrentCases).map(auth => ({
          data: auth,
          label: auth.label,
        })),
      ];
    }
  }

  async __loadReasons() {
    const res = await fetchMany();

    const reasons = res
      .filter(item => item.active)
      .map(item => ({
        label: item.name,
        data: { id: item.id },
      }));

    this.__rescheduleReasons = [EMPTY_RESCHEDULE_REASON, ...reasons];
  }

  async __loadSettings() {
    const {
      data: {
        providerRequired,
        resourceRequired,
        confirmOnDragDrop,
        editOnSameDayDragDrop,
        editOnDifferentDayDragDrop,
      },
    } = await fetchSettings(true);

    this.__appointmentDragAndDropSettings = {
      confirmOnDragDrop,
      editOnSameDayDragDrop,
      editOnDifferentDayDragDrop,
    };

    this.__providerRequired = providerRequired;
    this.__resourceRequired = resourceRequired;
  }

  async connectedCallback() {
    await Promise.all([
      this.__loadAppointmentTypes(),
      this.__loadRooms(),
      this.__loadCases(),
      this.__loadReasons(),
      this.__loadSettings(),
    ]);

    this.__providerService.connect();
    this.__locationsService.connect();

    super.connectedCallback();
  }

  disconnectedCallback() {
    try {
      this.__providerService.disconnect();
      this.__locationsService.disconnect();
    } catch (e) {
      // noop
    }

    super.disconnectedCallback();
  }

  __formatLocationsForPopup(locationIds) {
    const locations = this.__locations.filter(loc =>
      locationIds.includes(loc.data.id),
    );

    return formatter.format(locations.map(({ label }) => label));
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model.mode) {
      this.__mode = this.model.mode;
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <neb-form-reschedule-controller
        id="${ELEMENTS.form.id}"
        class="controller"
        .mode="${this.__mode}"
        .providers="${this.__providers}"
        .locations="${this.__locations}"
        .appointmentTypes="${this.__appointmentTypes}"
        .rooms="${this.__rooms}"
        .cases="${this.__cases}"
        .authorizations="${this.__authorizations}"
        .rescheduleReasons="${this.__rescheduleReasons}"
        .availability="${this.__availability}"
        .splitAvailability="${this.__splitAvailability}"
        .layout="${this.layout}"
        .model="${this.model}"
        .calendarSummary="${this.__calendarSummary}"
        .appointmentDragAndDropSettings="${
          this.__appointmentDragAndDropSettings
        }"
        .providerRequired="${this.__providerRequired}"
        .resourceRequired="${this.__resourceRequired}"
        saveBlockerLabel="${
          this.__mode === EDIT_MODE.RESCHEDULE
            ? 'Rescheduling Appointment'
            : 'Saving Appointment'
        }"
        .disableSaveIndicator="${this.__disableSaveIndicator}"
        .onCancel="${this.handlers.dismiss}"
        .onChangeDirty="${this.handlers.dirty}"
        .onSave="${this.handlers.save}"
        .onFetchAvailability="${this.handlers.fetchAvailabilityAndSummary}"
        .onToggleMode="${this.handlers.toggleMode}"
        .onAddCase="${this.handlers.addCase}"
        .onLoadCases="${this.handlers.loadCases}"
      ></neb-form-reschedule-controller>
    `;
  }
}

window.customElements.define(
  'neb-overlay-edit-reschedule-appointment',
  NebOverlayEditRescheduleAppointment,
);
