import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'appointments' });

const BASE_URL = 'calendar-summary';

export const getCalendarSummary = async ({
  type,
  start,
  end,
  providerIds,
  resourceIds,
  locationIds,
  includeSplits = false,
  status,
  signal,
}) => {
  const res = await apiClient.makeRequest({
    method: Method.POST,
    path: BASE_URL,
    queryParams: {
      start,
      end,
      type: type.toLowerCase(),
      status,
      includeSplits,
    },
    body: JSON.stringify({ providerIds, locationIds, resourceIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    signal,
  });

  return res;
};

export const getCalendarSummaryCondensed = async ({
  type,
  start,
  end,
  providerIds,
  resourceIds,
  locationIds,
  status,
  signal,
}) => {
  const res = await apiClient.makeRequest({
    method: Method.POST,
    path: `${BASE_URL}/condensed`,
    queryParams: { start, end, type: type.toLowerCase(), status },
    body: JSON.stringify({ providerIds, locationIds, resourceIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    signal,
  });

  return res && res.data[0];
};

export const getCalendarSummaryIntegrated = async ({
  start,
  end,
  providerId,
  resourceId,
  locationId,
  signal,
  colorView,
}) => {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/integrated`,
    queryParams: { start, end, providerId, locationId, resourceId, colorView },
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    signal,
  });

  return res && res.data;
};
