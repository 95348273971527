import '../../neb-tooltip';
import '../../neb-radio-button';
import '../../field-groups/neb-address';
import '../../inputs/neb-select';
import '../../inputs/neb-textfield';
import '../../controls/neb-button-action';

import { isRequired, isPhoneNumber } from '@neb/form-validators';
import { LitElement, html, css } from 'lit';

import { baseStyles, layoutStyles } from '../../../../../../src/styles';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import {
  SERVICE_FACILITY_ADDRESS_TYPE,
  SERVICE_FACILITY_NPI_SOURCE,
} from '../../../../../neb-utils/claims';
import {
  IDENTIFIERS,
  FEDERAL_TAX_ID_SOURCE,
  FEDERAL_TAX_ID_TYPE,
} from '../../../../../neb-utils/enums';
import { phone, taxId, ssn } from '../../../../../neb-utils/masks';
import {
  BLANK_IDENTIFIER,
  BLANK_PROVIDER_ADDRESS,
} from '../../../../../neb-utils/payer-plan-util';
import * as selectors from '../../../../../neb-utils/selectors';
import { isZipCode } from '../../../../../neb-utils/validators';

const ADDRESS_HELPERS = {
  address1: 'Required',
  address2: '',
  city: 'Required',
  state: 'Required',
  zipcode: 'Required',
};

export const NPI_LABELS = {
  'Organization NPI': 'Practice Organization NPI',
  'Provider NPI': 'Provider Individual NPI',
  'Provider Organization NPI': 'Provider Organization NPI',
};

const ALLOWED_PROVIDER_IDENTIFIER_TYPES = [
  IDENTIFIERS['0B'],
  IDENTIFIERS['1B'],
  IDENTIFIERS['1C'],
  IDENTIFIERS['1D'],
  IDENTIFIERS['1G'],
  IDENTIFIERS.EI,
  IDENTIFIERS.G2,
  IDENTIFIERS.LU,
  IDENTIFIERS.ZZ,
];

const ALLOWED_SERVICE_FACILITY_IDENTIFIER_TYPES = [
  IDENTIFIERS['0B'],
  IDENTIFIERS.EI,
  IDENTIFIERS.G2,
  IDENTIFIERS.LU,
];

const ALLOWED_BILLING_PROVIDER_IDENTIFIER_TYPES = [
  IDENTIFIERS['0B'],
  IDENTIFIERS.EI,
  IDENTIFIERS.G2,
  IDENTIFIERS.ZZ,
];

const EMPTY_ITEM = { data: '', label: '' };
const EMPTY_LABEL = { label: '', data: { id: '' } };

export const ELEMENTS = {
  providerOtherIdentifier: {
    id: 'provider-other-identifier',
    label: 'Rendering Provider (FL24IJ Shaded)',
  },
  tooltipProviderOtherIdentifier: {
    id: 'tooltip-provider-other-identifier',
    tip:
      'The selected value will populate in F24 I & J on paper forms as other ID in shaded area.',
  },
  providerOtherTaxId: {
    id: 'provider-other-tax-id',
    label: 'Tax ID (FL25)',
  },
  tooltipProviderOtherTaxId: {
    id: 'tooltip-provider-other-tax-id',
    tip: 'The selected value will populate FL25 on the claim.',
  },
  providerOtherTaxIdNumber: {
    id: 'provider-other-tax-id-number',
    label: 'Tax ID',
  },
  providerOtherTaxIdType: {
    id: 'provider-other-tax-id-type',
  },
  serviceFacilityLocation: {
    id: 'service-facility-location',
    label: 'Service Facility Location Information (FL32)',
    items: [
      SERVICE_FACILITY_ADDRESS_TYPE.PRACTICE_ADDRESS,
      SERVICE_FACILITY_ADDRESS_TYPE.BILLING_ADDRESS,
      SERVICE_FACILITY_ADDRESS_TYPE.PAY_TO_ADDRESS,
      SERVICE_FACILITY_ADDRESS_TYPE.OTHER,
    ].map(item => ({ data: { id: item }, label: item })),
  },
  tooltipServiceFacilityLocation: {
    id: 'tooltip-service-facility-location',
    tip: 'The selected value will populate in FL32 on the claim.',
  },
  otherServiceFacilityName: {
    id: 'other-service-facility-name',
  },
  otherServiceFacilityAddressForm: {
    id: 'other-service-facility-address-form',
  },
  otherServiceFacilityPhone: {
    id: 'other-service-facility-phone',
  },
  serviceFacilityNPISource: {
    id: 'service-facility-npi-source',
    label: 'Service Facility NPI# (FL32a)',
  },
  billingProviderNPISource: {
    id: 'billing-provider-npi-source',
    label: 'Organization or Individual NPI# (FL33a)',
  },
  serviceFacilityOtherIdentifier: {
    id: 'service-facility-other-identifier',
    label: 'Service Facility Other ID/Qualifier (FL32b)',
  },
  tooltipServiceFacilityOtherIdentifier: {
    id: 'tooltip-service-facility-other-identifier',
    tip:
      'This value will populate in FL 32b, and you can only chose from 0B, EI, G2, or LU.',
  },
  billingProviderLocation: {
    id: 'billing-provider-location',
    label: 'Billing Provider Address (FL33)',
    items: [
      SERVICE_FACILITY_ADDRESS_TYPE.PRACTICE_ADDRESS,
      SERVICE_FACILITY_ADDRESS_TYPE.BILLING_ADDRESS,
      SERVICE_FACILITY_ADDRESS_TYPE.PAY_TO_ADDRESS,
      SERVICE_FACILITY_ADDRESS_TYPE.OTHER,
    ].map(item => ({ data: { id: item }, label: item })),
  },
  tooltipBillingProviderLocation: {
    id: 'tooltip-billing-provider-location',
    tip:
      'The selected value will populate in FL33 on paper forms and as Billing Provider info for electronic claims.',
  },
  otherProviderAddressName: {
    id: 'other-provider-address-name',
  },
  otherProviderAddressForm: {
    id: 'other-provider-address-form',
  },
  otherProviderAddressPhone: {
    id: 'other-provider-address-phone',
  },
  billingProviderOtherIdentifier: {
    id: 'billing-provider-other-identifier',
    label: 'Billing Provider Other ID/Qualifier (FL33b)',
  },
  tooltipBillingProviderOtherIdentifier: {
    id: 'tooltip-billing-provider-other-identifier',
    tip:
      'This value will populate in FL 33b and is used to report the Non-NPI ID number of the billing provider and is the payer assigned unique identifier of the professional.',
  },
  locations: {
    id: 'locations',
    label: 'Locations',
  },
  removeButton: {
    id: 'remove-button',
  },
};

export const genProviderSpecificValues = () => ({
  locations: [],
  federalTaxIdSource: '',
  federalTaxIdProviderIdentifierId: '',
  federalTaxIdOtherNumber: '',
  federalTaxIdOtherType: '',
  serviceFacilityLocation: {
    data: { id: '' },
    label: '',
  },
  serviceFacilityNPISource: '',
  billingProviderNPISource: '',
  billingProviderLocation: {
    data: { id: '' },
    label: '',
  },
  billingProviderAddress: { ...BLANK_PROVIDER_ADDRESS },
  serviceFacilityAddress: { ...BLANK_PROVIDER_ADDRESS },
  providerOtherIdentifierId: '',
  serviceFacilityOtherIdentifierId: '',
  billingProviderOtherIdentifierId: '',
});

export default class NebProviderSpecificValuesFields extends LitElement {
  static get properties() {
    return {
      activeServiceLocations: Array,
      activeLocations: Array,
      billingInfo: Object,
      errors: Object,
      itemIndex: Number,
      model: Object,
      providerIndex: Number,
      providerOtherTaxIds: Array,
      selectedRenderingProvider: Object,
      selectedRenderingProviderIdentifiers: Array,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .grid-tooltip {
          display: grid;
          grid-gap: 12px ${CSS_SPACING};
          grid-template-columns: 1fr auto;
          align-items: center;
        }

        .grid-tax-id,
        .grid-locations {
          display: grid;
          grid-gap: 12px ${CSS_SPACING};
          grid-template-columns: 1fr auto;
          align-items: left;
        }

        .grid-tax-id-radio-buttons {
          display: flex;
          padding-top: 15px;
        }

        .grid-tooltip-space {
          padding-right: 40px;
        }

        .padding-left,
        .row-padding {
          padding-left: ${CSS_SPACING};
        }

        .add-provider-button-padding {
          padding: 0 0 0 ${CSS_SPACING};
        }

        .remove-provider-button-padding {
          padding: 0 0 ${CSS_SPACING};
        }

        .locations-select {
          margin-right: ${CSS_SPACING};
        }
      `,
    ];
  }

  static createModel(providerId) {
    return {
      providerId,
      items: [genProviderSpecificValues()],
    };
  }

  static createSelectors(locations) {
    return {
      items: {
        createItem: () => genProviderSpecificValues(),
        children: {
          $: {
            children: {
              locations: selectors.multiSelect(locations, locations, {
                validators: [isRequired()],
              }),
              federalTaxIdOtherNumber: {
                validators: [
                  {
                    error: 'Must be 9 digits',
                    validate: v =>
                      v.replace(/-/g, '').length === 9 || !v.length,
                  },
                  {
                    error: 'Required',
                    validate: (v, keyPath, state) => {
                      const index = keyPath[1];
                      const itemIndex = keyPath[3];
                      return (
                        (state.providerSpecificValues[index] &&
                          state.providerSpecificValues[index].items[itemIndex]
                            .federalTaxIdSource !==
                            FEDERAL_TAX_ID_SOURCE.OTHER) ||
                        v.length
                      );
                    },
                  },
                ],
              },
              serviceFacilityLocation: selectors.select([
                ...locations,
                ...ELEMENTS.serviceFacilityLocation.items,
              ]),
              serviceFacilityAddress: {
                children: {
                  name: {
                    validators: [
                      {
                        error: 'Required',
                        validate: (v, keyPath, state) => {
                          const index = keyPath[1];
                          const itemIndex = keyPath[3];

                          return (
                            (state.providerSpecificValues[index] &&
                              state.providerSpecificValues[index].items[
                                itemIndex
                              ].serviceFacilityLocation.label !==
                                SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                            v !== ''
                          );
                        },
                      },
                    ],
                  },
                  phone: [
                    isPhoneNumber('(###) ###-####'),
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const index = keyPath[1];
                        const itemIndex = keyPath[3];
                        return (
                          (state.providerSpecificValues[index] &&
                            state.providerSpecificValues[index].items[itemIndex]
                              .serviceFacilityLocation.label !==
                              SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                          v !== ''
                        );
                      },
                    },
                  ],
                  address: {
                    children: {
                      address1: {
                        validators: [
                          {
                            error: 'Required',
                            validate: (v, keyPath, state) => {
                              const index = keyPath[1];
                              const itemIndex = keyPath[3];
                              return (
                                (state.providerSpecificValues[index] &&
                                  state.providerSpecificValues[index].items[
                                    itemIndex
                                  ].serviceFacilityLocation.label !==
                                    SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                                v !== ''
                              );
                            },
                          },
                        ],
                      },
                      city: {
                        validators: [
                          {
                            error: 'Required',
                            validate: (v, keyPath, state) => {
                              const index = keyPath[1];
                              const itemIndex = keyPath[3];
                              return (
                                (state.providerSpecificValues[index] &&
                                  state.providerSpecificValues[index].items[
                                    itemIndex
                                  ].serviceFacilityLocation.label !==
                                    SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                                v !== ''
                              );
                            },
                          },
                        ],
                      },
                      state: {
                        validators: [
                          {
                            error: 'Required',
                            validate: (v, keyPath, state) => {
                              const index = keyPath[1];
                              const itemIndex = keyPath[3];
                              return (
                                (state.providerSpecificValues[index] &&
                                  state.providerSpecificValues[index].items[
                                    itemIndex
                                  ].serviceFacilityLocation.label !==
                                    SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                                v !== ''
                              );
                            },
                          },
                        ],
                      },
                      zipcode: [
                        isZipCode,
                        {
                          error: 'Required',
                          validate: (v, keyPath, state) => {
                            const index = keyPath[1];
                            const itemIndex = keyPath[3];
                            return (
                              (state.providerSpecificValues[index] &&
                                state.providerSpecificValues[index].items[
                                  itemIndex
                                ].serviceFacilityLocation.label !==
                                  SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                              v !== ''
                            );
                          },
                        },
                      ],
                    },
                  },
                },
              },
              billingProviderLocation: selectors.select([
                ...locations,
                ...ELEMENTS.billingProviderLocation.items,
              ]),
              billingProviderAddress: {
                children: {
                  name: {
                    validators: [
                      {
                        error: 'Required',
                        validate: (v, keyPath, state) => {
                          const index = keyPath[1];
                          const itemIndex = keyPath[3];
                          return (
                            (state.providerSpecificValues[index] &&
                              state.providerSpecificValues[index].items[
                                itemIndex
                              ].billingProviderLocation.label !==
                                SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                            v !== ''
                          );
                        },
                      },
                    ],
                  },
                  phone: [
                    isPhoneNumber('(###) ###-####'),
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const index = keyPath[1];
                        const itemIndex = keyPath[3];
                        return (
                          (state.providerSpecificValues[index] &&
                            state.providerSpecificValues[index].items[itemIndex]
                              .billingProviderLocation.label !==
                              SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                          v !== ''
                        );
                      },
                    },
                  ],
                  address: {
                    children: {
                      address1: {
                        validators: [
                          {
                            error: 'Required',
                            validate: (v, keyPath, state) => {
                              const index = keyPath[1];
                              const itemIndex = keyPath[3];
                              return (
                                (state.providerSpecificValues[index] &&
                                  state.providerSpecificValues[index].items[
                                    itemIndex
                                  ].billingProviderLocation.label !==
                                    SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                                v !== ''
                              );
                            },
                          },
                        ],
                      },
                      city: {
                        validators: [
                          {
                            error: 'Required',
                            validate: (v, keyPath, state) => {
                              const index = keyPath[1];
                              const itemIndex = keyPath[3];
                              return (
                                (state.providerSpecificValues[index] &&
                                  state.providerSpecificValues[index].items[
                                    itemIndex
                                  ].billingProviderLocation.label !==
                                    SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                                v !== ''
                              );
                            },
                          },
                        ],
                      },
                      state: {
                        validators: [
                          {
                            error: 'Required',
                            validate: (v, keyPath, state) => {
                              const index = keyPath[1];
                              const itemIndex = keyPath[3];
                              return (
                                (state.providerSpecificValues[index] &&
                                  state.providerSpecificValues[index].items[
                                    itemIndex
                                  ].billingProviderLocation.label !==
                                    SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                                v !== ''
                              );
                            },
                          },
                        ],
                      },
                      zipcode: [
                        isZipCode,
                        {
                          error: 'Required',
                          validate: (v, keyPath, state) => {
                            const index = keyPath[1];
                            const itemIndex = keyPath[3];
                            return (
                              (state.providerSpecificValues[index] &&
                                state.providerSpecificValues[index].items[
                                  itemIndex
                                ].billingProviderLocation.label !==
                                  SERVICE_FACILITY_ADDRESS_TYPE.OTHER) ||
                              v !== ''
                            );
                          },
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.activeServiceLocations = [];
    this.activeLocations = [];
    this.billingInfo = {};
    this.errors = {};
    this.itemIndex = 0;
    this.model = {};
    this.providerIndex = 0;
    this.providerOtherTaxIds = [];
    this.selectedRenderingProvider = {};
    this.selectedRenderingProviderIdentifiers = [];

    this.onRemove = () => {};

    this.providerSpecificValuesChanged = () => {};

    this.providerOtherTaxIdTypeChanged = () => {};

    this.providerSpecificTaxIdValueChanged = () => {};

    this.providerSpecificIdentifierChanged = () => {};

    this.locationChanged = () => {};

    this.providerSpecificNPIChanged = () => {};
  }

  __initHandlers() {
    this.handlers = {
      remove: e => this.onRemove(e),
      providerSpecificValuesChanged: e => this.providerSpecificValuesChanged(e),
      providerOtherTaxIdTypeChanged: e => this.providerOtherTaxIdTypeChanged(e),
      providerSpecificTaxIdValueChanged: e =>
        this.providerSpecificTaxIdValueChanged(e),
      providerSpecificIdentifierChanged: e =>
        this.providerSpecificIdentifierChanged(e),
      locationChanged: e => this.locationChanged(e),
      providerSpecificNPIChanged: e => this.providerSpecificNPIChanged(e),
    };
  }

  __renderRemoveButton(index) {
    return html`
      <neb-button-action
        id="${ELEMENTS.removeButton.id}"
        name="${index}.removeButton"
        class="remove-provider-button-padding limit-width remove-button"
        label="Remove Provider Values"
        leadingIcon="minus"
        .onClick="${this.handlers.remove}"
      ></neb-button-action>
    `;
  }

  __renderProviderOtherTaxId(providerIndex, itemIndex) {
    return this.model.items[itemIndex].federalTaxIdSource ===
      FEDERAL_TAX_ID_SOURCE.OTHER
      ? html`
          <div class="grid-tax-id">
            <neb-textfield
              id="${ELEMENTS.providerOtherTaxIdNumber.id}"
              name="${providerIndex}.items.${itemIndex}.federalTaxIdOtherNumber"
              label="Tax ID"
              helper="Required"
              maxLength="11"
              .mask="${
                this.model.items[itemIndex].federalTaxIdOtherType !==
                FEDERAL_TAX_ID_TYPE.EIN
                  ? ssn
                  : taxId
              }"
              .value="${this.model.items[itemIndex].federalTaxIdOtherNumber}"
              .error="${this.errors.items[itemIndex].federalTaxIdOtherNumber}"
              .onChange="${this.handlers.providerSpecificValuesChanged}"
            ></neb-textfield>

            <div class="grid-tax-id-radio-buttons">
              <neb-radio-button
                id="${ELEMENTS.providerOtherTaxIdType.id}"
                name="${providerIndex}.items.${itemIndex}.federalTaxIdOtherType"
                label="SSN"
                value="${FEDERAL_TAX_ID_TYPE.SSN}"
                .checked="${
                  this.model.items[itemIndex].federalTaxIdOtherType !==
                    FEDERAL_TAX_ID_TYPE.EIN
                }"
                .onChange="${this.handlers.providerOtherTaxIdTypeChanged}"
              ></neb-radio-button>

              <neb-radio-button
                id="${ELEMENTS.providerOtherTaxIdType.id}"
                name="${providerIndex}.items.${itemIndex}.federalTaxIdOtherType"
                label="EIN"
                value="${FEDERAL_TAX_ID_TYPE.EIN}"
                .checked="${
                  this.model.items[itemIndex].federalTaxIdOtherType ===
                    FEDERAL_TAX_ID_TYPE.EIN
                }"
                .onChange="${this.handlers.providerOtherTaxIdTypeChanged}"
              ></neb-radio-button>
            </div>
          </div>
        `
      : html`
          <div></div>
        `;
  }

  __renderOtherServiceLocation(providerIndex, itemIndex) {
    return this.model.items[itemIndex].serviceFacilityLocation.label === 'Other'
      ? html`
          <neb-textfield
            id="${ELEMENTS.otherServiceFacilityName.id}"
            class="span"
            label="Facility Name"
            name="${providerIndex}.items.${
              itemIndex
            }.serviceFacilityAddress.name"
            maxLength="50"
            helper="Required"
            .value="${this.model.items[itemIndex].serviceFacilityAddress.name}"
            .error="${this.errors.items[itemIndex].serviceFacilityAddress.name}"
            .onChange="${this.handlers.providerSpecificValuesChanged}"
          ></neb-textfield>

          <neb-address
            id="${ELEMENTS.otherServiceFacilityAddressForm.id}"
            class="span"
            name="${providerIndex}.items.${
              itemIndex
            }.serviceFacilityAddress.address"
            .model="${
              this.model.items[itemIndex].serviceFacilityAddress.address
            }"
            .errors="${
              this.errors.items[itemIndex].serviceFacilityAddress.address
            }"
            .helpers="${ADDRESS_HELPERS}"
            .onChange="${this.handlers.providerSpecificValuesChanged}"
          ></neb-address>

          <neb-textfield
            id="${ELEMENTS.otherServiceFacilityPhone.id}"
            label="Phone"
            name="${providerIndex}.items.${
              itemIndex
            }.serviceFacilityAddress.phone"
            .inputMode="${'numeric'}"
            maxLength="14"
            helper="Required"
            .mask="${phone}"
            .value="${this.model.items[itemIndex].serviceFacilityAddress.phone}"
            .error="${
              this.errors.items[itemIndex].serviceFacilityAddress.phone
            }"
            .onChange="${this.handlers.providerSpecificValuesChanged}"
          ></neb-textfield>

          <div></div>
        `
      : '';
  }

  __renderLocationsDropdown(providerIndex, itemIndex) {
    const filteredLocationItems = this.__getFilteredLocationItems(itemIndex);

    return html`
      <div class="grid-tooltip">
        <neb-select
          id="${ELEMENTS.locations.id}"
          class="grid-locations"
          helper="Required"
          name="${providerIndex}.items.${itemIndex}.locations"
          label="${ELEMENTS.locations.label}"
          .items="${filteredLocationItems}"
          .value="${this.model.items[itemIndex].locations}"
          .error="${this.errors.items[itemIndex].locations}"
          .onChange="${this.handlers.providerSpecificValuesChanged}"
          multiSelect
        ></neb-select>
      </div>
      <div></div>
    `;
  }

  __renderProviderTaxIdDropdown(providerIndex, itemIndex) {
    return html`
      <div class="grid-tooltip">
        <neb-select
          id="${ELEMENTS.providerOtherTaxId.id}"
          helper=" "
          name="${providerIndex}.items.${itemIndex}.federalTaxId"
          label="${ELEMENTS.providerOtherTaxId.label}"
          .items="${this.providerOtherTaxIds}"
          .value="${
            this.providerOtherTaxIds.find(
              x =>
                x.federalTaxIdSource ===
                this.model.items[itemIndex].federalTaxIdSource,
            )
          }"
          .onChange="${this.handlers.providerSpecificTaxIdValueChanged}"
        ></neb-select>

        <neb-tooltip id="${ELEMENTS.tooltipProviderOtherTaxId.id}">
          <div slot="tooltip">${ELEMENTS.tooltipProviderOtherTaxId.tip}</div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderServiceFacilityLocationDropdown(providerIndex, itemIndex) {
    const itemsServiceFacilityLocation = this.__getItemsServiceFacilityLocation();

    return html`
      <neb-select
        id="${ELEMENTS.serviceFacilityLocation.id}"
        helper=" "
        name="${providerIndex}.items.${itemIndex}.serviceFacilityLocation"
        label="${ELEMENTS.serviceFacilityLocation.label}"
        .items="${itemsServiceFacilityLocation}"
        .value="${this.model.items[itemIndex].serviceFacilityLocation}"
        .onChange="${this.handlers.locationChanged}"
      ></neb-select>
    `;
  }

  __renderBillingProviderLocationDropdown(providerIndex, itemIndex) {
    const itemsBillingProviderLocation = this.__getItemsBillingProviderLocation();

    return html`
      <neb-select
        id="${ELEMENTS.billingProviderLocation.id}"
        helper=" "
        name="${providerIndex}.items.${itemIndex}.billingProviderLocation"
        label="${ELEMENTS.billingProviderLocation.label}"
        .items="${itemsBillingProviderLocation}"
        .value="${this.model.items[itemIndex].billingProviderLocation}"
        .onChange="${this.handlers.locationChanged}"
      ></neb-select>
    `;
  }

  __renderServiceFacilityNPISourceDropdown(providerIndex, itemIndex) {
    const itemsNPISource = this.__getItemsNPISource();
    const valueServiceFacilityNPISource = this.__getValueServiceFacilityNPISource(
      itemIndex,
    );

    return html`
      <neb-select
        class="grid-tooltip-space"
        id="${ELEMENTS.serviceFacilityNPISource.id}"
        helper=" "
        name="${providerIndex}.items.${itemIndex}.serviceFacilityNPISource"
        label="${ELEMENTS.serviceFacilityNPISource.label}"
        .items="${itemsNPISource}"
        .value="${valueServiceFacilityNPISource}"
        .onChange="${this.handlers.providerSpecificNPIChanged}"
      ></neb-select>
    `;
  }

  __renderBillingProviderNPISourceDropdown(providerIndex, itemIndex) {
    const itemsNPISource = this.__getItemsNPISource();
    const valueBillingProviderNPISource = this.__getValueBillingProviderNPISource(
      itemIndex,
    );

    return html`
      <neb-select
        class="grid-tooltip-space"
        id="${ELEMENTS.billingProviderNPISource.id}"
        helper=" "
        name="${providerIndex}.items.${itemIndex}.billingProviderNPISource"
        label="${ELEMENTS.billingProviderNPISource.label}"
        .items="${itemsNPISource}"
        .value="${valueBillingProviderNPISource}"
        .onChange="${this.handlers.providerSpecificNPIChanged}"
      ></neb-select>
    `;
  }

  __getFilteredLocationItems(itemIndex) {
    const locations = this.model.items.flatMap(
      modelItem => modelItem.locations,
    );

    return this.activeServiceLocations.filter(location =>
      this.model.items[itemIndex].locations &&
      this.model.items[itemIndex].locations.find(
        locationItem => locationItem.data.id === location.data.id,
      )
        ? true
        : locations.findIndex(
            modelLocation => modelLocation.data.id === location.data.id,
          ) === -1,
    );
  }

  __getItemsNPISource() {
    const NPIs = this.__getNPIs();

    return [
      ...NPIs,
      ...this.activeServiceLocations.map(location => ({
        data: location.data.id,
        label: `${location.data.name} - ${location.data.organizationNPI || ''}`,
      })),
    ];
  }

  __getValueBillingProviderNPISource(itemIndex) {
    if (!this.model.items[itemIndex].billingProviderNPISource) {
      return EMPTY_ITEM;
    }

    if (
      Object.values(SERVICE_FACILITY_NPI_SOURCE).includes(
        this.model.items[itemIndex].billingProviderNPISource,
      )
    ) {
      return this.__getProviderNPISource(itemIndex);
    }

    const result = this.activeServiceLocations.find(
      location =>
        this.model.items[itemIndex].billingProviderNPISource ===
        location.data.id,
    );

    return {
      data: this.model.items[itemIndex].billingProviderNPISource,
      label: `${result.data.name} - ${result.data.organizationNPI || ''}`,
    };
  }

  __getValueServiceFacilityNPISource(itemIndex) {
    if (!this.model.items[itemIndex].serviceFacilityNPISource) {
      return EMPTY_ITEM;
    }

    if (
      Object.values(SERVICE_FACILITY_NPI_SOURCE).includes(
        this.model.items[itemIndex].serviceFacilityNPISource,
      )
    ) {
      return this.__getServiceNPISource(itemIndex);
    }

    const result = this.activeServiceLocations.find(
      location =>
        this.model.items[itemIndex].serviceFacilityNPISource ===
        location.data.id,
    );

    return {
      data: this.model.items[itemIndex].serviceFacilityNPISource,
      label: `${result.data.name} - ${result.data.organizationNPI || ''}`,
    };
  }

  __getItemsBillingProviderLocation() {
    return [
      EMPTY_LABEL,
      ...this.activeLocations,
      {
        label: SERVICE_FACILITY_ADDRESS_TYPE.OTHER,
        data: { id: SERVICE_FACILITY_ADDRESS_TYPE.OTHER },
      },
    ];
  }

  __getItemsServiceFacilityLocation() {
    return [
      EMPTY_LABEL,
      ...this.activeServiceLocations,
      {
        label: SERVICE_FACILITY_ADDRESS_TYPE.OTHER,
        data: { id: SERVICE_FACILITY_ADDRESS_TYPE.OTHER },
      },
    ];
  }

  __getServiceFacilityOtherIdentifiers() {
    return [
      BLANK_IDENTIFIER,
      ...this.selectedRenderingProviderIdentifiers.filter(entry =>
        ALLOWED_SERVICE_FACILITY_IDENTIFIER_TYPES.includes(entry.type),
      ),
    ];
  }

  __getBillingProviderOtherIdentifiers() {
    return [
      BLANK_IDENTIFIER,
      ...this.selectedRenderingProviderIdentifiers.filter(entry =>
        ALLOWED_BILLING_PROVIDER_IDENTIFIER_TYPES.includes(entry.type),
      ),
    ];
  }

  __getNPIs() {
    return [
      EMPTY_ITEM,
      {
        data: SERVICE_FACILITY_NPI_SOURCE.ORGANIZATION_NPI,
        label: `${
          NPI_LABELS[SERVICE_FACILITY_NPI_SOURCE.ORGANIZATION_NPI]
        } - ${this.billingInfo.organizationNPI || ''}`,
      },
      {
        data: SERVICE_FACILITY_NPI_SOURCE.PROVIDER_NPI,
        label: `${NPI_LABELS[SERVICE_FACILITY_NPI_SOURCE.PROVIDER_NPI]} - ${this
          .selectedRenderingProvider.NPI || ''}`,
      },
      {
        data: SERVICE_FACILITY_NPI_SOURCE.PROVIDER_ORGANIZATION_NPI,
        label: `${
          NPI_LABELS[SERVICE_FACILITY_NPI_SOURCE.PROVIDER_ORGANIZATION_NPI]
        } - ${this.selectedRenderingProvider.organizationNPI || ''}`,
      },
    ];
  }

  __getLabelNPI(src) {
    switch (src) {
      case SERVICE_FACILITY_NPI_SOURCE.ORGANIZATION_NPI:
        return this.billingInfo.organizationNPI;
      case SERVICE_FACILITY_NPI_SOURCE.PROVIDER_NPI:
        return this.selectedRenderingProvider.NPI;
      case SERVICE_FACILITY_NPI_SOURCE.PROVIDER_ORGANIZATION_NPI:
        return this.selectedRenderingProvider.organizationNPI;
      default:
        return '';
    }
  }

  __getServiceNPISource(index) {
    return {
      data: this.model.items[index].serviceFacilityNPISource,
      label: `${
        NPI_LABELS[this.model.items[index].serviceFacilityNPISource]
      } - ${this.__getLabelNPI(
        this.model.items[index].serviceFacilityNPISource,
      )}`,
    };
  }

  __getLocationNPISource(index) {
    return {
      data: this.model.items[index].serviceFacilityNPISource,
      label: `${
        NPI_LABELS[this.model.items[index].serviceFacilityNPISource]
      } - ${this.__getLabelNPI(
        this.model.items[index].serviceFacilityNPISource,
      )}`,
    };
  }

  __getServiceOtherId(identifiers, index) {
    return (
      identifiers.find(
        x => x.id === this.model.items[index].serviceFacilityOtherIdentifierId,
      ) || BLANK_IDENTIFIER
    );
  }

  __getProviderNPISource(index) {
    return {
      data: this.model.items[index].billingProviderNPISource,
      label: `${
        NPI_LABELS[this.model.items[index].billingProviderNPISource]
      } - ${this.__getLabelNPI(
        this.model.items[index].billingProviderNPISource,
      )}`,
    };
  }

  __getProviderOtherId(identifiers, index) {
    return (
      identifiers.find(
        x => x.id === this.model.items[index].billingProviderOtherIdentifierId,
      ) || BLANK_IDENTIFIER
    );
  }

  __renderProviderOnlyFields(providerIndex, itemIndex) {
    const serviceFacilityOtherIdentifiers = this.__getServiceFacilityOtherIdentifiers();

    const billingProviderOtherIdentifiers = this.__getBillingProviderOtherIdentifiers();

    const SERVICE_OTHER_ID = this.__getServiceOtherId(
      serviceFacilityOtherIdentifiers,
      itemIndex,
    );

    const PROVIDER_OTHER_ID = this.__getProviderOtherId(
      billingProviderOtherIdentifiers,
      itemIndex,
    );

    return html`
      ${this.__renderProviderTaxIdDropdown(providerIndex, itemIndex)}
      ${this.__renderProviderOtherTaxId(providerIndex, itemIndex)}

      <div class="grid-tooltip">
        ${
          this.__renderServiceFacilityLocationDropdown(providerIndex, itemIndex)
        }

        <neb-tooltip id="${ELEMENTS.tooltipServiceFacilityLocation.id}">
          <div slot="tooltip">
            ${ELEMENTS.tooltipServiceFacilityLocation.tip}
          </div>
        </neb-tooltip>
      </div>

      ${this.__renderServiceFacilityNPISourceDropdown(providerIndex, itemIndex)}

      <div></div>

      <div class="grid-tooltip">
        <neb-select
          id="${ELEMENTS.serviceFacilityOtherIdentifier.id}"
          helper=" "
          name="${providerIndex}.items.${
            itemIndex
          }.serviceFacilityOtherIdentifierId"
          label="${ELEMENTS.serviceFacilityOtherIdentifier.label}"
          .items="${serviceFacilityOtherIdentifiers}"
          .value="${SERVICE_OTHER_ID}"
          .onChange="${this.handlers.providerSpecificIdentifierChanged}"
        ></neb-select>

        <neb-tooltip id="${ELEMENTS.tooltipServiceFacilityOtherIdentifier.id}">
          <div slot="tooltip">
            ${ELEMENTS.tooltipServiceFacilityOtherIdentifier.tip}
          </div>
        </neb-tooltip>
      </div>

      ${this.__renderOtherServiceLocation(providerIndex, itemIndex)}

      <div class="grid-tooltip">
        ${
          this.__renderBillingProviderLocationDropdown(providerIndex, itemIndex)
        }
        <neb-tooltip id="${ELEMENTS.tooltipBillingProviderLocation.id}">
          <div slot="tooltip">
            ${ELEMENTS.tooltipBillingProviderLocation.tip}
          </div>
        </neb-tooltip>
      </div>

      ${this.__renderBillingProviderNPISourceDropdown(providerIndex, itemIndex)}

      <div></div>

      <div class="grid-tooltip">
        <neb-select
          id="${ELEMENTS.billingProviderOtherIdentifier.id}"
          helper=" "
          name="${providerIndex}.items.${
            itemIndex
          }.billingProviderOtherIdentifierId"
          label="${ELEMENTS.billingProviderOtherIdentifier.label}"
          .items="${billingProviderOtherIdentifiers}"
          .value="${PROVIDER_OTHER_ID}"
          .onChange="${this.handlers.providerSpecificIdentifierChanged}"
        ></neb-select>

        <neb-tooltip id="${ELEMENTS.tooltipBillingProviderOtherIdentifier.id}">
          <div slot="tooltip">
            ${ELEMENTS.tooltipBillingProviderOtherIdentifier.tip}
          </div>
        </neb-tooltip>
      </div>

      ${this.__renderOtherProviderLocation(providerIndex, itemIndex)}
    `;
  }

  __renderOtherProviderLocation(providerIndex, itemIndex) {
    return this.model.items[itemIndex].billingProviderLocation.label === 'Other'
      ? html`
          <neb-textfield
            id="${ELEMENTS.otherProviderAddressName.id}"
            class="span"
            label="Provider Billing Name"
            name="${providerIndex}.items.${
              itemIndex
            }.billingProviderAddress.name"
            maxLength="50"
            helper="Required"
            .value="${this.model.items[itemIndex].billingProviderAddress.name}"
            .error="${this.errors.items[itemIndex].billingProviderAddress.name}"
            .onChange="${this.handlers.providerSpecificValuesChanged}"
          ></neb-textfield>

          <neb-address
            id="${ELEMENTS.otherProviderAddressForm.id}"
            class="span"
            name="${providerIndex}.items.${
              itemIndex
            }.billingProviderAddress.address"
            .model="${
              this.model.items[itemIndex].billingProviderAddress.address
            }"
            .errors="${
              this.errors.items[itemIndex].billingProviderAddress.address
            }"
            .helpers="${ADDRESS_HELPERS}"
            .onChange="${this.handlers.providerSpecificValuesChanged}"
          ></neb-address>

          <neb-textfield
            id="${ELEMENTS.otherProviderAddressPhone.id}"
            label="Phone"
            name="${providerIndex}.items.${
              itemIndex
            }.billingProviderAddress.phone"
            .inputMode="${'numeric'}"
            maxLength="14"
            helper="Required"
            .mask="${phone}"
            .value="${this.model.items[itemIndex].billingProviderAddress.phone}"
            .error="${
              this.errors.items[itemIndex].billingProviderAddress.phone
            }"
            .onChange="${this.handlers.providerSpecificValuesChanged}"
          ></neb-textfield>

          <div></div>
        `
      : '';
  }

  render() {
    const providerOtherIdentifiers = [
      BLANK_IDENTIFIER,
      ...this.selectedRenderingProviderIdentifiers.filter(entry =>
        ALLOWED_PROVIDER_IDENTIFIER_TYPES.includes(entry.type),
      ),
    ];

    return html`
      <div class="item grid grid-2">
        ${this.__renderLocationsDropdown(this.providerIndex, this.itemIndex)}
        <div class="grid-tooltip">
          <neb-select
            id="${ELEMENTS.providerOtherIdentifier.id}"
            helper=" "
            name="${this.providerIndex}.items.${
              this.itemIndex
            }.providerOtherIdentifierId"
            label="${ELEMENTS.providerOtherIdentifier.label}"
            .items="${providerOtherIdentifiers}"
            .value="${
              providerOtherIdentifiers.find(
                x =>
                  x.id ===
                  this.model.items[this.itemIndex].providerOtherIdentifierId,
              ) || BLANK_IDENTIFIER
            }"
            .onChange="${this.handlers.providerSpecificIdentifierChanged}"
          ></neb-select>

          <neb-tooltip id="${ELEMENTS.tooltipProviderOtherIdentifier.id}">
            <div slot="tooltip">
              ${ELEMENTS.tooltipProviderOtherIdentifier.tip}
            </div>
          </neb-tooltip>
        </div>
        <div></div>
        ${this.__renderProviderOnlyFields(this.providerIndex, this.itemIndex)}
        <div></div>
        <div></div>
        ${this.__renderRemoveButton(this.itemIndex)}
        <div></div>
      </div>
    `;
  }
}

window.customElements.define(
  'neb-provider-specific-values-fields',
  NebProviderSpecificValuesFields,
);
