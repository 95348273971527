import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';

export const appointmentsApiClient = new ApiClient({
  microservice: 'appointments',
});

const BASE_URL = 'blocked-off-time';

export function createBlockedOffTime(
  appointment,
  optOutLoadingIndicator = false,
) {
  return appointmentsApiClient.makeRequest({
    method: Method.POST,
    path: BASE_URL,
    body: JSON.stringify(appointment),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    optOutLoadingIndicator,
    updateNotificationDetails: { blockedOffTime: { ...appointment } },
  });
}

export function updateBlockedOffTime(groupId, appointment, queryParams) {
  return appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${BASE_URL}/${groupId}`,
    queryParams,
    body: JSON.stringify(appointment),
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    updateNotificationDetails: { blockedOffTime: { ...appointment } },
  });
}

export function deleteBlockedOffTime(groupId) {
  return appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: `${BASE_URL}/${groupId}`,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    updateNotificationDetails: { blockedOffTime: { groupId } },
  });
}
