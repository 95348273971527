import './neb-patients-roster-page';
import '../../../../neb-lit-components/src/components/neb-action-bar';

import { LitElement, html, css } from 'lit';

export const ID_PAGE = 'id-page';
export const STATUS = 'Duplicate';
export const PAGE_SIZE = 25;
export const ID_ACTION_BAR = 'action-bar';
class NebPatientsDuplicatePage extends LitElement {
  static get properties() {
    return {
      importJobId: {
        type: Number,
      },
      totalCount: {
        type: Number,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.totalCount = 0;

    this.onCancel = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      cancel: () => this.onCancel(),
    };
  }

  static get styles() {
    return css`
      .roster-page {
        min-height: 0;
        height: 100%;
      }
    `;
  }

  render() {
    return html`
      <neb-patients-roster-page
        id="${ID_PAGE}"
        class="roster-page"
        .importStatus="${STATUS}"
        .importJobId="${this.importJobId}"
        .totalCount="${this.totalCount}"
        .pageSize="${PAGE_SIZE}"
      >
      </neb-patients-roster-page>
      ${
        this.totalCount === 0
          ? ''
          : html`
              <neb-action-bar
                id="${ID_ACTION_BAR}"
                confirmLabel=""
                .onCancel="${this.__handlers.cancel}"
              ></neb-action-bar>
            `
      }
    `;
  }
}
customElements.define('neb-patients-duplicate-page', NebPatientsDuplicatePage);
