import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

const getPath = patientId => `patients/${patientId}/provider-notes`;

export const getEmptyProviderNote = (patientId = null, updatedBy = null) => ({
  patientId,
  note: '',
  updatedBy,
});

export const billingClient = new ApiClient({
  microservice: 'billing',
  useTenant: true,
});

export const getProviderNote = async patientId => {
  const res = await billingClient.makeRequest({
    method: Method.GET,
    path: getPath(patientId),
    headers: { 'Content-Type': 'application/json' },
    version: 1,
  });

  if (res.count > 0) {
    return res.data[0];
  }
  return null;
};

export const upsertProviderNote = async providerNote => {
  const res = await billingClient.makeRequest({
    method: Method.PUT,
    path: getPath(providerNote.patientId),
    body: JSON.stringify(providerNote),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return res.data[0];
};
