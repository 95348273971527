import { LitElement, css, html } from 'lit';

import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
} from '../../styles';

export const ELEMENTS = {
  monthText: { id: 'month-text' },
  dayLabels: { selector: '.day-of-week' },
  dayCells: { selector: '.day-cell' },
};

const DAYS_OF_THE_WEEK = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

class NebRowCalendar extends LitElement {
  static get properties() {
    return {
      targetDate: Object,
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .month-header {
        display: flex;
        color: ${CSS_COLOR_HIGHLIGHT};
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        margin-bottom: ${CSS_SPACING};
        text-decoration: underline;
      }

      .week-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        font-weight: bold;
        color: ${CSS_COLOR_GREY_1};
      }

      .day-of-week {
        padding: 11px;
        font-size: ${CSS_FONT_SIZE_BODY};
        color: ${CSS_COLOR_GREY_1};
      }

      #days {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .day-cell {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-size: ${CSS_FONT_SIZE_BODY};
        width: 26px;
        height: 26px;
        border-radius: 50%;
        padding: 4px;
        margin: 6px;
        align-items: center;
        justify-content: center;
      }

      .today {
        background: ${CSS_COLOR_WHITE};
        color: ${CSS_COLOR_HIGHLIGHT};
        border: 2px solid;
        padding: 2px;
      }

      .selected {
        background: ${CSS_COLOR_HIGHLIGHT};
        color: ${CSS_COLOR_WHITE};
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.targetDate = parseDate();

    this.onLabelClicked = () => {};

    this.onSelectDate = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickMonthLabel: () => {
        this.onLabelClicked();
      },
      selectDate: date => {
        this.onSelectDate(date);
      },
    };
  }

  __renderDayNames() {
    return DAYS_OF_THE_WEEK.map(
      day => html`
        <div class="day-of-week">${day}</div>
      `,
    );
  }

  __renderDayCells() {
    const todayDate = parseDate();

    return DAYS_OF_THE_WEEK.map((day, index) => {
      const date = parseDate(this.targetDate)
        .startOf('week')
        .add(index, 'days');

      let classes = date.isSame(todayDate, 'day') ? 'today ' : '';
      classes += date.isSame(this.targetDate, 'day') ? 'selected' : '';

      return html`
        <div
          class="day-cell ${classes}"
          @click="${() => this.__handlers.selectDate(date)}"
        >
          ${date.date()}
        </div>
      `;
    });
  }

  render() {
    return html`
      <div class="month-header">
        <span
          id="${ELEMENTS.monthText.id}"
          @click="${this.__handlers.clickMonthLabel}"
        >
          ${this.targetDate.format('MMMM')} ${this.targetDate.year()}
        </span>
      </div>

      <div class="week-header">${this.__renderDayNames()}</div>
      <div id="days">${this.__renderDayCells()}</div>
    `;
  }
}

customElements.define('neb-row-calendar', NebRowCalendar);
