import '../../neb-lit-components/src/components/neb-tooltip';
import '../../neb-lit-components/src/components/inputs/neb-textarea';
import '../../neb-lit-components/src/components/inputs/neb-select';

import { html, css } from 'lit';

import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { CSS_FONT_FAMILY } from '../../neb-styles/neb-variables';
import {
  checkUnchangeableEclaimStatus,
  filterStatusAvailableToChangeFromTo,
} from '../../neb-utils/claims';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  confirmButton: { id: 'add' },
  cancelButton: { id: 'cancel' },
  closeButton: { id: 'close' },
  statusDropdown: { id: 'status-dropdown' },
  statusDropdownElectronic: { id: 'status-dropdown-electronic' },
  paperTextBox: { id: 'paper-text-box' },
  electronicTextBox: { id: 'electronic-text-box' },
  notChangeTextBox: { id: 'not-change-text-box' },
};

class NebPopupChangePaperClaimStatus extends NebPopup {
  static get properties() {
    return {
      __statusPaper: Object,
      __statusElectronic: Object,
    };
  }

  initState() {
    super.initState();
    this.title = 'Change Claim Status';
    this.claimsCanNotBeChanged = [];
    this.showCancelButton = true;
    this.__statusPaper = '';
    this.__statusElectronic = '';
    this.model = {
      paperClaims: [],
      eClaims: [],
    };

    this.__errors = {
      statusPaper: false,
      statusElectronic: false,
    };
  }

  initHandlers() {
    super.initHandlers();
    this.claimsCanNotBeChanged = [];
    this.handlers = {
      confirm: () => {
        if (this.__validate()) {
          this.onClose({
            paper: {
              status: this.__statusPaper,
              claims: this.model.paperClaims,
            },
            electronic: {
              status: this.__statusElectronic,
              claims: this.model.eClaims,
            },
            claimsCanNotBeChanged: {
              claimNumber: this.claimsCanNotBeChanged.map(
                claim => claim.claimNumber,
              ),
            },
          });
        }
      },
      cancel: () => this.onClose(false),
      changeStatusElectronic: ({ event, value }) => {
        if (event === 'select') {
          this.__statusElectronic = value;
          this.__errors.statusElectronic = false;
        }
      },
      changeStatus: ({ event, value }) => {
        if (event === 'select') {
          this.__statusPaper = value;
          this.__errors.statusPaper = false;
        }
      },
    };
  }

  __validate() {
    this.__errors.statusElectronic =
      this.model.eClaims.length > 0 && this.__statusElectronic === '';

    this.__errors.statusPaper =
      this.model.paperClaims.length > 0 && this.__statusPaper === '';

    this.requestUpdate();

    const invalid = this.__errors.statusElectronic || this.__errors.statusPaper;

    return !invalid;
  }

  __checkIfHasItemsToChange() {
    if (
      this.model.paperClaims.length === 0 &&
      this.model.eClaims.length === 0 &&
      this.claimsCanNotBeChanged.length > 0
    ) {
      this.onClose({
        claimsCanNotBeChanged: {
          claimNumber: this.claimsCanNotBeChanged.map(
            claim => claim.claimNumber,
          ),
        },
      });
    }
  }

  __updateModelClaims() {
    const eClaimsCanNotBeChanged = this.model.eClaims.filter(claim =>
      checkUnchangeableEclaimStatus(claim.status),
    );

    this.claimsCanNotBeChanged = [
      ...eClaimsCanNotBeChanged,
      ...this.claimsCanNotBeChanged,
    ];

    this.model.eClaims = this.model.eClaims.filter(
      claim => !this.claimsCanNotBeChanged.includes(claim),
    );
  }

  __selectClaims(isElectronic) {
    let includesCHCPayerId = false;

    const selectedClaims = isElectronic
      ? this.model.eClaims
      : this.model.paperClaims;

    const filteredSelectedClaims = selectedClaims.map(claim => {
      if (claim.chcPayerId) {
        includesCHCPayerId = true;
      }
      return claim.status;
    });
    const uniqueSelectedClaimStatuses = [...new Set(filteredSelectedClaims)];

    return { uniqueSelectedClaimStatuses, includesCHCPayerId };
  }

  __statusItems(isElectronic) {
    const {
      uniqueSelectedClaimStatuses,
      includesCHCPayerId,
    } = this.__selectClaims(isElectronic);

    return filterStatusAvailableToChangeFromTo(
      isElectronic,
      uniqueSelectedClaimStatuses,
      includesCHCPayerId,
    );
  }

  __getLabelTextBox(claimType) {
    return `The status of these ${claimType} claims will be changed:`;
  }

  __claimNumbers(claims) {
    return claims.map(claim => claim.claimNumber).join(', ');
  }

  __getPaperClaimNumbers() {
    return this.__claimNumbers(this.model.paperClaims);
  }

  __getEClaimNumbers() {
    return this.__claimNumbers(this.model.eClaims);
  }

  __getNotChangeClaimNumbers() {
    return this.__claimNumbers(this.claimsCanNotBeChanged);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          min-width: 525px;
          font-family: ${CSS_FONT_FAMILY};
        }

        .add-button {
          padding-right: 10px;
        }

        .buttons-container {
          margin-top: 30px;
          display: flex;
        }

        .container {
          display: flex;
          flex-direction: column;
        }

        .e-notes {
          display: flex;
          margin-bottom: 10px;
        }

        .paper-notes {
          display: flex;
          margin-bottom: 25px;
        }

        .status {
          margin-bottom: 25px;
        }

        #status-dropdown,
        #status-dropdown-electronic {
          width: 250px;
        }
      `,
    ];
  }

  renderPaperTextBox() {
    const label = this.__getLabelTextBox('paper');
    return this.model.paperClaims.length
      ? html`
          <neb-textarea
            id="${ELEMENTS.paperTextBox.id}"
            class="e-notes"
            label="${label}"
            .value="${this.__getPaperClaimNumbers()}"
            readonly
          ></neb-textarea>
        `
      : '';
  }

  renderElectronicTextBox() {
    const label = this.__getLabelTextBox('electronic');
    return this.model.eClaims.length
      ? html`
          <neb-textarea
            id="${ELEMENTS.electronicTextBox.id}"
            class="e-notes"
            label="${label}"
            .value="${this.__getEClaimNumbers()}"
            readonly
          ></neb-textarea>
        `
      : '';
  }

  renderNotChangeTextBox() {
    return this.claimsCanNotBeChanged.length
      ? html`
          <neb-textarea
            id="${ELEMENTS.notChangeTextBox.id}"
            class="not-change-notes"
            label="The status of these claims will not change:"
            .value="${this.__getNotChangeClaimNumbers()}"
            readonly
          ></neb-textarea>
        `
      : '';
  }

  renderStatusDropdown() {
    return this.model.paperClaims.length
      ? html`
          <neb-select
            id="${ELEMENTS.statusDropdown.id}"
            class="status"
            label="Status"
            .items="${this.__statusItems(false)}"
            .value="${this.__statusPaper}"
            .onChange="${this.handlers.changeStatus}"
            .error="${this.__errors.statusPaper}"
            ?invalid="${this.__errors.statusPaper}"
          ></neb-select>
        `
      : '';
  }

  renderStatusDropdownEclaim() {
    return this.model.eClaims.length
      ? html`
          <neb-select
            id="${ELEMENTS.statusDropdownElectronic.id}"
            class="status"
            label="Status"
            .items="${this.__statusItems(true)}"
            .value="${this.__statusElectronic}"
            .onChange="${this.handlers.changeStatusElectronic}"
            .error="${this.__errors.statusElectronic}"
            ?invalid="${this.__errors.statusElectronic}"
          ></neb-select>
        `
      : '';
  }

  renderButtons() {
    return html`
      <div class="buttons-container">
        <neb-button
          id="${ELEMENTS.confirmButton.id}"
          class="add-button"
          label="Ok"
          .onClick="${this.handlers.confirm}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.cancelButton.id}"
          role="${BUTTON_ROLE.CANCEL}"
          label="Cancel"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
      </div>
    `;
  }

  renderContent() {
    this.__updateModelClaims();
    this.__checkIfHasItemsToChange();

    return html`
      ${this.renderPaperTextBox()} ${this.renderStatusDropdown()}
      ${this.renderElectronicTextBox()} ${this.renderStatusDropdownEclaim()}
      ${this.renderNotChangeTextBox()} ${this.renderButtons()}
    `;
  }
}

customElements.define(
  'neb-popup-change-paper-claim-status',
  NebPopupChangePaperClaimStatus,
);
