import './neb-ripple-button';
import '../../../../src/components/misc/neb-icon';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_COLOR_DISABLED,
} from '../../../neb-styles/neb-variables';

const ANCHORS = ['top', 'bottom', 'left', 'right'];
export const ELEMENTS = {
  blocker: {
    id: 'blocker',
  },
  popover: {
    id: 'tooltip-data',
  },
  icon: {
    id: 'icon',
  },
};

const TOOLTIP_SPACING = 20;

class NebTooltip extends LitElement {
  static get properties() {
    return {
      __showTip: Boolean,
      __responsiveAnchor: String,
      __focus: {
        reflect: true,
        type: Boolean,
        attribute: 'focus',
      },
      icon: {
        type: String,
      },
      defaultAnchor: String,
      small: {
        reflect: true,
        type: Boolean,
      },
      large: {
        reflect: true,
        type: Boolean,
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      iconWidth: { type: Number },
      iconHeight: { type: Number },
      iconColor: {
        type: String,
      },
      popoverMarginTop: { type: Number },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__showTip = false;
    this.__responsiveAnchor = 'right';
    this.disabled = false;
    this.defaultAnchor = 'right';
    this.icon = 'neb:help';
    this.iconWidth = 24;
    this.iconHeight = 24;
    this.iconColor = '';
    this.popoverMarginTop = 0;
  }

  __initHandlers() {
    this.__handlers = {
      focus: () => {
        this.__focus = true;
      },
      blur: () => {
        this.__focus = false;
      },
      show: e => {
        e.stopPropagation();
        if (!this.disabled) this.__showTip = true;
      },
      hide: e => {
        e.stopPropagation();
        this.__showTip = false;
      },
      toggle: e => {
        e.stopPropagation();
        if (this.disabled) return;

        if (e.keyCode === 13 || e.keyCode === 32) {
          this.__showTip = !this.__showTip;
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keypress', this.__handlers.toggle);
    this.addEventListener('focus', this.__handlers.focus);
    this.addEventListener('blur', this.__handlers.blur);
    this.setAttribute('tabindex', 0);
  }

  updated(changedProps) {
    if (changedProps.has('__showTip') || changedProps.has('defaultAnchor')) {
      this.__calcResponsiveAnchor();
    }
  }

  __calcResponsiveAnchor() {
    this.__responsiveAnchor = this.defaultAnchor;

    if (this.__showTip) {
      const popoverEl = this.shadowRoot.getElementById(ELEMENTS.popover.id);
      const {
        right,
        width,
        bottom,
        height,
      } = popoverEl.getBoundingClientRect();

      if (
        this.defaultAnchor === 'right' &&
        right + TOOLTIP_SPACING > window.innerWidth
      ) {
        if (right - width / 2 < window.innerWidth) {
          this.__responsiveAnchor = 'bottom';
        } else this.__responsiveAnchor = 'left';
      }

      if (bottom > window.innerHeight && bottom - height > height) {
        this.__responsiveAnchor = 'top';
      }
    }
  }

  __getAnchor() {
    return ANCHORS.includes(this.__responsiveAnchor)
      ? this.__responsiveAnchor
      : 'right';
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          outline: none;
          display: block;
        }

        :host([disabled]) {
          pointer-events: none;
        }

        :host([disabled]) .icon {
          color: ${CSS_COLOR_DISABLED};
        }

        .container {
          position: relative;
          width: 20px;
          height: 20px;
        }

        .blocker {
          position: fixed;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: transparent;
          z-index: 1;
        }

        .icon {
          cursor: pointer;
          outline: none;
          position: relative;

          font-size: ${CSS_FONT_SIZE_BODY};
          color: ${CSS_COLOR_GREY_1};
          fill: ${CSS_COLOR_GREY_1};
        }

        :host([focus]) .icon {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .popover {
          z-index: 2;
          min-width: 240px;
          position: absolute;
          background: white;
          box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.14);
          padding: ${CSS_SPACING};
        }

        .popover[direction='right'] {
          left: 100%;
          top: 50%;
          transform: translateY(-50%);
          margin-left: 10px;
        }

        .popover[direction='left'] {
          right: 100%;
          top: 50%;
          transform: translateY(-50%);
          margin-right: 10px;
        }

        .popover[direction='top'] {
          bottom: 100%;
          right: 50%;
          transform: translateX(50%);
          margin-bottom: 10px;
        }

        .popover[direction='bottom'] {
          top: 100%;
          right: 50%;
          transform: translateX(50%);
          margin-top: 10px;
        }

        :host([small]) .popover {
          position: fixed;
          width: 80vw;
          margin: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        :host([large]) .popover {
          z-index: 2;
          min-width: 240px;
          width: max-content;
          position: absolute;
          background: white;
          box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.14);
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  __renderPopover() {
    return this.__showTip
      ? html`
          <div
            id="${ELEMENTS.popover.id}"
            class="popover"
            direction="${this.__getAnchor()}"
            @click="${this.__handlers.hide}"
            style="margin-top:${this.popoverMarginTop}px"
          >
            <slot name="tooltip"></slot>
          </div>
        `
      : '';
  }

  __renderBlocker() {
    return html`
      ${
        this.__showTip
          ? html`
              <div
                id="${ELEMENTS.blocker.id}"
                class="blocker"
                @click="${this.__handlers.hide}"
              ></div>
            `
          : ''
      }
    `;
  }

  render() {
    return html`
      ${this.__renderBlocker()}

      <div class="container">
        <neb-icon
          id="${ELEMENTS.icon.id}"
          class="icon"
          icon="${this.icon}"
          style="width: ${this.iconWidth}px; height: ${
            this.iconHeight
          }px; fill: ${this.iconColor}"
          @click="${this.__handlers.show}"
        ></neb-icon>

        ${this.__renderPopover()}
      </div>
    `;
  }
}

window.customElements.define('neb-tooltip', NebTooltip);
