import {
  Method,
  buildQueryString,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

export const eclaimApiClient = new ApiClientV2({
  microservice: 'electronic-claims',
  useTenant: true,
});

export const billingApiClient = new ApiClientV2({
  microservice: 'billing',
  useTenant: true,
});

export const getEraData = async rest => {
  const basePath = '/api/v1/tenants/:tenantId/era';
  const result = await eclaimApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${basePath}${buildQueryString(rest)}`,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return result;
};

export const updateEraStatus = (eraId, status) => {
  const path = '/api/v1/tenants/:tenantId/era/:eraId/:status';
  return eclaimApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    replacements: { eraId, status },
  });
};

export const bulkUpdateStatus = data => {
  const path = '/api/v1/tenants/:tenantId/era';
  return eclaimApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    body: JSON.stringify(data),
  });
};

export const reprocessEra = (reportId, optOutLoadingIndicator = false) => {
  const path = '/api/v1/tenants/:tenantId/era/reprocess/:reportId';
  return eclaimApiClient.makeRequest({
    path,
    method: Method.POST,
    headers: { 'Content-Type': 'application/json' },
    replacements: { reportId },
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const associatePayments = async (
  eraId,
  reportId,
  paymentIds,
  optOutLoadingIndicator = false,
) => {
  const path = '/api/v1/tenants/:tenantId/era/:eraId/payments';
  await billingApiClient.makeRequest({
    path,
    method: Method.POST,
    body: JSON.stringify({ reportId, paymentIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    replacements: { eraId },
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const createSecondaryClaims = async ({
  eraId,
  lineItemIds,
  optOutLoadingIndicator = false,
  version = 1,
}) => {
  const path =
    version === 1
      ? '/api/v1/tenants/:tenantId/era/:eraId/secondary-claims'
      : '/api/v2/tenants/:tenantId/era/:eraId/secondary-claims';
  const result = await billingApiClient.makeRequest({
    path,
    method: Method.POST,
    body: JSON.stringify({ lineItemIds }),
    replacements: { eraId },
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator,
  });

  return result;
};

export const associateCharges = ({
  version = 1,
  eraId,
  reportId,
  lineItemIds,
}) => {
  const path =
    version === 1
      ? '/api/v1/tenants/:tenantId/era/:eraId/charges'
      : '/api/v2/tenants/:tenantId/era/:eraId/charges';
  return billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    body: JSON.stringify({ reportId, lineItemIds }),
    replacements: { eraId },
  });
};

export const unlinkPayment = async (
  eraId,
  paymentId,
  reportId,
  optOutLoadingIndicator = false,
) => {
  const path = '/api/v1/tenants/:tenantId/era/:eraId/payments';
  await billingApiClient.makeRequest({
    path,
    method: Method.DELETE,
    body: JSON.stringify({ paymentId, reportId }),
    headers: {
      'Content-Type': 'application/json',
    },
    replacements: { eraId },
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const removeEraCharges = async ({
  version = 1,
  eraId,
  lineItemIds,
  optOutLoadingIndicator = false,
  reportId,
  claimStatus,
}) => {
  const rawObject = {
    lineItemIds,
    reportId,
    ...(claimStatus ? { claimStatus } : {}),
  };
  const path =
    version === 1
      ? '/api/v1/tenants/:tenantId/era/:eraId/charges'
      : '/api/v2/tenants/:tenantId/era/:eraId/charges';
  await billingApiClient.makeRequest({
    path,
    method: Method.DELETE,
    body: JSON.stringify(rawObject),
    headers: { 'Content-Type': 'application/json' },
    replacements: { eraId },
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const getEraSecondaryClaimDraftsToBeCreated = async (
  eraId,
  lineItemIds,
  optOutLoadingIndicator = false,
) => {
  const result = await billingApiClient.makeRequest({
    path:
      '/api/v1/tenants/:tenantId/era/:eraId/secondary-claims-to-create-total',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ lineItemIds }),
    replacements: { eraId },
    optOutLoadingIndicator,
  });

  return result;
};

export const getChargesAssociatedToEra = async eraId => {
  const path = '/api/v1/tenants/:tenantId/era/:eraId/charges';
  const result = await billingApiClient.makeRequest({
    path,
    method: Method.GET,
    replacements: { eraId },
    optOutLoadingIndicator: false,
    headers: { 'Content-Type': 'application/json' },
  });
  return result && result.map(({ lineItemId }) => lineItemId);
};
