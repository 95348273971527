import { html, css } from 'lit';

import { getMacroSetFolderById } from '../../../../packages/neb-api-client/src/macro-set-api-client';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../misc/settings/macros/neb-folder-finder';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_LARGE,
} from '../../../../packages/neb-styles/neb-variables';
import { MACRO_BUTTON_TYPE } from '../../../../packages/neb-utils/macro-sets';
import { deepCopy, setValueByPath } from '../../../../packages/neb-utils/utils';
import { mergeFolders } from '../../../utils/macros';

export const ELEMENTS = {
  header: { id: 'header' },
  actionBar: { id: 'action-bar' },
  folderFinder: { id: 'folder-finder' },
};

const SOAP_KEYS = [
  'subjectiveFolderId',
  'objectiveFolderId',
  'assessmentFolderId',
  'planFolderId',
];

class NebOverlayMacroFolderFinder extends Overlay {
  static get properties() {
    return {
      __selectedFolderId: String,
      __selectedItemStack: Array,
      __folderStack: Array,
      __state: Object,

      model: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }

        .spacing {
          margin: ${CSS_SPACING};
        }

        .finder {
          overflow-y: auto;
          flex: 1;
          border-radius: 4px;
          box-shadow: rgb(0 0 0 / 20%) 0 1px 3px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__selectedFolderId = '';
    this.__selectedItemStack = [];
    this.__folderStack = [];
    this.__newlyFetchedFolders = {};
    this.__newlyFetchedAnswerSets = {};
    this.__state = {};

    this.model = {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      dismissToMacroGrid: async () => {
        await this.handlers.dismiss();

        if (!this.isDirty) {
          this.handlers.dismiss();
        }
      },

      selectItem: async e => {
        const { index } = e.currentTarget;
        this.__selectedFolderId = e.currentTarget.item.id;

        this.isDirty = true;

        this.__selectedItemStack = [
          ...this.__selectedItemStack.slice(0, index),
          {
            folderId: this.__selectedFolderId,
            rowIndex: e.currentTarget.rowIndex,
          },
        ];

        let selectedFolderItems =
          this.__state.folders[this.__selectedFolderId].items || [];

        if (selectedFolderItems.length === 0) {
          const res = await getMacroSetFolderById(
            this.__selectedFolderId,
            this.model.id,
          );

          this.__newlyFetchedFolders = {
            ...this.__newlyFetchedFolders,
            ...res.folders,
          };

          this.__newlyFetchedAnswerSets = {
            ...this.__newlyFetchedAnswerSets,
            ...res.answerSets,
          };

          const mergedFolders = mergeFolders(
            this.__state.folders,
            deepCopy(res.folders),
          );

          const deepState = { ...this.__state };
          setValueByPath(deepState, ['folders'], mergedFolders);
          setValueByPath(deepState, ['answerSets'], {
            ...deepCopy(res.answerSets),
            ...this.__state.answerSets,
          });

          this.__state = deepState;

          selectedFolderItems = this.__state.folders[this.__selectedFolderId]
            .items;
        }
        const onlyFolderItems = selectedFolderItems.filter(
          item => !!item && item.type === MACRO_BUTTON_TYPE.FOLDER,
        );

        if (onlyFolderItems.length) {
          await this.__populateFolderStack(index, onlyFolderItems);
        } else {
          this.__folderStack = this.__folderStack.slice(0, index + 1);
        }
      },

      setSelectedMenuLink: () => {
        this.isDirty = false;

        this.dismiss({
          type: MACRO_BUTTON_TYPE.EXISTING_FOLDER,
          selectedExistingMenuId: this.__selectedFolderId,
          folderState: this.__state,
          newlyFetchedFolders: this.__newlyFetchedFolders,
          newlyFetchedAnswerSets: this.__newlyFetchedAnswerSets,
        });
      },
    };
  }

  async firstUpdated() {
    super.firstUpdated();

    this.__state = this.model;

    const res = await Promise.all(
      SOAP_KEYS.map(key => {
        const id = this.__state[key];

        if (this.__state.folders[id].items) return null;

        return getMacroSetFolderById(id, this.model.id);
      }),
    );

    this.__updateWithFetchedItems(res);

    this.__folderStack = [
      SOAP_KEYS.map(key => {
        const id = this.__state[key];

        return {
          id,
          name: this.__state.folders[id].name,
          folderCount: this.__state.folders[id].items.reduce(
            (counter, item) =>
              item && item.type === MACRO_BUTTON_TYPE.FOLDER
                ? counter + 1
                : counter,
            0,
          ),
        };
      }),
    ];
  }

  __updateWithFetchedItems(res) {
    const items = res.map(f => (f ? f.folders : f));
    const newlyFetchedAnswerSets = res.reduce(
      (acc, f) => (f ? { ...acc, ...f.answerSets } : acc),
      {},
    );

    const newFolderItems = items.reduce(
      (acc, curr) => (curr ? { ...acc, ...curr } : acc),
      {},
    );

    this.__newlyFetchedFolders = {
      ...this.__newlyFetchedFolders,
      ...newFolderItems,
    };

    this.__newlyFetchedAnswerSets = {
      ...this.__newlyFetchedAnswerSets,
      ...newlyFetchedAnswerSets,
    };

    const mergedFolders = mergeFolders(
      this.__state.folders,
      deepCopy(newFolderItems),
    );

    const deepState = { ...this.__state };
    setValueByPath(deepState, ['folders'], mergedFolders);
    setValueByPath(deepState, ['answerSets'], {
      ...newlyFetchedAnswerSets,
      ...deepState.answerSets,
    });

    this.__state = deepState;
  }

  async __populateFolderStack(index, items) {
    const res = await Promise.all(
      items.map(item =>
        !this.__state.folders[item.id].items
          ? getMacroSetFolderById(item.id, this.model.id)
          : null,
      ),
    );

    this.__updateWithFetchedItems(res);

    this.__folderStack = [
      ...this.__folderStack.slice(0, index + 1),
      items.map(item => ({
        id: item.id,
        name: this.__state.folders[item.id].name,
        folderCount: this.__state.folders[item.id].items.reduce(
          (counter, i) =>
            i && i.type === MACRO_BUTTON_TYPE.FOLDER ? counter + 1 : counter,
          0,
        ),
      })),
    ];
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="spacing"
        title="Choose Menu"
        showBackButton
        showCancelButton
        .onBack="${this.handlers.dismiss}"
        .onCancel="${this.handlers.dismissToMacroGrid}"
      ></neb-popup-header>
    `;
  }

  __renderActionBar() {
    return this.isDirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="Done"
            cancelLabel="Cancel"
            .confirmDisabled="${
              this.model.currentFolderId === this.__selectedFolderId
            }"
            .onConfirm="${this.handlers.setSelectedMenuLink}"
            .onCancel="${this.handlers.dismiss}"
          ></neb-action-bar>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}
      <neb-folder-finder
        id="${ELEMENTS.folderFinder.id}"
        class="finder spacing"
        .folderStack="${this.__folderStack}"
        .selectedFolderId="${this.__selectedFolderId}"
        .selectedItemStack="${this.__selectedItemStack}"
        .onSelectItem="${this.handlers.selectItem}"
      >
      </neb-folder-finder>
      ${this.__renderActionBar()}
    `;
  }
}

customElements.define(
  'neb-overlay-macro-folder-finder',
  NebOverlayMacroFolderFinder,
);
