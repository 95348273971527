import '../../../../../src/components/misc/neb-card-switch';
import '../../../../../src/components/misc/neb-card-select';
import { isRequired } from '@neb/form-validators';
import { LitElement, html, css } from 'lit';

import { isSuperUser } from '../../../../neb-api-client/src/permissions-api-client';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_2,
} from '../../../../neb-styles/neb-variables';
import { COGNITO_TYPES } from '../../../../neb-utils/cognito-util';
import * as selectors from '../../../../neb-utils/selectors';

export const PERMISSIONS = [
  {
    name: 'patients',
    title: 'Patients',
    description:
      'All users have access to view the patient roster, and to manage basic patient information.',
    icon: 'neb:patients',
    access: true,
    alwaysOn: true,
  },
  {
    name: 'dashboard',
    title: 'Analytics',
    description: 'Users who need access to view analytics.',
    icon: 'neb:analytics',
    access: true,
    alwaysOn: false,
  },
  {
    name: 'scheduling',
    title: 'Scheduling',
    description:
      'Users who need access to view the schedule, create appointments for patients, and manage blocked off times.',
    icon: 'neb:calendar',
    access: true,
    alwaysOn: false,
  },
  {
    name: 'charting',
    title: 'Charting',
    description:
      'Users who need access to view and modify patient encounters. This includes the ability to create encounters, chart encounters, and manage encounters.',
    icon: 'neb:charting',
    access: true,
    alwaysOn: false,
  },
  {
    name: 'billing',
    title: 'Billing',
    description:
      'Users who need access to view and manage patient billing information, generate invoices and statements, and manage insurance claims.',
    icon: 'neb:creditCard',
    access: true,
    alwaysOn: false,
  },
  {
    name: 'settings',
    title: 'Settings',
    description:
      'Users who need access to manage and configure practice level settings.',
    icon: 'neb:settings',
    access: true,
    alwaysOn: false,
  },
];

const AUTO_LOGOUT_CONFIG = {
  name: 'inactiveLogout',
  title: 'Automated Logout',
  description:
    'If enabled, the user will be automatically logged out of the software after 2 hours of inactivity. We strongly recommend leaving this setting enabled based on HIPAA regulations.',
  icon: 'neb:timing',
  access: true,
  alwaysOn: false,
};

const LOCATIONS_CONFIG = {
  name: 'locations',
  title: 'Location Access',
  description: 'Locations this user can access.',
  icon: 'neb:location',
};

export const ELEMENTS = {
  container: { id: 'container' },
  containerPermissions: { id: 'container-permissions' },
  info: { id: 'info' },
  permissions: { selector: 'permission' },
  permissionSuperUser: { id: 'permission-super-user' },
  permissionDashboard: { id: 'permission-dashboard' },
  permissionScheduling: { id: 'permission-scheduling' },
  permissionPatients: { id: 'permission-patients' },
  permissionCharting: { id: 'permission-charting' },
  permissionBilling: { id: 'permission-billing' },
  permissionSettings: { id: 'permission-settings' },
  permissionLocations: { id: 'permission-locations' },
  seperator: { id: 'seperator' },
  locationText: { id: 'location-text' },
  inactiveLogout: { id: 'inactive-logout' },
};

export default class NebPermissions extends LitElement {
  static get properties() {
    return {
      __superUserPermission: Boolean,

      model: Object,
      errors: Object,
      layout: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__superUserPermission = false;

    this.layout = '';
    this.model = null;
    this.errors = {};
    this.locations = [];

    this.onChange = () => {};
  }

  static createModel() {
    return {
      superUser: false,
      permissions: PERMISSIONS,
      locations: [],
      inactiveLogout: true,
    };
  }

  static createSelectors(locations) {
    return {
      locations: selectors.multiSelect(locations, locations, {
        validators: [isRequired()],
      }),
    };
  }

  __initHandlers() {
    this.__handlers = {
      change: e => this.onChange(e),
    };
  }

  update(changedProps) {
    const {
      session: {
        item: { type: cognitoType },
      },
    } = store.getState();

    this.__superUserPermission =
      cognitoType === COGNITO_TYPES.SUPPORT ? true : isSuperUser();

    super.update(changedProps);
  }

  static get styles() {
    return [
      css`
        .container {
          flex: 1 0 0;
          display: block;
          height: fit-content;
          width: 100%;
          padding: ${CSS_SPACING} 0;
        }

        .info {
          margin-bottom: ${CSS_SPACING};
          padding: 0 ${CSS_SPACING};
        }

        .seperator {
          display: flex;
          width: 100%;
          margin-bottom: ${CSS_SPACING};

          box-sizing: border-box;
          border: 1px solid ${CSS_COLOR_GREY_2};
          border-image: initial;
        }

        .location {
          display: flex;
          width: 100%;
          margin-bottom: ${CSS_SPACING};
          padding-left: ${CSS_SPACING};
          box-sizing: border-box;
        }

        .permission {
          display: flex;
          margin-bottom: ${CSS_SPACING};
          padding: 0 ${CSS_SPACING};
        }
      `,
    ];
  }

  __renderInstructionalText() {
    return this.__superUserPermission
      ? html`
          <div id="${ELEMENTS.info.id}" class="info">
            To start, all users have access to view and manage basic patient
            information. To give users access to additional functionality, you
            can assign one or more permissions groups below.
          </div>
        `
      : '';
  }

  __renderLocations({ name, description, title, icon }) {
    return html`
      <div id="${ELEMENTS.locationText.id}" class="location">
        By default, users have access to all locations. To customize access, you
        can select one or more locations below.
      </div>

      <neb-card-select
        id="${ELEMENTS.permissionLocations.id}"
        class="permission permission-${name}"
        name="${name}"
        label="Locations"
        .description="${description}"
        .title="${title}"
        .icon="${icon}"
        .readOnly="${!this.__superUserPermission}"
        .items="${this.locations}"
        .values="${this.model.locations}"
        .error="${this.errors.locations}"
        .layout="${this.layout}"
        .onChange="${this.__handlers.change}"
      ></neb-card-select>
    `;
  }

  __renderAutoLogout({ name, description, title, icon }) {
    return html`
      <neb-card-switch
        id="${ELEMENTS.inactiveLogout.id}"
        class="permission inactive-logout"
        name="${name}"
        .description="${description}"
        .title="${title}"
        .icon="${icon}"
        .readOnly="${!this.__superUserPermission}"
        .values="${this.model.inactiveLogout}"
        .error="${this.errors.inactiveLogout}"
        .layout="${this.layout}"
        .onChange="${this.__handlers.change}"
        .active="${this.model.inactiveLogout}"
        ?disabled="${!this.__superUserPermission}"
      ></neb-card-switch>
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        ${this.__renderInstructionalText()}

        <neb-card-switch
          id="${ELEMENTS.permissionSuperUser.id}"
          class="permission permission-super-user"
          name="superUser"
          description="Users who need access to all functions in the practice,
            including the ability to create and manage additional practice users.
            Users with this permissions group will have global access to all pages
            and functions."
          title="Super User"
          icon="neb:userAdmin"
          .active="${this.model.superUser}"
          .layout="${this.layout}"
          .onChange="${this.__handlers.change}"
          ?disabled="${!this.__superUserPermission}"
        ></neb-card-switch>

        <div id="${ELEMENTS.seperator.id}" class="seperator"></div>

        <div
          id="${ELEMENTS.containerPermissions.id}"
          class="container-permissions"
        >
          ${
            this.model.permissions.map(
              ({ name, description, title, icon, access, alwaysOn }, index) =>
                html`
                  <neb-card-switch
                    id="permission-${name}"
                    class="permission permission-${name}"
                    name="permissions.${index}.access"
                    .description="${description}"
                    .title="${title}"
                    .icon="${icon}"
                    .active="${access || this.model.superUser}"
                    .layout="${this.layout}"
                    .onChange="${this.__handlers.change}"
                    ?disabled="${
                      this.model.superUser || !this.__superUserPermission
                    }"
                    ?alwaysOn="${alwaysOn}"
                  ></neb-card-switch>
                `,
            )
          }
          ${this.__renderAutoLogout(AUTO_LOGOUT_CONFIG)}
          ${this.__renderLocations(LOCATIONS_CONFIG)}
        </div>
      </div>
    `;
  }
}
customElements.define('neb-permissions', NebPermissions);
