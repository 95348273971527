import { isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../controls/inputs/neb-checkbox';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';

import NebForm, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/forms/neb-form';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import {
  centsToCurrency,
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import { ITEM_EMPTY } from '../../../../packages/neb-utils/selectors';
import * as selectors from '../../../../packages/neb-utils/selectors';
import { deepCopy, getValueByPath } from '../../../../packages/neb-utils/utils';
import { getVisitSummary } from '../../../api-clients/visit-summary';
import { CSS_COLOR_GREY_7, CSS_SPACING } from '../../../styles';
import {
  CONFIG_FEES_AND_PURCHASES,
  CardVisitSummary,
} from '../../misc/neb-card-visit-summary';

export const OVERLAY_TITLE = 'Add Encounters';
export const OVERLAY_DESCRIPTION =
  'Choose the encounter(s) you would like view on the patient visit summary';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  cards: { selector: '[id^=card-]' },
  textEmpty: { id: 'text-empty' },
  textGrandTotal: { id: 'text-total' },
  buttonAddEncounters: { id: 'button-add-encounters' },
  buttonChangeProviderSignature: { id: 'button-change-signature' },
  buttonHideDiagnoses: { id: 'button-hide-diagnoses' },
  buttonHideAppointmentInfo: { id: 'button-hide-appointment-info' },
  buttonHideSignatures: { id: 'button-hide-signatures' },
  buttonHidePracticeInfo: { id: 'button-hide-practice-info' },
  buttonHidePatientInfo: { id: 'button-hide-patient-info' },
  buttonHideCharges: { id: 'button-hide-charges' },
  buttonHideAcknowledgement: { id: 'button-hide-acknowledgement' },
  buttonHideCreatedDate: { id: 'button-hide-created-date' },
  buttonHideFeesAndPurchases: { id: 'button-hide-fees-and-purchases' },
  buttonRemoveEncounters: { selector: '[id^=button-remove-]' },
  textProviderName: { selector: '[id^=text-provider-name-]' },
  imageProviderSignature: { selector: '[id^=image-provider-signature-]' },
  tableFeesAndPurchases: { id: 'table-fees-and-purchases' },
  cardTotals: { id: 'card-totals' },
  locationCheckbox: { id: 'location-checkbox' },
  locationDropdown: { id: 'location-dropdown' },
};

const APPEND_KEYS = {
  encounters: [
    'id',
    'patientId',
    'providerId',
    'balanceForward',
    'totalLineItemBalance',
    'displayedTotalBalance',
    'displayedBalanceForward',
    'serviceDate',
    'location.id',
    'location.address1',
    'location.address2',
    'location.city',
    'location.state',
    'location.zipCode',
    'location.businessName',
    'appointmentDetails.id',
    'appointmentDetails.start',
    'appointmentDetails.end',
    'appointmentDetails.locationId',
    'appointmentDetails.locationName',
    'appointmentDetails.appointmentTypeId',
    'appointmentDetails.appointmentTypeName',
    'appointmentDetails.providerDisplayName',
  ],
  encounterDiagnoses: ['id', 'code', 'description'],
  lineItems: [
    'id',
    'encounterChargeId',
    'chargeNumber',
    'dateOfService',
    'service',
    'unit',
    'charge',
    'tax',
    'payerPayment',
    'patientPayment',
    'adjustment',
    'patientBalance',
  ],
  totals: [
    'dateOfService',
    'balanceForward',
    'totalLineItemBalance',
    'displayedBalanceForward',
    'displayedTotalBalance',
  ],
  providers: [
    'id',
    'givenName',
    'familyName',
    'middleName',
    'suffix',
    'signature',
  ],
};

function getServiceDay(date) {
  return parseDate(date)
    .endOf('day')
    .toISOString(true);
}

export default class FormVisitSummary extends NebForm {
  static get properties() {
    return {
      model: {
        type: Object,
      },
      layout: {
        reflect: true,
        type: String,
      },

      __showLocationDropdown: { type: Boolean, reflect: true },
      __activeLocations: { type: Array },
    };
  }

  constructor() {
    super();

    this.initServices();
  }

  static createModel() {
    return {
      patientId: '',
      locationId: '',
      patient: {},
      encounters: [],
      providers: [],
      feesAndPurchases: {
        totals: [],
        lineItems: [],
      },
      opts: {
        hideFeesAndPurchases: false,
        hidePracticeInfo: false,
        hidePatientInfo: false,
        hideAppointmentInfo: false,
        hideDiagnoses: false,
        hideCharges: false,
        hideAcknowledgement: false,
        hideSignatures: false,
        hideCreatedDate: false,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .right {
          justify-items: end;
        }
        .button {
          white-space: nowrap;
        }

        .grid-action {
          grid-template-columns: 1fr 1fr 3fr 6fr;
          padding-bottom: initial;
        }

        :host([layout='large']) .grid-action {
          grid-template-rows: ${CSS_SPACING};
          padding-top: 26px;
        }

        :host([__showLocationDropdown][layout='large']) .grid-action {
          grid-template-columns: 1fr 1fr 3fr 7fr;
          grid-template-rows: 30px;
          padding-top: 5px;
          align-items: baseline;
        }

        :host([layout='medium']) .grid-action {
          grid-template-rows: 30px;
          padding-top: ${CSS_SPACING};
        }

        :host([__showLocationDropdown][layout='medium']) .grid-action {
          grid-template-rows: 35px;
          padding-top: initial;
          align-items: baseline;
        }

        .grid-button-remove {
          grid-template-columns: 12px 1fr;
        }

        :host([layout='small']) .button-actions {
          grid-template-columns: 1fr;
        }

        .signature {
          max-width: 200px;
        }

        .signature-line {
          width: 200px;
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
        }
      `,
    ];
  }

  createSelectors() {
    return {
      children: {
        encounters: {
          validators: [isRequired()],
          unformat: v => {
            v.forEach((item, index) => {
              item.lineItems = this.__formatEncounterCharges(item, index);
            });

            return v;
          },
          createItem: () => ({
            id: '',
            patientId: '',
            providerId: '',
            encounterDiagnoses: [],
            appointmentDetails: {
              id: '',
              start: '',
              end: '',
              locationId: '',
              locationName: '',
              appointmentTypeId: '',
              appointmentTypeName: '',
              providerDisplayName: '',
            },
            location: {
              id: '',
              address1: '',
              address2: '',
              city: '',
              state: '',
              zipCode: '',
              businessName: '',
            },
            lineItems: [],
            balanceForward: 0,
            totalLineItemBalance: 0,
            displayedTotalBalance: 0,
            displayedBalanceForward: 0,
            serviceDate: '',
          }),
          children: {
            $: {
              children: {
                lineItems: {
                  createItem: () => ({
                    id: '',
                    encounterChargeId: '',
                    chargeNumber: 0,
                    dateOfService: '',
                    service: '',
                    unit: 0,
                    charge: 0,
                    tax: 0,
                    payerPayment: 0,
                    patientPayment: 0,
                    adjustment: 0,
                    patientBalance: 0,
                  }),
                },
                encounterDiagnoses: {
                  createItem: () => ({
                    id: '',
                    code: '',
                    description: '',
                  }),
                },
              },
            },
          },
        },
        providers: {
          createItem: () => ({
            id: '',
            givenName: '',
            familyName: '',
            middleName: '',
            suffix: '',
            signature: '',
          }),
        },
        feesAndPurchases: {
          unformat: v => ({
            totals: v.totals,
            lineItems: this.__formatFeesAndPurchases(v),
          }),
          children: {
            lineItems: {
              createItem: () => ({
                id: '',
                encounterChargeId: '',
                chargeNumber: 0,
                dateOfService: '',
                service: '',
                unit: 0,
                charge: 0,
                tax: 0,
                payerPayment: 0,
                patientPayment: 0,
                adjustment: 0,
                patientBalance: 0,
              }),
            },
            totals: {
              createItem: () => ({
                dateOfService: '',
                balanceForward: 0,
                displayedBalanceForward: 0,
                totalLineItemBalance: 0,
                displayedTotalBalance: 0,
              }),
            },
          },
        },
        locationId: selectors.select(this.locationItems, ITEM_EMPTY),
      },
    };
  }

  initState() {
    super.initState();
    this.model = {};
    this.layout = '';
    this.__activeLocations = [];
    this.__showLocationDropdown = false;
  }

  initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__activeLocations = this.__formatLocations(userLocations).filter(
        location => location.data.active,
      );
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      overrideProviderSignature: async () => {
        const providerSignature = await openPopup(
          POPUP_RENDER_KEYS.SIGNATURE_CREATE,
          {
            title: 'Provider Signature',
            message: 'I verify that the products and services were provided.',
            confirmText: 'Confirm',
            cancelText: 'Cancel',
          },
        );

        if (providerSignature) {
          this.formService.apply('providers.0.signature', providerSignature);
        }
      },

      addEncounters: async () => {
        const { patientId } = this.model;
        const {
          appointmentTypes: { items: apptTypes },
          providers: { item: providers },
        } = store.getState();

        const encounters = await openOverlay(
          OVERLAY_KEYS.ASSOCIATE_ENCOUNTERS,
          {
            patientId,
            apptTypes,
            providers,
            title: OVERLAY_TITLE,
            description: OVERLAY_DESCRIPTION,
          },
        );

        if (encounters) {
          this.loading = true;
          const newEncounterIds = encounters
            .map(e => e.id)
            .filter(e => !this.state.encounters.map(enc => enc.id).includes(e));

          if (newEncounterIds && newEncounterIds.length) {
            const { data } = await getVisitSummary(
              {
                patientId,
                body: { encounterIds: newEncounterIds },
              },
              true,
            );
            this.__applyEncounters(data);
            this.__applyFeesAndPurchases(data);
            this.__applyProviders(data);
            this.__syncEncounters();
          }
          this.loading = false;
        }
      },

      removeEncounter: e => {
        this.loading = true;
        const index = this.state.encounters.findIndex(
          encounter => encounter.id === e,
        );

        this.formService.removeItem('encounters', index);
        this.__syncEncounters();
        this.__syncProviders();
        this.formService.validateKey(['encounters'], true);
        this.loading = false;
      },

      applyOpts: e => {
        const value = getValueByPath(this.state, e.name.split('.'));
        this.formService.apply(e.name, !value);
      },
      toggleLocationDropdown: () => {
        this.__showLocationDropdown = !this.__showLocationDropdown;

        this.__clearLocationDropdown();
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    this.__locationsService.disconnect();
    super.disconnectedCallback();
  }

  get locationItems() {
    return [ITEM_EMPTY, ...this.__activeLocations];
  }

  __clearLocationDropdown() {
    if (!this.__showLocationDropdown) {
      this.state.locationId = {
        data: { ...ITEM_EMPTY.data },
      };
    }
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      data: location,
      label: location.name,
    }));
  }

  __getVisibilityLabel(item, label) {
    return item ? `Show ${label}` : `Hide ${label}`;
  }

  __getVisibilityIcon(item) {
    return item ? 'visibilityOff' : 'visible';
  }

  __syncEncounters() {
    let sorted = [];

    const model = deepCopy(this.state);
    const selectedServiceDates = {};
    const allEncounters = model.encounters.sort(
      (a, b) => parseDate(a.serviceDate).diff(parseDate(b.serviceDate)) * -1,
    );

    if (allEncounters.length) {
      const totalsByServiceDate = {};

      allEncounters.forEach(encounter => {
        if (!totalsByServiceDate[encounter.serviceDate]) {
          totalsByServiceDate[encounter.serviceDate] = encounter.balanceForward;
        }

        totalsByServiceDate[encounter.serviceDate] =
          encounter.totalLineItemBalance +
          totalsByServiceDate[encounter.serviceDate];

        selectedServiceDates[getServiceDay(encounter.serviceDate)] = true;
      }, {});

      const sameServiceDateEncounter = [];
      const serviceDateEncounters = [];

      allEncounters.forEach(encounter => {
        if (
          !serviceDateEncounters.some(
            item => item.serviceDate === encounter.serviceDate,
          )
        ) {
          serviceDateEncounters.push(encounter);
        } else {
          sameServiceDateEncounter.push(encounter);
        }
      });

      serviceDateEncounters.forEach((currentEncounter, i) => {
        const nextEncounter = serviceDateEncounters[i + 1];

        currentEncounter.displayedBalanceForward = nextEncounter
          ? currentEncounter.balanceForward -
            totalsByServiceDate[nextEncounter.serviceDate]
          : currentEncounter.balanceForward;

        currentEncounter.displayedTotalBalance =
          currentEncounter.totalLineItemBalance +
          currentEncounter.displayedBalanceForward;
      });

      sameServiceDateEncounter.forEach(currentEncounter => {
        currentEncounter.displayedBalanceForward = 0;

        currentEncounter.displayedTotalBalance =
          currentEncounter.totalLineItemBalance +
          currentEncounter.displayedBalanceForward;
      });

      sorted = [...serviceDateEncounters, ...sameServiceDateEncounter].sort(
        (a, b) => parseDate(a.serviceDate).diff(parseDate(b.serviceDate)),
      );
    }

    this.__syncFeesAndPurchases(selectedServiceDates, {
      ...model,
      encounters: sorted,
    });
  }

  __syncFeesAndPurchases(selectedServiceDates, model) {
    const { feesAndPurchases } = model;

    const totals = feesAndPurchases.totals
      .sort(
        (a, b) =>
          parseDate(a.dateOfService).diff(parseDate(b.dateOfService)) * -1,
      )
      .filter(item => selectedServiceDates[getServiceDay(item.dateOfService)]);

    const lineItems = feesAndPurchases.lineItems
      .sort(
        (a, b) =>
          parseDate(a.dateOfService).diff(parseDate(b.dateOfService)) * -1,
      )
      .filter(item => selectedServiceDates[getServiceDay(item.dateOfService)]);

    totals.forEach((currentTotal, i) => {
      const nextTotal = totals[i + 1];
      currentTotal.displayedBalanceForward = nextTotal
        ? currentTotal.balanceForward -
          (nextTotal.totalLineItemBalance + nextTotal.balanceForward)
        : currentTotal.balanceForward;

      currentTotal.displayedTotalBalance =
        currentTotal.totalLineItemBalance +
        currentTotal.displayedBalanceForward;
    });

    this.formService.refresh({
      ...model,
      feesAndPurchases: {
        lineItems,
        totals,
      },
    });
  }

  __syncProviders() {
    const encounterProviderIds = [
      ...new Set(this.state.encounters.map(enc => enc.providerId)),
    ];

    if (encounterProviderIds.length !== this.state.providers.length) {
      const index = this.state.providers.findIndex(
        p => !encounterProviderIds.includes(p.id),
      );

      this.formService.removeItem('providers', index);
    }
  }

  __applyEncounters({ encounters }) {
    encounters.forEach(encounter => {
      const encounterIndex = this.state.encounters.length;
      this.__appendItem('encounters', encounter, encounterIndex);

      encounter.encounterDiagnoses.forEach(diagnosis => {
        const name = `encounters.${encounterIndex}.encounterDiagnoses`;
        const diagnosisIndex = this.state.encounters[encounterIndex]
          .encounterDiagnoses.length;

        this.__appendItem(name, diagnosis, diagnosisIndex);
      });

      encounter.lineItems.forEach(lineItem => {
        const name = `encounters.${encounterIndex}.lineItems`;
        const lineItemIndex = this.state.encounters[encounterIndex].lineItems
          .length;

        this.__appendItem(name, lineItem, lineItemIndex);
      });
    });
  }

  __applyFeesAndPurchases({ feesAndPurchases }) {
    feesAndPurchases.lineItems.forEach(lineItem => {
      if (
        !this.state.feesAndPurchases.lineItems
          .map(li => li.id)
          .includes(lineItem.id)
      ) {
        const name = 'feesAndPurchases.lineItems';
        const lineItemIndex = this.state.feesAndPurchases.lineItems.length;

        this.__appendItem(name, lineItem, lineItemIndex);
      }
    });

    feesAndPurchases.totals.forEach(total => {
      if (
        !this.state.feesAndPurchases.totals
          .map(t => t.dateOfService)
          .includes(total.dateOfService)
      ) {
        const name = 'feesAndPurchases.totals';
        const totalsIndex = this.state.feesAndPurchases.totals.length;
        this.__appendItem(
          name,
          {
            ...total,
            displayedBalanceForward: total.displayedBalanceForward || 0,
            displayedTotalBalance: total.displayedTotalBalance || 0,
          },
          totalsIndex,
        );
      }
    });
  }

  __applyProviders({ providers }) {
    providers.forEach(provider => {
      if (!this.state.providers.map(p => p.id).includes(provider.id)) {
        const index = this.state.providers.length;
        this.__appendItem('providers', provider, index);
      }
    });
  }

  __getBusinessOrLocationName(location, name) {
    return location && location.businessName ? location.businessName : name;
  }

  __appendItem(name, item, index) {
    this.formService.addItem(name);
    APPEND_KEYS[name.split('.').slice(-1)[0]].forEach(key => {
      if (name === 'encounters' && key === 'appointmentDetails.locationName') {
        this.formService.apply(
          `${name}.${index}.${key}`,
          this.__getBusinessOrLocationName(
            item.location,
            item.appointmentDetails.locationName,
          ),
        );
      } else {
        this.formService.apply(
          `${name}.${index}.${key}`,
          getValueByPath(item, key.split('.')),
        );
      }
    });
  }

  __formatEncounterCharges(item, index) {
    return [
      ...(index === 0 || item.displayedBalanceForward
        ? [
            {
              id: '',
              encounterChargeId: '',
              chargeNumber: '',
              dateOfService: item.serviceDate,
              service: `Balance Forward as of ${parseDate(
                item.serviceDate,
              ).format('MM/DD/YYYY hh:mm A')} - Encounter Charges`,
              unit: '',
              charge: '',
              tax: '',
              payerPayment: '',
              patientPayment: '',
              adjustment: '',
              patientBalance: item.displayedBalanceForward,
            },
          ]
        : []),
      ...item.lineItems,
      {
        id: '',
        encounterChargeId: '',
        chargeNumber: '',
        dateOfService: item.serviceDate,
        service: 'Total Balance',
        unit: '',
        charge: '',
        tax: '',
        payerPayment: '',
        patientPayment: '',
        adjustment: '',
        patientBalance: item.displayedTotalBalance,
      },
    ];
  }

  __formatFeesAndPurchases(feesAndPurchases) {
    const lineItems = feesAndPurchases.lineItems.reduce(
      (acc, li) => {
        const date = getServiceDay(li.dateOfService);

        if (!acc[date]) acc[date] = [];
        acc[date].push(li);
        return acc;
      },

      {},
    );
    return feesAndPurchases.totals.reduceRight((acc, total, index) => {
      acc = [
        ...acc,
        ...(index === feesAndPurchases.totals.length - 1 ||
        total.displayedBalanceForward
          ? [
              {
                id: '',
                encounterChargeId: '',
                chargeNumber: '',
                dateOfService: '',
                service:
                  index !== this.state.feesAndPurchases.totals.length - 1
                    ? `Balance Forward from ${parseDate(
                        this.state.feesAndPurchases.totals[index + 1]
                          .dateOfService,
                      ).format('L')} to ${parseDate(total.dateOfService).format(
                        'L',
                      )} - Fees and Purchases`
                    : `Balance Forward as of ${parseDate(
                        total.dateOfService,
                      ).format('L')} - Fees and Purchases`,
                unit: '',
                charge: '',
                tax: '',
                payerPayment: '',
                patientPayment: '',
                adjustment: '',
                patientBalance: total.displayedBalanceForward,
              },
            ]
          : []),
        ...(lineItems[total.dateOfService] || []),
        ...(index === 0
          ? [
              {
                id: '',
                encounterChargeId: '',
                chargeNumber: '',
                dateOfService: '',
                service: 'Total Balance',
                unit: '',
                charge: '',
                tax: '',
                payerPayment: '',
                patientPayment: '',
                adjustment: '',
                patientBalance:
                  feesAndPurchases.totals[0].totalLineItemBalance +
                  feesAndPurchases.totals[0].balanceForward,
              },
            ]
          : []),
      ];

      return acc;
    }, []);
  }

  __renderCards() {
    return this.state.encounters.length
      ? html`
          ${
            this.state.encounters.map((item, index) =>
              this.__renderCard(item, index),
            )
          }
          ${this.__renderFeeAndPurchaseTable()} ${this.__renderTotals()}
          ${this.__renderSignatures()}
        `
      : html`
          <div class="grid">
            <neb-text id="${ELEMENTS.textEmpty.id}" color="alert">
              No Encounters Selected.
            </neb-text>
          </div>
        `;
  }

  __renderCard(item, index) {
    const model = {
      ...CardVisitSummary.createModel(),
      ...item,
      lineItems: this.__formatEncounterCharges(item, index),
    };

    return html`
      <div class="grid grid-button-remove">
        <neb-button-icon
          id="button-remove-${index}"
          name="${item.id}"
          icon="neb:minus"
          .onClick="${this.handlers.removeEncounter}"
        ></neb-button-icon>

        <neb-card-visit-summary
          id="card-${index}"
          layout="${this.layout}"
          .model="${model}"
        ></neb-card-visit-summary>
      </div>
    `;
  }

  __renderFeeAndPurchaseTable() {
    return !this.state.opts.hideFeesAndPurchases
      ? html`
          <neb-header label="Fees and Purchases"></neb-header>

          <neb-table
            id="${ELEMENTS.tableFeesAndPurchases.id}"
            .config="${CONFIG_FEES_AND_PURCHASES}"
            .layout="${this.layout}"
            .model="${
              this.__formatFeesAndPurchases(this.state.feesAndPurchases)
            }"
          ></neb-table>
        `
      : '';
  }

  __formatTotals() {
    const encounterChargeTotal = this.state.encounters.reduce((acc, e) => {
      acc += e.displayedTotalBalance;
      return acc;
    }, 0);

    const feesAndPurchasesTotal = this.state.feesAndPurchases.totals.reduce(
      (acc, t) => {
        acc += t.displayedTotalBalance;
        return acc;
      },
      0,
    );

    return `Grand Total: ${centsToCurrency(
      encounterChargeTotal + feesAndPurchasesTotal,
    )}`;
  }

  __renderTotals() {
    return html`
      <div class="grid right">
        <neb-text id="${ELEMENTS.textGrandTotal.id}" bold>
          ${this.__formatTotals()}
        </neb-text>
      </div>
    `;
  }

  __renderSignatures() {
    return html`
      ${
        this.state.providers.map((item, index) =>
          this.__renderSignature(item, index),
        )
      }
    `;
  }

  __renderSignature(item, index) {
    return html`
      <div class="grid">
        <div class="signature-line">
          <img
            id="image-provider-signature-${index}"
            class="signature"
            src="${item.signature}"
          />
        </div>

        <neb-text id="text-provider-name-${index}">
          ${
            objToName(
              {
                first: item.givenName,
                last: item.familyName,
                middle: item.middleName,
                suffix: item.suffix,
              },
              DEFAULT_NAME_OPTS,
            )
          }
        </neb-text>
      </div>
    `;
  }

  __renderLocationCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.locationCheckbox.id}"
        class="checkbox"
        name="locations"
        label="Select Location"
        .checked="${this.__showLocationDropdown}"
        .onChange="${this.handlers.toggleLocationDropdown}"
      ></neb-checkbox>
    `;
  }

  __renderLocationDropdown() {
    return this.__showLocationDropdown
      ? html`
          <neb-select
            id="${ELEMENTS.locationDropdown.id}"
            name="locationId"
            label="Location"
            select
            wrapText
            .items="${this.locationItems}"
            .value="${this.state.locationId}"
            .onChange="${this.handlers.change}"
          ></neb-select>
        `
      : '';
  }

  renderActionBar() {
    return html`
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel="Patient Signature"
        .cancelLabel="${this.cancelLabel}"
        .onConfirm="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
      ></neb-action-bar>
    `;
  }

  renderContent() {
    return html`
      <div class="grid grid-action button-actions">
        <neb-button-action
          id="${ELEMENTS.buttonAddEncounters.id}"
          class="button"
          leadingIcon="calendar"
          label="Add Encounters"
          .onClick="${this.handlers.addEncounters}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.buttonChangeProviderSignature.id}"
          class="button"
          leadingIcon="edit"
          label="Change Provider Signature"
          .disabled="${this.state.providers.length > 1}"
          .onClick="${this.handlers.overrideProviderSignature}"
        ></neb-button-action>
        ${this.__renderLocationCheckbox()}${this.__renderLocationDropdown()}
        <div></div>
      </div>

      <neb-header label="Hide/Show Content"></neb-header>

      <div class="grid grid-3 button-actions">
        <neb-button-action
          id="${ELEMENTS.buttonHidePracticeInfo.id}"
          class="button"
          name="opts.hidePracticeInfo"
          .label="${
            this.__getVisibilityLabel(
              this.state.opts.hidePracticeInfo,
              'Practice Info',
            )
          }"
          .leadingIcon="${
            this.__getVisibilityIcon(this.state.opts.hidePracticeInfo)
          }"
          .onClick="${this.handlers.applyOpts}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.buttonHideDiagnoses.id}"
          class="button"
          name="opts.hideDiagnoses"
          .label="${
            this.__getVisibilityLabel(
              this.state.opts.hideDiagnoses,
              'Diagnoses',
            )
          }"
          .leadingIcon="${
            this.__getVisibilityIcon(this.state.opts.hideDiagnoses)
          }"
          .onClick="${this.handlers.applyOpts}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.buttonHideAppointmentInfo.id}"
          class="button"
          name="opts.hideAppointmentInfo"
          .label="${
            this.__getVisibilityLabel(
              this.state.opts.hideAppointmentInfo,
              'Appointment Info',
            )
          }"
          .leadingIcon="${
            this.__getVisibilityIcon(this.state.opts.hideAppointmentInfo)
          }"
          .onClick="${this.handlers.applyOpts}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.buttonHideSignatures.id}"
          class="button"
          name="opts.hideSignatures"
          .label="${
            this.__getVisibilityLabel(
              this.state.opts.hideSignatures,
              'Signatures',
            )
          }"
          .leadingIcon="${
            this.__getVisibilityIcon(this.state.opts.hideSignatures)
          }"
          .onClick="${this.handlers.applyOpts}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.buttonHidePatientInfo.id}"
          class="button"
          name="opts.hidePatientInfo"
          .label="${
            this.__getVisibilityLabel(
              this.state.opts.hidePatientInfo,
              'Patient Info',
            )
          }"
          .leadingIcon="${
            this.__getVisibilityIcon(this.state.opts.hidePatientInfo)
          }"
          .onClick="${this.handlers.applyOpts}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.buttonHideCharges.id}"
          class="button"
          name="opts.hideCharges"
          .label="${
            this.__getVisibilityLabel(
              this.state.opts.hideCharges,
              'Encounter Charges',
            )
          }"
          .leadingIcon="${
            this.__getVisibilityIcon(this.state.opts.hideCharges)
          }"
          .onClick="${this.handlers.applyOpts}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.buttonHideFeesAndPurchases.id}"
          class="button"
          name="opts.hideFeesAndPurchases"
          .label="${
            this.__getVisibilityLabel(
              this.state.opts.hideFeesAndPurchases,
              'Fees and Purchases',
            )
          }"
          .leadingIcon="${
            this.__getVisibilityIcon(this.state.opts.hideFeesAndPurchases)
          }"
          .onClick="${this.handlers.applyOpts}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.buttonHideAcknowledgement.id}"
          class="button"
          name="opts.hideAcknowledgement"
          .label="${
            this.__getVisibilityLabel(
              this.state.opts.hideAcknowledgement,
              'Acknowledgement',
            )
          }"
          .leadingIcon="${
            this.__getVisibilityIcon(this.state.opts.hideAcknowledgement)
          }"
          .onClick="${this.handlers.applyOpts}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.buttonHideCreatedDate.id}"
          class="button"
          name="opts.hideCreatedDate"
          .label="${
            this.__getVisibilityLabel(
              this.state.opts.hideCreatedDate,
              'Created Date',
            )
          }"
          .leadingIcon="${
            this.__getVisibilityIcon(this.state.opts.hideCreatedDate)
          }"
          .onClick="${this.handlers.applyOpts}"
        ></neb-button-action>
      </div>

      ${this.__renderCards()}
    `;
  }
}

window.customElements.define('neb-form-visit-summary', FormVisitSummary);
