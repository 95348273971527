import '../../../../../neb-material-design/src/components/neb-md-textfield';
import './neb-package-template-covered-charges-controller';
import '../../neb-action-bar';

import { html, css, LitElement } from 'lit';

import { getActiveMerchantAccountCount } from '../../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../neb-styles/neb-variables';
import { PACKAGE_TYPE } from '../../../../../neb-utils/enums';

import { MODEL_KEYS as COVERED_MODEL_KEYS } from './neb-package-template-covered-charges';
import { MODEL_KEYS as LIMITS_MODEL_KEYS } from './neb-package-template-limits-form';
import { MODEL_KEYS as SUMMARY_MODEL_KEYS } from './neb-package-template-summary';
import { MODEL_KEYS as TYPE_MODEL_KEYS } from './neb-package-template-type-form';

export const ELEMENTS = {
  actionBar: {
    id: 'action-bar',
  },
  coveredChargesController: {
    id: 'covered-charges-controller',
  },
  limitsHeader: {
    id: 'limits-header',
  },
  limitsForm: {
    id: 'form-limits',
  },
  summaryForm: {
    id: 'form-summary',
  },
  typeForm: {
    id: 'type-form',
  },
};

export const EMPTY_MODEL = {
  [COVERED_MODEL_KEYS.CHARGES]: [],
  [LIMITS_MODEL_KEYS.FREQUENCY]: null,
  [LIMITS_MODEL_KEYS.TOTAL_PRICE]: '',
  [LIMITS_MODEL_KEYS.TOTAL_VISITS]: '',
  [LIMITS_MODEL_KEYS.UNLIMITED]: false,
  [TYPE_MODEL_KEYS.NOTES]: '',
  [TYPE_MODEL_KEYS.SUBSCRIPTION]: false,
  [TYPE_MODEL_KEYS.TYPE]: PACKAGE_TYPE.VISIT,
  [SUMMARY_MODEL_KEYS.NAME]: '',
};

class NebPackagesTemplateForm extends LitElement {
  static get properties() {
    return {
      model: Object,
      disableSubscriptions: Boolean,
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__elements = {};
    this.model = { ...EMPTY_MODEL };
    this.disableSubscriptions = false;

    this.onChange = () => {};

    this.onAddCharge = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addCharge: () => this.onAddCharge(),
      changeField: ({ key, value }) =>
        this.onChange({
          key,
          value,
        }),
      removeCharge: charge =>
        this.onChange({
          key: COVERED_MODEL_KEYS.CHARGES,
          value: this.model.charges.filter(c => !(c === charge)),
        }),
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.disableSubscriptions = (await getActiveMerchantAccountCount()) === 0;
  }

  updated() {
    this.__elements = {
      limitsForm: this.shadowRoot.getElementById(ELEMENTS.limitsForm.id),
      summaryForm: this.shadowRoot.getElementById(ELEMENTS.summaryForm.id),
      coveredChargesController: this.shadowRoot.getElementById(
        ELEMENTS.coveredChargesController.id,
      ),
    };
  }

  async validate() {
    const validPromise = [
      this.__elements.summaryForm.validate(),
      this.__elements.limitsForm.validate(),
      this.__elements.coveredChargesController.validate(),
    ];
    const validValues = await Promise.all(validPromise);
    return validValues.every(v => !!v);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
        }

        .header {
          display: flex;
          align-items: center;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING};
          border-bottom: ${CSS_BORDER_GREY_2};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          flex-shrink: 0;
        }
      `,
    ];
  }

  render() {
    return html`
      <div class="container">
        <div class="header">Summary</div>
        <neb-package-template-summary
          id="${ELEMENTS.summaryForm.id}"
          .model="${this.model}"
          .onChange="${this.__handlers.changeField}"
        ></neb-package-template-summary>

        <div class="header">Package Type (Required)</div>
        <neb-package-template-type-form
          id="${ELEMENTS.typeForm.id}"
          .model="${this.model}"
          .disableSubscriptions="${this.disableSubscriptions}"
          .onChange="${this.__handlers.changeField}"
        ></neb-package-template-type-form>

        <div id="${ELEMENTS.limitsHeader.id}" class="header">
          ${
            this.model.subscription && this.model.frequency
              ? `${this.model.frequency} Limits`
              : 'Limits'
          }
        </div>

        <neb-package-template-limits-form
          id="${ELEMENTS.limitsForm.id}"
          .model="${this.model}"
          .onChange="${this.__handlers.changeField}"
        ></neb-package-template-limits-form>

        <div class="header">Covered Charges (Required)</div>
        <neb-package-template-covered-charges-controller
          id="${ELEMENTS.coveredChargesController.id}"
          .model="${this.model}"
          .onAddCharge="${this.__handlers.addCharge}"
          .onChange="${this.__handlers.changeField}"
          .onRemoveCharge="${this.__handlers.removeCharge}"
        ></neb-package-template-covered-charges-controller>
      </div>
    `;
  }
}

customElements.define('neb-packages-template-form', NebPackagesTemplateForm);
