import { mapAppointmentBillingInfoToClientModel } from './mappers/appointment-billing-info-mapper';
import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const buildBaseUrl = appointmentId =>
  `appointments/${appointmentId}/appointmentBillingInfo`;

export const getAppointmentBillingInfo = async appointmentId => {
  const json = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${buildBaseUrl(appointmentId)}`,
    version: 1,
    cacheKey: `${appointmentId}-appointment-billing-info`,
  });
  return json.count === 0
    ? null
    : mapAppointmentBillingInfoToClientModel(json.data[0]);
};

export const updateAppointmentBillingInfo = (appointmentId, billingInfo) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${buildBaseUrl(appointmentId)}/${billingInfo.id}`,
    version: 1,
    body: JSON.stringify(billingInfo),
  });

export const createAppointmentBillingInfo = async (
  appointmentId,
  billingInfo,
) => {
  const json = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `${buildBaseUrl(appointmentId)}`,
    version: 1,
    body: JSON.stringify(billingInfo),
  });

  return json.count === 0
    ? null
    : mapAppointmentBillingInfoToClientModel(json.data[0]);
};
