import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

const BASE_URL = 'organizations';

export function mapToModel(raw) {
  return {
    active: raw.isActive,
    id: raw.id || '',
    name: raw.name ? raw.name.trim() : '',
    taxId: raw.taxId || '',
    note: raw.note || '',
    emails: raw.emails.map(item => item || ''),
    phones: raw.phones.map(item => ({
      type: item.type || '',
      number: item.number || '',
    })),
    address: {
      address1: raw.address.address1,
      address2: raw.address.address2,
      city: raw.address.city,
      state: raw.address.state,
      zipcode: raw.address.zipcode,
    },
  };
}

export function mapToRaw(model) {
  return {
    isActive: model.active,
    id: model.id,
    name: model.name.trim(),
    taxId: model.taxId || null,
    note: model.note,
    emails: [...model.emails],
    phones: [...model.phones],
    address: { ...model.address },
  };
}

export async function createOrganization(model) {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(mapToRaw(model)),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    path: BASE_URL,
    updateNotificationDetails: {
      organization: {
        id: model.id,
      },
    },
  });

  return mapToModel(result);
}

export async function getOrganization(id) {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: `${BASE_URL}/${id}`,
  });

  return mapToModel(result);
}

export async function getOrganizations() {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    path: `${BASE_URL}`,
  });

  return result.map(org => mapToModel(org));
}

export async function updateOrganization(model) {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(mapToRaw(model)),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    path: `${BASE_URL}/${model.id}`,
  });

  return mapToModel(result);
}
